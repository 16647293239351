import { IconButton, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ListingTable from '../../../../listing/listing';
import { Form } from '../../../../form/Form';
import { addlandingpageonedata } from '../LandingpageReducer';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../../store';
import StateArray from '../../../../assets/json/state';
import LandingPage13Form from '../LandingPage13/LandingPage13Form';









function LandingPage14FormModal({ openModal, setopenModal }) {
    const closeModal = () => {
        setopenModal(false);
    };
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const [snackopen, sesnackopen] = useState(false);
    const [dataCopy, setDataCopy] = useState({});
    const [locationArry, setLocationArry] = useState([]);
    const [msg, setmsg] = useState('');

    // const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userForm14) ? state.formSlice?.formSubmissionState?.userForm14 : 0)
    // const formData = useSelector((state) => state.formSlice?.formData?.userForm14 ? state.formSlice.formData.userForm14 : null)
    // const landingPageSubmissionState = useSelector((state) => state.LandingpagefontReducer?.landingpagesubmittionstate ? state.LandingpagefontReducer.landingpagesubmittionstate : 0)
    // const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});
    // useEffect(() => {
    //     if (submissionState && submissionState === 4) {
    //         addapicall(formData)
    //         // console.log("submissionState===============", formData,submissionState);
    //     }

    // }, [submissionState])


    // useEffect(() => {
    //     let Newarr = [];
    //     console.log("StateArray-----", StateArray);
    //     if (StateArray && StateArray.length > 0) {
    //         for (let i in StateArray) {
    //             Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
    //         }
    //         setLocationArry(Newarr);
    //     }
    // }, [StateArray]);




    // useEffect(() => {
    //     if (landingPageSubmissionState && landingPageSubmissionState == 2) {
    //         setDataCopy(addedData)
    //         return
    //         const data = {
    //             stage_slug: '5QH6nS8FXNawY',
    //             added_by: 'System',
    //             added_for: addedData._id,
    //             is_delete: 0,
    //             status: 1
    //         }
    //         console.log("data===========================>", data)
    //         // return
    //         //   dispatch(updateStages(data));


    //         // const tagdata = {
    //         //     added_by: addedData._id,
    //         //     added_for: addedData._id,
    //         //     key: "Wnv14tRS9FlmV",
    //         //     label: "hm-policiesandcompliance-signed-success",
    //         //     is_delete: 0,
    //         //     status: 1
    //         // }
    //         // console.log("tagdata===========================>", tagdata)
    //         // // return
    //         // dispatch(updateTags(tagdata));


    //     }


    // }, [landingPageSubmissionState])

    // const addapicall = async (val) => {
    //     console.log("val>>>", val);

    //     val = { ...val }
    //     let body = {
    //         "firstname": val.firstname,
    //         "lastname": val.lastname,
    //         "email": val.email,
    //         "phone": val.phone,
    //         // "hiring_manager": val.hm_id,
    //         // "facilitytype": val.facility_type,
    //         // "other_info": val.other_info,
    //         "createdon_datetime": new Date().getTime(),
    //         "added_type": 'landingpage',
    //         // "address": val.address,
    //         "city": val.city,
    //         "state": val.state,
    //         "zip": val.zip,
    //         "status": 1,
    //         //   "roles": { is_rep: 1 },
    //         //   'rep_with_new_dashboard': true,
    //         //   "associated_program": ['63cf8c25ae5ab31db330fe10'],
    //         //   "password": val.password,
    //         //   "confirm_password": val.confirmPassword,
    //         //   "page_id": userData.landing_page_id,
    //         //   "page_slug": userData.landing_page_slug,
    //         //   "ipinfo": ipinfo,

    //     }

    //     if (Object.keys(body).length > 0) {
    //         // console.log("body-----", body);
    //         // return
    //         // setLoader(true)
    //         setloading(true)

    //         let dataresponse = await store.dispatch(addlandingpageonedata(body));
    //         console.log("dataresponse", dataresponse);
    //         setloading(false)
    //         if (dataresponse && dataresponse.payload && dataresponse.payload.status == 'error') {
    //             console.log("HITTTTT", dataresponse.payload.message);
    //             // if (landingPageSubmissionState && landingPageSubmissionState === 2) {
    //             sesnackopen(true)
    //             setmsg(dataresponse.payload.message)
    //             // dispatch(setSnackbar({ open: true, message: "jiiii", status: 'error' }))
    //         }
    //         // else {

    //         //     redirect(dataresponse.payload.results.res._id)
    //         //     // console.log("dataaddedinfo",dataaddedinfo);

    //         //     // navigate(`/thankyou/bluecoastasphire`);
    //         //     // }
    //         // }

    //         //   setActive(false)
    //         //  setLoader(false)


    //     }
    // };



















    // const formDataL3 = useRef({
    //     id: 'userForm14',
    //     formtype: "add",
    //     // api_url: process.env.REACT_APP_API_URL,
    //     // endPoint: "lead-manage/add-update-intake-form",
    //     // endPointBodyWrapper: "data",
    //     // urlPathOnSuccessfulFormSubmission: "/calendar",
    //     msgOnFailedFormSubmission: "Something Went Wrong",
    //     formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    //     formInlineCLass: "userForm", // Can be undefined default will be formInline
    //     formButtonClass: "l2submitbtnsection",
    //     submitBtnName: "Select your subscription on the next step",
    //     // submitBtnStartIcon: 'image_search_icon',
    //     // submitBtnEndIcon: 'search_icon',
    //     resetBtnName: "Reset",
    //     formAdditionalSubmissionData: {
    //         // type: "admin",
    //         // status: "active",
    //     },
    //     fields: [
    //         //////////////////////////////////// Text Input ///////////////////////////////
    //         {
    //             id: 0,
    //             // heading: "Full Name",
    //             label: "First Name",
    //             name: "firstname",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },
    //         {
    //             id: 1,
    //             // heading: "Full Name",
    //             label: "Last Name",
    //             name: "lastname",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },
    //         {
    //             id: 2,
    //             // heading: "Full Name",
    //             label: "Phone",
    //             name: "phone",
    //             className: 'formfild',
    //             type: "text",
    //             inputType: "phone",
    //             rules: { required: true, minLength: 10 },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },

    //         {
    //             id: 3,
    //             // heading: "Full Name",
    //             label: "Email",
    //             name: "email",
    //             className: 'formfild email_marg',
    //             type: "text",
    //             inputType: "email",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },
    //         {
    //             id: 30,
    //             type: "html",
    //             className: "email_btm_line",
    //             value: "<p></p>"
    //         },

    //         // {
    //         //     id: 4,
    //         //     // heading: "Street Address",
    //         //     label: "Street Address",
    //         //     name: "streetaddress",
    //         //     className: 'formfild',
    //         //     type: "text",
    //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    //         //     rules: { required: true },
    //         //     errorMessage: {
    //         //         required: "This Field is Required",
    //         //         custom: "Value is Invalid"
    //         //     },
    //         // },

    //         // {
    //         //     id: 5,
    //         //     // heading: "Street Address",
    //         //     label: "Apartment, suit, unit etc. (optional)",
    //         //     name: "apartment",
    //         //     className: 'formfild',
    //         //     type: "text",
    //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    //         //     // rules: { required: true },
    //         //     // errorMessage: {
    //         //     //     required: "This Field is Required",
    //         //     //     custom: "Value is Invalid"
    //         //     // },
    //         // },




    //         {
    //             id: 6,
    //             // heading: "Full Name",
    //             label: "Town / City",
    //             name: "city",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },
    //         // {
    //         //     id: 10,
    //         //     // heading: "Full Name",
    //         //     label: "Town / City",
    //         //     name: "city",
    //         //     className: 'formfild',
    //         //     type: "text",
    //         //     rules: { required: true },
    //         //     errorMessage: {
    //         //         required: "This Field is Required",
    //         //         custom: "Value is Invalid"
    //         //     },

    //         // },
    //         {
    //             id: 7,
    //             // heading: "Full Name",
    //             label: "State",
    //             name: "state",
    //             className: 'formfild state',
    //             type: "select",
    //             // other: true,
    //             values: StateArray,
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //             defaultValue: "",
    //         },
    //         {
    //             id: 8,
    //             // heading: "Full Name",
    //             label: "Zip / Postal Code",
    //             name: "zip",
    //             className: 'formfild',
    //             type: "text",
    //             inputType: "text",
    //             rules: { required: true, maxLength: 5, minLength: 5 },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid",
    //                 maxLength: "Maxlength length 5 allowed",
    //                 minLength: "Minlength length 5 allowed",
    //             },

    //         },
    //         // {
    //         //     id: 11,
    //         //     heading: "Please Choose Your Prescription Plan",
    //         //     name: "choose_prescription_plan",
    //         //     className: 'form_radio',
    //         //     values: [{ key: 'Family ($31.99 per mo.)', val: 'Family ($31.99 per mo.)' }, { key: 'Single +1 ($26.99 per mo.)', val: 'Single +1 ($26.99 per mo.)' }, { key: 'Single ($21.99 per mo.)', val: 'Single ($21.99 per mo.)' }],
    //         //     type: 'radio',
    //         //     // hint: 'Yes/No',
    //         //     // rules: { required: true },
    //         //     // errorMessage: {
    //         //     //   required: "This Field is Required",
    //         //     //   custom: "Value is Invalid"
    //         //     // },
    //         //     // defaultValue: "No"
    //         // }

    //     ]

    // });








    return (
        <>

            <div>
                <Modal
                    className="modalBaseStyle allMeetingMOdal SD_MOdallanding14"
                    open={openModal}
                    onClose={() => closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modalBaseBox mediumModal' >
                        <Tooltip title='Close' >
                            <IconButton onClick={() => closeModal()}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>


                        {/* <div>
                            <div className="rolelist_mainwrp form_style">
                                <h2>COMPLETE THE FORM BELOW TO ENROLL</h2>
                                <div className='rolelist_mainwrp_Table'>
                                    <Form formData={formDataL3.current} />

                                </div>
                            </div>
                        </div> */}
                        <div className="rolelist_mainwrp form_style">
                            <LandingPage13Form  noapi={true}/>

                        </div>


                    </Box>
                </Modal>
            </div>


        </>
    )
}

export default LandingPage14FormModal
