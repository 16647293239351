import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../listing/listing';
import { Button, Drawer, IconButton, LinearProgress, Paper, TableContainer, Tooltip } from '@mui/material';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommisionReportData, getcommisionReportData } from '../W9FormReducer';
import store from '../../../../store';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { useLocation, useParams } from 'react-router-dom';
import { clearPageState, clearTableCounts, clearTableOtherData, setTableCount, setTableCounts, setTableData, setTableDataAndCountAndBody, setTableDefaultreqBody, setTableFieldsData, unmountTable } from '../../../../listing/listReducer';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';
import { detailedConversionFiltredReportAffiliateListCount, detailedConversionReportAffiliateListCount, detailedConversionReportCount, resetAllListData, resetFilterData, resetInitData, conversionPieChart } from './AfffiliateConverisonReportReducer';

import AfffiliateConverisonReportModal from './AfffiliateConverisonReportModal'
import { inputExternalPlugin } from '../../../../helper/helperFunctions';


const AffiliateConversionDetailedReport = () => {

    const paramData = useParams();
    const Location = useLocation();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.AfffiliateConverisonReport?.loading ? state.AfffiliateConverisonReport.loading : false);
    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});



    const resolved = useSelector((state) =>
        state.ResolveReducer.resolved["/affiliate-conversion-detailed-report"]
            ? state.ResolveReducer.resolved["/affiliate-conversion-detailed-report"]
            : false
    );

    const listData = useSelector((state) => state.AfffiliateConverisonReport?.detailedConversionReportAffiliate && state.AfffiliateConverisonReport?.detailedConversionReportAffiliate.length > 0 ? state.AfffiliateConverisonReport.detailedConversionReportAffiliate : []);


    const filteredReportData = useSelector((state) => state.AfffiliateConverisonReport?.detailedConversionFiltredReportData ? state.AfffiliateConverisonReport.detailedConversionFiltredReportData : []);

    const ReportDataCount = useSelector((state) => state.AfffiliateConverisonReport?.ConversionDetailedReportCount ? state.AfffiliateConverisonReport.ConversionDetailedReportCount : null);

    const ReportSuccess = useSelector((state) => state.AfffiliateConverisonReport?.detailedConversionReportSuccess ? state.AfffiliateConverisonReport.detailedConversionReportSuccess : false);

    const loading = useSelector((state) => state.AfffiliateConverisonReport?.loading ? state.AfffiliateConverisonReport.loading : false);

    const dataloading = useSelector((state) => (state.AfffiliateConverisonReport?.dataloading) ? state.AfffiliateConverisonReport.dataloading : false);





    const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.CommisionReport_Search ? state.formSlice.formSubmissionState.CommisionReport_Search : false);

    const plan_type = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.plan_type ? state.formSlice.formData.CommisionReport_Search.plan_type : null);


    const username = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.username ? state.formSlice.formData.CommisionReport_Search.username : null);


    const start_date = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.start_date ? state.formSlice.formData.CommisionReport_Search.start_date : null);


    const end_date = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.end_date ? state.formSlice.formData.CommisionReport_Search.end_date : null);

    const affiliateTotalAmt = useSelector((state) => state.AfffiliateConverisonReport?.affiliateTotalAmt ? state.AfffiliateConverisonReport.affiliateTotalAmt : null);


    const pagginationTriggred = useSelector((state) => state.tableSlice?.pageState?.AffiliateConversionDetailedReport ? state.tableSlice.pageState.AffiliateConversionDetailedReport : null);



    const detailedReport = useSelector((state) => state.tableSlice?.customButtonClicked?.AffiliateConversionDetailedReport?.graph_modal ? state.tableSlice.customButtonClicked.AffiliateConversionDetailedReport.graph_modal : false);


    const rowData = useSelector((state) => state?.tableSlice?.tempRowData?.AffiliateConversionDetailedReport ? state.tableSlice.tempRowData.AffiliateConversionDetailedReport : {})


    const pieChartData = useSelector((state) => state.AfffiliateConverisonReport?.pieChartData ? state.AfffiliateConverisonReport.pieChartData : []);




    // ----- all states here ---- //
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [toogleVal, setToogleVal] = useState('all')
    const [filterData, setFilterData] = useState(false)
    const [filterListData, setFilterListData] = useState([])
    const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
    const [amtToogleFlag, setAmtToogleFlag] = useState(true);
    const [openModal, setOpenModal] = useState(false)
    const [tableRowData, setTableRowData] = useState({})

    //  -------------------------- //


    // ================ setting time stamps ====================//
    const todaysStartDate = moment().startOf('day').valueOf()
    const todaysEndDate = moment().endOf('day').valueOf()


    const weekStartDate = moment().startOf('week').valueOf()
    const weekEndDate = moment().endOf('week').valueOf()


    const monthStartDate = moment().startOf('month').valueOf()
    const monthEndDate = moment().endOf('month').valueOf()
    // ======================================================== //



    useEffect(() => {
        inputExternalPlugin("affiliate-conversion-detailed-report", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js?v=365");
    }, [])




    //  ========== rowData ======= //
    useEffect(() => {
        if (Object.keys(rowData)?.length > 0) {
            setTableRowData(rowData)
        }
    }, [JSON.stringify(rowData)])
    //  ========================== //



    //  ------- setting route path ---------- //
    var routeUrl = null
    useEffect(() => {
        if (Location?.pathname?.split('/').join('') === 'affiliate-report') {
            routeUrl = Location.pathname.split('/').join('')

            console.log("routeUrl===>", routeUrl);
        }
    }, [JSON.stringify(Location)])
    //  ---------------------------------- //



    useEffect(() => {
        return () => {
            store.dispatch(clearTableOtherData({ tableId: "AffiliateConversionDetailedReport" }));
        }
    }, [JSON.stringify(Location.pathname)])









    //  -==== Table Header Here ===== //
    var modifyTableHeaders = [
        // { val: "campaign_name", name: "Campaign Name" },

        { val: "landing_page_name", name: "Landing Page" },

        // {
        //     val: "createdon_datetime",
        //     name: "Created On",
        //     type: "datetime",
        //     format: "MM/DD/YYYY",
        // },
    ];
    //  =========================== //


    //  ======= main table ======== //
    let tableDataForConversionReport = useRef({
        tableId: "AffiliateConversionDetailedReport",
        tableTitle: "Traffic Partner Conversion Detailed Report",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/conversion-detailed-report-affiliate-list-count",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/conversion-detailed-report-affiliate-list-count",
        },

        reqBody: {
            condition: {
                limit: 30,
                skip: 0,
            },
            sort: {
                field: "record_date",
                type: "desc",
            },
            searchcondition: { affiliate_id: paramData._id },
            project: {},
            token: "",
            count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "record_date"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [


        ],
        buttons: [
            {
                id: "graph_modal",
                name: "pie_chartIcon",
                type: "other",
                label: "Conversion Graph Report",

            },
        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

        searchData: {
            heading: "Search Commission Report",
            type: "drawer",
            //   condition_type: "$or",
            formData: {
                id: "commisionsearchForm",
                formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                submitBtnName: "Search",
                resetBtnName: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [


                    {
                        id: 30,
                        label: "Search by Plan Type",
                        name: "plan_type",
                        // className: 'Status',
                        type: 'select',
                        multiple: false,
                        values: [
                            { val: "single", name: "single" },
                            { val: "single+1", name: "Single+1" },
                            { val: "Family", name: "Family" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "plan_type" },
                    },

                    {
                        id: 31,
                        label: "Search by Recorded on Start Date",
                        name: "start_date",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "record_date", param: "$gte" },
                    },
                    {
                        id: 4,
                        label: "Search by Recorded on End Date",
                        name: "end_date",
                        className: "list_search_item countDiv__column--col6 ",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "record_date", param: "$lte" },
                        // minDate:{minDate}
                        // rules: { required: true },
                        errorMessage: {
                            required: "This Field is Required",
                            custom: "End Date can not be less than Start Date"
                        },
                    },


                ],
            },
        },
    });
    // ========================= //




    // ----- entry point trigger here ---- //
    useEffect(() => {

        if (!resolved) {

            setTimeout(() => {
                searchTable('all');
            }, 200);

        }

        return () => {
            store.dispatch(resetInitData());
        };

    }, []);

    //  ---------------------------------- //

















    //   ---- graph modal open once --- //
    useEffect(() => {
        if (detailedReport) {

            let data = {}
  

            switch (toogleVal) {

                case 'all':


                    data = {
                        "landing_page_id": rowData?.landing_page_id,
                        "campaign_added_by": paramData?.id
                    }

                    dispatch(conversionPieChart(data))


                    break;

                case 'this_month':

                    data = {
                        "landing_page_id": rowData?.landing_page_id,
                        "campaign_added_by": paramData?.id,
                        "createdon_datetime": { $gte: monthStartDate, $lte: monthEndDate }
                    }

                    dispatch(conversionPieChart(data))

                    break;

                case 'this_week':


                    data = {
                        "landing_page_id": rowData?.landing_page_id,
                        "campaign_added_by": paramData?.id,
                        "createdon_datetime": { $gte: weekStartDate, $lte: weekEndDate }
                    }

                    dispatch(conversionPieChart(data))

                    break;

                case 'today':


                    data = {
                        "landing_page_id": rowData?.landing_page_id,
                        "campaign_added_by": paramData?.id,
                        "createdon_datetime": { $gte: todaysStartDate, $lte: todaysEndDate }
                    }

                    dispatch(conversionPieChart(data))

                    break;

                case "search":

                    data = {
                        "landing_page_id": rowData?.landing_page_id,
                        "campaign_added_by": paramData?.id,
                    }

                    if (start_date !== null || end_date !== null) {
                        data['createdon_datetime'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

                    }

                    dispatch(conversionPieChart(data))

                    break;

                default:
                // code block
            }

        }
    }, [detailedReport])
    //  ----------------------------- //















    // ============  on table filters click =========== //
    function searchTable(val) {
        setToogleVal(val);

        switch (val) {

            case 'all':
                dispatch(unmountTable({ "tableId": "AffiliateConversionDetailedReport" }));
                setFilterData(false)
                setAmtToogleFlag(false)
                dispatch(resetFilterData())

                let payloadAll = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "desc", "field": "createdon_datetime"
                    },
                    "searchcondition": { "affiliate_id": paramData.id }
                }

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "AffiliateConversionDetailedReport", "reqBody": payloadAll }))


                dispatch(detailedConversionReportAffiliateListCount(payloadAll))

                setTimeout(() => {
                    payloadAll['count'] = true
                    dispatch(detailedConversionReportCount(payloadAll))
                }, 200);


                break;

            case 'this_month':
                dispatch(unmountTable({ "tableId": "AffiliateConversionDetailedReport" }));
                setFilterData(true)
                dispatch(resetAllListData())
                setFilterListData([])

                let payloadMonth = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "createdon_datetime"
                    },

                    "searchcondition": { affiliate_id: paramData.id }
                }

                payloadMonth['searchcondition']['createdon_datetime'] = { $gte: monthStartDate, $lte: monthEndDate }

                dispatch(setTableDefaultreqBody({ "tableId": "AffiliateConversionDetailedReport", "reqBody": payloadMonth }))

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

                dispatch(detailedConversionFiltredReportAffiliateListCount(payloadMonth))


                setTimeout(() => {
                    payloadMonth['count'] = true
                    dispatch(detailedConversionReportCount(payloadMonth))
                }, 200);


                break;

            case 'this_week':
                dispatch(unmountTable({ "tableId": "AffiliateConversionDetailedReport" }));
                setFilterData(true)
                dispatch(resetAllListData())
                setFilterListData([])


                let payloadWeek = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "createdon_datetime"
                    },

                    "searchcondition": { affiliate_id: paramData.id }

                }

                payloadWeek['searchcondition']['createdon_datetime'] = { $gte: weekStartDate, $lte: weekEndDate }

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "AffiliateConversionDetailedReport", "reqBody": payloadWeek }))

                dispatch(detailedConversionFiltredReportAffiliateListCount(payloadWeek))

                dispatch(clearPageState({ "tableId": "AffiliateConversionDetailedReport" }))

                // dispatch(clearTableCounts({ "tableId": "AffiliateConversionDetailedReport" }))

                setTimeout(() => {
                    payloadWeek['count'] = true
                    dispatch(detailedConversionReportCount(payloadWeek))
                }, 200);


                break;

            case 'today':
                dispatch(unmountTable({ "tableId": "AffiliateConversionDetailedReport" }));
                setFilterData(true)
                dispatch(resetAllListData())
                setFilterListData([])


                let payloadToday = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "createdon_datetime"
                    },

                    "searchcondition": { affiliate_id: paramData.id }

                }

                payloadToday['searchcondition']['createdon_datetime'] = { $gte: todaysStartDate, $lte: todaysEndDate }

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadToday['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "AffiliateConversionDetailedReport", "reqBody": payloadToday }))


                dispatch(detailedConversionFiltredReportAffiliateListCount(payloadToday))


                setTimeout(() => {
                    payloadToday['count'] = true
                    dispatch(detailedConversionReportCount(payloadToday))
                }, 200);



                break;

            default:
            // code block
        }
    }
    //  ================================== //




    // ==== setTableData === //
    useEffect(() => {

        if (filterData && ReportSuccess && filteredReportData?.length > 0) {
            // dispatch(resetAllListData())
            setFilterListData(filteredReportData)
        }

    }, [JSON.stringify(filteredReportData), filterData, ReportSuccess])
    //  ================== //



    // ==== setTableData === //
    useEffect(() => {

        if (listData.length > 0) {
            setFilterListData(listData)
        }

    }, [JSON.stringify(listData)])
    //  ================== //





    useEffect(() => {
        if (filterListData) {
            dispatch(setTableData({ "tableId": "AffiliateConversionDetailedReport", "dataset": filterListData }))
        }
    }, [JSON.stringify(filterListData)])









    // ==== setTableCount === //
    useEffect(() => {
        if (ReportDataCount !== null) {
            dispatch(clearPageState({ "tableId": "AffiliateConversionDetailedReport" }))
            // dispatch(clearTableCounts({ "tableId": "AffiliateConversionDetailedReport" }))
            dispatch(setTableCounts({ "tableId": "AffiliateConversionDetailedReport", "count": ReportDataCount }))
        }
    }, [ReportDataCount])
    // ===================== //




    //----------------------search form configration---------------------
    const dataform = useRef({
        id: 'CommisionReport_Search',
        submitBtnName: "Search",
        submitBtnEndIcon: 'search_icon',
        resetForm: true,
        resetBtnName: "Refresh",
        resetBtnEndIcon: 'refresh',
        endPointBodyWrapper: "searchcondition",
        formButtonClass: "submitbtnsection",
        formWrapperClass: "formWrapperClass formCls",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {},

        fields: [



            {
                id: 31,
                label: "Search by Recorded on Start Date",
                name: "start_date",
                className: "datePicker",
                type: "datePicker",
                sx: { m: 1, width: 300 },
                className: "list_search_item countDiv__column--col6 ",
                payloadFormat: { key: "record_date", param: "$gte" },
            },
            {
                id: 4,
                label: "Search by Recorded on End Date",
                name: "end_date",
                className: "list_search_item countDiv__column--col6 ",
                type: "datePicker",
                fullDay: true,
                sx: { m: 1, width: 300 },
                payloadFormat: { key: "record_date", param: "$lte" },
                // minDate:{minDate}
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "End Date can not be less than Start Date"
                },
            },


        ],
    })

    // ================================================== //





    // ========= endpoint call for search after search drawwer ===== //
    useEffect(() => {
        if (formSubmissionState === 4) {
            setFilterData(false);
            setToogleVal(null);
            setAmtToogleFlag(false)

            const payload = {
                "condition": { "limit": 30, "skip": 0 },
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                "searchcondition": {
                    "affiliate_id": paramData?.id,
                    "plan_type": plan_type !== null ? plan_type : undefined,
                    "username": username !== null ? username : undefined,

                },
                "project": {},
                "token": "",
                "count": false
            }

            if (start_date !== null || end_date !== null) {
                payload['searchcondition']['createdon_datetime'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

            }



            tableDataForConversionReport.current['reqBody']['searchcondition'] = payload['searchcondition']


            dispatch(detailedConversionReportAffiliateListCount(payload))

            searchTable('search') 

            setSearchDrawer(false)
        }
    }, [formSubmissionState])
    // ================================================================= //






    useEffect(() => {
        if (pieChartData.length > 0) {
            setOpenModal(true)
        }
    }, [JSON.stringify(pieChartData)])






    return (
        <>
            <div className="rolelist_mainwrp">
                <div className='listing_heading'>
                    <h1> <span className='capitalize'> {paramData?.name}</span>'s Conversion Detailed Report</h1>
                </div>



                <div className='listNavigate affiliate-reportlist-navigation'>

                    <ul className='toogleBtns'>
                        <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
                        <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
                        <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
                        <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
                        <li> <Button variant="contained" onClick={() => { setSearchDrawer(true)}} className={searchDrawer && 'active'}> Query Conversion </Button>  </li>
                        <li> <Button variant="contained" onClick={() => {
                            dispatch(unmountTable({ "tableId": "AffiliateConversionDetailedReport" }));
                            searchTable('all')
                        }}>
                            <Tooltip title="Reload" placement="bottom" className=''>

                                <ReplayIcon />
                            </Tooltip>
                        </Button>  </li>
                    </ul>



                </div>



                <div className='rolelist_mainwrp_Table affiliate_report'>


                    {loading || dataloading && <LinearProgress />}

                    {loader === false ?
                        <TableContainer>
                            <ListingTable
                                tableData={tableDataForConversionReport.current}
                                dataset={filterData ? filteredReportData : listData}
                                modifyHeaders={modifyTableHeaders}
                            />
                        </TableContainer> : <TableSkeleton count={5} />

                    }

                </div>


                <Drawer
                    anchor="bottom"
                    open={searchDrawer}
                    onClose={() => setSearchDrawer(false)}
                >
                    <Paper elevation={7} className='list_search team_management_list_search search_img'>

                        <div className="searchBarHead DrawerBox">
                            <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Conversion Detailed Report</h2>
                            <span className="searchBarCloseIcon">
                                <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
                            </span>
                        </div>

                        <Form formData={dataform.current} />
                    </Paper>
                </Drawer>

            </div>



            {openModal && <AfffiliateConverisonReportModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                pieData={pieChartData}
                landing_page_id={tableRowData.landing_page_id}
                campaign_added_by={paramData.id}

            />}


        </>
    )
}

export default AffiliateConversionDetailedReport