import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../listing/listing';
import { Button, Drawer, IconButton, LinearProgress, Paper, Snackbar, TableContainer, Tooltip } from '@mui/material';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommisionReportData, getcommisionReportData } from './W9FormReducer';
import store from '../../../store';
import { resetresolvenew } from '../../../helper/ResolveReducer';
import { useLocation } from 'react-router-dom';
import { clearPageState, clearTableCounts, clearTableOtherData, resetUnmountTableFlag, setTableCount, setTableCounts, setTableData, setTableDataAndCountAndBody, setTableDefaultreqBody, setTableFieldsData, setTableReqData, unmountTable } from '../../../listing/listReducer';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { AffiliateReportTotalAmt, exportCSV, FilterAffiliateReport, FilterAffiliateReportCount, setAffiliateInitData } from './AffiliateReducer';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../form/Form';
import IosShareIcon from '@mui/icons-material/IosShare';

const RepAndAffiCommisionReport = () => {

  const Location = useLocation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.W9FormReducer?.loading ? state.W9FormReducer.loading : false);
  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});



  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/affiliate-report"]
      ? state.ResolveReducer.resolved["/affiliate-report"]
      : false
  );

  const listData = useSelector((state) => state.W9FormReducer?.commisionReportData && state.W9FormReducer?.commisionReportData.length > 0 ? state.W9FormReducer.commisionReportData : []);

  // let commisionAmount = useSelector((state) => state.tableSlice?.otherResData?.CommisionReportAll?.total_commission ? state.tableSlice?.otherResData?.CommisionReportAll?.total_commission : state.W9FormReducer?.commisionAmount ? state.W9FormReducer.commisionAmount : null);

  let commisionAmount = useSelector((state) => state.W9FormReducer?.commisionAmount ? state.W9FormReducer.commisionAmount : null);

  const filteredCommisionAmount = useSelector((state) => state.AffiliateReducer?.filteredCommissionAmount ? state.AffiliateReducer.filteredCommissionAmount : null);

  const filteredReportData = useSelector((state) => state.AffiliateReducer?.filteredAffiliateReportData ? state.AffiliateReducer.filteredAffiliateReportData : []);

  const filteredAffiliateReportDataCount = useSelector((state) => state.AffiliateReducer?.filteredAffiliateReportDataCount ? state.AffiliateReducer.filteredAffiliateReportDataCount : null);

  const commisionReportSuccess = useSelector((state) => state.AffiliateReducer?.filterAffiliateDataSuccess ? state.AffiliateReducer.filterAffiliateDataSuccess : false);

  const reportSuccess = useSelector((state) => state.W9FormReducer?.filterAffiliateDataSuccess ? state.W9FormReducer.filterAffiliateDataSuccess : false)

  const searching = useSelector((state) => state.W9FormReducer?.filterAffiliateDataSuccess ? state.W9FormReducer.filterAffiliateDataSuccess : state.AffiliateReducer?.filterAffiliateDataSuccess ? state.AffiliateReducer.filterAffiliateDataSuccess : false)


  const loading = useSelector((state) => state.AffiliateReducer?.loading ? state.AffiliateReducer.loading : false);


  const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.CommisionReport_Search ? state.formSlice.formSubmissionState.CommisionReport_Search : false);

  const plan_type = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.plan_type ? state.formSlice.formData.CommisionReport_Search.plan_type : null);


  const username = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.username ? state.formSlice.formData.CommisionReport_Search.username : null);

  const affiliate_name_search = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.name ? state.formSlice.formData.CommisionReport_Search.name : null);



  const start_date = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.start_date ? state.formSlice.formData.CommisionReport_Search.start_date : null);


  const end_date = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.end_date ? state.formSlice.formData.CommisionReport_Search.end_date : null);

  const affiliateTotalAmt = useSelector((state) => state.AffiliateReducer?.affiliateTotalAmt ? state.AffiliateReducer.affiliateTotalAmt : null);


  const pagginationTriggred = useSelector((state) => state.tableSlice?.pageState?.CommisionReportAll ? state.tableSlice.pageState.CommisionReportAll : null);


  const tableFeching = useSelector((state) => state.tableSlice?.loading?.CommisionReportAll ? state.tableSlice.loading.CommisionReportAll : false);

  // console.warn("pagginationTriggred ====>>>>>", pagginationTriggred)

  const csvSentSuccess = useSelector((state) => state.AffiliateReducer?.csvSent ? state.AffiliateReducer.csvSent : false);



  // ----- all states here ---- //
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [toogleVal, setToogleVal] = useState('all')
  const [filterData, setFilterData] = useState(false)
  const [filterListData, setFilterListData] = useState([])
  const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
  const [amtToogleFlag, setAmtToogleFlag] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  //  -------------------------- //

  console.warn("affiliate_name_search =====>>>>>", affiliate_name_search)







  // ===== open snackbar if csv export sucessfull ==== //
  useEffect(() => {
    if (csvSentSuccess) {
      setSnackOpen(true)
    }
  }, [csvSentSuccess])

  //  ================================================ //

  // ======== snackbar close ======= //
  function handleClose() {
    setSnackOpen(false)

  }
  // ========================= //






  // ================ setting time stamps ====================//
  const todaysStartDate = moment().startOf('day').valueOf()
  const todaysEndDate = moment().endOf('day').valueOf()


  const weekStartDate = moment().startOf('week').valueOf()
  const weekEndDate = moment().endOf('week').valueOf()


  const monthStartDate = moment().startOf('month').valueOf()
  const monthEndDate = moment().endOf('month').valueOf()
  // ======================================================== //








  // console.warn("Location here ======>>>>>>>>", Location.pathname.split('/').join(''))

  //  ------- setting route path ---------- //
  var routeUrl = null
  useEffect(() => {
    if (Location?.pathname?.split('/').join('') === 'affiliate-report') {
      routeUrl = Location.pathname.split('/').join('')
    }
  }, [JSON.stringify(Location)])
  //  ---------------------------------- //



  useEffect(() => {


    return () => {
      store.dispatch(clearTableOtherData({ tableId: "CommisionReportAll" }));


    }
  }, [JSON.stringify(Location.pathname)])






  // ==================================================================== //
  // =================     Main Table Section Here    =================== //
  // ==================================================================== //



  //  ======= main table ======== //
  let tableDataForCommisionReport = useRef({
    tableId: "CommisionReportAll",
    tableTitle: "Commission Report",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/commisionreportList",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "bclusers/commisionreportList",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "record_date",
        type: "desc",
      },
      searchcondition: { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "record_date"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [


    ],
    buttons: [

    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Search Commission Report",
      type: "drawer",
      //   condition_type: "$or",
      formData: {
        id: "commisionsearchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [


          {
            id: 30,
            label: "Search by Plan Type",
            name: "plan_type",
            // className: 'Status',
            type: 'select',
            multiple: false,
            values: [
              { val: "single", name: "single" },
              { val: "single+1", name: "Single+1" },
              { val: "Family", name: "Family" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "plan_type" },
          },

          {
            id: 31,
            label: "Search by Recorded on Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "record_date", param: "$gte" },
          },
          {
            id: 4,
            label: "Search by Recorded on End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "record_date", param: "$lte" },
            // minDate:{minDate}
            // rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "End Date can not be less than Start Date"
            },
          },


        ],
      },
    },
  });
  // ========================= //



  var modifyTableHeaders = useRef([
    // { val: "username", name: "Name" },
    { val: "name", name: "Name" },
    // { val: "role", name: "Role" },s
    { val: "plan_type", name: "Plan Type" },
    { val: "unit", name: "# Of Package" },
    //  { val: "commision_amount_for_rep", name: "AE commission Amount ($)" },
    { val: "commision_rate", name: "Commission Rate [Flat($)]" },
    { val: "commision_amt", name: "Commission Amount ($)" },

    {
      val: "record_date",
      name: "Recorded On",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ]);


  if (logedUserData.main_role === "is_rep") {



  } else {


    const header = [
      { val: "plan_type", name: "Plan Type" },
      { val: "unit", name: "Unit" },
      { val: "commision_rate", name: "Commission Rate ($)" },
      { val: "commision_amt", name: "Commission Amount ($)" },

      {
        val: "record_date",
        name: "Recorded On",
        type: "datetime",
        format: "MM/DD/YYYY",
      },
    ]
    modifyTableHeaders.current = header



  }


  // ==================================================================== //
  // ==================================================================== //
  // ==================================================================== //









  useEffect(() => {

    setSnackOpen(false)

    if (logedUserData.main_role === "is_rep") {
      let searchArr = [
        {
          id: 0,
          label: "Search By Name",
          name: "username",
          type: "autoComplete",
          // multiple: true,
          // preload:true,
          // autoStage:true,
          className: "list_search_item countDiv__column--col6 ",
          base_url:
            process.env.REACT_APP_API_URL + "bclusers/commisionusernameautocomp",
          reqBody: {
            // "formId": 'teamsearch',
            condition: {
              limit: 10,
              skip: 0,
            },
            sort: {
              type: "asc",
              field: "username",
            },
            searchcondition: {},
          },
          sx: { m: 1, minWidth: 300 },
          // payloadFormat: { key: "username", param: "$regex", options: "i" },
          payloadFormat: { key: "agent_code" },
        },

        // {
        //   id: 3,
        //   label: "Search by Role",
        //   name: "role",
        //   // className: 'Status',
        //   type: 'select',
        //   multiple: false,
        //   values: [
        //     { val: "is_rep", name: "Rep" },
        //     { val: "is_affiliate", name: "Affiliate" },
        //   ],
        //   sx: { m: 1, minWidth: 120 },
        //   className: 'list_search_item countDiv__column--col6 ',
        //   payloadFormat: { key: "role" },
        // },

      ]


      // searchArr.forEach((item) => { tableDataForCommisionReport.current.searchData.formData.fields.push(item) })

      // searchArr.forEach((x) => {dataform.current.fields.push(x)})


    }

  }, [])


  useEffect(() => {

    setSnackOpen(false)


    let reqBody = {
      // "formId": 'teamsearch',
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "record_date",
      },
      searchcondition: logedUserData.main_role === "is_rep" ? { rep_id: logedUserData._id } : { user_id: logedUserData._id },
    };

    // console.log("RoleList", resolve);
    if (!resolved) {

      // store.dispatch(getcommisionReportData(reqBody))

      searchTable('all');


    }


    // store.dispatch(resetresolvenew("/affiliate-report"));

    return () => {
      store.dispatch(clearCommisionReportData());
      dispatch(setAffiliateInitData())
      setSnackOpen(false)

    };
  }, []);








  // ============  on table filters click =========== //
  function searchTable(val) {
    setToogleVal(val);

    switch (val) {

      case 'all':
        setFilterData(false)
        setAmtToogleFlag(false)
        dispatch(unmountTable({ "tableId": "CommisionReportAll" }));
        setDataFetching(true)


        dispatch(AffiliateReportTotalAmt({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }
        }))


        let payloadAll = {
          "condition": {
            "limit": 30, "skip": 0
          },
          "sort": {
            "type": "desc", "field": "record_date"
          },
          "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }
        }

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReportAll", "reqBody": payloadAll }))


        dispatch(getcommisionReportData(payloadAll))

        setFilterListData([])
        dispatch(clearCommisionReportData())
        dispatch(setAffiliateInitData())

        break;

      case 'this_month':
        dispatch(unmountTable({ "tableId": "CommisionReportAll" }));
        setFilterData(true)
        setDataFetching(true)

        // dispatch(AffiliateReportTotalAmt({
        //   "condition": { "limit": 30, "skip": 0 },
        //   "sort": { "type": "desc", "field": "record_date" },
        //   "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }
        // }))

        setAmtToogleFlag(true)

        // setFilterListData([])
        // dispatch(clearCommisionReportData())
        // dispatch(setAffiliateInitData())

        // ==== main payload body ==== //
        let payloadMonth = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }
        }

        payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }
        //  ============================ //

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadMonth['searchcondition'] // setting page reqBody 

        dispatch(FilterAffiliateReport(payloadMonth)) // fetching data as per date range 

        // setting table default request body so that pagination works properly 
        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReportAll", "reqBody": payloadMonth }))



        setTimeout(() => {
          payloadMonth['count'] = true
          dispatch(FilterAffiliateReportCount(payloadMonth))
        }, 200);


        break;

      case 'this_week':
        setFilterData(true)
        setAmtToogleFlag(true)
        dispatch(unmountTable({ "tableId": "CommisionReportAll" }));
        setDataFetching(true)

        // dispatch(AffiliateReportTotalAmt({
        //   "condition": { "limit": 30, "skip": 0 },
        //   "sort": { "type": "desc", "field": "record_date" },
        //   "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }
        // }))

        // ==== main payload body ==== //
        let payloadWeek = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }

        }

        payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }
        //  ======================== //

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadWeek['searchcondition'] // setting page reqBody 



        // setFilterListData([])
        // dispatch(clearCommisionReportData())
        // dispatch(setAffiliateInitData())

        dispatch(FilterAffiliateReport(payloadWeek)) // fetching data as per date range 

        dispatch(clearPageState({ "tableId": "CommisionReportAll" })) // reseting list from 0 to solve pagination issue 

        // setting table default request body so that pagination works properly //
        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReportAll", "reqBody": payloadWeek }))




        setTimeout(() => {
          payloadWeek['count'] = true
          dispatch(FilterAffiliateReportCount(payloadWeek))
        }, 200);


        break;

      case 'today':
        dispatch(unmountTable({ "tableId": "CommisionReportAll" }));
        setFilterData(true)
        setAmtToogleFlag(true)
        setDataFetching(true)

        // dispatch(AffiliateReportTotalAmt({
        //   "condition": { "limit": 30, "skip": 0 },
        //   "sort": { "type": "desc", "field": "record_date" },
        //   "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }
        // }))


        // ==== main payload body ==== //
        let payloadToday = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] }

        }

        payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }
        //  ========================= //

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadToday['searchcondition']  // setting page reqBody 

        // setting table default request body so that pagination works properly //
        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReportAll", "reqBody": payloadToday }))

        dispatch(FilterAffiliateReport(payloadToday)) // setting page reqBody 


        // setFilterListData([])
        // dispatch(clearCommisionReportData())
        // dispatch(setAffiliateInitData())

        setTimeout(() => {
          payloadToday['count'] = true
          dispatch(FilterAffiliateReportCount(payloadToday))
        }, 200);



        break;

      case 'own':
        dispatch(unmountTable({ "tableId": "CommisionReportAll" }));
        setFilterData(true)
        setAmtToogleFlag(true)
        setDataFetching(true)

        // dispatch(AffiliateReportTotalAmt({
        //   "condition": { "limit": 30, "skip": 0 },
        //   "sort": { "type": "desc", "field": "record_date" },
        //   "searchcondition":  { user_id: logedUserData._id , role:"is_rep"}
        // }))

        const headers = modifyTableHeaders.current.filter((x) => x.val !== "name")
        modifyTableHeaders.current = headers




        // ==== main payload body ==== //
        let payloadOwn = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": { user_id: logedUserData._id, role: "is_rep" }

        }

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadOwn['searchcondition']  // setting page reqBody 

        // setting table default request body so that pagination works properly //
        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReportAll", "reqBody": payloadOwn }))

        dispatch(FilterAffiliateReport(payloadOwn)) // setting page reqBody 


        // setFilterListData([])
        // dispatch(clearCommisionReportData())
        // dispatch(setAffiliateInitData())

        setTimeout(() => {
          payloadOwn['count'] = true
          dispatch(FilterAffiliateReportCount(payloadOwn))
        }, 200);
        break;

      default:
      // code block
    }
  }
  //  ================================== //



  useEffect(() => {
    if (toogleVal === "own") {

      const headers = modifyTableHeaders.current.filter((x) => x.val !== "name")
      modifyTableHeaders.current = headers



    } else {


      const header = [
        // { val: "username", name: "Name" },
        { val: "name", name: "Name" },
        // { val: "role", name: "Role" },s
        { val: "plan_type", name: "Plan Type" },
        { val: "unit", name: "# Of Package" },
        //  { val: "commision_amount_for_rep", name: "AE commission Amount ($)" },
        { val: "commision_rate", name: "Commission Rate [Flat($)]" },
        { val: "commision_amt", name: "Commission Amount ($)" },

        {
          val: "record_date",
          name: "Recorded On",
          type: "datetime",
          format: "MM/DD/YYYY",
        },
      ]
      modifyTableHeaders.current = header


    }
  }, [toogleVal])







  // ==== setTableData === //
  useEffect(() => {

    if (filterData && commisionReportSuccess) {

      if (filteredReportData?.length > 0) {
        setFilterListData(filteredReportData)
        setDataFetching(false)
      } else {
        setFilterListData([])
        setDataFetching(false)

      }


    }

  }, [JSON.stringify(filteredReportData), filterData, commisionReportSuccess])

  //  ================== //





  // ==== setTableData === //
  useEffect(() => {

    if (reportSuccess && listData?.length > 0) {
      setFilterListData(listData)
      setDataFetching(false)

    }


  }, [JSON.stringify(listData), reportSuccess])

  //  ================== //



  useEffect(() => {

    if (filterListData) {
      dispatch(setTableData({ "tableId": "CommisionReportAll", "dataset": filterListData }))
      setDataFetching(false)
    }

  }, [JSON.stringify(filterListData)])








  // ==== setTableCount === //
  useEffect(() => {
    if (filteredAffiliateReportDataCount !== null) {
      dispatch(clearPageState({ "tableId": "CommisionReportAll" }))
      // dispatch(clearTableCounts({ "tableId": "CommisionReportAll" }))
      dispatch(setTableCounts({ "tableId": "CommisionReportAll", "count": filteredAffiliateReportDataCount }))
    }
  }, [filteredAffiliateReportDataCount])



  // ===================== //




  //----------------------search form configration---------------------
  const dataform = useRef({
    id: 'CommisionReport_Search',
    submitBtnName: "Search",
    submitBtnEndIcon: 'search_icon',
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: 'refresh',
    endPointBodyWrapper: "searchcondition",
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {},

    fields: [


      {
        id: 0,
        label: "Search by Traffic Partner Name",
        name: "name",
        type: "autoComplete",
        className: 'list_search_item countDiv__column--col6 ',
        base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
        reqBody: {
          "condition": {
              "limit": 10,
              "skip": 0
          },
          "sort": {
              "type": "asc",
              "field": "name"
          },
          "searchcondition": {
              "added_by": logedUserData._id,
              "roles.is_affiliate":1,
              "label": {
                  "$regex": "d",
                  "$options": "i"
              }
          },
          "type": "user_name",
          "source": "users"
      },
        sx: { m: 1, minWidth: 300 },
        payloadFormat: { key: "_id" },

      },

      {
        id: 30,
        label: "Search by Plan Type",
        name: "plan_type",
        // className: 'Status',
        type: 'select',
        multiple: false,
        values: [
          { val: "single", name: "single" },
          { val: "single+1", name: "Single+1" },
          { val: "Family", name: "Family" },
        ],
        sx: { m: 1, minWidth: 120 },
        className: 'list_search_item countDiv__column--col6 ',
        payloadFormat: { key: "plan_type" },
      },

      {
        id: 31,
        label: "Search by Recorded on Start Date",
        name: "start_date",
        className: "datePicker",
        type: "datePicker",
        sx: { m: 1, width: 300 },
        className: "list_search_item countDiv__column--col6 ",
        payloadFormat: { key: "record_date", param: "$gte" },
      },
      {
        id: 4,
        label: "Search by Recorded on End Date",
        name: "end_date",
        className: "list_search_item countDiv__column--col6 ",
        type: "datePicker",
        fullDay: true,
        sx: { m: 1, width: 300 },
        payloadFormat: { key: "record_date", param: "$lte" },
        // minDate:{minDate}
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "End Date can not be less than Start Date"
        },
      },


    ],
  })

  // ================================================== //




  // ========= endpoint call for search after search drawwer ===== //
  useEffect(() => {
    if (formSubmissionState === 4) {
      setFilterData(false);
      setToogleVal(null);
      setAmtToogleFlag(false)

      setToogleVal("search");

      const payload = {
        "condition": { "limit": 30, "skip": 0 },
        "sort": {
          "field": "record_date",
          "type": "desc"
        },
        "searchcondition": {
          '$or': [{ "rep_id": logedUserData._id }, { "user_id": logedUserData._id }],
          "plan_type": plan_type !== null ? plan_type : undefined,
          "username": username !== null ? username : undefined,

        },
        "project": {},
        "token": "",
        "count": false
      }

      if (start_date !== null || end_date !== null) {
        payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

      }

      if(affiliate_name_search!==null){
        delete payload['searchcondition']['$or']
        payload['searchcondition']['rep_id']= logedUserData._id 
        payload['searchcondition']['user_id']= affiliate_name_search 
      }

      tableDataForCommisionReport.current['reqBody']['searchcondition'] = payload['searchcondition']


      dispatch(getcommisionReportData(payload))
      setSearchDrawer(false)
    }
  }, [formSubmissionState])
  // ================================================================= //









  // =============== exportCSV ============= //
  function exportCSVthroughmail() {

    let payload = {}


    switch (toogleVal) {


      case 'all':
        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "$or": [
              {
                "rep_id": logedUserData._id
              },
              {
                "user_id": logedUserData._id
              }
            ],
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data",
        }

        dispatch(exportCSV(payload));
        break;

      case 'this_month':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "$or": [
              {
                "rep_id": logedUserData._id
              },
              {
                "user_id": logedUserData._id
              }
            ],
            "record_date": { $gte: monthStartDate, $lte: monthEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data"
        }

        dispatch(exportCSV(payload));

        break;

      case 'this_week':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "$or": [
              {
                "rep_id": logedUserData._id
              },
              {
                "user_id": logedUserData._id
              }
            ],
            "record_date": { $gte: weekStartDate, $lte: weekEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data"
        }

        dispatch(exportCSV(payload));

        break;

      case 'today':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "$or": [
              {
                "rep_id": logedUserData._id
              },
              {
                "user_id": logedUserData._id
              }
            ],
            "record_date": { $gte: todaysStartDate, $lte: todaysEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data"
        }

        dispatch(exportCSV(payload));
        break;

      case 'own':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": logedUserData._id
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data",
          "export_type": "own_data"
        }

        dispatch(exportCSV(payload));
        break;

      case 'search':


        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }],
            "plan_type": plan_type !== null ? plan_type : undefined,
            

          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data"
        }

        if (start_date !== null || end_date !== null) {
          payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

        }

        if(affiliate_name_search!==null){
          delete payload['searchcondition']['$or']
          payload['searchcondition']['rep_id']= logedUserData._id 
          payload['searchcondition']['user_id']= affiliate_name_search 
          payload['searchcondition']['export_type']= "searchby_affiliate_name"

        }
  

        dispatch(exportCSV(payload));

        break;

      default:
        break;
    }

  }
  //  ================================== //






  // console.warn("filteredReportData", filteredReportData)

  return (
    <>
      <div className="rolelist_mainwrp">
        <div className='listing_heading'>
          <h1>Commission Report</h1>
        </div>

        <div className='listNavigate affiliate-reportlist-navigation'>

          <ul className='toogleBtns'>
            <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'own' && 'active'} onClick={() => searchTable('own')}> Own Commission </Button>  </li>
            <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Commission </Button>  </li>
            <li> <Button variant="contained" onClick={() => {
              dispatch(unmountTable({ "tableId": "CommisionReportAll" }));
              searchTable('all')
            }}>
              <Tooltip title="Reload" placement="bottom-start" className=''>
                <ReplayIcon />
              </Tooltip>

            </Button>
            </li>

            {(filterListData.length > 0 || listData.length > 0) && <li>
              <Button variant="contained" onClick={() => exportCSVthroughmail()} >
                <Tooltip title="Export CSV" placement="bottom-start" className=''>
                  <IosShareIcon />
                </Tooltip>
              </Button>
            </li>}

          </ul>

          <ul className='total_bill_mt'>

            {/* ------- filter amount ------ */}
            {toogleVal !== null && toogleVal !== "all" && toogleVal !== "search" && !loading &&
              (<li className='double_bill'>
                <span className='total_bill_mt_text'>
                  {toogleVal === "this_month" ? "This Month" : toogleVal === "own" ? "Own Commission" : toogleVal === "this_week" ? "This Week" : toogleVal === "today" && "Today's"} Amount
                </span>
                <span className='total_bill_mt_amount'>
                  {filteredCommisionAmount !== null && typeof (filteredCommisionAmount) !== NaN ? '$' + parseFloat(filteredCommisionAmount)?.toFixed(2) : '$0'}
                </span>
              </li>)}
            {/* ------------------------------ */}


            {/* ------ total amount block ------ */}
            <li className='double_bill'>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {/* {affiliateTotalAmt !== null && amtToogleFlag && typeof (affiliateTotalAmt) !== NaN ? '$' + parseFloat(affiliateTotalAmt)?.toFixed(2) : commisionAmount !== null && typeof (commisionAmount) !== NaN ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'} */}

                {commisionAmount !== null && typeof (commisionAmount) !== NaN ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'}

              </span>
            </li>
            {/* -------------------------------- */}


          </ul>

        </div>



        <div className='rolelist_mainwrp_Table affiliate_report'>


          {(loading || dataFetching) && <LinearProgress />}

          {dataFetching == false && loader === false ?
            <TableContainer>
              <ListingTable
                tableData={tableDataForCommisionReport.current}
                dataset={filterData ? filterListData : listData}
                modifyHeaders={modifyTableHeaders.current}
              />
            </TableContainer> : <TableSkeleton count={5} />

          }
          <ul className='total_bill_mt'>

            <li>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {/* {affiliateTotalAmt !== null && amtToogleFlag ? '$' + parseFloat(affiliateTotalAmt)?.toFixed(2) : commisionAmount !== null ? '$' + parseFloat(commisionAmount).toFixed(2) : '$0'} */}

                {commisionAmount !== null && typeof (commisionAmount) !== NaN ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'}

              </span>
            </li>

          </ul>
        </div>




        <Drawer
          anchor="bottom"
          open={searchDrawer}
          onClose={() => setSearchDrawer(false)}
        >
          <Paper elevation={7} className='list_search team_management_list_search search_img'>

            <div className="searchBarHead DrawerBox">
              <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Commission Report</h2>
              <span className="searchBarCloseIcon">
                <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
              </span>
            </div>

            <Form formData={dataform.current} />
          </Paper>
        </Drawer>




        {
          snackOpen && (
            <Snackbar
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              message="CSV Data Sent Successfully. Please Check Your Mail"
            />
          )
        }



      </div>

    </>
  )
}

export default RepAndAffiCommisionReport