import { FemaleSharp } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { trusted } from "mongoose";

export const fetchAssociatedPackages = createAsyncThunk("commision/fetchpackages", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-associated-packages", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const configerComitionEdit = createAsyncThunk("configerComitionEdit", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/add-update-affiliate", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const fetchLandingPage = createAsyncThunk("fetchlandingpage", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/autocomplete-landingpage", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const W9FormViewData = createAsyncThunk("fetch/W9FormViewData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetchw9formdata", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




export const NDAFormModalData = createAsyncThunk("fetch/AffiliateNDAFormData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetchaffiliatecontract", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const FilterAffiliateReport = createAsyncThunk("fetch/filterAffiliateReport", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/commisionreportlistbytime", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const FilterAffiliateReportCount = createAsyncThunk("fetch/filterAffiliateReportCount", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/commisionreportlistbytime", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


//  ======= AffiliateReportTotalAmt ===== //
export const AffiliateReportTotalAmt = createAsyncThunk("fetch/affiliateReportTotalAmt", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/totalcommissionamt", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
//  =================================== //






//  ======= Export CSV ===== //
export const exportCSV = createAsyncThunk("comission/exportCSV", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/csv-export", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
//  =================================== //





//  ======= Export summaryexportCSV ===== //
export const summaryexportCSV = createAsyncThunk("comission/summary-exportCSV", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/partner-csv-export", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
//  =================================== //


export const getcommisionReportData = createAsyncThunk(
    "commisionReportData",
    async (reqbody) => {
        // console.log("reqbody==>", reqbody);
        let req_body = {};
        req_body = { ...reqbody };
        // console.log("req_body==", req_body);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(req_body),
        };
        // console.log("process.env==>", process.env);
        const response = await fetch(
            process.env.REACT_APP_API_URL + "bclusers/commisionreportList",
            requestOptions
        );
        const respdata = await response.json();
        // console.log("respdata after endpoint fetch==>", respdata);
        return respdata;
    }
);



const AffiliateReducer = createSlice({
    name: 'AffiliateReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        packageInfo: [],
        fetchPackageInfoSuccess: false,
        AffiliateCommissionType: [],
        affiliateAddSuccess: false,
        affiliateAddError: false,
        snackbarData: null,
        programdataStatuserr: false,
        landingPageDetails: [],
        W9FormData: [],

        ViewNDAFormModalData: [],
        fetchNDAFormModalSuccess: false,
        fetchW9FormDataSuccess: false,
        filteredAffiliateReportData: [],
        filterAffiliateDataSuccess: false,
        filteredCommissionAmount: null,
        filteredAffiliateReportDataCount: null,
        affiliateTotalAmt: null,

        csvSent: false
    },
    reducers: {
        setAffiliateInitData(state, action) {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.successMsg = null;
            state.errorMsg = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';
            state.packageInfo = [];
            state.affiliateAddSuccess = false;
            state.affiliateAddError = false;
            state.landingPageDetails = [];
            state.W9FormViewData = [];
            state.ViewNDAFormModal = [];
            state.filteredAffiliateReportData = [];
            state.filteredCommissionAmount = null;
            state.filterAffiliateDataSuccess = false;
            state.filteredAffiliateReportDataCount = null;
            state.csvSent = false;
        },

        setAffiliateCommissionType(state, action) {

        },
        clearSnackdata(state, action) {
            state.snackbarData = {}
        },
        clearfetchPackageInfoSuccess(state, action) {
            state.fetchPackageInfoSuccess = false;
        },
        clearUpdateState(state, action) {
            state.affiliateAddSuccess = false

        },
        clearprogramdataStatuserr(state, action) {
            state.programdataStatuserr = false;
        }


    },
    extraReducers: {
        [fetchAssociatedPackages.pending]: (state, action) => {
            state.loading = true
            state.packageInfo = []

        },
        [fetchAssociatedPackages.fulfilled]: (state, action) => {
            // console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.packageInfo = action.payload?.results?.res ? action.payload.results.res : [];
            state.fetchPackageInfoSuccess = true;
            if (action.payload.status === "error") {
                state.programdataStatuserr = true;
            }
        },
        [fetchAssociatedPackages.rejected]: (state, action) => {
            state.loading = false
            // console.log("gfxgfcfcfgfcf", action);
            state.programdataStatuserr = true;


        },




        // ------------- configerComitionEdit ---------- //
        [configerComitionEdit.pending]: (state, action) => {
            state.affiliateAddSuccess = false
            state.affiliateAddError = false
            state.loading = true;
            state.snackbarData = null;

        },
        [configerComitionEdit.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.affiliateAddSuccess = true
                state.loading = false;
                // console.log("action here", action);

            } else if (action.payload.status === "error") {
                state.affiliateAddError = true;
                state.loading = false;
                state.snackbarData = action?.payload?.message ? action.payload.message : null;
                // console.log("action here", action);

            }
            state.loading = false;

        },
        [configerComitionEdit.rejected]: (state, action) => {
            state.affiliateAddSuccess = false
            state.affiliateAddError = true
            state.loading = false;
            state.snackbarData = action?.payload;
        },

        //  -------------------------------------------- //




        // ------------- configerComitionEdit ---------- //
        [fetchLandingPage.pending]: (state, action) => {
            state.landingPageDetails = []
            state.loading = true;
        },
        [fetchLandingPage.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.landingPageDetails = action.payload?.results?.res ? action.payload.results.res : [];
            }
            state.loading = false;
        },
        [fetchLandingPage.rejected]: (state, action) => {
            state.landingPageDetails = []
            state.loading = false;
        },

        // -------------------------------------------------- //







        // ------------- W9FormViewData ---------- //
        [W9FormViewData.pending]: (state, action) => {
            state.W9FormData = []
            state.fetchW9FormDataSuccess = false
            state.loading = true;
        },
        [W9FormViewData.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.fetchW9FormDataSuccess = true
                state.W9FormData = action.payload?.results?.res ? action.payload.results.res : [];
            }
            state.loading = false;
        },
        [W9FormViewData.rejected]: (state, action) => {
            state.W9FormData = []
            state.fetchW9FormDataSuccess = false
            state.loading = false;
        },

        // -------------------------------------------------- //



        // ------------ ViewNDAFormModal  ------------ //
        [NDAFormModalData.pending]: (state, action) => {
            state.ViewNDAFormModalData = []
            state.loading = true;
            state.fetchNDAFormModalSuccess = false
        },
        [NDAFormModalData.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.fetchNDAFormModalSuccess = true
                state.ViewNDAFormModalData = action.payload?.results?.res ? action.payload.results.res : [];
            }
            state.loading = false;
        },
        [NDAFormModalData.rejected]: (state, action) => {
            state.ViewNDAFormModalData = []
            state.loading = false;
            state.fetchNDAFormModalSuccess = false

        },

        // -------------------------------------------------- //




        //  ---------------- FilterAffiliateReport ----------- //
        [FilterAffiliateReport.pending]: (state, action) => {
            state.filterAffiliateDataSuccess = false;
            state.filteredAffiliateReportData = []
            state.filteredCommissionAmount = null
            state.loading = true;

        },
        [FilterAffiliateReport.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.filterAffiliateDataSuccess = true;
                state.loading = false;
                state.filteredAffiliateReportData = action.payload?.results?.res ? action.payload.results.res : [];
                state.filteredCommissionAmount = action.payload?.results?.total_commission ? action.payload.results.total_commission : null
            }
            state.loading = false;
        },
        [FilterAffiliateReport.rejected]: (state, action) => {
            state.ViewNDAFormModalData = []
            state.loading = false;
            state.filterAffiliateDataSuccess = false;
        },





        [getcommisionReportData.rejected]: (state, action) => {
            state.commisionReportSuccess = false
            state.filterAffiliateDataSuccess = false
        },
        [getcommisionReportData.pending]: (state, action) => {

            // console.log("getContractSlagData=========================>")
            state.loading = true;
            state.commisionReportSuccess = false
            state.filterAffiliateDataSuccess = false

        },

        [getcommisionReportData.fulfilled]: (state, action) => {
            state.commisionReportData = action.payload?.results?.res ? action.payload.results.res : [];
            state.loading = false;
            state.commisionAmount = action.payload?.results?.total_commission ? action.payload.results.total_commission : null
            state.commisionReportSuccess = true
            state.filterAffiliateDataSuccess = true

        },





        // ==================== FilterAffiliateReportCount ========== //

        [FilterAffiliateReportCount.pending]: (state, action) => {
            state.filteredAffiliateReportDataCount = null
            state.loading = true;

        },
        [FilterAffiliateReportCount.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.filteredAffiliateReportDataCount = action.payload?.count ? action.payload.count : null;

            }
            state.loading = false;
        },
        [FilterAffiliateReportCount.rejected]: (state, action) => {
            state.filteredAffiliateReportDataCount = null
            state.loading = false;



        },


        //  ===================== AffiliateReportTotalAmt ========================== //

        [AffiliateReportTotalAmt.pending]: (state, action) => {
            state.affiliateTotalAmt = null
            state.loading = true;

        },
        [AffiliateReportTotalAmt.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.affiliateTotalAmt = action.payload?.total_commission ? action.payload.total_commission : null;

            }
            state.loading = false;
        },
        [AffiliateReportTotalAmt.rejected]: (state, action) => {
            state.affiliateTotalAmt = null
            state.loading = false;

        },

        //  =========================================================================== //









        //  ===================== AffiliateReportTotalAmt ========================== //

        [exportCSV.pending]: (state, action) => {
            state.loading = true;
            state.csvSent = false;
        },
        [exportCSV.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.csvSent = true;
            }
            state.loading = false;
        },
        [exportCSV.rejected]: (state, action) => {
            state.csvSent = false;
            state.loading = false;

        },

        //  =========================================================================== //






        //  ===================== AffiliateReportTotalAmt ========================== //

        [summaryexportCSV.pending]: (state, action) => {
            state.loading = true;
            state.csvSent = false;
        },
        [summaryexportCSV.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.csvSent = true;
            }
            state.loading = false;
        },
        [summaryexportCSV.rejected]: (state, action) => {
            state.csvSent = false;
            state.loading = false;
        },

        //  =========================================================================== //










    }
});



export default AffiliateReducer.reducer;
export const { setAffiliateInitData, clearfetchPackageInfoSuccess, clearUpdateState, clearprogramdataStatuserr } = AffiliateReducer.actions;