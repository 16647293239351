import { IconButton, LinearProgress, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { approvalChangeEmployer } from '../userListReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import { setReloadTableFlag } from '../../../../../listing/listReducer';


const ConfirmationModal = ({ openConfirm, setOpenConfirm, rowdataCopy, acceptData, setManageTFOpen }) => {


    const dispatch = useDispatch();

    const [employeeLoader, setEemployeeLoader] = useState(false) //----------------------loader after clicking

    // ----------------------confirmation given function--------------------

    const aprovalSentTraffic = (acceptData) => {
        // let value = val == acceptData ? true : false

        setEemployeeLoader(true)

        if (acceptData == "accept") {
            acceptData = true
        } else if (acceptData == "reject") {
            acceptData = false
        }

        // console.log(acceptData, "value===========>>>>>>>>.")
        let decisiondata = {
            _id: rowdataCopy?._id,
            dashboard_access: acceptData,
        };
        // console.log("decisiondata===========>>>>>>", decisiondata)
        dispatch(approvalChangeEmployer(decisiondata));

        setTimeout(() => {
            dispatch(
                setSnackbar({
                    open: true,
                    message: acceptData ? "Approval Given Successfully" : "Rejected Successfully",
                    status: "success",
                })
            );
        }, 1000);

        setTimeout(() => {
            handleCloseCofirmationMOdal(false);
        }, 1000);

        setTimeout(() => {

            setManageTFOpen(false)
        }, 1100);
        dispatch(setReloadTableFlag({ tableId: "userslist1" }));

    }

    // ------------------close functiona for confirmation modal------------------

    const handleCloseCofirmationMOdal = () => {
        setOpenConfirm(false)
    }
    return (
        <>
            <Modal
                className="add_Employee_Modal confirmationModal_trfc"
                open={openConfirm}
                onClose={handleCloseCofirmationMOdal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseCofirmationMOdal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className='dataform2_wrapper'>
                        <div className='adminformbody'>
                            <h2 className="previewModalTitle ">
                                <div className='modal_title modal_titlenew'>
                                    Confirmation
                                </div>
                            </h2>
                            {/* {JSON.stringify(editEmployee)} */}
                            <div className="inputBlock inputBlock3line disposition_task">
                                <Box sx={{ minWidth: 120 }}>
                                    {acceptData == "accept" ?

                                        <div className='otherForm_mainWrpr'>

                                            <h3>Are you sure to accept this contract?</h3>

                                            <div className="confirmationBtns">
                                                <button
                                                    className="blucoastList_specialbtn"
                                                    onClick={() => aprovalSentTraffic(acceptData)}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className="blucoastList_specialbtn"
                                                    onClick={() => handleCloseCofirmationMOdal()}
                                                >
                                                    No
                                                </button>
                                            </div>

                                        </div>
                                        :
                                        <div className='otherForm_mainWrpr'>

                                            <h3>Are you sure to reject this contract?</h3>

                                            <div className="confirmationBtns">
                                                <button
                                                    className="blucoastList_specialbtn"
                                                    onClick={() => aprovalSentTraffic(acceptData)}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className="blucoastList_specialbtn"
                                                    onClick={() => handleCloseCofirmationMOdal()}
                                                >
                                                    No
                                                </button>
                                            </div>

                                        </div>
                                    }
                                </Box>
                            </div>

                            {employeeLoader ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                                <LinearProgress />
                            </Box>
                            ) : (
                                ""
                            )}
                        </div>

                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default ConfirmationModal