import { IconButton, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react'
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import CloseIcon from "@mui/icons-material/Close";
import ListingTable from '../../../../listing/listing';
import { useDispatch, useSelector } from 'react-redux';
import { dispositioLeadsSummary } from '../BlueCoastReducer';
import { setReloadTableFlag } from '../../../../listing/listReducer';


function DispositionLeadsSummary({ dispLeadCountMOdalOpn, setDispLeadCountMOdalOpn, reqBody1, setMenuSelect, dispLeadsSummryBtnClicked, menuSelect }) {

    const dispatch = useDispatch();
    let token = useSelector((state) => state.loginSlice.token);


    const loadingCOunt = useSelector((state) => state.BlueCoastSlice?.dispositionLeadsCountLoadr ? state.BlueCoastSlice.dispositionLeadsCountLoadr : false);
    let dispostionLeadsCountFull = useSelector((state) => state.BlueCoastSlice?.dispostionLeadsCount && state.BlueCoastSlice?.dispostionLeadsCount.length > 0 ? state.BlueCoastSlice.dispostionLeadsCount : []);

    // console.log("dispostionLeadsCountFull========>>>>>321", dispostionLeadsCountFull);
    // useEffect(() => {
    //     if (dispostionLeadsCountFull) {
    //     }

    // }, [dispostionLeadsCountFull])


    useEffect(() => {

        if (menuSelect == 1) {
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition[
                "outsideData"
            ] = { $exists: false };
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition[
                "bluecoastPh"
            ] = { $exists: false };
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition[
                "bluecoastphblue"
            ] = { $exists: false };
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition[
                "accessfree"
            ] = { $exists: false };
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition[
                "is_deleted"
            ] = { $exists: false };
        } else if (menuSelect == 2) {
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition = {
                outsideData: true,
                is_deleted: { $exists: false },
            }

        } else if (menuSelect == 5) {
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition = {
                bluecoastPh: { $exists: true },
                is_deleted: { $exists: false },
            };
        } else if (menuSelect == 6) {
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition = {
                bluecoastphblue: { $exists: true },
                is_deleted: { $exists: false },
            };
        } else if (menuSelect == 9) {
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition = {
                $or: [
                    { bluecoastphblue: { $exists: true } },
                    { bluecoastPh: { $exists: true } },
                ],
            };
        } else if (menuSelect == 10) {
            tableDataforDispositionLeadsCount.current.reqBody.searchcondition = {
                accessfree: { $exists: false },
                bluecoastPh: { $exists: false },
                bluecoastphblue: { $exists: false },
                is_deleted: { $exists: false },
            };
        }
        dispatch(dispositioLeadsSummary(tableDataforDispositionLeadsCount.current.reqBody))

        // console.log("dispLeadsSummryBtnClicked===========>>>>", dispLeadCountMOdalOpn, menuSelect);

    }, [dispLeadCountMOdalOpn, menuSelect])


    var modifyTableHeaders =
        [
            { val: "disposition_name", name: "Dispostion Name" },
            { val: "count", name: "Count" },
        ];

    let tableDataforDispositionLeadsCount = useRef({
        tableId: "dispostionLeadsCountfor",
        tableTitle: "Disposition at a Glance List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "lead-manage/listfordispositionleadsummary",
            // deleteSingleUserEndpoint: "training-manage/delete-training",
            tableCountEndpoint: "lead-manage/listfordispositionleadsummary",
        },

        reqBody: {
            condition: {
                limit: 30,
                skip: 0
            },
            sort: {
                field: "disposition_name",
                type: "asc"
            },
            searchcondition:
            {
                outsideData: { $exists: false },
                bluecoastPh: { $exists: false },
                bluecoastphblue: { $exists: false },
                is_deleted: { $exists: false },
                accessfree: { $exists: false },
            },

            project: {},
            token: token,
            email: "",
            count: false
        },
        // reqBody: reqBody1,




        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to delete these records?</p>",
                modalHeader: "<p> Alert !! </p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    // endpoint: "manage-form/delete-field",
                    body: {
                        "source": "field_list",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },

        
        ],
        buttons: [

            

        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },


        searchData: {
            heading: " Search Email Send To AEs",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    // {
                    //     id: 0,
                    //     label: "Search by Name",
                    //     name: "fullname",
                    //     type: "autoComplete",
                    //     className: 'list_search_item countDiv__column--col6 ',
                    //     base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforemailname",
                    //     reqBody: {
                    //         // "source": "field_list",
                    //         "condition": {
                    //             "limit": 10,
                    //             "skip": 0
                    //         },
                    //         "sort": {
                    //             "type": "desc",
                    //             "field": "name"
                    //         },
                    //         "searchcondition": {

                    //         }
                    //     },
                    //     sx: { m: 1, minWidth: 300 },
                    //     payloadFormat: { key: "_id" },

                    // },
                    // {
                    //     id: 2,
                    //     label: "Search by Subject",
                    //     name: "subject",
                    //     type: "autoComplete",
                    //     className: 'list_search_item countDiv__column--col6 ',
                    //     base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforemailsubject",
                    //     reqBody: {

                    //         "condition": {
                    //             "limit": 10,
                    //             "skip": 0
                    //         },
                    //         "sort": {
                    //             "type": "desc",
                    //             "field": "name"
                    //         },
                    //         "searchcondition": {

                    //         }
                    //     },
                    //     sx: { m: 1, minWidth: 300 },
                    //     payloadFormat: { key: "subject" },

                    // },



                    // {
                    //     id: 8,
                    //     //   heading: "Search By Creation Time",
                    //     label: "Search by Updated on Start Date",
                    //     name: "start_date_by_update",
                    //     className: 'datePicker list_search_item countDiv__column--col6',
                    //     type: 'datePicker',
                    //     sx: { m: 1, width: 300 },
                    //     payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    // },
                    // {
                    //     id: 9,
                    //     label: "Search by Updated on End Date",
                    //     name: "end_date_by_update",
                    //     className: 'list_search_item countDiv__column--col6 ',
                    //     type: 'datePicker',
                    //     fullDay: true,
                    //     sx: { m: 1, width: 300 },
                    //     payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    // },
                ]
            }
        },

    });


    const handleCLoseDispositionLead = () => {
        setDispLeadCountMOdalOpn(false)
        dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));

    }



    return (
        <>
            <Modal
                className="modalBaseStyle  SentMail_mainModal dispositionCOunt "
                open={dispLeadCountMOdalOpn}
                onClose={handleCLoseDispositionLead}
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCLoseDispositionLead}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className="rolelist_mainwrp">
                        <h2 className='previewModalTitle '> <div className='modal_title modal_titlenew'>
                            Disposition at a Glance</div>
                        </h2>

                        <div className='rolelist_mainwrp_Table'>
                            {!loadingCOunt ? (
                                <ListingTable
                                    tableData={tableDataforDispositionLeadsCount.current}
                                    dataset={dispostionLeadsCountFull}
                                    modifyHeaders={modifyTableHeaders}
                                />
                            ) : (
                                <TableSkeleton count={5} />
                            )}

                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default DispositionLeadsSummary
