import { Button, Grid, IconButton, Modal, Paper, Skeleton, TableContainer, Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from 'react'
import { useCookies,Cookies } from 'react-cookie';
import store from '../../../store'
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import { clearHmDashboardData, getDataForHmDashboard, getDataForHmDashboardCard, setMinMaxTime, getlandingpageForRep, getDataForHmDashboardChart, getDataForHmDashboardGridList } from '../Dashboard/DashboardReducer';
import ListingTable from '../../../listing/listing';
import CustomLinks from '../CustomLinks/CustomLinks';
import { inputExternalPlugin } from '../../../helper/helperFunctions';

import SummarizeIcon from '@mui/icons-material/Summarize';
import FolderIcon from '@mui/icons-material/Folder';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StreetviewIcon from '@mui/icons-material/Streetview';


import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const RepAndAreaDashboard = () => {
    const cookies = new Cookies();
    const [getAllcookies, setgetcookies] = useState(cookies.getAll());
    const [isScriptTag, setIsScriptTag] = useState(false);
    const [value, setValue] = useState([null, null])

    useEffect(() => {
        if (!isScriptTag) {
            inputExternalPlugin("hm-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
            setIsScriptTag(true);
        }
    }, [isScriptTag])

    const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
    let userID = useSelector(state => (state.loginSlice.userInfo) ? state.loginSlice.userInfo?._id : null);
    let userType = useSelector(state => (state.loginSlice.userInfo?.user_types) ? state.loginSlice.userInfo?.user_types[0]?.slug : null);
    let lastloginTime = useSelector(state => state.loginSlice.last_login_time);


    const resolved = useSelector(state => state.ResolveReducer.resolved['/hm-dashboard'] ? state.ResolveReducer.resolved['/hm-dashboard'] : false)



    let mindate = useSelector(state => (state.DashboardReducer?.mindate) ? state.DashboardReducer.mindate : {})
    let maxDate = useSelector(state => (state.DashboardReducer?.maxDate) ? state.DashboardReducer.maxDate : {})


    let hmCardData = useSelector(state => (state.DashboardReducer?.hmCardData) ? state.DashboardReducer.hmCardData : {})
    let hmChartData = useSelector(state => (state.DashboardReducer?.hmChartData) ? state.DashboardReducer.hmChartData : {})
    let hmUserData = useSelector(state => (state.DashboardReducer?.hmUserData) ? state.DashboardReducer.hmUserData : {})
    let hmGridData = useSelector(state => (state.DashboardReducer?.hmGridData) ? state.DashboardReducer.hmGridData : {})
    let hmListData = useSelector(state => (state.DashboardReducer?.hmListData) ? state.DashboardReducer.hmListData : null)
    // let hmListData = useSelector(state => (state.DashboardReducer?.hmListData && state.DashboardReducer?.hmListData.length > 0) ? state.DashboardReducer.hmListData : null)

    const [openMoadl, setOpenMoadl] = React.useState(false);



    let bttnClicked = useSelector(state => state.tableSlice.customButtonClicked?.dashboardTable?.summarize_icon || state.tableSlice.customButtonClicked?.dashboardTable?.folder_icon || state.tableSlice.customButtonClicked?.dashboardTable?.assessment_icon || state.tableSlice.customButtonClicked?.dashboardTable?.streetview_icon === true);



    const loaderForHmData = useSelector(state => (state.DashboardReducer?.loaderForHmData) ? state.DashboardReducer.loaderForHmData : false)

    const landingpageData = useSelector(state => (state.DashboardReducer?.getlandingpage && state.DashboardReducer?.getlandingpage.length > 0) ? state.DashboardReducer.getlandingpage : [])


    let reqbody = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": {
            "_id": userID,
            "createdon_datetime": {
                "$gte": mindate,
                "$lte": maxDate
            }
        },
        "project": {},
        "token": "",
        "count": false
    }



    useEffect(() => {
        console.log("hmCardData", hmCardData);

        if (hmCardData && hmCardData.length > 0) {

            // console.log("singleTestimonial ------------>", singleTestimonial);
            let domNodeArr = null;
            setTimeout(() => {
                domNodeArr = document.querySelectorAll('user-card ');
                console.log("domNodeArr", domNodeArr);
                if (domNodeArr && domNodeArr.length > 0) {
                    for (let i = 0; i < domNodeArr.length; i++) {
                        if (domNodeArr[i]) domNodeArr[i].addEventListener('Summere-Card-Event', (e) => {
                            let eventData = JSON.parse(e.target.getAttribute('element-Event-Data'));
                            console.log("element-Event-Data ------>", eventData)
                            switch (eventData) {
                                case "Opportunity Created": {
                                    setOpenMoadl(true)
                                }
                                    break;
                                case "Conversion Created": {
                                    setOpenMoadl(true)
                                }
                                    break;
                                case "Training Completed": {
                                    setOpenMoadl(true)
                                }
                                    break;
                                case "Appointment Booked": {
                                    setOpenMoadl(true)
                                }
                                    break;
                                default: {
                                    setOpenMoadl(true)
                                    console.log("Inside Default Case of switch-case");
                                }
                                    break;
                            }
                        });
                    }
                }
            }, 1000);

        }

    }, [hmCardData]);


    useEffect(() => {
        let reqLandingPagebody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "priority",
                "type": "asc"
            },
            "searchcondition": {
                "$and":[
                    {"status":1},
                    {"associated_program": {"$in":getAllcookies.userinfo.associated_program}}
                ]
            },
            "project": {},
            "token": "",
            "count": false
        }
        if(getAllcookies.userinfo.main_role == "is_aer"){
            reqLandingPagebody.searchcondition["rep_only"] = {"$in":[0,1]} ;
        }
        else{
            reqLandingPagebody.searchcondition["rep_only"] = 0 ;
        }
        store.dispatch(getlandingpageForRep(reqLandingPagebody));
        // return () => {
        //     store.dispatch(clearlandingpage())
        // }
    }, [])




    var modifyTableHeaders = [
        { val: "fullname", name: "Name" },
        { val: "email", name: "Email" },
        { val: "address", name: "Email" },
        { val: "phone", name: "Phone" },
        // { val: "addedon", name: "Added ON" },
        { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY" },

    ];


    let tableDataDashboard = useRef({
        tableId: "dashboardTable",
        tableTitle: "User List View",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
                "_id": userID,
                "createdon_datetime": {
                    "$gte": 1772511400000,
                    "$lte": 1674112140543
                }
            },
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [

            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-delete",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },

        ],
        buttons: [

            {
                id: "summarize_icon",
                label: "Notes",
                type: "other",
                name: "summarize_icon",
                title: "Notes",
            },
            {
                id: "folder_icon",
                label: "Folder View",
                type: "other",
                name: "folder_icon",
                title: "Folder View",
            },
            {
                id: "assessment_icon",
                label: "Training Report",
                type: "other",
                name: "assessment_icon",
                title: "Training Report",
            },
            {
                id: "streetview_icon",
                label: "Leads",
                type: "other",
                name: "streetview_icon",
                title: "Leads",
            }


        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

        // searchData: {
        //     heading: "This is Search Bar",
        //     type: "drawer",
        //     // condition_type: "$or",
        //     formData: {
        //         id: 'searchForm',
        //         formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        //         formInlineCLass: "userForm", // Can be undefined default will be formInline
        //         formButtonClass: "submitbtnsection",
        //         submitBtnEndIcon: "search",
        //         resetBtnEndIcon: "replay",
        //         resetForm: true,
        //         formAdditionalSubmissionData: {},
        //         fields: [
        //             {
        //                 id: 0,
        //                 label: "Search By Contract  Name",
        //                 name: "name",
        //                 type: "autoComplete",
        //                 className: 'list_search_item countDiv__column--col6 ',
        //                 base_url: process.env.REACT_APP_API_URL + "contract-manage/fetch-contract",
        //                 reqBody: {
        //                     // "formId": 'teamsearch',
        //                     "condition": {
        //                         "limit": 10,
        //                         "skip": 0
        //                     },
        //                     "sort": {
        //                         "type": "asc",
        //                         "field": "name"
        //                     },
        //                     "searchcondition": {

        //                     }
        //                 },
        //                 sx: { m: 1, minWidth: 300 },
        //                 // payloadFormat: { key: "_id", param: "$regex", options: "i" },
        //                 payloadFormat: { key: "_id" },

        //             },

        //             {
        //                 id: 3,
        //                 label: "Search by Status",
        //                 name: "status_show",
        //                 type: 'select',
        //                 multiple: false,
        //                 values: [
        //                     { val: "Active", name: "Active" },
        //                     { val: "Inactive", name: "Inactive" }
        //                 ],
        //                 sx: { m: 1, minWidth: 120 },
        //                 className: 'list_search_item countDiv__column--col6 ',
        //                 payloadFormat: { key: "status" },
        //             },
        //             {
        //                 id: 8,
        //                 label: "Search by Start Date",
        //                 name: "start_date",
        //                 className: 'datePicker',
        //                 type: 'datePicker',
        //                 sx: { m: 1, width: 300 },
        //                 className: 'list_search_item countDiv__column--col6 ',
        //                 payloadFormat: { key: "createdon_datetime", param: "$gte" }
        //             },
        //             {
        //                 id: 9,
        //                 label: "Search by End Date",
        //                 name: "end_date",
        //                 className: 'list_search_item countDiv__column--col6 ',
        //                 type: 'datePicker',
        //                 fullDay: true,
        //                 sx: { m: 1, width: 300 },
        //                 payloadFormat: { key: "createdon_datetime", param: "$lte" }
        //             },
        //         ]
        //     }
        // },
    });





    useEffect(() => {
        if (!resolved) {


            store.dispatch(getDataForHmDashboardCard(reqbody));

        }
    }, [resolved])

    useEffect(() => {


        store.dispatch(getDataForHmDashboardChart(reqbody));
        store.dispatch(getDataForHmDashboardGridList(reqbody));
        return () => {
            store.dispatch(clearHmDashboardData())
        }
    }, [])





    useEffect(() => {
        if (bttnClicked) {
            setOpenMoadl(true)
        }
    }, [bttnClicked])



    const closeModal = () => {
        setOpenMoadl(false)


    };
    const openModal = () => {
        setOpenMoadl(true)


    };


    const handelsearch = () => {

        let stsrtTtime = (moment(value[0]).valueOf());
        let endTtime = (moment(value[1]).valueOf());
        console.log("time", value, stsrtTtime, endTtime);

        let reqbody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
                "_id": userID,
                "createdon_datetime": {
                    "$gte": stsrtTtime,
                    "$lte": endTtime
                }
            },
            "project": {},
            "token": "",
            "count": false
        }
        store.dispatch(clearHmDashboardData());


        store.dispatch(getDataForHmDashboardCard(reqbody));
        store.dispatch(getDataForHmDashboardChart(reqbody));
        store.dispatch(getDataForHmDashboardGridList(reqbody));
        store.dispatch(setMinMaxTime(value));




    };


    return (
        <>
            <div>

                <h1 className='page_heading'>  {userType == "REP" ? "AE" : "AEE"}-Dashboard</h1>




                <Grid container spacing={1} className="dashboard_con">
                    <Grid item md={12} xs={12}>
                        <div className='dashboard_block1 dashboardCommon'>
                            <h2><span>Welcome:</span> {userInfo.userinfo?.firstname + ' ' + userInfo.userinfo?.lastname} </h2>

                            {/* <h3><span>Last Login:</span> Wed, Dec 21 2022, 03:13 PM (IST)</h3> */}
                            <h3><span>Last Login:</span> {lastloginTime !== null ? moment(Number(lastloginTime)).format('dddd, MMMM Do YYYY, h:mm a') : "N/A"}</h3>

                        </div>
                    </Grid>
                </Grid>




                <div className='daterangepicker_one'>
                    <span className='dash_brd_date'> {moment(Number(mindate)).format('MMMM Do ')} - {moment(Number(maxDate)).format('MMMM Do , YYYY ')}</span>


                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}

                    >
                        <DateRangePicker
                            startText="Start Date"
                            endText="End Date"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            onOpen={() => {
                                // clearErrors(fielddata.name);

                                //////////////////////// Removing Missing License Key Message /////////////////////
                                setTimeout(() => {
                                    if (document.querySelector('daterangepicker_one') != undefined && document.querySelector('daterangepicker_one').style != undefined)
                                        document.querySelector('daterangepicker_one').style.display = 'none';

                                    let e = document.getElementsByTagName("div");
                                    for (let i = 0; i < e.length; i++) {
                                        let textval = e[i].innerText;
                                        if (textval.includes('Missing license key') && textval.length < 30) {
                                            e[i].style.display = 'none';
                                        }

                                    }
                                    if (document.querySelector('daterangepicker_one') != undefined && document.querySelector('daterangepicker_one').style != undefined)
                                        document.querySelector('daterangepicker_one').style.display = 'block'
                                }, 200);
                            }}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </>
                            )}
                        />
                    </LocalizationProvider>


                    <div className='daterangepicker_view_btn'>

                        <Button onClick={() => handelsearch()}>View</Button>
                    </div>
                </div>




                <div className="dashboard_con2">

                    {hmCardData.map((item) => {
                        return (

                            <div className='card_element dashboard_Icon_block dashboardCommon'>
                                <user-card data={JSON.stringify(item)}></user-card>
                            </div>
                        )
                    })}

                </div>



                {(loaderForHmData === false) ?

                    <div className="dashboard_con3">




                        <div className="dashboard_con3_sub CarouselView">
                            <h2>User Grid View</h2>
                            <Carousel showThumbs={false} autoPlay interval="5000" infiniteLoop>
                                {hmGridData?.map((rowgrid) =>

                                    <div className='divul'>

                                        <data-grid data={JSON.stringify(rowgrid)}></data-grid>


                                        <Tooltip title='Notes' >
                                            <IconButton onClick={() => openModal()} >
                                                <SummarizeIcon />
                                            </IconButton>
                                        </Tooltip>


                                        <Tooltip title='Folder View' >
                                            <IconButton onClick={() => openModal()}>
                                                <FolderIcon />
                                            </IconButton>
                                        </Tooltip>


                                        <Tooltip title='Training Report' >
                                            <IconButton onClick={() => openModal()}>
                                                <AssessmentIcon />
                                            </IconButton>
                                        </Tooltip>


                                        <Tooltip title='Leads' >
                                            <IconButton onClick={() => openModal()}>
                                                <StreetviewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                )}</Carousel>



                            <div>

                            </div>
                        </div>




                        <div className="dashboard_con3_sub">
                            <h2>User Chart View</h2>
                            <line-chart data={JSON.stringify(hmChartData)}></line-chart>
                        </div>
                    </div> :


                    <div className="dashboard_con3">


                        <Skeleton variant="rectangular" width={1830} height={600} />

                    </div>

                }



                <div className="dashboard_con3 dashboard_con3_listview">


                    <TableContainer component={Paper}>
                        {hmListData && <ListingTable
                            tableData={
                                tableDataDashboard.current
                            }
                            dataset={hmListData}
                            modifyHeaders={modifyTableHeaders}
                        />}
                    </TableContainer>
                </div>


            </div>


            <div>
                <Modal
                    classname="image_card_modal modalBaseStyle"
                    open={openMoadl}
                    onClose={closeModal}
                    closeAfterTransition
                >
                    <Box className='modalBaseBox largeModal'>
                        <IconButton className="modal_icon_button" onClick={closeModal}>
                            <CloseIcon className="modal_close_icon" />
                        </IconButton>
                        <h1>Cooming Soon</h1>

                    </Box>
                </Modal>
            </div>



            {landingpageData && landingpageData.length > 0 && <div className="dashboard_con4">
                    <h2>LANDING/MARKETING LINKS</h2>
                    <div className="dashboard_con4_wrapper">
                        <CustomLinks customlinksData={landingpageData} />
                    </div>
                </div>}


        </>
    )
}

export default RepAndAreaDashboard