import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"





export const addpartnerdata = createAsyncThunk("addpartnerdata", async (reqBody) => {




    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/add-update-partners", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})




/// #################### partnerCommissionListData ############################### ///
export const partnerCommissionListData = createAsyncThunk("partner-commission-list", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-commission-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})

// ================================================================================= //



/// #################### filterPartnerCommissionList ############################### ///
export const filterPartnerCommissionList = createAsyncThunk("filter-partner-commission-list", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-commission-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //



/// #################### filterPartnerCommissionList ############################### ///
export const filterPartnerCommissionListCount = createAsyncThunk("filter-partner-commission-list-count", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-commission-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //




/// #################### partnerCommissionTotalAmount ############################### ///
export const partnerCommissionTotalAmount = createAsyncThunk("partner/CommissionTotalAmount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-total-commission-amount", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //



/// #################### partnerCommissionSummary ############################### ///
export const partnerCommissionSummary = createAsyncThunk("partner/CommissionSummary", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-commission-summary-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //







/// #################### partnerFilterCommissionSummary ############################### ///
export const partnerFilterCommissionSummary = createAsyncThunk("partner/FilterCommissionSummary", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-commission-summary-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //




/// #################### partnerFilterCommissionSummaryCount ############################### ///
export const partnerFilterCommissionSummaryCount = createAsyncThunk("partner/FilterCommissionSummaryCount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "users/partner-commission-summary-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //












/// #################### aeCommissionSummary ############################### ///
export const aeCommissionSummary = createAsyncThunk("Ae/aeCommissionSummary", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/rep-commission-summary-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //




/// #################### aeCommissionSummary ############################### ///
export const aeFilterCommissionSummary = createAsyncThunk("Ae/filterCommissionSummary", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/rep-commission-summary-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //




/// #################### aeFilterCommissionSummaryCount ############################### ///
export const aeFilterCommissionSummaryCount = createAsyncThunk("Ae/filterCommissionSummarycount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/rep-commission-summary-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //










/// #################### commissionTransactionalReport ############################### ///
export const commissionTransactionalReport = createAsyncThunk("commission/transactional-report", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-transactional-report-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //



/// #################### commissionTransactionalReport ############################### ///
export const filterCommissionTransactionalReport = createAsyncThunk("commission/filter-transactional-report", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-transactional-report-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //




/// #################### filterCommissionTransactionalReportCount ############################### ///
export const filterCommissionTransactionalReportCount = createAsyncThunk("commission/filter-transactional-report-count", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-transactional-report-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //



/// #################### partnerCommissionTotalAmount ############################### ///
export const transactionListTotalAmount = createAsyncThunk("commission/transactionlistTotalAmount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/transactions-total-amt ", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //




/// #################### searchCommissionTransactional ############################### ///
export const searchCommissionTransactional = createAsyncThunk("commission/searchCommissionTransactionalList", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-transactional-report-list", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //













/// #################### affiliateSummary ############################### ///
export const affiliateSummary = createAsyncThunk("commission/affiliateSummary", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/affiliate-commission-summary", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //



/// #################### affiliateFilterSummary ############################### ///
export const affiliateFilterSummary = createAsyncThunk("commission/affiliateFilterSummary", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/affiliate-commission-summary", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //



/// #################### affiliateFilterSummaryCount ############################### ///
export const affiliateFilterSummaryCount = createAsyncThunk("commission/affiliateFiterSummaryCount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/affiliate-commission-summary", requestOptions);
    const respdata = await response.json();

    return respdata;

})
//  ================================== ================================================== //






const PartnersReducer = createSlice({
    name: 'PartnersReducer',
    initialState: {

        success: false,
        submitting: false,
        loading: false,
        editloading: false,
        partnerdata: [],
        partnerAddSuccess: false,
        errorMsg: null,
        filterPartnerCommListData: [],
        filteredCommissionAmount: null,
        filterPartnerDataSuccess: false,
        partnerCommissionListCount: null,
        partnerReportData: [],
        total_commission_amt: null,
        partnerCommissionSummaryListData: [],
        aeCommissionSummaryListData: [],



        commissionTransactionalListData: [],
        filterCommissionTransactionalListData: [],


        filterTransactionalReportCount: null,
        filteredCommissionTransactionaAmount: null,
        transactionTotalAmount: null,
        partnerFilterSummarryCount: null,
        partnerFilterSummarryData: [],
        aeFilterSummaryData: [],
        aeFilterSummaryDataCount: null,
        aeFilterSummarySucess: false,
        filterTransactionalSuccess: false,





        affiliateSummaryData: [],
        affiliateFilterSummaryData: [],
        filterAffiliateSummaryCount: null,
        filterAffiliateSummarySuccess: false,
        dataFetched: false,


    },
    reducers: {

        resetdata(state, action) {
            state.partnerdata = [];
            state.aeCommissionSummaryListData = [];
            state.aeFilterSummaryData = [];
            state.partnerCommissionSummaryListData = [];
            state.commissionTransactionalListData = [];

        },

        resetFilterData(state, action) {
            state.partnerFilterSummarryData = [];
        },


        clearPartnerReducerReportData(state, action) {
            state.success = false;
            state.filterPartnerCommListData = [];
            state.filterTransactionalSuccess = false;
            state.submitting = false;
            state.loading = false;
            state.editloading = false;
            state.partnerdata = [];
            state.partnerAddSuccess = false;
            state.errorMsg = null;
            state.filterPartnerCommListData = [];
            state.filteredCommissionAmount = null;
            state.filterPartnerDataSuccess = false;
            state.partnerCommissionListCount = null;
            state.partnerReportData = [];
            state.total_commission_amt = null;
            state.partnerCommissionSummaryListData = [];
            state.aeCommissionSummaryListData = [];
            state.commissionTransactionalListData = [];
            state.filterCommissionTransactionalListData = [];
            state.filterTransactionalReportCount = null;
            state.filteredCommissionTransactionaAmount = null;
            state.transactionTotalAmount = null;
            state.partnerFilterSummarryCount = null;
            state.partnerFilterSummarryData = [];
            state.aeFilterSummaryData = [];
            state.aeFilterSummaryDataCount = null;
            state.aeFilterSummarySucess = false;
        },
    },
    extraReducers: {




        /// #################### Training Listing ###############################
       
        [addpartnerdata.pending]: (state, action) => {


            console.log("addpartnerdata=========================>")
            state.loading = true;
            state.partnerAddSuccess = false
            state.errorMsg = null
        },
        [addpartnerdata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.partnerdata = action.payload.results?.res ? action.payload.results.res : [];
                state.loading = false;
                state.partnerAddSuccess = true
            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.loading = false;

            }

        },
        [addpartnerdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.partnerAddSuccess = false
            state.errorMsg = action.payload?.message ? action.payload.message : null
        },
        //  ===================================================================== //










        /// #################### partnerCommissionListData ############################### ///
        [partnerCommissionListData.pending]: (state, action) => {
            state.loading = true;
            state.errorMsg = null;
            state.partnerReportData = []
        },
        [partnerCommissionListData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.partnerReportData = action.payload.results?.res ? action.payload.results.res : [];
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.partnerReportData = [];


            }

        },
        [partnerCommissionListData.rejected]: (state, action) => {
            state.successAction = false;
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.partnerReportData = []
        },

        //  ============================================================================== //


        /// #################### filterPartnerCommissionList ############################### ///
        [filterPartnerCommissionList.pending]: (state, action) => {
            state.loading = true;
            state.errorMsg = null;
            state.filterPartnerCommListData = [];
            state.filteredCommissionAmount = null;
            state.filterPartnerDataSuccess = false

        },
        [filterPartnerCommissionList.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.filterPartnerCommListData = action.payload.results ? action.payload.results : [];
                state.filteredCommissionAmount = action.payload.results?.total_commission ? action.payload.results.total_commission : null;
                state.filterPartnerDataSuccess = true
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.filterPartnerCommListData = [];
                state.filteredCommissionAmount = null
                state.filterPartnerDataSuccess = true

            }

        },
        [filterPartnerCommissionList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.filteredCommissionAmount = null
            state.filterPartnerDataSuccess = false

        },

        //  ============================================================================== ///


        /// #################### filterPartnerCommissionListCount ############################### ///
        [filterPartnerCommissionListCount.pending]: (state, action) => {
            state.loading = true;
            state.errorMsg = null;
            state.partnerCommissionListCount = null

        },
        [filterPartnerCommissionListCount.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.partnerCommissionListCount = action.payload.count ? action.payload.count : null;
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [filterPartnerCommissionListCount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.partnerCommissionListCount = null
        },

        //  ====================================================================================== //


        // ======================== partnerCommissionTotalAmount ===================== //
        [partnerCommissionTotalAmount.pending]: (state, action) => {
            state.errorMsg = null;
            state.total_commission_amt = null

        },
        [partnerCommissionTotalAmount.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.total_commission_amt = action.payload.total_commission ? action.payload.total_commission : null;
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [partnerCommissionTotalAmount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.total_commission_amt = null
        },
        //  ====================================================================== //













        // ======================= commissionTransactionalReport =========================== //
        [commissionTransactionalReport.pending]: (state, action) => {
            state.loading = true;
            state.commissionTransactionalListData = []
            state.transactionTotalAmount = null
            state.dataFetched = false


        },
        [commissionTransactionalReport.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.commissionTransactionalListData = action.payload.results.res ? action.payload.results.res : [];
                state.transactionTotalAmount = action.payload.results.total_commission ? action.payload.results.total_commission : null;
                state.loading = false;
                state.dataFetched = true


            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.dataFetched = true

            }

        },
        [commissionTransactionalReport.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.commissionTransactionalListData = [];
            state.transactionTotalAmount = null
            state.dataFetched = true


        },
        // ================================================================= //



        // ======================= filterCommissionTransactionalReport =========================== //
        [filterCommissionTransactionalReport.pending]: (state, action) => {
            state.loading = true;
            state.filterCommissionTransactionalListData = [];
            state.filteredCommissionTransactionaAmount = null;
            state.filterTransactionalSuccess = false;
            state.dataFetched = false

        },
        [filterCommissionTransactionalReport.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.filterCommissionTransactionalListData = action.payload.results.res ? action.payload.results.res : [];
                state.filteredCommissionTransactionaAmount = action.payload.results.total_commission ? action.payload.results.total_commission : null;
                state.loading = false;
                state.filterTransactionalSuccess = true;
                state.dataFetched = true


            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.dataFetched = true

            }

        },
        [filterCommissionTransactionalReport.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.filterCommissionTransactionalListData = [];
            state.filteredCommissionTransactionaAmount = null;
            state.filterTransactionalSuccess = false
            state.dataFetched = true

        },
        // ================================================================= //



        // ======================= filterCommissionTransactionalReportCount =========================== //
        [filterCommissionTransactionalReportCount.pending]: (state, action) => {
            state.loading = true;
            state.filterTransactionalReportCount = null

        },
        [filterCommissionTransactionalReportCount.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.filterTransactionalReportCount = action.payload.count ? action.payload.count : null;
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [filterCommissionTransactionalReportCount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.filterTransactionalReportCount = null
        },
        // ================================================================= //



        // ======================= filterCommissionTransactionalReportCount =========================== //
        [transactionListTotalAmount.pending]: (state, action) => {
            state.loading = true;
            state.transactionTotalAmount = null

        },
        [transactionListTotalAmount.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.transactionTotalAmount = action.payload.total_commission ? action.payload.total_commission : null;
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [transactionListTotalAmount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.transactionTotalAmount = null
        },
        // ================================================================= //



        // ======================= searchCommissionTransactional =========================== //
        [searchCommissionTransactional.pending]: (state, action) => {
            state.loading = true;
            state.filterCommissionTransactionalListData = [];
            state.filterTransactionalSuccess = false;
            state.transactionTotalAmount = null;

        },
        [searchCommissionTransactional.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.filterCommissionTransactionalListData = action.payload.results.res ? action.payload.results.res : [];
                state.transactionTotalAmount = action.payload?.results.total_commission ? action.payload.results.total_commission : null
                state.filterTransactionalSuccess = true;
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [searchCommissionTransactional.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.filterCommissionTransactionalListData = []
            state.transactionTotalAmount = null
            state.filterTransactionalSuccess = false;
        },
        // ================================================================= //













        // ======================= partnerCommissionSummary =========================== //
        [partnerCommissionSummary.pending]: (state, action) => {
            state.loading = true;
            state.partnerCommissionSummaryListData = []
            state.success = false;

        },
        [partnerCommissionSummary.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {
                state.success = true;
                state.partnerCommissionSummaryListData = action.payload.results ? action.payload.results : [];
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.success = false;
            }

        },
        [partnerCommissionSummary.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.partnerCommissionSummaryListData = []
            state.success = false;
        },


        // ======================= partnerFilterCommissionSummary =========================== //
        [partnerFilterCommissionSummary.pending]: (state, action) => {
            state.loading = true;
            state.success = false
            state.partnerFilterSummarryData = null
            state.filterPartnerDataSuccess = false

        },
        [partnerFilterCommissionSummary.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {
                state.success = true
                state.partnerFilterSummarryData = action.payload.results ? action.payload.results : [];
                state.loading = false;
                state.filterPartnerDataSuccess = true

            } else if (action.payload.status === 'error') {
                state.success = false
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [partnerFilterCommissionSummary.rejected]: (state, action) => {
            state.success = false
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.partnerFilterSummarryData = []
            state.filterPartnerDataSuccess = false
        },
        // ================================================================= //




        // ======================= partnerFilterCommissionSummarryCount =========================== //
        [partnerFilterCommissionSummaryCount.pending]: (state, action) => {
            state.loading = true;
            state.partnerFilterSummarryCount = null

        },
        [partnerFilterCommissionSummaryCount.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.partnerFilterSummarryCount = action.payload.count ? action.payload.count : [];
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [partnerFilterCommissionSummaryCount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.partnerFilterSummarryCount = null
        },
        // ================================================================= //












        // ======================= aeCommissionSummary =========================== //
        [aeCommissionSummary.pending]: (state, action) => {
            state.loading = true;
            state.aeCommissionSummaryListData = []
            state.dataFetched = false

        },
        [aeCommissionSummary.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.aeCommissionSummaryListData = action.payload.results ? action.payload.results : [];
                state.loading = false;
                state.dataFetched = true


            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.dataFetched = false

            }

        },
        [aeCommissionSummary.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.aeCommissionSummaryListData = [];
            state.dataFetched = false

        },
        // ================================================================= //


        // ======================= aeFilterCommissionSummary =========================== //
        [aeFilterCommissionSummary.pending]: (state, action) => {
            state.loading = true;
            state.aeFilterSummaryData = []
            state.aeFilterSummarySucess = false
            state.dataFetched = false

        },
        [aeFilterCommissionSummary.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.aeFilterSummaryData = action.payload.results ? action.payload.results : [];
                state.loading = false;
                state.aeFilterSummarySucess = true
                state.dataFetched = true


            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.dataFetched = false

            }

        },
        [aeFilterCommissionSummary.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.aeFilterSummaryData = []
            state.aeFilterSummarySucess = false
            state.dataFetched = false


        },
        // ================================================================= //




        // ======================= aeFilterCommissionSummaryCount =========================== //
        [aeFilterCommissionSummaryCount.pending]: (state, action) => {
            state.loading = true;
            state.aeFilterSummaryDataCount = null

        },
        [aeFilterCommissionSummaryCount.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.aeFilterSummaryDataCount = action.payload.count ? action.payload.count : [];
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [aeFilterCommissionSummaryCount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.aeFilterSummaryDataCount = null
        },
        // ================================================================= //












        // ======================= affiliateSummary =========================== //
        [affiliateSummary.pending]: (state, action) => {
            state.loading = true;
            state.affiliateSummaryData = []
            state.dataFetched = false

        },
        [affiliateSummary.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.affiliateSummaryData = action.payload.results.res ? action.payload.results.res : [];
                state.loading = false;
                state.dataFetched = true


            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.dataFetched = false

            }

        },
        [affiliateSummary.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.affiliateSummaryData = [];
            state.dataFetched = false

        },
        // ================================================================= //



        // ======================= affiliateFilterSummary =========================== //
        [affiliateFilterSummary.pending]: (state, action) => {
            state.loading = true;
            state.affiliateFilterSummaryData = []
            state.filterAffiliateSummarySuccess = false
            state.dataFetched = false

        },
        [affiliateFilterSummary.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.affiliateFilterSummaryData = action.payload.results.res ? action.payload.results.res : [];
                state.loading = false;
                state.filterAffiliateSummarySuccess = true
                state.dataFetched = true


            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
                state.filterAffiliateSummarySuccess = false
                state.dataFetched = false

            }

        },
        [affiliateFilterSummary.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.affiliateFilterSummaryData = []
            state.filterAffiliateSummarySuccess = false
            state.dataFetched = false

        },
        // ================================================================= //



        // ======================= affiliateFilterSummaryCount =========================== //
        [affiliateFilterSummaryCount.pending]: (state, action) => {
            state.loading = true;
            state.filterAffiliateSummaryCount = null

        },
        [affiliateFilterSummaryCount.fulfilled]: (state, action) => {

            if (action.payload.status === 'success') {

                state.filterAffiliateSummaryCount = action.payload.count ? action.payload.count : [];
                state.loading = false;

            } else if (action.payload.status === 'error') {
                state.errorMsg = action.payload?.message ? action.payload.message : null
            }

        },
        [affiliateFilterSummaryCount.rejected]: (state, action) => {
            state.errorMsg = action.payload?.message ? action.payload.message : null
            state.filterAffiliateSummaryCount = null
        },
        // ================================================================= //



    }

})

export default PartnersReducer.reducer;
export const { resetdata, clearPartnerReducerReportData, resetFilterData } = PartnersReducer.actions;