import { List, Modal } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { reqBody } from "../../../form/config";
import { Cookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";



// *****************BlueCost Data Trancefer****************

export const BlueCoastDataTrancefer = createAsyncThunk("bclusers/listdatatransfer",
  async (reqBody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    let req_body = {}
    req_body = { ...reqBody, token: token }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/listdatatransfer", requestOptions);
    const respdata = await response.json();
    return respdata;
  }
)





// ************BlueCoast Lead List************

export const BlueCoastlist = createAsyncThunk(
  "blue-coast/list",

  
  async (reqbody) => {

    

    const cookies = new Cookies();
    const token = cookies.getAll().token;

    if (reqbody == null && reqbody === undefined) {

      const [searchParams] = useSearchParams();
    let leadid = searchParams.get('leadid');
      reqbody = {
        "condition": {
          "limit": 30,
          "skip": 0
        },
        "sort": {
          "field": "createdon_datetime",
          "type": "desc"
        },
        "searchcondition": leadid ? {
          "outsideData": { $exists: false },
          "bluecoastPh": { $exists: false },
          "bluecoastphblue": { $exists: false },
          "is_deleted": { $exists: false },
          "accessfree": { $exists: false },
          "_id": leadid
        } : 
        {
          "outsideData": { $exists: false },
          "bluecoastPh": { $exists: false },
          "bluecoastphblue": { $exists: false },
          "is_deleted": { $exists: false },
          "accessfree": { $exists: false }
        },
        "project": {},
        "token": "",
        "email": "",
        "count": false,
      };
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/bcl-lead-list",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);


export const BlueCoastPayment = createAsyncThunk("BlueCoastPayment/list", async (reqbody) => {

  // console.log("reqbody==>", reqbody);

  // console.log("req_body==", req_body);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqbody),
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "bclusers/bcl-lead-list",
    requestOptions
  );
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
}
);

// ************BlueCoast Special Action Modal************

export const BlueCoastSpecialModal = createAsyncThunk(
  "blue-coast/specialmodal",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/bluecoast_under_lead_accept",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

// ************BlueCoast Get Other Special Person************

export const getothersplicalpersons = createAsyncThunk(
  "getothersplicalpersons",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/findotherspcialentities",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);
export const getsplicalpersons = createAsyncThunk(
  "getsplicalpersons",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/findspcialentities",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

// ************BlueCoast Notes Modal************
export const blueCoastNotesrep = createAsyncThunk(
  "blueCoastNotesrep/notes/count",
  async (val) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    let reqbody = {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: { notes_for: val },
      project: {},
      token: "",
      count: false,
    };

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL +
      "lead-manage/bluecoast_under_note_list_for_rep",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const blueCoastNotes = createAsyncThunk(
  "blue-coast/notes/count",
  async (reqbody) => {

    const cookies = new Cookies();
    const token = cookies.getAll().token;

    if (reqbody == null && reqbody === undefined) {
      reqbody = {
        condition: {
          limit: 30,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/bluecoast_under_note_list",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

// ************BlueCoast Disposition Modal************

export const getDisposition = createAsyncThunk(
  "getdispositions",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/bluecoastdispositionadd",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

/**************Disposition Task*********** */
export const manageDispositionTask = createAsyncThunk(
  "manageDispositionTask",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/dispositiontaskaddandupdate",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

/************Add Business Manager****** */

export const businessManager = createAsyncThunk(
  "businessManager",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/addbuisnessmanagerlead",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);





// ************BlueCoast operationaldispositionadd Modal************

export const operationaldispositionadd = createAsyncThunk(
  "operationaldispositionadd",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/operationaldispositionadd",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const pharmacydispositionadd = createAsyncThunk(
  "getdispositions",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/pharmacydispositionadd",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

// ************BlueCoast Set Priority Modal************

export const blueCoastSetPriority = createAsyncThunk(
  "blue-coast/set-priority",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/bluecoastleadpriorityadd",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);
// ***********BlueCoast Set Priority Modal***********



// ************BlueCoast Set underpayment************

export const blueCoastaddunderpayment = createAsyncThunk(
  "blue-coast/add-underpayment",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/bluecoastUnderPaymentadd",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);




// ************BlueCoast Set annualBilling************

export const blueCoastaddannualBilling = createAsyncThunk(
  "blue-coast/add-annualBilling",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/bluecoastannualbillingadd",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);



export const blueCoastGetPriorityData = createAsyncThunk(
  "blue-coast/getprioritydata",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/getdatapriority",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);





export const getbluecoastannualbilling = createAsyncThunk(
  "blue-coast/getbluecoastannualbilling",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/getbluecoastannualbilling",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);


export const getbluecoastunderpayment = createAsyncThunk(
  "blue-coast/getbluecoastunderpayment",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/getbluecoastunderpayment",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

/***********Load Disposition data preload when modal clicked */

export const dispositionDataLoad = createAsyncThunk(
  "blue-coast/set-disposition",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/getdispositiondata",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

/***********Load Disposition data preload when modal clicked */

export const operationalDataLoad = createAsyncThunk(
  "blue-coast/set-operation",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/getoperationaldispositiondata",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

/***********All Disposition data******************** */
export const alldispositionData = createAsyncThunk(
  "blue-coast/all-disposition",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/getalldispositiondata",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

/***********Disposition Call data******************** */

export const dispositionCalllist = createAsyncThunk(
  "blue-coast/disposition-call-list",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/disposition_call_datalist",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getownerShipTransferData = createAsyncThunk(
  "ownershiptransfer",
  async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/bluecoastleasownertransfer",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const aspireleadownertransfer = createAsyncThunk(
  "aspireleadownertransfer",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/aspireleadownertransfer",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);



// ******************  BlueCoast Lead statuschang thank ************************
export const bluecoastLeadStatusChang = createAsyncThunk(
  "bluecoastLeadStatusChang",
  async (reqbody) => {
    let req_body = {}
    req_body = { ...reqbody }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/bclleadsetflagisdeleted", requestOptions)
    const respdata = await response.json()
    return respdata
  }
)


//*********************Blue Coast Notes View List**********************************/

export const blueCoastNotesViewTable = createAsyncThunk("blue-coast-notesViewTable",
  async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
      reqbody = {
        condition: {
          limit: 30,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/bclunderpaymentlist",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);


//*********************Blue Coast mailSendToAEProd**********************************/

export const mailSendToAEProd = createAsyncThunk("mailSendToAEProd",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/bclmailsent",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

//*********************Blue Coast Export CSV**********************************/
export const exportCSVtoUserMail = createAsyncThunk("exportCSVListtoMail",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/export-list",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

// **************************Send  COntact Mail List***********************************
export const SendMailFulList = createAsyncThunk("send-mail-full-list", async (reqbody) => {
  const cookies = new Cookies();
  const token = cookies.getAll().token;
  if (reqbody == null && reqbody === undefined) {
    reqbody = {
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
      },
      "project": {},
      "token": "",
      "count": false,
    }
  }
  // console.log("reqbody==>", reqbody);
  let req_body = {};
  req_body = { ...reqbody, token: token };
  // console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/sendemaillist", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

// ******************************Disposition Leads Summary******************************************
export const dispositioLeadsSummary = createAsyncThunk("dispositio-leads-summary", async (reqbody) => {
  const cookies = new Cookies();
  const token = cookies.getAll().token;
  if (reqbody == null && reqbody === undefined) {
    reqbody = {
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
        // "outsideData": { $exists: false },
        // "bluecoastPh": { $exists: false },
        // "bluecoastphblue": { $exists: false },
        // "is_deleted": { $exists: false },
        // "accessfree": { $exists: false }
      },
      "project": {},
      "token": "",
      "count": false,
    }
  }
  // console.log("reqbody==>", reqbody);
  let req_body = {};
  req_body = { ...reqbody, token: token };
  // console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/listfordispositionleadsummary", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});


/**************************Manage Disposition Task List************* */

export const DispositonTaskList = createAsyncThunk("disposition-task-list", async (reqbody) => {

  const cookies = new Cookies();
  const token = cookies.getAll().token;

  if (reqbody == null && reqbody === undefined) {
    reqbody = {
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
      },
      "project": {},
      "token": "",
      "count": false,
    }
  }
  // console.log("reqbody==>", reqbody);
  let req_body = {};
  req_body = { ...reqbody, token: token };
  // console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/dispositiontasklist", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

/***********************Business Managers List**************** */

export const BusinessManagersList = createAsyncThunk("business-managers-list", async (reqbody) => {

  const cookies = new Cookies();
  const token = cookies.getAll().token;

  if (reqbody == null && reqbody === undefined) {
    reqbody = {
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
      },
      "project": {},
      "token": "",
      "count": false,
    }
  }
  // console.log("reqbody==>", reqbody);
  let req_body = {};
  req_body = { ...reqbody, token: token };
  // console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/buisnessmanagerleadlist", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

/**********Current diposition for Edit******** */
export const CurrentDispositonTaskList = createAsyncThunk("current-disposition-task-data", async (reqbody) => {
  if (reqbody == null && reqbody === undefined) {
    reqbody = {
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "created_on",
        "type": "desc"
      },
      "searchcondition": {
      },
      "project": {},
      "token": "",
      "count": false,
    }
  }
  // console.log("reqbody==>", reqbody);
  let req_body = {};
  req_body = { ...reqbody };
  // console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/dispositiontasklist", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

export const DispositonTaskListAll = createAsyncThunk("disposition-task-data-All", async (requestBody) => {

  let req_body = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": { "_id": requestBody },
    "project": {},
    "token": ""
  }

  const requestOptions = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };

  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/dispositiontasklist", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

export const statusChangeTask = createAsyncThunk("statusChangedispositionTask", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  // console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  // console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "lead-manage/disposiontaskstatusupdate", requestOptions);
  const respData = await responseData.json();
  return respData;
});


function removeElementsByName(arr, nameToRemove) {
  return arr.filter(item => item.firstname !== nameToRemove);
}



const BlueCoastReducer = createSlice({
  name: "bluecoastReducer",
  initialState: {
    submitting: false,
    loading: false,
    submitted: false,
    resMessage: null,
    successmsg: null,
    success: false,
    failed: false,
    bluecoastdata: [],
    bluecoastnotesdatas: [],
    othersplicialdata: [],
    splicialuserdata: [],
    disposition: [],
    operationalDisposition: [],
    dispostionDataForPharmacy: [],
    dispostionData: [],
    operationaldispostionData: [],
    alldisposition: [],
    repnotebluecoast: [],
    repNoteloading: false,
    prioritydata: [],
    underPaymentData: null,
    underPaymentLoadingstate: 0,
    annualBillingData: null,
    annualBillingDataLoadingstate: 0,
    dispositioncalllistdata: [],
    dispcallloading: false,
    bluecoastDataTranceferStatus: {},
    bluecoastDataTranceferLoader: false,
    statusTogalloading: false,
    deleteFlage: 0,
    undersuccess: false,
    prioritysuccess: false,
    alldispositionsuccess: false,
    bluecoastPymtData: {},
    noteSuccess: false,
    dispositionsuccess: false,
    notesViewTablesuccess: [],
    notesWithoutSpreedData: [],
    allleaddata: [],
    sendMailFullList: [],
    dispositiontask: [],
    dispositontasklistdata: [],
    sentmailAEsLoader: false,
    dispositionTaskLoader: false,
    dispostionLeadsCount: [],
    dispositionLeadsCountLoadr: false,
    dispositionTaskAddloader: false,
    currentdispositontaskdata: [],
    dispositionflag: false,
    currentdispflag: false,
    dispositontaskdataall: [],
    dispositionTaskAllLoader: false,
    statusChangeFlag: false,
    getcontactdata: [],
    businessmanagerslistdata: [],
    businessmanagerLoader: false,
    businessmanager: [],
    businessManagerAddLoader: false
  },
  reducers: {
    clearbluecoastDataTranceferStatus(state, action) {
      state.bluecoastDataTranceferStatus = {}
    },
    clearDispositionData(state, action) {
      state.dispostionData = [];
    },

    clearoperationaldispostionData(state, action) {
      state.operationaldispostionData = [];
    },
    clearUnderPaymentData(state, action) {
      state.underPaymentData = null;
      state.underPaymentLoadingstate = 0;
      state.undersuccess = false
    },
    clearAnnualBillingData(state, action) {
      state.annualBillingData = null;
      state.annualBillingDataLoadingstate = 0;
      state.success = false
    },
    clearBlueCoastList(state, action) {
      state.bluecoastdata = [];
    },
    clearBlueCoastNotesList(state, action) {
      state.bluecoastnotesdatas = [];
      state.noteSuccess = false
    },
    clearBlueCoastRepNotesList(state, action) {
      state.repnotebluecoast = [];
    },
    clearAllDisposition(state, action) {
      state.alldisposition = [];
      state.alldispositionsuccess = false;
      state.dispositionsuccess = false
    },
    clearPriorityData(state, action) {
      state.prioritydata = [];
      state.prioritysuccess = false
    },
    clearAllLeadList(state, action) {
      state.allleaddata = [];
    },
    clearNotesViewList(state, action) {
      state.notesViewTablesuccess = [];
      state.notesWithoutSpreedData = []
    },
    clearDispositionTaskList(state, action) {
      state.dispositontasklistdata = [];
    },
    clearBusinessManagerList(state, action) {
      state.businessmanagerslistdata = [];
    },
    clearCurrentDispositionTaskList(state, action) {
      state.currentdispositontaskdata = [];
    },
    clearcurrentdispflag(state, action) {
      state.currentdispflag = false;
    },
    cleardisposition(state, action) {
      state.disposition = [];
    },
    cleardispositionTask(state, action) {
      state.dispositiontask = [];
    },
    clearStatusChangeFlag(state, action) {
      state.statusChangeFlag = false;
    },
    clearBusinessManager(state, action) {
      state.businessmanager = false;
    }
  },
  extraReducers: {
    // ----BlueCoast ExtraReducer 
    [BlueCoastDataTrancefer.pending]: (state, action) => {
      state.bluecoastDataTranceferLoader = true
      // console.log("BlueCoast ExtraReducer",action);
    },
    [BlueCoastDataTrancefer.fulfilled]: (state, action) => {
      // console.log("BlueCoast ExtraReducer",action);
      state.bluecoastDataTranceferLoader = false
      state.bluecoastDataTranceferStatus = action.payload
    },
    [BlueCoastDataTrancefer.rejected]: (state, action) => {
      // console.log("BlueCoast ExtraReducer",action);
      state.bluecoastDataTranceferLoader = false
    },




    [blueCoastNotesrep.rejected]: (state, action) => {
      state.repNoteloading = false;
      // state.successAction = false;
    },
    [blueCoastNotesrep.pending]: (state, action) => {
      // console.log("action p", action)
      // state.usersList = [];
      state.repNoteloading = true;
    },
    [blueCoastNotesrep.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action f storedata", action)
      if (action.payload.status === "success") {
        state.repnotebluecoast = action.payload.results.res;
        // state.message = action.payload.status;
        state.repNoteloading = false;
        // state.tokenRequired = false;
        // state.tokenRequiredMsg = ''
      }
    },
    /// #################### BlueCoast Listing ###############################
    [BlueCoastlist.rejected]: (state, action) => {
      state.loading = false;
      state.successAction = false;
    },
    [BlueCoastlist.pending]: (state, action) => {
      // console.log("action p", action)
      // state.usersList = [];
      state.loading = true;
    },
    [BlueCoastlist.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action f storedata", action)
      if (action.payload.status === "success") {
        state.bluecoastdata = action.payload.results.res;
        state.message = action.payload.status;
        state.loading = false;
        state.tokenRequired = false;
        state.tokenRequiredMsg = "";
        // console.log("data of list amount", action.payload);
        // console.log("data of list amount----",action.payload.totalUnderPayment);
      }
    },


    [BlueCoastPayment.rejected]: (state, action) => {
      state.paymentloading = false;
      // state.successAction = false;
    },
    [BlueCoastPayment.pending]: (state, action) => {
      // console.log("action p", action)
      // state.usersList = [];
      state.paymentloading = true;
      state.bluecoastPaymentList = []
    },
    [BlueCoastPayment.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.paymentloading = false
        state.bluecoastPymtData = action.payload.results
        state.bluecoastPaymentList = action.payload.results.res
        console.log("data of amount", state.bluecoastPymtData.totalAnnualBilling);
        console.log("data of amount----", state.bluecoastPymtData.totalUnderPayment);

      }
    },

    //  ************blue coast notes listing********** /

    [blueCoastNotes.rejected]: (state, action) => {
      state.notesloading = false;
      state.successAction = false;
    },
    [blueCoastNotes.pending]: (state, action) => {
      // console.log("action p", action)
      // state.usersList = [];
      state.notesloading = true;
    },
    [blueCoastNotes.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);
      if (action.payload.status === "success") {

        state.bluecoastnotesdatas = action.payload.results.res;
        state.status = action.payload.status;
        state.noteSuccess = action.payload.status
        state.notesloading = false;
      }
    },

    //  ************blue coast othersepcial person listing********** /

    [getothersplicalpersons.rejected]: (state, action) => { },
    [getothersplicalpersons.pending]: (state, action) => { },
    [getothersplicalpersons.fulfilled]: (state, action) => {


      if (action.payload.status === "success") {
        state.othersplicialdata = action.payload.results.res.results;
      }
    },
    [getsplicalpersons.rejected]: (state, action) => { },
    [getsplicalpersons.pending]: (state, action) => { },
    [getsplicalpersons.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        // state.splicialuserdata = action.payload.results.res.results;
        state.splicialuserdata = removeElementsByName(action.payload.results.res.results, "Travis")

      }
    },

    /********disposition***** */
    [getDisposition.rejected]: (state, action) => { },
    [getDisposition.pending]: (state, action) => { },
    [getDisposition.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.disposition = action.payload.results.res;
        // state.success = action.payload.status;
      }
    },

    /********Disposition task*************** */

    [manageDispositionTask.rejected]: (state, action) => { },
    [manageDispositionTask.pending]: (state, action) => {
      state.dispositionTaskAddloader = true
    },
    [manageDispositionTask.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.dispositiontask = action.payload.results.res;
        state.dispositionTaskAddloader = false
        // state.success = action.payload.status;
      }
    },

    /***** Business Manager******* */

    [businessManager.rejected]: (state, action) => { },
    [businessManager.pending]: (state, action) => {
      state.businessManagerAddLoader = true
    },
    [businessManager.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.businessmanager = action.payload.results.res;
        state.businessManagerAddLoader = false
        // state.success = action.payload.status;
      }
    },



    /********disposition***** */
    [operationaldispositionadd.rejected]: (state, action) => { },
    [operationaldispositionadd.pending]: (state, action) => { },
    [operationaldispositionadd.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.operationalDisposition = action.payload.results.res;
        state.success = action.payload.status;
      }
    },

    /********disposition for Pharmacy***** */
    [pharmacydispositionadd.rejected]: (state, action) => { },
    [pharmacydispositionadd.pending]: (state, action) => { },
    [pharmacydispositionadd.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.dispostionDataForPharmacy = action.payload.results.res;
        state.success = action.payload.status;
      }
    },
    /******Disposition data load */
    [dispositionDataLoad.rejected]: (state, action) => { },
    [dispositionDataLoad.pending]: (state, action) => { },
    [dispositionDataLoad.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.dispostionData = action.payload.results.res[0];
        // state.success = action.payload.status;
        state.dispositionsuccess = action.payload.status;
        state.dispositionflag = true
      }
    },

    /******operationalDataLoad data load */
    [operationalDataLoad.rejected]: (state, action) => { },
    [operationalDataLoad.pending]: (state, action) => { },
    [operationalDataLoad.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.operationaldispostionData = action.payload.results.res[0];
        state.success = action.payload.status;
      }
    },
    /**************all disposition data**** */

    [alldispositionData.rejected]: (state, action) => { },
    [alldispositionData.pending]: (state, action) => { },
    [alldispositionData.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.alldisposition = action.payload.results.res;
        // state.success = action.payload.status;
        state.alldispositionsuccess = action.payload.status
      }
    },

    /**************priority data**** */

    [blueCoastGetPriorityData.rejected]: (state, action) => { },
    [blueCoastGetPriorityData.pending]: (state, action) => { },
    [blueCoastGetPriorityData.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.prioritydata = action.payload.results.res[0];
        // state.success = action.payload.status;
        state.prioritysuccess = action.payload.status;
      }
    },

    /**************underPaymentData data**** */

    [getbluecoastunderpayment.rejected]: (state, action) => {
      state.underPaymentLoadingstate = 3;
    },
    [getbluecoastunderpayment.pending]: (state, action) => {
      state.underPaymentLoadingstate = 1;
    },
    [getbluecoastunderpayment.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.underPaymentData = action.payload.results.res[0];
        // state.underPaymentLoading = false;
        state.underPaymentLoadingstate = 2;
        // state.success = action.payload.status;
        state.undersuccess = action.payload.status
      }
    },
    /**************annualBilling Data**** */

    [getbluecoastannualbilling.rejected]: (state, action) => {
      state.annualBillingDataLoadingstate = 3;
    },
    [getbluecoastannualbilling.pending]: (state, action) => {
      state.annualBillingDataLoadingstate = 1;
    },
    [getbluecoastannualbilling.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action in bluecoasnote", action);

      if (action.payload.status === "success") {
        state.annualBillingData = action.payload.results.res[0];
        state.annualBillingDataLoadingstate = 2;
        state.success = action.payload.status;
      }
    },


    /*******************Disposition Call List Data****** */

    [dispositionCalllist.rejected]: (state, action) => { },
    [dispositionCalllist.pending]: (state, action) => { },
    [dispositionCalllist.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.dispositioncalllistdata = action.payload.results.res;

        state.dispcallloading = false;
      }
    },

    // *******************BlueCostLead Status chang Extra reducer*******************888
    [bluecoastLeadStatusChang.pending]: (state, action) => {
      state.statusTogalloading = true;
      state.deleteFlage = 1

    },
    [bluecoastLeadStatusChang.fulfilled]: (state, action) => {

      state.statusTogalloading = false;
      state.bluecoastDataTranceferStatus = action.payload
      state.deleteFlage = 2
    },
    [bluecoastLeadStatusChang.rejected]: (state, action) => {
      state.statusTogalloading = false;
      state.deleteFlage = 3
      state.bluecoastDataTranceferStatus = action.payload
    },

    // *******************BlueCostLead Notes View table*******************888

    [blueCoastNotesViewTable.pending]: (state, action) => {
      state.loading = true;
      // state.deleteFlage = 1

    },
    [blueCoastNotesViewTable.fulfilled]: (state, action) => {

      if (action.payload.status === "success") {
        if (action.payload.results.res.length > 0) {


          state.notesViewTablesuccess = [...state.notesViewTablesuccess, ...action.payload.results.res];
        }
        state.notesWithoutSpreedData = action.payload.results.res
        state.loading = false;
      }
    },
    [blueCoastNotesViewTable.rejected]: (state, action) => {
      state.loading = false;
      state.notesViewTablesuccess = action.payload;
      state.notesWithoutSpreedData = action.payload;
    },

    // ---------------------Send COntact Mail Full List---------------------
    [SendMailFulList.rejected]: (state, action) => {
      state.sentmailAEsLoader = false;
      // state.successAction = false;

    },
    [SendMailFulList.pending]: (state, action) => {
      // console.log("action p", action)
      // state.usersList = [];
      state.sentmailAEsLoader = true;
    },
    [SendMailFulList.fulfilled]: (state, action) => {
      // console.log("action f storedata", action)
      // console.log("action f storedata", action)
      if (action.payload.status === 'success') {

        state.sendMailFullList = action.payload.results.res;
        // state.message = action.payload.status;
        state.sentmailAEsLoader = false;
        //   state.tokenRequired = false;
        //   state.tokenRequiredMsg = ''
      }
    },

    // -------------------------------Dispostion Leads Count--------------------------

    [dispositioLeadsSummary.rejected]: (state, action) => {
      state.dispositionLeadsCountLoadr = false;
      // state.successAction = false;

    },
    [dispositioLeadsSummary.pending]: (state, action) => {

      // state.usersList = [];
      state.dispositionLeadsCountLoadr = true;
    },
    [dispositioLeadsSummary.fulfilled]: (state, action) => {

      if (action.payload.status === 'success') {

        state.dispostionLeadsCount = action.payload.results.res;
        // state.message = action.payload.status;
        state.dispositionLeadsCountLoadr = false;
        //   state.tokenRequired = false;
        //   state.tokenRequiredMsg = ''
      }
    },


    /******Disposition Task List******** */
    [DispositonTaskList.rejected]: (state, action) => {
      state.dispositionTaskLoader = false;
      state.successAction = false;

    },
    [DispositonTaskList.pending]: (state, action) => {

      // state.usersList = [];
      state.dispositionTaskLoader = true;
    },
    [DispositonTaskList.fulfilled]: (state, action) => {

      if (action.payload.status === 'success') {

        state.dispositontasklistdata = action.payload.results.res;
        state.message = action.payload.status;
        state.dispositionTaskLoader = false;
        // state.sentmailAEsLoader = false;
        //   state.tokenRequired = false;
        //   state.tokenRequiredMsg = ''
      }
    },


    /******************Business Managers Lists********* */
    [BusinessManagersList.rejected]: (state, action) => {
      state.businessmanagerLoader = false;
      state.successAction = false;

    },
    [BusinessManagersList.pending]: (state, action) => {

      // state.usersList = [];
      state.businessmanagerLoader = true;
    },
    [BusinessManagersList.fulfilled]: (state, action) => {

      if (action.payload.status === 'success') {

        state.businessmanagerslistdata = action.payload.results.res;
        state.message = action.payload.status;
        state.businessmanagerLoader = false;

      }
    },

    /********Current disposition task******* */

    [CurrentDispositonTaskList.rejected]: (state, action) => {
      // state.dispositionTaskLoader = false;
      state.successAction = false;

    },
    [CurrentDispositonTaskList.pending]: (state, action) => {

      // state.usersList = [];
      state.dispositionTaskLoader = true;
    },
    [CurrentDispositonTaskList.fulfilled]: (state, action) => {

      if (action.payload.status === 'success') {

        state.currentdispositontaskdata = action.payload.results.res[0];
        state.message = action.payload.status;
        state.currentdispflag = true

        // state.dispositionTaskLoader = false;
        // state.sentmailAEsLoader = false;
        //   state.tokenRequired = false;
        //   state.tokenRequiredMsg = ''
      }
    },

    /************For all task****** */
    [DispositonTaskListAll.rejected]: (state, action) => {
      // state.dispositionTaskLoader = false;
      state.successAction = false;

    },
    [DispositonTaskListAll.pending]: (state, action) => {

      // state.usersList = [];
      state.dispositionTaskAllLoader = true;
    },
    [DispositonTaskListAll.fulfilled]: (state, action) => {

      if (action.payload.status === 'success') {

        state.dispositontaskdataall = action.payload.results.res[0];
        state.message = action.payload.status;
        state.currentdispflag = true

        // state.dispositionTaskLoader = false;
        // state.sentmailAEsLoader = false;
        //   state.tokenRequired = false;
        //   state.tokenRequiredMsg = ''
      }
    },





    [statusChangeTask.pending]: (state, action) => {
      state.loading = true;
      state.statusChangeFlag = true;
    },
    [statusChangeTask.fulfilled]: (state, action) => {

      if (action.payload.status === "success") {
        state.loading = false;
        state.submitting = false;
        state.statusChangeFlag = false;

      }
    },
    [statusChangeTask.rejected]: (state, action) => {
      state.loading = false;
      state.statusChangeFlag = false;
    },
  },
});

export default BlueCoastReducer.reducer;
export const {
  clearBlueCoastList,
  clearUnderPaymentData,
  clearDispositionData,
  clearAnnualBillingData,
  clearAllDisposition,
  clearBlueCoastRepNotesList,
  clearBlueCoastNotesList,
  clearPriorityData,
  clearbluecoastDataTranceferStatus,
  clearoperationaldispostionData,
  clearNotesViewList, clearDispositionTaskList, clearCurrentDispositionTaskList, clearcurrentdispflag, cleardisposition, clearStatusChangeFlag, clearBusinessManager, cleardispositionTask, clearBusinessManagerList
} = BlueCoastReducer.actions;
