import { Chip, IconButton, LinearProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SegmentIcon from "@mui/icons-material/Segment";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { FetchTagDetails } from "../userList/userListReducer";
import ConfigureTagsModal from "../userList/configureTagsModal";
import FDSearch from "./FDSearch";
import { getStageLifeCycleData, getTagLifeCycleData, TagLogsInfo, UserActivity } from "./folderViewReducer";
import { useLocation } from "react-router-dom";
import { fetchUserData } from "../fetchUserDataReducer";
const TagLifeCycle = ({ LifeCycleDataTag, folderdata }) => {
  console.log("LifeCycleDataTag", folderdata);
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [openTagModal, setOpenTagModal] = useState(false)
  const [statusAction, setStatusAction] = useState(null);  //--- after Confirmtion modal cofirm button clicked ----//

  const dispatch = useDispatch()
  const loading = useSelector((state) =>
    state?.userFolderViewReducer?.loading
      ? state.userFolderViewReducer.loading
      : false
  );
  const tagloading = useSelector((state) =>
    state?.userManagment?.loading
      ? state.userManagment.loading
      : false
  );
  const TagManageModalData = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : []);
  console.log("TagManageModalData", TagManageModalData);
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
 
  console.log("tag search data",searchData);

  const tageupdate = useSelector(state => (state?.userManagment?.tagUpdated) ? state.userManagment.tagUpdated : false)


  useEffect(() => {
    setStatusAction(null);
  }, [])

  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();


  const paramId = queryParam.search.split("=");

  console.log("data paramId", paramId);

  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [JSON.stringify(paramId)])

  // -------------------------------

  ///------------------fetch tag data---------------------

  useEffect(() => {
    if (tageupdate === true) {
      let data = {
        "condition": { "limit": 30, "skip": 0 },
        "sort": { "field": "createdon_datetime", "type": "desc" },
        "searchcondition": { "_id": folderdata._id },
        "count": false
      }
      dispatch(getTagLifeCycleData(data))
      dispatch(TagLogsInfo(data));

    }

  }, [TagManageModalData])

  //=====================================================










  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)

      let activity_times = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined

      }

  
      if (activity_times.$gte == "" || activity_times.$gte == undefined || activity_times.$gte == 'undefined') {
        delete activity_times["$gte"];
      }

      if (activity_times.$lte == "" || activity_times.$lte == undefined || activity_times.$lte == 'undefined') {
        delete activity_times["$lte"];
      }


      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
         "searchcondition": {
          "_id": folderdata._id,
          "tag_slug":searchData.tag_by,
           "createdon_datetime": Object.keys(activity_times).length > 0 ? activity_times : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      }

      if (payload.searchcondition?.createdon_datetime == undefined || payload.searchcondition?.createdon_datetime == 'undefined') {
        delete payload.searchcondition["createdon_datetime"]
      }

      dispatch(getTagLifeCycleData(payload))

    }
  }, [submissionState])





  // ----------------

  const handelAddTag = () => {
    //   let roles = Object.keys(folderdata?.roles).map((key) => key)
    //   const data = {
    //     "_id": folderdata._id,
    //     "roles": { $in: roles },
    //     "project": {
    //         "subject": 1
    //     },
    // }
    const data = {
      "_id": folderdata._id,
      "roles": {
        "$in": [
          "is_rep",
          "is_admin"
        ]
      }
    }
    dispatch(FetchTagDetails(data))
  }
  useEffect(() => {
    if (TagManageModalData.length > 0) {
      setOpenTagModal(true)

    }
  }, [TagManageModalData])


  // ------- SearchFeilds ------ //
  const searchFields = [

    {
      id: 7,
      label: "Search By Tag",
      name: "tag_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "assign-tag/assign-tag-list",
      reqBody: {

          "added_for": folderdata._id
      },

    },
    {
      id: 8,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 9,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  // ------------------------- //




  // console.log("TagLifeCycle", LifeCycleDataTag);
  return (
    <>
      <div className="blocks">
        <h3>
          <SegmentIcon /> Tag Life Cycle{" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search TagLifeCycle">
              <IconButton onClick={() => setSearchDraw(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Tag">
              <IconButton onClick={handelAddTag} >
                <LocalOfferIcon />
              </IconButton>
            </Tooltip>
          </div>
        </h3>
        {loading || tagloading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}

        {LifeCycleDataTag.length > 0 ? (
          <div>
            {
              LifeCycleDataTag?.map((x) => (
                // <ul className="Fv-tags">
                //   <li className="tagsBlck">
                //     <Chip label={x.tag_name} variant="outlined" />
                //     <QuestionMarkIcon className="qIcon" />
                //     {/* <p className="chipDesBuble">
                //       {x.tag_description} <br />
                //       Added by : {x.added_by_name} On{" "}
                //       {format(x.createdon_datetime, "dd-MM-yyyy HH:mm")}
                //     </p> */}
                //   </li>
                // </ul>
                <span className='stageChipB'>
                  <Chip label={x.tag_name} sx={{ margin: '10px' }} variant="outlined" />
                  <QuestionMarkIcon className='qIcon' />


                  <span className='chipDesBuble'>
                    {x.stage_name} <br />
                    Added by: {x.added_by_name} On {format(x.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                  </span>
                </span>
              ))
            }
          </div>
        ) : (
          <p> No tag assigned!</p>
        )}
      </div>


      {openTagModal ? <ConfigureTagsModal
        toogle={openTagModal}
        tooglefunc={setOpenTagModal}
        setModalResponse={setStatusAction}
        tags={TagManageModalData}
        UserId={folderdata._id}
        Userinfo={folderdata}
      /> : ""}



      {/* ----- Search Drawer here ------ */}
      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type="tag_life_cycle" /> : ""}
      {/*  ------------------------ */}

    </>
  );
};

export default TagLifeCycle;
