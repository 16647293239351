import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from "react-cookie";

export const getlandingpageoneuserdata = createAsyncThunk("getlandingpageoneuserdata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/get-user-info", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

/*************************For MLM-One****************** */
export const getmlmoneuserdata = createAsyncThunk("getmlmoneuserdata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/getmlmuserinfo", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const forcelogin = createAsyncThunk("forcelogin", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/forcelogin", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const addlandingpageonedata = createAsyncThunk("addlandingpageonedata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/add-user", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const addlandingpagetwodatalead = createAsyncThunk("addlandingpagetwodatalead", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/addorupdatelead", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const addlandingpageninedata = createAsyncThunk("addlandingpageninedata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/addupdate-users", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const getlandingpagedata = createAsyncThunk("getlandingpagedata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/get-page-info", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
/***************************For Mlm********************** */
// export const getmlmpagedata = createAsyncThunk("getmlmpagedata", async (reqBody) => {
//     console.log("reqbody==>", reqBody);
//     let req_body = {};
//     req_body = { ...reqBody };
//     console.log("req_body==", req_body);
//     const requestOptions = {
//         method: 'post',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/getmlmuserinfo", requestOptions);
//     const respdata = await response.json();
//     console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;
// })

///////////////////////////////// AssignStages //////////////////////////////////
export const updateStages = createAsyncThunk("landing/updatestages", async (reqbody) => {
    console.warn("<<<<<<<< payload from AssignStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assign-stage", requestOptions);
    const updateStages = await response.json();

    return updateStages;
});

///////////////////////////////// updateTags //////////////////////////////////
export const updateTags = createAsyncThunk("landing/updateUserTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-tag-add", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});
export const addconverstiondata = createAsyncThunk("addconverstiondata", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/add-conversion", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});

export const addlandingpageninedatabluecosde = createAsyncThunk("addlandingpageninedatabluecosde", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/addupdate-users", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const addlandingpageupdatesameemaildata = createAsyncThunk("addlandingpageupdatesameemaildata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/newcreateupdatedata", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
export const fetchbluecodelead = createAsyncThunk("fetchbluecodelead", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "_id": id };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/fetch-lead", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
export const fetchbluecoasteasplead = createAsyncThunk("fetchbluecoasteasplead", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "_id": id };
    console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-data", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const getAffiliateManageData = createAsyncThunk("getaffiliatemanagedata", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "id": id };
    console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/getusers", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const getNakaGamesData = createAsyncThunk("getNakaGamesdata", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "id": id };
    console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/getusers", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const getMLMData = createAsyncThunk("getMLMdata", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "id": id };
    console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/getusers", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const addemailcampaignapprover = createAsyncThunk("addemailcampaignapprover", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/addupdateemailcampaignapprovers", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
export const fetchemailapprover = createAsyncThunk("fetchemailapprover", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "_id": id };
    console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/getemailcampaignapprovers", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})



export const EmailCampaignApproverlist = createAsyncThunk("email-campaign-approver/list", async (reqbody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    if (reqbody == null && reqbody === undefined) {
        reqbody = {

            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",


            "count": false,



        }
    }
    console.log("token=======>", token);
    let req_body = {};
    req_body = { ...reqbody , token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/emailcampaignapproverslist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});





export const fetchNDAformData = createAsyncThunk("fetch/NDAformData", async (req) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = req;
    console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetchndaformdata", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const EmailCampaignApproverlistBlueCoast = createAsyncThunk("email-campaign-approver-bluecoast/list", async (reqbody) => {
    
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    if (reqbody == null && reqbody === undefined) {
        reqbody = {

            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
                "email_campaign_approver": "bluecoast"
            },
            "project": {},
            "token": "",


            "count": false,



        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody, token:token };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/emailcampaignapproverslist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// =============================landing page rep add new api 2024==========================
export const addNewRepUserData = createAsyncThunk("addNewRepUserDataId", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "cognito_manage/landing-page-ten-add-user", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})




// -------------------pharmacy saving landing page3 async---------------

// export const addlandingpagethreedata = createAsyncThunk("addlandingpagethreedata", async (reqBody) => {
// console.log("reqbody==>", reqBody);
// let req_body = {};
// req_body = { ...reqBody };
// console.log("req_body==", req_body);
// const requestOptions = {
// method: 'post',
// headers: { 'Content-Type': 'application/json' },
// body: JSON.stringify(req_body)
// };
// console.log("process.env==>", process.env);
// const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/add-user", requestOptions);
// const respdata = await response.json();
// console.log("respdata after endpoint fetch==>", respdata);
// return respdata;
// })


const LandingpagefontReducer = createSlice({
    name: 'LandingpagefontReducer',
    initialState: {
        landingpageoneuserdata: [],
        loader: false,
        landingpagesubmittionstate: 0,
        addedinfo: [],
        Stagesdata: [],
        FetchStages: [],
        FetchTagDetailsData: [],
        bluecouseadddata: null,
        bluecoseloader: false,
        adddsuccessflag: true,
        bluefetchdata: null,
        affiliatedata: null,
        nakagamesdata: null,
        blueaspadddata: null,
        blueaspfetchdata: null,
        forcelogindata: null,
        forceloginapicall: false,
        forceloginapicallloader: false,
        landingwithcampain: [],
        response: null,
        emailcampaignapproverdata: null,
        emailapproverfetchdata: null,
        emailapproverloader: false,
        addedemailapproverinfo: [],
        emailcampaignapproverdatalist: [],
        NDAformData: [],
        emailcampaignapproverdatalistbluecoast: [],
        scrollView: {},
        mlmdata:null,
        bluecostSingelFieldUpdate:null
    },
    reducers: {
        setlandingwithcampain(state, action) {
            state.landingwithcampain = action.payload

        },
        clearforceloginapidata(state, action) {
            state.forceloginapicall = false
            state.forcelogindata = null
            state.forceloginapicallloader = false
        },
        clearadddsuccessflag(state, action) {
            state.adddsuccessflag = false
            state.bluecouseadddata = null
        },
        bluefetchdata(state, action) {

            state.bluefetchdata = null

        },
        clearLandingpageReducer(state, action) {
            state.landingpageoneuserdata = []
        },
       
        clearaddedinfo(state, action) {
            state.addedinfo = []
            state.response = null
            state.emailcampaignapproverdata = null
        },
        clearlandingpagesubmittionstate(state, action) {
            state.landingpagesubmittionstate = 0
        },
        blueaspfetchdata(state, action) {
            state.blueaspfetchdata = null
        },
        emailcampaignapprover(state, action) {
            state.emailapproverfetchdata = null
        },
        clearmlmdata(state,action){
            state.mlmdata= null
        },
        clearbluecostSingelFieldUpdate(state,action){
            state.bluecostSingelFieldUpdate=null
        }




    },
    extraReducers: {


        [forcelogin.pending]: (state, action) => {
            state.forceloginapicallloader = true

            state.loader = true;
            // state.bluecoseloader = true;
        },
        [forcelogin.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                // state.bluecoseloader = false;
                state.forceloginapicallloader = false

                state.forceloginapicall = true;
                state.forcelogindata = action.payload;
                // state.adddsuccessflag=true
            }
            state.loader = false;

        },
        [forcelogin.rejected]: (state, action) => {
            state.bluecoseloader = false;
            state.loader = false;
        },
        [fetchbluecodelead.pending]: (state, action) => {
            // state.loading = true;
            state.bluecoseloader = true;
        },
        [fetchbluecodelead.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.bluecoseloader = false;
                state.bluefetchdata = action.payload.results;
                // state.adddsuccessflag=true
            }
        },
        [fetchbluecodelead.rejected]: (state, action) => {
            state.bluecoseloader = false;
        },



        [addlandingpageninedatabluecosde.pending]: (state, action) => {

            // state.loading = true;
            state.bluecoseloader = true;


        },
        [addlandingpageninedatabluecosde.fulfilled]: (state, action) => {
            console.log("action",action);
            if (action.payload.status === "success") {
                if( action.payload.results){
                    state.bluecouseadddata = action.payload.results;
                }else{
                    state.bluecostSingelFieldUpdate = action.payload;
                }
                state.adddsuccessflag = true
            }
            state.bluecoseloader = false;
        },
        [addlandingpageninedatabluecosde.rejected]: (state, action) => {
            state.bluecoseloader = false;




        },
        /////////////////////////// updateTags ///////////////////////////
        [updateTags.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;
            state.tagUpdated = false;

        },
        [updateTags.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.tagUpdated = true;
                state.FetchTagDetailsData.find((data) => data.key === action.meta.arg.tag_data.key).status = action.meta.arg.tag_data.status
            } else {
                state.loading = false;
                state.resMessage = 'Something Went Wrong';
                state.tagUpdated = false;

            }
        },
        [updateTags.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []
            state.tagUpdated = false;



        },



        /////////////////////////// AssignStages ///////////////////////////
        [updateStages.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;

        },
        [updateStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;

                console.warn("<<<<<<<<<<<< state.FetchStages >>>>>>>>", [...state.FetchStages])

                state.FetchStages = state.FetchStages.map((stages) => ({ ...stages, status: stages.key === action.meta.arg.stage_slug ? 1 : 0 }))

                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';


            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            }
        },
        [updateStages.rejected]: (state, action) => {
            state.loading = false;
            // state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';



        },

        [fetchbluecoasteasplead.pending]: (state, action) => {
            // state.loading = true;
            state.bluecoseloader = true;
        },
        [fetchbluecoasteasplead.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.bluecoseloader = false;
                state.blueaspfetchdata = action.payload.results.res;
                // state.adddsuccessflag=true
            }
        },
        [fetchbluecoasteasplead.rejected]: (state, action) => {
            state.bluecoseloader = false;
        },




        [addlandingpageonedata.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addlandingpageonedata.pending]: (state, action) => {

            state.landingpagesubmittionstate = 1
        },
        [addlandingpageonedata.fulfilled]: (state, action) => {
            state.landingpagesubmittionstate = 2
            state.addedinfo = action.payload.results?.res;
            state.blueaspadddata = 2
            state.response = action.payload
        },



        [getlandingpageoneuserdata.rejected]: (state, action) => {
            state.loader = false
        },
        [getlandingpageoneuserdata.pending]: (state, action) => {
            state.loader = true
        },
        [getlandingpageoneuserdata.fulfilled]: (state, action) => {
            state.loader = false
            state.landingpageoneuserdata = action.payload.results;
        },

        /*************************For MlmOne********* */

        [getmlmoneuserdata.rejected]: (state, action) => {
            state.loader = false
        },
        [getmlmoneuserdata.pending]: (state, action) => {
            state.loader = true
        },
        [getmlmoneuserdata.fulfilled]: (state, action) => {
            state.loader = false
            state.landingpageoneuserdata = action.payload.results.res;
        },


        // [getmlmpagedata.rejected]: (state, action) => {
        //     state.loader = false
        // },
        // [getmlmpagedata.pending]: (state, action) => {
        //     state.loader = true
        // },
        // [getmlmpagedata.fulfilled]: (state, action) => {
        //     state.loader = false
        //     state.landingpageoneuserdata = action.payload.results;
        // },
        /******************************************************** */

        [addlandingpagetwodatalead.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addlandingpagetwodatalead.pending]: (state, action) => {

            state.landingpagesubmittionstate = 1
        },
        [addlandingpagetwodatalead.fulfilled]: (state, action) => {
            if (action.payload.status === "success" && action.payload.results) {
                state.landingpagesubmittionstate = 2
                state.addedinfo = action.payload.results.res;
            }

        },

        [addlandingpageninedata.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addlandingpageninedata.pending]: (state, action) => {

            state.landingpagesubmittionstate = 1
        },
        [addlandingpageninedata.fulfilled]: (state, action) => {
            state.landingpagesubmittionstate = 2
            state.addedinfo = action.payload.results.res;
        },




        [getlandingpagedata.rejected]: (state, action) => {
            state.loader = false
        },
        [getlandingpagedata.pending]: (state, action) => {
            state.loader = true
        },
        [getlandingpagedata.fulfilled]: (state, action) => {
            state.loader = false
            state.landingpageoneuserdata = action.payload.results;
        },


        [getAffiliateManageData.rejected]: (state, action) => {
            state.loader = false
        },
        [getAffiliateManageData.pending]: (state, action) => {
            state.loader = true
        },
        [getAffiliateManageData.fulfilled]: (state, action) => {
            console.log("action======>>>", state.affiliatedata);

            state.loader = false
            state.affiliatedata = action.payload.results.res[0];
        },

        /*******************For NakaGames************* */
        [getNakaGamesData.rejected]: (state, action) => {
            state.loader = false
        },
        [getNakaGamesData.pending]: (state, action) => {
            state.loader = true
        },
        [getNakaGamesData.fulfilled]: (state, action) => {
            state.loader = false
            state.nakagamesdata = action.payload.results.res[0];
        },

         /*******************For MLM************* */
         [getMLMData.rejected]: (state, action) => {
            state.loader = false
        },
        [getMLMData.pending]: (state, action) => {
            state.loader = true
        },
        [getMLMData.fulfilled]: (state, action) => {
            state.loader = false
            state.mlmdata = action.payload.results.res[0];
        },
        /**************************************************** */
        [addemailcampaignapprover.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addemailcampaignapprover.pending]: (state, action) => {

            state.landingpagesubmittionstate = 1
        },
        [addemailcampaignapprover.fulfilled]: (state, action) => {
            if (action.payload.status === "success" && action.payload.results) {
                state.landingpagesubmittionstate = 2
                state.addedemailapproverinfo = action.payload.results?.res;
                state.emailcampaignapproverdata = 2
                state.response = action.payload
                state.adddsuccessflag = true
            }
        },
        [fetchemailapprover.pending]: (state, action) => {
            // state.loading = true;
            state.emailapproverloader = true;
        },
        [fetchemailapprover.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.emailapproverloader = false;
                state.emailapproverfetchdata = action.payload.results.res[0];
                // state.adddsuccessflag=true
            }
        },
        [fetchemailapprover.rejected]: (state, action) => {
            state.emailapproverloader = false;
        },



        [EmailCampaignApproverlist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [EmailCampaignApproverlist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [EmailCampaignApproverlist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.emailcampaignapproverdatalist = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
        },





        // ============ fetchNDAformData ============ //


        [fetchNDAformData.pending]: (state, action) => {
            state.NDAformData = []
            state.loading = true;
        },
        [fetchNDAformData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.NDAformData = action.payload?.results?.res ? action.payload.results.res : [];
                state.message = action.payload.status;
                state.loading = false;

            }
        },
        [fetchNDAformData.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;
            state.NDAformData = [];
        },

        // ====================================== //

        [EmailCampaignApproverlistBlueCoast.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [EmailCampaignApproverlistBlueCoast.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [EmailCampaignApproverlistBlueCoast.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.emailcampaignapproverdatalistbluecoast = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
        },

        // =============================landing page rep add new api lifecycle 2024==========================
        
        [addNewRepUserData.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addNewRepUserData.pending]: (state, action) => {
            state.landingpagesubmittionstate = 1
        },
        [addNewRepUserData.fulfilled]: (state, action) => {
            state.landingpagesubmittionstate = 2
            state.addedinfo = action.payload.results?.res;
            console.log("state.addedinfo=====>>>>1", state.addedinfo);
            state.blueaspadddata = 2
            state.response = action.payload
            console.log("state.addedinfo=====>>>>2", state.response);

        },


    }
})

export default LandingpagefontReducer.reducer;
export const { clearLandingpageReducer, clearlandingpagesubmittionstate, clearaddedinfo, clearforceloginapidata, clearadddsuccessflag, bluefetchdata, blueaspfetchdata, setlandingwithcampain, clearAddEditblcstData, emailcampaignapprover,clearmlmdata ,clearbluecostSingelFieldUpdate} = LandingpagefontReducer.actions;
