
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import CancelIcon from '@mui/icons-material/Cancel';
// import "./addUserDrawer.css";
import { useCookies, Cookies } from "react-cookie";
import store from "../../../../store";
import { Button, LinearProgress } from "@mui/material";
import { clearContractData, fetchContactTemplate, fetchContractTemplate } from "./userListReducer";
import ContractViewModal from "./contractViewModal";
import { Box } from "@mui/system";

// import {
//     addUser, setRouteType
// } from "./addUserDrawerReducer";

// import {
//     addUser,
// } from "./addUserDrawerReducer";

const ContactDrawer = (props) => {
    console.log("<<< drawer props goes here >>>>", props)


    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const dispatch = useDispatch();

    const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]); //**** FOR Getting Cookie****//
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    const [contactRef, setContactRef] = useState('');  //*****Set Contact Ref****//
    const [previewModal, setPreviewModal] = useState(false);  //*****Set previewModal****//


    // ------- contractTemplateData -------
    const contractTemplateData = useSelector(state => (state.userManagment?.contractTemplate) ? state.userManagment.contractTemplate : {});

    const Loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment.loading : false);

    useEffect(() => {
        if (Object.keys(contractTemplateData).length > 0) {
            setPreviewModal(true)
        }
    }, [contractTemplateData])




    // --- drawer close function ---
    function closeDrawer() {

        props.setcontractdrawer(false)

        dispatch(clearContractData())
    }


    useEffect(() => {
        if (props.contractdata.length > 1) {

            // props.contractdata.map((data) => console.warn("<<<<<<< contract data >>>>>> ", data))
            // console.warn("<<<<<<< contract data >>>>>> ", typeof (props.contractdata));
        }
        setPreviewModal(false)
    }, [])

    function setupData(val) {
        // console.warn("<<<<<<<<<< hereeee >>>>>>>>>>", val)
        setContactRef(val)
        // console.warn("<<<<<<<<<< contactRef >>>>>>>>>>", contactRef)

    }


    useEffect(() => {
        if (contactRef !== '') {
            const payload = {
                "searchcondition": {
                    "user_id": props.userid,
                    "contract_id": contactRef,

                }
            }
            dispatch(fetchContractTemplate(payload))
        }
    }, [contactRef])


    return (
        <>

            {/* --- Drawer ---- */}
            <Drawer anchor="bottom" open={props.open} className="contractdrawer">

                <div className="drawer-content">

                    <div className="heading">
                    {Loader && (<Box sx={{ width: "100%"}}><LinearProgress /></Box>)}

                        <h2>

                            Choose Contract {`For : ${props.source === "dashboard_user" || props.source === "dashboard_Lead" ? props.username : props.userinfo?.fullname ? props.userinfo.fullname : props.userinfo?.name ? props.userinfo.name : props?.userinfo?.firstname + ' ' + props?.userinfo?.lastname}`}
                        </h2>

                        {/* {folderView} */}

                    </div>

                    {/* -- if we login with Admin then this block will appear */}

                    <div className="btn-grp">

                        {
                            //   props.contractdata.name? (<li> </li>):""
                            props.contractdata.map((data, i) => <Button variant="contained" key={i} className="conts" id={data._id} onClick={() => setupData(data.key)}>{data.label}</Button>)
                        }


                    </div>

                    <CancelIcon onClick={closeDrawer} className="drawerCloseIcon" />

                </div>
            </Drawer>


            {previewModal ? <ContractViewModal
                contractdata={contractTemplateData}
                toogle={previewModal}
                tooglefunc={setPreviewModal}
                username={props.username}
                userinfo={props.userinfo}
                source={props.source}
            /> : ""}


        </>
    )
}

export default ContactDrawer;