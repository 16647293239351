import React from 'react'
import "./LandingPage14.css"

export default function Lp14block6() {
    return (
        <>
            <div className="Lp14_block6">
                <div className="landingpage14_common_width">
                    <div className="Lp14_block6_TOPheading">
                        <h1><span>ACCESS DISCOUNT HEALTHCARE</span> includes</h1>
                    </div>
                    <div className="Lp14_block6_bottomwrpr">
                        <div className="Lp14_b6_subwrpr">
                            <div className="Lp14_b6_comn_minblock">
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/med.png`} />
                                {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/med.png" alt="med_img" /> */}
                                <h2>Acute Medications</h2>
                                <h3>(Immediate Need)</h3>
                                <p>If you need these types of medications, we have included a prescription card in the welcome packet to use at a retail pharmacy. This card includes the top most prescribed acute medications for up to a 21-day supply
                                    at over 64,000 pharmacies nationwide.</p>
                            </div>
                            <div className="Lp14_b6_comn_minblock">
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/med_time.png`} />
                                {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/med_time.png" alt="med_time_img" /> */}
                                <h2>Maintenance Medications</h2>
                                <h3>(Chronic Need)</h3>
                                <p>Maintenance drugs are medications prescribed for chronic, long-term conditions and are taken regularly. Examples of chronic conditions are high blood pressure, high cholesterol, and diabetes, to name a few. These drugs are well-established medications that have proven safety and efficacy and are considered first-line therapy options for the treatment of common chronic conditions.
                                </p>
                            </div>
                        </div>
                        <div className="Lp14_b6_subwrpr">
                            <div className="Lp14_b6_comn_minblock">
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/medshop.png`} />
                                {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/medshop.png" alt="medshop_img" /> */}
                                <h2>Over-The-Counter Medications</h2>
                                <h3>(Everyday)</h3>
                                <p>Access Discount Healthcare offers the most common over-the-counter medications, such as Ibuprofen, Acetaminophen, Fexofenadine, Diphenhydramine, and others, at a fraction of the price. We know the little things add up each month, so we aim to save you money on the medications you use every day.</p>
                            </div>
                            <div className="Lp14_b6_comn_minblock">
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/medbox.png`} />
                                {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/medbox.png" alt="medbox_img" /> */}
                                <h2>Diabetes</h2>
                                <h3>(Save On Diabetes)</h3>
                                <p>SaveOn Diabetes is our game-changing program for members with Diabetes in which they will get a FREE meter, low-cost testing strips, lancets, and more. Many people have diabetes, and one in three Americans is pre-diabetic. Make sure you are watching the warning signs.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
