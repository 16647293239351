import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from "react-cookie";
import { apicall, getUSerdataFromState } from "../../../helper/helperFunctions";
const cookies = new Cookies().getAll();




export const getleaddata = createAsyncThunk("getleaddata", async (reqBody) => {
    // console.log("reqbody==>", reqBody,cookies,getUSerdataFromState());
    const cookies = new Cookies();
    const token = cookies.getAll().token;
    let userData = getUSerdataFromState()
    let req_body = {};
    req_body = { ...reqBody, token: token };
    console.log("req_body==", req_body);
    if (userData) {
        if (userData.main_role == 'is_rep' && !reqBody.searchcondition['rep_id']) {
            reqBody.searchcondition['rep_id'] = userData._id
        }
        if (userData.main_role == 'hr_manager' && !reqBody.searchcondition['hiring_manager'] && !reqBody.noFlag == "data") {
            reqBody.searchcondition['hiring_manager'] = userData._id
        }
        // if (userData.main_role == 'hr_manager' && reqBody.noFlag=="data") {
        //     reqBody.searchcondition = userData._id
        // }
        if (userData.main_role == 'is_bdd' && !reqBody.searchcondition['associated_bdd']) {
            reqBody.searchcondition['associated_bdd'] = userData._id
        }

    }


    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    // const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/lead-list-count566", requestOptions);
    // const respdata = await response.json();
    const response = await apicall("lead-manage/lead-list-count", requestOptions);
    // console.log("respdata after endpoint fetch==>", response);
    return response;
})











export const getleadeditdata = createAsyncThunk("editrole", async (id) => {
    let reqBody = {
        "_id": id
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetch-lead", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getallprogramdata = createAsyncThunk("getallprogramdata", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;




})





export const fatchViewData = createAsyncThunk("fatchViewData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetch-lead", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const getNotesleaddata = createAsyncThunk("getNotesleaddata", async (reqBody) => {

    if (!reqBody) {
        reqBody = {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "_id",
                type: "desc",
            },
            searchcondition: {},
            project: {},
            token: "",
            count: false,
        };
    }

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "note/note-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getNotesleaddataCount = createAsyncThunk("getNotesleaddataCount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "note/note-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


/////////////////////////////////  FetchStages //////////////////////////////////
export const FetchStages = createAsyncThunk("lead/fetchstages", async (reqbody) => {
    console.warn("<<<<<<<< payload from FetchStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assigned-stage-list", requestOptions);
    const FetchStages = await response.json();

    return FetchStages;
});




/////////////////////////////////  AssignStages //////////////////////////////////
export const updateStages = createAsyncThunk("lead/updatestages", async (reqbody) => {
    console.warn("<<<<<<<< payload from AssignStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assign-stage", requestOptions);
    const updateStages = await response.json();

    return updateStages;
});




/////////////////////////////////  FetchTagDetails //////////////////////////////////
export const FetchTagDetails = createAsyncThunk("lead/FetchTagDetails", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/manage-tags", requestOptions);
    const FetchTagDetails = await response.json();

    return FetchTagDetails;
});


/////////////////////////////////  updateTags //////////////////////////////////
export const updateTags = createAsyncThunk("lead/updateleadTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-tag-add", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});










export const getusersList = createAsyncThunk("getusersList", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    if (cookies.userinfo.main_role != 'is_admin') {
        reqBody.searchcondition['added_by'] = cookies.userinfo._id
    }

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-list-or-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// For getting CIF Form Configuration
export const getCIFForm = createAsyncThunk("LeadReducer/getCIFForm", async (reqBody) => {
    console.log("reqbody for CIF form --->", reqBody);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/preview-form", requestOptions);
    const respdata = await response.json();
    console.log("respdata for CIF form --->", respdata);
    return respdata;
})

// For getting CIF Form Configuration
export const sendCIF_Mail = createAsyncThunk("LeadReducer/sendCIF_Mail", async (reqBody) => {
    console.log("reqbody for CIF form --->", reqBody);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "sample/send-cif-mail", requestOptions);
    const respdata = await response.json();
    console.log("respdata for CIF form --->", respdata);
    return respdata;
})

// For getting Lead Tags
export const getLeadTags = createAsyncThunk("lead/updateUserTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from getLeadTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/single-fetch-tag-data", requestOptions);
    const updateTagsres = await response.json();

    return updateTagsres;
});




export const getDatafollowUpCallList = createAsyncThunk("leadlist/followUpCallList", async (reqBody) => {



    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/follow-up-call-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const sendMailForUser = createAsyncThunk("sendMailForUser", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-template/assign-email", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
export const getProgramRelatedCIFList = createAsyncThunk("getProgramRelatedCIFList", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "updatedon_datetime",
            "type": "desc"
        },
        "searchcondition": {
            "related_programs": reqBody
        },
        "project": {},
        "token": ""
    };

    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/cif-form-preview", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})



export const getProductautocomplete = createAsyncThunk("getproductautocomplete", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "program_list",
        "sort": {
            "type": "desc",
            "field": "name"
        },
        "searchcondition": {

        }


    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/fetch-programs", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});



/////////////////////////////////  ShowStageList //////////////////////////////////
export const ShowStageList = createAsyncThunk("lead/showstages", async (reqbody) => {
    console.warn("<<<<<<<< payload from showstageList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-stage-data", requestOptions);
    const respdata = await response.json();

    return respdata;
});


export const gettagalldata = createAsyncThunk("lead/gettagalldata", async (reqbody) => {
    console.warn("<<<<<<<< payload from showtagList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/single-fetch-tag-data", requestOptions);
    const reponse = await response.json();

    return reponse;
});

export const getcalenderinvitationlink = createAsyncThunk("lead/getcalenderinvitationlink", async (reqbody) => {
    console.warn("<<<<<<<< payload from showtagList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "calendar-api/resend-calendar-booking-link", requestOptions);
    const reponse = await response.json();

    return reponse;
});


export const getconversiondone = createAsyncThunk("lead/getconversiondone", async (reqbody) => {
    console.warn("<<<<<<<< payload from showtagList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/set-contract", requestOptions);
    console.log("response", response)
    const reponse = await response.json();

    return reponse;
});


export const addlead = createAsyncThunk("addleaddata", async (reqBody) => {
    console.log("addleaddata reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/addorupdatelead", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const csvupload = createAsyncThunk("lead/csvupload", async (reqbody) => {


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/csv-upload", requestOptions);
    const reponse = await response.json();

    return reponse;
});

// --------------------------------------------------------for manual status update ------------------------------------

export const LeadStatusUpdate = createAsyncThunk("lead-manage/status-update", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/status-update", requestOptions);
    const reponse = await response.json();
    return reponse;
})
// --------------------------------------------------------for manual status delite ------------------------------------
export const deliteLead = createAsyncThunk("deliteLead", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/delete-lead", requestOptions);
    const reponse = await response.json();
    return reponse;
})

/*********************************For rep autocomplete in Edit and ADD category page******** */
// export const getRepData = createAsyncThunk("getrepdata/list", async () => {

//     // console.log("reqbody==>", reqbody);
//     let req_body = {
//         // "source": "users",
//         "sort": {
//             "type": "desc",
//             "field": "name"
//         },
//         "searchcondition": {
//             "status": 1,
//             'is_rep': 1

//         }
//     };

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     // console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "users/auto-complete-user-name", requestOptions);
//     const respdata = await response.json();
//     console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;

// });









/////////////////////////////////  Lead Add new follow up //////////////////////////////////
export const leadAddFollowUp = createAsyncThunk("lead/leadAddFollowUp", async (reqbody) => {
    console.warn("<<<<<<<< payload from addFollowUp thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/follow-up-call-add", requestOptions);
    const addFollowUp = await response.json();

    return addFollowUp;
});


export const autoCompleteHMData = createAsyncThunk("fetch/autoCompleteHMData", async (reqBody) => {
    // console.warn("<<<<<< inside autoCompleteBddData >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/autocomplete-hm ", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

//autoCompleteBddData

export const autoCompleteBddData = createAsyncThunk("fetch/autoCompleteBddData", async (reqBody) => {
    // console.warn("<<<<<< inside autoCompleteBddData >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/autocomplete-bdd ", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const leadHmBddData = createAsyncThunk("fetch/leadHmBddData", async (reqBody) => {
    console.warn("<<<<<< inside fetchHmBddData >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/bdd-hm-info", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const updateAssignHmBdd = createAsyncThunk("updateAssignHmBdd", async (reqBody) => {
    console.log("addleaddata reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/update-bdd-hm", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// LeadReducer Slice
const LeadReducer = createSlice({
    name: 'LeadReducer',
    initialState: {

        submitting: false,
        hmbddLoading:false,
        loading: false,
        editloading: false,
        editleaddata: null,
        leaddata: [],
        singalLeadData: {},
        programdata: [],

        fatchViewData: {},
        Notesleaddata: [],
        notesloading: false,
        notesleaddataCount: 0,

        usersList: [],
        FetchStages: [],
        resMessage: null,
        FetchTagDetailsData: [],
        updateTagData: {},
        tokenRequired: false,
        tokenRequiredMsg: '',
        ShowStageListData: [],
        ShowStageListDataFetch: false,
        ShowTagListData: [],
        getproduct: [],

        info_CIF: null,
        cif_mail_send_res: null,
        leadTags: null,

        followUpCallData: [],
        loaderForFollowUp: false,
        loaderForsendmail: false,
        submistionstate: 0,
        taglifecycledata: [],
        programRelatedCIFList: [],
        calenderinvitation: null,
        markconversion: null,
        leadadditiondata: null,
        addsuccessflag: true,
        editsuccessflag: true,
        addleadloader: false,
        updatestageloader: false,
        uploadCsv: [],
        snakebarData: {},
        deleteSnackBarData: {},
        statusSnackBarData: {},
        Leadtogalbtnview: null,
        // getrepdata: [],
        upgradeTagMassage: null,
        upgradeTagStatus: null,
        upgradeStageMassage: null,
        upgradeStageStatus: null,
        errmasage: null,
        leadNotesStatus: null,
        addFollowUpData: null,
        emailSendStatus: null,
        autoCompleteHMData: [],
        autoCompleteBddData: [],
        leadHmBddDataInfo: null,
        leadHmBddDataStatus: null,
        snackBarMessage: null,
        updateHmBddStatus: null,
        taglifecyclestatus:null,
        emailStatus:null,

        loadingTag: false

    },
    reducers: {
        clearleaddata(state, action) {
            state.leaddata = []
            state.singalLeadData = {}
        },
        clearSingalleaddata(state, action) {
            state.singalLeadData = {}
        },
        clearinvationdata(state, action) {
            state.calenderinvitation = null
        },
        clearconversiondata(state, action) {
            state.markconversion = null
        },

        cleareditleaddata(state, action) {
            state.editsuccessflag = false
            state.editleaddata = null
        },
        clearprogramdata(state, action) {
            state.programdata = []
        },

        clearfatchViewData(state, action) {
            state.fatchViewData = {}
        },

        cleargetNotesleaddata(state, action) {
            state.Notesleaddata = [];
            state.notesloading = false;
        },

        setStatesBlank(state, action) {
            state.loading = false;
            state.FetchTagDetailsData = null;
            state.FetchStages = [];
            state.resMessage = null;
            state.successAction = false;

            // state.ShowStageListData = []
        },
        reSetSubmistionstate(state, action) {

            state.submistionstate = 0;

        },
        clearaddsuccessflag(state, action) {
            state.addsuccessflag = false
            state.leadadditiondata = null


        },
        clearCsvUpload(state, action) {
            state.uploadCsv = []
        },
        clearLeadStageData(state, action) {
            state.FetchStages = [];
        },
        clearLeadTagData(state, action) {
            state.FetchTagDetailsData = null
        },
        clearFoloUPCallData(state, action) {
            state.followUpCallData = []
        },
        clearSnakebarData(state, action) {
            state.snakebarData = {}
            state.upgradeTagMassage = null
            state.upgradeTagStatus = null
            state.upgradeStageMassage = null
            state.upgradeStageStatus = null
            state.deleteSnackBarData = {}
            state.statusSnackBarData = {}
            state.errmasage = null
        },
        clearEmailSendStatus(state, action) {
            state.emailSendStatus = null
        },

        // setTempRowId(state, action) {

        // },
        clearHmBddData(state, action) {
            state.autoCompleteBddData = []
            state.autoCompleteHMData = []
        },
        clearleadHmBddDataInfo(state, action) {
            state.leadHmBddDataInfo = []
            state.leadHmBddDataStatus = null

        },

        clearUpdateHmBddStatus(state, action) {
            state.updateHmBddStatus = null
        },
        cleartaglifecyclestatus(state,action){
            state.taglifecyclestatus=null
        }


    },
    extraReducers: {


        [gettagalldata.rejected]: (state, action) => {
            state.loadingTag = false

        },
        [gettagalldata.pending]: (state, action) => {
            state.loadingTag = true
            // 

        },
        [gettagalldata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.taglifecycledata = action.payload.results.res;
                console.log(state.taglifecycledata, "taglifecycledata>>>>>>>>");
                state.loadingTag = false
                state.taglifecyclestatus=action.payload.status
            }
        },


        // <----------------- For Getting Program Related CIF List ----------------->
        [getProgramRelatedCIFList.pending]: (state, action) => {
            state.loading = true;

        },
        [getProgramRelatedCIFList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loading = false;
                state.programRelatedCIFList = action.payload.results.res;
            }
        },
        [getProgramRelatedCIFList.rejected]: (state, action) => {
            state.loading = false;
        },
        // <------------------------------------------------------------------------>

        [sendMailForUser.rejected]: (state, action) => {
            state.loaderForsendmail = false;
            state.submistionstate = 3;

        },
        [sendMailForUser.pending]: (state, action) => {
            state.loaderForsendmail = true;
            state.submistionstate = 1;

        },
        [sendMailForUser.fulfilled]: (state, action) => {
            state.emailSendStatus = action.payload.status
            if (action.payload.status === 'success') {
                state.loaderForsendmail = false;
                state.submistionstate = 2;
                state.emailStatus="success"
            }
        },



        [getDatafollowUpCallList.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.followUpCallData = []
            state.loaderForFollowUp = false;
            state.loading = false;
        },
        [getDatafollowUpCallList.pending]: (state, action) => {
            state.followUpCallData = []
            state.loaderForFollowUp = true;
            state.loading = true;

        },
        [getDatafollowUpCallList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.followUpCallData = action.payload?.results?.res ? action.payload.results.res : [];
                state.loaderForFollowUp = false;

                state.loading = false;

            }
        },




        // ================ For GET DATA ================ //
        [getusersList.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getusersList.pending]: (state, action) => {
            state.loading = true;
        },
        [getusersList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.usersList = action.payload.results.res;
                state.loading = false;
            }
        },


        // ================ For GET DATA ================ //
        [getleaddata.rejected]: (state, action) => {
            console.log("LOPOO 1");
            // state.endpointsuccess[action.type] = false
            state.errmasage = null
            state.successAction = false;
        },
        [getleaddata.pending]: (state, action) => {
            console.log("LOPOO 2");
            state.errmasage = null

            state.loading = true;
        },
        [getleaddata.fulfilled]: (state, action) => {
            console.log("LOPOO 3", action);

            if (action.payload.status === 'success') {
                state.leaddata = action?.payload?.results?.res;
                state.loading = false;
            }
            if (action.payload.results?.res.length === 0) {
                state.errmasage = "No User Found"
            }
            state.loading = false
            console.log("action.payload.results", action.payload.results);


        },

        // -------------------------- ###################3 ------------ //



        // ================ For getNotesleaddata ================ //
        [getNotesleaddata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.notesloading = false
        },
        [getNotesleaddata.pending]: (state, action) => {
            state.notesloading = true
            state.Notesleaddata = []
        },
        [getNotesleaddata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.Notesleaddata = action?.payload?.results?.res;
                state.notesloading = false
                state.leadNotesStatus = action?.payload?.status
                console.log("lead note data", action?.payload);
            }
        },


        [getNotesleaddataCount.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.notesloading = false
        },
        [getNotesleaddataCount.pending]: (state, action) => {
            state.notesloading = true
        },
        [getNotesleaddataCount.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.notesleaddataCount = action?.payload?.count;
                state.notesloading = false
            }
        },

        /*************************For getting Rep in add form page****** */
        //  [getRepData.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.successAction = false;

        // },
        // [getRepData.pending]: (state, action) => {
        //     // console.log("action p", action)
        //     // state.usersList = [];
        //     state.loading = true;
        // },
        // [getRepData.fulfilled]: (state, action) => {
        //     console.log("action f storedata", action)
        //     if (action.payload.status === 'success') {
        //         // console.log("Resyyyyyyyyyyyyyyyyyyy",action.payload.results.res)
        //         state.getrepdata = action.payload.results.res;

        //     }
        // },





        //-----------########   fatchViewData #######   --------//
        [fatchViewData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.fatchViewData = {};

        },
        [fatchViewData.pending]: (state, action) => {
            // state.loading = true;
            state.fatchViewData = {};
        },
        [fatchViewData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.fatchViewData = action.payload?.results?.res;
                // state.loading = false;
            }
        },





        // ================ For EDIT DATA ================ //
        [getleadeditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.loading = false;


        },
        [getleadeditdata.pending]: (state, action) => {
            state.editloading = true;
            state.loading = true;

        },
        [getleadeditdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editsuccessflag = true;
                state.editleaddata = action.payload.results.res;
                state.singalLeadData = action.payload.results.res[0]
                state.editloading = false;
                console.log('Fullllllllllllllllllllll', state.editleaddata[0])
                state.loading = false;

            }
        },



        [getallprogramdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [getallprogramdata.pending]: (state, action) => {
            state.editloading = true;
        },
        [getallprogramdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.programdata = action.payload.results.res;
                state.editloading = false;
                console.log('programdata', state.programdata)

            }
        },


        /////////////////////////// FetchStages ///////////////////////////
        [FetchStages.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.FetchStages = []

        },
        [FetchStages.fulfilled]: (state, action) => {
            console.log("FetchStages======>>>", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.FetchStages = action.payload?.results?.res ? action.payload.results.res : []
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.FetchStages = []
            }
        },
        [FetchStages.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchStages = []

        },


        /////////////////////////// AssignStages ///////////////////////////
        [updateStages.pending]: (state, action) => {

            state.updatestageloader = true;
            // state.resMessage = null;

        },
        [updateStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.updatestageloader = false;

                // console.warn("<<<<<<<<<<<< state.FetchStages >>>>>>>>", [...state.FetchStages])

                state.FetchStages = state.FetchStages.map((stages) => ({ ...stages, status: stages.key === action.meta.arg.stage_slug ? 1 : 0 }))

                // state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            } else {
                state.updatestageloader = false;
                // state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            }
            state.upgradeStageMassage = action.payload.message
            state.upgradeStageStatus = action.payload.status

            state.snakebarData = action.payload
        },
        [updateStages.rejected]: (state, action) => {
            state.updatestageloader = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },


        /////////////////////////// FetchTagDetails ///////////////////////////
        [FetchTagDetails.pending]: (state, action) => {

            state.loadingTag = true;
            state.resMessage = null;
            state.FetchTagDetailsData = []
        },
        [FetchTagDetails.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loadingTag = false;
                state.FetchTagDetailsData = action.payload?.results?.res ? action.payload.results.res : [];

            }
        },
        [FetchTagDetails.rejected]: (state, action) => {
            state.loadingTag = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []


        },

        /////////////////////////// updateTags ///////////////////////////
        [updateTags.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.tagUpdated = false;
            state.updateTagData = {};

        },
        [updateTags.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.tagUpdated = true;
                // state.FetchTagDetailsData.find((data) => data === action.meta.arg.data).status = action.meta.arg.data
                state.updateTagData = action.meta.arg.data[0]
                state.snakebarData = action.payload
                state.upgradeTagMassage = "Tag update successfully"
                state.upgradeTagStatus = "success"
            } else {
                state.loading = false;
                state.resMessage = 'Something Went Wrong';
                state.tagUpdated = false;
                state.upgradeTagMassage = "Unabel to Update"
                state.upgradeTagStatus = "failed"
            }


        },
        [updateTags.rejected]: (state, action) => {

            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.updateTagData = action.meta.arg.data
            state.tagUpdated = false;
            state.upgradeTagMassage = "Something Went Wrong"
            state.upgradeTagStatus = "failed"


        },

        [getProductautocomplete.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getProductautocomplete.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getProductautocomplete.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                console.log("Resyyyyyyyyyyyyyyyyyyy", action.payload.results.res)
                state.getproduct = action.payload.results.res;

            }
        },


        /////////////////////////// ShowStageList ///////////////////////////
        [ShowStageList.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.ShowStageListData = []
            state.ShowStageListDataFetch = false

        },
        [ShowStageList.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                console.log("lead stage cyt", action.payload.results.res);
                state.loading = false;
                state.ShowStageListData = action.payload?.results?.res ? action.payload.results.res : []
                state.ShowStageListDataFetch = true

            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.ShowStageListData = []
                state.ShowStageListDataFetch = true

            }
        },
        [ShowStageList.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.ShowStageListData = []
            state.ShowStageListDataFetch = true


        },




        // <--------------------------------- Extra Reducers for CIF Form Configuration --------------------------------->
        [getCIFForm.pending]: (state, action) => {
            state.loading = true;
        },
        [getCIFForm.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") state.info_CIF = action.payload?.results?.res ? action.payload.results.res : null;
        },
        [getCIFForm.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.info_CIF = null;
        },

        // <--------------------------------- Extra Reducers for CIF Form Configuration --------------------------------->
        [sendCIF_Mail.pending]: (state, action) => {
            state.loading = true;
        },
        [sendCIF_Mail.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") state.cif_mail_send_res = action.payload?.results?.res ? action.payload.results.res : null;
        },
        [sendCIF_Mail.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.cif_mail_send_res = null;
        },

        // <---------------------------------------- Extra Reducers for LeadTags ---------------------------------------->
        [getLeadTags.pending]: (state, action) => {
            state.loading = true;
            state.ShowTagListData = []
        },
        [getLeadTags.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success")
                state.leadTags = action.payload?.results?.res ? action.payload.results.res : null;
            state.ShowTagListData = action.payload?.results?.res ? action.payload.results.res : null;
            console.log(state.ShowTagListData, "ShowTagListData>>>");
        },
        [getLeadTags.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.info_CIF = null;
            state.ShowTagListData = [];
        },

        /*****************for calender invitation link******* */
        [getcalenderinvitationlink.pending]: (state, action) => {
            // state.loading = true;

        },
        [getcalenderinvitationlink.fulfilled]: (state, action) => {
            // state.loading = false;
            if (action.payload.status === "success")
                state.calenderinvitation = action.payload?.status ? action.payload.status : null;
            state.resMessage = "email sent successfully";
            state.successAction = true;

        },
        [getcalenderinvitationlink.rejected]: (state, action) => {
            // state.loading = false;

        },

        /********************Upload Csv*************** */

        [csvupload.pending]: (state, action) => {
            // state.loading = true;

        },
        [csvupload.fulfilled]: (state, action) => {
            // state.loading = false;
            if (action.payload.status === "success")
                state.uploadCsv = action.payload?.result ? action.payload.result : [];
            state.resMessage = "CSV uploaded successfully";
            state.successAction = true;

        },
        [csvupload.rejected]: (state, action) => {
            // state.loading = false;

        },




        /*****************for mark as conversion done******* */
        [getconversiondone.pending]: (state, action) => {
            state.loading = true;

        },
        [getconversiondone.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") {
                state.markconversion = action.payload?.status ? action.payload.status : null;
                state.resMessage = "Conversion done  successfully";
            }
        },
        [getconversiondone.rejected]: (state, action) => {
            state.loading = false;
        },


        

        /***********for lead add***** */
        [addlead.pending]: (state, action) => {

            // state.loading = true;
            state.addleadloader = true;


        },
        [addlead.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.addleadloader = false;
                state.leadadditiondata = action.payload.results;
                console.log("leadadditiondata", state.leadadditiondata)
                state.addsuccessflag = true

            }
        },
        [addlead.rejected]: (state, action) => {
            state.addleadloader = false;




        },


        //----------------------------This Reducer For Update AssignHM/BDD----------------------

        [updateAssignHmBdd.pending]: (state, action) => {

            // state.loading = true;
            state.addleadloader = true;


        },
        [updateAssignHmBdd.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.addleadloader = false;
                state.updateHmBddStatus = action?.payload?.status
                state.snakebarData = "Update Successfully"


            }
            else {
                state.snakebarData = "Not Update "
            }
        },
        [updateAssignHmBdd.rejected]: (state, action) => {
            state.addleadloader = false;




        },

        //======================================================================================





        // --------------------------------------------------------for status update ------------------------------------
        [LeadStatusUpdate.pending]: (state, action) => {
            state.loading = true
            state.Leadtogalbtnview = null
        },
        [LeadStatusUpdate.fulfilled]: (state, action) => {
            state.loading = false
            state.snakebarData = action.payload
            state.statusSnackBarData = action.payload
            state.Leadtogalbtnview = action.payload.message
            console.log("statyscc", action);
        },
        [LeadStatusUpdate.rejected]: (state, action) => {
            state.loading = false
        },






        // --------------------------------------------------------for Add new follow up  ------------------------------------
        [leadAddFollowUp.pending]: (state, action) => {
            state.loading = true

        },
        [leadAddFollowUp.fulfilled]: (state, action) => {
            console.log("follow up form success", action);
            state.loading = false
            state.addFollowUpData = ""


        },
        [leadAddFollowUp.rejected]: (state, action) => {
            state.loading = false
        },


        ///--------------------fetch hm bdd data-----------------

        [autoCompleteHMData.pending]: (state, action) => {
            state.loading = true


        },
        [autoCompleteHMData.fulfilled]: (state, action) => {
            state.loading = false
            state.autoCompleteHMData = action.payload.results.res
            console.log("dash action autoCompleteHMData", state.autoCompleteHMData)

        },
        [autoCompleteHMData.rejected]: (state, action) => {
            state.loading = false
        },


        //========================================================


        ///--------------------fetch hm bdd data-----------------

        [autoCompleteBddData.pending]: (state, action) => {
            state.loading = true


        },
        [autoCompleteBddData.fulfilled]: (state, action) => {
            state.loading = false
            state.autoCompleteBddData = action.payload.results.res
            console.log("dash action autoCompleteHMData", state.autoCompleteBddData)

        },
        [autoCompleteBddData.rejected]: (state, action) => {
            state.loading = false
        },


        //========================================================





        //----------------------------------------------------------for delite Lead ----------------------------------------
        [deliteLead.pending]: (state, action) => {
            state.loading = true
        },
        [deliteLead.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.snakebarData = action.payload
            }
            state.deleteSnackBarData = action.payload
            state.loading = false
        },
        [deliteLead.rejected]: (state, action) => {
            state.loading = false
        },

        ///--------------------fetch hm bdd data-----------------

        [leadHmBddData.pending]: (state, action) => {
            state.loading = true
            state.leadHmBddDataReducer = null
            state.hmbddLoading=true
            // state.userInfo ={}

        },
        [leadHmBddData.fulfilled]: (state, action) => {


            state.hmbddLoading=false
            state.loading = false;
            state.leadHmBddDataInfo = action.payload?.results?.res;
            state.leadHmBddDataStatus = action.payload.status
            // state.userInfo = action.payload?.results?.res;
            console.log("dash actionleadHmBddData", action.payload)
            if (state.leadHmBddDataStatus === "success") {
                state.snakebarData = "update successfully"
            }
        },
        [leadHmBddData.rejected]: (state, action) => {
            state.hmbddLoading=false

            state.loading = false
        },


        //========================================================



    }
})







export default LeadReducer.reducer;
export const { clearCsvUpload, cleartaglifecyclestatus,clearUpdateHmBddStatus, clearconversiondata, clearleaddata, clearSnakebarData, cleareditleaddata, clearfatchViewData, cleargetNotesleaddata, setStatesBlank, clearSingalleaddata, setTempRowId, reSetSubmistionstate, clearinvationdata, clearaddsuccessflag, clearLeadStageData, clearLeadTagData, clearFoloUPCallData, clearEmailSendStatus, clearHmBddData, clearleadHmBddDataInfo } = LeadReducer.actions;
