import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { apicall } from "../../../helper/helperFunctions";
import { Cookies } from "react-cookie";

export const getDataForHmDashboardGridList = createAsyncThunk(
  "hm-dashboard-grid-list",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",
    //   requestOptions
    // );
    const response = await apicall("users/hm-dashboard-grid-list", requestOptions);

    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getDataForHmDashboardChart = createAsyncThunk(
  "hm-dashboard-chart",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-chart",
    //   requestOptions
    // );
    const response = await apicall("users/hm-dashboard-chart", requestOptions);

    // const respdata = await response.json();
    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getDataForHmDashboardCard = createAsyncThunk(
  "hm-dashboard-card",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-card",
    //   requestOptions
    // );
    const response = await apicall("users/hm-dashboard-card", requestOptions);

    // const respdata = await response.json();
    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getDataFoAdminDashboardGridList = createAsyncThunk(
  "admin-dashboard-grid-list",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
        source: "users",
        type: "user_created",
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/admin-dashboard-list-grid",
    //   requestOptions
    // );
    const response = await apicall("users/admin-dashboard-list-grid", requestOptions);

    const respdata = response;
    // const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getDataFoAdminDashboardChart = createAsyncThunk(
  "admin-dashboard-chart",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/admin-dashboard-chart",
    //   requestOptions
    // );
    const response = await apicall("users/admin-dashboard-chart", requestOptions);

    // const respdata = await response.json();
    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getDataFoAdminDashboardCard = createAsyncThunk(
  "admin-dashboard-card",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/admin-dashboard-card",
    //   requestOptions
    // );
    const response = await apicall("users/admin-dashboard-card", requestOptions);

    // const respdata = await response.json();
    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getlandingpageForRep = createAsyncThunk(
  "getlandingpage",
  async (reqBody) => {
    const cookies = new Cookies();
    const token = cookies.getAll().token;

    // console.log("reqbody==>", reqBody);

    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "priority",
          type: "asc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    let req_body = {};
    req_body = { ...reqBody, token: token };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "landing-page-manage/landing-page-list",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getDataForNotes = createAsyncThunk(
  "dataForNotes",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "note/note-list-count",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("getDataForNotes==>", respdata);
    return respdata;
  }
);

export const getuniqurlhs = createAsyncThunk(
  "getuniqurlhs",
  async (id) => {
    let body = {
      _id: id
    }


    // console.log("req_body==", body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",
    //   requestOptions
    // );
    const response = await apicall("lead-manage/rep_uniqurl_present", requestOptions);

    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);
export const getconversioncount = createAsyncThunk(
  "getconversioncount",
  async (id) => {
    let body = {
      campaign_added_by: id
    }


    // console.log("req_body==", body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",
    //   requestOptions
    // );
    const response = await apicall("campaign-manage/campaignaddedbycount", requestOptions);

    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);
export const getcampaignaccessdata = createAsyncThunk(
  "getcampaignaccessdata",
  async (id) => {
    let body = {
      _id: id
    }


    // console.log("req_body==", body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",
    //   requestOptions
    // );
    const response = await apicall("landing-page-manage/projectcampaignaccess", requestOptions);

    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);



export const getconversioncountontime = createAsyncThunk(
  "getconversioncountontime",
  async (bodyc) => {
    let body = { ...bodyc }


    // console.log("req_body==", body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",
    //   requestOptions
    // );
    const response = await apicall("campaign-manage/campaignaddedbycount", requestOptions);

    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);
export const getpiechartdata = createAsyncThunk(
  "getpiechartdata",
  async (bodyc) => {
    let body = { ...bodyc }


    // console.log("req_body==", body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    // console.log("process.env==>", process.env);
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",
    //   requestOptions
    // );
    const response = await apicall("campaign-manage/paichardata", requestOptions);

    const respdata = response;
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);


const DashboardReducer = createSlice({
  name: "DashboardReducer",
  initialState: {
    mindate: moment().subtract(7, "days").valueOf(),
    maxDate: moment().valueOf(),

    submitting: false,
    loading: false,
    loaderForHmData: false,
    hmChartData: [],
    hmGridData: [],
    hmCardData: [],
    hmListData: null,

    adminChartData: [],
    adminGridData: [],
    adminCardData: [],
    adminListData: null,
    loaderForAdminData: false,
    sucessStateForGridList: 0,
    DNotesData: [],

    getlandingpage: [],
    useruniqurlhas: {},
    conversioncount: null,
    conversioncounttime: null,
    paichartdata: [],
    paichartdataforconviesion: [],
    paichartdataforclick: [],
    piechartloader: false,
    campaginaccessdata: {},

  },
  reducers: {
    clearHmDashboardData(state, action) {
      // state.hmChartData = []
      state.hmGridData = [];
      // state.hmCardData = []
      state.hmListData = null;
    },

    clearSucessStateForGridList(state, action) {
      state.sucessStateForGridList = 0

    },

    clearAdminDashboardData(state, action) {
      state.adminChartData = [];
      state.adminGridData = [];
      state.adminCardData = [];
      state.adminListData = null;
    },
    clearAdminDashboardUserListData(state, action) {
      state.adminChartData = [];
      state.adminGridData = [];
      // state.adminCardData = []
      state.adminListData = null;
    },
    clearAdminGridData(state, action) {
      state.adminGridData = [];
    },
    setMinMaxTime(state, action) {
      let fullday = 24 * 60 * 60 * 1000;
      let halfDay = 6 * 60 * 60 * 1000


      state.mindate = moment(action.payload[0]).valueOf() + halfDay;
      state.maxDate = moment(action.payload[1]).valueOf() + halfDay;
      // state.maxDate = moment(action.payload[1]).valueOf();

      // console.log("action11111      ", state.mindate, state.maxDate);
    },
    clearDnNotes(state, action) {
      state.DNotesData = [];
    },
    clearConversionCount (state, action){
      state.paichartdataforconviesion = []
    }
    // clearConversionCount (state, action) {
    //   state.conversioncount = null;
    // }
  },
  extraReducers: {

    [getcampaignaccessdata.rejected]: (state, action) => {

    },
    [getcampaignaccessdata.pending]: (state, action) => {

    },
    [getcampaignaccessdata.fulfilled]: (state, action) => {
      // console.log("action from storedata", action.payload.results.res);
      if (action.payload.status === "success") {
        if (action.payload.results.res && action.payload.results.res.length > 0){
          state.campaginaccessdata = action.payload.results.res[0];
        }
          
      }
    },

    [getpiechartdata.rejected]: (state, action) => {

    },
    [getpiechartdata.pending]: (state, action) => {
      state.piechartloader = true
    },
    [getpiechartdata.fulfilled]: (state, action) => {
      // console.log("pie data", action.payload.results.res);
      // if (action.payload.status === "success") {

      // state.paichartdata = action.payload.results.res;
      if (action.payload.results.res) {
        // console.log("HITt in ");
        if (action.payload.results.res[0].click) {
          // console.log("click in");
          state.paichartdataforclick = action.payload.results.res[0].click
        }
        if (action.payload.results.res[1].conversion) {
          // console.log("conversion in");

          state.paichartdataforconviesion = action.payload.results.res[1].conversion
        }
        state.piechartloader = false

      }
      // console.log("state.conversioncount", state.conversioncount);
      // }
    },

    [getconversioncountontime.rejected]: (state, action) => {

    },
    [getconversioncountontime.pending]: (state, action) => {

    },
    [getconversioncountontime.fulfilled]: (state, action) => {
      // console.log("action from storedata", action.payload.results.res);
      // if (action.payload.status === "success") {

      state.conversioncounttime = action.payload.results.res;
      // console.log("state.conversioncount", state.conversioncount);
      // }
    },

    [getconversioncount.rejected]: (state, action) => {

    },
    [getconversioncount.pending]: (state, action) => {

    },
    [getconversioncount.fulfilled]: (state, action) => {
      // console.log("action from storedata", action.payload.results.res);
      // if (action.payload.status === "success") {

      state.conversioncount = action.payload.results.res;
      // console.log("state.conversioncount", state.conversioncount);
      // }
    },

    [getuniqurlhs.rejected]: (state, action) => {

    },
    [getuniqurlhs.pending]: (state, action) => {

    },
    [getuniqurlhs.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {

        state.useruniqurlhas = action.payload.results.res;

      }
    },


    [getDataForHmDashboardGridList.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;
      // state.loading = false;
      state.successAction = false;
      state.loaderForHmData = false;
    },
    [getDataForHmDashboardGridList.pending]: (state, action) => {
      // console.log("getDataForHmDashboardGridList=========================>");
      state.loading = true;
      state.loaderForHmData = true;
    },
    [getDataForHmDashboardGridList.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.hmGridData = action.payload.results.grid_data;
        state.hmListData = action.payload.results.res;
        state.loaderForHmData = false;
      }
    },

    [getDataFoAdminDashboardGridList.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;
      // state.loading = false;
      state.successAction = false;
      state.loaderForAdminData = false;
      state.sucessStateForGridList = 3;
    },
    [getDataFoAdminDashboardGridList.pending]: (state, action) => {
      // console.log("getDataFoAdminDashboardGridList=========================>");
      state.loading = true;
      state.loaderForAdminData = true;
      state.sucessStateForGridList = 1;

    },
    [getDataFoAdminDashboardGridList.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.adminGridData = action.payload.results.grid_data;
        state.adminListData = action.payload.results.res;
        state.loaderForAdminData = false;
        state.sucessStateForGridList = 2;

      }
    },

    [getDataFoAdminDashboardCard.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;
      // state.loading = false;
      state.successAction = false;
      state.loaderForAdminData = false;
    },
    [getDataFoAdminDashboardCard.pending]: (state, action) => {
      // console.log("getDataFoAdminDashboardCard=========================>");
      state.loading = true;
      state.loaderForAdminData = true;
    },
    [getDataFoAdminDashboardCard.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.adminCardData = action.payload.results.card_data;
        // console.log("adminCardData", state.adminCardData);
        state.loaderForAdminData = false;
      }
    },

    [getDataFoAdminDashboardChart.rejected]: (state, action) => {
      state.successAction = false;
      state.loaderForAdminData = false;
    },
    [getDataFoAdminDashboardChart.pending]: (state, action) => {
      state.loaderForAdminData = true;
    },
    [getDataFoAdminDashboardChart.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.adminChartData = action.payload.results;

        state.loaderForAdminData = false;
      }
    },

    [getDataForHmDashboardCard.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;
      // state.loading = false;
      state.successAction = false;
      state.loaderForHmData = false;
    },
    [getDataForHmDashboardCard.pending]: (state, action) => {
      // console.log("getDataForHmDashboardCard=========================>");
      state.loading = true;
      state.loaderForHmData = true;
    },
    [getDataForHmDashboardCard.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.loading = false;

        state.hmCardData = action.payload.results.card_data;
        state.loaderForHmData = false;
      }
    },

    [getDataForHmDashboardChart.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;

      state.successAction = false;
      state.loaderForHmData = false;
    },
    [getDataForHmDashboardChart.pending]: (state, action) => {
      state.loaderForHmData = true;
    },
    [getDataForHmDashboardChart.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.hmChartData = action.payload.results;
        state.loaderForHmData = false;
      }
    },

    [getlandingpageForRep.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;
      state.successAction = false;
      state.getlandingpage = [];
    },
    [getlandingpageForRep.pending]: (state, action) => {
      state.loading = true;
      state.getlandingpage = [];
    },
    [getlandingpageForRep.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.getlandingpage = action.payload?.results?.res;
        state.loading = false;
      }
    },

    [getDataForNotes.rejected]: (state, action) => {
      state.loading = false;
      state.DNotesData = [];
    },
    [getDataForNotes.pending]: (state, action) => {
      state.loading = true;
      state.DNotesData = [];

      // console.log("dnnotes data",action);
    },
    [getDataForNotes.fulfilled]: (state, action) => {
      // console.log("dnnotes data", action);
      state.DNotesData = action.payload?.results?.res;
      state.loading = false;

    },
  },
});

export default DashboardReducer.reducer;
export const {
  clearHmDashboardData,
  clearAdminDashboardData,
  setMinMaxTime,
  clearAdminDashboardUserListData,
  clearHmGridData,
  clearDnNotes,
  clearAdminGridData,
  clearSucessStateForGridList,
  clearConversionCount,
} = DashboardReducer.actions;
