import { Chip, IconButton, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

function PreviewSubLogin(props) {

    const [subEmployeeRow, setSubEmployeeRow] = useState(null)
    const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForSubEmployerData && Object.keys(state.tableSlice.tempRowData.tableForSubEmployerData).length > 0 ? state.tableSlice.tempRowData.tableForSubEmployerData : {})


    useEffect(() => {
        if (props.previewSubEmployeeClicked && subEmployeeRowData && Object.keys(subEmployeeRowData).length > 0) {
            setSubEmployeeRow(subEmployeeRowData)

            // console.log("subEmployeeRowData========>>>>", subEmployeeRowData.location_name.map(x => x.name))
        }
    }, [props.previewSubEmployeeClicked, JSON.stringify(subEmployeeRowData)])


    const handleClosePreview = () => {
        props.setPreviewOpen(false)
        setSubEmployeeRow(null)
    }
    return (
        <>
            <div>
                <Modal
                    className="modalBaseStyle  preview_details previewSubLogin"
                    open={props.previewOpen}
                    onClose={handleClosePreview}
                >
                    <Box className="modalBaseBox mediumModal">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleClosePreview}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className="view_details">

                            <div className="modal_title modal_titlenew SD_bluecoast_preview">

                                <h2 className="previewModalTitle "> Details of {subEmployeeRow?.name}</h2>
                            </div>
                            <div className="modalview_group">
                                {subEmployeeRow ? (
                                    <div className="data_container data_containerscroll">
                                        {/* <p>{JSON.stringify(subEmployeeRow)}</p> */}
                                        <div className="data_block">
                                            {subEmployeeRow && (
                                                <div className="property_container">
                                                    <label className="property_label">Name:</label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.firstname + " " + subEmployeeRow.lastname}
                                                    </label>
                                                </div>
                                            )}
                                            {subEmployeeRow?.email && (
                                                <div className="property_container">
                                                    <label className="property_label">Email:</label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.email}
                                                    </label>
                                                </div>
                                            )}
                                            {subEmployeeRow?.phone && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Phone Number:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.phone}
                                                    </label>
                                                </div>
                                            )}

                                            {subEmployeeRow?.status && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Status:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.status}
                                                    </label>
                                                </div>
                                            )}
                                            {subEmployeeRow?.location_name && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Location:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.location_name.map(x =>
                                                            <Chip label={x.name} key={x._id}
                                                            />)}
                                                    </label>
                                                </div>
                                            )}

                                            {subEmployeeRow?.company_name && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Company Name:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.company_name}
                                                    </label>
                                                </div>
                                            )}



                                            {subEmployeeRow?.title && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Title:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.title}
                                                    </label>
                                                </div>
                                            )}


                                            {subEmployeeRow?.address && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Address:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.address}
                                                    </label>
                                                </div>
                                            )}

                                            {subEmployeeRow?.city && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        City:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.city}
                                                    </label>
                                                </div>
                                            )}

                                            {subEmployeeRow?.state && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        State:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.state}
                                                    </label>
                                                </div>
                                            )}


                                            {subEmployeeRow?.zip && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Zip:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.zip}
                                                    </label>
                                                </div>
                                            )}

                                            {/* 
                                            {subEmployeeRow?.notes && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Notes:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.notes}
                                                    </label>
                                                </div>
                                            )} */}
                                            {subEmployeeRow?.other_info && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Any other information we should know?:
                                                    </label>
                                                    <label className="property_value">
                                                        {subEmployeeRow.other_info}
                                                    </label>
                                                </div>
                                            )}
                                            {subEmployeeRow?.createdon_datetime && (
                                                <div className="property_container">
                                                    <label className="property_label">Added On:</label>
                                                    <label className="property_value">
                                                        {format(
                                                            subEmployeeRow.createdon_datetime,
                                                            "dd-MM-yyyy HH:mm"
                                                        )}
                                                    </label>
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                ) : (
                                    <div className="nodatafound">No Records Found</div>
                                )}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default PreviewSubLogin