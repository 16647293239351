import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import store from '../../../../store';
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { getjobcategorydata, cleareditjobticketsdata, getjobticketlistdata, geteditjobticketsdata } from '../../Jobtickets/JobTicketReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';

function AddEditJobtickets(props) {

  const [selecttypeArry, setselecttypeArry] = useState([]);
  const pathname = useLocation();
  const { id } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const resolved = useSelector(state => state.ResolveReducer?.resolved[`/edit-jobtickets/${id}`] ? state.ResolveReducer.resolved[`/edit-jobtickets/${id}`] : false)
  const editdata = useSelector(state => (state.JobTicketReducer?.editjobticketsdata && state.JobTicketReducer?.editjobticketsdata.length > 0) ? state.JobTicketReducer.editjobticketsdata[0] : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //
  let jobcategorydata = useSelector(state => (state.JobTicketReducer?.jobcategorydata && state.JobTicketReducer?.jobcategorydata?.length > 0) ? state.JobTicketReducer.jobcategorydata : []);
  const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.manageJobAddEditForm?.goback001 ? state.formSlice.customButtonClicked.manageJobAddEditForm.goback001 : false);
  const submitbtnclick = useSelector(state => state.formSlice.formSubmissionState.manageJobAddEditForm ? state.formSlice.formSubmissionState.manageJobAddEditForm : 0)
  const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
  const user_name = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo.firstname +' '+ state.loginSlice.userInfo.lastname : null);
  const user_email = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo.email : null);
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.manageJobAddEditForm) ? state.formSlice.formSubmissionState.manageJobAddEditForm : 0)



  useEffect(() => {
    if(submissionState===2){
      props.source !== "folderView" &&  navigate("/manage-jobtickets")
    }
  }, [submissionState])
  


  useEffect(() => {
    if (id !== undefined && id !== null && editdata !== null) {
      if (submitbtnclick == 2) {
        dispatch(setSnackbar({ open: true, message: "Update Successfully !", status: 'success' }))
      };
    }
    else {
      if (submitbtnclick == 2) {
        dispatch(setSnackbar({ open: true, message: "Add Successfully !", status: 'success' }))
      };
    }
  }, [submitbtnclick])




  useEffect(() => {
    let reqbodyforjobcategory = {
      "condition": {
        "limit": 100,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": { $or: [{ status: 1 }] },
      "project": {},
      "token": ""
    }
    store.dispatch(getjobcategorydata(reqbodyforjobcategory));

    const backBtn = {
      id: 'jobgoback001',
      label: 'Go Back',
      className: 'default',
      type: 'button',
    }




    return () => {
      store.dispatch(cleareditjobticketsdata())
      store.dispatch(resetresolvenew('/edit-jobtickets/:id'))
    }

  }, [])

  useEffect(()=>{
    if(id){ 
      dispatch(geteditjobticketsdata(id)) 
    }
  },[id])




  useEffect(() => {
    if (jobcategorydata && jobcategorydata.length > 0) {
      let arr = [];
      for (let i in jobcategorydata) {
        arr.push({ val: jobcategorydata[i]._id, name: jobcategorydata[i].category_name })
      }
      setselecttypeArry(arr)
    } else {
      let arr = [];
      arr.push({ val: '', name: 'No Active Category Found' })
      setselecttypeArry(arr)
    }
  }, [JSON.stringify(jobcategorydata)])



  useEffect(() => {
    if (goBackClicked) {

      let reqbodyforlead = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {},
        "project": {},
        "token": ""
      }

      resolvefunction(navigate, '/manage-jobtickets', getjobticketlistdata(reqbodyforlead))
    }
  }, [goBackClicked])







  const dataform = {
    id: 'manageJobAddEditForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "lead-manage/addorupdatejobticket",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    className: 'inputBlock inputBlock3line',
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {

      _id: (id !== undefined && id !== null) ? id : undefined,
      ticket_addedby: props.source === "folderView" ? props.paramId : user_id,
      hm_fullname: user_name,
      hm_email: user_email
      // tikit_adder_user_type:
    },

    fields: [
      {
        id: 0,
        heading: "Title *",
        name: "ticket_name",
        label: "Title",
        className: 'inputBlock inputBlock2line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.ticket_name!== undefined) ? editdata.ticket_name: undefined,

      },
      {
        id: 1,
        heading: "Select Category *",
        label: "Select Category",
        name: "ticket_category",
        className: ' inputBlock inputBlock2line',
        type: 'select',
        checkbox: false,
        other: false,
        rules: { required: true },
        values: selecttypeArry,
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.ticket_category !== undefined) ? editdata.ticket_category : undefined,
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 2,
        heading: "Description *",
        label: "SunEditor",
        name: "ticket_des",
        type: "sunEditor",
        className: "inputBlock1line",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },

        placeholder: "Please type here...",
        editorWidth: 300,
        buttonListOptions: [
          ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
          ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
          ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
        ],
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.ticket_des !== undefined) ? editdata.ticket_des : undefined,

      },
      // {
      //   id: 4,
      //   heading: "HM Fullname *",
      //   name: "hm_fullname",
      //   label: "HM Fullname",
      //   className: 'inputBlock inputBlock2line',
      //   type: "text",
      //   disable:true,
      //   //rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      //   defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.hm_fullname!== undefined) ? editdata.hm_fullname: user_name,

      // },
      // {
      //   id: 9,
      //   heading: "HM Email *",
      //   name: "hm_email",
      //   label: "HM Email",
      //   className: 'inputBlock inputBlock2line',
      //   type: "text",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      //   defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.hm_email!== undefined) ? editdata.hm_email: user_email,

      // },
      {
        id: 10,
        heading: "Subject *",
        name: "subject_val",
        label: "Subject",
        className: 'inputBlock inputBlock2line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.subject_val!== undefined) ? editdata.subject_val: undefined,

      },
      {
        id: 11,
        heading: "List the contact email(s) that are associated with the issue. *",
        name: "contact_emails",
        label: "",
        className: 'inputBlock inputBlock2line',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.contact_emails!== undefined) ? editdata.contact_emails: undefined,

      },
      {
        id: 12,
        heading: "Explain the issue(s) you're experiencing as detailed as possible. *",
        name: "issue_details",
        label: "",
        className: 'inputBlock inputBlock2line',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.issue_details!== undefined) ? editdata.issue_details: undefined,

      },
      {
        id: 13,
        heading: "Are you able to reproduce the issue? If so, please explain.",
        name: "reproduce_issue",
        label: "",
        className: 'inputBlock inputBlock2line',
        type: "textarea",
       // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.reproduce_issue!== undefined) ? editdata.reproduce_issue: undefined,

      },
      {
        id: 14,
        heading: "If possible provide a Loom (loom.com) video link showing the issue you're experiencing. (Optional)",
        name: "loom_video_link",
        label: "Loom video link",
        className: 'inputBlock inputBlock2line',
        type: "text",
       // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.loom_video_link!== undefined) ? editdata.loom_video_link: undefined,

      },
      {
        id: 8,
        heading: "Thumbnail",
        lable: "Add Thumbnail",
        name: "ticket_attachment",
        className: "inputBlock inputBlock1line",
        type: "fileUploader",
        multiple: false,
        accept: "image/*",//////// Write properly /////////
        bucket: "uploaded-files-react",
        path: "aspireuploadimage/",
        base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        endpoint: "requestUploadURL",
        deleteEndpoint: "deletefilefromBucket",
        defaultFileLinks: (editdata && Object.keys(editdata).length > 0 && typeof editdata?.ticket_attachment !== undefined && typeof editdata?.ticket_attachment == undefined) ? [{
          url: editdata.ticket_attachment.url,
          type: "image",
          name: editdata.ticket_attachment.file_local_name
        }] : undefined
      },


      {
        id:3,
        heading: "Priority*",
        name: "priority",
        className: 'inputBlock inputBlock2line',
        values: [{ key: 'low', val: 'Low' }, { key: 'medium', val: 'Medium' },{key: 'high', val: 'High'}],
        type: 'radio',
        hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.priority !== undefined) ? editdata.priority : undefined,
      },

      // {
      //   id: 3,
      //   heading: "Priority",
      //   label: "priority",
      //   name: "priority",
      //   className: 'inputBlock inputBlock2line',
      //   type: 'select',
      //   multiple: false,
      //   values: [
      //     { val: "high", name: "High" },
      //     { val: "medium", name: "Medium" },
      //     { val: "low", name: "Low" }
      //   ],
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     maxLength: "MaxLength length 20 allowed",
      //     validate: "Lastnameeeeeeee is not valid",
      //     custom: "Value is Invalid"
      //   },
      //   defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.priority !== undefined) ? editdata.priority : undefined,
      // }
    ],
    customButtons: [
      {
        id: 'goback001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },
    ]
  };


  // useEffect(() => {
  //   console.log("fkbfgkbgbg",selecttypeArry);
  //   if(selecttypeArry.length>0){
  //    const feild = dataform.fields.find((x)=> x.name==="ticket_category")
  //     if(feild){
  //       feild.values=selecttypeArry
  //     }
  //   }
  // }, [JSON.stringify(selecttypeArry)])
  





  return (
    <>
      <h1 className='page_heading sdheading2'> {id?"EDIT JOBTICKET":"ADD JOBTICKET"}</h1>
      <div className="dataform2_wrapper managejobticketform">
        <div className='adminformbody'>
          {id !== undefined && editdata && (editdata.length > 0 || Object.keys(editdata).length > 0) ? <> <Form formData={dataform} /> </> : !id && <Form formData={dataform} />}
        </div>
      </div>
    </>
  )
}

export default AddEditJobtickets
