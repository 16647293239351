import React, { useEffect, useRef, useState } from 'react'
import "../LandingPage19.css";
import { Box } from "@mui/system";
import { Button, IconButton, Modal, TextField, Tooltip } from "@mui/material";
import ReactPlayer from "react-player";
import LandingPage19formComponent from "./LandingPage19formComponent";
import CloseIcon from '@mui/icons-material/Close';
// import ListingTable from '../../../../listing/listing';
// import TableSkeleton from '../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { TableSkeleton } from "../../../../../helper/TableSkeleton";
import ListingTable from "../../../../../listing/listing";
import { MedicineListing } from '../../../../Backend/Medicine/MedicineReducer';




export default function Landingpage19Banner({ scrollToview }) {

  const [openMoadl2, setopenMoadl2] = useState(false)
  const dispatch = useDispatch();


  const [open, setOpen] = React.useState(false);
  const [openSearch, SetopenSearch] = React.useState(false);
  // const scrollToview = useRef();
  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };

  const medcat = useSelector((state) => (state.medicineSlice?.medicinedata && state.medicineSlice?.medicinedata.length > 0) ? state.medicineSlice.medicinedata : [])
  const loadingInMedCat = useSelector(state => state.medicineSlice?.loading ? state.medicineSlice.loading : false);


  const handleClose = () => setOpen(false);

  const openModal = () => {
    setopenMoadl2(true);
  };
  const closeModal = () => {
    setopenMoadl2(false);
  };
  var modifyTableHeadersforMedCatlist =
    [

      { val: "name", name: "Medicine Name" },
      { val: "med_by_catagorey", name: "Medicine Category" },



    ];

  let tableDataForMedCatlist = useRef({
    tableId: "medcatlist",
    tableTitle: "Medicine List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {

      // "source": "training_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["name", "med_by_catagorey"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "alldruglist",
        type: "format_list_bulleted",
        title: "All Drug List",
        className: "format_list_bulleted",
        default: true,
      },
    ],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      // heading: "Search Medicine & Category List",
      //   type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchMedicineandCategoryList',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },

          },



        ]
      }
    },

  });
  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody));
  }, [])



  return (
    <>
      <div className="lp19_banner">
        <div className="lp19_banner_BGimage">
          <div className="common_width">
            <div className="lp19_banner_Main">
              <div className="lp19_banner_logo_headding">
                <div className="lp19_banner_logo">
                  <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/banner/LP19_bannerAccesslogo.png`}
                  // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/LP19_bannerAccesslogo.png"
                  />
                </div>
                <div className="lp19_banner_headdingText">
                  <h1>
                    {/* Over a <span> million people</span> ARE USING OUR ONLINE
                    SUBSCRIPTION PHARMACY AND SAVING MONTHLY.{" "} */}

                    OVER A <span>MILLION PEOPLE</span> ARE USING OUR ONLINE SUBSCRIPTION PHARMACY TO GET ALL THEIR PRESCRIPTIONS FOR FREE.
                  </h1>
                </div>
              </div>
              <div className="lp19_banner_Body">
                <div className="lp19_banner_text_video">
                  <div className="lp19_banner_Text">
                    {/* <p>
                      Are you paying more than <span> $21.99 for monthly</span>{" "}
                      prescription medications for yourself or more than{" "}
                      <span> $31.99</span> for your family? If your answer is
                      yes, you are paying too much!
                    </p> */}

                    <p>For the <span> FIRST TIME</span>, you can pay as low as <span>$21.99</span> for ALL your Monthly Prescription Medication, saving YOU hundreds of dollars monthly and thousands of dollars annually. </p>
                    <p>Whether you take one or multiple medications you can get all of them for as low as <span>$21.99</span> a month by subscribing to our Free Pharmacy Program. </p>
                  </div>
                  <div className="lp19_banner_Video">
                    {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/videoThumbnailLP19.webp" /> */}
                    <ReactPlayer
                      className="page5_react_playerLP19"
                      // url="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/AspirePharma_banner_video.mp4"
                      url={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/banner/Free_Access_PharmacyVideoLP19.mp4`}
                      // light={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/banner/videoThumbnailLP19.webp`}
                      light="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/landingpage19_banner_thumbnail.webp"
                      playing={true}
                      controls={true}
                    />
                  </div>
                </div>
                <div className="lp19_banner_Form">
                  <div className="lp19_banner_Form_sub">
                    <div className="lp19_banner_Form_sub_top_header">
                      {/* <h1>
                        COMPLETE <br /> THE FORM BELOW TO ENROLL <br /> AND START SAVING TODAY
                      </h1> */}

                      {/* <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      className="LP19_formSection"
                    >
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Phone"
                        variant="outlined"
                      />
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                      />
                      <TextField
                        id="outlined-basic"
                        label="State"
                        variant="outlined"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Zip"
                        variant="outlined"
                      />
                    </Box>
                    <Button variant="contained">Submit</Button> */}
                      <div className="lpage19_form_wrpper" ref={scrollToview}>
                        <LandingPage19formComponent />
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>



      <div>
        <Modal
          className="modalBaseStyle allMeetingMOdal  MOdallanding13 SD_MOdallanding SR_ClassModal lpage19_modal"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={handleClose}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <div className="rolelist_mainwrp">

                <div className='listing_heading'>

                  <h1>Access Free Pharmacy Medicine</h1>

                </div>




                {/* <Paper>
  <Button variant='contained' className='addrole_btn' ></Button>
</Paper> */}
                <div className='rolelist_mainwrp_Table'>
                  {!loadingInMedCat ? (
                    <ListingTable
                      tableData={tableDataForMedCatlist.current}
                      dataset={medcat}
                      modifyHeaders={modifyTableHeadersforMedCatlist}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}

                </div>
              </div>
            </div>

          </Box>
        </Modal>
      </div>






    </>
  );
}
