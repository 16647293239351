import { Outlet, useMatch,useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { withCookies, useCookies } from "react-cookie";

import { Alert, IconButton,Snackbar } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Footer from "./Pages/Layout/Footer.js";
import BackendHeader from "./Pages/Layout/BackendHeader.js";
import debounce from 'lodash.debounce';
import Frontendfooter from "./Pages/Layout/Frontendfooter.js";
import { setSnackbar } from "./Pages/Layout/layoutReducer.js";
import { Close } from "@mui/icons-material";
let storedata = null;

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation();
  const location = useLocation();
  const calendarmatch = useMatch("/calendar/:lead_id/:orgrole/:orgid");
  const calendarReschedulematch = useMatch("/reschedule-slot/:identifier/:orgrole/:orgid");
  const thankyoumatch = useMatch("/thankyou/:page/:lead_id");
  const thankyoumatchnew = useMatch("/thankyou/:leadid");
  const thankyoubluecoastmatchnew = useMatch("/thankyousubmission/:leadid");
  const thankyouaccessmatchnew = useMatch("/thankyouaccess/:leadid");
  const bluecoastrepmatch = useMatch("/accessconfirmation/:repid");
  const aspireconfirmationrepmatch = useMatch("/accessconfirmation/:repid");
  const cancelslotmatch = useMatch("/cancel-slot/:identifier");
  const employerMatch = useMatch("/employers-signup/:_id")
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);


  // ------ Loading during any action -----
  const userinfo = useSelector(state => (state.fetchUserDataReducer?.userInfo) ? state.fetchUserDataReducer.userInfo: {});
  const open = useSelector((state) =>state.layoutSlice.open ? state.layoutSlice.open : false);
  const message = useSelector((state) =>state.layoutSlice.message ? state.layoutSlice.message : "");
  const status = useSelector((state) =>state.layoutSlice.status ? state.layoutSlice.status : "success");



  useEffect(() => {
    setTimeout(() => {
      if (open === true) dispatch(setSnackbar({ open: false, message: "", }));
    }, 10000);
  }, [open]);





  useEffect(() => {

    if (Object.keys(userinfo)?.length > 0) {
      setuserInfoCookie(
        "userinfo",
        JSON.stringify(userinfo),
        { path: "/" }
      );
    }
    
  }, [userinfo])



  const [scroll, setScroll] = useState(null);
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);


  const debouncedSetFormData = useCallback(debounce((value) => {
    setScroll(value > 20)
  }, 200), []);



  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      debouncedSetFormData(currentScrollY)
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);





  const { uniquename } = useParams();
  const { banner } = useParams();
  const { username } = useParams();
  const { uuid } = useParams();

  const {mlmoneid} = useParams();
  const {priority} = useParams();  


  useEffect(() => {

    if(location.search.includes('leadid')){
      window.sessionStorage.setItem("previus_url",`${location.pathname}${location.search}`)
    }

  }, [location]);



  return (
    <>

      <div className={(!location.pathname.includes("/") || !location.pathname.includes("/home")) && !thankyoumatch && !cancelslotmatch && !employerMatch && scroll ? "showTop" : "showTop"}>

        <div className="OutletBody" >
          <div className={location.pathname == "/" || location.pathname == '/' + uniquename ||uuid||mlmoneid|| priority || banner || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page9' || location.pathname == '/landing-page10' || location.pathname == '/landing-page11' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || location.pathname == '/landing-page13' || location.pathname == '/landing-page14' || location.pathname == '/landing-page15' || location.pathname == '/landing-page17' || location.pathname == '/landing-page19' || location.pathname == '/landing-page20' || location.pathname == '/medication-enrollment' || location.pathname == '/nakagames'|| location.pathname == '/mlm' || location.pathname == '/nakagames/'+username || calendarmatch || calendarReschedulematch || thankyoumatch || thankyoubluecoastmatchnew || thankyouaccessmatchnew || bluecoastrepmatch || aspireconfirmationrepmatch || cancelslotmatch || location.pathname == '/thankyou' || employerMatch || location.pathname == "/error-page" || location.pathname == "/404page" || location.pathname == "/otp-verify" || location.pathname == "/no-link" ? "" : "BackendBody"}>
            {location.pathname == "/" || location.pathname == '/home' || location.pathname == '/' + uniquename ||uuid||mlmoneid|| priority|| banner || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page9' || location.pathname == '/landing-page10' || location.pathname == '/landing-page11' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || location.pathname == '/landing-page13' || location.pathname == '/landing-page14' || location.pathname == '/landing-page15' || location.pathname == '/landing-page17' || location.pathname == '/landing-page19'|| location.pathname == '/landing-page20' || location.pathname == '/medication-enrollment' || location.pathname == '/nakagames'|| location.pathname == '/mlm' || location.pathname == '/nakagames/'+username || calendarmatch || calendarReschedulematch || thankyoumatch || thankyoumatchnew || thankyoubluecoastmatchnew || thankyouaccessmatchnew || bluecoastrepmatch || aspireconfirmationrepmatch || cancelslotmatch || location.pathname == '/thankyou' || employerMatch || location.pathname == "/error-page" || location.pathname == "/404page" || location.pathname == "/otp-verify" || location.pathname.includes('interview-contract') || location.pathname == "/no-link" ? null : <div className="BackendHeader"><BackendHeader /></div>}
            <div className={location.pathname == "/" || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page9' || location.pathname == '/landing-page10' || location.pathname == '/landing-page11' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || location.pathname == '/landing-page13' || location.pathname == '/landing-page14' || location.pathname == '/landing-page15' || location.pathname == '/landing-page19'|| location.pathname == '/landing-page20' || location.pathname == '/medication-enrollment' || location.pathname == '/nakagames' || location.pathname == '/nakagames/'+username|| location.pathname == '/mlm' || calendarmatch || calendarReschedulematch || thankyoumatch || thankyoumatchnew || thankyoubluecoastmatchnew || thankyouaccessmatchnew || bluecoastrepmatch || aspireconfirmationrepmatch || cancelslotmatch || location.pathname == '/thankyou' || employerMatch || location.pathname == "/error-page" || location.pathname.includes('interview-contract') || location.pathname == "/404page" || location.pathname == "/otp-verify" || location.pathname == "/no-link"  ? "" : "BackendOutlet"} >
              <Outlet />
              {location.pathname == "/" || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page9' || location.pathname == '/landing-page10' || location.pathname == '/landing-page11' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || location.pathname == '/landing-page13' || location.pathname == '/landing-page14' || location.pathname == '/landing-page15' || location.pathname == '/landing-page19'|| location.pathname == '/landing-page20' || location.pathname == '/medication-enrollment' || location.pathname == '/nakagames' || location.pathname == '/nakagames/'+username|| location.pathname == '/mlm' || location.pathname == '/thankyou' || uniquename ||uuid||mlmoneid|| priority|| banner || aspireconfirmationrepmatch || bluecoastrepmatch || employerMatch || location.pathname == "/error-page" || location.pathname == "/404page" || location.pathname == "/otp-verify" || location.pathname.includes('interview-contract') || location.pathname == "/no-link" ? null : <Footer />}
              {(location.pathname == "/" && !process.env.REACT_APP_IS_BACKEND) || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page9' || location.pathname == '/landing-page10' || location.pathname == '/landing-page11' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video'  ? <Frontendfooter /> : null}

            </div>

          </div>
        </div>

      </div>
      <Snackbar
        className="form_error_snackbar"
        open={open}
        autoHideDuration={10000}
        onClose={() => dispatch(setSnackbar({ open: false, message: "" }))}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() =>
              dispatch(setSnackbar({ open: false, message: "" }))
            }
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={() => dispatch(setSnackbar({ open: false, message: "" }))} severity={(status == 'success' || status == 'Success') ? "success" : (status == 'error' || status == 'Error') ? "error" : status == 'info' ? 'info' : 'success'} >{message} </Alert>

      </Snackbar>

    </>
  );
};

export default withCookies(Layout);
