import React from 'react'
import { useParams } from 'react-router-dom';
import LandingPage16Form from './LandingPage16Form'

function LandingPage16() {

 



    return (

        <div className='LandingPage9Body LandingBlCst_New'>
          <div className='LandingPage9Body_wrapper'>
            <div className='LandingPage9Body_Top'>
              <div className='LandingPage9Body_TopLeft'> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt=''/> </div>
              <div className='LandingPage9Body_TopRight'>
              <h2><span>Sign up and get access to landing pages to start </span></h2> 
              <h2><span>  sharing the Access Health Discount Pharmacy Program</span></h2>
                {/* <h2><span>SIGN UP FOR AFFILIATE </span></h2>
                <h2><span>(AFFILIATE SIGNUP) </span></h2> */}
{/* 
                <h3><span></span> <strong>right away!</strong></h3> */}
              </div>
            </div>
    
            <div className='LandingPage9Body_Formwrapper LandingPage10Body'>
              <div className='LandingPage9Body_Formwrapper_con LandingPage11Body_sub'>
                <div className='LandingPage9FormCon LandingPage11Body_subCon'>
                  <LandingPage16Form />
                </div>
              </div>
            </div>
    
            <div className='LandingPage9BottomLogo'>
              <span> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img></span>
            </div>
    
            {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_img1.webp' className='landingpage9_img1'></img> */}
          </div>
    
    
        </div>
      )
}

export default LandingPage16