import React, { useEffect, useRef, useState } from 'react'
import { Form } from '../../../../form/Form';
import StateArray from '../../../../assets/json/state';
import store from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { addNewRepUserData, addconverstiondata, addlandingpageonedata, addlandingpageupdatesameemaildata, clearaddedinfo, fetchbluecoasteasplead, getlandingpagedata, getlandingpageoneuserdata, updateStages } from '../LandingpageReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Alert, Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip, Typography } from '@mui/material';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { Close } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';



function LandingPage10Form() {

  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { uniquename } = useParams();
  const [dataCopy, setDataCopy] = useState({});
  const [snackopen, setSnackopen] = useState(false);
  const [msg, setMsg] = useState('');
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  let location = useLocation();

  

  let reqbodyforjobcategory = {}
  if (typeof uniquename != 'undefined') {
    reqbodyforjobcategory = {
      "uniquename": uniquename,
    }
  }
  else {
    reqbodyforjobcategory = {
      "page_slug": location?.pathname == '/bluecoast' ? "gc7MqHWxe7HIW" : location?.pathname == '/aspire' ? "NycjyqRnjFUEd" : process.env.REACT_APP_LANDINGPAGE_SLUG,
    }
    // console.warn("process.env>>>>>>>", process.env);
  }


  const [conversionapicall, setConversionapicall] = useState(false);

  const formInputData = useSelector((state) =>
    state.formSlice.formData.userForm10 ? state.formSlice.formData.userForm10 : null
  );

  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});


  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userForm10) ? state.formSlice?.formSubmissionState?.userForm10 : 0)
  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
  const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});
  const landingPageSubmissionState = useSelector((state) => state.LandingpagefontReducer?.blueaspadddata ? state.LandingpagefontReducer.blueaspadddata : 0)
  const dataaddedinfo = useSelector((state) => state.LandingpagefontReducer?.addedinfo ? state.LandingpagefontReducer.addedinfo : null)
  const [locationArrys, setLocationArrys] = useState([]);
  // --- setting state array 
  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      console.log("Newarr+", Newarr);
      setLocationArrys(Newarr);
    }
  }, []);


  useEffect(() => {
    if (userData && ipinfo) {
      console.log("userData", userData);
      let body = {
        // "campaign_id":userData.campaign_id,
        "associated_program": userData.associated_program,
        "landing_page_id": userData.landing_page_id,
        "landing_page_slug": userData.landing_page_slug,
        // "campaign_added_by":userData.user_id,
        "hiring_manager": userData.hm_id,
        "ipInfo": ipinfo
      }
      if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
      if (userData.user_id) body['campaign_added_by'] = userData.user_id;
      if (userData.hm_id) body['hiring_manager'] = userData.hm_id;

      console.log("+++++++++++++++++++", body);
      if (body && body.landing_page_id && !conversionapicall) {
        dispatch(addconverstiondata(body))
        setConversionapicall(true)

      }

      //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
      //    console.log("bodyc",bodyc);
    }

  }, [JSON.stringify(ipinfo), JSON.stringify(userData)])

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);


  useEffect(() => {
    console.log("landingPageSubmissionState", landingPageSubmissionState);
    return
    if (landingPageSubmissionState && landingPageSubmissionState === 2) {
      console.log("dataaddedinfo", dataaddedinfo);
      dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))
      resolvefunction(navigate, `/bluecoastrep/${dataaddedinfo._id}`, fetchbluecoasteasplead(dataaddedinfo._id))
      // navigate(`/thankyou/bluecoastasphire`);
    }
  }, [landingPageSubmissionState])

  // console.warn("landingPageSubmissionState123456", landingPageSubmissionState)

  useEffect(() => {
    if (landingPageSubmissionState && landingPageSubmissionState == 2) {
      setDataCopy(addedData)
      return
      const data = {
        stage_slug: '5QH6nS8FXNawY',
        added_by: 'System',
        added_for: addedData._id,
        is_delete: 0,
        status: 1
      }
      console.log("data===========================>", data)
      // return
      dispatch(updateStages(data));


      // const tagdata = {
      //     added_by: addedData._id,
      //     added_for: addedData._id,
      //     key: "Wnv14tRS9FlmV",
      //     label: "hm-policiesandcompliance-signed-success",
      //     is_delete: 0,
      //     status: 1
      // }
      // console.log("tagdata===========================>", tagdata)
      // // return
      // dispatch(updateTags(tagdata));


    }


  }, [landingPageSubmissionState])



  useEffect(() => {
    if (uniquename) {
      store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
    }
    else {
      store.dispatch(getlandingpagedata(reqbodyforjobcategory));
    }
  }, [])

  /*******************************Same Email logIn********************* */
  const [submittedData, setSubmittedData] = useState(null)

  const userUpdateFunc = async () => {
    console.log("submittedData", submittedData);
    let dataSet = {
      "firstname": submittedData.firstname,
      "lastname": submittedData.lastname,
      "email": submittedData.email,
      "phone": submittedData.phone,
      "address": submittedData.address,
      "city": submittedData.city,
      "state": submittedData.state,
      "zip": submittedData.zip,
      "password": submittedData.password,
      "added_type": 'landingpage',
     

    }
    setloading(true)
    await dispatch(addlandingpageupdatesameemaildata(dataSet))

    setloading(false)
    navigate("/login")

    setTimeout(() => { setSubmittedData(null) }, 1000)
  }

  const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val }
    setSubmittedData(val)
    let body = {
      "firstname": val.firstname,
      "lastname": val.lastname,
      "email": val.email,
      "phone": val.phone,
      "hiring_manager": val.hm_id,
      // "facilitytype": val.facility_type,
      "other_info": val.other_info,
      "createdon_datetime": new Date().getTime(),
      "added_type": 'landingpage',
      "address": val.address,
      "city": val.city,
      "state": val.state,
      "zip": val.zip,
      "status": 1,
      "roles": { is_rep: 1 },
      'rep_with_new_dashboard': true,
      "associated_program": ['63cf8c25ae5ab31db330fe10'],
      "password": val.password,
      "confirm_password": val.confirmPassword,
      "page_id": userData.landing_page_id,
      "page_slug": userData.landing_page_slug,
      "ipinfo": ipinfo,
      "signup_form": "aspire" ,
      // "campaign_access":1,
 

    }

    if (Object.keys(body).length > 0) {
      console.log("body-----", body);
      // return
      // setLoader(true)
      setloading(true)

      let dataresponse = await store.dispatch(addNewRepUserData(body));
      console.log("dataresponse", dataresponse);
      setloading(false)
      if (dataresponse && dataresponse.payload && dataresponse.payload.status == 'error') {
        console.log("HITTTTT", dataresponse.payload.message);
        if (dataresponse.payload.userexists) {
          setMessageModalOpen(true)
        } else {
          setSnackopen(true)
          setMsg(dataresponse.payload.message)
        }

        // if (landingPageSubmissionState && landingPageSubmissionState === 2) {

        // dispatch(setSnackbar({ open: true, message: "jiiii", status: 'error' }))
      } else {

        redirect(dataresponse.payload.results.res._id)
        // console.log("dataaddedinfo",dataaddedinfo);

        // navigate(`/thankyou/bluecoastasphire`);
        // }
      }

      //   setActive(false)
      //  setLoader(false)


    }
  };
  const redirect = (id) => {
    setSubmittedData(null)
    store.dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))
    resolvefunction(navigate, `/accessconfirmation/${id ? id : dataaddedinfo._id}`, fetchbluecoasteasplead(id ? id : dataaddedinfo._id))
    const data = {
      stage_slug: '5QH6nS8FXNawY',
      added_by: 'System',
      added_for: id ? id : dataaddedinfo._id,
      is_delete: 0,
      status: 1
    }
    console.log("data===========================>", data)
    // return
    dispatch(updateStages(data));
    dispatch(clearaddedinfo())
  }



  const formDataL10 = {
    id: 'userForm10',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    // msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {

      "signup_form":"aspire",
      "status": 0,
      // "roles": { is_rep: 1 },

    },

    //////////////////////////////////// Text Input ///////////////////////////////

    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "firstname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
    
      {
        id: 15,
        heading: "Your Last Name",
        label: "Last Name",
        name: "lastname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 13,
        heading: "Your Email",
        label: "Your Email",
        name: "email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 14,
        heading: "Your Phone Number",
        // label: "Same as you used for Aspire Partners [ex-(515) 445-4545])",
        label: "Phone Number Format: (123) 456-7890",
        name: "phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true, pattern: /^[^@#%&$]*$/ },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
      },
      //   {
      //     id: 25,
      //     heading: "SELECT HM ",
      //     label: "SELECT HM ",
      //     name: "hm_id",
      //     className: 'inputBlock usertypebox inputBlock4line',
      //     type: "select",
      //     other: true,
      //     values: [],
      //     // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',

      //     // inputType: "number",/////////////// If not mentioned default will be text //////////////
      //     // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      //     rules: { required: true },
      //     errorMessage: {
      //       required: "This Field is Required",
      //       custom: "Value is Invalid"
      //     },

      //   },
      //   {
      //     id: 25,
      //     heading: " Hiring Manager ",
      //     label: "Hiring Manager",
      //     name: "hm_id",
      //     type: "autoComplete",
      //     className: 'list_search_item hmSelection formfild6 ',
      //     multiple: false,
      //     base_url: process.env.REACT_APP_API_URL + "users/autocomplete-hm-data",
      //     reqBody: {
      //       "formId": 'searchtestimonial',
      //       "condition": {
      //         "limit": 10,
      //         "skip": 0
      //       },
      //       "sort": {
      //         "type": "asc",
      //         "field": "label"
      //     },
      //       "searchcondition": {
      //         "source": 'old_system',
      //         "main_access": 0,
      //         'roles.hr_manager': 1
      //       },

      //     },
      //     sx: { m: 1, minWidth: 300 },
      // //    defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.slug_variable !== undefined) ? editdata.slug_variable : undefined,
      //   rules: { required: true },
      //   errorMessage: {
      //   required: "This Field is Required",
      //   // custom: "Value is Invalid"
      // }

      //   },
      {
        id: 26,
        heading: "Address",
        placeholder: 'Address',
        label: "Address",
        name: "address",
        className: 'formfild textareafild',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          // custom: "Value is Invalid"
        }

      },
      {
        id: 6,
        heading: "State",
        label: "Select a State",
        name: "state",
        className: 'list_search_item formfild6',
        type: "select",
        // other: true,
        values: locationArrys,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // rules: { required: true },
        // errorMessage: {
        //     required: "This Field is Required",
        //     // custom: "Value is Invalid"
        // },
        defaultValue: "",
      },




      //   {
      //     id: 25,
      //     heading: "SELECT HM ",
      //     label: "SELECT HM ",
      //     name: "hm_id",
      //     className: 'inputBlock usertypebox inputBlock4line',
      //     type: "select",
      //     other: true,
      //     values: [],
      //     // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',

      //     // inputType: "number",/////////////// If not mentioned default will be text //////////////
      //     // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      //     rules: { required: true },
      //     errorMessage: {
      //       required: "This Field is Required",
      //       custom: "Value is Invalid"
      //     },

      //   },




      {
        id: 7,
        heading: "City",
        label: "City",
        name: "city",
        className: 'inputBlock formfild6',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.city !== undefined) ? folderdata.city : '',

        // inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   // custom: "Value is Invalid"
        // },

      },

      {
        id: 8,
        heading: "Zip Codeee",
        label: "Zip Code",
        name: "zip",
        className: 'inputBlock formfild6',
        type: "text",
        // inputType:"number",
        inputType: "zip",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
    

      },

      {
        id: 11,
        heading: "Password",
        label: "Password",
        name: "password",
        className: 'inputBlock formfild6 passwordinput',
        type: 'password',
        defaultValue: '',
        // rules: { required: true, pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
        rules: { required: true,maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },

        errorMessage: {
          required: "This Field is Required",
          // errorMessageConfirmPassword: "Password does not match",         
          custom: "Value is Invalid",
          validate: "Password does not match",
        maxLength: "Maximum allowed input length is 15 characters",

          pattern: "You need to provide a strong password",
        },
      },

      {
        id: 12,
        heading: "Confirm Password",
        label: "Confirm Password",
        name: "confirmPassword",
        className: 'inputBlock formfild6 passwordinput',
        type: 'confirm_password',
        defaultValue: '',
        rules: { required: true, maxLength: 15,pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
        errorMessage: {
          required: "This Field is Required",
          validate: "Password does not match",
          errorMessageConfirmPassword: "Password does not match",
          
        maxLength: "Maximum allowed input length is 15 characters",

          pattern: "You need to provide a strong password",
          custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
        },
      },
      {
        id: 22,
        type: "html",
        className: "passwordCls inputBlock1line ",
        value: "<p>* Password should contain at least 8 characters and max 15 characters with one numeric digit, one uppercase letter, one lowercase letter, and a special character.</p>"
      },
      // {
      //   id: 20,
      //   heading: "Any other information we should know?",
      //   placeholder: 'Any other information we should know?',
      //   label: "Any other information we should know?",
      //   name: "other_info",
      //   className: 'formfild textareafild',
      //   type: "textarea",
      //   // rules: { required: true },
      //   // errorMessage: {
      //   //   required: "This Field is Required",
      //   //   custom: "Value is Invalid"
      //   // }

      // },

      {
        id: 125,
        // heading: "Captcha",
        // label: "Captcha",
        name: "captcha",
        className: 'formfild formfild4',
        type: "captcha",
        rules: { required: true },
        errorMessage: {
          required: "Prove, You're Not a Robot",
          custom: "Value is Invalid"
        },
      },


    ]

  };
  return (
    <>
      <div>
        <Form formData={formDataL10} />
        {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}

        <Snackbar
          className="form_error_snackbar"
          open={snackopen}
          autoHideDuration={6000}
          onClose={() => setSnackopen(false)}
          // message={message}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() =>
                setSnackopen(false)
              }
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert onClose={() => setSnackopen(false)} severity={'error'} >{msg} </Alert>

        </Snackbar>
      </div>

      
      <Modal
        className="modalblock emailExistAlert_modal"
        open={messageModalOpen}
        onClose={() => setMessageModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="BoxMui_modal" sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          // p: 4,
          textAlign: "center",
        }}>
          <div>
            <h1 className="modalTopTitle modal_title"><span>Alert!</span>  <span>User With Corresponding Email Already Exists.</span></h1>
            {/* <h2 className="modalAlertTitle">
              User Name With Corresponding Email Already Exists.
            </h2> */}
            <h3 className="modalAlertTitle">
              Do you want to continue with current given data?
            </h3>
          </div>

          <Button variant="contained" onClick={() => userUpdateFunc()} className="modalBTN">Confirm & Go To Log In</Button>
          <Button variant="contained" onClick={() => setMessageModalOpen(false)} className="modalBTN">Cancel</Button>



          <Button onClick={() => setMessageModalOpen(false)} className="closeModalBtn">

            <Tooltip title="Close" placement="right-start">

              <CloseIcon />
            </Tooltip>

          </Button>
          {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}
        </Box>
      </Modal>
    </>
  )
}

export default LandingPage10Form