import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";
import "./../../../assets/List.css";
import Badge from '@mui/material/Badge';

import store from "../../../store";
import {
  clearleaddata,
  getleaddata,
  getleadeditdata,
  clearfatchViewData,
  fatchViewData,
  FetchStages,
  FetchTagDetails,
  followUpCallList,
  getDatafollowUpCallList,
  tempRowId,
  setTempRowId,
  setStatesBlank,
  ShowStageList,
  getLeadTags,
  gettagalldata,
  getcalenderinvitationlink,
  clearinvationdata,
  getconversiondone,
  clearconversiondata,
  getNotesleaddata,
  cleargetNotesleaddata,
  clearEmailSendStatus,
  autoCompleteBddData,
  autoCompleteHMData,
  leadHmBddData,
  cleartaglifecyclestatus
} from "./LeadReducer";
import { resolvefunction } from "../../../helper/helperFunctions";
import { resetresolvenew } from "../../../helper/ResolveReducer";
import { fetchUserData } from "../userManagement/fetchUserDataReducer";
import PopupModal from "../../../commoncomponents/modal/popupmodal";

import AddIcon from "@mui/icons-material/Add";

import { IconButton, LinearProgress, Modal, Tooltip } from "@mui/material";
import { Box, display } from "@mui/system";
import { Cookies, useCookies } from "react-cookie";
import LeadStageModal from "./LeadStageModal";
import LeadTagModal from "./LeadTagModal";
import FollowUpcall from "./FollowUpcall";
import { setSnackbar } from "../../Layout/layoutReducer";
import EmailSendDrower from "./EmailSendDrower";
import {
  clearEmailTemplatedata,
  getEmailTeamplatedata,
} from "../EmailTemplateManagement/EmailTemplateReducer";
import {
  clearRemasaage,
  fetchBDDHmInfo,
  fetchContract,
  fetchContractTemplate,
} from "../userManagement/userList/userListReducer";
import ContractViewModal from "../userManagement/userList/contractViewModal";
import ContactDrawer from "../userManagement/userList/sendcontractDrawer";
import ShowStageListModal from "./ShowStageListModal";
import ShowTagListModal from "./ShowTagListModal";

import { setEnableRowLoader, setReloadTableFlag } from "../../../listing/listReducer";
import { folderViewVisitLog } from "../userManagement/folderView/folderViewReducer";
import SnackBarComp from "../../../commoncomponents/snackbar/snackBar";
import { clearinformationlistdata, getInformationListData } from "../InformationlinkModule/InformationReducer";
import InformationSentOption from "./InformationSentOption";
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns'
import { setIpinfo } from "../../login/loginReducer";
import AssignBddHm from "../userManagement/userList/assign_BDD_HM";
import DNotes from "../Dashboard/DNotes";
import { clearDnNotes, getDataForNotes } from "../Dashboard/DashboardReducer";
import ConfigureTagsModal from "../userManagement/userList/configureTagsModal";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AssinProgramModal from "../userManagement/userList/AssinProgramModal";
import AssociateBbdHmInfoModal from "../userManagement/userList/associate_bbd_hm_info_modal";
import { setField } from "../../../form/formReducer";

function LeadList(props) {


  let token = useSelector((state) => state.loginSlice.token);

  
  const [listRowData,setListRowData]=useState({})
  const [openMoadlforNotesUsertype, setOpenMoadlforNotesUsertype] = useState(null);

  // -------------------------------------------------------------Invoking Hooks------------------------------------------------------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies().getAll();
  const { role, id } = useParams();
  //tableSlice.tempRowData.leaddata._id
  console.warn("cookies from lead listing ====>>>", cookies)
  const [snackFire, setSnackFire] = useState(false);
  const [resMessage, setResMessage] = useState(null);
  const [endpointResStatus, setEndpointResStatus] = useState(null); //--- resMessage  ----//
  



  const leadListRowData = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : {});
  console.log("leadListRowData", leadListRowData);

  useEffect(() => {
    if (Object.keys(leadListRowData).length > 0) {
      setListRowData(leadListRowData)
    }
  }, [leadListRowData])
  // ---------------------------------------------------------Global State from Redux--------------------------------------------------------- //
  const formsubmissionstate = useSelector((state) => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);
  const successMsgFollowUpCall = useSelector((state) => state.formSlice.message?.followUpcallForm); // for snackbar massge
  const successStatusFollowUpCall = useSelector((state) => state.formSlice.status?.followUpcallForm);
  const endpointLoading = useSelector((state) => state.userManagment?.loading ? state.userManagment.loading : false); // ------ during endpoint hit loading status -----
  const endpointLoader = useSelector((state) => state.fetchUserDataReducer?.loading ? state.fetchUserDataReducer.loading : false); // ------ during endpoint hit loading status -----
  const notesForId = useSelector((state) => state.tableSlice?.tempRowData?.userTable?._id);
  const loggedUserId = useSelector((state) => state.loginSlice.userInfo._id);
  const goBackClickedForFollowUpCall = useSelector((state) => state.formSlice?.customButtonClicked?.followUpcallForm?.goback001);
  const leaddataforlist = useSelector((state) => state.LeadReducer?.leaddata && state.LeadReducer?.leaddata.length > 0 ? state.LeadReducer.leaddata : []);
  const loader = useSelector((state) => state.LeadReducer?.loading ? state.LeadReducer.loading : false);
  const resolved = useSelector((state) => state.ResolveReducer.resolved["/lead-list"] ? state.ResolveReducer.resolved["/lead-list"] : false);
  const editData = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : {});
  const editClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.editlead ? state.tableSlice.customButtonClicked.leaddata.editlead : false);
  const folder_view_click = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.folder_view_click ? state.tableSlice.customButtonClicked.leaddata.folder_view_click : false); // ------ Folder View Btn Click Status ------
  const manageStageBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.manage_stage ? state.tableSlice.customButtonClicked.leaddata.manage_stage : false);
  const fetchedStateData = useSelector((state) => state.LeadReducer?.FetchStages ? state.LeadReducer.FetchStages : []); // ------- FetchedStateData
  const TagManageBtn = useSelector(state => state?.tableSlice?.customButtonClicked?.leaddata?.Tag_Manage ? state.tableSlice.customButtonClicked.leaddata.Tag_Manage : false); // ------ TagManageBtn ------
  const TagManageModalData = useSelector(state => state?.LeadReducer?.FetchTagDetailsData ? state.LeadReducer.FetchTagDetailsData : []);

  const notesButtonClickedforrep = useSelector((state) => state.tableSlice?.customButtonClicked?.bluecoastdata?.notes_for_rep ? state.tableSlice.customButtonClicked.bluecoastdata.notes_for_rep : false)

  // const notesAddedbyId = useSelector((state)=> state.tableSlice?.tableData?.notesTable?.notes_by)
  // const userRoles = useSelector((state)=> state.loginSlice?.user_main_role)
  // const tagManageModalDataFlag = useSelector((state)=> state.LeadReducer?.fetchTagDetailsDataFlag ?  state.LeadReducer.fetchTagDetailsDataFlag: 0 )
  // ------ TagManageData ------

  // const stateManageModalDataFlag = useSelector((state)=> state.LeadReducer?.fetchStageDetailsDataFlag? state.LeadReducer.fetchStageDetailsDataFlag : 0)

  // const showStageListBtn = useSelector((state) =>
  //   state.tableSlice?.customButtonClicked?.leaddata?.show_stage_list
  //     ? state.tableSlice.customButtonClicked.leaddata.show_stage_list
  //     : false
  // );


  // const showTagListBtn = useSelector((state) =>
  //   state.tableSlice?.customButtonClicked?.leaddata &&
  //   state.tableSlice?.customButtonClicked?.leaddata?.show_tag_list
  //     ? state.tableSlice?.customButtonClicked?.leaddata?.show_tag_list
  //     : false
  // );

  //----------------------------get follow up form  submition status---------------
  const followUpStatus = useSelector(state => state.formSlice?.status?.followUpcallForm ? state.formSlice.status.followUpcallForm : "error"); // ------ ------

  //===============================================================================

  /************for calender management link******** */
  const mainUserInfo = useSelector((state) => state.loginSlice?.userInfo?.main_role)
  const sendCalenderInvitationLinkButtonClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.send_calender_link ? state.tableSlice.customButtonClicked.leaddata.send_calender_link : false)
  const calenderInvitationData = useSelector((state) => state.LeadReducer?.calenderinvitation ? state.LeadReducer?.calenderinvitation : null);


  /************ for mark as conversion done********(for this we will use mainUserInfo for role type id named as send_by_id) ************ */
  const markAsConversionDoneButtonClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.mark_as_conversion_done ? state.tableSlice.customButtonClicked.leaddata.mark_as_conversion_done : false)
  const markAsConversionDoneData = useSelector((state) => state.LeadReducer?.markconversion ? state.LeadReducer?.markconversion : null);


  //----stageList Life Cycle ----------------
  const showStageListBtnOpen = useSelector(state => state.tableSlice?.customButtonClicked?.leaddata?.show_stage_list ? state.tableSlice.customButtonClicked.leaddata.show_stage_list : false); // ------ ShowStageListBtn ------
  const showStagebtnData = useSelector(state => (state.tableSlice?.tempRowData?.leaddata && Object.keys(state.tableSlice.tempRowData.leaddata).length > 0) ? state.tableSlice.tempRowData.leaddata : null); //-----showStatgeTable Data ------
  const fetchStagelistData = useSelector(state => (state.LeadReducer?.ShowStageListData) ? state.LeadReducer.ShowStageListData : []) // ------- FetchedStagelistData


  //  ****************************************for Preview Modal***********************
  const [previewModal, setPreviewModal] = useState(false);
  const [previewModalData, setPreviewModalData] = useState(null)
  const viewBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.viewBtnAction) ? state.tableSlice?.customButtonClicked?.leaddata?.viewBtnAction : false);
  const previewData = useSelector((state) => (state.tableSlice?.tempRowData?.leaddata && Object.keys(state.tableSlice.tempRowData.leaddata).length > 0) ? state.tableSlice.tempRowData.leaddata : null)
  const handleClose = () => setPreviewModal(false);

  // ----------------------------------------Components Private State---------------------------------------- //
  const [tagManageModal, setTagManageModal] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [statusAction, setStatusAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//

  /************************For FollowUp calls************************************** */
  const [openMoadlForFollwCall, setOpenMoadlForFollwCall] = React.useState(false);
  const [addedForId, setAddedForId] = useState({}); //***FOR ROW DATA****//
  const [addedForNmae, setAddedForName] = useState({}); //***FOR ROW DATA****//
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//
  const [stageModal, setStageModal] = useState(false);
  const [propsRole, setPropsRole] = useState('');

  const [showstageList, setshowstageList] = useState(false);
  const [showtagListModal, setshowtagListModal] = useState(false);
  const [showstageListAction, setshowstageListAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//

  const [openMoadl, setOpenMoadl] = useState(false); // -----

  const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);
  const followUpClicked = useSelector((state) => state?.tableSlice?.customButtonClicked?.leaddata?.followUpCall ? state.tableSlice.customButtonClicked.leaddata.followUpCall : false);
  let loaderForFollowUp = useSelector((state) => state.LeadReducer?.loaderForFollowUp);
  let followUpCallList = useSelector((state) => state.LeadReducer?.followUpCallData && state.LeadReducer?.followUpCallData.length > 0 ? state.LeadReducer.followUpCallData : []);


  /************************ For Email Send Section ************************************** */
  const [openDrowerForEmail, setOpenDrowerForEmail] = React.useState(false);
  const sendMailClicked = useSelector((state) => state.tableSlice.customButtonClicked?.leaddata?.sendMail);
  let emailtemplateData = useSelector((state) => state.EmailTemplateReducer?.emailtemplateListData && state.EmailTemplateReducer?.emailtemplateListData.length > 0 ? state.EmailTemplateReducer.emailtemplateListData : []);
  let loaderForsendmail = useSelector((state) => state.LeadReducer?.loaderForsendmail);
  let submistionstate = useSelector((state) => state.LeadReducer?.submistionstate);
  let loaderforEmaildata = useSelector((state) => state.EmailTemplateReducer?.loading ? state.EmailTemplateReducer.loading : false);
  /************************ For Email Send Section End ********************************** */




  /************************ For information link Send Section start ************************************ */
  const [openDrowerForInfoLink, setOpenDrowerForInfoLink] = React.useState(false);
  const informationsendClicked = useSelector((state) => state.tableSlice.customButtonClicked?.leaddata?.informationLink);
  let infoLinksData = useSelector((state) => state.informationReducer?.informationlistdata && state.informationReducer?.informationlistdata.length > 0 ? state.informationReducer.informationlistdata : []);
  let loaderForInformationSend = useSelector((state) => state.informationReducer?.loading);
  /************************ For information link Send Section end ************************************** */




  /************************ For Registration IP Info Section start ************************************ */

  const [ipInfoModal, setIpInfoModal] = useState(false)
  const [ipInfo, setIpInfo] = useState(null)
  const handleCloseIpInfo = () => setIpInfoModal(false);

  //-------------------------For Notes preview----------------------------------------------------------
  const [openNotesModel, setOpenNotesModel] = useState(false)
  const [notesModel, setNotesModel] = useState(false)
  const [singalRowData, setSingalRowData] = useState({})
  const notesClick = useSelector(state => state?.tableSlice?.customButtonClicked?.leaddata?.notes ? state.tableSlice.customButtonClicked.leaddata.notes : false);
  let singaldata = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : null)
  let leadNotesData = useSelector((state) => state.LeadReducer?.Notesleaddata ? state.LeadReducer.Notesleaddata : []);
  // console.log("leadNotesData",leadNotesData);
  //-----------------------------Fetch lead Notes Data------------------------
  let leadNotesStatus = useSelector((state) => state.LeadReducer?.leadNotesStatus && state.LeadReducer?.leadNotesStatus.length > 0 ? state.LeadReducer.leadNotesStatus : null);

  //==========================================================================

  //----------------------Email Send Status--------------------------------
  const emailSendStatus = useSelector((state) => state.LeadReducer?.emailSendStatus ? state.LeadReducer.emailSendStatus : null);

  //=======================================================================


  //-------------------fetch tag update status-------------
  const tagUpdateStatus = useSelector((state) => state.LeadReducer?.upgradeTagStatus ? state.LeadReducer.upgradeTagStatus : null);

  //=====================================================





  useEffect(() => {
    if (notesClick === true) {
      // dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

      //  dispatch(fatchViewData())
      //  dispatch(cleargetNotesleaddata())
      setSingalRowData(singaldata)
      setNotesModel(true)
       setSingalRowData(singaldata)
       setNotesModel(true)
       setOpenMoadlforNotesUsertype("lead")
    }
  },[notesClick])

  useEffect(() => {
    console.log("singalRowData=======>>>>>>", singalRowData);
  }, [JSON.stringify(singalRowData)])
  



  useEffect(() => {
    if (Object.keys(singalRowData).length > 0) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

      console.log("singalRowData", singalRowData._id);
      let reqbody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {
          "notes_for": singalRowData._id
        },
        project: {},
        token: "",
      };
      dispatch(getNotesleaddata(reqbody));


    }
  }, [singalRowData])

  // useEffect(()=>{
  //   if(leadNotesStatus==="success"){
  //     dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

  //     setOpenNotesModel(true)

  //   }
  // },[leadNotesStatus])

  const closeModalForNotes = () => {
    dispatch(cleargetNotesleaddata())
    setNotesModel(false);
  };

  //====================================================================================================


  const reg_ip_info_click = useSelector(state => state?.tableSlice?.customButtonClicked?.leaddata?.registration_ip_info ? state.tableSlice.customButtonClicked.leaddata.registration_ip_info : false);
  const ipInfoData = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : null)
  useEffect(() => {
    // console.log("registration_ip_info btn click");

    if (reg_ip_info_click) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));
      setIpInfo(ipInfoData)
      setIpInfoModal(true)
      // let reqbody = { };
      // dispatch(getIPAddress(reqbody));
    }
  }, [reg_ip_info_click])

  /************************ For Registration IP Info Section end ************************************** */




  /************************ For choose_hm_bdd Section start ************************************ */
  const [assignBddHmModal, setAssignBddHmModal] = useState(false);
  const [hmBddRowData, setHMBddRowData] = useState(null)
  const [bddHmModel, setBddHmModel] = useState(false)


  const choose_hm_bddClick = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.choose_hm_bdd ? state.tableSlice.customButtonClicked.leaddata.choose_hm_bdd : false); // ------ Folder View Btn Click Status ------

  let singalDataForHM_BDD = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : null)


  const Associate_BBD_HMData = useSelector(state => state?.userManagment?.BDDHMInfo ? state.userManagment.BDDHMInfo : [])  // ----- Data for Associate_BBD_HM ------


  let leadHmBddDataInfo = useSelector((state) => state.LeadReducer?.leadHmBddDataInfo && state.LeadReducer?.leadHmBddDataInfo.length > 0 ? state.LeadReducer?.leadHmBddDataInfo : []);


  let leadHmBddDataStatus = useSelector((state) => state.LeadReducer?.leadHmBddDataStatus && state.LeadReducer?.leadHmBddDataStatus.length > 0 ? state.LeadReducer?.leadHmBddDataStatus : []);

  useEffect(() => {

    if (choose_hm_bddClick === true) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      setHMBddRowData(singalDataForHM_BDD)

    }
  }, [choose_hm_bddClick])

  useEffect(() => {

    if (hmBddRowData?._id) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      let payload = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "type": "asc",
          "field": "email"
        },
        "searchcondition": {
        }


      }
      //  dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: hmBddRowData?._id ? hmBddRowData._id : editData?._id, load: true }));

      dispatch(autoCompleteHMData(payload))
      dispatch(autoCompleteBddData(payload))
      let data = {
        _id: hmBddRowData._id,
        project: true
      }

      dispatch(leadHmBddData(data))
      // setAssignBddHmModal(true)



    }
  }, [hmBddRowData])

  useEffect(() => {
    if (leadHmBddDataStatus === "success") {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      setAssignBddHmModal(true)
    }
  }, [leadHmBddDataStatus])

  useEffect(() => {
    if (bddHmModel === true) {
      dispatch(setReloadTableFlag({ tableId: "leaddata" }))

    }
    setTimeout(() => {
      setBddHmModel(false)
    }, 3000)
  }, [bddHmModel])

//********************************************************************************** */

// ------- associate_BBD_HM_Btn -----
const associate_BBD_HM_Btn = useSelector((state) =>
state.tableSlice?.customButtonClicked?.leaddata?.associate_BBD_HM
  ? state.tableSlice.customButtonClicked.leaddata.associate_BBD_HM
  : false
);

// ----- Data for Associate_BBD_HM ------
const preview_BBD_HMData = useSelector((state) =>
state.userManagment?.BDDHMInfo ? state.userManagment.BDDHMInfo : []
);

const BDDHMInfoStatus = useSelector((state) =>
state.userManagment?.BDDHMInfoStatus ? state.userManagment.BDDHMInfoStatus : []
);

const [associateModalData, setAssociateModalData] = useState([]);
const [associateModal, setAssociateModal] = useState(false);

// -------------

 //------------ associate_BBD_HM_Btn -----------
 useEffect(() => {
  if (associate_BBD_HM_Btn) {
    dispatch(
      setEnableRowLoader({
        tableId: "leaddata",
        rowId: dataCopy._id,
        load: true,
      })
    ); //---- row loadder false----

    const payload = {
      _id: dataRef?._id,
      source: "google_events"
    };
    dispatch(fetchBDDHmInfo(payload));
  }
}, [associate_BBD_HM_Btn]);
// -----------------------------

// ------------- Associate_BBD_HMData here ---------
useEffect(() => {
  if (preview_BBD_HMData.length > 0) {
    setTimeout(() => {
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: dataCopy._id,
          load: false,
        })
      ); //---- row loadder false----
    }, 1000);

    setAssociateModalData(preview_BBD_HMData);
    setAssociateModal(true);
  }
}, [preview_BBD_HMData]);

useEffect(()=>{
  if(preview_BBD_HMData.length ===0 && BDDHMInfoStatus==="success"){
    setSnackFire(true);
    setResMessage("No Associatte BDD HM Yet!");
    setEndpointResStatus("failed");
  }
  setTimeout(() => {
    dispatch(clearRemasaage())
  }, 2000);
},[preview_BBD_HMData])


// ------------------------------------------


  /************************ For choose_hm_bdd Section end ************************************** */





  const [contractDrawer, setContractDrawer] = useState(false);
  const [contractPreviewModalNew, setContractPreviewModalNew] = useState(false);

  // ------- sendContractBtn -------
  const sendContractBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.send_contract ? state.tableSlice.customButtonClicked.leaddata.send_contract : false);
  // ----- On DataRef on every btn trigger ----
  const dataRef = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.leaddata ? state.tableSlice?.tempRowData?.leaddata : {});

  const contractData = useSelector((state) => state.userManagment?.fetchContractData ? state.userManagment.fetchContractData : []);
  const contractTemplateData = useSelector((state) => state.userManagment?.contractTemplate ? state.userManagment.contractTemplate : {});

  // const [modalopen, setModalOpen] = useState(false);

  //----------ShwoTagLife Cycle Data------------------------
  const taglifecyclemodalopen = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.show_tag_list_new ? state.tableSlice.customButtonClicked.leaddata.show_tag_list_new : false);
  const tagshowbtndata = useSelector((state) => state.tableSlice?.tempRowData?.leaddata && Object.keys(state.tableSlice.tempRowData.leaddata).length > 0 ? state.tableSlice.tempRowData.leaddata : null);
  let taglifecycledatas = useSelector((state) => state.LeadReducer?.taglifecycledata && state.LeadReducer?.taglifecycledata.length > 0 ? state.LeadReducer?.taglifecycledata : []);

  //-----------------------------------Assign Progran Configration---------------------------------------------
  const [openProgramModal, setOpenProgramModal] = useState(false);
  const [programRowData, setProgramRowData] = useState(null)
  //------AssinProgramBtn---------------------------------------------------------
  const AssinProgramBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.leaddata?.manageProgram
      ? state.tableSlice?.customButtonClicked?.leaddata?.manageProgram
      : false
  );

  let AssignProgramRowData = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : null);



  useEffect(() => {
    if (AssinProgramBtn === true) {
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: dataCopy._id,
          load: true,
        })
      );
      setProgramRowData(AssignProgramRowData);

    }
  }, [AssinProgramBtn]);

  useEffect(() => {
    if (programRowData !== null) {


      setOpenProgramModal(true);
    }
  }, [programRowData])

  //============================================================================================================
  useEffect(() => {
    if (Object.keys(dataRef).length > 0) {
      setDataCopy(dataRef)
    }
  }, [JSON.stringify(dataRef)])


  /******************  For getting Ip Info***** */
  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
  }, []);




  /**********************************************for calender Invitation link button push******* */

  useEffect(() => {
    console.log("mainUserInfo", mainUserInfo);
    if (mainUserInfo == "is_bdd") {
      // console.log("INNNN");
      tableDataForUsers.current.buttons.push({
        id: "send_calender_link",
        label: "Send Calender Booking Link",
        type: "other",
        name: " insert_invitation_icon",
        condField: "calender_access_form_submit_time",
        condVal: 1,
      },
        {
          id: "send_calender_link",
          label: "Resend Calender Booking Link",
          type: "other",
          name: " insert_invitation_icon",
          condField: "calender_access_form_submit_time",
          condVal: 2,

        }
      )


    }

    if (mainUserInfo === "hr_manager" || mainUserInfo === "is_rep" || mainUserInfo === "is_aer") {
      const btnSet = tableDataForUsers.current.buttons.filter((x) => x.id !== "send_contract")
      tableDataForUsers.current.buttons = btnSet
    }

  }, [])


  //----------ShwoTagLife Cycle Data------------------------

  const taglifecyclestatus = useSelector((state) => state.LeadReducer?.taglifecyclestatus && state.LeadReducer?.taglifecyclestatus.length > 0 ? state.LeadReducer?.taglifecyclestatus : []);


  useEffect(() => {
    if (taglifecyclemodalopen) {
      setDataCopy(dataRef);

      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      dispatch(gettagalldata({ _id: dataRef._id }));
    }
  }, [taglifecyclemodalopen]);


  useEffect(() => {
    if (taglifecycledatas.length > 0) {



      setshowtagListModal(true);

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "leaddata",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
    } else {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "leaddata",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

   
    }
  }, [JSON.stringify(taglifecycledatas)]);


  useEffect(() => {
    if (taglifecycledatas.length === 0 && taglifecyclestatus === "success") {
      setSnackFire(true);
      setResMessage("No Tag Assigned yet!");
      setEndpointResStatus("failed");
    }
    setTimeout(() => {
      dispatch(cleartaglifecyclestatus())
    }, 2000);
  }, [taglifecyclestatus])


  useEffect(() => {
    if (tagUpdateStatus === "success") {

    
      dispatch(setReloadTableFlag({ tableId: "leaddata" }))

    }
  }, [tagUpdateStatus])
  //----------ShwoStageLife Cycle Data------------------------

  const ShowStageListDataFetch = useSelector((state) =>
  state.LeadReducer?.ShowStageListDataFetch
    ? state.LeadReducer.ShowStageListDataFetch
    : false
);

  useEffect(() => {
    if (showStageListBtnOpen) {
      setDataCopy(dataRef);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      dispatch(
        ShowStageList({
          condition: {
            limit: 30,
            skip: 0,
          },
          sort: {
            field: "createdon_datetime",
            type: "desc",
          },
          searchcondition: dataRef?._id ? { _id: dataRef._id } : {},

          // "project": {

          // },

          count: false,
          // "token": usersToken
        })
      );
    }
  }, [showStageListBtnOpen]);
  // ----------------------------

  // ---------- on StageLifecycle Data fetch mmodal fire or snackbar fire ------
  useEffect(() => {
    if (fetchStagelistData.length > 0) {
      setshowstageList(true);
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "leaddata",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
    } else if (ShowStageListDataFetch && fetchStagelistData.length === 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "leaddata",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
      setSnackFire(true);
      setResMessage("No Stage Assigned yet!");
      setEndpointResStatus("failed");
    }
  }, [JSON.stringify(fetchStagelistData), ShowStageListDataFetch]);


  ///////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   if (showStagebtnData && showStageListBtnOpen) {
  //     callstagefetchapi(showStagebtnData._id)

  //     dispatch(
  //       setEnableRowLoader({
  //         tableId: "leaddata",
  //         rowId: showStagebtnData._id,
  //         load: true,
  //       })
  //     )

  //   }



  // }, [showStageListBtnOpen, JSON.stringify(showStagebtnData)])

  // const callstagefetchapi = async (val) => {
  //   const data = {
  //     "condition": {
  //       "limit": 10,
  //       "skip": 0
  //     },
  //     "sort": {
  //       "field": "_id",
  //       "type": "asc"
  //     },
  //     "searchcondition": {
  //       _id: val
  //     },
  //     "project": {
  //     },
  //     "token": "",
  //     "count": false
  //   }
  //   dispatch(ShowStageList(data))

  //   dispatch(
  //     setEnableRowLoader({
  //       tableId: "leaddata",
  //       rowId: showStagebtnData._id,
  //       load: false,
  //     })
  //   )

  //   setshowstageList(true)
  // }
  // --------------------------------------------Other Veriables-------------------------------------------- //

  useEffect(() => {
    setContractPreviewModalNew(false);

    if (sendContractBtn) {
      setDataCopy(dataRef);
      setContractPreviewModalNew(false);

      const data = {
        condition: {},
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {
          status: 1,
          roles: { $in: ['is_lead'] },
        },
        project: { name: 1 },
        token: "",
        data: { user_id: dataRef._id },
        source: "google_event",
      };

      dispatch(fetchContract(data));
    }
  }, [sendContractBtn]);

  useEffect(() => {
    if (contractData.length > 1) {
      setContractPreviewModalNew(false);
      setContractDrawer(true);
    } else if (contractData.length == 1) {
      setContractDrawer(false);
      setContractPreviewModalNew(false);

      console.warn("<<<<<<<<< contractData goes here >>>>>>>>>", contractData);

      const payload = {
        searchcondition: {
          user_id: dataCopy._id,
          contract_id: contractData[0]._id,
        },
      };
      dispatch(fetchContractTemplate(payload));
    } else {
      setContractDrawer(false);
    }
  }, [contractData]);

  /******************************For Preview modal************************* */
  useEffect(() => {
    if (viewBtn) {
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: previewData._id,
          load: true,
        })
      );
      setPreviewModal(true)
      setPreviewModalData(previewData)
    }
  }, [viewBtn])

  //-------------------------------------------text funtions -------------------------------------------------
  useEffect(() => {
    console.log(":leaddataforlist", leaddataforlist);
  }, [leaddataforlist])


  // ------###### notes_for_rep ######------//
  useEffect(() => {
    if (notesButtonClickedforrep) {

    }
  }, [notesButtonClickedforrep])
  // ------ ########################### -------- //


  //---------------------------------------------text funtions -------------------------------------------------
  useEffect(()=>{
    if(openProgramModal === false){
      setProgramRowData(null)
    }
  },[openProgramModal])

  // ############################## tableDataForUsers #######################


  

  var modifyTableHeaders = useRef([
    { val: "lead_uniq_id", name: "Lead ID" },
    { val: "hidden_name", name: "Name" },
    { val: "email", name: "Email" },
    { val: "landing_page_name", name: "Source" },
    { val: "phone", name: "Phone Number" },
    { val: "tag_names", name: "Tags" },
    { val: "stage_name", name: "Stage Name" },
    { val: "batch_name", name: "Batch Name" },
    { val: "associated_bdd_rep_hm", name: "Associated BDD/AE/HM/AEE" },
    { val: "createdon_datetime", name: "Joined On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss" },
  ]);

  const [reqbody, setreqbody] = useState({

    condition: {
      limit: 30,
      skip: 0,
    },
    sort: {
      field: "createdon_datetime",
      type: "desc",
    },

    searchcondition: props?.parentType?.roles?.is_rep === 1 ? { 'rep_id': props.parentType._id } : props?.parentType?.roles?.is_bdd === 1 ? { 'associated_bdd': { "$in": props.parentType._id } } : props?.parentType?.roles?.hr_manager === 1 ? { 'hiring_manager': props.parentType._id } : props?.parentType?.roles?.is_aer === 1 ? { 'aer_id': props.parentType._id } : userInfo?.userinfo?.main_role === 'hr_manager' ? { 'hiring_manager': userInfo?.userinfo?._id } : userInfo?.userinfo?.main_role === 'is_aer' ? { 'aer_id': userInfo?.userinfo?._id } : userInfo?.userinfo?.main_role === 'is_bdd' ? { 'associated_bdd': { $in: [userInfo?.userinfo?._id] } } : userInfo?.userinfo?.main_role === 'is_rep' ? { 'rep_id': userInfo?.userinfo?._id } : props.source === "PharmacysavingsBluecoast" ? { "rep_page_slug": "gc7MqHWxe7HIW" } : props.source === "PharmacysavingsAspire" ? { "rep_page_slug": "NycjyqRnjFUEd" } : {},

    project: {},
    token: token,
    count: false,


  });


  // Lead list table
  let tableDataForUsers = useRef({
    tableId: "leaddata",
    tableTitle: cookies?.userinfo?.rep_with_new_dashboard === true ? "Enrollment Form List" : props.source === "PharmacysavingsAspire" ? "Pharmacysavings Aspire Lead List" : props.source === "PharmacysavingsBluecoast" ? "Pharmacysavings Bluecoast Lead List" : "Lead List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/lead-list-count",
      deleteSingleUserEndpoint: "lead-manage/delete-lead",
      tableCountEndpoint: "lead-manage/lead-list-count",
    },

    reqBody: reqbody,
    deleteSingleUserReqBody: {
      source: "role_list",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: [
      "lead_uniq_id",
      "fullname",
      "email",
      "status",
      "createdon_datetime",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "manage_multiple_stage",
        label: "Manage Stage",
        type: "clear_all_icon",
        category: "clear_all_icon",
        icon_type: "clear_all_icon",
        className: "status_data",
      },

      {
        id: "manage_multiple_tags",
        label: "Tag Manage",
        type: "local_offer_icon",
        category: "local_offer_icon",
        icon_type: "local_offer_icon",
        className: "status_data",
      },

      {
        id: "delete_tags",
        label: "Multi Delete Tags",
        type: "api",
        category: "modal",
        icon_type: "delete_sweep_icon",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to delete this tag (s)?</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          // endpoint: "lead-manage/delete-lead",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },


      {
        id: "delete",
        label: "Multi Delete Lead",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to delete this record (s)?</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-lead",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalHeader: "<p>Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },





      // {
      //     id: "manage_stage",
      //     label: "Manage Stage",
      //     type: "clear_all_icon",
      //     category: "clear_all_icon",
      //     icon_type: "clear_all_icon",
      //     className: "status_data",
      // },
    ],

    // Action Buttons in use
    buttons: [
      {
        id: "manage_stage",
        type: "other",
        name: "clear_all_icon",
        label: "Manage Stage",
      },

      {
        id: "Tag_Manage",
        type: "other",
        name: "local_offer_icon",
        label: "Tag Manage",
      },

      {
        id: "send_contract",
        type: "other",
        name: "article_icon",
        label: "Send Contract",
        condField: "contract_send_flag",
        // condVal: 1,
      },

      {
        id: "editlead",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "manageProgram",
        label: "Manage Programe",
        type: "other",
        name: "fact_check_icon",
        // condField: "rr_btn",
        // condVal: 1,
      },

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this record? This can't be undone.</p>",
        modalHeader: "<p>Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-lead",
          body: {
            source: "google_events",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },

      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        modalHeader: "<p>Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/status-update",
          body: {
            source: "google_events",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },

      {
        id: "folder_view_click",
        label: "Folder View",
        type: "other",
        icon_type: "insert_drive_file_icon",
        name: "folderview",
      },

      {
        id: "viewBtnAction",
        label: "Preview",
        type: "other",
        icon_type: "preview",
        name: "preview",
      },


      {
        id: "notes",
        label: "Notes",
        type: "other",
        name: "summarize_icon_icon",
        showcount: true,
        countFiled: 'notescount'
      },

      {
        id: "followUpCall",
        label: "Follow UP Call",
        type: "other",
        name: "follow_the_signs_icon",
        showcount: true,
        countFiled: 'follow_up_count'
      },

      {
        id: "sendMail",
        label: "Send Email",
        type: "other",
        name: "forward_to_inbox_two_tone_icon",
        showcount: true,
        countFiled: 'email_sent_count'
      },

      {
        id: "show_stage_list",
        label: "Stage Lifecycle",
        type: "other",
        name: "view_day_icon",
      },

      {
        id: "show_tag_list_new",
        label: "Tag Lifecycle",
        type: "other",
        name: "data_saver_off_icon",
      },

      {
        id: "mark_as_conversion_done",
        label: "Mark As Conversion Done",
        type: "other",
        name: "how_to_reg",
      },

      // {
      //   id: "send_calender_link",
      //   label: "Send Calender Invitation Link",
      //   type: "other",
      //   name: " insert_invitation_icon",
      // },
      {
        id: "informationLink",
        label: "Send Information Link",
        type: "other",
        name: "ios_share_icon",
      },
      {
        id: "associate_BBD_HM",
        name: "people_alt_icon",
        type: "other",
        label: "Associate BDD/HM",
       
      },
    ],

    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: cookies?.userinfo?.rep_with_new_dashboard === true ? "Search Pharmacy Sign Up" : "Search Lead",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetBtnName: "Refresh",
        submitBtnName: "Search",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Lead ID",
            name: "lead_uniq_id",
            type: "text",
            inputType: "number",
            className: "inputblock2line",
            payloadFormat: { key: "lead_uniq_id" },
          },

          {
            id: 1,
            label: "Search by Name",
            name: "fullname",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead-name",
            // base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead-search",
            reqBody: {
              source: "google_events",
              sort: { type: "desc", field: "fullname" },
              condition: { limit: 10, skip: 0 },
              searchcondition: cookies.userinfo?.main_role === "is_rep" ? { "rep_id": cookies?.userinfo?._id } : {},

              autocomplete_for: "fullname",
            },
            payloadFormat: { key: "_id" },
          },

          {
            id: 2,
            label: "Search By Program",
            name: "program_name",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
            reqBody: {
              source: "google_events",
              sort: { type: "desc", field: "name" },
              condition: { limit: 10, skip: 0 },
              searchcondition: {},
            },
            payloadFormat: { key: "associated_program" },
          },

          {
            id: 3,
            label: "Search By Tag",
            name: "tag_names",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-users-tag",
            reqBody: {
              source: "google_events",
              sort: { type: "desc", field: "name" },
              condition: { limit: 10, skip: 0 },
              searchcondition: {},
            },
            payloadFormat: { key: "latest_tag.tag_slug" },
          },

          // {
          //   id: 4,
          //   label: "Search By Hiring Manager",
          //   name: "hiring_manager",
          //   type: "autoComplete",
          //   className: "inputblock2line",
          //   base_url:
          //     process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
          //   reqBody: {
          //     source: "lead_list",
          //     condition: {
          //       limit: 10,
          //       skip: 0,
          //     },
          //     sort: {
          //       field: "name",
          //       type: "asc",
          //     },
          //     searchcondition: {},
          //     project: {},
          //     autocomplete_for: "hiring_manager",
          //   },
          //   payloadFormat: { key: "hiring_manager" },
          // },

          {
            id: 5,
            label: "Search By AE",
            name: "rep",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "users",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "name",
                type: "asc",
              },
              searchcondition: {},
              project: {},
              autocomplete_for: "rep",
            },
            payloadFormat: { key: "rep_id" },
          },

          {
            id: 6,
            label: "Search By BDD",
            name: "bdd",
            type: "autoComplete",
            // multiple: true,
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "google_events",
              condition: { limit: 10, skip: 0 },
              sort: { field: "name", type: "desc" },
              searchcondition: {},
              project: {},
              autocomplete_for: "bdd",
            },
            payloadFormat: { key: "associated_bdd" },
          },

          {
            id: 7,
            label: "Search By AEE",
            name: "aer",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "google_events",
              condition: { limit: 10, skip: 0 },
              sort: { field: "name", type: "asc" },
              searchcondition: {},
              project: {},
              autocomplete_for: "aer",
            },
            payloadFormat: { key: "aer_id" },
          },

          {
            id: 13,
            label: "Search By Source",
            name: "slug",
            type: "autoComplete",
            multiple: true,
            checkbox: true,
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "landing-page-manage/landing-page-autocomplete",
            reqBody: {
              source: "landing_page",
              condition: { limit: 30, skip: 0 },
              sort: { field: "name", type: "asc" },
              autocomplete_for: "name",
              searchcondition: { "rep_only": 0 },

            },
            payloadFormat: { key: "page_slug", param: "$in" },
          },

          {
            id: 14,
            label: "Search By Batch Name",
            name: "batch",
            type: "autoComplete",
            // multiple: true,
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "bclusers/batch-name-autocomplete",
            reqBody: {
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {},
              "searchcondition": {
              }
            },
            payloadFormat: { key: "batch_name" },

          },

          {
            id: 8,
            label: "Search by Start Date",
            name: "createdon_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "inputblock2line",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },

          {
            id: 9,
            label: "Search by End Date",
            name: "createdon_datetime1",
            className: "inputblock2line",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },

          {
            id: 10,
            label: "Search By Email",
            name: "email",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead-search",
            reqBody: {
              source: "google_events",
              condition: { limit: 10, skip: 0 },
              sort: { type: "desc", field: "email" },
              searchcondition: {},
              autocomplete_for: "email",
            },
            payloadFormat: { key: "email", options: "i" },
          },

          {
            id: 11,
            label: "Search by Status",
            name: "status",
            type: "select",
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            payloadFormat: { key: "status" },
          },

          {
            id: 12,
            label: "Phone Number Format: (123) 456-7890",
            name: "phone",
            type: "text",
            inputType: "phone",
            className: "inputblock2line",
            rules: {  pattern: /^[^@#%&$]*$/ },
            payloadFormat: { key: "phone_trimmed", param: "$regex", options: "i" },
            errorMessage: {
              pattern: "Phone no does not contain any special characters (@#%&$)",
              maxLength: "Phone number can not be greater than 10 digits",
            },
          },
        ],
      },
    },
  });

  // ------------------------------------------------------------------- //
  useEffect(()=>{
   
 console.log("cookies.userinfo.main_role",cookies.userinfo.main_role);

    if (cookies?.userinfo?.main_role && cookies.userinfo.main_role !== "hr_manager"){
      const hiringManagerfield={
        id: 4,
        label: "Search By Hiring Manager",
        name: "hiring_manager",
        type: "autoComplete",
        className: "inputblock2line",
        base_url:
          process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
        reqBody: {
          source: "lead_list",
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            field: "name",
            type: "asc",
          },
          searchcondition: {},
          project: {},
          autocomplete_for: "hiring_manager",
        },
        payloadFormat: { key: "hiring_manager" },
      }
       
       tableDataForUsers.current.searchData.formData.fields.splice(4,0,hiringManagerfield)
    }

  },[])



  // --------------------------------------------store.subscribe Section-------------------------------------------- //
  store.subscribe(() => {
    const storeData = store.getState();

    if (
      storeData.tableSlice.tempRowData.leaddata?._id != undefined ||
      storeData.tableSlice.tempRowData.leaddata?._id != null
    ) {
      setAddedForId(storeData.tableSlice.tempRowData.leaddata._id);
      setAddedForName(storeData.tableSlice.tempRowData.leaddata.fullname)
      // console.log("addedForId------->", addedForId);
      // store.dispatch(setTempRowId(addedForId))
    }
  });


 

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    setContractDrawer(false);
    closeDrower();

    // if (cookies.userinfo.main_role == 'is_rep') {
    //     reqbody.searchcondition['rep_id'] = cookies.userinfo._id
    // }
    // if (cookies.userinfo.main_role == 'hr_manager') {
    //     reqbody.searchcondition['hiring_manager'] = cookies.userinfo._id
    // }

    if (cookies?.userinfo?.main_role && cookies.userinfo.main_role === "is_admin") {
      tableDataForUsers.current.buttons.push(
        {
          id: "registration_ip_info",
          label: "Registration IP Info",
          type: "other",
          name: "info_icon",
        },
        {
          id: "choose_hm_bdd",
          label: "Choose HM/Bdd",
          type: "other",
          name: "group_add_icon",
        }
      )
    }

    if (cookies.userinfo.main_role == "is_rep" && !reqbody.searchcondition["rep_id"]) reqbody.searchcondition["rep_id"] = cookies.userinfo._id;
    if (cookies.userinfo.main_role == "hr_manager" && !reqbody.searchcondition["hiring_manager"]) reqbody.searchcondition["hiring_manager"] = cookies.userinfo._id;
    if (cookies.userinfo.main_role == "is_bdd" && !reqbody.searchcondition["associated_bdd"]) reqbody.searchcondition["associated_bdd"] = cookies.userinfo._id;

    if (!resolved && !(role && id)) store.dispatch(getleaddata(reqbody));
    setStageModal(false);
    setStatusAction(null);
    setTagManageModal(false);
    setshowstageList(false);
    setshowstageListAction(null);
    setshowtagListModal(null);


    // ---- if we login with new rep then filter lead listing buttons -------- //

    if (cookies?.userinfo?.rep_with_new_dashboard === true) {

      tableDataForUsers.current.buttons.length = 0

      tableDataForUsers.current.customBtnHead.length = 0

      const header = modifyTableHeaders.current.filter((x) => { return x.val === "hidden_name" || x.val === "createdon_datetime" })

      header.push({ val: "campaign_landing_page_name", name: "Campaign Name" }, { val: "phone_number", name: "Phone" }, { val: "step", name: "Step", customVal: { '0': "First Step" } })

      modifyTableHeaders.current = header


      const searchFeilds = tableDataForUsers.current.searchData.formData.fields.filter((x) => { return x.name === "fullname" || x.name === "phone" || x.name === "createdon_datetime1" || x.name === "createdon_datetime" })

      tableDataForUsers.current.searchData.formData.fields = searchFeilds

      tableDataForUsers.current.searchData.formData.fields.push({
        id: 17,
        label: "Search By Campaign Name",
        name: "campaign_id",
        type: "autoComplete",
        // multiple: true,
        className: "inputblock2line",
        base_url: process.env.REACT_APP_API_URL + "campaign-manage/auto-complete-campaign-for-rep",
        reqBody: {
          condition: {
            limit: 10,
            skip: 0,
          },
          searchcondition: {
            rep_id: userInfo.userinfo._id,
          },
          sort: { field: "name", type: "desc" },
          autocomplete_for: "name",
        },
        payloadFormat: { key: "campaign_id" },
      })




    }
    // ------------------------------------------------------------------------ //


    if (props.source === "PharmacysavingsBluecoast" || props.source === "PharmacysavingsAspire") {

      const btnSet = tableDataForUsers.current.buttons.filter((x) => x.id === "editlead" || x.id === "notes" || x.id === "viewBtnAction" || x.id === "status")
      tableDataForUsers.current.buttons = btnSet


      const notesForRep = {
        id: "notes_for_rep",
        label: "Note About AE/REP",
        type: "other",
        name: "description_icon",
      }

      tableDataForUsers.current.buttons.push(notesForRep)




    }



    return () => {
      store.dispatch(clearleaddata());
      store.dispatch(resetresolvenew("/lead-list"));
      setStageModal(false);
      setStatusAction(null);
      setTagManageModal(false);
      dispatch(setStatesBlank());
      setshowstageList(false);
      setshowstageListAction(null);
      setshowtagListModal(null);

    };
  }, []);





  // useEffect(() => {

  //   if (leaddata.length === 0 && role && id) {
  //         let reqBody = { ...reqbody };
  //         if (role === "is_rep") reqBody.searchcondition["rep_id"] = id;
  //         if (role === "hr_manager") reqBody.searchcondition["hiring_manager"] = id;
  //         if (role === "is_bdd") reqBody.searchcondition["associated_bdd"] = id;

  //         setreqbody(reqBody);

  //       }
  //       store.dispatch(getleaddata(reqbody));

  // }, [])



  // For Folder View Button Click
  useEffect(() => {
    if (folder_view_click && editData && Object.keys(editData).length > 0) {
      setDataCopy(dataRef);
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));  //---- row loadder true----

      const payload = {
        user_for: dataRef._id,
        user_by: userInfo?.userinfo._id,
      };
      dispatch(folderViewVisitLog(payload));
      resolvefunction(navigate, "/lead-folder-view/" + editData?._id, getleadeditdata(editData._id));
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id, load: false })); //---- row loadder false----
    }
  }, [folder_view_click]);


  useEffect(() => {
    if (role && id) {
      let reqBody = { ...reqbody };
      if (role === "is_rep") reqBody.searchcondition["rep_id"] = id;
      if (role === "hr_manager") reqBody.searchcondition["hiring_manager"] = id;
      if (role === "is_bdd") reqBody.searchcondition["associated_bdd"] = id;

      setreqbody(reqBody);
      store.dispatch(getleaddata(reqbody));
    }
  }, [role, id]);

  // ---- on managestagebtn click  thunk call-----I
  useEffect(() => {
    if (manageStageBtn === true) {
      setDataCopy(editData);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: editData._id,
          load: true,
        })
      );
      const data = {
        condition: {},
        sort: {},
        searchcondition: { added_for: editData._id, roles: { $in: ["is_lead"] } },
        project: { subject: 1 },
        token: "",
        count: false,
      };

      dispatch(FetchStages(data));
    }
  }, [manageStageBtn]);

  /**********************After Click Tag Management Custom Btn Section start********************** */
  useEffect(() => {
    if (TagManageBtn) {
      setUserId(editData._id);
      setDataCopy(editData);
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: editData._id, load: true }));

      const data = {
        "_id": editData._id,
        "roles": { $in: ["is_lead"] },
        "project": { "subject": 1 },
      }
      dispatch(FetchTagDetails(data));
    }
  }, [TagManageBtn]);

  //  on sucessfully data fetch TAG Modal open
  useEffect(() => {
    if (TagManageModalData?.length > 0) {
      setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);
      setTagManageModal(true);
    };
    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);

  }, [TagManageModalData]);
  /**********************After Click Tag Management Custom Btn Section End********************** */

  // modal after fetchedStateData fetched -----
  useEffect(() => {
    console.log("fetchedStateData", fetchedStateData);
    if (fetchedStateData.length > 0) {
      // setUserId(dataRef._id)
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id, load: false }))
      setStageModal(true);
    }
    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);

  }, [fetchedStateData]);










  // ---- on show stage list-----
  // useEffect(() => {
  //   if (showStageListBtn === true) {
  //     setshowstageList(true);

  //     setDataCopy(editData);
  //     const data = {
  //       condition: {},
  //       sort: {},
  //       searchcondition: {
  //         "added_for": editData._id


  //       },
  //       project: {},
  //       token: "",
  //       count: false,
  //     };

  //     dispatch(ShowStageList(data));
  //   }
  // }, [showStageListBtn]);

  // modal after fetchedStageListData fetched -----
  // useEffect(() => {
  //     console.log("fetchStatelistData", fetchStatelistData);
  //     if (fetchStatelistData.length > 0) {
  //         setUserId(dataRef._id)
  //         setshowstageList(true)
  //     }
  // }, [fetchStatelistData])



  // useEffect(() => {

  //     if (ShowTagListModalData.length > 0) {
  //         console.log("ShowTagListModalData", ShowTagListModalData);
  //         setshowtagListModal(true)
  //     }
  // }, [ShowTagListModalData])

  useEffect(() => { }, [loader, leaddataforlist]);

  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));
      resolvefunction(
        navigate,
        `/lead-edit/${editData._id}`,
        getleadeditdata(editData._id)
      );
    }
  }, [editClicked]);




  /**********************Follow Up Section Start********************************* */
  // -----Follow Up button click-----
  // console.log("leade folow up id",addedForId);
  useEffect(() => {
    if (followUpClicked === true) {
      console.log("followUpClicked", followUpClicked);
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));
      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: { added_for: addedForId },
        count: false,
        source: "",
        // "project": {},
        // "token": "",
      };
      dispatch(getDatafollowUpCallList(reqbody));
      setOpenMoadlForFollwCall(true);
    }
  }, [followUpClicked]);

  ///---------------------lead list reload configration on follow up update--------------------
  useEffect(() => {
    if (followUpStatus === "success" || emailSendStatus === "success") {
      dispatch(setReloadTableFlag({ tableId: "leaddata" }))

    }

    setTimeout(() => {
      dispatch(clearEmailSendStatus())
    }, 5000);

  }, [followUpStatus, emailSendStatus])

  //=============================================================================================



  useEffect(() => {
    if (goBackClickedForFollowUpCall === true) {
      setOpenMoadlForFollwCall(false);
    }
  }, [goBackClickedForFollowUpCall]);

  useEffect(() => {
    if (formsubmissionstate.followUpcallForm === 2) {

      store.dispatch(
        setSnackbar({
          open: true,
          message: successMsgFollowUpCall,
          status: successStatusFollowUpCall,
        })
      );
      // setOpenMoadlForFollwCall(false);

    }
  }, [formsubmissionstate]);

  const closeModal = () => setOpenMoadlForFollwCall(false);
  const openModal = () => setOpenMoadlForFollwCall(true);
  /**********************Follow Up Section end********************************* */



  /**********************Information Link Section start********************************* */
  useEffect(() => {
    if (informationsendClicked === true) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: {
          // "added_for": addedForId
        },
        count: false,
        source: "",
      };

      store.dispatch(getInformationListData(reqbody));
    }
  }, [informationsendClicked]);

  useEffect(() => {
    if (infoLinksData.length > 0) {
      setOpenDrowerForInfoLink(true);
    }

    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: false })), 3000);
  }, [infoLinksData]);

  const closeDrowerForInfoLink = () => {
    dispatch(setReloadTableFlag({ tableId: 'leaddata' }))

    setOpenDrowerForInfoLink(false);
    dispatch(clearinformationlistdata());
  };
  /**********************Information Link Section Stop********************************* */



  /**********************For Email Send start********************************* */
  useEffect(() => {
    if (sendMailClicked === true) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: {
          // "added_for": addedForId
          "roles": { $in: ["is_lead"] }
        },
        count: false,
        source: "",
      };

      store.dispatch(getEmailTeamplatedata(reqbody));
    }
  }, [sendMailClicked]);

  useEffect(() => {
    if (emailtemplateData.length > 0) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      setOpenDrowerForEmail(true);
    }
    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);  // Stop Row Loader
  }, [emailtemplateData]);

  const closeDrower = () => {
    dispatch(setReloadTableFlag({ tableId: 'leaddata' }))
    setOpenDrowerForEmail(false);
    dispatch(clearEmailTemplatedata());
  };
  /**********************Email Send Stop********************************* */






  /***********************************UseEffect For send Calender Invitation Link */
  useEffect(() => {
    console.log("TagManageBtn", sendCalenderInvitationLinkButtonClicked);
    if (sendCalenderInvitationLinkButtonClicked == true) {
      setUserId(editData._id);
      setDataCopy(editData);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: editData._id,
          load: true,
        })
      );
      const data = {
        _id: editData._id,
        org_id: loggedUserId
      };

      dispatch(getcalenderinvitationlink(data));
      // setSnackFire(true)
      // setResMessage(endpointResMsg)
      // dispatch( setEnableRowLoader({
      //   tableId: "leaddata",
      //   rowId:  dataCopy._id,
      //   load: false,
      // }))
    }
  }, [sendCalenderInvitationLinkButtonClicked]);

  /****************************for calender invitation data fetch************ */
  useEffect(() => {
    if (calenderInvitationData) {
      // console.log("calenderInvitationData", calenderInvitationData);
      dispatch(setEnableRowLoader({
        tableId: "leaddata",
        rowId: dataCopy._id,
        load: false,
      }))
      dispatch(
        setSnackbar({ open: true, message: 'Calender Invitation Send', status: 'success' })
      );
      dispatch(clearinvationdata())

    }
  }, [calenderInvitationData]);


  /***********************************UseEffect For send mark as conversion Link */

  useEffect(() => {
    // console.log(" markAsConversionDoneButtonClicked", markAsConversionDoneButtonClicked);
    if (markAsConversionDoneButtonClicked == true) {
      setUserId(editData._id);
      setDataCopy(editData);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: editData._id,
          load: true,
        })
      );
      const datac = {
        'data': {
          user_id: editData._id,
          send_by_id: loggedUserId
        }

      };

      dispatch(getconversiondone(datac));

    }
  }, [markAsConversionDoneButtonClicked]);

  /****************************for UseEffect For send mark as conversion Linkh************ */
  useEffect(() => {
    if (markAsConversionDoneData) {
      // console.log("markAsConversionDoneData", markAsConversionDoneData);
      dispatch(setEnableRowLoader({
        tableId: "leaddata",
        rowId: dataCopy._id,
        load: false,
      }))
      dispatch(
        setSnackbar({ open: true, message: 'Marked as Convertion Done', status: 'success' })
      );
      dispatch(clearconversiondata())

    }
  }, [markAsConversionDoneData]);




  // useEffect(() => {
  //     // console.log("RoleList", resolve);
  //     if (!resolved) store.dispatch(getContractListData(reqbody));

  //     return () => {

  //         store.dispatch(clearContractdata())
  //         store.dispatch(resetresolvenew('/contract-list'))
  //     }
  // }, [])

  // useEffect(() => {
  //     if (Object.keys(viewModalData).length> 0) {
  //         setModalOpen(true);
  //     }
  // }, [viewModalData])

  // useEffect(() => {
  //     setModalOpen(false);

  // }, [])

  // --------------------------------------------Other Handlers Goes Here-------------------------------------------- //


  const closestagemodal = () => {
    setStageModal(false)
    dispatch(setReloadTableFlag({ tableId: 'leaddata' }))
  }


  // ---------------------------------------------------- return ---------------------------------------------------- //
  return (
    <>
      <div className="rolelist_mainwrp">


        {props.source !== "userFolderView" ? (<div className="listing_heading">
          {cookies?.userinfo?.rep_with_new_dashboard === true ? (<h1>Enrollment Form List</h1>) : props.source === "PharmacysavingsBluecoast" ? (<h1> Pharmacysavings Blue Coast Lead List</h1>) : props.source === "PharmacysavingsAspire" ? (<h1> Pharmacysavings Aspire Lead List</h1>) : (<h1>Lead Management</h1>)}


          {(cookies?.userinfo?.rep_with_new_dashboard === false || cookies?.userinfo?.rep_with_new_dashboard === undefined) && props.source !== "PharmacysavingsBluecoast" && props.source !== "PharmacysavingsAspire" ?
            <button className="addrole_btn" onClick={() => navigate("/lead-add")}>
              <AddIcon />{" "}
            </button> : ""}


        </div>) : ""}



        <div className={cookies?.userinfo?.rep_with_new_dashboard === true ? "rolelist_mainwrp_Table" : "userlisttable"}>
          {/* ----- Loader ---- */}
          {endpointLoading || endpointLoader ? (
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                top: "0px",
                background: "#68aad7",
                zIndex: 1,
                padding: "10px",
              }}
              className="loader"
            >
              <LinearProgress />
            </Box>
          ) : (
            ""
          )}
          {/* ------------------- */}

          {loaderforEmaildata || loader && <LinearProgress />}

          {/* <button onClick={()=>store.dispatch(changeTestData("loooool"))}>click me</button> */}
          {leaddataforlist !== null && leaddataforlist.length > 0 ? (
            <ListingTable
              tableData={tableDataForUsers.current}
              dataset={leaddataforlist}
              modifyHeaders={modifyTableHeaders.current}
            />
          ) :
            (

              <img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" style={{ margin: "auto", display: "block" }} />

            )
            // : (
            //   <TableSkeleton count={5} />
            // )
          }
        </div>
      </div>


      {/* ---- AssignBddHm Modal -----  */}

      {assignBddHmModal ? <AssignBddHm popuptoogle={assignBddHmModal} data={Associate_BBD_HMData} rowdata={hmBddRowData} popuptooglefunc={setAssignBddHmModal} source="leadList" leadHmBddDataInfo={leadHmBddDataInfo} setHMBddRowData={setHMBddRowData} setBddHmModel={setBddHmModel} /> : ""}
      {/* -------------------- */}

      {/* -------- LeadStageModal ------ */}
      {stageModal && <LeadStageModal toogle={stageModal} tooglefunc={() => closestagemodal()} stage={stageModal} UserId={dataCopy._id} UserName={dataCopy.fullname} />}
      {/* ---- ConfigureTagsModal------ */}
      {tagManageModal && <LeadTagModal 
        toogle={tagManageModal} 
        tooglefunc={setTagManageModal} 
        setModalResponse={setStatusAction} 
        tags={TagManageModalData} 
        UserId={UserId} 
        UserName={dataCopy.fullname} />}

      {/* ---- ConfigureTagsModal------ */}
      {/* {tagManageModal ? (<ConfigureTagsModal
        toogle={tagManageModal}
        tooglefunc={setTagManageModal}
        setModalResponse={setStatusAction}
        tags={TagManageModalData}
        UserId={UserId}
        Userinfo={dataCopy.fullname}
      />) : ""} */}

      {/* ---------------------------- */}





      {contractPreviewModalNew && <ContractViewModal toogle={contractPreviewModalNew} tooglefunc={contractPreviewModalNew} contractdata={contractTemplateData} userinfo={dataCopy} source="lead" />}


      {contractDrawer && <ContactDrawer open={contractDrawer} setcontractdrawer={setContractDrawer} contractdata={contractData} userid={dataCopy._id} userinfo={dataCopy} className="contract-drawer" source="lead" />}



      {/* -------- ShowStageListModal ------ */}
      {showstageList && <ShowStageListModal open={showstageList} toogle={showstageList} tooglefunc={setshowstageList} setModalResponse={setshowstageListAction} stagesdata={fetchStagelistData} userName={dataCopy} />}

      {/* {showstageList ? (
        <ShowStageListModal
          open={showstageList}
          toogle={showstageList}
          tooglefunc={setshowstageList}
          setModalResponse={setshowstageListAction}
          stage={showstageList}
          UserId={dataCopy._id}
          UserName={dataCopy.fullname}
        />
      ) : (
        ""
      )} */}

      {/* ---- ShowTagListModal------ */}
      {showtagListModal ? (
        <ShowTagListModal
          toogle={showtagListModal}
          tooglefunc={setshowtagListModal}
          setModalResponse={setStatusAction}
          tagsdata={taglifecycledatas}
          userName={dataCopy}
        />
      ) : (
        ""
      )}

      {/* ---------------------- */}

      {/* --------- Popup Modal ------------ */}
      {/* {Object.keys(viewModalData).length> 0?
                (<PopupModal
                    popupdata={viewModalData}
                    popuptoogle={modalopen}
                    popuptooglefunc={setModalOpen}
                    type="profileInfo"
                />) : ""} */}
      {/* --------------------------------- */}
      {/* {console.log("addfor if follow up", addedForId)} */}

      {addedForId !== null && addedForId !== undefined ? <FollowUpcall
        open={openMoadlForFollwCall}
        close={closeModal}
        addedById={addedById}
        addedForId={addedForId}
        addedForNmae={addedForNmae}
        listData={followUpCallList}
        loading={loaderForFollowUp}
        collectionName={"lead"}
      /> : ""}




      <InformationSentOption
        open={openDrowerForInfoLink}
        close={closeDrowerForInfoLink}
        data={infoLinksData}
        addedById={addedById}
        addedForId={addedForId}
        loading={loaderForInformationSend}


      />

      {openDrowerForEmail ? (
        <EmailSendDrower
          open={openDrowerForEmail}
          close={closeDrower}
          data={emailtemplateData}
          addedById={addedById}
          addedForId={addedForId}
          loading={loaderForsendmail}
          submistionstate={submistionstate}
          userName={dataCopy}
        />
      ) : (
        ""
      )}

      {/* ------------------------Assign Program ---------------------- */}


      {openProgramModal ? (
        <AssinProgramModal
          Modalstate={openProgramModal}
          togalState={setOpenProgramModal}
          userName={programRowData?.fullname}
          commonData={programRowData}
          source="lead"
          // setProgramRowData={setProgramRowData}
        />
      ) : (
        ""
      )}


      {/* ===================================================================== */}



         {/* ---- AssociateBbdHmInfoModal -----  */}
         {associateModalData.length > 0 && associateModal ? (
        <AssociateBbdHmInfoModal
          popuptoogle={associateModal}
          data={associateModalData}
          rowdata={dataCopy}
          popuptooglefunc={setAssociateModal}
        />
      ) : (
        ""
      )}
      {/* -------------------- */}





      {/* ------------ */}
      {/* {console.log("preview modal lead data",previewModalData)} */}
      <div>
        <Modal
          className="modalBaseStyle"
          open={previewModal}
          onClose={handleClose}>
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={handleClose}
                // className="CloseModal"
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className='view_details'>
              <h2 className='previewModalTitle '>
                {previewModalData?.fullname && <div className='modal_title modal_titlenew'> Details for {previewModalData.fullname}</div>}
              </h2>


              <div className='modalview_group'>
                {previewModalData ? <div className='data_container data_containerscroll'>

                  <div className='data_block'>
                    {previewModalData?.lead_uniq_id && <div className='property_container'><label className='property_label'>Lead ID:</label><label className='property_value'>{previewModalData.lead_uniq_id}</label></div>}
                    {previewModalData?.phone && <div className='property_container'><label className='property_label'>Phone Number:</label><label className='property_value'>{previewModalData.phone}</label></div>}
                    {previewModalData?.email && <div className='property_container'><label className='property_label'>Email:</label><label className='property_value'>{previewModalData.email}</label></div>}
                    {previewModalData?.time_zone && <div className='property_container'><label className='property_label'>Time Zone:</label><label className='property_value'>{previewModalData.time_zone}</label></div>}
                    {previewModalData?.associated_program_name_str && <div className='property_container'><label className='property_label'>Program Name:</label><label className='property_value'><div dangerouslySetInnerHTML={{ __html: previewModalData.associated_program_name_str }} /></label></div>}
                    {previewModalData?.hiring_manager_name && <div className='property_container'><label className='property_label'>Hiring Manager:</label><label className='property_value'>{previewModalData.hiring_manager_name}</label></div>}
                    {previewModalData?.rep_name && <div className='property_container'><label className='property_label'>AE Name:</label><label className='property_value'>{previewModalData.rep_name}</label></div>}
                    {previewModalData?.bdd_name && <div className='property_container'><label className='property_label'>BDD Name:</label><label className='property_value'>{previewModalData.bdd_name}</label></div>}
                    {previewModalData?.aer_name && <div className='property_container'><label className='property_label'>AEE Name:</label><label className='property_value'>{previewModalData.aer_name}</label></div>}
                    {previewModalData?.batch_name && <div className='property_container'><label className='property_label'>Batch Name:</label><label className='property_value'>{previewModalData.batch_name}</label></div>}
                    {previewModalData?.createdon_datetime && <div className='property_container'><label className='property_label'>Added On:</label><label className='property_value'>{format(previewModalData.createdon_datetime, 'dd-MM-yyyy HH:mm')}</label></div>}
                  </div>
                </div> : <div className='nodatafound'>No Records Found</div>}
              </div>

            </div>

          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          className="modalBaseStyle"
          open={ipInfoModal}
          onClose={handleCloseIpInfo}>
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={handleCloseIpInfo}
                // className="CloseModal"
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className='view_details'>
              <h2 className='previewModalTitle '>
                {ipInfo?.fullname && <div className='modal_title modal_titlenew'> Registration Ip-Info for {ipInfo.fullname}</div>}
              </h2>


              <div className='modalview_group'>
                {ipInfo?.ipinfo && Object.keys(ipInfo.ipinfo).length > 0 ? <div className='data_container'>

                  <div className='data_block'>
                    {ipInfo?.ipinfo?.ip && <div className='property_container'><label className='property_label'>IP:</label><label className='property_value'>{ipInfo.ipinfo.ip}</label></div>}
                    {ipInfo?.ipinfo?.hostname && <div className='property_container'><label className='property_label'>Host Name:</label><label className='property_value'>{ipInfo.ipinfo.hostname}</label></div>}
                    {ipInfo?.ipinfo?.city && <div className='property_container'><label className='property_label'>City:</label><label className='property_value'>{ipInfo.ipinfo.city}</label></div>}
                    {ipInfo?.ipinfo?.region && <div className='property_container'><label className='property_label'>Region:</label><label className='property_value'>{ipInfo.ipinfo.region}</label></div>}
                    {ipInfo?.ipinfo?.country && <div className='property_container'><label className='property_label'>Country:</label><label className='property_value'>{ipInfo.ipinfo.country}</label></div>}
                    {ipInfo?.ipinfo?.loc && <div className='property_container'><label className='property_label'>Location:</label><label className='property_value'>{ipInfo.ipinfo.loc}</label></div>}
                    {ipInfo?.ipinfo?.org && <div className='property_container'><label className='property_label'>Organization:</label><label className='property_value'>{ipInfo.ipinfo.org}</label></div>}
                    {ipInfo?.ipinfo?.postal && <div className='property_container'><label className='property_label'>Postal:</label><label className='property_value'>{ipInfo.ipinfo.postal}</label></div>}
                    {ipInfo?.ipinfo?.timezone && <div className='property_container'><label className='property_label'>Time Zone:</label><label className='property_value'>{ipInfo.ipinfo.timezone}</label></div>}
                  </div>
                </div> : <div className='nodatafound'>No Records Found</div>}
              </div>

            </div>

          </Box>
        </Modal>



      </div>





      {/* -------- ######## Notes for rep ######## ---------- */}
     
      {/* ------ ######################### ---------- */}







      {/* ------------------------Notes Components configration-----------------------------  */}
      {/* {(notesModel && (Object.keys(singalRowData).length > 0)) && <DNotes
        modalComonId={singalRowData._id}
        openMoadlforNotesUsertype={"lead"}
        gridData={singalRowData}
        openMoadl={notesModel}
        closeModal={setNotesModel}
        userName={{ fullname: singalRowData.fullname }}
        types="LeadNotes"
      />} */}


  {/* ------------------------Notes Components configration-----------------------------  */}
  {( notesModel  && (Object.keys(singalRowData).length > 0)) && <DNotes
       modalComonId  ={singalRowData._id}
         openMoadlforNotesUsertype={"lead"}
         gridData={singalRowData}
         openMoadl={notesModel}
         closeModal={setNotesModel}
         userName={{fullname: singalRowData.fullname}}
         types="LeadNotes"
        />}
      

       {/* --------- SnackBar -------- */}
       {snackFire !== false && resMessage !== null ? (
        <SnackBarComp
          setSnackFire={setSnackFire}
          resMessage={resMessage}
          snackFire={snackFire}
          endpointResStatus={endpointResStatus}
        />
      ) : (
        ""
      )}

    </>
  );
}
export default LeadList;