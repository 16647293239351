import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import EmployeeListCount from "./EmployerDashBoardDetails/EmployeeListCount";
import SubLoginEmployerCount from "./EmployerDashBoardDetails/SubLoginEmployerCount";
import TotalGrossPackage from "./EmployerDashBoardDetails/TotalGrossPackage";

function EmployerDashboard() {

  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
  let lastloginTime = useSelector(state => state.loginSlice.last_login_time);
  // const subEMployerDataListSuccess = useSelector(state => state.EmployerReducer?.subEmployerDataSuccess ? state.EmployerReducer.subEmployerDataSuccess : null);
  // const employeeListDetails = useSelector((state) => state.EmployerReducer?.employeeListData ? state.EmployerReducer.employeeListData : null)




  useEffect(() => {
    // console.log("userInfo=========>>>", userInfo, lastloginTime);
  }, [userInfo])

  const [totalCostFetchStatus, setTotalCostFetchStatus] = useState(false)
  const [employeeFetchStatus, setEmployeeFetchStatus] = useState(false)

  const totalGrossFetching = (event) => {
    // console.log("event", event)
    if (event !== undefined && event !== null) {
      setTotalCostFetchStatus(event);
    }

    // console.log("========>>>>>>>", totalCostFetchStatus)
  }

  const employeeFetching = (event) => {
    // console.log("event", event)
    if (event !== undefined && event !== null) {
      setEmployeeFetchStatus(event);
    }

    // console.log("========>>>>>>>", employeeFetchStatus)
  }


  return (
    <>
      <h1 className="page_heading">Employer Dashboard</h1>

      <Grid container spacing={1} className="dashboard_con">
        <Grid item md={12} xs={12}>
          <div className="dashboard_block1 dashboardCommon">
            <h2>
              <span>Welcome:</span>{" "}
              {userInfo.userinfo?.firstname + " " + userInfo.userinfo?.lastname}{" "}
            </h2>

            {userInfo.userinfo && userInfo.userinfo.user_name && <h2>
              <span>Employer Id :</span>{" "}
              {userInfo.userinfo.user_name}
            </h2>}

            <h3>
              <span>Last Login:</span>{" "}
              {lastloginTime !== null
                ? moment(Number(lastloginTime)).format("dddd, MMMM Do YYYY, h:mm a")
                : "N/A"}
            </h3>
          </div>
        </Grid>
      </Grid>

      <TotalGrossPackage totalGrossFetched={totalGrossFetching} />
      <br />

      {(totalCostFetchStatus) ? <EmployeeListCount employeeFetched={employeeFetching} /> : ""}

      <br />
      {(employeeFetchStatus) ? <SubLoginEmployerCount /> : ""}


    </>
  );
}

export default EmployerDashboard;
