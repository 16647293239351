import React from 'react'
import "../LandingPage13.css";

function Landingpageblock12() {
    return (
        <>
            {/* <div className='landingpage13block12_main_wrapper'>
        <div className='landingpage13block12_main_wrapper_width'>
            <div className='landingpage13block12_main_sub_wrapper'>
                <div className='landingpage13block12_main_sub1_wrapper'>
                    <div className='landingpage13block12_main_sub1_sec_wrapper'>
                    <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/landingpage13block12_main_sub1_wrapper_img.png'/>
                    </div>
                    <div className='landingpage13block12_main_sub1_sec1_wrapper'>
                        <h2>Acute Medication 
Info</h2>
                    </div>
                </div>
                <div className='landingpage13block12_main_sub2_wrapper'></div>
                <div className='landingpage13block12_main_sub1_wrapper'></div>
                <div className='landingpage13block12_main_sub2_wrapper'></div>
                <div className='landingpage13block12_main_sub1_wrapper'></div>

            </div>
        </div>

    </div> */}

        </>
    )
}

export default Landingpageblock12