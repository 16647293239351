
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Form } from "../../../../../form/Form"
import { fieldDataremove, removeField, resetField, reSetFieldExtraData, resetForm, resetFormData, resetFormResetFlag, setField, setFieldNewValue, setTempFormUpdateData, updateFormFieldsData } from "../../../../../form/formReducer";
import { resolvefunction } from "../../../../../helper/helperFunctions";
import { getusersList } from "../../../userManagement/userList/userListReducer";
import { clearDeleteData, getCommissionConfigList } from "../../commissionReducer";





const CommissionAddEditForm = (props) => {

  // console.log("props CommissionAddEditForm", props);
  const dispatch = useDispatch()
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const location = useLocation()

  // console.log("current location", location);


  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.commissionConfigForm) ? state.formSlice.formSubmissionState.commissionConfigForm : false);
  const snackMsg = useSelector(state => (state.formSlice?.message?.commissionConfigForm) ? state.formSlice.message.commissionConfigForm : null);

  //---------------------------get login user id--------- //
  let userID = useSelector((state) =>
    state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null
  );
  //--------------------------------------------------------------


  //----------fetch Commission type data from  form fields----------------------- //
  const searchCommissionType = useSelector((state) =>
    state.formSlice?.formData?.commissionConfigForm?.commission_type ? state.formSlice.formData.commissionConfigForm.commission_type : null
  );


  //-------------------------------------------------------------------------------------- //
  const goBackBtnClick = useSelector(state => (state.formSlice.customButtonClicked?.commissionConfigForm && state.formSlice.customButtonClicked?.commissionConfigForm?.comGoBack) ? state.formSlice.customButtonClicked.commissionConfigForm.comGoBack : false);
  // console.log("goBackBtnClick", goBackBtnClick);


  // ------------ Package price ---------- ///

  const packagePrice = useSelector((state) =>
    state.formSlice?.formData?.commissionConfigForm?.package_price ? state.formSlice.formData.commissionConfigForm.package_price : null
  );



  // ------------ comission percentage ---------- ///

  const commisionPercentage = useSelector((state) =>
    state.formSlice?.formData?.commissionConfigForm?.commission_percentage ? state.formSlice.formData.commissionConfigForm.commission_percentage : null
  );


  //  success btn 

  const submitBtmClick = useSelector((state => state.formSlice?.formSubmissionState?.commissionConfigForm ? state.formSlice.formSubmissionState.commissionConfigForm : 0))



  useEffect(() => {
    if (submitBtmClick === 2) {

      // console.log("submitBtmClick==========>>>>>>>", submitBtmClick)
      return () => {
        dispatch(resetForm({ formId: "commissionConfigForm" }))
        dispatch(resetFormData({ formId: "commissionConfigForm" }))

        dispatch(removeField({ formId: "commissionConfigForm", name: "bps_info" }))
        setTimeout(() => {
          dispatch(fieldDataremove({ formId: "commissionConfigForm", fieldName: "commission_amount" }))
        }, 1000);

        setTimeout(() => {
          dispatch(fieldDataremove({ formId: "commissionConfigForm", fieldName: "commission_percentage" }))
        }, 1400);
      }

    }
  }, [submitBtmClick])







  // ------ gobackButton Click -------
  useEffect(() => {
    if (goBackBtnClick === true) {
      dispatch(clearDeleteData())

      navigate("/configur-commision")

      return () => {
        dispatch(resetForm({ formId: "commissionConfigForm" }))
        dispatch(resetFormData({ formId: "commissionConfigForm" }))

        dispatch(removeField({ formId: "commissionConfigForm", name: "bps_info" }))
        setTimeout(() => {
          dispatch(fieldDataremove({ formId: "commissionConfigForm", fieldName: "commission_amount" }))
        }, 1000);

        setTimeout(() => {
          dispatch(fieldDataremove({ formId: "commissionConfigForm", fieldName: "commission_percentage" }))
        }, 1400);

      }

    }
  }, [goBackBtnClick])
  // ----------------------------



  const isValidCommissionAmount = (data, packagePrice) => parseInt(data) < parseInt(packagePrice.package_price)


  // ---- userCreateForm ------
  const formData = useRef({
    id: 'commissionConfigForm',
    formtype: "add",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "commission/config-add-update",
    endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/configur-commision",
    // msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "tblform",// Can be undefined default will be formCls
    formInlineCLass: "inlnfrm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    // submitBtnName: props.queryParamId ? "UPDATE" : "SUBMIT",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      created_by: userID ? userID : undefined,
      _id: props?.commissionsdata?._id ? props.commissionsdata._id : undefined
    },

    fields: [




      {
        id: 1,
        heading: "Choose Program",
        label: "Choose Program",
        name: "associated_programme",
        type: "autoComplete",
        rules: { required: true },
        preload: true,
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        className: "inputBlock inputBlock4line ",
        base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
        reqBody: {
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        defaultValue: props?.commissionsdata?.associated_programme ? props.commissionsdata.associated_programme : "",

        sx: { m: 1, minWidth: 300 },
      },



      {
        id: 2,
        heading: "Package name",
        label: "Package Name",
        name: "package_name",
        className: 'inputBlock inputBlock4line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.package_name ? props.commissionsdata.package_name : "",

      },


      {
        id: 3,
        heading: "Package Price",
        label: "Package Price",
        name: "package_price",
        className: 'inputBlock inputBlock4line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.package_price ? props.commissionsdata.package_price : "",
        adornments: {
          type: "prefix",
          content: "$",
          position: "start"
        },

      },




      {
        id: 4,
        type: "html",
        className: "Noteheading",
        value: "<p>Note : NON BPS Stands For Flat Rate of Comission(%) Calculation and BPS Stands For Variable Rate of commission(%)</p>"
      },

      {
        id: 5,
        heading: "Commission Type",
        label: "Select Commission Type",
        name: "commission_type",
        className: 'inputBlock usertypebox inputBlock4line',
        type: 'select',
        multiple: false,
        checkbox: true,
        rules: { required: true },
        values: [{ val: 'non_bps', name: "Percentage" }, { val: 'bps', name: "BPS" }, { val: 'flat', name: "Flat" }],
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_type ? props.commissionsdata.commission_type : "",

        sx: { m: 1, minWidth: 120 },
      },


      {
        id: 8,
        heading: "Commission Amount",
        label: "Commission Amount",
        name: "commission_amount",
        className: 'inputBlock usertypebox inputBlock4line',
        type: "text",
        inputType: "float",
        rules: { required: true, validate: isValidCommissionAmount },
        disabled: true,
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          validate: "Commission amount should be less than package price",
        },
        defaultValue: props?.commissionsdata?.commission_amount ? props.commissionsdata.commission_amount : "",
        sx: { m: 1, minWidth: 120 },
        adornments: {
          type: "prefix",
          content: "$",
          position: "start"
        },
      },


      {
        id: 9,
        label: "Description",
        name: "description",
        className: 'inputBlock addressbox inputBlock1line',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.description ? props.commissionsdata.description : "",

      },

      {
        id: 10,
        heading: "Priority",
        label: "Priority",
        name: "priority",
        className: 'inputBlock addressbox inputBlock1line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.priority ? props.commissionsdata.priority : "",

      },

      {
        id: 11,
        heading: "Active",
        name: "status",
        className: "inputBlock inputBlock1line usertypebox",
        type: 'singleCheckbox',
        values: { key: 1 },
        defaultValue: props?.commissionsdata?.status ? props.commissionsdata.status : ""

      },




    ],

    customButtons: [
      {
        id: 'comGoBack',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },

    ]

  })
  // ---------------- ############################################################ ---------------------- //





  //---------------------------Inject fields according to commission type-----------------------------------
  useEffect(() => {


    if (searchCommissionType === "flat") {


      dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_percentage", value: "" }))


      setTimeout(() => {
        dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_amount", value: "" }))
      }, 800);


      setTimeout(() => {
        dispatch(removeField({ formId: "commissionConfigForm", name: "bps_info" }))
      }, 1000);

      setTimeout(() => dispatch(removeField({ formId: "commissionConfigForm", name: "commission_percentage" })), 1500)

      // ------ feild disabled endbaled toogled ----- //
      setTimeout(() => {

        const valueFeild = formData.current.fields.map((x) => ({ ...x, disabled: x.name == "commission_amount" && false }))
        // console.warn("valueFeild here", valueFeild)
        formData.current.fields = valueFeild

      }, 200);
      // --------------------------------------- ////

    } else if (searchCommissionType === "non_bps") {

      const commission_percentage = {
        id: 6,
        heading: "Commission Percentage",
        label: "Commission Percentage",
        name: "commission_percentage",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
        adornments: {
          type: "suffix",
          content: "%",
          position: "end"
        },
      }


      setTimeout(() => {
        dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_amount", value: "" }))
        dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_percentage", value: "" }))
      }, 200);

      setTimeout(() => {
        const valueFeild = formData.current.fields.map((x) => ({ ...x, disabled: x.name == "commission_amount" && true }))
        formData.current.fields = valueFeild
      }, 300);

      // console.log("data commision");
      dispatch(removeField({ formId: "commissionConfigForm", name: "bps_info" }))
      setTimeout(() => dispatch(setField({ formId: "commissionConfigForm", after: "commission_type", fieldData: commission_percentage })), 1000)


    } else if (searchCommissionType === "bps") {

      const bps_info = {
        id: 7,
        heading: "Bps Info",
        name: "bps_info",
        className: 'inputBlock addressbox inputBlock1line',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.bps_info ? props.commissionsdata.bps_info : "",

      }

      dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_amount", value: "" }))

      setTimeout(() => {
        dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_percentage", value: "" }))
      }, 300);

      dispatch(removeField({ formId: "commissionConfigForm", name: "commission_percentage" }))
      setTimeout(() => dispatch(setField({ formId: "commissionConfigForm", after: "commission_type", fieldData: bps_info })), 1000)

    }

  }, [searchCommissionType])

  //-------------------------------------------------------------------------------------------------------




  // -------------------- packagePrice  --------------------- // 
  useEffect(() => {

    if (packagePrice !== null && commisionPercentage !== null) {
      let commisionAmount = packagePrice * commisionPercentage / 100
      // console.warn(" final commision Amount here ====>>>> ", commisionAmount)

      if (commisionAmount !== null && commisionAmount !== undefined) {
        dispatch(setTempFormUpdateData({ formId: "commissionConfigForm", fieldName: "commission_amount", value: commisionAmount }))
      }
    }



  }, [JSON.stringify(packagePrice), JSON.stringify(commisionPercentage)])
  //  ------------------------------------------------------ //


  return (

    <>
      <div>

        <div className='adminform_wrapper'>
          <div className='dataform2_wrapper'>
            <div className='adminformbody'>
              <Form formData={formData.current} />

            </div>

          </div>



        </div>

      </div>


    </>
  )
}


export default CommissionAddEditForm