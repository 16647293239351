import React from 'react'
import "../Components/LandingPage14.css"

function Lp14blockheader() {
  return (
   <>
   <div className='Lp14blockheader_main_wrapper'>
    <div className='landingpage14_common_width'>
        <div className='Lp14blockheader_sub_wrapper'>
            <p>WE OFFER THE 605 MOST PRESCRIBED DRUGS IN AMERICA FOR FREE!</p>
        </div>
    </div>

   </div>
   </>
  )
}

export default Lp14blockheader