import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import ArticleIcon from '@mui/icons-material/Article';
import SendIcon from '@mui/icons-material/Send';
import { fetchContract, setStatesBlank } from '../userList/userListReducer';
import ContractViewModal from '../userList/contractViewModal';
import ContactDrawer from '../userList/sendcontractDrawer';
import { fetchContractTemplate } from '../userList/userListReducer';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';
import { ContractLogsInfo, folderViewCounts, UserActivity } from './folderViewReducer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';

const ContractManage = (props) => {
    const [contractPreviewModal, setContractPreviewModal] = useState(false);
    const [contractDrawer, setContractDrawer] = useState(false);

    const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//

    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    console.warn("<<<<<<<<<< props from ContractManage  Component >>>>>>", props)

    // ---- States here -----
    const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const
    const contractTemplateData = useSelector(state => (state.userManagment?.contractTemplate) ? state.userManagment.contractTemplate : {});
    // const dataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1) ? state.tableSlice?.tempRowData?.userslist1 : {});
    const contractData = useSelector(state => (state.userManagment?.fetchContractData) ? state.userManagment.fetchContractData : [])
    const contractLoader = useSelector(state => (state.userManagment?.contractLoader) ? state.userManagment.contractLoader : false)
    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [JSON.stringify(paramId)])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    // console.warn("<<<<< cookieData from User Contractmanage >>>>>", cookieData)
    // console.warn("<<<<< queryParamId from User Contractmanage >>>>>", queryParamId)


    // --- loader --- 
    const loader = useSelector(state => (state.userFolderViewReducer?.loading
    ) ? state.userFolderViewReducer.loading
        : null);

    // console.warn("<<<<<<<<<< contractlogs goes here >>>>>>>>>", props.contractlogs)

    const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
    const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

    // ------ on contract send success ------
    const contract_send = useSelector(state => (state?.userManagment?.contractSend) ? state.userManagment.contractSend : false)




    // ---- on contract sent sucess reload contract log list in folder view 
    useEffect(() => {
        if (contract_send) {
            const ContractLogPaylod = {
                "condition": {},
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                "searchcondition": {
                    "_id": queryParamId ? queryParamId : props.queryParamId
                },
                "project": {
                }
            }
            // console.log("ContractLogPaylod");



            const data = {
                "_id": queryParamId ? queryParamId : props.queryParamId
            }


            setTimeout(() => {
                dispatch(ContractLogsInfo(ContractLogPaylod))
                dispatch(folderViewCounts(data))
            }, 500);

            setTimeout(() => {
                dispatch(setStatesBlank())
            }, 1000);

        }
    }, [contract_send])
    // ----------------------------------------------


    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };


    // useEffect(() => {

    //     if (queryParamId != undefined && queryParamId != null) {

    //     }
    // }, [])

    // useEffect(() => {
    //     console.log("contractData", contractData);
    //     console.log("contractTemplateData", contractTemplateData);
    // }, [contractData, contractTemplateData])


    useEffect(() => {
        if (contractTemplateData && Object.keys(contractTemplateData).length > 0 && !contractDrawer && contractTemplateData.status !== "error") {
            setContractPreviewModal(true)
        }
    }, [contractTemplateData])





    const HandelContractSend = () => {
        // setDataCopy(dataRef)
        // setContractPreviewModal(false)


       // checking active role type then stor those active roles in a array
       let roles = []
       Object.keys(props.folderdata.roles).forEach(function (key, index) {
 
         if (props.folderdata.roles[key] == 1) {
           return roles.push(key)
         }
 
       })


        const data = {
            "condition": {},
            "sort": {
                "field": "name",
                "type": "asc"
            },
            "searchcondition": {
                "status": 1,
                "roles": { $in: roles },
            },
            "project": { "name": 1 },
            "token": "",
            "data": { "user_id": props.queryParamId },
            "source": props.source === "leadFolderView" ? "google_events" : "users"
        }

        dispatch(fetchContract(data))
    }





    useEffect(() => {
        if (contractData.length > 1) {
            setContractPreviewModal(false)
            setContractDrawer(true)

        }
        else if (contractData.length == 1) {
            setContractDrawer(false)
            setContractPreviewModal(false)

            // console.warn("<<<<<<<<< contractData goes here >>>>>>>>>", contractData)

            const payload = {
                "searchcondition": {
                    "user_id": props.queryParamId,
                    "contract_id": contractData[0]._id
                },
            }
            dispatch(fetchContractTemplate(payload))

        }

    }, [contractData])

    const searchFields = [

        {
            id: 0,
            label: "Search By Sent By ",
            name: "sent_by",
            className: 'inputBlock inputBlock3line',
            type: "autoComplete",
            sx: { m: 1, width: 600 },
            base_url: process.env.REACT_APP_API_URL + "contract-manage/contract-send-by",
            reqBody: {
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {

                    "user_id": queryParamId ? queryParamId : props?.userinfo?._id ? props.userinfo._id : props.queryParamId
                },
                "project": {},
                "token": "",
                "email": ""
            },

        },


        {
            id: 1,
            label: "Search By Contract  ",
            name: "contract_by",
            className: 'inputBlock inputBlock3line',
            type: "autoComplete",
            sx: { m: 1, width: 600 },
            base_url: process.env.REACT_APP_API_URL + "contract-manage/contract-details",
            reqBody: {
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {

                    "user_id": queryParamId ? queryParamId : props?.userinfo?._id ? props.userinfo._id : props.queryParamId
                },
                "project": {
                    'key': '$contract_id', 'label': '$contract_name', '_id': 0
                },
                "token": "",
                "email": ""
            },

        },

        {
            id: 8,
            label: "Search by Start Date",
            name: "start_date",
            className: 'inputBlock inputBlock3line',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            dateFormat: 'MM/DD/YYYY',


        },
        {
            id: 9,
            label: "Search by End Date",
            name: "end_date",
            className: 'datePicker',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            dateFormat: 'MM/DD/YYYY',
            className: 'inputBlock inputBlock3line',
            errorMessage: {
                dateCheck: "End Date can not be less than Start Date",
            },
        },
    ]

    useEffect(() => {
        if (submissionState === 4) {
            setSearchDraw(false)

            let activity_time = {
                "$gte": searchData?.start_date ? searchData.start_date : undefined,
                "$lte": searchData?.end_date ? searchData.end_date : undefined
            }


            if (activity_time.$gte == "" || activity_time.$gte == undefined || activity_time.$gte == 'undefined') {
                delete activity_time["$gte"];
            }

            if (activity_time.$lte == "" || activity_time.$lte == undefined || activity_time.$lte == 'undefined') {
                delete activity_time["$lte"];
            }



            const payload = {
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "field": "activity_time",
                    "type": "desc"
                },
                "searchcondition": {
                    "user_id": queryParamId ? queryParamId : props?.userinfo?._id ? props.userinfo._id : props.queryParamId ? props.queryParamId : undefined,
                    'send_by_id': searchData.sent_by ? searchData.sent_by : undefined,
                    'contract_id': searchData.contract_by ? searchData.contract_by : undefined,
                    "createdon_datetime": `$or:[$gte:${searchData?.start_date ? searchData.start_date : undefined},$lte:${searchData?.end_date ? searchData.end_date : undefined}]`


                },
                "project": {},
                "token": "",
                "email": ""
            }



            if (payload.searchcondition?.activity_time == undefined || payload.searchcondition?.activity_time == 'undefined') {
                delete payload.searchcondition["createdon_datetime"]
            }

            if (payload.searchcondition?.activity_by == undefined || payload.searchcondition?.activity_by == 'undefined') {
                delete payload.searchcondition['createdon_datetime'];
            }


            dispatch(ContractLogsInfo(payload))
        }
    }, [submissionState])
    // ----------------

    return (
        <>


            <div>
                <h3> <ArticleIcon /> Contract Logs

                    <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>

                        <Tooltip title="Send Contract">
                            <IconButton onClick={() => HandelContractSend()} >
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Search Contract">
                            <IconButton onClick={() => setSearchDraw(true)}>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                </h3>
                {(loader || contractLoader) ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {props.contractlogs?.length > 0 ? props?.contractlogs?.map((x, i) =>
                    <div className='user-activity' key={i}>

                        <ul className='ua-data-block'>
                            <li>  {x.contract_name} Sent By {x?.send_by} {x?.updatedon_datetime && `On ${format(x?.updatedon_datetime, 'dd-MM-yyyy HH:mm:ss')}`} </li>

                            {/* <li>Sent On:  {format(x?.createdon_datetime, 'dd-MM-yyyy HH:mm:ss')} </li>
                            <li>Sent By:  </li> */}
                            {/* <li>View Contract: <a href={x.link} target="_blank"> {x?.link} </a>  </li> */}

                            <li>
                                <a href={x.link} target="_blank" className='actionBtn'> &nbsp; <RemoveRedEyeIcon /> </a>

                                {x.signed ? <a href={x.link} target="_blank" className='actionBtn'> <DownloadIcon />  </a> : ""}

                            </li>
                        </ul>

                    </div>) : <p> No activity found!</p>}

            </div>


            {contractDrawer ? (<ContactDrawer
                open={contractDrawer}
                setcontractdrawer={setContractDrawer}
                contractdata={contractData}
                userinfo={props.folderdata}
                userid={props.queryParamId}
                className="contract-drawer"
                source={props.source}
            />) : ""}

            {contractPreviewModal && !contractDrawer ? (<ContractViewModal
                toogle={contractPreviewModal}
                tooglefunc={setContractPreviewModal}
                contractdata={contractTemplateData}
                userinfo={props.folderdata}
                source={props.source}
            />) : ""}

            {searchDraw ? <FDSearch
                open={searchDraw}
                close={setSearchDraw}
                formFields={searchFields}
                searchFields={searchFields}
                type="contract_Logs " /> : ""}


        </>
    )
}

export default ContractManage;






