import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function ErrorPage() {
    return (
        <>
            <div className='forEmployerThanks'>
                <div className='thankyou-container'>
                    <div className='thankyou-block'>
                        <div className='contentEmployer'>
                            <Box sx={{ flexGrow: 1, }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <div className="headerLogoEmployer">
                                            <img src="https://all-aspire-assets.s3.amazonaws.com/DealfirecrmlogoNew.png" alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h2>Coming Soon.....</h2>
                                    </Grid>

                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPage