import React, { useEffect, useRef, useState } from 'react'
import { Form } from '../../../form/Form'
import { Button, IconButton, LinearProgress, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close";
import { getContactData, manageDispositionTask } from './BlueCoastReducer';
import { setSnackbar } from '../../Layout/layoutReducer';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../store';
import { setReloadTableFlag } from '../../../listing/listReducer';
import { removeField, setField, setTempFormUpdateData } from '../../../form/formReducer';
import { cloneDeep } from 'lodash';



const AddTaskForm = ({ taskFormOpen, taskFormClose, setAddTaskModalOpen, editTaskbtn, editRowtaskData, taskaddcond }) => {




  const dispatch = useDispatch();
  // const [contactDataArray, setContactDataArray] = useState([])
  // const [contactData, setContactData] = useState(null)
  const [prospectData, setProspectData] = useState([])
  const [repIDProspect, setRepIDProspect] = useState({})
  const [formOpen, setFormOpen] = useState(false)

  const dispositionTaskData = useSelector((state) => state.formSlice?.formData?.addedittaskform && Object.keys(state.formSlice.formData.addedittaskform) ? state.formSlice.formData.addedittaskform
    : undefined);
  const taskSubmissionState = useSelector(state => (state.formSlice?.formSubmissionState?.addedittaskform) ? state.formSlice?.formSubmissionState?.addedittaskform : 0);

  const cancelButtonClicked = useSelector((state) => state.formSlice.customButtonClicked?.addedittaskform?.goback001 ? state.formSlice.customButtonClicked.addedittaskform.goback001 : false)
  let user = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice.userInfo : null)

  const [editeddata, setediteddata] = useState(null)
  // const repidforProspect = null

  const contactIdforTask = useSelector((state) => state.formSlice?.formData?.addedittaskform?.contact_id && Object.keys(state.formSlice.formData.addedittaskform?.contact_id) ? state.formSlice.formData.addedittaskform.contact_id
    : undefined);


  useEffect(() => {
    if (contactIdforTask) {
      // console.log("contactIdforTask=====>>>>>", contactIdforTask);
      if (taskFormNew.current.fields[4].reqBody.searchcondition) {

        taskFormNew.current.fields[4].reqBody.searchcondition.rep_id = contactIdforTask
        // console.log("contactIDjsdjdk===>>", taskFormNew.current.fields[4].reqBody.searchcondition);
      }
    }


  }, [contactIdforTask])


  useEffect(() => {
    if (dispositionTaskData && Object.keys(dispositionTaskData).length > 0) {
      setRepIDProspect(dispositionTaskData)
    }

  }, [dispositionTaskData])

  // console.log("repIDProspect==>>>", repIDProspect);

  // const [contcProctID, setContcProctID] = useState(null)


  // let arrforProspect = [];

  useEffect(() => {
    if (editRowtaskData) {
      // console.log("editRowtaskData===>>>>", editRowtaskData)
      setediteddata(editRowtaskData)
    }

  }, [JSON.stringify(editRowtaskData)])

  useEffect(() => {
    // console.log("editeddata=================", editeddata);
  }, [editeddata])


  // const [editTaskMOdal, setEditTaskMOdal] = useState(null)


  useEffect(() => {
    if (taskSubmissionState && taskSubmissionState === 4) {

      addapiTask(dispositionTaskData);
      setTimeout(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: "Task Added Successfully",
            status: "success",
          })
        )
      }, 1000)
      dispatch(setReloadTableFlag({ tableId: "alltaskdata" }))
      setRepIDProspect({})
      setProspectData([])

      // console.log("formInputData", dispositionFormData, dispositionFormData);
    }
  }, [taskSubmissionState]);



  const handleCLoseDispTask = () => {
    taskFormNew.current.fields[4].reqBody.searchcondition = {}
    taskFormClose(false)
    setFormOpen(false)
    // setEditTaskMOdal(null)
    setediteddata(null)
    dispatch(setReloadTableFlag({ tableId: "alltaskdata" }))
  }

  useEffect(() => {
    if (cancelButtonClicked) {
      handleCLoseDispTask()
    }

  }, [cancelButtonClicked])


  useEffect(() => {
    if (editTaskbtn == true && editeddata && Object.keys(editeddata).length > 0) {

      // console.log("editTaskbtn", editTaskbtn);

      // setTimeout(()=>{
      for (let i in taskFormNew.current.fields) {
        // console.log("editeddata[taskFormNew.current.fields[i].name]", taskFormNew.current.fields[i], editeddata[taskFormNew.current.fields[i].name]);
        // taskFormNew.current.fields[i]= { ...taskFormNew.current.fields[i], defaultValue: editeddata[taskFormNew.current.fields[i].name] }
        taskFormNew.current.fields[i].defaultValue = editeddata[taskFormNew.current.fields[i].name];
        setFormOpen(true)

        // console.log("find i", taskFormNew.current.fields[i]);
      }


    //  if(editeddata?.contact_id){
    //   console.log(editeddata?.contact_id, "contactID====>>>");
    //  } 


      taskFormNew.current.fields[3].preload = true;
      // taskFormNew.current.fields[4].preload = true;


      // },100)







      // for (let k in taskFormNew.current.fields) {
      //   if (editeddata[taskFormNew.current.fields[k].name]) {
      //     taskFormNew.current.fields[k].defaultValue =
      //       editeddata[taskFormNew.current.fields[k].name]
      //   }



      // }
    }

  }, [editTaskbtn, JSON.stringify(editeddata)])


  const addapiTask = async (val) => {
    // console.log("val>>>", val);
    // console.log("menuSelect",menuSelect);
    val = { ...val };
    let body = {
      tasktitle: val.tasktitle,
      taskdescription: val.taskdescription,
      tasktargetdate: val.tasktargetdate,
      assignee: val.assignee,
      contact_id: val.contact_id,
      prospect_id: val.prospect_id,
      createdon_datetime: new Date().getTime(),
      addedby: user._id,
      addedbyname: user.name,
      markasdone: 1,

      _id:
        editeddata && Object.keys(editeddata).length > 0
          ? editeddata._id
          : undefined,

    };

    // console.log("body===>>>", body);


    if (Object.keys(body).length > 0) {

      await store.dispatch(manageDispositionTask(body));

      for (let k in taskFormNew.current.fields) {
        let field = cloneDeep(taskFormNew.current.fields[k]);
        field.defaultValue = undefined;
        taskFormNew.current.fields[k] = field;

      }

      dispatch(
        setSnackbar({
          open: true,
          message:
            editeddata && Object.keys(editeddata).length > 0
              ? "Updated Successfully"
              : "Added Successfully",
          status: "success",
        })
      );

      setTimeout(() => { taskFormClose(false) }, 3000)
      setediteddata(null)
    }
  };


  // useEffect(() => {

  //   if ((repIDProspect?.contact_id) || editeddata?.prospect_id) {

  //     console.log("prospectData===>>>> hittt", prospectData, repIDProspect.contact_id);

  //     let rep_id = (repIDProspect?.contact_id) ? repIDProspect?.contact_id : (editeddata?.prospect_id) ? editeddata.prospect_id : undefined;
  //     console.log("rep_id", rep_id);

  //     let field = {

  //       id: 47,
  //       label: "Prospect",
  //       name: "prospect_id",
  //       type: "autoComplete",
  //       className: "list_search_item countDiv__column--col6 ",
  //       multiple: false,
  //       preload: true,
  //       // values: prospectData,
  //       base_url: process.env.REACT_APP_API_URL + "lead-manage/disposionsprospectfetch",
  //       reqBody: {
  //         "sort": {
  //           "field": "label",
  //           "type": "asc"
  //         },
  //         "searchcondition": {
  //           "rep_id": rep_id,
  //           "label": {
  //             "$regex": "",
  //             "$options": "i"
  //           }
  //         },
  //         "project": {},
  //         "token": "",
  //         "autoCompleteId": "contact_id"

  //       },
  //       sx: { m: 1, minWidth: 300 },
  //       defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.prospect_id !== undefined) ? editeddata.prospect_id : '',

  //       // payloadFormat: { key: "prospect_id" },

  //     }
  //     setTimeout(() => {
  //       dispatch(setField({ formId: "addedittaskform", after: "contact_id", fieldData: field }))
  //     }, 1000);
  //   } else if ((repIDProspect && Object.keys(repIDProspect).length > 0 && !repIDProspect.contact_id) || (editeddata && Object.keys(editeddata).length > 0 && !editeddata.contact_id)) {
  //     setTimeout(() => dispatch(removeField({ formId: "addedittaskform", name: "prospect_id" })), 2000)
  //   }

  // }, [repIDProspect, JSON.stringify(editeddata)])



  const taskFormNew = useRef({

    id: 'addedittaskform',
    api_url: process.env.REACT_APP_API_URL,
    endPointBodyWrapper: " ",
    submitBtnName: "Save",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineCLass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {},


    fields: [
      {
        id: 0,
        heading: "Task Title",
        label: "Task Title",
        name: "tasktitle",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: (editeddata && editeddata.tasktitle !== undefined) ? editeddata.tasktitle : undefined,
        defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.tasktitle !== undefined) ? editeddata.tasktitle : undefined,


      },
      {
        id: 1,
        // heading: "Task Description",
        label: "Task Description",
        name: "taskdescription",
        className: 'formfild formfild4 dispField',
        type: "textarea",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.taskdescription !== undefined) ? editeddata.taskdescription : undefined,

      },
      {
        id: 2,
        label: "Assignee",
        heading: "Assignee",
        name: "assignee",
        type: "autoComplete",
        preload: true,
        className: 'inputBlock inputBlock3line form_item2',
        multiple: false,
        base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforspecialentities",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {}
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.assignee !== undefined) ? editeddata.assignee : "",
        // payloadFormat: { key: "assignee" },

      },

      {
        id: 3,
        heading: "Contact",
        label: "Contact",
        name: "contact_id",
        className: 'inputBlock inputBlock2line form_item2',
        type: "autoComplete",
        multiple: false,
        // preload: true,
        // multiple: true,
        preload: false,
        base_url: process.env.REACT_APP_API_URL + "lead-manage/disposionsrepfetch",
        reqBody: {

          "sort": {
            "field": "label",
            "type": "asc"
          },
          "searchcondition": {},
          "project": {

          },
          "token": ""
        },
        sx: { m: 1, minWidth: 300 },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },

        defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.contact_id !== undefined) ? editeddata.contact_id : undefined,


      },

      {
        id: 4,
        heading: "Prospect",
        label: "Prospect",
        name: "prospect_id",
        type: "autoComplete",
        className: "list_search_item countDiv__column--col6 form_item2",
        multiple: false,
        preload: true,
        // values: prospectData,
        base_url: process.env.REACT_APP_API_URL + "lead-manage/disposionsprospectfetch",
        reqBody: {
          "sort": {
            "field": "label",
            "type": "asc"
          },
          "searchcondition": {
            // "rep_id": contactIdforTask?contactIdforTask:undefined,
            "rep_id": contactIdforTask !== undefined ? contactIdforTask : undefined,
            "label": {
              "$regex": "",
              "$options": "i"
            }
          },
          "project": {},
          "token": "",
          "autoCompleteId": "contact_id"

        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.prospect_id !== undefined) ? editeddata.prospect_id : '',
      },

      {
        id: 5,
        heading: "Due Date",
        label: "Due Date",
        name: "tasktargetdate",
        className: "datePicker list_search_item countDiv__column--col6 dispField taskLabel form_item2",
        type: "dateTimePicker",
        sx: { m: 1, width: 300 },
        // defaultValue: new Date().getTime(),
        minDate: new Date().getTime(),
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.tasktargetdate !== undefined) ? editeddata.tasktargetdate : undefined,
      },
    ],

    customButtons: [
      {
        id: 'goback001',
        label: 'Cancel',
        className: 'default',
        type: 'button',
      },
    ]
  })


  return (
    <>
      <Modal
        className="addFormBlueCOast addtaskListMOdal SR_addTask_list"
        open={taskFormOpen}
        onClose={handleCLoseDispTask}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={handleCLoseDispTask}
              // className="CloseModal"
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {/* <p> uui {JSON.stringify(editeddata)}</p> */}
          <div className='taskMOdal_wrp'>
            <h2 className="previewModalTitle ">
              <div className="modal_title modal_titlenew">
                {editeddata && Object.keys(editeddata).length > 0
                  ? "Edit Task"
                  : "Add Task"}
              </div>
              {/* {JSON.stringify(taskaddcond)} */}
            </h2>
            {/* {!taskaddcond && editeddata && Object.keys(editeddata).length > 0 && editeddata?._id  ? <Form formData={taskFormNew} /> : !editeddata?._id && <Form formData={taskFormNew} />} */}

            {formOpen && editeddata && Object.keys(editeddata).length > 0 && editeddata?._id && <Form formData={taskFormNew.current} />}
            {!editeddata?._id && <Form formData={taskFormNew.current} />}
            {/* <p>{JSON.stringify(taskFormNew.current)}</p> */}
            {/* 
            {id !== undefined && editdata && (editdata.length > 0 || Object.keys(editdata).length > 0) ? <> <Form formData={dataform} /> </> : !id && <Form formData={dataform} />} */}

          </div>
          {/* {dispostiontaskLoader ? (
                <Box
                  className="loaderProgress"
                  sx={{ width: "100%", mt: "10px" }}
                >
                  <LinearProgress />
                </Box>
              ) : (
                ""
              )} */}
        </Box>
      </Modal>

    </>
  )
}

export default AddTaskForm
