import { Chip, IconButton, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const PreviewModal = (props) => {
    const rowDataEvents = useSelector((state) => state.tableSlice?.tempRowData?.upcomingEvents ? state.tableSlice.tempRowData.upcomingEvents : null);
    const [saveRowVents, setSaveRowVents] = useState(null)
    useEffect(() => {
        if (rowDataEvents !== null && rowDataEvents !== undefined) {
            setSaveRowVents(rowDataEvents)
        }
    }, [JSON.stringify(rowDataEvents)])
    useEffect(() => {
        console.log("saveRowVents====>>>>>", saveRowVents);
    }, [saveRowVents]);

    const handleClosePreview = () => {
        props.setPreviewOpen(false)
    }
    return (
        <>
            <div>
                <Modal
                    className="modalBaseStyle  preview_details previewSubLogin"
                    open={props.previewOpen}
                    onClose={handleClosePreview}
                >
                    <Box className="modalBaseBox mediumModal">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleClosePreview}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className="view_details">
                            <div className="modal_title modal_titlenew SD_bluecoast_preview">
                                <h2 className="previewModalTitle ">Event Details Preview</h2>
                            </div>
                            <div className="modalview_group">
                                {saveRowVents ? (
                                    <div className="data_container data_containerscroll">
                                        <div className="data_block">
                                            {saveRowVents && (
                                                <div className="property_container">
                                                    <label className="property_label">Organiser Name:</label>
                                                    <label className="property_value">
                                                        <span dangerouslySetInnerHTML={{ __html: saveRowVents.username }}></span>
                                                    </label>
                                                </div>
                                            )}
                                            {/* {saveRowVents?.useremail && (
                                                <div className="property_container">
                                                    <label className="property_label">Organizer Email:</label>
                                                    <label className="property_value">
                                                        {saveRowVents.useremail}
                                                    </label>
                                                </div>
                                            )} */}
                                            {saveRowVents?.event_title && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Event Title:
                                                    </label>
                                                    <label className="property_value">
                                                        {saveRowVents.event_title}
                                                    </label>
                                                </div>
                                            )}

                                            {saveRowVents?.attendee_details && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Attendee Details:
                                                    </label>
                                                    <label className="property_value">
                                                        <span dangerouslySetInnerHTML={{ __html: saveRowVents.attendee_details }}></span>
                                                    </label>
                                                </div>
                                            )}
                                            {saveRowVents?.start_end_time_human && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Meeting Time:
                                                    </label>
                                                    <label className="property_value">
                                                        {saveRowVents.start_end_time_human}
                                                    </label>
                                                </div>
                                            )}
                                            {saveRowVents?.disposition_show && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Last Disposition:
                                                    </label>
                                                    <label className="property_value">
                                                        <span dangerouslySetInnerHTML={{ __html: saveRowVents.disposition_show }}></span>
                                                    </label>
                                                </div>
                                            )}
                                            {saveRowVents?.start_datetime_unix && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Appointment Date:
                                                    </label>
                                                    <label className="property_value">{format(
                                                        saveRowVents.start_datetime_unix,
                                                        "dd-MM-yyyy"
                                                    )}
                                                    </label>
                                                </div>
                                            )}
                                            {/* {saveRowVents?.start_date_format && (
                                                <div className="property_container">
                                                    <label className="property_label">Date:</label>
                                                    <label className="property_value">
                                                        {saveRowVents.start_date_format}
                                                    </label>
                                                </div>
                                            )} */}

                                            {/* {saveRowVents?.timespan && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Timespan(minutes):
                                                    </label>
                                                    <label className="property_value">
                                                        {saveRowVents.timespan}
                                                    </label>
                                                </div>
                                            )} */}
                                            {saveRowVents?.booking_time_unix && (
                                                <div className="property_container">
                                                    <label className="property_label">Booked On:</label>
                                                    <label className="property_value">
                                                        {format(
                                                            saveRowVents.booking_time_unix,
                                                            "dd-MM-yyyy"
                                                        )}
                                                    </label>
                                                </div>
                                            )}

                                            {saveRowVents?.notes && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Notes:
                                                    </label>
                                                    <label className="property_value">
                                                        {saveRowVents.notes}
                                                    </label>
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                ) : (
                                    <div className="nodatafound">No Records Found</div>
                                )}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default PreviewModal