import React, { useEffect, useRef, useState } from "react";
import LandingPage13Form from "./LandingPage13Form";
import ReactPlayer from "react-player";
import Landingpageblock1 from "./Components/Landingpageblock1";
import Aslandingpageblock10 from "./Components/Aslandingpageblock10";
import Aslandingpageblock11 from "./Components/Aslandingpageblock11";
import LandingpageblockAD1 from "./Components/LandingpageblockAD1";
import { Button, IconButton, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import MedCatList from "../../../Backend/MedCatOnlyList/MedCatList";
import Landingpageblock5 from "./Components/Landingpageblock5";
import Landingpageblock4 from "./Components/Landingpageblock4";
import Landingpageblock9 from "./Components/Landingpageblock9";
import PharmacyCoachingBlock from "./Components/PharmacyCoachingBlock";
import LazyImage from "./Components/LazyImage";
import { MedicineListing } from "../../../Backend/Medicine/MedicineReducer";
import { useDispatch, useSelector } from "react-redux";
import Landingpageblock12 from "./Components/Landingpageblock12";
import { useParams } from "react-router-dom";

function LandingPage13() {
  const scrollToview = useRef();
  const dispatch = useDispatch()
  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };
  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
  const username = useParams();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [openSearch, SetopenSearch] = React.useState(false);
  const handleClose = () => { setOpen(false); scrollToview.current.scrollIntoView({ behavior: "smooth" }); }
  const [modal, setModal] = React.useState(false);
  useEffect(() => {
    setOpen(true)


    setTimeout(() => {
      setModal(true)
    }, 3000)
    // return () => {
    //   second
    // }
  }, [])
  const handleSearchopen = () => {
    setOpen(false)
    SetopenSearch(true)

  }

  useEffect(() => {

    setTimeout(() => {
      setOpen2(true)
      console.log("setOpen2----------", open2);
    }, 3000)


  }, [open2])


  // let reqBody= {

  //   // "source": "training_list",
  //   "condition": {
  //     "limit": 30,
  //     "skip": 0
  //   },
  //   "sort": {
  //     "field": "createdon_datetime",
  //     "type": "desc"
  //   },
  //   "searchcondition": {

  //   },
  //   "project": {},
  //   "token": "",
  //   "email": "",

  //   "count": false,



  // }
  // useEffect(()=>{

  //   dispatch(MedicineListing(reqBody));
  // },[])






  return (

    <>
      <div className="popup_wrapper2">
        <img loading="lazy" src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/popup_modal_img.webp" />
      </div>
      {/* <Button variant="contained" onClick={()=>setOpen(true)}>MEDICINE AND CATEGORY LISTING</Button> */}
      <div>
        {/* <Modal
            className="modalBaseStyle allMeetingMOdal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className='modalBaseBox mediumModal' >
              <Tooltip title='Close' >
                <IconButton onClick={handleClose}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
                <div>
                <MedCatList/>
                </div>

            </Box>
          </Modal> */}
      </div>

      <div className="landingpage5_whole_cont landingpage13_main_wrapper">
        <div className="landing_page5_bannerblock">
          <div className="landing-container">
            <div className="landing-col-container">
              <div className="landing-banner-left">
                <div className="lpage_logowrapper">
                  <img
                    src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}access_discount_logo.webp`}
                  />
                </div>

                <div className="l5_videowrapper Videolanding3">
                  <div className="l5_vdo_txt_wrp gradiant_fontBW ">
                    <h2>Pay Nothing For The Most Prescribed Generic Prescriptions!</h2>
                  </div>

                  <div className="lpage_banner_sub_wrapper">
                    <div className="landpage_videowrapper">
                      <div className="landpage_video_subwrapper">
                        <ReactPlayer
                          className="page5_react_player"
                          // url="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/AspirePharma_banner_video.mp4"
                          url={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}AspirePharma_banner_video.mp4`}
                          light={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}banner_video_img.webp`}
                          playing={true}
                          controls={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lpage_arrow_wrapper">
                  <h2>
                    Enroll today to gain access to over{" "}
                    <span>605 medications for free at</span> one{" "}
                    <span>low membership price!</span>
                  </h2>
                </div>
              </div>
              <div ref={scrollToview}></div>
              <LandingPage13Form scrollToview={scrollToview} />
            </div>
          </div>
        </div>
      </div>

      <Landingpageblock1 scrollToview={scrollToview} />
      <LandingpageblockAD1 scrollToview={scrollToview} SetopenSearch={SetopenSearch} openSearch={openSearch} />


      {/* <div className="landingpage13_block3_main">
        <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/landingpage13_block3new_img.webp" />
      </div> */}
      <Landingpageblock4 />
      <Landingpageblock5 scrollToview={scrollToview} />
      {open2 && <Landingpageblock12 />}
      {open2 && <PharmacyCoachingBlock />}
      {open2 && <Landingpageblock9 />}
      {open2 && <Aslandingpageblock10 />}
      {open2 && <Aslandingpageblock11 />}


      <div className="landingpage13_block7_main">
      </div>

      <div className="delivery_block">
        <div className='SD_common_wrapper'>
          <div className='SD_flex13 SD_font25'>
            <div className='SD_imagewrapper13'>
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/lamdingpage13_delivery_1block_img.webp'/> */}
            </div>
            <div className='SD_textwrapper13'>
              <h2>“Members can get a 90-Day Prescription for $0 Shipped for Free!”</h2>
              <button onClick={() => setScroll()}>Enroll today to gain access to your free medication!</button>
            </div>

          </div>
        </div>

      </div>
      <div className="lp13_lastblock_mainwrapper">
        <div className="lp13_lastblock">
          <div className="lp13_lastblock_sub_txt_wrapper1">
            <p>Please call our Customer Support team at {userData?.agent_code ?
              <strong><a href="tel:1-800-378-4025">1-800-378-4025</a></strong> : <strong> <a href="tel:1-800-378-4025">1-800-378-4025</a></strong>} if you have any questions. Enrollment Code: "{userData.agent_code ? userData.agent_code : "N/A"}"</p>
            {/* <h1>THIS IS NOT INSURANCE </h1> */}
          </div>
          <div className="lp13_lastblock-img_wrapper">
            {/* {`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}banner_video_img.webp`} */}
            {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/as_lp-13_lastblock_img.png" /> */}
            <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/as_lp-13_lastblock_img.png`} />
            {/* <img src= "https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/as_lp-13_lastblock_img.png" /> */}
          </div>
        </div>
      </div>
      <div className='land_footer_block13'>
        <p>© 2023 Access discount healthcare. All Right Reserved</p>
        <h2>This is not insurance</h2>

        {(userData?.is_nakagames === true) &&
          <a href={`/nakagames/${userData?.user_name}`} target='_blank' className='linked_btn'>
            <button className=' custom_red_btn'>
              join as an affiliate

            </button>
          </a>}
      </div>


      <Modal
        className="modalBaseStyle allMeetingMOdal  MOdallanding13 SC_popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={handleClose}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {/* <div className="popup_wrapper">
            <LazyImage src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/popup_modal_img.webp" />
            <img loading="lazy" src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/popup_modal_img.webp" />
            {modal && <button onClick={handleClose} className="popup_btn">SIGN UP</button>}
          </div> */}
          <div className="popup_main_wrapper">
            <div className="popup_main_wrapper_block">
              <div className="popup_main_wrapper_sub_1block">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/popup_main_wrapper_sub_1block.png" />

              </div>
              <div className="popup_main_wrapper_sub_2block">
                <h2>MONTHLY SAVINGS PLAN</h2>
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/popup_main_wrapper_sub1block.png" />
                <div className="sign_up_wrapper">
                  {modal && <Button onClick={handleClose}>SIGN UP</Button>}
                </div>
              </div>
            </div>
            <div className="popup_main_1wrapper_block">
              <div className="popup_main_1wrapper_sub_1block">
                <p>Pay $0 for your medications</p>
              </div>
              <div className="popup_main_1wrapper_sub_2block">
                <p><span>600+</span><strong>Free </strong>Medications</p>
                <h5>ACCESS TO 125 ACUTE, 480 CHRONIC, & DIABETES MEDICATIONS</h5>
                <div className="see_our_full_list_wrapper">
                  <Button onClick={handleSearchopen}>SEE OUR FULL LIST!</Button>

                </div>
              </div>
              <div className="popup_main_1wrapper_sub_3block">
                <p>No limit on your orders/refills</p>
              </div>
              <div className="popup_main_1wrapper_sub_4block">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/Untitled-4448894s.png" />
              </div>
              <div className="popup_main_1wrapper_sub_5block">
                <p>Add new covered medications anytime!</p>
              </div>
              <div className="popup_main_1wrapper_sub_6block">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/Untitled-4448894s.png" />
              </div>
              <div className="popup_main_1wrapper_sub_3block">
                <p>Licensed in all 50 States</p>
              </div>
              <div className="popup_main_1wrapper_sub_4block">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/Untitled-4448894s.png" />
              </div>
              <div className="popup_main_1wrapper_sub_5block">
                <p>FREE standard shipping</p>
              </div>
              <div className="popup_main_1wrapper_sub_6block">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/Untitled-4448894s.png" />
              </div>
              <div className="popup_main_1wrapper_sub_3block">
                <p>Experienced team of customer service and licensed pharmacists</p>
              </div>
              <div className="popup_main_1wrapper_sub_4block">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/Untitled-4448894s.png" />
              </div>
            </div>
          </div>


        </Box>
      </Modal>



    </>
  );
}

export default LandingPage13;
