import { Button, IconButton, InputAdornment, LinearProgress, Modal, TextField, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useDispatch,useSelector } from 'react-redux'
import { setSnackbar } from '../../Layout/layoutReducer';
import { blueCoastaddunderpayment, clearUnderPaymentData } from './BlueCoastReducer';
import { deleteTempSearchEndpoint, setReloadTableFlag } from '../../../listing/listReducer';

const PaymentOpportunityUnderpayment  = ({rowdata,paymentUnderPaymentModaOpenl,setPaymentUnderPaymentModaOpenl,setForLoading,setPaymentRowData}) => {

  const [loaderUnderForPayment, setLoaderUnderForPayment] = useState(null);
  const [underPaymentValue, setunderPaymentValue] = useState(null);
     

    const dispatch=useDispatch()

    const underPaymentData = useSelector((state) => state.BlueCoastSlice?.underPaymentData?.under_payment ? state.BlueCoastSlice.underPaymentData.under_payment : null)

    const handleChangeUnderPayment = async (event) => {
        setLoaderUnderForPayment(true)
    
        await dispatch(blueCoastaddunderpayment({ under_payment: underPaymentValue, "addedfor": rowdata._id, source: "bluecoast_leads", }))
        setLoaderUnderForPayment(false)
    
        dispatch(setSnackbar({ open: true, message: 'Under Payment opportunity  added  Successfully', status: 'success' }))
        dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }))
      };


    const CloseUnderPaymentModaOpenl = () => {
        setForLoading(true)
        setPaymentRowData({})
        setPaymentUnderPaymentModaOpenl(false);
        dispatch(clearUnderPaymentData())
        // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata"}))
      };

    

  return (
    <div> 

<Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast"
        open={paymentUnderPaymentModaOpenl}
        onClose={CloseUnderPaymentModaOpenl}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={CloseUnderPaymentModaOpenl}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className='priority_Box_Main'>
            <h2 className='previewModalTitle '>

              {rowdata?.prospectfullname && <div className='modal_title modal_titlenew'> Underpayment Opportunity for : {rowdata?.prospectfullname}</div>}
            </h2>
            <Box sx={{ minWidth: 120 }} className="mpdal_healper">
              <TextField
                id="under_payment"
                label="Underpayment Opportunity "
                value={underPaymentValue}
                defaultValue={underPaymentData !== null ? underPaymentData : undefined}
                fullWidth
                type="number"
                onChange={(e) => {
                  setunderPaymentValue(Number(e.target.value));
                }}
                // onChange={handleChangeUnderPayment} 

                helperText="This should be dollar amount"



                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              // variant="standard"


              />

            </Box>
            <div className='Formsubmit_button modal_sbBtn'>
              <Button variant="contained" onClick={() => handleChangeUnderPayment()}>Submit</Button>
            </div>
            {loaderUnderForPayment && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}

          </div>
        </Box>
      </Modal>

    </div>
  )
}

export default PaymentOpportunityUnderpayment 