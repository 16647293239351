import { Button, Card, CardActions, CardContent, Chip, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from '../../../../form/Form';
import store from '../../../../store';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addlContactDataEach, deletecontactdata } from '../EmployerReducer';
import { resetForm, resetFormData, resetFormFieldDataForAuto, resetFormFieldDataForAutoClear, resetFormResetFlag, resetexternalSeachReset, setexternalSeachReset } from '../../../../form/formReducer';


function AdditionalCompanyContact({ addlcontactOpen, setAddlcontactOpen, added_by }) {
    const dispatch = useDispatch()

    const addlCompanyCOntactSubmissionState = useSelector(state => (state.formSlice?.formSubmissionState?.addaddlCompanyTable) ? state.formSlice?.formSubmissionState?.addaddlCompanyTable : 0);
    const addaddlContactFormData = useSelector((state) => state.formSlice.formData.addaddlCompanyTable ? state.formSlice.formData.addaddlCompanyTable : null);

    let contactCompanyData = useSelector((state) => state.EmployerReducer.addlContactData ? state.EmployerReducer.addlContactData : []);

    // const [addcmpny, setAddcmpny] = useState(false)


    useEffect(() => {
        // console.log("added_by=========>>>>", added_by);
    }, [added_by])


    const handleCloseAddcontactClose = () => {
        setAddlcontactOpen(false)
    }

    const [objSet, setObjSet] = useState([])

    useEffect(() => {
        // console.log("setObjSet=====>>>", objSet);
    }, [JSON.stringify(objSet)])

    // let body = []

    const addaddlContactDetails = async (val) => {
        // console.log("val>>>", val);
        // let Obj = val
        //   let  newArray = [ {...val},val ];
        let body = {
            firstname: val.firstname.trim(),
            lastname: val.lastname.trim(),
            email: val.email.trim(),
            phone: val.phone,
            address: val.address.trim(),
            added_type: "Manual",

        }
        setObjSet({ ...body }, body)


        // console.log("body==========>>>>", body)



        if (Object.keys(body).length > 0) {
            // console.log("fieldIndex", fieldIndex);
            let Data = cloneDeep(contactCompanyData)
            if (fieldIndex == null) {

                await store.dispatch(addlContactDataEach([body, ...Data]));
            } else {
                for (let i in Data) {
                    // console.log("iiiiiiiiiiii", Data[i], fieldIndex, i);

                    if (i == fieldIndex) {
                        // console.log("hit", fieldIndex, body);

                        Data[i]["added_type"] = body.added_type
                        Data[i]["address"] = body.address
                        Data[i]["email"] = body.email
                        Data[i]["firstname"] = body.firstname
                        Data[i]["lastname"] = body.lastname
                        Data[i]["phone"] = body.phone
                    }
                }

                await store.dispatch(addlContactDataEach([...Data]));

                setFieldIndex(null)

            }


            // console.log("body------------>>>>", body)fieldIndex

            // for (let k in addformaddlContactDetails.current.fields) {

            //     console.log("test==========>>>>", addformaddlContactDetails.current.fields);

            //     let field = cloneDeep(addformaddlContactDetails.current.fields[k]);
            //     field.defaultValue = undefined;
            //     addformaddlContactDetails.current.fields[k] = field;
            // }

        }



        dispatch(resetFormFieldDataForAuto({ formId: "addaddlCompanyTable", name: "restform" }));
        setTimeout(() => {
            dispatch(
                resetFormFieldDataForAutoClear({ formId: "addaddlCompanyTable", name: "restform" })
            );
        }, 1000);

    };

    // *********************add EMployee Form Submission state*******************

    useEffect(() => {
        if (addlCompanyCOntactSubmissionState && addlCompanyCOntactSubmissionState === 4) {

            addaddlContactDetails(addaddlContactFormData);

            // console.log("addaddlContactFormData================>>>>", addaddlContactFormData)

            // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }))
            //    dispatch(resetFormFieldDataForAutoClear(addformaddlContactDetails.current.fields))


        }
    }, [addlCompanyCOntactSubmissionState]);

    const [editAddlData, setEditAddlData] = useState(null)
    const [refreshflag, setrefreshflag] = useState(true)


    // useEffect(() => {
    //     if (editAddlData) {
    //         setrefreshflag(true);
    //         setTimeout(() => {
    //             setrefreshflag(false);
    //         }, 10);
    //     }
    // }, [JSON.stringify(editAddlData)])

    const [editbuttonstate, setEditbuttonstate] = useState(false)

    const [fieldIndex, setFieldIndex] = useState(null)


    const editClickedCard = (i, idx) => {
        // setEditAddlData(i)

        setFieldIndex(idx)
        // console.log("testVal============>>>>>>", i, idx)

        setEditbuttonstate(true)

        addformaddlContactDetails.current.fields[0].defaultValue = i.firstname
        addformaddlContactDetails.current.fields[1].defaultValue = i.lastname

        addformaddlContactDetails.current.fields[2].defaultValue = i.email
        addformaddlContactDetails.current.fields[3].defaultValue = i.phone
        addformaddlContactDetails.current.fields[4].defaultValue = i.address
        // console.log("testVal============>>>>>>123", addformaddlContactDetails.current.fields)

        // let newDataField = [...fieldIndex]



        setrefreshflag(false);
        setTimeout(() => {
            setrefreshflag(true);
        }, 100);

    }

    useEffect(() => {

        console.log("testVal============>>>>>>", editbuttonstate)

    }, [editbuttonstate])


    // ===========================Add employee form============================

    const addformaddlContactDetails = useRef({
        id: 'addaddlCompanyTable',
        formtype: "add",
        endPointBodyWrapper: "",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "Formsubmit_button",
        submitBtnName: "Submit",
        resetBtnName: "Reset",
        // resetForm: true,

        formAdditionalSubmissionData: {
            // _id: (editEmployee?._id !== null && editEmployee?._id !== undefined) ? editEmployee._id : null
            added_by: added_by
        },

        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                heading: "First Name",
                label: "First Name",
                name: "firstname",
                className: 'form_item formfild inputBlock inputBlock2line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: undefined,

                // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.firstname !== undefined && editEmployee.firstname !== null) ? editEmployee.firstname : undefined,

            },

            {
                id: 1,
                heading: "Last Name",
                label: "Last Name",
                name: "lastname",
                className: 'form_item formfild inputBlock inputBlock2line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue:  undefined,
                // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.lastname !== undefined && editEmployee.lastname !== null) ? editEmployee.lastname : undefined,


            },

            {
                id: 2,
                heading: "Email",
                label: "Email",
                name: "email",
                className: "form_item formfild inputBlock inputBlock2line",
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                },
                defaultValue:  undefined,
                // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.email !== undefined && editEmployee.email !== null) ? editEmployee.email : undefined,
            },


            // {
            //     id: 31,
            //     heading: "Phone Number",
            //     label: "Phone Number Format: (123) 456-7890",
            //     name: "phone",
            //     className: "form_item formfild inputBlock inputBlock2line",
            //     type: "text",
            //     inputType: "phone",
            //     // rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid",
            //         pattern: "Phone no does not contain any special characters (@#%&$)",
            //         maxLength: "Phone number can not be greater than 10 digits",
            //     },
            //     defaultValue: (editAddlData && editAddlData?.phone !== undefined && editAddlData?.phone !== null) ? editAddlData.phone : undefined,
            //     // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.phone !== undefined && editEmployee.phone !== null) ? editEmployee.phone : undefined,
            // },

            {
                id: 9,
                heading: "Phone",
                label: "Phone",
                name: "phone",
                className: 'inputBlock inputBlock2line',
                type: "text",
                inputType: "phone",/////////////// If not mentioned default will be text //////////////
                // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

                defaultValue: undefined
            },

            {
                id: 4,
                // heading: "Address",
                label: "Address",
                name: "address",
                className: "form_item form_item inputBlock inputBlock1line",
                type: "textarea",
                inputType: "address",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                },
                defaultValue:  undefined,
                // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.address !== undefined && editEmployee.address !== null) ? editEmployee.address : undefined,
            },
        ]

    });










    return (
        <>
            <Modal
                className="add_Employee_Modal addtaskListMOdal addAdditional_contact"
                open={addlcontactOpen}
                onClose={handleCloseAddcontactClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseAddcontactClose}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className='dataform2_wrapper'>
                        <div className='adminformbody'>
                            <h2 className="previewModalTitle ">
                                <div className='modal_title modal_titlenew'>
                                    {editbuttonstate === true ? <h4>Edit Additional Company Contact</h4> : <h4>Add Additional Company Contact</h4>}
                                </div>
                            </h2>
                            {/* {JSON.stringify(editAddlData)} */}
                            <div className="inputBlock inputBlock3line disposition_task">
                                <Box sx={{ minWidth: 120 }}>
                                    <div className='otherForm_mainWrpr'>

                                        {refreshflag == true && <Form formData={addformaddlContactDetails.current} />}

                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>


                    <div className='addlContactDtls_mainwrpr'>

                        {contactCompanyData.length > 0 && contactCompanyData.map((i, idx) => <Card sx={{ position: "relative" }} className='addlContactDtls' key={idx}>
                            <Tooltip title="Close">
                                <IconButton
                                    onClick={() => dispatch(deletecontactdata(i))}
                                    className="modal_icon_button"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() => editClickedCard(i, idx)}
                                    className="modal_icon_button"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                                    <p>Name: {i.firstname + " " + i.lastname}</p>
                                    <p>Email: {i.email}</p>
                                    <p>Phone: {i.phone}</p>
                                    <p>Address: {i.address}</p>
                                </Typography>

                            </CardContent>

                        </Card>)
                        }
                    </div>
                </Box>


            </Modal>
        </>
    )
}

export default AdditionalCompanyContact