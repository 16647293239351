import React, { useRef } from 'react'
import "../LandingPage13.css";

function Landingpageblock5({scrollToview}) {
    const setScroll = () => {
        scrollToview.current.scrollIntoView({ behavior: "smooth" });
    };
  return (
    <>
    <div className='landingpage13_block5_main_wrapper'>
      <div className='landingpage5_bannerblock3'>

<div className='landing-container'>
    <div className='landingpage5_bannerblock3_subwrapper'>
        <div className='landing-col-container3'>
            {/* <div className='landingpage5_bannerblock3_firstblock gradiant_fontBW gradiant_darkBG'>
                <h2>We offer the 605 most prescribed drugs in America for free!</h2>

            </div> */}
            {/* <div className='landingpage5_bannerblock3_secondblock'>
                <div className='landingpage5_bannerblock3_secondblock_leftsection'>
                    <p><span>125</span> Generic Acute <br />
                        Medications<strong> (Immediate Need)</strong> </p>

                </div>
                <div className='landingpage5_bannerblock3_secondblock_leftsection'>
                    <p><span>480</span> Generic Chronic <br />
                        Medications<strong> (Maintenance)</strong></p>

                </div>
            </div> */}
            <div className='landingpage5_bannerblock3_thirdblock'>
                <h2>Here is a sample of the medications on our list</h2>
                {/* <div className="hr"></div> */}
                <ul>
                    <li>ACYCLOVIR</li>
                    <li>ALLOPURINOL</li>
                    <li>AMITRITYLINE</li>
                    <li>AMLODIPINE</li>
                    <li>AMOXICILLIN</li>
                    <li>ANASTRAZOLE</li>
                    <li>ATENOLOL</li>
                    <li>ATORVASTATIN</li>
                    <li>AZITHROMYCIN</li>
                    <li>AMLODIPINE</li>
                    <li>BUPROPION</li>
                    <li>CARVEDILOL</li>
                    <li>CEFDINIR</li>
                    <li>CELECOXIB</li>
                    <li>CEPHALEXIN</li>
                    <li>CIPROFLOXACIN</li>
                    <li>CITALOPRAM</li>
                    <li>CLOPIDOGREL</li>
                    <li>CYCLOBENZAPRINE</li>
                    <li>DICLOFENAC</li>
                    <li>DILTIAZEM</li>
                    <li>DOXYCYCLINE</li>
                    <li>DULOXETINE</li>
                    <li>ESCITALOPRAM</li>
                    <li>ESTRADIOL</li>
                    <li>EZETIMIBE</li>
                    <li>FENOFIBRATE</li>
                    <li>FINASTERIDE</li>
                    <li>FLUCONAZOLE</li>
                    <li>FLUOXETINE</li>
                    <li>GLIMEPIRIDE</li>
                    <li>GLIPIZIDE</li>
                    <li>HYDRALAZINE</li>
                    <li>IBUPROFEN</li>
                    <li>LAMOTRIGINE</li>
                    <li>LEVOTHYROXINE</li>
                    <li>LIOTHYRONINE</li>
                    <li>LISINOPRIL</li>
                    <li>LISINOPRIL/HCTZ</li>
                    <li>LOSARTAN</li>
                    <li>MELOXICAM</li>
                    <li>METFORMIN</li>
                    <li>METHOTREXATE</li>
                    <li>METOPROLOL</li>
                    <li>MONTELUKAST</li>
                    <li>OMEPRAZOLE</li>
                    <li>PANTOPRAZOLE</li>
                    <li>PAROXETINE</li>
                    <li>PRAVASTATIN</li>
                    <li>PROGESTERONE</li>
                    <li>ROSUVASTATIN</li>
                    <li>SERTRALINE</li>
                    <li>SIMVASTATIN</li>
                    <li>SILDENAFIL</li>
                    <li>TAMSULOSIN</li>
                    <li>TRAMADOL</li>
                    <li>TRAZODONE</li>
                    <li>TRIAMCINOLONE</li>
                    <li>VALACYCLOVIR</li>
                    <li>VENLAFAXINE</li>

                </ul>

            </div>




        </div>


    </div>
    <button onClick={() => setScroll()} className='landingpage5_bannerblock3_fourblock gradiant_fontBW gradiant_black_darkBG'>
        <h2>Enroll today to gain access to your free medication!</h2>

    </button>

</div>
</div>
</div>
    </>
  )
}

export default Landingpageblock5