import React, { useState } from 'react'
import "../Components/LandingPage14.css"
import LandingPage14FormModal from '../LandingPage14FormModal';
import { setscrollView } from '../../../../Layout/layoutReducer';
import { useDispatch } from 'react-redux';

function Lp14block9({page}) {

  const [openModal, setopenModal] = useState(false);

  const modalOpen = () => {
    setopenModal(true);
  };


  const setsScroll = () => {
    dispatch(setscrollView("scroll"));
}
const dispatch = useDispatch();


  return (
    <>
      <div className='Lp14block9_main_wrapper'>
        <div className='landingpage14_common_width'>
          <div className='Lp14block9_sub_wrapper'>
            <h1>Stop Paying For Prescriptions…It’s Easy!</h1>
            <p>At Access Discount Healthcare we have made it our mission to help the uninsured and underinsured (people who have
              insurance but whose deductibles are so high they never meet them, and they are paying full price for medicine). With
              decades of relationships, partnerships, and buying power, we have pooled our resources and are bringing you the lowest
              prices on medications in the country. </p>
          </div>
          <hr></hr>
          <div className="Lp14_block9_bottom_main">
            <div className="Lp14_block9_bottom_sub_left">
              <h1>The Program Includes</h1>
              <ul>
                <li>A FREE Premium bilingual voice-response glucometer</li>
                <li>3-month supply of test strips</li>
                <li>Supply of lancets. lancing device, and control 180 solution</li>
                <li>No out-of-pocket on diabetes medications (below)</li>
                <li>FREE shipping to your mailbox</li>
              </ul>
            </div>
            <div className="Lp14_block9_bottom_sub_right">
              <div className="Lp14_block9_btm_sub_table_main_wrpr">
                <div className="Lp14_block9_table_head">
                  <h1>DIABETES MEDICATIONS ON OUR FORMULARY</h1>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Medication</th>
                      <th>Strength</th>
                      <th>Qty Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Acarbose</td>
                      <td>50mg</td>
                      <td>270</td>
                    </tr>
                    <tr>
                      <td>Glimepiride</td>
                      <td>1mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glimepiride</td>
                      <td>2mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glimepiride</td>
                      <td>4mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Glipizide/Metformin</td>
                      <td>2.5/500mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Glipizide/Metformin</td>
                      <td>5/500mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Glipizide</td>
                      <td>2.5mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glipizide</td>
                      <td>5mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glipizide</td>
                      <td>10mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glipizide ER</td>
                      <td>2.5mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glipizide ER</td>
                      <td>5mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glipizide ER</td>
                      <td>10mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Glyburide (Non-micronized)</td>
                      <td>2.5mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glyburide (Non-micronized)</td>
                      <td>5mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Glyburide/Metformin</td>
                      <td>2.5/500mg</td>
                      <td>360</td>
                    </tr> <tr>
                      <td>Glyburide/Metformin</td>
                      <td>5/500mg</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Metformin ER 24HR</td>
                      <td>500mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Metformin ER 24HR</td>
                      <td>750mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Metformin (IR)</td>
                      <td>500mg</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>Metformin</td>
                      <td>850mg</td>
                      <td>270</td>
                    </tr>
                    <tr>
                      <td>Metformin</td>
                      <td>1000mg</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Pioglitazone</td>
                      <td>30mg</td>
                      <td>90</td>
                    </tr>
                    <tr>
                      <td>Pioglitazone</td>
                      <td>45</td>
                      <td>90</td>
                    </tr>

                  </tbody>
                </table>
                <div className="Lp14_block9_table_tag">
                  <p>These Medications Will Be FREE With Your Membership and a Valid Prescription!</p>
                </div>
              </div>

            </div>

          </div>
          <hr></hr>
          <div className="Lp14_block9_footer_text_wrpr">
            <div className="Lp14_block9_footer_text_wrpr_sub1">
              <h1>Reducing Prescription Costs, One Member at a Time</h1>
              <p>The average consumer is being taken advantage of and is unaware that there are options for their health. We are here to help, educate and advise wherever we can. Access Discount Healthcare is committed to bringing you the most cost-effective drugs in the industry.</p>
            </div>
            <hr></hr>
            <div className="Lp14_block9_footer_text_wrpr_sub2">
              <h1>We Are Looking Out For You</h1>
              <p>Our experienced team of customer service and Licensed Pharmacists provide the highest level of care when members need it most. Through Pharmacy Coaching, we educate members on possible medication options and opportunities to save additional money when available. We will consult with you and your doctor to inform you of the alternatives and help you make the best decision for your personal and financial health.</p>
            </div>
            <div className="Lp14block2_sub1_wrapper">{page === "17" ? <button onClick={() => setsScroll()}>speak with one of our organization specialist right away</button> : <button onClick={() => modalOpen()}>Enroll Today</button>}</div>
          </div>
        </div>

      </div>
      <div><LandingPage14FormModal openModal={openModal} setopenModal={setopenModal} /></div>
    </>
  )
}

export default Lp14block9