import { IconButton, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

function PreviewLocation(props) {

    const [subLocationRow, setlocationRow] = useState(null)
    const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForLocationData && Object.keys(state.tableSlice.tempRowData.tableForLocationData).length > 0 ? state.tableSlice.tempRowData.tableForLocationData : {})


    useEffect(() => {
        if (props.previewLocationClicked && subEmployeeRowData && Object.keys(subEmployeeRowData).length > 0) {
            setlocationRow(subEmployeeRowData)

            // console.log("subEmployeeRowData========>>>>", subEmployeeRowData)
        }
    }, [props.previewLocationClicked, JSON.stringify(subEmployeeRowData)])


    const handleClosePreview = () => {
        props.setPreviewOpen(false)
        setlocationRow(null)
    }
    return (
        <>
            <div>
                <Modal
                    className="modalBaseStyle  preview_details"
                    open={props.previewOpen}
                    onClose={handleClosePreview}
                >
                    <Box className="modalBaseBox mediumModal">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleClosePreview}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className="view_details">

                            <div className="modal_title modal_titlenew SD_bluecoast_preview">

                                <h2 className="previewModalTitle "> Location Details of {subLocationRow?.location_name}</h2>
                            </div>
                            <div className="modalview_group">
                                {subLocationRow ? (
                                    <div className="data_container data_containerscroll">

                                        <div className="data_block">
                                            {subLocationRow && (
                                                <div className="property_container">
                                                    <label className="property_label">Location Name:</label>
                                                    <label className="property_value">
                                                        {subLocationRow.location_name}
                                                    </label>
                                                </div>
                                            )}
                                 

                                            {subLocationRow?.status && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Status:
                                                    </label>
                                                    <label className="property_value">
                                                        {subLocationRow.status}
                                                    </label>
                                                </div>
                                            )}
                                     


                                            {subLocationRow?.address && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Address:
                                                    </label>
                                                    <label className="property_value">
                                                        {subLocationRow.address}
                                                    </label>
                                                </div>
                                            )}

                                            {subLocationRow?.city && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        City:
                                                    </label>
                                                    <label className="property_value">
                                                        {subLocationRow.city}
                                                    </label>
                                                </div>
                                            )}

                                            {subLocationRow?.state && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        State:
                                                    </label>
                                                    <label className="property_value">
                                                        {subLocationRow.state}
                                                    </label>
                                                </div>
                                            )}


                                            {subLocationRow?.zip && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Zip:
                                                    </label>
                                                    <label className="property_value">
                                                        {subLocationRow.zip}
                                                    </label>
                                                </div>
                                            )}


                                            {subLocationRow?.createdon_datetime && (
                                                <div className="property_container">
                                                    <label className="property_label">Added On:</label>
                                                    <label className="property_value">
                                                        {format(
                                                            subLocationRow.createdon_datetime,
                                                            "dd-MM-yyyy HH:mm"
                                                        )}
                                                    </label>
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                ) : (
                                    <div className="nodatafound">No Records Found</div>
                                )}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default PreviewLocation