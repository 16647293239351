import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing"

export const ConflictingSlots = ({ conflictingSlotFetched }) => {

    const [conflictingSlotList, setConflictingSlotList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})

    useEffect(() => {
        conflictingList.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);

    const conflictingList = useRef({
        tableId: "conflictingSlotLists",
        tableTitle: "Conflicting Slots",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/conflicting-slots-listing",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/conflicting-slots-listing",
        },

        reqBody: {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["time_diff_creation", "slot_start_time_unix", "slot_end_time_unix"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchData: {
            heading: "Search Conflicting Slots",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Summary",
                        name: "summary",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "summary", param: "$regex", options: "i" },

                    },
                  {
                        id: 0,
                        label: "Search By Connected Gmail",
                        name: "connected_gmail",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },

                    },
                   {
                        id: 0,
                        label: "Search By User Email",
                        name: "user_email",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "user_email", param: "$regex", options: "i" },

                    },
                   
                ]
                // heading: "Search Conflicting Slots",
                // api: {
                //     url: process.env.REACT_APP_API_URL,
                //     endPoint: "calendar-api/conflicting-slots-listing",
                //     tableCountEndpoint: "calendar-api/conflicting-slots-listing",
                //     reqBody: {
                //         // count:true,
                //         // "source": "users",
                //         token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
                //         condition: {
                //             limit: 5,
                //             skip: 0,
                //         },
                //         sort: {
                //             type: "desc",
                //             field: "_id",
                //         },
                //     },
                // },

                // searchSettings: {
                //     datesearch: [
                //         // {
                //         //     id: 0,
                //         //     heading: "Search By Joining Date",
                //         //     startdatelabel: "Start Date",
                //         //     enddatelabel: "End Date",
                //         //     className: "formGroup createdon_datetime countDiv__column--col4",
                //         //     submit: "Search",
                //         //     field: "slot_start_time_unix",
                //         // },
                //     ],
                //     selectsearch: [
                //         // {
                //         //     id: 1,
                //         //     label: "Search By Status",
                //         //     field: "status",
                //         //     type: "select",
                //         //     values: [0, 1],
                //         //     // value: "",
                //         // },


                //     ],
                //     textsearch: [
                //         {
                //             id: 1,
                //             // heading: "Search By First Name",
                //             label: "Search By Summary ",
                //             field: "summary",
                //             className: "formGroup summary countDiv__column--col4",
                //             value: "",
                //         },
                //         {
                //             id: 2,
                //             // heading: "Search By First Name",
                //             label: "Search By Connected Gmail ",
                //             field: "connected_gmail",
                //             className: "formGroup connected_gmail countDiv__column--col4",
                //             value: "",
                //         },
                //         {
                //             id: 3,
                //             // heading: "Search By First Name",
                //             label: "Search By User Email ",
                //             field: "user_email",
                //             className: "formGroup user_email countDiv__column--col4",
                //             value: "",
                //         },
                //         // {
                //         //     id: 4,
                //         //     // heading: "Search By First Name",
                //         //     label: "Search By Organizer Email ",
                //         //     field: "organizer_email",
                //         //     className: "formGroup organizer_email countDiv__column--col4",
                //         //     value: "",
                //         // },
                //     ],

            },
        },
    });

    var modifyTableHeaders =
        [
            { val: "summary", name: "Summary" },
            { val: "connected_gmail", name: "Connected Gmail" },
            { val: "user_email", name: "User Email" },
            { val: "time_diff_creation", name: "Deletion Time(min)" },
            { val: "timespan", name: "Timespan" },
            { val: "slot_start_time_unix", name: "Appointment Start Time", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },
            { val: "slot_end_time_unix", name: "Appointment End Time", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(conflictingList.current.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendar-api/conflicting-slots-listing",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setConflictingSlotList([...respdata.results.res])
        }
        setloaderFlag(false);
        conflictingSlotFetched(true);
    }

    useEffect(() => {
        if (conflictingSlotList && conflictingSlotList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList()
        }

    }, [conflictingSlotList]);

    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">

                    {!loaderFlag && conflictingSlotList !== undefined ? (
                        <ListingTable
                            tableData={conflictingList.current}
                            dataset={conflictingSlotList}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}

                </div>
            </div>
        </>
    )
}