import { IconButton, LinearProgress, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCommissionSingalData } from '../../commissionReducer';
import CommissionAddEditForm from './CommissionAddEditForm'
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system';


const CommissionAddEdit = (props) => {

    const [queryParamId, setQueryParamId] = useState();//----state for get ParamId

    const dispatch = useDispatch()
    const navigate = useNavigate();

    //------------------------fetch Commission row singal Data--------------------------------
    const commissionSingalData = useSelector((state) => state.commissionReducer?.commissionSingalData ? state.commissionReducer.commissionSingalData : {})

    const loader = useSelector((state) => state.commissionReducer?.loading ? state.commissionReducer.loading : false)

    // console.log("commissionSingalData==========>>>>>>", commissionSingalData)
    //========================================================================================

    // -------- Getting QueryParam and Calling thunk for fetching data through endpoint ---------

    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])


    useEffect(() => {
        if (queryParamId != undefined && queryParamId != null) {
            dispatch(getCommissionSingalData(queryParamId));
        }
    }, [queryParamId])

    // console.warn("<<<<< query param from addEditFrom component>>>>>",queryParamId)

    //----------------------------------------------------------------------------------------









    return (
        <>
            <h1 className='page_heading'> {queryParamId ? "Edit" : "Add"} AE Commission Configuration </h1>


            <div className='dataform2_wrapper'>

                {loader ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null}

                <div className='adminformbody'>
                    {queryParamId ? Object.keys(commissionSingalData).length > 0 &&
                        <CommissionAddEditForm
                            queryparamid={queryParamId}
                            commissionsdata={commissionSingalData}
                        /> : <CommissionAddEditForm />}




                </div>

            </div>

        </>
    )
}

export default CommissionAddEdit