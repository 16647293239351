import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from 'react-cookie';



export const getRoleData = createAsyncThunk("FaqReducer/getRoleData", async (reqBody) => {

    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "role/roles-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch 111==>", respdata);

    return respdata;

    // console.log("respdata.res", respdata.results.res);


})
export const getfaqcategorydata = createAsyncThunk("getfaqcategorydata", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-faq-category/faq-category-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const getfaqdata = createAsyncThunk("getfaqdata", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-faq/faq-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const geteditfaqdata = createAsyncThunk("geteditfaqdata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-faq/faq-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const geteditfaqcategorydata = createAsyncThunk("geteditfaqcategorydata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-faq-category/faq-category-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


const FaqReducer = createSlice({
    name: 'FaqReducer',
    initialState: {
        roledata: [],
        faqcategorydata: [],
        faqdata: [],
        editfaqdata: [],
        editfaqcategorydata: []

    },

    reducers: {


        clearroledata(state, action) {
            state.roledata = []
        },
        clearfaqcategorydata(state, action) {
            state.faqcategorydata = []
        },
        clearfaqdata(state, action) {
            state.faqdata = []
        },
        cleareditfaqdata(state, action) {
            state.editfaqdata = []
        },
        cleareditfaqcategorydata(state, action) {
            state.editfaqcategorydata = []
        }

    },
    extraReducers: {

        [geteditfaqdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false

        },
        [geteditfaqdata.pending]: (state, action) => {
            state.editloading = true;
        },
        [geteditfaqdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editfaqdata = action.payload.results.res;
                state.editloading = false;
                // console.log('Full', state.editfaqdata)

            }
        },

        [geteditfaqcategorydata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false

        },
        [geteditfaqcategorydata.pending]: (state, action) => {
            state.editloading = true;
        },
        [geteditfaqcategorydata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editfaqcategorydata = action.payload.results.res;
                state.editloading = false;
                // console.log('Full', state.editfaqcategorydata)

            }
        },


        [getRoleData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getRoleData.pending]: (state, action) => {
            state.loading = true;
        },
        [getRoleData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.roledata = action.payload.results.res;
                state.loading = false;
            }


        },
        [getfaqcategorydata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getfaqcategorydata.pending]: (state, action) => {
            state.loading = true;
        },
        [getfaqcategorydata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.faqcategorydata = action.payload.results.res;
                state.loading = false;
            }


        },

        [getfaqdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getfaqdata.pending]: (state, action) => {
            state.loading = true;
        },
        [getfaqdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.faqdata = action.payload.results.res;
                state.loading = false;
            }


        },

    }

})

export default FaqReducer.reducer;
export const { clearroledata, clearfaqcategorydata, clearfaqdata, cleareditfaqdata, cleareditfaqcategorydata } = FaqReducer.actions