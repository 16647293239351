import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apicall } from "../../../helper/helperFunctions";



//  ------------ getCommissionConfigList ----------------------- //


export const getCommissionConfigList = createAsyncThunk("commissionconfig/listing", async (reqBody) => {

    if (!reqBody) {

        reqBody =
        {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {

            },
            "token": "",
            "count": false
        }
    }


    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/config-list-count", requestOptions);

    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

//  ------------------------------------------------- //



//  ------------ commissionList ----------------------- //
export const commissionList = createAsyncThunk("commission/listing", async (reqBody) => {

    if (!reqBody) {

        reqBody =
        {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {

            },
            "token": "",
            "count": false
        }
    }


    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-list-count", requestOptions);

    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

})

//  ------------------------------------------------- //




export const getCommissionAddUpdate = createAsyncThunk("commissionconfig/addupdate", async (reqBody) => {



    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/config-add-update", requestOptions);

    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getCommissionSingalData = createAsyncThunk("commissionconfig/singaldata", async (reqBody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    // console.log("process.env==>", process.env);

    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-single-data", requestOptions);

    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const commissiondelite = createAsyncThunk("commissiondelite", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/delete", requestOptions);
    const reponse = await response.json();
    return reponse;
})


export const commisdelite = createAsyncThunk("commisdelite", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-delete", requestOptions);
    const reponse = await response.json();
    return reponse;
})


// --------------------------------------------------------for manual status update ------------------------------------

export const commissionStatusUpdate = createAsyncThunk("commission/status-update", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/status-update", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// ------------------------------------ ///



// ----------------- fetchGlobalSetingsData ---------- //
export const fetchGlobalSetingsData = createAsyncThunk("commission/fetchGlobalSetingsData", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-global-setingsdata", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// ------------------------------------------------- //



// ----------------- fetchLeadData ---------- //
export const fetchLeadData = createAsyncThunk("commission/fetch-lead-data", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-lead-data", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// ------------------------------------------------- //


// ----------------- fetchAeData ---------- //
export const fetchAeData = createAsyncThunk("commission/fetchAeData", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-ae-data", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// ------------------------------------------------- //



// ----------------- fetchCommissionInformation ---------- //
export const fetchCommissionInformation = createAsyncThunk("commission/fetchCommissionInformation", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-commission-information", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// ------------------------------------------------- //




// ----------------- fetchCommissionGlobalSettings ---------- //
export const fetchCommissionGlobalSettings = createAsyncThunk("commission/fetch-global-settings", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/fetch-global-settings-data", requestOptions);
    const reponse = await response.json();
    return reponse;
})
// ------------------------------------------------- //





// ----------------- commissionSingleFetchData ---------- //
export const commissionSingleFetchData = createAsyncThunk("commission/commissionSingleFetchData", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-single-fetch-data", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// ------------------------------------------------- //


// --------------------------------------------------------for manual status update ------------------------------------

export const commisStatusUpdate = createAsyncThunk("commis/status-update", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "commission/commission-status-update", requestOptions);
    const reponse = await response.json();
    return reponse;
})

// --------------------------------------------------- //


// --------------------------------------------------------commission manual entry ------------------------------------


export const commissionManualEntryDataForm = createAsyncThunk("commissionmanualentrydta", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/createaccesshcomdata", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// ------------------------------------ ///

// ----------------------------------Programme data as per programme sletec--------------------

export const programmeSelectCommissionData = createAsyncThunk("programmeselectcommissiondata", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/get_partner_data", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


// ----------------------------------add Traffic Partner Commission Data--------------------

export const addupdatetraffciPartnerCommission = createAsyncThunk("addupdatetraffcipartnercommission", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/configure_traffic_commission", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


// ----------------------------------Traffic Partner Commission Data--------------------

export const traffciPartnerCommissionList = createAsyncThunk("traffcipartnercommissionlist", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/configure_traffic_commission_list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})



const commissionReducer = createSlice({
    name: 'commissionReducer',
    initialState: {

        submitting: false,
        loading: false,
        editloading: false,
        commisssionListData: [],
        editcontractSlagData: [],
        contractListData: [],
        editcontractData: [],
        contractSlugAutocompleteData: [],
        loadinglist: false,
        commissionSingalData: {},
        snakebarData: {},
        globalSetingsData: [],
        globalDataSucess: false,
        fetchLeadInfo: [],
        commisionDataForListing: [],
        commissionSingleData: {},
        commisDeleteStatus: null,
        fetchedAeInfo: [],
        commissionConfigInfo: [],
        globalSettingsData: [],
        commissionmanualdata: null,
        adddsuccesscommissionflag: true,
        commissionLoader: false,
        partnerCommissionData: {},
        trafficpartnercommsisnData: []

    },
    reducers: {
        clearCommissionReducerData(state, action) {
            state.commisssionListData = []
        },
        clearDeleteData(state, action) {
            state.snakebarData = {}
        },
        clearcommisDeleteStatus(state, action) {
            state.commisDeleteStatus = null
        },
        clearaddsuccesscommisonflag(state, action) {
            state.adddsuccesscommissionflag = false
            state.commissionmanualdata = null
        },
    },
    extraReducers: {



        /// ############# getCommissionConfigList ##################
        [getCommissionConfigList.pending]: (state, action) => {
            state.loading = true;
            state.loadinglist = true
            state.commisssionListData = [];

        },

        [getCommissionConfigList.fulfilled]: (state, action) => {

            // console.log("data for commission", action.payload.results.res);
            console.warn("action.payload", action.payload)
            if (action?.payload?.status === 'success') {
                state.loadinglist = false;
                state.commisssionListData = action.payload?.results?.res ? action.payload.results.res : [];
                state.loading = false;
            }
        },

        [getCommissionConfigList.rejected]: (state, action) => {
            state.endpointsuccess = false
            // state.loading = false;
            state.successAction = false;

        },




        /// ############# getCommissionAddUpdate ##################
        [getCommissionAddUpdate.pending]: (state, action) => {
            state.loading = true;
        },

        [getCommissionAddUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loading = false;
            }
        },

        [getCommissionAddUpdate.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },




        /// ############# getCommissionSingalData ##################
        [getCommissionSingalData.pending]: (state, action) => {
            state.loading = true;
            state.commissionSingalData = {}
        },

        [getCommissionSingalData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loading = false;
                state.commissionSingalData = action.payload?.results?.res[0] ? action.payload.results.res[0] : {};

            }
        },

        [getCommissionSingalData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.commissionSingalData = {}
            state.loading = false;
            state.successAction = false;


        },

        //----------------------------------------------------------for delite Commission row data ----------------------------------------
        [commissiondelite.pending]: (state, action) => {
            state.loading = true
        },
        [commissiondelite.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.snakebarData = action.payload
                // state.loading=false
                // console.log("deleteSnackBarData", state.snakebarData);

            }
            state.deleteSnackBarData = action.payload
            state.loading = false
        },
        [commissiondelite.rejected]: (state, action) => {
            state.loading = false
        },






        ///-----------------------------dont be confuse this is differnt reducer for different pupose --------------------


        [commisdelite.pending]: (state, action) => {
            state.loading = true
        },
        [commisdelite.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.snakebarData = action.payload
                state.commisDeleteStatus = action.payload.status
                // console.log("deleteSnackBarData", state.snakebarData);

            }
            state.deleteSnackBarData = action.payload
            state.loading = false
        },
        [commisdelite.rejected]: (state, action) => {
            state.loading = false
        },

        // --------------------------------------------------------for status update ------------------------------------
        [commissionStatusUpdate.pending]: (state, action) => {
            state.loading = true
            state.Leadtogalbtnview = null
        },
        [commissionStatusUpdate.fulfilled]: (state, action) => {
            state.loading = false
            state.snakebarData = action.payload
            state.statusSnackBarData = action.payload
            state.Leadtogalbtnview = action.payload.message
            // console.log("statyscc", action);
        },
        [commisStatusUpdate.rejected]: (state, action) => {
            state.loading = false
        },

        // --------------------------------------------------------for status update ------------------------------------
        [commisStatusUpdate.pending]: (state, action) => {
            state.loading = true
            state.Leadtogalbtnview = null
        },
        [commisStatusUpdate.fulfilled]: (state, action) => {
            state.loading = false
            state.snakebarData = action.payload
            state.statusSnackBarData = action.payload
            state.Leadtogalbtnview = action.payload.message
            // console.log("statyscc", action);
        },
        [commissionStatusUpdate.rejected]: (state, action) => {
            state.loading = false
        },



        // ------------------ fetchGlobalSetingsData --------
        [fetchGlobalSetingsData.pending]: (state, action) => {
            state.loading = true
            state.globalSetingsData = []
            state.globalDataSucess = false;
        },
        [fetchGlobalSetingsData.fulfilled]: (state, action) => {
            state.loading = false
            state.globalSetingsData = action.payload?.results?.res ? action.payload?.results?.res : []
            state.globalDataSucess = true;

        },
        [fetchGlobalSetingsData.rejected]: (state, action) => {
            state.loading = false
            state.globalSetingsData = []
            state.globalDataSucess = false;

        },
        //  ------------------------------------------ //




        // ------------------ fetchLeadData  --------
        [fetchLeadData.pending]: (state, action) => {
            state.loading = true
            state.fetchLeadInfo = []

        },
        [fetchLeadData.fulfilled]: (state, action) => {
            state.loading = false
            state.fetchLeadInfo = action.payload?.results?.res ? action.payload?.results?.res : [];

        },
        [fetchLeadData.rejected]: (state, action) => {
            state.loading = false
            state.fetchLeadInfo = []

        },
        //  ------------------------------------------ //





        // ------------------ fetchAeData  --------
        [fetchAeData.pending]: (state, action) => {
            state.loading = true
            state.fetchedAeInfo = []

        },
        [fetchAeData.fulfilled]: (state, action) => {
            state.loading = false
            state.fetchedAeInfo = action.payload?.results?.res ? action.payload?.results?.res : [];

        },
        [fetchAeData.rejected]: (state, action) => {
            state.loading = false
            state.fetchedAeInfo = []

        },
        //  ------------------------------------------ //



        // ------------------ fetchCommissionInformation  --------
        [fetchCommissionInformation.pending]: (state, action) => {
            state.loading = true
            state.commissionConfigInfo = []

        },
        [fetchCommissionInformation.fulfilled]: (state, action) => {
            state.loading = false
            state.commissionConfigInfo = action.payload?.results?.res ? action.payload?.results?.res : [];

        },
        [fetchCommissionInformation.rejected]: (state, action) => {
            state.loading = false
            state.commissionConfigInfo = []

        },
        //  ------------------------------------------ //








        // ------------------ fetch commissionList Data  --------
        [commissionList.pending]: (state, action) => {
            state.loading = true
            state.commisionDataForListing = []
            state.loadinglist = true
        },
        [commissionList.fulfilled]: (state, action) => {
            state.loading = false
            state.commisionDataForListing = action.payload?.results?.res ? action.payload?.results?.res : [];
            state.loadinglist = false

        },
        [commissionList.rejected]: (state, action) => {
            state.loading = false
            state.commisionDataForListing = []
            state.loadinglist = false

        },
        //  ------------------------------------------ //






        // ------------------ fetchCommissionGlobalSettings data  --------
        [fetchCommissionGlobalSettings.pending]: (state, action) => {
            state.loading = true
            state.globalSettingsData = []
            state.loadinglist = true
        },
        [fetchCommissionGlobalSettings.fulfilled]: (state, action) => {
            state.loading = false
            state.globalSettingsData = action.payload?.results?.res ? action.payload?.results?.res : [];
            state.loadinglist = false

        },
        [fetchCommissionGlobalSettings.rejected]: (state, action) => {
            state.loading = false
            state.globalSettingsData = []
            state.loadinglist = false

        },
        //  ------------------------------------------ //



        // ------------------ commissionSingleFetchData  --------
        [commissionSingleFetchData.pending]: (state, action) => {
            state.loading = true
            state.commissionSingleData = {}
            // state.loadinglist = true
        },
        [commissionSingleFetchData.fulfilled]: (state, action) => {
            state.loading = false
            state.commissionSingleData = action.payload?.results?.res ? action.payload?.results?.res[0] : {};
            // state.loadinglist = false

        },
        [commissionSingleFetchData.rejected]: (state, action) => {
            state.loading = false
            state.commissionSingleData = {}
            // state.loadinglist = false

        },
        //  ------------------------------------------ //



        // ------------------ commission manual Entry  --------

        [commissionManualEntryDataForm.pending]: (state, action) => {
            state.commissionLoader = true;

        },
        [commissionManualEntryDataForm.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {

                state.commissionmanualdata = action.payload.results;
                state.adddsuccesscommissionflag = true

            }
            state.commissionLoader = false;
        },
        [commissionManualEntryDataForm.rejected]: (state, action) => {
            state.commissionLoader = false;
        },



        // ------------------------------------------------------- //

        // ------------------ partner data commission  --------

        [programmeSelectCommissionData.pending]: (state, action) => {
            state.commissionLoader = true;

        },
        [programmeSelectCommissionData.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.partnerCommissionData = action.payload.results.res[0];
            }
            state.commissionLoader = false;
        },
        [programmeSelectCommissionData.rejected]: (state, action) => {
            state.commissionLoader = false;
        },


        // ------------------traffic partner data commission  --------

        [traffciPartnerCommissionList.pending]: (state, action) => {
            state.commissionLoader = true;

        },
        [traffciPartnerCommissionList.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.trafficpartnercommsisnData = action.payload.results.res;
            }
            state.commissionLoader = false;
        },
        [traffciPartnerCommissionList.rejected]: (state, action) => {
            state.commissionLoader = false;
        },

        // ------------------add traffic partner data commission  --------

        [addupdatetraffciPartnerCommission.pending]: (state, action) => {
            state.commissionLoader = true;

        },
        [addupdatetraffciPartnerCommission.fulfilled]: (state, action) => {

         
            state.commissionLoader = false;
        },
        [addupdatetraffciPartnerCommission.rejected]: (state, action) => {
            state.commissionLoader = false;
        },


    }

})

export default commissionReducer.reducer;
export const { clearCommissionReducerData, clearDeleteData, clearcommisDeleteStatus, clearaddsuccesscommisonflag } = commissionReducer.actions;

