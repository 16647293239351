import { IconButton, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';

function PreviewEmployeeDetails(props) {

    const [previewEmployee, setPreviewEmployee] = useState(null)

    useEffect(() => {
        if (props.previewEmployeeClicked && props.employeeRowData && Object.keys(props.employeeRowData).length > 0) {
            setPreviewEmployee(props.employeeRowData)

            // console.log("previewData===>>>>", props.employeeRowData)
        }
    }, [props.previewEmployeeClicked, JSON.stringify(props.employeeRowData)])


    const handleClosePreview = () => {
        props.setPreviewOpen(false);
        setPreviewEmployee(null)
    }

    return (
        <>
            <div>
                <Modal
                    className="modalBaseStyle  preview_details"
                    open={props.previewOpen}
                    onClose={handleClosePreview}
                >
                    <Box className="modalBaseBox mediumModal">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleClosePreview}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className="view_details">

                            <div className="modal_title modal_titlenew SD_bluecoast_preview">

                                <h2 className="previewModalTitle "> Details of {previewEmployee?.name}</h2>
                            </div>
                            <div className="modalview_group">
                                {previewEmployee ? (
                                    <div className="data_container data_containerscroll">

                                        <div className="data_block">
                                            {previewEmployee && (
                                                <div className="property_container">
                                                    <label className="property_label">Name:</label>
                                                    <label className="property_value">
                                                        {previewEmployee.firstname + " " + previewEmployee.lastname}
                                                    </label>
                                                </div>
                                            )}
                                            {previewEmployee?.email && (
                                                <div className="property_container">
                                                    <label className="property_label">Email:</label>
                                                    <label className="property_value">
                                                        {previewEmployee.email}
                                                    </label>
                                                </div>
                                            )}
                                            {previewEmployee?.phone && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Phone Number:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.phone}
                                                    </label>
                                                </div>
                                            )}

                                            {previewEmployee?.location_name && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Location:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.location_name}
                                                    </label>
                                                </div>
                                            )}

                                            {previewEmployee?.status && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Status:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.status}
                                                    </label>
                                                </div>
                                            )}

                                            {previewEmployee?.package_name && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Package:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.package_name}
                                                    </label>
                                                </div>
                                            )}


                                            {previewEmployee?.address && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Address:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.address}
                                                    </label>
                                                </div>
                                            )}

                                            {previewEmployee?.city && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        City:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.city}
                                                    </label>
                                                </div>
                                            )}

                                            {previewEmployee?.state && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        State:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.state}
                                                    </label>
                                                </div>
                                            )}


                                            {previewEmployee?.zip && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Zip:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.zip}
                                                    </label>
                                                </div>
                                            )}


                                            {previewEmployee?.notes && (
                                                <div className="property_container">
                                                    <label className="property_label">
                                                        Notes:
                                                    </label>
                                                    <label className="property_value">
                                                        {previewEmployee.notes}
                                                    </label>
                                                </div>
                                            )}
                                            {previewEmployee?.createdon_datetime && (
                                                <div className="property_container">
                                                    <label className="property_label">Added On:</label>
                                                    <label className="property_value">
                                                        {format(
                                                            previewEmployee.createdon_datetime,
                                                            "dd-MM-yyyy HH:mm"
                                                        )}
                                                    </label>
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                ) : (
                                    <div className="nodatafound">No Records Found</div>
                                )}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default PreviewEmployeeDetails