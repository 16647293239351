import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const Traininglist = createAsyncThunk("training/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "source": "training_list",
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "project": {},
            "token": "",
            "count": false,
        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/training-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/**********************************Training Edit******************* */
export const gettrainingeditdata = createAsyncThunk("edittraining", async (id) => {
    let reqBody = {

        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "desc"
        },
        "searchcondition": {
            "_id": id
        },
        "project": {},
        "token": ""

    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/training-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

/*********************************For parent autocomplete in training category page******** */
export const getTrainingData = createAsyncThunk("getparentcategorydata/list", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "traning_list",
        "sort": {
            "type": "desc",
            "field": "name"
        },
        "searchcondition": {

        }
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/auto-complete-training", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});

export const getProductData = createAsyncThunk("getproductdata", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "program_list",
        "sort": {
            "type": "desc",
            "field": "name"
        },
        "searchcondition": {

        }


    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/fetch-programs", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});


/***********Fetch Single data for lessson preview************** */

export const fetchinglessondata = createAsyncThunk("fetch/singledata", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "lesson_list" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/lesson-single-fetch-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// API call for users-list
export const Lessonlist = createAsyncThunk("lesson/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {



            "source": "lesson_list",
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false,





        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/lesson-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const fetchTrainingLessonsingle = createAsyncThunk("fetch/fetchTrainingLessonsingle", async (reqBody) => {
    console.log("process.env==>", process.env);
    let req_body = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "_id": reqBody },
        "project": {},
        "token": ""
    }

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/lesson-single-fetch-data", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/**********************************Lesson Edit******************* */
export const getlessoneditdata = createAsyncThunk("editlesson", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "desc"
        },
        "searchcondition": {
            "_id": id
        },
        "project": {},
        "token": ""

    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body=================>>>>>>>>>>>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/lesson-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})
export const getAllDoneTrainingListByUser = createAsyncThunk("getAllDoneTrainingListByUser", async (id) => {
    console.log("getAllDoneTrainingListByUserid", id)
    let reqBody = {
        "userid": id
    }
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body=================>>>>>>>>>>>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/done-traning-by-user", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

})

export const fetchTrainingPercentage = createAsyncThunk("fetchTrainingPercentage", async (id) => {
    let reqBody = {
        "userid": id
    }
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body=================>>>>>>>>>>>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/fetch-traning-percentage", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// export const editDataFetchLesson = createAsyncThunk("lesson/editdata", async (reqbody) => {
//     const response = await fetch(
//         `${process.env.REACT_APP_NODE_URL}api1/getedittdata-traning?_id=${reqbody}&source=${"lesson_list"}`,
//         // requestOptions
//     );
//     const respdata = await response.json();
//     // console.log("respdata", respdata)
//     return respdata;

// })

// export const getmaterialGridData = createAsyncThunk("materialtrainingGridData", async (reqBody) => {

//     if (!reqBody) {

//         reqBody = {
//             "condition": {
//                 "limit": 10,
//                 "skip": 0
//             },
//             "sort": {
//                 "field": "_id",
//                 "type": "desc"
//             },
//             "searchcondition": {

//             },
//             "project": {},
//             "token": "",
//             "count": false
//         }
//     }


//     console.log("reqbody11==>", reqBody);
//     let req_body = {};
//     req_body = { ...reqBody };
//     console.log("req_body==", req_body);
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "training-material/program-wise-training-material", requestOptions);
//     const respdata = await response.json();
//     console.log(" hiring managment respdata after endpoint fetch==>", respdata);
//     return respdata;


// })



const training = createSlice({
    name: 'training',
    initialState: {
        submitting: false,
        loading: false,
        submitted: false,
        resMessage: null,
        successmsg: null,
        success: false,
        failed: false,
        trainingdata: [],
        lessondata: [],
        gettrainnigdata: [],
        statusChangeFlag: null,
        editDataLoading: false,
        editDataTraining: [],
        editDataLesson: [],
        getproduct: [],
        fetchinglessondatas: null,
        editDataLoadingLesson: false,
        fetchTrainingLessonSingleLoader: false,
        viewLessonData: {},
        // materialGridData:[],
        // materialGridDataCount:null,
        allDoneTrainingList: [],
        allDoneTrainingListFetchSuccess: false,
        fetchTrainingPercentageData: null,
        ongoingLessionData: []
    },
    reducers: {
        clearTrainingList(state, action) {
            state.trainingdata = [];
        },
        clearLessonList(state, action) {
            state.lessondata = [];
        },
        clearMetarialListData(state, action) {
            state.materialGridData = [];
        },
        cleareditdatatraining(state, action) {
            state.editDataTraining = [];
        },
        cleareditdataLesson(state, action) {
            state.editDataLesson = null;
        },
        setInitialData(state, action) {
            state.loading = false;
            state.success = false;
            // state.fetchSingleDataTraining = null;
            state.fetchinglessondatas = null;
            // state.getadditionaltrainnigdata= [];
        },
        resetTrainingDetails(state, action) {
            state.allDoneTrainingList = [];
            state.allDoneTrainingListFetchSuccess = false;
            state.fetchTrainingPercentageData = null;
        }
    },

    extraReducers: {

        /// #################### Training Listing ###############################
        [Traininglist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [Traininglist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [Traininglist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.trainingdata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
        },

        /**************************************Training Edit******************** */
        [gettrainingeditdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [gettrainingeditdata.pending]: (state, action) => {


            // console.log("gettrainingeditdata=========================>")
            state.editDataLoading = true;

        },
        [gettrainingeditdata.fulfilled]: (state, action) => {

            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editDataTraining = action.payload.results.res[0];
                // console.log("state for edit data training", state.editDataTraining)
                state.editDataLoading = false;

            }

        },
        /*************************For Parent category in add form page****** */
        [getTrainingData.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getTrainingData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getTrainingData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                state.loading = false;
                // console.log("Resyyyyyyyyyyyyyyyyyyy",action.payload.results.res)
                state.gettrainnigdata = action.payload.results.res;

            }
        },

        [getProductData.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getProductData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getProductData.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                state.loading = false;
                console.log("Resyyyyyyyyyyyyyyyyyyy", action.payload.results.res)
                state.getproduct = action.payload.results.res;

            }
        },

        /// #################### Lesson Listing ###############################
        [Lessonlist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [Lessonlist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [Lessonlist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                console.log("lesssssssssssssssssssss", action.payload.results.res)
                state.lessondata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
        },

        /**************************************Lesson Edit******************** */

        [getlessoneditdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.editDataLoadingLesson = false;
            // state.successAction = false;

        },
        [getlessoneditdata.pending]: (state, action) => {


            // console.log("getlessoneditdata=========================>")
            state.editDataLoadingLesson = true;

        },
        [getlessoneditdata.fulfilled]: (state, action) => {
            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editDataLesson = action.payload.results.res[0];
                state.editDataLoadingLesson = false;
            }
        },
        [getAllDoneTrainingListByUser.rejected]: (state, action) => {
            state.loading = false;
            state.allDoneTrainingListFetchSuccess = false;
        },
        [getAllDoneTrainingListByUser.pending]: (state, action) => {
            // console.log("getAllDoneTrainingListByUser=========================>")
            state.loading = true;
        },
        [getAllDoneTrainingListByUser.fulfilled]: (state, action) => {
            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.loading = false;
                state.allDoneTrainingList = action.payload.result
                state.allDoneTrainingListFetchSuccess = true;
            }
        },





        /*........................for preview..........................*/

        [fetchinglessondata.pending]: (state, action) => {
            state.loading = true
            state.fetchinglessondatas = null

        },
        [fetchinglessondata.fulfilled]: (state, action) => {
            // console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                state.fetchinglessondatas = action.payload?.results?.res;

            }
        },
        [fetchinglessondata.rejected]: (state, action) => {
            state.loading = false
        },
        /****************fetching single data************** */
        [fetchTrainingLessonsingle.pending](state, action) {
            state.fetchTrainingLessonSingleLoader = true;
        },
        [fetchTrainingLessonsingle.fulfilled](state, action) {
            state.fetchTrainingLessonSingleLoader = false;
            if (action.payload.status === "success") {
                state.success = true;
                state.viewLessonData = action.payload.results?.res[0]
            }
            console.log("action ", action)
        },
        [fetchTrainingLessonsingle.rejected](state, action) {
            state.fetchTrainingLessonSingleLoader = false;
        },


        // [getmaterialGridData.rejected]: (state, action) => {
        //     state.endpointsuccess[action.type] = false
        //     state.successAction = false;
        //     // state.hmGridData = [];

        // },
        // [getmaterialGridData.pending]: (state, action) => {
        //     state.loading = true;
        //     // state.hmGridData = [];
        // },
        // [getmaterialGridData.fulfilled]: (state, action) => {
        //     if (action.payload.status === 'success') {
        //         if(action.payload?.results?.res){

        //             state.materialGridData = action.payload?.results?.res;
        //         }
        //         state.loading = false;
        //         if(action.payload.count){

        //             state.materialGridDataCount=action.payload?.count;
        //         }
        //     }
        // },

        [fetchTrainingPercentage.pending](state, action) {
            state.loading = true;
        },
        [fetchTrainingPercentage.fulfilled](state, action) {
            state.loading = false;
            console.log("action fetchTrainingPercentage", action.payload)
            if (action.payload.status === "success") {                
                state.success = true;
                state.fetchTrainingPercentageData = action.payload
            }
        },
        [fetchTrainingPercentage.rejected](state, action) {
            state.loading = false;
        },


    }
})

export default training.reducer;

export const { clearTrainingList, cleareditdatatraining, clearLessonList, cleareditdataLesson, clearMetarialListData, setInitialData, resetTrainingDetails } = training.actions;