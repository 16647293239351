
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, LinearProgress, List, Tooltip } from '@mui/material';

import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";

const MultiStatusChangeModal = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    const [response, setResponse] = useState(null);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);


    // ------ Loading during any action -----
    const loader = useSelector(state => (state.userManagment?.loading
    ) ? state.userManagment.loading
        : false);
    // console.warn("<<<<< props from confirmation modal compo >>>>", props)

    useEffect(() => {
        props.setModalResponse(null);
        // console.log("props", props);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        setResponse(null)
        props.setModalResponse(null);
        props.tooglefunc(false);


    }


    // ------- onModal Action ------
    useEffect(() => {

        if (response !== null) {
            // console.warn("<<<<<<< respose set for multiple status chnage >>>.",response)
            props.setModalResponse(response);
            // props.tooglefunc(false);
        }

    }, [response])


    return (
        <>

            {/* ------ Confirmation Modal ------ */}
            {/* modalblock */}
            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* BoxMui_modal */}
                <Box className="" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    // border: "2px solid #000",
                    border: '10px solid #0569ad!important',
                    background:'#fff',
                    boxShadow: 24,
                    // p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h5">

                        <p className='alertPara2'> Alert !!</p>

                        <p> {props.confrimationMsg} </p>
                    </Typography>
                    {props.deliteLeadModal ?
                        <div style={{padding:"20px"}}>
                            <Button variant="contained" onClick={() => setResponse("yes")} className="modalBTN">Yes</Button>
                            <Button variant="contained" onClick={() => setResponse("no")} className="modalBTN">No</Button>
                        </div>
                        : <div>
                            <Button variant="contained" sx={{'margin':'10px'}} onClick={() => setResponse(1)} className="modalBTN">Active</Button>
                            <Button variant="contained" sx={{'margin':'10px'}} onClick={() => setResponse(0)} className="modalBTN">Inactive</Button>
                        </div>}

                    {loader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>

        </>
    )
}


export default MultiStatusChangeModal;