import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import ListingTable from "../../../../listing/listing";
import "./../../../../assets/List.css";
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';

import store from "../../../../store";
import { IconButton, Tooltip } from "@mui/material";
import UserManagementList from "../userList/userList";


function RepListingForHMFolderView(props) {

  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  






  return (
    <>
      {/* ---- Heading Goes Here --- */}
      <div>
        <h3>
          <PersonIcon /> {props?.name} AE Listing
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
          </div>

        </h3>
      </div>
      {/* ------  ######### ------- */}

      <div className="rolelist_mainwrp">

        <UserManagementList source="HmFolderView" queryParamId={props.queryParamId}/>


      </div>

    </>
  );
}
export default RepListingForHMFolderView;
