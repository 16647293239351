import { IconButton, LinearProgress, Modal, TextareaAutosize, Tooltip, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { viewEmployerList, viewEmployerTrafficPartnerList, viewEmployerTrafficPartnerlistforAEData } from '../userListReducer';
import { setEnableRowLoader, setReloadTableFlag } from '../../../../../listing/listReducer';
import { setSnackbar } from '../../../../Layout/layoutReducer';
// import { employerNotesList } from '../../../EmployerManagement/EmployerReducer';

function EmployerListforAE({ employerListMOdalOpen, setEmployerListMOdalOpen, userDataList, employerListButtonClicked }) {

    const dispatch = useDispatch()

    const viewEmployerFullLsitData = useSelector((state) => state.userManagment?.viewEmployersFullList && state.userManagment?.viewEmployersFullList.length > 0 ? state.userManagment.viewEmployersFullList : [])
    const loaderForList = useSelector((state) => state.userManagment?.loading ? state.userManagment.loading : false);
    const notesEmployerClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForEmployerList?.notesEmployer ? state.tableSlice.customButtonClicked.tableForEmployerList.notesEmployer : false)
    const notesEmployerClickedrow = useSelector((state) => state.tableSlice?.tempRowData?.tableForEmployerList ? state.tableSlice.tempRowData.tableForEmployerList : null);
    const viewEmployerNotesData = useSelector((state) => state.userManagment?.viewAddedNOtesEmployerData && state.userManagment?.viewAddedNOtesEmployerData.length > 0 ? state.userManagment.viewAddedNOtesEmployerData : [])
    // const loader = useSelector((state) => state.userManagment?.notesloading && state.userManagment?.notesloading.length > 0 ? state.userManagment.notesloading : false)

    useEffect(() => {
        // console.log("viewEmployerNotesData=============>>>>>>>", viewEmployerNotesData, userDataList);
    }, [JSON.stringify(viewEmployerNotesData)])

    // =====================employer data check from store==================
    useEffect(() => {
        // console.log("viewEmployerFullLsitData=========>>>", notesEmployerClickedrow);
    }, [viewEmployerFullLsitData, notesEmployerClickedrow])

    const [notesModalRowData, setNotesModalRowData] = useState(null)

    useEffect(() => {
        if (notesEmployerClickedrow !== null && notesEmployerClickedrow !== undefined) {
            setNotesModalRowData(notesEmployerClickedrow)
        }
    }, [JSON.stringify(notesEmployerClickedrow)])


    // =========================notes button functioanlity=================
    const [notesOpen, setNotesOpen] = useState(false);
    const [scratchaddnoteflag, setscratchaddnoteflag] = useState(false);


    const inputTextEmployer = useRef(null);
    const textareayvalue = useRef(null);

    const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);


    const addedForEmployer = useSelector((state) => state.tableSlice?.tempRowData?.tableForEmployerList?._id ? state.tableSlice.tempRowData.tableForEmployerList._id : null);


    const [addedForId, setAddedForId] = useState(null)

    useEffect(() => {
        if (addedForEmployer !== null && addedForEmployer !== undefined) {
            // console.log("addedForEmployer====>>>", addedForEmployer)
            setAddedForId(addedForEmployer)
        }
    }, [addedForEmployer])


    useEffect(() => {
        // console.log("addedForId===>>>", addedForId);
    }, [addedForId])



    useEffect(() => {
        if (notesEmployerClicked && notesModalRowData && addedForId !== null) {
            // console.log("checkforRowid======>>>>", notesEmployerClicked, notesModalRowData);

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForEmployerList",
                    rowId: addedForId,
                    load: true,
                })
            );

            notesListApiCall()

        }
    }, [notesEmployerClicked, JSON.stringify(notesModalRowData), addedForId])


    const notesListApiCall = async () => {

        if (viewEmployerAddedNotesList.current.reqBody && viewEmployerAddedNotesList.current.reqBody.searchcondition) {
            viewEmployerAddedNotesList.current.reqBody.searchcondition.notes_for = addedForId
        }

        viewEmployerAddedNotesList.current.searchData.formData.fields[0].reqBody.searchcondition.notes_for = addedForId

        await dispatch(viewEmployerTrafficPartnerlistforAEData(viewEmployerAddedNotesList.current.reqBody));
        setNotesOpen(true)

        dispatch(
            setEnableRowLoader({
                tableId: "tableForEmployerList",
                rowId: addedForId,
                load: false,
            })
        );

    }


    const handleCloseNotesModal = () => {
        setNotesOpen(false)
        textareayvalue.current = null;
        setAddedForId(null)
        dispatch(setReloadTableFlag({ tableId: "tableForEmployerList" }));


    }

    // ==========================Add notes funtioanlity================

    const addnote = async () => {
        if (textareayvalue.current) {
            setscratchaddnoteflag(true);
            let reqbody = {
                notes_by: addedById,
                notes_for: addedForId,
                notes: textareayvalue.current,
                // token: token
            };
            // console.log("reqbody==============>>>>", reqbody)
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(reqbody),
            };
            // console.log("addBody", reqbody);
            const response = await fetch(
                process.env.REACT_APP_API_URL + "employer_manage/addemployersnotes",
                requestOptions
            );
            const respdata = await response.json();
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Added Successfully",
                    status: "success",
                })
            );
            setscratchaddnoteflag(false);
            textareayvalue.current = null;
            inputTextEmployer.current.value = null;

            await dispatch(viewEmployerTrafficPartnerlistforAEData(viewEmployerAddedNotesList.current.reqBody));

            dispatch(setReloadTableFlag({ tableId: "employerNotesTable" }));
        } else {
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Please Add a Note",
                    status: "error",
                })
            );
        }
    };


    // ======================================= table for addedNotes ===============================

    var modifyTableHeadersforEmployerNotes = [
        { val: "notes", name: "Notes" },
        { val: "added_name", name: "Added By" },
        {
            val: "createdon_datetime",
            name: "Added On",
            type: "datetime",
            format: "MM/DD/YYYY",
        },
    ];

    let viewEmployerAddedNotesList = useRef({
        tableId: "employerNotesTable",
        tableTitle: "Note About Employer",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "note/note-list-count",
            tableCountEndpoint: "note/note-list-count",
        },

        reqBody: {
            condition: {
                limit: 30,
                skip: 0,
            },
            sort: {
                field: "createdon_datetime",
                type: "desc",
            },
            searchcondition: {
                notes_for: addedForId,
            },
            project: {},
            email: "",
            count: false,
        },

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime", "notes", "addedby_name"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [
            // {
            //     id: "delete",
            //     label: "Delete",
            //     type: "api",
            //     category: "modal",
            //     icon_type: "delete",
            //     className: "delete_data",
            //     modalClassName: "delete_modal",
            //     modalTitle:
            //         "<p>Are you sure you want to delete this Note ? This can't be undone. </p>",
            //     modalHeader: "<p> Alert!</p>",
            //     btn_label: ["Yes", "No"],
            //     api_data: {
            //         base_url: process.env.REACT_APP_API_URL,
            //         endpoint: "employer_manage/deletenotes",
            //         body: {
            //             // source: "users",

            //             secret: "AZ|lepL`",
            //             token:
            //                 "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
            //         },
            //     },
            // },

            // {
            //     id: "delete",
            //     label: "Delete",
            //     type: "api",
            //     category: "modal",
            //     icon_type: "delete",
            //     className: "delete_data",
            //     modalClassName: "delete_modal",
            //     modalTitle:
            //       "<p>Are you sure that you want to delete this record(s)?</p>",
            //     modalClassName: "delete_modal",
            //     modalHeader: "<p> Alert !!</p>",
            //     btn_label: ["Yes", "No"],
            //     api_data: {
            //       base_url: process.env.REACT_APP_API_URL,
            //     //   endpoint: "contract-manage/contract-delete",
            //         endpoint: "employer_manage/deletenotes",

            //       body: {
            //         source: "users",
            //         secret: "AZ|lepL`",
            //         token:
            //           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
            //       },
            //     },
            //   },


            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle:
                    "<p>Are you sure you want to delete this Note ? This can't be undone. </p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Yes", "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "employer_manage/deletenotes",
                    body: {
                        // source: "users",

                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },
        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },
        searchData: {
            heading: "Search Note About Employer",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: "searchNotesForm",
                formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
                formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search by Notes",
                        name: "notes",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL +
                            "note/auto-complete-notes",
                        reqBody: {
                            condition: {
                                limit: 30,
                                skip: 0,
                            },
                            sort: {
                                field: "createdon_datetime",
                                type: "desc",
                            },
                            searchcondition: {
                                notes_for: addedForId,
                                label: {
                                    $regex: "",
                                    $options: "i",
                                },
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },
                    {
                        id: 1,
                        label: "Search by Added By",
                        name: "notes_by",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL +
                            "note/auto-complete-note-added-by",
                        reqBody: {
                            condition: {
                                limit: 30,
                                skip: 0,
                            },
                            sort: {
                                field: "createdon_datetime",
                                type: "desc",
                            },
                            searchcondition: {
                                label: {
                                    $regex: "",
                                    $options: "i",
                                },
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "notes_by" },
                    },
                    {
                        id: 8,
                        label: "Search by Created on Start Date",
                        name: "createdon_Start_datetime",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: "list_search_item countDiv__column--col4 ",
                        payloadFormat: { key: "createdon_datetime", param: "$gte" },
                    },
                    {
                        id: 9,
                        label: "Search by Created on End Date",
                        name: "createdtedon_datetime",
                        className: "list_search_item countDiv__column--col4 ",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY',
                        payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    },
                ],
            },
        },
    });







    // ====================close function for main modal=====================

    const handleCloseEmployerList = () => {
        setEmployerListMOdalOpen(false)
        dispatch(setReloadTableFlag({ tableId: "userslist1" }));

    }

    // =========================employer list button props functioanlity==================

    useEffect(() => {
        if (employerListButtonClicked && userDataList) {

            // dispatch(
            //     setEnableRowLoader({
            //         tableId: "userslist1",
            //         rowId: userDataList._id,
            //         load: true,
            //     })
            // );
            let employerBody = {
                "condition": {
                    "limit": 30,
                    "skip": 0
                },
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                "searchcondition": {
                    "added_by": userDataList._id,
                    "roles.is_employer": 1,
                },
                "project": {},
                "token": "",
                "email": "",
                "count": false

            }
            dispatch(viewEmployerTrafficPartnerList(employerBody))
        }
    }, [employerListButtonClicked, JSON.stringify(userDataList)])

    // ====================main table header===============
    var modifyTableHeadersForEmployers =
        [
            { val: "name", name: "Name" },
            { val: "email", name: "Email" },
            { val: "phone", name: "Phone" },
            {
                val: "status",
                name: "Status",
                customVal: { 0: "Inactive", 1: "Active" },
            },
            { val: "createdon_datetime", name: "Updation Time", type: "datetime", format: "MM/DD/YYYY" },
        ];

    // ======================main table for employer lsit==================

    let tableDataForEmployerList = useRef({
        tableId: "tableForEmployerList",
        tableTitle: "Employer List for AEs",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "employer_manage/employers_listing",
            // deleteSingleUserEndpoint: "training-manage/delete-training",
            tableCountEndpoint: "employer_manage/employers_listing",
        },

        reqBody: {
            // // "source": "field_list",
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                "added_by": userDataList._id,
                "roles.is_employer": 1,
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false
        },




        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to delete these records?</p>",
                modalHeader: "<p> Alert !! </p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    // endpoint: "employer_manage/deletenotes",
                    body: {
                        "source": "field_list",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],
        buttons: [

            {
                id: "notesEmployer",
                label: "Notes",
                type: "other",
                icon_type: "newspaper_icon",
                name: "newspaper_icon",
                showcount: true,
                countFiled: "notes_count",
            },


        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },


        searchData: {
            heading: " Search Employer List for AE",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [


                    {
                        id: 0,
                        label: "Search By Employer Name",
                        name: "name",
                        type: "autoComplete",
                        // multiple: true,
                        preload: false,
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL + "employer_manage/autocompleteemployersname",
                        reqBody: {
                            condition: {
                                limit: 10,
                                skip: 0,
                            },
                            sort: {
                                type: "asc",
                                field: "name",
                            },
                            searchcondition: {
                                label: {
                                    $regex: "",
                                    $options: "i"
                                }
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },
                    {
                        id: 1,
                        label: "Search By Employer Email",
                        name: "email",
                        type: "autoComplete",
                        className: "inputblock2line",
                        base_url:
                            process.env.REACT_APP_API_URL +
                            "users/auto-complete-user-mail",
                        reqBody: {
                            condition: { limit: 10, skip: 0 },
                            sort: { type: "desc", field: "email" },
                            searchcondition: {
                                label: {
                                    $regex: "",
                                    $options: "i"
                                }
                            },
                        },
                        payloadFormat: { key: "_id" },
                    },

                    // {
                    //     id: 2,
                    //     label: "Phone Number Format: (123) 456-7890",
                    //     name: "phone",
                    //     className: "form_item inputBlock inputBlock4line",
                    //     type: "text",
                    //     inputType: "phone",
                    //     // rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
                    //     // rules: { required: true, pattern: /^[^@#%&$]*$/ },
                    //     errorMessage: {
                    //         required: "This Field is Required",
                    //         custom: "Value is Invalid",
                    //         pattern: "Phone no does not contain any special characters (@#%&$)",
                    //         maxLength: "Phone number can not be greater than 10 digits",
                    //     },
                    // },

                    {
                        id: 3,
                        label: "Search by Status",
                        name: "dashboard_access",
                        type: "select",
                        multiple: false,
                        values: [
                            { val: true, name: "Active" },
                            { val: false, name: "Inactive" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "dashboard_access" },
                    },



                    {
                        id: 8,
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker list_search_item countDiv__column--col6',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                ]
            }
        },

    });


    return (
        <>
            <Modal className="contractFormMOdal SentMail_mainModal"
                open={employerListMOdalOpen}
                onClose={handleCloseEmployerList}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseEmployerList}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <div className="rolelist_mainwrp">
                        <h2 className="previewModalTitle ">
                            <div className="modal_title modal_titlenew">
                                Employer List for {userDataList.firstname + " " + userDataList.lastname}
                            </div>
                        </h2>


                        <div className='rolelist_mainwrp_Table'>
                            {!loaderForList && viewEmployerFullLsitData ? (
                                <ListingTable
                                    tableData={tableDataForEmployerList.current}
                                    dataset={viewEmployerFullLsitData}
                                    modifyHeaders={modifyTableHeadersForEmployers}
                                />
                            ) : (
                                <TableSkeleton count={5} />
                            )}

                        </div>

                    </div>
                </Box>
            </Modal>

            {/* {*************Modal for Notes**************} */}

            <Modal className="contractFormMOdal notesModal_blcoast"
                open={notesOpen}
                onClose={handleCloseNotesModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseNotesModal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <div className="rolelist_mainwrp">
                        <h2 className="previewModalTitle ">
                            <div className="modal_title modal_titlenew">
                                Notes for {notesModalRowData?.firstname + " " + notesModalRowData?.lastname}
                                {/* <p>name:{JSON.stringify()}</p> */}
                            </div>
                        </h2>

                        {/* <p>{JSON.stringify(viewEmployerFullLsitData)}</p> */}

                        <div className='rolelist_mainwrp_Table'>
                            {viewEmployerNotesData !== null && !loaderForList ? (<ListingTable
                                tableData={viewEmployerAddedNotesList.current}
                                dataset={viewEmployerNotesData}
                                modifyHeaders={modifyTableHeadersforEmployerNotes}
                            />) : (
                                <TableSkeleton count={5} />
                            )}

                        </div>

                        <div className="Notes_Form">
                            <p>Notes</p>
                            <TextareaAutosize
                                placeholder={"Type here..."}
                                // value={textareayvalue.current}
                                ref={inputTextEmployer}
                                onChange={(e) => {
                                    textareayvalue.current = e.target.value;
                                }}
                            />
                        </div>

                        <div>
                            <button
                                className="blucoastList_specialbtn" onClick={() => addnote()}
                            >
                                Add Note
                            </button>

                            {scratchaddnoteflag && <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }} >
                                <LinearProgress />
                            </Box>}

                        </div>

                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default EmployerListforAE