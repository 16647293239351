import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";

export const ConflictFullDayEvents = ({ conflictingFulldayEventFetched }) => {

    const [conflictingFulldayEventList, setConflictingFulldayEventList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})


    useEffect(() => {
        conflictingFulldayEvents.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);

    const conflictingFulldayEvents = useRef({
        tableId: "conflictingFulldayEventLists",
        tableTitle: "Conflicting Fullday Events",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/conflict-fullday-event_list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/conflict-fullday-event_list",
        },

        reqBody: {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["time_diff_creation", "slot_start_time_unix", "slot_end_time_unix"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },
        searchData: {
            heading: "Search Conflicting Fullday Events",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                resetBtnEndIcon: "replay",
                resetBtnName: "Refresh",
                submitBtnName: "Search",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Summary",
                        name: "summary",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "calendar-api/conflictfullday-autocomplete-summary",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "_id",
                                "type": "asc"
                            },
                            "searchcondition": {},
                            // "count": false
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },
                    {
                        id: 1,
                        label: "Search By Organizer Email",
                        name: "organizer_email",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "calendar-api/conflictfullday-autocomplete-orgname",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "name",
                                "type": "desc"
                            },
                            "searchcondition": {},
                            // "count": false
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id"},
                    },
                    {
                        id: 2,
                        label: "Appointment Start Date",
                        name: "slot_start_time_unix",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        field: "slot_start_time_unix",
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "slot_start_time_unix", param: "$lte" }
                    },
                    {
                        id: 3,
                        label: "Appointment End Date",
                        name: "slot_end_time_unix",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        field: "slot_end_time_unix",
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "slot_end_time_unix", param: "$lte" }
                    },

                ]
            }
        },
    });




    var modifyTableHeaders =
        [
            { val: "summary", name: "Summary" },
            // { val: "connected_gmail", name: "connected_gmail" },
            { val: "organizer_email", name: "Organizer Email" },
            { val: "time_diff_creation", name: "Deletion Time(min)" },
            { val: "timespan", name: "Timespan" },
            { val: "slot_start_time_unix", name: "Appointment Start Time Unix", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },
            { val: "slot_end_time_unix", name: "Appointment End Time Unix", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(conflictingFulldayEvents.current.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendar-api/conflict-fullday-event_list",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setConflictingFulldayEventList([...respdata.results.res])
        }
        setloaderFlag(false);
        conflictingFulldayEventFetched(true);
    }

    useEffect(() => {
        if (conflictingFulldayEventList && conflictingFulldayEventList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList();
        }

    }, [conflictingFulldayEventList]);


    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">
                    {!loaderFlag && conflictingFulldayEventList !== undefined ? (
                        <ListingTable
                            tableData={conflictingFulldayEvents.current}
                            dataset={conflictingFulldayEventList}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}
                </div>
            </div>
        </>
    )
}