import { Close } from '@mui/icons-material';
import { Alert, Box, IconButton, LinearProgress, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StateArray from '../../../../assets/json/state';
import { Form } from '../../../../form/Form';
import { getEmployerData, getlandingpagedataEmployer } from '../../../Backend/EmployerManagement/EmployerReducer';
import store from '../../../../store';
import { addlandingpageonedata, clearaddedinfo, getlandingpageoneuserdata, updateStages } from '../LandingpageReducer';

function EmployersFormLPForm(props) {
    // console.log("props+++++++++++++", props)

    const { _id } = useParams(); //--- Fetch PathParams from URL ---
    const [loading, setloading] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')
    const [snackStatusSeverity, setSnackStatusSeverity] = useState('success')

    const dispatch = useDispatch()
    const [locationArrys, setLocationArrys] = useState([]);

    let reqbodyforjobcategory = { "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG, }

    // console.log("reqbodyforjobcategory===>>>", reqbodyforjobcategory);
    // --- setting state array 
    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            // console.log("Newarr+", Newarr);
            setLocationArrys(Newarr);
        }
    }, []);

    // const employerPrePopulateData = useSelector((state) => (state.LandingpagefontReducer?.affiliatedata && Object.keys(state.LandingpagefontReducer.affiliatedata).length > 0) ? state.LandingpagefontReducer.affiliatedata : {})

    const employerPrePopulateData = useSelector((state) => state.EmployerReducer?.employerData && Object.keys(state.EmployerReducer.employerData).length > 0 ? state.EmployerReducer.employerData : null)


    const formInputData = useSelector((state) =>
        state.formSlice.formData.employerformset ? state.formSlice.formData.employerformset : null
    );

    const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.employerformset) ? state.formSlice?.formSubmissionState?.employerformset : 0)

    const dataaddedinfo = useSelector((state) => state.EmployerReducer?.addedinfo ? state.EmployerReducer.addedinfo : null)
    const userData = useSelector(state => (state.EmployerReducer?.landingpageoneuserdata && Object.keys(state.EmployerReducer.landingpageoneuserdata).length > 0) ? state.EmployerReducer.landingpageoneuserdata : {});
    // useEffect(() => {
    //     console.log("dataaddedinfo====>>>", dataaddedinfo )
    //     }, [])

    useEffect(() => {

        store.dispatch(getlandingpagedataEmployer(reqbodyforjobcategory));

    }, [])


    const addapicall = async (val) => {
        // console.log("val>>>", val);

        val = { ...val }
        let body = {
            "firstname": val.firstname,
            "lastname": val.lastname,
            "email": val.email,
            "phone": val.phone,
            "address": val.address,
            "state": val.state,
            "city": val.city,
            "zip": val.zip,
            "password": val.password,
            "confirm_password": val.confirmPassword,
            "employer_signup_stage": 2,
            "createdon_datetime": new Date().getTime(),
            "_id": (_id !== undefined && _id !== null) ? _id : undefined,
            // "page_id": userData.landing_page_id,
            // "page_slug": userData.landing_page_slug,
        }

        if (Object.keys(body).length > 0) {
            // console.log("body-----", body);
            // return
            // setLoader(true)
            setloading(true)

            let dataresponse = await store.dispatch(addlandingpageonedata(body));
            // console.log("dataresponse", dataresponse);
            setloading(false)
            if (dataresponse && dataresponse.payload && dataresponse.payload.status == 'error') {
                // console.log("HITTTTT", dataresponse.payload.message);
                setSnackMessage(dataresponse.payload?.message)
                setSnackStatusSeverity('error')
                setTimeout(() => { setOpenSnack(true) }, 1000)
                // // if (landingPageSubmissionState && landingPageSubmissionState === 2) {
                // // setSnackopen(true)
                // setMsg(dataresponse.payload.message)
                // // dispatch(setSnackbar({ open: true, message: "jiiii", status: 'error' }))}
            }
            else {
                // console.log("Responseeeeeeeeeeeeeeeee");
                redirect(_id)
                // console.log("dataaddedinfo",dataaddedinfo);


            }


        }
    };

    const redirect = () => {
        // console.log("Res+++++++++++++++++++++++++++++");
        // dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))
        setSnackMessage("Thank you for submitting!")
        setOpenSnack(true)

        // const data = {
        //     stage_slug: '5QH6nS8FXNawY',
        //     added_by: 'System',
        //     added_for: id ? id : dataaddedinfo._id,
        //     is_delete: 0,
        //     status: 1
        // }
        // console.log("data===========================>", data)
        // // return
        // dispatch(updateStages(data));
        // dispatch(clearaddedinfo())
        setTimeout(() => {
            dispatch(getEmployerData(_id))
        }, 1000)



    }

    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
            // console.log("formInputData", formInputData, submissionState);
        }
    }, [submissionState]);





    const employerFormSet = {
        id: 'employerformset',
        formtype: "add",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection9",
        submitBtnName: "Submit",
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            _id: (_id !== undefined && _id !== null) ? _id : undefined

        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                heading: "Your First Name",
                label: " First Name",
                name: "firstname",
                className: 'formfild formfild4',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.firstname !== undefined) ? employerPrePopulateData.firstname : undefined,

            },
            {
                id: 15,
                heading: "Your Last Name",
                label: "Last Name",
                name: "lastname",
                className: 'formfild formfild4',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.lastname !== undefined) ? employerPrePopulateData.lastname : undefined,
            },
            {
                id: 13,
                heading: "Your Email",
                label: "Your Email",
                name: "email",
                className: 'formfild formfild4',
                type: "text",
                inputType: "email",
                autoCapitalize: "none",
                rules: { required: true },
                disabled: (employerPrePopulateData && employerPrePopulateData.email !== undefined) ? true : false,
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.email !== undefined) ? employerPrePopulateData.email : undefined,
            },

            {
                id: 14,
                heading: "Your Phone Number",
                label: "Your Phone Number",
                name: "phone",
                className: 'formfild formfild4',
                type: "text",
                inputType: "phone",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.phone !== undefined) ? employerPrePopulateData.phone : undefined,
            },
            {
                id: 25,
                heading: "Address",
                placeholder: 'Address',
                label: "Address",
                name: "address",
                className: 'formfild textareafild',
                type: "textarea",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    // custom: "Value is Invalid"
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.address !== undefined) ? employerPrePopulateData.address : undefined,


            },
            {
                id: 6,
                heading: "State",
                label: "State",
                name: "state",
                className: 'list_search_item formfild6',
                type: "select",
                // other: true,
                values: locationArrys,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.state !== undefined) ? employerPrePopulateData.state : undefined,
            },

            {
                id: 7,
                heading: "City",
                label: "City",
                name: "city",
                className: 'inputBlock formfild6',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.city !== undefined) ? employerPrePopulateData.city : undefined,

            },

            {
                id: 8,
                heading: "Zip Code",
                label: "Zip Code",
                autoComplete: "off",
                name: "zip",
                className: 'inputBlock formfild6',
                type: "text",
                // inputType:"number",
                inputType: "zip",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.zip !== undefined) ? employerPrePopulateData.zip : undefined,

            },

            {
                id: 11,
                heading: "Password",
                label: "Password",
                name: "password",
                className: 'inputBlock formfild6 passwordinput',
                type: 'password',
                rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },

                errorMessage: {
                    required: "This Field is Required",
                    // errorMessageConfirmPassword: "Password does not match",         
                    custom: "Value is Invalid",
                    validate: "Password does not match",
                    maxLength: "Maximum allowed input length is 15 characters",

                    pattern: "You need to provide a strong password",
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.password !== undefined) ? employerPrePopulateData.password : undefined,

            },

            {
                id: 12,
                heading: "Confirm Password",
                label: "Confirm Password",
                name: "confirmPassword",
                className: 'inputBlock formfild6 passwordinput',
                type: 'confirm_password',
                defaultValue: '',
                rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
                errorMessage: {
                    required: "This Field is Required",
                    validate: "Password does not match",
                    errorMessageConfirmPassword: "Password does not match",
                    maxLength: "Maximum allowed input length is 15 characters",

                    pattern: "You need to provide a strong password",
                    custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
                },
                defaultValue: (employerPrePopulateData && employerPrePopulateData.confirmPassword !== undefined) ? employerPrePopulateData.confirmPassword : undefined,
            },
            {
                id: 22,
                type: "html",
                className: "passwordCls inputBlock1line ",
                value: "<p>* Password should contain at least 8 characters and max 15 characters with one numeric digit, one uppercase letter, one lowercase letter, and a special character.</p>"
            },
            {
                id: 125,
                // heading: "Captcha",
                // label: "Captcha",
                name: "captcha",
                className: 'formfild formfild4',
                type: "captcha",
                rules: { required: true },
                errorMessage: {
                    required: "Prove, You're Not a Robot",
                    custom: "Value is Invalid"
                },
            },


        ]

    };

    const handleClose = (event, reason) => {
        setOpenSnack(false);
    };


    return (
        <>
            <div>
                {/* <p>{JSON.stringify(employerPrePopulateData)}</p> */}
                {_id !== null && employerPrePopulateData && (employerPrePopulateData.length > 0 || Object.keys(employerPrePopulateData).length > 0) && <Form formData={employerFormSet} />}
                {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}

                <Snackbar
                    className="form_error_snackbar"
                    open={openSnack}
                    autoHideDuration={10000}
                    onClose={() => handleClose()}
                    // message={message}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => handleClose()}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    }
                >
                    <Alert onClose={handleClose} severity={snackStatusSeverity} > {snackMessage}</Alert>
                </Snackbar>

            </div>
        </>
    )
}

export default EmployersFormLPForm