import React from 'react'
import { Form } from '../../../form/Form'

const AddFileCetagory = () => {

    const dataform = {
        id: 'add_file_form',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "",
        urlPathOnSuccessfulFormSubmission: "/jobticket-category",
        submitBtnName: "Submit",
        cancelBtnName: "Cancel",
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            heading: "Category Name",
            label: "Category Name",
            name: "category_name",
            className: 'inputBlock inputBlock1line',
            type: "text",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
            },
            defaultValue:"",
    
          },
          {
            id: 3,
            heading: "Priority",
            label: "priority",
            name: "priority",
            className: 'inputBlock inputBlock2line',
            type: "text",
            inputType: "number",/////////////// If not mentioned default will be text //////////////
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              maxLength: "MaxLength length 20 allowed",
              validate: "Lastname is not valid",
              custom: "Value is Invalid"
            },
            defaultValue: "",
          },
          {
            id: 1,
            heading: "Description",
            label: "SunEditor",
            name: "category_des",
            type: "sunEditor",
            className: "inputBlock1line",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
            },
    
            placeholder: "Please type here...",
            editorWidth: 300,
            buttonListOptions: [
              ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
              ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
              ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
            ],
            defaultValue: "",
    
          },
          {
            id: 4,
            name: "status",
            className: 'inputBlock1line',
            type: 'singleCheckbox',
            values: { key: 1, val: 'Active' },
            defaultValue: "",
          },
        ],
        customButtons: [
          {
            id: 'jobgoback001',
            label: 'Go Back',
            className: 'default',
            type: 'button',
        },
        ]
      }
  return (
    <>
        <h1 className='page_heading sdheading'>ADD DOCUMENT CATEGORY</h1>
            <div className="dataform2_wrapper">
                <div className='adminformbody'>
                <Form formData={dataform} />
            </div>
        </div>
    </>
  )
}

export default AddFileCetagory