import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const NoPage = () => {
    return (
        <>


            <div className='forEmployerThanks'>
                <div className='thankyou-container'>
                    <div className='thankyou-block'>
                        <div className='contentEmployer error-page'>
                            <Box sx={{ flexGrow: 1, }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <div className="headerLogoEmployer">
                                            <h1> 404 </h1>

                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3> Opps! Page Not Found.</h3>

                                        <p>Sorry, the page you're looking for doesn't exist.</p>

                                    </Grid>

                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>);
};

export default NoPage;
