import { Button, IconButton, LinearProgress, Modal, Paper, Snackbar, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLocationList } from '../../EmployerReducer'
import { setReloadTableFlag } from '../../../../../listing/listReducer'
import { setSnackbar } from '../../../../Layout/layoutReducer'

function DeleteModalLocation(props) {

    const dispatch = useDispatch()

    // const deleteSuccessLocation = useSelector((state) => state.EmployerReducer?.deleteLocationSuccess ? state.EmployerReducer.deleteLocationSuccess : null)
    const deleteSuccessLocation = useSelector(state => state.EmployerReducer?.locationMessageSuccess ? state.EmployerReducer.locationMessageSuccess : null);

    // const deleteLocationLoadr = useSelector(state => state.EmployerReducer?.deleteLoader ? state.EmployerReducer.deleteLoader : false)
    const deleteLocationLoadr = useSelector((state) => state.EmployerReducer?.statusloading ? state.EmployerReducer.statusloading : null)


    // console.log("deleteSuccessLocation=========>>>", deleteSuccessLocation);

    const [openSnackbar, setOpenSnackbar] = useState(false)

    const [rowDataFordlt, setRowDataFordlt] = useState(null)
    // const [dltLoader, setDltLoader] = useState(false)

    useEffect(() => {
        if (props.rowDataID !== null && Object.keys(props.rowDataID).length > 0) {
            setRowDataFordlt(props.rowDataID)
            // console.log("rowdATTA==>>>", props.rowDataID, rowDataFordlt);
        }
    }, [JSON.stringify(props.rowDataID)])


    const deleteFunctioanl = () => {
        let body = {
            "_id": rowDataFordlt?._id
        }

        dispatch(deleteLocationList(body))

        // console.log("body=========>>>>", body)


        // setDltLoader(true)

        setTimeout(() => {
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Location Deleted Succesfully!",
                    status: "success",
                })
            );
        }, 1000)

        setTimeout(() => {
            props.setDeleteOpen(false)

        }, 2000);


        setTimeout(() => {
            dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }));
        }, 2000)
    }


    const handleCloseDelete = () => {
        props.setDeleteOpen(false)
    }

    return (
        <>
            <Modal className='modalblock notes_details delete_modal'
                open={props.deleteOpen}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    border: '10px solid #0569ad!important',
                    background: '#fff',
                    boxShadow: 24,
                    textAlign: "center",
                }}>
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseDelete}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography id="modal-modal-title" variant="h5" component="h5">
                        <p className='alertPara2'> Alert !!</p>
                        <div className='notes_header'>
                            <p> Are you sure you want to delete this record(s)? This can't be undone. </p>
                        </div>
                    </Typography>
                    <div>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={deleteFunctioanl} className="modalBTN">Yes</Button>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={handleCloseDelete} className="modalBTN">No</Button>
                    </div>

                    {deleteLocationLoadr && <LinearProgress sx={{ marginTop: '10px' }} />}

                </Box>
            </Modal>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={deleteSuccessLocation}
                // action={action}
                sx={{ bottom: "24px !important" }}
            />


        </>
    )
}

export default DeleteModalLocation