
import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState, useLocation } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import FDSearch from './FDSearch';
import { clearLeadNotes, folderAccessDeleteNotes, getNotes, UserActivity } from './folderViewReducer';
import FDNotes from './FDNotes';
import DeleteIcon from '@mui/icons-material/Delete';

import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { cleargetNotesleaddata, getNotesleaddata, getNotesleaddataCount } from '../../LeadManagement/LeadReducer';
import store from '../../../../store';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { useCookies } from 'react-cookie';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AddEditJobtickets from '../../Jobtickets/ManageJobtickets/AddEditJobtickets';
import { getjobticketlistdata } from '../../Jobtickets/JobTicketReducer';



const JobticketAddEditFV = (props) => {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch()
  const { id } = useParams();
  const [cookieData, setCookieData] = useCookies();
  const [queryParamId, setQueryParamId] = useState();

  // console.warn("<<<<<<<<< props from notes component >>>>>>", notesData)

  // ---- geeting and setting QueryParam -----
  // const queryParam = useLocation();
  // const paramId = queryParam?.search?.split("=");

  // useEffect(() => {
  //   if (paramId[1]) {
  //     setQueryParamId(paramId[1])
  //   }
  // }, [JSON.stringify(paramId)])
  // -------------------------------

  // console.warn("<<<<<<<<< queryParamId from NotesView component >>>>>>", queryParamId)



  // ----------------------------------------Global State from Redux---------------------------------------- //
  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const loader = useSelector(state => (state?.DashboardReducer?.loading) ? state.DashboardReducer.loading : false)
  const notesLoading = useSelector((state) => state.LeadReducer?.notesloading ? state.LeadReducer.notesloading : false)


  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.manageJobAddEditForm) ? state.formSlice.formSubmissionState.manageJobAddEditForm : 0)

  // ----------------------------------------Components Private State---------------------------------------- //

  useEffect(() => {
    if (submissionState === 2) {

      setTimeout(() => {
        props.modalclose(false);

      }, 1500);

    }
  }, [submissionState])






  // snackbar Close
  const ModalClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.modalclose(false);
  };
  // ---------------



  return (
    <>


      <Modal
        className="modalblock SD_modalblock"
        open={props.toogle}
        onClose={ModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box className="BoxMui_modal configure-role" sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: '80%',
          height: '80%',
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,

          overflow: "scroll"
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            {loader ? <LinearProgress /> : ""}
          </Typography>


         {

           (<AddEditJobtickets
            source={props.source}
            paramId={props.paramId}
          />)
          
          }


          <Button onClick={ModalClose} className="closeModalBtn">
            <Tooltip title="Close" placement="right-start">

              <CloseIcon />
            </Tooltip>
          </Button>
        </Box>
      </Modal>



    </>
  )
}

export default JobticketAddEditFV