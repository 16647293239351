import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, TextField, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { UserActivity, UserActivityInfinityScroll } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SearchIcon from '@mui/icons-material/Search';
import LinkIcon from '@mui/icons-material/Link';
import FDSearch from './FDSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import NotesIcon from '@mui/icons-material/Notes';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';




const UserActivityBlock = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 
  const watchElem = useRef(); //------- useRef
  const watchParentElem = useRef(); //------- useRef

  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  

  console.warn("<<<<<<<<<< props from userActivity FOlder view  Component >>>>>>", props)

  // ---- States here -----
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [activityList, setActivityList] = useState([]);  //*****FOR Seeting QueryP****//
  const [loadData, setLoadData] = useState(false);  //*****FOR Seeting QueryP****//

  const [open, setOpen] = React.useState(false); // snackbar const
  const [value, setValue] = React.useState([null, null]);
  const [activityLogs, setActivityLogs] = useState([]);  //***** activityLogs ****//

  const [showLoadMoreMsg, setShowLoadMoreMsg] = React.useState(false);


  //------search drawer------
  // if(searchData){
  //   setSearchDraw(false)
  // }
  //----------------------

  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();

  const paramId = queryParam.search.split("=");

  // console.log("data paramId", paramId);

  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [JSON.stringify(paramId)])

  // -------------------------------


  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


  // --- loader --- 
  const loader = useSelector(state => (state.fetchUserDataReducer?.loading
  ) ? state.fetchUserDataReducer.loading
    : null);

  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

  const loading = useSelector(state => (state?.userFolderViewReducer?.waiting) ? state.userFolderViewReducer.waiting : false)

  const userActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

  // console.log("<<<<<<<<< searchData >>>>>>>>>>>", searchData);
  console.log(userActivityData, "<<<<<<<<< searchData >>>>>>>>>>>", setActivityList);

  //-------------fetch Start_Date=mindat End_Date=masdate--------------
  let mindate = useSelector((state) =>
    state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
  );
  let maxDate = useSelector((state) =>
    state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
  );
  //---------------------------------------------------------------------



  // ####### if Activity list found copy them into a state #########
  useEffect(() => {
    if (userActivityData.length > 0) {
      setActivityList(userActivityData)
      console.log(userActivityData, "<<<<<<<<< searchData 222222222 >>>>>>>>>>>", setActivityList);
    }
  }, [userActivityData])
  //##########################################



  useEffect(() => {
    setActivityList(props.activity)

    setTimeout(() => {
      setShowLoadMoreMsg(true)
    }, 30000);
  }, [])



  // ############### Infinty Scroll with (Intersection Observer API) ##################

  useEffect(() => {


    if(watchElem.current!== undefined){

    if (activityList.length > 0) {
      let options = {
        root: watchParentElem.current,
        rootMargin: "1px",
        threshold: 1.0,
      };



      let callback = (entries, observer) => {

        entries.forEach((entry) => {
          // console.warn("<<<<<<<<<<<<<< Obserable API Triggred >>>>>>>>>>>>>>>"

          const data = {
            "condition": {
              "limit": 20,
              "skip": 20
            },
            "sort": {
              "field": "activity_time",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": props.id ? props.id : undefined
            },
            "project": {},
            "token": "",
            "email": ""
          }


          dispatch(UserActivityInfinityScroll(data))

        });
      };

      let observer = new IntersectionObserver(callback, options);

      console.warn("watchElem.current", watchElem.current)

      observer.observe(watchElem?.current);
      }
    }

  }, [watchElem.current])

  // #############################################################################













  //------------Fetch data from User Activity search Fields ------------------

  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)

      let activity_times = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined

      }

      // console.log("greater than data ", activity_time['$gte']);
      // console.log("less than data ", activity_time['$lte']);


      if (activity_times.$gte == "" || activity_times.$gte == undefined || activity_times.$gte == 'undefined') {
        delete activity_times["$gte"];
      }

      if (activity_times.$lte == "" || activity_times.$lte == undefined || activity_times.$lte == 'undefined') {
        delete activity_times["$lte"];
      }




      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          "activity_by": searchData?.activity_by ? searchData.activity_by : undefined,
          "activity_type": searchData?.activity_type ? searchData.activity_type : undefined,
          "activity_time": Object.keys(activity_times).length > 0 ? activity_times : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      }


      if (payload.searchcondition?.activity_type == undefined || payload.searchcondition?.activity_type == 'undefined' || payload.searchcondition?.activity_type == '') {
        delete payload.searchcondition.activity_type
      }

      if (payload.searchcondition?.activity_by == undefined || payload.searchcondition?.activity_by == 'undefined') {
        delete payload.searchcondition['activity_by'];
      }


      if (payload.searchcondition?.activity_time == undefined || payload.searchcondition?.activity_time == 'undefined') {
        delete payload.searchcondition["activity_time"]
      }



      // console.warn("<<<<<< Final payload Goes Here >>>>>", payload)
      dispatch(UserActivity(payload))



    }
  }, [submissionState])
  // -------------------------------------------------------------------------


  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // setErrPopup(false);
    // setErrMsgCopy(null);
  };


  //---------------------------- Input Fields For Search-----------------------------
  const searchFields = [
    {
      id: 0,
      label: "Search  Activity By ",
      name: "activity_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activityby",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {

          "user_id": queryParamId ? queryParamId : props.id
        },
        "project": {},
        "token": "",
        "email": ""
      },

    },

    {
      id: 1,
      label: "Search by Activity Type",
      name: "activity_type",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, minWidth: 300 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activity-type",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : props.id

        },
        "project": {},
        "token": "",
        "email": ""
      },

    },


    {
      id: 2,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, minWidth: 300 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 3,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, minWidth: 300 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]
  //--------------------------------------------------------------------


  return (
    <>
      <div>
        <h3>
          <PersonIcon /> {props?.name} Activity
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search User Activity">
              <IconButton onClick={() => setSearchDraw(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </div>

        </h3>
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}

        <div className="UA-scroll-activity" ref={watchParentElem}>
          {userActivityData?.length > 0 ? (
            userActivityData?.map((x, i) => (

              <div key={i} className="user-activity">
                <ul className="ua-data-block">
                  <li className="heading listItem">
                    {" "}
                    {x.activity_type == "login" ? (
                      <LoginIcon />
                    ) : x.activity_type == "tag_assigned" || x.activity_type == "tag_assigned_by" ? (
                      <LocalOfferIcon />
                    ) : x.activity_type == "folder_accessed" ||
                      x.activity_type == "folder_accessed_by" ? (
                      <FolderIcon />
                    ) : x.activity_type == "email_sent" ||
                      x.activity_type == "email_sent_by" ? (
                      <MarkEmailReadIcon />
                    ) : x.activity_type == "Log out complete" ? (
                      <LogoutIcon />
                    ) : x.activity_type == "stage_assigned_by" ||
                      x.activity_type == "stage_assigned" ? (
                      <ClearAllIcon />
                    ) : x.activity_type == "Get Contract" ? (
                      <TextSnippetIcon />
                    ) : x.activity_type == "email_open_by" ? (
                      <DraftsIcon />
                    ) : x.activity_type == "notes add" ? (
                      <NotesIcon />
                    ) : x.activity_type == "info_link_sent" ? (
                      <InsertLinkIcon />
                    ) : x.activity_type == "follow_up_call_added_by" ? (
                      <CalendarMonthIcon />
                    ) : (" ")
                    }{" "}


                    {/* {x.activity_type ? x.activity_type.split('_').join(' ') : "N/A"}{" "} */}
                    <span dangerouslySetInnerHTML={{ __html: x?.activity }}></span>
                    {x.activity_time ? <span>  &nbsp; On{" "} {format(x.activity_time, "dd-MM-yyyy HH:mm:ss")} </span> : ""}
                    {/* {x.activity} */}
                  </li>
                  <li>
                    {/* Activity Time:{" "} {format(x.activity_time, "dd-MM-yyyy HH:mm:ss")}{" "} */}
                    {/* &nbsp; On{" "} {format(x.activity_time, "dd-MM-yyyy HH:mm:ss")}{" "} */}
                  </li>
                  {/* <li>Activity: {x.activity}</li> */}
                </ul>


              </div>

            ))
          ) : (
            <p> No activity found!</p>
          )}

          {/* {activityList.length > 0 && showLoadMoreMsg ?  */}
          <div ref={watchElem}> Loading ....... </div> 
           
        </div>

      </div>
      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type={props.name == 'User' ? "user_activity" : 'lead_activity'} /> : ""}
    </>
  );
}

export default UserActivityBlock;