import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReplayIcon from "@mui/icons-material/Replay";
import { visuallyHidden } from "@mui/utils";
import {
  Badge,
  Button,
  Chip,
  Drawer,
  FormControl,
  FormLabel,
  Icon,
  LinearProgress,
  Modal,
  Pagination,
  Snackbar,
  Stack,
  SwipeableDrawer,
  Switch,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import store from "../store";
import {
  clearCustomButtonClicked,
  deleteSingleTableData,
  fetchTableCount,
  fetchTableList,
  onRowActionButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  resetRowActionData,
  resetDeleteSingleDataFlag,
  setCustomButtonClicked,
  setMultipleSeletedList,
  setTableData,
  setTableFieldsData,
  setTableRowData,
  setTempEditData,
  setViewData,
  setTableDefaultreqBody,
  clearTablerowData,
  rowCellClicked,
  resetRowCellClicked,
  setTableCount,
  resetUnmountTableFlag,
  setTableConfig,
  deleteTempSearchEndpoint,
  setCustomSorting,
  resetCustomSorting,
  setPageState,
  resetTableDataAndCount,
  resetTempBody,
  setTableReqData,
  resetApiBtnSubmissionState,
  setReloadTableFlag,
  onSubmittingApi,
  onSubmittingViewApi,
  resetApiViewActionState,
  resetApiViewActionData,
  onSubmittingActionApi,
  resetApiActionBtnSubmissionState,
  updateTableReqBodyForNotes,
  onSubmittingEditApi,
  resetApiEditActionState,
  resetApiEditActionData,
  setTempSearchEndpoint,
} from "./listReducer";
import { SearchBar } from "../search/SearchBar";
import {
  randomId,
  requestSequencer,
  timeConverter,
} from "../helper/helperFunctions";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import NotesBadge from "./Components/NotesBadge";
import NotesDialog from "./Components/NotesDialog";
import APIDialog from "./Components/APIDialog";
import ViewDialog from "./Components/ViewDialog";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import "./listing.css";
import Search from "./Search/Search";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { resetForm, resetFormData, resetFormFieldDataForAuto } from "../form/formReducer";
import { Form } from "../form/Form";
import { addlandingpageninedatabluecosde, clearbluecostSingelFieldUpdate } from "../Pages/Frontend/LandingPage/LandingpageReducer";
import { Controller } from "react-hook-form";
import { TextArea } from "../form/formComponents/TextArea";
import AddCircleIcon from '@mui/icons-material/AddCircle';
var headersArray = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableData,
    setPage,
    setOrder,
    setSelected,
    setSortDirection,
    sortDirection,
    defaultReqBody,
    updateNotesTableData,
  } = props;
  const createSortHandler = (event, property, tableData, orderBy) => {
    // console.log("log-----event", event);
    // console.log("log-----property", property);
    // console.log("log-----tableData", tableData);
    // console.log("log-----orderBy", orderBy);
    // console.log("log-----class", event.target.classList);
    // console.log(
    //   "EVENT+++++ON SORT++",
    //   event.target,
    //   event.target.classList.contains("MuiTableSortLabel-iconDirectionDesc"),
    //   event.target.classList.contains("MuiTableSortLabel-iconDirectionAsc"),
    //   property,
    //   tableData
    // );
    // console.log("SORT++++++++++++++++++++++");
    // console.log("sortType", tableData.reqBody.sort);

    if (tableData.reqBody.sort.type === "desc") {
      tableData.reqBody.sort.type = "asc";
      // console.log("DESC_________________", tableData.reqBody.sort.type);
    } else {
      tableData.reqBody.sort.type = "desc";
    }
    if (property !== orderBy) {
      // console.log("CHANGE NODE++++++++++========");
      tableData.reqBody.sort.type = "desc";
    }
    const newSortField = property;
    // console.log("newSortField", newSortField);
    tableData.reqBody.sort.field = newSortField;
    // console.log("newSortField1", tableData.reqBody.sort.field);

    tableData.reqBody.condition.skip = 0;

    if (updateNotesTableData) updateNotesTableData(tableData.reqBody);

    onHandleReload({
      // defaultReqBody,
      tableData,
      setSelected,
      setSortDirection,
    });
    onRequestSort(event, property);

    setOrder(tableData.reqBody.sort.type);
    // console.log("oredermm", tableData.reqBody.sort.type);
    setPage(0);

    setSortDirection((prev) => {
      if (tableData.reqBody.sort.type === "desc")
        return { ...prev, [property]: "asc" };
      else return { ...prev, [property]: "desc" };
    });

    // console.log("oredermm1", tableData.reqBody.sort.type);


    let tableBody = {
      tableId: tableData.tableId,
      reqBody: tableData.reqBody,
    };
    const cloneReqBody = cloneDeep(tableBody);

    // console.log("tableBody", tableBody);
    store.dispatch(setTempSearchEndpoint(cloneReqBody));
  };

  const customSorting = (property, tableData, orderBy) => {
    // console.log("custom sorting ========>", property, orderBy);
    store.dispatch(
      setCustomSorting({ tableId: tableData.tableId, column: property })
    );
    setTimeout(() => {
      store.dispatch(resetCustomSorting({ tableId: tableData.tableId }));
    }, 500);
  };
  // console.log("order=========================>", order, orderBy);
  return (
    <TableHead>
      <TableRow>
        {tableData.customBtnHead !== undefined &&
          tableData.customBtnHead.length > 0 && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
          )}

        {/* Index Header */}
        <TableCell className="index_column index_header">#</TableCell>

        {headersArray.map((headCell) => {
          return !tableData.customSort ? (
            <TableCell
              key={headCell.val + headCell.name + randomId()}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={
                typeof headCell.val == "string"
                  ? headCell.val
                  : headCell.val.index
              }
            >
              <span dangerouslySetInnerHTML={{ __html: headCell.name }}></span>
              {tableData?.sortFields?.includes(headCell.val) &&
                (orderBy === headCell.val ? (
                  <IconButton
                    className="sort_btn active_sort"
                    title={
                      order === "desc"
                        ? "Sort By Ascending"
                        : "Sort By Descending"
                    }
                    onClick={(e) =>
                      createSortHandler(e, headCell.val, tableData, orderBy)
                    }
                  >
                    {order === "desc" ? (
                      <ArrowDownward className="sort_desc" />
                    ) : (
                      <ArrowUpward className="sort_asc" />
                    )}
                  </IconButton>
                ) : (
                  <IconButton
                    className="sort_btn inactive_sort"
                    title="Sort By Descending"
                    onClick={(e) =>
                      createSortHandler(e, headCell.val, tableData, orderBy)
                    }
                  >
                    <UnfoldMoreIcon className="sort_inactive" />
                  </IconButton>
                ))}

              {/* {tableData.sortFields.includes(headCell.val) && (
                                    <Tooltip title={sortDirection[headCell.val] === undefined ? "Sort Descending" : (sortDirection[headCell.val] === 'asc') ? 'Sort Ascending' : 'Sort Descending'}>
                                        <TableSortLabel
                                            active={orderBy === headCell.val}
                                            direction={orderBy === headCell.val ? order : 'asc'}
                                            onClick={(e) => createSortHandler(e, headCell.val, tableData, orderBy)}
                                        >
                                            {orderBy === headCell.val ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </Tooltip>
                                )} */}
            </TableCell>
          ) : (
            <TableCell
              key={headCell.val + headCell.name + randomId()}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={
                typeof headCell.val == "string"
                  ? headCell.val +
                  ` ${tableData.customSort &&
                    tableData.sortFields.includes(headCell.val)
                    ? "customSort"
                    : ""
                  }`
                  : headCell.val.index +
                  ` ${tableData.customSort &&
                    tableData.sortFields.includes(headCell.val)
                    ? "customSort"
                    : ""
                  }`
              }
              onClick={() =>
                tableData.customSort == true &&
                tableData.sortFields.includes(headCell.val) &&
                customSorting(headCell.val, tableData, orderBy)
              }
              style={{
                cursor: `${tableData.sortFields.includes(headCell.val) ? "pointer" : ""
                  }`,
              }}
            >
              {tableData.sortFields.includes(headCell.val) ? (
                <Tooltip title={`Sort By ${headCell.name}`} arrow>
                  <span
                    dangerouslySetInnerHTML={{ __html: headCell.name }}
                  ></span>
                </Tooltip>
              ) : (
                <span
                  dangerouslySetInnerHTML={{ __html: headCell.name }}
                ></span>
              )}
            </TableCell>
          );
        })}
        {tableData.buttons !== undefined && tableData.buttons.length > 0 && (
          <TableCell padding="normal" align="center" title="Actions">
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const onCustomButtonClicked = ({ tableId, buttonId }) => {
  store.dispatch(setCustomButtonClicked({ tableId, buttonId }));
  setTimeout(
    () => store.dispatch(clearCustomButtonClicked({ tableId, buttonId })),
    500
  );
};

const onHandleReload = ({
  defaultReqBody,
  tableData,
  setSelected,
  reload,
  setSortDirection,
}) => {
  // console.log(
  //   "Reload===>",
  //   tableData.api,
  //   tableData,
  //   "defaultReqBody",
  //   defaultReqBody
  // );
  // console.log("body12345===>", defaultReqBody);
  // console.log("body123456===>", tableData.reqBody);
  setSortDirection({});
  ////////////////// only for reload back to first page //////////////////////////////
  if (reload) {
    store.dispatch(resetForm({ formId: tableData.searchData.formData.id }));
    store.dispatch(resetFormData({ formId: tableData.searchData.formData.id }));
    store.dispatch(resetTempBody({ tableId: tableData.tableId }));
    store.dispatch(deleteTempSearchEndpoint({ tableId: tableData.tableId }));
    store.dispatch(resetFormFieldDataForAuto({ formId: tableData.searchData.formData.id }));
    tableData.reqBody = cloneDeep(defaultReqBody);

    tableData.reqBody.condition.skip = 0;
  }
  ///////////////////////////////////////////////////////////////////////////////////
  // store.dispatch(fetchTableList({ BASE_URL: tableData.api.url, endPoint: tableData.api.endPoint, reqBody: tableData.reqBody, restResponseAttachment: { tableId: tableData.tableId } })).then(() => {
  //     store.dispatch(fetchTableCount({ BASE_URL: tableData.api.url, endPoint: tableData.api.tableCountEndpoint, reqBody: tableData.reqBody, restResponseAttachment: { tableId: tableData.tableId } }));
  // });



  if (tableData.api.endPoint) {
    if (defaultReqBody) {
      tableData.reqBody = cloneDeep(defaultReqBody);
    }
    requestSequencer({
      dispatch: store.dispatch,
      func: fetchTableList,
      args: {
        BASE_URL: tableData.api.url,
        endPoint: tableData.api.endPoint,
        reqBody: tableData.reqBody,
        restResponseAttachment: { tableId: tableData.tableId },
        recieveResponseParam: tableData.recieveResponseParam,
        endPointType: tableData.api.endPointType,
      },
    });
  }
  if (tableData.api.tableCountEndpoint) {

    requestSequencer({
      dispatch: store.dispatch,
      func: fetchTableCount,
      args: {
        BASE_URL: tableData.api.url,
        endPoint: tableData.api.tableCountEndpoint,
        reqBody: { ...cloneDeep(tableData.reqBody), count: true },
        restResponseAttachment: { tableId: tableData.tableId },
        endPointType: tableData.api.countEndPointType,
      },
    });
  }

  setSelected([]);

  // console.log(
  //   "tableData.reqBody.condition.skip at last =============================>",
  //   tableData.reqBody.condition.skip
  // );
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    tableData,
    setDrawerToggle,
    selected,
    setModalType,
    setSelected,
    setSortDirection,
    defaultReqBody,
    openApiModal,
    showSearchBar,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableData.tableTitle}
        </Typography>
      )}
      {/* ----------- Custom Buttons of Table head only on multiple selection ----------------------- */}
      {tableData.customBtnHead !== undefined &&
        tableData.customBtnHead.length > 0 &&
        tableData.customBtnHead.map((btn) => {
          if (numSelected > 0 || btn.default == true) {
            if (
              btn.type === "api" &&
              (btn.category === "modal" || btn.category === "status")
            ) {
              return (
                <Tooltip
                  className={btn.className}
                  title={btn.label ? btn.label : ""}
                >
                  <IconButton
                    className={`api_btn_header`}
                    onClick={() =>
                      openApiModal({ buttonData: btn, row: selected })
                    }
                  >
                    <Icon>
                      {btn?.category !== "status"
                        ? btn.icon_type
                          ? btn.icon_type
                          : "delete"
                        : "toggle_on"}
                    </Icon>
                  </IconButton>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={btn.title} key={randomId()}>
                  <IconButton
                    disabled={btn.disabled ? btn.disabled : false}
                    onClick={() =>
                      onCustomButtonClicked({
                        tableId: tableData.tableId,
                        buttonId: btn.id,
                      })
                    }
                  >
                    <Icon>{btn.type}</Icon>
                  </IconButton>
                </Tooltip>
              );
            }
          } else
            return <span key={randomId()} style={{ display: "none" }}></span>;
        })}

      {numSelected === 0 &&
        showSearchBar === true &&
        (tableData?.searchBarData ||
          (tableData?.searchData?.formData &&
            tableData?.searchData?.type === "drawer")) && (
          <Tooltip title="Search ">
            <IconButton
              className="search_btn"
              onClick={() => setDrawerToggle(true)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}

      {numSelected === 0 && (
        <Tooltip title="Reload">
          <IconButton
            className="replayicon"
            onClick={() =>
              onHandleReload({
                defaultReqBody,
                tableData,
                setSelected,
                reload: true,
                setSortDirection,
              })
            }
          >
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function ListingTable({
  tableData,
  dataset,
  modifyHeaders,
  paginationData,
  updateNotesTableData,
}) {
  let init = true;
  const [rows, setRows] = React.useState([]);

  const searchFlag = useSelector((state) =>
    state.tableSlice.seachFlag[tableData.tableId]
      ? state.tableSlice.seachFlag[tableData.tableId]
      : null
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      if (dataset) {
        dispatch(setTableData({ dataset, tableId: tableData.tableId }));
        if (tableData.paging && paginationData) {
          dispatch(
            setTableCount({ tableId: tableData.tableId, paginationData })
          );
        }
      }
      /////////////////////// Sequence Endpoint Call //////////////////////////////////
      if (
        tableData.api.tableCountEndpoint &&
        init === true &&
        searchFlag !== true &&
        dataset.length > 0
      ) {
        ///&& paginationData === undefined
        // console.log("count endpoint hitted-------------");
        requestSequencer({
          dispatch: store.dispatch,
          func: fetchTableCount,
          args: {
            BASE_URL: tableData.api.url,
            endPoint: tableData.api.tableCountEndpoint,
            reqBody: { ...cloneDeep(tableData.reqBody), count: true },
            restResponseAttachment: { tableId: tableData.tableId },
            endPointType: tableData.api.countEndPointType,
          },
        });
        init = false;
      }
      // dispatch(fetchTableCount({ BASE_URL: tableData.api.url, endPoint: tableData.api.tableCountEndpoint, reqBody: tableData.reqBody, restResponseAttachment: { tableId: tableData.tableId } }));
    }, 100);
  }, []);
  const [order, setOrder] = React.useState("asc");
  const [sortDirection, setSortDirection] = React.useState({});
  const [orderBy, setOrderBy] = React.useState(tableData.reqBody.sort.field);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modifyTableHeaders, setmodifyTableHeaders] = React.useState(
    modifyHeaders.length > 0 ? modifyHeaders : []
  );
  const [loading, setLoading] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(dataset.length);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [drawerToggle, setDrawerToggle] = React.useState(false);
  const [reloadTableFlag, setReloadTableFlagState] = React.useState(false);
  const [openSingleDelete, setOpenSingleDelete] = React.useState(false);
  const [defaultReqBody, setDefaultReqBody] = React.useState(null);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [customPaged, setCustomPaged] = React.useState(false);
  const [customTotalPage, setTotalPage] = React.useState(null);
  const [customPageNumber, setCustomPageNumber] = React.useState(0);
  const [customPageSkip, setCustomPageSkip] = React.useState(0);
  const [customPageLimit, setCustomPageLimit] = React.useState(0);
  const [customTotalRows, setCustomTotalRows] = React.useState(0);
  const [openApiModalState, setOpenApiModalState] = React.useState(false);
  const [apiModalTitle, setApiModalTitle] = React.useState("");
  const [apiModalHeader, setApiModalHeader] = React.useState("");
  const [apiModalClassName, setApiModalClassName] = React.useState("");
  const [apiBtnData, setApiBtnData] = React.useState(null);
  const [rowData, setRowData] = React.useState(null);
  const [openApiSnackbar, setOpenApiSnackbar] = React.useState(false);
  const [openViewSnackbar, setOpenViewSnackbar] = React.useState(false);
  const [openNoteAddSnackbar, setOpenNoteAddSnackbar] = React.useState(false);
  const [showSearchBar, setShowSearchBar] = React.useState(true);
  const [indFieldEditModal, setIndFieldEditModal] = React.useState(false)
  const [current_Input_Fields, setCurrent_Input_Fields] = React.useState('')
  const [fieldDataRow, setFieldDataRow] = React.useState({})
  const [nextStepData, setNextStepData] = React.useState([])
  const [singelFieldUpdateSnackState, setSingelFieldUpdateSnackState] = React.useState(false)
  const [singelFieldUpdateSnackMassage, setSingelFieldUpdateSnackMassage] = React.useState('')
  const [nextStepAddEdit, setNextStepAddEdit] = React.useState(false)
  const [nextStepValue, setNextStepValue] = React.useState('')
  const [nextStepEditStatus, setNextStepEditStatus] = React.useState(undefined)

  const unmountFlag = useSelector((state) =>
    state.tableSlice.unmountTableFlag[tableData.tableId]
      ? state.tableSlice.unmountTableFlag[tableData.tableId]
      : false
  );
  const tableReqBody = useSelector((state) =>
    state.tableSlice.tableDefaultReqBody[tableData.tableId]
      ? state.tableSlice.tableDefaultReqBody[tableData.tableId]
      : tableData.reqBody
  );
  const rowLoading = useSelector((state) =>
    state.tableSlice.rowLoading[tableData.tableId]
      ? state.tableSlice.rowLoading[tableData.tableId]
      : false
  );
  const tableDataFromStore = useSelector((state) =>
    state.tableSlice.tableConfig[tableData.tableId]
      ? state.tableSlice.tableConfig[tableData.tableId]
      : null
  );
  const searchEndPoint = useSelector((state) =>
    state.tableSlice.searchEndpoint[tableData.tableId]
      ? state.tableSlice.searchEndpoint[tableData.tableId]
      : null
  );
  const tempBody = useSelector((state) =>
    state.tableSlice.tempReqBody[tableData.tableId]
      ? state.tableSlice.tempReqBody[tableData.tableId]
      : null
  );
  const enableRowLoader = useSelector((state) =>
    state.tableSlice.enableRowLoader ? state.tableSlice.enableRowLoader : {}
  );
  const apiBtnSubmissionState = useSelector((state) =>
    state.tableSlice.apiBtnSubmissionState[tableData.tableId]
      ? state.tableSlice.apiBtnSubmissionState[tableData.tableId]
      : 0
  );
  const apiSnackbarMessage = useSelector((state) =>
    state.tableSlice.apiSnackbarMessage[tableData.tableId]
      ? state.tableSlice.apiSnackbarMessage[tableData.tableId]
      : ""
  );
  const apiViewActionState = useSelector((state) =>
    state.tableSlice.apiViewActionState[tableData.tableId]
      ? state.tableSlice.apiViewActionState[tableData.tableId]
      : 0
  );
  const apiViewActionData = useSelector((state) =>
    state.tableSlice.apiViewActionData[tableData.tableId]
      ? state.tableSlice.apiViewActionData[tableData.tableId]
      : null
  );
  const apiActionBtnSubmissionState = useSelector((state) =>
    state.tableSlice.apiActionBtnSubmissionState[tableData.tableId]
      ? state.tableSlice.apiActionBtnSubmissionState[tableData.tableId]
      : 0
  );
  const notesAddFormSubmissionState = useSelector((state) =>
    state.tableSlice.notesAddFormSubmissionState[tableData.tableId]
      ? state.tableSlice.notesAddFormSubmissionState[tableData.tableId]
      : 0
  );
  const notesAddFormSnackbarMessage = useSelector((state) =>
    state.tableSlice.notesAddFormSnackbarMessage[tableData.tableId]
      ? state.tableSlice.notesAddFormSnackbarMessage[tableData.tableId]
      : ""
  );
  const apiEditBtnSubmissionState = useSelector((state) =>
    state.tableSlice.apiEditBtnSubmissionState[tableData.tableId]
      ? state.tableSlice.apiEditBtnSubmissionState[tableData.tableId]
      : 0
  );
  const clickedRowData = useSelector((state) =>
    state.tableSlice.tempRowData[tableData.tableId]
      ? state.tableSlice.tempRowData[tableData.tableId]
      : null
  );
  const searchedDefaultBody = useSelector((state) =>
    state.tableSlice.searchEndpoint[tableData.tableId]
      ? state.tableSlice.searchEndpoint[tableData.tableId]
      : null
  );



  // Deep 
  const editIndeField = useSelector((state) => state.formSlice.formSubmissionState?.editBlueCstLeadField ? state.formSlice.formSubmissionState.editBlueCstLeadField : 0)
  const indeChangedFoemData = useSelector((state) => state.formSlice.formData?.editBlueCstLeadField ? state.formSlice.formData.editBlueCstLeadField : {})
  const bluecoseloader = useSelector((state) => state.LandingpagefontReducer?.bluecoseloader ? state.LandingpagefontReducer?.bluecoseloader : false);
  const bluecostSingelFieldUpdate = useSelector((state) => state.LandingpagefontReducer?.bluecostSingelFieldUpdate ? state.LandingpagefontReducer?.bluecostSingelFieldUpdate : null);

  React.useEffect(() => {
    // console.log(
    //   "enableRowLoader===============================+>",
    //   searchedDefaultBody
    // );
  }, [searchedDefaultBody]);
  React.useEffect(() => {
    if (unmountFlag == true) {
      // console.log("unmountFlag==========================>", unmountFlag);
      tableData.reqBody = cloneDeep(tableReqBody);
      dispatch(resetUnmountTableFlag({ tableId: tableData.tableId }));
    }
  }, [unmountFlag]);

  //   setmodifyTableHeaders([...modifyTableHeaders])
  // console.log("modifyTableHeaders++", modifyTableHeaders);
  headersArray = modifyHeaders;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  React.useEffect(() => {
    // const tableDataDefaultReqBody = Object.assign({}, tableData.reqBody);
    const tableDataDefaultReqBody = cloneDeep(tableData.reqBody);
    const tableConfig = cloneDeep(tableData);
    dispatch(
      setTableDefaultreqBody({
        reqBody: tableDataDefaultReqBody,
        tableId: tableData.tableId,
      })
    );
    dispatch(setTableConfig({ tableId: tableData.tableId, tableConfig }));
  }, []);

  React.useEffect(() => {
    dispatch(
      setMultipleSeletedList({ tableId: tableData.tableId, rowIds: selected })
    );

    //////////// Clear Custom Button Clicked of Multiple Delete and Edit ///////////////
    if (tableData.customBtnHead !== undefined)
      tableData.customBtnHead.forEach((btn) => {
        if (
          selected.length === 0 &&
          store.getState().tableSlice.customButtonClicked[tableData.tableId] &&
          store.getState().tableSlice.customButtonClicked[tableData.tableId][
          btn.id
          ] !== undefined
        ) {
          dispatch(
            clearCustomButtonClicked({
              tableId: tableData.tableId,
              buttonId: btn.id,
            })
          );
        }
      });
  }, [selected]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  React.useEffect(() => {
    // console.log("Selected", selected);
  }, [selected]);

  //////////////////////////////////// ON PAGE CHANGE /////////////////////////////////////////////
  const handleChangePage = (event, newPage) => {
    // console.log(
    //   "NEW PAGE_________________--",
    //   newPage,
    //   page,
    //   "tableData",
    //   tableData
    // );
    dispatch(
      setPageState({
        tableId: tableData.tableId,
        newPage: newPage,
        prevPage: page,
      })
    );

    let newSkip = null;
    if (tableData.paginationType === "stackPagination") {
      newSkip = tableData.reqBody.condition.limit * (newPage - 1);
      setPage(newPage - 1);
    } else {
      newSkip = tableData.reqBody.condition.limit * newPage;
      setPage(newPage);
    }
    // console.log("NEW SKIP+++++++=", defaultReqBody, newSkip);
    // let reqBody = tempBody !== null ? tempBody : tableData.reqBody;
    let reqBody = {};

    tableData.reqBody.condition.skip = newSkip;
    let storedata = store.getState();

    if (
      storedata.tableSlice !== undefined &&
      storedata.tableSlice.tempReqBody !== undefined &&
      storedata.tableSlice.tempReqBody[tableData.tableId] !== undefined &&
      storedata.tableSlice.tempReqBody[tableData.tableId].searchcondition !==
      undefined
    )
      tableData.reqBody.searchcondition =
        storedata.tableSlice.tempReqBody[tableData.tableId].searchcondition;
    reqBody = tableData.reqBody;


    if (updateNotesTableData) updateNotesTableData(reqBody);

    onHandleReload({ reqBody, tableData, setSelected, setSortDirection });
    let tableBody = {
      tableId: tableData.tableId,
      reqBody: tableData.reqBody,
    };
    const cloneReqBody = cloneDeep(tableBody);
    dispatch(setTempSearchEndpoint(cloneReqBody));
  };

  React.useEffect(() => {
    // console.log("tableData----------------======>", tableData);
  }, [tableData]);

  const handleChangeCustomPage = (e, newPage) => {
    const choicePage = (newPage - 1).toString();

    let url;
    if (searchEndPoint) url = new URL(tableData.api.url + searchEndPoint);
    else url = new URL(tableData.api.url + tableData.api.endPoint);
    let searchParams = url.searchParams;

    searchParams.set("page", choicePage);
    url.search = searchParams.toString();

    const new_url = url.toString().replace(tableData.api.url, "");

    // console.log("tableData.api.endPoint====>", tableData.api.endPoint);

    if (tableData.api.endPoint) {
      requestSequencer({
        dispatch: dispatch,
        func: fetchTableList,
        args: {
          BASE_URL: tableData.api.url,
          endPoint: new_url,
          reqBody: tableData.reqBody,
          restResponseAttachment: { tableId: tableData.tableId },
          recieveResponseParam: tableData.recieveResponseParam,
          endPointType: tableData.api.endPointType,
        },
      });
      dispatch(setTempSearchEndpoint(tableData));
    }
  };
  ////////////////////////////////////// ON PAGE ROWS COUNT CHANGE //////////////////////////////////
  const handleChangeRowsPerPage = (event) => {
    const newLimit = event.target.value;
    setRowsPerPage(newLimit);
    let reqBody = tableData.reqBody;
    reqBody.condition.limit = newLimit;
    reqBody.condition.skip = 0;
    let storedata = store.getState();
    if (
      storedata.tableSlice !== undefined &&
      storedata.tableSlice.tempReqBody !== undefined &&
      storedata.tableSlice.tempReqBody[tableData.tableId] !== undefined &&
      storedata.tableSlice.tempReqBody[tableData.tableId].searchcondition !==
      undefined
    )
      tableData.reqBody.searchcondition =
        storedata.tableSlice.tempReqBody[tableData.tableId].searchcondition;
    // reqBody = tableData.reqBody;

    onHandleReload({
      defaultReqBody,
      tableData,
      setSelected,
      setSortDirection,
    });
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  /////////////////// On Delete List Data //////////////////
  const onHandleDelete = (row, tableData) => {
    setOpenModal(true);
    setDeleteRow(row);
  };
  const handleConfirmDelete = async (confirm) => {
    if (confirm && deleteRow !== null) {
      await store.dispatch(
        deleteSingleTableData({
          BASE_URL: tableData.api.url,
          endPoint: tableData.api.deleteSingleUserEndpoint,
          reqBody: tableData.deleteSingleUserReqBody,
          restResponseAttachment: { tableId: tableData.tableId },
          restBodyAttachment: { id: deleteRow._id },
        })
      );

      onHandleReload({
        defaultReqBody,
        tableData,
        setSelected,
        setSortDirection,
      });
    }

    setOpenModal(false);
    setDeleteRow(null);
  };

  /////////////////// On View List Data //////////////////
  const onHandleView = (row, buttonId) => {
    dispatch(
      setViewData({
        tableId: tableData.tableId,
        viewData: row,
        buttonId: buttonId,
      })
    );
  };

  ////////////////////// On Edit List Data //////////////////
  const onHandleEdit = (row, buttonId) => {
    dispatch(
      setTempEditData({
        editData: row,
        tableId: tableData.tableId,
        buttonId: buttonId,
      })
    );
  };
  //////////////////////////// No Row Custom Button Clicked ////////////////////
  const onCustomBtnClickedRow = (row, buttonId) => {
    dispatch(setCustomButtonClicked({ tableId: tableData.tableId, buttonId }));
    dispatch(setTableRowData({ tableId: tableData.tableId, row }));
    setTimeout(() => {
      dispatch(
        clearCustomButtonClicked({ tableId: tableData.tableId, buttonId })
      );
      dispatch(clearTablerowData({ tableId: tableData.tableId }));
    }, 1000);
  };

  ///////////////////////// Action Button Functionality //////////////////////////
  const onRowActionButtonClick = ({
    row,
    buttonId,
    base_url,
    endPoint,
    body,
    payloadAttach,
    isArray,
    rowId,
  }) => {
    // console.log(
    //   "On action button clicked",
    //   row,
    //   buttonId,
    //   base_url,
    //   endPoint,
    //   body,
    //   payloadAttach,
    //   isArray
    // );
    dispatch(setTableRowData({ tableId: tableData.tableId, row }));
    let reqBody = { ...body };
    if (payloadAttach && payloadAttach.length > 0) {
      payloadAttach.forEach((cur) => {
        if (row[cur] !== undefined && row[cur])
          reqBody.searchcondition[cur] = row[cur];
      });
    }
    dispatch(
      onRowActionButtonClicked({
        BASE_URL: base_url,
        endPoint,
        reqBody,
        isArray,
        buttonId,
        tableId: tableData.tableId,
        rowId,
      })
    );
  };
  ///////////////////// link Button Functionality /////////////////////////////
  const onRowLinkButtonClick = ({
    row,
    targetBlank,
    queryParams,
    params,
    base_url,
    endPoint,
  }) => {
    // console.log(
    //   "On link button Click",
    //   row,
    //   targetBlank,
    //   queryParams,
    //   params,
    //   base_url,
    //   endPoint
    // );
    let url = `${endPoint}`;
    ///////////////////// Adding params in the url //////////////////////
    if (queryParams) {
      params.forEach((curParam, index) => {
        if (row[curParam] !== undefined) {
          //////////// Add ? or & ////////
          if (index === 0) url += `?`;
          else url += `&`;
          ////////// Add query ///////////////
          url += `${curParam}=${row[curParam]}`;
        }
      });
    } else {
      params.forEach((curParam) => {
        if (row[curParam] !== undefined) {
          ////////// Add params ///////////////
          url += `/${row[curParam]}`;
        }
      });
    }
    //////////////////////////// Open in Traget Balnk or Not ////////////////////////
    if (targetBlank) {
      window.open(`${base_url}${url}`, "_blank");
    } else {
      navigate(`/${url}`);
    }
  };

  const onHandleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDrawer = () => {
    if (drawerToggle === true) setDrawerToggle(false);
  };



  const isSelected = (name) => selected.indexOf(name) !== -1;

  const mergeColumn = (place, row, data) => {
    // console.log("data====>", data, row);
    if (place == "after") {
      return `<span className='mergeAfter'>${row[data.attach.after]}</span>`;
    } else if (place == "before") {
      return `<span className='mergeBefore'>${row[data.attach.before]}</span>`;
    } else return "";
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("_+++++++++++++++++++++++++=");
    setRows(storeData.tableSlice.tableData[tableData.tableId]);
    setLoading(storeData.tableSlice.loading[tableData.tableId]);
    setRowsPerPage(tableData.reqBody.condition.limit);
    setOrderBy(tableData.reqBody.sort.field);
    setOrder(tableData.reqBody.sort.type);
    setPage(
      parseInt(
        tableData.reqBody.condition.skip / tableData.reqBody.condition.limit
      )
    );
    setReloadTableFlagState(
      storeData.tableSlice.reloadTableFlag[tableData.tableId]
    );
    setDefaultReqBody(
      storeData.tableSlice.tableDefaultReqBody[tableData.tableId]
    );
    setDeleteLoading(storeData.tableSlice.deleteLoading[tableData.tableId]);
    if (storeData.tableSlice.customPagination[tableData.tableId]) {
      setCustomPaged(
        storeData.tableSlice.customPagination[tableData.tableId]?.pageable
          ?.paged
      );
      setTotalPage(
        storeData.tableSlice.customPagination[tableData.tableId]?.totalPages
      );
      setCustomPageNumber(
        storeData.tableSlice.customPagination[tableData.tableId]?.pageable
          ?.pageNumber
      );
      setCustomPageSkip(
        storeData.tableSlice.customPagination[tableData.tableId]?.pageable
          ?.offset
      );
      setCustomPageLimit(
        storeData.tableSlice.customPagination[tableData.tableId]?.pageable
          ?.pageSize
      );
      setCustomTotalRows(
        storeData.tableSlice.customPagination[tableData.tableId]?.totalElements
      );
    }
    ////////////////////////////////// Delete Sanackbar /////////////////////////////
    if (
      storeData.tableSlice.deleteSingleDataSuccess[tableData.tableId] !==
      undefined &&
      storeData.tableSlice.deleteSingleDataSuccess[tableData.tableId] === 2
    ) {
      // setOpenSingleDelete(true)
    } else {
      setOpenSingleDelete(false);
    }
    //////////////////////////////////////////////////////////////////////////////
    if (storeData.tableSlice.count[tableData.tableId] !== undefined) {
      setRowCount(storeData.tableSlice.count[tableData.tableId]);
    }



  });
  ///////////////////////// reset table single delete flag /////////////////////////////////
  React.useEffect(() => {
    if (openSingleDelete)
      setTimeout(
        () =>
          dispatch(resetDeleteSingleDataFlag({ tableId: tableData.tableId })),
        500
      );
  }, [openSingleDelete]);
  ////////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    // console.log("defaultReqBody0000", defaultReqBody);
  }, [defaultReqBody]);

  React.useEffect(() => {
    if (reloadTableFlag === true) {
      // console.log("reloadhit", reloadTableFlag);
      let body =
        searchedDefaultBody !== null && searchedDefaultBody !== undefined
          ? searchedDefaultBody
          : defaultReqBody;

      // console.log("body1234", body);
      // onHandleReload({ defaultReqBody, tableData, setSelected, setSortDirection, reload: true });

      // setTimeout(() => {
      onHandleReload({
        defaultReqBody: body,
        tableData,
        setSelected,
        setSortDirection,
        reload: false,
      });
      // }, 1000);

      // console.log("body1234--", body);
      // console.log("defaultReqBody1234",tableData);
      dispatch(resetReloadTableFlag({ tableId: tableData.tableId }));
    }
  }, [reloadTableFlag]);

  const openApiModal = ({ buttonData, row }) => {
    // console.log("row=====================+>", row);
    // return;
    setOpenApiModalState(true);
    setApiModalTitle(buttonData.modalTitle ? buttonData.modalTitle : "");
    setApiModalHeader(buttonData.modalHeader ? buttonData.modalHeader : "");
    setApiModalClassName(
      buttonData.modalClassName ? buttonData.modalClassName : ""
    );
    setApiBtnData(buttonData);
    setRowData(row);
  };

  const doAction = ({ buttonData, row }) => {
    const base_url = buttonData?.api_data?.base_url
      ? buttonData.api_data.base_url
      : "not_available";
    const endpoint = buttonData?.api_data?.endpoint
      ? buttonData.api_data.endpoint
      : "not_available";
    const body = buttonData?.api_data?.body
      ? cloneDeep(buttonData.api_data.body)
      : {};
    body.id = row._id ? row._id : row._id;

    dispatch(
      onSubmittingActionApi({
        BASE_URL: base_url,
        endPoint: endpoint,
        reqBody: body,
        tableId: tableData.tableId,
        rowId: row._id,
      })
    );
  };

  const closeApiModal = () => {
    setOpenApiModalState(false);
    // dispatch(resetApiBtnSubmissionState({ tableId: tableData.tableId }))
    setTimeout(() => {
      setApiModalTitle("");
      setApiModalHeader("");
      setApiModalClassName("");
      setApiBtnData(null);
      setRowData(null);
    }, 200);
  };

  const viewAction = ({ buttonData, row }) => {
    const base_url = buttonData?.api_data?.base_url
      ? buttonData.api_data.base_url
      : "not_available";
    const endpoint = buttonData?.api_data?.endpoint
      ? buttonData.api_data.endpoint
      : "not_available";
    const body = buttonData?.api_data?.body
      ? cloneDeep(buttonData.api_data.body)
      : {};
    if (!body.searchcondition) body.searchcondition = {};
    body.searchcondition._id = row._id ? row._id : row._id;
    setApiBtnData(buttonData);
    setApiModalClassName(
      buttonData.modalClassName ? buttonData.modalClassName : ""
    );
    dispatch(
      onSubmittingViewApi({
        BASE_URL: base_url,
        endPoint: endpoint,
        reqBody: body,
        tableId: tableData.tableId,
        rowId: row._id,
      })
    );
  };

  const editAction = ({ buttonData, row }) => {
    const base_url = buttonData?.api_data?.base_url
      ? buttonData.api_data.base_url
      : "not_available";
    const endpoint = buttonData?.api_data?.endpoint
      ? buttonData.api_data.endpoint
      : "not_available";
    const body = buttonData?.api_data?.body
      ? cloneDeep(buttonData.api_data.body)
      : {};
    if (!body.searchcondition) body.searchcondition = {};
    body.searchcondition._id = row._id ? row._id : row._id;
    setApiBtnData(buttonData);
    setRowData(row);
    dispatch(
      onSubmittingEditApi({
        BASE_URL: base_url,
        endPoint: endpoint,
        reqBody: body,
        tableId: tableData.tableId,
        rowId: row._id,
      })
    );
  };

  React.useEffect(() => {
    if (apiActionBtnSubmissionState && apiActionBtnSubmissionState !== 0) {
      setTimeout(() => {
        dispatch(
          resetApiActionBtnSubmissionState({ tableId: tableData.tableId })
        );
      }, 1000);
    }
  }, [apiActionBtnSubmissionState]);

  React.useEffect(() => {
    if (apiBtnSubmissionState === 2) {
      setOpenApiSnackbar(true);
      dispatch(setReloadTableFlag({ tableId: tableData.tableId }));
      closeApiModal();
      setTimeout(() => {
        dispatch(resetApiBtnSubmissionState({ tableId: tableData.tableId }));
      }, 500);
    }
    if (apiBtnSubmissionState === 3) {
      setTimeout(() => {
        dispatch(resetApiBtnSubmissionState({ tableId: tableData.tableId }));
      }, 500);
    }

    setTimeout(() => setOpenApiSnackbar(false), 7000);
  }, [apiBtnSubmissionState]);

  React.useEffect(() => {
    if (apiViewActionState === 2) {
      setOpenViewSnackbar(true);
    }
    if (apiViewActionState && apiViewActionState !== 0) {
      setTimeout(() => {
        dispatch(resetApiViewActionState({ tableId: tableData.tableId }));
      }, 500);
    }
  }, [apiViewActionState]);

  React.useEffect(() => {
    if (notesAddFormSubmissionState === 2) {
      setOpenNoteAddSnackbar(true);
    }
    setTimeout(() => setOpenNoteAddSnackbar(false), 7000);
  }, [notesAddFormSubmissionState]);

  React.useEffect(() => {
    if (apiEditBtnSubmissionState === 2) {
      const navigationPath = `${apiBtnData?.navigate}/${rowData._id}`;
      navigate(navigationPath);
      setApiBtnData(null);
      setRowData(null);
    }
    if (apiEditBtnSubmissionState && apiEditBtnSubmissionState !== 0) {
      setTimeout(() => {
        dispatch(resetApiEditActionState({ tableId: tableData.tableId }));
      }, 500);
    }
  }, [apiEditBtnSubmissionState]);

  React.useEffect(() => {
    // console.log("page========================>", page);
  }, [page]);

  React.useEffect(() => {
    // console.log(
    //   "rows=========================>",
    //   rows,
    //   Object.keys(tableData.reqBody.searchcondition),
    //   Object.keys(tableReqBody.searchcondition),
    //   Object.keys(tableData.reqBody.searchcondition).length ===
    //   Object.keys(tableReqBody.searchcondition).length
    // );
    if (
      rows?.length === 0 &&
      Object.keys(tableData.reqBody.searchcondition).length ===
      Object.keys(tableReqBody.searchcondition).length
    ) {
      setShowSearchBar(false);
    } else {
      setShowSearchBar(true);
    }
  }, [rows, tableReqBody]);



  const addFormBlueCstLead = React.useRef({
    id: "editBlueCstLeadField",
    formtype: "add",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: "Submit",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      status: 0,
    },
    fields: [],
  })


  {/* Wark Space */}

  const handleCellClick = (row, val) => {
    setFieldDataRow(row)
    if(typeof row.next_steps === 'string'){
      let arrconv =  row.next_steps.split(',')
      setNextStepData(arrconv)

    }else{
    setNextStepData(row.next_steps)

    }

    if (tableData.cellEdit && tableData.editField && tableData.editFieldEndPoint) {
      let editebelField = tableData.editField.includes(val)
      if (editebelField) {
        if (val !== "next_steps_view") {
          setIndFieldEditModal(true)
        }
        setCurrent_Input_Fields(val)
      }
    }
    if (
      tableData.clickableColumn &&
      Array.isArray(tableData.clickableColumn) &&
      (tableData.clickableColumn.includes(val) ||
        (typeof val &&
          JSON.stringify(tableData.clickableColumn).includes(
            JSON.stringify(val)
          )))
    ) {
      dispatch(
        rowCellClicked({ tableId: tableData.tableId, row: row, column: val })
      );
      setTimeout(() => {
        dispatch(
          resetRowCellClicked({ tableId: tableData.tableId, column: val })
        );
        dispatch(clearTablerowData({ tableId: tableData.tableId }));
      }, 1500);
    }
  };




  const addNextStepFun = () => {

    let arr = []
    if (nextStepData && nextStepData.length !== 0) {
      arr = [...nextStepData]
    }

    if (nextStepEditStatus === undefined) {
      if (nextStepValue !== '') {
        arr.push(nextStepValue)
        setNextStepData(arr)
        setNextStepValue('')
        setNextStepEditStatus(undefined)
      }
    } else {
      arr[nextStepEditStatus] = nextStepValue
      setNextStepData(arr)
      setNextStepValue('')
      setNextStepEditStatus(undefined)

    }

  }

  const deleteFuntion = (index) => {
    let arr = [...nextStepData]
    arr.splice(index, 1)
    setNextStepData(arr)
  }

  const editNextStep = (index) => {
    let val = nextStepData[index]
    setNextStepValue(val)
    setNextStepEditStatus(index)
  }

  const handelNextStagePost = (e) => {
    e.preventDefault()
    let obj = {}
    if (current_Input_Fields === 'next_steps_view') {
      obj = {}
      let StepsHtml = nextStepData && nextStepData.map((item,index) => {
        if( nextStepData.length === index + 1 ){
          return `<span>Step${index+1}: ${item}</span>`
        }else{
          return `<span>Step${index+1}: ${item}</span> <hr/>`
        }
      })
      console.log("StepsHtml", StepsHtml);
      obj['next_steps_view'] = StepsHtml.join(" ")
      obj['next_steps'] = nextStepData
    }
    obj['_id'] = fieldDataRow._id
    dispatch(addlandingpageninedatabluecosde(obj))
  }







  React.useEffect(() => {
    let fields = []
    console.log("current_Input_Fields", current_Input_Fields);
    if (Object.keys(fieldDataRow).length > 0) {
      if (current_Input_Fields === "prospectbusinessname_type") {
        fields = [
          {
            id: 1,
            heading: "Prospect's Medical Facility Business Name",
            label: "Prospect's Medical Facility Business Name",
            name: "prospectbusinessname",
            className: "form_item inputBlock inputBlock1line",
            type: "text",
            defaultValue: fieldDataRow && fieldDataRow.prospectbusinessname ? fieldDataRow.prospectbusinessname : "",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          },
          {
            id: 2,
            heading: "Prospect's Title",
            label: "Prospect's Title",
            name: "medicalfacilitytype",
            className: "form_item inputBlock inputBlock1line",
            type: "text",
            defaultValue: fieldDataRow && fieldDataRow.medicalfacilitytype ? fieldDataRow.medicalfacilitytype : "",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          }
        ]

        addFormBlueCstLead.current.fields.push(...fields)
      } else if (current_Input_Fields === "approxgrossreciept") {
        fields = [
          {
            id: 3,
            heading: "Annual Revenue Must be a minimum of $50M",
            placeholder: "Annual Revenue Must be a minimum of $50M",
            label: "Annual Revenue Must be a minimum of $50M",
            name: "approxgrossreciept",
            className: "formfild inputBlock1line grossreceipts",
            type: "text",
            defaultValue: fieldDataRow?.approxgrossreciept,
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          }
        ]
        addFormBlueCstLead.current.fields.push(...fields)
      } else if (current_Input_Fields === "dc_date") {
        fields = [
          {
            id: 4,
            heading: "DC Date",
            placeholder: "DC Date",
            label: "DC Date",
            name: "dc_date",
            className: "formfild inputBlock1line grossreceipts",
            type: "datePicker",
            rules: { required: true },
            defaultValue: fieldDataRow?.dc_date,
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          }
        ]
        addFormBlueCstLead.current.fields.push(...fields)
      } else if (current_Input_Fields === 'other_info') {
        fields = [
          {
            id: 5,
            placeholder: "Any other information we should know?",
            label: "Any other information we should know?",
            name: "other_info",
            className: "formfild textareafild inputBlock1line",
            type: "textarea",
            defaultValue: fieldDataRow?.other_info,
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          }
        ]
        addFormBlueCstLead.current.fields.push(...fields)
      } else if (current_Input_Fields === 'confidence') {
        fields = [
          {
            id: 6,
            heading: "Confidence (H, M, L)",
            label: "Confidence (H, M, L)",
            name: "confidence",
            className: "formfild inputBlock inputBlock2line state",
            type: "select",
            // other: true,
            values: [{ "name": "High", "val": "High" }, { "name": "Medium", "val": "Medium" }, { "name": "Low", "val": "Low" }],
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
            defaultValue: fieldDataRow?.confidence,
          }
        ]
        addFormBlueCstLead.current.fields.push(...fields)

      } else if (current_Input_Fields === "contarct_review_date") {
        fields = [
          {
            id: 7,
            heading: "Contarct Review Date",
            placeholder: "Contarct Review Date",
            label: "Contarct Review Date",
            name: "contarct_review_date",
            className: "formfild inputBlock1line grossreceipts",
            type: "datePicker",
            rules: { required: true },
            defaultValue: fieldDataRow?.contarct_review_date,
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          }
        ]
        addFormBlueCstLead.current.fields.push(...fields)

      } else if (current_Input_Fields === 'next_action_date') {
        fields = [
          {
            id: 8,
            heading: "Next Action Date",
            placeholder: "Next Action Date",
            label: "Next Action Date",
            name: "next_action_date",
            className: "formfild inputBlock1line grossreceipts",
            type: "datePicker",
            rules: { required: true },
            defaultValue: fieldDataRow?.next_action_date,
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          }
        ]
        addFormBlueCstLead.current.fields.push(...fields)
      } else if (current_Input_Fields === 'next_steps_view') {
        setNextStepAddEdit(true)
      } else if (current_Input_Fields === 'lost_declined') {
        fields = [
          {
            id: 10,
            heading: "Lost/Declined",
            label: "Lost/Declined",
            name: "lost_declined",
            className: "formfild inputBlock inputBlock2line state",
            type: "select",
            // other: true,
            values: [{ "name": "Lost", "val": "Lost" }, { "name": "Declined", "val": "Declined" }],
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
            defaultValue: fieldDataRow?.lost_declined,
          }

        ]
        addFormBlueCstLead.current.fields.push(...fields)
      } else if (current_Input_Fields === 'why') {
        fields = [
          {
            id: 11,
            heading: "Why",
            placeholder: "Why",
            label: "Why",
            name: "why",
            className: "formfild inputBlock1line grossreceipts",
            type: "text",
            rules: { required: true },
            defaultValue: fieldDataRow?.why,
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid",
            },
          },

        ]
        addFormBlueCstLead.current.fields.push(...fields)
      }

    }

    return () => {
      addFormBlueCstLead.current.fields = []
    }

  }, [current_Input_Fields, fieldDataRow])


  const handelIndiField = () => {
    setIndFieldEditModal(false)
    setFieldDataRow({})
    setNextStepAddEdit(false)
  }




  React.useEffect(() => {




    if (editIndeField === 4) {
      let data = indeChangedFoemData
      let obj = {}
      if (current_Input_Fields === "prospectbusinessname_type") {
        obj['prospectbusinessname'] = data.prospectbusinessname
        obj['medicalfacilitytype'] = data.medicalfacilitytype
      } else if (current_Input_Fields === "approxgrossreciept") {
        obj = {}
        obj['approxgrossreciept'] = data.approxgrossreciept
      } else if (current_Input_Fields === "dc_date") {
        obj = {}
        obj['dc_date'] = data.dc_date
      } else if (current_Input_Fields === 'other_info') {
        obj = {}
        obj['other_info'] = data.other_info
      } else if (current_Input_Fields === 'confidence') {
        obj = {}
        obj['confidence'] = data.confidence
      } else if (current_Input_Fields === "contarct_review_date") {
        obj = {}
        obj['contarct_review_date'] = data.contarct_review_date
      } else if (current_Input_Fields === 'next_action_date') {
        obj = {}
        obj['next_action_date'] = data.next_action_date
      } else if (current_Input_Fields === 'next_steps_view') {
        obj = {}
        obj['next_steps'] = data.next_steps
      } else if (current_Input_Fields === 'lost_declined') {
        obj = {}
        obj['lost_declined'] = data.lost_declined
      } else if (current_Input_Fields === 'why') {
        obj = {}
        obj['why'] = data.why
      }




      obj['_id'] = fieldDataRow._id

      dispatch(addlandingpageninedatabluecosde(obj))
    }
  }, [editIndeField])





  React.useEffect(() => {
    console.log("bluecostSingelFieldUpdate",bluecostSingelFieldUpdate);
    if (bluecostSingelFieldUpdate && bluecostSingelFieldUpdate.status === "success") {
      const storeData = store.getState();
      setSingelFieldUpdateSnackState(true)
      setSingelFieldUpdateSnackMassage(bluecostSingelFieldUpdate.message)
      setIndFieldEditModal(false)
      setFieldDataRow({})
      onHandleReload({ defaultReqBody, tableData, setSelected, setSortDirection, });
      setNextStepData([])
      setNextStepValue('')
      setNextStepEditStatus(undefined)
      setNextStepAddEdit(false)
      dispatch(clearbluecostSingelFieldUpdate())
    }
  }, [bluecostSingelFieldUpdate])







  return (
    <>
      {tableData?.searchData?.formData &&
        showSearchBar === true &&
        (tableData?.searchData?.type === "drawer" ? (
          <Drawer
            anchor="bottom"
            open={drawerToggle}
            onClose={handleCloseDrawer}
          >
            <Search
              formData={tableData.searchData.formData}
              tableData={tableData}
              close={handleCloseDrawer}
              clickedRowData={clickedRowData}
            />
          </Drawer>
        ) : (
          <Search
            formData={tableData.searchData.formData}
            tableData={tableData}
            clickedRowData={clickedRowData}

          />
        ))}
      <Paper
        elevation={tableData.elevation ? tableData.elevation : 7}
        className={showSearchBar === false ? "hide_table" : ""}
      >
        <div className={"muiTable " + tableData.tableId}>
          {loading && (
            <Box sx={{ width: "100%" }} className="tableProgressBar">
              <LinearProgress />
            </Box>
          )}

          <Box sx={{ width: "100%" }}>
            {/* <Paper sx={{ width: '100%', mb: 2 }} elevation={tableData.elevation ? tableData.elevation : 7}> */}
            <EnhancedTableToolbar
              numSelected={selected?.length}
              tableData={tableData}
              selected={selected}
              setDrawerToggle={setDrawerToggle}
              setSelected={setSelected}
              setSortDirection={setSortDirection}
              defaultReqBody={defaultReqBody}
              openApiModal={openApiModal}
              showSearchBar={showSearchBar}
            />
            {rows &&
              (rows?.length > 0 ? (


                <>


                  {/**********************************Pagination for upper section************************************* */}
                  {
                    tableData.upperPagination &&

                    (tableData.paging ? (
                      ////////////////////////////////////// For Mynt pagination //////////////////////////////////////////
                      customPaged && (
                        <Stack spacing={2} className="stackPagination">
                          <div className="listCount">
                            Showing {1 + customPageSkip}-
                            {customPageLimit + customPageSkip > customTotalRows
                              ? customTotalRows
                              : customPageLimit + customPageSkip}{" "}
                            of {customTotalRows}
                          </div>
                          <Pagination
                            count={customTotalPage}
                            page={customPageNumber + 1}
                            onChange={handleChangeCustomPage}
                            variant="outlined"
                            shape="rounded"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "20px 0px",
                            }}
                          />
                        </Stack>
                      )
                    ) : ////////////////////////////////////////////////// for Default pagination ///////////////////////////////////////////
                      tableData.paginationType === "stackPagination" ? (
                        <Stack spacing={2} className="stackPagination">
                          <div className="listCount">
                            Showing {1 + tableData.reqBody.condition.skip}-
                            {tableData.reqBody.condition.limit +
                              tableData.reqBody.condition.skip >
                              rowCount
                              ? rowCount
                              : tableData.reqBody.condition.limit +
                              tableData.reqBody.condition.skip}{" "}
                            of {rowCount}
                          </div>
                          <Pagination
                            count={Math.ceil(rowCount / rowsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            page={page + 1}
                            onChange={handleChangePage}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "20px 0px",
                            }}
                          />
                        </Stack>
                      ) : (
                        <TablePagination
                          rowsPerPageOptions={tableData.rowsPerPageOptions}
                          component="div"
                          count={rowCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          className="tablePagination"
                        />
                      )

                    )}



                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead
                        numSelected={selected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        tableData={tableData}
                        setPage={setPage}
                        setOrder={setOrder}
                        setSelected={setSelected}
                        sortDirection={sortDirection}
                        setSortDirection={setSortDirection}
                        defaultReqBody={defaultReqBody}
                        updateNotesTableData={updateNotesTableData}
                      />
                      <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const rowId = index + 1;
                            
                           /* console.log("data1111======>123", row); */
                            return (
                              <>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row._id + randomId()}
                                  selected={isItemSelected}
                                  className={tableData.showColorInTr && row.username.includes('jeannette')?'jeannetteClass':tableData.showColorInTr && row.username.includes('beto')?'betoClass':''}
                                >
                                  {/* ----------------------------------- Row Loader ------------------------------------ */}
                                  {/* {(tableData.enableRowActionLoader && rowLoading && rowLoading == rowId) && <LinearProgress className='row_loader' />} */}
                                  {/* ----------------------------------------------------------------------------------- */}
                                  {tableData.customBtnHead !== undefined &&
                                    tableData.customBtnHead.length > 0 && (
                                      <TableCell
                                        onClick={(event) =>
                                          handleClick(event, row._id)
                                        }
                                      >
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </TableCell>
                                    )}
                                  {/* INDEXING */}
                                  <TableCell className="index_column index_cell">
                                    {tableData.paging
                                      ? index + 1 + customPageSkip
                                      : index +
                                      1 +
                                      tableData.reqBody.condition.skip}
                                  </TableCell>

                                  {headersArray.map((data, index) => {

                                    return (

                                      <TableCell
                                        title={data.name}
                                        key={row._id + "cell" + row[data.val] + index + randomId()}
                                        className={`${typeof data.val == "string" ? data.val : data.val.index} ${tableData.clickableColumn && tableData.clickableColumn.includes(data.val) ? "clickableCell" : ""}`}
                                        onClick={() => handleCellClick(row, data.val)
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            (data.attach &&
                                              (data.attach.after ||
                                                data.attach.before)
                                              ? '<div class="wrapper">'
                                              : "") +
                                            (data.attach && data.attach.before
                                              ? mergeColumn("before", row, data)
                                              : "") +
                                            ((row[data.val] === null ||
                                              row[data.val] === undefined ||
                                              row[data.val].length === 0) &&
                                              typeof data.val === "string"
                                              ? tableData.showNotAvailable
                                                ? tableData.showNotAvailable
                                                : "N/A"
                                              : data?.type?.toLowerCase() ===
                                                "datetime"
                                                ? timeConverter({
                                                  time: row[data.val],
                                                  format: data.format,
                                                })
                                                : data?.type?.toLowerCase() ===
                                                  "image"
                                                  ? Array.isArray(row[data.val])
                                                    ? `<div class='table_image_container'> ${row[
                                                      data.val
                                                    ].map(
                                                      (cur) =>
                                                        `<img src=${cur} class="table_image" />`
                                                    )}</div>`
                                                    : `<div class='table_image_warpper'><img src=${row[data.val]
                                                    } class="table_image" /></div>`
                                                  : typeof data.val === "string"
                                                    ? data.customVal &&
                                                      data.customVal[row[data.val]]
                                                      ? data.customVal[row[data.val]]
                                                      : row[data.val]
                                                    : typeof data.val === "object" &&
                                                      row[data?.val?.val] &&
                                                      row[data?.val?.val][
                                                      data?.val?.index
                                                      ]
                                                      ? row[data.val.val][
                                                      data.val.index
                                                      ]
                                                      : tableData.showNotAvailable
                                                        ? tableData.showNotAvailable
                                                        : "N/A") 
                                                        + ((tableData.cellEdit && tableData.editField && tableData.editField.includes(data.val)) ? '<Tooltip title="Edit Column" id="edit_button"> <IconButton><i class="fa-solid fa-pen"></i></IconButton> </Tooltip>':"") + 
                                            (data.attach && data.attach.after
                                              ? mergeColumn("after", row, data)
                                              : "") +
                                            (data.attach &&
                                              (data.attach.after ||
                                                data.attach.before)
                                              ? "</div>"
                                              : ""),
                                        }}
                                      > 
                                      </TableCell>
                                    );
                                  })}
                                  {tableData.buttons !== undefined &&
                                    tableData.buttons.length > 0 && (
                                      <TableCell title="Actions">
                                        <div className="listing_btn_class">
                                          {tableData.buttons.map(
                                            (button, index) => {
                                              if (button.type === "delete") {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <Tooltip
                                                      key={index + randomId()}
                                                      className={button.name}
                                                      title={button.name}
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onHandleDelete(
                                                            row,
                                                            tableData
                                                          )
                                                        }
                                                        disabled={
                                                          selected.length > 0
                                                        }
                                                      >
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  );
                                              }
                                              if (button.type === "edit") {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <Tooltip
                                                      key={index + randomId()}
                                                      className={button.name}
                                                      title={button.name}
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onHandleEdit(
                                                            row,
                                                            button.id
                                                          )
                                                        }
                                                        disabled={
                                                          selected.length > 0
                                                        }
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  );
                                              }
                                              if (button.type === "view") {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <Tooltip
                                                      key={index + randomId()}
                                                      className={button.name}
                                                      title={button.name}
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onHandleView(
                                                            row,
                                                            button.id
                                                          )
                                                        }
                                                        disabled={
                                                          selected.length > 0
                                                        }
                                                      >
                                                        <VisibilityIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  );
                                              }
                                              if (button.type === "other") {
                                                if (
                                                  // (
                                                  //    row[button.condField] === button.condVal &&
                                                  //    (
                                                  //        (button.conditionIndex === null && button.conditionValue === null) ||
                                                  //       row[button.conditionIndex] === button.conditionValue
                                                  //    )
                                                  // ) ||
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <Tooltip
                                                      key={index + randomId()}
                                                      className={button.name}
                                                      title={button.label}
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onCustomBtnClickedRow(
                                                            row,
                                                            button.id
                                                          )
                                                        }
                                                        disabled={
                                                          selected.length > 0
                                                        }
                                                      >
                                                        {button.id != null &&
                                                          button.id ==
                                                          "changestatus" ? (
                                                          <Icon>
                                                            {row.status !=
                                                              null &&
                                                              row.status === 0
                                                              ? "toggle_off_icon"
                                                              : button.name}
                                                          </Icon>
                                                        ) : button.showcount &&
                                                          button.countFiled ? (
                                                          <Badge
                                                            badgeContent={
                                                              row[
                                                              button
                                                                .countFiled
                                                              ]
                                                            }
                                                            color="primary"
                                                          >
                                                            <Icon>
                                                              {button.name}
                                                            </Icon>
                                                          </Badge>
                                                        ) : (
                                                          <Icon>
                                                            {button.name}
                                                          </Icon>
                                                        )}
                                                      </IconButton>
                                                    </Tooltip>
                                                  );
                                              }
                                              //////////////// Action Buttons /////////////
                                              if (button.type === "action") {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <Tooltip
                                                      key={index + randomId()}
                                                      className={button.name}
                                                      title={button.name}
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onRowActionButtonClick(
                                                            {
                                                              row,
                                                              buttonId:
                                                                button.id,
                                                              base_url:
                                                                button.base_url ||
                                                                "",
                                                              endPoint:
                                                                button.endpoint ||
                                                                "",
                                                              body:
                                                                button.body ||
                                                                {},
                                                              payloadAttach:
                                                                button.payloadAttach ||
                                                                [],
                                                              isArray:
                                                                button.isArray ||
                                                                false,
                                                              rowId,
                                                            }
                                                          )
                                                        }
                                                        disabled={
                                                          selected.length > 0
                                                        }
                                                      >
                                                        <Icon>
                                                          {button.icon_type}
                                                        </Icon>
                                                      </IconButton>
                                                    </Tooltip>
                                                  );
                                              }
                                              /////////////////// Link Buttons ////////////////
                                              if (button.type === "link") {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <Tooltip
                                                      key={index + randomId()}
                                                      className={button.name}
                                                      title={button.name}
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onRowLinkButtonClick({
                                                            row,
                                                            targetBlank:
                                                              button.targetBlank ||
                                                              false,
                                                            queryParams:
                                                              button.queryParams ||
                                                              false,
                                                            params:
                                                              button.params ||
                                                              [],
                                                            base_url:
                                                              button.base_url ||
                                                              "",
                                                            endPoint:
                                                              button.endpoint ||
                                                              "",
                                                          })
                                                        }
                                                        disabled={
                                                          selected.length > 0
                                                        }
                                                      >
                                                        <Icon>
                                                          {button.icon_type}
                                                        </Icon>
                                                      </IconButton>
                                                    </Tooltip>
                                                  );
                                              }
                                              /////////////////// Notes Button ////////////////
                                              if (button.type === "note") {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <NotesBadge
                                                      disabled={
                                                        selected.length > 0
                                                      }
                                                      iconType={
                                                        button.icon_type
                                                          ? button.icon_type
                                                          : "summarize_icon"
                                                      }
                                                      row={row}
                                                      buttonData={button}
                                                      tableId={
                                                        tableData.tableId
                                                      }
                                                    />
                                                  );
                                              }
                                              /////////////////// API Button ////////////////
                                              if (
                                                button.type === "api" &&
                                                (button.category === "modal" ||
                                                  button.category ===
                                                  "action" ||
                                                  button.category ===
                                                  "status" ||
                                                  button.category === "view" ||
                                                  button.category === "edit")
                                              ) {
                                                if (
                                                  (button.condField &&
                                                    button.condVal &&
                                                    ((row[button.condField] &&
                                                      ((Array.isArray(
                                                        button.condVal
                                                      ) &&
                                                        button.condVal.includes(
                                                          row[button.condField]
                                                        )) ||
                                                        (!Array.isArray(
                                                          button.condVal
                                                        ) &&
                                                          row[
                                                          button.condField
                                                          ] ===
                                                          button.condVal))) ||
                                                      !row[
                                                      button.condField
                                                      ])) ||
                                                  !button.condField ||
                                                  !button.condVal
                                                )
                                                  return (
                                                    <>
                                                      <Tooltip
                                                        className={
                                                          button.className
                                                        }
                                                        title={
                                                          button.label
                                                            ? button.label
                                                            : ""
                                                        }
                                                      >
                                                        <IconButton
                                                          disabled={
                                                            selected.length > 0
                                                          }
                                                          className={`api_btn ${button?.category ===
                                                            "status" &&
                                                            (row.status === 1 ||
                                                              row.status ===
                                                              true ||
                                                              row.status ===
                                                              "active"
                                                              ? "status_active"
                                                              : "status_inactive")
                                                            }`}
                                                          onClick={() =>
                                                            button?.category ===
                                                              "modal" ||
                                                              button?.category ===
                                                              "status"
                                                              ? openApiModal({
                                                                buttonData:
                                                                  button,
                                                                row,
                                                              })
                                                              : button?.category ===
                                                                "view"
                                                                ? viewAction({
                                                                  buttonData:
                                                                    button,
                                                                  row,
                                                                })
                                                                : button?.category ===
                                                                  "edit"
                                                                  ? editAction({
                                                                    buttonData:
                                                                      button,
                                                                    row,
                                                                  })
                                                                  : doAction({
                                                                    buttonData:
                                                                      button,
                                                                    row,
                                                                  })
                                                          }
                                                        >
                                                          <Icon>
                                                            {button?.category !==
                                                              "status"
                                                              ? button.icon_type
                                                                ? button.icon_type
                                                                : "summarize_icon"
                                                              : row.status ===
                                                                1 ||
                                                                row.status ===
                                                                true ||
                                                                row.status ===
                                                                "active"
                                                                ? "toggle_on"
                                                                : "toggle_off"}
                                                          </Icon>
                                                        </IconButton>
                                                      </Tooltip>
                                                    </>
                                                  );
                                              }
                                            }
                                          )}
                                        </div>
                                      </TableCell>
                                    )}

                                  {((tableData.enableRowActionLoader &&
                                    rowLoading &&
                                    rowLoading == rowId) ||
                                    (enableRowLoader[tableData.tableId] &&
                                      enableRowLoader[tableData.tableId][
                                      row._id
                                      ] &&
                                      enableRowLoader[tableData.tableId][
                                      row._id
                                      ] === true)) && (
                                      <LinearProgress className="row_loader" />
                                    )}
                                </TableRow>
                              </>
                            );
                          })}

                        {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {tableData.paging ? (
                    ////////////////////////////////////// For Mynt pagination //////////////////////////////////////////
                    customPaged && (
                      <Stack spacing={2} className="stackPagination">
                        <div className="listCount">
                          Showing {1 + customPageSkip}-
                          {customPageLimit + customPageSkip > customTotalRows
                            ? customTotalRows
                            : customPageLimit + customPageSkip}{" "}
                          of {customTotalRows}
                        </div>
                        <Pagination
                          count={customTotalPage}
                          page={customPageNumber + 1}
                          onChange={handleChangeCustomPage}
                          variant="outlined"
                          shape="rounded"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px",
                          }}
                        />
                      </Stack>
                    )
                  ) : ////////////////////////////////////////////////// for Default pagination ///////////////////////////////////////////
                    tableData.paginationType === "stackPagination" ? (
                      <Stack spacing={2} className="stackPagination">
                        <div className="listCount">
                          Showing {1 + tableData.reqBody.condition.skip}-
                          {tableData.reqBody.condition.limit +
                            tableData.reqBody.condition.skip >
                            rowCount
                            ? rowCount
                            : tableData.reqBody.condition.limit +
                            tableData.reqBody.condition.skip}{" "}
                          of {rowCount}
                        </div>
                        <Pagination
                          count={Math.ceil(rowCount / rowsPerPage)}
                          variant="outlined"
                          shape="rounded"
                          page={page + 1}
                          onChange={handleChangePage}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px",
                          }}
                        />
                      </Stack>
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={tableData.rowsPerPageOptions}
                        component="div"
                        count={rowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className="tablePagination"
                      />
                    )}
                </>
              ) : // <div className='noDataAvailable' >No Data Found</div>
                tableData.no_data_html ? (
                  <div
                    className="noDataAvailable"
                    dangerouslySetInnerHTML={{ __html: tableData.no_data_html }}
                  ></div>
                ) : (
                  <div className="noDataAvailable">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" />
                  </div>
                ))}

            {/* </Paper> */}
            {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
          </Box>

          <Modal
            className={`modalBaseStyle ${tableData?.deleteModal?.modalClassName}`}
            // className="modalBaseStyle"
            open={openModal}
            onClose={onHandleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="modalBaseBox smallModal"
            // sx={tableData?.deleteModal?.modalStyle !== undefined ? tableData?.deleteModal?.modalStyle : {
            //     position: 'absolute',
            //     top: '50%',
            //     left: '50%',
            //     transform: 'translate(-50%, -50%)',
            //     width: 400,
            //     bgcolor: 'background.paper',
            //     border: '2px solid #000',
            //     boxShadow: 24,
            //     p: 4,
            //     textAlign: "center"
            // }}
            >
              {/* Close Button */}
              <Tooltip title="Close">
                <IconButton
                  className="modal_icon_button"
                  onClick={onHandleCloseModal}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {/* Loader */}
              {deleteLoading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
              {/* For Delete Modal */}
              {
                <>
                  <h1 className="modalTopTitle">Hey !</h1>
                  <h2 className="modalAlertTitle">
                    Are you sure you want to delete this record? This can't be
                    undone.
                  </h2>
                  <div className="deleteBtnWrapper">
                    <Button onClick={() => handleConfirmDelete(true)}>
                      Yes
                    </Button>
                    <Button onClick={() => handleConfirmDelete(false)}>
                      No
                    </Button>
                  </div>
                </>
              }
            </Box>
          </Modal>


          {/* indi field edit modal  */}


          <Modal className={(current_Input_Fields === "other_info" || current_Input_Fields === "why") ? `modalBaseStyle addFormBlueCOast access_dealMOdal otherOnly_Info` : `modalBaseStyle addFormBlueCOast access_dealMOdal`} open={indFieldEditModal} onClose={() => handelIndiField()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="modalBaseBox smallModal accessDeal_editModal">

              <Tooltip title="Close">
                <IconButton className="modal_icon_button" onClick={() => handelIndiField()}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {bluecoseloader ? <LinearProgress /> : ""}

              <div className="dataform2_wrapper">
                <div className="adminformbody">
                  <h2 className="addFormTitle modal_title">
                    {current_Input_Fields === "prospectbusinessname_type" && `Edit Company Details for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "approxgrossreciept" && `Edit Revenue for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "dc_date" && `Edit DC Date for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "other_info" && `Edit Other Info for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {(current_Input_Fields === 'confidence') && `Edit Confidence (H, M, L) for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "contarct_review_date" && `Edit Contarct Review Date for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "next_action_date" && `Edit Next Action Date for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "lost_declined" && `Edit Lost/Declined for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                    {current_Input_Fields === "why" && `Edit Why for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                  </h2>
                  <Form formData={addFormBlueCstLead.current} />
                </div>
              </div>

            </Box>
          </Modal>

          <Modal className={current_Input_Fields === "other_info" ? `modalBaseStyle addFormBlueCOast access_dealMOdal otherOnly_Info` : `modalBaseStyle addFormBlueCOast access_dealMOdal`} open={nextStepAddEdit} onClose={() => handelIndiField()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="modalBaseBox smallModal accessDeal_nextSteps">

              <Tooltip title="Close">
                <IconButton className="modal_icon_button" onClick={() => handelIndiField()}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>

              {bluecoseloader ? <LinearProgress /> : ""}
              <div className="dataform2_wrapper">
                <div className="adminformbody">
                  <h2 className="addFormTitle modal_title">
                    {current_Input_Fields === "next_steps_view" && `Edit Next Steps for ${fieldDataRow?.prospectfirstname + " " + fieldDataRow?.prospectlastname}`}
                  </h2>
                  <div className="formWrapperClass editBlueCstLeadField formCls">
                    <form className="userForm formInline previewfield" onSubmit={(e) => handelNextStagePost(e)}>
                      <div className="form_item formfild textareafild inputBlock1line next_step_form">
                        <FormControl>
                          <TextareaAutosize placeholder="What's Your next Step" value={nextStepValue ? nextStepValue : ""} onChange={(e) => setNextStepValue(e.target.value)} />
                        </FormControl>
                        <Tooltip title="Add Steps">
                          <IconButton className="form_custom_button" onClick={() => addNextStepFun()} >
                            {nextStepEditStatus !== undefined ? <EditIcon size={30} /> : <AddCircleIcon size={30} />}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className="chipWrprs_next">
                        {nextStepData && nextStepData.map((item, index) => {
                          return <Chip sx={{ display: "block" }} label={item} onClick={() => editNextStep(index)} onDelete={() => deleteFuntion(index)} />
                        })}
                      </div>
                      <div className="Formsubmit_button">
                        <Button type="submit" className="submit" disabled={nextStepValue === '' ? false : true}>
                          <span>
                            Submit
                          </span>
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </Box>
          </Modal>


          {tableData.searchBarData && (
            <Drawer
              anchor="bottom"
              open={drawerToggle}
              onClose={handleCloseDrawer}
            >
              <SearchBar
                tableData={tableData}
                searchBarData={tableData.searchBarData}
                tableId={tableData.tableId}
                handleCloseDrawer={handleCloseDrawer}
                clickedRowData={clickedRowData}
              />
            </Drawer>
          )}
          <NotesDialog tableId={tableData.tableId} />
          <APIDialog
            open={openApiModalState}
            close={closeApiModal}
            title={apiModalTitle ? apiModalTitle : ""}
            onNo={closeApiModal}
            buttonData={apiBtnData}
            row={rowData}
            tableId={tableData.tableId}
            header={apiModalHeader}
            mainClass={apiModalClassName ? apiModalClassName : ""}
          />
          <ViewDialog
            open={openViewSnackbar}
            mainClass={apiModalClassName ? apiModalClassName : ""}
            close={() => {
              dispatch(resetApiViewActionData({ tableId: tableData.tableId }));
              setOpenViewSnackbar(false);
              setApiModalClassName("");
              setApiBtnData(null);
            }}
            data={apiViewActionData}
            buttonData={apiBtnData}
            tableID={tableData.tableId}
          />
        </div>
      </Paper>

      {/* ------------- Single Delete Success message----------------------- */}

      <Snackbar
        className="singleDeleteSnackbar"
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openSingleDelete}
        onClose={() => setOpenSingleDelete(false)}
        autoHideDuration={1000}
        message="Deleted Successfully"
      />
      <Snackbar
        className="singleDeleteSnackbar"
        open={singelFieldUpdateSnackState}
        onClose={() => setSingelFieldUpdateSnackState(false)}
        autoHideDuration={1000}
        message={singelFieldUpdateSnackMassage}
      />

      {/* singelFieldUpdateSnackState */}
      <Snackbar
        className="deleteSnackbar"
        open={openApiSnackbar}
        autoHideDuration={5000}
        message={apiSnackbarMessage}
        action={
          <IconButton
            className="close_btn"
            onClick={() => setOpenApiSnackbar(false)}
          >
            <CloseIcon
              className="close_icon"
              size="small"
              aria-label="close"
              color="inherit"
              fontSize="small"
            />
          </IconButton>
        }
      />
      <Snackbar
        className="deleteSnackbar"
        open={openNoteAddSnackbar}
        autoHideDuration={5000}
        message={notesAddFormSnackbarMessage}
        action={
          <IconButton
            className="close_btn"
            onClick={() => setOpenNoteAddSnackbar(false)}
          >
            <CloseIcon
              className="close_icon"
              size="small"
              aria-label="close"
              color="inherit"
              fontSize="small"
            />
          </IconButton>
        }
      />
    </>
  );
}
