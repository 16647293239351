import { IconButton, LinearProgress, Modal, TextareaAutosize, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../listing/listing'
import { TableSkeleton } from '../../../helper/TableSkeleton'
import { blueCoastNotesrep, clearBlueCoastRepNotesList } from './BlueCoastReducer'
import { useDispatch, useSelector } from 'react-redux'
import { setSnackbar } from '../../Layout/layoutReducer'
import { setReloadTableFlag } from '../../../listing/listReducer'
import CloseIcon from '@mui/icons-material/Close';

const PaymentAeRepNotes = ({ rowdata, setnotesforrepmodal, notesforrepmodal,setPaymentRowData,setForLoading }) => {


    // console.log("notes for payment rowdat", rowdata);

    const [scratchaddnoteflag, setscratchaddnoteflag] = useState(false)
    const [addedForId, setAddedForId] = useState({});

    

    const dispatch = useDispatch()
    const textareayvalueforrep = useRef(null);
    const inputRef = useRef(null);

    const bluecoastrepnotedata = useSelector((state) => (state.BlueCoastSlice?.repnotebluecoast && state.BlueCoastSlice?.repnotebluecoast.length > 0) ? state.BlueCoastSlice.repnotebluecoast : [])
    const addedById = useSelector((state) => state.loginSlice?.userInfo?._id)
    const userInfo = useSelector((state) => (state.loginSlice && state.loginSlice?.userInfo) ? state.loginSlice?.userInfo : null)
    const loadinginRepnotes = useSelector(state => state.BlueCoastSlice?.repNoteloading ? state.BlueCoastSlice.repNoteloading : false);




    useEffect(() => {
        tableDataForRepNotes.current.reqBody.searchcondition.notes_for = rowdata.rep_id
        tableDataForRepNotes.current.searchData.formData.fields[0].reqBody.searchcondition.notes_for = rowdata.rep_id
        tableDataForRepNotes.current.searchData.formData.fields[1].reqBody.searchcondition.notes_for = rowdata.rep_id


        dispatch(blueCoastNotesrep(rowdata.rep_id))
    }, [])

    var modifyTableHeadersforRepnotes =
        [
            { val: "notes", name: "Notes" },
            { val: "addedby_name", name: "Added By" },
            // { val: "addedfor_name", name: "AE/REP Name" },
            { val: "createdon_datetime", name: "Creation Time", type: "datetime", format: "MM/DD/YYYY" },
        ];


    let tableDataForRepNotes = useRef({
        tableId: "repnotes_list",
        tableTitle: "Note About AE/REP",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "lead-manage/bluecoast_under_note_list_for_rep",
            // deleteSingleUserEndpoint: "training-manage/delete-training",
            tableCountEndpoint: "lead-manage/bluecoast_under_note_list_for_rep",
        },

        reqBody: {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                "notes_for": rowdata?.rep_id,
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false,
        },

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime", "notes", "addedby_name", "addedfor_name"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [
            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle:
                    "<p>Are you sure you want to delete this Note ? This can't be undone. </p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Yes", "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "lead-manage/deletebluecoastundernotelistforrep",
                    body: {
                        // source: "users",

                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },

        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },
        searchData: {
            heading: "Search AE/REP Notes",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchNotesForm',
                formWrapperClass: "searchBarHead formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 10,
                        label: "Search by Notes",
                        name: "notessearch",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletenoteforrep",
                        reqBody: {
                            "condition": {
                                "limit": 5,
                                "skip": 0
                            },
                            "sort": {
                                "type": "asc",
                                "field": "email"
                            },
                            "searchcondition": { notes_for: rowdata?.rep_id },

                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },

                    },

                    {
                        id: 1,
                        label: "Search by Added By",
                        name: "addedby",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "bclusers/autocompleteforaddedby",
                        reqBody: {
                            "condition": {
                                "limit": 5,
                                "skip": 0
                            },
                            "sort": {
                                "type": "asc",
                                "field": "email"
                            },
                            "searchcondition": { notes_for: rowdata?.rep_id },
                            "type": "fullname",
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "notes_by" },

                    },

                    // {
                    //   id: 5,
                    //   label: "Search By AE/REP",
                    //   name: "addedfor",
                    //   type: "autoComplete",
                    //   className: 'list_search_item countDiv__column--col6 ',
                    //   base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforaerepname",
                    //   reqBody: {
                    //     "condition": {
                    //       "limit": 5,
                    //       "skip": 0
                    //     },
                    //     "sort": {
                    //       "type": "asc",
                    //       "field": "email"
                    //     },
                    //     "searchcondition": { notes_for: rowdata?.rep_id },
                    //     "type": "fullname",
                    //   },
                    //   sx: { m: 1, minWidth: 300 },
                    //   payloadFormat: { key: "notes_by" },

                    // },

                    {
                        id: 8,
                        label: "Search by Created on Start Date",
                        name: "createdon_Start_datetime",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: 'list_search_item countDiv__column--col4 ',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Created on End Date",
                        name: "createdtedon_datetime",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY',
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },

                ]
            }
        },

    });


    const addnoteforrep = async () => {
        if (textareayvalueforrep.current) {
            setscratchaddnoteflag(true)
            // console.log("textareayvalue", textareayvalue, checkboxvalues);
            let reqbody = {
                'notes_by': addedById,
                'notes_for': rowdata.rep_id,
                "notes": textareayvalueforrep.current
            }
            // console.log("reqbody", reqbody);


            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reqbody)
            };
            // console.log("process.env==>", process.env);
            const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/add_note_bluecoast_rep", requestOptions);
            const respdata = await response.json();
            dispatch(setSnackbar({ open: true, message: 'Added Successfully', status: 'success' }))
            setscratchaddnoteflag(false)
            // setnotesforrepmodal(false)

            textareayvalueforrep.current = undefined
            textareayvalueforrep.current = null
            inputRef.current.value = null;

            // console.log("textareayvalueforrep123",textareayvalueforrep.current);
            // settextareayvalueforrep(null)
            await dispatch(blueCoastNotesrep(rowdata.rep_id))
            // tableDataForRepNotes
            dispatch(setReloadTableFlag({ tableId: 'repnotes_list' }))
            // setcheckboxvalues([])
        } else {
            dispatch(setSnackbar({ open: true, message: 'Please Add a Note', status: 'error' }))
        }
    }
    const handleCloseRepNotesModal = () => {
        setnotesforrepmodal(false);
        dispatch(clearBlueCoastRepNotesList())
        setPaymentRowData({})
        setForLoading(true)
    };

    return (
        <div>
            {/* ************************************Notes AE/Rep Modal******************* */}
            <Modal className='notesModal_blcoast'
                open={notesforrepmodal}
                onClose={handleCloseRepNotesModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modalBaseBox mediumModal'>
                    <Tooltip title='Close' >
                        <IconButton onClick={handleCloseRepNotesModal}
                            // className="CloseModal"
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className='innerWrapperfor_notes'>
                        <div>
                            <h2 className='previewModalTitle'>
                                {rowdata?.fullname && <div className='modal_title'>Notes for AE/REP ({rowdata?.fullname})</div>}
                                {/* <h4>{JSON.stringify(ipInfo)}</h4> */}
                            </h2>
                            <div className='rolelist_mainwrp_Table'>
                                {/* <p>{JSON.stringify(bluecoastrepnotedata)}</p> */}

                                {!loadinginRepnotes && bluecoastrepnotedata && bluecoastrepnotedata.length > 0 && (
                                    <ListingTable
                                        tableData={tableDataForRepNotes.current}
                                        dataset={bluecoastrepnotedata}
                                        modifyHeaders={modifyTableHeadersforRepnotes}
                                    />
                                )}
                                {loadinginRepnotes && <TableSkeleton count={5} />}


                            </div>
                        </div>
                        {/* {JSON.stringify(textareayvalueforrep.current)} */}
                        {userInfo && Object.keys(userInfo).length > 0 && userInfo.main_role == 'special_entities' && <div>
                            <div className='Notes_Form'>
                                <p>Notes</p>
                                <TextareaAutosize
                                    placeholder={"Type here..."}
                                    ref={inputRef}
                                    value={textareayvalueforrep.current}

                                    onChange={(e) => {
                                        textareayvalueforrep.current = e.target.value

                                    }}

                                />
                            </div>
                            <div>
                            </div>
                            <div>
                                <button className='blucoastList_specialbtn' onClick={() => addnoteforrep()}>Add Note</button>
                                {scratchaddnoteflag && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}

                            </div>
                        </div>}
                    </div>
                </Box>
            </Modal>



            {/* ************************************Notes AE/Rep Modal for pharmacy bluecoast and aspire******************* */}



        </div>
    )
}

export default PaymentAeRepNotes