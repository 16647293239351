import React from 'react'
import '../LandingPage19.css'
import { useState } from 'react';

export default function Landingpage19Block3({ scrollToview }) {
  const [loading, setloading] = useState(false);

  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div className='lp19_block3_main_wrapper'>
        <div className='common_width'>

          <div className='lp19_tstimnl_wrp'>
            <h2 className='testi_heading'>Testimonials</h2>
            <div className='lp19_tstimnl_flex_wrp'>
              <div className='lp19_tstimnl_innr_wrp lp19_tstimnl_text_wrp'>

                <div className='lp19_tstimnl_img_wrp'>
                  {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/testimonials_img_shadow.webp' /> */}
                  <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/testimonials_first.img.webp' />
                </div>
                <h2>Neil E.</h2>
                <p>I am on Medicare, and the cream I was prescribed was <span>$282 with my Medicare coverage</span>. With my subscription, it was free.</p>

              </div>
              <div className='lp19_tstimnl_innr_wrp lp19_tstimnl_text_wrp'>

                <div className='lp19_tstimnl_img_wrp'>
                  <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/testimonials-second-img.webp' />
                </div>
                <h2>Katherine H.</h2>
                <p>I signed up for the <span>free pharmacy program</span>, and it covered all of my monthly prescription medications. The sign-up was a breeze, and their customer service was outstanding. </p>

              </div>
              <div className='lp19_tstimnl_innr_wrp lp19_tstimnl_text_wrp'>

                <div className='lp19_tstimnl_img_wrp'>
                  <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/testimonials-third-img.webp' />
                </div>
                <h2>Larry I.</h2>
                <p>My wife and I signed up to avoid <span>paying $100 per month</span> for our co-pays for our monthly prescriptions. We no longer have to pay this co-pay and receive all our medications with our subscription plan shipped directly to us for free. </p>

              </div>
            </div>
          </div>
          <div className='lp19_block3_sub1_wrapper'>
            <div className='lp19_block3_flex'>

              <div className='lp19_block3_flex_item'>

                <div className='lp19_block3_text'>
                  <div className='lp19_block3_deliverfree_img'>
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/deliverfree_img.webp`}
                    // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/deliverfree_img.webp'
                    />
                  </div>
                  <p>SHIPPING IS <span>FREE</span> <br />IN 2-4 BUSINESS DAYS, OR</p>
                  <h1>If you have immediate needs to pick up your prescriptions before our shipment date or for acute medications, you can go to one of the 64,000 pharmacies Nationwide, including: </h1>

                  <button onClick={() => setScroll()}>ENROLL TODAY</button>

                </div>

              </div>
            </div>
            <div className='lp19_block3_sub2_wrapper'>
              <div className='lp19_block3_deliveryman_img'>
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/lp19_block3deliveryman_img.png`}
                // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/lp19_block3deliveryman_img.png'
                />
              </div>
              <div className='lp19_block3_sub2_wrapper_item'>
                <img className='lp19_bl3_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/layer1.img.webp`}
                // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/layer1.img.webp' 
                />
                <img className='lp19_bl3_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/layer2.img.webp`}
                // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/layer2.img.webp'
                />
                <img className='lp19_bl3_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/layer3.img.webp`}
                // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/layer3.img.webp'
                />
                <img className='lp19_bl3_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/layer4.img.webp`}
                // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/layer4.img.webp'
                />
                <img className='lp19_bl3_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block3/layer5.img.webp`}
                //  'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/layer5.img.webp'
                />
              </div>

            </div>
            {/* <div className='lp19_block3_sub2_wrapper_input_field'>
              <div className='form_wrp_l19'>
                <input type='text' placeholder='Enter the Drug Name'></input>
                <div className='Lp14block2_sub1_wrapper'>
                  <button onClick={() => setScroll()}>ENROLL TODAY</button>
                </div>

              </div>

            </div> */}

          </div>
        </div>
      </div>
    </>
  )
}
