import { IconButton, LinearProgress, Modal, TextareaAutosize, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import { useDispatch, useSelector } from 'react-redux';
import ListingTable from '../../../../../listing/listing';
import CloseIcon from "@mui/icons-material/Close";
import { setEnableRowLoader, setReloadTableFlag } from '../../../../../listing/listReducer';
import { addedNotesListSubemployer, deleteNotes } from '../../EmployerReducer';
import DeleteFromList from '../DeleteFromList';


function AddNotesSub({ notesOpen, setNotesOpen, notesSubEmployeeClicked }) {

  const dispatch = useDispatch()

  // const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForSubEmployerData?._id  ? state.tableSlice.tempRowData.tableForSubEmployerData._id : {})
  const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForSubEmployerData && Object.keys(state.tableSlice.tempRowData.tableForSubEmployerData).length > 0 ? state.tableSlice.tempRowData.tableForSubEmployerData : null)
  const subEmployeeRowId = useSelector((state) => state.tableSlice.tempRowData.tableForSubEmployerData?._id ? state.tableSlice.tempRowData.tableForSubEmployerData._id : null)


  const subEmployerNotes = useSelector((state) => state.EmployerReducer?.addnotesListData ? state.EmployerReducer.addnotesListData : [])
  const subEmployerLoader = useSelector((state) => state.EmployerReducer?.loadingForNotes ? state.EmployerReducer.loadingForNotes : false)

  const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);

  // ------------------------delete button click-----------------------
  const deleteClick = useSelector((state) => state.tableSlice?.customButtonClicked?.SubemployerNotesTable?.deleteNotes ? state.tableSlice.customButtonClicked.SubemployerNotesTable.deleteNotes : null)

  //--------------------------temp row data for notes------------------
  const employeeNotesRowData = useSelector((state) => state.tableSlice.tempRowData.SubemployerNotesTable && Object.keys(state.tableSlice.tempRowData.SubemployerNotesTable).length > 0 ? state.tableSlice.tempRowData.SubemployerNotesTable : null)

  //--------------------temp row data for notes store in state----------------
  const [notesRow, setNotesRow] = useState({})

  useEffect(() => {
    if (employeeNotesRowData !== null && employeeNotesRowData !== undefined && Object.keys(employeeNotesRowData).length > 0) {
      setNotesRow(employeeNotesRowData)
      // console.log("employeeNotesRowData========>>>>", employeeNotesRowData)
    }
  }, [JSON.stringify(employeeNotesRowData)])

  useEffect(() => {
    // console.log("notesRow==========>>>>>>>", notesRow)
  }, [notesRow])

  const [rowDataFlag, setRowDataFlag] = useState()

  useEffect(() => {
    if (subEmployeeRowData !== null && subEmployeeRowData !== undefined) {
      // console.log("SubEmployeeRowData========>>", subEmployeeRowData);
      setRowDataFlag(subEmployeeRowData)

      // console.log("rowDataFlag========>>", rowDataFlag);

    }
  }, [JSON.stringify(subEmployeeRowData)])


  const [rowDataId, setRowDataId] = useState(null)

  useEffect(() => {
    if (subEmployeeRowId !== null && subEmployeeRowId !== undefined) {
      // console.log("subEmployeeRowId========>>", subEmployeeRowId);
      setRowDataId(subEmployeeRowId)


    }
  }, [JSON.stringify(subEmployeeRowId)])

  useEffect(() => {

    // console.log("rowDataId========>>", rowDataId);

  }, [rowDataId])




  const [scratchaddnoteflag, setscratchaddnoteflag] = useState(false);


  const inputTextEmployer = useRef(null);
  const textareayvalue = useRef(null);






  useEffect(() => {
    if (notesSubEmployeeClicked && rowDataId !== null) {
      // console.log("checkforRowid======>>>>",  notesSubEmployeeClicked, rowDataId);



      notesListApiCall()

    }
  }, [notesSubEmployeeClicked, rowDataId])


  const notesListApiCall = async () => {

    if (viewSubEmployerAddedNotesList.current.reqBody && viewSubEmployerAddedNotesList.current.reqBody.searchcondition) {
      viewSubEmployerAddedNotesList.current.reqBody.searchcondition.notes_for = rowDataId
    }

    viewSubEmployerAddedNotesList.current.searchData.formData.fields[0].reqBody.searchcondition.notes_for = rowDataId

    await dispatch(addedNotesListSubemployer(viewSubEmployerAddedNotesList.current.reqBody));

    // console.log("viewSubEmployerAddedNotesList==============>>>>>>", viewSubEmployerAddedNotesList)



  }

  const addnote = async () => {
    if (textareayvalue.current) {
      setscratchaddnoteflag(true);
      let reqbody = {
        notes_by: addedById,
        notes_for: rowDataId,
        notes: textareayvalue.current,
        // token: token
      };
      // console.log("reqbody==============>>>>", reqbody)
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqbody),
      };
      // console.log("addBody", reqbody);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/addemployersnotes",
        requestOptions
      );
      const respdata = await response.json();
      dispatch(
        setSnackbar({
          open: true,
          message: "Added Successfully",
          status: "success",
        })
      );
      setscratchaddnoteflag(false);
      textareayvalue.current = null;
      inputTextEmployer.current.value = null;

      await dispatch(addedNotesListSubemployer(viewSubEmployerAddedNotesList.current.reqBody));

      dispatch(setReloadTableFlag({ tableId: "SubemployerNotesTable" }));
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: "Please Add a Note",
          status: "error",
        })
      );
    }
  };

  // ---------------------------===========NOtes delete============-----------------------
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (deleteClick === true) {
      dispatch(
        setEnableRowLoader({
          tableId: "SubemployerNotesTable",
          rowId: notesRow._id,
          load: true,
        })
      );
      setTimeout(() => {

        setDeleteOpen(true)
      }, 500)

      setTimeout(() => {

        dispatch(
          setEnableRowLoader({
            tableId: "SubemployerNotesTable",
            rowId: notesRow._id,
            load: false,
          })
        );
      }, 1000)

    }
  }, [deleteClick])

  const deleteFunctioanl = () => {
    let body = {
      "_id": notesRow?._id
    }

    dispatch(deleteNotes(body))

    // console.log("body=========>>>>", body)

    // setDltLoader(true)

    setTimeout(() => {
      dispatch(
        setSnackbar({
          open: true,
          message: "Notes Deleted Succesfully!",
          status: "success",
        })
      );
    }, 1000)



    setTimeout(() => {
      setDeleteOpen(false)

    }, 2000);
    // }


    setTimeout(() => {
      dispatch(setReloadTableFlag({ tableId: "SubemployerNotesTable" }));

    }, 2000)
  }

  // ======================================= table for addedNotes ===============================

  var modifyTableHeadersforSubEmployerNotes = [
    { val: "notes", name: "Notes" },
    { val: "notes_by_name", name: "Added By" },
    {
      val: "createdon_datetime",
      name: "Creation Time",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ];

  let viewSubEmployerAddedNotesList = useRef({
    tableId: "SubemployerNotesTable",
    tableTitle: "Note About Sub Employer",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "employer_manage/subemployee_notes_list",
      tableCountEndpoint: "employer_manage/subemployee_notes_list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        notes_for: rowDataId,
      },
      project: {},
      email: "",
      count: false,
    },

    rowsPerPageOptions: [30],
    sortFields: ["createdon_datetime", "notes", "addedby_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [
      {
        id: "deleteNotes",
        label: "Delete",
        type: "other",
        name: "delete_icon",
      },
    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search Note About Sub Employer",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchNotesForm",
        formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Notes",
            name: "notes",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "employer_manage/autocompletenotes",
            reqBody: {
              condition: {
                limit: 30,
                skip: 0,
              },
              sort: {
                field: "createdon_datetime",
                type: "desc",
              },
              searchcondition: {
                notes_for: rowDataId,
                label: {
                  $regex: "",
                  $options: "i",
                },
              },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "createdon_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col4 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "createdtedon_datetime",
            className: "list_search_item countDiv__column--col4 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  const handleCloseNotesModal = () => {
    setNotesOpen(false)
    textareayvalue.current = null;
    setRowDataId(null)
    dispatch(setReloadTableFlag({ tableId: "tableForSubEmployerData" }));


  }


  return (
    <>
      <Modal className="contractFormMOdal notesModal_blcoast"
        open={notesOpen}
        onClose={handleCloseNotesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox">
          <Tooltip title="Close">
            <IconButton
              onClick={handleCloseNotesModal}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <div className="rolelist_mainwrp">
            <h2 className="previewModalTitle ">
              <div className="modal_title modal_titlenew">
                Notes for {rowDataFlag?.firstname + " " + rowDataFlag?.lastname}
                {/* <p>name:{JSON.stringify()}</p> */}
              </div>
            </h2>

            {/* <p>{JSON.stringify(viewEmployerFullLsitData)}</p> */}

            <div className='rolelist_mainwrp_Table'>
              {subEmployerNotes !== null && !subEmployerLoader ? (<ListingTable
                tableData={viewSubEmployerAddedNotesList.current}
                dataset={subEmployerNotes}
                modifyHeaders={modifyTableHeadersforSubEmployerNotes}
              />) : (
                <TableSkeleton count={5} />
              )}

            </div>

            <div className="Notes_Form">
              <p>Notes</p>
              <TextareaAutosize
                placeholder={"Type here..."}
                // value={textareayvalue.current}
                ref={inputTextEmployer}
                onChange={(e) => {
                  textareayvalue.current = e.target.value;
                }}
              />
            </div>

            <div>
              <button
                className="blucoastList_specialbtn" onClick={() => addnote()}
              >
                Add Note
              </button>

              {scratchaddnoteflag && <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }} >
                <LinearProgress />
              </Box>}

            </div>

          </div>
        </Box>
      </Modal>

      {deleteOpen && <DeleteFromList
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        deleteFunctioanl={deleteFunctioanl}

      />}
    </>
  )
}

export default AddNotesSub