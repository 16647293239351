import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { Form } from '../../../../../form/Form';
import StateArray from '../../../../../assets/json/state';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../../../store';
import { addUpdateSubEmployer, clearRespomseSubEmplyrEdit, viewSubEmployersList } from '../../EmployerReducer';
import { cloneDeep } from 'lodash';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import { setReloadTableFlag } from '../../../../../listing/listReducer';

function AddEditSubLogin({ subEmployerOpen, setSubEmployerOpen, logedUserData, subEmployerListDetails, subEmployerReqBody, editSubEmployeeClicked }) {

    const dispatch = useDispatch();
    const [locationArrys, setLocationArrys] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false)

    const locationListDataSet = useSelector((state) => state.EmployerReducer?.locationsListData ? state.EmployerReducer.locationsListData : null)

    const employeeLoader = useSelector((state) => state.EmployerReducer?.addloadingemployee ? state.EmployerReducer.addloadingemployee : null)

    const submissionSubemployerstate = useSelector(state => state.EmployerReducer?.subEmployerSubmission ? state.EmployerReducer.subEmployerSubmission : null);

    const subEmployerSnackFire = useSelector(state => state.EmployerReducer?.subloginSnackFire ? state.EmployerReducer.subloginSnackFire : "");

    const subEmployerStatus = useSelector(state => state.EmployerReducer?.subloginStatus ? state.EmployerReducer.subloginStatus : null);

    // ------------- setting location array ----------------
    let NewArray = [];
    useEffect(() => {
        if (locationListDataSet && locationListDataSet.length > 0) {
            // console.log("locationListDataSet===============>>>>>", locationListDataSet)
            for (let i in locationListDataSet) {
                // console.log("locationListDataSet===============>>>>>3", locationListDataSet)
                NewArray.push({ val: locationListDataSet[i]._id, name: locationListDataSet[i].location_name + " " + "(" + locationListDataSet[i].address + "," + " " + locationListDataSet[i].city + ", " + locationListDataSet[i].state + " " + locationListDataSet[i].zip + ")", key: locationListDataSet[i]._id });
            }
            // console.log("NewArray+", NewArray);
            setLocationArrys(NewArray);
            // console.log("locationArrys===========>>>>", locationArrys)

        }
    }, [locationListDataSet]);

    // =============================employee edit funtionality=================

    const [editSubEmployer, setEditSubEmployer] = useState({})

    // ==========================add/edit subEmployer form close function=======================
    const handleCloseAddSubEmployer = () => {

        for (let k in addformSubEmployerDetails.fields) {
            let field = cloneDeep(addformSubEmployerDetails.fields[k]);
            field.defaultValue = undefined;
            addformSubEmployerDetails.fields[k] = field;
        }

        // if (addformSubEmployerDetails?.fields[21]) {
        //     addformSubEmployerDetails.fields[21].disabled = false
        // }

        setSubEmployerOpen(false)
        setEditSubEmployer({})
        dispatch(clearRespomseSubEmplyrEdit())
        dispatch(setReloadTableFlag({ tableId: "tableForSubEmployerData" }));


    }

    const subEMployerFormData = useSelector((state) => state.formSlice.formData.addSubEmployerTable ? state.formSlice.formData.addSubEmployerTable : null);

    const subEmployerDataSubmissionState = useSelector(state => (state.formSlice?.formSubmissionState?.addSubEmployerTable) ? state.formSlice?.formSubmissionState?.addSubEmployerTable : 0);
    // const employeeEditData = useSelector((state) => state.tableSlice.tempRowData.tableForEmployeeData && Object.keys(state.tableSlice.tempRowData.tableForEmployeeData).length > 0 ? state.tableSlice.tempRowData.tableForEmployeeData : {})
    const editsubEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForSubEmployerData && Object.keys(state.tableSlice.tempRowData.tableForSubEmployerData).length > 0 ? state.tableSlice.tempRowData.tableForSubEmployerData : {})

    // ===============================sub employeeedit state===================
    // const [editSubEmployee, setEditSubEmployee] = useState(null)

    // ============================add Sub Employer function======================

    const addSubEmployerDetails = async (val) => {
        // console.log("val>>>", val);
        val = { ...val };

        let body = {
            firstname: val.firstname.trim(),
            lastname: val.lastname.trim(),
            email: val.email.trim(),
            phone: val.phone.trim(),
            address: val.address.trim(),
            city: val.city.trim(),
            state: val.state.trim(),
            zip: val.zip.trim(),
            notes: val.notes,
            other_info: val.other_info,
            createdon_datetime: new Date().getTime(),
            added_by: logedUserData?._id,
            location_id: val.location,
            roles: { is_subEmployer: 1 },
            title: val.title,
            company_name: val.company_name,
            status: val.status,
            // status: val.status === 1 ? true : val.status === 6 ? false : null,
            // status: val.status === 1 ? true : val.status === 6 ? false : null,


            added_type: "Manual",
            password: val.password,
            confirmPassword: val.confirmPassword,

            _id:
                editSubEmployer && Object.keys(editSubEmployer).length > 0
                    ? editSubEmployer._id
                    : undefined,


        };

        // console.log("body==========>>>>", body)



        if (Object.keys(body).length > 0) {

            await store.dispatch(addUpdateSubEmployer(body));

            for (let k in addformSubEmployerDetails.fields) {

                let field = cloneDeep(addformSubEmployerDetails.fields[k]);
                field.defaultValue = undefined;
                addformSubEmployerDetails.fields[k] = field;
            }

            // dispatch(
            //     setSnackbar({
            //         open: true,
            //         message:
            //             editSubEmployer && Object.keys(editSubEmployer).length > 0
            //                 ? "Updated Successfully"
            //                 : "Added Successfully",
            //         status: "success",
            //     })
            // );

            setOpenSnackbar(true)
            // setEditSubEmployer({})





            dispatch(viewSubEmployersList(subEmployerReqBody))
            // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }));
        }

    };


    useEffect(() => {
        if (submissionSubemployerstate === 2 && subEmployerStatus == 'success') {
            // console.log("submissionSubemployerstate============>>>>", submissionSubemployerstate, subEmployerStatus)
            setTimeout(() => {
                handleCloseAddSubEmployer()
            }, 1000);
        }
    }, [submissionSubemployerstate, subEmployerStatus])


    // **************************Add Sub Employer Data submission state**************

    useEffect(() => {
        if (subEmployerDataSubmissionState && subEmployerDataSubmissionState === 4) {

            addSubEmployerDetails(subEMployerFormData);

            // console.log("subEMployerFormData================>>>>", subEMployerFormData)

            // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }))

        }
    }, [subEmployerDataSubmissionState]);

    // ======================Edit temp row Data set========================

    useEffect(() => {
        if (editsubEmployeeRowData && Object.keys(editsubEmployeeRowData).length > 0) {
            // console.log("editsubEmployeeRowData============>>>", editsubEmployeeRowData);
            setEditSubEmployer(editsubEmployeeRowData)
        }
    }, [JSON.stringify(editsubEmployeeRowData)])



    useEffect(() => {
        // console.log("editSubEmployee======>>>1", editSubEmployer);

        // let statusEdit = editSubEmployer

    }, [editSubEmployer])

    // ===========================edit subEmployer button clicked========================

    useEffect(() => {
        if (editSubEmployeeClicked && editSubEmployer && Object.keys(editSubEmployer).length > 0) {

            // console.log("editSubEmployer======>>>2", editSubEmployer);


            // seteditSubEmployer(employeeEditData)

            for (let k in addformSubEmployerDetails.fields) {

                // if (editSubEmployer[addformSubEmployerDetails.fields[k].name]) {
                addformSubEmployerDetails.fields[k].defaultValue =
                    editSubEmployer[addformSubEmployerDetails.fields[k].name];
                // console.log("testhit====>>>>", editSubEmployer[addformSubEmployerDetails.fields[k].name])
                // setFormOpen(true)
                // }
                // addformSubEmployerDetails.fields[21].disabled = true

            }
            // console.log("editSubEmployer======>>>3", editSubEmployer)

            // if (addformSubEmployerDetails?.fields[21]) {
            //     addformSubEmployerDetails.fields[21].disabled = true
            // }


            // setTimeout(() => {
                setSubEmployerOpen(true)
            // }, 1000);




        }
    }, [editSubEmployeeClicked, JSON.stringify(editSubEmployer)])

    // ===========================Add subemployer form============================

    const addformSubEmployerDetails = {
        id: 'addSubEmployerTable',
        formtype: "add",
        endPointBodyWrapper: "",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "Formsubmit_button SubmitEmploy",
        submitBtnName: Object.keys(editSubEmployer).length > 0 ? "Update" : "Submit",
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
        },

        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                heading: "First Name",
                label: "First Name",
                name: "firstname",
                className: 'form_item formfild inputBlock inputBlock3line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.firstname !== undefined && editSubEmployer.firstname !== null) ? editSubEmployer.firstname : undefined,

            },

            {
                id: 1,
                heading: "Last Name",
                label: "Last Name",
                name: "lastname",
                className: 'form_item formfild inputBlock inputBlock3line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.lastname !== undefined && editSubEmployer.lastname !== null) ? editSubEmployer.lastname : undefined,


            },

            {
                id: 2,
                heading: "Email",
                label: "Email",
                name: "email".trim(),
                className: "form_item formfild inputBlock inputBlock3line",
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.email !== undefined && editSubEmployer.email !== null) ? editSubEmployer.email : undefined,
            },

            {
                id: 3,
                heading: "Phone Number",
                label: "Phone Number Format: (123) 456-7890",
                name: "phone",
                className: "form_item formfild inputBlock inputBlock2line",
                type: "text",
                inputType: "phone",
                // rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                    pattern: "Phone no does not contain any special characters (@#%&$)",
                    maxLength: "Phone number can not be greater than 10 digits",
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.phone !== undefined && editSubEmployer.phone !== null) ? editSubEmployer.phone : undefined,
            },

            // {
            //     id: 29,
            //     heading: "Status",
            //     label: "Select Status",
            //     name: "status",
            //     className: 'form_item formfild inputBlock inputBlock2line',
            //     type: 'select',
            //     multiple: false,
            //     checkbox: true,
            //     values: [
            //         { val: 1, name: "Active" },
            //         { val: 6, name: "Inactive" },
            //     ],
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required"
            //     },
            //     // defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.status == "Active") ? 1 : editSubEmployer.status == "Inactive" ? 6 : null,
            //     defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer?.status !== null && editSubEmployer?.status !== undefined) ? editSubEmployer.status : null,
            // },


            // {
            //     id: 10,
            //     heading: "Company Name",
            //     label: "Company Name",
            //     name: "company_name",
            //     className: "form_item form_item inputBlock inputBlock3line",
            //     type: "text",
            //     // inputType: "address",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid",
            //     },
            //     defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer?.company_name !== undefined && editSubEmployer?.company_name !== null) ? editSubEmployer?.company_name : undefined,
            // },

            {
                id: 11,
                heading: "Title",
                label: "Title",
                name: "title",
                className: "form_item form_item inputBlock inputBlock2line",
                type: "text",
                // inputType: "address",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.title !== undefined && editSubEmployer.title !== null) ? editSubEmployer.title : undefined,
            },
            {
                id: 5,
                heading: "Address",
                label: "Address",
                name: "address",
                className: 'form_item form_item inputBlock inputBlock1line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.address !== undefined && editSubEmployer.address !== null) ? editSubEmployer.address : undefined,
            },

            {
                id: 89,
                heading: "City",
                label: "City",
                name: "city",
                className: 'form_item form_item inputBlock inputBlock3line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.city !== undefined && editSubEmployer.city !== null) ? editSubEmployer.city : undefined,
            },


            {
                id: 6,
                heading: "State",
                label: "State",
                name: "state",
                className: 'form_item form_item inputBlock inputBlock3line',
                type: "select",
                values: StateArray,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.state !== undefined && editSubEmployer.state !== null) ? editSubEmployer.state : undefined,
            },


            {
                id: 7,
                heading: "Zip",
                label: "Zip",
                name: "zip",
                className: 'form_item form_item inputBlock inputBlock3line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.zip !== undefined && editSubEmployer.zip !== null) ? editSubEmployer.zip : undefined,
            },


            // {
            //     id: 8,
            //     heading: "Notes",
            //     // label: "Notes",
            //     name: "notes",
            //     className: 'form_item form_item inputBlock inputBlock1line',
            //     type: "textarea",
            //     // rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },
            //     defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.notes !== undefined && editSubEmployer.notes !== null) ? editSubEmployer.notes : undefined,
            // },


            {
                id: 9,
                heading: "Location",
                label: "Choose a Location",
                name: "location",
                className: 'form_item form_item inputBlock inputBlock3line locationDataEmployer',
                type: 'select',
                multiple: true,
                checkbox: true,
                values: locationArrys,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required"
                },
                // sx: { m: 1, minWidth: 120 },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer?.location_id !== undefined && editSubEmployer?.location_id !== null) ? editSubEmployer.location_id : undefined,

            },


            {
                id: 19,
                heading: "Any other information we should know?",
                // label: "Notes",
                name: "other_info",
                className: 'form_item form_item inputBlock inputBlock1line',
                type: "textarea",
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.other_info !== undefined && editSubEmployer.other_info !== null) ? editSubEmployer.other_info : undefined,
            },




            {
                id: 21,
                heading: "Password",
                label: "Password",
                name: "password",
                className: 'inputBlock formfild6 inputBlock2line passwordinput',
                type: (editSubEmployer && Object.keys(editSubEmployer).length > 0) ? 'hidden' : 'password',
                disabled: (editSubEmployer && Object.keys(editSubEmployer).length > 0) ? true : false,
                rules: { required: editSubEmployer ? false : true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },

                errorMessage: {
                    required: "This Field is Required",
                    // errorMessageConfirmPassword: "Password does not match",         
                    custom: "Value is Invalid",
                    validate: "Password does not match",
                    maxLength: "Maximum allowed input length is 15 characters",

                    pattern: "You need to provide a strong password",
                },
                // defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.password !== undefined && editSubEmployer.password !== null) ? editSubEmployer.password : undefined,

            },

            {
                id: 22,
                heading: "Confirm Password",
                label: "Confirm Password",
                name: "confirmPassword",
                className: 'inputBlock formfild6 inputBlock2line passwordinput',
                type: (editSubEmployer && Object.keys(editSubEmployer).length > 0) ? 'hidden' : 'confirm_password',
                disabled: (editSubEmployer && Object.keys(editSubEmployer).length > 0) ? true : false,
                rules: { required: (editSubEmployer && Object.keys(editSubEmployer).length > 0) ? false : true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
                errorMessage: {
                    required: "This Field is Required",
                    validate: "Password does not match",
                    errorMessageConfirmPassword: "Password does not match",
                    maxLength: "Maximum allowed input length is 15 characters",
                    pattern: "You need to provide a strong password",
                    custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
                },
                // defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.password !== undefined && editSubEmployer.password !== null) ? editSubEmployer.password : undefined,
                // defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.confirmPassword == undefined && editSubEmployer.confirmPassword == null) ? editSubEmployer.password : undefined,



            },

            {
                id: 13,
                // heading: "Status",
                name: "status",
                className: "form_item form_item statusCheck",
                type: 'singleCheckbox',
                values: { key: 0, val: 'Active' },
                defaultValue: (editSubEmployer && Object.keys(editSubEmployer).length > 0 && editSubEmployer.status !== undefined && editSubEmployer.status == "Active") ? true : false,
            },


        ]

    };


    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpenSnackbar(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Modal
                className="add_Employee_Modal addtaskListMOdal"
                open={subEmployerOpen}
                onClose={handleCloseAddSubEmployer}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseAddSubEmployer}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className='dataform2_wrapper'>
                        <div className='adminformbody'>
                            <h2 className="previewModalTitle ">
                                <div className='modal_title modal_titlenew'>
                                    {editSubEmployer &&
                                        Object.keys(editSubEmployer).length > 0
                                        ? "Edit Sub Employer"
                                        : "Add Sub Employer"}
                                </div>
                            </h2>
                            {/* {JSON.stringify(editSubEmployer)} */}
                            <div className="inputBlock inputBlock3line disposition_task">
                                <Box sx={{ minWidth: 120 }}>
                                    <div className='otherForm_mainWrpr'>

                                        {(editSubEmployer && editSubEmployer !== null && Object.keys(editSubEmployer).length > 0) && <Form formData={addformSubEmployerDetails} />}

                                        {Object.keys(editSubEmployer).length === 0 && <Form formData={addformSubEmployerDetails} />}

                                    </div>
                                </Box>
                            </div>

                            {employeeLoader ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                                <LinearProgress />
                            </Box>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                </Box>
            </Modal>

            {/* ========================SnackBar======================= */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={subEmployerSnackFire}
                action={action}
                sx={{ bottom: "24px !important" }}
            />
        </>
    )
}

export default AddEditSubLogin