import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'


import '../../../../assets/List.css'
import store from '../../../../store'

import { Button, Drawer, IconButton, LinearProgress, Paper, Snackbar, Tooltip } from '@mui/material'
import { TableSkeleton } from '../../../../helper/TableSkeleton'
import ListingTable from '../../../../listing/listing'
import { clearPartnerReducerReportData, partnerCommissionSummary, partnerFilterCommissionSummary, partnerFilterCommissionSummaryCount, resetdata, resetFilterData } from '../PartnersReducer'
import { resolvefunction } from '../../../../helper/helperFunctions'
import { clearPageState, setTableCounts, setTableData, setTableDefaultreqBody, unmountTable } from '../../../../listing/listReducer'
import { Form } from '../../../../form/Form'
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import moment from 'moment'
import IosShareIcon from '@mui/icons-material/IosShare';
import { exportCSV, summaryexportCSV } from '../../AffiliateManagement/AffiliateReducer'



function PartnersSummaryReport() {

    const paramData = useParams();
    const navigate = useNavigate();
    const Location = useLocation();
    const dispatch = useDispatch();


    const [toogleVal, setToogleVal] = useState('all')
    const [filterData, setFilterData] = useState(false)
    const [ListData, setListData] = useState([])
    const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
    const [amtToogleFlag, setAmtToogleFlag] = useState(true);
    const [snackOpen, setSnackOpen] = useState(false);


    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});

    // -------- resolve ------ //
    const resolved = useSelector((state) => state.ResolveReducer.resolved["/partners-summary-report"] ? state.ResolveReducer.resolved["/partners-summary-report"] : false);
    //  --------------------- //

    // -------- Main List Data ------ //
    let listingData = useSelector((state) => (state.PartnersReducer?.partnerCommissionSummaryListData && state.PartnersReducer?.partnerCommissionSummaryListData?.length > 0) ? state.PartnersReducer.partnerCommissionSummaryListData : [])
    // ---------------------------- //


    const filteredReportData = useSelector((state) => state.PartnersReducer?.partnerFilterSummarryData ? state.PartnersReducer.partnerFilterSummarryData : []);

    const commisionReportSuccess = useSelector((state) => state.PartnersReducer?.filterPartnerDataSuccess ? state.PartnersReducer.filterPartnerDataSuccess : false);


    const filteredAffiliateReportDataCount = useSelector((state) => state.PartnersReducer?.partnerFilterSummarryCount ? state.PartnersReducer.partnerFilterSummarryCount : null);


    const success = useSelector((state) => state.PartnersReducer?.success ? state.PartnersReducer.success : false);

    // ----------- Loader ------------ //
    let loader = useSelector((state) => (state.PartnersReducer?.loading) ? state.PartnersReducer.loading : false)
    const loading = useSelector((state) => state.AffiliateReducer?.loading ? state.AffiliateReducer.loading : false);

    //  --------------------------- //


    // ----------- transactionReportBtn ------------ //
    const transactionReportBtn = useSelector((state) => (state.tableSlice.customButtonClicked.partner_commission_summary && state.tableSlice.customButtonClicked.partner_commission_summary.transactional_report) ? state.tableSlice.customButtonClicked.partner_commission_summary.transactional_report : false)
    // ------------------------------------------ //


    // ----- On DataRef on every btn trigger ---- //
    const dataRef = useSelector((state) =>
        state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.partner_commission_summary
            ? state.tableSlice?.tempRowData?.partner_commission_summary
            : {}
    );
    // ----------------------------------------- //



    const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.partner_summary_report_Search ? state.formSlice.formSubmissionState.partner_summary_report_Search : false);

    const partnerType = useSelector((state) => state.formSlice?.formData?.partner_summary_report_Search?.partnerType ? state.formSlice.formData.partner_summary_report_Search.partnerType : null);


    const plan_type = useSelector((state) => state.formSlice?.formData?.partner_summary_report_Search?.plan_type ? state.formSlice.formData.partner_summary_report_Search.plan_type : null);


    const username = useSelector((state) => state.formSlice?.formData?.partner_summary_report_Search?.username ? state.formSlice.formData.partner_summary_report_Search.username : null);


    const start_date = useSelector((state) => state.formSlice?.formData?.partner_summary_report_Search?.start_date ? state.formSlice.formData.partner_summary_report_Search.start_date : null);


    const end_date = useSelector((state) => state.formSlice?.formData?.partner_summary_report_Search?.end_date ? state.formSlice.formData.partner_summary_report_Search.end_date : null);


    const csvSentSuccess = useSelector((state) => state.AffiliateReducer?.csvSent ? state.AffiliateReducer.csvSent : false);



    // ================ setting time stamps ====================//
    const todaysStartDate = moment().startOf('day').valueOf()
    const todaysEndDate = moment().endOf('day').valueOf()


    const weekStartDate = moment().startOf('week').valueOf()
    const weekEndDate = moment().endOf('week').valueOf()


    const monthStartDate = moment().startOf('month').valueOf()
    const monthEndDate = moment().endOf('month').valueOf()
    // ======================================================== //



    // ------- entrypoint triggrer ---- //
    useEffect(() => {

        setSnackOpen(false)

        let reqBody = {
            // "formId": 'teamsearch',
            condition: {
                limit: 30,
                skip: 0,
            },
            sort: {
                type: "desc",
                field: "record_date",
            },
            searchcondition: {},
        };

        // console.log("RoleList", resolve);
        if (!resolved) {

            // dispatch(partnerCommissionSummary(reqBody))

            // setTimeout(() => {
            searchTable('all');
            // }, 50);
        }


        return () => {
            store.dispatch(clearPartnerReducerReportData());
            setSnackOpen(false)

        };
    }, []);
    //  ------------------------ //



    //   -------- transactionReportBtn trigger ------ // 
    useEffect(() => {
        // console.warn("transactionReportBtn", transactionReportBtn)

        if (transactionReportBtn) {
            // resolvefunction(navigate, "/partners-transactional-report");
            window.open("/ae-summary-report/" + dataRef.partnerType, "_blank")

            // console.warn("transactionReportBtn triggrer ==========>>>>>>>")
        }
    }, [transactionReportBtn])
    //   ------------------------------------------ //




    // ==== setTableData === //
    useEffect(() => {

        if (filteredReportData.length > 0 && commisionReportSuccess && filterData) {
            setListData(filteredReportData)
        }

    }, [JSON.stringify(filteredReportData), commisionReportSuccess, filterData])
    //  ================== //


    // ==== setAllTableData === //
    useEffect(() => {
        if (listingData.length > 0 && success) {
            setListData(listingData)
        }
    }, [JSON.stringify(listingData), success])
    //  ================== //

    useEffect(() => {
        if (success && ListData) {
            dispatch(setTableData({ "tableId": "partner_commission_summary", "dataset": ListData }))
        }
    }, [JSON.stringify(ListData), success])



    // --------- Table list for partnerCommissionSummaryList --------- //
    var modifyTableHeaders =
        [
            { val: "partner_name", name: "Partner Name" },
            // { val: "partnerType", name: "Partner Type" },
            { val: "totalCommisionAmount", name: "Total Commission Amt ($)" },
        ];

    let partnerCommissionSummaryList = useRef({
        tableId: "partner_commission_summary",
        tableTitle: "Professional Partner Commission Summary ",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "users/partner-commission-summary-list",
            // // deleteSingleUserEndpoint: "role/delete-roles",
            tableCountEndpoint: "users/partner-commission-summary-list",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "role_list",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [


        ],
        buttons: [
            {
                id: "transactional_report",
                name: "summarize_icon",
                type: "other",
                label: "AE Summary Report",
            },



        ],

        searchData: {
            heading: "Search Role",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [

                    {
                        id: 8,
                        label: "Search By Creation start Time",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search By Creation end Time",
                        name: "end_date",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                    {
                        id: 8,
                        label: "Search By Updated on start Time",
                        name: "start_date_by_update",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "updatedon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search By Updated on end Time",
                        name: "end_date_by_update",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "updatedon_datetime", param: "$lte" }
                    },

                ]
            }
        },
    });

    //  ----------------------------- //


    // ============  on table filters click =========== //
    function searchTable(val) {
        setToogleVal(val);

        switch (val) {

            case 'all':
                dispatch(unmountTable({ "tableId": "ComissionTransactionalReport" }));

                setFilterData(false)
                setAmtToogleFlag(false)

                let payloadAll = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {

                    }

                }

                partnerCommissionSummaryList.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReport", "reqBody": payloadAll }))

                // setTimeout(() => {
                dispatch(partnerCommissionSummary(payloadAll))
                // }, 100);

                dispatch(resetFilterData())
                setListData([])

                // setTimeout(() => {
                //     payloadAll['count'] = true
                //     dispatch(partnerFilterCommissionSummaryCount(payloadAll))
                // }, 200);

                break;

            case 'this_month':
                dispatch(unmountTable({ "tableId": "ComissionTransactionalReport" }));
                setFilterData(true)
                setAmtToogleFlag(true)

                let payloadMonth = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {}
                }

                payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }

                dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReport", "reqBody": payloadMonth }))

                partnerCommissionSummaryList.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

                // setTimeout(() => {
                dispatch(partnerFilterCommissionSummary(payloadMonth))
                // }, 100);


                setListData([])
                dispatch(resetdata())

                // setTimeout(() => {
                //     payloadMonth['count'] = true
                //     dispatch(partnerFilterCommissionSummaryCount(payloadMonth))
                // }, 200);


                break;

            case 'this_week':
                dispatch(unmountTable({ "tableId": "ComissionTransactionalReport" }));

                setFilterData(true)
                setAmtToogleFlag(true)




                let payloadWeek = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {}

                }

                payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }

                partnerCommissionSummaryList.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReport", "reqBody": payloadWeek }))

                // setTimeout(() => {
                dispatch(partnerFilterCommissionSummary(payloadWeek))
                // }, 100);

                setListData([])
                dispatch(resetdata())

                // setTimeout(() => {
                //     payloadWeek['count'] = true
                //     dispatch(partnerFilterCommissionSummaryCount(payloadWeek))
                // }, 200);


                break;

            case 'today':
                dispatch(unmountTable({ "tableId": "ComissionTransactionalReport" }));
                setFilterData(true)
                setAmtToogleFlag(true)

                let payloadToday = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {}

                }

                payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }

                partnerCommissionSummaryList.current['reqBody']['searchcondition'] = payloadToday['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReport", "reqBody": payloadToday }))


                // setTimeout(() => {
                dispatch(partnerFilterCommissionSummary(payloadToday))
                // }, 100);

                dispatch(resetdata())
                setListData([])

                // setTimeout(() => {
                //     payloadToday['count'] = true
                //     dispatch(partnerFilterCommissionSummaryCount(payloadToday))
                // }, 200);



                break;

            default:
            // code block
        }
    }
    //  ================================== //


    // ==== setTableCount === //
    useEffect(() => {
        if (filteredAffiliateReportDataCount !== null) {
            dispatch(clearPageState({ "tableId": "partner_commission_summary" }))
            dispatch(setTableCounts({ "tableId": "partner_commission_summary", "count": filteredAffiliateReportDataCount }))
        }
    }, [JSON.stringify(filteredAffiliateReportDataCount)])
    // ===================== //


    //----------------------search form configration---------------------
    const dataform = useRef({
        id: 'partner_summary_report_Search',
        submitBtnName: "Search",
        submitBtnEndIcon: 'search_icon',
        resetForm: true,
        resetBtnName: "Refresh",
        resetBtnEndIcon: 'refresh',
        endPointBodyWrapper: "searchcondition",
        formButtonClass: "submitbtnsection",
        formWrapperClass: "formWrapperClass formCls",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {},

        fields: [

            {
                id: 1,
                label: "Search by Partner Name",
                name: "partnerType",
                // className: 'Status',
                type: 'select',
                multiple: false,
                values: [
                    { val: "naka", name: "NAKA" },
                    { val: "aspire", name: "ASPIRE" },
                    { val: "bluecoast", name: "BLUECOAST" },
                ],
                sx: { m: 1, minWidth: 120 },
                className: 'list_search_item countDiv__column--col6 ',
                payloadFormat: { key: "partnerType" },
            },

            {
                id: 2,
                label: "Search by Recorded on Start Date",
                name: "start_date",
                className: "datePicker",
                type: "datePicker",
                sx: { m: 1, width: 300 },
                className: "list_search_item countDiv__column--col6 ",
                payloadFormat: { key: "record_date", param: "$gte" },
            },

            {
                id: 3,
                label: "Search by Recorded on End Date",
                name: "end_date",
                className: "list_search_item countDiv__column--col6 ",
                type: "datePicker",
                fullDay: true,
                sx: { m: 1, width: 300 },
                payloadFormat: { key: "record_date", param: "$lte" },
                // minDate:{minDate}
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "End Date can not be less than Start Date"
                },
            },


        ],
    })

    // ================================================== //


    // ========= endpoint call for search after search drawwer ===== //
    useEffect(() => {
        if (formSubmissionState === 4) {
            setFilterData(true);
            setToogleVal(null);
            setAmtToogleFlag(false)
            setListData(null)

            const payload = {
                "condition": { "limit": 30, "skip": 0 },
                "sort": {
                    "field": "record_date",
                    "type": "desc"
                },
                "searchcondition": {

                },
                "project": {},
                "token": "",
                "count": false
            }

            if (start_date !== null || end_date !== null) {
                payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

            }

            if (partnerType !== null) {
                payload['searchcondition']['partnerType'] = partnerType

            }



            partnerCommissionSummaryList.current['reqBody']['searchcondition'] = payload['searchcondition']



            dispatch(partnerFilterCommissionSummary(payload))

            setToogleVal('search')


            setTimeout(() => {
                payload['count'] = true
                dispatch(partnerFilterCommissionSummaryCount(payload))
            }, 200);

            setSearchDrawer(false)
        }
    }, [formSubmissionState])
    // ================================================================= //



    // console.warn("listingData  :  filteredReportData ===> ", listingData , filteredReportData)






















    // =============== exportCSV ============= //
    function exportCSVthroughmail() {

        let payload = {}


        switch (toogleVal) {


            case 'all':

                payload = {


                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {

                    },
                    "email": logedUserData.email,
                    "source": "partner_commission_details",
                    "list_ref": "partner_commission_summary",
                    "export_type": "partner_summary"

                }



                dispatch(summaryexportCSV(payload));
                break;


            case 'this_month':

                payload = {


                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {
                        'record_date': { $gte: monthStartDate, $lte: monthEndDate }

                    },
                    "email": logedUserData.email,
                    "source": "partner_commission_details",
                    "list_ref": "partner_commission_summary",
                    "export_type": "partner_summary"

                }


                dispatch(summaryexportCSV(payload));

                break;

            case 'this_week':

                payload = {


                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {
                        "record_date": { $gte: weekStartDate, $lte: weekEndDate }


                    },
                    "email": logedUserData.email,
                    "source": "partner_commission_details",
                    "list_ref": "partner_commission_summary",
                    "export_type": "partner_summary"

                }



                dispatch(summaryexportCSV(payload));

                break;

            case 'today':

                payload = {

                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {
                        "record_date": { $gte: todaysStartDate, $lte: todaysEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "partner_commission_details",
                    "list_ref": "partner_commission_summary",
                    "export_type": "partner_summary"

                }



                dispatch(summaryexportCSV(payload));
                break;


            case 'search':


                payload = {

                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {

                    },
                    "email": logedUserData.email,
                    "source": "partner_commission_details",
                    "list_ref": "partner_commission_summary",
                    "export_type": "partner_summary"

                }


                if (start_date !== null || end_date !== null) {
                    payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

                }

                if (partnerType !== null) {
                    payload['searchcondition']['partnerType'] = partnerType
                }

                dispatch(summaryexportCSV(payload));

                break;

            default:
                break;
        }

    }
    //  ================================== //




    // ===== open snackbar if csv export sucessfull ==== //
    useEffect(() => {
        if (csvSentSuccess) {
            setSnackOpen(true)
        }
    }, [csvSentSuccess])

    //  ================================================ //

    // ======== snackbar close ======= //
    function handleClose() {
        setSnackOpen(false)

    }
    // ========================= //






    return (

        <div className='rolelist_mainwrp'>

            {(loader || loading) && <LinearProgress />}

            <div className='listing_heading'>
                <h1>Professional Partner Commission Summary </h1>
            </div>

            <div className='listNavigate affiliate-reportlist-navigation'>
                <ul className='toogleBtns'>
                    <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
                    <li> <Button variant="contained" onClick={() => { setSearchDrawer(true) }} className={searchDrawer && 'active'}> Query Commission </Button>  </li>
                    <li> <Button variant="contained" onClick={() => {
                        dispatch(unmountTable({ "tableId": "ComissionTransactionalReport" }));
                        searchTable('all')
                    }}>
                        <Tooltip title="Reload" placement="bottom" className=''>
                            <ReplayIcon />
                        </Tooltip>
                    </Button>  </li>



                    {(filteredReportData?.length > 0 || listingData?.length > 0) && <li>
                        <Button variant="contained" onClick={() => exportCSVthroughmail()} >
                            <Tooltip title="Export CSV" placement="bottom" className=''>
                                <IosShareIcon />
                            </Tooltip>
                        </Button>
                    </li>}


                </ul>
            </div>


            <div className='rolelist_mainwrp_Table'>

                {loader === false && success ? (
                    <ListingTable

                        tableData={partnerCommissionSummaryList.current}
                        // dataset={ListData}
                        dataset={filterData ? filteredReportData : listingData}



                        modifyHeaders={modifyTableHeaders}
                    />
                ) : (<TableSkeleton count={5} />)}

            </div>







            {/* ============ search drawer ======== */}
            <Drawer
                anchor="bottom"
                open={searchDrawer}
                onClose={() => setSearchDrawer(false)}
            >
                <Paper elevation={7} className='list_search team_management_list_search search_img'>

                    <div className="searchBarHead DrawerBox">
                        <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Partner Commission Summary Report</h2>
                        <span className="searchBarCloseIcon">
                            <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
                        </span>
                    </div>

                    <Form formData={dataform.current} />
                </Paper>
            </Drawer>
            {/* =================================== */}




            {
                snackOpen && (
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message="CSV Data Sent Successfully. Please Check Your Mail"
                    />
                )
            }



        </div>
    )
}

export default PartnersSummaryReport
