import React, { useEffect, useState } from "react";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from "react-redux";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { format } from 'date-fns'
import { getEmailTeamplatedata } from "../../EmailTemplateManagement/EmailTemplateReducer";
import EmailSendDrower from "../../LeadManagement/EmailSendDrower";
import { useCookies } from "react-cookie";
import store from "../../../../store";
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from "./FDSearch";
import { getMailInfo, UserActivity } from "./folderViewReducer";
import { useLocation } from "react-router-dom";
import { clearEmailSendStatus } from "../../LeadManagement/LeadReducer";




const EmailVew = ({ emailData, showDiv, emailFor, type,leadqueryParamId }) => {

  console.log("emailview1", emailData);
  console.log("emailview2", showDiv);
  console.log("emailview3", emailFor);



  //----------state defines-----------------
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [dataCopy, setDataCopy] = useState({});
  const [openDrowerForEmail, setOpenDrowerForEmail] = useState(false);
  const [queryParamId, setQueryParamId] = useState();
  //---------------------------------------
  const dispatch = useDispatch();

  const dataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1) ? state.tableSlice?.tempRowData?.userslist1 : {});
  const loading = useSelector(state => (state?.EmailTemplateReducer?.loading) ? state.EmailTemplateReducer.loading : false)
  const loader = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const emailtemplateData = useSelector(state => (state.EmailTemplateReducer?.emailtemplateListData && state.EmailTemplateReducer?.emailtemplateListData.length > 0) ? state.EmailTemplateReducer.emailtemplateListData : []);
  const loaderForsendmail = useSelector(state => state.LeadReducer?.loaderForsendmail);
  const submistionstate = useSelector(state => state.LeadReducer?.submistionstate);
   console.log("lead emailtemplateData",emailtemplateData);

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
//----------------------Email Send Status--------------------------------
const emailSendStatus=useSelector((state) => state.LeadReducer?.emailSendStatus  ? state.LeadReducer.emailSendStatus : null);
console.log("emailSendStatus",emailSendStatus);
//=======================================================================

  // ----------------
  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();
  let paramId=null;
  

  if(emailFor==="is_lead"){
    paramId =queryParam.pathname.split("/")
  }
  else{
    paramId = queryParam.search.split("=");
  }

  console.warn("lucky this queryParamId",paramId);
 



  useEffect(() => {
    if (paramId.length===2) {
      setQueryParamId(paramId[1])
    }
    else{
      setQueryParamId(paramId[2])
    }
  }, [JSON.stringify(paramId)])

  console.log("lucky this queryParamId",queryParamId);

  // -------------------------------

  //-----------------this configration is  each send mail upate----------------

// useEffect(()=>{
//   if(emailSendStatus==="success"){
//     let data = {
//       "condition": {
//         "limit": 20,
//         "skip": 0
//       },
//       "sort": {
//         "field": "start_datetime_unix",
//         "type": "desc"
//       },
//       "searchcondition": {
//         "added_for":queryParamId ? queryParamId : undefined ,
        
//       },
      
  
//     }
//     // dispatch(getMailInfo(data));
//     dispatch(getMailInfo( data ));

//   }

//   setTimeout(() => {
//     dispatch(clearEmailSendStatus())
//   }, 5000);

// },[emailSendStatus])

//=========================================================================
console.log("");

  useEffect(() => {
    setOpenDrowerForEmail(false)
  }, [])

  const HandelEmail = () => {

    let reqbody = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": { roles: { $in: [emailFor] } },
      "count": false,
      "source": "",
    }
    setDataCopy(dataRef)
    store.dispatch(getEmailTeamplatedata(reqbody));

  }

  useEffect(() => {

    if (emailtemplateData.length > 0) {
      setOpenDrowerForEmail(true)
    }

  }, [emailtemplateData])

  const closeDrower = () => {
    setOpenDrowerForEmail(false)
  };


  //----------------------From Input Fields for Search---------------------------
  const searchFields_EmailSend = [
    {
      id: 0,
      label: "Search By Email",
      name: "template_id",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-email-subject",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {

          "added_for": queryParamId ? queryParamId : undefined
        },
        "project": {},
        "token": "",
        "email": ""
      },
    },
    {
      id: 1,
      label: "Email Sent By",
      name: "activity_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activityby",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {

          "user_id": queryParamId ? queryParamId : undefined,
          "activity_type": 'email_sent',
          "activity_for": queryParamId ? queryParamId : undefined,
        },
        "project": {},
        "token": "",
        "email": ""
      },
    },

    {
      id: 2,
      label: "Email Opened",
      name: "email_opened",
      type: 'select',
      multiple: false,
      values: [{ val: 1, name: "YES" }, { val: 0, name: "NO" }],
      sx: { m: 1, minWidth: 120 },
      className: 'inputblock2line',
      payloadFormat: { key: "user_type", param: "$in", options: "i" },

    },


    {
      id: 3,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 4,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  const searchFields_EmailSendBy = [

    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]
  //--------------------------------------------------------------------------



  //-------------------get from search fields-------------------------------
  useEffect(() => {
    if (submissionState === 4) {

      // console.warn("searchData Here===================>>>", searchData)

      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== "" || activity_time.$lte !== undefined && delete activity_time["$lte"];

      activity_time.$gte !== "" || activity_time.$gte !== undefined && delete activity_time["$gte"];

      const payload = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "added_for": queryParamId ? queryParamId : undefined,
          "added_by": queryParamId ? queryParamId : undefined,
          "activity_by": searchData?.activity_by ? searchData.activity_by : undefined,
          "createdon_datetime": activity_time,
          "template_id": searchData?.template_id ? searchData.template_id : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      }

      activity_time.$gte == undefined && activity_time.$lte == undefined && delete payload.searchcondition["createdon_datetime"];

      type=="EmailSentBy" && delete payload.searchcondition["added_for"]
      type=="EmailSent" && delete payload.searchcondition["added_by"]

      dispatch(getMailInfo(payload))

      showDiv !== "EmailSend" && delete payload?.searchcondition["activity_by"];
    }
  }, [submissionState])
  //-----------------------------------------------------------------------------

  return (
    <>
      <div>
        {showDiv == "EmailSend" && (
          <h3>
            <SendIcon />Email Sent
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Send Email">
                <IconButton className="filtersearch" onClick={() => HandelEmail()} ><SendIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Search">
                <IconButton><SearchIcon onClick={() => setSearchDraw(true)} /></IconButton>
              </Tooltip>
            </div>
          </h3>
        )}
        {showDiv == "EmailSendBy" && (
          <h3>
            <MailOutlineIcon /> Email Sent By
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>

              <Tooltip title="search">
                <IconButton>
                  <SearchIcon onClick={() => setSearchDraw(true)} />
                </IconButton>
              </Tooltip>
            </div>
          </h3>
        )}
        {loading || loader ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {emailData.length > 0 &&
          emailData.map((x) => (
            <div className="user-activity" key={x._id}>
              {showDiv == "EmailSend" && (
                <ul className="ua-data-block" sx={{'flex-direction':'column !important', 'align-items':'flex-start !important'}}>
                  <li>{x.email_subject} Sent By {x.sent_by_name} To {x.sent_to_name} On {format(x.createdon_datetime, "dd-MM-yyyy HH:mm:ss")} </li>
                  {/* <li>Mail Sent Time:  </li>
                  <li>Mail  </li>
                  <li>Recipient:  </li> */}

                  <li>
                    Email Status :{" "}
                    {x.email_open === 1
                      ? x?.email_opened_at
                        ? `Email Open On ${format(
                          x?.email_opened_at,
                          "dd-MM-yyyy HH:mm:ss"
                        )}`
                        : " "
                      : "Email deliverd but not open yet"}
                  </li>
                  <li>
                    Mail Tracking: {x.email_tracking ? "Active" : "Inactive"}{" "}
                  </li>
                </ul>
              )}
              {showDiv == "EmailSendBy" && (
                <ul className="ua-data-block">
                  <li> {x.email_subject} Sent To {x.sent_to_name} On {x.createdon_datetime} </li>

                  {/* <li>Mail Sent Time:  </li>

                  <li>Mail  </li> */}
                  {/* <li>Mail Sent By: {x.sent_by_name} </li> */}
                  {/* <li>Email Status : {x.email_open===1 ? `Email Open On ${format(x?.email_opened_at, 'dd-MM-yyyy HH:mm:ss')}`:"Email deliverd but not open yet"}</li> */}
                </ul>
              )}
            </div>
          ))}
        {emailData.length === 0 && <p> No Data Found !</p>}
      </div>


      {/* ---- Email Send Drawer --- */}
      {/* {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null} */}
      {openDrowerForEmail ? <EmailSendDrower
        open={openDrowerForEmail}
        close={closeDrower}
        data={emailtemplateData}
        addedById={userInfo?.userinfo?._id}
        addedForId={queryParamId}
        loading={loaderForsendmail}
        submistionstate={submistionstate}
      /> : ""}
      {/* --------------------------- */}
      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        searchFields={showDiv === "EmailSend" ? searchFields_EmailSend : searchFields_EmailSendBy}
        type="email " /> : ""}
    </>
  );
};

export default EmailVew;
