import React, { useEffect, useState } from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useNavigate } from "react-router-dom";

import { resolvefunction } from "../../helper/helperFunctions";
import { Button, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

function Frontendfooter() {
  const navigate = useNavigate();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [openMoadl, setOpenMoadl] = React.useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeModal = () => {
    setOpenMoadl(false);
  };

  return (
    <>
      <div>
        <div className="top-to-btm">
          {" "}
          {showTopBtn && (
            <a className="icon-position icon-style" onClick={goToTop}>
              <KeyboardDoubleArrowUpIcon />
            </a>
          )}{" "}
        </div>
        <div className="footer">
          <div className="landing-container">
            <div className="footercontainer">
              <p onClick={() => setOpenMoadl(true)}>
                {/* <span >
                  Terms & Conditions
                </span> */}
                |<span >Privacy Policy</span>
                Copyright@2022
              </p>
              {/* <button className='footerloginbtn' onClick={() => { resolvefunction(navigate, "/login",); }}  > Login  </button> */}
              {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'saVit1YdCuovt') ?null:   <button
                className="footerloginbtn"
                onClick={() => window.open("/login", "_blank")}
              >
                Login
              </button>}
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          classname="image_card_modal modalBaseStyle"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal">
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            <h1>Cooming Soon</h1>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Frontendfooter;
