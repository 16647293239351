import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import LandingPage13Form from '../../LandingPage13/LandingPage13Form';

export default function LandingPage19formComponent() {
    // const [locationArry, setLocationArry] = useState([]);
    // const [externalformUrl, setExternalformUrl] = useState("https://enroll.adhcrx.com/Prefill");
    // const [newloader, setnewloader] = useState(false)
    // const dispatch = useDispatch();
    // const [loading, setloading] = useState(false);

    // const [submissionState, setSubmissionState] = useState(0);


    // const { uniquename } = useParams();
    // console.log("uniquename******", uniquename);

    // let reqbodyforjobcategory = {}
    // if (typeof uniquename != 'undefined') {
    //     reqbodyforjobcategory = {
    //         "uniquename": uniquename,
    //     }
    // }
    // else {
    //     reqbodyforjobcategory = {
    //         "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG,
    //     }
    //     console.warn("process.env>>>>>>>", process.env);
    // }
    // useEffect(() => {
    //     fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
    //         .then((results) => results.json())
    //         .then((data) => {
    //             console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
    //             dispatch(setIpinfo(data));
    //         });

    // }, []);
    // // *********************UseSelectors***********************
    // const formInputData = useSelector((state) => state.formSlice.formData.userForm13 ? state.formSlice.formData.userForm13 : null);
    // const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
    // const landingpageformsubmitionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer.landingpagesubmittionstate : 0)
    // const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});
    // const fetchedStateData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment?.FetchStages : [])
    // const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)


    // const [conversionapicall, setConversionapicall] = useState(false);
    // const [stoeFormdata, setstoeFormdata] = useState(null);

    // //   console.log("uouiuiuiuuiu",noapi);

    // useEffect(() => {
    //     console.log("ipinfo++++++", ipinfo, userData);
    //     if (userData && ipinfo && !noapi) {
    //         console.log("userData", userData);
    //         let body = {
    //             // "campaign_id":userData.campaign_id,
    //             "associated_program": userData.associated_program,
    //             "landing_page_id": userData.landing_page_id,
    //             "landing_page_slug": userData.landing_page_slug,
    //             // "campaign_added_by":userData.user_id,
    //             // "hiring_manager":userData.hm_id,
    //             "ipInfo": ipinfo
    //         }
    //         if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
    //         if (userData.user_id) body['campaign_added_by'] = userData.user_id;
    //         if (userData.hm_id) body['hiring_manager'] = userData.hm_id;
    //         if (userData.rep_id) body['rep_id'] = userData.rep_id;
    //         console.log("+++++++++++++++++++", body);
    //         if (body && body.landing_page_id && !conversionapicall) {
    //             dispatch(addconverstiondata(body))
    //             setConversionapicall(true)

    //         }

    //         //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
    //         //    console.log("bodyc",bodyc);
    //     }

    // }, [JSON.stringify(ipinfo), JSON.stringify(userData)])

    // let currTime = new Date().getTime();
    // let dayAfterTomorrow = (currTime + (48 * 3600 * 1000));
    // const [slotListBody, setSlotListBody] = useState({
    //     "condition": {
    //         "limit": 10,
    //         "skip": 0
    //     },
    //     "sort": {
    //         "field": "start_datetime_unix",
    //         "type": "desc"
    //     },
    //     "searchcondition": {
    //         // userid: getcookies.userInfo?._id,
    //         "start_datetime_unix": {
    //             "$gte": dayAfterTomorrow
    //         }
    //     }
    //     // "slot_creation_type": props.type === 'np' ? 2 : 1
    // })

    // useEffect(() => {
    //     if (uniquename) {
    //         store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
    //     }
    //     else {
    //         store.dispatch(getlandingpagedata(reqbodyforjobcategory));
    //     }
    // }, [])

    // useEffect(() => {
    //     console.log("userData", landingpageformsubmitionstate, addedData);
    //     if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
    //         // dispatch(setSnackbar({ open: true, message: "Thank you for Submitting !", status: 'success' }))
    //         // dispatch(clearlandingpagesubmittionstate())

    //         callstageandredirecr()

    //         // setopenMoadl(true);


    //         // if (userData.calendar_access && userData.calendar_access == 1) {
    //         //     if (userData?.hm_id) {
    //         //         slotListBody.searchcondition['userid'] = userData.hm_id
    //         //         slotListBody['organiser_role'] = 'hm'
    //         //     }
    //         //     slotListBody['_id'] = addedData._id;
    //         //     resolvefunction(navigate, `/calendar/${addedData._id}/hm/${userData.hm_id}`, [
    //         //         fetchCalenderEvents(slotListBody),
    //         //     ]);
    //         // }
    //         // else {
    //         //     navigate(`/thankyou`);
    //         // }

    //         // dispatch(setLeadinfo(addedData));
    //         // store.dispatch(clearlandingpagesubmittionstate())





    //     }




    // }, [landingpageformsubmitionstate, fetchedStateData])


    // useEffect(() => {
    //     if (userData && userData.signup_form) {
    //         if (userData.signup_form === "aspire") {
    //             setExternalformUrl('https://aspire.adhcrx.com/Prefill')
    //         }
    //         if (userData.signup_form === "bluecoast") {
    //             setExternalformUrl('https://bluecoast.adhcrx.com/Prefill')

    //         }

    //         if (userData.signup_form === "naka") {
    //             setExternalformUrl('https://nakagames.adhcrx.com/Prefill')


    //         }
    //     }

    // }, [JSON.stringify(userData)])


    // const callstageandredirecr = async () => {
    //     setnewloader(true)
    //     await dispatch(clearlandingpagesubmittionstate())
    //     let body = {
    //         // "campaign_id":userData.campaign_id,
    //         "associated_program": userData.associated_program,
    //         "landing_page_id": userData.landing_page_id,
    //         "landing_page_slug": userData.landing_page_slug,
    //         "lead_id": addedData._id,
    //         // "campaign_added_by":userData.user_id,
    //         // "hiring_manager":userData.hm_id,
    //         "ipInfo": ipinfo
    //     }
    //     if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
    //     if (userData.user_id) body['campaign_added_by'] = userData.user_id;
    //     if (userData.hm_id) body['hiring_manager'] = userData.hm_id;
    //     if (userData.rep_id) body['rep_id'] = userData.rep_id;
    //     if (userData.affiliate_id) body['affiliate_id'] = userData.affiliate_id;
    //     console.log("+++++++++++++++++++-------------------", body.landing_page_id);
    //     if (body && body.landing_page_id) {

    //         console.log("jkjhkljdfkdsfkldfgk")

    //         dispatch(addconverstiondata(body))


    //     }
    //     const dataC = {
    //         stage_slug: 'MJuEcxiUQqAPy',
    //         added_by: userData.user_id,
    //         added_for: addedData._id,
    //         is_delete: 0,
    //         status: 1
    //     }
    //     console.log("data===========================>", dataC)
    //     // return
    //     await dispatch(updateStages(dataC));

    //     dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))
    //     setnewloader(false)

    //     const data = {
    //         stage_slug: '6oZTsBFEkuX7J',
    //         added_by: userData.user_id,
    //         added_for: addedData._id,
    //         is_delete: 0,
    //         status: 1
    //     }
    //     console.log("data===========================>", data)
    //     // return
    //     dispatch(updateStages(data));
    //     dispatch(clearaddedinfo())
    //     document.forms["externalForm"].submit()
    //     setTimeout(() => {
    //         setstoeFormdata(null)
    //     }, 1000);
    //     return
    //     if (userData?.agent_code) {
    //         window.location.replace(`https://enroll.adhcrx.com/?agentcode=${userData.agent_code}`);

    //     } else {
    //         window.location.replace("https://enroll.adhcrx.com");

    //     }
    // }

    // const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//

    // useEffect(() => {
    //     console.log("addedData", addedData);

    // }, [addedData])

    // useEffect(() => {
    //     if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
    //         return
    //         setConversionapicall(false)
    //         setDataCopy(addedData)
    //         const dataC = {
    //             stage_slug: 'MJuEcxiUQqAPy',
    //             added_by: userData.rep_id,
    //             added_for: addedData._id,
    //             is_delete: 0,
    //             status: 1
    //         }
    //         console.log("data===========================>", dataC)
    //         // return
    //         dispatch(updateStages(dataC));

    //         // let body = {
    //         //     // "campaign_id":userData.campaign_id,
    //         //     "associated_program": userData.associated_program,
    //         //     "landing_page_id": userData.landing_page_id,
    //         //     "landing_page_slug": userData.landing_page_slug,
    //         //     "lead_id":addedData._id,
    //         //     // "campaign_added_by":userData.user_id,
    //         //     // "hiring_manager":userData.hm_id,
    //         //     "ipInfo": ipinfo
    //         // }
    //         // if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
    //         // if (userData.user_id) body['campaign_added_by'] = userData.user_id;
    //         // if (userData.hm_id) body['hiring_manager'] = userData.hm_id;

    //         // console.log("+++++++++++++++++++-------------------", body.landing_page_id);
    //         // if (body && body.landing_page_id ) {

    //         //     console.log("jkjhkljdfkdsfkldfgk")

    //         //     dispatch(addconverstiondata(body))
    //         //     setConversionapicall(true)

    //         // }


    //         const tagdata = {
    //             added_by: userData.rep_id,
    //             added_for: addedData._id,
    //             key: "Wnv14tRS9FlmV",
    //             label: "hm-policiesandcompliance-signed-success",
    //             is_delete: 0,
    //             status: 1
    //         }
    //         console.log("tagdata===========================>", tagdata)
    //         // return
    //         dispatch(updateTags(tagdata));

    //         const data = {
    //             stage_slug: '6oZTsBFEkuX7J',
    //             added_by: userData.rep_id,
    //             added_for: addedData._id,
    //             is_delete: 0,
    //             status: 1
    //         }
    //         console.log("data===========================>", data)
    //         // return
    //         dispatch(updateStages(data));


    //     }


    // }, [landingpageformsubmitionstate])


    // useEffect(() => {
    //     let Newarr = [];
    //     if (StateArray && StateArray.length > 0) {
    //         for (let i in StateArray) {
    //             Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
    //         }
    //         setLocationArry(Newarr);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (submissionState && submissionState === 4) {

    //         let dataset = { ...formInputData }
    //         if (dataset.state) {
    //             console.log("if 1");
    //             if (StateArray.length > 0) {
    //                 console.log("if 2");
    //                 for (let i in StateArray) {
    //                     console.log("if 3");
    //                     if (StateArray[i].name == dataset.state) {
    //                         console.log("if 4");
    //                         dataset = { ...dataset, state_value: StateArray[i].val }
    //                     }
    //                 }
    //             }

    //         }
    //         console.log("dataset", dataset);
    //         addapicall(formInputData);
    //         setstoeFormdata(dataset)

    //         console.log("formInputData", formInputData, submissionState);
    //     }
    // }, [submissionState]);
    // store.subscribe(() => {
    //     const storeData = store.getState();
    //     if (storeData && storeData.formSlice && storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.userForm13) {
    //         setSubmissionState(storeData.formSlice.formSubmissionState.userForm13);
    //     }
    //     // console.log(setSubmissionState, "setSubmissionState")
    //     // console.log("landing store", storeData)
    // })
    // const addapicall = async (val) => {
    //     console.log("val>>>", val);

    //     val = { ...val }
    //     let body = {
    //         "firstname": val.firstname,
    //         "lastname": val.lastname,
    //         "email": val.email,
    //         "phone": val.phone,
    //         "city": val.city,
    //         "state": val.state,
    //         "zip": val.zip,
    //         "streetaddress": val.streetaddress,
    //         "apartment": val.apartment,

    //         // "married": val.married,
    //         "campaign_id": userData.campaign_id,
    //         "associated_program": userData.associated_program,
    //         "page_id": userData.landing_page_id,
    //         "page_slug": userData.landing_page_slug,
    //         "added_by": userData.user_id,
    //         "hiring_manager": userData.hm_id,
    //         "rep_page_id": userData.rep_page_id,
    //         "rep_page_slug": userData.rep_page_slug,
    //         "rep_id": userData?.rep_id ? userData.rep_id : undefined,
    //         "affiliate_id": userData?.affiliate_id ? userData?.affiliate_id : undefined,
    //         "createdon_datetime": new Date().getTime(),
    //         "status": 0,
    //         // "roles": { is_rep: 1 },
    //         "aer_id": userData.aer_id,
    //         "associated_bdd": userData.associated_bdd,
    //         "redirected_to_accesspage": true,
    //         "added_type": 'landingpage',
    //         "calender_access_form_submit_time": userData.calendar_access ? 2 : 1,
    //         "ipinfo": ipinfo,
    //         "choose_prescription_plan": val.choose_prescription_plan
    //         // is_rep: true,
    //     }

    //     if (Object.keys(body).length > 0) {
    //         console.log("body-----", body);

    //         setloading(true)
    //         let responsedataset = await store.dispatch(addlandingpagetwodatalead(body));

    //         console.log("responsedataset", responsedataset);
    //         if (responsedataset && responsedataset.payload && responsedataset.payload.status == 'error') {
    //             store.dispatch(
    //                 setSnackbar({ open: true, message: responsedataset.payload.message ? responsedataset.payload.message : 'Something went wrong', status: 'error' })
    //             )
    //             setSubmissionState(0)
    //         }
    //         if (userData.pixel_tracking_conversion) {
    //             const node = document.createRange().createContextualFragment(userData.pixel_tracking_conversion);
    //             refforconversion.current.appendChild(node);
    //         }
    //         // pixel_tracking_conversion
    //         // setActive(false)
    //         setloading(false)

    //     }
    // };



    // const formDataL2 = {
    //     id: 'userForm13',
    //     formtype: "add",
    //     // api_url: process.env.REACT_APP_API_URL,
    //     // endPoint: "lead-manage/add-update-intake-form",
    //     // endPointBodyWrapper: "data",
    //     // urlPathOnSuccessfulFormSubmission: "/calendar",
    //     msgOnFailedFormSubmission: "Something Went Wrong",
    //     formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    //     formInlineCLass: "userForm", // Can be undefined default will be formInline
    //     formButtonClass: "l2submitbtnsection",
    //     submitBtnName: "Select your subscription on the next step",
    //     // submitBtnStartIcon: 'image_search_icon',
    //     // submitBtnEndIcon: 'search_icon',
    //     resetBtnName: "Reset",
    //     formAdditionalSubmissionData: {
    //         type: "admin",
    //         status: "active",
    //     },
    //     fields: [
    //         //////////////////////////////////// Text Input ///////////////////////////////
    //         {
    //             id: 0,
    //             // heading: "Full Name",
    //             label: "First Name",
    //             name: "firstname",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },
    //         {
    //             id: 1,
    //             // heading: "Full Name",
    //             label: "Last Name",
    //             name: "lastname",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },
    //         {
    //             id: 2,
    //             // heading: "Full Name",
    //             label: "Phone",
    //             name: "phone",
    //             className: 'formfild',
    //             type: "text",
    //             inputType: "phone",
    //             rules: { required: true, minLength: 10 },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },

    //         {
    //             id: 3,
    //             // heading: "Full Name",
    //             label: "Email",
    //             name: "email",
    //             className: 'formfild email_marg',
    //             type: "text",
    //             inputType: "email",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },
    //         {
    //             id: 30,
    //             type: "html",
    //             className: "email_btm_line",
    //             value: "<p></p>"
    //         },

    //         // {
    //         //     id: 4,
    //         //     // heading: "Street Address",
    //         //     label: "Street Address",
    //         //     name: "streetaddress",
    //         //     className: 'formfild',
    //         //     type: "text",
    //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    //         //     rules: { required: true },
    //         //     errorMessage: {
    //         //         required: "This Field is Required",
    //         //         custom: "Value is Invalid"
    //         //     },
    //         // },

    //         // {
    //         //     id: 5,
    //         //     // heading: "Street Address",
    //         //     label: "Apartment, suit, unit etc. (optional)",
    //         //     name: "apartment",
    //         //     className: 'formfild',
    //         //     type: "text",
    //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    //         //     // rules: { required: true },
    //         //     // errorMessage: {
    //         //     //     required: "This Field is Required",
    //         //     //     custom: "Value is Invalid"
    //         //     // },
    //         // },




    //         {
    //             id: 6,
    //             // heading: "Full Name",
    //             label: "Town / City",
    //             name: "city",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },
    //         {
    //             id: 7,
    //             // heading: "Full Name",
    //             label: "State",
    //             name: "state",
    //             className: 'formfild state',
    //             type: "select",
    //             // other: true,
    //             values: locationArry,
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //             defaultValue: "",
    //         },
    //         {
    //             id: 8,
    //             // heading: "Full Name",
    //             label: "Zip / Postal Code",
    //             name: "zip",
    //             className: 'formfild zipCode_for',
    //             type: "text",
    //             // inputType: "number",
    //             inputType: "zip",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",

    //             },

    //         },
    //         // {
    //         //     id: 11,
    //         //     heading: "Please Choose Your Prescription Plan",
    //         //     name: "choose_prescription_plan",
    //         //     className: 'form_radio',
    //         //     values: [{ key: 'Family ($31.99 per mo.)', val: 'Family ($31.99 per mo.)' }, { key: 'Single +1 ($26.99 per mo.)', val: 'Single +1 ($26.99 per mo.)' }, { key: 'Single ($21.99 per mo.)', val: 'Single ($21.99 per mo.)' }],
    //         //     type: 'radio',
    //         //     // hint: 'Yes/No',
    //         //     // rules: { required: true },
    //         //     // errorMessage: {
    //         //     //   required: "This Field is Required",
    //         //     //   custom: "Value is Invalid"
    //         //     // },
    //         //     // defaultValue: "No"
    //         // }
    //         {
    //             id: 125,
    //             // heading: "Captcha",
    //             // label: "Captcha",
    //             name: "captcha",
    //             className: 'formfild formfild4 reCaptcha',
    //             type: "captcha",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },

    //     ]

    // };

    // // const formDataL2 = {
    // //     id: 'userForm5',
    // //     formtype: "add",
    // //     // api_url: process.env.REACT_APP_API_URL,
    // //     // endPoint: "lead-manage/add-update-intake-form",
    // //     // endPointBodyWrapper: "data",
    // //     // urlPathOnSuccessfulFormSubmission: "/calendar",
    // //     msgOnFailedFormSubmission: "Something Went Wrong",
    // //     formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    // //     formInlineCLass: "userForm", // Can be undefined default will be formInline
    // //     formButtonClass: "l2submitbtnsection",
    // //     submitBtnName: "SUBMIT",
    // //     // submitBtnStartIcon: 'image_search_icon',
    // //     // submitBtnEndIcon: 'search_icon',
    // //     resetBtnName: "Reset",
    // //     formAdditionalSubmissionData: {
    // //         type: "admin",
    // //         status: "active",
    // //     },
    // //     fields: [
    // //         //////////////////////////////////// Text Input ///////////////////////////////
    // //         {
    // //             id: 0,
    // //             // heading: "Full Name",
    // //             label: "First Name",
    // //             name: "firstname",
    // //             className: 'formfild',
    // //             type: "text",
    // //             rules: { required: true },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid"
    // //             },
    // //         },
    // //         {
    // //             id: 1,
    // //             // heading: "Full Name",
    // //             label: "Last Name",
    // //             name: "lastname",
    // //             className: 'formfild',
    // //             type: "text",
    // //             rules: { required: true },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid"
    // //             },
    // //         },
    // //         {
    // //             id: 2,
    // //             // heading: "Full Name",
    // //             label: "Phone",
    // //             name: "phone",
    // //             className: 'formfild',
    // //             type: "text",
    // //             inputType: "phone",
    // //             rules: { required: true, minLength: 10 },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid"
    // //             },

    // //         },

    // //         {
    // //             id: 3,
    // //             // heading: "Full Name",
    // //             label: "Email",
    // //             name: "email",
    // //             className: 'formfild email_marg',
    // //             type: "text",
    // //             inputType: "email",
    // //             rules: { required: true },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid"
    // //             },

    // //         },
    // //         {
    // //             id: 30,
    // //             type: "html",
    // //             className: "email_btm_line",
    // //             value: "<p></p>"
    // //         },

    // //         // {
    // //         //     id: 4,
    // //         //     // heading: "Street Address",
    // //         //     label: "Street Address",
    // //         //     name: "streetaddress",
    // //         //     className: 'formfild',
    // //         //     type: "text",
    // //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    // //         //     rules: { required: true },
    // //         //     errorMessage: {
    // //         //         required: "This Field is Required",
    // //         //         custom: "Value is Invalid"
    // //         //     },
    // //         // },

    // //         // {
    // //         //     id: 5,
    // //         //     // heading: "Street Address",
    // //         //     label: "Apartment, suit, unit etc. (optional)",
    // //         //     name: "apartment",
    // //         //     className: 'formfild',
    // //         //     type: "text",
    // //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    // //         //     // rules: { required: true },
    // //         //     // errorMessage: {
    // //         //     //     required: "This Field is Required",
    // //         //     //     custom: "Value is Invalid"
    // //         //     // },
    // //         // },




    // //         {
    // //             id: 6,
    // //             // heading: "Full Name",
    // //             label: "Town / City",
    // //             name: "city",
    // //             className: 'formfild',
    // //             type: "text",
    // //             rules: { required: true },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid"
    // //             },

    // //         },
    // //         {
    // //             id: 7,
    // //             // heading: "Full Name",
    // //             label: "State",
    // //             name: "state",
    // //             className: 'formfild state',
    // //             type: "select",
    // //             // other: true,
    // //             values: locationArry,
    // //             rules: { required: true },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid"
    // //             },
    // //             defaultValue: "",
    // //         },
    // //         {
    // //             id: 8,
    // //             // heading: "Full Name",
    // //             label: "Zip / Postal Code",
    // //             name: "zip",
    // //             className: 'formfild',
    // //             type: "text",
    // //             inputType: "text",
    // //             rules: { required: true, maxLength: 5, minLength: 5 },
    // //             errorMessage: {
    // //                 required: "This Field is Required",
    // //                 custom: "Value is Invalid",
    // //                 maxLength: "Maxlength length 5 allowed",
    // //                 minLength: "Minlength length 5 allowed",
    // //             },

    // //         },
    // //         // {
    // //         //     id: 11,
    // //         //     heading: "Please Choose Your Prescription Plan",
    // //         //     name: "choose_prescription_plan",
    // //         //     className: 'form_radio',
    // //         //     values: [{ key: 'Family ($31.99 per mo.)', val: 'Family ($31.99 per mo.)' }, { key: 'Single +1 ($26.99 per mo.)', val: 'Single +1 ($26.99 per mo.)' }, { key: 'Single ($21.99 per mo.)', val: 'Single ($21.99 per mo.)' }],
    // //         //     type: 'radio',
    // //         //     // hint: 'Yes/No',
    // //         //     // rules: { required: true },
    // //         //     // errorMessage: {
    // //         //     //   required: "This Field is Required",
    // //         //     //   custom: "Value is Invalid"
    // //         //     // },
    // //         //     // defaultValue: "No"
    // //         // }

    // //     ]

    // // };
    // const ref = useRef();
    // const refforconversion = useRef();
    // useEffect(() => {
    //     if (userData.pixel_tracking_click && !noapi) {

    //         const node = document.createRange().createContextualFragment(userData.pixel_tracking_click);
    //         ref.current.appendChild(node);
    //     }
    // }, [JSON.stringify(userData)])
    return (
        <>






            <LandingPage13Form formheader={"COMPLETE THE FORM TO ENROLL AND START SAVING TODAY"} submittext={"Submit"} />

            <div className='blank'>

            </div>










            {/* <div>
                <h1>
                    COMPLETE THE FORM TO ENROLL AND START SAVING TODAY
                </h1>
                <Form formData={formDataL2} />
                
            </div> */}

        </>
    )
}
