import React, { useEffect, useRef, useState } from "react";
import "../LandingPage20/LandingPage20.css";
import { Button, IconButton, LinearProgress, Modal, TextField, Tooltip } from "@mui/material";
import ReactPlayer from "react-player";
import LandingPage13Form from "../LandingPage13/LandingPage13Form";
import { Form } from '../../../../form/Form';
import { Box } from "@mui/system";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import ListingTable from "../../../../listing/listing";
import { MedicineListing } from "../../../Backend/Medicine/MedicineReducer";
import { TableSkeleton } from "../../../../helper/TableSkeleton";




export default function LandingPage20({ submittext }) {
  const dispatch = useDispatch();
  const scrollToview = useRef();
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);

  const medcat = useSelector((state) => (state.medicineSlice?.medicinedata && state.medicineSlice?.medicinedata.length > 0) ? state.medicineSlice.medicinedata : [])
  const loadingInMedCat = useSelector(state => state.medicineSlice?.loading ? state.medicineSlice.loading : false);

  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };


  const [play, SetPlay] = useState(false);
  const [seek, setSeek] = useState(1);
  let [pause, setPause] = useState(1);

  const videoPlay = () => {

    var element = document.getElementById("classGrp");
    console.log("element", element);
    element.classList.remove("Lp14block1_sub_left_wrapper2");

    SetPlay(true)

  }


  var modifyTableHeadersforMedCatlist =
    [

      { val: "name", name: "Medicine Name" },
      { val: "med_by_catagorey", name: "Medicine Category" },



    ];

  let tableDataForMedCatlist = useRef({
    tableId: "medcatlist",
    tableTitle: "Free Medicine List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {

      // "source": "training_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["name", "med_by_catagorey"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      // {
      //   id: "alldruglist",
      //   type: "format_list_bulleted",
      //   title: "All Drug List",
      //   className: "format_list_bulleted",
      //   default: true,
      // },
      // {
      //   id: "backtoMain",
      //   type: "keyboard_backspace_icon",
      //   title: "Back to Main List",
      //   className: "keyboard_backspace_icon",
      //   default: true,
      // },
    ],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      // heading: "Search Medicine & Category List",
      //   type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchMedicineandCategoryList',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },

          },



        ]
      }
    },

  });
  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody));
  }, [])



  useEffect(() => {
    console.log("play--------", play);


  }, [play])

  // const formDataL2 = {
  //   id: 'userForm13',
  //   formtype: "add",
  //   // api_url: process.env.REACT_APP_API_URL,
  //   // endPoint: "lead-manage/add-update-intake-form",
  //   // endPointBodyWrapper: "data",
  //   // urlPathOnSuccessfulFormSubmission: "/calendar",
  //   msgOnFailedFormSubmission: "Something Went Wrong",
  //   formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
  //   formInlineCLass: "userForm", // Can be undefined default will be formInline
  //   formButtonClass: "l2submitbtnsection",
  //   submitBtnName: submittext ? submittext : "Select your subscription on the next step",
  //   // submitBtnStartIcon: 'image_search_icon',
  //   // submitBtnEndIcon: 'search_icon',
  //   resetBtnName: "Reset",
  //   formAdditionalSubmissionData: {
  //     type: "admin",
  //     status: "active",
  //   },
  //   fields: [
  //     //////////////////////////////////// Text Input ///////////////////////////////
  //     {
  //       id: 0,
  //       // heading: "Full Name",
  //       label: "First Name",
  //       name: "firstname",
  //       className: 'formfild',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //     },
  //     {
  //       id: 1,
  //       // heading: "Full Name",
  //       label: "Last Name",
  //       name: "lastname",
  //       className: 'formfild',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //     },
  //     {
  //       id: 2,
  //       // heading: "Full Name",
  //       label: "Phone",
  //       name: "phone",
  //       className: 'formfild',
  //       type: "text",
  //       inputType: "phone",
  //       rules: { required: true, minLength: 10 },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },

  //     },

  //     {
  //       id: 3,
  //       // heading: "Full Name",
  //       label: "Email",
  //       name: "email",
  //       className: 'formfild email_marg',
  //       type: "text",
  //       inputType: "email",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },

  //     },
  //     {
  //       id: 30,
  //       type: "html",
  //       className: "email_btm_line",
  //       value: "<p></p>"
  //     },

  //     // {
  //     //     id: 4,
  //     //     // heading: "Street Address",
  //     //     label: "Street Address",
  //     //     name: "streetaddress",
  //     //     className: 'formfild',
  //     //     type: "text",
  //     //     inputType: "text",/////////////// If not mentioned default will be text //////////////
  //     //     rules: { required: true },
  //     //     errorMessage: {
  //     //         required: "This Field is Required",
  //     //         custom: "Value is Invalid"
  //     //     },
  //     // },

  //     // {
  //     //     id: 5,
  //     //     // heading: "Street Address",
  //     //     label: "Apartment, suit, unit etc. (optional)",
  //     //     name: "apartment",
  //     //     className: 'formfild',
  //     //     type: "text",
  //     //     inputType: "text",/////////////// If not mentioned default will be text //////////////
  //     //     // rules: { required: true },
  //     //     // errorMessage: {
  //     //     //     required: "This Field is Required",
  //     //     //     custom: "Value is Invalid"
  //     //     // },
  //     // },




  //     {
  //       id: 6,
  //       // heading: "Full Name",
  //       label: "Town / City",
  //       name: "city",
  //       className: 'formfild',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },

  //     },
  //     {
  //       id: 7,
  //       // heading: "Full Name",
  //       label: "State",
  //       name: "state",
  //       className: 'formfild state',
  //       type: "select",
  //       // other: true,
  //       values: "",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: "",
  //     },
  //     {
  //       id: 8,
  //       // heading: "Full Name",
  //       label: "Zip / Postal Code",
  //       name: "zip",
  //       className: 'formfild zipCode_for',
  //       type: "text",
  //       // inputType: "number",
  //       inputType: "zip",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",

  //       },

  //     },
  //     // {
  //     //     id: 11,
  //     //     heading: "Please Choose Your Prescription Plan",
  //     //     name: "choose_prescription_plan",
  //     //     className: 'form_radio',
  //     //     values: [{ key: 'Family ($31.99 per mo.)', val: 'Family ($31.99 per mo.)' }, { key: 'Single +1 ($26.99 per mo.)', val: 'Single +1 ($26.99 per mo.)' }, { key: 'Single ($21.99 per mo.)', val: 'Single ($21.99 per mo.)' }],
  //     //     type: 'radio',
  //     //     // hint: 'Yes/No',
  //     //     // rules: { required: true },
  //     //     // errorMessage: {
  //     //     //   required: "This Field is Required",
  //     //     //   custom: "Value is Invalid"
  //     //     // },
  //     //     // defaultValue: "No"
  //     // }
  //     {
  //       id: 125,
  //       // heading: "Captcha",
  //       // label: "Captcha",
  //       name: "captcha",
  //       className: 'formfild formfild4 reCaptcha',
  //       type: "captcha",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "Prove, You're Not a Robot",
  //         custom: "Value is Invalid"
  //       },
  //     },

  //   ]

  // };
  return (
    <>
      <div className="lp20_main">
        <div className="landingPage20_main_wrapper">
          <div className="landingPage20_main_header_wrapper">
            <div className="landingPage20_main_header_common_wrapper">
              <div className="landingPage20_block1_header_wrapper">
                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/banner/landingpage20-aspire_logo.webp" />
                <div className="landingPage20_block1_textfild_button">
                  {/* <TextField type="text" placeholder="Enter A Drug Name" /> */}
                  {/* <TextField
                    id="outlined-basic"
                    label="Enter A Drug Name"
                    variant="outlined"
                  /> */}
                  <Button onClick={() => setOpen(true)}>VIEW THE FULL DRUG LIST </Button>
                </div>
              </div>
            </div>
            <div   id="classGrp" className="landingPage20_block2_header">
              <div className="landingPage20_main_header_common_wrapper">
                <div className="landingPage20_block2_header_wrapper">
                  <div className="landingPage20_block2_header_text">
                    <h1>
                      Are you paying too much for your diabetes medication?
                    </h1>
                    <p>
                      <span>You are</span> if you are paying more than{" "}
                      <span> $21.99</span> for yourself! Or more than
                      <span> $31.99</span> your family!
                    </p>
                    <h2>
                      ALL Chronic Medications including Diabetic Medications
                      listed below are Free! For a 90-Day Supply with
                      Membership! No coupons, no gimmicks, and no fake prices.
                    </h2>
                    <h3>
                      Subscribe Today for As Low As<span> $21.99.</span>{" "}
                    </h3>
                  </div>
                  <div className='Lp14block1_sub_right_wrapper'>
                    {play && <ReactPlayer className='page5_react_player'
                      // url='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/AspirePharma_banner_video.mp4'
                      url={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}AspirePharma_banner_video.mp4`}
                      // light="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_1block_2bg.webp"
                      // playing={true}
                      controls={true}
                      // onPlay={videoPlay()}
                      onPause={() => {

                        console.log("pause hit");
                        // setTimeout(() => {
                        // if (seek == 1) {
                        // console.log("pause hit in intervel",seek);
                        // SetPlay(false)
                        // let val = pause++
                        setPause(2)
                        // }
                        // }, 2000)
                      }
                      }
                      // autoplay={true}
                      // muted={true}
                      onSeek={() => {
                        // console.log("seek hit",seek)
                        setSeek(2)
                        // SetPlay(true)
                        // setTimeout(() => {
                        //   console.log("seek hit Interval",seek)
                        //   setSeek(1)
                        // }, 4000);
                      }}
                      playing={play}
                    />}
                    {!play &&
                      <>
                        <div className='SD_video_img'>
                          {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_1block_2bg.webp' /> */}
                          <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_1block_2bg.webp`} />
                        </div>
                        <div id="playBtn" onClick={() => videoPlay()} className='Lp14block1_sub_sec2_wrapper'>
                          <div className='Lp14block1_sub_sec2_wrapper_ply_btn'>
                            <PlayCircleOutlineIcon />
                          </div>
                          <button>Play Video</button>
                        </div></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lp20_block1_main">
            <div className="landingpage20_common_width">
              <div className="lp20_block1_sub_wrapper">
                <div className="lp20_block1_sub_wrapper_text">
                  <p>
                    OVER A{" "}
                    <span>
                      MILLION PEOPLE ARE USING OUR ONLINE SUBSCRIPTION
                    </span>{" "}
                    SERVICE TO GET THEIR <span>PRESCRIPTIONS FOR FREE</span>.
                  </p>
                </div>
              </div>
              <div className="lp20_block2_wrapper">
                <div className="ip20_block2_sub1_wrapper">
                  <div className="ip20_block2_sub1_img">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block2/lp20_block2_img.webp" />
                  </div>
                  <div className="ip20_block2_sub1_text">
                    <h1>Are you covered by insurance?</h1>
                    <p>
                      For diabetes patients<span> covered by insurance</span>,
                      insulin, co-pays and first line{" "}
                      <span>
                        diabetes drugs can cost $200 per month or more
                      </span>
                      . Insulin pumps and blood glucose meters can add hundreds
                      or thousands a year.
                    </p>
                  </div>
                </div>
                <div className="ip20_block2_sub1_wrapper">
                  <div className="ip20_block2_sub2_img">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block2/lp20_block2_heart_img.webp" />
                  </div>
                  <div className="ip20_block2_sub2_text">
                    <h1>Don’t have insurance?</h1>
                    <p>
                      For diabetes patients
                      <span>
                        {" "}
                        <span>not covered by insurance</span>, diabetes
                        medications, and insulin can cost $200-500 or more per
                        month
                      </span>
                      . Insulin pumps and blood glucose meter devices can add
                      thousands a year.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lp20_block2_main">
            <div className="landingpage20_common_width">
              <div className="lp20_block2_main_wrapper">
                <div className="lp20_block2_text_from">
                  <div className="lp20_block2_text">
                    <h1>
                      We offer <span>605</span> of the most prescribed
                      medications,{" "}
                    </h1>
                    <p>
                      including 125 acute need medications in their high-quality
                      generic formulas
                    </p>
                    <h1>
                      <span>93%</span> of all medications on the market are in
                      our formulary.
                    </h1>
                    <Button>CHECK PRESCRIPTION COVERAGE</Button>
                    <div className="lp20_block2_sub1_text">
                      <p>
                        If you don’t find your generic drug on the list, contact
                        <span> 800.863.8926</span>, and one of our licensed
                        pharmacists will contact your doctor to find a
                        therapeutically similar one.
                      </p>
                    </div>
                  </div>
                  <div className="lp20_block2_form" ref={scrollToview}>
                    {/* <Form formData={formDataL2} /> */}
                    {/* {loading || newloader ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)} */}
                    <LandingPage13Form formheader={"Complete the form to enroll AND START SAVING TODAY"} submittext={"Select your subscription on the next step"} />
                  </div>
                </div>
                <div className="lp20_block2_sub2_text">
                  <p>
                    For the FIRST TIME, you can pay as low as
                    <span>$21.99 for ALL your Monthly</span> Prescription
                    Medication, saving YOU hundreds of dollars monthly and
                    thousands of dollars annually. It doesn't matter if you take
                    one or ten prescribed medications monthly. ALL your
                    medications can now be as low as
                    <span>$21.99 by subscribing</span> to our Free Pharmacy
                    Program.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lp20_block3_main">
            <div className="landingpage20_common_width">
              <div className="lp20_block3_main_wrapper">
                <h3>Pricing</h3>
                <div className="lp20_block3_sub_wrapper">
                  <div className="lp20_block3_sub">
                    <div className="lp20_block3_sub_img">
                      <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block2/lp20-block3-sub1-img.webp" />
                    </div>
                    <div className="lp20_block3_sub_text">
                      <h1>Individual</h1>
                      <h2>$21.99</h2>
                      <p> For one individual member.</p>
                    </div>
                  </div>
                  <div className="lp20_block3_sub">
                    <div className="lp20_block3_sub_img">
                      <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block2/lp20-block3-sub2-img.webp" />
                    </div>
                    <div className="lp20_block3_sub_text">
                      <h1>2 Person Family</h1>
                      <h2>$26.99</h2>
                      <p>
                        Easily add on one additional household family member.
                      </p>
                    </div>
                  </div>
                  <div className="lp20_block3_sub">
                    <div className="lp20_block3_sub_img">
                      <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block2/lp20-block3-sub3-img.webp" />
                    </div>
                    <div className="lp20_block3_sub_text">
                      <h1>Family Plan</h1>
                      <h2>$31.99</h2>
                      <p>For the entire family.</p>
                    </div>
                  </div>
                </div>
                <Button onClick={setScroll}>Enroll Today</Button>
              </div>
            </div>
          </div>

          <div className="lp20_block4_main">
            <div className="landingpage20_common_width">
              <div className="lp20_block4_main_wrapper">
                <div className="lp20_block4_sub_wrapper_text">
                  <p>
                    GET A <span>FREE</span> STARTER KIT AND DIABETIC
                    PRESCRIPTIONS!
                  </p>
                </div>
              </div>
              <div className="lp20_block4_sub_wrapper">
                <div className="lp20_block4_sub1_left">
                  <h1>The Program Includes</h1>
                  <ul>
                    <li>A FREE Premium bilingual voice-response glucometer</li>
                    <li>3-month supply of test strips</li>
                    <li>
                      Supply of lancets. lancing device, and control 180
                      solution
                    </li>
                    <li>No out-of-pocket on diabetes medications</li>
                    <li>FREE shipping to your mailbox</li>
                  </ul>
                </div>
                <div className="lp20_block4_sub1_right">
                  <div className="Lp20_block4_table_main_wrpr">
                    <div className="Lp20_block4_table_head">
                      <h1>DIABETES MEDICATIONS ON OUR FORMULARY</h1>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Medication</th>
                          <th>Strength</th>
                          <th>Qty Limit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Acarbose</td>
                          <td>50mg</td>
                          <td>270</td>
                        </tr>
                        <tr>
                          <td>Glimepiride</td>
                          <td>1mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glimepiride</td>
                          <td>2mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glimepiride</td>
                          <td>4mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Glipizide/Metformin</td>
                          <td>2.5/500mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Glipizide/Metformin</td>
                          <td>5/500mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Glipizide</td>
                          <td>2.5mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glipizide</td>
                          <td>5mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glipizide</td>
                          <td>10mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glipizide ER</td>
                          <td>2.5mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glipizide ER</td>
                          <td>5mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glipizide ER</td>
                          <td>10mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Glyburide (Non-micronized)</td>
                          <td>2.5mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glyburide (Non-micronized)</td>
                          <td>5mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Glyburide/Metformin</td>
                          <td>2.5/500mg</td>
                          <td>360</td>
                        </tr>{" "}
                        <tr>
                          <td>Glyburide/Metformin</td>
                          <td>5/500mg</td>
                          <td>360</td>
                        </tr>
                        <tr>
                          <td>Metformin ER 24HR</td>
                          <td>500mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Metformin ER 24HR</td>
                          <td>750mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Metformin (IR)</td>
                          <td>500mg</td>
                          <td>450</td>
                        </tr>
                        <tr>
                          <td>Metformin</td>
                          <td>850mg</td>
                          <td>270</td>
                        </tr>
                        <tr>
                          <td>Metformin</td>
                          <td>1000mg</td>
                          <td>180</td>
                        </tr>
                        <tr>
                          <td>Pioglitazone</td>
                          <td>30mg</td>
                          <td>90</td>
                        </tr>
                        <tr>
                          <td>Pioglitazone</td>
                          <td>45mg</td>
                          <td>90</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="Lp20_block4_table_tag">
                      <p>
                        These Medications Will Be FREE With Your Membership and
                        a Valid Prescription!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Lp20_block5_main_wrapper">
            <div className="landingpage20_common_width">
              <div className="Lp20_block5_sub_wrapper">
                <div className="Lp20_block5_sub_wrapper_text">
                  <h1>The Access Discount Healthcare Assurance Plan</h1>
                  <p>The process is easy and simple</p>
                </div>
                <div className="Lp20_block5_box_wrapper">
                  <div className="Lp20_block5_main_box">
                    <div className="Lp20_block5_box">
                      <div className="Lp20_block5_box1_img">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block5/Lp20_block5_box1_img.webp" />
                      </div>
                      <div className="Lp20_block5_box_text">
                        <p>
                          Look up your prescriptions here, and if they are on
                          the list, pick the subscription plan that works for
                          you and transfer the prescriptions.
                        </p>
                      </div>
                      <div className="lp20_block5_button">
                        <Button onClick={() => setOpen(true)}>CHECK DRUG LIST</Button>
                      </div>
                    </div>
                    <div className="Lp20_block5_box">
                      <div className="Lp20_block5_box1_img">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block5/Lp20_block5_box2_img.webp" />
                      </div>
                      <div className="Lp20_block5_box_text">
                        <p>
                          We will ship any of the 480 maintenance drugs directly
                          to you with free shipping, or you can pick up any of
                          our 125 acute-need medications at one of the 64,000
                          pharmacies nationwide.
                        </p>
                      </div>
                    </div>
                    <div className="Lp20_block5_box">
                      <div className="Lp20_block5_box1_img">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block5/Lp20_block5_box3_img.webp" />
                      </div>
                      <div className="Lp20_block5_box_text">
                        <p>
                          Rest easy knowing you are getting the prescriptions
                          you need for free as well as other health care items
                          like Ibuprofen, or test strips at a significant
                          discount.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Lp20_block6_main_wrapper">
            <div className="landingpage20_common_width">
              <div className="Lp20_block6_sub_wrapper">
                <div className="Lp20_block6_box">
                  <div className="lp20_block6_sub1_img">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block6/lp20_block6_sub1.webp" />
                  </div>
                  <div className="Lp20_block6_box_text">
                    <p> No limit on orders/refills</p>
                  </div>
                </div>
                <div className="Lp20_block6_box">
                  <div className="Lp20_block6_sub2_img">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block6/Lp20_block6_sub2.webp" />
                  </div>
                  <div className="Lp20_block6_box_text">
                    <p>Add new covered medications anytime</p>
                  </div>
                </div>
                <div className="Lp20_block6_box">
                  <div className="Lp20_block6_sub3_img">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block6/Lp20_block6_sub3.webp" />
                  </div>
                  <div className="Lp20_block6_box_text">
                    <p> Licensed in all 50 US States</p>
                  </div>
                </div>
                <div className="Lp20_block6_box">
                  <div className=" Lp20_block6_sub4_img ">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block6/Lp20_block6_sub4.webp" />
                  </div>
                  <div className="Lp20_block6_box_text">
                    <p>
                      Licensed Pharmacists & Experienced Customer Service Team
                    </p>
                  </div>
                </div>
              </div>
              <div className="lp20_block6_button">
                <Button onClick={setScroll}>Enroll Today</Button>
              </div>
            </div>
          </div>
          <div className="Lp20_block7_main_wrapper">
            <div className="landingpage20_common_width">
              <div className="Lp20_block7_sub_wrapper">
                <div className="Lp20_block7_sub_sec1_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/LP20_delivery_man_pic.webp" />
                </div>
                <div className="Lp20_block7_sub_sec2_wrapper">
                  <h1>
                    SHIPPING IS FREE IN <span>2-4 BUSINESS DAYS</span>, OR IF
                    YOU HAVE IMMEDIATE NEEDS TO PICK UP YOUR PRESCRIPTIONS
                    BEFORE OUR SHIPMENT DATE OR FOR ACUTE
                    MEDICATIONS, YOU CAN GO TO ONE OF THE <span>
                      64,000
                    </span>{" "}
                    PHARMACIES NATIONWIDE, INCLUDING:
                  </h1>
                  <p>
                    All packages that leave our facility are scanned and tracked
                    through USPS’ state-of-the-art tracking system so that you
                    always know where your package is until it arrives at your
                    door.
                  </p>
                </div>
              </div>
              <div className="Lp20_block7_sub1_wrapper">
                <div className="Lp20_block7_sub1_sec_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/lp20_bl7_1logo.webp" />
                </div>
                <div className="Lp20_block7_sub1_sec_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/lp20_bl7_2logo.webp" />
                </div>
                <div className="Lp20_block7_sub1_sec_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/lp20_bl7_3logo.webp" />
                </div>
                <div className="Lp20_block7_sub1_sec_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/lp20_bl7_4logo.webp" />
                </div>
                <div className="Lp20_block7_sub1_sec_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/lp20_bl7_5logo.webp" />
                </div>
              </div>
              <div className="lp20_block7_textfild_button">
                <div className="lp20_block7_textfild">
                  {/* <TextField type="text" placeholder="Enter A Drug Name" /> */}
                  {/* <TextField
                    id="outlined-basic"
                    label="Enter A Drug Name"
                    variant="outlined"
                  /> */}
                </div>
                <div className="lp20_block7_button">
                  <Button onClick={setScroll}>Enroll Today</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="Lp20_block_footer_main">
            <div className="landingpage20_common_width">
              <div className="Lp20_block_footer_sub_wrapper">
                <div className="Lp20_block_footer_img">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/block7/lp20_footer_logo.webp" />
                </div>
                <div className="Lp20_block_footer_text_button">
                  <div className="Lp20_block_footer_text">
                    <h5>THIS IS NOT INSURANCE </h5>
                    <p>Questions? Please contact our Customer</p>
                    <h1>
                      Support Team at <span><a href='tel:1-800-378-4025'>1-800-378-4025</a></span>
                    </h1>
                  </div>

                  {/* <Button>Enrollment Code: "AS00491"</Button> */}
                  <div className="Lp20_block_footer_text_Enrollment">
                    <p>Enrollment Code: "AS00491"</p> </div>
                </div>
              </div>
              <div className="Lp20_block_footer_sub1_wrapper">
                <p>2023 Access Discount Healthcare Rx All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div>
        <Modal
          className="modalBaseStyle allMeetingMOdal  MOdallanding13 SD_MOdallanding SR_ClassModal lpage19_modal sr_toggle_btn MOdallanding20"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={handleClose}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <div className="rolelist_mainwrp">

                <div className='listing_heading'>

                  <h1>Access Free Pharmacy Medicine</h1>

                </div>


                {/* <div className="bluecoastLead_list_mainwrpr">
                  <div className='click_buttonwp navigationMain'>

                    <Button
                      id="buttonthree"
                      className={menuToggle === 1 ? "active" : ""}
                      variant="contained"
                      onClick={() => menuToggleSelected(1)}
                    >
                      Free Medicine List
                    </Button>

                    <Button
                      id="buttonthree"
                      className={menuToggle === 2 ? "active" : ""}
                      variant="contained"
                      onClick={() => menuToggleSelected(2)}
                    >
                      All Drug List
                    </Button>
                  </div>
                </div> */}


                {/* <Paper>
  <Button variant='contained' className='addrole_btn' ></Button>
</Paper> */}
                <div className='rolelist_mainwrp_Table'>
                  {!loadingInMedCat ? (
                    <ListingTable
                      tableData={tableDataForMedCatlist.current}
                      dataset={medcat}
                      modifyHeaders={modifyTableHeadersforMedCatlist}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}

                </div>
              </div>
            </div>

          </Box>
        </Modal>
      </div>
    </>
  );
}
