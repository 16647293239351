import { IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { resolvefunction } from '../../../../../helper/helperFunctions';
import PopupModal from '../../../../../commoncomponents/modal/popupmodal';
import CloseIcon from "@mui/icons-material/Close";
import { clearcommisDeleteStatus, commisdelite, commissiondelite, commissionList, commissionSingleFetchData, commissionStatusUpdate, commisStatusUpdate, getCommissionConfigList, getCommissionSingalData } from '../../commissionReducer';
import ConfirmationModal from '../../../../../commoncomponents/modal/confirmationModal';
import { setReloadTableFlag } from '../../../../../listing/listReducer';
import MultiStatusChangeModal from '../../../../../commoncomponents/modal/multipleStatusChnageModal';

const AddCommissionList = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [popupToogle, setPopupToogle] = useState(false);  //--- Toogle ----//
  const [perviwModel, setPerviwModel] = useState(false);  //--- Toogle ----//
  const [open, setOpen] = useState(false);//----snack bar state-----
  const [errPopup, setErrPopup] = useState(false); // snackbar const
  const [snackMsgCopy, setSnackMsgCopy] = useState(null); // snackbar const
  const [toogle, setToogle] = useState(false);  //--- Toogle ----//
  const [confrimationMsg, setConfrimationMsg] = useState(null);
  const [btnType, setBtnType] = useState("");  //--- ButtonType Set  ----//
  const [modalResponse, setModalResponse] = useState(false);
  const [dataRef, setDataRef] = useState({})
  const [statusChangeModel, setStatusChangeModel] = useState(false)//---------state for status model
  const [statusConfrimationMsg, setStatusConfrimationMsg] = useState("")//--state for status confirmation message
  const [userStatus, setUserstatus] = useState(null)//state for status userStatus
  const [modelDelete,setModelDelete]=useState(false)
  //-----------------------------------Status Change Button Click-------------------------
  const statusChangeClick = useSelector(state => (state.tableSlice.customButtonClicked?.commissionListing && state.tableSlice.customButtonClicked?.commissionListing?.change_status1) ? state.tableSlice.customButtonClicked.commissionListing.change_status1 : false);
  // console.log("statusChangeClick",statusChangeClick);

  //--------------------------------------------------------------------------------------

  //----------------------------------for snack bar data---------------------------------
  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.commissionConfigForm) ? state.formSlice.formSubmissionState.commissionConfigForm : false);
  const snackMsg = useSelector(state => (state.formSlice?.message?.commissionConfigForm) ? state.formSlice.message.commissionConfigForm : null);
  //----------------------------------------------------------------------------------------

  //----------------------------------edit button fetch-------------------------------------------
  const commissionEditClick = useSelector(state => (state.tableSlice.customButtonClicked?.commissionListing && state.tableSlice.customButtonClicked?.commissionListing?.edittable9) ? state.tableSlice.customButtonClicked.commissionListing.edittable9 : false);

  //----------------------------------Preview button fetch------------------------------------------
  const previewClick = useSelector(state => (state.tableSlice.customButtonClicked?.commissionListing && state.tableSlice.customButtonClicked?.commissionListing?.previewBtnAction) ? state.tableSlice.customButtonClicked.commissionListing.previewBtnAction : false);


  //----------------------------------Deleted button fetch-------------------------------------------

  const deleteClick = useSelector(state => (state.tableSlice.customButtonClicked?.commissionListing && state.tableSlice.customButtonClicked?.commissionListing?.delete01) ? state.tableSlice.customButtonClicked.commissionListing.delete01 : false);
  const deleteMultipleClick = useSelector(state => (state.tableSlice.customButtonClicked?.commissionListing && state.tableSlice.customButtonClicked?.commissionListing?.deleteMultiple) ? state.tableSlice.customButtonClicked.commissionListing.deleteMultiple : false);
  const deletsnackmsg = useSelector(state => (state.commissionReducer?.snakebarData) ? state.commissionReducer.snakebarData : {});
  // console.log("deletsnackmsg", deletsnackmsg);
  //=====================================================================================

  //-------------------fetch data for commission list------------------------

  const listData = useSelector((state) => state.commissionReducer?.commisionDataForListing ? state.commissionReducer.commisionDataForListing : [])
   const commisDeleteStatus = useSelector((state) => state.commissionReducer?.commisDeleteStatus ? state.commissionReducer.commisDeleteStatus : null)
  //  console.log("clearcommisDeleteStatus",commisDeleteStatus);

  const loadinglist = useSelector((state) => state.commissionReducer?.loadinglist ? state.commissionReducer.loadinglist : false)

  //---------------------------fetch commission row data---------------------
  const commissionRowData = useSelector((state) => state?.tableSlice?.tempRowData?.commissionListing ? state.tableSlice.tempRowData.commissionListing : {})

  // console.log("commision listion row data",commissionRowData);


  const commissionFetchData = useSelector((state) => state.commissionReducer?.commissionSingleData ? state.commissionReducer.commissionSingleData : {})



  // console.log("commissionRowData", commissionRowData);
  //=========================================================================



  //--------------Get Commission List--------------------
  useEffect(() => {
    dispatch(commissionList())
  }, [])
  //--------------------------------------------------------

  //------------------------------preview model configration---------------------

  useEffect(() => {
    if (previewClick === true) {
      dispatch(commissionSingleFetchData({"_id":commissionRowData._id}));
      setDataRef(commissionRowData)
      setPopupToogle(true)
      setPerviwModel(true)
      dispatch(setReloadTableFlag({ tableId: "configurCommission" }))
    }
  }, [previewClick])


  useEffect(() => {
   if(Object.keys(commissionFetchData)>0){
    setPopupToogle(true)
    setPerviwModel(true)
   }
  }, [commissionFetchData])
  

  //============================================================================



  //--------------------Commission Edit from configration-------------------------------

  useEffect(() => {
    if (commissionEditClick) {
      resolvefunction(navigate, "/add-commission?id=" + commissionRowData._id,  commissionSingleFetchData({"_id":commissionRowData._id}))
    }
  }, [commissionEditClick])


  // 
  //------------------------------------------------------------------------------------


  //--------------Snack Bar configration--------------------

  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrPopup(false);
    setSnackMsgCopy(null);
  };


  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );



  //---- based on endpoint call ---


  useEffect(() => {
    if (endpointProcessState == 1) {
      setOpen(false);
    }

    else if (endpointProcessState == 2) {
      setOpen(true);
      setSnackMsgCopy(snackMsg ? snackMsg : deletsnackmsg.message);

    }
    else if (endpointProcessState == 3) {

      setSnackMsgCopy(snackMsg);
      setErrPopup(true)

    }

  }, [endpointProcessState])


  //======================================================


  // -------  Delte icon Click configration ------------------------ 
  useEffect(() => {
    if (modalResponse == true) {

      let body = {
        "_id": [dataRef._id],
        "secret": "AZ|lepL`",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
      }
      dispatch(commisdelite(body))


    }
  }, [modalResponse])

  useEffect(() => {
    if (deleteClick === true) {
      setBtnType("dltBtn")
      setConfrimationMsg("Are you sure you want to delete this  record? This can't be undone.")
      setToogle(true)
      setDataRef(commissionRowData)

    }
  }, [deleteClick])

  useEffect(() => {
    if (deletsnackmsg.status === "success") {
      setSnackMsgCopy(deletsnackmsg.message)
      setOpen(true)
      setToogle(false)

    }

  }, [deletsnackmsg])

  useEffect(()=>()=>{
    if(commisDeleteStatus==="success"){
   
      dispatch(setReloadTableFlag({ tableId: "commissionListing" }))
   
    
    }
    setTimeout(()=>{
     dispatch(clearcommisDeleteStatus())
    },1000)
  },[commisDeleteStatus])


  // --------------------------------


  //-------------------------Status Change Configration--------------------------------

  useEffect(() => {
    if (statusChangeClick === true) {
      setStatusConfrimationMsg("Are you sure you that want to change the status?");
      setStatusChangeModel(true)
      setDataRef(commissionRowData)
    }
  }, [statusChangeClick])


  useEffect(() => {
  
    if ((userStatus !== null && userStatus !== undefined)) {



      let body = {
        "_id": [dataRef._id],
        "status": userStatus,
        "secret": "AZ|lepL`",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
      }
      dispatch(commisStatusUpdate(body))

      setStatusChangeModel(false)
      setTimeout(() => {
        dispatch(setReloadTableFlag({ tableId: "commissionListing" }))
      }, 1000)

    }

  }, [userStatus]);

  // useEffect(() => {
  //   if ((userStatus !== null && userStatus !== undefined)) {
  //     dispatch(setReloadTableFlag({ tableId: "commissionListing" }))

  //   }
  // }, [userStatus])

  // console.log("commission userstatus", userStatus);


  //-----------------------------------------------------------------------------------

  //-----------------------------------------------------------------------------------
  
  



  // ----- Table list for user -----
  var modifyTableHeaders =
    [
      { val: "lead_name", name: "Lead" },
      { val: "hm_name", name: "Associated HM" },
      { val: "rep_name", name: "Associated AE" },
      { val: "programe_name", name: "Programme" },
      { val: "commission_amount", name: "Payout  Amount" },
      { val: "conversion_datetime", name: "Conversion Time", type: "datetime", format: "MM/DD/YYYY hh:mm:ss"},
      { val: "createdon_datetime", name: "Commission Added Time", type: "datetime", format: "MM/DD/YYYY hh:mm:ss"},
      { val: "status", customVal: { '1': "Active", '0': "Inactive", "true": "Active", 'false': "Inactive" }, name: "Status" },


    ];

  let tableCommissionListing = useRef({
    tableId: "commissionListing",
    tableTitle: "",
    enableRowActionLoader: true,
    showReload: true,
    showFilter: true,
    adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "commission/commission-list-count",
      tableCountEndpoint: "commission/commission-list-count",
      deleteSingleUserEndpoint: "commission/delete",
    },

    reqBody: {

      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "count": false,

    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: "",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: "",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["name", "type", "login_time", "createdon_datetime", "status", "email"],
    paginationType: "stackPagination",
    // customSort: true,
    customBtnHead: [
      {
        id: "deleteMultiple",
        type: "delete_icon",
        title: "Delete",
      },
      {
        id: "editMultiple",
        type: "toggle_on_icon",
        title: "Status Change",
      },

      {
        id: "multi_payout_btn",
        title: "Payout",
        type: "paid_icon",
        className: "forward_to_inbox_two_tone_icon",

      },


    ],
    buttons: [

    

      {
        id: "payout_btn",
        type: "other",
        icon_type: "paid_icon",
        label: "Payout",
        name: "paid_icon",
      },


      {
        id: "edittable9",
        type: "other",
        icon_type: "edit",
        label: "Edit",
        name: "edit",
      },


      {
        id: "change_status1",
        type: "other",
        label: "Change Status",
        name: " toggle_off ",
      },



      {
        id: "previewBtnAction",
        type: "other",
        name: "preview",
        label: "Preview",
      },

      {
        id: "delete01",
        type: "other",
        name: "delete_icon_icon",
        label: "Delete",
      },





      //  ########################### Notes ########################################
      {
        id: "notesBadge",// for unique id of button//
        label: "Notes",//on hover show//
        type: "note",//****button category */
        icon_type: "summarize_icon",//for the button icon from mui icons  https://mui.com/material-ui/material-icons///
        name: "note_inbuilt",//as your wish///
        className: "forminline",//for modal top level tag class name//
        count_key: 'notescount',//count of notes, view in badge, value should be in row data object//
        notes_for_key: "name",//modal title notes for dynamic data, value should be in row data object
        noteaddbaseurl: process.env.REACT_APP_API_URL,//for end point base path//
        noteaddendpoint: "note/add-note",//final path of end point//
        noteaddbody: {//default body to add notes//
          "data": {
            "notes_by": userInfo?.userinfo?._id,
            "collection_name": 'users'
          }
        },
        body: {
          "source": "", "condition": { "limit": 5, "skip": 0 },
          "sort": { "type": "desc", "field": "assigned_datetime" }, "searchcondition": {}
        },//for initial listing end point call body//
        tableData: {//config for notes table in side modal like normal table config//
          tableId: "notesTable1",
          mainTableId: "userslist1",
          tableTitle: " Notes Table",
          no_data_html: "<p>No Data Found </p>",
          payload_key: "notes_for",
          showReload: true,
          showFilter: true,
          api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "note/note-list-count",
            deleteSingleUserEndpoint: "note/delete-notes",
            tableCountEndpoint: "note/note-list-count",
          },
          reqBody: {
            condition: {
              limit: 5,
              skip: 0,
            },
            searchcondition: {},
            sort: {
              type: "desc",
              field: "createdon_datetime",
            },
          },
          deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
          rowsPerPageOptions: [5, 10, 20, 30],
          sortFields: ["notesby_names", "createdon_datetime", "notes"],
          paginationType: "stackPagination", ////////////////////// By Default Rows per page option will be available////////////,
          customBtnHead: [
            {
              id: "delete",
              label: "Delete",
              type: "api",
              category: "modal",
              condField: "notes_by",
              condVal: userInfo?.userinfo._id,
              icon_type: "delete",
              className: "delete_data",
              modalClassName: "delete_modal",
              modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
              modalClassName: "delete_modal",
              modalHeader: "<p> Alert !!</p>",
              btn_label: ['Yes', "No"],
              api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "note/delete-notes",
                body: {
                  "source": "users",
                  "secret": "AZ|lepL`",
                  "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                }
              }
            }

          ],
          buttons: [


            {
              id: "delete",
              label: "Delete",
              type: "api",
              category: "modal",
              condField: "notes_by",
              condVal: userInfo?.userinfo._id,
              icon_type: "delete",
              className: "delete_data",
              modalClassName: "delete_modal",
              modalHeader: "<p> Alert !!</p>",
              modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
              btn_label: ['Yes', "No"],
              api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "note/delete-notes",
                body: {
                  "source": "users",
                  "secret": "AZ|lepL`",
                  "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                }
              }
            },

          ],
          deleteModal: {
            modalClassName: "Delete Note",
          },

          searchBarData: {
            formWrapperClass: "backendTableWrapper",// Can be undefined default will be formCls
            formInlineCLass: "backendTableInline test_one", // Can be undefined default will be formInline
            formButtonClass: "submitbuttonwrapper test searchRefreshIconBtn",
            endPointBodyWrapper: "searchcondition",
            heading: "Search Notes",
            class: "draWerp",

            api: {
              url: process.env.REACT_APP_API_URL,
              endPoint: "note/note-list-count",
              tableCountEndpoint: "note/note-list-count",
              reqBody: {
                source: "",
                condition: {
                  'limit': 5,
                  'skip': 0

                },
                sort: {
                  type: "desc",
                  field: "_id",
                },
                "searchcondition": {

                  // "notes_by": userInfo?.userinfo._id
                }

              },
            },
            searchSettings: {
              datesearch: [
                {
                  id: 0,
                  // heading: "Search By Joining Date",
                  startdatelabel: "Added On",
                  enddatelabel: "End Date",
                  fullDay: true,
                  className: "inputblock4line",
                  field: "createdon_datetime",
                },
              ],

              textsearch: [
                {
                  id: 1,
                  //   heading: "Search By Note",
                  label: "Search By Note",
                  field: "notes",
                  className: "inputblock4line",
                  value: "",
                },




              ],
              autoCompleteSearch: [
                {
                  id: 5,
                  label: "Search Added By",
                  field: "added_name",
                  values: "",
                  multiple: false,
                  preload: true,
                  className: 'form_item list_search_item countDiv__column--col6 "',
                  serverSearchData: {

                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "api/autocompletenotestitle",
                    reqBody: {
                      "condition": {
                        "limit": 10,
                        "skip": 0
                      },
                      "sort": {
                        "type": "desc",
                        "field": "_id"
                      },
                      "searchcondition": {}
                    },
                  },
                },



              ],

              selectsearch: [



              ],
            },
          },

        },
        headers: [
          { val: "notes", name: "Note" },
          { val: "added_name", name: "Added By" },

          { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss" },
        ]
      },

    ],
    deleteModal: {
      modalClassName: "Delete Modal Deletemodal",
    },

    searchData: {
      heading: "",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        resetBtnName: "REFRESH",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [





          {
            id: 9,
            label: "Search by Source",
            name: "added_type",
            type: 'select',
            multiple: true,
            values: [{ val: 'landing_page', name: 'Landing page' }],
            sx: { m: 1, minWidth: 120 },
            className: 'inputblock2line',
            payloadFormat: { key: "added_type", param: "$in", options: "i" },

          },



          {
            id: 10,
            label: "Search by Agent Code",
            name: "agent_code",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "",
            reqBody: {
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {

              },
              "source": "users"
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },



          {
            id: 11,
            label: "Search by Start Date",
            name: "start_date",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: 'inputblock2line',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 12,
            label: "Search by End Date",
            name: "end_date",
            className: 'inputblock2line',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },


        ]
      }
    },

  });
  // -----------------------




  return (
    <div>
      <div className='rolelist_mainwrp'>

        <div className='listing_heading'>

          <h1>  Commission Listing</h1>


          <Tooltip title="ADD USER">
            <button className='addrole_btn' onClick={() => navigate("/add-commission")} >
              <AddIcon />
            </button>
          </Tooltip>

        </div>
        <div className='rolelist_mainwrp'>
          {/* {loading && <LinearProgress/>} */}
          <div className="reactTableWrapper">
            <div className="rolelist_mainwrp_Table table_Icons ">
              {/* {console.warn("listData===============>>>>", listData)} */}
              {!loadinglist && listData ? (
                <ListingTable
                  tableData={tableCommissionListing.current}
                  dataset={listData}
                  modifyHeaders={modifyTableHeaders}
                />
              ) : (<TableSkeleton count={5} />)}

            </div>
          </div>
        </div>

        {/* ------- Popup Modal for preview user details ------------ */}
        {perviwModel === true && commissionRowData ?
          (<PopupModal
            popupdata={dataRef}
            popuptoogle={popupToogle}
            popuptooglefunc={setPopupToogle}
            type="CommissionleInfo"
          />) : ""}
        {/* ----------------- */}

        {/* ---- SnackBar on data Submisstion */}
        {endpointProcessState === 2 || deletsnackmsg ? (<Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={snackMsgCopy}
          action={action}
        />) : ""}

        {/* ------------ */}

        {/* ------Delete Confirmation Modal ------ */}
        {toogle ? (<ConfirmationModal className="confirmaModal"
          confrimationMsg={confrimationMsg}
          toogle={toogle}
          tooglefunc={setToogle}
          setModalResponse={setModalResponse}
          setModelDelete={setModelDelete}
        />) : ""}
        {/* -------------------------------- */}

        {/* -------------------Status Confirmation Modal--------------------------------- */}

        {statusChangeModel && <MultiStatusChangeModal
          className="confirmaModal"
          confrimationMsg={statusConfrimationMsg}
          toogle={statusChangeModel}
          // deliteLeadModal={deliteLeadModal}
          tooglefunc={setStatusChangeModel}
          setModalResponse={setUserstatus}
        />}


        {/* ------------------------------------------------------------------------------------- */}

      </div>
    </div>
  )
}

export default AddCommissionList