import React, { useEffect, useState } from "react";
import { InputLabel, TextField, Icon, FormControl } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import store from "../../store";
import { resetFormFieldError, resetTempFormUpdateData } from "../formReducer";
import { useDispatch } from "react-redux";

export const Text = ({
  fielddata,
  value,
  setValue,
  getValues,
  errors,
  clearErrors,
  formId,
  setError,
}) => {
  const [val, setVal] = useState(null);
  const [customError, setCustomError] = useState(null);

  const dispatch = useDispatch();

  /////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, []);

  store.subscribe(() => {
    const storeData = store.getState();

    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    if (
      Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 &&
      storeData.formSlice.tempFormUpdateData[formId] !== undefined &&
      storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !==
        undefined
    ) {
      // console.log("storing====>");
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name]);
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (
      Object.keys(storeData.formSlice.tempFormFieldError).length > 0 &&
      storeData.formSlice.tempFormFieldError[formId] !== undefined &&
      storeData.formSlice.tempFormFieldError[formId][fielddata.name] !==
        undefined
    ) {
      // console.log("storing====>");
      setCustomError(
        storeData.formSlice.tempFormFieldError[formId][fielddata.name]
      );
    }
  });

  //////////////////////////////////////// Effect the field value update from outside //////////////////////////////
  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }));
    }
  }, [val]);

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      // console.log("customError", customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }));
    }
  }, [customError]);

  var filter = [];
  const keypadZero = 48;
  const numpadZero = 96;

  //add key codes for digits 0 - 9 into this filter
  for (var i = 0; i <= 9; i++) {
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);
  }

  //add other keys that might be needed for navigation
  //or for editing the keyboard input
  filter.push(8); //backspace
  filter.push(9); //tab
  filter.push(46); //delete
  filter.push(37); //left arrow
  filter.push(39); //right arrow

  /*******************************************************
   * onKeyDown(e)
   * when a key is pressed down, check if it is allowed
   * or not. If not allowed, prevent the key event
   * from propagating further
   *******************************************************/
  function onKeyDown(e) {
    // if (filter.indexOf(e.keyCode) < 0) {
    //   e.preventDefault();
    //   return false;
    // }


    // let  input = e.target.value;
    // let   result = input.replace(/\D/g,'');

    // if (result.length>10) {
    //   e.preventDefault();
    //   return true;
    // } else {
    //   return false;
    // }

  }
  // Formating Input text
  // function formatPhoneText(value) {
  //   value = value.trim().replaceAll("-", "");
  //   value = value.trim().replaceAll("(", "");
  //   value = value.trim().replaceAll(")", "");
  //   value = value.trim().replaceAll(" ", "");

  //   console.log("value============>", value, value.length);

  //   if (value.length > 3 && value.length <= 6)
  //     value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
  //   else if (value.length > 6 && value.length < 11) {
  //     value =
  //       "(" +
  //       value.slice(0, 3) +
  //       ")" +
  //       " " +
  //       value.slice(3, 6) +
  //       "-" +
  //       value.slice(6);
  //   } else if (value.length === 11 || value.length > 11) {
  //     console.log("value===>1", value);

  //     // value = "+"+ value.slice(0,1) + "(" + value.slice(1, 4) +")" +" " +value.slice(4, 7) +"-" +value.slice(7);
  //   }

  //   return value;
  // }

  // function formatPhoneNumber(phoneNumberString) {
  //   var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  //   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     var intlCode = match[1] ? "+1 " : "";
  //     console.log(
  //       "return=======>",
  //       [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  //     );
  //     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  //   }
  //   return phoneNumberString;
  // }

  // function validatePhone(p) {
  //   var phoneRe =
  //     /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

  //   var digits = p.replace(/\D/g, "");
  //   return phoneRe.test(digits);
  // }
  function onKeyUp(e) {
    console.log("keyup", e.target.value);
    var input = e.target;
    // var formatted = formatPhoneNumber(input.value);

    var cleanedv = ("" + e.target.value.trim()).replace(/\D/g, "");
    var matchv = cleanedv.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var formatted = e.target.value;
    if (matchv) {
      var intlCode = matchv[1] ? "+1 " : "";
      console.log(
        "return=======>",
        [intlCode, "(", matchv[2], ") ", matchv[3], "-", matchv[4]].join("")
      );
      formatted=[intlCode, "(", matchv[2], ") ", matchv[3], "-", matchv[4]].join("");
      setValue(fielddata.name, formatted);
      // setValue()
      // return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    // var isError = validatePhone(formatted) || formatted.length === 0;
    var isError = false;
    var color = isError ? "gray" : "red";
    var borderWidth = isError ? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  function setupPhoneFields(fielddata, value, className) {
    if (fielddata.name == "secondary_phone") {
      var input = document.querySelectorAll(".phoneField input")[1];
      // console.log("trytest", document.querySelectorAll(".phoneField input"));
    } else {
      var input = document.querySelectorAll(".phoneField input")[0];
      // console.log("trytest", document.querySelectorAll(".phoneField input"));
    }
    // console.log("input", input);
    input.addEventListener("keydown", onKeyDown);
    input.addEventListener("keyup", onKeyUp);
    // console.log("valueLength input", value);

    // setValue(fielddata.name, value);
  }

  //    function validCheck() {

  //     let  input = "1-205-330-2342";
  //     let   result = input.replace(/\D/g, '');

  //     console.log("result===========>",result);

  // }

  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && (
        <InputLabel>
          <div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div>
        </InputLabel>
      )}
      {/* FIELD */}
      <FormControl>
        <TextField
          autoComplete="new-password"
          type={
            fielddata.inputType !== undefined || fielddata.inputType !== ""
              ? fielddata.inputType
              : "text"
          }
          disabled={
            fielddata.disabled !== undefined ? fielddata.disabled : false
          }
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          className={fielddata.inputType === "phone" ? "phoneField" : ""}
          onChange={(e) => {
            if (fielddata.inputType === "number") {
              setValue(fielddata.name, parseInt(e.target.value));
              // console.log("fieldval===============>", Number(e.target.value));
            } else if (fielddata.inputType === "phone") {
              setValue(fielddata.name, e.target.value);
              setupPhoneFields(fielddata, e.target.value, "phoneField");
            } else {
              setValue(fielddata.name, e.target.value);
            }

            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          onKeyPress={(e) => {
            if (fielddata.inputType === "zip") {

              var charTyped = String.fromCharCode(e.which);
              var letterRegex = /[^0-9]/;

              if (charTyped.match(letterRegex)) {
                e.preventDefault();
                return true;
              } else {
                return false;
              }
            }

            if (fielddata.inputType === "phone") {


              var charTyped = String.fromCharCode(e.which);
              var letterRegex = /[^0-9]/;

              // console.log("letterRegex",letterRegex);
              if (charTyped.match(letterRegex)) {
                e.preventDefault();
                return true;
              } else {

                let  input = e.target.value;
                let   result = input.replace(/\D/g,'');
                if (result.length>10) {
                  e.preventDefault();
                  return true;
                } 

                return false;
              }


              // validCheck()
              let  input = e.target.value;
              let   result = input.replace(/\D/g,'');

              if (result.length>10) {
                e.preventDefault();
                return true;
              } else {
                return false;
              }

            }
          }}
          label={fielddata.label}
          error={
            Object.keys(errors).length > 0 && errors[fielddata.name] != null
          }
          helperText={
            errors[fielddata.name] && errors[fielddata.name].type
              ? fielddata.errorMessage[errors[fielddata.name].type]
              : ""
          }
          InputProps={
            fielddata.adornments !== undefined
              ? fielddata.adornments.position === "start"
                ? {
                    startAdornment: (
                      <InputAdornment position={fielddata.adornments.position}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: fielddata.adornments.content,
                          }}
                        ></div>
                      </InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position={fielddata.adornments.position}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: fielddata.adornments.content,
                          }}
                        ></div>
                      </InputAdornment>
                    ),
                  }
              : {}
          }
        />
      </FormControl>
    </div>
  );
};
