import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '../../../../form/Form'
import store from '../../../../store';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { interviewFormdata } from './folderViewReducer';
import './Interview.css'
import { fetchUserData } from '../fetchUserDataReducer';

function Interview({ id, data }) {
  const dispatch = useDispatch();

  const formsubmissionstate = useSelector(state => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);
  const successMsg = useSelector(state => state.formSlice.message?.InterviewAddEdit)//for snackbar massge
  const successStatus = useSelector(state => state.formSlice.status?.InterviewAddEdit)

  const interviewform = {

    id: 'InterviewAddEdit',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "users/name-interview-data-add-update",
    // urlPathOnSuccessfulFormSubmission: "",
    // submitBtnName: (editdata !== null) ? "Update" : "Submit",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    endPointBodyWrapper: "data",
    formAdditionalSubmissionData: {
      user_id: (id !== undefined && id !== null) ? id : undefined
    },

    fields: [
      {
        id: 0,
        heading: "First Name",
        name: "firstname",
        className: "inputBlock1line ",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "Fast Name is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.firstname !== undefined) ? data.firstname : '',

      },
      {
        id: 0,
        heading: "Last Name",
        name: "lastname",
        className: "inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "Last Name is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.lastname !== undefined) ? data.lastname : '',

      },

      {
        id: 1,
        heading: "Email",
        name: "email",
        className: "inputBlock1line",
        type: "text",
        // rules: { required: true },
        // // disabled: true,
        // errorMessage: {
        //   required: "Email is Required",
        // },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.email !== undefined) ? data.email : '',

      },

      {
        id: 2,
        heading: "Phone",
        name: "phone",
        className: 'inputBlock1line',
        type: "text",
        inputType: "phone",
        rules: { pattern: /^[^@#%&$]*$/ },
        errorMessage: {
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
        /////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        // rules: { required: true },
        // errorMessage: {
        //   required: "Phone No is Required",
        // },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.phone !== undefined) ? data.phone : '',


      },
      {
        id: 3,
        type: "html",
        className: "heading blue_text inputBlock1line",
        value: "<h1>Beginning of the Call:</h1>"
      },
      {
        id: 4,
        type: "html",
        className: "paragraph blue_text inputBlock1line",
        value: "<p>1. Relational/Small talk</p>"
      },
      // {
      //   id: 5,
      //   type: "html",
      //   className: "paragraph blue_text inputBlock1line",
      //   value: "<p>2. Confirm it is okay to record the call</p>"
      // },
      // {
      //   id: 6,
      //   type: "html",
      //   className: "paragraph blue_text inputBlock1line",
      //   value: "<p>3. Outline goal of call (get better acquainted so by end of call we know if we want to take the next steps together)</p>"
      // },
      {
        id: 6,
        type: "html",
        className: "paragraph blue_text inputBlock1line",
        value: "<p>3. Outline the goal of call (get better acquainted so that by the end of the call, we know if we want to take the next steps together)</p>"
      },
      {
        id: 27,
        type: "html",
        className: "heading inputBlock1line",
        value: "<h1>Part 1. Background - Tell me a little bit about yourself</h1>"
      },
      {
        id: 32,
        heading: "What's the most important thing you'd like me to know about you, either personally or professionally *",
        // label: "Address",
        name: "important_summary",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.important_summary !== undefined) ? data.important_summary : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 7,
        // heading: "Give me a quick 90 second summary of your background. What are you looking to do now? *",
        heading: "Give me a quick 90 second summary of your background. What have you been doing? What are you looking to do now? *",
        // label: "Address",
        name: "summary",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.summary !== undefined) ? data.summary : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 8,
        type: "html",
        className: "heading inputBlock1line",
        value: "<h1>Part 2. Why Aspire?</h1>"
      },
      {
        id: 9,
        heading: "Did you get a chance to review our website? *",
        name: "video_response",
        className: 'radio title_sub_wrp inputBlock1line',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (data && Object.keys(data).length > 0 && data.video_response !== undefined) ? data.video_response : '',
        // defaultValue: "No"
      },
      {
        id: 28,
        heading: "What is it about Aspire that interests you? (Other ways to ask…Why Aspire? What was it about Aspire that attracted you? How do you want to help companies?) *",
        // label: "Address",
        name: "description",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        defaultValue: (data && Object.keys(data).length > 0 && data.description !== undefined) ? data.description : '',
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },

      {
        id: 33,
        heading: "Which two of our services most resonated with you and why? *",
        // label: "Address",
        name: "resonated_service",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        defaultValue: (data && Object.keys(data).length > 0 && data.resonated_service !== undefined) ? data.resonated_service : '',
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },


      {
        id: 10,
        type: "html",
        className: "heading inputBlock1line",
        value: "<h1>Part 3. Qualified Relationships</h1>"
      },
      {
        id: 34,
        heading: "How many established relationships do you have C-level executive, decision-makers, or business owners? *",
        name: "relationships_no",
        className: 'radio title_sub_wrp inline3',
        values: [{ key: '0-1', val: '0-1' }, { key: '2-9', val: '2-9' }, { key: '10-49', val: '10-49' }, { key: '50-99', val: '50-99' }, { key: '100+', val: '100+' },],
        type: 'radio',
        hint: '',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
        defaultValue: (data && Object.keys(data).length > 0 && data.relationships_no !== undefined) ? data.relationships_no : '',

      },
      // {
      //   id: 29,
      //   type: "html",
      //   className: "paragraph inputBlock1line",
      //   value: "<p>How many established relationships do you have with C-level executives/decision-makers/business owners?</p>"
      // },
      {
        id: 11,
        heading: "Talk me through the first 2 people you would reach out to (This helps you get a feel for their relationships and gives you contacts to circle back to when helping them with a business plan.) *",
        // label: "Address",
        name: "relationships",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        defaultValue: (data && Object.keys(data).length > 0 && data.relationships !== undefined) ? data.relationships : '',
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 12,
        type: "html",
        className: "heading inputBlock1line",
        value: "<h1>Part 4. Questions/Comments</h1>"
      },
      {
        id: 13,
        heading: "Do you have any additional questions?",
        // label: "Comments",
        name: "questions",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.questions !== undefined) ? data.questions : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 13,
        heading: "Comments",
        // label: "Comments",
        name: "comments",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.comments !== undefined) ? data.comments : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 14,
        type: "html",
        className: "heading inputBlock1line",
        value: "<h1>Part 5. Discuss Next Steps (if moving forward)</h1>"
      },
      {
        id: 15,
        type: "html",
        className: "paragraph inputBlock1line",
        value: "<p>1. Send you our Agreement (it's an NDA with a right to represent, spells out your role, compensation, willable, etc.)</p>"
      },
      {
        id: 16,
        type: "html",
        className: "paragraph inputBlock1line",
        // value: "<p>2. Our next National Call is ___________ at 1pm ET. You will receive an invite once you sign the Agreement</p>"
        value: "<p>2. Once you sign agreement, you will receive a Welcome email from Aspire. It will contain your login credentails to our Member Training Center.</p>"
      },
      {
        id: 17,
        type: "html",
        className: "paragraph inputBlock1line",
        // value: "<p>3. Sign Agreement. As soon as you do, you'll receive an email with credentials to Access Member Center</p>"
        value: "<p>3. That will give you access to Traning for all of our service. Begin with the 'Start Here' section, and then be sure to look at the 'Quick Reference info' area, in particular our 'Quick Reference Guide'. </p>"
      },
      {
        id: 18,
        type: "html",
        className: "paragraph inputBlock1line",
        // value: "<p>4. That will give you access to Training for all services</p>"
        value: "<p>4. We have weekly National Calls that feature one of our service providers. They are at 1pm ET. </p>",
      },
      {
        id: 19,
        type: "html",
        className: "paragraph inputBlock1line",
        // value: "<p>5. Please take a look at the Start Here section as that gives you a great orientation to Access and the  training section. There is a video and a document you'll find helpful.</p>"
        value: "<p>5. You will also receive an email from us every Monday morning with a summary of what is happening at Aspire that week. It will have links for any calls, lead submition form etc.</p>"
      },
      {
        id: 20,
        type: "html",
        className: "paragraph inputBlock1line",
        // value: "<p>6. Please plan to join our National Call on Wednesday</p>"
        value: "<p>6. Once you've signed agreement, I'd like to schedule a call to review our Training Center and ensure you are comfortable submitting a lead. We can talk through a business plan during that cell as well.</p>"
      },
      // {
      //   id: 21,
      //   type: "html",
      //   className: "paragraph inputBlock1line",
      //   value: "<p>7. Look for the email with the Agreement from me and then you will also receive an email with the National Call information.</p>"
      // },
      {
        id: 22,
        type: "html",
        className: "paragraph inputBlock1line",
        value: "<p>7. Do you have any last questions?</p>"
      },
      // {
      //   id: 30,
      //   type: "html",
      //   className: "paragraph inputBlock1line",
      //   value: "<p>9. I've enjoyed talking to you and look forward to working with you. Please reach out if you have any questions</p>"
      // },


      // {
      //   id: 23,
      //   heading: "",
      //   // label: "Address",
      //   name: "dns",
      //   className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
      //   type: "textarea",
      //   //inputType: "number",/////////////// If not mentioned default will be text //////////////
      //   // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      //   defaultValue: (data && Object.keys(data).length > 0 && data.dns !== undefined) ? data.dns : '',
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },

      {
        id: 24,
        heading: "Second Interview Needed? *",
        name: "second_interview",
        className: 'radio title_sub_wrp inline3',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
        defaultValue: (data && Object.keys(data).length > 0 && data.second_interview !== undefined) ? data.second_interview : '',

      },
      {
        id: 25,
        heading: "Was position offered? *",
        name: "position_offered",
        className: 'radio title_sub_wrp inline3',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
        defaultValue: (data && Object.keys(data).length > 0 && data.position_offered !== undefined) ? data.position_offered : '',

      },
      {
        id: 26,
        heading: "Was position accepted? *",
        name: "position_accepted",
        className: 'radio title_sub_wrp inline3',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        defaultValue: (data && Object.keys(data).length > 0 && data.position_accepted !== undefined) ? data.position_accepted : '',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
      },



    ]
  }
  useEffect(() => {
    if (formsubmissionstate.InterviewAddEdit === 2) {
      console.log("formsubmissionstate............", formsubmissionstate)
      // setOpenSnacAddEdit(true)

      store.dispatch(
        setSnackbar({ open: true, message: successMsg, status: successStatus })
      )


      let InterviewFormPaylod = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": id ? id : undefined
        },
        "project": {},
        "token": "",
        "email": ""
      }
      // console.log("InterviewForm");
      dispatch(interviewFormdata(InterviewFormPaylod))
      let _id=id
      dispatch(fetchUserData(_id));



    }

    console.log("true==========>");
  }, [formsubmissionstate])


  return (
    <>
      <h1 className='page_heading'>AE Interview Form </h1>

      <div className='interview_wrp'>
        {/* <Form formData={interviewform} /> */}
        {id !== undefined && data && (data.length > 0 || Object.keys(data).length > 0) ? <> <Form formData={interviewform} /> </> : <Form formData={interviewform} />}
      </div>
    </>
  )
}

export default Interview
