import { IconButton, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const NoteViewModal = ({modalState,ComonData,togalModalState}) => {
    const ModalClose=()=>{
        togalModalState(false)
    }
    useEffect(()=>{
        // console.log("ComonDatafgfg",ComonData);
    },[ComonData])
  return (
    <>
        <Modal className=" modalblock userList_modal" open={modalState} onClose={ModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={"BoxMui_modal onlymaterial_BoxMui_modal"}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 200,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}
            >
                <div className='attechment_preview_mt'>
                <div className=" ModalBox_scroll">
                    <div className="heading">
                        <h2>
                            Notes View 
                        </h2>
                    </div>
                    <ul className="listflexblock">
                        {ComonData?.added_name ? (
                            <li>
                                <strong>Added By Name :</strong>
                                <strong>{ComonData?.added_name}</strong>
                            </li>
                        ):""}
                        {ComonData?.createdon_datetime ? (
                            <li>
                                <strong>Added Time :</strong>
                                <strong>{moment(ComonData?.createdon_datetime).format('MM/DD/yyyy')}</strong>
                            </li>
                        ):""}
                        {ComonData?.notes ? (
                            <li>
                                <strong>Note :</strong>
                                <strong>{ComonData.notes}</strong>
                            </li>
                        ):""}
                        {ComonData?.upload_file ? (
                            <li>
                                <strong>attachment :</strong>
                                <strong><img src={ComonData.upload_file.url} alt="" /></strong>
                            </li>
                        ):""}
                        
                    </ul>
                </div>

                </div>

                <Tooltip title="Close" placement="right-start">
                    <IconButton className="modalCloseBtn" onClick={ModalClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Modal>
    </>
  )
}

export default NoteViewModal