import { Button, IconButton, LinearProgress, Modal, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { clearConversionListData, clearData, conversionListData, conversionListDatacon, setmaxDate, setmindate } from "../../../CampaignManagement/CampaignReducer";
import { getconversioncountontime, getpiechartdata } from "../../Dashboard/DashboardReducer";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import ListingTable from "../../../../listing/listing";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { conversionPieChart } from "./AfffiliateConverisonReportReducer";
import ReplayIcon from '@mui/icons-material/Replay';




const AfffiliateConverisonReportModal = (props) => {

    const [showpiechart, setshowpiechart] = useState(true);
    const [loaderforclick, setsloaderforclick] = useState(false);
    const [selected, setSelected] = useState(1);
    const [countDetailsAll, setcountDetailsAll] = useState(null);
    const [openUserModal, setUserModal] = useState(false);
    const [openCampainModal, setCampainModal] = useState(false);
    const [openCampainModalList, setCampainModalList] = useState(false);
    const [valueOfCampainModal, setvalueOfCampainModal] = useState(false);
    const [redundentFlag, setRedundentFlag] = useState(false);
    const [pagadataObj, setpagadataObj] = useState({});
    const [copyUrlModal, setCopyUrlModal] = useState(false) /****For Copy url Listing Modal* */
    const [flagfoshowdoopdown, setflagfoshowdoopdown] = useState(false);
    const [landingpageclick, setlandingpageclick] = useState(null);
    const [openQRCodeModal, setOpenQRCodeModal] = useState(false);
    const [qrValue, setQrValue] = useState("")
    const [qrId, setQRid] = useState("")
    const [valuee, setValuee] = useState([null, null]);
    const [openCountMOdal, setOpenCountMOdal] = useState(false);


    const dispatch = useDispatch();
    let divLoader = useSelector(state => (state.camPaignManagementReducer?.loading) ? state.camPaignManagementReducer.loading : false);
    const userconversioncountonsecch = useSelector((state) => (state.DashboardReducer?.conversioncounttime) ? state.DashboardReducer.conversioncounttime : null);


    const piechartdtataforclick = useSelector((state) => (state.AfffiliateConverisonReport?.pieChartData[0]?.click) ? state.AfffiliateConverisonReport.pieChartData[0].click : null);

    const piechartdtataforcon = useSelector((state) => (state.AfffiliateConverisonReport?.pieChartData[1]?.conversion) ? state.AfffiliateConverisonReport.pieChartData[1].conversion : null);


    const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
    let mindate = useSelector((state) => state.camPaignManagementReducer?.mindate ? state.camPaignManagementReducer.mindate : {});
    let maxDate = useSelector((state) => state.camPaignManagementReducer?.maxDate ? state.camPaignManagementReducer.maxDate : {});
    const maxDateForDateRangePicker = new Date();
    let conversionListdataset = useSelector((state) => state.camPaignManagementReducer?.conversionList && state.camPaignManagementReducer?.conversionList.length > 0 ? state.camPaignManagementReducer.conversionList : []);

    let conversionListdatasetforconversion = useSelector((state) => state.camPaignManagementReducer?.conversionListforconversion && state.camPaignManagementReducer?.conversionListforconversion.length > 0 ? state.camPaignManagementReducer.conversionListforconversion : []);


    let piechartloader = useSelector((state) => state.AfffiliateConverisonReport.piechartloader ? state.AfffiliateConverisonReport.piechartloader : false);


    // console.warn("Chart data ===>>> ", piechartdtataforclick, piechartdtataforcon)
    // console.warn("props ===>>> ", props)




    const addapicallforconversion = (val) => {
        setSelected(val)
        if (val == 1) {
            tableDataConversionDetails.current.reqBody.searchcondition["lead_id"] = { $exists: false }
            dispatch(conversionListData(tableDataConversionDetails.current.reqBody))

        } else if (val == 2) {
            tableDataConversionDetailsforconversion.current.reqBody.searchcondition["lead_id"] = { $exists: true }
            dispatch(conversionListDatacon(tableDataConversionDetailsforconversion.current.reqBody))
        }
    }






    const handelsearch = async () => {

        let fullday = 24 * 60 * 60 * 1000
        let halfDay = 6 * 60 * 60 * 1000
        let stsrtTtime = moment(valuee[0]).valueOf();
        // let end_date = moment(daterangeValue[1]?.getTime()).add((24 * 3600 * 1000) - 1000, 'milliseconds').valueOf();
        let endTtime = moment(valuee[1]?.getTime()).add((24 * 3600 * 1000) - 1000, 'milliseconds').valueOf();

        dispatch(setmindate(stsrtTtime))
        dispatch(setmaxDate(endTtime))

        setsloaderforclick(true)

        // let bodyc = { ...tableDataConversionDetails?.current?.reqBody?.searchcondition }
        // if (bodyc.lead_id) {
        //     delete bodyc.lead_id
        // }

        // bodyc["createdon_datetime"] = {
        //     $gte: stsrtTtime,
        //     $lte: endTtime,
        // }
        // console.log("bodyc=====>>>", bodyc);

        const data = {
            "landing_page_id": props?.landing_page_id,
            "campaign_added_by": props?.campaign_added_by,
            "createdon_datetime": { $gte: stsrtTtime, $lte: endTtime, }
        }

        //bodyc
        dispatch(conversionPieChart(data))


        setshowpiechart(false)
        setTimeout(() => {
            setshowpiechart(true)

        }, 500);
        setsloaderforclick(false)

        // }, 1000);
        return
    };







    // ======  clickPieData === //
    let clickPieData = {
        data: {
            labels: piechartdtataforclick?.label,
            datasets: [{
                label: '# of Votes13',
                data: piechartdtataforclick?.data,
                borderWidth: 1,
                backgroundColor: ["#f67d24", "#035587", "#009fe2", "#003266", "#c50723", "blue", "yellow", "green", "purple", "red", "orange", "brown", "turquoise", "pink", "azure", "ivory", "teal", "silver", "purple", "navy", "pea", "gray", "maroon", "charcoal", "aquamarine", "coral", "fuchsia", "wheat", "lime", "crimson", "khaki", "magenta", "golden", "plum", "olive", "cyan"],

            }],



        },
        options: {

            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: true,
                text: "Pie Chart For Click Report",
                //html:"<h2>Pie chart</h2>"

            }
        },
        id: 'pai13'

    }
    // ====================== //


    // ===== conversionPieData ==== //
    let conversionPieData = {
        data: {
            labels: piechartdtataforcon?.label,
            datasets: [{
                label: '# of Votes15',
                data: piechartdtataforcon?.data,
                borderWidth: 1,
                backgroundColor: ["#009fe2", "#003266", "#c50723", "blue", "yellow", "green", "purple", "red", "orange", "brown", "turquoise", "pink", "azure", "ivory", "teal", "silver", "purple", "navy", "pea", "gray", "maroon", "charcoal", "aquamarine", "coral", "fuchsia", "wheat", "lime", "crimson", "khaki", "magenta", "golden", "plum", "olive", "cyan", "#f67d24", "#0c256c",],

            }],



        },
        options: {

            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: true,
                text: "Pie Chart For Conversion Report",
                //html:"<h2>Pie chart</h2>"

            }
        },
        id: 'pai14'


    }
    // =========================== //





    useEffect(() => {
        return () => {
            setValuee([null, null])
            dispatch(clearData())
        }
    }, [])









    var modifyTableConversionHeaders = [
        { val: "campaign_name", name: "Campaigns Name" },
        { val: "landing_page_name", name: "Landing Page" },
        { val: "lead_name", name: "Lead Name" },
        {
            val: "createdon_datetime",
            name: "Converted On",
            type: "datetime",
            format: "MM/DD/YYYY",
        },
    ];

    let tableDataConversionDetails = useRef({
        tableId: "conversionlistTable",
        // tableTitle: "Campaign's List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/conversionlist",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/conversionlist",
        },

        reqBody: {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "createdon_datetime",
                type: "desc",
            },
            searchcondition: {
                "landing_page_id": landingpageclick,
                "lead_id": { $exists: false },
                "campaign_added_by": user_id,
                // "createdon_datetime": {
                //     $gte: mindate,
                //     $lte: maxDate,
                // },
            },
            project: {},
            token: "",
            // count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["campaign_name", "createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle:
                    "<p>Are you sure that you want to delete this record(s)?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Yes", "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/delete-campaign",
                    body: {
                        source: "users",
                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Active", "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/status-update",
                    body: {
                        source: "users",
                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },
        ],
        buttons: [

            {
                id: "viewApi",
                label: "Click Details View",
                type: "api",
                category: "view",
                icon_type: "preview_icon",
                className: "preview",
                modalTitle: "Click Details View",
                modalClassName: "contractDetails",
                // extera_btn_lavel:"Download",
                // extra_btn: [{
                //   id: "download",
                //   level: "Download"
                // }],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/conversionlist",
                    body: {
                        source: "",
                        condition: { limit: 1, skip: 0 },
                        sort: { type: "asc", field: "_id" },
                        searchcondition: {

                        },
                    },
                },
                headers: [
                    { val: "campaign_name", label: "Campaign Name" },
                    { val: "landing_page_name", label: "Landing Page Name" },

                    //   { val: "ip", label: "Ip" },
                    //   { val: "hostname", label: "Host Name (Ip-Info)" },
                    //   { val: "org", label: "Organizer (Ip-Info)" },
                    { val: "city", label: "City" },
                    { val: "region", label: "State" },
                    { val: "postal", label: "ZIP" },
                    { val: "country", label: "Country" },
                    { val: "timezone", label: "Timezone" },
                    { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
                ],
            },


        ],
        deleteModal: {
            modalClassName: "modalblock",
        },



        searchData: {
            heading: "Search Click Details",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchFormClick',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 6,
                        label: "Search By Campaign Name",
                        name: "campaign_name",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/campaignnameautocomplete",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "updatedon_datetime",
                                "type": "desc"
                            },
                            "searchcondition": {
                                "landing_page_id": landingpageclick,
                                // "lead_id": { $exists: false },
                                "campaign_added_by": user_id,
                                // "createdon_datetime": {
                                //     $gte: mindate,
                                //     $lte: maxDate,
                                // },
                            },
                            "type": "campaign_name",
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "campaign_id" },
                    },
                    {
                        id: 11,
                        label: "Search by Start Date",
                        name: "start_date",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: "inputblock2line",
                        payloadFormat: { key: "createdon_datetime", param: "$gte" },
                    },
                    {
                        id: 12,
                        label: "Search by End Date",
                        name: "end_date",
                        className: "inputblock2line",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    },
                ]
            }
        },


    });





    var modifyTableClickCountHeaders = [
        { val: "campaign_name", name: "Campaigns Name" },
        { val: "landing_page_name", name: "Landing Page" },
        {
            val: "createdon_datetime",
            name: "Clicked On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm A",
        },
    ];

    let tableDataConversionDetailsforconversion = useRef({
        tableId: "tableDataConversionDetailsforconversion",
        // tableTitle: "Campaign's List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/conversionlist",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/conversionlist",
        },

        reqBody: {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "createdon_datetime",
                type: "desc",
            },
            searchcondition: {
                "landing_page_id": landingpageclick,
                "lead_id": { $exists: true },
                "campaign_added_by": user_id,
                // "createdon_datetime": {
                //     $gte: mindate,
                //     $lte: maxDate,
                // },

            },
            project: {},
            token: "",
            // count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["campaign_name", "createdon_datetime", "lead_name"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,


        buttons: [

            {
                id: "viewApi",
                label: "Conversion Details View",
                type: "api",
                category: "view",
                icon_type: "preview_icon",
                className: "preview",
                modalTitle: "Conversion Details View",
                modalClassName: "contractDetails",
                // extera_btn_lavel:"Download",
                // extra_btn: [{
                //   id: "download",
                //   level: "Download"
                // }],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/conversionlist",
                    body: {
                        source: "",
                        condition: { limit: 1, skip: 0 },
                        sort: { type: "asc", field: "_id" },
                        searchcondition: {

                        },
                    },
                },
                headers: [
                    { val: "campaign_name", label: "Campaign Name" },
                    { val: "landing_page_name", label: "Landing Page Name" },
                    { val: "lead_name", label: "Lead Name" },
                    // { val: "ip", label: "Ip" },
                    // { val: "hostname", label: "Host Name (Ip-Info)" },
                    // { val: "org", label: "Organizer (Ip-Info)" },
                    { val: "city", label: "City" },
                    { val: "region", label: "State" },
                    { val: "postal", label: "ZIP" },
                    { val: "country", label: "Country" },
                    { val: "timezone", label: "Timezone" },
                    { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },

                ],
            },


        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

        searchData: {
            heading: "Search Conversion Details",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchFormCampaign',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search by Campaign Name",
                        name: "campaign_name",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/campaignnameautocomplete",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "updatedon_datetime",
                                "type": "desc"
                            },
                            "searchcondition": {
                                "campaign_added_by": user_id,
                                // "createdon_datetime": {
                                //     $gte: mindate,
                                //     $lte: maxDate,
                                // },
                            },
                            "project": {},
                            "token": ""
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "campaign_id" },

                    },
                    {
                        id: 7,
                        label: "Search By Lead Name",
                        name: "landing_name",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/leadnameautocomplete",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "updatedon_datetime",
                                "type": "desc"
                            },
                            "searchcondition": {
                                "landing_page_id": landingpageclick,
                                // "lead_id": { $exists: true },
                                "campaign_added_by": user_id,
                                // "createdon_datetime": {
                                //     $gte: mindate,
                                //     $lte: maxDate,
                                // },
                            },
                            "project": {},
                            "token": ""
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "lead_id" },
                    },
                    {
                        id: 11,
                        label: "Search by Start Date",
                        name: "start_date",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: "inputblock2line",
                        payloadFormat: { key: "createdon_datetime", param: "$gte" },
                    },
                    {
                        id: 12,
                        label: "Search by End Date",
                        name: "end_date",
                        className: "inputblock2line",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    },




                ]
            }
        },


    });









    // ---- calculating total click count ------ //
    let clickCount = 0
    piechartdtataforclick?.data?.map((x) => clickCount = clickCount + x)
    // ----------------------------------------- //


    // ---------- total conversion count -------- //
    let cornversionCount = 0
    piechartdtataforcon?.data?.map((y) => cornversionCount = cornversionCount + y)
    // ----------------------------------------- //








    // === modal close function ==== //
    const CloseCountModal = () => {
        props.setOpenModal(false)
        dispatch(clearConversionListData())
    };
    // =========================== //





    const resetModalData = () => {
        dispatch(clearData())
        setValuee([null, null])

        const data = {
            "landing_page_id": props?.landing_page_id,
            "campaign_added_by": props?.campaign_added_by,
        }
        dispatch(conversionPieChart(data))
    }



    return (
        <>
            <Modal
                className="Count_ReportMOdal modalBaseStyle "
                open={props.openModal}
                onClose={CloseCountModal}
                closeAfterTransition
            >
                <Box className='modalBaseBox largeModal campain_modal_width'>
                    <IconButton className="modal_icon_button" onClick={CloseCountModal}>
                        <CloseIcon className="modal_close_icon" />
                    </IconButton>
                    <div className='countModal_headr modal_title'>
                        {selected && selected == 1 && <h3>Showing clicks relative to landing pages and campaigns</h3>}
                        {selected && selected === 2 && <h3>Showing First Page conversions relative  to landing pages and campaigns </h3>}
                    </div>
                    <div className='COuntSData_mainwrpr'>


                        {/* ------ Loader ------- */}
                        {piechartloader && <div>
                            <Box sx={{ width: "100%", marginTop: '10px', marginBottom: "10px" }}>
                                <LinearProgress />
                            </Box>
                        </div>}
                        {/* --------------------- */}




                        {/* ---- Date Picker ----- */}
                        <div className="daterangepicker_one ">
                            {(typeof (mindate) !== "object" && typeof (maxDate) !== "object") ? <span className="dash_brd_date">
                                {" "}
                                {moment(mindate).format("MMMM D ")} - {" "}
                                {moment(maxDate).format("MMMM D ")}

                            </span> : <span className="dash_brd_date">Till Date</span>}


                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateRangePicker
                                    startText="Start Date"
                                    endText="End Date"
                                    value={valuee}
                                    maxDate={maxDateForDateRangePicker}
                                    onChange={(newValue) => {

                                        setValuee(newValue);
                                    }}

                                    onOpen={() => {

                                        //////////////////////// Removing Missing License Key Message /////////////////////
                                        setTimeout(() => {
                                            if (
                                                document.querySelector("daterangepicker_one") !=
                                                undefined &&
                                                document.querySelector("daterangepicker_one").style !=
                                                undefined
                                            )
                                                document.querySelector("daterangepicker_one").style.display = "none";

                                            let e = document.getElementsByTagName("div");
                                            for (let i = 0; i < e.length; i++) {
                                                let textval = e[i].innerText;
                                                if (
                                                    textval.includes("Missing license key") &&
                                                    textval.length < 30
                                                ) {
                                                    e[i].style.display = "none";
                                                }
                                            }
                                            if (
                                                document.querySelector("daterangepicker_one") !=
                                                undefined &&
                                                document.querySelector("daterangepicker_one").style !=
                                                undefined
                                            )
                                                document.querySelector("daterangepicker_one").style.display = "block";
                                        }, 200);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} />
                                        </>
                                    )}
                                />
                            </LocalizationProvider>

                            <div className="daterangepicker_view_btn">
                                <Button onClick={() => handelsearch()}>View</Button>
                            </div>

                            <div className="daterangepicker_view_btn">
                                <Button variant="contained" onClick={() => resetModalData()} className='resetmodalDataBtn'>
                                    <Tooltip title="Reload" placement="bottom" >
                                        <ReplayIcon />
                                    </Tooltip>
                                </Button>
                            </div>


                        </div>
                        {/* ---------------------- */}





                        {/* ------- Counts ----- */}
                        <div className='countDetail_Title'>
                            <p>Total Click Count: {piechartdtataforclick?.data?.length > 0 ? clickCount : 0}</p>
                            <p>Total Conversion Count: {piechartdtataforcon?.data?.length > 0 ? cornversionCount : 0}</p>
                        </div>
                        {/* -------------------- */}






                        {/* --------- Pie Chart Section ------------ */}
                        <div className='header4piechrt'><h3>Click and Conversion Report in Pie Chart View</h3></div>
                        {showpiechart && <div className='showchartdiv'>
                            <div className='clickchart'>

                                {piechartdtataforclick?.data?.length > 0 ? <app-pie-chart key={1} data={JSON.stringify(clickPieData)}></app-pie-chart> : <div className="noDataFound"><span>No data found! </span></div>}

                                {piechartdtataforclick?.data?.length > 0 ? <p className="pieChrt_Hint"><strong>Click Pie Chart:</strong> This chart displays the ratio of number of clicks against each campaign.</p> : " "}
                            </div>
                            <div className='conversionchart'>

                                {piechartdtataforcon?.data?.length > 0 ? <app-pie-chart key={2} data={JSON.stringify(conversionPieData)}></app-pie-chart> : <div className="noDataFound"><span>No data found!</span></div>}

                                {piechartdtataforcon?.data?.length > 0 ? <p className="pieChrt_Hint"><strong>Conversion Pie Chart:</strong> This chart displays the ratio of number of conversions against each campaign.</p> : ""}
                            </div>
                        </div>}
                        {/* --------------------------------------- */}


























                        {/* <div className='click_buttonwp'>
                            <Button className={selected === 1 ? "active" : ""} variant="contained" onClick={() => addapicallforconversion(1)}>Click Details
                            </Button>
                            <Button className={selected === 2 ? "active" : ""} variant="contained" onClick={() => addapicallforconversion(2)}>Conversion Details
                            </Button>
                        </div> */}

                        {/* {selected && selected === 1 && <div className="rolelist_mainwrp">
                            <div className="rolelist_mainwrp_Table">
                                {divLoader && (
                                    <TableSkeleton count={5} />
                                )}
                                {!divLoader && <>{conversionListdataset && conversionListdataset.length > 0 && !divLoader ? <ListingTable
                                    tableData={tableDataConversionDetails.current}
                                    dataset={conversionListdataset}
                                    modifyHeaders={modifyTableClickCountHeaders}
                                /> :
                                    <p className='norecord'>NO Record Found</p>
                                }</>}

                            </div>
                        </div>} */}

                        {/* {selected && selected === 2 && <div className="rolelist_mainwrp">
                            <div className="rolelist_mainwrp_Table">
                                {divLoader && (
                                    <TableSkeleton count={5} />
                                )}
                                {!divLoader && <>
                                    {conversionListdatasetforconversion && conversionListdatasetforconversion.length > 0 ? <ListingTable
                                        tableData={tableDataConversionDetailsforconversion.current}
                                        dataset={conversionListdatasetforconversion}
                                        modifyHeaders={modifyTableConversionHeaders}
                                    /> : <p className='norecord'>NO Record Found</p>}
                                </>}

                            </div>
                        </div>} */}







                    </div>

                </Box>
            </Modal>

        </>
    )

}

export default AfffiliateConverisonReportModal