import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, IconButton,LinearProgress,Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { Form } from '../../../form/Form';
import { setSnackbar } from '../../Layout/layoutReducer';
import ListingTable from '../../../listing/listing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import QRCode from "qrcode.react";
import {clearcampaignDatadata,getCampaignListData,} from "../../CampaignManagement/CampaignReducer";
import store from '../../../store';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import copy from "copy-to-clipboard";
import { resetDisableState, setFormFieldError } from '../../../form/formReducer';
import { QrCodeScanner } from '@mui/icons-material';

function CampainManageModal({ open, close, user_id, user_name, landingpage_id }) {
  const dispatch = useDispatch();
  const [addflag, setaddflagfun] = useState(false);
  const [editflag, editflagfun] = useState(false);
  const [landingpagedata, setlandingpagedata] = useState('');
  const [copyUrlSnackModal, setCopyUrlSnackModal] = useState(false);
 

  const [openQRCodeModal, setOpenQRCodeModal] = useState(false)
  const [qrValue, setQrValue] = useState("")
  const [qrId, setQRid] = useState("")


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

  const campaginaccessData = useSelector((state) => (state.DashboardReducer?.campaginaccessdata) ? state.DashboardReducer.campaginaccessdata : null);
  const campaignformSubmissionState = useSelector((state) => state.formSlice.formSubmissionState.CampaignAddEdit ? state.formSlice.formSubmissionState.CampaignAddEdit : 0)
  const searchFormData = useSelector((state) =>state.formSlice.formData.searchFormCampaign? state.formSlice.formData.searchFormCampaign: null);
  let loader = useSelector((state) =>state.camPaignManagementReducer?.loading? state.camPaignManagementReducer.loading: false);
  const editClicked = useSelector((state) =>state.tableSlice?.customButtonClicked?.campaigntdataTable?.edittable9? state.tableSlice.customButtonClicked.campaigntdataTable.edittable9: false);
  const editData = useSelector((state) =>state.tableSlice?.tempRowData?.campaigntdataTable? state.tableSlice.tempRowData.campaigntdataTable: {});
  const user_data = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);
  const copyClicked = useSelector((state) =>state.tableSlice?.customButtonClicked?.campaigntdataTable?.cpoyurlbtn? state.tableSlice.customButtonClicked.campaigntdataTable.cpoyurlbtn: false);
  const qrButtonClicked = useSelector((state) =>state.tableSlice?.customButtonClicked?.campaigntdataTable?.qrcodebutton? state.tableSlice.customButtonClicked.campaigntdataTable.qrcodebutton: false);
  const addflagback = useSelector(state => state.formSlice?.customButtonClicked?.CampaignAddEdit?.addflagback ? state.formSlice.customButtonClicked.CampaignAddEdit.addflagback : false); // ########### Click Function For GO BACK Button ########### //
 
  let campaingDataforlist = useSelector((state) => state.camPaignManagementReducer?.campaignData && state.camPaignManagementReducer?.campaignData.length > 0 ? state.camPaignManagementReducer.campaignData : []);



  useEffect(()=>{
    console.log("campaingDataforlist",campaingDataforlist);
  },[campaingDataforlist])





  useEffect(() => {
    if (landingpage_id && Object.keys(landingpage_id).length > 0) {
      setlandingpagedata(landingpage_id)
      tableDataCampaign.current.reqBody.searchcondition.landing_page_id = landingpage_id.page_id
    }
  }, [JSON.stringify(landingpage_id)])

  useEffect(() => {
    if (user_id) {
      tableDataCampaign.current.reqBody.searchcondition.user_id = user_id
    }
  }, [user_id])

  

  useEffect(() => {
    if (
      searchFormData !== undefined &&
      searchFormData?.updatedon_Start_datetime !== undefined &&
      searchFormData?.updatedon_Start_datetime !== null &&
      searchFormData?.updatedon_datetime !== undefined &&
      searchFormData?.updatedon_datetime !== null &&
      searchFormData?.updatedon_datetime <= searchFormData.updatedon_Start_datetime
    ) {
      store.dispatch(setFormFieldError({ formId: 'searchFormCampaign', fieldName: "updatedon_datetime", error: { type: 'custom' } }))
    }
    else {
      store.dispatch(resetDisableState({ formId: 'searchFormCampaign' }))
    }
  }, [JSON.stringify(searchFormData)])







  const copyToClipboard = (e) => {
    if (campaginaccessData?.campaign_access == 2) {
      copy(e);
    }
    if (campaginaccessData?.campaign_access != 2) {
      setCopyUrlSnackModal(true)
    } else {
      dispatch(setSnackbar({ open: true, message: "Copied To Clipboard!", status: 'success' }))
    }
  }





  useEffect(() => {
    if (copyClicked && Object.keys(editData).length > 0) {
      if (landingpagedata && typeof landingpagedata != 'string' && landingpagedata !== '' && Object.keys(landingpagedata).length > 0) {
        let copyValname = landingpagedata.p_url;
        let copyVal = copyValname + user_data.user_name + editData.sub_id;
        copyToClipboard(copyVal)
      }
    }
  }, [copyClicked]);










useEffect(()=>{
  if(qrButtonClicked && Object.keys(editData).length > 0){
     if (landingpagedata && typeof landingpagedata != 'string' && landingpagedata !== '' && Object.keys(landingpagedata).length > 0) {
      let qrVal = landingpagedata.p_url;
      let idQrCode = user_data.user_name + editData.sub_id;
      let qrCodeVal = qrVal + user_data.user_name + editData.sub_id;
      setQRid(idQrCode)
      setQrValue(qrCodeVal)
    }
    setOpenQRCodeModal(true)
  }
},[qrButtonClicked])




const downloadQR = () => {
  const canvas = document.getElementById(qrId);
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = qrId +'.png';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};



  useEffect(() => {
    if (addflagback) {
      setaddflagfun(false);
    }
    if (landingpage_id.edit_flag) {
      setaddflagfun(true);
    }
  }, [addflagback])




  let reqbodyofList = {
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "updatedon_datetime",
      type: "desc",
    },
    searchcondition: {
      "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
      "user_id": user_id
    },
    project: {},
    token: "",
  };



  useEffect(() => {
    console.log("loginjvsdjh",landingpage_id?.page_id );
    if(landingpage_id?.page_id && user_id){
      store.dispatch(getCampaignListData(reqbodyofList));
    }
    return () => {
      store.dispatch(clearcampaignDatadata());
    };
  }, []);


  const dataform = {
    id: 'CampaignAddEdit',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "campaign-manage/add-update-campaign",
    urlPathOnSuccessfulFormSubmission: "",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {
      "_id": (editData && Object.keys(editData).length > 0 && editData._id !== undefined) ? editData._id : undefined,
      "user_id": editflag ? undefined : user_id,
      "user_name": editflag ? undefined : user_name,
      "landing_page_id": editflag ? undefined : landingpage_id?.page_id && landingpage_id.page_id
    },

    fields: [
      {
        id: 0,
        heading: "Campaign Name",
        lable: "Campaign Name",
        name: "name",
        className: "inputBlock inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.name !== undefined) ? editData.name : undefined,
      },
      {
        id: 2,
        heading: "Description",
        name: "description",
        className: "inputBlock inputBlock1line",
        type: 'textarea',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.description !== undefined) ? editData.description : undefined,
      },
      {
        id: 7,
        heading: "Pixel For Tracking Clicks",
        name: "pixel_tracking_click",
        className: "inputBlock inputBlock1line pixelTrack",
        placeholder: "Type Here...",
        type: 'textarea',
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.pixel_tracking_click !== undefined) ? editData.pixel_tracking_click : undefined,
      },
      {
        id: 25,
        type: "html",
        className: "passwordCls inputBlock1line pixelHint",
        value: `<p><strong>Pixel for tracking clicks:</strong> Input the pixel tracking code here. This will track the number of clicks against the campaign.</p>
        <div class="for_exmpl">(<strong>e.g.,</strong>Google Analytics Tracking Code, Facebook Custom Audience, Google AdWords, Bing Ads, etc)</div>`
      },
      {
        id: 9,
        heading: "Pixel For Tracking Conversion",
        name: "pixel_tracking_conversion",
        className: "inputBlock inputBlock1line pixelTrack",
        placeholder: "Type Here...",
        type: 'textarea',
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.pixel_tracking_conversion !== undefined) ? editData.pixel_tracking_conversion : undefined,
      },
      {
        id: 26,
        type: "html",
        className: "passwordCls inputBlock1line pixelHint",
        value: `<p><strong>Pixel for tracking conversion:</strong> Input the pixel tracking code here. This will track the conversion rate of your campaign.</p>
        <div class="for_exmpl">(<strong>e.g.,</strong>Google Analytics Tracking Code, Facebook Custom Audience, Google AdWords, Bing Ads, etc)</div>`
      },

      {
        id: 4,
        heading: "",
        lable: "",
        name: "status",
        className: "inputBlock1line statusinput",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Active*' },
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.status !== undefined) ? editData.status : 1,
      },
      {
        id: 5,
        heading: "_id",
        name: "_id",
        className: "inputBlock inputBlock1line",
        type: 'hidden',
        defaultValue: (editData && Object.keys(editData).length > 0 && editData._id !== undefined) ? editData._id : undefined,
      },
      {
        id: 22,
        type: "html",
        className: "passwordCls inputBlock1line ",
        value: "<p> * Make sure the “Active” checkbox is selected. Otherwise, the campaign will not be displayed in the dropdown options on the Dashboard.</p>"
      },
    ],
    customButtons: [
      {
        id: 'addflagback',
        label: ' Back to List',
        className: 'default',
        type: 'button',
      },
    ]
  }

  useEffect(() => {

    if (campaignformSubmissionState && campaignformSubmissionState == 2) {
      if (editflag) {
        dispatch(setSnackbar({ open: true, message: "Campaign Edited Successfully!", status: 'success' }));
        editflagfun(false)
      }
      else {
        dispatch(setSnackbar({ open: true, message: "Campaign Added Successfully!", status: 'success' }));
      }
      if(landingpage_id ){
      store.dispatch(getCampaignListData(reqbodyofList));
      }
      setaddflagfun(false);
    }
  }, [campaignformSubmissionState])



  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      setaddflagfun(true);
      editflagfun(true);
    }
  }, [editClicked]);

  var modifyTableHeaders = [
    { val: "name", name: "Campaigns Name" },
    { val: "landing_page_name", name: "Landing Page" },
    {val: "status",name: "Status",customVal: { 0: "Inactive", 1: "Active" },},
    {val: "updatedon_datetime",name: "Updated On",type: "datetime",format: "MM/DD/YYYY",},
  ];

  

  let tableDataCampaign = useRef({
    tableId: "campaigntdataTable",
    tableTitle: "Campaign List",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "campaign-manage/user-list-or-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "campaign-manage/user-list-or-count",
    },
    reqBody: {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "updatedon_datetime",
        type: "desc",
      },
      searchcondition: {
        "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
        "user_id": user_id
      },
      project: {},
      token: "",
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/delete-campaign",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      {
        id: "edittable9",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "viewApi",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Campaign Preview Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/user-list-or-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {
              "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
              "user_id": user_id
            }
          }
        },
        headers: [
          { val: "name", label: "Campaign Name" },
          { val: "landing_page_name", label: "Landing Page Name" },
          { val: "description", label: "Campaign Description" },
          { val: "pixel_tracking_click", label: "Pixel For Tracking Clicks" },
          { val: "pixel_tracking_conversion", label: "Pixel For Tracking Conversion" },
          {val: "createdon_datetime",label: "Added on",type: "datetime",format: "MM/DD/YYYY",},
        ]
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        modalHeader: "<p> Alert!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "cpoyurlbtn",
        type: "other",
        icon_type: "content_copy_icon",
        label: "Copy Url",
        name: "content_copy_icon",

      },
      {
        id: "qrcodebutton",
        type: "other",
        icon_type: "qr_code_scanner_icon",
        label: "QR Scanner",
        name: "qr_code_scanner_icon",
    },
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert!</p>",
        btn_label: ["Yes", "No"],
        condField: "default",
        condVal: "true",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/delete-campaign",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },



    searchData: {
      heading: "Search Campaign",
      type: "drawer",
      formData: {
        id: 'searchFormCampaign',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Campaign Name",
            name: "name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "campaign-manage/auto-complete-campaign",
            reqBody: {
              "source": "campaign_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {
                "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
                "user_id": user_id
              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 3,
            label: "Search by Status",
            name: "status",
            type: 'select',
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "status" },
          },

          {
            id: 8,
            label: "Search by Updation Start Date",
            name: "updatedon_Start_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "updatedon_datetime", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Updation End Date",
            name: "updatedon_datetime",
            className: 'list_search_item countDiv__column--col4 ',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "updatedon_datetime", param: "$lte" },
            errorMessage: {
              custom: "End Date can not be less than Start Date"
            },

          },
        ]
      }
    },


  });
 

  const addModal = () => {
    setaddflagfun(true);
  }

  const closeModal = () => {
    setaddflagfun(false);
    close();
  }
  return (
    <div>
      <div>
        <Dialog
          className="hmmodal modoal_for_CampaignUrl creatcampain"
          open={open}
          onClose={() => closeModal()}>

          <Tooltip title='Close' >
            <Button onClick={() => closeModal()} className="hmmodalCloseBTN"> <CloseIcon /> </Button>
          </Tooltip>
          <Box className='hmmodal_user_Box' >

            {addflag ?
              <div className='hmmodal_user_Box_wrapper' >
                {editflag ? <h3>Edit Campaign For: {landingpage_id.p_name}</h3> : <h3>Create Your Campaign For : {landingpage_id.p_name}</h3>}
                <Form formData={dataform} />
              </div> :
              <div className='hmmodal_user_Box_wrapper' >
                <div className="SD_header_flex">
                  <h4><span> Create/Edit Your Campaigns For: {landingpage_id.p_name} </span></h4>
                </div>
                <div className='icon_details_wrp'>
                  <Tooltip title="Add Campaign">
                    <Button className="addCampaign_btn" onClick={() => addModal()}>Add Campaign</Button>
                  </Tooltip>
                  <h1>Instructions</h1>
                  <div className='pharsavings_table_cls'><div><CheckBoxOutlineBlankIcon />Checkbox:</div><p> Select multiple/single number of data by selecting the checkbox.</p></div>
                  <div className='pharsavings_table_cls'><div> <SearchIcon />Search:</div><p> Search any campaign by clicking on the search icon.</p></div>
                  <div className='pharsavings_table_cls'><div><ReplayIcon />Reload: </div><p> Reload the campaign’s listing by clicking on the icon.</p></div>
                  <div className='pharsavings_table_cls'><div><EditIcon />Edit: </div><p>Edit the campaign information by clicking on the icon.</p></div>
                  <div className='pharsavings_table_cls'><div><ToggleOnIcon />Status Change: </div><p> Change the status of any Campaign from Active to Inactive or from Inactive to Active by clicking on the icon.</p></div>
                  <div className='pharsavings_table_cls'><div><ContentCopyIcon />Copy Icon:  </div><p>Copy a campaign URL by clicking on the icon.</p></div>
                  <div className='pharsavings_table_cls'><div><DeleteIcon />Delete:</div><p>Delete any campaign by clicking on the icon.</p></div>
                  <div className='pharsavings_table_cls'><div><QrCodeScanner />QR Scanner:</div><p>Get QR Code of the campaign by clicking on the icon.</p></div>
                </div>

                <div className="rolelist_mainwrp">
                  {loader &&  <LinearProgress />}
                  <div className="rolelist_mainwrp_Table">
                    {!loader ? (<ListingTable tableData={tableDataCampaign.current} dataset={campaingDataforlist} modifyHeaders={modifyTableHeaders} />) : (<TableSkeleton count={5} />)}
                  </div>
                </div>

              </div>
            }
          </Box>
        </Dialog>
        <Modal className='copy_urlSnack_modal_Wrpr'
                    open={copyUrlSnackModal}
                    onClose={() => setCopyUrlSnackModal(false)}>
                    <Box sx={style} className="modalBaseBox">
                        <div className='hmmodal_user_Box_wrapper' >
                            <img src="https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp" alt='' />
                        </div>
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setCopyUrlSnackModal(false)} className="modal_icon_button">
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className='icon_details_wrp'>
                                <h4>Pharmacy Marketing Tools will be available at our Launch Wednesday May 17th. Please log back in at this time to start using the pharmacy marketing system.</h4>
                        </div>
                    </Box>
        </Modal>

        <Modal className='copy_urlSnack_modal_Wrpr qr_modal_class'
                    open={openQRCodeModal}
                    onClose={() => setOpenQRCodeModal(false)}>
                    <Box sx={style} className="modalBaseBox">
                        <div className='hmmodal_user_Box_wrapper' >
                            <img src="https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp" alt='' />
                        </div>
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setOpenQRCodeModal(false)} className="modal_icon_button">
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className='qr_code_class'>
                            <QRCode
                            id={qrId}
                            value= {qrValue}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                            />
                            <div className='qr_code_download_button'>
                            <Button variant="contained" onClick={downloadQR}> Download QR </Button>
                            </div>
                      </div>
                    </Box>
        </Modal>
      </div>
    </div>
  )
}


export default CampainManageModal