import { Button, LinearProgress, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import { clearforceloginapidata, fetchbluecoasteasplead, forcelogin } from './LandingPage/LandingpageReducer';
import { setSnackbar } from '../Layout/layoutReducer';
import { removeField, setField } from '../../form/formReducer';
import { Form } from '../../form/Form';
import StateArray from '../../assets/json/state';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Cookies } from 'react-cookie';
import { setIpinfo, setLogedinUserInfo } from '../login/loginReducer';
import axios from 'axios';
import { getgenerateAgentCode } from '../Calendar/calenderReducer';
import { resolvefunction } from '../../helper/helperFunctions';
function SuccessBlueCoast() {
  const dispatch = useDispatch()
  const { repid } = useParams();
  const [open, setOpen] = useState(false)
  const [loader, setloader] = useState(false)
  const navigate = useNavigate();
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const editAspdatablue = useSelector(state => (state.LandingpagefontReducer?.blueaspfetchdata) ? state.LandingpagefontReducer?.blueaspfetchdata : null)
  const loading = useSelector(state => (state.LandingpagefontReducer?.bluecoseloader) ? state.LandingpagefontReducer?.bluecoseloader : false)
  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.formblueaspediitt) ? state.formSlice?.formSubmissionState?.formblueaspediitt : 0)
  // const formblueediittotherRadioButtonClicked = useSelector((state) => (state.formSlice?.formData?.formblueediitt && Object.keys(state.formSlice.formData.formblueediitt)) ? state.formSlice.formData.formblueediitt : undefined)
  const [locationArrys, setLocationArrys] = useState([]);
  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
  useEffect(() => {
    if (repid) dispatch(fetchbluecoasteasplead(repid))
  }, [repid])

  const forceLOgindata = useSelector(state => (state.LandingpagefontReducer?.forcelogindata) ? state.LandingpagefontReducer?.forcelogindata : null)

  const forceloginapicallfalg = useSelector(state => (state.LandingpagefontReducer?.forceloginapicall) ? state.LandingpagefontReducer?.forceloginapicall : false)

  const forceloginapicalLloader = useSelector(state => (state.LandingpagefontReducer?.forceloginapicallloader) ? state.LandingpagefontReducer?.forceloginapicallloader : false)


  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
  }, []);



  useEffect(() => {
    axios.get(
      process.env.REACT_APP_CRON_HIT_URL)
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
  }, [])



  // useEffect(() => {
  //   console.log("editAspdatablue>>>>1234", editAspdatablue);
  //   if (editAspdatablue) {
  //     let formField = { ...formblueeAspedit.current }
  //     console.log("hitt");
  //     for (let i in formField.fields) {
  //       console.log("hitt 2", formField.fields[i], editAspdatablue[formField.fields[i].name]);

  //       if (editAspdatablue[formField.fields[i].name] && formField.fields[i]) {
  //         console.log("hitt 3");

  //         formField.fields[i] = { ...formField.fields[i], defaultValue: editAspdatablue[formField.fields[i].name] }
  //       }
  //     }
  //     if (editAspdatablue.other || (editAspdatablue.medicalfacilitytype && editAspdatablue.medicalfacilitytype == 'Other')) {

  //       let field = {

  //         id: 35,
  //         // heading: "Other",
  //         label: "Other",
  //         name: "other",
  //         className: 'formfild formfild1',
  //         // multiple: true,
  //         type: "text",

  //         defaultValue: editAspdatablue.other ? editAspdatablue.other : undefined
  //       };
  //       setTimeout(() => dispatch(setField({ formId: "formblueediitt", after: "medicalfacilitytype", fieldData: field })), 200);
  //     }
  //     if (editAspdatablue._id) {
  //       formField.formAdditionalSubmissionData['_id'] = editAspdatablue._id
  //     }

  //   }
  // }, [JSON.stringify(editAspdatablue)])

  // useEffect(() => {
  //   console.log("formblueediittotherRadioButtonClicked", formblueediittotherRadioButtonClicked);
  //   if (formblueediittotherRadioButtonClicked && formblueediittotherRadioButtonClicked.medicalfacilitytype) {
  //     if (formblueediittotherRadioButtonClicked.medicalfacilitytype && formblueediittotherRadioButtonClicked.medicalfacilitytype == 'Other') {

  //       console.log("formblueediittotherRadioButtonClicked", formblueediittotherRadioButtonClicked);


  //       let field = {

  //         id: 35,
  //         // heading: "Other",
  //         label: "Other",
  //         name: "other",
  //         className: 'formfild formfild1',
  //         // multiple: true,
  //         type: "text",
  //         defaultValue: ''

  //       };
  //       setTimeout(() => dispatch(setField({ formId: "formblueediitt", after: "medicalfacilitytype", fieldData: field })), 200);


  //     } else {
  //       setTimeout(() => dispatch(removeField({ formId: "formblueediitt", name: "other" })), 2000)

  //     }


  //   }

  // }, [JSON.stringify(formblueediittotherRadioButtonClicked)])
  const modalopen = () => {
    setOpen(true)
  }

  // let existingUserInfo = cookies.get('userinfo') ? cookies.get('userinfo') : {}

  const redirectandcookieset = async () => {
    let userInfodata = {
      "main_role": forceLOgindata.item.main_role,
      ...forceLOgindata.item.results[0]
    }
    await cookies.remove("userinfo", { path: "/" });
    await cookies.remove("lastLoginTime", { path: "/" });

    await cookies.set('userinfo', userInfodata, { path: '/' });
    await cookies.set('token', forceLOgindata.token, { path: '/' });
    await cookies.set('lastLoginTime', new Date().getTime(), { path: '/' });
    if (!userInfodata.last_login_time) {
      userInfodata.last_login_time = new Date().getTime()
    }
    let dataset = {
      "userinfo": userInfodata,
      "token": forceLOgindata.token
    }
    await dispatch(setLogedinUserInfo(dataset));
    setloader(false)
    navigate('/new-ae-dashboard');
    dispatch(clearforceloginapidata())
  }
  useEffect(() => {
    if (forceloginapicallfalg && forceLOgindata) {
      redirectandcookieset()
      // console.warn(existingUserInfo, "existingUserInfo>>>>");
    }
  }, [JSON.stringify(forceLOgindata), forceloginapicallfalg])

  const backToDashboard = async () => {
    setloader(true)
    // let data = {
    //   "email": editAspdatablue.email,
    //   "password": editAspdatablue.email,
    //   "_id": editAspdatablue._id,
    //   "login_time": Math.round(new Date().getTime()),
    //   "login_data": ipinfo
    // }
    navigate("/login") //---------------force login stopped
    // dispatch(forcelogin(data))



  }

  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      console.log("Newarr+", Newarr);
      setLocationArrys(Newarr);
    }
  }, []);
  const formblueeAspedit = {
    id: 'formblueaspediitt',
    formtype: "edit",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    // resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      '_id': editAspdatablue && editAspdatablue._id ? editAspdatablue._id : undefined,
      // "status": 0,
      // "roles": { is_rep: 1 },
    },
    fields: [{
      id: 0,
      heading: "Your First Name",
      label: " First Name",
      name: "firstname",
      className: 'formfild formfild4',
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.firstname !== undefined) ? editAspdatablue.firstname : undefined,
    },
    {
      id: 15,
      heading: "Your Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'formfild formfild4',
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.lastname !== undefined) ? editAspdatablue.lastname : undefined,
    },
    {
      id: 13,
      heading: "Your Email",
      label: "Email",
      name: "email",
      className: 'formfild formfild4',
      type: "text",
      inputType: "email",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.email !== undefined) ? editAspdatablue.email : undefined,
    },
    {
      id: 14,
      heading: "Your Phone Number",
      label: "Phone Number",
      name: "phone",
      className: 'formfild formfild4',
      type: "text",
      inputType: "phone",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.phone !== undefined) ? editAspdatablue.phone : undefined,
    },
    //   {
    //     id: 25,
    //     heading: "SELECT HM ",
    //     label: "SELECT HM ",
    //     name: "hm_id",
    //     className: 'inputBlock usertypebox inputBlock4line',
    //     type: "select",
    //     other: true,
    //     values: [],
    //     // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',

    //     // inputType: "number",/////////////// If not mentioned default will be text //////////////
    //     // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
    //     rules: { required: true },
    //     errorMessage: {
    //       required: "This Field is Required",
    //       custom: "Value is Invalid"
    //     },

    //   },
    // {
    //   id: 25,
    //   heading: " Hiring Manager ",
    //   label: "Hiring Manager",
    //   name: "hm_id",
    //   type: "autoComplete",
    //   className: 'list_search_item hmSelection formfild6 ',
    //   multiple: false,
    //   base_url: process.env.REACT_APP_API_URL + "users/autocomplete-hm-data",
    //   reqBody: {
    //     "formId": 'searchtestimonial',
    //     "condition": {
    //       "limit": 10,
    //       "skip": 0
    //     },
    //     "sort": {

    //     },
    //     "searchcondition": {
    //       "source": 'old_system',
    //       "main_access": 0,
    //       'roles.hr_manager': 1
    //     },

    //   },
    //   sx: { m: 1, minWidth: 300 },
    //   defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue?.hm_name && editAspdatablue?.hm_name.length > 0 && editAspdatablue?.hm_name[0].name !== undefined) ? editAspdatablue.hm_name[0]._id : undefined,
    // },
    {
      id: 26,
      heading: "Address",
      placeholder: 'Address',
      label: "Address",
      name: "address",
      className: 'formfild textareafild',
      type: "textarea",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.address !== undefined) ? editAspdatablue.address : undefined,
    },
    {
      id: 6,
      heading: "State",
      label: "Select a State",
      name: "state",
      className: 'list_search_item formfild6',
      type: "select",
      // other: true,
      values: locationArrys,
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.state !== undefined) ? editAspdatablue.state : undefined,
    },
    {
      id: 7,
      heading: "City",
      label: "City",
      name: "city",
      className: 'inputBlock formfild6',
      type: "text",
      // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.city !== undefined) ? folderdata.city : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.city !== undefined) ? editAspdatablue.city : undefined,
    },
    {
      id: 8,
      heading: "Zip",
      label: "Zip Code",
      name: "zip",
      className: 'inputBlock formfild6',
      type: "text",
      // inputType:"number",
      inputType: "zip",
      // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.zip !== undefined) ? folderdata.zip : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.zip !== undefined) ? editAspdatablue.zip : undefined,
    },
      // {
      //   id: 20,
      //   heading: "Any other information we should know?",
      //   placeholder: 'Any other information we should know?',
      //   label: "Any other information we should know?",
      //   name: "other_info",
      //   className: 'formfild textareafild',
      //   type: "textarea",
      //   // rules: { required: true },
      //   // errorMessage: {
      //   //   required: "This Field is Required",
      //   //   custom: "Value is Invalid"
      //   // }
      //   defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.other_info !== undefined) ? editAspdatablue.other_info : undefined,
      // },
    ]
  }
  const formblueeAspeditforphar = {
    id: 'formblueaspediitt',
    formtype: "edit",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    // msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    // resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      '_id': editAspdatablue && editAspdatablue._id ? editAspdatablue._id : undefined,
      // "status": 0,
      // "roles": { is_rep: 1 },
    },
    fields: [{
      id: 0,
      heading: "Your First Name",
      label: " First Name",
      name: "firstname",
      className: 'formfild formfild4',
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.firstname !== undefined) ? editAspdatablue.firstname : undefined,
    },
    {
      id: 15,
      heading: "Your Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'formfild formfild4',
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.lastname !== undefined) ? editAspdatablue.lastname : undefined,
    },
    {
      id: 13,
      heading: "Your Email",
      label: "Email",
      name: "email",
      className: 'formfild formfild4',
      type: "text",
      inputType: "email",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.email !== undefined) ? editAspdatablue.email : undefined,
    },
    {
      id: 14,
      heading: "Your Phone Number",
      label: "Phone Number",
      name: "phone",
      className: 'formfild formfild4',
      type: "text",
      inputType: "phone",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.phone !== undefined) ? editAspdatablue.phone : undefined,
    },
    //   {
    //     id: 25,
    //     heading: "SELECT HM ",
    //     label: "SELECT HM ",
    //     name: "hm_id",
    //     className: 'inputBlock usertypebox inputBlock4line',
    //     type: "select",
    //     other: true,
    //     values: [],
    //     // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',

    //     // inputType: "number",/////////////// If not mentioned default will be text //////////////
    //     // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
    //     rules: { required: true },
    //     errorMessage: {
    //       required: "This Field is Required",
    //       custom: "Value is Invalid"
    //     },

    //   },

    {
      id: 26,
      heading: "Address",
      placeholder: 'Address',
      label: "Address",
      name: "address",
      className: 'formfild textareafild',
      type: "textarea",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
      },
      // rules: { required: true },
      // errorMessage: {
      //   required: "This Field is Required",
      //   custom: "Value is Invalid"
      // }
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.address !== undefined) ? editAspdatablue.address : undefined,
    },
    {
      id: 6,
      heading: "State",
      label: "Select a State",
      name: "state",
      className: 'list_search_item formfild6',
      type: "select",
      // other: true,
      values: locationArrys,
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.state !== undefined) ? editAspdatablue.state : undefined,
    },
    {
      id: 7,
      heading: "City",
      label: "City",
      name: "city",
      className: 'inputBlock formfild6',
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
      },
      // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.city !== undefined) ? folderdata.city : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      // errorMessage: {
      //   required: "This Field is Required",
      //   custom: "Value is Invalid"
      // },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.city !== undefined) ? editAspdatablue.city : undefined,
    },
    {
      id: 8,
      heading: "Zip Code",
      label: "Zip Code",
      name: "zip",
      className: 'inputBlock formfild6',
      type: "text",
      // inputType:"number",
      inputType: "zip",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
      },
      // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.zip !== undefined) ? folderdata.zip : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      // errorMessage: {
      //   required: "This Field is Required",
      //   custom: "Value is Invalid"
      // },
      defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.zip !== undefined) ? editAspdatablue.zip : undefined,
    },
      // {
      //   id: 20,
      //   heading: "Any other information we should know?",
      //   placeholder: 'Any other information we should know?',
      //   label: "Any other information we should know?",
      //   name: "other_info",
      //   className: 'formfild textareafild',
      //   type: "textarea",
      //   // rules: { required: true },
      //   // errorMessage: {
      //   //   required: "This Field is Required",
      //   //   custom: "Value is Invalid"
      //   // }
      //   defaultValue: (editAspdatablue && Object.keys(editAspdatablue).length > 0 && editAspdatablue.other_info !== undefined) ? editAspdatablue.other_info : undefined,
      // },
    ]
  }


  useEffect(() => {
    console.log("submissionState", submissionState);
    if (submissionState && submissionState == 2) {
      dispatch(
        setSnackbar({ open: true, message: 'Updated successfully!', status: 'success' })
      )
      editapicall()
    }
  }, [submissionState])

  const editapicall = async () => {
    dispatch(fetchbluecoasteasplead(repid))
    setOpen(false)
  }

  const [doneBtnSuccess, setDoneBtnSuccess] = useState(1)

  const donebtnclicked = () => {
    setDoneBtnSuccess(2)
    dispatch(
      setSnackbar({ open: true, message: 'Successfully Done!', status: 'success' })
    )

  }
  return (
    <div>
      <div className='thankYou_wrpr blueCoast_Success'>
        <div className='thankYou_bgdesgn'>
          <div className="bgdesgn1"></div>
          <div className="bgdesgn2"></div>
          <div className="bgdesgn3"></div>
          <div className="bgdesgn4"></div>
          <div className="bgdesgn5"></div>
          <div className="bgdesgn6"></div>
          <div className="bgdesgn7"></div>
          <div className="bgdesgn8"></div>
        </div>
        <div className='thankyou-container'>
          <div className='thankyou-block'>

            <div className='content center'>
              {/* <h1>Successful, please review your submission.  YOU WILL RECEIVE A COPY VIA EMAIL FOR YOUR RECORDS.  </h1> */}
              <h1> <span>Success!</span>
                <span>Following are the details you submitted. You will also receive a copy of this in your email from </span><span> DO-NOT-REPLY@dealfirecrm.com.</span>
              </h1>
              {/* {!params && !params.rep && <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>}  */}
              {repid ? <div className='thankyou-containerSub'>
                <div className='thankyou-blockSub'>
                  {(loading || loader || forceloginapicalLloader) && <Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>}
                  <div className="landingpage9successBody">
                    {/* <div className="editiconinsucccess" onClick={() => modalopen()}>
                      <EditIcon />
                    </div> */}
                    {!loading && editAspdatablue && <div className="landingpage9success">

                      <p><strong>Your Name:</strong> <span> {editAspdatablue.firstname} {editAspdatablue.lastname} </span> </p>
                      <p><strong>Your Email:</strong> <span> {editAspdatablue.email} </span> </p>
                      <p><strong>Your Phone Number:</strong> <span> {editAspdatablue.phone} </span> </p>
                      {editAspdatablue.hm_name && editAspdatablue.hm_name[0] && <p><strong> Hiring Manager Name :</strong> <span> {editAspdatablue.hm_name[0].name} </span> </p>}
                      <p><strong> Address:</strong> <span> {editAspdatablue.address} </span> </p>
                      <p><strong> State:</strong>  {editAspdatablue?.state ? <span>{editAspdatablue.state}</span> : <span>NA</span>}  </p>
                      <p><strong> City:</strong> {editAspdatablue?.city ? <span>{editAspdatablue.city}</span> : <span>NA</span>} </p>
                      <p><strong> Zip Code:</strong> {editAspdatablue?.zip ? <span>{editAspdatablue.zip}</span> : <span>NA</span>}  </p>
                      {/* <p><strong> Any other information we should know? :</strong>  {editAspdatablue?.other_info ? <span>{editAspdatablue.other_info} </span> : <span>NA</span>} </p> */}

                      <div className="bttnWrpr">

                        {/* {doneBtnSuccess == 1 && <div className="editiconinsucccess" onClick={() => modalopen()}>
                          Edit

                        </div>} */}
                        {/* <div className="editiconinsucccess" onClick={() => donebtnclicked()}>Done</div> */}
                      </div>


                      <button className='b2dashboard' onClick={backToDashboard}>Confirm and Go to Login <DashboardIcon /></button>


                    </div>}
                  </div>
                </div>
              </div> : <p>Greeting for your interest in Access. Our team will verify your details and contact you as soon as possible.</p>}
            </div>
          </div>
        </div>
      </div>

      {editAspdatablue && <Modal
        className="modalblock successBlucoastMOdal"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="BoxMui_modal successmodal9" sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}>
          <h2>Please confirm the information below</h2>

          <Form formData={process.env.REACT_APP_LANDINGPAGE_SLUG == 'NycjyqRnjFUEd' ? formblueeAspedit : formblueeAspeditforphar} />

          {/* -------------------------------------- */}

          <Button onClick={() => setOpen(false)} className="closeModalBtn">
            <Tooltip title="Close" placement="right-start">
              <CloseIcon />
            </Tooltip>
          </Button>
        </Box>
      </Modal>}
    </div>
  );
}

export default SuccessBlueCoast