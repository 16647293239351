import React, { useEffect, useRef, useState } from 'react'
import '../LandingPage19.css'
import { Button, IconButton, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ListingTable from '../../../../../listing/listing';
import { MedicineListing, clearMedicineList } from '../../../../Backend/Medicine/MedicineReducer';
import { deleteTempSearchEndpoint, setReloadTableFlag } from '../../../../../listing/listReducer';
import { resetFormData, resetautocompletelist } from '../../../../../form/formReducer';

export default function Landingpage19Block1({ scrollToview }) {
  const [loading, setloading] = useState(false);
  const [openMoadl2, setopenMoadl2] = useState(false)
  const dispatch = useDispatch();
  // const [scroll, setScroll] = useState(false);
  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };
  const [open, setOpen] = React.useState(false);
  const medcat = useSelector((state) => (state.medicineSlice?.medicinedata && state.medicineSlice?.medicinedata.length > 0) ? state.medicineSlice.medicinedata : [])
  const loadingInMedCat = useSelector(state => state.medicineSlice?.loading ? state.medicineSlice.loading : false);


  const handleClose = () => setOpen(false);

  const openModal = () => {
    setopenMoadl2(true);
  };
  const closeModal = () => {
    setopenMoadl2(false);
  };

  // ===================================drug list toggle button and data function==================================
  const [menuToggle, setmenuToggle] = useState(1)


  const menuToggleSelected = (val) => {

    setmenuToggle(val)

    if (val == 2) {
      tableDataForMedCatlist.current.reqBody.searchcondition = {};
      tableDataForMedCatlist.current.reqBody.searchcondition["is_allmedicine"] = {$eq: true};
      // tableDataForMedCatlist.current.reqBody.condition["skip"] = 0;
      tableDataForMedCatlist?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
     
  
      
      tableDataForMedCatlist.current.tableTitle = "Additional Discounted Medical Items"
     
      dispatch(setReloadTableFlag({ tableId: "medcatlist" }))
      dispatch(deleteTempSearchEndpoint({ tableId: "medcatlist" }));
  
      dispatch(resetFormData({ formId: "searchMedicineandCategoryList" }));
      dispatch(resetautocompletelist());
  
      
      dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody))
      dispatch(clearMedicineList())
   
      
    }
    else if (val == 1) {
      
      tableDataForMedCatlist.current.reqBody.searchcondition = {};
      // tableDataForMedCatlist.current.reqBody.condition["skip"] = 0;
     
      tableDataForMedCatlist.current.tableTitle = "Free Medical Items"
      tableDataForMedCatlist?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody.searchcondition) {
            delete data.reqBody.searchcondition;
          }
        }
      );
     
      dispatch(setReloadTableFlag({ tableId: "medcatlist" }))
      dispatch(deleteTempSearchEndpoint({ tableId: "medcatlist" }));
      dispatch(resetFormData({ formId: "searchMedicineandCategoryList" }));
      dispatch(resetautocompletelist());
      
      dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody))
      dispatch(clearMedicineList())
      
  
    }
  }
  // ===================================drug list toggle button and data function==================================

  var modifyTableHeadersforMedCatlist =
    [

      { val: "name", name: "Medicine Name" },
      { val: "med_by_catagorey", name: "Medicine Category" },



    ];

  let tableDataForMedCatlist = useRef({
    tableId: "medcatlist",
    tableTitle: "Free Medical Items",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {

      // "source": "training_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["name", "med_by_catagorey"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      // {
      //   id: "alldruglist",
      //   type: "format_list_bulleted",
      //   title: "All Drug List",
      //   className: "format_list_bulleted",
      //   default: true,
      // },
      // {
      //   id: "backtoMain",
      //   type: "keyboard_backspace_icon",
      //   title: "Back to Main List",
      //   className: "keyboard_backspace_icon",
      //   default: true,
      // },
    ],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      // heading: "Search Medicine & Category List",
      //   type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchMedicineandCategoryList',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },

          },



        ]
      }
    },

  });
  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody));
  }, [])



  return (
    <>
      <div className='lp19_block1_main_wrapper'>
        <div className='common_width'>
          <div className="lp19_banner_new_sec">
            <div class="lp19_block1_sub1_sec1_wrapper">
              <div class="lp19_block1_sub1_sec1_text">
                <h1>
                  <strong>Click to search</strong> our formulary and see if
                  your medications are on the list.
                </h1>
              </div>
              <div class="lp19_block1_sub1_sec2_text">
                <div class="Lp14block2_sub1_wrapper">
                  <button onClick={() => setOpen(true)} >SEE THE DRUG LIST</button>
                </div>
              </div>
            </div>
          </div>
          <div className="lp19_banner_new1_sec">
            <p>
              If your medication is not on our list, don’t worry.{" "}
              <span> We have a team of licensed pharmacists to work</span>{" "}
              with you and your doctor to see if moving you over to a
              therapeutically similar free medication with your prescription
              membership makes sense. Simply call <a className='linked_text' href='tel:1-800-378-4025'>1-800-378-4025</a> to find
              out.{" "}
            </p>
          </div>
          <div className='lp19_block1_sub_wrapper'>

            <div className="lp19_block1_5_ease_steps">
              <div className='lp19_banner_new2_sec'>
                <p><span>We are America’s first <span>Free Pharmacy</span></span>. And we are one of the Nation’s largest and most trusted pharmacies, with all our prescription medications manufactured here in the U.S.
                </p>
                <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/medications_blue.webp' />

              </div>
              <div className='lp19_block1_jim_gail'>
                <div className='lp19_block1_jim_gail_img'>
                  <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/jim%26gali-img.webp' />
                </div>
                <div className='lp19_block1_jim_gail_text'>
                  <h2>Jim & Gail C.</h2>

                  <img src='' />
                  <p>We were paying $232 per month, and now we pay nothing for our monthly prescription medications with our $31.99 family <span>plan.</span>
                  </p>

                </div>
              </div>
              <h1 className='blackh1'>5 Easy steps:</h1>
              <div className='lp19_block1_steps_Main_wrpr'>
                <div className="lp19_block1_steps_sub_wrpr_greenBG">
                  <div className="lp19_block1_steps_sub_wrpr">

                    <div className="lp19_block1_steps_blocks">
                      <div className="lp19_block1_steps_num_text_logo">
                        <div className="lp19_block1_steps_num_text">
                          <h2>Step</h2>
                          <h1>1</h1>
                        </div>
                        <div className="lp19_block1_steps_logo">
                          <img
                            //  src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/LP19_block1_seps_logo1.png" />
                            src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/LP19_block1_seps_logo1.png`} />
                        </div>
                      </div>
                      <div className='lp19_block1_steps_desc'>
                        <p>Enroll right here on our page</p>
                      </div>
                    </div>
                    <div className='lp19_block1_steps_arrow'>
                      <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_steps_arrow.webp`}
                      // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_steps_arrow.webp" 
                      />
                    </div>

                    <div className="lp19_block1_steps_blocks">
                      <div className="lp19_block1_steps_num_text_logo">
                        <div className="lp19_block1_steps_num_text h1Color1">
                          <h2>Step</h2>
                          <h1>2</h1>
                        </div>
                        <div className="lp19_block1_steps_logo">
                          <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/LP19_block1_seps_logo2.webp`}
                          // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/LP19_block1_seps_logo2.webp"
                          />
                        </div>
                      </div>
                      <div className='lp19_block1_steps_desc'>
                        <p>Log into our members portal</p>
                      </div>
                    </div>
                    <div className='lp19_block1_steps_arrow'>
                      <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_steps_arrow.webp`}
                      // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_steps_arrow.webp" 
                      />
                    </div>

                    <div className="lp19_block1_steps_blocks">
                      <div className="lp19_block1_steps_num_text_logo">
                        <div className="lp19_block1_steps_num_text h1Color2">
                          <h2>Step</h2>
                          <h1>3</h1>
                        </div>
                        <div className="lp19_block1_steps_logo">
                          <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/LP19_block1_seps_logo3.webp`}
                          // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/LP19_block1_seps_logo3.webp" 
                          />
                        </div>
                      </div>
                      <div className='lp19_block1_steps_desc'>
                        <p>Put in your physicians info</p>
                      </div>
                    </div>
                    <div className='lp19_block1_steps_arrow'>
                      <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_steps_arrow.webp`}
                      // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_steps_arrow.webp" 
                      />
                    </div>

                    <div className="lp19_block1_steps_blocks">
                      <div className="lp19_block1_steps_num_text_logo">
                        <div className="lp19_block1_steps_num_text h1Color3">
                          <h2>Step</h2>
                          <h1>4</h1>
                        </div>
                        <div className="lp19_block1_steps_logo">
                          <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/LP19_block1_seps_logo4.webp`}
                          // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/LP19_block1_seps_logo4.webp" 
                          />
                        </div>
                      </div>
                      <div className='lp19_block1_steps_desc'>
                        <p>Our Pharmacists will request your scripts</p>
                      </div>
                    </div>
                    <div className='lp19_block1_steps_arrow'>
                      <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_steps_arrow.webp`}
                      // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_steps_arrow.webp"
                      />
                    </div>

                    <div className="lp19_block1_steps_blocks">
                      <div className="lp19_block1_steps_num_text_logo">
                        <div className="lp19_block1_steps_num_text h1Color4">
                          <h2>Step</h2>
                          <h1>5</h1>
                        </div>
                        <div className="lp19_block1_steps_logo">
                          <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/LP19_block1_seps_logo5.webp`}
                          // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/LP19_block1_seps_logo5.webp" 
                          />
                        </div>
                      </div>
                      <div className='lp19_block1_steps_desc'>
                        <p>We will send your prescriptions directly to your door every month!</p>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>



            <div class="Lp14block2_sub1_wrapper">
              <button onClick={() => setScroll()} >Enroll Today</button>
            </div>
            <div class="Lp14block2_sub2_wrapper">
              <div class="Lp14block2_sub2_sec_wrapper">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_1logo.png`}
                // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_1logo.png" 
                />
                <div class="Lp14block2_sub2_text_sec_wrapper">
                  <p>Pay $0 for your medications</p>
                </div>
              </div>
              <div class="Lp14block2_sub2_sec_wrapper">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_2logo.png`}
                // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_2logo.png"
                />
                <div class="Lp14block2_sub2_text_sec_wrapper">
                  <p>No limit on orders/refills</p>
                </div>
              </div>
              <div class="Lp14block2_sub2_sec_wrapper">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_3logo.png`}
                // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_3logo.png"
                />
                <div class="Lp14block2_sub2_text_sec_wrapper">
                  <p>Add new covered medications anytime</p>
                </div>
              </div>
              <div class="Lp14block2_sub2_sec_wrapper">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_4logo.png`}
                // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_4logo.png"
                />
                <div class="Lp14block2_sub2_text_sec_wrapper">
                  <p>Licensed in all 50 US States</p>
                </div>
              </div>
              <div class="Lp14block2_sub2_sec_wrapper">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_5logo.png`}
                // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_5logo.png" 
                />
                <div class="Lp14block2_sub2_text_sec_wrapper">
                  <p>Free Standard Shipping</p>
                </div>
              </div>
              <div class="Lp14block2_sub2_sec_wrapper">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_6logo.png`}
                // "https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_6logo.png"
                />
                <div class="Lp14block2_sub2_text_sec_wrapper">
                  <p>Licensed Pharmacists &amp; Experienced Customer Service Team</p>
                </div>
              </div>
            </div>

            <div className='lp19_block1_sub1_wrapper'>
              <img className='medicine_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block1/lp19_bl1_1pst_img.webp`}
              // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block1/lp19_bl1_1pst_img.webp' 
              />
              <div className='lp19_block1_sub1_new_wrapper'>
                <p>All packages that leave our facility are scanned and <span>tracked through USPS</span>’ state-of-the-art tracking system so that you always know where your package is until it arrives at your door. </p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div>
        <Modal
          className="modalBaseStyle   MOdallanding13 SD_MOdallanding SR_ClassModal lpage19_modal sr_toggle_btn"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={handleClose}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <div className="rolelist_mainwrp">

                <div className='listing_heading'>

                  <h1>Access Free Pharmacy Medicine</h1>

                </div>


                <div className="bluecoastLead_list_mainwrpr">
                  <div className='click_buttonwp navigationMain'>

                    <Button
                      id="buttonzero"
                      className={menuToggle === 1 ? "active" : ""}
                      variant="contained"
                      onClick={() => menuToggleSelected(1)}
                    >
                      Free Medical Items
                    </Button>

                    <Button
                      id="buttonone"
                      className={menuToggle === 2 ? "active" : ""}
                      variant="contained"
                      onClick={() => menuToggleSelected(2)}
                    >
                      Additional Discounted Medical Items
                    </Button>
                  </div>
                </div>


                {/* <Paper>
  <Button variant='contained' className='addrole_btn' ></Button>
</Paper> */}
                <div className='rolelist_mainwrp_Table'>
                  {!loadingInMedCat ? (
                    <ListingTable
                      tableData={tableDataForMedCatlist.current}
                      dataset={medcat}
                      modifyHeaders={modifyTableHeadersforMedCatlist}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}

                </div>
              </div>
            </div>

          </Box>
        </Modal>
      </div>



    </>
  )
}
