import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Button, IconButton, LinearProgress, Modal, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { commissionPartnerSetUp } from '../userListReducer';
import store from '../../../../../store';
import { addupdatetraffciPartnerCommission } from '../../../Commission/commissionReducer';
import { setReloadTableFlag } from '../../../../../listing/listReducer';


const TrafficPartnerCommission = ({ commissionTraffic, setCommissionTraffic }) => {

    const dispatch = useDispatch();

    const [submitState, setSubmitState] = useState(1);

    const commissiondataLoader = useSelector(state => (state.commissionReducer?.commissionLoader) ? state.commissionReducer?.commissionLoader : false)

    const [userRowData, setUserRowData] = useState({}) //---------------row data for partnerlist

    // ----- Temp Row data for partner list --------
    const rowDataforCommission = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1 ? state.tableSlice?.tempRowData?.userslist1 : {});

    //--------------programme data prepopulate----------------

    const partnerDataCommission = useSelector(state => (state.userManagment?.viewProgrammeDatafull) ? state.userManagment?.viewProgrammeDatafull : [])

    //------------temp row data stores in state-----------
    useEffect(() => {
        if (rowDataforCommission && Object.keys(rowDataforCommission).length > 0) {
            console.log("rowData========>>>>", rowDataforCommission)
            setUserRowData(rowDataforCommission)
        }
    }, [JSON.stringify(rowDataforCommission)])

    useEffect(() => {
        console.log("userRowData====>>>>>>", userRowData);
    }, [userRowData])


    // -------------------Temprow data for traffic partnercommisn----------------
    const [trafficCommisn, setTrafficCommisn] = useState("")
    const [trafficCommisnSingle, setTrafficCommisnSingle] = useState("")
    const [trafficCommisnSingleOne, setTrafficCommisnSingleOne] = useState("")

    useEffect(() => {
        if (rowDataforCommission?.trafficPartnercommissions?.Family?.traffic_partner_commission !== undefined) {
            setTrafficCommisn(rowDataforCommission?.trafficPartnercommissions?.Family?.traffic_partner_commission)
        } if (rowDataforCommission?.trafficPartnercommissions?.Single?.traffic_partner_commission !== undefined) {
            setTrafficCommisnSingle(rowDataforCommission?.trafficPartnercommissions?.Single?.traffic_partner_commission)
        } if (rowDataforCommission?.trafficPartnercommissions?.["Single+1"]?.traffic_partner_commission !== undefined) {
            setTrafficCommisnSingleOne(rowDataforCommission?.trafficPartnercommissions?.["Single+1"]?.traffic_partner_commission)
        }
    }, [JSON.stringify(rowDataforCommission)])

    // useEffect(() => {

    //     console.log("trafficCommisn==>>>>", trafficCommisn)

    // }, [trafficCommisn])



    // -----------------------api trigger for partnerdata---------------------
    useEffect(() => {
        if (partnerDataCommission) {
            console.log('partnerDataCommission===========>>>>>>>>1', partnerDataCommission)
            let reQBody = {
                condition: {
                    limit: 30,
                    skip: 0,
                },
                sort: {
                    field: "name",
                    type: "asc",
                },
                searchcondition: {},
                project: {},
                token: "",
                count: false,
            }
            dispatch(commissionPartnerSetUp(reQBody))
            console.log('partnerDataCommission===========>>>>>>>>2', partnerDataCommission)

        }
    }, [])

    // ---------------------traffic partner commission field event--------------------

    const [eventTrafficData, setEventTrafficData] = useState("")
    const [eventTrafficDataSingle, setEventTrafficDataSingle] = useState("")
    const [eventTrafficDataSingleone, setEventTrafficDataSingleone] = useState("")

    const trafficDatacommsson = (e) => {
        console.log("e================>>>>>>>1", e);
        if (e.target.name == "commissionFamily" && e.target.value.length > 0) {
            setTrafficCommisn("")
        }
        if (e.target.name == "commissionSingle" && e.target.value.length > 0) {
            setTrafficCommisnSingle("")


        }
        if (e.target.name == "commissionSingleOne" && e.target.value.length > 0) {
            setTrafficCommisnSingleOne("")


        }
        if (e.target.name == "commissionFamily") {
            console.log("e.target.name", e.target.name);

            setEventTrafficData(e.target.value)
        } else if (e.target.name == "commissionSingle") {
            console.log("e.target.name", e.target.name);

            setEventTrafficDataSingle(e.target.value)

        } else if (e.target.name == "commissionSingleOne") {
            console.log("e.target.name", e.target.name);

            setEventTrafficDataSingleone(e.target.value)

        }
        // setTrafficCommisn()

    }

    useEffect(() => {
        console.log("e================>>>>>>>2", eventTrafficData);

    }, [eventTrafficData])




    // ---------------------submit for commssion set up---------------

    const addCommissionObj = async (val) => {
        console.log("val================>>>>>", val)

        setSubmitState(2)

        let Body = {
            _id: userRowData?._id,
            trafficPartnercommissions: {
                Family: {
                    package_name: userRowData.commissionsdata.Family.package_name,
                    package_price: userRowData.commissionsdata.Family.package_price,
                    partner_commission_value: userRowData.commissionsdata.Family.partner_commission_value,
                    traffic_partner_commission: eventTrafficData ? eventTrafficData : trafficCommisn
                },
                Single: {
                    package_name: userRowData.commissionsdata.Single.package_name,
                    package_price: userRowData.commissionsdata.Single.package_price,
                    partner_commission_value: userRowData.commissionsdata.Single.partner_commission_value,
                    traffic_partner_commission: eventTrafficDataSingle ? eventTrafficDataSingle : trafficCommisnSingle
                },
                "Single+1": {
                    package_name: userRowData.commissionsdata["Single+1"].package_name,
                    package_price: userRowData.commissionsdata["Single+1"].package_price,
                    partner_commission_value: userRowData.commissionsdata["Single+1"].partner_commission_value,
                    traffic_partner_commission: eventTrafficDataSingleone ? eventTrafficDataSingleone : trafficCommisnSingleOne
                }
            }
        }
        console.log("Body==============>>>>>>", Body)


        if (Object.keys(Body).length > 0) {
            await store.dispatch(addupdatetraffciPartnerCommission(Body))
            setCommissionTraffic(false)

            dispatch(setReloadTableFlag({ tableId: "userslist1" }));

            // dispatch()
        }
    }


    //-------------Main Modal Close Functioan-------------
    const handleCloseConfigurTarffic = () => {
        setCommissionTraffic(false)
        setTrafficCommisn("")
        setTrafficCommisnSingle("")
        setTrafficCommisnSingleOne("")
        setEventTrafficData("")
        setEventTrafficDataSingle("")
        setEventTrafficDataSingleone("")
        dispatch(setReloadTableFlag({ tableId: "userslist1" }));

    }


    return (
        <>
            <Modal
                className="modalBaseStyle  package_modal_commission"
                open={commissionTraffic}
                onClose={handleCloseConfigurTarffic}
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseConfigurTarffic}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className="view_details">
                        <div className="modal_title modal_titlenew SD_bluecoast_preview">
                            <h2 className="previewModalTitle "> Configure Commssion for Traffic Partner</h2>
                        </div>
                        <div className="modalview_group">
                            <div className='package_fields_details'>

                                {/* <h6>{JSON.stringify(userRowData.commissionsdata?.Family)}</h6> */}

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata.Family.package_name : ""}
                                    label="Package name (Family)"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata?.Family?.package_price : ""}
                                    label="Package Price"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata.Family.partner_commission_value : ""}
                                    label="AE Commission Amount"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={false}
                                    variant="outlined"
                                    // value={Object.keys(userRowData).length > 0 ? userRowData.trafficPartnercommissions.Family.traffic_partner_commission : eventTrafficData}
                                    value={eventTrafficData ? eventTrafficData : trafficCommisn ? trafficCommisn : ""}
                                    label="Traffic Partner Commission Amount"
                                    name="commissionFamily"
                                    sx={{ width: "22.5%" }}
                                    onChange={trafficDatacommsson}
                                    helperText={
                                        submitState === 2 && eventTrafficData?.length < 1 && trafficCommisn.length == 0
                                            ? "This field is required"
                                            : ""
                                    }
                                />

                            </div>

                            <div className='package_fields_details'>

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata.Single.package_name : ""}
                                    label="Package name (Single)"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata.Single.package_price : ""}
                                    label="Package Price"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata.Single.partner_commission_value : ""}
                                    label="AE Commission Amount"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={false}
                                    variant="outlined"
                                    value={eventTrafficDataSingle ? eventTrafficDataSingle : trafficCommisnSingle ? trafficCommisnSingle : ""}
                                    label="Traffic Partner Commission Amount"
                                    sx={{ width: "22.5%" }}
                                    name="commissionSingle"
                                    onChange={trafficDatacommsson}
                                    helperText={
                                        submitState === 2 && eventTrafficDataSingle?.length < 1 && trafficCommisnSingle.length == 0
                                            ? "This field is required"
                                            : ""
                                    }
                                />

                            </div>

                            <div className='package_fields_details'>

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    // value={Object.keys(userRowData).length > 0 ? userRowData.trafficPartnercommissions["Single+1"].package_name : ""}
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata["Single+1"].package_name : ""}
                                    label="Package name (Single+1)"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    // value={Object.keys(userRowData).length > 0 ? userRowData.trafficPartnercommissions["Single+1"].package_price : ""}
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata["Single+1"].package_price : ""}
                                    label="Package Price"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={true}
                                    variant="outlined"
                                    // value={Object.keys(userRowData).length > 0 ? userRowData.trafficPartnercommissions["Single+1"].partner_commission_value : ""}
                                    value={Object.keys(userRowData).length > 0 ? userRowData.commissionsdata["Single+1"].partner_commission_value : ""}
                                    label="AE Commission Amount"
                                    sx={{ width: "22.5%" }}
                                />

                                <TextField
                                    hiddenLabel
                                    id="outlined-basic"
                                    disabled={false}
                                    variant="outlined"
                                    value={eventTrafficDataSingleone ? eventTrafficDataSingleone : trafficCommisnSingleOne ? trafficCommisnSingleOne : ""}
                                    label="Traffic Partner Commission Amount"
                                    name="commissionSingleOne"
                                    onChange={trafficDatacommsson}
                                    sx={{ width: "22.5%" }}
                                    helperText={
                                        submitState === 2 && eventTrafficDataSingleone?.length < 1 && trafficCommisnSingleOne.length == 0
                                            ? "This field is required"
                                            : ""
                                    }
                                />

                            </div>


                        </div>
                    </div>

                    <div className="w9_btn_wrp">

                        <Button className="w9_btn" onClick={addCommissionObj}>
                            Submit
                        </Button>


                        {commissiondataLoader && (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '0px' }}><LinearProgress /></Box>)}

                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default TrafficPartnerCommission