import { Chip } from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


function ShowStageListMap(props) {
  console.log("props",props);
  const [showvaluearary,setshowvaluearary]=useState(props.dataset)  
  return (
    <div>
      <div className='showStagelistMap'>
                                 {/* showvaluearary.slice(0).reverse().map((item) */}
                                {showvaluearary.map((item) => (
                                    <span className='tagChipwrp'>
                                        <Chip label={item?.stage_name} key={item.stage_slug} variant="outlined" />
                                        <QuestionMarkIcon className='qIcon' />


                                        <span className='chipDesBuble'>
                                            {/* {item?.tag_name} <br /> */}
                                        Added by: {item.added_by_name}<br />
                                        Added for: {item.added_for_name}<br />
                                        Date: {format (item.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                                        </span>
                                     
                                    </span>
                                ))}

                    </div>
    </div>
  )
}

export default ShowStageListMap