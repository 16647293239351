import * as React from 'react';
import { Suspense } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { accesscontroll } from '../helper/helperFunctions';

import Layout from "../Layout";

import JobticketsCategory from '../Pages/Backend/Jobtickets/JobticketsCategory/JobticketsCategory';
import JobticketsCategoryAddEdit from '../Pages/Backend/Jobtickets/JobticketsCategory/JobticketsCategoryAddEdit';
import AddEditJobtickets from '../Pages/Backend/Jobtickets/ManageJobtickets/AddEditJobtickets';
import ManageJobtickets from '../Pages/Backend/Jobtickets/ManageJobtickets/ManageJobtickets';
import Profile from '../Pages/Backend/myaccount/profile';
import { TrainingCenter } from '../Pages/Backend/Training/TrainingCenter/TrainingCenter';
import FolderView from '../Pages/Backend/userManagement/folderView/folderview';
import AddEdituser from '../Pages/Backend/userManagement/userAddEdit/userAddEdit';
import UserManagement from '../Pages/Backend/userManagement/userList/userList';
import Home from '../Pages/Frontend/Home/Home';
import LandingPage1 from '../Pages/Frontend/LandingPage/LandingPage1/LandingPage1';
import LandingPage2 from '../Pages/Frontend/LandingPage/LandingPage2/LandingPage2';
import LandingPage3 from '../Pages/Frontend/LandingPage/LandingPage3/LandingPage3';
import LandingPage4 from '../Pages/Frontend/LandingPage/LandingPage4/LandingPage4';
import ForgotPassword from '../Pages/login/forgotPassword';
import { Miscellaneous } from '../Pages/Calendar/Miscellaneous/Miscellaneous';
// import { BookedSlot } from '../Pages/Calendar/BookedSlot';

// Import Email Related Component from EmailTemplateManagement Folder...



// import Home from '../pages/Frontend/Home/Home';

import Login from '../Pages/login/login';
import ResetPassword from '../Pages/login/resetPassword';
import Noaccesspage from '../Pages/Backend/Noaccesspage'
// import NoPage from '../Pages/404page';

// import { ResetPassword } from '../Pages/ForgetPassword/ResetPassword';
// import { ForgotPassword } from '../Pages/ForgetPassword/ForgotPassword';
import store from '../store';
import ReplyJobticket from '../Pages/Backend/Jobtickets/ManageJobtickets/ReplyJobticket';

import RepAndAreaDashboard from '../Pages/Backend/RepAndAreaDashboard/RepAndAreaDashboard';
import ManageRoundRodin from '../Pages/Backend/ManageRoundRobin/ManageRoundRodin';
import { ThankyouPage } from '../Pages/Frontend/Thankyou';
import Success from '../Pages/Frontend/Success';
import SuccessBlueCoast from '../Pages/Frontend/SuccessBlueCoast';
import CancelSlot from '../Pages/Frontend/CancelSlot';
import AuditAnalyze from '../Pages/Frontend/LandingPage/LandingPage3/AuditAnalaize/AuditAnalyze';
import AudtingFrim from '../Pages/Frontend/LandingPage/LandingPage3/AudtingFrim.js/AudtingFrim';
import AuditTestimonial from '../Pages/Frontend/LandingPage/LandingPage3/AuditTestimonial/AuditTestimonial';
import LandingPage5 from '../Pages/Frontend/LandingPage/LandingPage5/LandingPage5';
import NoPage from '../Pages/404page';
import Auditbanner from '../Pages/Frontend/LandingPage/LandingPage3/AuditBanner/Auditbanner';
import OngoingManagement from '../Pages/Frontend/LandingPage/LandingPage3/OngoingManagement/OngoingManagement';
import AnalysisImplementation from '../Pages/Frontend/LandingPage/LandingPage3/AnalysisImplementation/AnalysisImplementation';
import OurPromises from '../Pages/Frontend/LandingPage/LandingPage3/OurPromises/OurPromises';
import WorkComp from '../Pages/Frontend/LandingPage/LandingPage3/WorkComp/WorkComp';
import LandingPage6 from '../Pages/Frontend/LandingPage/LandingPage6/LandingPage6';
import Patnership from '../Pages/Frontend/LandingPage/LandingPage6/Patnership/Patnership';
import BrandTestimonial from '../Pages/Frontend/LandingPage/LandingPage6/BrandTestimonial/BrandTestimonial';
import ClintInfoForm from '../Pages/ClintInfoForm/ClintInfoForm';
import LandingPage3Video from '../Pages/Frontend/LandingPage/LandingPage3Video/LandingPage3Video';
import LandingPage4Video from '../Pages/Frontend/LandingPage/LandingPage4Video/LandingPage4Video';
import LandingPage2Video from '../Pages/Frontend/LandingPage/LandingPage2Video/LandingPage2Video';
import ViewContractDetails from '../Pages/Backend/ContractManagement/Contract/ViewContractDetails';
import Faq from '../Pages/Backend/FaqManagement/Faq';
import FaqCategory from '../Pages/Backend/FaqManagement/FaqCategory';
import AddEditFaqCategory from '../Pages/Backend/FaqManagement/AddEditFaqCategory';
import AddEditFaq from '../Pages/Backend/FaqManagement/AddEditFaq';
import ContractSuccess from '../Pages/Backend/ContractManagement/Contract/ContractSuccess';
import LandingPage9 from '../Pages/Frontend/LandingPage/LandingPage9/LandingPage9';
import LandingPage10 from '../Pages/Frontend/LandingPage/LandingPage10/LandingPage10';
import LandingPage11 from '../Pages/Frontend/LandingPage/LandingPage11/LandingPage11';
import NakaGamesPage from '../Pages/Frontend/LandingPage/NakaGamesLandingPage/NakaGamesPage';
import { useSelector } from 'react-redux';
import { clearSethttprsponsedata, setHttpResponse, sethttprsponsedata } from '../helper/ResolveReducer';
import ConfigurCommissionList from '../Pages/Backend/Commission/commissionConfig/ConfigurCommissionList/ConfigurCommissionList';
import CommissionAddEdit from '../Pages/Backend/Commission/commissionConfig/ConfigureCommissionAddEdit/CommissionAddEdit';
import CommissionDistribution from '../Pages/Backend/Commission/commissionDistribution/commisionDistribution';
import AddCommission from '../Pages/Backend/Commission/AddCommission/CommissionAddEditForm/AddCommission';
import AddCommissionList from '../Pages/Backend/Commission/AddCommission/CommissionLisitng/ComissionListing';
import LandingPage14 from '../Pages/Frontend/LandingPage/LandingPage14/Components/LandingPage14';
import LandingPage15 from '../Pages/Frontend/LandingPage/LandingPage15/LandingPage15';
import LandingPage14Email from '../Pages/Frontend/LandingPage/LandingPage14/LandingPage14Email';
import PharmacysavingsBluecoast from '../Pages/Backend/Pharmacysavings Bluecoast/PharmacysavingsBluecoast';
import PharmacysavingsAspire from '../Pages/Backend/Pharmacysavings Apire/PharmacysavingsAspire';
import AffiliateAddEdit from '../Pages/Backend/AffiliateManagement/CreateAffiliate/AffiliateAddEdit';
import AffiliateListing from '../Pages/Backend/AffiliateManagement/ViewAffiliate/AffiliateListing';
import Successblueph from '../Pages/Frontend/Successblueph';
import LandingPage15Aspire from '../Pages/Frontend/LandingPage/LandingPage15/LandingPage15Aspire';
import LandingPage15BlueCoast from '../Pages/Frontend/LandingPage/LandingPage15/LandingPage15BlueCoast';
import W9Form from '../Pages/Backend/AffiliateManagement/W9Form';
import LandingPage16 from '../Pages/Frontend/LandingPage/LandingPage16/LandingPage16';
import NDAContractForm from '../Pages/Frontend/NDAContractForm/NDAContractForm';
import AffilaiteFormsRedirect from '../Pages/Frontend/Affiliate/AffilaiteFormsRedirect';
import LandingPage17 from '../Pages/Frontend/LandingPage/LandingPage17/LandingPage17';
import Successaspire from '../Pages/Frontend/Successaspire';
import OthersFormViews from '../Pages/Frontend/LandingPage/OtherForms/OthersFormViews';
import OtherFormsSuccess from '../Pages/Frontend/LandingPage/OtherForms/OtherFormsSuccess';
import LandingPage18 from '../Pages/Frontend/LandingPage/LandingPage18/LandingPage18';
import AcceptPrivacy from '../Pages/Backend/AffiliateManagement/AcceptPrivacy';
import AffiliateReport from '../Pages/Backend/AffiliateManagement/AffiliateReports/AffiliateReport';
import NewOtherPageStructure from '../Pages/Frontend/LandingPage/NewOtherPage/NewOtherPageStructure';
import RepAndAffiCommisionReport from '../Pages/Backend/AffiliateManagement/RepAndAffiCommisionReport';
import NakaGamesLandingPage from '../Pages/Frontend/LandingPage/NakaGamesLandingPage/NakaGamesLandingPage';
import NakaGamaesPageForm from '../Pages/Frontend/LandingPage/NakaGamesLandingPage/NakaGamaesPageForm';
import NakaGamesFormRedirect from '../Pages/Frontend/NakaGames/NakaGamesFormRedirect';
import OtherFormViewBlueCoast from '../Pages/Frontend/LandingPage/OtherForms/EmailCampaignAprroverBlueCoast/OtherFormViewBlueCoast';
import OtherFormSuccessBlueCoast from '../Pages/Frontend/LandingPage/OtherForms/EmailCampaignAprroverBlueCoast/OtherFormSuccessBlueCoast';
import DownloadW9NDA from '../Pages/Backend/AffiliateManagement/DownloadW9NDA';
import LandingPage19 from '../Pages/Frontend/LandingPage/LandingPage19/LandingPage19';
import CommissionManualEntry from '../Pages/Backend/Commission/CommissionManualEntry/CommissionManualEntry';
import BlueCoastList from '../Pages/Backend/BlueCoast/BlueCoastList';
import AddPartners from '../Pages/Backend/Partners/AddPartners/AddPartners';
import PartnersListing from '../Pages/Backend/Partners/PartnersListing/PartnersListing';
import PartnersCommissionReport from '../Pages/Backend/Partners/PartnerCommisison/PartnersCommissionReport';
import PartnersSummaryReport from '../Pages/Backend/Partners/PartnerCommisison/PartnersSummaryReport';
import LandingPage20 from '../Pages/Frontend/LandingPage/LandingPage20/LandingPage20';
import AeSummarryReport from '../Pages/Backend/Partners/PartnerCommisison/AeSummarryReport';
import ComissionTransactionalReport from '../Pages/Backend/Partners/PartnerCommisison/ComissionTransactionalReport';
import AffiliateSummaryReport from '../Pages/Backend/Partners/PartnerCommisison/AffiliateSummaryReport';
import AfffiliateConverisonReport from '../Pages/Backend/AffiliateManagement/AffiliateConversionReport/AfffiliateConverisonReport';
import AffiliateConversionDetailedReport from '../Pages/Backend/AffiliateManagement/AffiliateConversionReport/AfffiliateConverisonDetailedReport';
import RepSalesReport from '../Pages/Backend/AffiliateManagement/RepSalesReport';

import MlmPage from '../Pages/Frontend/LandingPage/MLMLandingpages/MlmPage';
import MlmFormsRediredct from '../Pages/Frontend/MLM/MlmFormsRediredct';
import EmployerAddEdit from '../Pages/Backend/EmployerManagement/AddEditEmployer/AddEditEmployer';
import EmployerListing from '../Pages/Backend/EmployerManagement/EmployerListing/EmployerListing';
import EmployersFormsRedirect from '../Pages/Backend/EmployerManagement/EmployersFormsRedirect/EmployersFormsRedirect';
import NDAContractEmployer from '../Pages/Backend/EmployerManagement/EmployersFormsRedirect/NDAContractEmployer';
import ThankYouPageEmployer from '../Pages/Backend/EmployerManagement/EmployersFormsRedirect/ThankYouPageEmployer';
import ViewLocations from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageLocation/ViewLocations';
import EmployeeListing from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageEmployee/EmployeeListing';
import ErrorPage from '../Pages/Frontend/LandingPage/ErrorPage';
// import EmployerSubLogin from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageSubLogin/EmployerSubLogin';
import EmployerSubLoginListing from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageSubLogin/EmployerSubLoginListing';
import EmployerDashboard from '../Pages/Backend/EmployerManagement/EmployerDashboard/EmployerDashboard';
import SubLoginDashboard from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageSubLogin/SubLoginDashboard/SubLoginDashboard';
import EmployeeListingSubLogin from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageSubLogin/SubLoginDashboard/EmployeeListingSubLogin';
import TFCommissionSummary from '../Pages/Backend/AffiliateManagement/AffiliateReports/TFCommissionSummary';
import EmployeeDashboard from '../Pages/Backend/EmployerManagement/EmployerDashboard/ManageEmployee/EmployeeDashboard/EmployeeDashboard';
import OTPVerify from '../Pages/Frontend/OTPVerification/OTPVerify';
import OTPVerificatioTrial from '../Pages/Frontend/OTPVerification/OTPVerificatioTrial';
import ManageUser from '../Pages/Backend/BlueCoast/ManageUser/ManageUser';
import UserAddEditbcl from '../Pages/Backend/BlueCoast/ManageUser/UseAddEditBcl/UserAddEditbcl';
import AddFile from '../Pages/Backend/FileManagement/AddFile';
import AddFileCetagory from '../Pages/Backend/FileManagement/AddFileCetagory';
import TrainingReport from '../Pages/Backend/Training/TrainingReport/TrainingReports';
import EventListing from '../Pages/Calendar/CalendarEventListing/EventListing';
import InterviewContract from '../Pages/InterviewContract/InterviewContract';
import NoLinkPage from '../Pages/NoLinkPage';
// import CommissionTrafficPartnerList from '../Pages/Backend/Commission/commissionConfig/ConfigureCommissionTrafficPartner/CommissionTrafficPartnerList';
// import AddEditCommissionTrafficPartner from '../Pages/Backend/Commission/commissionConfig/ConfigureCommissionTrafficPartner/AddEditCommissionTrafficPartner';

// import OtherForms  from '../Pages/Frontend/LandingPage/OtherForms/OtherForms';
// import LandingPage17New from '../Pages/Frontend/LandingPage/LandingPage17/LandingPage17New';
// import EnrollmentFormList from '../Pages/Backend/LeadManagement/EnrollmentFormList';





// import TrainingMaterialCentarList from '../Pages/Backend/Training/Training Material Centar/TrainingMaterialCentarList';

// import HmDashboard from '../Pages/Backend/HmDashboard/HmDashboard';
// import BDDdashboard from '../Pages/Backend/BDDdashboard/BDDdashboard';
// import AerDashboard from '../Pages/Backend/AerDashboard/AerDashboard';
// import RepDashboard from '../Pages/Backend/RepDashboard/RepDashboard';
// import Informationtemplate from '../Pages/Backend/InformationlinkModule/Informationtemplate';
// import TagList from '../Pages/Backend/TagManagement/Tag/TagList';
// import TagAddEdit from '../Pages/Backend/TagManagement/Tag/TagAddEdit';
// import HmGroupList from '../Pages/Backend/HmGroup/HmGroupList';
// import HmGroupAddEdit from '../Pages/Backend/HmGroup/HmGroupAddEdit';
// import HiringManagment from '../Pages/Backend/HirinManagement/HiringManagment';


// import Contract from '../Pages/Backend/ContractManagement/Contract/Contract';
// import ContractAddEdit from '../Pages/Backend/ContractManagement/Contract/ContractAddEdit';
// import ContractSlag from '../Pages/Backend/ContractManagement/ContractSlag/ContractSlag';
// import ContractSlagAddEdit from '../Pages/Backend/ContractManagement/ContractSlag/ContractSlagAddEdit';
// import Dashboard from '../Pages/Backend/Dashboard/Dashboard';
// import EmailSlug from '../Pages/Backend/EmailTemplateManagement/EmailSlug/EmailSlug';
// import EmailSlugAddEdit from '../Pages/Backend/EmailTemplateManagement/EmailSlug/EmailSlugAddEdit';










// ---- Back End Lazy Loading -----
// const Dashboard = React.lazy(() => import('../Pages/Backend/dashboard/dashboard'))

const HmDashboard = React.lazy(() => import('../Pages/Backend/HmDashboard/HmDashboard'))
const BDDdashboard = React.lazy(() => import('../Pages/Backend/BDDdashboard/BDDdashboard'))
const AerDashboard = React.lazy(() => import('../Pages/Backend/AerDashboard/AerDashboard'))
const RepDashboard = React.lazy(() => import('../Pages/Backend/RepDashboard/RepDashboard'))
const Informationtemplate = React.lazy(() => import('../Pages/Backend/InformationlinkModule/Informationtemplate'))
const TagList = React.lazy(() => import('../Pages/Backend/TagManagement/Tag/TagList'))
const TagAddEdit = React.lazy(() => import('../Pages/Backend/TagManagement/Tag/TagAddEdit'))
const HmGroupList = React.lazy(() => import('../Pages/Backend/HmGroup/HmGroupList'))
const HmGroupAddEdit = React.lazy(() => import('../Pages/Backend/HmGroup/HmGroupAddEdit'))
const HiringManagment = React.lazy(() => import('../Pages/Backend/HirinManagement/HiringManagment'))

const Contract = React.lazy(() => import('../Pages/Backend/ContractManagement/Contract/Contract'))
const ContractAddEdit = React.lazy(() => import('../Pages/Backend/ContractManagement/Contract/ContractAddEdit'))
const ContractSlag = React.lazy(() => import('../Pages/Backend/ContractManagement/ContractSlag/ContractSlag'))
const ContractSlagAddEdit = React.lazy(() => import('../Pages/Backend/ContractManagement/ContractSlag/ContractSlagAddEdit'))
const Dashboard = React.lazy(() => import('../Pages/Backend/Dashboard/Dashboard'))
const EmailSlug = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/EmailSlug/EmailSlug'))
const EmailSlugAddEdit = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/EmailSlug/EmailSlugAddEdit'))



const RoleAddEdit = React.lazy(() => import('../Pages/Backend/Role/RoleAddEdit'))
const AddEditProgram = React.lazy(() => import('../Pages/Backend/Program/AddEditProgram'))
const AddTraining = React.lazy(() => import('../Pages/Backend/Training/TrainingCategory/AddTrainingCategory'));
const AddLesson = React.lazy(() => import('../Pages/Backend/Training/TrainingLesson/AddTrainingLesson'));
const TrainingList = React.lazy(() => import('../Pages/Backend/Training/TrainingCategory/TrainingCategoryList'));
const LessonList = React.lazy(() => import('../Pages/Backend/Training/TrainingLesson/TrainingLessonList'));
const RoleList = React.lazy(() => import('../Pages/Backend/Role/RoleList'));
const LandingPageAddEdit = React.lazy(() => import('../Pages/Backend/LandingPage/LandingPageAddEdit'))
const LandingPageList = React.lazy(() => import('../Pages/Backend/LandingPage/LandingPageList'))

const InformationAddEdit = React.lazy(() => import('../Pages/Backend/InformationlinkModule/InformationAddEdit'))
const InformationList = React.lazy(() => import('../Pages/Backend/InformationlinkModule/InformationList'))

const RepBlueCoastDashboard = React.lazy(() => import('../Pages/Backend/RepBlueCoastDashboard/RepBlueCoastDashboard'))

const MarketingCampaignInstruction = React.lazy(() => import('../Pages/Backend/MarketingCampaignInstruction/MarketingCampaignInstruction'))


const ProgramList = React.lazy(() => import('../Pages/Backend/Program/ProgramList'))
const LeadList = React.lazy(() => import('../Pages/Backend/LeadManagement/LeadList'))
const EnrollmentFormList = React.lazy(() => import('../Pages/Backend/LeadManagement/EnrollmentFormList'))
const LeadEdit = React.lazy(() => import('../Pages/Backend/LeadManagement/LeadEdit'))
// const LeadBulkAddEdit = React.lazy(()=> import('../Pages/Backend/LeadManagement/LeadBulkAddEdit'))
const TrainingMaterialList = React.lazy(() => import('../Pages/Backend/Training/TrainingMaterial/TrainingMaterialList'));
const AddEditMaterial = React.lazy(() => import('../Pages/Backend/Training/TrainingMaterial/AddEditMaterial'));
const TrainingMaterialCentarList = React.lazy(() => import('../Pages/Backend/Training/Training Material Centar/TrainingMaterialCentarList'));

const QuizList = React.lazy(() => import("../Pages/Backend/Training/Quizzes/QuizList"));
const QuizAddEdit = React.lazy(() => import("../Pages/Backend/Training/Quizzes/QuizAddEdit"));
const LeadFolderView = React.lazy(() => import('../Pages/Backend/LeadManagement/LeadFolderView/LeadFolderView'))
const CampaignAddEdit = React.lazy(() => import('../Pages/CampaignManagement/CampaignAddEdit'))
const CampaignList = React.lazy(() => import('../Pages/CampaignManagement/CampaingList'))

const CreateEvent = React.lazy(() => import('../Pages/Calendar/CreateEvent'))
const AvaibilityList = React.lazy(() => import('../Pages/Calendar/AvaibilityList'));
const AvailableSlots = React.lazy(() => import('../Pages/Calendar/AvailableSlot'));
const Calendar = React.lazy(() => import('../Pages/Frontend/Calendar'));
const BookedSlot = React.lazy(() => import('../Pages/Calendar/BookedSlot'));

const StageList = React.lazy(() => import('../Pages/Backend/StageDistribution/StageList'));
const StageAddEdit = React.lazy(() => import('../Pages/Backend/StageDistribution/StageAddEdit'));
const EmailTemplateAddEdit = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/EmailTemplate/EmailTemplateAddEdit'));
const EmailTemplateList = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/EmailTemplate/EmailTemplateList'));
// const BookedSlot = React.lazy(() => import('../Pages/Calendar/BookedSlot'));
const AddEditFormModule = React.lazy(() => import('../Pages/Backend/FormManagement/FormModule/AddEditForm'))
const AddEditFieldModule = React.lazy(() => import('../Pages/Backend/FormManagement/FieldModule/AddEditField'))
const FieldList = React.lazy(() => import('../Pages/Backend/FormManagement/FieldModule/FieldList'))
const FormList = React.lazy(() => import('../Pages/Backend/FormManagement/FormModule/FormList'))
const AddEditGroupModule = React.lazy(() => import('../Pages/Backend/FormManagement/GroupModule/GroupAddEdit'))
const GroupList = React.lazy(() => import('../Pages/Backend/FormManagement/GroupModule/GroupList'))
const TriggerManagement = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/TriggerManagement/TriggerManagement'))
const TriggerAddEdit = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/TriggerManagement/TriggerAddEdit'))

/********Email campaign approver***** */

const EmailCampaignApprover = React.lazy(() => import('../Pages/Frontend/LandingPage/OtherForms/EmailCampaignApproverList'))
const EmailCampaignApproverBlueCoast = React.lazy(() => import('../Pages/Frontend/LandingPage/OtherForms/EmailCampaignAprroverBlueCoast/EmailCampaignApproverListBlueCoast'))

// ================Send COntact Mail to AE/REP==================
const SendContactMailList = React.lazy(() => import('../Pages/Backend/BlueCoast/SendContactMailList/SendContactMailList'))


// -----------------souvik routing--------------
const LandingPage13 = React.lazy(() => import('../Pages/Frontend/LandingPage/LandingPage13/LandingPage13'))
// --------------------
// const BlueCoastList = React.lazy(() => import('../Pages/Backend/BlueCoast/BlueCoastList'))
const MedicineCategoryAddEdit = React.lazy(() => import('../Pages/Backend/MedicineCategory/AddEditMedicineCategory'))
const MedicineCategoryList = React.lazy(() => import('../Pages/Backend/MedicineCategory/MedicineCategoryList'))
const MedicineAddEdit = React.lazy(() => import('../Pages/Backend/Medicine/AddEditMedicine'))
const MedicineList = React.lazy(() => import('../Pages/Backend/Medicine/MedicineList'))
const MedCatList = React.lazy(() => import('../Pages/Backend/MedCatOnlyList/MedCatList'))
const cookies = new Cookies();

// console.warn("<<<< cookie details from routes >>>>", cookies.cookies)


// -----------------------

// ---- Front End Lazy Loading -----
// const Home = React.lazy(() => import('../Pages/FrontEndPages/Home'))
// -------------------------------------
// console.warn(" <<<<< loginin >>>>>", store.getState().loginSlice.isLoggedIn)

const useAuth = () => {


  if (store.getState().loginSlice.isLoggedIn || (store.getState().loginSlice?.userInfo && Object.keys(store.getState().loginSlice.userInfo).length > 0)) {
    return true;
  }
}

const PrivateRoute = ({ children }) => {
  // console.log("authed", useAuth());
  const auth = useAuth();

  return (
    auth === true ? children : <Navigate to="/login" replace={true} />
  )
}

const AccessControll = ({ children, path }) => {
  // console.log("path", path, children);

  const isacceable = accesscontroll(path, cookies.getAll());
  // console.log("isacceable", isacceable);

  // console.log("authed", useAuth());
  const auth = useAuth();

  return (
    auth === true ? isacceable ? children : <Noaccesspage /> : <Navigate to="/login" replace={true} />
  )

}

const LoginRoute = ({ children, redirectPath }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
}






// const [userInfo,    setuserInfoCookie] = useCookies(["userinfocookies"]);
// console.warn("<<< userinfocookies goes here >>>>", userInfo.userinfocookies)

const GetRoutes = () => {
  const dataAll = useSelector(state => state.ResolveReducer?.httprsponsedata ? state.ResolveReducer?.httprsponsedata : [])

  // console.log(" process.env", process.env);


  React.useEffect(() => {
    // console.log("data===============>", dataAll);
    if (dataAll.length > 5) {

      store.dispatch(setHttpResponse(dataAll))


      store.dispatch(clearSethttprsponsedata())
    }



  }, [JSON.stringify(dataAll)])



  return (
    <BrowserRouter>
      <Routes>

        {/* --------------------Front-End Pages-------------------- */}

        {/* <Route path="/home" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "} */}

        {/* 
        <Route path="/home"  element={<Home />} />{" "} */}
        <Route path="login" element={<Login />} />{" "}
        <Route path="forget-password" element={<ForgotPassword />} />{" "}
        <Route path="reset-password" element={<ResetPassword />} />{" "}
        <Route path="viewcontract/:user_id/:contract_id" element={<ViewContractDetails />} />{" "}
        <Route path="contract-success" element={<ContractSuccess />} />{" "}
        {/* <Route path="/404page" element={<NoPage />} />{" "}  */}

        <Route path='/noaccess' element={<Noaccesspage />} />
        {/* <Route path='/aspire' element={<LandingPage10 />} />
        <Route path='/bluecoast' element={<LandingPage11 />} /> */}
        {/* <Route path='affiliate-signup/:affiliate_id' element={<LandingPage16 />} /> */}
        <Route path="affiliate-signup/:_id" element={<AffilaiteFormsRedirect />} />
        <Route path="naka-ae-signup/:_id" element={<NakaGamesFormRedirect />} />
        <Route path='fill-w9form' element={<W9Form />} />
        <Route path='download-w9form/:id' element={<W9Form />} />
        <Route path='agreement-policy' element={<AcceptPrivacy />} />
        <Route path='nda-signature' element={<NDAContractForm />} />
        <Route path='download-nda-signature/:_id' element={<NDAContractForm />} />
        <Route path='download-nda-w9form/:_id' element={<DownloadW9NDA />} />
        <Route path='naka-games-user-submission-form' element={<NakaGamesPage />} />
        {/* <Route path='aspire-underpayments-form' element={<NewOtherPageStructure />} /> */}
        <Route path='aspire-underpayments-form' element={<NoLinkPage />} />
        <Route path="mlm-signup/:_id" element={<MlmFormsRediredct />} />
        <Route path='nda-employer-signature' element={<NDAContractEmployer />} />




        {/* <Route path='/aspire' element={<LandingPage10 />} />
        <Route path='/bluecoast' element={<LandingPage11 />} /> */}
        {/* <Route path='affiliate-signup' element={<LandingPage16 />} /> */}

        {/* <Route path='/aspire' element={process.env.REACT_APP_LANDINGPAGE_SLUG == 's6UVNi1CVhKMa' ? <LandingPage10 /> : <Home />} />
        <Route path='/bluecoast' element={process.env.REACT_APP_LANDINGPAGE_SLUG == 's6UVNi1CVhKMa' ? <LandingPage11 /> : <Home />} /> */}

        <Route path='/aspire' element={process.env.REACT_APP_LANDINGPAGE_SLUG == 's6UVNi1CVhKMa' && <NoPage />} />
        <Route path='/bluecoast' element={process.env.REACT_APP_LANDINGPAGE_SLUG == 's6UVNi1CVhKMa' && <NoPage />} />

        <Route path="/cif-from/:lead_id/:form_id" element={<Suspense fallback={<></>}><ClintInfoForm /></Suspense>} />

        <Route path='email-campaign-approver-aspire' element={<OthersFormViews />} />
        <Route path='email-campaign-approver-aspire/:aeid' element={<OtherFormsSuccess />} />
        <Route path='email-campaign-approver-bluecoast' element={<OtherFormViewBlueCoast />} />
        <Route path='email-campaign-approver-bluecoast/:aeid' element={<OtherFormSuccessBlueCoast />} />


        {/* ----- Backend Pages ------ */}
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={(process.env.REACT_APP_IS_BACKEND && process.env.REACT_APP_IS_BACKEND == 'true') ? <Login /> : <Home />} />
          <Route path=":uniquename" element={<Home />} />
          <Route path=":uniquename/:banner" element={<Home />} />
          <Route path="landing-page2" element={<LandingPage2 />} />
          <Route path="landing-page2Video" element={<LandingPage2Video />} />
          <Route path=":uid/:mlmoneid/:priority" element={<Home />} />




          <Route path="landing-page3" element={<LandingPage3 />} />
          <Route path="landing-page3Video" element={<LandingPage3Video />} />

          <Route path="landing-page3/html" element={< Patnership />} />
          <Route path="landing-page3/html1" element={<BrandTestimonial />} />
          <Route path="landing-page4" element={<LandingPage4 />} />
          <Route path="landing-page5" element={<LandingPage5 />} />
          <Route path='thankyou' element={<Success />} />
          <Route path='accessconfirmation/:repid' element={<SuccessBlueCoast />} />
          <Route path='accessconfirmation/:repid' element={<SuccessBlueCoast />} />

          <Route path='thankyou/:leadid' element={<Success />} />
          <Route path='thankyousubmission/:leadid' element={<Successblueph />} />
          <Route path='thankyouaccess/:leadid' element={<Successaspire />} />
          <Route path='landing-page6' element={<LandingPage6 />} />
          <Route path='landing-page6-video' element={<LandingPage6 />} />
          <Route path='landing-page4Video' element={<LandingPage4Video />} />
          {/* <Route path='landing-page9' element={<LandingPage9 />} /> */}
          <Route path='landing-page9' element={process.env.REACT_APP_LANDINGPAGE_SLUG == 'saVit1YdCuovt' && <NoPage /> } />
          <Route path='landing-page10' element={<LandingPage10 />} />
          <Route path='landing-page11' element={<LandingPage11 />} />
          <Route path='landing-page13' element={<LandingPage13 />} />
          <Route path='landing-page14' element={<LandingPage14 />} />
          <Route path='landing-page15' element={<LandingPage15 />} />
          <Route path='landing-page15-aspire' element={<LandingPage15Aspire />} />
          <Route path='landing-page15-bluecoast' element={<LandingPage15BlueCoast />} />
          <Route path='landing-page17' element={<LandingPage17 />} />
          <Route path='medication-enrollment' element={<LandingPage18 />} />
          <Route path='landing-page19' element={<LandingPage19 />} />
          <Route path='nakagames' element={<NakaGamesLandingPage />} />
          <Route path='landing-page20' element={<LandingPage20 />} />
          <Route path='nakagames/:username' element={<NakaGamesLandingPage />} />
          <Route path='interview-contract/:_id' element={<InterviewContract />} />
          <Route path='mlm' element={<MlmPage />} />

          <Route path='otp-verify' element={<OTPVerify />} />
          <Route path='otp-verify-trial' element={<OTPVerificatioTrial />} />

          {/* <Route path='naka-games-user-submission-form' element={<NakaGamesPage />} /> */}
          {/* <Route path='landing-page17-new' element={<LandingPage17New />} /> */}



          {/* -----------------------------------landing page14 emailtemplate route for testing------------------------ */}
          <Route path='landing-page14-email-template' element={<LandingPage14Email />} />



          {/* -------------------- JobTickets -------------------- */}

          <Route path="/jobticket-category" element={<PrivateRoute><Suspense fallback={<></>}><JobticketsCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-jobtickets-category" element={<PrivateRoute><Suspense fallback={<></>}><JobticketsCategoryAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-jobtickets-category/:id" element={<PrivateRoute><Suspense fallback={<></>}><JobticketsCategoryAddEdit /></Suspense></PrivateRoute>} />{" "}



          <Route path="/manage-jobtickets" element={<PrivateRoute><Suspense fallback={<></>}><ManageJobtickets /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-Jobtickets" element={<PrivateRoute><Suspense fallback={<></>}><AddEditJobtickets /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-jobtickets/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditJobtickets /></Suspense></PrivateRoute>} />{" "}
          <Route path="/reply-jobticket/:id" element={<PrivateRoute><Suspense fallback={<></>}><ReplyJobticket /></Suspense></PrivateRoute>} />{" "}

          {/* {----------------------- Faq Management -----------------------------------------} */}
          <Route path="/faq" element={<PrivateRoute><Suspense fallback={<></>}><Faq /></Suspense></PrivateRoute>} />{" "}
          <Route path="/faq-category" element={<PrivateRoute><Suspense fallback={<></>}><FaqCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-faqcategory" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaqCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-faqcategory/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaqCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-faq" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaq /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-faq/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaq /></Suspense></PrivateRoute>} />{" "}


          {/* -------------------LANDING PAGE ROUTE-----------------------------*/}

          <Route path="/add-landingpage" element={<PrivateRoute><Suspense fallback={<></>}><LandingPageAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-landingpage/:id" element={<PrivateRoute><Suspense fallback={<></>}><LandingPageAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/list-landingpage" element={<PrivateRoute><Suspense fallback={<></>}><LandingPageList /></Suspense></PrivateRoute>} />{" "}

          {/* --------------------------------------------------------------------------------------------------- */}

          {/* ------------------------------------INFORMATION LINK MODULE------------------------ */}

          <Route path="/add-information" element={<PrivateRoute><Suspense fallback={<></>}><InformationAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-information/:id" element={<PrivateRoute><Suspense fallback={<></>}><InformationAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/list-information" element={<PrivateRoute><Suspense fallback={<></>}><InformationList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/information-link" element={<PrivateRoute><Suspense fallback={<></>}><Informationtemplate /></Suspense></PrivateRoute>} />{" "}

          {/* ------------------------------------------------------------------- */}








          {/* -------------------****CAMPAIGN MANAGEMENT PAGE ROUTE****-----------------------------*/}

          <Route path="/add-campaign" element={<PrivateRoute><Suspense fallback={<></>}><CampaignAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-campaign/:id" element={<PrivateRoute><Suspense fallback={<></>}><CampaignAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/list-campaign" element={<PrivateRoute><Suspense fallback={<></>}><CampaignList /></Suspense></PrivateRoute>} />{" "}

          {/* --------------------------------------------------------------------------------------------------- */}


          {/* -------------------------- USER MANAGEMENT ----------------------- */}

          <Route path="/useraddedit" element={<PrivateRoute><Suspense fallback={<></>}><AddEdituser /></Suspense></PrivateRoute>} />{" "}
          <Route path="/usermanagement" element={<PrivateRoute><Suspense fallback={<></>}><UserManagement /></Suspense></PrivateRoute>} />
          <Route path="/folderview" element={<PrivateRoute><Suspense fallback={<></>}><FolderView /></Suspense></PrivateRoute>} />

          {/* --------------------------------------------------------------------------------------------------- */}

          <Route path="/super_admindashboard" element={<PrivateRoute><Suspense fallback={<></>}><Dashboard /></Suspense></PrivateRoute>} />{" "}

          <Route path="/dashboard" element={<PrivateRoute><Suspense fallback={<></>}><Dashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/hm-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><HmDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/ae-aee-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><RepAndAreaDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/ae-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><RepDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/affiliate-converison-report" element={<PrivateRoute><Suspense fallback={<></>}><AfffiliateConverisonReport /></Suspense></PrivateRoute>} />{" "}
          <Route path="/aee-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><AerDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/bdd-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><BDDdashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-training" element={<PrivateRoute><Suspense fallback={<></>}><AddTraining /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-training/:_id" element={<Suspense fallback={<></>}><AddTraining /></Suspense>} />{" "}
          <Route path="/training-category/list" element={<PrivateRoute><Suspense fallback={<></>}><TrainingList /></Suspense> </PrivateRoute>} />{" "}
          <Route path="/add-lesson" element={<PrivateRoute><Suspense fallback={<></>}><AddLesson /></Suspense> </PrivateRoute>} />{" "}
          <Route path="/training-lesson/list" element={<PrivateRoute><Suspense fallback={<></>}><LessonList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-lesson/:_id" element={<Suspense fallback={<></>}><AddLesson /></Suspense>} />{" "}
          <Route path="/training-material" element={<PrivateRoute><Suspense fallback={<></>}><TrainingMaterialList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/material-add" element={<PrivateRoute><Suspense fallback={<></>}><AddEditMaterial /></Suspense></PrivateRoute>} />{" "}
          <Route path="/material-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditMaterial /></Suspense></PrivateRoute>} />{" "}
          {/* <Route path="/training-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditMaterial /></Suspense></PrivateRoute>} />{" "} */}
          <Route path="/training-material-centar-list" element={<PrivateRoute><Suspense fallback={<></>}><TrainingMaterialCentarList /></Suspense></PrivateRoute>} />{" "}

          <Route path="/quizzes" element={<PrivateRoute><Suspense fallback={<></>}><QuizList /></Suspense></PrivateRoute>} />
          <Route path="/quiz-add" element={<PrivateRoute><Suspense fallback={<></>}><QuizAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/quiz-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><QuizAddEdit /></Suspense></PrivateRoute>} />

          {/* <Route path="/role-add" element={<AccessControll path={'/role-add'}><Suspense fallback={<></>}><RoleAddEdit /></Suspense></AccessControll>} /> */}
          <Route path="/role-add" element={<PrivateRoute><Suspense fallback={<></>}><RoleAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/program-add" element={<PrivateRoute><Suspense fallback={<></>}><AddEditProgram /></Suspense></PrivateRoute>} />
          <Route path="/role-list" element={<PrivateRoute><Suspense fallback={<></>}><RoleList /></Suspense></PrivateRoute>} />
          <Route path="/program-list" element={<PrivateRoute><Suspense fallback={<></>}><ProgramList /></Suspense></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Suspense fallback={<></>}><Profile /></Suspense></PrivateRoute>} />


          <Route path="/role-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><RoleAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/program-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditProgram /></Suspense></PrivateRoute>} />
          <Route path="/training-center" element={<Suspense fallback={<></>}><TrainingCenter /></Suspense>} />
          <Route path="/training-center/:cat_id" element={<Suspense fallback={<></>}><TrainingCenter /></Suspense>} />
          <Route path="/training-center/:cat_id/:lesson_id" element={<Suspense fallback={<></>}><TrainingCenter /></Suspense>} />
          <Route path="/training-report" element={<Suspense fallback={<></>}><TrainingReport /></Suspense>} />

          <Route path="/marketing-campaign-instruction" element={<PrivateRoute><Suspense fallback={<></>}><MarketingCampaignInstruction /></Suspense></PrivateRoute>} />{" "}



          {/* -----------------------------------------------Calender Management--------------------------------------------------- */}

          <Route path="/available-slots" element={<PrivateRoute><Suspense fallback={<></>}><AvailableSlots /></Suspense></PrivateRoute>} />
          <Route path="/create-event" element={<PrivateRoute><Suspense fallback={<></>}><CreateEvent /></Suspense></PrivateRoute>} />
          <Route path="/avilibility-list" element={<PrivateRoute><Suspense fallback={<></>}><AvaibilityList /></Suspense></PrivateRoute>} />
          <Route path="/edit-event/:slotID" element={<PrivateRoute><Suspense fallback={<></>}><CreateEvent /></Suspense></PrivateRoute>} />
          <Route path="/booked-slot" element={<PrivateRoute><Suspense fallback={<></>}><BookedSlot /></Suspense></PrivateRoute>} />
          <Route path="/miscellaneous" element={<PrivateRoute><Suspense fallback={<></>}><Miscellaneous /></Suspense></PrivateRoute>} />{" "}
          <Route path="/calendar/:lead_id/:orgrole/:orgid" element={<Suspense fallback={<></>}><Calendar /></Suspense>} />{" "}
          <Route path="/reschedule-slot/:identifier/:orgrole/:orgid" element={<Suspense fallback={<></>}><Calendar /></Suspense>} />{" "}
          <Route path='/thankyou/:page/:lead_id' element={<Suspense fallback={<></>}><ThankyouPage /> </Suspense>} />{" "}
          <Route path="/cancel-slot/:identifier" element={<Suspense fallback={<></>}><CancelSlot /></Suspense>} />

          <Route path="/calendar-event-listing" element={<PrivateRoute><Suspense fallback={<></>}><EventListing /></Suspense></PrivateRoute>} />
          <Route path="/calendar-event-listing/:id" element={<PrivateRoute><Suspense fallback={<></>}><EventListing /></Suspense></PrivateRoute>} />


          {/* -----------------------------------------------Contract Management--------------------------------------------------- */}

          <Route path="/contract-list" element={<PrivateRoute><Suspense fallback={<></>}><Contract /></Suspense></PrivateRoute>} />
          <Route path="/contract-slug-list" element={<PrivateRoute><Suspense fallback={<></>}><ContractSlag /></Suspense></PrivateRoute>} />
          <Route path="/add-contract" element={<PrivateRoute><Suspense fallback={<></>}><ContractAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-contract/:id" element={<PrivateRoute><Suspense fallback={<></>}><ContractAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/add-contract-slug" element={<PrivateRoute><Suspense fallback={<></>}><ContractSlagAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-contract-slug/:id" element={<PrivateRoute><Suspense fallback={<></>}><ContractSlagAddEdit /></Suspense></PrivateRoute>} />


          {/* -----------------------------------------------Lead Management--------------------------------------------------- */}
          <Route path="/lead-list" element={<PrivateRoute><Suspense fallback={<></>}><LeadList /></Suspense></PrivateRoute>} />
          <Route path="/enrollment-list" element={<PrivateRoute><Suspense fallback={<></>}><EnrollmentFormList /></Suspense></PrivateRoute>} />
          <Route path="/lead-list/:role/:id" element={<PrivateRoute><Suspense fallback={<></>}><LeadList /></Suspense></PrivateRoute>} />
          <Route path="/lead-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><LeadEdit /></Suspense></PrivateRoute>} />
          <Route path="/lead-add" element={<PrivateRoute><Suspense fallback={<></>}><LeadEdit /></Suspense></PrivateRoute>} />
          <Route path="/lead-folder-view/:id" element={<PrivateRoute><Suspense fallback={<></>}><LeadFolderView /></Suspense></PrivateRoute>} />
          {/* <Route path="/lead-bulk-add" element={<PrivateRoute><Suspense fallback={<></>}><LeadEdit /></Suspense></PrivateRoute>} /> */}
          {/* -----------------------------------------------email template Management--------------------------------------------------- */}
          <Route path="/email-template-add" element={<PrivateRoute><Suspense fallback={<></>}><EmailTemplateAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/email-template-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><EmailTemplateAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/email-template-list" element={<PrivateRoute><Suspense fallback={<></>}><EmailTemplateList /></Suspense></PrivateRoute>} />

          {/*-------------------------------------------------Tag Management------------------------------------------------------*/}
          <Route path="/tag-list" element={<PrivateRoute><Suspense fallback={<></>}><TagList /></Suspense></PrivateRoute>} />
          <Route path="/tag-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><TagAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/tag-edit" element={<PrivateRoute><Suspense fallback={<></>}><TagAddEdit /></Suspense></PrivateRoute>} />

          {/*------------------------------------------Form Management---------------------------------------------------------- */}
          <Route path="/add-form" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFormModule /></Suspense></PrivateRoute>} />
          <Route path="/form-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFormModule /></Suspense></PrivateRoute>} />
          <Route path="/add-field" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFieldModule /></Suspense></PrivateRoute>} />
          <Route path="/field-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFieldModule /></Suspense></PrivateRoute>} />
          <Route path="/field-module/list" element={<PrivateRoute><Suspense fallback={<></>}><FieldList /></Suspense></PrivateRoute>} />
          <Route path="/form-module/list" element={<PrivateRoute><Suspense fallback={<></>}><FormList /></Suspense></PrivateRoute>} />
          <Route path="/add-group" element={<PrivateRoute><Suspense fallback={<></>}><AddEditGroupModule /></Suspense></PrivateRoute>} />
          <Route path="/edit-group/:_id" element={<Suspense fallback={<></>}><AddEditGroupModule /></Suspense>} />{" "}
          <Route path="/group-module/list" element={<PrivateRoute><Suspense fallback={<></>}><GroupList /></Suspense></PrivateRoute>} />

          {/* ---------------------------------------------------Email Management--------------------------------------------------- */}

          <Route path="/email-slug" element={<PrivateRoute><Suspense fallback={<></>}><EmailSlug /></Suspense></PrivateRoute>} />
          <Route path="/email-slug-add" element={<PrivateRoute><Suspense fallback={<></>}><EmailSlugAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/email-slug-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><EmailSlugAddEdit /></Suspense></PrivateRoute>} />
          {/* ---------------------------------------------------Trigger Management--------------------------------------------------- */}
          <Route path="/trigger-list" element={<PrivateRoute><Suspense fallback={<></>}><TriggerManagement /></Suspense></PrivateRoute>} />
          <Route path="/trigger-add" element={<PrivateRoute><Suspense fallback={<></>}><TriggerAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/trigger-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><TriggerAddEdit /></Suspense></PrivateRoute>} />



          {/***************************************************BlueCoast*********************************************** */}
          <Route path="/businesslead-list" element={<PrivateRoute><Suspense fallback={<></>}><BlueCoastList /></Suspense></PrivateRoute>} />
          <Route path="/pharmacysavings-bluecoast" element={<PrivateRoute><Suspense fallback={<></>}><PharmacysavingsBluecoast /></Suspense></PrivateRoute>} />
          <Route path="/pharmacysavings-aspire" element={<PrivateRoute><Suspense fallback={<></>}><PharmacysavingsAspire /></Suspense></PrivateRoute>} />

          <Route path="/newuseraddedit" element={<PrivateRoute><Suspense fallback={<></>}><AddEdituser /></Suspense></PrivateRoute>} />{" "}

          <Route path="/businesslead-list-access-deal" element={<PrivateRoute><Suspense fallback={<></>}><BlueCoastList /></Suspense></PrivateRoute>} />


          <Route path="/manage-user" element={<PrivateRoute><Suspense fallback={<></>}><ManageUser /></Suspense></PrivateRoute>} />
          <Route path="/user-add-edit-bcl" element={<PrivateRoute><Suspense fallback={<></>}><UserAddEditbcl /></Suspense></PrivateRoute>} />{" "}



          {/* -----------------Medicine Category Management------------------ */}
          <Route path="/add-medicine-category" element={<PrivateRoute><Suspense fallback={<></>}><MedicineCategoryAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/medicine-category/list" element={<PrivateRoute><Suspense fallback={<></>}><MedicineCategoryList /></Suspense></PrivateRoute>} />
          <Route path="/edit-medicine-category/:_id" element={<Suspense fallback={<></>}><MedicineCategoryAddEdit /></Suspense>} />{" "}


          {/* ---------------Only Medicine and category listing--------------- */}
          {/* <Route path="/medicine-and-category/list" element={<PrivateRoute><Suspense fallback={<></>}><MedCatList /></Suspense></PrivateRoute>} /> */}


          {/* -------Medicine Management***** */}

          <Route path="/add-medicine" element={<PrivateRoute><Suspense fallback={<></>}><MedicineAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/medicine/list" element={<PrivateRoute><Suspense fallback={<></>}><MedicineList /></Suspense></PrivateRoute>} />
          <Route path="/edit-medicine/:_id" element={<Suspense fallback={<></>}><MedicineAddEdit /></Suspense>} />{" "}


          {/* ---------------------------------------------------Stage Distribution Management--------------------------------------------------- */}


          <Route path="/stage-distribution-list" element={<PrivateRoute><Suspense fallback={<></>}><StageList /></Suspense></PrivateRoute>} />
          <Route path="/stage-add" element={<PrivateRoute><Suspense fallback={<></>}><StageAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-stage/:id" element={<PrivateRoute><Suspense fallback={<></>}><StageAddEdit /></Suspense></PrivateRoute>} />


          {/* ---------------------------------------------------Manage Round Robin--------------------------------------------------- */}
          <Route path="/manage-round-rodin" element={<PrivateRoute><Suspense fallback={<></>}><ManageRoundRodin /></Suspense></PrivateRoute>} />


          {/* ---------------------------------------------------Hiring Management System--------------------------------------------------- */}

          <Route path="/hiring-managment" element={<PrivateRoute><Suspense fallback={<></>}><HiringManagment /></Suspense></PrivateRoute>} />
          <Route path="/404page" element={<NoPage />} />{" "} 
          <Route path="/no-link" element={<NoLinkPage />} />{" "}



          {/* ---------------------------------------------------HM GROUP Management System--------------------------------------------------- */}
          <Route path="/hm-group-add" element={<PrivateRoute><Suspense fallback={<></>}><HmGroupAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/hm-group-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><HmGroupAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/hm-group-list" element={<PrivateRoute><Suspense fallback={<></>}><HmGroupList /></Suspense></PrivateRoute>} />



          {/* -------------------------------------------------Commission Management Routes--------------------------------------------------------- */}
          <Route path="/configur-commision" element={<PrivateRoute><Suspense fallback={<></>}><ConfigurCommissionList /></Suspense></PrivateRoute>} />
          <Route path="/commission-add-edit" element={<PrivateRoute><Suspense fallback={<></>}><CommissionAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/commission-distribution-hm-aee" element={<PrivateRoute><Suspense fallback={<></>}><CommissionDistribution /></Suspense></PrivateRoute>} />
          <Route path="/add-commission" element={<PrivateRoute><Suspense fallback={<></>}><AddCommission /></Suspense></PrivateRoute>} />
          <Route path="/commission-listing" element={<PrivateRoute><Suspense fallback={<></>}><AddCommissionList /></Suspense></PrivateRoute>} />
          <Route path="/commission-manual-entry" element={<PrivateRoute><Suspense fallback={<></>}><CommissionManualEntry /></Suspense></PrivateRoute>} />


          {/* ----------------------------------------------------Commission Configure Traffic Partner------------------------------------------------------ */}
          {/* <Route path="/configur-commision-traffic-partner" element={<PrivateRoute><Suspense fallback={<></>}><CommissionTrafficPartnerList /></Suspense></PrivateRoute>} />
          <Route path="/commission-add-edit-traffic-partner" element={<PrivateRoute><Suspense fallback={<></>}><AddEditCommissionTrafficPartner /></Suspense></PrivateRoute>} /> */}





          {/* -------------------------------------------------partners Management Routes--------------------------------------------------------- */}

          <Route path="/add-partners" element={<PrivateRoute><Suspense fallback={<></>}><AddPartners /></Suspense></PrivateRoute>} />
          <Route path="/partners-listing" element={<PrivateRoute><Suspense fallback={<></>}><PartnersListing /></Suspense></PrivateRoute>} />
          <Route path="/partners-transactional-report/:id" element={<PrivateRoute><Suspense fallback={<></>}><PartnersCommissionReport /></Suspense></PrivateRoute>} />
          <Route path="/partners-summary-report" element={<PrivateRoute><Suspense fallback={<></>}><PartnersSummaryReport /></Suspense></PrivateRoute>} />
          <Route path="/ae-summary-report/:id" element={<PrivateRoute><Suspense fallback={<></>}><AeSummarryReport /></Suspense></PrivateRoute>} />
          <Route path="/commission-transactional-report/:id/:name" element={<PrivateRoute><Suspense fallback={<></>}><ComissionTransactionalReport /></Suspense></PrivateRoute>} />
          <Route path="/affiliate-summary-report/:id" element={<PrivateRoute><Suspense fallback={<></>}><AffiliateSummaryReport /></Suspense></PrivateRoute>} />




          {/* ------------------------------------- Affiliate ------------------------------------------------------ */}
          <Route path="/affiliate-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><RepBlueCoastDashboard /></Suspense></PrivateRoute>} />
          <Route path="/create-affiliate" element={<PrivateRoute><Suspense fallback={<></>}><AffiliateAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/view-affiliate" element={<PrivateRoute><Suspense fallback={<></>}><AffiliateListing /></Suspense></PrivateRoute>} />
          <Route path="/affiliate-report" element={<PrivateRoute><Suspense fallback={<></>}><AffiliateReport /></Suspense></PrivateRoute>} />
          <Route path="/traffic-partner-commission-summary" element={<PrivateRoute><Suspense fallback={<></>}><TFCommissionSummary /></Suspense></PrivateRoute>} />
          <Route path="/commission-report" element={<PrivateRoute><Suspense fallback={<></>}><RepAndAffiCommisionReport /></Suspense></PrivateRoute>} />
          <Route path="/affiliate-conversion-detailed-report/:id/:name" element={<PrivateRoute><Suspense fallback={<></>}><AffiliateConversionDetailedReport /></Suspense></PrivateRoute>} />




          {/* ***********************Email Camapign Approver*************************** */}
          <Route path="/email-campaign-approver-list" element={<PrivateRoute><Suspense fallback={<></>}><EmailCampaignApprover /></Suspense></PrivateRoute>} />
          <Route path="/email-campaign-approver-list-bluecoast" element={<PrivateRoute><Suspense fallback={<></>}><EmailCampaignApproverBlueCoast /></Suspense></PrivateRoute>} />



          {/* *********************************************Send Contact Mail List to AE/REP*********************************** */}
          <Route path="/send-contact-mail" element={<PrivateRoute><Suspense fallback={<></>}><SendContactMailList /></Suspense></PrivateRoute>} />


          {/* ------------------------------------- New AE Dashboard ------------------------------------------------------ */}
          <Route path="/new-ae-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><RepBlueCoastDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/ae-sales-report" element={<PrivateRoute><Suspense fallback={<></>}><RepSalesReport /></Suspense></PrivateRoute>} />{" "}



          {/* ------------------------------------- Employer Management ------------------------------------------------------ */}
          <Route path="/add-edit-employer" element={<PrivateRoute><Suspense fallback={<></>}><EmployerAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/employer-listing" element={<PrivateRoute><Suspense fallback={<></>}><EmployerListing /></Suspense></PrivateRoute>} />{" "}
          <Route path="/employers-signup/:_id" element={<EmployersFormsRedirect />} />
          <Route path="/thank-you-employer" element={<ThankYouPageEmployer />} />

          {/*----------------------------------------Employer Dashboard------------------------------------------------------------*/}
          <Route path="/new-employer-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><EmployerDashboard /></Suspense></PrivateRoute>} />
          <Route path="/view-location-list" element={<PrivateRoute><Suspense fallback={<></>}><ViewLocations /></Suspense></PrivateRoute>} />
          <Route path="/view-employee-list" element={<PrivateRoute><Suspense fallback={<></>}><EmployeeListing /></Suspense></PrivateRoute>} />
          <Route path="/view-sub-employer" element={<PrivateRoute><Suspense fallback={<></>}><EmployerSubLoginListing /></Suspense></PrivateRoute>} />

          {/* *--------------------------------------------Sub-Employer Dashboard----------------------------------------------------* */}
          <Route path="/new-sublogin-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><SubLoginDashboard /></Suspense></PrivateRoute>} />
          <Route path="/view-employee-list-sublogin" element={<PrivateRoute><Suspense fallback={<></>}><EmployeeListingSubLogin /></Suspense></PrivateRoute>} />

          {/* *--------------------------------------------Employee Dashboard----------------------------------------------------* */}
          <Route path="/new-employee-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><EmployeeDashboard /></Suspense></PrivateRoute>} />

          {/* ----------------------------------------------File Managment-------------------------------------------------------------- */}
          <Route path="/add_file" element={<PrivateRoute><Suspense fallback={<></>}><AddFile/></Suspense></PrivateRoute>} />
          <Route path="/add_file_cetagory" element={<PrivateRoute><Suspense fallback={<></>}><AddFileCetagory/></Suspense></PrivateRoute>} />



          {/* ---------------------------------------------By default Error Page------------------------------------------------------- */}
          <Route path="/error-page" element={<PrivateRoute><Suspense fallback={<></>}><ErrorPage /></Suspense></PrivateRoute>} />






        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default GetRoutes;