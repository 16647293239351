import React, { useState } from 'react'
import "../Components/LandingPage14.css"
import LandingPage14FormModal from '../LandingPage14FormModal'
import { useDispatch } from 'react-redux';
import { setscrollView } from '../../../../Layout/layoutReducer';

function Lp14block8({ page }) {

    const [openModal, setopenModal] = useState(false);

    const modalOpen = () => {
        setopenModal(true);
    };
    const setsScroll = () => {
        dispatch(setscrollView("scroll"));
    }
    const dispatch = useDispatch();

    return (
        <>
            <div className='Lp14block8_main_wrapper'>
                <div className='landingpage14_common_width'>
                    <div className='Lp14block8_sub_wrapper'>
                        <h1>Access Discount Healthcare</h1>
                        <h2>How to get started</h2>
                    </div>
                    <div className='Lp14block8_sub1_wrapper'>
                        <div className='Lp14block8_sub1_sec_wrapper'>
                            <div className='Lp14block8_img_sec'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_blc8_1logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_blc8_1logo.png'/> */}
                            </div>
                            <h2>SIGN-UP FOR YOUR MONTHLY MEMBERSHIP</h2>
                            <p>As A Member In Our Program You Get Access To All Of Our Medications For FREE!</p>
                        </div>
                        <div className='Lp14block8_sub1_sec_wrapper'>
                            <div className='Lp14block8_img_sec'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_block8_2logp.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_block8_2logp.png'/> */}
                            </div>
                            <h2> SEARCH OUR LIST OF 605 GENERIC MEDICATIONS</h2>
                            <p>Identify The Medications You Are Taking. Compare What You Are Currently Paying To Free From Us.</p>
                        </div>
                        <div className='Lp14block8_sub1_sec_wrapper'>
                            <div className='Lp14block8_img_sec'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_block8_3logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_block8_3logo.png'/> */}
                            </div>
                            <h2> CALL OUR PHARMACY COACHES TO REVIEW YOUR MEDICATIONS</h2>
                            <p>Our Team Is Here To Help. We Will Make Sure Your Medications Are On The List Or Find Therapeutically Similar Ones.</p>
                        </div>
                        <div className='Lp14block8_sub1_sec_wrapper'>
                            <div className='Lp14block8_img_sec'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_block8_4logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_block8_4logo.png'/> */}
                            </div>
                            <h2> WE WILL CONTACT YOU TO CONFIRM YOUR ORDER AND COLLECT PAYMENT</h2>
                            <p>We Always Make Sure Everything Is Accurate. We Double Check The Prescription And Securely Place Your Order.</p>
                        </div>
                        <div className='Lp14block8_sub1_sec_wrapper'>
                            <div className='Lp14block8_img_sec'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_block8_5logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_block8_5logo.png'/> */}
                            </div>
                            <h2>RECEIVE YOUR MEDICATIONS</h2>
                            <p>You Can Pick-up Acute Medications at your Local Pharmacy or Have Chronic Medications Shipped To Your Home For FREE!</p>
                        </div>
                        <div className='Lp14block8_sub1_sec_wrapper'>
                            <div className='Lp14block8_img_sec'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_block8_6logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_block8_6logo.png'/> */}
                            </div>
                            <h2>TRANSFER YOUR PRESCRIPTIONS OR YOUR DOCTOR CAN EPRESCRIBE TO US</h2>
                            <p>Transferring Prescriptions Can Be Difficult. So Our Pharmacy Team Does It ALL For You!</p>
                        </div>
                    </div>
                    <div className='Lp14block2_sub1_wrapper'>
                        {page === "17" ? <button onClick={() => setsScroll()}>speak with one of our organization specialist right away</button> : <button onClick={() => modalOpen()}>Enroll Today</button>}
                    </div>
                    <div className='Lp14block8_sub2_wrapper'>
                        <img className='Lp14block8_sub2_img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl8_ivt_1logo.png`} />
                        {/* <img className='Lp14block8_sub2_img' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl8_ivt_1logo.png'/> */}
                        <em>The prices of prescriptions for my family were going up every year, and I couldn't keep paying the high prices,
                            so I searched for a better option. I came across Access Discount Healthcare , and I won't buy them any
                            other way now. With Access Discount Healthcare , I pay nothing for our medication!</em>
                        <p>- LAUREN KALVIN</p>
                        <img className='Lp14block8_sub2_1img' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl8_apts_2logo.png`} />
                        {/* <img className='Lp14block8_sub2_1img' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl8_apts_2logo.png'/> */}
                    </div>
                </div>
            </div>
            <div><LandingPage14FormModal openModal={openModal} setopenModal={setopenModal} /></div>
        </>
    )
}

export default Lp14block8