import { Button, IconButton, LinearProgress, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from '@mui/icons-material/Preview';
import { setReloadTableFlag } from '../../../../../listing/listReducer';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from './ConfirmationModal';
import { viewCOntractFormEmployer } from '../userListReducer';
import NDAContractPreviewTP from './NDAContractPreviewTP';


function AproveTrafficPartner({ manageTFOpen, setManageTFOpen, userDataList }) {

    const dispatch = useDispatch()

    const [openConfirm, setOpenConfirm] = useState(false)

    const [acceptData, setAcceptData] = useState(null)

    const [loadingApproval, setLoadingApproval] = useState(false);

    const [loadingContract, setLoadingContract] = useState(false);

    const [contractOpne, setContractOpne] = useState(false);

    const [viewContractState, setViewContractState] = useState(null)


    //--------------------list traffic partner data-------------
    const contraFomrState = useSelector((state) => state.userManagment?.contractFormview ? state.userManagment.contractFormview : false);

    // ---------------row data for the list---------------------
    const dataRef = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1 ? state.tableSlice?.tempRowData?.userslist1 : {});

    const [rowdataCopy, setRowdataCopy] = useState({})


    // -------------------temp row data stored in state functioan-------------
    useEffect(() => {
        if (dataRef && Object.keys(dataRef).length > 0) {
            setRowdataCopy(dataRef)
        }
    }, [JSON.stringify(dataRef)])

    useEffect(() => {
        console.log("rowdataCopy========>>>>>", rowdataCopy);
    }, [rowdataCopy])

    //--------------------------stores user data------------------------------------------

    useEffect(() => {
        console.log("contraFomrState======>>>>", contraFomrState)
        if ((contraFomrState !== null || contraFomrState !== undefined) && Object.keys(contraFomrState).length > 0) {
            setViewContractState(contraFomrState)
        }
    }, [contraFomrState]);

    // --------------------------------stored traffic partner user data------------------------
    const [userDetails, setUserDetails] = useState(null)

    useEffect(() => {
        if (userDataList) {
            setUserDetails(userDataList)
        }
    }, [userDataList])



    // -------------------------aproval function and confirmation modal open---------------
    const aprovemailSent = (val) => {
        setLoadingApproval(true)
        setAcceptData(val)

        setTimeout(() => {

            setOpenConfirm(true)
        }, 200);

        setTimeout(() => {

            setLoadingApproval(false)
        }, 500);

        // let eventVal = val
        console.log("accept========>>>>", val)
    }

    // --------------------------NDA contract form data modal----------------------------------



    const contractFormVuiew = async () => {
        setLoadingContract(true);


        let idBody = {
            _id: userDataList?._id,
        }
        console.log("userDataList=====>>>>", userDataList)

        await dispatch(viewCOntractFormEmployer(idBody))
        setContractOpne(true);
        setLoadingContract(false);

    };



    // -------------------close functiona for main modal------------------
    const handleCloseTfModal = () => {
        setManageTFOpen(false)
        dispatch(setReloadTableFlag({ tableId: "userslist1" }));

    }

    return (
        <>
            <Modal
                className="modalBaseStyle sendEmail_modal notesModal_blcoast SR_UserDetail_preview"
                open={manageTFOpen}
                onClose={handleCloseTfModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseTfModal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className="notes_main_header">
                        <p>Preview Contract for  {userDataList?.firstname + " " + userDataList?.lastname}</p>
                        <div>
                            <ul className="listflexblock">
                                {userDataList ? (
                                    <li>
                                        {" "}
                                        <strong>Full Name :</strong>{" "}
                                        <span>
                                            {userDataList?.firstname + " " + userDataList?.lastname}
                                        </span>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {userDataList ? (
                                    <li>
                                        {" "}
                                        <strong>Email :</strong>{" "}
                                        {/* <span>{userDataList?.email}</span> */}
                                        <span
                                            dangerouslySetInnerHTML={{ __html: userDataList?.email }}
                                        ></span>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {userDataList ? (
                                    <li>
                                        {" "}
                                        <strong>Phone :</strong>{" "}
                                        {/* <span>{userDataList?.phone}</span> */}
                                        <span
                                            dangerouslySetInnerHTML={{ __html: userDataList?.phone }}
                                        ></span>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {userDataList?.address ? (
                                    <li>

                                        <strong>Address :</strong>
                                        <span>{userDataList?.address}</span>

                                    </li>
                                ) : ""}
                                {userDataList?.state ? (
                                    <li>
                                        {" "}
                                        <strong>State :</strong>
                                        <span>{userDataList?.state}</span>
                                    </li>
                                ) :
                                    ""
                                }
                                {userDataList?.city ? (
                                    <li>
                                        {" "}
                                        <strong>City :</strong> <span>{userDataList?.city}</span>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {userDataList?.zip ? (
                                    <li>
                                        {" "}
                                        <strong>Zip Code :</strong> <span>{userDataList?.zip}</span>
                                    </li>
                                ) : (
                                    ""
                                )}
                                <li className="contractForm_icon">
                                    <strong>View Contract :</strong>
                                    <span>
                                        {" "}
                                        <Button
                                            onClick={contractFormVuiew}
                                        >
                                            <Tooltip title="Preview NDA Form">
                                                <PreviewIcon />
                                            </Tooltip>
                                        </Button>{" "}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        {loadingContract && <Box sx={{ width: '100%' }}> <LinearProgress /> </Box>}

                        {userDataList?.affiliate_signup_stage > 2 ? <div className="modalview_group">

                            {(userDataList?.dashboard_access === false) ? <p>This contract is already rejected. Do you want to accept?</p> :
                                (userDataList?.dashboard_access == undefined) ? <p>Will you accept the contract?</p> :
                                    <p>This contract is already accepted. Do you want to reject?</p>}

                            {/* <p>{JSON.stringify(userDataList)}</p> */}

                            <div className="confirmationBtns">
                                <button
                                    className="blucoastList_specialbtn"
                                    style={(userDataList?.dashboard_access == undefined || userDataList?.dashboard_access === false) ? { display: "block" } : { display: "none" }}
                                    onClick={() => aprovemailSent("accept")}
                                >
                                    Accept
                                </button>
                                <button
                                    className="blucoastList_specialbtn"
                                    style={(userDataList?.dashboard_access == undefined || userDataList?.dashboard_access === true) ? { display: "block" } : { display: "none" }}
                                    onClick={() => aprovemailSent("reject")}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                            :
                            <div className="modalview_group">
                                <p style={{ color: "#f87f07", fontSize: "14px", fontWeight: 700 }}>*Traffic Partner did not complete the signup process yet.</p>

                            </div>
                        }

                        {loadingApproval && <Box sx={{ width: '100%', marginBottom: '10px' }}> <LinearProgress /> </Box>}

                    </div>
                </Box>
            </Modal>


            {/* ********************confimation modal props***************** */}
            {openConfirm && <ConfirmationModal
                openConfirm={openConfirm}
                setOpenConfirm={setOpenConfirm}
                rowdataCopy={rowdataCopy}
                acceptData={acceptData}
                setManageTFOpen={setManageTFOpen}
            />}

            {/* **************************NDA Contract MOdal********************* */}
            {contractOpne && <NDAContractPreviewTP
                contractOpne={contractOpne}
                setContractOpne={setContractOpne}
                viewContractState={viewContractState}
                userDetails={userDetails}
                rowdataCopy={rowdataCopy}

            />}
        </>
    )
}

export default AproveTrafficPartner