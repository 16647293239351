import React, { useRef, useState } from 'react'
import '../LandingPage19.css'

export default function Landingpage19Block2({ scrollToview }) {
  const [loading, setloading] = useState(false);

  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className='lp19_block2_main_wrapper'>
        <div className='common_width'>
          <div className='lp19_block2_sub_wrapper'>
            <h1>PRICING</h1>

            <div className='lp19_block2_sec_wrapper'>
              <div className='lp19_block2_sub_sec_outer_wrapper'>
                <div className='lp19_block2_sub_sec_wrapper'>
                  <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block2/lp19_blc2_1logo.png`}
                  // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block2/lp19_blc2_1logo.png'
                  />
                  <h2>Individual</h2>
                  <h3>$21.99</h3>
                  <p> For one individual member.</p>
                </div>
              </div>
              <div className='lp19_block2_sub_sec_outer_wrapper'>
                <div className='lp19_block2_sub_sec_wrapper'>
                  <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block2/lp19_blc2_2logo.png`}
                  // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block2/lp19_blc2_2logo.png'
                  />
                  <h2>2 Person Family</h2>
                  <h3>$26.99</h3>
                  <p>Easily add on one additional household family member.</p>
                </div>
              </div>
              <div className='lp19_block2_sub_sec_outer_wrapper'>
                <div className='lp19_block2_sub_sec_wrapper'>
                  <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/block2/lp19_blc2_3logo.png`}
                  // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block2/lp19_blc2_3logo.png'
                  />
                  <h2>Family Plan</h2>
                  <h3>$31.99</h3>
                  <p> For the entire family.</p>
                </div>
              </div>
            </div>

            <div class="Lp14block2_sub1_wrapper">
              <button onClick={() => setScroll()}>Enroll Today</button>
            </div>
          </div>
          
        </div>
      </div>

    </>
  )
}
