import { Badge, Dialog, Icon, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { insertNoteFieldData, notesBtnClicked, onNotesButtonClick, resetNotesLoaded, setTableRowData } from '../listReducer';
import { cloneDeep } from 'lodash';


function NotesBadge({ disabled, iconType, row, buttonData, tableId }) {
  const dispatch = useDispatch();


  const notesLoading = useSelector((state) => state.tableSlice.notesLoading[tableId] ? Boolean(state.tableSlice.notesLoading[tableId]) : false)

  const count = (row[buttonData.count_key] && Number(row[buttonData.count_key])) ? Number(row[buttonData.count_key]) : null

  const detailView = () => {
    if (notesLoading) return;
    const reqBody = buttonData?.tableData?.reqBody ? cloneDeep(buttonData.tableData.reqBody) : { "source": "", "condition": { "limit": 3, "skip": 0 }, "sort": { "type": "desc", "field": "_id" }, "searchcondition": {} }

    reqBody.searchcondition[buttonData?.tableData?.payload_key ? buttonData.tableData.payload_key : '_id'] = row._id ? row._id : 'NA'
    const noteTableData = cloneDeep(buttonData.tableData)
    noteTableData.reqBody = reqBody
    // console.log("reqBodynote",reqBody);

    // console.log("tableId===============================++>", tableId)
    dispatch(onNotesButtonClick({
      BASE_URL: buttonData.tableData.api.url,
      endPoint: buttonData.tableData.api.endPoint,
      reqBody: reqBody,
      tableId: tableId,
      rowId: row._id
    }))
    dispatch(insertNoteFieldData({
      headers: buttonData.headers ? buttonData.headers : [],
      tableData: noteTableData,
      tableId,
      notesForKey: buttonData.notes_for_key,
      noteaddbody: buttonData.noteaddbody,
      noteaddendpoint: buttonData.noteaddendpoint,
      noteaddbaseurl: buttonData.noteaddbaseurl
    }))


    dispatch(setTableRowData({ tableId, row }))


  }


  return (
    <>
      <Tooltip className={buttonData.name} title={buttonData.label ? buttonData.label : ""}>
        <IconButton disabled={disabled} onClick={detailView}>
          <Badge className='badge_icon' badgeContent={count ? count : " "} variant={count ? 'standard' : 'dot'} color="primary" invisible={count ? false : true}>
            <Icon>
              {iconType}
            </Icon>
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  )
}

export default NotesBadge