import { IconButton, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';


const PaymentIpInfo = ({paymentRowData,paymentIpInfoModal,setPaymentIpInfoModal,setPaymentRowData}) => {

    const handleClose = () => {
        
        setPaymentIpInfoModal(false)
        setPaymentRowData({})
      };
  return (
    <div>
         <Modal
            className="modalBaseStyle notesModal_blcoast"
            open={paymentIpInfoModal}
            onClose={handleClose}>
            <Box className='modalBaseBox mediumModal'>
              <Tooltip title='Close' >
                <IconButton onClick={handleClose}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className='view_details'>
                <h2 className='previewModalTitle '>
                  { paymentRowData?.fullname && <div className='modal_title modal_titlenew'> Ip-Info for {paymentRowData.fullname}</div>}
                </h2>
                <div className='modalview_group'>
                  {paymentRowData?.ipinfo && Object.keys(paymentRowData.ipinfo).length > 0 ? <div className='data_container'>
                    <div className='data_block'>
                      {paymentRowData?.ipinfo?.ip && <div className='property_container'><label className='property_label'>IP:</label><label className='property_value'>{paymentRowData.ipinfo.ip}</label></div>}
                      {paymentRowData?.ipinfo?.hostname && <div className='property_container'><label className='property_label'>Host Name:</label><label className='property_value'>{paymentRowData.ipinfo.hostname}</label></div>}
                      {paymentRowData?.ipinfo?.city && <div className='property_container'><label className='property_label'>City:</label><label className='property_value'>{paymentRowData.ipinfo.city}</label></div>}
                      {paymentRowData?.ipinfo?.region && <div className='property_container'><label className='property_label'>Region:</label><label className='property_value'>{paymentRowData.ipinfo.region}</label></div>}
                      {paymentRowData?.ipinfo?.country && <div className='property_container'><label className='property_label'>Country:</label><label className='property_value'>{paymentRowData.ipinfo.country}</label></div>}
                      {paymentRowData?.ipinfo?.loc && <div className='property_container'><label className='property_label'>Location:</label><label className='property_value'>{paymentRowData.ipinfo.loc}</label></div>}
                      {paymentRowData?.ipinfo?.org && <div className='property_container'><label className='property_label'>Organization:</label><label className='property_value'>{paymentRowData.ipinfo.org}</label></div>}
                      {paymentRowData?.ipinfo?.postal && <div className='property_container'><label className='property_label'>Postal:</label><label className='property_value'>{paymentRowData.ipinfo.postal}</label></div>}
                      {paymentRowData?.ipinfo?.timezone && <div className='property_container'><label className='property_label'>Time Zone:</label><label className='property_value'>{paymentRowData.ipinfo.timezone}</label></div>}
                    </div>
                  </div> : <div className='nodatafound'>No Records Found</div>}
                </div>
              </div>
            </Box>
          </Modal>
    </div>
  )
}

export default PaymentIpInfo