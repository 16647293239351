import React, { useEffect, useRef, useState } from "react";
import {
  setEnableRowLoader,
  setReloadTableFlag,
} from "../../../listing/listReducer";

import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { aspireleadownertransfer, getownerShipTransferData } from "./BlueCoastReducer";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TransFerOwnerForPharmacyAspire = ({ ownerShipRowData, paymentTransferOwnerModal, setPaymentTransferOwnerModal, spicialusersall, setPaymentRowData, setForLoading }) => {
  const userInfo = useSelector((state) => (state.loginSlice && state.loginSlice?.userInfo) ? state.loginSlice?.userInfo : null)
  let token = useSelector((state) => state.loginSlice.token);

  

  const dispatch = useDispatch();
  const tranceFormOwershipText = useRef(null)
  const tranceFormOwershipTextValue = useRef(null)
  const [transferOwnershipModlaOpen, setTransferOwnershipModlaOpen] = useState(false);
  const [ownerShipLoader, setOwnerShipLoader] = useState(false);
  const [sendEmailtoSpecialEntity, setSendEmailtoSpecialEntity] = useState(false);
  const [sendEmailtoTravisModal, setSendEmailtoTravisModal] = useState(false);

  const [changeOwnerID, setChangeOwnerID] = useState(null);
  const [ownershipRowData, setOwnershipRowData] = useState(null);
  const [addNotesTransferOwner, setAddNotesTransferOwner] = useState('');
  const [sendContractTo, setSendContractTo] = useState(null);
  const handleAddNote = events => {

    setAddNotesTransferOwner(events.target.value);
    // console.log('Notesssssssssssssssssssssssssss', events.target.value);
  };

  const handleOwnershipChange = event => {
    setChangeOwnerID(event.target.value)

    let betoId = process.env.REACT_APP_ENVIRONMENT == 'dev' ? "642551962ec2465a2d30831a" : "6426b8597fec19a24e5186fa"

    let travisId = process.env.REACT_APP_ENVIRONMENT == 'dev' ? "642679d3179b46420e44ae36" : "6426b9d0fab6d78fa2e2f06b"
    // console.log("betoId====123", betoId, travisId);
    if (event.target.value == betoId || event.target.value == travisId) {
      // console.log("betoId>>>>>", betoId)
      if ((ownershipRowData?.bluecoastPh == true || ownershipRowData?.bluecoastphblue == true) && event.target.value == betoId) {
        // console.log("ownershipRowData>>>>213", ownershipRowData);
        setSendEmailtoSpecialEntity(true);
      }
      if (((!ownershipRowData?.bluecoastPh && !ownershipRowData?.bluecoastphblue) || ownershipRowData.outsideData == true) && event.target.value == travisId) {
        // console.log("ownershipRowData>>>>213", ownershipRowData);
        setSendEmailtoTravisModal(true);
      }
    } else {


    }


  }
  const ChooseSendEmail = (val) => {
    setSendContractTo(val)
    if (val == 'Beto') {
      handleCloseEmailtoSpecialEntity()

    } else {
      handleCloseEmailtoTravis()
    }
  }

  const handleCloseEmailtoSpecialEntity = () => {
    setSendEmailtoSpecialEntity(false)
  }
  const handleCloseEmailtoTravis = () => {
    setSendEmailtoTravisModal(false)
  }


  useEffect(() => {
    if (ownerShipRowData) {
      setChangeOwnerID(ownerShipRowData.owner)


    }
  }, [ownerShipRowData])


  const changeOwnershp = async (val) => {
    // console.log(val, "update transfer vale1234", ownerShipRowData)

    let dataSetOwner = {
      "_id": ownerShipRowData._id,
      "owner": changeOwnerID,
      "notes": addNotesTransferOwner,
      "added_by": userInfo?._id,
       token: token
    }
    setOwnerShipLoader(true)

    await dispatch(getownerShipTransferData(dataSetOwner))


    setOwnerShipLoader(false)

    tranceFormOwershipText.current = null
    tranceFormOwershipTextValue.current.value = null
    // setAddNotesTransferOwner('')

    dispatch(setSnackbar({ open: true, message: 'Ownership Transfered Successfully !!', status: 'success' }))
  }
  const CloseOwnershipModal = () => {
    // setAddNotesTransferOwner('')
    setPaymentTransferOwnerModal(false);
    setPaymentRowData({})
    setForLoading(true)
  };



  

  // console.log("tranansfer owership row", ownerShipRowData);

  return (
    <>

      {/* <Modal
          className="modalBaseStyle Alldisposition_Modal Ownership_trnsfer notesModal_blcoast"
          open={paymentTransferOwnerModal}
          onClose={CloseOwnershipModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={CloseOwnershipModal}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className='priority_Box_Main'>
              <h2 className='previewModalTitle '>

                <div className='modal_title modal_titlenew'> Transfer Ownership</div>
              </h2>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Transfer Ownership</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={changeOwnerID}
                    label="Manage Set Priority"
                    onChange={handleOwnershipChange}
                    MenuProps={MenuProps}
                  >
                    {spicialusersall && spicialusersall.length > 0 && spicialusersall.map((data, idx) => (
                      <MenuItem key={idx} value={data._id}>
                        <ListItemText primary={data.firstname + ' ' + data.lastname} />
                      </MenuItem>
                    ))}
                  </Select>
                  <div className='transfer_owner_notes'>
                    <TextareaAutosize
                      label="Add Notes"
                      aria-label="minimum height"
                      minRows={4}
                      placeholder="Add Notes..."
                      value={addNotesTransferOwner}
                      onChange={handleAddNote}
                      style={{ width: 800 }}
                    />
                  </div>
                  <div className='Formsubmit_button'>
                    <Button variant="contained" onClick={() => changeOwnershp()}>Submit</Button>
                  </div>
                </FormControl>
              </Box>

              {ownerShipLoader && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}

            </div>
          </Box>
        </Modal> */}




      <Modal
        className="modalBaseStyle Alldisposition_Modal Ownership_trnsfer notesModal_blcoast"
        open={paymentTransferOwnerModal}
        onClose={CloseOwnershipModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={CloseOwnershipModal}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className='priority_Box_Main'>
            <h2 className='previewModalTitle '>

              <div className='modal_title modal_titlenew'> Transfer Ownership</div>
            </h2>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Transfer Ownership</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={changeOwnerID}
                  label="Manage Set Priority"
                  onChange={handleOwnershipChange}
                  MenuProps={MenuProps}
                >
                  {spicialusersall && spicialusersall.length > 0 && spicialusersall.map((data, idx) => (
                    <MenuItem key={idx} value={data._id}>
                      <ListItemText primary={data.firstname + ' ' + data.lastname} />
                    </MenuItem>
                  ))}
                </Select>
                <div className='transfer_owner_notes'>
                  <TextareaAutosize
                    label="Add Notes"
                    aria-label="minimum height"
                    minRows={4}
                    placeholder="Add Notes..."
                    // value={addNotesTransferOwner}
                    ref={tranceFormOwershipTextValue}
                    onChange={(e) => tranceFormOwershipText.current = e.target.value}
                    style={{ width: 800 }}
                  />
                </div>
                <div className='Formsubmit_button'>
                  <Button variant="contained" onClick={() => changeOwnershp()}>Submit</Button>
                </div>
              </FormControl>
            </Box>

            {ownerShipLoader && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}

          </div>
        </Box>
      </Modal>




      {/* *************Send Notification to Beto Modal************** */}
      <Modal
        className="modalBaseStyle sendEmail_modal notesModal_blcoast"
        open={sendEmailtoSpecialEntity}
        onClose={handleCloseEmailtoSpecialEntity}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={handleCloseEmailtoSpecialEntity}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {/* <h2 className='previewModalTitle modal_title'>
                {(changeOwnerID == 'Beto Parades' || changeOwnerID == 'Travis Baggett') && "Are you want send notification?"}
              </h2> */}
          <div className='notes_main_header'>
            <p>Attention!</p>
            <div className='modalview_group'>
              <p>Send Contact Email to AE?</p>
              <div className='confirmationBtns'>
                <button className="blucoastList_specialbtn" onClick={() => ChooseSendEmail('Beto')}>Yes</button>
                <button className="blucoastList_specialbtn" onClick={handleCloseEmailtoSpecialEntity}>No</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* *************Send Notification to Travis Modal************** */}
      <Modal
        className="modalBaseStyle sendEmail_modal notesModal_blcoast"
        open={sendEmailtoTravisModal}
        onClose={handleCloseEmailtoTravis}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={handleCloseEmailtoTravis}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className='notes_main_header'>
            <p>Attention!</p>
            <div className='modalview_group'>
              <p>Send Contact Email to AE?</p>
              <div className='confirmationBtns'>
                <button className="blucoastList_specialbtn" onClick={() => ChooseSendEmail('Travis')}>Yes</button>
                <button className="blucoastList_specialbtn" onClick={handleCloseEmailtoSpecialEntity}>No</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>






    </>
  )
}

export default TransFerOwnerForPharmacyAspire