import { Button, IconButton, LinearProgress, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../../../../store';
import { employeePackageStatus } from '../../../EmployerReducer';
import { setSnackbar } from '../../../../../Layout/layoutReducer';


const PackageStatus = ({ packageStatusOpen, setPackageStatusOpen, TestReplay }) => {

    const dispatch = useDispatch()

    const employeeData = useSelector((state) => state.EmployerReducer?.employeePackageAllData && Object.keys(state.EmployerReducer.employeePackageAllData).length > 0 ? state.EmployerReducer?.employeePackageAllData : {})
    const employeeLoader = useSelector((state) => state.EmployerReducer?.statusloading ? state.EmployerReducer.statusloading : null)


    const statusChnageApiCall = async (val) => {
        let value = val == 1 ? true : false

        let reqBody = {
            "_id": employeeData._id,
            status: value
        }
        await store.dispatch(employeePackageStatus(reqBody))
        // console.log("reqBody==========>>>>", reqBody);
        dispatch(
            setSnackbar({
                open: true,
                message: "Status Updated Succesfully!",
                status: "success",
            })
        );
        setTimeout(() => {
            handleCloseMOdal() 
        }, 1000);
        TestReplay()
    }

    const handleCloseMOdal = () => {
        setPackageStatusOpen(false)
    }

    return (
        <>
            <Modal
                className="modalBaseStyle sendEmail_modal notesModal_blcoast SR_UserDetail_preview"
                open={packageStatusOpen}
                onClose={handleCloseMOdal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseMOdal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className="notes_main_header">


                        <p className='alertPara2'> Alert !!</p>


                        <div className="modalview_group">



                            <p> Are You Sure You Want To Change Status? </p>

                            <div className='packageStatus_button' style={{display: "flex", justifyContent: "center"}}>
                                <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => statusChnageApiCall(1)} className="modalBTN">Active</Button>
                                <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => statusChnageApiCall(0)} className="modalBTN">Incative
                                </Button>

                            </div>
                        </div>



                        {employeeLoader && <Box sx={{ width: '100%', marginBottom: '10px' }}> <LinearProgress /> </Box>}

                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default PackageStatus