import { IconButton, LinearProgress, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { commissionSingleFetchData, fetchAeData, fetchCommissionGlobalSettings, fetchCommissionInformation, fetchLeadData, getCommissionSingalData } from '../../commissionReducer';
import CommissionAddEditForm from './AddCommissionForm'
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system';


const AddCommission = (props) => {

    const [queryParamId, setQueryParamId] = useState();//----state for get ParamId

    const dispatch = useDispatch()
    const navigate = useNavigate();

    //------------------------fetch Commission row singal Data--------------------------------
    const commissionSingalData = useSelector((state) => state.commissionReducer?.commissionSingalData ? state.commissionReducer.commissionSingalData : {})

    const loader = useSelector((state) => state.commissionReducer?.loading ? state.commissionReducer.loading : false)

    const leadRef = useSelector((state) => state.formSlice?.formData?.AddFormCommission?.lead ? state.formSlice.formData.AddFormCommission.lead : null)

    const aeRef = useSelector((state) => state.formSlice?.formData?.AddFormCommission?.associated_rep ? state.formSlice.formData.AddFormCommission.associated_rep : null)

    const selected_programme = useSelector(state => (state.formSlice?.formData?.AddFormCommission?.associated_programme) ? state.formSlice.formData.AddFormCommission.associated_programme : null);


    const commissionFetchData = useSelector((state) => state.commissionReducer?.commissionSingleData ? state.commissionReducer.commissionSingleData : {})





    //========================================================================================



    // ---- on AE selection endpoint call for fetch data ------ //
    useEffect(() => {
        if (aeRef !== null) {
            dispatch(fetchAeData({ "rep_id": aeRef }))
        }
    }, [aeRef])
    // -------------------------------------------------------- //




    // ---- on Lead selection endpoint call for fetch data ------ //
    useEffect(() => {
        if (leadRef !== null) {
            dispatch(fetchLeadData({ "lead": leadRef }))
        }
    }, [leadRef])
    // -------------------------------------------------------- //



    // ---- on Programme selection endpoint call for fetch data ------ //
    useEffect(() => {
        if (selected_programme !== null) {
            dispatch(fetchCommissionInformation({ "associated_program": selected_programme }))
        }
    }, [selected_programme])
    // -------------------------------------------------------- //



    // ------ fetch Global SettingsData ------- //
    useEffect(() => {
        dispatch(fetchCommissionGlobalSettings({}))
    }, [])
    // ----------------------------------- // 







    // -------- Getting QueryParam and Calling thunk for fetching data through endpoint ---------

    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])


    useEffect(() => {
        if (queryParamId !== undefined && queryParamId !== null && Object.keys(commissionFetchData).length === 0) {
            dispatch(commissionSingleFetchData({ "_id": queryParamId }));
        }
    }, [queryParamId])

    // console.warn("<<<<< query param from addEditFrom component>>>>>",queryParamId)

    //----------------------------------------------------------------------------------------









    return (
        <>
            <h1 className='page_heading'> {queryParamId ? "Edit" : "Add"} Commission  </h1>


            <div className='dataform2_wrapper'>

                {loader ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null}

                <div className='adminformbody'>
                    {queryParamId ? Object.keys(commissionFetchData)?.length > 0 &&
                        <CommissionAddEditForm
                            queryparamid={queryParamId}
                            commissionsdata={commissionFetchData}
                        /> : <CommissionAddEditForm />}

                </div>

            </div>

        </>
    )
}

export default AddCommission