import React from 'react'
import '../LandingPage19.css'
import { useSelector } from 'react-redux';

export default function Landingpage19Footer() {
  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
// const id = 
  return (
    <>
      <div className='Landingpage19_Footer'>
        <div className='common_width'>
          <div className='Landingpage19_Footer_block1'>

            <div className="Landingpage19_Footer_flex">
              <div className="Landingpage19_Footer-img">
                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage19/footer/Landingpage19AccessFooterlogo.png`}
                // 'https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/footer/Landingpage19AccessFooterlogo.png' 
                />
              </div>
              <div className="Landingpage19_Footer_text">
                <h1>THIS IS NOT INSURANCE</h1>
                <h5>Questions?<br />
                  Please contact our Customer Support Team at <span><a href='tel:1-800-378-4025'>1-800-378-4025</a> </span></h5>
              </div>
              <div className="Landingpage19_Footer_button">
                <h2>Enrollment Code:{userData.agent_code}</h2>
              </div>


            </div>
            {(userData?.is_nakagames === true) && <a href={`/nakagames/${userData?.user_name}`} target='_blank' className='linked_btn'>
              <button className=' lp14_btn'>
                join as an affiliate

              </button>
            </a>}

          </div>
        </div>
      </div>
      <div className='Landingpage19_Footer_block2 '>
        <p>2023 Access Discount Healthcare All Rights Reserved</p>
      </div>
    </>
  )
}
