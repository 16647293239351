
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";
import "../../Dashboard/Dashboard.css"
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, clerTagData, FetchTagDetails, updateTags } from './userListReducer';



const ConfigureTagsModal = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----


    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null)
    const [snackMsg,setSnackMsg]=useState(null)

    // console.warn("<<<<<< props goes here >>>>>>", props)
    console.warn("<<<<<< clicked>>>>>>", props)

    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)
    const tagsDetails = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : [])
    // const success = useSelector(state => (state.userManagment?.resMessage) ? state.userManagment?.resMessage : null)
    const loggedUserId = useSelector((state) => state.loginSlice?.userInfo?._id ? state.loginSlice.userInfo._id : null)
    const tagUpdated = useSelector(state => (state?.userManagment?.tagUpdated) ? state.userManagment.tagUpdated : false)
    console.log("lead tagsDetails", tagsDetails);
    useEffect(() => {
        if (tagUpdated === true) {
            const data = {
                "_id": props.UserId,
                "roles": { "$in": [props.Userinfo.main_role] },
                "project": { subject: 1 }
            }
            dispatch(FetchTagDetails(data))
        }
    }, [tagUpdated])

    // useEffect(() => {
    //     if (tagUpdated === true) {
    //         const data = {
    //             "_id": props.UserId,
    //             "roles": { "$in": [props.Userinfo.main_role] },
    //             "project": { subject: 1 }
    //         }
    //         dispatch(FetchTagDetails(data))
    //     }
    // }, [tagUpdated])


    // useEffect(() => {
    //     if (success) {
    //         setopen(true)
    //     }
    // }, [success])


    useEffect(() => {
        if (tagsDetails.length > 0) {
            setOpenDropDown(false)
        }
    }, [tagsDetails])




    useEffect(() => {
        props.setModalResponse(false);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModalResponse(false);
        props.tooglefunc(false);
        setOpenDropDown(false)
        dispatch(clerTagData())

    }

    // ----------- Delete Chips ----------------------
    const deleteChips = (dltVal) => {

        const modifiedTagDetails = tagsDetails.map((tag) => ({
            tag_slug: tag.key,
            added_by: loggedUserId,
            tag_name: tag.label,
            added_for: props.UserId,
            is_delete: dltVal.status === 0 ? 0 : 1,
            status: (dltVal.key === tag.key) ? dltVal.status === 0 ? 1 : 0 : tag.status,
            updated: (dltVal.key === tag.key) ? true : false
        }))
        console.log("modifiedTagDetails==========>", modifiedTagDetails)

        dispatch(updateTags({ data: modifiedTagDetails, _id: props.UserId }));
        
        

      
            setSnackMsg("Tag  Removed Successfully")
            setopen(true)
        
    };
    // --------------------------------

    useEffect(() => {
        if (clicked) {
            const modifiedTagDetails = tagsDetails.map((tag) => ({
                tag_slug: tag.key,
                added_by: loggedUserId,
                tag_name: tag.label,
                added_for: props.UserId,
                is_delete: clicked.status === 0 ? 0 : 1,
                status: (clicked.key === tag.key) ? clicked.status === 0 ? 1 : 0 : tag.status,
                updated: (clicked.key === tag.key) ? true : false
            }))

            dispatch(updateTags({ data: modifiedTagDetails, _id: props.UserId }));
            setOpenDropDown(false)
            setSnackMsg("Tag  Assigned Successfully")
            setopen(true)
           
        }
    }, [clicked])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h4">
                        {loader ? <LinearProgress /> : ""}

                        CONFIGURE TAGS FOR: <span className='name'>{props?.Userinfo?.name}</span>
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}
           
                    <form>
                        <div className='MK_tagmodel'>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Select Tags</InputLabel>

                                <Select

                                    open={openDropDown}
                                    onOpen={() => setOpenDropDown(true)}
                                    onClose={() => setOpenDropDown(false)}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={tagsDetails.filter((item) => item.status === 1).map((item) => item)}
                                    input={<OutlinedInput label="tag" />}
                                    renderValue={(tagList) => tagList.map(tag => tag.label).join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {tagsDetails.map((item) => (
                                        <MenuItem key={item.key} value={item} onClick={() => setClicked(item)}>
                                            <Checkbox checked={Boolean(item.status)} />
                                            <ListItemText primary={item.label} />
                                        </MenuItem>
                                    ))}

                                </Select>


                                <Stack direction="row" spacing={1} sx={{
                                    marginTop: "10px",
                                }}>

                                    <div className='ChipsSet-grp' sx={{
                                        display: "flex",
                                        width: '100%',
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        margin: "0 auto",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                    }}>

                                        {tagsDetails.length > 0 && loader === false && tagsDetails.filter((tag) => tag.status === 1).length > 0
                                            ? tagsDetails.filter((tag) => tag.status === 1).map((val) => <Chip
                                                label={val.label}
                                                variant="outlined"
                                                sx={{ margin: '3px 3px' }}
                                                onDelete={() => deleteChips(val)}
                                            />)
                                            : tagsDetails.length > 0 && loader === false && tagsDetails.filter((tag) => tag.status === 1).length === 0 ?
                                            (<h3> No Tags Assigned</h3>)
                                            : ""}



                                        {/* {copyUserRoles.length > 0 ? copyUserRoles.map((val) => val.length > 0 ? (<Chip label={val} variant="outlined" onDelete={() => deleteChips(val)} />):"") : (<h3> No Role Assigned</h3>)} */}
                                    </div>


                                </Stack>



                            </FormControl>
                        </div>
                        {/* <Button variant="contained" onClick={ModalClose} className="modalBTN">Cancel</Button> */}
                    </form>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {open && props.fromDashbord !== true ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={snackMsg}
            // action={action}
            />) : ""}



        </>
    )
}


export default ConfigureTagsModal;










