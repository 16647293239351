import React, { useEffect, useRef, useState } from 'react'
import { Cookies } from "react-cookie";
import store from '../../../store'
import { useSelector, useDispatch } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LinkIcon from '@mui/icons-material/Link';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import copy from "copy-to-clipboard";
import { setSnackbar } from '../../Layout/layoutReducer';
import { Badge, Button, Fade, IconButton, LinearProgress, Modal, TextField, Tooltip } from '@mui/material';
import UserNameCreate from './../../CampaignManagement/UserNameCreate';
import CampainManageModal from './CampainManageModal';
import { getCampaignListData, getAllCampaignListData, conversionListDatacon, setmindate, setmaxDate, clearConversionListData, clearData } from '../../CampaignManagement/CampaignReducer';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import QRCode from "qrcode.react";
import Select from '@mui/material/Select';
import { clearConversionCount, getcampaignaccessdata, getconversioncount, getconversioncountontime, getpiechartdata, getuniqurlhs } from '../Dashboard/DashboardReducer';
import { setlandingwithcampain } from '../../Frontend/LandingPage/LandingpageReducer';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import ListingTable from '../../../listing/listing';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import { conversionListData } from '../../CampaignManagement/CampaignReducer';
import moment from 'moment';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LazyImage from '../../Frontend/LandingPage/LandingPage13/Components/LazyImage';
import { QrCodeScanner } from '@mui/icons-material';


const CustomLinks = (props) => {

    const cookies = new Cookies();
    const token = cookies.getAll().token;
    const myRef = useRef(null)
    let divLoader = useSelector(state => (state.camPaignManagementReducer?.loading) ? state.camPaignManagementReducer.loading : false);
    const [landingLiksArray, setlandingLiksArray] = useState(null);
    const [loadershow, setloadershow] = useState(false);
    const [getcookies, setgetcookies] = useState(cookies.getAll());
    const dispatch = useDispatch();
    const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
    const user_name = useSelector((state) => state.loginSlice.userInfo?.user_name ? state.loginSlice.userInfo.user_name : null);
    const user_data = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);
    const userurlhasornot = useSelector((state) => (state.DashboardReducer?.useruniqurlhas && Object.keys(state.DashboardReducer.useruniqurlhas).length > 0) ? state.DashboardReducer.useruniqurlhas : null);
    const [openUserModal, setUserModal] = useState(false);
    const [openCampainModal, setCampainModal] = useState(false);
    const [valueOfCampainModal, setvalueOfCampainModal] = useState(false);
    const [redundentFlag, setRedundentFlag] = useState(false);
    const [pagadataObj, setpagadataObj] = useState({});
    const [copyUrlModal, setCopyUrlModal] = useState(false) /****For Copy url Listing Modal* */
    const [flagfoshowdoopdown, setflagfoshowdoopdown] = useState(false);
    const [landingpageclick, setlandingpageclick] = useState(null);
    const [openQRCodeModal, setOpenQRCodeModal] = useState(false);
    const [qrValue, setQrValue] = useState("")
    const [qrId, setQRid] = useState("")
    const [valuee, setValuee] = useState([null, null]);
    const maxDateForDateRangePicker = new Date();
    const [showpiechart, setshowpiechart] = useState(true);
    const [loaderforclick, setsloaderforclick] = useState(false);
    const [openCountMOdal, setOpenCountMOdal] = useState(false);
    const [copyUrlSnackModal, setCopyUrlSnackModal] = useState(false);

    const userconversioncount = useSelector((state) => (state.DashboardReducer?.conversioncount) ? state.DashboardReducer.conversioncount : null);
    const userconversioncountonsecch = useSelector((state) => (state.DashboardReducer?.conversioncounttime) ? state.DashboardReducer.conversioncounttime : null);
    const piechartdtataforcon = useSelector((state) => (state.DashboardReducer?.paichartdataforconviesion) ? state.DashboardReducer.paichartdataforconviesion : null);
    const piechartdtataforclick = useSelector((state) => (state.DashboardReducer?.paichartdataforclick) ? state.DashboardReducer.paichartdataforclick : null);
    const campaginaccessData = useSelector((state) => (state.DashboardReducer?.campaginaccessdata) ? state.DashboardReducer.campaginaccessdata : null);
    let loaderUrl = useSelector((state) =>state.camPaignManagementReducer?.loading? state.camPaignManagementReducer.loading: false);
    let mindate = useSelector((state) => state.camPaignManagementReducer?.mindate ? state.camPaignManagementReducer.mindate : null);
    let maxDate = useSelector((state) => state.camPaignManagementReducer?.maxDate ? state.camPaignManagementReducer.maxDate : null);
    const copyUrlbuttonclicked = useSelector((state) => state.tableSlice?.customButtonClicked?.campaignurldataTable?.cpoyurlbtn ? state.tableSlice.customButtonClicked.campaignurldataTable.cpoyurlbtn : false)
    const dataforcopyurl = useSelector((state) => state.tableSlice?.tempRowData?.campaignurldataTable ? state.tableSlice.tempRowData.campaignurldataTable : {})
    const qrButtonClick = useSelector((state) =>state.tableSlice?.customButtonClicked?.campaignurldataTable?.qrcodebutton? state.tableSlice.customButtonClicked.campaignurldataTable.qrcodebutton: false);
    // let copyUrlcampaingDataforlist = useSelector((state) => state.camPaignManagementReducer?.campaignData && state.camPaignManagementReducer?.campaignData.length > 0 ? state.camPaignManagementReducer.campaignData : []);


    const CloseCountModal = () => {
        setOpenCountMOdal(false)
        delete tableDataConversionDetails.current.reqBody.searchcondition["createdon_datetime"]
        delete tableDataConversionDetailsforconversion.current.reqBody.searchcondition["createdon_datetime"]
        setSelected(1)
        dispatch(clearData())
        setValuee([null, null])
        setcountDetailsAll(null)
        dispatch(clearConversionListData())
        dispatch(clearConversionCount())
    };


    useEffect(() => {
        if (user_id) {
            dispatch(getcampaignaccessdata(user_id))
        }
    }, [user_id])

    const [selected, setSelected] = useState(1);
    const [countDetailsAll, setcountDetailsAll] = useState(null);








    useEffect(() => {
        dispatch(clearData())
        return () => {
            dispatch(clearData())
            setValuee([null, null])
        }
    }, [])


    const handelsearch = async () => {
        let stsrtTtime = moment(valuee[0]).valueOf();
        let endTtime = moment(valuee[1]?.getTime()).add((24 * 3600 * 1000) - 1000, 'milliseconds').valueOf();
        dispatch(setmindate(stsrtTtime))
        dispatch(setmaxDate(endTtime))

        setsloaderforclick(true)
        tableDataConversionDetails.current.reqBody.searchcondition["lead_id"] = { $exists: false }
        tableDataConversionDetails.current.reqBody.searchcondition["createdon_datetime"] = {
            $gte: stsrtTtime,
            $lte: endTtime,
        }
        await dispatch(conversionListData(tableDataConversionDetails.current.reqBody))
        tableDataConversionDetailsforconversion.current.reqBody.searchcondition["lead_id"] = { $exists: true }
        tableDataConversionDetailsforconversion.current.reqBody.searchcondition["createdon_datetime"] = {
            $gte: stsrtTtime,
            $lte: endTtime,
        }

        await dispatch(conversionListDatacon(tableDataConversionDetailsforconversion.current.reqBody))
        let bodyc = { ...tableDataConversionDetails.current.reqBody.searchcondition }
        if (bodyc.lead_id) {
            delete bodyc.lead_id
        }
        bodyc["createdon_datetime"] = {
            $gte: stsrtTtime,
            $lte: endTtime,
        }
        await dispatch(getconversioncountontime(bodyc))
        await dispatch(getpiechartdata(bodyc))
        setshowpiechart(false)
        setTimeout(() => {
            setshowpiechart(true)
        }, 500);
        setsloaderforclick(false)
        return
    };




    const copiedToClipboard = (e) => {
        copy(e);
        dispatch(setSnackbar({ open: true, message: "Copied To Clipboard!", status: 'success' }))
    }

    useEffect(() => {
        if (copyUrlbuttonclicked && Object.keys(dataforcopyurl).length > 0) {
            if (campaginaccessData?.campaign_access == 2) {
                let copyVal
                if (dataforcopyurl.landing_page_id) {
                    if (landingLiksArray.length > 0) {
                        for (let i in landingLiksArray) {
                            if (landingLiksArray[i]._id == dataforcopyurl.landing_page_id) {
                                copyVal = landingLiksArray[i].domain_url + dataforcopyurl.user_name;
                            }
                        }
                    }
                }
                copiedToClipboard(copyVal)
            } else {
                setCopyUrlSnackModal(true)
            }
        }
    }, [copyUrlbuttonclicked]);

    /************QR Data Value*************** */
    const [openQRModal, setOpenQRModal] = useState(false);
    const [qrCodeValue, setQrCodeValue] = useState("")
    const [qrCodeId, setQRCodeid] = useState("")


    useEffect(() => {
        if (qrButtonClick && Object.keys(dataforcopyurl).length > 0) {
            if (campaginaccessData?.campaign_access == 2) {
                let QRVal
                let QrCodeId
                if (dataforcopyurl.landing_page_id) {
                    if (landingLiksArray.length > 0) {
                        for (let i in landingLiksArray) {
                            if (landingLiksArray[i]._id == dataforcopyurl.landing_page_id) {
                                QrCodeId = dataforcopyurl.user_name;
                                QRVal = landingLiksArray[i].domain_url + dataforcopyurl.user_name;
                            }
                        }
                    }
                }
                setQRCodeid(QrCodeId)
                setQrCodeValue(QRVal)
            }
            setOpenQRModal(true)
        }
    }, [qrButtonClick])




    /*************************************************************************************************** */


    const campainData = useSelector((state) => state.camPaignManagementReducer?.allcampaignData && state.camPaignManagementReducer?.allcampaignData.length > 0 ? state.camPaignManagementReducer.allcampaignData : []);
    let flag = true;
    const copyToClipboard = (e) => {
        let copyValue = ''
        if (e.user_name != undefined && campaginaccessData?.campaign_access == 2) {
            if (e.sub_id != undefined) {
                copyValue = e.domain_url + e.user_name + e.sub_id;
            }
            else {
                copyValue = e.domain_url + e.user_name;
            }
            copy(copyValue);
        }
        if (!e.user_name) {
            dispatch(setSnackbar({ open: true, message: "Add Campaign To Copy!", status: 'error' }))

        } else if (campaginaccessData?.campaign_access !== 2) {
            setCopyUrlSnackModal(true)
        } else {
            dispatch(setSnackbar({ open: true, message: "Copied To Clipboard!", status: 'success' }))
        }
    }

    const qrCodeFunction = (e) => {

        let qrCodeVal = ''
        let qrCodeId = ''
        if (e.user_name != undefined && campaginaccessData?.campaign_access == 2) {
            if (e.sub_id != undefined) {
                qrCodeId = e.user_name + e.sub_id;
                qrCodeVal = e.domain_url + e.user_name + e.sub_id;
            }
            else {
                qrCodeId = e.user_name;
                qrCodeVal = e.domain_url + e.user_name;

            }
            setQRid(qrCodeId)
            setQrValue(qrCodeVal)
            setOpenQRCodeModal(true)
        }
    }



    const campainMnage = (val, url, p_name) => {
        console.log("campain_modal_data",val,url,p_name);
        setpagadataObj({ page_id: val, p_url: url, p_name: p_name })
        if (user_data && user_data.user_name && user_data.user_name !== null) {
            let obj = { page_id: val, p_url: url, p_name: p_name };
            setvalueOfCampainModal(obj);
            findCampainSingleValue(val);
            setCampainModal(true);
        }
        else {
            setUserModal(true);
        }
    }

    const setUsernameOption = (value, id) => {

        let addlandingLiksArray = landingLiksArray
        addlandingLiksArray = { ...addlandingLiksArray }


        for (const k in addlandingLiksArray) {
            if (addlandingLiksArray[k]._id == id) {
                addlandingLiksArray[k] = { ...addlandingLiksArray[k], user_name: value };
            }
        }
        const result = Object.keys(addlandingLiksArray).map((key) => addlandingLiksArray[key]);
        setlandingLiksArray(result);
        dispatch(setlandingwithcampain(result))
    }

    const handleCloseUserModal = async (val, user_name) => {

        if (val == 1 && Object.keys(pagadataObj).length > 0) {
            if (typeof user_data !== 'undefined' && (user_data.user_name || user_name)) {
                let obj = pagadataObj;
                setvalueOfCampainModal(obj);
                findCampainSingleValue(val);
                setCampainModal(true);
            }
        }
        if (val == 1) {
            setUserModal(false)
        } else {
            if (userurlhasornot && Object.keys(userurlhasornot) && userurlhasornot.uniqe_url_has) {
                setUserModal(false)
            }
        }

        if (campainData !== undefined && campainData.length > 0) {
            setArrayValue(campainData, user_data?.user_name)
        }
        store.dispatch(getuniqurlhs(user_id))
        findCampainValue()

    };

    const handleCloseCampaignModal = () => {

        findCampainValue();
        console.warn(campainData, "campainData && getcookies==>", getcookies);
        if (campainData !== undefined && campainData.length > 0) {
            setArrayValue(campainData, user_data?.user_name)
        }
        setflagfoshowdoopdown(true)
        setTimeout(() => {
            setflagfoshowdoopdown(false)
            setCampainModal(false)
        }, 1000);
        store.dispatch(getuniqurlhs(user_id))
    };



    useEffect(() => {
        findCampainValue()
        dispatch(getconversioncount(user_id))
        return () => {
        };
    }, []);

    useEffect(() => {
        if (campainData !== undefined && campainData.length > 0) {

            setArrayValue(campainData, user_data.user_name)
        } else {
            if (!redundentFlag)
                setArrayValue(campainData, user_data?.user_name)
        }
    }, [JSON.stringify(campainData)])



    const setArrayValue = (campainData, username) => {
        let campCount = 0;
        let campArray = [];
        let defaultUser = null
        console.warn("LIST && CAMPAIN ======>", campainData, landingLiksArray, props.customlinksData);
        let addlandingLiksArray = props.customlinksData
        addlandingLiksArray = { ...addlandingLiksArray }
        if (campainData !== undefined && campainData.length > 0) {
            for (let i in addlandingLiksArray) {
                for (const key in campainData) {
                    if (addlandingLiksArray[i]._id == campainData[key].landing_page_id) {
                        campCount++;
                        campArray.push({ name: campainData[key].name, user_name: campainData[key].user_name, status: campainData[key].status })
                        if (typeof campainData[key].default != 'undefined' && campainData[key].default == true) {
                            defaultUser = campainData[key].user_name
                        }
                        if (defaultUser != null) {
                            addlandingLiksArray[i] = { ...addlandingLiksArray[i], count: campCount, user_name: defaultUser, campDeatis: campArray };

                        } else {
                            addlandingLiksArray[i] = { ...addlandingLiksArray[i], count: campCount, user_name: campainData[key].user_name, campDeatis: campArray };
                        }

                    }

                }
                campCount = 0;
                campArray = [];
                defaultUser = null
            }


        }





        const result = Object.keys(addlandingLiksArray).map((key) => addlandingLiksArray[key]);
        setlandingLiksArray(result);
        setRedundentFlag(true)
        dispatch(setlandingwithcampain(result))
    }


    const findCampainValue = () => {
        let reqbody = {
            condition: {
                limit: 100,
                skip: 0,
            },
            sort: {
                field: "updatedon_datetime",
                type: "desc",
            },
            searchcondition: {
                "user_id": user_id,
            },
            project: {},
            token: "",
        };
        store.dispatch(getAllCampaignListData(reqbody));
    }

    const findCampainSingleValue = (val) => {
        let reqbody = {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "updatedon_datetime",
                type: "desc",
            },
            searchcondition: {
                "user_id": user_id,
                "landing_page_id": val ? val : undefined
            },
            project: {},
            token: "",
        };
        store.dispatch(getCampaignListData(reqbody));
    }


    const [modalDatat, setmodalDatat] = useState(null);
    const [openText, setOpenText] = React.useState(false);
    const handleCloseText = () => {
        setOpenText(false);
    };




    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const addurloutside = async () => {
        if (userurlhasornot && !userurlhasornot.uniqe_url_has) {
            setUserModal(true);
        } else {
            dispatch(setSnackbar({ open: true, message: `Unique URL Already Created, Your Unique URL is ${user_name}`, status: 'info' }))
        }
    }


    const addcampainoutsideforcampign = async () => {
        setopencapinchose(true)
    }
    const gotocampain = (id, url, name) => {
        setopencapinchose(false)
        campainMnage(id, url, name)
    }

    const copyUrlcampign = async () => {
        let requestbody = {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "updatedon_datetime",
                type: "desc",
            },
            searchcondition: {
                "user_id": user_id,
            },
            project: {},
            token: "",
        };
        dispatch(getCampaignListData(requestbody))
        setCopyUrlModal(true)
    }




    const [opencapinchose, setopencapinchose] = useState(false)


    useEffect(() => {
        if (userurlhasornot && Object.keys(userurlhasornot) && !userurlhasornot.uniqe_url_has) {
            addurloutside()
        }

    }, [JSON.stringify(userurlhasornot)])




    const finddefaultvalue = (val) => {
        if (val.campDeatis && val.campDeatis.length > 0) {
            let b = [...val.campDeatis].reverse()
            let obj = b.find(o => o.status == 1)
            if (obj && Object.keys(obj).length > 0) {
                return obj.user_name
            }

        }
    }

    // ************conversion lIst********************
    let conversionListdataset = useSelector((state) => state.camPaignManagementReducer?.conversionList && state.camPaignManagementReducer?.conversionList.length > 0 ? state.camPaignManagementReducer.conversionList : []);

    let conversionListdatasetforconversion = useSelector((state) => state.camPaignManagementReducer?.conversionListforconversion && state.camPaignManagementReducer?.conversionListforconversion.length > 0 ? state.camPaignManagementReducer.conversionListforconversion : []);



    var modifyTableCopyUrlHeaders = [
        { val: "name", name: "Campaigns Name" },
        { val: "landing_page_name", name: "Landing Page" },
        {
            val: "status",
            name: "Status",
            customVal: { 0: "Inactive", 1: "Active" },
        },

        {
            val: "updatedon_datetime",
            name: "Updated On",
            type: "datetime",
            format: "MM/DD/YYYY",
        },
    ];




    let tableDataCopyUrlCampaign = {
        tableId: "campaignurldataTable",
        tableTitle: "All Campaigns List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/user-list-or-count",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/user-list-or-count",
        },

        reqBody: {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "updatedon_datetime",
                type: "desc",
            },
            searchcondition: {
                // "user_name": user_name,
                "user_id": user_id
            },
            project: {},
            token: token,
            // count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        // deleteMultipleReqBody: {
        //     source: "users",
        //     secret: "AZ|lepL`",
        // },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        buttons: [
            {
                id: "cpoyurlbtn",
                type: "other",
                icon_type: "content_copy_icon",
                label: "Copy Url",
                name: "content_copy_icon",

            },
            {
                id: "qrcodebutton",
                type: "other",
                icon_type: "qr_code_scanner_icon",
                label: "QR Scanner",
                name: "qr_code_scanner_icon",
            }


        ],


       

        searchData: {
            heading: "Search Campaign",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchFormUrlCopyCampaign',
                formWrapperClass: "searchBarHead ",// Can be undefined default will be formCls
                formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Campaign Name",
                        name: "name",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/auto-complete-campaign",
                        reqBody: {
                            "source": "campaign_list",
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "type": "asc",
                                "field": "name"
                            },
                            "searchcondition": {
                                // //         //"user_name": user_name,
                                "user_id": user_id
                            }
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },

                    },

                    {
                        id: 3,
                        label: "Search by Status",
                        name: "status",
                        // className: 'Status',
                        type: 'select',
                        multiple: false,
                        values: [
                            { val: 1, name: "Active" },
                            { val: 0, name: "Inactive" }
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: 'list_search_item countDiv__column--col4 ',
                        payloadFormat: { key: "status" },
                    },

                    {
                        id: 8,
                        label: "Search by Updation Start Date",
                        name: "updatedon_Start_datetime",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        // format: 'mm/dd/yyyy', //////////////////////////// MM should be capital //////////////////
                        className: 'list_search_item countDiv__column--col4 ',
                        payloadFormat: { key: "updatedon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Updation End Date",
                        name: "updatedon_datetime",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        // fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'mm/dd/yyyy',
                        payloadFormat: { key: "updatedon_datetime", param: "$lte" },
                        errorMessage: {
                            custom: "End Date can not be less than Start Date"
                        },

                    },
                    // {
                    //   id: 10,
                    //   label: "Search by Date Range",
                    //   name: "createdon_datetime",
                    //   className: 'list_search_item countDiv__column--col4 ',
                    //   type: 'dateRangePicker',
                    //   sx: { m: 1, width: 300 },
                    //   // format: 'MM/DD/YYYY'
                    // },
                ]
            }
        },


    };


    var modifyTableConversionHeaders = [
        { val: "campaign_name", name: "Campaigns Name" },
        { val: "landing_page_name", name: "Landing Page" },
        { val: "lead_name", name: "Lead Name" },
        {
            val: "createdon_datetime",
            name: "Converted On",
            type: "datetime",
            format: "MM/DD/YYYY",
        },
    ];

    var modifyTableClickCountHeaders = [
        { val: "campaign_name", name: "Campaigns Name" },
        { val: "landing_page_name", name: "Landing Page" },
        {
            val: "createdon_datetime",
            name: "Clicked On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm A",
        },
    ];

    let tableDataConversionDetails = useRef({
        tableId: "conversionlistTable",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/conversionlist",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/conversionlist",
        },

        reqBody: {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "createdon_datetime",
                type: "desc",
            },
            searchcondition: {
                "landing_page_id": landingpageclick,
                "lead_id": { $exists: false },
                "campaign_added_by": user_id,
            },
            project: {},
            token: "",
            // count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["campaign_name", "createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle:
                    "<p>Are you sure that you want to delete this record(s)?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Yes", "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/delete-campaign",
                    body: {
                        source: "users",
                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Active", "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/status-update",
                    body: {
                        source: "users",
                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },
        ],
        buttons: [

            {
                id: "viewApi",
                label: "Click Details View",
                type: "api",
                category: "view",
                icon_type: "preview_icon",
                className: "preview",
                modalTitle: "Click Details View",
                modalClassName: "contractDetails",
                // extera_btn_lavel:"Download",
                // extra_btn: [{
                //   id: "download",
                //   level: "Download"
                // }],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/conversionlist",
                    body: {
                        source: "",
                        condition: { limit: 1, skip: 0 },
                        sort: { type: "asc", field: "_id" },
                        searchcondition: {

                        },
                    },
                },
                headers: [
                    { val: "campaign_name", label: "Campaign Name" },
                    { val: "landing_page_name", label: "Landing Page Name" },

                    //   { val: "ip", label: "Ip" },
                    //   { val: "hostname", label: "Host Name (Ip-Info)" },
                    //   { val: "org", label: "Organizer (Ip-Info)" },
                    { val: "city", label: "City" },
                    { val: "region", label: "State" },
                    { val: "postal", label: "ZIP" },
                    { val: "country", label: "Country" },
                    { val: "timezone", label: "Timezone" },
                    { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
                ],
            },


        ],
        deleteModal: {
            modalClassName: "modalblock",
        },



        searchData: {
            heading: "Search Click Details",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchFormClick',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 6,
                        label: "Search By Campaign Name",
                        name: "campaign_name",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/campaignnameautocomplete",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "updatedon_datetime",
                                "type": "desc"
                            },
                            "searchcondition": {
                                "landing_page_id": landingpageclick,
                                // "lead_id": { $exists: false },
                                "campaign_added_by": user_id,
                                // "createdon_datetime": {
                                //     $gte: mindate,
                                //     $lte: maxDate,
                                // },
                            },
                            "type": "campaign_name",
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "campaign_id" },
                    },
                    {
                        id: 11,
                        label: "Search by Start Date",
                        name: "start_date",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: "inputblock2line",
                        payloadFormat: { key: "createdon_datetime", param: "$gte" },
                    },
                    {
                        id: 12,
                        label: "Search by End Date",
                        name: "end_date",
                        className: "inputblock2line",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    },
                ]
            }
        },


    });
    let tableDataConversionDetailsforconversion = useRef({
        tableId: "tableDataConversionDetailsforconversion",
        // tableTitle: "Campaign's List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/conversionlist",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/conversionlist",
        },

        reqBody: {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "createdon_datetime",
                type: "desc",
            },
            searchcondition: {
                "landing_page_id": landingpageclick,
                "lead_id": { $exists: true },
                "campaign_added_by": user_id,
                // "createdon_datetime": {
                //     $gte: mindate,
                //     $lte: maxDate,
                // },

            },
            project: {},
            token: "",
            // count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["campaign_name", "createdon_datetime", "lead_name"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,

        // customBtnHead: [
        //     {
        //         id: "delete",
        //         label: "Multi Delete",
        //         type: "api",
        //         category: "modal",
        //         icon_type: "delete",
        //         className: "delete_data",
        //         modalClassName: "delete_modal",
        //         modalTitle:
        //             "<p>Are you sure that you want to delete this record(s)?</p>",
        //         modalHeader: "<p> Alert !!</p>",
        //         btn_label: ["Yes", "No"],
        //         api_data: {
        //             base_url: process.env.REACT_APP_API_URL,
        //             endpoint: "campaign-manage/delete-campaign",
        //             body: {
        //                 source: "users",
        //                 secret: "AZ|lepL`",
        //                 token:
        //                     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
        //             },
        //         },
        //     },
        //     {
        //         id: "status",
        //         label: "Status",
        //         type: "api",
        //         category: "status",
        //         icon_type: "toggle_off",
        //         className: "status_data",
        //         modalClassName: "delete_modal",
        //         modalTitle: "<p>Are you sure you want to change the status ?</p>",
        //         modalHeader: "<p> Alert !!</p>",
        //         btn_label: ["Active", "Inactive"],
        //         api_data: {
        //             base_url: process.env.REACT_APP_API_URL,
        //             endpoint: "campaign-manage/status-update",
        //             body: {
        //                 source: "users",
        //                 secret: "AZ|lepL`",
        //                 token:
        //                     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
        //             },
        //         },
        //     },
        // ],
        // buttons: [
        //     {
        //         id: "edittable9",
        //         type: "other",
        //         icon_type: "edit_icon",
        //         label: "Edit",
        //         name: "edit",
        //     },


        //     {
        //         id: "delete",
        //         label: "Delete",
        //         type: "api",
        //         category: "modal",
        //         icon_type: "delete",
        //         className: "delete_data",
        //         modalClassName: "delete_modal",
        //         modalTitle:
        //             "<p>Are you sure that you want to delete this record(s)?</p>",
        //         modalHeader: "<p> Alert !!</p>",
        //         btn_label: ["Yes", "No"],
        //         condField: "default",
        //         condVal: "true",
        //         api_data: {
        //             base_url: process.env.REACT_APP_API_URL,
        //             endpoint: "campaign-manage/delete-campaign",
        //             body: {
        //                 source: "users",
        //                 secret: "AZ|lepL`",
        //                 token:
        //                     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
        //             },
        //         },
        //     },
        //     {
        //         id: "status",
        //         label: "Status",
        //         type: "api",
        //         category: "status",
        //         icon_type: "toggle_off",
        //         className: "status_data",
        //         modalClassName: "delete_modal",
        //         modalTitle: "<p>Are you sure you that want to change the status?</p>",
        //         modalHeader: "<p> Alert !!</p>",
        //         btn_label: ["Active", "Inactive"],
        //         api_data: {
        //             base_url: process.env.REACT_APP_API_URL,
        //             endpoint: "campaign-manage/status-update",
        //             body: {
        //                 source: "users",
        //                 secret: "AZ|lepL`",
        //                 token:
        //                     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
        //             },
        //         },
        //     },
        //     {
        //         id: "cpoyurlbtn",
        //         type: "other",
        //         icon_type: "content_copy_icon",
        //         label: "Copy Url",
        //         name: "content_copy_icon",

        //     },
        // ],
        buttons: [

            {
                id: "viewApi",
                label: "Conversion Details View",
                type: "api",
                category: "view",
                icon_type: "preview_icon",
                className: "preview",
                modalTitle: "Conversion Details View",
                modalClassName: "contractDetails",
                // extera_btn_lavel:"Download",
                // extra_btn: [{
                //   id: "download",
                //   level: "Download"
                // }],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "campaign-manage/conversionlist",
                    body: {
                        source: "",
                        condition: { limit: 1, skip: 0 },
                        sort: { type: "asc", field: "_id" },
                        searchcondition: {

                        },
                    },
                },
                headers: [
                    { val: "campaign_name", label: "Campaign Name" },
                    { val: "landing_page_name", label: "Landing Page Name" },
                    { val: "lead_name", label: "Lead Name" },
                    // { val: "ip", label: "Ip" },
                    // { val: "hostname", label: "Host Name (Ip-Info)" },
                    // { val: "org", label: "Organizer (Ip-Info)" },
                    { val: "city", label: "City" },
                    { val: "region", label: "State" },
                    { val: "postal", label: "ZIP" },
                    { val: "country", label: "Country" },
                    { val: "timezone", label: "Timezone" },
                    { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },

                ],
            },


        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

        searchData: {
            heading: "Search Conversion Details",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchFormCampaign',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search by Campaign Name",
                        name: "campaign_name",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/campaignnameautocomplete",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "updatedon_datetime",
                                "type": "desc"
                            },
                            "searchcondition": {
                                "campaign_added_by": user_id,
                                // "createdon_datetime": {
                                //     $gte: mindate,
                                //     $lte: maxDate,
                                // },
                            },
                            "project": {},
                            "token": ""
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "campaign_id" },

                    },
                    {
                        id: 7,
                        label: "Search By Lead Name",
                        name: "landing_name",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url: process.env.REACT_APP_API_URL + "campaign-manage/leadnameautocomplete",
                        reqBody: {
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "field": "updatedon_datetime",
                                "type": "desc"
                            },
                            "searchcondition": {
                                "landing_page_id": landingpageclick,
                                // "lead_id": { $exists: true },
                                "campaign_added_by": user_id,
                                // "createdon_datetime": {
                                //     $gte: mindate,
                                //     $lte: maxDate,
                                // },
                            },
                            "project": {},
                            "token": ""
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "lead_id" },
                    },
                    {
                        id: 11,
                        label: "Search by Start Date",
                        name: "start_date",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: "inputblock2line",
                        payloadFormat: { key: "createdon_datetime", param: "$gte" },
                    },
                    {
                        id: 12,
                        label: "Search by End Date",
                        name: "end_date",
                        className: "inputblock2line",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    },




                ]
            }
        },


    });

    const showconversioncount = (val) => {

        let clickcount = 0
        let conversioncount = 0
        if (userconversioncount && val) {

            if (userconversioncount[0].click && userconversioncount[0].click.length > 0) {

                for (let i in userconversioncount[0].click) {

                    if (userconversioncount[0].click[i]._id == val._id) {

                        clickcount = userconversioncount[0].click[i].count
                    }
                }

            }
            if (userconversioncount[1].conversion && userconversioncount[1].conversion.length > 0) {
                for (let i in userconversioncount[1].conversion) {
                    if (userconversioncount[1].conversion[i]._id == val._id) {

                        conversioncount = userconversioncount[1].conversion[i].count
                    }
                }

            }
        }
        return `${clickcount}/${conversioncount}`
    }

    const showclickcount = (val) => {

        let clickcount = 0
        // let conversioncount = 0
        if (userconversioncountonsecch && val) {

            if (userconversioncountonsecch[0].click && userconversioncountonsecch[0].click.length > 0) {

                for (let i in userconversioncountonsecch[0].click) {

                    if (userconversioncountonsecch[0].click[i]._id == val._id) {

                        clickcount = userconversioncountonsecch[0].click[i].count
                    }
                }

            }

        }
        return `${clickcount}`
    }

    const showconversioncountbtn = (val) => {

        // let clickcount = 0
        let conversioncount = 0
        if (userconversioncountonsecch && val) {


            if (userconversioncountonsecch[1].conversion && userconversioncountonsecch[1].conversion.length > 0) {
                for (let i in userconversioncountonsecch[1].conversion) {
                    if (userconversioncountonsecch[1].conversion[i]._id == val._id) {

                        conversioncount = userconversioncountonsecch[1].conversion[i].count
                    }
                }

            }
        }
        return `${conversioncount}`
    }



    const addapicallforconversion = (val) => {
        setSelected(val)

        // console.log("val===>>>>", val);
        if (val == 1) {
            tableDataConversionDetails.current.reqBody.searchcondition["lead_id"] = { $exists: false }

            // dispatch(setReloadTableFlag({ tableId: "conversionlistTable" }))

            dispatch(conversionListData(tableDataConversionDetails.current.reqBody))

        } else if (val == 2) {
            tableDataConversionDetailsforconversion.current.reqBody.searchcondition["lead_id"] = { $exists: true }

            // dispatch(setReloadTableFlag({ tableId: "tableDataConversionDetailsforconversion" }))

            dispatch(conversionListDatacon(tableDataConversionDetailsforconversion.current.reqBody))
        }
    }


    const openconversionlist = async (val) => {
        setlandingpageclick(val._id)
        setcountDetailsAll(val)
        tableDataConversionDetails.current.reqBody.searchcondition['landing_page_id'] = val._id
        tableDataConversionDetails.current.searchData.formData.fields[0].reqBody.searchcondition['landing_page_id'] = val._id



        tableDataConversionDetailsforconversion.current.reqBody.searchcondition['landing_page_id'] = val._id
        tableDataConversionDetailsforconversion.current.searchData.formData.fields[0].reqBody.searchcondition['landing_page_id'] = val._id

        tableDataConversionDetailsforconversion.current.searchData.formData.fields[1].reqBody.searchcondition['landing_page_id'] = val._id
        await dispatch(conversionListData(tableDataConversionDetails.current.reqBody))
        setloadershow(true)
        let bodyc = { ...tableDataConversionDetails.current.reqBody.searchcondition }
        if (bodyc.lead_id) {
            delete bodyc.lead_id
        }
        await dispatch(getconversioncountontime(bodyc))
        await dispatch(getpiechartdata(bodyc))
        setloadershow(false)
        setOpenCountMOdal(true);

    }

    useEffect(() => {
        // console.log("landingpageclick123", landingpageclick);
    }, [landingpageclick])

    // const showpiechat = async () => {
    //     let pieData = {
    //         data: {
    //           labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    //           datasets: [{
    //             label: '# of Votes',
    //             data: [12, 19, 3, 5, 2, 3],
    //             borderWidth: 1,
    //             backgroundColor: ["red", "blue", "yellow", "green", "purple", "orange"],

    //           }],



    //         },
    //         options: {

    //           legend: {
    //             display: true,
    //             position: 'bottom'
    //           },
    //           title: {
    //             display: true,
    //             text: "pie chart",
    //             //html:"<h2>Pie chart</h2>"

    //           }
    //         }


    //       }
    //       return <app-pie-chart data={JSON.stringify(pieData)}></app-pie-chart>

    // }
    // const showpiechatforclick = (val) => {

    let pieforClickData = {
        data: {
            labels: piechartdtataforclick.label,
            datasets: [{
                label: '# of Votes2',
                data: piechartdtataforclick.data,
                borderWidth: 1,
                backgroundColor: ["#f67d24", "#035587", "#009fe2", "#003266", "#c50723", "blue", "yellow", "green", "purple", "red", "orange", "brown", "turquoise", "pink", "azure", "ivory", "teal", "silver", "purple", "navy", "pea", "gray", "maroon", "charcoal", "aquamarine", "coral", "fuchsia", "wheat", "lime", "crimson", "khaki", "magenta", "golden", "plum", "olive", "cyan"],

            }],



        },
        options: {

            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: true,
                text: "Pie Chart For Click Report",
                //html:"<h2>Pie chart</h2>"

            }
        },
        id: 'pai2'

    }

    // return <app-pie-chart key={1} data={JSON.stringify(pieData)}></app-pie-chart>;

    // }
    // const showpiechatforconversion = (val) => {

    let pieforConversionData = {
        data: {
            labels: piechartdtataforcon.label,
            datasets: [{
                label: '# of Votes',
                data: piechartdtataforcon.data,
                borderWidth: 1,
                backgroundColor: ["#f67d24", "#0c256c", "#009fe2", "#003266", "#c50723", "blue", "yellow", "green", "purple", "red", "orange", "brown", "turquoise", "pink", "azure", "ivory", "teal", "silver", "purple", "navy", "pea", "gray", "maroon", "charcoal", "aquamarine", "coral", "fuchsia", "wheat", "lime", "crimson", "khaki", "magenta", "golden", "plum", "olive", "cyan"],

            }],



        },
        options: {

            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: true,
                text: "Pie Chart For Conversion Report",
                //html:"<h2>Pie chart</h2>"

            }
        },
        id: 'pai1'


    }
    // return <app-pie-chart key={2} data={JSON.stringify(pieData)}></app-pie-chart>;
    // }

    // let pieData = {
    //     data: {
    //         labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    //         datasets: [{
    //             label: '# of Votes',
    //             data: [12, 19, 3, 5, 2, 3],
    //             borderWidth: 1,
    //             backgroundColor: ["red", "blue", "yellow", "green", "purple", "orange"],

    //         }],



    //     },
    //     options: {

    //         legend: {
    //             display: true,
    //             position: 'bottom'
    //         },
    //         title: {
    //             display: true,
    //             text: "pie chart",
    //             //html:"<h2>Pie chart</h2>"

    //         }
    //     }


    // }

    /***********************Download QR code******************* */
    const downloadQR = () => {
        const canvas = document.getElementById(qrId);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = qrId + '.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    //  /***********************Download QR code******************* */
    const downloadQRCode = () => {
        const canvasa = document.getElementById(qrCodeId);
        const pngUrls = canvasa
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLinks = document.createElement("a");
        downloadLinks.href = pngUrls;
        downloadLinks.download = qrCodeId + '.png';
        document.body.appendChild(downloadLinks);
        downloadLinks.click();
        document.body.removeChild(downloadLinks);
    };
    return (
        <>



            <div className="customLinks_mainwrpr">

                {/* {JSON.stringify(userurlhasornot)} */}
                {userurlhasornot && Object.keys(userurlhasornot) && landingLiksArray && landingLiksArray.length > 0 && <div className={`steps_wrpr ${userurlhasornot && !userurlhasornot.uniqe_url_has || !userurlhasornot.campanin_has ? 'as_btn_wrapper1' : 'as_btn_wrapper2'}`}>

                    {/* <div
                        // title='Add an Unique URL to manage you campaign. Hint: If your name Stephen Lang, unique URL should be like stephenlang/stephen001/StephenLang etc' 
                        onClick={() => addurloutside()} className={userurlhasornot && !userurlhasornot.uniqe_url_has ? 'nousernameadd' : 'alreadyhasuniqueurl'}>
                        <p>Step One: Create Your Unique URL</p>

                    </div> */}
                    <div title='To create a new campaign, please click on this button or the create campaign icon and  select the landing/marketing link that you would like to use. Next, fill out the form with the details for your campaign, including the campaign name and description.' placement="top-start" className={userurlhasornot && (!userurlhasornot.campanin_has && userurlhasornot.uniqe_url_has) ? 'nousernameadd' : ''} >
                        <p onClick={() => addcampainoutsideforcampign()}>Step One: Create Your Campaign <AddTaskIcon /></p>
                    </div>
                    <div title='To grab the campaign URL, click on step three. Once you have chosen your campaign, click on the copy icon from the all campaigns list.' >
                        <p onClick={() => copyUrlcampign()}>Step Two: Copy URL <ContentCopyIcon /></p>
                    </div>
                </div>}

                <Modal
                    open={openText}
                    onClose={handleCloseText}>
                    <Box sx={style} className="BoxMui_modal">

                        <div className='landingmodalCon'>
                            <h1 className='h1textcon'>{modalDatat && modalDatat.name}</h1>
                            <p>{modalDatat && modalDatat.description}</p>
                        </div>


                        <Button onClick={handleCloseText} className="closeModalBtn">
                            <Tooltip title="Close" placement="right-start">

                                <CloseIcon />
                            </Tooltip>
                        </Button>
                    </Box>
                </Modal>

                <Modal className='cmpaignModal_Wrpr'
                    open={opencapinchose}
                    onClose={() => setopencapinchose(false)}>
                    <Box sx={style} className="modalBaseBox">
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setopencapinchose(false)} className="modal_icon_button">
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className='chosecampainnamewrapper'>
                            <h1 className='modal_title modal_titlenew'>Choose A Landing Page</h1>
                            <div className='imgBlock_wrpr'>
                                {landingLiksArray && landingLiksArray.length > 0 &&
                                    landingLiksArray.map((response, index) => {
                                        return (
                                            <div key={index} className='showimg' onClick={() => gotocampain(response._id, response.domain_url, response.name)}>
                                                <div>
                                                    <img src={response.thumbnail_url}></img>
                                                </div>
                                                <div>
                                                    <label><span>{response.name}</span> </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </Box>
                </Modal>



                {divLoader || loadershow ? <Box sx={{ width: "100%", marginTop: '10px', marginBottom: "10px" }}>
                    <LinearProgress />
                </Box> : ''}
                {/* <p>Data: {JSON.stringify(landingLiksArray) }</p> */}

                <div className='marketing_links_wrpr' ref={myRef}>
                    <h2 className='links_Header'>Landing Page/Marketing Links</h2>

                    {landingLiksArray && landingLiksArray.length > 0 ?
                        landingLiksArray.map((response, index) => {
                            console.log("response_landingLiksArray",response);
                            return (
                                <div key={index} className='customlinksData_block'>
                                    <div className='customlinksData_con'>
                                        <div className='customlinksData_IMG'>
                                            <LazyImage src={response.thumbnail_url}></LazyImage>
                                        </div>
                                        <div className='customlinksData_text'>
                                            <div className='customlinksData_text_con'>

                                                <label><span>{response.name}</span> </label>

                                                <div className='text_CustmbuttonWrp'>
                                                    <p>
                                                        <span> {response.description} </span>
                                                    </p>
                                                </div>

                                            </div>
                                            <div className='customlinksData_text_wrapper'>
                                                {(response?.user_name || user_name) ?

                                                    <div className="customlinksData_text_link">
                                                        <span onClick={() => copyToClipboard(response)} className='cursorpointer'>
                                                            <Tooltip title="Copy URL ">
                                                                <LinkIcon />
                                                            </Tooltip>
                                                        </span>
                                                        <p className={(!response?.user_name || campaginaccessData?.campaign_access !== 2) ? 'linktext disablecopy' : 'linktext'}>  {response.domain_url}{campaginaccessData && campaginaccessData.campaign_access !== 2 ? '*****' : response.user_name ? response.user_name : user_name}</p>
                                                    </div> :
                                                    <div className="customlinksData_text_link"><span onClick={() => copyToClipboard(response)}><Tooltip title="Copy URL"><LinkIcon /></Tooltip></span> <p className={userurlhasornot && (!userurlhasornot.campanin_has) ? 'linktext disablecopy' : 'linktext'}>  {response.domain_url}</p></div>
                                                }
                                                <div className='customlinksData_select'>
                                        
                                                    {response?.campDeatis && !flagfoshowdoopdown &&
                                                        <Select defaultValue={response.user_name}>
                                                            {response.campDeatis.map((campainDataf) => {
                                                                return campainDataf && campainDataf.status == 1 && (
                                                                    <MenuItem onClick={() => setUsernameOption(campainDataf.user_name, response._id)} value={campainDataf.user_name}>{campainDataf.name}</MenuItem>
                                                                )
                                                            })
                                                            }
                                                        </Select>
                                                    }
                                                </div>

                                                <div className={`customlinksData_text_icon ${userurlhasornot && (!userurlhasornot.uniqe_url_has || !userurlhasornot.campanin_has) ? 'asiconwrapper1' : 'asiconwrapper2'}`}>
                                                    <span onClick={() => copyToClipboard(response)} ><Tooltip title="Copy URL"><ContentCopyIcon /></Tooltip></span>
                                                    <span onClick={() => qrCodeFunction(response)} ><Tooltip title="QR Scanner"><QrCodeScanner /></Tooltip></span>
                                                    <span onClick={() => campainMnage(response._id, response.domain_url, response.name)} className={userurlhasornot && (!userurlhasornot.uniqe_url_has || !userurlhasornot.campanin_has) ? 'nothasuniqurl' : 'hasuniqurl'}>
                                                        <Badge badgeContent={response.count}
                                                            color="primary">
                                                            <Tooltip title="Create Campaign"><AddTaskIcon /></Tooltip>
                                                        </Badge>
                                                    </span>
                                                    <span onClick={() => openconversionlist(response)}>
                                                        <Badge badgeContent={showconversioncount(response)} color="primary">
                                                            <Tooltip title="Click Count / Conversion Count"><AssessmentOutlinedIcon /></Tooltip>
                                                        </Badge>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })

                        :
                        <div className='errortextcss'>
                            <p>No Data Found !</p>
                        </div>

                    }
                    {divLoader || loadershow ? <Box sx={{ width: "100%", marginTop: '10px', marginBottom: "10px" }}>
                        <LinearProgress />
                    </Box> : ''}
                </div>

                <UserNameCreate open={openUserModal} close={handleCloseUserModal} user_id={user_id}/>

                <CampainManageModal open={openCampainModal} close={handleCloseCampaignModal} user_id={user_id} user_name={user_data?.user_name} landingpage_id={valueOfCampainModal}/>

                {/* *********Copy Url Modal***** */}
                {/* <Modal className='copy_url_modal_Wrpr'
                    open={copyUrlModal}
                    onClose={() => setCopyUrlModal(false)}>
                    <Box sx={style} className="modalBaseBox">
                        {loaderUrl ? <Box sx={{ width: "100%", margin: "10px" }}><LinearProgress /></Box> : ''}
                        <div className='hmmodal_user_Box_wrapper' >
                            <h3>All Campaigns List</h3>
                        </div>
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setCopyUrlModal(false)}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className='icon_details_wrp'>

                            <h1>Instructions</h1>
                            <div className='pharsavings_table_cls'><div> <SearchIcon />Search:</div><p> Search any campaign by clicking on the search icon.</p></div>
                            <div className='pharsavings_table_cls'><div><ReplayIcon />Reload: </div><p> Reload the campaign’s listing by clicking on the icon.</p></div>
                            <div className='pharsavings_table_cls'><div><ContentCopyIcon />Copy Icon:  </div><p>Copy a campaign URL by clicking on the icon.</p></div>
                            <div className='pharsavings_table_cls'><div><QrCodeScanner />QR Scanner:</div><p>Get QR Code of the campaign by clicking on the icon.</p></div>
                        </div>
                        <div className="rolelist_mainwrp copy_url_campaign">
                            <div className="rolelist_mainwrp_Table">
                                {copyUrlcampaingDataforlist && copyUrlcampaingDataforlist.length > 0 ? (<ListingTable tableData={tableDataCopyUrlCampaign} dataset={copyUrlcampaingDataforlist} modifyHeaders={modifyTableCopyUrlHeaders}/>) : (<TableSkeleton count={5} />)}
                            </div>
                        </div>


                    </Box>
                </Modal> */}

                <Modal
                    className="Count_ReportMOdal modalBaseStyle "
                    open={openCountMOdal}
                    onClose={CloseCountModal}
                    closeAfterTransition
                >
                    <Box className='modalBaseBox largeModal campain_modal_width'>
                        <IconButton className="modal_icon_button" onClick={CloseCountModal}>
                            <CloseIcon className="modal_close_icon" />
                        </IconButton>
                        <div className='countModal_headr modal_title'>
                            {selected && selected == 1 && <h3>Showing clicks relative to landing pages and campaigns</h3>}
                            {selected && selected === 2 && <h3>Showing First Page conversions relative to landing pages and campaigns </h3>}
                        </div>


                        {/* <p>Demotest 1 {JSON.stringify(tableDataConversionDetails.current.reqBody.searchcondition)}</p>
      <p>Demotest 1 {JSON.stringify(tableDataConversionDetailsforconversion.current.reqBody.searchcondition)}</p> */}
                        <div className='COuntSData_mainwrpr'>
                            {loaderforclick && <div>
                                <Box sx={{ width: "100%", marginTop: '10px', marginBottom: "10px" }}>
                                    <LinearProgress />
                                </Box>
                            </div>}
                            <div className="daterangepicker_one ">
                                {((mindate) !== null && (maxDate) !== null) ? <span className="dash_brd_date">
                                    {" "}
                                    {moment(mindate).format("MMMM D ")} - {" "}
                                    {moment(maxDate).format("MMMM D ")}

                                </span> : <span className="dash_brd_date">Till Date</span>}





                                {/* -{" "} */}
                                {/* <span className="dash_brd_date">
                                    {" "}
                                   { (mindate && Object.keys(mindate).length>0) ? (moment(Number(mindate)).format("MMMM Do ")) : ""}
                                    {(maxDate && Object.keys(maxDate).length>0) ? (moment(Number(maxDate)).format("MMMM Do , YYYY ")) : ""}
                                </span> */}


                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateRangePicker
                                        startText="Start Date"
                                        endText="End Date"
                                        value={valuee}
                                        maxDate={maxDateForDateRangePicker}
                                        onChange={(newValue) => {

                                            setValuee(newValue);
                                        }}

                                        onOpen={() => {

                                            //////////////////////// Removing Missing License Key Message /////////////////////
                                            setTimeout(() => {
                                                if (
                                                    document.querySelector("daterangepicker_one") !=
                                                    undefined &&
                                                    document.querySelector("daterangepicker_one").style !=
                                                    undefined
                                                )
                                                    document.querySelector("daterangepicker_one").style.display = "none";

                                                let e = document.getElementsByTagName("div");
                                                for (let i = 0; i < e.length; i++) {
                                                    let textval = e[i].innerText;
                                                    if (
                                                        textval.includes("Missing license key") &&
                                                        textval.length < 30
                                                    ) {
                                                        e[i].style.display = "none";
                                                    }
                                                }
                                                if (
                                                    document.querySelector("daterangepicker_one") !=
                                                    undefined &&
                                                    document.querySelector("daterangepicker_one").style !=
                                                    undefined
                                                )
                                                    document.querySelector("daterangepicker_one").style.display = "block";
                                            }, 200);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField {...startProps} />
                                                <Box sx={{ mx: 2 }}> to </Box>
                                                <TextField {...endProps} />
                                            </>
                                        )}
                                    />
                                </LocalizationProvider>

                                <div className="daterangepicker_view_btn">
                                    <Button onClick={() => handelsearch()}>View</Button>
                                </div>
                            </div>


                            <div className='countDetail_Title'>
                                <p>Total Click Count: {showclickcount(countDetailsAll)}</p>
                                <p>Total Conversion Count: {showconversioncountbtn(countDetailsAll)}</p>

                            </div>

                            <div className='header4piechrt'><h3>Click and Conversion Report in Pie Chart View</h3></div>
                            {showpiechart && <div className='showchartdiv'>
                                {piechartdtataforclick?.data?.length > 0 ? <div className='clickchart' >
                                    {/* {showpiechatforclick()} */}
                                    {/* {piechartdtataforclick?.data?.length>0 ? <app-pie-chart key={1} data={JSON.stringify(pieforClickData)}></app-pie-chart> : <div className="noDataFound"><span>No data found! </span></div>} */}
                                    <app-pie-chart key={1} data={JSON.stringify(pieforClickData)}></app-pie-chart>
                                    {/* <p>{JSON.stringify(piechartdtataforclick)}</p> */}


                                    <p className="pieChrt_Hint"><strong>Click Pie Chart:</strong> This chart displays the ratio of number of clicks against each campaign.</p>
                                </div> : <div className="noDataFound"><span>No data found! </span></div>}
                                {piechartdtataforcon?.data?.length > 0 ? <div className='conversionchart'>
                                    {/* {showpiechatforconversion()} */}

                                    {/* {piechartdtataforcon?.data?.length>0 ? <app-pie-chart key={2} data={JSON.stringify(pieforConversionData)}></app-pie-chart> : <div className="noDataFound"><span>No data found! </span></div>} */}
                                    <app-pie-chart key={2} data={JSON.stringify(pieforConversionData)}></app-pie-chart>
                                    {/* <p>{JSON.stringify(piechartdtataforcon)}</p> */}

                                    <p className="pieChrt_Hint"><strong>Conversion Pie Chart:</strong> This chart displays the ratio of number of conversions against each campaign.</p>
                                </div> : <div className="noDataFound"><span>No data found! </span></div>}
                            </div>}
                            <div className='click_buttonwp'>
                                <Button className={selected === 1 ? "active" : ""} variant="contained" onClick={() => addapicallforconversion(1)}>Click Details
                                </Button>
                                <Button className={selected === 2 ? "active" : ""} variant="contained" onClick={() => addapicallforconversion(2)}>Conversion Details
                                </Button>
                            </div>

                            {selected && selected === 1 && <div className="rolelist_mainwrp">
                                <div className="rolelist_mainwrp_Table">
                                    {divLoader && (
                                        <TableSkeleton count={5} />
                                    )}
                                    {!divLoader && <>{conversionListdataset && conversionListdataset.length > 0 && !divLoader ? <ListingTable
                                        tableData={tableDataConversionDetails.current}
                                        dataset={conversionListdataset}
                                        modifyHeaders={modifyTableClickCountHeaders}
                                    /> :
                                        <p className='norecord'>NO Record Found</p>
                                    }</>}

                                </div>
                            </div>}

                            {selected && selected === 2 && <div className="rolelist_mainwrp">
                                <div className="rolelist_mainwrp_Table">
                                    {divLoader && (
                                        <TableSkeleton count={5} />
                                    )}
                                    {!divLoader && <>
                                        {conversionListdatasetforconversion && conversionListdatasetforconversion.length > 0 ? <ListingTable
                                            tableData={tableDataConversionDetailsforconversion.current}
                                            dataset={conversionListdatasetforconversion}
                                            modifyHeaders={modifyTableConversionHeaders}
                                        /> : <p className='norecord'>NO Record Found</p>}
                                    </>}

                                </div>
                            </div>}
                        </div>

                    </Box>
                </Modal>

                {/* *****************CopyURL SnackMSG Modal***************** */}
                <Modal className='copy_urlSnack_modal_Wrpr'
                    open={copyUrlSnackModal}
                    onClose={() => setCopyUrlSnackModal(false)}>
                    <Box sx={style} className="modalBaseBox">
                        {loaderUrl ? <Box sx={{ width: "100%", margin: "10px" }}><LinearProgress /></Box> : ''}
                        <div className='hmmodal_user_Box_wrapper' >
                            <img src="https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp" alt='' />
                        </div>
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setCopyUrlSnackModal(false)}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className='icon_details_wrp'>
                            <h4>Pharmacy Marketing Tools will be available at our Launch Wednesday May 17th. Please log back in at this time to start using the pharmacy marketing system.</h4>

                        </div>



                    </Box>
                </Modal>


                {/* <CampaignAddEdit open={openCampainModal} userName={uniqueUserName}/> */}

                {/* *******************************QR Code Modal********************** */}

                <Modal className='copy_urlSnack_modal_Wrpr qr_modal_class'
                    open={openQRCodeModal}
                    onClose={() => setOpenQRCodeModal(false)}>
                    <Box sx={style} className="modalBaseBox">
                        <div className='hmmodal_user_Box_wrapper' >
                            <img src="https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp" alt='' />
                        </div>
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setOpenQRCodeModal(false)}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className='qr_code_class'>
                            <QRCode
                                id={qrId}
                                value={qrValue}
                                size={290}
                                level={"H"}
                                includeMargin={true}
                            />
                            <div className='qr_code_download_button'>
                                <Button variant="contained" onClick={downloadQR}> Download QR </Button>
                            </div>

                        </div>



                    </Box>
                </Modal>

                {/* ****************QR Code Modal For All Campaign list************ */}
                <Modal className='copy_urlSnack_modal_Wrpr qr_modal_class'
                    open={openQRModal}
                    onClose={() => setOpenQRModal(false)}>
                    <Box sx={style} className="modalBaseBox">
                        <div className='hmmodal_user_Box_wrapper' >
                            <img src="https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp" alt='' />
                        </div>
                        <Tooltip title='Close' >
                            <IconButton onClick={() => setOpenQRModal(false)}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className='qr_code_class'>
                            <QRCode
                                id={qrCodeId}
                                value={qrCodeValue}
                                size={290}
                                level={"H"}
                                includeMargin={true}
                            />
                            <div className='qr_code_download_button'>
                                <Button variant="contained" onClick={downloadQRCode}> Download QR </Button>
                            </div>

                        </div>



                    </Box>
                </Modal>

            </div>
        </>
    )
}

export default CustomLinks