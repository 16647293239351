import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { unstable_HistoryRouter, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeExternalFormData, resetForm, resetFormData, setExternalFormData, setFormFieldsData, setOtherData, setTempFormUpdateData } from '../../../../form/formReducer';
import { Alert, Button, Grid, IconButton, LinearProgress, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { useCookies } from "react-cookie";
// import "../../BackEndPages.css";
import StateArray from '../../../../assets/json/state';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { getusersList } from '../../userManagement/userList/userListReducer';
import { clearfetchPackageInfoSuccess, clearprogramdataStatuserr, configerComitionEdit, fetchAssociatedPackages, fetchLandingPage, setAffiliateInitData } from '../../AffiliateManagement/AffiliateReducer';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import zIndex from '@mui/material/styles/zIndex';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';
import ConfigurePackagePartnerModal from './ConfigurePackagePartnerModal';
import { addpartnerdata } from '../PartnersReducer';

import '../../../../assets/Form.css'

const AddPartners = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 
  // const history = window.history.back();

  // console.warn("<<<<<<<<<< history >>>>>>", history.back())

  // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

  // ---- States here -----
  const [loading, setLoading] = useState(true);  //*****FOR LODING****//
  const [modal, setModal] = useState(false);  //*****modal Open toogle ****//
  const [userInfo, setUserInfo] = useState(null); // Set User Information from Cookie
  const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
  const [locationArry, setLocationArry] = useState([]);
  const [comissionConfig, setComissionConfig] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMSG, setSnackbarMSG] = useState(null)
  const [packageData, setPackageData] = useState([])
  const [commissionDataMissing, setCommissionDataMissing] = useState(false)
  const [programSnackbarstate, setProgramSnackbarState] = useState(false)



  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from addEditFrom component>>>>>", cookieData)

  // ----- On editDataRef trigger ----
  const goBackBtnClick = useSelector(state => (state.formSlice?.customButtonClicked?.affiliateAddForm && state.formSlice?.customButtonClicked?.affiliateAddForm?.goBack001) ? state.formSlice?.customButtonClicked?.affiliateAddForm?.goBack001 : false);

  // ----------- affiliateSelected -------- //
  const programeSelected = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.associated_program ? state.formSlice?.formData?.affiliateAddForm?.associated_program : null);
  // ------------------------------------- //

  // ----------- packages -------- //
  const packages = useSelector(state => state.AffiliateReducer?.packageInfo.length > 0 ? state.AffiliateReducer?.packageInfo : []);
  // ------------------------------------- //

  // ---------- loader ------- //
  const loader = useSelector(state => state.AffiliateReducer?.loading ? state.AffiliateReducer?.loading : false);
  const loadingg = useSelector(state => state.PartnersReducer?.loading ? state.PartnersReducer?.loading : false);
  // -------------------- //

  // ---------- partnerAddSuccess ------- //
  const partnerAddSuccess = useSelector(state => state.PartnersReducer?.partnerAddSuccess ? state.PartnersReducer?.partnerAddSuccess : false);
  // ------------------------------------- //

  // // -------- affiliateAddError ---------//
  // const affiliateAddError = useSelector(state => state.AffiliateReducer?.affiliateAddError ? state.AffiliateReducer?.affiliateAddError : false);

  // ---------------------------- --

  //  ---- Commission modal btn -------- // 
  const comissionModalBtn = useSelector(state => state.formSlice?.customButtonClicked?.affiliateAddForm ? state.formSlice?.customButtonClicked?.affiliateAddForm : false);
  //  ----------------------------------- //

  // --------- fetchPackageInfoSuccess --------- //
  const fetchPackageInfoSuccess = useSelector(state => state.AffiliateReducer?.fetchPackageInfoSuccess ? state.AffiliateReducer?.fetchPackageInfoSuccess : false);


  // --------- landingPageDetails --------- //
  const landingPageDetails = useSelector(state => state.AffiliateReducer?.landingPageDetails ? state.AffiliateReducer?.landingPageDetails : []);
  // -------------------------------------- //


  // --------- firstname --------- //
  const firstName = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.firstname ? state.formSlice.formData.affiliateAddForm.firstname : null);

  // --------- lastname --------- //
  const lastName = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.lastname ? state.formSlice.formData.affiliateAddForm.lastname : null);

  // --------- email --------- //
  const email = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.email ? state.formSlice.formData.affiliateAddForm.email : null);


  //  --------- password ------------ //

  const pass = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.password ? state.formSlice.formData.affiliateAddForm.password : null);


  const confirmPass = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.confirmPassword ? state.formSlice.formData.affiliateAddForm.confirmPassword : null);

  
  

  // --------- user_name --------- //
  const userName = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.user_name ? state.formSlice.formData.affiliateAddForm.user_name : null);

  // // --------- programe info --------- //
  const programInfo = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.associated_program ? state.formSlice.formData.affiliateAddForm.associated_program : null);

  //-----------  landing_page_access -------- //
  const landing_page_access = useSelector(state => state.formSlice?.formData?.affiliateAddForm?.landing_page_access ? state.formSlice.formData.affiliateAddForm.landing_page_access : []);


  // --------- formSubmission --------//
  const fromSubmission = useSelector(state => state.formSlice?.formSubmissionState?.affiliateAddForm ? state.formSlice.formSubmissionState.affiliateAddForm : 5);


  // -------------- preview status -------- // 
  const preview = useSelector(state => state.formSlice?.listnerEditFormData?.affiliateAddForm && state.formSlice?.listnerEditFormData?.affiliateAddForm?.preview ? state.formSlice.listnerEditFormData.affiliateAddForm.preview : []);



  // --------------programdataStatuserr------------------------
  const programdataStatuserr = useSelector(state => state.AffiliateReducer?.programdataStatuserr ? state.AffiliateReducer?.programdataStatuserr : false)


  // --------- errorMsg --------//
  const errorMsg = useSelector(state => state.PartnersReducer?.errorMsg ? state.PartnersReducer.errorMsg : null);










  useEffect(() => {
    if (programdataStatuserr === true) {
      setProgramSnackbarState(true)
    }
  }, [programdataStatuserr])

  useEffect(() => {
    if (programSnackbarstate === false) {
      dispatch(clearprogramdataStatuserr())
    }
  }, [programSnackbarstate])






  // ----------------- settings landingPageDetails ------------- //
  let landing = []

  if (landingPageDetails && landingPageDetails.length > 0) {
    landingPageDetails.map((x) => landing.push(x.val))
  }


  useEffect(() => {
    if (landingPageDetails.length > 0) {
      // const assignLandingPageFeild = formData.current.fields.filter((x) => x.name === "landing_page_access")

      // assignLandingPageFeild[0].values = landingPageDetails

      // console.warn("landingPageDetails", landingPageDetails)


      // formData.current.fields.splice(5, 0, assignLandingPageFeild)

    }
  }, [JSON.stringify(landingPageDetails)])

  // -------------------------------------------------- //







  // ---  After sucessfully form submissiion ----- //
  useEffect(() => {
    if (fromSubmission === 4) {
      console.warn("landingPageDetails", landingPageDetails)
      const payload = {
        firstname: firstName !== null ? firstName : undefined,
        lastname: lastName !== null ? lastName : undefined,
        email: email !== null ? email : undefined,
        user_name: userName !== null ? userName : undefined,
        associated_program: programInfo !== null ? programInfo : undefined,
        roles: ['is_partner'],
        commissionsdata: comissionConfig ? comissionConfig : undefined,
        added_by: cookieData?.userinfo?._id,
        landing_page_access: landing_page_access.length > 0 ? landing_page_access : undefined,
        landing_page_access: landing,
        password: pass !==null ? pass : undefined,
        confirmPassword : confirmPass !== null ? confirmPass : undefined
      }




      if (payload.commissionsdata !== undefined && payload.commissionsdata && payload.commissionsdata !== null && Object.keys(payload.commissionsdata)?.length > 0 && payload.password == payload.confirmPassword) {
        setCommissionDataMissing(false)
        setOpenSnackbar(false)
        dispatch(addpartnerdata({ data: payload }))


      } else if (payload.commissionsdata === undefined || payload.commissionsdata === null && Object.keys(payload.commissionsdata)?.length < 0) {
        setCommissionDataMissing(true)
        setOpenSnackbar(true)
      }

    }
  }, [fromSubmission])

  // ------------------------------------------------ //






  useEffect(() => {
    if (preview?.length > 0) {
      setPackageData(preview)
      setModal(true)
    }
  }, [preview])


  useEffect(() => {
    setPackageData(packages)
  }, [packages])





  // --- After Selecting programe name package name fetched ---- //
  useEffect(() => {
    if (programeSelected !== null) {
      dispatch(fetchLandingPage({ "associated_programme": programeSelected }))

      setTimeout(() => {
        dispatch(fetchAssociatedPackages({ "associated_programme": programeSelected }))
      }, 200);

    }
  }, [programeSelected])
  // --------------------------------------------------------- //


  // ------------- after fecthing packages modal open ------- ///
  // useEffect(() => {
  //   if (packages.length > 0) {
  //     setModal(true)
  //   }

  // }, [packages])

  // ------------------------------------------------------- //


  // ------------- comissionModalBtn clicked ------ // 
  useEffect(() => {
    if (comissionModalBtn) {
      if (packages.length > 0) {
        setModal(true)
      }
    }
  }, [comissionModalBtn])



  // --------- Blank Dependency UseEffects  ------------- //
  useEffect(() => {

    // { console.warn("openSnackbar =====> ", openSnackbar) }


    if (cookieData && cookieData.userinfocookies) {
      setUserInfo(cookieData.userinfocookies);
    }
    setComissionConfig(null)
    dispatch(setAffiliateInitData())
    setModal(false)
    return () => {
      dispatch(resetFormData({ formId: "affiliateAddForm" }))
      dispatch(setAffiliateInitData())
      setModal(false)
      setComissionConfig(null)

      dispatch(removeExternalFormData(
        {
          formId: "affiliateAddForm",
          fieldName: "preview",
          formData: {
            label: "Preview commission Details",
            className: "affiliatefeilddisplayblock",
            value: [],
            // innerHtml: innerHtmlValue.join(""),
          },
          index: 10,
        }
      ))

    }
  }, [])
  // ------------------------------ //


  // ---------- on form backbutton clicked ----------------// 
  useEffect(() => {
    if (goBackBtnClick) {
      setModal(false)
      dispatch(clearfetchPackageInfoSuccess())


      const payload_affiliatelist = {
        "condition": {
          "limit": 30, "skip": 0
        },
        "sort": {
          "field": "createdon_datetime", "type": "desc"
        },
        "searchcondition": {
          "roles.is_affiliate": 1,
          "added_by": cookieData?.userinfo?._id

        },
        "count": false
      }

      resolvefunction(
        navigate,
        "/view-affiliate",
        getusersList(payload_affiliatelist)
      );



      // navigate('/view-affiliate');
    }
  }, [goBackBtnClick])

  // ------------------------------------------------------ //


  //  ------- on sucessfull Affiliate add route to affiliate listings-------- // 
  useEffect(() => {
    if (partnerAddSuccess) {

      setOpenSnackbar(true)

      const payload_affiliatelist = {
        "condition": {
          "limit": 30, "skip": 0
        },
        "sort": {
          "field": "createdon_datetime", "type": "desc"
        },
        "searchcondition": {
          "roles.is_affiliate": 1,
          "added_by": cookieData?.userinfo?._id && cookieData.userinfo._id
        },
        "count": false
      }

      resolvefunction(
        navigate,
        "/partners-listing",
        getusersList(payload_affiliatelist)
      );

    }
  }, [partnerAddSuccess])

  // ------------------------------------------ // 



  //  --------------------- affiliateAddError ------------------------------- //

  useEffect(() => {
    if (errorMsg !== null) {

      setOpenSnackbar(true)
    }
  }, [errorMsg])

  // ---------------------------------------------------------------------- //



  // --------- after seeting commision details preiew section here -------------------- //

  useEffect(() => {

    if (comissionConfig !== null) {
      // console.warn("comissionConfig values here ====>>>>>>", comissionConfig)


      const innerHtmlValue = Object.values(comissionConfig).map((x) => {
        return `<ul className="affiliateInnerHtmlBlocks" style="list-style:none"> 
                   <li> Plan Type: ${x.package_name} </li>
                   <li>  Package Price: $${parseFloat(x.package_price).toFixed(2)} </li>
                   <li> <b>Partner Commission </b>: $${parseFloat(x.partner_commission_value).toFixed(2)} </li>


                </ul>`


        // <li> Commission Type : ${x.affilate_commission_type === "non_bps" ? "PERCENTAGE" : x.affilate_commission_type.toUpperCase()} </li>
        // ${ x.affilate_commission_percentage !== undefined && x.affilate_commission_percentage !== "undefined" && `<li> Comission Percentage : ${x.affilate_commission_percentage !== undefined && x.affilate_commission_percentage !== "undefined" ? x.affilate_commission_percentage + '%' : "NA"} </li>`}
      })



      // console.warn("innerHtmlValue =====>>>>>", innerHtmlValue)

      dispatch(setExternalFormData({
        formId: "affiliateAddForm",
        fieldName: "preview",
        formData: {
          label: "Partner Commissions",
          className: "affiliatefeilddisplayblock",
          value: comissionConfig,
          innerHtml: innerHtmlValue.join(""),
        },
        index: 10,
        // multiple: true
      }))

    }
  }, [JSON.stringify(comissionConfig)])
  // ---------------------------------------------------------------- //



  //  ----------- Commision Config btn injection in form ---------- //
  useEffect(() => {

    if (fetchPackageInfoSuccess && packageData.length > 0 && comissionConfig === null) {

      const Commision_Config = {
        id: 'comission_modal',
        label: 'Set-up Commission',
        className: 'default',
        type: 'button',
      }

      formData.current.customButtons.push(Commision_Config)


    } else {

      const buttons = formData.current.customButtons.filter((x) => x.id !== "comission_modal")
      formData.current.customButtons = buttons
    }


  }, [JSON.stringify(fetchPackageInfoSuccess), JSON.stringify(packageData), JSON.stringify(comissionConfig)])

  // -------------------------------------------------------------- //




  // ----------- Configuring state feild with local array --------- //
  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      setLocationArry(Newarr);
    }
  }, [])
  //  ----------------------------------------------------- //



  let addForm = [
    {
      id: 0,
      heading: "First Name",
      label: "First Name",
      name: "firstname",
      className: 'inputBlock inputBlock4line',
      defaultValue: props.fetchData?.firstname ? props.fetchData.firstname : '',

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 1,
      heading: "Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 2,
      heading: "Email",
      label: "Email",
      name: "email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: '',
      value: '',
      inputType: "email",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 3,
      heading: "Unique URL",
      label: "Unique URL",
      name: "user_name",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 4,
      heading: "Choose Program",
      label: "Choose Program",
      name: "associated_program",
      type: "autoComplete",
      className: "inputBlock inputBlock4line displaynone",
      base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
      rules: { required: true },
      preload: true,
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "type": "asc",
          "field": "name"
        },
        "searchcondition": {
          "status": 1,
        }
      },
      // defaultValue: props?.commissionsdata?.associated_program ? props.commissionsdata.associated_program : "",
      defaultValue: "63cf8c25ae5ab31db330fe10",


      sx: { m: 1, minWidth: 300 },
    },




    {
      id: 5,
      heading: "Password",
      label: "Password",
      name: "password",
      className: 'inputBlock inputBlock4line passwordinput',
      type: 'password',
      defaultValue: '',
      rules: { required: true,maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
      errorMessage: {
        required: "This Field is Required",
        // errorMessageConfirmPassword: "Password does not match",         
        custom: "Value is Invalid",
        validate: "Password does not match",
        maxLength: "Maximum allowed input length is 15 characters",

        pattern: "You need to provide a strong password",
      },


    },

    {
      id: 6,
      heading: "Confirm Password",
      label: "Confirm Password",
      name: "confirmPassword",
      className: 'inputBlock inputBlock4line passwordinput',
      type: 'confirm_password',
      defaultValue: '',
      rules: { required: true,maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
      errorMessage: {
        required: "This Field is Required",
        validate: "Password does not match",
        errorMessageConfirmPassword: "Password does not match",
        maxLength: "Maximum allowed input length is 15 characters",
        pattern: "You need to provide a strong password",
        custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
      },


    },



    {
      id: 7,
      label: "Preview",
      name: "preview",
      className: 'inputBlock inputBlock1line affiliatedisplaydatabtn',
      type: "button",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },





  ]


  let editForm = [
    {
      id: 0,
      heading: "First Name",
      label: "First Name",
      name: "firstname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.firstname ? props.fetchData.firstname : '',
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
    },


    {
      id: 1,
      heading: "Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.lastname ? props.fetchData.lastname : '',
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 2,
      heading: "Email",
      label: "Email",
      name: "email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      disabled: true,
      defaultValue: props.fetchData?.email ? props.fetchData.email : '',
      inputType: "email",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },



    {
      id: 4,
      heading: "Unique URL",
      label: "Unique URL",
      name: "user_name",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.user_name ? props.fetchData.user_name : '',

      //inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 5,
      label: "Address",
      name: "address",
      className: 'inputBlock addressbox inputBlock1line',
      type: "textarea",
      defaultValue: props.fetchData?.address ? props.fetchData.address : '',

      //inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 6,
      heading: "State",
      label: "Select State",
      name: "state",
      className: 'inputBlock state inputBlock4line',
      type: "select",
      defaultValue: props.fetchData?.state ? props.fetchData.state : '',
      other: true,
      values: locationArry,
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 7,
      heading: "City",
      label: "City",
      name: "city",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.city ? props.fetchData.city : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 8,
      heading: "Zip",
      label: "Zip",
      name: "zip",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType:"number",
      inputType: "zip",
      defaultValue: props.fetchData?.zip ? props.fetchData.zip : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },
    {
      id: 9,
      heading: "Secondary Phone",
      label: "Secondary Phone",
      name: "secondary_phone",
      className: 'inputBlock inputBlock4line',
      type: "number",
      defaultValue: props.fetchData?.secondary_phone ? props.fetchData.secondary_phone : '',

      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      // errorMessage: {
      //   required: "This Field is Required",
      //   custom: "Value is Invalid"
      // },

      // defaultValue: "Test"
    },

    {
      id: 10,
      heading: "Secondary Email",
      label: "Secondary Email",
      name: "secondary_email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.secondary_email ? props.fetchData.secondary_email : '',
      ////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      // errorMessage: {
      //   required: "This Field is Required",
      //   custom: "Value is Invalid"
      // },

      // defaultValue: "Test"
    },

    {
      id: 11,
      heading: "Phone",
      label: "Phone",
      name: "phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.phone ? props.fetchData.phone : '',

      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },



      // defaultValue: "Test"
    },

    {
      id: 12,
      // heading: "Status",
      label: "Status",
      name: "status",
      className: 'singleCheckbox inputBlock1line',
      type: 'singleCheckbox',
      values: { key: 1, val: 'Active' },
      // rules: { required: true, validate: isValidCheck },
      errorMessage: {
        required: "This Field is Required",
        validate: "is not valid",
        custom: "Value is Invalid"
      },
      defaultValue: props.fetchData?.status ? props.fetchData.status : '',
    },


  ]


  // ---- userCreateForm ------
  const formData = useRef({
    id: 'affiliateAddForm',
    formtype: "add",
    api_url: process.env.REACT_APP_API_URL,
    // endPoint: "users/add-update-affiliate",
    endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/usermanagement",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "tblform",// Can be undefined default will be formCls
    formInlineCLass: "inlnfrm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: props.queryParamId ? "UPDATE" : "SUBMIT",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      // type: "admin",
      // status: "active",
      type: props.fetchData?.type ? props.fetchData.type : props.userType,
      added_by: cookieData?.userinfo?._id,
      _id: props?.queryParamId ? props.queryParamId : undefined,
      roles: ['is_affiliate']
    },

    fields: props?.queryParamId ? editForm : addForm,

    customButtons: [
      // {
      //   id: 'goBack001',
      //   label: 'Go Back',
      //   className: 'default',
      //   type: 'button',
      // },


    ]

  });


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    backgroundColor: '#ffff'
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  useEffect(() => {
    // console.log("comissionConfig========>", comissionConfig)
    if (modal == false && comissionConfig != null) {
      // dispatch(setTempFormUpdateData({ formId: "affiliateAddForm", fieldName: "commissionsdata", value: comissionConfig }))
      formData.current.formAdditionalSubmissionData.commissionsdata = comissionConfig
      // console.warn("comissionConfig final data ", comissionConfig)
      delete formData.current.formAdditionalSubmissionData.preview

    }
  }, [modal])





  return (
    <>
      <div>


        <div className='adminform_wrapper'>
          <h1 class="page_heading">Add Partner</h1>
          {/* ---------- loader goes here ------- */}

          {(loader || loadingg) && <LinearProgress sx={{ marginTop: '10px' }} />}

          {/* ----------------------------------- */}
          <div className='dataform2_wrapper'>
            <div className='adminformbody'>
              <div className='adminform_wrapper'>
                <Form formData={formData.current} />  {/* --- form here -- */}
              </div>
            </div>
          </div>
        </div>
        {/*  ------ modal goes here ------ */}
        {modal && fetchPackageInfoSuccess && <ConfigurePackagePartnerModal setOpenSnackbar={setOpenSnackbar} setSnackbarMSG={setSnackbarMSG} snackbar={openSnackbar} open={modal} close={setModal} package={packageData} setComissionConfig={setComissionConfig} />}
        {/*  ----------------------------- */}


      </div>



      {/*  --- snakcbar section here ---- */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMSG !== null ? snackbarMSG : errorMsg !== null ? errorMsg : commissionDataMissing ? "Please Enter Commission Information" : partnerAddSuccess ? "Partner Sucessfully Created" : "All fields must be filled up with correct value"}
        action={action}
        sx={{ bottom: "24px !important" }}
      />


      {programSnackbarstate === true &&
        <SnackBarComp
          setSnackFire={setProgramSnackbarState}
          resMessage={"Program data not found"}
          snackFire={programSnackbarstate}
          endpointResStatus={"failed"}
        />
      }





      {/*  ---------------------- */}


    </>
  )
}

export default AddPartners;