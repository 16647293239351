import { Button, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { MdDashboard, MdKeyboardArrowDown, MdManageAccounts, MdOutlineModelTraining } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { inputExternalPlugin, resolvefunction } from '../../helper/helperFunctions';
import { EmailCampaignApproverlist, EmailCampaignApproverlistBlueCoast } from '../Frontend/LandingPage/LandingpageReducer';
import { BlueCoastlist, SendMailFulList } from '../Backend/BlueCoast/BlueCoastReducer';
import { useSelector } from 'react-redux';
import { getusersList } from '../Backend/userManagement/userList/userListReducer';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Help } from '@mui/icons-material';
import { getEmailSlagData, getEmailTeamplatedata } from '../Backend/EmailTemplateManagement/EmailTemplateReducer';
import { Lessonlist, Traininglist } from '../Backend/Training/TrainingReducer';
import { trainingCenterCategoryFetching } from '../Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer';
import { TrainingMaterialCenterlist, fetchQuizList, fetchTrainingMaterial } from '../Backend/Training/TrainingMaterial/TrainingMaterialReducer';
import { bookedSlotListData } from '../Calendar/calenderReducer';
import moment from 'moment';

function SpecialEntityMenu({ topmenu }) {

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const { _id } = useParams();
    const splitLocation = pathname.split("/");

    const userInfo = useSelector((state) =>
        state.loginSlice && state.loginSlice?.userInfo
            ? state.loginSlice?.userInfo
            : null
    );

    let userslug = useSelector(state => (state.loginSlice?.user_slug && state.loginSlice?.user_slug.length > 0) ? state.loginSlice.user_slug : [])
    let loginUserInfo = useSelector(state => state.loginSlice?.userInfo ? state.loginSlice.userInfo : [])

    useEffect(() => {
        console.log("loginUserInfo======>>>>>>>", loginUserInfo)
    }, [JSON.stringify(loginUserInfo)])




    let reqbodyformeterilcenter = {
        "roles": userslug,
        "searchcondition": {},
    }

    const [childMenuActive, setChildMenuActive] = useState(false);

    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const [anchorEl8, setAnchorEl8] = useState(null);
    const [anchorEl9, setAnchorEl9] = useState(null);

    const handleClick4 = (event) => setAnchorEl4(event.currentTarget);
    const open4 = Boolean(anchorEl4);
    const handleClose4 = () => setAnchorEl4(null);

    const handleClick7 = (event) => {
        setAnchorEl7(event.currentTarget);
        setChildMenuActive(true)
    }
    const open7 = Boolean(anchorEl7);
    const handleClose7 = () => {
        setAnchorEl7(null);
        setChildMenuActive(false)
    }

    const handleClick8 = (event) => {
        setAnchorEl8(event.currentTarget);
        setChildMenuActive(true)
    }
    const open8 = Boolean(anchorEl8);
    const handleClose8 = () => {
        setAnchorEl8(null);
        setChildMenuActive(false)
    }

    const handleClick9 = (event) => setAnchorEl9(event.currentTarget);
    const open9 = Boolean(anchorEl9);
    const handleClose9 = () => setAnchorEl9(null);


    // =====================payload for userlist in blue coast dashboard================
    const payLoadUser = {
        "source": "users",
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "createdon_datetime",
            "type": "desc"
        },
        "searchcondition": {
        },
        "project": {},
        "token": "",
        "email": "",
        "count": false,
        "bluecoast": true
    }


    const trainingCenterFUnc = async () => {
        await inputExternalPlugin("training-center", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/elements.js");
        resolvefunction(navigate, "/training-center", [
            trainingCenterCategoryFetching()
        ]);
        setTimeout(() => {
            document.location.reload();
        }, 3000)
    }

    // =======================payload for calendar event listing from blue coast dashboard navigatio===================
    const upComingList = {
        "condition": {
            "limit": 30,
            "skip": 0
        },
        "sort": {
            "field": "tstamp",
            "type": "desc"
        },
        "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "searchcondition": {
            "tstamp": {
                "$gte": moment().valueOf()
            },
            "userid": { "$in": ['659d30c8fddb573879253cc2', '645b27af0308b0288a86a4ff', '645b27d70308b0288a86a501', '645b27230308b0288a86a4fb', '645b27550308b0288a86a4fd'] },
            "status": { "$ne": 2 },
        },
        "count": false
    }



    return (
        <>
            <div className='click_buttonwp'>
                <ul>
                    <li>
                        {(loginUserInfo?.email == "beto@yopmail.com" || loginUserInfo?.email == "beto@betoparedes.com") && <button onClick={() => {
                            resolvefunction(
                                navigate,
                                "/businesslead-list",
                                BlueCoastlist()
                            );
                        }} className={(pathname && pathname == '/businesslead-list') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Dashboard</span>
                        </button>}
                    </li>

                    <li>
                        {(loginUserInfo?.email == "beto@yopmail.com" || loginUserInfo?.email == "beto@betoparedes.com") && <button onClick={() => {
                            resolvefunction(
                                navigate,
                                "/businesslead-list-access-deal",
                                BlueCoastlist()
                            );
                        }} className={(pathname && pathname == '/businesslead-list-access-deal') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Blue Coast Access Deal</span>
                        </button>}
                    </li>

                    <li>
                        {(loginUserInfo?.email == "beto@yopmail.com" || loginUserInfo?.email == "beto@betoparedes.com") && <button onClick={() => {
                            resolvefunction(
                                navigate,
                                "/calendar-event-listing",
                                bookedSlotListData(upComingList)

                            );
                        }} className={(pathname && pathname == '/calendar-event-listing') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Calendar Events</span>
                        </button>}
                    </li>

                    {process.env.REACT_APP_ENVIRONMENT != 'prod' && <li>
                        <button
                            onClick={() => {
                                resolvefunction(
                                    navigate,
                                    "/email-campaign-approver-list",
                                    EmailCampaignApproverlist()
                                );
                            }}
                            className={
                                pathname && pathname == "/email-campaign-approver-list" ? "active" : ""
                            }
                        >
                            <MdManageAccounts />

                            <span>Email Campaign Approver (Aspire)</span>
                        </button>
                    </li>}
                    {process.env.REACT_APP_ENVIRONMENT != 'prod' && userInfo?.email &&
                        userInfo?.email != "bbutters@aspirepartnersusa.com" &&
                        userInfo?.email != "wayne@aspirepartnersusa.com" &&
                        userInfo?.email !== "sellisha@aspirepartnersusa.com" &&
                        userInfo?.email != "butters@yopmail.com" &&
                        userInfo?.email != "selisha.test@yopmail.com" &&
                        userInfo?.email != "wayne.entity@yopmail.com" &&
                        (
                            <li>
                                <button
                                    onClick={() => {
                                        resolvefunction(
                                            navigate,
                                            "/email-campaign-approver-list-bluecoast",
                                            EmailCampaignApproverlistBlueCoast()
                                        );
                                    }}
                                    className={
                                        pathname && pathname == "/email-campaign-approver-list-bluecoast" ? "active" : ""
                                    }
                                >
                                    <MdManageAccounts />

                                    <span>Email Campaign Approver (Blue Coast)</span>
                                </button>
                            </li>






                        )



                    }

                    {/* Training */}
                    {process.env.REACT_APP_ENVIRONMENT != 'prod' && <li>
                        <Button
                            id="demo-positioned-button"
                            aria-controls={open4 ? "demo-positioned-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open4 ? "true" : undefined}
                            onClick={handleClick4}
                            endIcon={<KeyboardArrowDownIcon />}
                            className={
                                pathname == "/training-category/list" ||
                                    pathname == "/add-training" ||
                                    pathname == `/edit-training/${_id}` ||
                                    pathname == "/training-center" ||
                                    pathname == "/training-lesson/list" ||
                                    pathname == "/add-lesson" ||
                                    pathname == `/edit-lesson/${_id}` ||
                                    pathname === "/training-material" ||
                                    pathname == "/material-add" ||
                                    pathname == `/material-edit/${_id}` ||
                                    pathname === "/quizzes" ||
                                    pathname === "/quiz-add" ||
                                    pathname === `/quiz-edit/${_id}`
                                    ? "active"
                                    : "inactive"
                            }
                        >


                            <MdOutlineModelTraining />
                            <span>Training</span>
                            <MdKeyboardArrowDown className="leftArrowIcon" />
                        </Button>

                    </li>}

                    <li>
                        <Menu
                            // className="addVid_dropDwn"
                            className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}

                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl4}
                            open={open4}
                            onClose={handleClose4}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <MenuItem
                                className={
                                    splitLocation[1] === "training-list"
                                        ? "submenu_item active"
                                        : "submenu_item"
                                }
                                onClick={() => {
                                    handleClose4();
                                    resolvefunction(navigate, "/training-category/list", [
                                        Traininglist(),
                                    ]);
                                }}
                            >

                                <span> Training </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose4();
                                    trainingCenterFUnc();


                                }}
                            >
                                <span>Training Center</span>
                            </MenuItem>
                            <MenuItem
                                className={
                                    splitLocation[1] === "lesson-list"
                                        ? "submenu_item active"
                                        : "submenu_item"
                                }
                                onClick={() => {
                                    handleClose4();
                                    resolvefunction(navigate, "/training-lesson/list", [
                                        Lessonlist(),
                                    ]);
                                }}
                            >
                                <span> Lessons </span>
                            </MenuItem>
                            <MenuItem
                                className={
                                    splitLocation[1] === "training-material"
                                        ? "submenu_item active"
                                        : "submenu_item"
                                }
                                onClick={() => {
                                    handleClose4();
                                    resolvefunction(
                                        navigate,
                                        "/training-material",
                                        fetchTrainingMaterial()
                                    );
                                }}
                            >
                                <span> Material </span>
                            </MenuItem>
                            <MenuItem
                                className={
                                    splitLocation[1] === "quizzes"
                                        ? "submenu_item active"
                                        : "submenu_item"
                                }
                                onClick={() => {
                                    handleClose4();
                                    resolvefunction(
                                        navigate,
                                        "/quizzes",
                                        fetchQuizList()
                                    );
                                }}
                            >
                                <span> Quizzes </span>
                            </MenuItem>

                            <MenuItem
                                className={
                                    splitLocation[1] === "training-material-centar-list"
                                        ? "submenu_item active"
                                        : "submenu_item"
                                }
                                onClick={() => {
                                    handleClose4();
                                    resolvefunction(
                                        navigate,
                                        "/training-material-centar-list",
                                        TrainingMaterialCenterlist(reqbodyformeterilcenter)
                                    );
                                }}
                            >
                                <span> Training Material Center </span>
                            </MenuItem>

                        </Menu>
                    </li>


                    {process.env.REACT_APP_ENVIRONMENT != 'prod' && <li>
                        <button
                            onClick={() => {
                                resolvefunction(navigate, "/manage-user", getusersList(payLoadUser));
                            }}
                            className={pathname && pathname == "/manage-user" ? "active" : ""}
                        >
                            <MdManageAccounts />
                            <span>Manage Users</span>
                        </button>
                    </li>}

                    {process.env.REACT_APP_ENVIRONMENT != 'prod' && <li>
                        <Button
                            id="demo-positioned-button"
                            aria-controls={open7 ? "demo-positioned-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open7 ? "true" : undefined}
                            onClick={handleClick9}
                            endIcon={<KeyboardArrowDownIcon />}
                            className={(pathname == "/email-template-list" || pathname == "/email-slug") ? "active" : "inactive"}

                        >
                            <Help />
                            <span>Miscellaneous</span>
                            <MdKeyboardArrowDown className="leftArrowIcon" />
                        </Button>
                    </li>}

                    <li>
                        <Menu
                            className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl9}
                            open={open9}
                            onClose={handleClose9}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <MenuItem>
                                {/* <-------------------Drop Down for Email Template-------------------> */}
                                <li>
                                    <Button
                                        id="demo-positioned-button"
                                        aria-controls={open8 ? "demo-positioned-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open8 ? "true" : undefined}
                                        onClick={handleClick8}
                                        endIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <span>Email Template</span>
                                    </Button>
                                </li>



                                <li>
                                    <Menu
                                        className={childMenuActive ? 'childMenuActive topmenu' : 'addVid_dropDwn'}
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl8}
                                        open={open8}
                                        onClose={handleClose8}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                resolvefunction(navigate, "/email-template-list", [
                                                    getEmailTeamplatedata(),
                                                ]);
                                            }}
                                        >
                                            <span onClick={() => { handleClose8(); handleClose9(); }}> Email</span>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                resolvefunction(navigate, "/email-slug", [
                                                    getEmailSlagData(),
                                                ]);
                                            }}
                                        >
                                            <span onClick={() => { handleClose8(); handleClose9(); }}> Email Slug </span>
                                        </MenuItem>
                                    </Menu>
                                </li>
                            </MenuItem>
                            <MenuItem>
                                {/* <-------------------Drop Down for Email Template-------------------> */}
                                <li>
                                    <Button
                                        id="demo-positioned-button"
                                        aria-haspopup="true"
                                        onClick={() => { resolvefunction(navigate, "/add_file"); }}
                                    >
                                        <span>Add Doument</span>
                                    </Button>
                                </li>
                            </MenuItem>

                            <MenuItem>
                                <li>
                                    <Button
                                        id="demo-positioned-button"
                                        aria-haspopup="true"
                                        onClick={() => { resolvefunction(navigate, "/add_file_cetagory"); }}
                                    >
                                        <span>Add Doument Category</span>
                                    </Button>
                                </li>
                            </MenuItem>
                        </Menu>
                    </li>

                </ul>


            </div>



        </>
    )
}

export default SpecialEntityMenu