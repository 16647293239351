import { IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../../listing/listing'
import { useDispatch, useSelector } from 'react-redux';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { employeePackageTotalCost, viewEmployeeList } from '../../EmployerReducer';
import moment from 'moment';
import { Box } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import ReplayIcon from '@mui/icons-material/Replay';
import '../../../../../assets/List.css'




function TotalGrossPackage({ totalGrossFetched }) {


    const dispatch = useDispatch()
    const [packageTableData, setPackageTableData] = useState([])

    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});
    const employeeLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)
    const employeeTotalPackage = useSelector((state) => state.EmployerReducer?.employeeTotalPAckageCost ? state.EmployerReducer.employeeTotalPAckageCost : {})
    const packageDeatilData = useSelector((state) => state.EmployerReducer?.totalPackageListData ? state.EmployerReducer.totalPackageListData : null)

    // console.log("=========>>>>>>>", packageDeatilData)


    /*****************************For Resolve*********************** */
    const resolved = useSelector((state) =>
        state.ResolveReducer?.resolved["/new-employer-dashboard"]
            ? state.ResolveReducer.resolved["/new-employer-dashboard"]
            : false
    );

    useEffect(() => {
        if (!resolved) {

            let reqBody = {

                "condition": {
                    "limit": 30,
                    "skip": 0
                },
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                "searchcondition": logedUserData?.main_role === "is_employer" ? {
                    // employer_id: logedUserData?._id
                    $or: [{ employer_id: logedUserData?._id },
                    { mainEmployer_id: logedUserData?._id },]
                } :
                    {
                        $or: [{ employer_id: logedUserData?._id },
                        {
                            location_id: {
                                $in:
                                    logedUserData?.location_id

                            }
                        }]
                    },
                "project": {},
                "token": "",
                "email": "",
                "count": false

            }

            // let reqBody1 = {

            //     "condition": {
            //         "limit": 30,
            //         "skip": 0
            //     },
            //     "sort": {
            //         "field": "createdon_datetime",
            //         "type": "desc"
            //     },
            //     "searchcondition": logedUserData?.main_role === "is_employer" ? {
            //         employer_id: logedUserData?._id,
            //         status: true,
            //     } :
            //         logedUserData?.main_role === "is_subEmployer" ? {
            //             $or: [{ employer_id: logedUserData?._id },
            //             {
            //                 location_id: {
            //                     $in:
            //                         logedUserData?.location_id

            //                 }
            //             },
            //             { status: true, }
            //             ]
            //         } : "",
            //     "project": {},
            //     "token": "",
            //     "email": "",
            //     "count": false

            // }


            dispatch(employeePackageTotalCost(reqBody))
            dispatch(viewEmployeeList(reqBody))

        }
    }, [])

    useEffect(() => {
        if (packageDeatilData && employeeTotalPackage) {
            // console.log("testHit===========>>>>>", packageDeatilData, employeeTotalPackage)
            totalGrossFetched(true)
        }
        // console.log("testHit===========>>>>>", packageDeatilData)
    }, [packageDeatilData, employeeTotalPackage])

    useEffect(() => {
        if (packageDeatilData && Object.keys(packageDeatilData).length > 0) {
            // console.log("packageDeatilData=========>>>>", packageDeatilData);
            setPackageTableData(packageDeatilData)
            // console.log("test=>>>", packageTableData)

            let reqBody = {

                "condition": {
                    "limit": 30,
                    "skip": 0
                },
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                // "searchcondition": {
                //     employer_id: logedUserData?._id,
                // },
                "searchcondition": logedUserData?.main_role === "is_employer" ? {
                    // employer_id: logedUserData?._id
                    $or: [{ employer_id: logedUserData?._id },
                    { mainEmployer_id: logedUserData?._id },]
                } :
                    {
                        $or: [{ employer_id: logedUserData?._id },
                        {
                            location_id: {
                                $in:
                                    logedUserData?.location_id

                            }
                        }]
                    },
                "project": {},
                "token": "",
                "email": "",
                "count": false

            }
            dispatch(employeePackageTotalCost(reqBody))


        }
    }, [])





    useEffect(() => {
        if (employeeTotalPackage && Object.keys(employeeTotalPackage).length > 0) {

            let reqBody = {

                "condition": {
                    "limit": 30,
                    "skip": 0
                },
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                // "searchcondition": {
                //     employer_id: logedUserData?._id,
                // },
                "searchcondition": logedUserData?.main_role === "is_employer" ? {
                    employer_id: logedUserData?._id
                    // $or: [{ employer_id: logedUserData?._id },
                    // { mainEmployer_id: logedUserData?._id },]
                } :
                    {
                        $or: [{ employer_id: logedUserData?._id },
                        {
                            location_id: {
                                $in:
                                    logedUserData?.location_id

                            }
                        }]
                    },
                "project": {},
                "token": "",
                "email": "",
                "count": false

            }

            dispatch(viewEmployeeList(reqBody))
        }
    }, [])





    return (
        <>
            {employeeLoader ? <LinearProgress sx={{ marginTop: "10px" }} /> : ""}

            <div className="employee_Count NotesViewList_main totalCost_table">
                <div className="rolelist_mainwrp_Table">
                    {/* <p>Total Package Costing</p> */}

                    <Toolbar
                    >
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Total Package Costing
                        </Typography>
                        <Tooltip title="Reload">
                            <IconButton>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>

                    </Toolbar>

                    {!employeeLoader && packageDeatilData?.length > 0 ?
                        (
                            <Table sx={{ minWidth: 750 }} aria-label="caption table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='index_column index_header'>#</TableCell>
                                        <TableCell>	Package Name</TableCell>
                                        <TableCell align="right">No. of Subscription</TableCell>
                                        <TableCell align="right">Package Price ($)</TableCell>
                                        <TableCell align="right">Total Package Cost ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {packageDeatilData?.length > 0 && packageDeatilData?.map((packageData, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="index_column index_cell">{packageData.index ? index + 1 : index + 1}</TableCell>
                                            <TableCell align="right">{packageData?._id}</TableCell>
                                            <TableCell align="right">{packageData?.package_count}</TableCell>
                                            <TableCell align="right">{packageData?.package_price}</TableCell>
                                            <TableCell align="right">{(packageData?.total_package_cost).toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        ) : employeeLoader ? <TableSkeleton count={5} />
                            : <div className="noDataAvailable">
                                <img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" alt='' />
                            </div>
                    }


                    {/* <p>{JSON.stringify(employeeTotalPackage)}</p> */}

                    {Object.keys(employeeTotalPackage).length > 0 && <ul className='total_bill_mt'>
                        <li className='double_bill'>
                            <span className='total_bill_mt_text'> Total Amount</span>
                            <span className='total_bill_mt_amount'>
                                {employeeTotalPackage?.total_cost !== null && typeof (employeeTotalPackage?.total_cost) !== isNaN ? '$' + parseFloat(employeeTotalPackage?.total_cost)?.toFixed(2) : '$0'}

                            </span>
                        </li>

                    </ul>}


                </div>
            </div>

        </>
    )
}

export default TotalGrossPackage