import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import FormPreviewModal from '../../../../commoncomponents/modal/FormPreviewModal';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import ListingTable from '../../../../listing/listing';
import store from '../../../../store';
import { clearfielddata, fieldList, getfieldeditdata } from '../FormManagementReducer';

function FieldList() {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let fielddatalist = useSelector(state => (state.FormManagementSlice?.fieldData && state.FormManagementSlice?.fieldData.length > 0) ? state.FormManagementSlice.fieldData : [])

  let loader = useSelector(state => (state.FormManagementSlice?.loading) ? state.FormManagementSlice.loading : false)

  const resolved = useSelector(state => state.ResolveReducer.resolved['/field-module/list'] ? state.ResolveReducer.resolved['/field-module/list'] : false)

  /****************for Edit*********************** */

  const editBtnClicked = useSelector(state => state.tableSlice?.customButtonClicked?.fieldformdata?.edittable9 ? state.tableSlice.customButtonClicked.fieldformdata.edittable9 : false);

  const filedpreviewclick = useSelector(state => (state.tableSlice?.actionLoading && Object.keys(state.tableSlice?.actionLoading).length > 0 && state.tableSlice?.actionLoading.fieldformdata?.FieldView && state.tableSlice?.actionLoading.fieldformdata?.FieldView == 2) ? true : false);

  const filedpreviewdata = useSelector(state => (state.tableSlice?.actionData && Object.keys(state.tableSlice?.actionData).length > 0 && state.tableSlice?.actionData.fieldformdata?.FieldView && state.tableSlice?.actionData.fieldformdata.FieldView.length > 0) ? state.tableSlice?.actionData.fieldformdata.FieldView : null);

  const [openfieldpreview, setOpenfieldpreview] = useState()

  useEffect(() => {
    // console.log("editBtnClicked==========>", editBtnClicked);
  }, [editBtnClicked])

  useEffect(() => {
    // console.log("filedpreviewclick", filedpreviewclick, filedpreviewdata);

    if (filedpreviewclick && filedpreviewdata) {
      setOpenfieldpreview(true)
    }
  }, [filedpreviewdata, filedpreviewclick])


  const editfieldData = useSelector(state => state.tableSlice?.tempRowData?.fieldformdata ? state.tableSlice.tempRowData.fieldformdata : {});

  useEffect(() => {
    // console.log("editfieldData==========>", editfieldData);
  }, [editfieldData])


  useEffect(() => {
    if (editBtnClicked && Object.keys(editfieldData).length > 0) {
      resolvefunction(navigate, `/field-edit/${editfieldData._id}`, getfieldeditdata(editfieldData._id))
    }

  }, [editBtnClicked])

  /******************Field Table List Call *************** */
  useEffect(() => {
    if (!resolved) {
      store.dispatch(fieldList(tableDataForUsers.current.reqBody));
    };
    return () => {
      dispatch(resetresolvenew())
      dispatch(clearfielddata())

      //whenever the component removes it will executes
    }

  }, []);


  const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})


  useEffect(() => {
    tableDataForUsers.current.searchData.formData.fields.forEach((data) => {
          data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
          // console.log("data1234", data, searchFormData);
      })
      // console.log("searchFormData", searchFormData);
  }, [JSON.stringify(searchFormData)]);










  var modifyTableHeaders =
    [
      { val: "field_name", name: "Field Name" },
      { val: "field_label", name: "Field Label" },
      { val: "field_type", name: "Field Type" },
      { val: "group_name", name: "Related Groups" },

      // { val: "program_names", name: "Program/Product" },
      //    { val: "description", name: "Description" },
      { val: "priority", name: "Priority" },
      { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active" } },
      // { val: "createdon_datetime", name: "Creation Time", type: "datetime", format: "MM/DD/YYYY" },
      { val: "createdon_datetime", name: "Updation Time", type: "datetime", format: "MM/DD/YYYY" },


    ];

  let tableDataForUsers = useRef({
    tableId: "fieldformdata",
    tableTitle: "Field List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "manage-form/field-list-count",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "manage-form/field-list-count",
    },

    reqBody: {

      "source": "field_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    // deleteSingleUserReqBody: {
    //   source: "training_list",
    //   secret: "AZ|lepL`",

    // },
    // deleteMultipleReqBody: {
    //   source: "training_list",
    //   secret: "AZ|lepL`",

    // },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["training_name", "priority", "status", "createdon_datetime", "updatedon_datetime", "training_parent_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to delete these records?</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/delete-field",
          body: {
            "source": "field_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status?</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "/manage-form/status-update-field",
          body: {
            "source": "field_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      // {
      //   id: "adduser",
      //   type: "add_icon",
      //   title: "Add Training",
      //   className: "add_user_icon",
      //   default: true,
      // },
    ],
    buttons: [



      {
        id: "edittable9",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },

      // {
      //   id: "deletetable1",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },

      // {
      //   id: "change_status1",
      //   type: "other",
      //   label: "Change Status",
      //   name: "toggle_off ",
      // },




      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/delete-field",
          body: {
            "source": "field_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !! </p>",
        modalTitle: "<p>Are you sure you want to change the status?</p>",

        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/status-update-field",
          body: {
            "source": "field_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "viewApi",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Field Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/field-list-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {}
          }
        },
        headers: [
          { val: "field_name", label: "Field Name" },
          { val: "field_label", label: "Field Label" },
          { val: "field_type", label: "Field Type" },
          { val: "group_name", label: "Related Groups" },
          // { val: "field_options", label: "Field Options" },


          // { val: "type", label: "Type" },
          { val: "description", label: "Description" },
          {
            val: "createdon_datetime",
            label: "Creation Time",
            // name: "Creation Time",
            type: "datetime",
            format: "MM/DD/YYYY",
          },
          {
            val: "updatedon_datetime",
            label: "Updation Time",
            // name: "Updation Time",
            type: "datetime",
            format: "MM/DD/YYYY",
          },
        ]
      },


      {
        id: "FieldView",
        type: "action",
        icon_type: "feed_icon",
        label: "Field",
        name: "Preview Field",
     
        className:'filedview ',
        base_url: process.env.REACT_APP_API_URL,
        endpoint: "manage-form/preview-form-field",
        body: {
          "source": "",

          "searchcondition": {}
        },
        // payloadAttach: ["_id", "type", "email"],
        payloadAttach: ["_id"],
      },


    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      heading: " Search Field",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Filed Name",
            name: "field_name",
            type: "text",
            className: 'list_search_item countDiv__column--col6 ',
            // base_url: process.env.REACT_APP_API_URL + "training-manage/auto-complete-training",
            reqBody: {
              "source": "field_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "field_name" },

          },
          {
            id: 2,
            label: "Search by Field Label",
            name: "field_label",
            type: "text",
            className: 'list_search_item countDiv__column--col6 ',
            // base_url: process.env.REACT_APP_API_URL + "training-manage/auto-complete-training",
            reqBody: {
              "source": "field_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "field_label" },

          },
          {
            id: 5,
            label: "Search by Field Type",
            name: "field_type",
            type: "select",
            className: 'list_search_item countDiv__column--col6 ',
            // base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
            // reqBody: {
            //   "source": "field_list",
            //   "condition": {
            //     "limit": 10,
            //     "skip": 0
            //   },
            //   "sort": {
            //     "type": "asc",
            //     "field": "name"
            //   },
            //   "searchcondition": {

            //   }
            // },
            checkbox: true,
            multiple: false,

            values: [

              { val: 'textbox', name: 'TextBox' },
              { val: 'radio', name: 'Radio' },
              { val: 'checkbox', name: 'CheckBox' },
              { val: 'selectbox', name: 'SelectBox' },
              { val: 'select', name: 'Select' },
              { val: 'autoComplete', name: 'AutoComplete' },
              { val: 'date', name: 'Date' },
              { val: 'number', name: 'Number' },
              { val: 'textarea', name: 'TextArea' }



            ],
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "field_type" },

          },

          {
            id: 6,
            label: "Search by Related Groups",
            name: "related_groups",
            type: "autocomplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "manage-form/groupnameautocomplete",
            reqBody: {
              "source": "field_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "related_groups" },

          },

          {
            id: 3,
            label: "Search by Status",
            name: "status",
            // className: 'Status',
            type: 'select',
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "status" },
          },

          {
            id: 8,
            label: "Search by Creation Start Date",
            name: "createdon_Start_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Creation End Date",
            name: "createdon_datetime",
            className: 'list_search_item countDiv__column--col4 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },
          {
            id: 10,
            //   heading: "Search By Creation Time",
            label: "Search by Updated on Start Date",
            name: "start_date_by_update",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "updatedon_datetime", param: "$gte" }
          },
          {
            id: 11,
            label: "Search by Updated on End Date",
            name: "end_date_by_update",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "updatedon_datetime", param: "$lte" }
          },
        ]
      }
    },

  });






  return (

    <>
      <div className="rolelist_mainwrp">
        <div className='listing_heading'>

          <h1>Field Management</h1>
          <button className='addrole_btn' title="Add Group" onClick={() => navigate('/add-field')}>+</button>
        </div>
        <div className='rolelist_mainwrp_Table'>
          {!loader ? (
            <ListingTable
              tableData={tableDataForUsers.current}
              dataset={fielddatalist}
              modifyHeaders={modifyTableHeaders}
            />
          ) : (
            <TableSkeleton count={5} />
          )}

        </div>
      </div>
 {/* //////////////////////////// Field Preview Modal ////////////////// */}
      {openfieldpreview && <FormPreviewModal
        openfieldpreview={openfieldpreview}
        setOpenfieldpreview={setOpenfieldpreview}
        dataset={filedpreviewdata}
        titel='Preview Field'
      />}


    </>

  )
}

export default FieldList