// import React from 'react'
import { Box, Button, LinearProgress, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import store from '../../store';
import { fetchbluecodelead } from "./LandingPage/LandingpageReducer";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "../../form/Form";
import { setSnackbar } from "../Layout/layoutReducer";
import { removeField, setField } from "../../form/formReducer";


function Successaspire() {
  const dispatch = useDispatch()
  const { leadid } = useParams();
  const [open, setOpen] = useState(false)
  // console.log("leadid", leadid);
  const editdatablue = useSelector(state => (state.LandingpagefontReducer?.bluefetchdata) ? state.LandingpagefontReducer?.bluefetchdata : null)
  const loading = useSelector(state => (state.LandingpagefontReducer?.bluecoseloader) ? state.LandingpagefontReducer?.bluecoseloader : false)
  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.accessform) ? state.formSlice?.formSubmissionState?.accessform : 0)
  const formblueediittotherRadioButtonClicked = useSelector((state) => (state.formSlice?.formData?.accessform && Object.keys(state.formSlice.formData.accessform)) ? state.formSlice.formData.accessform : undefined)
  useEffect(() => {
    if (leadid) dispatch(fetchbluecodelead(leadid))
  }, [leadid])

  useEffect(() => {
    console.log("submissionState+++", submissionState);
  }, [submissionState])
  useEffect(() => {
    console.log("editdatablue", editdatablue);
    if (editdatablue) {
      let formField = { ...formblueedit.current }
      console.log("hitt");
      for (let i in formField.fields) {
        console.log("hitt 2", formField.fields[i], editdatablue[formField.fields[i].name]);

        if (editdatablue[formField.fields[i].name] && formField.fields[i]) {
          console.log("hitt 3");

          formField.fields[i] = { ...formField.fields[i], defaultValue: editdatablue[formField.fields[i].name] }
        }
      }
      if (editdatablue.other || (editdatablue.medicalfacilitytype && editdatablue.medicalfacilitytype == 'Other')) {

        let field = {

          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: 'formfild formfild1',
          // multiple: true,
          type: "text",

          defaultValue: editdatablue.other ? editdatablue.other : undefined
        };
        setTimeout(() => dispatch(setField({ formId: "accessform", after: "medicalfacilitytype", fieldData: field })), 200);
      }
      if (editdatablue._id) {
        formField.formAdditionalSubmissionData['_id'] = editdatablue._id
      }

    }
  }, [JSON.stringify(editdatablue)])

  useEffect(() => {
    console.log("formblueediittotherRadioButtonClicked", formblueediittotherRadioButtonClicked);
    if (formblueediittotherRadioButtonClicked && formblueediittotherRadioButtonClicked.medicalfacilitytype) {
      if (formblueediittotherRadioButtonClicked.medicalfacilitytype && formblueediittotherRadioButtonClicked.medicalfacilitytype == 'Other') {

        console.log("formblueediittotherRadioButtonClicked", formblueediittotherRadioButtonClicked);


        let field = {

          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: 'formfild formfild1',
          // multiple: true,
          type: "text",
          defaultValue: ''

        };
        setTimeout(() => dispatch(setField({ formId: "accessform", after: "medicalfacilitytype", fieldData: field })), 200);


      } else {
        setTimeout(() => dispatch(removeField({ formId: "accessform", name: "other" })), 2000)

      }


    }

  }, [JSON.stringify(formblueediittotherRadioButtonClicked)])
  const modalopen = () => {
    setOpen(true)
  }
  const formblueedit = useRef({
    id: 'accessform',
    formtype: "edit",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "bclusers/addupdate-users",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    // resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      '_id': editdatablue && editdatablue._id ? editdatablue._id : undefined,
      "status": 0,
      // "roles": { is_rep: 1 },

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "first_name",
        className: 'formfild formfild4 hiddenfied',
        type: "text",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.first_name !== undefined) ? editdatablue.first_name : undefined,

      },
      {
        id: 2,
        heading: "Your Last Name",
        label: "Last Name",
        name: "last_name",
        className: 'formfild formfild4 hiddenfied',
        type: "text",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.last_name !== undefined) ? editdatablue.last_name : undefined,

      },
      {
        id: 3,
        heading: "Your Email",
        label: "Email",
        name: "email",
        className: 'formfild formfild4 hiddenfied',
        type: "text",
        inputType: "email",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.email !== undefined) ? editdatablue.email : undefined,

      },

      {
        id: 4,
        heading: "Your Phone Number",
        label: "Phone Number",
        name: "phone",
        className: 'formfild formfild4 hiddenfied',
        type: "text",
        inputType: "phone",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.phone !== undefined) ? editdatablue.phone : undefined,

      },
      {
        id: 5,
        heading: "Potential Pharmacy Partner Name",
        label: "Potential Pharmacy Partner Name",
        name: "prospectbusinessname",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectbusinessname !== undefined) ? editdatablue.prospectbusinessname : undefined,

      },

      {
        id: 6,
        heading: "Prospect Title",
        label: "Prospect Title",
        name: "prospecttitle",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospecttitle !== undefined) ? editdatablue.prospecttitle : undefined,
      },

      {
        id: 7,
        heading: "Prospect First Name",
        label: "Prospect First Name",
        name: "prospectfirstname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectfirstname !== undefined) ? editdatablue.prospectfirstname : undefined,
      },
      {
        id: 8,
        heading: "Prospect Last Name",
        label: "Prospect Last Name",
        name: "prospectlastname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectlastname !== undefined) ? editdatablue.prospectlastname : undefined,
      },

      {
        id: 9,
        heading: "Prospect Email",
        label: "Prospect Email",
        name: "prospectemail",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectemail !== undefined) ? editdatablue.prospectemail : undefined,
      },

      {
        id: 10,
        heading: "Prospect Phone Number ",
        label: "Prospect Phone Number ",
        name: "prospectcontactnumber",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectcontactnumber !== undefined) ? editdatablue.prospectcontactnumber : undefined,
      },
      {
        id: 11,
        heading: "Type of business or group",
        name: "medicalfacilitytype",
        className: 'form_radio',
        values: [{ key: 'Affinity Group', val: 'Affinity Group' }, { key: 'Chamber of Commerce', val: 'Chamber of Commerce' }, { key: 'MLM', val: 'MLM' }, { key: 'Affiliate Organization', val: 'Affiliate Organization' }, { key: 'Employer', val: 'Employer' }, { key: 'Other', val: 'Other' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.medicalfacilitytype !== undefined) ? editdatablue.medicalfacilitytype : undefined,
        // defaultValue: "No"
      },


      // {
      //   id: 22,
      //   heading: "Type of facility",
      //   name: "facility_type",
      //   className: 'form_radio',
      //   values: [{ key: 'Med Practice', val: 'Med Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Other', val: 'Other' }],
      //   type: 'radio',
      //   // hint: 'Yes/No',
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },



      // {
      //   id: 1,
      //   heading: "Name of medical facility",
      //   label: "Name of medical facility",
      //   name: "medical_facility_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      // },
      // {
      //   id: 2,
      //   heading: "Name of their relationship at facility and that persons title (would be good to have this fill able so that they can type details)",
      //   label: "Name of their relationship at facility and that persons title",
      //   name: "relationship_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true},
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },


      {
        id: 12,
        heading: "What is your relationship with the prospect? ",
        name: "natureofrelationship",
        className: 'form_radio',
        values: [{ key: 'Client', val: 'Client' }, { key: 'Client/Friend', val: 'Client/Friend' }, { key: 'Friend', val: 'Friend' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.natureofrelationship !== undefined) ? editdatablue.natureofrelationship : undefined,
        // defaultValue: "No"
      },
      {
        id: 51,
        heading: "Please describe your relationship",
        placeholder: "Please describe your relationship",
        label: "Please describe your relationship",
        name: "relationshipdescription",
        className: "formfild inputBlock1line grossreceipts",
        type: "text",
        // inputType: "email",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid",
        // },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.relationshipdescription !== undefined) ? editdatablue.relationshipdescription : undefined,
      },



      // {
      //   id: 13,
      //   heading: "Approx gross receipts with top 3 insurers ($2M minimum)",
      //   label: "Approx gross receipts with top 3 insurers ($2M minimum)",
      //   name: "approxgrossreciept",
      //   className: 'formfild',
      //   type: "text",
      //   // inputType: "email",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      //   defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.approxgrossreciept !== undefined) ? editdatablue.approxgrossreciept : undefined,

      // },
      // {
      //   id: 31,
      //   heading: "Website",
      //   label: "Website",
      //   name: "company_website",
      //   className: 'formfild formfild1',
      //   type: "text",
      //   // rules: { required: true },
      //   // errorMessage: {
      //   //   required: "This Field is Required",
      //   //   custom: "Value is Invalid"
      //   // },
      //   defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.company_website !== undefined) ? editdatablue.company_website : undefined
      // },
      {
        id: 14,
        heading: "Any other information we should know?",
        label: "Any other information we should know?",
        name: "other_info",
        placeholder: 'Any other information we should know?',
        className: 'formfild textareafild',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.other_info !== undefined) ? editdatablue.other_info : undefined,
      },
      {
        id: 22,
        heading: "Must add extensive notes for this to be reviewed for qualification",
        placeholder: 'Must add extensive notes for this to be reviewed for qualification',
        label: "Must add extensive notes for this to be reviewed for qualification",
        name: "extensive_notes",
        className: 'formfild textareafild',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.extensive_notes !== undefined) ? editdatablue.extensive_notes : undefined,

      }




    ]

  })


  useEffect(() => {
    console.log("submissionState", submissionState);
    if (submissionState && submissionState == 2) {
      dispatch(
        setSnackbar({ open: true, message: 'Updated successfully!', status: 'success' })
      )
      editapicall()

    }
  }, [submissionState])

  const editapicall = async () => {
    dispatch(fetchbluecodelead(leadid))
    setOpen(false)
  }


  const [doneBtnSuccess, setDoneBtnSuccess] = useState(1)

  const donebtnclicked = () => {
    setDoneBtnSuccess(2)
    dispatch(
      setSnackbar({ open: true, message: 'Successfully Done!', status: 'success' })
    )

  }
  return (
    <div>
      <div className='thankYou_wrpr blueCoast_Success'>
        <div className='thankYou_bgdesgn'>
          <div className="bgdesgn1"></div>
          <div className="bgdesgn2"></div>
          <div className="bgdesgn3"></div>
          <div className="bgdesgn4"></div>
          <div className="bgdesgn5"></div>
          <div className="bgdesgn6"></div>
          <div className="bgdesgn7"></div>
          <div className="bgdesgn8"></div>
        </div>


        <div className='thankyou-container'>
          <div className='thankyou-block'>

            <div className='content center'>
              {/* {leadid ? <h1>Successful, please review your submission </h1> : <h1>Thank You For Submitting the Form</h1>} */}
              {leadid ? <h1> <span>Success!</span>
                <span>Following are the details you submitted. You will also receive a copy of this in your email from </span><span> DO-NOT-REPLY@dealfirecrm.com.</span>
              </h1> : <h1>Thank You For Submitting the Form</h1>}
              {/* {!params && !params.rep && <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>}  */}
              {leadid ? <div className='thankyou-containerSub'>
                <div className='thankyou-blockSub'>


                  {loading && <Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>}
                  <div className="landingpage9successBody">
                    {/* <div className="editiconinsucccess" onClick={() => modalopen()}>
                      <EditIcon />

                    </div> */}
                    {!loading && editdatablue && <div className="landingpage9success">
                      {/* <h1>Your Slot {page === "booking" ? 'Booked' : page === "reschedule" ? "Re-Scheduled" : "Canceled"} Successfully!</h1> */}


                      <p><strong>Your Name:</strong> <span> {editdatablue.first_name} {editdatablue.last_name} </span> </p>
                      <p><strong>Your Email:</strong> <span> {editdatablue.email} </span> </p>
                      <p><strong>Your Phone Number:</strong> <span> {editdatablue.phone} </span> </p>
                      <p><strong> Potential Pharmacy Partner Name :</strong> <span> {editdatablue.prospectbusinessname} </span> </p>
                      <p><strong> Prospect's Title:</strong> <span> {editdatablue.prospecttitle} </span> </p>

                      <p><strong> Prospect's First Name:</strong> <span> {editdatablue.prospectfirstname} </span> </p>

                      <p><strong> Prospect's Last Name:</strong> <span> {editdatablue.prospectlastname} </span> </p>
                      <p><strong> Prospect's Email:</strong> <span> {editdatablue.prospectemail} </span> </p>
                      <p><strong> Prospect's Phone Number:</strong> <span> {editdatablue.prospectcontactnumber} </span> </p>
                      <p><strong> Type of business or group : </strong> <span> {(editdatablue.other && editdatablue.medicalfacilitytype == 'Other') ? editdatablue.other : editdatablue.medicalfacilitytype} </span> </p>
                      {/* <p><strong>Type of facility:</strong> <span> {editdatablue.facilitytype} </span> </p> */}
                      <p><strong> What is your relationship with the prospect?:</strong> <span> {editdatablue.natureofrelationship} </span> </p>
                      <p><strong> Relationship description with the prospect:</strong> <span> {editdatablue.relationshipdescription} </span> </p>
                      
                      <p><strong> Website:</strong> <span> {editdatablue.company_website} </span> </p>
                      {/* <p><strong> Approx gross receipts with top 3 insurers <span>($2M minimum):</span></strong> <span> {editdatablue.approxgrossreciept} </span> </p> */}
                      {editdatablue.other_info && <p><strong> Any other information we should know? :</strong> <span> {editdatablue.other_info} </span> </p>}
                      {editdatablue.extensive_notes && <p><strong> Must add extensive notes for this to be reviewed for qualification:</strong> <span> {editdatablue.extensive_notes} </span> </p>}

                      <div className="bttnWrpr">

                        {/* {doneBtnSuccess == 1 && <div className="editiconinsucccess" onClick={() => modalopen()}>
                          Edit

                        </div>} */}
                        {/* <div className="editiconinsucccess" onClick={() => donebtnclicked()}>Done</div> */}
                      </div>
                    </div>}


                  </div>


                </div>
              </div> : <p>Greeting for your interest in Access. Our team will verify your details and contact you as soon as possible.</p>}
            </div>
          </div>
        </div>
      </div>

      {editdatablue && <Modal
        className="modalblock"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box className="BoxMui_modal successmodal9" sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}>
          <h2>Edit your information and click “Submit” to save.</h2>

          <Form formData={formblueedit.current} />

          {/* -------------------------------------- */}



          <Button onClick={() => setOpen(false)} className="closeModalBtn">
            <Tooltip title="Close" placement="right-start">

              <CloseIcon />
            </Tooltip>
          </Button>
        </Box>
      </Modal>}
    </div>
  );
}

export default Successaspire


