



import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { unstable_HistoryRouter, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Alert, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, NativeSelect, OutlinedInput, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { useCookies } from "react-cookie";
// import "../../BackEndPages.css";
import StateArray from '../../../../assets/json/state';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { getusersList } from '../../userManagement/userList/userListReducer';
import { fetchAssociatedPackages } from '../../AffiliateManagement/AffiliateReducer';
import { Box } from '@mui/system';
import CloseIcon from "@mui/icons-material/Close";
import { ConnectingAirportsOutlined } from '@mui/icons-material';


const ConfigurePackagePartnerModal = (props) => {
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 


    // ---- States here -----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    const [modal, setModal] = useState(false);  //*****modal Open toogle ****//

    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    const [percentage, setPercentage] = useState({});
    const [packageName, setPackageName] = useState(null);
    const [packagePrice, setPackagePrice] = useState(null);
    let [commisionType, setCommisionType] = useState({});
    const [commisionAmount, setCommisionAmount] = useState({});
    const [bpsInfo, setBpsInfo] = useState(null);
    const [packageDetails, setPackageDetails] = useState(null);
    const [count, setCount] = useState(0);
    const [terminateProcess, setTerminateProcess] = useState(false);
    const [copyEditCommisionType, setCopyEditCommisionType] = useState({});
    const [snackbar, setSnackbar] = useState(false)


    const [error, setError] = useState(false);


    // console.warn("<<<<< cookieData from addEditFrom component>>>>>", cookieData)

    // ----------- affiliateSelected -------- //
    const packages = useSelector(state => state.AffiliateReducer?.packageInfo?.length > 0 ? state.AffiliateReducer?.packageInfo : []);
    // ------------------------------------- //

    console.warn("packages", packages)


    // ---------- loader ------- //
    const loader = useSelector(state => state.AffiliateReducer?.loading ? state.AffiliateReducer?.loading : false);
    // -------------------- //

    const style = {

    };



    // ------- on package available sort in asc order ------ // 
    useEffect(() => {
        if (packages?.length > 0) {
            let copyPackage = [...packages]

            const packageSortedList = copyPackage.sort((a, b) => {
                const packageFiltred = a.priority - b.priority;
                return packageFiltred;

            }, true);


            setPackageDetails(packageSortedList)
        }




    }, [packages])

    //  -------------------------------------------------- //


    // -------------------- setting default value during edit ---------------------// 
    let commisionValue
    let defaultCommisionValue
    let commissionPercentageValue
    let commissionAmountValue
    let defaultCommissionPercentage
    let defaultCommissionAmount

    if (props.package.length > 0) {

        const arr = props.package.map((x) => Object.keys(x))

        commisionValue = props.package.map((x) => arr[0].map((y) => x[y]?.affilate_commission_type ? x[y]?.affilate_commission_type : ''))
        commissionPercentageValue = props.package.map((x) => arr[0].map((y) => x[y]?.affilate_commission_percentage ? x[y]?.affilate_commission_percentage : ''))
        commissionAmountValue = props.package.map((x) => arr[0].map((y) => x[y]?.partner_commission_value ? x[y]?.partner_commission_value : ''))

        // console.warn("commissionPercentageValue", commissionPercentageValue)
        // console.warn("commissionAmountValue", commissionAmountValue)

        defaultCommisionValue = commisionValue[0]
        defaultCommissionPercentage = commissionPercentageValue[0]
        defaultCommissionAmount = commissionAmountValue[0]

    }

    // -------------------------------------------------------------------------- //



    // -------------------- if props available ------------------------ //
    useEffect(() => {
        if (props.package.length > 0) {

            // console.warn("commisionType ===>>>", commisionType)
            console.warn("props.package", props.package)

            const editCommisionData = { ...props.package[0] }
            dataset = JSON.parse(JSON.stringify(editCommisionData))


            // ------- setting oold commision type data during edit ------------ //
            let comT = {}

            Object.values(editCommisionData).map((item, i) => {
                if (item?.affilate_commission_type !== undefined) {
                    comT[i] = item.affilate_commission_type
                    // console.warn("comT", comT)
                }
            })

            if (Object.keys(comT).length > 0) {
                setCommisionType(comT);
            }
            // --------------------------------------- //


            // ------- setting old commision percentage data during edit ------------ //

            let percentageData = {}

            Object.values(editCommisionData).map((item, i) => {
                if (item?.affilate_commission_percentage !== undefined) {
                    percentageData[i] = item?.affilate_commission_percentage
                    // console.warn("comT", comT)
                }
            })

            if (Object.keys(percentageData).length > 0) {
                setPercentage(percentageData);
            }

            // --------------------------------------------------------------- // 


            // ------- setting old commision percentage data during edit ------------ //

            let commisionAmountData = {}

            Object.values(editCommisionData).map((item, i) => {
                if (item?.partner_commission_value !== undefined) {
                    commisionAmountData[i] = item?.partner_commission_value
                    // console.warn("comT", comT)
                }
            })

            if (Object.keys(commisionAmountData).length > 0) {
                setCommisionAmount(commisionAmountData);
            }

            // --------------------------------------------------------------- // 





        }
    }, [props.package])





    // --------------------------------------------------------------- //



    // useEffect(()=>{
    //     if(comitionData){
    //       let comT = {}
    //       comT={...commisionType}

    // Object.values(comitionData).map((item, i) => {
    //     comT[i] = item.affilate_commission_type
    // })
    // setCommisionType(comT);
    // // }


    //   },[comitionData])




    let dataset = {}

    function submit() {
        // console.warn("Fianl submisstion data")


        packageDetails.forEach((data, index) => {
            dataset[data.package_name] = {

                package_name: data.package_name,
                package_price: data.package_price,
                // status: data.status,
                // partner_commission_percentage: percentage[index] ? percentage[index] : undefined,
                // affilate_commission_type: commisionType[index],
                partner_commission_type: "flat",
                // partner_commission_value: commisionType[index] == "flat" ? parseInt(commisionAmount[index]) : ((data.commission_amount * percentage[index]) / 100).toFixed(2),
                partner_commission_value: parseFloat(commisionAmount[index]).toFixed(2)
            }
        })

        const invalid = Object.values(dataset).find((val) => val?.partner_commission_value == "NaN")
        // console.log("Object.values(dataset)================>", Object.values(dataset), invalid)
        console.warn("Fianl submisstion data=====>>>", dataset,)



        if (Object.values(dataset).length < packages.length || Object.values(dataset).find((val) => val?.partner_commission_value > val?.package_price)) {
            props.setSnackbarMSG("The Number You Are Entering Is Incorrect")
            props.setOpenSnackbar(true)
            console.warn("1 stage ===>>>")

        } else if (Object.values(dataset).length < packages.length || Object.values(dataset).find((val) => isNaN(val.partner_commission_value))) {

            props.setOpenSnackbar(true)
            console.warn("2 stage ===>>>")

        } else if (Object.values(dataset).find((val) => val?.partner_commission_value == undefined || val?.partner_commission_value < 0)) {

            props.setSnackbarMSG("The Number You Are Entering Is Incorrect")

            setTimeout(() => {
                props.setOpenSnackbar(true)
            }, 200);

            console.warn("3 stage ===>>>", props.setOpenSnackbar, props.snackbar)

        } else {
            props.setComissionConfig(dataset)
            props.close(false)
            props.setOpenSnackbar(false)
        }




    }





    useEffect(() => {
        setTerminateProcess(false)
        // props.setOpenSnackbar(false)
    }, [])






    const inputRef = useRef([]);


    // ------------- modal Close Function here -------- //
    function ModalClose() {
        props.close(false)
        // if (props.type === "eventInfo") {
        //     dispatch(clearViewData());
        // }
        // if (props.type === "materialInfo") {
        //     dispatch(clearViewMaterialData());
        // }
        // dispatch(clearPreviewData())
    }

    //   -------------- ------------------ //


    return (
        <>


            {/* ------- Loader goes Here ------- */}
            {loader && <LinearProgress sx={{ marginTop: '10px' }} />}
            {/* ---------------------------- */}


            <Modal className='modalblock affiliatemodal'
                style={style}
                open={props.open}
                onClose={() => props.close(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="BoxMui_modal  other_BoxMui_modal">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Configure commission for AE
                    </Typography>

                    <div className='SD_affiliate_cls'>
                        {
                            // packages.map((x, i) => {

                            packageDetails !== null && packageDetails.map((x, i) => {


                                // console.warn(" Value goes hereeeeee =======>>>>>>", x)


                                return (
                                    <>
                                        {commisionType[i] && commisionType[i] == "non_bps" && x.commission_percentage &&
                                            <div className='notifyMsg'>
                                                <p> Note: For {x.package_name} package Percentage should be less than {x.commission_percentage} </p>
                                            </div>}


                                        <div className='package_blocks'>


                                            {/*  ------- package name -------- */}
                                            <FormControl>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="Plan Type"
                                                    name={x.package_name}
                                                    disabled={true} variant="outlined"
                                                    defaultValue={x.package_name}
                                                    onChange={(e) => setPackageName(e.target.value)} />
                                            </FormControl>
                                            {/* ------------------------------ */}


                                            {/* --------------- package Price ---------- */}
                                            <FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount"> Package price</InputLabel>

                                                <OutlinedInput
                                                    id="outlined-basic"
                                                    label="Package price"
                                                    disabled={true}
                                                    name="package_price"
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    onChange={(e) => setPackagePrice(e.target.value)}
                                                    type="number"
                                                    variant="outlined"
                                                    defaultValue={x.package_price}
                                                />
                                            </FormControl>
                                            {/* ----------------------------------------- */}


                                            {/* ------------- AE Percentage ------------- */}
                                            {/* {x.commission_percentage !== null && x.commission_percentage !== undefined && (<FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">AE Commision Percentage </InputLabel>

                                                <OutlinedInput
                                                    id="outlined-basic"
                                                    label="AE Percentage"
                                                    disabled={true}
                                                    name="ae_percentage"
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                    onChange={(e) => setPackagePrice(e.target.value)}
                                                    type="number"
                                                    variant="outlined"
                                                    defaultValue={x.commission_percentage}
                                                />
                                            </FormControl>)} */}
                                            {/* ------------------------------------------- */}


                                            {/* ---------- AE Commision Amount ------------ */}
                                            {/* {x.commission_amount !== null && x.commission_amount !== undefined && (<FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">Base AE Commision Amount </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-basic"
                                                    label="AE Commision Amount "
                                                    disabled={true}
                                                    // name="ae"
                                                    onChange={(e) => setPackagePrice(e.target.value)}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    type="number"
                                                    variant="outlined"
                                                    defaultValue={x.commission_amount.toFixed(2)}
                                                />
                                            </FormControl>)} */}
                                            {/* ------------------------------------------- */}










                                            {/* --------------- Affiliate Commission Type ------------ */}
                                            {/* <FormControl>
                                                <InputLabel id="demo-simple-select-label">Commision Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={(commisionType !== null && commisionType[i.toString()] !== null) ? commisionType[i.toString()] : 'flat'}

                                                    name={'commission_type' + i}
                                                    label="Commision Type"

                                                    defaultValue={(defaultCommisionValue && defaultCommisionValue.length > 0 && defaultCommisionValue[i]) && defaultCommisionValue[i]}

                                                    onChange={(x) => {
                                                        let tempComissionVal = {}
                                                        tempComissionVal = { ...commisionType }
                                                        tempComissionVal[i.toString()] = x.target.value
                                                        setCommisionType(tempComissionVal)
                                                        setPercentage((prev) => ({ ...prev, [`${i}`]: undefined }))
                                             
                                                    }}

                                                >
                                                    <MenuItem value="non_bps">Percentage</MenuItem>
                                                    {/* <MenuItem value="bps">BPS</MenuItem> */}
                                            {/* <MenuItem value="flat">Flat</MenuItem> */}
                                            {/* 
                                                </Select>
                                            </FormControl>  */}
                                            {/* -------------------------------------------- */}



                                            {/* ---------- Affiliate Percentage ------------- */}
                                            {
                                                // commisionType[i] && commisionType[i] == "non_bps" &&
                                                // (<FormControl>
                                                //     <InputLabel htmlFor="outlined-adornment-amount"> Affiliate Percentage</InputLabel>

                                                //     <OutlinedInput
                                                //         id={i}
                                                //         label=" Affiliate Percentage"
                                                //         endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                //         name="percentage"
                                                //         value={percentage[`${i}`] ? Number(percentage[`${i}`]) : undefined}
                                                //         InputProps={{ inputProps: { min: 0 } }}
                                                //         defaultValue={(defaultCommissionPercentage && defaultCommissionPercentage.length > 0 && defaultCommissionPercentage[i]) && defaultCommissionPercentage[i]}

                                                //         onChange={(e) => {
                                                //             setPercentage((prev) => ({ ...prev, [`${i}`]: e.target.value }))
                                                //         }}
                                                //         type="number"
                                                //         variant="outlined"
                                                //     // helperText={percentage[`${i}`] && percentage[`${i}`] >= x.commission_percentage && `For Family package Percentage should be less than ${x.commission_percentage}`}
                                                //     />

                                                //     {x.commission_type === "non_bps" && percentage[`${i}`] && percentage[`${i}`] >= x.commission_percentage && (
                                                //         <FormHelperText error id="accountId-error">
                                                //             {percentage[`${i}`] && percentage[`${i}`] >= x.commission_percentage && `For ${x.package_name} package Percentage should be less than ${x.commission_percentage}`}
                                                //         </FormHelperText>

                                                //     )}

                                                // </FormControl>)
                                            }
                                            {/* --------------------------------------------- */}

                                            {console.warn("ccccccccccc =====>>>", commisionAmount[i])}

                                            {/* ---------------- Affiliate Commision Amount -------------- */}
                                            {/* {commisionType[i] && (commisionType[i] == "non_bps" || commisionType[i] == "flat") && */}

                                            <FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount"> AE Commission Amount</InputLabel>

                                                <OutlinedInput autoFocus
                                                    value={
                                                        percentage[i] &&
                                                        ((x.commission_amount * percentage[i]) / 100).toFixed(2)

                                                    }



                                                    // value={
                                                    //     commisionAmount!== undefined &&
                                                    //     commisionAmount

                                                    // }




                                                    defaultValue={(defaultCommissionAmount && defaultCommissionAmount?.length > 0 && defaultCommissionAmount[i]) && defaultCommissionAmount[i]}

                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1,
                                                            type: "text",
                                                            pattern: "[0-9]*"

                                                        }
                                                    }}

                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    InputLabelProps={{ shrink: percentage[i] || commisionType[i] == "flat" ? true : false }}
                                                    disabled={commisionType[i] == "non_bps"}
                                                    id="outlined-basic"
                                                    label="commission Amount"
                                                    name={`${x.package_name}_commision_amount`}
                                                    variant="outlined"
                                                    type="number"
                                                    // onChange={(e) => setPackageName(e.target.value)}
                                                    onChange={(e) => setCommisionAmount((prev) => ({ ...prev, [`${i}`]: e.target.value }))}

                                                    onkeydown={(e) => console.warn("e >>>", e.target.value)}

                                                />


                                                {/*  --- error msg ---  */}
                                                {
                                                    x.commission_type === "flat" && commisionAmount[i] && parseFloat(commisionAmount[i]) >= parseFloat(x.package_price) ?
                                                        (<FormHelperText error id="accountId-error">
                                                            {`For ${x.package_name} plan type AE commission amount should be less than $${(x.package_price)?.toFixed(2)}`}
                                                        </FormHelperText>)

                                                        :

                                                        x.commission_type === "flat" && (commisionAmount[i] && parseFloat(commisionAmount[i]) < 0) &&
                                                        (<FormHelperText error id="accountId-error">
                                                            {`For ${x.package_name} plan type AE commission amount should be less than $${(x.packagePrice)?.toFixed(2)}`}
                                                        </FormHelperText>)

                                                    //         :

                                                    // (x.commission_type === "flat" && percentage[`${i}`] !== undefined) && ((x.commission_amount * percentage[`${i}`] / 100).toFixed(2) > x.commission_amount) &&
                                                    //     (<FormHelperText error id="accountId-error">
                                                    //         {`For ${x.package_name} plan type affiliate commision amount should be less than ${(x.commission_amount).toFixed(2)}`}
                                                    //     </FormHelperText>)

                                                }
                                                {/*  -------- ----------  */}


                                            </FormControl>
                                            {/* } */}
                                            {/* ------------------------------------------------- */}










                                            {/* ---------- New AE Commision Amount ------------ */}
                                            {/* {commisionAmount[i] !== null && commisionAmount[i] !== undefined && ( */}
                                            {/* <FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">New AE Commision Amount </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-basic"
                                                    label="AE Commision Amount "
                                                    disabled={true}
                                                    // name="ae"
                                                    onChange={(e) => setPackagePrice(e.target.value)}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    type="float"
                                                    variant="outlined"
                                                    // value={
                                                    //     commisionType[i] && commisionType[i] == "flat" ? x.commission_amount - commisionAmount[i] : commisionType[i] && commisionType[i] == "non_bps" ? x.commission_amount - ((x.commission_amount * percentage[i]) / 100).toFixed(3) :  ""
                                                    // }
                                                    defaultValue=""

                                                    value={commisionAmount[i] ? (x.commission_amount - commisionAmount[i]).toFixed(2) : ''}
                                                />
                                            </FormControl> */}
                                            {/* )} */}
                                            {/* ------------------------------------------- */}




                                        </div>




                                    </>
                                );
                            })




                        }

                    </div>


                    <div className='btnSection'>
                        <Button variant="contained" onClick={submit}>Submit</Button>
                    </div>




                    <Tooltip title="Close" placement="right-start">
                        <IconButton className="modalCloseBtn" onClick={() => {
                            dataset = null;
                            ModalClose();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                </Box>

            </Modal>



        </>
    )
}

export default ConfigurePackagePartnerModal;









