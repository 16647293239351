import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton, LinearProgress, TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearTablerowData, notesBtnClicked, onSubmittingNote, resetNotesAddFormSubmissionState, resetNotesLoaded, setReloadTableFlag, updateTableReqBodyForNotes } from '../listReducer'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { timeConverter } from '../../helper/helperFunctions';
import ListingTable from '../listing';
import { cloneDeep } from 'lodash';

function NotesDialog({ tableId }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false)
  const [notesFor, setNotesFor] = useState("")
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false)


  const tableHeaders = useSelector((state) => state.tableSlice.noteFieldsData[tableId]?.headers ? state.tableSlice.noteFieldsData[tableId]['headers'] : [])
  const tableData = useSelector((state) => state.tableSlice.noteFieldsData[tableId]?.tableData ? state.tableSlice.noteFieldsData[tableId]['tableData'] : {})
  const notesForKey = useSelector((state) => state.tableSlice.noteFieldsData[tableId]?.notesForKey ? state.tableSlice.noteFieldsData[tableId]['notesForKey'] : "")
  const noteaddbody = useSelector((state) => state.tableSlice.noteFieldsData[tableId]?.noteaddbody ? state.tableSlice.noteFieldsData[tableId]['noteaddbody'] : {})
  const noteaddendpoint = useSelector((state) => state.tableSlice.noteFieldsData[tableId]?.noteaddendpoint ? state.tableSlice.noteFieldsData[tableId]['noteaddendpoint'] : {})
  const noteaddbaseurl = useSelector((state) => state.tableSlice.noteFieldsData[tableId]?.noteaddbaseurl ? state.tableSlice.noteFieldsData[tableId]['noteaddbaseurl'] : {})
  const notaddsubmissionstate = useSelector((state) => state.tableSlice.notesAddFormSubmissionState[tableId] ? state.tableSlice.notesAddFormSubmissionState[tableId] : 0)

  const tableDataSet = useSelector((state) => state.tableSlice.tableData[tableData.tableId])

  const notes = useSelector((state) => state.tableSlice.note[tableId] ? state.tableSlice.note[tableId] : [])
  const notesLoaded = useSelector((state) => state.tableSlice.notesLoaded ? state.tableSlice.notesLoaded : false)
  const rowData = useSelector((state) => state?.tableSlice?.tempRowData[tableId] ? state.tableSlice.tempRowData[tableId] : null)

  useEffect(() => {
    if (rowData && rowData[notesForKey]) {
      setNotesFor(rowData[notesForKey])
    }
  }, [rowData])

  useEffect(() => {
    if (notesLoaded === true) {
      setOpen(true)
      dispatch(resetNotesLoaded())
    }

    return () => {

    }
  }, [notesLoaded])

  const closeDialog = () => {
    setOpen(false)
    dispatch(clearTablerowData({ tableId }))
    dispatch(setReloadTableFlag({ tableId: tableId }))

  }


  const insertData = () => {
    let data;
    if (noteaddbody) {
      data = { ...cloneDeep(noteaddbody) }
    } else {
      data = { data: {} }
    }

    data.data.notes = value
    data.data.notes_for = rowData._id

    dispatch(onSubmittingNote({
      BASE_URL: noteaddbaseurl,
      endPoint: noteaddendpoint,
      reqBody: data,
      tableId
    }))

  }

  const updateNotesTableData = (reqbody) => {
    dispatch(updateTableReqBodyForNotes({ tableId: tableId, reqBody: reqbody }))
  }

  useEffect(() => {
    if (notaddsubmissionstate === 1) {
      // console.log("notaddsubmissionstate==========================>", notaddsubmissionstate)
      setLoading(true)
    }
    if (notaddsubmissionstate === 2) {
      // console.log("notaddsubmissionstate==========================>", notaddsubmissionstate)
      dispatch(setReloadTableFlag({ tableId: tableData.tableId }))
      setLoading(false)
      setValue('');
    }
    if (notaddsubmissionstate === 3) {
      // console.log("notaddsubmissionstate==========================>", notaddsubmissionstate)
      setLoading(false)
    }

    dispatch(resetNotesAddFormSubmissionState({ tableId: tableId }))
  }, [notaddsubmissionstate])

  useEffect(() => {
    // console.log("tableDataSet===================>", tableDataSet)
  }, [tableDataSet])

  return (
    <Dialog open={open} onClose={closeDialog} className='notes_details'>
      <IconButton title='Close' className='close_icon_btn' onClick={closeDialog}><Icon>close</Icon></IconButton>
      <DialogTitle className='notes_header'>Notes For:&nbsp;<div dangerouslySetInnerHTML={{ __html: ` ${notesFor}` }}></div></DialogTitle>
      <DialogContent className='content_container'>
        {notes && <div><ListingTable dataset={notes} modifyHeaders={tableHeaders} tableData={cloneDeep(tableData)} updateNotesTableData={updateNotesTableData} /></div>}


      </DialogContent>

      <DialogActions className='action_btn_container'>
        <TextareaAutosize
          className='textarea_add_note'
          placeholder="Type Here !!"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <div className='notes_btn_container'>
          <Button title="Submit" className='btn submit_btn' onClick={() => insertData()} disabled={!value} >Submit</Button>
          {/* <Button title="Cancel" className='btn close_btn' onClick={closeDialog}>Cancel</Button> */}
        </div>
      </DialogActions>
      {loading && <LinearProgress />}
    </Dialog>
  )
}

export default NotesDialog