import React, { useEffect, useState } from "react";
import {
  setEnableRowLoader,
  setReloadTableFlag,
} from "../../../listing/listReducer";

import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { clearAllDisposition, dispositionDataLoad, getDisposition, pharmacydispositionadd } from "./BlueCoastReducer";
import { timeConverter } from '../../../helper/helperFunctions';
import { Form } from "../../../form/Form";














const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ManageDispositionForPharmacyAspire = ({ paymentDisposition, setPaymentDisposition, rowData, setPaymentRowData, setForLoading,setPaymentAnnulBillingModalOpen }) => {

  const dispositionData = useSelector((state) => (state.BlueCoastSlice?.dispostionData?.disposition_last ? state.BlueCoastSlice?.dispostionData?.disposition_last : {}))

  const dispatch = useDispatch();

  const [dispositionSelectValueModal, setDispositionSelectValueModal] = useState(false);

  const [dispositionSucessLoader, setDispositionSucessLoader] = useState(false);



  const [manageDistModalOpen, setManageDistOpenModal] = useState(false)
  const [manageDist, setManageDist] = React.useState('New Lead');
  const [ipInfo, setIpInfo] = useState(null)

  const userInfo = useSelector((state) => (state.loginSlice && state.loginSlice?.userInfo) ? state.loginSlice?.userInfo : null)
  const dispositionDateTimeFormSubmission = useSelector((state) => state.formSlice?.formSubmissionState?.datetimeform1 ? state.formSlice.formSubmissionState.datetimeform1 : 0)

  const dispositioDateTimePicker = useSelector((state) => state.formSlice?.formData?.datetimeform1?.dateTimePicker ? state.formSlice.formData.datetimeform1 : null)





  const dateTimeForm = {
    id: 'datetimeform1',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: "Submit",
    // // submitBtnStartIcon: 'image_search_icon',
    // // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {},
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 32,
        heading: "Set Meeting Date",
        // label: "Set Meeting Date Time",  
        name: "dateTimePicker",
        className: 'dateTimePicker',
        type: 'datePicker',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          invalid: "Invalid Date Time Format",
          custom: "Value is Invalid"
        },
        sx: { m: 1, width: 300 },
        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
        defaultValue: new Date().getTime(), // Always use new Date().getTime() to convert into miliseconds 
      },
      {
        id: 32,
        heading: "Set Meeting Time",
        // label: "Set Meeting Date Time",  
        name: "time",
        className: 'dateTimePicker',
        type: 'timePicker',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          invalid: "Invalid Date Time Format",
          custom: "Value is Invalid"
        },
        sx: { m: 1, width: 300 },
        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
        defaultValue: new Date().getTime(), // Always use new Date().getTime() to convert into miliseconds 
      },

    ]

  };





 









  useEffect(() => {
    if (dispositionData && dispositionData.disposition_name) {
      setManageDist(dispositionData.disposition_name);
    }
  }, [dispositionData])




  const handleChange = async (event) => {
    // console.log("event.target.value", event.target.value);
    if (manageDist && event.target.value && event.target.value != manageDist) {
      setManageDist(event.target.value);
      if (event.target.value == 'Discovery Call Set' || event.target.value == 'Strategy Call' || event.target.value == 'Advisor/AE Meeting Set') {
        setDispositionSelectValueModal(true);
      } else {


        let dataSet = {
          "addedby": userInfo._id,
          "addedfor": rowData._id,
          "disposition_name": event.target.value,
          "action_added_time_human": timeConverter({
            'time': Math.round(new Date().getTime()),
            format: "MM/DD/YYYY",
          })
        }
        setDispositionSucessLoader(true)
        await dispatch(getDisposition(dataSet))
        setPaymentAnnulBillingModalOpen(false)
        dispatch(setSnackbar({ open: true, message: 'Disposition Updated Successfully', status: 'success' }))
        setTimeout(() => { setDispositionSucessLoader(false) }, 1000)
      }


    }


  };
  const callnewdisposition = async (val) => {
    // console.log("vall in date picker", val);
    var options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    let dataSet = {
      "addedby": userInfo._id,
      "addedfor": rowData._id,
      "disposition_name": manageDist,
      "action_added_time_human": timeConverter({
        'time': Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      }),
      'set_up_time': new Date(val.time).toLocaleTimeString('en-US', options),
      'set_up_date': val.dateTimePicker
    }

    setDispositionSucessLoader(true)
    await dispatch(getDisposition(dataSet))
    setPaymentAnnulBillingModalOpen(false)
    dispatch(setSnackbar({ open: true, message: 'Disposition Updated Successfully', status: 'success' }))
    setTimeout(() => { setDispositionSucessLoader(false) }, 1000)
  }
  useEffect(() => {
    // console.log("dispositionDateTimeFormSubmission", dispositionDateTimeFormSubmission, dispositioDateTimePicker);
    if (dispositioDateTimePicker && Object.keys(dispositioDateTimePicker).length > 0 && dispositionDateTimeFormSubmission == 4) {
      setDispositionSelectValueModal(false);
      callnewdisposition(dispositioDateTimePicker)
    }

  }, [dispositionDateTimeFormSubmission, JSON.stringify(dispositioDateTimePicker)])

  // const dispositioDateTimePicker = useSelector((state) => state.formSlice?.formFieldsData?.datetimeform1?.dateTimePicker ? state.formSlice.formFieldsData.datetimeform1.dateTimePicker : '')
  // const dispositionDateTimeFormSubmission = useSelector((state) => state.formSlice?.formSubmissionState?.datetimeform1 ? state.formSlice.formSubmissionState.datetimeform1 : 0)


  const handleCloseDistManageModal = () => {
    setPaymentDisposition(false);
    dispatch(clearAllDisposition())
    setForLoading(true)
    setPaymentRowData({})
  };

  const handleCloseDistSelectValueModal = () => {
    setDispositionSelectValueModal(false);

  };

  return (
    <>

      <Modal className='notesModal_blcoast All_Noteswrpr'
        open={paymentDisposition}
        onClose={handleCloseDistManageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal'>
          <Tooltip title='Close' >
            <IconButton onClick={handleCloseDistManageModal}
              // className="CloseModal"
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div>
            <h2 className='previewModalTitle '>
              {/* <h1>{JSON.stringify(dispositionData)}</h1> */}
              {<div className='modal_title modal_titlenew'> Manage Disposition for : {rowData.prospectfullname}</div>}
              {/* <h1>{JSON.stringify(ipInfo)}</h1> */}
            </h2>
            <div className='inputBlock inputBlock3line'>

              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Manage Disposition</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={manageDist}
                    label="Manage Disposition"
                    onChange={handleChange}

                  >
                    <MenuItem value={'New Lead'}>New Lead </MenuItem>
                    <MenuItem value={'Accepted'}>Accepted</MenuItem>
                    <MenuItem value={'Declined'}>Declined</MenuItem>
                    <MenuItem value={'Advisor/AE Contacted'}>Advisor/AE Contacted</MenuItem>
                    {/* <MenuItem value={'Strategy Call'}>Strategy Call</MenuItem> */}
                    <MenuItem value={'Advisor/AE Meeting Set'}>Advisor/AE Meeting Scheduled</MenuItem>
                    <MenuItem value={'Advisor/AE Meeting Completed'}>Advisor/AE Meeting Completed</MenuItem>
                    <MenuItem value={'Advisor/AE Meeting Missed'}>Advisor/AE Meeting Missed</MenuItem>
                    <MenuItem value={'Discovery Call Set'}>Discovery Call Scheduled</MenuItem>
                    <MenuItem value={'Discovery call completed'}>Discovery call completed</MenuItem>
                    <MenuItem value={'Discovery Call Cancelled'}>Discovery Call Cancelled</MenuItem>
                    <MenuItem value={'Discovery Call Missed'}>Discovery Call Missed</MenuItem>
                    <MenuItem value={'Contract Sent'}>Contract Sent</MenuItem>
                    <MenuItem value={'Contract Review Scheduled'}>Contract Review Scheduled</MenuItem>
                    <MenuItem value={'Contract Review Completed'}>Contract Review Completed</MenuItem>
                    <MenuItem value={'Contract Signed'}>Contract Signed</MenuItem>
                    {/* <MenuItem value={'Cold Lead'}>Cold Lead </MenuItem> */}
                    <MenuItem value={'Lost'}>Lost</MenuItem>

                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          {dispositionSucessLoader ? <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box> : ''}

        </Box>
      </Modal>


      {/* *********Modal For Disposition Selected Value******  */}

      <Modal
        className="modalBaseStyle setTime_Modal notesModal_blcoast"
        open={dispositionSelectValueModal}
        onClose={handleCloseDistSelectValueModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={handleCloseDistSelectValueModal}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <h2 className='previewModalTitle modal_title'>{manageDist}</h2>
          <div>
            <Form formData={dateTimeForm} />
            {/* <h1>{timeformeeting}</h1>
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <TimePicker label="Basic time picker"  onChange={(e) => {e!==null && settimeformeeting(new Date(e.toString()).toLocaleTimeString())}} 
                    renderInput={(params) => <TextField {...params} />}
                  />

                </LocalizationProvider> */}
          </div>
        </Box>
      </Modal>







    </>
  )
}

export default ManageDispositionForPharmacyAspire