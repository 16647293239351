import React, { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FolderIcon from '@mui/icons-material/Folder';
import { format } from 'date-fns'
import store from "../../../../store";

import LoginIcon from '@mui/icons-material/Login';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import { Button, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';
import { UserActivity } from './folderViewReducer';
import FollowUpcall from '../../LeadManagement/FollowUpcall';
import { useCookies } from 'react-cookie';
import { getDatafollowUpCallList } from '../../LeadManagement/LeadReducer';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AddBoxIcon from '@mui/icons-material/AddBox';


export const FollowUpCall = ({ followUpCallList, addedForName, addedFor, source,type }) => {
  const dispatch = useDispatch()
  //--------state define----------
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [dataCopy, setDataCopy] = useState({});
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [open, setOpen] = React.useState(false); // snackbar const
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [followUpModel, setFollowUpModel] = useState(false)//follow up model state
  const [followupRowdata, setFollowupRowdata] = useState({})
  const [btnType, setBtnType] = useState("")
  //---------------------------------------
  console.log("followUpCallList", followUpCallList)
  const loaderForFollowUp = useSelector(state => state.LeadReducer?.loaderForFollowUp ? state.LeadReducer.loaderForFollowUp : false)
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  // ----- FormSubmission Sucess ----//
  const submissionSuccess = useSelector(state => (state?.formSlice?.formSubmissionState?.followUpcallForm) ? state.formSlice.formSubmissionState.followUpcallForm : 0)
  const snackMsg = useSelector(state => (state?.formSlice?.message?.followUpcallForm) ? state.formSlice.message.followUpcallForm : null)
  // ------------------------------//

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

  // ------------search payload configration----------------------
  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];

      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];

      const payload = {
        "condition": {
          "limit": 20,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "added_for": addedFor ? addedFor : undefined,
          "added_by": searchData?.added_by ? searchData.added_by : undefined,
          "createdon_datetime": activity_time

        },
        "project": {},
        "token": "",
        "email": ""
      }
      activity_time.$gte === undefined && activity_time.$lte === undefined && delete payload.searchcondition["createdon_datetime"];

      dispatch(getDatafollowUpCallList(payload))
    }
  }, [submissionState])

  //////////////-----------------------------------------------------


  // ---- on succesfull follwupCall submison ----
  useEffect(() => {
    if (submissionSuccess == 2) {
      setOpen(true);
      setFollowUpModel(false);

      let FollowUpCallPaylod = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "added_for": addedFor ? addedFor : undefined
        },
        "count": false,
        "source": "",
      }
      // console.log("FollowUpCall");
      store.dispatch(getDatafollowUpCallList(FollowUpCallPaylod));


    }
  }, [submissionSuccess])
  // ---------------------------------



  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
    
  }, [snackMsg])



  // ------ handel Close ----------
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  // console.log(followUpCallList, "followUpCallList");
  const searchFields = [
    {
      id: 0,
      label: "Search By Added By",
      name: "added_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "users/follow-up-call-by",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {

          "added_for": addedFor ? addedFor : undefined
        },
        "project": {},
        "token": "",
        "email": ""
      },

    },
    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  const closeModal = () => {
    setFollowUpModel(false)
    setFollowupRowdata({})
  };


  //................configration for handleReschedule button------------

  const handleReschedule = (getdata) => {
    console.log("row data of follow up ", getdata)

    setFollowupRowdata(getdata)
    if (followupRowdata) {
      // setFollowUpModel(true)
      setBtnType("reschudle")
    }
  }

  useEffect(() => {
    if (Object.keys(followupRowdata).length > 0) {
      setFollowUpModel(true)

    }
  }, [followupRowdata])



  //=====================================================================

  //................configration for handleAddFollowup button------------


  const handleAddFollowup = (getdata) => {
    console.log("row data of follow up---- ", getdata)
    setFollowupRowdata(getdata)
    if (followupRowdata) {
      setFollowUpModel(true)
      setBtnType("addfollowup")
    }
  }



  return (
    <>

      <div>
        <h3>
          <LocalPhoneIcon /> Follow Up Call
          <div className="srchfld_wrp" style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>

            <Tooltip title="Search FollowUpCall">
              <IconButton onClick={() => setSearchDraw(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Add FollowUpCall">
              <IconButton onClick={() => setFollowUpModel(true)} sx={{ cursor: 'pointer' }}>
                <FollowTheSignsIcon />
              </IconButton>
            </Tooltip>

          </div>
        </h3>
        {loaderForFollowUp ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


        {followUpCallList.length > 0 ? followUpCallList.map((x, i) => (
          <div className='user-activity' key={i}>

            <ul className='ua-data-block'>
              {/* <li className='heading'>  {x.activity_type == 'login' ? (<LoginIcon/>) : x.activity_type == 'tag_assigned' ? (<LocalOfferIcon />) : x.activity_type == 'folder_accessed' || x.activity_type == 'folder_accessed_by' ? (<FolderIcon />) : ' '}    {x.activity_type ? x.activity_type : 'N/A'} </li>  */}
              {/* <li>Added By:  {format(x.added_by, 'dd-MM-yyyy HH:mm:ss')} </li> */}
              {/* <li>Added For:  {x.added_for_name} </li> */}
              <li>Follow Up Call Added By  {x.added_by_name} On {moment(x.date).format('MMMM Do YYYY')}, {x.start_time} | Notes:  {x.notes} </li>
              {/* <li>Date:   </li>
              <li>Time:   </li> */}
              {/* <li>Notes:  {x.notes} </li> */}
              {/* <li>Activity: {x.activity}</li>  */}
              {x.added_by === userInfo.userinfo._id &&
                (<li>
                  <Tooltip title="Reschedule" placement="right-start">
                    <EventRepeatIcon sx={{ cursor: 'pointer' }} onClick={() => handleReschedule(x)} />
                  </Tooltip>

                  <Tooltip title="Create New Call" placement="right-start">
                    <AddBoxIcon sx={{ cursor: 'pointer' }} onClick={() => handleAddFollowup(x)} />
                  </Tooltip>
                </li>)}

            </ul>

          </div>)) : <p> NO FOLLOW UP CALL ADDED!</p>}

      </div>




      {open ? (<Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackMsg}

      />) : ""}

      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type="follow_up_call " /> : ""}



      {console.warn("followupRowdata 2222 =======>>>>", followupRowdata)}

      {followUpModel ? <FollowUpcall
        open={followUpModel}
        close={closeModal}
        addedById={userInfo.userinfo._id}
        addedForId={addedFor}
        addedForNmae={addedForName}
        listData={followUpCallList}
        listFlag={"folderview"}
        loading={loaderForFollowUp}
        source={source}
        followupGridData={followupRowdata}
        typeBtn={btnType}
        collectionName={type==="lead"?"lead":"user"}
      /> : ""}


    </>
  )
}
