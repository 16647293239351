import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getusersList } from "../../userManagement/userList/userListReducer";
import ListingTable from "../../../../listing/listing";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import { resolvefunction } from "../../../../helper/helperFunctions";
import { setEnableRowLoader } from "../../../../listing/listReducer";
import { fetchUserData } from "../../userManagement/fetchUserDataReducer";
import PopupModal from "../../../../commoncomponents/modal/popupmodal";
import PreviewModal from "./PreviewModal";

const ManageUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//
  const [popupToogle, setPopupToogle] = useState(false); //--- Toogle ----//
  const [comitionModal, setComitionModal] = useState(false);
  const [popupData, setPopupData] = useState([]); //--- popupData ----//
  const [previewOpen, setPreviewOpen] = useState(false);

  const loadinglist = useSelector((state) =>
    state.userManagment?.loadinglist ? state.userManagment.loadinglist : false
  );
  const users = useSelector((state) => state.userManagment?.usersList);
  const usersToken = "ssdsd";
  let mindate = useSelector((state) =>
    state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
  );
  let maxDate = useSelector((state) =>
    state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
  );

  // ----- On DataRef on every btn trigger ----
  const dataRef = useSelector((state) =>
    state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1
      ? state.tableSlice?.tempRowData?.userslist1
      : {}
  );

  // ----- On editBtn trigger ----
  const editBtn = useSelector((state) =>
    state.tableSlice.customButtonClicked?.userslist1 &&
    state.tableSlice.customButtonClicked?.userslist1?.edittable9
      ? state.tableSlice.customButtonClicked.userslist1.edittable9
      : false
  );
  // ----- On viewBtnAction trigger ----
  const viewBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.userslist1 &&
    state.tableSlice?.customButtonClicked?.userslist1?.viewBtnAction
      ? state.tableSlice?.customButtonClicked?.userslist1?.viewBtnAction
      : false
  );

  /*****************************For Resolve*********************** */
  const resolved = useSelector((state) =>
    state.ResolveReducer?.resolved["/manage-user"]
      ? state.ResolveReducer.resolved["/manage-user"]
      : false
  );

  useEffect(() => {
    if (!resolved) {
      dispatch(getusersList(tableDataForUsers.current.reqBody));
    }
  }, []);

  // --- on AddUser button click drawer open ---
  useEffect(() => {
    if (editBtn === true) {
      resolvefunction(navigate, "/newuseraddedit?id=" + dataRef._id);
    }
  }, [editBtn]);

  // ------ viewBtn triigred -------
  useEffect(() => {
    if (viewBtn === true) {
      setDataCopy(dataRef);
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      // dispatch(fetchUserData(dataRef._id)); //-- fetchUserData endpoint call from this func --
      setPreviewOpen(true);
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: false,
        })
      );
    }
  }, [viewBtn]);

  // ----- Table list for user -----
  var modifyTableHeaders = useRef([
    { val: "name", name: "Name" },
    { val: "email", name: "E-mail" },
    { val: "phone", name: "Contact" },
    { val: "tag_names", name: "Tags" },
    { val: "stage_name", name: "Stage" },
    { val: "landing_page_name", name: "Source" },
    { val: "address", name: "Address" },
    { val: "training_percentage_html", name: "Training Complete (%)" },
    { val: "last_activity", name: "Last Activity" },
    { val: "roles_string", name: "Type" },
    { val: "lead_count", name: "Lead Count" },
    { val: "agent_code", name: "Agent Code" },
    // {
    //   val: "status",
    //   customVal:
    //     props.source === "employer_listing"
    //       ? { 1: true, 0: false }
    //       : { 1: "Active", 0: "Inactive" },
    //   name: "Status",
    // },
    {
      val: "createdon_datetime",
      name: "Joined On",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
    {
      val: "last_login_time",
      name: "Last Login",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
    { val: "associated_bdd__hm", name: "Associate BDD/HM" },
    {
      val: "updatedon_datetime",
      name: "Updated On",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
  ]);

  let tableDataForUsers = useRef({
    tableId: "userslist1",
    tableTitle: "Users Listing",
    enableRowActionLoader: true,
    showReload: false,
    showFilter: true,
    adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/user-list-or-count",
      // deleteSingleUserEndpoint: "api/delete-user",
      tableCountEndpoint: "users/user-list-or-count",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},

      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: usersToken,
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: usersToken,
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: [
      "name",
      "type",
      "login_time",
      "createdon_datetime",
      "status",
      "email",
    ],
    paginationType: "stackPagination",
    // customSort: true,
    customBtnHead: [
      {
        id: "multipalCampainAssecc",
        type: "perm_data_setting_icon",
        title: "Campaign Access",
      },
      {
        id: "editMultiple",
        type: "toggle_on_icon",
        title: "Status Change",
      },

      {
        id: "sendMail_multiple",
        title: "Bulk Email",
        type: "forward_to_inbox_two_tone_icon",
        className: "forward_to_inbox_two_tone_icon",
      },
    ],
    buttons: [
      {
        id: "edittable9",
        type: "other",
        icon_type: "edit",
        label: "Edit",
        name: "edit",
      },

      {
        id: "viewBtnAction",
        type: "other",
        name: "preview",
        label: "Preview",
      },
    ],
    deleteModal: {
      modalClassName: "Delete Modal Deletemodal",
    },

    searchData: {
      heading: "Search User",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        resetBtnName: "REFRESH",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Name",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "name",
              },
              searchcondition: {},
              type: "user_name",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 1,
            label: "Search By Email",
            name: "email",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 6,
            label: "Search By Stage",
            name: "stage",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "dashboard-cards/auto-complete-users-stage",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                // createdon_datetime: {
                //   $gte: mindate,
                //   $lte: maxDate,
                // },
              },
              type: "user_stage",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "latest_stage.stage_slug" },
          },

          {
            id: 7,
            label: "Search By Tag",
            name: "tag",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "dashboard-cards/auto-complete-users-tag",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_tag",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "latest_tag.tag_slug" },
          },

          {
            id: 8,
            label: "Search by User Types",
            name: "user_type",
            type: "select",
            multiple: true,
            checkbox: true,
            values: [],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            payloadFormat: { key: "user_type", param: "$in", options: "i" },
          },

          {
            id: 9,
            label: "Search by Source",
            name: "page_id",
            type: "autoComplete",
            multiple: true,
            values: [],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "users/autocomplete-pageid",
            reqBody: {
              condition: {
                limit: 30,
                skip: 0,
              },
              sort: {
                field: "createdon_datetime",
                type: "desc",
              },
              searchcondition: {
                // "rep_only": 1
              },
              project: {},
            },
            payloadFormat: { key: "page_id", param: "$in" },
          },

          {
            id: 10,
            label: "Search by Sign-up Source",
            name: "signup_form",
            type: "select",
            multiple: true,
            checkbox: true,
            values: [
              { val: "aspire", name: "Aspire" },
              { val: "bluecoast", name: "Bluecoast" },
              { val: "naka", name: "NAKA Games" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            payloadFormat: { key: "signup_form", param: "$in", options: "i" },
          },

          {
            id: 11,
            label: "Search by Agent Code",
            name: "agent_code",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/autocomplete-agent-code",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                // createdon_datetime: {
                //   $gte: mindate,
                //   $lte: maxDate,
                // },
              },
              // "type": "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 12,
            label: "Search by Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "inputblock2line",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 13,
            label: "Search by End Date",
            name: "end_date",
            className: "inputblock2line",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  return (
    <>
      <div className="bluecoastLead_list_mainwrpr">
        <div className="rolelist_mainwrp userMnageMnetBcl">
          <div className="listing_heading">
            <h1>User Management</h1>
            <Tooltip title="ADD USER">
              <button
                className="addrole_btn"
                onClick={() => navigate("/newuseraddedit")}
              >
                <AddIcon />
              </button>
            </Tooltip>
          </div>
          <div className="userlisttable">
            <div className="reactTableWrapper">
              <div className="adminlisttable_v2 table_Icons ">
                {!loadinglist && users ? (
                  <ListingTable
                    tableData={tableDataForUsers.current}
                    dataset={users}
                    modifyHeaders={modifyTableHeaders.current}
                  />
                ) : (
                  <TableSkeleton count={5} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------- Popup Modal for preview user details ------------ */}
      {/* {popupToogle && comitionModal === false ? (
        <PopupModal
          popupdata={popupData}
          popuptoogle={popupToogle}
          popuptooglefunc={setPopupToogle}
          type="profileInfo"
        />
      ) : (
        ""
      )} */}

      {previewOpen ? (
        <PreviewModal
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ManageUser;
