import { configureStore } from "@reduxjs/toolkit";
import tableSlice from "./listing/listReducer"

import formSlice from './form/formReducer'
import searchSlice from './search/searchReducer'
import fileUploaderSlice from './form/fileUploaderReducer'

import ResolveReducer from "./helper/ResolveReducer";
import TrainingReducer from "./Pages/Backend/Training/TrainingReducer";

import RoleReducer from "./Pages/Backend/Role/RoleReducer";

import fetchUserDataReducer from "./Pages/Backend/userManagement/fetchUserDataReducer";

import userManagment from "./Pages/Backend/userManagement/userList/userListReducer";
import LandingPageReducer from "./Pages/Backend/LandingPage/LandingPageReducer";

import loginSlice from "./Pages/login/loginReducer"

import layoutSlice from "./Pages/Layout/layoutReducer"
import TrainingCenterCategoryReducer from "./Pages/Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer";
import contractReducer from "./Pages/Backend/ContractManagement/ContractReducer"
import ProgramReducer from "./Pages/Backend/Program/ProgramReducer";
import LeadReducer from "./Pages/Backend/LeadManagement/LeadReducer";
import TrainingMaterialReducer from "./Pages/Backend/Training/TrainingMaterial/TrainingMaterialReducer"
import JobTicketReducer from "./Pages/Backend/Jobtickets/JobTicketReducer";
import camPaignManagementReducer from "./Pages/CampaignManagement/CampaignReducer";
import calenderEvents from './Pages/Calendar/calenderReducer';
import EmailTemplateReducer from "./Pages/Backend/EmailTemplateManagement/EmailTemplateReducer";
import TagReducer from "./Pages/Backend/TagManagement/TagReducer";
import FormManagementReducer from "./Pages/Backend/FormManagement/FormManagementReducer";
import StageReducer from "./Pages/Backend/StageDistribution/StageReducer";
import DashboardReducer from "./Pages/Backend/Dashboard/DashboardReducer";
import HmDashboardReducer from "./Pages/Backend/HmDashboard/HmDashboardReducer";
import ManageRoundRodinReducer from "./Pages/Backend/ManageRoundRobin/ManageRoundRobinReducer"
import userFolderViewReducer from "./Pages/Backend/userManagement/folderView/folderViewReducer"
import TriggerReducer from "./Pages/Backend/EmailTemplateManagement/TriggerReducer";
import LandingpagefontReducer from "./Pages/Frontend/LandingPage/LandingpageReducer";
import AerDashboardReducer from "./Pages/Backend/AerDashboard/AerDashboardReducer";
import RepDashboardReducer from "./Pages/Backend/RepDashboard/RepDashboardReducer";
import BDDdashBoardReducer from "./Pages/Backend/BDDdashboard/BDDdashBoardReducer";
import InformationReducer from "./Pages/Backend/InformationlinkModule/InformationReducer";
import DashbordListReducer from "./Pages/Backend/Dashboard/DashbordListReducer";
import HmDashboardListReducer from "./Pages/Backend/HmDashboard/HmDashboardListReducer";
import FaqReducer from "./Pages/Backend/FaqManagement/FaqReducer";
import HmGroupReducer from "./Pages/Backend/HmGroup/HmGroupReducer";
import BlueCoastReducer from "./Pages/Backend/BlueCoast/BlueCoastReducer"
import commissionReducer from "./Pages/Backend/Commission/commissionReducer";
import MedicineCategoryReducer from "./Pages/Backend/MedicineCategory/MedicineCategoryReducer";
import MedicineReducer from "./Pages/Backend/Medicine/MedicineReducer";
import AffiliateReducer from "./Pages/Backend/AffiliateManagement/AffiliateReducer";
import W9FormReducer from "./Pages/Backend/AffiliateManagement/W9FormReducer";
import PartnersReducer from "./Pages/Backend/Partners/PartnersReducer";
import AfffiliateConverisonReportReducer from "./Pages/Backend/AffiliateManagement/AffiliateConversionReport/AfffiliateConverisonReportReducer";
import EmployerReducer from "./Pages/Backend/EmployerManagement/EmployerReducer";


export default configureStore({
    reducer: {

        formSlice: formSlice,
        tableSlice: tableSlice,
        searchSlice: searchSlice,
        fileUploaderSlice,
        trainingCenterCategorySlice: TrainingCenterCategoryReducer,
        RoleReducer: RoleReducer,
        ResolveReducer: ResolveReducer,
        trainingSlice: TrainingReducer,
        LandingPageReducer: LandingPageReducer,
        loginSlice: loginSlice,
        fetchUserDataReducer: fetchUserDataReducer,
        layoutSlice: layoutSlice,
        userManagment: userManagment,
        contractReducer: contractReducer,
        ProgramReducer: ProgramReducer,
        LeadReducer: LeadReducer,
        trainingMaterialReducer: TrainingMaterialReducer,
        JobTicketReducer: JobTicketReducer,
        camPaignManagementReducer: camPaignManagementReducer,
        calenderEventsStore: calenderEvents,
        EmailTemplateReducer: EmailTemplateReducer,
        tagEditAddRedicer: TagReducer,
        FormManagementSlice: FormManagementReducer,
        StageReducer: StageReducer,
        DashboardReducer: DashboardReducer,
        HmDashboardReducer: HmDashboardReducer,
        ManageRoundRodinReducer: ManageRoundRodinReducer,
        userFolderViewReducer: userFolderViewReducer,
        TriggerReducer: TriggerReducer,
        LandingpagefontReducer: LandingpagefontReducer,
        AerDashboardReducer: AerDashboardReducer,
        RepDashboardReducer: RepDashboardReducer,
        BddDashboardReducer: BDDdashBoardReducer,
        informationReducer: InformationReducer,
        DashboardListReducer: DashbordListReducer,
        HmDashboardListReducer: HmDashboardListReducer,
        FaqReducer: FaqReducer,
        HmGroupReducer: HmGroupReducer,
        BlueCoastSlice: BlueCoastReducer,
        commissionReducer:commissionReducer,
        medicineCategorySlice:MedicineCategoryReducer,
        medicineSlice:MedicineReducer,
        AffiliateReducer:AffiliateReducer,
        W9FormReducer: W9FormReducer,
        PartnersReducer:PartnersReducer,
        AfffiliateConverisonReport : AfffiliateConverisonReportReducer,
        EmployerReducer:EmployerReducer
    },
});