import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../../listing/listing'
import { TableSkeleton } from '../../../../../helper/TableSkeleton'
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateLocation, clearLocatioTable, locationStatusUpdate, viewlocationsListData } from '../../EmployerReducer';
import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip, Typography } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import { Box } from '@mui/system';
import { Form } from '../../../../../form/Form';
import CloseIcon from "@mui/icons-material/Close";
import store from '../../../../../store';
import { cloneDeep } from 'lodash';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import { setEnableRowLoader, setReloadTableFlag } from '../../../../../listing/listReducer';
import { FifteenMp } from '@mui/icons-material';
import MultiStatusChangeModal from '../../../../../commoncomponents/modal/multipleStatusChnageModal';
import StatusChangeModal from './StatusChangeModal';
import DeleteModalLocation from './DeleteModalLocation';
import StateArray from '../../../../../assets/json/state';
import PreviewLocation from './PreviewLocation';
import AddNotesLocation from './AddNotesLocation';



function ViewLocations() {



    const dispatch = useDispatch()

    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});


    const locationListDataSet = useSelector((state) => state.EmployerReducer?.locationsListData ? state.EmployerReducer.locationsListData : null)

    const locationLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)

    const locationAddLoader = useSelector((state) => state.EmployerReducer?.locationAddLoader ? state.EmployerReducer.locationAddLoader : null)


    const rowData = useSelector((state) => state.tableSlice?.tempRowData?.tableForLocationData ? state.tableSlice.tempRowData.tableForLocationData : {});

    const locationEditData = useSelector((state) => state.tableSlice.tempRowData.tableForLocationData && Object.keys(state.tableSlice.tempRowData.tableForLocationData).length > 0 ? state.tableSlice.tempRowData.tableForLocationData : {})

    const locationFormData = useSelector((state) => state.formSlice.formData.locationTable ? state.formSlice.formData.locationTable : null);

    const locationDataSubmissionState = useSelector(state => (state.formSlice?.formSubmissionState?.locationTable) ? state.formSlice?.formSubmissionState?.locationTable : 0);

    const editLocationClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForLocationData?.editLocationid ? state.tableSlice.customButtonClicked.tableForLocationData.editLocationid : false)

    const previewLocationClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForLocationData?.previewLocation ? state.tableSlice.customButtonClicked.tableForLocationData.previewLocation : false)



    const successMessageLocation = useSelector(state => state.EmployerReducer?.locationMessageSuccess ? state.EmployerReducer.locationMessageSuccess : null);

    const resposnseMessageLocation = useSelector(state => state.EmployerReducer?.successLocation ? state.EmployerReducer.successLocation : null);


    // const locationMessageError = useSelector(state => state.EmployerReducer?.locationMessageError ? state.EmployerReducer.locationMessageError : null);

    const [openSnackbar, setOpenSnackbar] = useState(false)

    useEffect(() => {
        // console.log("locationListDataSet=======>>>>", locationListDataSet)
    }, [locationListDataSet])


    // =====================================location add error=====================
    const locationAddError = useSelector(state => state.EmployerReducer?.addLocationError ? state.EmployerReducer.addLocationError : null);

    // console.log(">>>>>>>>>>>>>>>>========", locationAddError)

    // ================================Notes add=====================================
    const notesLocationClick = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForLocationData?.notesLocation ? state.tableSlice.customButtonClicked.tableForLocationData.notesLocation : null)



    // ===========================status change button click============================

    const statusChangeClick = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForLocationData?.change_status1 ? state.tableSlice.customButtonClicked.tableForLocationData.change_status1 : null)

    const [statusModalOpen, setStatusModalOpen] = useState(false)


    // ===============================Location Delete Clicke============================

    const deleteClick = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForLocationData?.deleteLocation ? state.tableSlice.customButtonClicked.tableForLocationData.deleteLocation : null)

    const [deleteOpen, setDeleteOpen] = useState(false)


    useEffect(() => {
        // console.log("rowData====>>>>", rowData, logedUserData);

    }, [rowData, logedUserData])


    /*******************For Resolve****** */
    const resolved = useSelector((state) =>
        state.ResolveReducer?.resolved["/view-location-list"]
            ? state.ResolveReducer.resolved["/view-location-list"]
            : false
    );

    useEffect(() => {
        if (!resolved) {
            dispatch(viewlocationsListData(tableDataforLocationList.current.reqBody))
        }
    }, [])

    // ===============================Add notes Location================================
    const [notesOpen, setNotesOpen] = useState(false)

    useEffect(() => {
        if (notesLocationClick === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForLocationData",
                    rowId: rowData._id,
                    load: true,
                })
            );
            setNotesOpen(true)

            setTimeout(() => {

                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForLocationData",
                        rowId: rowData._id,
                        load: false,
                    })
                );
            }, 2000);
        }
    }, [notesLocationClick])



    // ========================status change button clicked==========================

    // useEffect(() => {
    //     setTempDataSet(rowData)
    // }, [JSON.stringify(rowData)])

    const [tempDataSet, setTempDataSet] = useState(null)



    useEffect(() => {
        if (statusChangeClick === true && rowData !== null) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForLocationData",
                    rowId: rowData._id,
                    load: true,
                })
            );


            // console.log("statusChangeClick=============>>>", statusChangeClick)
            setTempDataSet(rowData)
            // console.log("tempDataSet=======>>>>", tempDataSet)
            setStatusModalOpen(true)

            setTimeout(() => {

                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForLocationData",
                        rowId: rowData._id,
                        load: false,
                    })
                );
            }, 2000);


        }
    }, [statusChangeClick, JSON.stringify(rowData)])

    // ===============================Delete location modal Clicked=========================
    useEffect(() => {
        if (deleteClick === true) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForLocationData",
                    rowId: rowData._id,
                    load: true,
                })
            );
            setDeleteOpen(true)

            setTimeout(() => {

                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForLocationData",
                        rowId: rowData._id,
                        load: false,
                    })
                );
            }, 2000);
        }
    }, [deleteClick])


    // ====================main table header===============
    var modifyTableHeadersForLocations =
        [
            { val: "location_name", name: "Location Name" },
            // { val: "description", name: "Location Detail" },
            { val: "address", name: "Address" },
            { val: "city", name: "City" },
            { val: "state", name: "State" },
            { val: "zip", name: "Zip" },
            {
                val: "status",
                name: "Status",
                // customVal: { false: "Inactive", true: "Active" }
                customVal: { 0: "Inactive", 1: "Active" },

            },
            { val: "createdon_datetime", name: "Updation Time", type: "datetime", format: "MM/DD/YYYY" },
        ];

    // ======================main table for location lsit==================

    let tableDataforLocationList = useRef({
        tableId: "tableForLocationData",
        tableTitle: "Location Lists",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "employer_manage/managelocationlist",
            // deleteSingleUserEndpoint: "training-manage/delete-training",
            tableCountEndpoint: "employer_manage/managelocationlist",
        },

        reqBody: {

            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                employer_id: logedUserData?._id
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [

            {
                id: "editLocationid",
                type: "other",
                icon_type: "edit_icon",
                label: "Edit",
                name: "edit",
            },

            {
                id: "previewLocation",
                label: "Preview",
                type: "other",
                icon_type: "preview",
                name: "preview",
            },

            // {
            //     id: "change_status1",
            //     type: "other",
            //     label: "Change Status",
            //     name: "toggle_on_icon",
            //     condField: "inactive",
            //     condVal: 2,
            // },

            {
                id: "change_status1",
                type: "other",
                label: "Change Status",
                name: "toggle_on_icon",
            },

            // {
            //     id: "notesLocation",
            //     label: "Notes",
            //     type: "other",
            //     icon_type: "newspaper_icon",
            //     name: "newspaper_icon",
            //     showcount: true,
            //     countFiled: "notes_count",
            // },

            {
                id: "deleteLocation",
                label: "Delete",
                type: "other",
                name: "delete_icon",
            },


        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },


        searchData: {
            heading: " Search Locations",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [


                    {
                        id: 0,
                        label: "Search By Location Name",
                        name: "name",
                        type: "autoComplete",
                        // multiple: true,
                        preload: false,
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL + "employer_manage/location_name_autocomplete",
                        reqBody: {
                            condition: {
                                limit: 30,
                                skip: 0,
                            },
                            sort: {
                                type: "asc",
                                field: "createdon_datetime",
                            },
                            searchcondition: {
                                employer_id: logedUserData?._id,
                                label: {
                                    $regex: "",
                                    $options: "i"
                                }
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },
                    // {
                    //     id: 1,
                    //     label: "Search By Description",
                    //     name: "email",
                    //     type: "autoComplete",
                    //     className: "inputblock2line",
                    //     base_url:
                    //         process.env.REACT_APP_API_URL + "employer_manage/location_name_autocomplete",
                    //     reqBody: {
                    //         condition: { limit: 10, skip: 0 },
                    //         sort: { type: "desc", field: "email" },
                    //         searchcondition: {
                    //             employer_id: logedUserData?._id,
                    //             label: {
                    //                 $regex: "",
                    //                 $options: "i"
                    //             }
                    //         },
                    //     },
                    //     payloadFormat: { key: "_id" },
                    // },


                    {
                        id: 3,
                        label: "Search by Status",
                        name: "status",
                        type: "select",
                        // multiple: false,
                        values: [
                            { val: 1, name: "Active" },
                            { val: 0, name: "Inactive" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "status" },
                    },



                    {
                        id: 8,
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker list_search_item countDiv__column--col6',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                ]
            }
        },

    });

    // =============================location edit funtionality=================

    const [editLocation, setEditLocation] = useState(null)

    // ============================add location function======================

    const addLocationDetails = async (val) => {
        // console.log("val>>>", val);
        val = { ...val };

        let body = {
            location_name: val.location_name.trim(),
            address: val.address.trim(),
            city: val.city.trim(),
            state: val.state.trim(),
            zip: val.zip.trim(),
            // description: val.description.trim(),
            createdon_datetime: new Date().getTime(),
            employer_id: logedUserData?._id,
            // status: true,
            status: val.status,
            // status: val.status === 1 ? true : val.status === 6 ? false : null,



            _id:
                editLocation && Object.keys(editLocation).length > 0
                    ? editLocation._id
                    : undefined,


        };

        // console.log("body==========>>>>", val.status)



        if (Object.keys(body).length > 0) {

            await store.dispatch(addUpdateLocation(body));

            for (let k in formLocationDetails.fields) {

                let field = cloneDeep(formLocationDetails.fields[k]);
                field.defaultValue = undefined;
                formLocationDetails.fields[k] = field;
            }


     

      
            setOpenSnackbar(true)

 



            dispatch(viewlocationsListData(tableDataforLocationList.current.reqBody))
            // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }));
        }

    };

    useEffect(() => {

        if (resposnseMessageLocation === 2 && locationAddError == 'success') {
            // console.log("testResposne======>>>>>", resposnseMessageLocation)
            setTimeout(() => {
                handleCloseLocationModal()
            }, 1000)
        }

    }, [resposnseMessageLocation, locationAddError])

 






    useEffect(() => {
        if (locationEditData && Object.keys(locationEditData).length > 0) {
            setEditLocation(locationEditData)

        }
    }, [JSON.stringify(locationEditData)])

    useEffect(() => {
        // console.log("editLocation============>>>>", editLocation)
    }, [editLocation])





    // ===========================edit location button clicked========================

    useEffect(() => {
        if (editLocationClicked && locationEditData && Object.keys(locationEditData).length > 0) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForLocationData",
                    rowId: rowData._id,
                    load: true,
                })
            );
            // setEditLocation(locationEditData)
            // formLocationDetails.fields[29].disabled = false

            for (let k in formLocationDetails.fields) {

                // if (locationEditData[formLocationDetails.fields[k].name]) {
                formLocationDetails.fields[k].defaultValue =
                    locationEditData[formLocationDetails.fields[k].name];
                // console.log("testHittsss======>>>", locationEditData[formLocationDetails.fields[k].name])
                // }
            }




            setTimeout(() => {
                setLocationMOdalOpen(true)
            }, 1000);

            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForLocationData",
                        rowId: rowData._id,
                        load: false,
                    })
                );
            }, 1000);


        }
    }, [editLocationClicked, JSON.stringify(locationEditData)])


    // =============================preview location clicked==================

    const [previewOpen, setPreviewOpen] = useState(false)

    useEffect(() => {
        if (previewLocationClicked == true) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForLocationData",
                    rowId: rowData._id,
                    load: true,
                })
            );
            setPreviewOpen(true)

            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForLocationData",
                        rowId: rowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [previewLocationClicked])



    // console.log("locationEditData============>", locationEditData);

    // ========================add form for locations======================

    const formLocationDetails = {
        id: 'locationTable',
        formtype: "add",
        endPointBodyWrapper: "",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "Formsubmit_button SubmitEmploy",
        submitBtnName: editLocation !== null ? "Update" : "Submit",
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {


            // _id: (locationEditData._id !== undefined && locationEditData._id !== null) ? locationEditData._id : undefined,
        },

        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                heading: "Location Name",
                label: "Location Name",
                name: "location_name",
                className: 'form_item formfild inputBlock inputBlock2line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: editLocation && Object.keys(editLocation).length > 0 && editLocation?.location_name !== null && editLocation?.location_name !== undefined ? editLocation.location_name : undefined,

            },

            // {
            //     id: 29,
            //     heading: "Status",
            //     label: "Select Status",
            //     name: "status",
            //     className: 'form_item formfild inputBlock inputBlock2line',
            //     type: 'select',
            //     multiple: false,
            //     checkbox: true,
            //     disabled: false,
            //     values: [
            //         { val: 1, name: "Active" },
            //         { val: 6, name: "Inactive" },
            //     ],
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required"
            //     },
            //     // defaultValue: editLocation && Object.keys(editLocation).length>0 && editLocation?.status !== null && editLocation?.status !== undefined ? editLocation.staus : null,
            //     defaultValue: editLocation?.status == "Active" ? 1 : editLocation?.status == "Inactive" ? 6 : null,
            // },



            {
                id: 20,
                heading: "Location City",
                label: "Location City",
                name: "city",
                className: 'form_item formfild inputBlock inputBlock2line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: editLocation && Object.keys(editLocation).length > 0 && editLocation?.city !== null && editLocation?.city !== undefined ? editLocation.city : undefined,

            },

            {
                id: 15,
                // heading: "Address",
                label: "Address",
                name: "address",
                className: 'form_item form_item inputBlock inputBlock1line',
                type: "textarea",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: editLocation && Object.keys(editLocation).length > 0 && editLocation?.address !== null && editLocation?.address !== undefined ? editLocation.address : undefined,

            },

            {
                id: 6,
                heading: "State",
                label: "State",
                name: "state",
                className: 'form_item formfild inputBlock inputBlock2line',
                type: "select",
                values: StateArray,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: editLocation && Object.keys(editLocation).length > 0 && editLocation?.state !== null && editLocation?.state !== undefined ? editLocation.state : undefined,

            },

            {
                id: 7,
                heading: "Zip",
                label: "Zip",
                name: "zip",
                className: 'form_item formfild inputBlock inputBlock2line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: editLocation && Object.keys(editLocation).length > 0 && editLocation?.zip !== null && editLocation?.zip !== undefined ? editLocation.zip : undefined,

            },

            {
                id: 13,
                // heading: "Status",
                name: "status",
                className: "form_item form_item   statusCheck",
                type: 'singleCheckbox',
                values: { key: 0, val: 'Active' },
                defaultValue: (editLocation && Object.keys(editLocation).length > 0 && editLocation.status !== undefined && editLocation.status == "Active") ? true : false,
            },



            // {
            //     id: 15,
            //     heading: "Location Detail",
            //     label: "Location Detail",
            //     name: "description",
            //     className: 'formfild formfild4 dispField taskLabel',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },
            // },

        ]

    };


    const [locationMOdalOpen, setLocationMOdalOpen] = useState(false)


    const addLocationModal = () => {
        setEditLocation(null)

        setLocationMOdalOpen(true)
    }

    useEffect(() => {
        if (locationDataSubmissionState && locationDataSubmissionState === 4) {

            // console.log("submission===========>>>>>", locationDataSubmissionState)

            addLocationDetails(locationFormData);

            // console.log("locationFormData================>>>>", locationFormData)

            // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }))

        }
    }, [locationDataSubmissionState]);

    const handleCloseLocationModal = () => {

        // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }))

        for (let k in formLocationDetails.fields) {

            let field = cloneDeep(formLocationDetails.fields[k]);
            field.defaultValue = undefined;
            formLocationDetails.fields[k] = field;
        }

        setLocationMOdalOpen(false)
        setEditLocation(null)
        // dispatch(clearLocatioTable())
    }

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpenSnackbar(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <>
            <div className="rolelist_mainwrp Manage_location">
                <div className="listing_heading ">
                    <h1>Location List</h1>

                    <Tooltip title="ADD USER">
                        <button
                            className="addrole_btn"
                            onClick={() => addLocationModal()}
                        >
                            <AddIcon />
                        </button>
                    </Tooltip>
                </div>

                {/* <p>{JSON.stringify(locationListDataSet)}</p> */}

                {locationLoader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

                <div className='rolelist_mainwrp_Table'>
                    {!locationLoader && Object.keys(locationListDataSet).length > 0 ? (
                        <ListingTable
                            tableData={tableDataforLocationList.current}
                            dataset={locationListDataSet}
                            modifyHeaders={modifyTableHeadersForLocations}
                        />
                    ) : (
                        <div><TableSkeleton count={5} /></div>
                    )}


                </div>

            </div>

            {/* ******************Location add/edit modal************************* */}

            <Modal
                className="Add_Location_modal addtaskListMOdal"
                open={locationMOdalOpen}
                onClose={handleCloseLocationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseLocationModal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <div className='adminformbody'>
                        <h2 className="previewModalTitle ">
                            <div className='modal_title modal_titlenew'>
                                {editLocation !== null
                                    ? "Edit Location"
                                    : "Add Location"}
                            </div>

                        </h2>
                        {/* <p>{JSON.stringify(editLocation)}</p> */}
                        <div className="inputBlock inputBlock3line disposition_task">
                            <Box sx={{ minWidth: 120 }}>
                                <div className='otherForm_mainWrpr'>
                                    {editLocation && editLocation !== null && Object.keys(editLocation).length > 0 && <Form formData={formLocationDetails} />}
                                    {editLocation == null && <Form formData={formLocationDetails} />}
                                </div>

                            </Box>
                        </div>
                    </div>
                    {locationAddLoader ? (
                        <Box
                            className="loaderProgress"
                            sx={{ width: "100%", mt: "10px" }}
                        >
                            <LinearProgress />
                        </Box>
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>

            {/* ========================Preview of Location details================== */}
            {previewOpen && <PreviewLocation
                previewOpen={previewOpen}
                setPreviewOpen={setPreviewOpen}
                previewLocationClicked={previewLocationClicked}
            />}

            {/* ====================Status change Modal======================= */}
            {statusModalOpen && <StatusChangeModal
                statusModalOpen={statusModalOpen}
                setStatusModalOpen={setStatusModalOpen}
                statusChangeClick={statusChangeClick}
                rowDataId={locationEditData}
            />}

            {/* ==========================add notes modal======================== */}
            {notesOpen ? <AddNotesLocation
                notesOpen={notesOpen}
                setNotesOpen={setNotesOpen}
                notesLocationClick={notesLocationClick}
            /> : ""}

            {/* ========================Delete Modal Location======================== */}
            {deleteOpen && <DeleteModalLocation
                deleteOpen={deleteOpen}
                setDeleteOpen={setDeleteOpen}
                rowDataID={rowData}
                logedUserData={logedUserData}
            />}

            {/* ========================SnackBar======================= */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={successMessageLocation}
                action={action}
                sx={{ bottom: "24px !important" }}
            />
        </>
    )
}

export default ViewLocations