import { Button, Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import store from "../../../../../store";
import { fetchInitialLession, resetAllDataInTraingcenter, resetInitialLessionIDshow, resetLessonLoadMore, trainingCenterCategoryFetching, trainingCenterCategoryFetchingLoadMore } from "./TrainingCenterCategoryReducer";
import { fetchTrainingPercentage, resetTrainingDetails } from "../../TrainingReducer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const TrainingCenterCategory = () => {
    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cat_id, lesson_id } = useParams();
    // const { lesson_id } = useParams();
    const mediasec = useRef(null);
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const [checkedCompletedLesson, setCheckedCompletedLesson] = useState(false);
    const [fetchNextCetegoryWithLessonCount, setFetchNextCetegoryWithLessonCount] = useState(0);
    const [initialLessionData, setinitialLessionData] = useState(null);
    const [allTrainingCompleted, setAllTrainingCompleted] = useState(false);

    // ----------------------------------------Global State from Redux---------------------------------------- //
    let categoryList = useSelector((state) =>
        state.trainingCenterCategorySlice?.trainingCategoryList &&
            state.trainingCenterCategorySlice?.trainingCategoryList !== undefined &&
            state.trainingCenterCategorySlice?.trainingCategoryList.length > 0
            ? state.trainingCenterCategorySlice?.trainingCategoryList
            : null
    );
    let lessionList = useSelector((state) =>
        state.trainingCenterCategorySlice?.lessionList &&
            state.trainingCenterCategorySlice?.lessionList !== undefined &&
            state.trainingCenterCategorySlice?.lessionList.length > 0
            ? state.trainingCenterCategorySlice?.lessionList
            : null
    );
    let initialLessionID = useSelector((state) =>
        state.trainingCenterCategorySlice?.initialLessionID &&
            state.trainingCenterCategorySlice?.initialLessionID !== undefined
            ? state.trainingCenterCategorySlice?.initialLessionID
            : null
    );
    // let initialLessionData = useSelector((state) =>
    //     state.trainingCenterCategorySlice?.initialLessionData &&
    //         state.trainingCenterCategorySlice?.initialLessionData !== undefined
    //         ? state.trainingCenterCategorySlice?.initialLessionData
    //         : []
    // );
    // let initialLessionData = useSelector((state) =>
    //         state.trainingSlice?.ongoingLessionData &&
    //             state.trainingSlice?.ongoingLessionData !== undefined
    //             ? state.trainingSlice?.ongoingLessionData
    //             : []
    //     );

    let loader = useSelector((state) =>
        state.trainingCenterCategorySlice?.loading
            ? state.trainingCenterCategorySlice?.loading
            : false
    );
    let lessionListLoadEmpty = useSelector((state) =>
        state.trainingCenterCategorySlice?.lessionListLoadEmpty
            ? state.trainingCenterCategorySlice?.lessionListLoadEmpty
            : false
    );
    const resolved = useSelector((state) =>
        state.ResolveReducer.resolved["/training-center"]
            ? state.ResolveReducer.resolved["/training-center"]
            : false
    );
    const allDoneTrainingListFetchSuccess = useSelector(state => state.trainingSlice?.allDoneTrainingListFetchSuccess ? state.trainingSlice.allDoneTrainingListFetchSuccess : false)
    const allDoneTrainingList = useSelector(state => state.trainingSlice?.allDoneTrainingList ? state.trainingSlice.allDoneTrainingList : [])

    const percentage_data = useSelector(state => state.trainingSlice?.fetchTrainingPercentageData ? state.trainingSlice.fetchTrainingPercentageData : null)

    // ----------------------------------------Components Private State---------------------------------------- //
    const [selectedcatid, setselectedcatid] = useState(null);
    const [selectedlessonid, setselectedlessonid] = useState(null);
    const [openlessonId, setOpenlessonId] = useState(null);
    const [useridsetdata, setuseridsetdata] = useState(getcookies.userinfo?._id);
    const [nextTrainingLesson, setNextTrainingLesson] = useState(false);
    const [currentTrainingLessonID, setCurrentTrainingLessonID] = useState(null);
    const [reqbody, setreqbody] = useState({
        source: "additional_training_list",
        sort: {
            type: "asc",
            field: "training_priority",
        },
        condition: {
            // limit: 5,
            // skip: 0,
        },
        searchcondition: {},
    });
    // const [loader, setLoader] = useState(false);

    // --------------------------------------------Other Veriables-------------------------------------------- //
    const categoryFetchingReqBody = {
        "source": "additional_training_list",
        "sort": {
            "type": "asc",
            "field": "training_priority"
        },
        "condition": {
            "limit": 5,
            "skip": 0
        },
        "searchcondition": {
        },
        "fetch": "category"
    };

    // -------------------------------------------useEffect Section------------------------------------------- //
    useEffect(() => {
        if (!resolved) {
            if (!cat_id && !lesson_id) {
                // console.log("allDoneTrainingList 3", allDoneTrainingList)
                store.dispatch(trainingCenterCategoryFetching(categoryFetchingReqBody));
            }
        }
        return () => store.dispatch(resetAllDataInTraingcenter());
    }, [allDoneTrainingListFetchSuccess]);

    //     useEffect(() => {
    //         if (categoryList && categoryList.length > 0 && lessionList && lessionList.length > 0 && !nextTrainingLesson) {
    //             console.log("categoryList", categoryList, "lessionList", lessionList, "initialLessionData", initialLessionData,)
    //             if (percentage_data && percentage_data.done_training_list.length > 0) {
    //                 store.dispatch(resetInitialLessionIDshow());
    //                 console.log("percentage_data", percentage_data)
    // // getCurrentLesson(initialLessionData[0].training_id, lessionList[0]._id, 'fetch', null);
    //                 if (percentage_data && percentage_data.all_lesson_list && percentage_data.all_lesson_list.length > 0) {
    //                     percentage_data.all_lesson_list.forEach((e, i) => {
    //                         if (e.lesson_complition_status === 'ongoing') {
    //                             console.log("percentage_data ongoing", e)
    //                             setselectedcatid(e.training_id);
    //                             getCurrentLesson(e.training_id, e._id, 'fetch', null);
    //                         }
    //                     })
    //                 }


    //             } else {
    //                 console.log("percentage_data", percentage_data)
    //                 getCurrentLesson(lessionList[0].training_id, lessionList[0]._id, 'fetch', null);
    //             }
    //             setNextTrainingLesson(true);
    //         }
    //     }, [categoryList, percentage_data, lessionList, nextTrainingLesson,initialLessionData])

    useEffect(() => {
        if (percentage_data) {
            if (percentage_data.all_lesson_list) {
                percentage_data.all_lesson_list.forEach((e, i) => {
                    if (currentTrainingLessonID == null) {
                        if (e.lesson_complition_status === 'ongoing') {
                            console.log('initialLessionData', e)
                            setinitialLessionData(e);
                            getCurrentCategory(e.training_id, percentage_data.all_lesson_list);
                            getCurrentLesson(e.training_id, e._id, 'fetch', null);
                        }
                        if (e.is_last_lesson && e.lesson_complition_status === 'done') {
                            setinitialLessionData(e);
                        }
                    } else {
                        if (currentTrainingLessonID === e._id) {
                            let tempE = JSON.parse(JSON.stringify(e))
                            tempE.lesson_complition_status = 'ongoing';
                            e = tempE;
                            setinitialLessionData(e);
                            getCurrentCategory(e.training_id, percentage_data.all_lesson_list);
                            getCurrentLesson(e.training_id, e._id, 'clicked', null);
                            mediasec.current.scrollIntoView({ behavior: "smooth" });
                        }
                    }
                })
            }
        }
    }, [percentage_data, currentTrainingLessonID])


    useEffect(() => {
        if (getcookies && getcookies.userinfo && percentage_data == null) {
            store.dispatch(fetchTrainingPercentage(getcookies.userinfo._id));
        }
        // if (getcookies && getcookies.userinfo && !allDoneTrainingListFetchSuccess && percentage_data) {
        // console.log("getcookies",getcookies,allDoneTrainingListFetchSuccess)
        // store.dispatch(getAllDoneTrainingListByUser(getcookies.userinfo._id));
        // }
        // console.log("percentage_data,allDoneTrainingListFetchSuccess,getcookies", percentage_data, getcookies)
    }, [percentage_data])

    useEffect(() => {
        // if (initialLessionID && checkedCompletedLesson) {
        //     console.log("fist", initialLessionID);
        //     let data = {
        //         "source": "lesson_list",
        //         "_id": initialLessionID
        //     }
        //     store.dispatch(fetchInitialLession(data));
        //     store.dispatch(resetInitialLessionIDshow());
        // }
        // setLoader(true);
    }, [initialLessionID, checkedCompletedLesson]);

    useEffect(() => {
        if (initialLessionData && percentage_data && categoryList == null && !allTrainingCompleted) {
            store.dispatch(trainingCenterCategoryFetching(categoryFetchingReqBody));
            mediasec.current.scrollIntoView({ behavior: "smooth" });
            if (initialLessionData.is_last_lesson && (percentage_data.done_traning_count === percentage_data.total_lesson_count)) {
                let updateinitialLessionData = JSON.parse(JSON.stringify(initialLessionData));
                updateinitialLessionData.lesson_complition_status = 'done';
                setinitialLessionData(updateinitialLessionData);
                setAllTrainingCompleted(true);
            }
            console.log("percentage_data,allDoneTrainingListFetchSuccess,getcookies", percentage_data, initialLessionData)
        }
    }, [initialLessionData, percentage_data, allTrainingCompleted])
    // useEffect(() => {
    //     if (initialLessionData && initialLessionData.length > 0) {
    //         if (initialLessionData[0]._id) {
    //             setselectedlessonid(initialLessionData[0]._id);
    //         }
    //         if (cat_id) {
    //             setselectedcatid(cat_id);
    //         } else if (initialLessionData[0].training_id) {
    //             setselectedcatid(initialLessionData[0].training_id);
    //         }
    //     }
    // }, [initialLessionData, cat_id]);

    // useEffect(() => {
    //     if (
    //         cat_id &&
    //         reqbody.searchcondition["training_id"] !== cat_id &&
    //         categoryList &&
    //         categoryList.length > 0
    //     ) {
    //         reqbody.searchcondition["training_id"] = cat_id;
    //         setreqbody(reqbody);
    //         store.dispatch(trainingCenterCategoryFetching(reqbody));
    //     }

    //     if (
    //         cat_id &&
    //         reqbody.searchcondition["training_id"] !== cat_id &&
    //         !categoryList
    //     ) {
    //         reqbody.searchcondition["training_id"] = cat_id;
    //         setreqbody(reqbody);

    //         reqbody["fetch"] = "category";
    //         console.log("allDoneTrainingList 2",allDoneTrainingList)

    //         store.dispatch(trainingCenterCategoryFetching(reqbody));
    //     }
    // }, [cat_id]);

    // useEffect(() => {
    //     if (cat_id && initialLessionID) {
    //         let data = {
    //             "source": "lesson_list",
    //             "_id": initialLessionID
    //         }
    //         store.dispatch(fetchInitialLession(data));
    //         store.dispatch(resetInitialLessionIDshow());
    //     }
    // }, [cat_id, initialLessionID]);

    // useEffect(() => {
    //     if (lesson_id) {
    //         let data = {
    //             "source": "lesson_list",
    //             "_id": lesson_id
    //         }
    //         store.dispatch(fetchInitialLession(data));
    //     }
    // }, [lesson_id]);

    useEffect(() => {
        if (initialLessionData && initialLessionData.lesson_complition_status !== 'done' && categoryList?.length > 0) {
            mediasec.current.scrollIntoView({ behavior: "smooth" });
            // getCurrentLesson(initialLessionData.training_id, initialLessionData._id, 'fetch', null);
            console.log("getCurrentLesson categoryList", categoryList, "initialLessionData", initialLessionData, "percentage_data", percentage_data)
        }
    }, [categoryList, initialLessionData]);

    useEffect(() => {
        let domNodeArr = null;
        setTimeout(() => {
            domNodeArr = document.querySelectorAll('app-traing-center');
            // console.log("domNodeArr ------------>", domNodeArr);
            if (domNodeArr && domNodeArr.length > 0) {
                for (let i = 0; i < domNodeArr.length; i++) {
                    if (domNodeArr[i]) domNodeArr[i].addEventListener('app-traing-event', (e) => {
                        let eventData = JSON.parse(e.target.getAttribute('app-traing-center'));
                        console.log("useEffectss eventData", eventData)
                        if (eventData && eventData.type === 'mark_as_done') {
                            if (eventData.nextLesson && eventData.nextLesson === 'yes') {
                                store.dispatch(resetAllDataInTraingcenter())
                                store.dispatch(resetTrainingDetails())
                                setNextTrainingLesson(false);
                                // if (percentage_data && percentage_data.all_lesson_list && percentage_data.all_lesson_list.length > 0) {
                                //     percentage_data.all_lesson_list.forEach((e, i) => {
                                //         if (e._id === eventData.success_data.result.lesson_id) {
                                //             console.log("allDoneTrainingList useEffectss e", e)
                                //             if (e.next_lesson) {
                                //                 let data = {
                                //                     "source": "lesson_list",
                                //                     "_id": e.next_lesson
                                //                 }
                                //                 store.dispatch(fetchInitialLession(data));
                                //                 setCheckedCompletedLesson(true);
                                //             } else {
                                //                 fetchNextCetegoryWithLesson(e.training_id);
                                //             }
                                //         }
                                //     })
                                // }
                            } else {
                                if (currentTrainingLessonID === null) {
                                    store.dispatch(fetchTrainingPercentage(getcookies.userinfo._id));
                                    setCurrentTrainingLessonID(eventData.success_data.result.lesson_id);
                                }
                            }
                        }


                        // store.dispatch(fetchTrainingPercentage(getcookies.userinfo._id));
                        // console.log("app-traing-center elem event Data  lessondata --------->", eventData);
                        if (eventData && eventData.type == "next_lesson" && lessionList.length > 0) {
                            // lessionList.forEach((e, i) => {
                            //     if (selectedlessonid === e._id) {
                            //         if (i === lessionList.length - 1) {
                            //             categoryList.forEach((f, j) => {
                            //                 if (e.training_id === f._id) {
                            //                     const currentIndex = categoryList.indexOf(f);
                            //                     const nextIndex = (currentIndex + 1) % categoryList.length;
                            //                     setselectedcatid(categoryList[nextIndex]._id)
                            //                     reqbody.searchcondition["training_id"] = categoryList[nextIndex]._id;
                            //                     setreqbody(reqbody);
                            //                     store.dispatch(trainingCenterCategoryFetching(reqbody));
                            //                     // navigate(`/training-center/${categoryList[nextIndex]._id}`);
                            //                     setNextTrainingLesson(true);
                            //                 }
                            //             })
                            //         } else {
                            //             const currentIndex = lessionList.indexOf(e);
                            //             const nextIndex = (currentIndex + 1) % lessionList.length;
                            //             // navigate(`/training-center/${selectedcatid}/${lessionList[nextIndex]._id}`);
                            //         }
                            //     }
                            // })

                        }
                    })
                }
            }
        })
    }, [selectedlessonid, lessionList, categoryList]);

    // useEffect(() => {
    //     if (nextTrainingLesson) {
    //         if (lessionList && lessionList.length > 0) {
    //             navigate(`/training-center/${selectedcatid}/${lessionList[0]._id}`);
    //         } else {
    //             navigate(`/training-center/${selectedcatid}`);
    //         }
    //     }
    // }, [nextTrainingLesson, lessionList]);
    const fetchNextCetegoryWithLesson = (currTrainingId) => {
        let newcategoryList = categoryList.filter((e) => e._id !== currTrainingId);
        if (newcategoryList.length > 0) {
            reqbody.searchcondition["training_id"] = newcategoryList[0]._id;
            setreqbody(reqbody);
            store.dispatch(trainingCenterCategoryFetching(reqbody));
            setCheckedCompletedLesson(false);
            setFetchNextCetegoryWithLessonCount(1);
        }
        console.log("newcategoryList", newcategoryList)
    }
    const getCurrentCategory = (categeory_id, lessionList) => {
        console.log("getCurrentCategory", categeory_id, lessionList)
        setselectedcatid(categeory_id)
        reqbody.searchcondition["training_id"] = categeory_id;
        setreqbody(reqbody);
        store.dispatch(trainingCenterCategoryFetching(reqbody));
    }
    const getCurrentLesson = (val_id, lesson_id, type, event) => {
        // console.log("getCurrentLesson",initialLessionData2, "initialLessionData", initialLessionData)
        // let data = {
        //     "source": "lesson_list",
        //     "_id": lesson_id
        // }
        // store.dispatch(fetchInitialLession(data));
        setCheckedCompletedLesson(true);
        if (percentage_data && percentage_data.all_lesson_list && percentage_data.all_lesson_list.length > 0) {
            let allLessonTags = document.querySelectorAll(".lessoncontent");
            // console.log("getCurrentLesson allLessonTags", val_id, lesson_id, type, percentage_data.all_lesson_list)
            if (type === 'clicked') {
                percentage_data.all_lesson_list.forEach((e, i) => {
                    if (e.lesson_id_string === lesson_id) {
                        if (allLessonTags && allLessonTags.length > 0) {
                            allLessonTags.forEach((f, j) => {
                                if (f.classList.contains('ongoing')) {
                                    f.classList.add('notdone');
                                }
                                if (f.innerText.toLowerCase().trim() === e.lesson_name.toLowerCase().trim()) {
                                    // console.log("getCurrentLesson allLessonTags", e,f.innerText)
                                    f.classList.add('ongoing');
                                    if (f.classList.contains('done')) {
                                        f.classList.remove('done');
                                    }
                                    if (f.classList.contains('notdone')) {
                                        f.classList.remove('notdone');
                                    }
                                    setinitialLessionData(e);
                                }
                            })
                        }
                    }
                });
                if (percentage_data.done_training_list && percentage_data.done_training_list.length > 0) {
                    percentage_data.all_lesson_list.forEach((e, i) => {
                        percentage_data.done_training_list.forEach((g, h) => {
                            if (g.lesson_id === lesson_id) {
                                if (allLessonTags && allLessonTags.length > 0) {
                                    allLessonTags.forEach((f, j) => {
                                        if ((f.innerText.toLowerCase().trim() === e.lesson_name.toLowerCase().trim()) && (e.lesson_id_string === g.lesson_id)) {
                                            if (f.classList.contains('done')) {
                                                f.classList.remove('done');
                                                f.classList.add('ongoing');
                                            }
                                        }
                                    })
                                }
                            } else {
                                if (e.lesson_id_string === g.lesson_id) {
                                    if (allLessonTags && allLessonTags.length > 0) {
                                        allLessonTags.forEach((f, j) => {
                                            if ((f.innerText.toLowerCase().trim() === e.lesson_name.toLowerCase().trim())) {
                                                if (f.classList.contains('ongoing')) {
                                                    f.classList.remove('ongoing');
                                                    f.classList.add('done');
                                                }
                                                if (f.classList.contains('notdone')) {
                                                    f.classList.remove('notdone');
                                                    f.classList.add('done');
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    })
                }
            }
        }
    }

    // --------------------------------------------Other Handlers-------------------------------------------- //
    const showcatagorydata = (val, lessondata) => {
        // console.log("lessondata val", val, lessondata);

        return (
            <>
                {/* <div  className="cat_block" > */}
                <div
                    className={
                        selectedcatid && selectedcatid === val._id
                            ? "cat_block active"
                            : "cat_block inactive"
                    }
                >
                    <div
                        className="cat_block_cat_data"
                        // onClick={() => [navigate(`/training-center/${val._id}`), resetTrainingLesson()]}
                        onClick={() => [getCurrentCategory(val._id, lessondata), resetTrainingLesson()]}
                    >
                        <div className="training_name_text">
                            <CategoryIcon fontSize="medium" />
                            <p>{val.training_name}</p>
                        </div>
                        <span>
                            {selectedcatid && selectedcatid === val._id ?
                                <KeyboardArrowUpIcon /> :
                                <KeyboardArrowDownIcon />
                            }
                        </span>
                    </div>
                    {selectedcatid && selectedcatid === val._id ?
                        <div className="cat_block_lesson_data">
                            {lessondata &&
                                lessondata.length > 0 &&
                                lessondata.map((curr, index) => (
                                    <>
                                        <div key={index}>
                                            {curr.training_id == val._id ? (
                                                <>

                                                    <div
                                                        className={
                                                            curr.lesson_complition_status === 'done'
                                                                ? "lessoncontent done"
                                                                : curr.lesson_complition_status === 'ongoing' ? "lessoncontent ongoing" : "lessoncontent notdone"
                                                        }
                                                        onClick={(e) =>
                                                            // navigate(
                                                            //     `/training-center/${val._id}/${curr._id}`
                                                            // )
                                                            getCurrentLesson(val._id, curr._id, 'clicked', e)
                                                        }
                                                    >
                                                        {/* <img src={curr.youtube_thumbnail} /> */}
                                                        <p>{curr.lesson_name}</p>

                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </>
                                ))
                            }
                            {selectedcatid === val._id && lessondata === null ? 'Lesson Not Added!' : ''}
                        </div>
                        : ''}
                </div>
                {/* {lessondata && lessondata[0].training_id == val._id && <div className="readmorediv">
                    <Button onClick={() => readmoreClick(val)} className={lessionListLoadEmpty ? "disabled" : ""} disabled={lessionListLoadEmpty}> Load more {lessionListLoadEmpty}</Button>
                </div>} */}
            </>
        );
    };
    const readmoreClick = (val) => {
        console.log("reloadhit lessionList", lessionListLoadEmpty);
        // console.log("val lessionList", lessionList.length);
        if (lessionList && lessionList.length > 0) {
            reqbody.condition.skip = lessionList.length;

        } else {
            reqbody.condition.skip = 0;

        }
        if (reqbody.fetch) {
            delete reqbody.fetch
        }

        store.dispatch(trainingCenterCategoryFetchingLoadMore(reqbody));
    }
    const lessonData = (value) => {
        let val = value;
        // console.log("lessonDatas val", val)
        // if (userDonetraininglesson && userDonetraininglesson[0]?.completed_lessons?.length > 0) {
        //     for (let i in userDonetraininglesson[0].completed_lessons) {
        //         // console.log("idsss", userDonetraininglesson[0].completed_lessons[i]._id,val._id );
        //         if (val._id === userDonetraininglesson[0].completed_lessons[i]._id) {
        //             // console.log("id match");
        //             val = { ...val, done: 1 }

        //         }
        //     }

        // }

        if (val && openlessonId && openlessonId !== undefined) {
            if (val._id == openlessonId) {
                val = { ...val, isopen: true }
            }
        }

        if (val.poster_image != null && val.poster_image !== undefined) {
            // console.log("getdataa", val);

        }

        let trainingdata = {};
        if ((val.video && Object.keys(val.video).length > 0 && val.files && val.files.length > 0 && val.poster_image && Object.keys(val.poster_image).length > 0 && val.audio && val.audio.url) ||
            (val.video && Object.keys(val.video).length > 0 && val.files && val.files.length > 0 && val.poster_image && Object.keys(val.poster_image).length > 0) ||
            (val.video && Object.keys(val.video).length > 0 && val.audio && val.audio.url && val.poster_image && Object.keys(val.poster_image).length > 0) ||
            (val.video && Object.keys(val.video).length > 0 && val.files && val.files.length > 0 && val.audio && val.audio.url) ||
            (val.video && Object.keys(val.video).length > 0 && val.poster_image && Object.keys(val.poster_image).length > 0 && val.audio && val.audio.url) ||
            (val.video && Object.keys(val.video).length > 0 && val.files && val.files.length > 0) ||
            (val.video && Object.keys(val.video).length > 0 && val.poster_image && Object.keys(val.poster_image).length > 0) ||
            (val.video && Object.keys(val.video).length > 0 && val.audio && val.audio.url) ||

            (val.files && val.files.length > 0 && val.poster_image && Object.keys(val.poster_image).length > 0 && val.audio && val.audio.url) ||
            (val.files && val.files.length > 0 && val.poster_image && Object.keys(val.poster_image).length > 0) ||
            (val.files && val.files.length > 0 && val.audio && val.audio.url) ||

            (val.poster_image && Object.keys(val.poster_image).length > 0 && val.audio && val.audio.url)
        ) {
            // console.log("lessonDatas val iffff", val)
            let mediaUrlArr = [];
            if (val.video && val.video.url) {
                let obj = { video: val.video.url }
                mediaUrlArr.push(obj)
            }
            if (val.poster_image && val.poster_image.url) {
                let obj = { img: val.poster_image.url }
                mediaUrlArr.push(obj)
            }
            if (val.files && val.files.length > 0) {
                let obj = { file: val.files[0].upload_file.url }
                mediaUrlArr.push(obj)
            }
            if (val.audio && val.audio.url) {
                let obj = { audio: val.audio.url }
                mediaUrlArr.push(obj)
            }
            trainingdata = {
                media_url: mediaUrlArr,
                tittle: val.lesson_name,
                html_content: val.editor_description,
                wrapperclass: 'test_html_class tncgCenter_dta',
                _id: val._id,
                user_id: useridsetdata,
                markasdone_api: "training-manage/done-traning",
                traning_id: val.training_id,
                lesson_id: val._id,
                done: (val.lesson_complition_status && val.lesson_complition_status === 'done') ? 1 : 0,
                isopen: (val.isopen && val.isopen !== null && val.isopen !== undefined) ? val.isopen : true,
                only_header_show: false,
                poster: (val.poster_image?.url != null && val.poster_image?.url !== undefined) ? val.poster_image.url : (val.traning_category && val.traning_category !== undefined && val.traning_category == 1) ? undefined : undefined,
                // index: index + 1,
                is_last_lesson: (val.is_last_lesson) ? true : false,
                showQuiz: val.showquiz,
                apiUrl: process.env.REACT_APP_API_URL,
                fetchQuiz_api: 'training-manage/quiz-submission',
                extession: val.video ? 'video' : val.audio ? 'audio' : val.files && val.files.length > 0 ? 'file' : val.poster_image && val.poster_image.url ? 'img' : null
            };
        } else {
            // console.log("lessonDatas val elseee", val)
            trainingdata = {
                media_url: val.video ? val.video.url : val.poster_image ? val.poster_image.url : val.files && val.files.length > 0 ? val.files[0].upload_file.url : val.audio ? val.audio.url : null,
                tittle: val.lesson_name,
                html_content: val.editor_description,
                wrapperclass: 'test_html_class tncgCenter_dta',
                _id: val._id,
                user_id: useridsetdata,
                markasdone_api: "training-manage/done-traning",
                traning_id: val.training_id,
                lesson_id: val._id,
                done: (val.lesson_complition_status && val.lesson_complition_status === 'done') ? 1 : 0,
                isopen: (val.isopen && val.isopen !== null && val.isopen !== undefined) ? val.isopen : true,
                only_header_show: false,
                poster: (val.poster_image?.url != null && val.poster_image?.url !== undefined) ? val.poster_image.url : (val.traning_category && val.traning_category !== undefined && val.traning_category == 1) ? undefined : undefined,
                // index: index + 1,
                is_last_lesson: (val.is_last_lesson) ? true : false,
                showQuiz: val.showquiz,
                apiUrl: process.env.REACT_APP_API_URL,
                fetchQuiz_api: 'training-manage/quiz-submission',
                extession: val.video ? 'video' : val.audio ? 'audio' : val.files && val.files.length > 0 ? 'file' : 'img'
            };
        }


        // console.log("lessonData trainingdata}}}", trainingdata, initialLessionData, categoryList);
        return <app-traing-center key={val._id} data={JSON.stringify(trainingdata)}></app-traing-center>;

    }
    const resetTrainingLesson = () => {
        // setNextTrainingLesson(false);
        reqbody.condition.skip = 0;
        dispatch(resetLessonLoadMore());
    }
    const percentageShow = (val) => {

        let dataset = {
            wrapperclass: 'trainingCenter_Progressbar',
            progress_wrapperclass: 'progressbar_wrapper',
            progressbar_value: val.done_traning_percentage,
            countshow: true,
            total_count: val.total_lesson_count,
            complete_count: val.done_traning_count,
        }
        return <app-training-percentage key={val._id} data={JSON.stringify(dataset)}></app-training-percentage>;
    }


    // store.subscribe(() => {
    // const storeData = store.getState();
    // if(storeData.trainingCenterCategorySlice && storeData.trainingCenterCategorySlice.success && storeData.trainingCenterCategorySlice.trainingCategoryList){
    // setCategoryList(storeData.trainingCenterCategorySlice.trainingCategoryList)
    // setLoader(false);
    // console.log("storeData ",storeData.trainingCenterCategorySlice)
    // }
    // })


    //----------------------------------------------return-----------------------------------------------//
    return (
        <>
            <div className='show_percentage'>
                <div className='commonWidth_admin'>
                    {percentage_data && Object.keys(percentage_data).length > 0 && percentageShow(percentage_data)}
                </div>
            </div>
            <div
                className="trainingCenter_Sub additional_trainingCenter_Sub"
                ref={mediasec}
            >

                <Grid
                    item
                    className="traingcenterdata_wrapper leftdatashow"
                    xl={8}
                >

                    {/* <------------------------------Blog Card List From angular element------------------------------> */}
                    {/* {initialLessionData && initialLessionData[0]?._id != undefined && initialLessionData.map((curr, index) => lessonData(curr, index))} */}
                    {initialLessionData && initialLessionData._id !== undefined && initialLessionData.lesson_complition_status === 'ongoing' && lessonData(initialLessionData)}
                    {initialLessionData && initialLessionData.is_last_lesson && initialLessionData.lesson_complition_status === 'done' ? 'You have completed all Training' : ''}
                    {/* {initialLessionData && ( */}
                    {/* <div className="leftpart_wrapper">
                            <div className="leftpart_header">
                                <h1>{initialLessionData.lesson_name}</h1>
                            </div>
                            <div className="mediacontentpart"> */}
                    {/* <YouTube
                        videoId={initialLessionData?.youtube_video?.id}
                        opts={opts}
                        onEnd={videoenevd}
                      /> */}
                    {/* </div>
                            <div className="mediacontentpart"> */}
                    {/* <span
                        dangerouslySetInnerHTML={{
                          __html: initialLessionData.descriptions,
                        }}
                        className="description"
                      ></span> */}
                    {/* </div>
                        </div> */}
                    {/*  )} */}
                </Grid>
                <Grid
                    item
                    xl={4}
                    className="traingcenterlist_wrapper rightdatashow"
                >
                    <div className="traingcenterlist_innerwrapper">
                        {categoryList && percentage_data && percentage_data.all_training_list.map((category, index) =>
                            <div key={index}>
                                {showcatagorydata(category, percentage_data.all_lesson_list)}
                                {/* <button>{category.training_name}</button> */}
                            </div>
                        )}
                    </div>
                </Grid>
            </div>
        </>
    )
}