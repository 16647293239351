import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apicall } from "../../../helper/helperFunctions";


export const getAdminDashbordListData = createAsyncThunk("getAdminDashbordListData",async(reqBody)=>{
    let req_body = {};
    req_body = { ...reqBody };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // const response = await fetch(process.env.REACT_APP_API_URL + "users/admin-dashboard-list-grid",requestOptions)
    const response = await apicall( "users/admin-dashboard-list-grid", requestOptions);

   

    const respdata = response;
    // const respdata = await response.json();
   
    return respdata;
    
})

export const getAdminChartData = createAsyncThunk("getAdminChartData",async(reqBody)=>{
    let req_body = {};
    req_body = { ...reqBody };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // const response = await fetch(process.env.REACT_APP_API_URL + "users/dynamic-admin-chart",requestOptions)
    const response = await apicall( "users/dynamic-admin-chart", requestOptions);

  
    // const respdata = await response.json();
    const respdata =  response;
   
    return respdata;
})



export const getInfoLinkData = createAsyncThunk("getInfoLinkData",async(reuBody)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reuBody)
    };
    const response = await apicall("info-link/info-link-single-fetch",requestOptions)
    const respdata = response
    return respdata
})


const DashboardListReducer = createSlice({
    name:"DashboardListReducer",
    initialState:{
        ListData:[],
        gridData:[],
        chartData:{},
        listType:null,
        gridListName:"User",
        loader:false,
        usePaylod:[],
        infoLinkData:[]
    },
    reducers:{
        cleardashbordListdata(state,action){
            state.ListData=[]
            state.gridData=[]
            state.chartData={}
            state.listType=null
        },
        clearGridListName(state,action){
            state.gridListName="User"
        },
        clearGridList(state,action){
            state.gridData=[]
        }
    },
    extraReducers:{
        // grid Data and List Data 
        [getAdminDashbordListData.rejected]:(state,action)=>{
            state.loader=false
            state.ListData=[]
        },
        [getAdminDashbordListData.pending]:(state,action)=>{ 
            // state.listType = null
            state.loader=true
            state.ListData=[]
        },
        [getAdminDashbordListData.fulfilled]:(state,action)=>{
            
            state.listType = action.meta.arg.type
            if(action.meta.arg.type === "opportunities") state.gridListName = "Opportunities"
            if(action.meta.arg.type === "lead_appointment") state.gridListName = "Lead Appointment"
            if(action.meta.arg.type === "training") state.gridListName = "Training"
            if(action.meta.arg.type === "follow_up_call") state.gridListName = "Follow Up Call"
            if(action.meta.arg.type === "folder_access_rep") state.gridListName = "Folder Access AE"
            if(action.meta.arg.type === "folder_access_lead") state.gridListName = "Folder Access Lead"
            if(action.meta.arg.type === "email_sent") state.gridListName = "Email Sent"
            if(action.meta.arg.type === "info_link_sent") state.gridListName = "Information Link"
            if(action.meta.arg.type === "discovery_call_rep") state.gridListName = "Discovery Call AE"
            if(action.meta.arg.type === "discovery_call_lead") state.gridListName = "Discovery Call Lead"
            if(action.meta.arg.type === "activity_deatails") state.gridListName = "All Activity"
            if(action.meta.arg.type === "conversion_done") state.gridListName = "Conversion"
            state.ListData=action.payload.results.res
            state.gridData=action.payload.results.grid_data
            state.loader=false
        
            state.usePaylod=action.meta.arg
            
           
        },


        // Chart Data
        [getAdminChartData.rejected]:(state,action)=>{
            
        },
        [getAdminChartData.pending]:(state,action)=>{
            
        },
        [getAdminChartData.fulfilled]:(state,action)=>{
            
            state.chartData = action.payload.results
          
        },


        //------------------------------------ infolinkdata-----------------------------------
        [getInfoLinkData.pending]:(state,action)=>{
            state.loader=true
        },
        [getInfoLinkData.fulfilled]:(state,action)=>{
            state.loader=false
            // console.log("action------LinkInfo",action.payload.results.res[0]);
            state.infoLinkData = action.payload.results.res[0]
        },
        [getInfoLinkData.rejected]:(state,action)=>{
            state.loader=false
        },

      

    }
})


export default DashboardListReducer.reducer;
export const {cleardashbordListdata,clearGridListName,clearGridList} = DashboardListReducer.actions