import { IconButton, LinearProgress, Modal, TextareaAutosize, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import { setReloadTableFlag } from '../../../../../listing/listReducer';
import { addedNotesListLocation } from '../../EmployerReducer';


function AddNotesLocation({ notesOpen, setNotesOpen, notesLocationClick }) {

    const dispatch = useDispatch()

    // const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForLocationData?._id  ? state.tableSlice.tempRowData.tableForLocationData._id : {})
    const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForLocationData && Object.keys(state.tableSlice.tempRowData.tableForLocationData).length > 0 ? state.tableSlice.tempRowData.tableForLocationData : null)
    const subEmployeeRowId = useSelector((state) => state.tableSlice.tempRowData.tableForLocationData?._id ? state.tableSlice.tempRowData.tableForLocationData._id : null)


    const LocationNotesData = useSelector((state) => state.EmployerReducer?.addnotesLocationListData ? state.EmployerReducer.addnotesLocationListData : [])
    const subEmployerLoader = useSelector((state) => state.EmployerReducer?.loadingForNotes ? state.EmployerReducer.loadingForNotes : false)

    const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);



    const [rowDataFlag, setRowDataFlag] = useState()

    useEffect(() => {
        if (subEmployeeRowData !== null && subEmployeeRowData !== undefined) {
            // console.log("SubEmployeeRowData========>>", subEmployeeRowData);
            setRowDataFlag(subEmployeeRowData)

            // console.log("rowDataFlag========>>", rowDataFlag);

        }
    }, [JSON.stringify(subEmployeeRowData)])


    const [rowDataId, setRowDataId] = useState(null)

    useEffect(() => {
        if (subEmployeeRowId !== null && subEmployeeRowId !== undefined) {
            // console.log("subEmployeeRowId========>>", subEmployeeRowId);
            setRowDataId(subEmployeeRowId)


        }
    }, [JSON.stringify(subEmployeeRowId)])

    useEffect(() => {

        // console.log("rowDataId========>>", rowDataId);

    }, [rowDataId])




    const [scratchaddnoteflag, setscratchaddnoteflag] = useState(false);


    const inputTextEmployer = useRef(null);
    const textareayvalue = useRef(null);






    useEffect(() => {
        if (notesLocationClick && rowDataId !== null) {
            // console.log("checkforRowid======>>>>", notesLocationClick, rowDataId);

            notesListApiCall()

        }
    }, [notesLocationClick, rowDataId])


    const notesListApiCall = async () => {

        if (viewLocationNotesList.current.reqBody && viewLocationNotesList.current.reqBody.searchcondition) {
            viewLocationNotesList.current.reqBody.searchcondition.notes_for = rowDataId
        }

        viewLocationNotesList.current.searchData.formData.fields[0].reqBody.searchcondition.notes_for = rowDataId

        await dispatch(addedNotesListLocation(viewLocationNotesList.current.reqBody));

        // console.log("viewLocationNotesList==============>>>>>>", viewLocationNotesList)



    }

    const addnote = async () => {
        if (textareayvalue.current) {
            setscratchaddnoteflag(true);
            let reqbody = {
                notes_by: addedById,
                notes_for: rowDataId,
                notes: textareayvalue.current,
                // token: token
            };
            // console.log("reqbody==============>>>>", reqbody)
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(reqbody),
            };
            // console.log("addBody", reqbody);
            const response = await fetch(
                process.env.REACT_APP_API_URL + "employer_manage/addemployersnotes",
                requestOptions
            );
            const respdata = await response.json();
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Added Successfully",
                    status: "success",
                })
            );
            setscratchaddnoteflag(false);
            textareayvalue.current = null;
            inputTextEmployer.current.value = null;

            await dispatch(addedNotesListLocation(viewLocationNotesList.current.reqBody));

            dispatch(setReloadTableFlag({ tableId: "locationNotesTable" }));
        } else {
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Please Add a Note",
                    status: "error",
                })
            );
        }
    };

    // ======================================= table for addedNotes ===============================

    var modifyTableHeadersforLocationNotes = [
        { val: "notes", name: "Notes" },
        { val: "notes_by_name", name: "Added By" },
        {
            val: "createdon_datetime",
            name: "Creation Time",
            type: "datetime",
            format: "MM/DD/YYYY",
        },
    ];

    let viewLocationNotesList = useRef({
        tableId: "locationNotesTable",
        tableTitle: "Note About Employer",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "employer_manage/location_notes_list",
            tableCountEndpoint: "employer_manage/location_notes_list",
        },

        reqBody: {
            condition: {
                limit: 30,
                skip: 0,
            },
            sort: {
                field: "createdon_datetime",
                type: "desc",
            },
            searchcondition: {
                notes_for: rowDataId,
            },
            project: {},
            email: "",
            count: false,
        },

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime", "notes", "addedby_name"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [
            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle:
                    "<p>Are you sure you want to delete this Note ? This can't be undone. </p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ["Yes", "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "employer_manage/deletenotes",
                    body: {
                        // source: "users",

                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                },
            },
        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },
        searchData: {
            heading: "Search Note About Location",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: "searchNotesForm",
                formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
                formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search by Notes",
                        name: "notes",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL +
                            "employer_manage/autocompletenotes",
                        reqBody: {
                            condition: {
                                limit: 30,
                                skip: 0,
                            },
                            sort: {
                                field: "createdon_datetime",
                                type: "desc",
                            },
                            searchcondition: {
                                notes_for: rowDataId,
                                label: {
                                    $regex: "",
                                    $options: "i",
                                },
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },
             
                    {
                        id: 8,
                        label: "Search by Created on Start Date",
                        name: "createdon_Start_datetime",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: "list_search_item countDiv__column--col4 ",
                        payloadFormat: { key: "createdon_datetime", param: "$gte" },
                    },
                    {
                        id: 9,
                        label: "Search by Created on End Date",
                        name: "createdtedon_datetime",
                        className: "list_search_item countDiv__column--col4 ",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY',
                        payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    },
                ],
            },
        },
    });

    const handleCloseNotesModal = () => {
        setNotesOpen(false)
        textareayvalue.current = null;
        setRowDataId(null)
        dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }));


    }

    return (
        <>
            <>
                <Modal className="contractFormMOdal notesModal_blcoast"
                    open={notesOpen}
                    onClose={handleCloseNotesModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modalBaseBox">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleCloseNotesModal}
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                        <div className="rolelist_mainwrp">
                            <h2 className="previewModalTitle ">
                                <div className="modal_title modal_titlenew">
                                    Notes for {rowDataFlag?.location_name}
                                    {/* <p>name:{JSON.stringify()}</p> */}
                                </div>
                            </h2>

                            {/* <p>{JSON.stringify(viewEmployerFullLsitData)}</p> */}

                             <div className='rolelist_mainwrp_Table'>
                            {LocationNotesData !== null && !subEmployerLoader ? (<ListingTable
                                tableData={viewLocationNotesList.current}
                                dataset={LocationNotesData}
                                modifyHeaders={modifyTableHeadersforLocationNotes}
                            />) : (
                                <TableSkeleton count={5} />
                            )} 

                        </div> 

                            <div className="Notes_Form">
                                <p>Notes</p>
                                <TextareaAutosize
                                    placeholder={"Type here..."}
                                    // value={textareayvalue.current}
                                    ref={inputTextEmployer}
                                    onChange={(e) => {
                                        textareayvalue.current = e.target.value;
                                    }}
                                />
                            </div>

                            <div>
                                <button
                                    className="blucoastList_specialbtn" onClick={() => addnote()}
                                >
                                    Add Note
                                </button>

                                {scratchaddnoteflag && <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }} >
                                    <LinearProgress />
                                </Box>}

                            </div>

                        </div>
                    </Box>
                </Modal>
            </>
        </>
    )
}

export default AddNotesLocation