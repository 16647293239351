import React, { useEffect, useRef } from 'react'
import { Form } from '../../../../form/Form';
import { clearaddsuccesscommisonflag, commissionManualEntryDataForm } from '../commissionReducer';
import store from '../../../../store';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';


function CommissionManualEntry() {

  const dispatch = useDispatch();

  const formInputData = useSelector((state) => state.formSlice.formData.commissionManual ? state.formSlice.formData.commissionManual : null);
  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.commissionManual) ? state.formSlice?.formSubmissionState?.commissionManual : 0)
  const commisionmanualdata = useSelector(state => (state.commissionReducer?.commissionmanualdata) ? state.commissionReducer?.commissionmanualdata : null)
  const commisionmanualdataflag = useSelector(state => (state.commissionReducer?.adddsuccesscommissionflag) ? state.commissionReducer?.adddsuccesscommissionflag : false)
  const commissiondataLoader = useSelector(state => (state.commissionReducer?.commissionLoader) ? state.commissionReducer?.commissionLoader : false)

  useEffect(() => {
    if (commisionmanualdata && Object.keys(commisionmanualdata) && commisionmanualdataflag) {

      store.dispatch(
        setSnackbar({ open: true, message: 'Thank You for Submiting!', status: 'success' })
      )
      dispatch(clearaddsuccesscommisonflag())
    }
  }, [commisionmanualdataflag, JSON.stringify(commisionmanualdata)])


  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      // console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);


  const addapicall = async (val) => {

    val = { ...val }
    let body = {
      "agent_code": val.agent_code,
      "plan_type": val.plan_type,
      "unit": val.unit,
      "dateTimePicker": new Date().getTime(),
    }

    // console.log("val===>>>", val);


    if (Object.keys(body).length > 0) {
      // console.log("body-----", body);
      let dataset = await store.dispatch(commissionManualEntryDataForm(body));
      // console.log("Response dataset", dataset);
      if (dataset && dataset.payload && dataset.payload.status == 'error') {
        store.dispatch(
          setSnackbar({ open: true, message: dataset.payload.message ? dataset.payload.message : 'Something went wrong', status: 'error' })
        )
      }
    }
  };


  const commissionManualEntry = useRef({
    id: "commissionManual",
    formtype: "add",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      status: 0,
    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 15,
        heading: "Agent Code",
        label: "Agen Code",
        name: "agent_code",
        className: "form_item inputBlock agentCode",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 3,
        heading: "Plan Type",
        label: "Plan Type",
        name: "plan_type",
        className: 'list_search_item planType ',
        type: 'select',
        multiple: false,
        values: [
          { val: "single", name: "Single" },
          { val: "single+1", name: "Single + 1" },
          { val: "Family", name: "Family" }
        ],
        sx: { m: 1, minWidth: 120 },
        payloadFormat: { key: "status" },
      },

      {
        id: 18,
        heading: "Unit",
        label: "Unit",
        name: "unit",
        className: "form_item inputBlock unit",
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 8,
        heading: "Choose Date",
        // label: "Set Meeting Date Time",
        name: "dateTimePicker",
        className: "dateTimePicker",
        type: "datePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          invalid: "Invalid Date Time Format",
          custom: "Value is Invalid",
        },
        sx: { m: 1, width: 300 },
        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
        // defaultValue: new Date().getTime(), // Always use new Date().getTime() to convert into miliseconds
      },
   
    ],
  });



  return (
    <>
      <div className='commission_ManualENtry_wrpr'>
        <div className='manualENtry_title'>
          <h1>Commission Manual Entry</h1>
        </div>
        <div className='manuelENtry_mainWrpr'>
          <Form formData={commissionManualEntry.current} />
          {commissiondataLoader ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}
        </div>
      </div>
    </>
  )
}

export default CommissionManualEntry