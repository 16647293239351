import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { updateStages } from './LeadReducer'
import { setSnackbar } from '../../Layout/layoutReducer';


const LeadStageModal = (props) => {
    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const dispatch = useDispatch();

    // ----------------------------------------Global State from Redux---------------------------------------- //
    const loader = useSelector(state => (state.LeadReducer?.loading) ? state.LeadReducer?.loading : false)
    const stageData = useSelector(state => (state.LeadReducer?.FetchStages) ? state.LeadReducer?.FetchStages : [])
    const loggedUserId = useSelector((state) => state.loginSlice?.userInfo?._id ? state.loginSlice.userInfo._id : null)
    const updateStageLoader = useSelector((state) => state.LeadReducer?.updatestageloader ? state.LeadReducer.updatestageloader : false)

    // ----------------------------------------Components Private State---------------------------------------- //
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null);

    useEffect(() => {
        if (stageData.length > 0) setOpenDropDown(false)
    }, [stageData])

    // -------------   ------------------
    useEffect(() => {
        if (clicked) {
            const data = {
                stage_slug: clicked.key,
                stage_name: clicked?.label,
                added_by: loggedUserId,
                added_for: props?.UserId ? props.UserId : undefined,
                is_delete: clicked.status === 0 ? 0 : 1,
                status: clicked.status === 0 ? 1 : 1
            }
            dispatch(updateStages(data));
            setOpenDropDown(false);
            setTimeout(() => dispatch(setSnackbar({ open: true, message: 'Stage Assigned Successfully!!', status: 'success' })), 3000);
        }
    }, [clicked])

    // --------------------------------------------Other Handlers Goes Here-------------------------------------------- //
    function ModalClose() {
        props.tooglefunc(false);
        setOpenDropDown(false)
    }

    // ----------------------------
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            }
        }
    };

    //----------------------------------------------return-----------------------------------------------//
    return (
        <>
            {/* ------ Confirmation Modal ------ */}
            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="BoxMui_modal configure-role"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        textAlign: "center",
                    }}>
                    <Typography id="modal-modal-title" variant="h6" component="h6">
                        {loader ? <LinearProgress /> : ""}
                        Manage Stage For: {props.UserName}
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}
                    <form>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Select Stage</InputLabel>
                            <Select
                                open={openDropDown}
                                onOpen={() => setOpenDropDown(true)}
                                onClose={() => setOpenDropDown(false)}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                // multiple
                                value={stageData.filter((item) => item.status === 1).map((item) => item)}
                                input={<OutlinedInput label="tag" />}
                                renderValue={(stageData) => stageData.map(stage => stage.label).join(', ')}
                                MenuProps={MenuProps}
                            >
                                {stageData.map((item) => (
                                    <MenuItem key={item.key} value={item} onClick={() => setClicked(item)}>
                                        <Checkbox checked={Boolean(item.status)} />
                                        <ListItemText primary={item.label} />
                                    </MenuItem>
                                ))}

                            </Select>

                            {updateStageLoader ?
                                <LinearProgress />
                                : false}

                            {(props.fromDashbord !== true) && <Stack direction="row" spacing={1} sx={{
                                marginTop: "10px",
                            }}>

                                <div className='ChipsSet-grp' sx={{
                                    display: "flex",
                                    width: '100%',
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    margin: "0 auto",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}>

                                    {/* {stageData.length > 0
                                        ? stageData.filter((tag) => tag.status === 1).map((val) => <Chip
                                            label={val.label}
                                            variant="outlined"
                                            sx={{ margin: '3px 3px' }}
                                        // onDelete={() => deleteChips(val)}
                                        />)
                                        : (<h3> No Stage Assigned</h3>)} */}

                                    {stageData.length > 0 && loader === false && stageData.filter((tag) => tag.status === 1).length > 0
                                        ? stageData.filter((tag) => tag.status === 1).map((val) => <Chip
                                            label={val.label}
                                            variant="outlined"
                                            sx={{ margin: '3px 3px' }}
                                        // onDelete={() => deleteChips(val)}
                                        />)
                                        : stageData.length > 0 && loader === false && stageData.filter((tag) => tag.status === 1).length === 0 ?
                                            (<h3> No Stage Assigned</h3>)
                                            : ""}


                                </div>

                            </Stack>}

                        </FormControl>
                    </form>

                    {/* -------------------------------------- */}

                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>
        </>
    )
}


export default LeadStageModal;