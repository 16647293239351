import { Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { inputExternalPlugin } from "../../../../helper/helperFunctions";
import { TrainingCenterCategory } from "./TrainingCenterCategory/TrainingCenterCategory";
import bannerImage from "../../../../assets/image/training-center-top-Banner.jpg";
export const TrainingCenter = () => {
  const [isScriptTag, setIsScriptTag] = useState(false);

  useEffect(() => {
    if (!isScriptTag) {
      inputExternalPlugin(
        "training-center",
        "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/elements.js"
      );
      setIsScriptTag(true);
    }
  }, [isScriptTag]);

  let loader = useSelector((state) =>
    state.trainingCenterCategorySlice?.loading || state.trainingSlice?.loading
      ? true :false
  );
  const myStyle = { display: "block", width: "100%" };

  return (
    <>
      <div className="trainingCenter_main " >

        <div className="trngCentr_banner_mainwrapper">
          <div className="trngCentr_banner_subwrapper">
            <div className="commonWidth_training">
              <div className="trngCentr_banner_blockwrap">
                <div className="trngCentr_block_subwrap">

                  <img className="paralgrm1" src="https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/txt_bnr_side.png" alt="" />
                  <img className="paralgrm2" src="https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/bnr_side1.png" alt="" />
                  <img className="paralgrm3" src="https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/bnr_side2.png" alt="" />
                  <img className="paralgrm4" src="https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/bnr_side3.png" alt="" />
                  <img className="paralgrm5" src="https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/txt_bnr_side2.png" alt="" />
                  <div className="trngCentr_block_text">
                    <h3>You can easily complete your training from here. If there is a video/audio you need to complete that first before moving on to the next lessons.</h3>



                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>



        {loader ? (<Box sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}><LinearProgress /> </Box>) : null}

        <div className="trainingCenter_Sub">
          <div className="commonWidth_admin">
            <TrainingCenterCategory />
          </div>
        </div>
      </div>
    </>
  );
};
