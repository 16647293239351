import { Button, LinearProgress, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { TableSkeleton } from '../../../helper/TableSkeleton';
import { bookedSlotListData, clearEventListing } from '../calenderReducer';
import { useDispatch, useSelector } from 'react-redux';
import ListingTable from '../../../listing/listing';
import { deleteTempSearchEndpoint, setEnableRowLoader, setReloadTableFlag } from '../../../listing/listReducer';
import { resetFormData, resetautocompletelist } from '../../../form/formReducer';
import { useLocation, useSearchParams } from 'react-router-dom';
import PreviewModal from './PreviewModal';
import AddEditAttendeeModal from './AddEditAttendeeModal';
const EventListing = () => {


    const dispatch = useDispatch();
    const [selectMenu, setSelectMenu] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const Location = useLocation()
    const [previewOpen, setPreviewOpen] = useState(false);
    const [attendeeOpen, setAttendeeOpen] = useState(false);

    const [rowClickData, setRowClickData] = useState({})

    const previewBtnClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.upcomingEvents?.viewBtnAction ? state.tableSlice.customButtonClicked.upcomingEvents.viewBtnAction : false);
    const addAttendeeBtnClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.upcomingEvents?.addediattndee ? state.tableSlice.customButtonClicked.upcomingEvents.addediattndee : false);
    const rowDataEvents = useSelector((state) => state.tableSlice?.tempRowData?.upcomingEvents ? state.tableSlice.tempRowData.upcomingEvents : null);

    let localTimeZOne = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log("offset====>>>>", localTimeZOne);


    useEffect(() => {
        dispatch(bookedSlotListData(tableForAllUpcomingEvents.current.reqBody))
    }, [])

    const upcomingEventData = useSelector(state => (state.calenderEventsStore?.bookedSlotList) ? state.calenderEventsStore.bookedSlotList : []);
    let loader = useSelector(state => state.calenderEventsStore.loading ? state.calenderEventsStore.loading : false)

    // let loader = true;

    useEffect(() => {
        console.log("Location", searchParams.get('leademail'));
    }, [searchParams])

    const anyDate = new Date(upcomingEventData.startUnixVal)
    console.log("offset1====>>>>", anyDate);



    // let x = document.querySelectorAll(".beto")?.parentElement.closest("tr")
    // console.log("x=====>>>>>>", x);

    // if (upcomingEventData.length > 0) {

    // var divs = document.querySelectorAll(".beto");
    // var divMain = document.querySelector(".rolelist_mainwrp_Table");
    // console.log("divMain=====>>>>", divMain);
    // if (divMain) {
    //     if (divs && divs.length > 0) {
    //         console.log("divs=====>>>>>>", divs);



    //         divs.forEach(function (div) {

    //             // Get the closest parent tr element
    //             var tr = div.closest('tr');
    //             console.log("tr=====>>>>>>1", tr);
    //             // Add inline style to set background color
    //             if (tr) {
    //                 // setTimeout(() => {
    //                 tr.classList.add('betoClass');
    //                 tr.style.display="none"
    //                 console.log("tr=====>>>>>>2", tr);

    //                 // }, 1000);

    //             }

    //             // tr.style.backgroundColor = 'yellow';

    //         });
    //     }
    // }
    // const highlightRows = () => {
    //     console.log("======>>>>>>>>>>hit");
    //     var divs = document.querySelectorAll(".beto");
    //     if (divs && divs.length > 0) {
    //         console.log("divs=====>>>>>>", divs);



    //         divs.forEach(function (div) {

    //             // Get the closest parent tr element
    //             var tr = div.closest('tr');
    //             console.log("tr=====>>>>>>", tr);
    //             // Add inline style to set background color
    //             if (tr) {
    //                 // loader = true
    //                 setTimeout(() => {

    //                     tr.classList.add('betoClass');
    //                     console.log("tr=====>>>>>>", tr);
    //                     // setDataLoad(true)
    //                     // loader = false

    //                 }, 1000);

    //             }

    //             // tr.style.backgroundColor = 'yellow';

    //         });
    //     }
    // }
    // }

    // document.addEventListener('DOMContentLoaded', function() {
    //     highlightRows();
    //   });


    // ==========================Preview Button Funcitonality =========================
    useEffect(() => {
        if (previewBtnClicked === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "upcomingEvents",
                    rowId: rowDataEvents._id,
                    load: true,
                })
            );
            setTimeout(() => {
                setPreviewOpen(true)
            }, 100)
            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "upcomingEvents",
                        rowId: rowDataEvents._id,
                        load: false,
                    })
                );
            }, 500);
        }
    }, [previewBtnClicked])

    // ===========================Add/Edit button Funcitonality =========================
    useEffect(() => {
        if (addAttendeeBtnClicked === true) {
            console.log("setAttendeeOpen", addAttendeeBtnClicked)

            setAttendeeOpen(true)
        }
    }, [addAttendeeBtnClicked])

    useEffect(() => {
        console.log("setAttendeeOpen", attendeeOpen)
    }, [attendeeOpen])




    const eventButtonClicked = (val) => {
        setSelectMenu(val)
        if (val === 2) {
            setLoading(true)
            tableForAllUpcomingEvents.current.reqBody.searchcondition = {};
            tableForAllUpcomingEvents.current.tableTitle = "Past Events"
            tableForAllUpcomingEvents.current.searchData.heading = "Search Past Events"
            tableForAllUpcomingEvents.current.reqBody.searchcondition = {
                "tstamp": { "$lte": moment().valueOf() },
                "userid": { "$in": ['659d30c8fddb573879253cc2', '645b27af0308b0288a86a4ff', '645b27d70308b0288a86a501', '645b27230308b0288a86a4fb', '645b27550308b0288a86a4fd'] },
                "status": { "$ne": 2 },
                // "timeZone": {localTimeZOne},
            }
            if (searchParams.get('leademail')) {
                tableForAllUpcomingEvents.current.reqBody.searchcondition['atten_email'] = { $elemMatch: { email: searchParams.get('leademail') } }
            }
            dispatch(setReloadTableFlag({ tableId: "allEventData" }))
            dispatch(deleteTempSearchEndpoint({ tableId: "allEventData" }));
            dispatch(resetFormData({ formId: "searchEventsList" }));
            dispatch(resetautocompletelist());
            dispatch(bookedSlotListData(tableForAllUpcomingEvents.current.reqBody))
            dispatch(clearEventListing())
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
        if (val === 1) {
            setLoading(true)
            tableForAllUpcomingEvents.current.reqBody.searchcondition = {};
            tableForAllUpcomingEvents.current.tableTitle = "Upcoming Events"
            tableForAllUpcomingEvents.current.searchData.heading = "Search Upcoming Events"
            tableForAllUpcomingEvents.current.reqBody.searchcondition = {
                "tstamp": { "$gte": moment().valueOf() },
                "userid": { "$in": ['659d30c8fddb573879253cc2', '645b27af0308b0288a86a4ff', '645b27d70308b0288a86a501', '645b27230308b0288a86a4fb', '645b27550308b0288a86a4fd'] },
                "status": { "$ne": 2 },
                // "timeZone": {localTimeZOne},

            };
            if (searchParams.get('leademail')) {
                tableForAllUpcomingEvents.current.reqBody.searchcondition['atten_email'] = { $elemMatch: { email: searchParams.get('leademail') } }
            }
            dispatch(setReloadTableFlag({ tableId: "allEventData" }))
            dispatch(deleteTempSearchEndpoint({ tableId: "allEventData" }));
            dispatch(resetFormData({ formId: "searchEventsList" }));
            dispatch(resetautocompletelist());
            dispatch(bookedSlotListData(tableForAllUpcomingEvents.current.reqBody))
            dispatch(clearEventListing())
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }


    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})


    useEffect(() => {
        tableForAllUpcomingEvents.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
        })
    }, [JSON.stringify(searchFormData)]);

    var modifyTableHeaders =
        [
            { val: "username", name: "Organizer Name" },
            // { val: "useremail", name: "Organizer Email" },
            { val: "event_title", name: "Event Title" },
            { val: "attendee_details", name: "Attendee Details" },
            { val: "start_end_time_human", name: "Meeting Time" },
            { val: "disposition_show", name: "Last Disposition" },
            // { val: "slot", name: "Slot Time" },
            { val: "startUnixVal", name: "Appointment Date", type: "datetime", format: "MM/DD/YYYY" },
            // { val: "timespan", name: "Timespan(minutes)" },
            { val: "booking_time_unix", name: "Booked On", type: "datetime", format: "MM/DD/YYYY" },
            // { val: "startUnixVal", name: "Meeting Start", type: "datetime", format: "hh:mm A" },
        ];

    let tableForAllUpcomingEvents = useRef({
        tableId: "upcomingEvents",
        tableTitle: "Upcoming Events",
        showReload: true,
        showFilter: false,
        showColorInTr: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "lead-manage/get-lead-calendar-data",
            tableCountEndpoint: "lead-manage/get-lead-calendar-data",
        },

        reqBody: {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "startUnixVal",
                "type": "asc"
            },
            "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
            "searchcondition": {
                "tstamp": { "$gte": moment().valueOf() },
                "userid": { "$in": ['659d30c8fddb573879253cc2', '645b27af0308b0288a86a4ff', '645b27d70308b0288a86a501', '645b27230308b0288a86a4fb', '645b27550308b0288a86a4fd'] },
                "atten_email": searchParams.get('leademail') ? { $elemMatch: { email: searchParams.get('leademail') } } : undefined,
                "status": { "$ne": 2 },
            },
            "count": false,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["slot", "event_title", "startUnixVal"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to delete these records?</p>",
                modalHeader: "<p> Alert !! </p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    // endpoint: "manage-form/delete-field",
                    body: {
                        "source": "field_list",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],
        buttons: [
            {
                id: "viewBtnAction",
                label: "Preview",
                type: "other",
                icon_type: "preview",
                name: "preview",
            },
            process.env.REACT_APP_ENVIRONMENT !== "prod" && {
                id: "addediattndee",
                label: "Add/Edit Attendee",
                type: "other",
                icon_type: "person_add_alt_rounded_icon",
                name: "person_add_alt_rounded_icon",
            },
        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },
        searchData: {
            heading: "Search Upcoming Events",
            type: "drawer",
            formData: {
                id: 'searchEventsList',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [

                    {
                        id: 0,
                        label: "Search by Organiser Name",
                        name: "username",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "username", param: "$regex", options: "i" },

                    },

                    {
                        id: 1,
                        label: "Search by Event Title",
                        name: "event_title",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "event_title", param: "$regex", options: "i" },

                    },

                    {
                        id: 200,
                        label: "Search by Attendee Name",
                        name: "attendee_name",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "atten_email.name", param: "$regex", options: "i" },

                    },

                    {
                        id: 3000,
                        label: "Search by Attendee Email",
                        name: "attendee_email",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "atten_email.email", param: "$regex", options: "i" },

                    },

                    {
                        id: 4,
                        label: "Search by Disposition",
                        name: "disposition_show",
                        type: "select",
                        multiple: false,
                        values: [
                            { val: "New Lead", name: "New Lead" },
                            { val: "Accepted", name: "Accepted" },
                            { val: "Declined", name: "Declined" },
                            { val: "Advisor/AE Contacted", name: "Advisor/AE Contacted" },
                            { val: "Advisor/AE Meeting Set", name: "Advisor/AE Meeting Scheduled", },
                            { val: "Advisor/AE Meeting Completed", name: "Advisor/AE Meeting Completed", },
                            { val: "Advisor/AE Meeting Missed", name: "Advisor/AE Meeting Missed", },
                            { val: "Discovery Call Set", name: "Discovery Call Scheduled" },
                            { val: "Discovery Call Completed", name: "Discovery Call Completed", },
                            { val: "Discovery Call Cancelled", name: "Discovery Call Cancelled", },
                            { val: "Discovery Call Missed", name: "Discovery Call Missed", },
                            { val: "Contract Sent", name: "Contract Sent" },
                            { val: "Contract Review Scheduled", name: "Contract Review Scheduled", },
                            { val: "Contract Review Completed", name: "Contract Review Completed", },
                            { val: "Contract Signed", name: "Contract Signed" },
                            { val: "Contract Completed", name: "Contract Completed" },
                            { val: "Data Call Requested", name: "Data Call Requested" },
                            { val: "Data Call Scheduled", name: "Data Call Scheduled" },
                            { val: "Data Call Completed", name: "Data Call Completed" },
                            { val: "Link Sent", name: "Link Sent" },
                            { val: "Has Contracts No Claims Uploaded", name: "Has Contracts No Claims Uploaded", },
                            { val: "Has Claims No Contracts Uploaded", name: "Has Claims No Contracts Uploaded", },
                            { val: "Data and Contracts Upload Completed", name: "Data and Contracts Upload Completed", },
                            { val: "Initial Evaluation", name: "Initial Evaluation" },
                            { val: "On Hold", name: "On Hold" },
                            { val: "Collection Begun", name: "Collection Begun" },
                            { val: "Follow-Up Collection", name: "Follow-Up Collection" },
                            { val: "In Settlement", name: "In Settlement" },
                            { val: "Account Cancelled", name: "Account Cancelled" },
                            { val: "Lost", name: "Lost" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: {
                            key: "disposition_data.disposition_name",
                        },
                    },

                    {
                        id: 6,
                        //   heading: "Search By Creation Time",
                        label: "Search by Appointment Start Date",
                        name: "start_date_by_appointment",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "startUnixVal", param: "$gte" }
                    },
                    {
                        id: 7,
                        label: "Search by Appointment End Date",
                        name: "end_date_by_appointment",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "startUnixVal", param: "$lte" }
                    },

                    {
                        id: 8,
                        //   heading: "Search By Creation Time",
                        label: "Search by Booking Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "booking_time_unix", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Booking End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "booking_time_unix", param: "$lte" }
                    },
                ]
            }
        },

    });

    useEffect(() => {
        if (rowDataEvents && Object.keys(rowDataEvents).length > 0) {
            setRowClickData(rowDataEvents)
        }
    }, [rowDataEvents])



    return (
        <>
            <div className='bluecoastLead_list_mainwrpr'>
                <div className='click_buttonwp navigationMain'>
                    <Button id="buttonsix" className={selectMenu === 1 ? "active" : ""} variant="contained" onClick={() => eventButtonClicked(1)} >
                        <p> Upcoming Events </p>
                    </Button>
                    <Button id="buttonsix" className={selectMenu === 2 ? "active" : ""} variant="contained" onClick={() => eventButtonClicked(2)}>
                        <p> Past Events </p>
                    </Button>
                </div>
                {loader ? <LinearProgress /> : ""}
                <div className="rolelist_mainwrp">
                    <div className='listing_heading'>
                        {selectMenu === 1 ? <h1>Upcoming Events</h1> : <h1>Past Events</h1>}
                    </div>
                    <div className='calendar_timeZone'>
                        <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ p: 10 }}>
                            <h3>Your are currently viewing in {localTimeZOne} timezone</h3>
                        </Typography>
                    </div>
                    <div className='rolelist_mainwrp_Table'>
                        {!loader ? (<ListingTable tableData={tableForAllUpcomingEvents.current} dataset={upcomingEventData} modifyHeaders={modifyTableHeaders} />) : (<TableSkeleton count={5} />)}
                    </div>
                </div>
            </div>
            {previewOpen ? <PreviewModal previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} /> : ""}
            {attendeeOpen && Object.keys(rowClickData).length > 0 ? <AddEditAttendeeModal attendeeOpen={attendeeOpen} setAttendeeOpen={setAttendeeOpen} rowData={rowClickData} /> : ""}
        </>
    )
}

export default EventListing