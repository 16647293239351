import React from 'react'
import "../LandingPage9/LandingPage9.css"
import Lp15AspireBlCstForm from './Lp15AspireBlCstForm'

function LandingPage15BlueCoast() {
  return (
    <>
    <div className='LandingPage9Body'>
        <div className='LandingPage9Body_wrapper'>
            <div className='LandingPage9Body_Top'>
                <div className='LandingPage9Body_TopLeft'> <img src='https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp' alt=''/> </div>
                <div className='LandingPage9Body_TopRight'>
                    <h2><span>ACCESS DISCOUNT HEALTHCARE ARE OFFERING OUR</span></h2>
                    <h2><span>PHARMACY DISCOUNT PROGRAMS TO GROUPS!</span></h2>
                    <h3><span>ENTER YOUR</span> <strong>INFORMATION BELOW!</strong></h3>
                </div>
            </div>

            <div className='LandingPage9Body_Formwrapper'>
                <div className='LandingPage9Body_Formwrapper_con prospect_Details'>
                    <div className='LandingPage9FormCon'>
                        <Lp15AspireBlCstForm  flag={2}/>
                    </div>
                </div>
            </div>

            <div className='LandingPage9BottomLogo'>
                <span> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img></span>
            </div>

            {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_img1.webp' className='landingpage9_img1'></img> */}
        </div>


    </div>
</>
  )
}

export default LandingPage15BlueCoast