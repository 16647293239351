import { Grid } from '@mui/material'
import moment from 'moment';
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import EmployeePackageData from './EmployeePackageData';
import { employeePackageData } from '../../../EmployerReducer';

const EmployeeDashboard = () => {

    const dispatch = useDispatch()

    // const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});


    // const employeeData = useSelector((state) => state.EmployerReducer?.employeePackageAllData && Object.keys(state.EmployerReducer.employeePackageAllData).length > 0 ? state.EmployerReducer?.employeePackageAllData : null)


    const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
    let lastloginTime = useSelector(state => state.loginSlice.last_login_time);

    // console.log(userInfo, "userInfo======>>>")

    useEffect(() => {
        let reqBody = {
            "_id": userInfo?.userinfo?._id
        }


        dispatch(employeePackageData(reqBody))

        // console.log("employeeData====>>>", employeeData)
    }, [])


    return (
        <>
            {/* <h1 className="page_heading">Employee Dashboard</h1> */}

            <Grid container spacing={1} className="dashboard_con">
                <Grid item md={12} xs={12}>
                    <div className="dashboard_block1 dashboardCommon">
                        <h2>
                            <span>Welcome:</span>{" "}
                            {userInfo.userinfo?.firstname + " " + userInfo.userinfo?.lastname}{" "}
                        </h2>

                        {/* {userInfo.userinfo?.location_id && <h2>
                            <span>Location Name :</span>{" "}
                            {userInfo.userinfo?.location_id}
                        </h2>} */}

                        <h3>
                            <span>Last Login:</span>{" "}
                            {lastloginTime !== null
                                ? moment(Number(lastloginTime)).format("dddd, MMMM Do YYYY, h:mm a")
                                : "N/A"}
                        </h3>
                    </div>
                </Grid>
            </Grid>

            {userInfo?.userinfo?.main_role == "is_employee" && <EmployeePackageData />}
        </>
    )
}

export default EmployeeDashboard