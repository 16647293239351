
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from '../../../store'

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { clearSnakebarData, FetchTagDetails, updateTags } from './LeadReducer';



const LeadTagModal = (props) => {
    console.log("porpsss", props);


    const dispatch = useDispatch(); // ---- Dispatch -----


    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null)
    const [snackMsg,setSnackMsg]=useState(null)

    console.warn("<<<<<< props goes here >>>>>>", props)

    const loader = useSelector(state => (state.LeadReducer?.loadingTag) ? state.LeadReducer?.loadingTag : false)

    const tagsDetails = useSelector(state => (state.LeadReducer?.FetchTagDetailsData) ? state.LeadReducer?.FetchTagDetailsData : [])
    console.log("lead tagsDetails",tagsDetails);
    const updateTagData = useSelector(state => (state.LeadReducer?.updateTagData) ? state.LeadReducer?.updateTagData : {})

    const success = useSelector(state => (state.LeadReducer?.resMessage) ? state.LeadReducer?.resMessage : null)
    const tagSuccess = useSelector(state => (state.LeadReducer?.upgradeTagStatus) ? state.LeadReducer?.upgradeTagStatus : null)
    const tagSuccessMessage = useSelector(state => (state.LeadReducer?.upgradeTagMassage) ? state.LeadReducer?.upgradeTagMassage : null)
    const loggedUserId = useSelector((state) => state.loginSlice?.userInfo?._id ? state.loginSlice.userInfo._id : null)

   console.log("for tag tagSuccess",tagSuccess);


  useEffect(()=>{
    if( Object.keys(updateTagData).length > 0 ){
        const data = {
            "_id": props.UserId,
            "roles": { $in: ["is_lead"] },
            "project": { "subject": 1 },
          }
          dispatch(FetchTagDetails(data));
    }
    
  },[updateTagData])

//   useEffect(()=>{
    
//   },[updateTagData])

    useEffect(() => {
        if (success || tagSuccess==="success") {
            setopen(true)
            setTimeout(() => {
                dispatch(clearSnakebarData())
            }, 1000);
        }
    }, [success,tagSuccess])


    useEffect(() => {
        if (tagsDetails.length > 0) {
            setOpenDropDown(false)
        }
    }, [JSON.stringify(tagsDetails)])




    // useEffect(() => {
    //     props.setModalResponse(false);
    // }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        // props.setModalResponse(false);
        props.tooglefunc(false);
        setOpenDropDown(false)
    }

    // ----------- Delete Chips ----------------------
    const deleteChips = (dltVal) => {

        const modifiedTagDetails = tagsDetails.map((tag) => ({
            tag_slug: tag.key,
            added_by: loggedUserId,
            tag_name: tag.label,
            added_for: props.UserId,
            is_delete: dltVal.status === 0 ? 0 : 1,
            status: (dltVal.key === tag.key) ? dltVal.status === 0 ? 1 : 0 : tag.status,
            updated: (dltVal.key === tag.key) ? true : false
        }))
        console.log("modifiedTagDetails==========>", modifiedTagDetails)

        dispatch(updateTags({ data: modifiedTagDetails, _id: props.UserId }));

        setSnackMsg("Tag  Removed Successfully")
        setopen(true)

     
    };
    // --------------------------------

    useEffect(() => {
        if (clicked) {

            const modifiedTagDetails = tagsDetails.map((tag) => ({
                tag_slug: tag.key,
                added_by: loggedUserId,
                tag_name: tag.label,
                added_for: props.UserId,
                is_delete: clicked.status === 0 ? 0 : 1,
                status: (clicked.key === tag.key) ? clicked.status === 0 ? 1 : 0 : tag.status,
                updated: (clicked.key === tag.key) ? true : false
            }))

            dispatch(updateTags({ data: modifiedTagDetails, _id: props.UserId }));
            setOpenDropDown(false)

            setSnackMsg("Tag  Assigned Successfully")
            setopen(true)
            
        }
    }, [clicked])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    // const showTags = () => {
    //     if (tagsDetails && tagsDetails?.length > 0) {
    //         let tags = tagsDetails.filter((tag) => tag.status === 1).map((val) => {
    //             return <Chip label={val.label} variant="outlined" sx={{ margin: '3px 3px' }} onDelete={() => deleteChips(val)} />
    //         })
    //     }

    //     tagsDetails?.length > 0 ? tagsDetails.filter((tag) => tag.status === 1).map((val) => {

    //     }
    //     ) : (<h3> No Tags Assigned</h3>)
    // }



    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h6">
                        {loader ? <LinearProgress /> : ""}

                        Configure Tags for: {props.UserName}
                    </Typography>

                    {/* ----- Dropdown for selecting roles ----- */}

                    <form>

                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Select Tags</InputLabel>
                            <Select
                                open={openDropDown}
                                onOpen={() => setOpenDropDown(true)}
                                onClose={() => setOpenDropDown(false)}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={tagsDetails.filter((item) => item.status === 1).map((item) => item)}
                                input={<OutlinedInput label="tag" />}
                                renderValue={(tagList) => tagList.map(tag => tag.label).join(', ')}
                                MenuProps={MenuProps}
                            >
                                {tagsDetails.map((item) => (
                                    <MenuItem key={item.key} value={item} onClick={() => setClicked(item)}>
                                        <Checkbox checked={Boolean(item.status)} />
                                        <ListItemText primary={item.label} />
                                    </MenuItem>
                                ))}

                            </Select>
                            {tagsDetails.length > 0 && 
                             <Stack direction="row" spacing={1} sx={{
                                marginTop: "10px",
                            }}>

                                <div className='ChipsSet-grp' sx={{
                                    display: "flex",
                                    width: '100%',
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    margin: "0 auto",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}>
                                    


                                    {tagsDetails.length > 0 && loader === false && tagsDetails.filter((tag) => tag.status === 1).length > 0
                                            ? tagsDetails.filter((tag) => tag.status === 1).map((val) => <Chip
                                                label={val.label}
                                                variant="outlined"
                                                sx={{ margin: '3px 3px' }}
                                                onDelete={() => deleteChips(val)}
                                            />)
                                            : tagsDetails.length > 0 && loader === false && tagsDetails.filter((tag) => tag.status === 1).length === 0 ?
                                            (<h3> No Tags Assigned</h3>)
                                            : ""}
                                </div>


                            </Stack>
                            }

                           



                        </FormControl>

                        {/* <Button variant="contained" onClick={ModalClose} className="modalBTN">Cancel</Button> */}
                    </form>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {open && props.fromDashbord !== true ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={snackMsg}
            // action={action}
            />) : ""}

            {console.log("lead list Tag manage data",tagsDetails)}

        </>
    )
}


export default LeadTagModal;










