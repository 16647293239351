import { Button, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { MdDashboard } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { resolvefunction } from '../../helper/helperFunctions';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MdKeyboardArrowDown, MdOutlineModelTraining } from "react-icons/md";
import { getusersList } from '../Backend/userManagement/userList/userListReducer';
import { setAffiliateInitData } from '../Backend/AffiliateManagement/AffiliateReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getcommisionReportData } from '../Backend/AffiliateManagement/W9FormReducer';
import { Cookies, useCookies } from 'react-cookie';



function AffiliateMenu({ topmenu }) {
  // const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const cookie = new Cookies();
  const navigate = useNavigate()


  const splitLocation = pathname.split("/");
  const [openModalCampaign, setOpenModalCampaign] = React.useState(false);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [openMoadl, setOpenMoadl] = React.useState(false);
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

  // console.warn("cookies=====>>>", userInfo)


  const closeModalCampaign = () => {
    setOpenModalCampaign(false)
  };

  const open4 = Boolean(anchorEl4);
  const handleClose4 = () => setAnchorEl4(null);
  const handleClick4 = (event) => setAnchorEl4(event.currentTarget);

  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo: {});

  const payload_affiliatelist = {
    "condition": {
      "limit": 30, "skip": 0
    },
    "sort": {
      "field": "createdon_datetime", "type": "desc"
    },
    "searchcondition": {
      "roles.is_affiliate": 1
    },
    "count": false
  }

  // const downloadEmployeeData = () => {
  //   fetch('https://all-frontend-assets.s3.amazonaws.com/Aspire_Free_Pharmacy_Savings_Overview.pdf')
  //     .then(response => {
  //       response.blob().then(blob => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement('a');
  //         a.href = url;
  //         a.download = 'Aspire_Free_Pharmacy_Savings_Overview.pdf';
  //         a.click();
  //       });
  //       //window.location.href = response.url;
  //     });
  // }




  const closeModal = () => {
    setOpenMoadl(false)


};
const openModal = () => {
    setOpenMoadl(true)


};

const commisionBody = {
  "condition": {
    "limit": 30, "skip": 0
  },
  "sort": {
    "field": "record_date", "type": "desc"
  },
  "searchcondition":  { user_id: logedUserData._id },
  "count": false
}


  return (
    <div>
      <ul>

        <li>
          <button onClick={() => { navigate("/affiliate-dashboard") }} className={(pathname && pathname == '/affiliate-dashboard') ? 'active' : ''}>
            <MdDashboard />

            <span>Dashboard</span>
          </button>
        </li>

        {/* <li>
          <button onClick={() => openModal()}>

          
            <span>Enrollment Form List</span>
          </button>
        </li>  */}


         <li>
          <button onClick={() => { navigate("/enrollment-list") }} className={(pathname && pathname == '/enrollment-list') ? 'active' : ''}>
            <span>Landing Page Forms Filled Out</span>
          </button>
        </li>

        {process.env.REACT_APP_ENVIRONMENT != 'prod' &&
        <li>
          <button onClick={() => resolvefunction(navigate, "/affiliate-report", getcommisionReportData(commisionBody))} className={(pathname && pathname == '/affiliate-report') ? 'active' : ''}>
            <span>commission</span>
          </button>
        </li>}

        




      

        <li>
            <button onClick={() => { navigate("/marketing-campaign-instruction") }} className={(pathname && pathname == '/marketing-campaign-instruction') ? 'active' : ''}>
              <span>Marketing Campaign Instructions</span>
            </button>
          </li>


         
          <li>
          <Button
              id="demo-positioned-button"
              aria-controls={open4 ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open4 ? "true" : undefined}
              onClick={handleClick4}
              endIcon={<KeyboardArrowDownIcon />}
              className={
                pathname == "/training-category/list" ||
                  pathname == "/training-center"
                  ? "active"
                  : "inactive"
              }
            >


              <MdOutlineModelTraining />
              <span>Miscellaneous</span>
              <MdKeyboardArrowDown className="leftArrowIcon" />
            </Button>

          </li>

          <li>
            <Menu
              // className="addVid_dropDwn"
              className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}

              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl4}
              open={open4}
              onClose={handleClose4}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >


              {/* <MenuItem
                onClick={() => {
                  handleClose4();

                  window.open('/download-w9form/'+userInfo.userinfo._id, "target_blank")
                }}
              >
                <span> Download W9-Form </span>
              </MenuItem> */}

              <MenuItem
                onClick={() => {
                  handleClose4();
                  window.open('/download-nda-signature/'+userInfo.userinfo._id, "target_blank")


                }}
              >
                <span> Download NDA  </span>
              </MenuItem>

              {/* <MenuItem
                onClick={() => {
                  handleClose4();
                  window.open('/download-nda-w9form/'+userInfo.userinfo._id, "target_blank")


                }}
              >
                <span> Download NDA & W9-Form </span>
              </MenuItem> */}

              
            </Menu>
          </li>



      </ul>

    


      <div>
        <Modal
          classname="image_card_modal modalBaseStyle SD_marketing_modal"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className='modalBaseBox largeModal marketin_campaign'>
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            
            <h2>Coming Soon</h2>

          </Box>
        </Modal>
      </div>

    </div>
  )
}

export default AffiliateMenu