import React, { useEffect, useState } from "react";
import "../../../Pages/Backend/AffiliateManagement/W9Form.css";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  TextareaAutosize,
  Tooltip,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from "react-redux";
import store from "../../../store";
import { getw9Formdata } from "./W9FormReducer";
import { addlandingpageonedata, getAffiliateManageData } from "../../Frontend/LandingPage/LandingpageReducer";
import { redirect, useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { setSnackbar } from "../../Layout/layoutReducer";
import AcceptPrivacy from "./AcceptPrivacy";
import { ConnectingAirportsOutlined, ContactsOutlined } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { W9FormViewData } from "./AffiliateReducer";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

function W9Form(props) {
  const { _id } = useParams();
  const [loading, setloading] = useState(false);
  const [downloadable, setDownloadable] = useState(false);

  const location = useLocation();
  const path = location?.pathname?.split('/');

  // console.warn("<<<<<<< W9Form location Data here =======>>>>>>", location.pathname.split('/'))
  // console.warn("<<<<<<< path here =======>>>>>>", path[1])


  const affiliatePrePopulateData = useSelector((state) => (state.LandingpagefontReducer?.affiliatedata && Object.keys(state.LandingpagefontReducer.affiliatedata).length > 0) ? state.LandingpagefontReducer.affiliatedata : {})
  const data = useSelector((state) => (state.W9FormReducer?.w9Formsubdata) ? state.W9FormReducer.w9Formsubdata : {})
  const affiliateState = useSelector((state) => state.LandingpagefontReducer?.affiliatedata ? state.LandingpagefontReducer.affiliatedata : null)

  // console.warn("<<<<<<< W9Form Data here =======>>>>>>", props.FetchedW9FormData)


  // ============= after endpoint call FetchedW9FormData during download =============== //
  const FetchedW9FormData = useSelector(state => (state.AffiliateReducer?.W9FormData) ? state.AffiliateReducer.W9FormData : [])
  //  ================================================================================= //

  // ==================== loading here ============== //
  const loader = useSelector(state => (state.AffiliateReducer?.loading) ? state.AffiliateReducer.loading : false)
  //  ============================================== //






  useEffect(() => {
    let body = {
      "affiliate_signup_stage": 4,
      "_id": (_id !== undefined && _id !== null) ? _id : undefined,
    }


    if (data === 2) {
      updateApi(body)
    }
    // console.log("data---------", data);

  }, [data])
  const updateApi = async (body) => {
    setloading(true)
    await dispatch(addlandingpageonedata(body));
    dispatch(getAffiliateManageData(_id))
    setloading(false)

  }



  useEffect(() => {
    // console.log("affiliatePrePopulateData------------", affiliatePrePopulateData);
  }, [affiliatePrePopulateData])




  const [agentType, setagentType] = useState("");
  const [payeeNameType, setpayeeNameType] = useState((affiliatePrePopulateData?.firstname != undefined && affiliatePrePopulateData?.lastname != undefined) ? affiliatePrePopulateData.firstname + ' ' + affiliatePrePopulateData.lastname : '');
  const [signAgentType, setsignAgentType] = useState("");
  const [signJointAccountType, setsignJointAccountType] = useState("");
  const [accountNumberType, setaccountNumberType] = useState("");
  const [routingNumberType, setroutingNumberType] = useState("");
  const [billingDateType, setbillingDateType] = useState(moment().valueOf());

  const [nameType, setnameType] = useState((affiliatePrePopulateData?.firstname != undefined && affiliatePrePopulateData?.lastname != undefined) ? affiliatePrePopulateData.firstname + ' ' + affiliatePrePopulateData.lastname : '');
  const [businessNameType, setbusinessNameType] = useState("");
  const [soleType, setsoleType] = useState(false);
  const [corporationType, setcorporationType] = useState(false);
  const [scorporationType, setscorporationType] = useState(false);
  const [partnershipType, setpartnershipType] = useState(false);
  const [stateType, setstateType] = useState(false);
  const [taxType, settaxType] = useState("");
  const [liabilityType, setliabilityType] = useState(false);
  const [instructionType, setinstructionType] = useState(false);
  const [listinstructionType, setlistinstructionType] = useState("");
  const [payeeType, setpayeeType] = useState("");
  const [reportType, setreportType] = useState("");
  const [addressType, setaddressType] = useState(affiliatePrePopulateData.address);
  const [cityType, setcityType] = useState(affiliatePrePopulateData.city + ',' + ' ' + affiliatePrePopulateData.state + ',' + ' ' + affiliatePrePopulateData.zip);
  const [textareaType, settextareaType] = useState("");
  const [accountType, setaccountType] = useState("");
  const [numberOneType, setnumberOneType] = useState("");
  const [numberTwoType, setnumberTwoType] = useState("");
  const [numberThreeType, setnumberThreeType] = useState("");
  const [identityOneType, setidentityOneType] = useState("");
  const [identityTwoType, setidentityTwoType] = useState("");
  const [signatureType, setsignatureType] = useState("");
  const [dateType, setdateType] = useState(moment().valueOf());
  const [visible, setVisible] = useState(true);

  const [submitState, setSubmitState] = useState(1);
  const [error, setError] = useState(false);

  // console.warn("radio button values ======>>>>", soleType, corporationType, scorporationType ,partnershipType , stateType, taxType,liabilityType )

  useEffect(() => {

    if (
      payeeNameType?.length === 0 ||
      routingNumberType?.length === 0 ||
      accountNumberType?.length === 0 ||
      signAgentType?.length === 0 ||
      signatureType?.length === 0 ||
      (identityOneType.length === 0 && numberOneType.length === 0)

    ) {
      setError(true);
      // console.log("error------------1", error);
    } else {
      setError(false);
      // console.log("error------------2", error);
    }

    // console.log("identityOneType---", numberOneType.length, identityOneType.length);
  }, [
    signAgentType,
    signatureType,
    payeeNameType,
    accountNumberType,
    routingNumberType,
    identityOneType,
    numberOneType
  ]);





  // useEffect(() => {
  //   console.log("setnameType-----------", nameType);
  //   console.log("businessNameType-----------", businessNameType);
  //   console.log("addressType-----------", addressType);
  //   console.log("cityType-----------", cityType);
  //   console.log("accountType-----------", accountType);
  //   console.log("dateType-----------", dateType);
  //   console.log("signatureType-----------", signatureType);

  //   if (
  //     payeeNameType?.length === 0 ||
  //     routingNumberType?.length === 0 ||
  //     accountNumberType?.length === 0 ||
  //     nameType?.length === 0 ||
  //     businessNameType?.length === 0 ||
  //     addressType?.length === 0 ||
  //     signatureType?.length === 0 ||
  //     cityType?.length === 0 || 
  //     (identityOneType.length === 0 && numberOneType.length === 0)

  //   ) {
  //     setError(true);
  //     console.log("error------------1", error);
  //   } else {
  //     setError(false);
  //     console.log("error------------2", error);
  //   }

  //   console.log("identityOneType---", numberOneType.length, identityOneType.length);
  // }, [
  //   nameType,
  //   businessNameType,
  //   addressType,
  //   cityType,
  //   signatureType,
  //   payeeNameType,
  //   accountNumberType,
  //   routingNumberType,
  //   identityOneType,
  //   numberOneType
  // ]);














  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 3) {
      setnumberOneType(inputValue);
    }
  };

  const handleInputChange2 = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 2) {
      setnumberTwoType(inputValue);
    }
  };

  const handleInputChange3 = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 4) {
      setnumberThreeType(inputValue);
    }
  };

  const handleInputChange4 = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 2) {
      setidentityOneType(inputValue);
      // console.log("inputvalue-------------", identityOneType);
    }
  };

  const handleInputChange5 = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 7) {
      setidentityTwoType(inputValue);
    }
  };



  const dispatch = useDispatch();
  const handleSubmit = () => {
    setSubmitState(2);

    // setnameType(firstName + ' ' + lastName)

    let obj = {
      billing_payee: payeeNameType,
      billing_date: billingDateType,
      billing_agent_sign: signAgentType,
      billing_joint_account_owner: signJointAccountType,
      account_number: accountNumberType,
      routing_number: routingNumberType,
      name: nameType,
      businessName: businessNameType,
      llc: soleType,
      c_corporation: corporationType,
      s_corporation: scorporationType,
      partnership: partnershipType,
      e_state: stateType,
      liability: liabilityType,
      tax_classifications: taxType,
      instruction: instructionType,
      listinstruction: listinstructionType,
      payee: payeeType,
      fatca_report: reportType,
      address: addressType,
      city: cityType,
      requesters: textareaType,
      list_account: accountType,
      social_security: numberOneType + numberTwoType + numberThreeType,
      employ_identification_no: identityOneType + identityTwoType,
      signature: signatureType,
      date: dateType,
      user_id: (_id !== undefined && _id !== null) ? _id : undefined,
      // affiliate_signup_stage: 3,
    };


    if (error === false) {
      // console.log("obj", obj);
      // console.log("obj1", error);
      dispatch(getw9Formdata(obj))
    }

    setTimeout(() => {

      if (error === true) {
        const collection = document.getElementsByClassName("MuiFormHelperText-root");
        collection[0].scrollIntoView();
      }

    }, 200);




  };
  const handleBackpage = () => {
    window.history.back();
  }


  // ======== if path id download-w9form then fetch  user data =======  //
  useEffect(() => {
    if (path && path.length > 0 && (path[1] === "download-w9form" || path[1] === "download-nda-w9form")) {
      dispatch(W9FormViewData({ "_id": path[2] }))
    }
  }, [JSON.stringify(path)])
  //  ============================================================= //


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  useEffect(() => {
    if (path[1] === "download-nda-w9form" || path[1] === "download-w9form") {
      setDownloadable(true)
      setVisible(false)
    }
  }, [JSON.stringify(path)])








  return (
    <>
      {props.source !== "affiliate_listing" && visible && <span className="back_icon"><Tooltip title="Go Back" arrow><ArrowBackIcon onClick={handleBackpage} /></Tooltip></span>}

      {path[1] === "download-w9form" || path[1] === "download-nda-w9form" && loader && (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>)}



      <div className="w9_mainwrp" id="w9_mainwrp" style={{ "margin-top": "70px" }}>
        {/* <form
        autoComplete="none"
        onSubmit={handleSubmit}
      
      > */}
        <div className="w9_common_width">
          <div className="w9_new_blk">
            <div className="w9_logo_wrp">
              <img src="https://all-frontend-assets.s3.amazonaws.com/Deal_firecrm_logo.png" />
            </div>

            <p>AUTHORIZATION AGREEMENT FOR AUTOMATIC BILLING (ACH)</p>

          </div>
          {/* <div className="w9_flex-wrapper2 w9_agents_block">
            <p>AGENTS NAME:</p><TextField
              hiddenLabel
              id=""
              // defaultValue={firstName + ' ' + lastName}
              variant="filled"
              value={agentType}
              onChange={(e) => setagentType(e.target.value)}
              helperText={
                submitState === 2 && agentType?.length < 1
                  ? "This field is required"
                  : ""
              }
            />
          </div> */}
          <div className="w9_flex-wrapper2 w9_agents_block margin4">
            <p>PAYEE:</p><TextField
              hiddenLabel
              id=""
              disabled={downloadable && true}
              // defaultValue={firstName + ' ' + lastName}
              variant="filled"
              value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.billing_payee : payeeNameType}
              onChange={(e) => setpayeeNameType(e.target.value)}
              helperText={
                submitState === 2 && payeeNameType?.length < 1
                  ? "This field is required"
                  : ""
              }
            />
          </div>
          {/* <div className="w9_agents_block2">
            <p>Deal Fire CRM</p></div> */}
          <div className="w9_para">
            <p>The undersigned Agent hereby authorizes Deal Fire CRM or its
              assignees, to credit into the account indicated below, for Commissions
              earned by the undersigned. This Authorization Agreement must be
              executed by an authorized signor on the Agent's checking/savings
              account indicated below.</p>
            <p>Please confirm with your bank that the routing and account numbers are
              appropriate for ACH transactions.</p>
          </div>
          <div className="w9_flex-wrapper2 w9_agents_block">
            <p>Date ▶ </p>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disabled={true}
                // label="label"
                inputFormat={"MM/dd/yyyy"}
                defaultValue={downloadable && FetchedW9FormData.length > 0 && FetchedW9FormData[0]?.billing_date}
                value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.billing_date : billingDateType}
                onChange={(e) => setbillingDateType(e.target.value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="cheque_flex">


            <div className="w9_signature_block">
              <TextField
                hiddenLabel
                id=""
                // defaultValue={firstName + ' ' + lastName}
                variant="filled"
                value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.billing_agent_sign : signAgentType}
                disabled={downloadable && true}
                onChange={(e) => setsignAgentType(e.target.value)}
                helperText={
                  submitState === 2 && signAgentType?.length < 1
                    ? "This field is required"
                    : ""
                }
              />
              <p>Signature of Agents</p>
            </div>

            <div className="w9_signature_block">
              <TextField
                hiddenLabel
                id=""
                // defaultValue={firstName + ' ' + lastName}
                variant="filled"
                value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.billing_joint_account_owner : signJointAccountType}
                disabled={downloadable && true}
                onChange={(e) => setsignJointAccountType(e.target.value)}
              // helperText={
              //   submitState === 2 && signJointAccountType?.length < 1
              //     ? "This field is required"
              //     : ""
              // }
              />
              <p>Signature of Joint Account Owner (If Applicable)</p>
            </div>
          </div>

          <div className="w9_cheque_block margin3">
            <div className="w9_cheque_sub_block">
              <TextField
                className="right_input"
                hiddenLabel
                id=""
                disabled={true}

              />
              <div className="cheque_flex1">
                <div className="pay_text"><p>PAY TO</p><span>THE ORDER OF </span></div><TextField
                  className="wdth90"
                  hiddenLabel
                  id=""
                  disabled={true}

                />
              </div><div className="cheque_flex1">$<TextField
                className="wdth96"
                hiddenLabel
                id=""
                disabled={true}

              />DOLLARS
              </div>
              <div className="cheque_flex">
                <TextField
                  hiddenLabel
                  id=""
                  disabled={true}

                /><TextField
                  hiddenLabel
                  id=""
                  disabled={true}

                />

              </div>
            </div>
          </div>

          <div className="w9_flex-wrapper2 w9_agents_block banking_block">
            <p>Bank Account Number</p><TextField
              hiddenLabel
              id=""

              variant="filled"
              disabled={downloadable && true}
              value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.account_number : accountNumberType}
              onChange={(e) => setaccountNumberType(e.target.value)}
              helperText={
                submitState === 2 && accountNumberType?.length < 1
                  ? "This field is required"
                  : ""
              }
            />
          </div>
          <div className="w9_flex-wrapper2 w9_agents_block banking_block">
            <p>Bank Routing Number</p><TextField
              hiddenLabel
              id=""
              variant="filled"
              disabled={downloadable && true}
              value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.routing_number : routingNumberType}
              onChange={(e) => setroutingNumberType(e.target.value)}
              helperText={
                submitState === 2 && routingNumberType?.length < 1
                  ? "This field is required"
                  : ""
              }
            />
          </div>

          <div className="w9_top">
            <div className="w9_top_left">
              <h4>
                Form<span>W-9</span>
              </h4>
              <h4>
                (Rev. October 2018) Department of the Treasury Internal Revenue
                Service
              </h4>
              <h4></h4>
              <h4></h4>
            </div>
            <div className="w9_top_middle">
              <h1>
                Request for Taxpayer
                <br /> Identification Number and Certification
              </h1>
              <h3>
                ▶ Go to{" "}
                <a
                  className="linked_tle"
                  href="https://www.irs.gov/FormW9"
                  target="_blank"
                >
                  www.irs.gov/FormW9
                </a>{" "}
                for instructions and the latest information.
              </h3>
            </div>
            <div className="w9_top_right">
              <h2>Give Form to the requester. Do not send to the IRS.</h2>
            </div>
          </div>
          <div className="w9_flex-wrapper">
            <div className="w9_block1_left margin3">
              <div>
                <h3>Print or type.</h3>
                <h3>See Specific Instructions on page 3.</h3>
              </div>
            </div>
            <div className="w9_block1_right">
              <div className="block2_common">
                <p>
                  1 Name (as shown on your income tax return). Name is required
                  on this line; do not leave this line blank.
                </p>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  // defaultValue={firstName + ' ' + lastName}
                  variant="filled"
                  disabled={downloadable && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.name : nameType}
                  onChange={(e) => setnameType(e.target.value)}
                // helperText={
                //   submitState === 2 && nameType?.length < 1
                //     ? "This field is required"
                //     : ""
                // }
                />
              </div>
              <div className="block2_common">
                <p>
                  2 Business name/disregarded entity name, if different from
                  above
                </p>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                  variant="filled"
                  disabled={downloadable && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.businessName : businessNameType}
                  onChange={(e) => setbusinessNameType(e.target.value)}
                // helperText={
                //   submitState === 2 && businessNameType?.length < 1
                //     ? "This field is required"
                //     : ""
                // }
                />
              </div>
              <div className="w9_flex-wrapper2">
                <div className="w9_flex-wrapper2_left">
                  <p>
                    3 Check appropriate box for federal tax classification of
                    the person whose name is entered on line 1. Check only{" "}
                    <span className="w9_bold_text">one</span> of the following
                    seven boxes.
                  </p>
                  <div>






                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="female"
                      name="radio-buttons-group"
                    >



                      <FormControlLabel value="Individual/sole proprietor or single-member LLC" control={<Radio

                        disabled={downloadable && true}
                        checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.llc : soleType}

                        onChange={(e) => {
                          //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                          if (e.target.checked === true) {
                            setsoleType(true);
                            setliabilityType(false);
                            setcorporationType(false);
                            setscorporationType(false);
                            setstateType(false);
                            setpartnershipType(false);
                            setinstructionType(false);


                          } else {
                            setsoleType(false);
                          }
                          //   clearErrors(fielddata.name);
                        }}

                      />} label="Individual/sole proprietor or single-member LLC" />



                      <FormControlLabel value="c corporation" control={<Radio
                        disabled={downloadable && true}
                        checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.c_corporation : corporationType}

                        onChange={(e) => {
                          //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                          if (e.target.checked === true) {
                            setsoleType(false);
                            setcorporationType(true);
                            setliabilityType(false);
                            setscorporationType(false);
                            setstateType(false);
                            setpartnershipType(false);
                            setinstructionType(false);


                          } else {
                            setcorporationType(false);
                          }
                          //   clearErrors(fielddata.name);
                        }} />} label="C Corporation" />


                      <FormControlLabel value="s corporation" control={<Radio
                        disabled={downloadable && true}
                        checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.s_corporation : scorporationType}
                        onChange={(e) => {
                          //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                          if (e.target.checked === true) {
                            setscorporationType(true);
                            setliabilityType(false);
                            setcorporationType(false);
                            setsoleType(false);
                            setstateType(false);
                            setpartnershipType(false);
                            setinstructionType(false);



                          } else {
                            setscorporationType(false);
                          }
                          //   clearErrors(fielddata.name);
                        }}

                      />} label="S Corporation" />



                      <FormControlLabel value="partnership" control={<Radio
                        disabled={downloadable && true}
                        checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.partnership : partnershipType}
                        onChange={(e) => {
                          //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                          if (e.target.checked === true) {
                            setpartnershipType(true);
                            setliabilityType(false);
                            setscorporationType(false);
                            setcorporationType(false);
                            setsoleType(false);
                            setstateType(false);
                            setinstructionType(false);


                          } else {
                            setpartnershipType(false);
                          }
                          //   clearErrors(fielddata.name);
                        }}

                      />} label="Partnership" />



                      <FormControlLabel value="trust/estate" control={<Radio
                        disabled={downloadable && true}
                        checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.state : stateType}
                        onChange={(e) => {
                          //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                          if (e.target.checked === true) {
                            setstateType(true);
                            setliabilityType(false);
                            setscorporationType(false);
                            setcorporationType(false);
                            setsoleType(false);
                            setpartnershipType(false);
                            setinstructionType(false);


                          } else {
                            setstateType(false);
                          }
                          //   clearErrors(fielddata.name);
                        }}

                      />} label="Trust/estate" />



                      <FormControlLabel value="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)" control={<Radio
                        disabled={downloadable && true}
                        checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.liability : liabilityType}
                        onChange={(e) => {
                          //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                          if (e.target.checked === true) {
                            setliabilityType(true);
                            setscorporationType(false);
                            setcorporationType(false);
                            setsoleType(false);
                            setpartnershipType(false);
                            setstateType(false);
                            setinstructionType(false);

                          } else {
                            setliabilityType(false);
                          }
                          //   clearErrors(fielddata.name);
                        }}

                      />} label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)" />










                      {/* <FormControlLabel
                      control={
                        <Radio
                          //  checked={getValues(fielddata.name) ? Boolean(getValues(fielddata.name)) : false}

                          disabled={props.source === "affiliate_listing" && true}
                          checked={props.source === "affiliate_listing" && props.FetchedW9FormData[0].llc ? props.FetchedW9FormData[0].llc : soleType}

                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setsoleType(true);
                            } else {
                              setsoleType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}
                        />
                      }
                      label="Individual/sole proprietor or single-member LLC"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          disabled={props.source === "affiliate_listing" && true}
                          checked={props.source === "affiliate_listing" ? props.FetchedW9FormData[0].c_corporation : corporationType}

                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setcorporationType(true);
                            } else {
                              setcorporationType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}
                        />
                      }
                      label="C Corporation"
                    // onChange={(e) => setcorporationType(e.target.value)}
                    /> */}

                      {/* <FormControlLabel
                      control={
                        <Radio
                          disabled={props.source === "affiliate_listing" && true}
                          checked={props.source === "affiliate_listing" ? props.FetchedW9FormData[0].s_corporation : scorporationType}
                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setscorporationType(true);
                            } else {
                              setscorporationType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}
                        />
                      }
                      label="S Corporation"
                    // onChange={(e) => setscorporationType(e.target.value)}
                    /> */}



                      {/* <FormControlLabel
                      control={
                        <Radio
                          disabled={props.source === "affiliate_listing" && true}
                          checked={props.source === "affiliate_listing" ? props.FetchedW9FormData[0].partnership : partnershipType}
                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setpartnershipType(true);
                            } else {
                              setpartnershipType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}
                        />
                      }
                      label="Partnership"
                    // onChange={(e) => setpartnershipType(e.target.value)}
                    />{" "} */}

                      {/* <FormControlLabel
                      control={
                        <Radio
                          disabled={props.source === "affiliate_listing" && true}
                          checked={props.source === "affiliate_listing" ? props.FetchedW9FormData[0].e_state : stateType}
                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setstateType(true);
                            } else {
                              setstateType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}
                        />
                      }
                      label="Trust/estate"
                    // onChange={(e) => setstateType(e.target.value)}
                    />{" "} */}










                      <span className="inline_input">


                        {/* <FormControlLabel
                        control={
                          <Radio
                            disabled={props.source === "affiliate_listing" && true}
                            checked={props.source === "affiliate_listing" ? props.FetchedW9FormData[0].liability : liabilityType}
                            onChange={(e) => {
                              //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                              if (e.target.checked === true) {
                                setliabilityType(true);
                              } else {
                                setliabilityType(false);
                              }
                              //   clearErrors(fielddata.name);
                            }}
                          />
                        }
                        label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
                      // onChange={(e) => settaxType(e.target.value)}
                      /> */}




                        {liabilityType === true && (
                          <TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            defaultValue=""
                            variant="filled"
                            value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.tax_classifications : taxType}
                            // onChange={(e) => setliabilityType(e.target.value)}
                            disabled={downloadable && true}

                            tax_classifications
                            onChange={(e) => settaxType(e.target.value)}
                          // helperText={
                          //   submitState === 2 && taxType?.length < 1
                          //     ? "This field is required"
                          //     : ""
                          // }
                          />
                        )}
                      </span>


                      <p>
                        <span>Note:</span> Check the appropriate box in the line
                        above for the tax classification of the single-member owner.
                        Do not check Exemption from FATCA reporting LLC if the LLC
                        is classified as a single-member LLC that is disregarded
                        from the owner unless the owner of the LLC is code (if any)
                        another LLC that is not disregarded from the owner for U.S.
                        federal tax purposes. Otherwise, a single-member LLC that is
                        disregarded from the owner should check the appropriate box
                        for the tax classification of its owner.
                      </p>
                      <span>



                        <FormControlLabel value="others" control={<Radio
                          disabled={downloadable && true}
                          checked={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.instruction : instructionType}
                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setinstructionType(true);
                              setstateType(false);
                              setliabilityType(false);
                              setscorporationType(false);
                              setcorporationType(false);
                              setsoleType(false);
                              setpartnershipType(false);

                            } else {
                              setinstructionType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}

                        />} label="Other (see instructions)" />



                        {/* <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                            if (e.target.checked === true) {
                              setinstructionType(true);
                            } else {
                              setinstructionType(false);
                            }
                            //   clearErrors(fielddata.name);
                          }}
                        />
                      }
                      label="Other (see instructions)"
                    // onChange={(e) => setinstructionType(e.target.value)}
                    /> */}




                        {instructionType === true && (
                          <TextField
                            className="full_width"
                            hiddenLabel
                            id="filled-hidden-label-normal"

                            value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.listinstruction : listinstructionType}
                            disabled={downloadable && true}

                            defaultValue=""
                            variant="filled"
                            onChange={(e) => setlistinstructionType(e.target.value)}
                          // helperText={
                          //   submitState === 2 && listinstructionType?.length < 1
                          //     ? "This field is required"
                          //     : ""
                          // }
                          />
                        )}
                      </span>


                    </RadioGroup>
                  </div>

                </div>







                <div className="w9_flex-wrapper2_right">
                  <p>
                    4 Exemptions (codes apply only to certain entities, not
                    individuals; see instructions on page 3):
                  </p>
                  <p>
                    Exempt payee code (if any)
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                      disabled={downloadable && true}
                      value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.payee : payeeType}
                      onChange={(e) => setpayeeType(e.target.value)}
                    />
                  </p>
                  <p>
                    Exemption from FATCA reporting code (if any)
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                      disabled={downloadable && true}
                      value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.fatca_report : reportType}
                      onChange={(e) => setreportType(e.target.value)}
                    />
                  </p>
                  <h5>(Applies to accounts maintained outside the U.S.)</h5>
                </div>
              </div>

              <div className="w9_flex-wrapper3">
                <div className="flex3_left">
                  <div className="block2_common bordercls">
                    <p>
                      5 Address (number, street, and apt. or suite no.) See
                      instructions.
                    </p>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                      disabled={downloadable && true}
                      value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.address : addressType}
                      onChange={(e) => setaddressType(e.target.value)}
                    // helperText={
                    //   submitState === 2 && addressType?.length < 1
                    //     ? "This field is required"
                    //     : ""
                    // }
                    />
                  </div>
                  <div className="block2_common border_nne">
                    <p>6 City, state, and ZIP code</p>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      // defaultValue={city + ' ' + state + '' + zip}
                      variant="filled"
                      disabled={downloadable && true}
                      value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.city : cityType}
                      onChange={(e) => setcityType(e.target.value)}
                    // helperText={
                    //   submitState === 2 && cityType?.length < 1
                    //     ? "This field is required"
                    //     : ""
                    // }
                    />
                  </div>
                </div>
                <div className="flex3_right">
                  <p>Requester’s name and address (optional)</p>
                  <TextareaAutosize
                    placeholder={""}
                    defaultValue=""
                    disabled={downloadable && true}
                    value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.requesters : textareaType}
                    onChange={(e) => settextareaType(e.target.value)}
                  // value={textareayvalue.current}
                  // ref={inputTextProspect}
                  // onChange={(e) => {
                  //     textareayvalue.current = e.target.value;
                  //     // console.log("textareayvalue=========>",textareayvalue.current);

                  // }}
                  />
                </div>
              </div>
              <div className="block2_common">
                <p>7 List account number(s) here (optional)</p>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                  variant="filled"
                  disabled={downloadable && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.list_account : accountType}
                  onChange={(e) => setaccountType(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="part_cmn">
            <span className="black_bg">Part I</span>
            <span> Taxpayer Identification Number (TIN)</span>
          </div>
          <div className="w9_flex4">
            <div className="w9_flex4_left new_left">
              <p>
                Enter your TIN in the appropriate box. The TIN provided must
                match the name given on line 1 to avoid backup withholding. For
                individuals, this is generally your social security number
                (SSN). However, for a resident alien, sole proprietor, or
                disregarded entity, see the instructions for Part I, later. For
                other entities, it is your employer identification number (EIN).
                If you do not have a number, see How to get a TIN, later.
              </p>
              <p>
                <span className="w9_bold_text">Note:</span> If the account is in
                more than one name, see the instructions for line 1. Also see
                What Name and Number To Give the Requester for guidelines on
                whose number to enter.
              </p>
            </div>
            <div className="w9_flex4_right new_right">
              <p>Social security number</p>
              <span className="flx_spn">
                {" "}
                <TextField
                  InputProps={{
                    maxLength: 3,
                    minLength: 3,
                  }}
                  //   onChange={(e) => setnumberOneType(e.target.value)}
                  onChange={handleInputChange}
                  // disabled={props.source === "affiliate_listing" && true}

                  disabled={downloadable && FetchedW9FormData.length > 0 && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.social_security.substring(0,3) : numberOneType}

                  // value={numberOneType}
                  className="width70"
                />{" "}-{" "}



                <TextField
                  //   onChange={(e) => setnumberTwoType(e.target.value)}
                  onChange={handleInputChange2}
                  // value={numberTwoType}

                  disabled={downloadable && FetchedW9FormData.length > 0 && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.social_security.substring(3,5) : numberTwoType}


                  className="width50"
                />{" "} -{" "}

                <TextField
                  //   onChange={(e) => setnumberThreeType(e.target.value)}
                  onChange={handleInputChange3}
                  // value={numberThreeType}

                  disabled={downloadable && FetchedW9FormData.length > 0 && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.social_security.substring(5) : numberThreeType}

                  className="width150"
                />
              </span>

              <h3>or</h3>
              <p>Employer identification number</p>
              <span className="flx_spn">
                {" "}
                <TextField
                  //   onChange={(e) => setidentityOneType(e.target.value)}
                  onChange={handleInputChange4}

                  disabled={downloadable && FetchedW9FormData.length > 0 && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.employ_identification_no.substring(0, 2) : identityOneType}

                  className="width150"

                />{" "}
                -{" "}
                <TextField
                  //   onChange={(e) => setidentityTwoType(e.target.value)}
                  onChange={handleInputChange5}
                  // value={identityTwoType}
                  disabled={downloadable && FetchedW9FormData.length > 0 && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.employ_identification_no.substring(2) : identityTwoType}

                />
              </span>
              {submitState === 2 &&
                (identityOneType.length < 1 && numberOneType < 1) && (
                  <p className="red_alert_text">This field is required</p>
                )}
            </div>
          </div>
          <div className="part_cmn">
            <span className="black_bg">Part II</span>
            <span> Certification</span>
          </div>
          <div className="font20_w9">
            <p>Under penalties of perjury, I certify that:</p>
            <p>
              1. The number shown on this form is my correct taxpayer
              identification number (or I am waiting for a number to be issued
              to me); and
            </p>
            <p>
              2. I am not subject to backup withholding because: (a) I am exempt
              from backup withholding, or (b) I have not been notified by the
              Internal Revenue Service (IRS) that I am subject to backup
              withholding as a result of a failure to report all interest or
              dividends, or (c) the IRS has notified me that I am no longer
              subject to backup withholding; and
            </p>
            <p>
              3. I am a U.S. citizen or other U.S. person (defined below); and
            </p>
            <p>
              4. The FATCA code(s) entered on this form (if any) indicating that
              I am exempt from FATCA reporting is correct.
            </p>
            <p>
              <span className="w9_bold_text">Certification instructions.</span>{" "}
              You must cross out item 2 above if you have been notified by the
              IRS that you are currently subject to backup withholding because
              you have failed to report all interest and dividends on your tax
              return. For real estate transactions, item 2 does not apply. For
              mortgage interest paid, acquisition or abandonment of secured
              property, cancellation of debt, contributions to an individual
              retirement arrangement (IRA), and generally, payments other than
              interest and dividends, you are not required to sign the
              certification, but you must provide your correct TIN. See the
              instructions for Part II, later.
            </p>
          </div>
          <div className="flex5">
            <h3>Sign Here</h3>
            <div className="flex6">
              <div className="flex6_left">
                <p>Signature of U.S. person ▶ </p>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                  variant="filled"
                  disabled={downloadable && true}
                  value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.signature : signatureType}
                  onChange={(e) => setsignatureType(e.target.value)}

                  helperText={
                    submitState === 2 && signatureType?.length < 1
                      ? "This field is required"
                      : ""
                  }
                />
              </div>


              <div className="flex6_right">
                <p>Date ▶ </p>
                {/* <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                  variant="filled"
                  type="number"
                  value={dateType}
                  onChange={(e) => setdateType(e.target.value)}
                  helperText={
                    submitState === 2 && dateType?.length < 1
                      ? "This field is required"
                      : ""
                  }
                /> */}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={true}
                    // label="label"
                    inputFormat={"MM/dd/yyyy"}
                    defaultValue={downloadable && FetchedW9FormData.length > 0 && FetchedW9FormData[0]?.date}
                    value={downloadable && FetchedW9FormData.length > 0 ? FetchedW9FormData[0]?.date : dateType}
                    
                    onChange={(e) => setdateType(e.target.value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              {props.source !== "affiliate_listing" && visible && (<div className="w9_btn_wrp">
                <Button onClick={handleSubmit} className="w9_btn">
                  {/* <Button type="submit"  className="w9_btn"> */}
                  Submit
                </Button>

              </div>)}
            </div>

          </div>
          {data === 1 && (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>)}
          {/* <div className="w9_common_flex">
            <div className="w9_left_wrpper">
              <h2>General Instructions</h2>
              <p>
                Section references are to the Internal Revenue Code unless
                otherwise noted.
              </p>
              <p>
                <span className="w9_bold_text">Future developments.</span> For
                the latest information about developments related to Form W-9
                and its instructions, such as legislation enacted after they
                were published, go to{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.irs.gov/FormW9"
                    target="_blank"
                  >
                    www.irs.gov/FormW9
                  </a>
                </span>
                .
              </p>
              <h2>Purpose of Form</h2>
              <p>
                An individual or entity (Form W-9 requester) who is required to
                file an information return with the IRS must obtain your correct
                taxpayer identification number (TIN) which may be your social
                security number (SSN), individual taxpayer identification number
                (ITIN), adoption taxpayer identification number (ATIN), or
                employer identification number (EIN), to report on an
                information return the amount paid to you, or other amount
                reportable on an information return. Examples of information
                returns include, but are not limited to, the following.
              </p>
              <li>Form 1099-INT (interest earned or paid)</li>
            </div>
            <div className="w9_right_wrpper">
              <ul>
                <li>
                  Form 1099-DIV (dividends, including those from stocks or
                  mutual funds)
                </li>
                <li>
                  Form 1099-MISC (various types of income, prizes, awards, or
                  gross proceeds)
                </li>
                <li>
                  Form 1099-B (stock or mutual fund sales and certain other
                  transactions by brokers)
                </li>
                <li>Form 1099-S (proceeds from real estate transactions)</li>
                <li>
                  Form 1099-K (merchant card and third party network
                  transactions)
                </li>
                <li>
                  Form 1098 (home mortgage interest), 1098-E (student loan
                  interest), 1098-T (tuition)
                </li>
                <li>Form 1099-C (canceled debt)</li>
                <li>
                  Form 1099-A (acquisition or abandonment of secured property)
                </li>
              </ul>
              <p>
                Use Form W-9 only if you are a U.S. person (including a resident
                alien), to provide your correct TIN.
              </p>
              <p>
                <span className="w9_italict_text">
                  If you do not return Form W-9 to the requester with a TIN, you
                  might be subject to backup withholding. See
                </span>{" "}
                What is backup withholding,
                <span className="w9_italict_text">later.</span>
              </p>
            </div>
          </div> */}

          <div className="w9_common_flex">
            <div className="w9_left_wrpper">
              <h2>General Instructions</h2>
              <p>
                Section references are to the Internal Revenue Code unless
                otherwise noted.
              </p>
              <p>
                <span className="w9_bold_text">Future developments.</span> For
                the latest information about developments related to Form W-9
                and its instructions, such as legislation enacted after they
                were published, go to{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.irs.gov/FormW9"
                    target="_blank"
                  >
                    www.irs.gov/FormW9
                  </a>
                </span>
                .
              </p>
              <h2>Purpose of Form</h2>
              <p>
                An individual or entity (Form W-9 requester) who is required to
                file an information return with the IRS must obtain your correct
                taxpayer identification number (TIN) which may be your social
                security number (SSN), individual taxpayer identification number
                (ITIN), adoption taxpayer identification number (ATIN), or
                employer identification number (EIN), to report on an
                information return the amount paid to you, or other amount
                reportable on an information return. Examples of information
                returns include, but are not limited to, the following.
              </p>
              <li>Form 1099-INT (interest earned or paid)</li>
            </div>
            <div className="w9_right_wrpper">
              <ul>
                <li>
                  Form 1099-DIV (dividends, including those from stocks or
                  mutual funds)
                </li>
                <li>
                  Form 1099-MISC (various types of income, prizes, awards, or
                  gross proceeds)
                </li>
                <li>
                  Form 1099-B (stock or mutual fund sales and certain other
                  transactions by brokers)
                </li>
                <li>Form 1099-S (proceeds from real estate transactions)</li>
                <li>
                  Form 1099-K (merchant card and third party network
                  transactions)
                </li>
                <li>
                  Form 1098 (home mortgage interest), 1098-E (student loan
                  interest), 1098-T (tuition)
                </li>
                <li>Form 1099-C (canceled debt)</li>
                <li>
                  Form 1099-A (acquisition or abandonment of secured property)
                </li>
                <li>
                  Use Form W-9 only if you are a U.S. person (including a
                  resident alien), to provide your correct TIN.
                </li>
              </ul>
              <p>
                <span className="w9_italict_text">
                  If you do not return Form W-9 to the requester with a TIN, you
                  might be subject to backup withholding
                </span>
                . See What is backup withholding, later.
              </p>
            </div>
          </div>

          <div className="pageno_flex">
            <p>Form W-9 (Rev. 10-2018)</p>{" "}
            <p>
              page <span>2</span>
            </p>
          </div>

          <div className="w9_common_flex">
            <div className="w9_left_wrpper">
              <p>By signing the filled-out form, you:</p>
              <p>
                1. Certify that the TIN you are giving is correct (or you are
                waiting for a number to be issued),
              </p>
              <p>
                2. Certify that you are not subject to backup withholding, or
              </p>
              <p>
                3. Claim exemption from backup withholding if you are a U.S.
                exempt payee. If applicable, you are also certifying that as a
                U.S. person, your allocable share of any partnership income from
                a U.S. trade or business is not subject to the withholding tax
                on foreign partners' share of effectively connected income, and
              </p>
              <p>
                4. Certify that FATCA code(s) entered on this form (if any)
                indicating that you are exempt from the FATCA reporting, is
                correct. See{" "}
                <span className="w9_italict_text">
                  What is FATCA reporting,
                </span>{" "}
                later, for further information.
              </p>
              <p>
                <span className="w9_bold_text">Note:</span> If you are a U.S.
                person and a requester gives you a form other than Form W-9 to
                request your TIN, you must use the requester’s form if it is
                substantially similar to this Form W-9.
              </p>
              <p>
                Definition of a U.S. person. For federal tax purposes, you are
                considered a U.S. person if you are:
              </p>
              <ul>
                <li>
                  An individual who is a U.S. citizen or U.S. resident alien;
                </li>
                <li>
                  A partnership, corporation, company, or association created or
                  organized in the United States or under the laws of the United
                  States;
                </li>
                <li>An estate (other than a foreign estate); or</li>
                <li>
                  A domestic trust (as defined in Regulations section
                  301.7701-7).
                </li>
              </ul>
              <p>
                <span className="w9_bold_text">
                  Special rules for partnerships.
                </span>{" "}
                Partnerships that conduct a trade or business in the United
                States are generally required to pay a withholding tax under
                section 1446 on any foreign partners’ share of effectively
                connected taxable income from such business. Further, in certain
                cases where a Form W-9 has not been received, the rules under
                section 1446 require a partnership to presume that a partner is
                a foreign person, and pay the section 1446 withholding tax.
                Therefore, if you are a U.S. person that is a partner in a
                partnership conducting a trade or business in the United States,
                provide Form W-9 to the partnership to establish your U.S.
                status and avoid section 1446 withholding on your share of
                partnership income.
              </p>
              <p>
                In the cases below, the following person must give Form W-9 to
                the partnership for purposes of establishing its U.S. status and
                avoiding withholding on its allocable share of net income from
                the partnership conducting a trade or business in the United
                States.
              </p>
              <ul>
                <li>
                  In the case of a disregarded entity with a U.S. owner, the
                  U.S. owner of the disregarded entity and not the entity;
                </li>
                <li>
                  In the case of a grantor trust with a U.S. grantor or other
                  U.S. owner, generally, the U.S. grantor or other U.S. owner of
                  the grantor trust and not the trust; and
                </li>
                <li>
                  In the case of a U.S. trust (other than a grantor trust), the
                  U.S. trust (other than a grantor trust) and not the
                  beneficiaries of the trust.
                </li>
              </ul>
              <p>
                <span className="w9_bold_text">Foreign person.</span> If you are
                a foreign person or the U.S. branch of a foreign bank that has
                elected to be treated as a U.S. person, do not use Form W-9.
                Instead, use the appropriate Form W-8 or Form 8233 (see Pub.
                515, Withholding of Tax on Nonresident Aliens and Foreign
                Entities).
              </p>
              <p>
                <span className="w9_bold_text">
                  Nonresident alien who becomes a resident alien.
                </span>{" "}
                Generally, only a nonresident alien individual may use the terms
                of a tax treaty to reduce or eliminate U.S. tax on certain types
                of income. However, most tax treaties contain a provision known
                as a “saving clause.” Exceptions specified in the saving clause
                may permit an exemption from tax to continue for certain types
                of income even after the payee has otherwise become a U.S.
                resident alien for tax purposes.
              </p>
              <p>
                If you are a U.S. resident alien who is relying on an exception
                contained in the saving clause of a tax treaty to claim an
                exemption from U.S. tax on certain types of income, you must
                attach a statement to Form W-9 that specifies the following five
                items.
              </p>
              <p>
                1. The treaty country. Generally, this must be the same treaty
                under which you claimed exemption from tax as a nonresident
                alien.
              </p>
              <p>2. The treaty article addressing the income.</p>
              <p>
                3. The article number (or location) in the tax treaty that
                contains the saving clause and its exceptions.
              </p>
              <p>
                4. The type and amount of income that qualifies for the
                exemption from tax.
              </p>
              <p>
                5. Sufficient facts to justify the exemption from tax under the
                terms of the treaty article.
              </p>
            </div>

            <div className="w9_right_wrpper">
              <p>
                <span className="w9_bold_text w9_italict_text">Example.</span>{" "}
                Article 20 of the U.S.-China income tax treaty allows an
                exemption from tax for scholarship income received by a Chinese
                student temporarily present in the United States. Under U.S.
                law, this student will become a resident alien for tax purposes
                if his or her stay in the United States exceeds 5 calendar
                years. However, paragraph 2 of the first Protocol to the
                U.S.-China treaty (dated April 30, 1984) allows the provisions
                of Article 20 to continue to apply even after the Chinese
                student becomes a resident alien of the United States. A Chinese
                student who qualifies for this exception (under paragraph 2 of
                the first protocol) and is relying on this exception to claim an
                exemption from tax on his or her scholarship or fellowship
                income would attach to Form W-9 a statement that includes the
                information described above to support that exemption.
              </p>
              <p>
                If you are a nonresident alien or a foreign entity, give the
                requester the appropriate completed Form W-8 or Form 8233.
              </p>
              <h2>Backup Withholding</h2>
              <p>
                <span className="w9_bold_text">
                  What is backup withholding?
                </span>{" "}
                Persons making certain payments to you must under certain
                conditions withhold and pay to the IRS 24% of such payments.
                This is called “backup withholding.” Payments that may be
                subject to backup withholding include interest, tax-exempt
                interest, dividends, broker and barter exchange transactions,
                rents, royalties, nonemployee pay, payments made in settlement
                of payment card and third party network transactions, and
                certain payments from fishing boat operators. Real estate
                transactions are not subject to backup withholding.
              </p>
              <p>
                You will not be subject to backup withholding on payments you
                receive if you give the requester your correct TIN, make the
                proper certifications, and report all your taxable interest and
                dividends on your tax return.{" "}
              </p>
              <p>
                <span className="w9_bold_text">
                  Payments you receive will be subject to backup withholding if:
                </span>
              </p>
              <p>1. You do not furnish your TIN to the requester,</p>
              <p>
                2. You do not certify your TIN when required (see the
                instructions for Part II for details),
              </p>
              <p>
                3. The IRS tells the requester that you furnished an incorrect
                TIN,
              </p>
              <p>
                4. The IRS tells you that you are subject to backup withholding
                because you did not report all your interest and dividends on
                your tax return (for reportable interest and dividends only), or
              </p>
              <p>
                5. You do not certify to the requester that you are not subject
                to backup withholding under 4 above (for reportable interest and
                dividend accounts opened after 1983 only).
              </p>
              <p>
                Certain payees and payments are exempt from backup withholding.
                See <span className="w9_italict_text">Exempt payee code,</span>{" "}
                later, and the separate Instructions for the Requester of Form
                W-9 for more information.
              </p>
              <p>
                Also see{" "}
                <span className="w9_italict_text">
                  Special rules for partnerships,
                </span>{" "}
                earlier.
              </p>
              <h2>What is FATCA Reporting?</h2>
              <p>
                The Foreign Account Tax Compliance Act (FATCA) requires a
                participating foreign financial institution to report all United
                States account holders that are specified United States persons.
                Certain payees are exempt from FATCA reporting. See{" "}
                <span className="w9_italict_text">
                  Exemption from FATCA reporting code,
                </span>{" "}
                later, and the Instructions for the Requester of Form W-9 for
                more information.
              </p>
              <h2>Updating Your Information</h2>
              <p>
                You must provide updated information to any person to whom you
                claimed to be an exempt payee if you are no longer an exempt
                payee and anticipate receiving reportable payments in the future
                from this person. For example, you may need to provide updated
                information if you are a C corporation that elects to be an S
                corporation, or if you no longer are tax exempt. In addition,
                you must furnish a new Form W-9 if the name or TIN changes for
                the account; for example, if the grantor of a grantor trust
                dies.
              </p>
              <h2></h2>
              <h2>Penalties</h2>
              <p>
                <span className="w9_bold_text">Failure to furnish TIN.</span> If
                you fail to furnish your correct TIN to a requester, you are
                subject to a penalty of $50 for each such failure unless your
                failure is due to reasonable cause and not to willful neglect.
              </p>
              <p>
                <span className="w9_bold_text">
                  Civil penalty for false information with respect to
                  withholding.
                </span>{" "}
                If you make a false statement with no reasonable basis that
                results in no backup withholding, you are subject to a $500
                penalty.
              </p>
            </div>
          </div>

          <div className="pageno_flex margtop2">
            <p>Form W-9 (Rev. 10-2018)</p>{" "}
            <p>
              page <span>3</span>
            </p>
          </div>
          <div className="w9_common_flex">
            <div className="w9_left_wrpper">
              <p>
                <span className="w9_bold_text">
                  Criminal penalty for falsifying information.
                </span>{" "}
                Willfully falsifying certifications or affirmations may subject
                you to criminal penalties including fines and/or imprisonment.
              </p>
              <p>
                <span className="w9_bold_text">Misuse of TINs.</span> If the
                requester discloses or uses TINs in violation of federal law,
                the requester may be subject to civil and criminal penalties.
              </p>
              <h2>Specific Instructions</h2>
              <p>
                <span className="w9_bold_text">Line 1</span>
              </p>
              <p>
                You must enter one of the following on this line;{" "}
                <span className="w9_bold_text">do not</span> leave this line
                blank. The name should match the name on your tax return.
              </p>
              <p>
                If this Form W-9 is for a joint account (other than an account
                maintained by a foreign financial institution (FFI)), list
                first, and then circle, the name of the person or entity whose
                number you entered in Part I of Form W-9. If you are providing
                Form W-9 to an FFI to document a joint account, each holder of
                the account that is a U.S. person must provide a Form W-9.
              </p>
              <p>
                a. <span className="w9_bold_text">Individual.</span> Generally,
                enter the name shown on your tax return. If you have changed
                your last name without informing the Social Security
                Administration (SSA) of the name change, enter your first name,
                the last name as shown on your social security card, and your
                new last name.
              </p>
              <p>
                <span className="w9_bold_text">Note: ITIN applicant:</span>{" "}
                Enter your individual name as it was entered on your Form W-7
                application, line 1a. This should also be the same as the name
                you entered on the Form 1040/1040A/1040EZ you filed with your
                application.
              </p>
              <p>
                b.{" "}
                <span className="w9_bold_text">
                  Sole proprietor or single-member LLC.
                </span>{" "}
                Enter your individual name as shown on your 1040/1040A/1040EZ on
                line 1. You may enter your business, trade, or “doing business
                as” (DBA) name on line 2.
              </p>
              <p>
                c.{" "}
                <span className="w9_bold_text">
                  Partnership, LLC that is not a single-member LLC, C
                  corporation, or S corporation.
                </span>{" "}
                Enter the entity's name as shown on the entity's tax return on
                line 1 and any business, trade, or DBA name on line 2.
              </p>
              <p>
                d. <span className="w9_bold_text">Other entities.</span> Enter
                your name as shown on required U.S. federal tax documents on
                line 1. This name should match the name shown on the charter or
                other legal document creating the entity. You may enter any
                business, trade, or DBA name on line 2.
              </p>
              <p>
                e. <span className="w9_bold_text">Disregarded entity.</span> For
                U.S. federal tax purposes, an entity that is disregarded as an
                entity separate from its owner is treated as a “disregarded
                entity.” See Regulations section 301.7701-2(c)(2)(iii). Enter
                the owner's name on line 1. The name of the entity entered on
                line 1 should never be a disregarded entity. The name on line 1
                should be the name shown on the income tax return on which the
                income should be reported. For example, if a foreign LLC that is
                treated as a disregarded entity for U.S. federal tax purposes
                has a single owner that is a U.S. person, the U.S. owner's name
                is required to be provided on line 1. If the direct owner of the
                entity is also a disregarded entity, enter the first owner that
                is not disregarded for federal tax purposes. Enter the
                disregarded entity's name on line 2, “Business name/disregarded
                entity name.” If the owner of the disregarded entity is a
                foreign person, the owner must complete an appropriate Form W-8
                instead of a Form W-9. This is the case even if the foreign
                person has a U.S. TIN.
              </p>

              <p>
                <span className="w9_bold_text">Line 2</span>
              </p>
              <p>
                If you have a business name, trade name, DBA name, or
                disregarded entity name, you may enter it on line 2.
              </p>
              <p>
                <span className="w9_bold_text">Line 3</span>
              </p>
              <p>
                Check the appropriate box on line 3 for the U.S. federal tax
                classification of the person whose name is entered on line 1.
                Check only one box on line 3.
              </p>
            </div>

            <div className="w9_right_wrpper">
              <div>
                <table>
                  <tr>
                    <th>
                      <p>IF the entity/person on line 1 is a(n) . . .</p>
                    </th>
                    <th>
                      <p>THEN check the box for . . .</p>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <li>Corporation</li>
                    </td>
                    <td>
                      <p>Corporation</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li>Individual</li>
                      <li>Sole proprietorship, or</li>
                      <li>
                        Single-member limited liability company (LLC) owned by
                        an individual and disregarded for U.S. federal tax
                        purposes.
                      </li>
                    </td>
                    <td>
                      <p>Individual/sole proprietor or single- member LLC</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li>
                        LLC treated as a partnership for U.S. federal tax
                        purposes,
                      </li>
                      <li>
                        LLC that has filed Form 8832 or 2553 to be taxed as a
                        corporation, or
                      </li>
                      <li>
                        LLC that is disregarded as an entity separate from its
                        owner but the owner is another LLC that is not
                        disregarded for U.S. federal tax purposes.
                      </li>
                    </td>
                    <td>
                      <p>
                        Limited liability company and enter the appropriate tax
                        classification. (P= Partnership; C= C corporation; or S=
                        S corporation)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li>Partnership</li>
                    </td>
                    <td>
                      <p>Partnership</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li>Trust/estate</li>
                    </td>
                    <td>
                      <p>Trust/estate</p>
                    </td>
                  </tr>
                </table>
              </div>
              <h2>Line 4, Exemptions</h2>
              <p>
                If you are exempt from backup withholding and/or FATCA
                reporting, enter in the appropriate space on line 4 any code(s)
                that may apply to you.
              </p>
              <p>
                <span className="w9_bold_text">Exempt payee code.</span>
              </p>
              <ul>
                <li>
                  Generally, individuals (including sole proprietors) are not
                  exempt from backup withholding.
                </li>
                <li>
                  Except as provided below, corporations are exempt from backup
                  withholding for certain payments, including interest and
                  dividends.
                </li>
                <li>
                  Corporations are not exempt from backup withholding for
                  payments made in settlement of payment card or third party
                  network transactions.
                </li>
                <li>
                  Corporations are not exempt from backup withholding with
                  respect to attorneys’ fees or gross proceeds paid to
                  attorneys, and corporations that provide medical or health
                  care services are not exempt with respect to payments
                  reportable on Form 1099-MISC.
                </li>
                <p>
                  The following codes identify payees that are exempt from
                  backup withholding. Enter the appropriate code in the space in
                  line 4.
                </p>
                <p>
                  1—An organization exempt from tax under section 501(a), any
                  IRA, or a custodial account under section 403(b)(7) if the
                  account satisfies the requirements of section 401(f)(2)
                </p>
                <p>
                  2—The United States or any of its agencies or
                  instrumentalities
                </p>
                <p>
                  3—A state, the District of Columbia, a U.S. commonwealth or
                  possession, or any of their political subdivisions or
                  instrumentalities
                </p>
                <p>
                  4—A foreign government or any of its political subdivisions,
                  agencies, or instrumentalities
                </p>
                <p>5—A corporation</p>
                <p>
                  6—A dealer in securities or commodities required to register
                  in the United States, the District of Columbia, or a U.S.
                  commonwealth or possession
                </p>
                <p>
                  7—A futures commission merchant registered with the Commodity
                  Futures Trading Commission
                </p>
                <p>8—A real estate investment trust</p>
                <p>
                  9—An entity registered at all times during the tax year under
                  the Investment Company Act of 1940
                </p>
                <p>
                  10—A common trust fund operated by a bank under section 584(a)
                </p>
                <p>11—A financial institution</p>
                <p>
                  12—A middleman known in the investment community as a nominee
                  or custodian
                </p>
                <p>
                  13—A trust exempt from tax under section 664 or described in
                  section 4947
                </p>
              </ul>
            </div>
          </div>

          <div className="pageno_flex">
            <p>Form W-9 (Rev. 10-2018)</p>{" "}
            <p>
              page <span>4</span>
            </p>
          </div>

          <div className="w9_common_flex table2">
            <div className="w9_left_wrpper">
              <p>
                The following chart shows types of payments that may be exempt
                from backup withholding. The chart applies to the exempt payees
                listed above, 1 through 13.
              </p>
              <table>
                <tr>
                  <th>
                    <p>IF the payment is for . . .</p>
                  </th>
                  <th>
                    <p>THEN the payment is exempt for . . .</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>Interest and dividend payments</p>
                  </td>
                  <td>
                    <p>All exempt payees except for 7</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Broker transactions</p>
                  </td>
                  <td>
                    <p>
                      Exempt payees 1 through 4 and 6 through 11 and all C
                      corporations. S corporations must not enter an exempt
                      payee code because they are exempt only for sales of
                      noncovered securities acquired prior to 2012.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Barter exchange transactions and patronage dividends</p>
                  </td>
                  <td>
                    <p>Exempt payees 1 through 4</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Payments over $600 required to be reported and direct
                      sales over $5,000<sup>1</sup>
                    </p>
                  </td>
                  <td>
                    <p>
                      Generally, exempt payees 1 through 5<sup>2</sup>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Payments made in settlement of payment card or third party
                      network transactions
                    </p>
                  </td>
                  <td>
                    <p>Exempt payees 1 through 4</p>
                  </td>
                </tr>
              </table>
              <p>
                <sup>1</sup> See Form 1099-MISC, Miscellaneous Income, and its
                instructions.
              </p>
              <p>
                <sup>2</sup> However, the following payments made to a
                corporation and reportable on Form 1099-MISC are not exempt from
                backup withholding: medical and health care payments, attorneys’
                fees, gross proceeds paid to an attorney reportable under
                section 6045(f), and payments for services paid by a federal
                executive agency.
              </p>
              <p>
                <span className="w9_bold_text">
                  Exemption from FATCA reporting code.
                </span>{" "}
                The following codes identify payees that are exempt from
                reporting under FATCA. These codes apply to persons submitting
                this form for accounts maintained outside of the United States
                by certain foreign financial institutions. Therefore, if you are
                only submitting this form for an account you hold in the United
                States, you may leave this field blank. Consult with the person
                requesting this form if you are uncertain if the financial
                institution is subject to these requirements. A requester may
                indicate that a code is not required by providing you with a
                Form W-9 with “Not Applicable” (or any similar indication)
                written or printed on the line for a FATCA exemption code.
              </p>
              <p>
                A—An organization exempt from tax under section 501(a) or any
                individual retirement plan as defined in section 7701(a)(37)
              </p>
              <p>
                B—The United States or any of its agencies or instrumentalities
              </p>
              <p>
                C—A state, the District of Columbia, a U.S. commonwealth or
                possession, or any of their political subdivisions or
                instrumentalities
              </p>
              <p>
                D—A corporation the stock of which is regularly traded on one or
                more established securities markets, as described in Regulations
                section 1.1472-1(c)(1)(i)
              </p>
              <p>
                E—A corporation that is a member of the same expanded affiliated
                group as a corporation described in Regulations section
                1.1472-1(c)(1)(i)
              </p>
              <p>
                F—A dealer in securities, commodities, or derivative financial
                instruments (including notional principal contracts, futures,
                forwards, and options) that is registered as such under the laws
                of the United States or any state
              </p>
              <p>G—A real estate investment trust</p>
              <p>
                H—A regulated investment company as defined in section 851 or an
                entity registered at all times during the tax year under the
                Investment Company Act of 1940
              </p>
              <p>I—A common trust fund as defined in section 584(a)</p>
              <p>J—A bank as defined in section 581</p>
              <p>K—A broker</p>
              <p>
                L—A trust exempt from tax under section 664 or described in
                section 4947(a)(1)
              </p>
            </div>
            <div className="w9_right_wrpper">
              <p>
                M—A tax exempt trust under a section 403(b) plan or section
                457(g) plan
              </p>
              <p>
                <span className="w9_bold_text">Note:</span> You may wish to
                consult with the financial institution requesting this form to
                determine whether the FATCA code and/or exempt payee code should
                be completed.
              </p>
              <p>
                <span className="w9_bold_text">Line 5</span>
              </p>
              <p>
                Enter your address (number, street, and apartment or suite
                number). This is where the requester of this Form W-9 will mail
                your information returns. If this address differs from the one
                the requester already has on file, write NEW at the top. If a
                new address is provided, there is still a chance the old address
                will be used until the payor changes your address in their
                records.
              </p>
              <p>
                <span className="w9_bold_text">Line 6</span>
              </p>
              <p>Enter your city, state, and ZIP code.</p>
              <h2>Part I. Taxpayer Identification Number (TIN)</h2>
              <p>
                <span className="w9_bold_text">
                  Enter your TIN in the appropriate box.
                </span>{" "}
                If you are a resident alien and you do not have and are not
                eligible to get an SSN, your TIN is your IRS individual taxpayer
                identification number (ITIN). Enter it in the social security
                number box. If you do not have an ITIN, see How to get a TIN
                below.
              </p>
              <p>
                If you are a sole proprietor and you have an EIN, you may enter
                either your SSN or EIN.
              </p>
              <p>
                If you are a single-member LLC that is disregarded as an entity
                separate from its owner, enter the owner’s SSN (or EIN, if the
                owner has one). Do not enter the disregarded entity’s EIN. If
                the LLC is classified as a corporation or partnership, enter the
                entity’s EIN.
              </p>
              <p>
                <span className="w9_bold_text">Note:</span> See{" "}
                <span className="w9_italict_text">
                  What Name and Number To Give the Requester,
                </span>{" "}
                later, for further clarification of name and TIN combinations.
              </p>
              <p>
                <span className="w9_bold_text">How to get a TIN.</span> If you
                do not have a TIN, apply for one immediately. To apply for an
                SSN, get Form SS-5, Application for a Social Security Card, from
                your local SSA office or get this form online at
                <span className="w9_italict_text">
                  {" "}
                  <a
                    className="linked_tle"
                    href="https://www.SSA.gov"
                    target="_blank"
                  >
                    www.SSA.gov
                  </a>
                  .
                </span>{" "}
                You may also get this form by calling 1-800-772-1213. Use Form
                W-7, Application for IRS Individual Taxpayer Identification
                Number, to apply for an ITIN, or Form SS-4, Application for
                Employer Identification Number, to apply for an EIN. You can
                apply for an EIN online by accessing the IRS website at{" "}
                <a
                  className="linked_tle"
                  href="https://www.irs.gov/Businesses"
                  target="_blank"
                >
                  www.irs.gov/Businesses
                </a>{" "}
                and clicking on Employer Identification Number (EIN) under
                Starting a Business. Go to{" "}
                <span className="w9_italict_text">
                  {" "}
                  <a
                    className="linked_tle"
                    href="https://www.irs.gov/Forms"
                    target="_blank"
                  >
                    www.irs.gov/Forms
                  </a>
                </span>{" "}
                to view, download, or print Form W-7 and/or Form SS-4. Or, you
                can go to{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.irs.gov/OrderForms"
                    target="_blank"
                  >
                    www.irs.gov/OrderForms
                  </a>
                </span>{" "}
                to place an order and have Form W-7 and/or SS-4 mailed to you
                within 10 business days.
              </p>
              <p>
                If you are asked to complete Form W-9 but do not have a TIN,
                apply for a TIN and write “Applied For” in the space for the
                TIN, sign and date the form, and give it to the requester. For
                interest and dividend payments, and certain payments made with
                respect to readily tradable instruments, generally you will have
                60 days to get a TIN and give it to the requester before you are
                subject to backup withholding on payments. The 60-day rule does
                not apply to other types of payments. You will be subject to
                backup withholding on all such payments until you provide your
                TIN to the requester.
              </p>
              <p>
                <span className="w9_bold_text">Note:</span> Entering “Applied
                For” means that you have already applied for a TIN or that you
                intend to apply for one soon.
              </p>
              <p>
                <span className="w9_bold_text">Caution:</span> A disregarded
                U.S. entity that has a foreign owner must use the appropriate
                Form W-8.
              </p>
              <h2>Part II. Certification</h2>
              <div className="font20_w9">
                <p>
                  To establish to the withholding agent that you are a U.S.
                  person, or resident alien, sign Form W-9. You may be requested
                  to sign by the withholding agent even if item 1, 4, or 5 below
                  indicates otherwise.
                </p>
                <p>
                  For a joint account, only the person whose TIN is shown in
                  Part I should sign (when required). In the case of a
                  disregarded entity, the person identified on line 1 must sign.
                  Exempt payees, see{" "}
                  <span className="w9_italict_text">Exempt payee code,</span>{" "}
                  earlier.
                </p>
                <p>
                  <span className="w9_bold_text">Signature requirements.</span>{" "}
                  Complete the certification as indicated in items 1 through 5
                  below.
                </p>
              </div>
            </div>
          </div>
          <div className="pageno_flex margtop1">
            <p>Form W-9 (Rev. 10-2018)</p>{" "}
            <p>
              page <span>5</span>
            </p>
          </div>
          <div className="w9_common_flex">
            <div className="w9_left_wrpper">
              <p>
                <span className="w9_bold_text">
                  1. Interest, dividend, and barter exchange accounts opened
                  before 1984 and broker accounts considered active during 1983.
                </span>
                You must give your correct TIN, but you do not have to sign the
                certification.
              </p>
              <p>
                <span className="w9_bold_text">
                  2. Interest, dividend, broker, and barter exchange accounts
                  opened after 1983 and broker accounts considered inactive
                  during 1983.
                </span>{" "}
                You must sign the certification or backup withholding will
                apply. If you are subject to backup withholding and you are
                merely providing your correct TIN to the requester, you must
                cross out item 2 in the certification before signing the form.
              </p>
              <p>
                <span className="w9_bold_text">
                  3. Real estate transactions.
                </span>{" "}
                You must sign the certification. You may cross out item 2 of the
                certification.
              </p>
              <p>
                <span className="w9_bold_text">4. Other payments.</span> You
                must give your correct TIN, but you do not have to sign the
                certification unless you have been notified that you have
                previously given an incorrect TIN. “Other payments” include
                payments made in the course of the requester’s trade or business
                for rents, royalties, goods (other than bills for merchandise),
                medical and health care services (including payments to
                corporations), payments to a nonemployee for services, payments
                made in settlement of payment card and third party network
                transactions, payments to certain fishing boat crew members and
                fishermen, and gross proceeds paid to attorneys (including
                payments to corporations).
              </p>
              <p>
                <span className="w9_bold_text">
                  5. Mortgage interest paid by you, acquisition or abandonment
                  of secured property, cancellation of debt, qualified tuition
                  program payments (under section 529), ABLE accounts (under
                  section 529A), IRA, Coverdell ESA, Archer MSA or HSA
                  contributions or distributions, and pension distributions.
                </span>{" "}
                You must give your correct TIN, but you do not have to sign the
                certification.
              </p>
              <h2>What Name and Number To Give the Requester</h2>
              <table className="table3">
                <tr>
                  <th>
                    <p>For this type of account:</p>
                  </th>
                  <th>
                    <p>Give name and SSN of:</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>1. Individual</p>
                  </td>
                  <td>
                    <p>The individual</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      2. Two or more individuals (joint account) other than an
                      account maintained by an FFI
                    </p>
                  </td>
                  <td>
                    <p>
                      The actual owner of the account or, if combined funds, the
                      first individual on the account <sup>1</sup>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      3. Two or more U.S. persons (joint account maintained by
                      an FFI)
                    </p>
                  </td>
                  <td>
                    <p>Each holder of the account</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      4. Custodial account of a minor (Uniform Gift to Minors
                      Act)
                    </p>
                  </td>
                  <td>
                    <p>
                      The minor <sup>2</sup>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      5. a. The usual revocable savings trust (grantor is also
                      trustee)
                    </p>
                    <p>
                      b. So-called trust account that is not a legal or valid
                      trust under state law
                    </p>
                  </td>
                  <td>
                    <p>
                      The grantor-trustee<sup>1</sup>
                    </p>
                    <p>
                      The actual owner<sup>1</sup>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      6. Sole proprietorship or disregarded entity owned by an
                      individual
                    </p>
                  </td>
                  <td>
                    <p>
                      The owner <sup>3</sup>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      7. Grantor trust filing under Optional Form 1099 Filing
                      Method 1 (see Regulations section 1.671-4(b)(2)(i) (A))
                    </p>
                  </td>
                  <td>
                    <p>
                      The grantor <sup>*</sup>
                    </p>
                  </td>
                </tr>
              </table>
              <table className="table3 bottom_table">
                <tr>
                  <th>
                    <p>For this type of account:</p>
                  </th>
                  <th>
                    <p>Give name and EIN of:</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>8. Disregarded entity not owned by an individual</p>
                  </td>
                  <td>
                    <p>The owner</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>9. A valid trust, estate, or pension trust</p>
                  </td>
                  <td>
                    <p>
                      Legal entity <sup>4</sup>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      10. Corporation or LLC electing corporate status on Form
                      8832 or Form 2553
                    </p>
                  </td>
                  <td>
                    <p>The corporation</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      11. Association, club, religious, charitable, educational,
                      or other tax- exempt organization
                    </p>
                  </td>
                  <td>
                    <p>The organization</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>12. Partnership or multi-member LLC</p>
                  </td>
                  <td>
                    <p>The partnership</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>13. A broker or registered nominee</p>
                  </td>
                  <td>
                    <p>The broker or nominee</p>
                  </td>
                </tr>
              </table>
            </div>

            <div className="w9_right_wrpper">
              <table className="table3">
                <tr>
                  <th>
                    <p>For this type of account:</p>
                  </th>
                  <th>
                    <p>Give name and EIN of:</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>
                      14. Account with the Department of Agriculture in the name
                      of a public entity (such as a state or local government,
                      school district, or prison) that receives agricultural
                      program payments
                    </p>
                  </td>
                  <td>
                    <p>The public entity</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      15. Grantor trust filing under the Form 1041 Filing Method
                      or the Optional Form 1099 Filing Method 2 (see Regulations
                      section 1.671-4(b)(2)(i)(B))
                    </p>
                  </td>
                  <td>
                    <p>The trust</p>
                  </td>
                </tr>
              </table>
              <p>
                <sup>1</sup> List first and circle the name of the person whose
                number you furnish. If only one person on a joint account has an
                SSN, that person’s number must be furnished.
              </p>
              <p>
                <sup>2</sup> Circle the minor’s name and furnish the minor’s
                SSN.
              </p>
              <p>
                <sup>3</sup> You must show your individual name and you may also
                enter your business or DBA name on the “Business
                name/disregarded entity” name line. You may use either your SSN
                or EIN (if you have one), but the IRS encourages you to use your
                SSN.
              </p>
              <p>
                <sup>4</sup> List first and circle the name of the trust,
                estate, or pension trust. (Do not furnish the TIN of the
                personal representative or trustee unless the legal entity
                itself is not designated in the account title.) Also see{" "}
                <span className="w9_italict_text">
                  Special rules for partnerships,
                </span>{" "}
                earlier.
              </p>
              <p>
                <span className="w9_bold_text">*Note:</span> The grantor also
                must provide a Form W-9 to trustee of trust.
              </p>
              <p>
                <span className="w9_bold_text">Note:</span> If no name is
                circled when more than one name is listed, the number will be
                considered to be that of the first name listed.
              </p>
              <h2>Secure Your Tax Records From Identity Theft</h2>
              <p>
                Identity theft occurs when someone uses your personal
                information such as your name, SSN, or other identifying
                information, without your permission, to commit fraud or other
                crimes. An identity thief may use your SSN to get a job or may
                file a tax return using your SSN to receive a refund.
              </p>
              <p>To reduce your risk:</p>
              <ul>
                <li>Protect your SSN,</li>
                <li>Ensure your employer is protecting your SSN, and</li>
                <li>Be careful when choosing a tax preparer.</li>
              </ul>
              <p>
                If your tax records are affected by identity theft and you
                receive a notice from the IRS, respond right away to the name
                and phone number printed on the IRS notice or letter.
              </p>
              <p>
                If your tax records are not currently affected by identity theft
                but you think you are at risk due to a lost or stolen purse or
                wallet, questionable credit card activity or credit report,
                contact the IRS Identity Theft Hotline at 1-800-908-4490 or
                submit Form 14039.
              </p>
              <p>
                For more information, see Pub. 5027, Identity Theft Information
                for Taxpayers.
              </p>
              <p>
                Victims of identity theft who are experiencing economic harm or
                a systemic problem, or are seeking help in resolving tax
                problems that have not been resolved through normal channels,
                may be eligible for Taxpayer Advocate Service (TAS) assistance.
                You can reach TAS by calling the TAS toll-free case intake line
                at{" "}
                <a href="tel:1-877-777-4778" className="linked_tle">
                  1-877-777-4778
                </a>{" "}
                or TTY/TDD{" "}
                <a href="tel:1-800-829-4059" className="linked_tle">
                  1-800-829-4059
                </a>
                .
              </p>
              <p>
                <span className="w9_bold_text">
                  Protect yourself from suspicious emails or phishing schemes.
                </span>
                Phishing is the creation and use of email and websites designed
                to mimic legitimate business emails and websites. The most
                common act is sending an email to a user falsely claiming to be
                an established legitimate enterprise in an attempt to scam the
                user into surrendering private information that will be used for
                identity theft.
              </p>
            </div>
          </div>

          <div className="pageno_flex">
            <p>Form W-9 (Rev. 10-2018)</p>
            <p>
              page <span>6</span>
            </p>
          </div>
          <div className="w9_common_flex">
            <div className="w9_left_wrpper">
              <p>
                The IRS does not initiate contacts with taxpayers via emails.
                Also, the IRS does not request personal detailed information
                through email or ask taxpayers for the PIN numbers, passwords,
                or similar secret access information for their credit card,
                bank, or other financial accounts.
              </p>
              <p>
                If you receive an unsolicited email claiming to be from the IRS,
                forward this message to{" "}
                <span className="w9_italict_text">phishing@irs.gov</span>. You
                may also report misuse of the IRS name, logo, or other IRS
                property to the Treasury Inspector General for Tax
                Administration (TIGTA) at 1-800-366-4484. You can forward
                suspicious emails to the Federal Trade Commission at
                spam@uce.gov or report them at{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.ftc.gov/complaint"
                    target="_blank"
                  >
                    {" "}
                    www.ftc.gov/complaint
                  </a>
                  .
                </span>{" "}
                You can contact the FTC at{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.ftc.gov/idtheft"
                    target="_blank"
                  >
                    www.ftc.gov/idtheft
                  </a>
                </span>{" "}
                or 877-IDTHEFT{" "}
                <a href="tel:877-438-4338" className="linked_tle">
                  (877-438-4338)
                </a>
                . If you have been the victim of identity theft, see{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.IdentityTheft.gov"
                    target="_blank"
                  >
                    www.IdentityTheft.gov{" "}
                  </a>{" "}
                </span>{" "}
                and Pub. 5027.
              </p>
              <p>
                Visit{" "}
                <span className="w9_italict_text">
                  <a
                    className="linked_tle"
                    href="https://www.irs.gov/identity-theft-central"
                    target="_blank"
                  >
                    www.irs.gov/IdentityTheft
                  </a>
                </span>{" "}
                to learn more about identity theft and how to reduce your risk.
              </p>
            </div>
            <div className="w9_right_wrpper">
              <h2>Privacy Act Notice</h2>
              <p>
                Section 6109 of the Internal Revenue Code requires you to
                provide your correct TIN to persons (including federal agencies)
                who are required to file information returns with the IRS to
                report interest, dividends, or certain other income paid to you;
                mortgage interest you paid; the acquisition or abandonment of
                secured property; the cancellation of debt; or contributions you
                made to an IRA, Archer MSA, or HSA. The person collecting this
                form uses the information on the form to file information
                returns with the IRS, reporting the above information. Routine
                uses of this information include giving it to the Department of
                Justice for civil and criminal litigation and to cities, states,
                the District of Columbia, and U.S. commonwealths and possessions
                for use in administering their laws. The information also may be
                disclosed to other countries under a treaty, to federal and
                state agencies to enforce civil and criminal laws, or to federal
                law enforcement and intelligence agencies to combat terrorism.
                You must provide your TIN whether or not you are required to
                file a tax return. Under section 3406, payers must generally
                withhold a percentage of taxable interest, dividend, and certain
                other payments to a payee who does not give a TIN to the payer.
                Certain penalties may also apply for providing false or
                fraudulent information.
              </p>
            </div>
          </div>


          <div className="w9_btn_wrp">
            {visible && (<Button onClick={handleSubmit} className="w9_btn">
              {/* <Button type="submit"  className="w9_btn"> */}
              Submit
            </Button>)}

            {path[1] === "download-w9form" && (<Button onClick={() => window.print()} className="w9_btn downloadBtn" sx={{ width: "auto !important" }}>
              <DownloadIcon /> Download
            </Button>)}


            {data === 1 && (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>)}
          </div>
        </div>
        {/* </form> */}
      </div>
    </>
  );
}

export default W9Form;
