import { LinearProgress, Paper } from '@mui/material'

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { MedicineListing } from '../../Backend/Medicine/MedicineReducer';
import { setIpinfo } from '../../login/loginReducer';


import LandingPage1 from '../LandingPage/LandingPage1/LandingPage1'
import LandingPage10 from '../LandingPage/LandingPage10/LandingPage10';
import LandingPage11 from '../LandingPage/LandingPage11/LandingPage11';
import LandingPage2 from '../LandingPage/LandingPage2/LandingPage2'
import LandingPage3 from '../LandingPage/LandingPage3/LandingPage3';
import LandingPage4 from '../LandingPage/LandingPage4/LandingPage4';
import LandingPage5 from '../LandingPage/LandingPage5/LandingPage5';
import LandingPage6 from '../LandingPage/LandingPage6/LandingPage6';
import LandingPage9 from '../LandingPage/LandingPage9/LandingPage9';
import { getlandingpagedata, getlandingpageoneuserdata } from '../LandingPage/LandingpageReducer';
import PhamacyRedirect from './PhamacyRedirect';
import LandingPage15 from '../LandingPage/LandingPage15/LandingPage15';
import Landingpage15option from '../LandingPage/LandingPage15/Landingpage15option';
import LandingPage15Aspire from '../LandingPage/LandingPage15/LandingPage15Aspire';
import LandingPage15BlueCoast from '../LandingPage/LandingPage15/LandingPage15BlueCoast';
import NewOtherPageStructure from '../LandingPage/NewOtherPage/NewOtherPageStructure';




function Home() {

  const pathname = useLocation();
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log('Landdd',process.env);

  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
      
  }, []);





  






  return (
    <>
      {/* <Noaccesspage /> */}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'dxiSvO69jQgjH') && <LandingPage1 />}

      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'rQLIpATi8DDSn') && <LandingPage2 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 's6UVNi1CVhKMa') && <PhamacyRedirect />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == '0sAQwKXAmAzB1') && <LandingPage4 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && (process.env.REACT_APP_LANDINGPAGE_SLUG == 'CKPMHcnh0ugN3' || process.env.REACT_APP_LANDINGPAGE_SLUG == 'DSqUHfhYLszrA')) && <LandingPage3 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && (process.env.REACT_APP_LANDINGPAGE_SLUG == 'FbqQrFdY3dNIC' || process.env.REACT_APP_LANDINGPAGE_SLUG == 'jLiUc37STmcj8')) && <LandingPage6 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'saVit1YdCuovt') && <LandingPage9 />}

      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'gc7MqHWxe7HIW') && <LandingPage11 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'NycjyqRnjFUEd') && <LandingPage10 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'xP39VNvaFkLxu') && <LandingPage15Aspire/>}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'v2zt0Uj3bprwP') && <LandingPage15BlueCoast />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'XwbuQrPO5f8cF') && <NewOtherPageStructure />}

      


      {/* <LandingPage1/>  */}
      {/* {location.pathname == "/landing-page2" ?   <LandingPage2/>: null}   */}
      {/* <p>Home page 1    </p> */}

      {/* <LandingPage1/>     
      <LandingPage2/>  
          */}


    </>
  )

}

export default Home
