import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// API call for users-list
// export const gettraingcatagorydata = createAsyncThunk("gettraingcatagorydata", async (reqbody) => {

//     console.log("reqbody==>", reqbody);
//     let req_body = {};
//     req_body = { ...reqbody };
//     console.log("req_body==", req_body);
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "api1/traninglist", requestOptions);
//     const respdata = await response.json();
//     // console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;
// }, { unwrap: true });

export const setHttpResponse = createAsyncThunk(
  "setHttpResponse",
  async (body) => {
    let reqBody = {

      data: body ,

    };
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "users/httpres",
      requestOptions
    );
    // const response = await apicall( "contract-manage/contract-list-count", requestOptions);

    const respdata = await response.json();

    return respdata;
  }
);

const ResolveReducer = createSlice({
  name: "ResolveReducer",
  initialState: {
    loader: false,
    resolved: {},
    httprsponsedata: [],
    testStage: 0,
  },
  reducers: {
    sethttprsponsedata(state, action) {
      // console.log("payload", action.payload);
      let datac = JSON.parse(action.payload);
      state.httprsponsedata.push(datac);
    },
    clearSethttprsponsedata(state, action) {
      state.httprsponsedata = [];
    },
    startresolve(state, action) {
      // console.log("in resolve,", action.payload);
      if (
        action?.payload &&
        action.payload &&
        Object.keys(action.payload).length > 0
      ) {
        if (
          action.payload.loader !== null &&
          action.payload.loader !== undefined
        ) {
          state.loader = action.payload.loader;
        }
        if (action.payload.path !== null && action.payload.path !== undefined) {
          state.resolved[action.payload.path] = true;
        }
      }
    },
    resetresolvenew(state, action) {
      if (
        state.resolved &&
        Object.keys(state.resolved).length > 0 &&
        state.resolved[action.payload]
      ) {
        delete state.resolved[action.payload];
      }
      // state.resolved={};
      state.loader = false;
    },
  },
  extraReducers: {
    /// #################### Training Listing ###############################
    [setHttpResponse.rejected]: (state, action) => {
      state.testStage = 3;
    },
    [setHttpResponse.pending]: (state, action) => {
      // console.log("setHttpResponse=========================>");
      state.testStage = 1;
    },
    [setHttpResponse.fulfilled]: (state, action) => {
      // console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.testStage = 2;
      }
    },
  },
});

export default ResolveReducer.reducer;
export const { startresolve, resetresolvenew, sethttprsponsedata,clearSethttprsponsedata } =
  ResolveReducer.actions;
