import React from "react";

import "../../LandingPage5/LandingPage5.css";
import "./asvideoblock.css";
import ReactPlayer from "react-player";

function Asvideoblock() {
  return (
    
      <div className="landing-container">
      <div className="lp18_video_block_main_wrapper">
          <div className="lp18_blue_heading_wrapper">
            <h1>
              Watch the video below and let’s help your friend’s fundraiser by
              saving you money today. For exact fundraising details, ask
              your friend for any additional information.{" "}
              <span>Thank you.</span>{" "}
            </h1>
          </div>
          <div className="lp18_video_sec">
            <div className="l5_videowrapper Videolanding3">
              <div className="l5_vdo_txt_wrp gradiant_fontBW ">
                <h2>ACCESS DISCOUNT HEALTHCARE</h2>
                <p>
                  Pay Nothing For The Most Prescribed Generic Prescriptions!
                </p>
              </div>

              <div className="lpage_banner_sub_wrapper">
                <div className="landpage_videowrapper">
                  <div className="landpage_video_subwrapper">
                    <ReactPlayer
                      className="page5_react_player"
                      // url='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/AspirePharma_banner_video.mp4'
                      url={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}AspirePharma_banner_video.mp4`}
                      light={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}banner_video_img.webp`}
                      playing={true}
                      controls={true}
                      // autoplay={true}
                      // muted={true}
                      // playing={true}
                    />
                    {/* <iframe width="" height="" src="https://all-frontend-assets.s3.amazonaws.com/landing-page5/AspirePharma.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    {/* <iframe
                                                width=""
                                                height=""
                                                src="https://all-frontend-assets.s3.amazonaws.com/landing-page5/AspirePharma.mp4"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                            ></iframe> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}

export default Asvideoblock;
