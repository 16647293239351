import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from "react-cookie";


//--------------------Data Fetch For Campaign listing Data --------------//

export const getCampaignListData = createAsyncThunk("getCampaignList", async (reqBody) => {


    const cookies = new Cookies();
    const token = cookies.getAll().token;

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 100,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                
            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody, token: token };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/user-list-or-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

//----------------------Data fetch for single data and Edit Time--------------//

export const getCampaignEdit = createAsyncThunk("getCampaignEdit", async (id) => {
    let reqBody = {
        "condition": {},
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/single-fetch-data", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getAllCampaignListData = createAsyncThunk("getAllCampaignListData", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    const cookies = new Cookies();
    const token = cookies.getAll().token;

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 50,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {
            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody, token: token };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/user-list-or-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const addusernamedata = createAsyncThunk("addusernamedata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/set-username", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// --------------------click Count and conversion count--------------------

export const conversionListData = createAsyncThunk("conversionlist", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/conversionlist", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
export const conversionListDatacon = createAsyncThunk("conversionListDatacon", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/conversionlist", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

const camPaignManagementReducer = createSlice({
    name: 'camPaignManagementReducer',
    initialState: {

        // mindate: moment().subtract(7, "days").valueOf(),
        // maxDate: moment().valueOf(),
        mindate:null,
        maxDate:null,


        submitting: false,
        loading: false,
        editloading: false,
        campaignData: [],
        editCampaignData: [],
        message: null,
        addusernamestate: 0,
        allcampaignData: [],
        conversionList: [],
        conversionListforconversion:[]


    },

    reducers: {
        clearcampaignDatadata(state, action) {
            state.campaignData = []
            // state.loading=false;
        },
        cleareditdata(state, action) {
            state.editCampaignData = []
        },
        setmindate(state, action){
            state.mindate=action.payload
        },
        setmaxDate(state, action){
            state.maxDate=action.payload
        },
        clearConversionListData(state, action){
            state.conversionList = []
        },
        clearData(state, action){
            state.mindate=null;
            state.maxDate=null;
        }

    },

    extraReducers: { 

         // ------------------For Campaign List Data------------//

         [getCampaignListData.pending]: (state, action) => {
            state.loading = true;
        },
        [getCampaignListData.fulfilled]: (state, action) => {
            console.log("actionsdsfdsfsd",action);
            if (action.payload.status === 'success') {
                state.campaignData = action.payload.results.res;
                state.loading = false;
            }
        },
        [getCampaignListData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },


        // --------------------------For Campaign Edit Data-----------------//

        [getCampaignEdit.pending]: (state, action) => {
            console.log("getroleeditdata=========================>")
             state.editloading = true;
         },
         [getCampaignEdit.fulfilled]: (state, action) => {
 
             console.log("action from storedata", action)
             if (action.payload.status === 'success') {
 
                 state.editCampaignData = action.payload.results.res;
                 state.editloading = false;
 
             }
 
         },
         [getCampaignEdit.rejected]: (state, action) => {
             state.endpointsuccess[action.type] = false
             // state.loading = false;
             // state.successAction = false;
 
         },




         [addusernamedata.pending]: (state, action) => {
            state.addusernamestate = 3
         },
         [addusernamedata.fulfilled]: (state, action) => {
 
            state.addusernamestate = 2
 
         },
         [addusernamedata.rejected]: (state, action) => {
            state.addusernamestate = 1
 
         },



         [getAllCampaignListData.pending]: (state, action) => {
            console.log("getroledata=========================>")
            state.loading = true;
        },
        [getAllCampaignListData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.allcampaignData = action.payload.results.res;
                state.loading = false;

            }

        },
        [getAllCampaignListData.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },

        [conversionListData.pending]: (state, action) => {
            console.log("getroledata=========================>")
            state.loading = true;
        },
        [conversionListData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.conversionList = action.payload.results.res;
                state.loading = false;

            }

        },
        [conversionListData.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [conversionListDatacon.pending]: (state, action) => {
            console.log("getroledata=========================>")
            state.loading = true;
        },
        [conversionListDatacon.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.conversionListforconversion = action.payload.results.res;
                state.loading = false;

            }

        },
        [conversionListDatacon.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        

    }


})

export default camPaignManagementReducer.reducer;
export const {clearcampaignDatadata,cleareditdata,setmaxDate,setmindate, clearConversionListData , clearData} = camPaignManagementReducer.actions;

