import React, { useEffect, useState } from "react";
import { InputLabel, TextField, Icon, FormControl } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import store from "../../store";
import { resetFormFieldError, resetTempFormUpdateData } from "../formReducer";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({
  fielddata,
  value,
  setValue,
  getValues,
  errors,
  clearErrors,
  formId,
  setError,
}) => {
  const [val, setVal] = useState(null);
  const [customError, setCustomError] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // console.log("token======>", token);
  }, [token]);

  const dispatch = useDispatch();

  const recaptchaRef = React.createRef();

  /////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, []);

  store.subscribe(() => {
    const storeData = store.getState();

    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    if (
      Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 &&
      storeData.formSlice.tempFormUpdateData[formId] !== undefined &&
      storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !==
      undefined
    ) {
      // console.log("storing====>");
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name]);
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (
      Object.keys(storeData.formSlice.tempFormFieldError).length > 0 &&
      storeData.formSlice.tempFormFieldError[formId] !== undefined &&
      storeData.formSlice.tempFormFieldError[formId][fielddata.name] !==
      undefined
    ) {
      // console.log("storing====>");
      setCustomError(
        storeData.formSlice.tempFormFieldError[formId][fielddata.name]
      );
    }
  });

  //////////////////////////////////////// Effect the field value update from outside //////////////////////////////
  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }));
    }
  }, [val]);

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      // console.log("customError", customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }));
    }
  }, [customError]);

  //   const capToken = async () => {

  // };

  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && (
        <InputLabel>
          <div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div>
        </InputLabel>
      )}
      {/* FIELD */}
      <FormControl>
        <ReCAPTCHA
          // autoComplete="new-password"
          ref={recaptchaRef}
          // ref={(ref) => (this.recaptchaRef = ref)}
          sitekey={
            fielddata.sitekey
              ? fielddata.sitekey
              : "6LfcCaImAAAAAHjTMHvdGeyrHV1ECA94_pzTNlkz"
          }
          className={fielddata.type === "captcha" ? "captchaField" : ""}
          onChange={(e) => {
            // console.log("eeee===>", e);

            // setValue(fielddata.name, e.target.value);

            setToken(e);

            setValue(fielddata.name, e);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          // label={fielddata.label}
          error={
            Object.keys(errors).length > 0 && errors[fielddata.name] != null
          }
          helperText={
            errors[fielddata.name] && errors[fielddata.name].type
              ? fielddata.errorMessage[errors[fielddata.name].type]
              : ""
          }
        />
        {Object.keys(errors).length > 0 && errors[fielddata.name] != null &&

          <div className="captchaErrorCls">
            {errors[fielddata.name] && errors[fielddata.name].type
              ? fielddata.errorMessage[errors[fielddata.name].type]
              : ""}
          </div>

        }

      </FormControl>
    </div>
  );
};

export default Captcha;
