import React, { useEffect, useRef, useState } from "react";

const OTPVerificatioTrial = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");

  const handleInputChange = (index, value) => {
    if (isNaN(value)) {
      setError("Please enter only numeric values.");
      return;
    }

    setError("");
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus to the next input field
    if (index < otp.length - 1 && value !== "") {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleInputPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text/plain")
      .slice(0, otp.length);
    const newOtp = [...otp];

    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
    }

    setOtp(newOtp);
  };

  return (
    <>
      <div>
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onPaste={(e) => handleInputPaste(e)}
          />
        ))}
        {error && <p>{error}</p>}
        <button onClick={() => console.log(otp.join(""))}>Verify OTP</button>
      </div>
    </>
  );
};

export default OTPVerificatioTrial;
