import { IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import { Box } from '@mui/system';
import store from '../../../store';
import { BlueCoastlist, blueCoastNotes, blueCoastNotesViewTable, clearNotesViewList } from './BlueCoastReducer';

function NotesView({ reqBody, setNotesViewOpen, setOpenAllNotesModalLoader, menuSelect, notesReqBody, dispatchMainTable, dispatchPorspectNote }) {

  const dispatch = useDispatch();

  // console.log("props1234>>>>", props)
  // console.log("props1234>>>>1", reqBody)
  const [dense, setDense] = React.useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [notesViewtableData, setNotesViewtableData] = useState([]);
  const [tabledata, setTabledata] = useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [page, setPage] = React.useState(0);

  const [reload, setReload] = useState(false)



  const notesloading = useSelector(state => (state.BlueCoastSlice?.loading) ? state.BlueCoastSlice?.loading : false)

  const notesViewtable = useSelector((state) => (state.BlueCoastSlice?.notesViewTablesuccess && state.BlueCoastSlice?.notesViewTablesuccess.length > 0) ? state.BlueCoastSlice.notesViewTablesuccess : [])
  const notesWithoutSpreedData = useSelector((state) => (state.BlueCoastSlice?.notesWithoutSpreedData && state.BlueCoastSlice?.notesWithoutSpreedData.length > 0) ? state.BlueCoastSlice.notesWithoutSpreedData : [])
  // console.log("notesViewtableData1133>>>>", notesViewtableData);




  store.subscribe(() => {
    const storeData = store.getState();

    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    if (storeData.BlueCoastSlice?.notesViewTablesuccess && storeData.BlueCoastSlice?.notesViewTablesuccess.length > 0) {
      // console.log("storing====>")
      setNotesViewtableData(storeData.BlueCoastSlice?.notesViewTablesuccess)
      // setTabledata(notesViewtableData)
    }


  })


  useEffect(() => {
    if (notesViewtableData.length > 0) {

      setTabledata(notesViewtableData)
    }

  }, [notesViewtableData])





  useEffect(() => {
    if (isFetching === true) {
      moreData();
    }
    // console.log("Fetch data=====================>", isFetching)
  }, [isFetching]);


  useEffect(() => {

    // console.log("Fetch data=====================> 1", notesWithoutSpreedData?.length, notesloading)
  }, [JSON.stringify(notesWithoutSpreedData), notesloading]);


  const moreData = async () => {

    setPage(page + 1)

    let newpage = page + 1

    // let skip=0
    let skip = newpage * 30

    let reqBody1 = {
      "condition": {
        "limit": 30,
        "skip": skip,
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": reqBody,
      // "searchcondition": {},
      "project": {},
      "token": "",
      "email": "",
      "count": false,
    }

    await dispatch(blueCoastNotesViewTable(reqBody1))

    if (notesloading === false && notesWithoutSpreedData.length > 0) {

      setTimeout(() => {

        setIsFetching(false)
      }, 2000);


    }
  }



  function handleScroll() {

    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      // console.log("scroll Hit===========>1");

      return;
      // console.log("scroll Hit===========>2");

    }
    else {
      // if (notesloading === false && notesWithoutSpreedData.length > 0) {

      // setIsFetching(false)

      setIsFetching(true)
      // console.log("scroll Hit===========>2");
      // }

    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  useEffect(() => {
    if (reload === true) {

      const reqBody1 = {
        "condition": {
          "limit": 30,
          "skip": 0,
        },
        "sort": {
          "field": "createdon_datetime",
          "type": "desc"
        },
        "searchcondition": reqBody,
        // "searchcondition": {},
        "project": {},
        "token": "",
        "email": "",
        "count": false,
      }
      dispatch(blueCoastNotesViewTable(reqBody1))
    }
    setReload(false);


  }, [reload])


  const backtoMainList = () => {
    // console.log("backtoMainList123>>>", backtoMainList);
    if (menuSelect == 1) {
      // console.log("menuSelect====>>>>", menuSelect);
      reqBody[
        "outsideData"
      ] = { $exists: false };
      reqBody[
        "bluecoastPh"
      ] = { $exists: false };
      reqBody[
        "bluecoastphblue"
      ] = { $exists: false };
      reqBody[
        "accessfree"
      ] = { $exists: false };
      reqBody[
        "is_deleted"
      ] = { $exists: false };

      // notesReqBody
      // notesReqBody[
      //   "outsideData"
      // ] = { $exists: false };
      // notesReqBody[
      //   "bluecoastPh"
      // ] = { $exists: false };
      // notesReqBody[
      //   "bluecoastphblue"
      // ] = { $exists: false };
      // notesReqBody[
      //   "accessfree"
      // ] = { $exists: false };
      // notesReqBody[
      //   "is_deleted"
      // ] = { $exists: false };
    } else if (menuSelect == 2) {
      reqBody = {
        outsideData: true,
        is_deleted: { $exists: false },
      }
    } else if (menuSelect == 5) {
      reqBody = {
        bluecoastPh: { $exists: true },
        is_deleted: { $exists: false },
      }
    } else if (menuSelect == 6) {
      reqBody = {
        bluecoastphblue: { $exists: true },
        is_deleted: { $exists: false },
      }
    } else if (menuSelect == 9) {
      reqBody = {
        $or: [
          { bluecoastphblue: { $exists: true } },
          { bluecoastPh: { $exists: true } },
        ],
      }
    } else if (menuSelect == 10) {
      reqBody = {
        accessfree: { $exists: false },
        bluecoastPh: { $exists: false },
        bluecoastphblue: { $exists: false },
        is_deleted: { $exists: false },
      }
    }
    dispatch(blueCoastNotes(dispatchPorspectNote))
    dispatch(BlueCoastlist(dispatchMainTable))
    setOpenAllNotesModalLoader(true)
    dispatch(clearNotesViewList())
    setOpenAllNotesModalLoader(false)
    setTimeout(() => {
      setNotesViewOpen(false)

    }, 2000);

  }




  return (
    <>

      {/* {(notesloading === true) && <LinearProgress />} */}

      <div className='rolelist_mainwrp NotesViewList_main'>
        <div className='listing_heading'>

          <h1>Notes View table</h1>


          <div className='srchfld_wrp'>
            <Tooltip title="Back to Main List">
              <IconButton className='back2mainListBtn' onClick={() => backtoMainList()}>
                <ReplyAllIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Search">
              <IconButton className='filtersearch' >
                <SearchOutlinedIcon />
              </IconButton>
            </Tooltip> */}


            {/* <Tooltip title="Reload">
              <IconButton className='reloadsearch'    onClick={() => setReload(true)}>
           
                <ReplayIcon />
              </IconButton>
            </Tooltip> */}


          </div>

        </div>

        {notesloading && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}

        <TableContainer className='rolelist_mainwrp_Table'>
          {notesViewtableData?.length > 0 ? (<Table sx={{ minWidth: 750 }} aria-label="caption table" size={dense ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <TableCell className='index_column index_header'>#</TableCell>
                <TableCell>	AE/REP/Advisor (Name/Phone No./Email)</TableCell>
                <TableCell align="right">Prospect's (Name/Phone No./Email)</TableCell>
                <TableCell align="right">Notes</TableCell>
                <TableCell align="right">Creation Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notesViewtableData?.length > 0 && notesViewtableData.map((notesData, index) => (
                <TableRow key={index}>
                  <TableCell className="index_column index_cell">{notesData.index ? index + 1 : index + 1}</TableCell>
                  <TableCell align="right"><span dangerouslySetInnerHTML={{ __html: notesData?.ae_details }}></span></TableCell>
                  <TableCell align="right"><span dangerouslySetInnerHTML={{ __html: notesData?.prospect_details }}></span></TableCell>
                  <TableCell align="right">{notesData?.notes}</TableCell>
                  <TableCell align="right">{moment(notesData?.createdon_datetime).format('dddd, MMMM Do YYYY, h:mm a')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>) : (<TableSkeleton count={10} />)}
          {isFetching}
          {notesloading && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}
        </TableContainer>


      </div>
    </>
  )
}

export default NotesView