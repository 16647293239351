import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { removeField, setField } from '../../../../form/formReducer';
import { addlandingpageninedatabluecosde, clearadddsuccessflag, fetchbluecodelead } from '../LandingpageReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import store from '../../../../store';
import "./LandingPage15.css";
import { setIpinfo } from '../../../login/loginReducer';
import StateArray from '../../../../assets/json/state';

function Lp15AspireBlCstForm({ flag }) {
  console.log("flag++++++++", flag);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
  }, []);

  const loading = useSelector(state => (state.LandingpagefontReducer?.bluecoseloader) ? state.LandingpagefontReducer?.bluecoseloader : false)
  const formInputData = useSelector((state) => state.formSlice.formData.userFormAsprBlcst15 ? state.formSlice.formData.userFormAsprBlcst15 : null);
  const otherRadioButtonClicked = useSelector((state) => (state.formSlice?.formData?.userFormAsprBlcst15 && Object.keys(state.formSlice.formData.userFormAsprBlcst15).length > 0) ? state.formSlice.formData.userFormAsprBlcst15 : undefined)
  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userFormAsprBlcst15) ? state.formSlice?.formSubmissionState?.userFormAsprBlcst15 : 0)

  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});

  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
  const leadaddeddata = useSelector(state => (state.LandingpagefontReducer?.bluecouseadddata) ? state.LandingpagefontReducer?.bluecouseadddata : null)
  const leadaddeddataFlag = useSelector(state => (state.LandingpagefontReducer?.adddsuccessflag) ? state.LandingpagefontReducer?.adddsuccessflag : false)



  useEffect(() => {
    console.log("otherRadioButtonClicked", otherRadioButtonClicked);
    if (otherRadioButtonClicked && otherRadioButtonClicked.business_or_group_type) {
      if (otherRadioButtonClicked.business_or_group_type && otherRadioButtonClicked.business_or_group_type == 'Other') {

        console.log("otherRadioButtonClicked", otherRadioButtonClicked);


        let field = {

          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: 'formfild formfild1',
          // multiple: true,
          type: "text",


        };
        setTimeout(() => dispatch(setField({ formId: "userFormAsprBlcst15", after: "business_or_group_type", fieldData: field })), 200);


      } else {
        setTimeout(() => dispatch(removeField({ formId: "userFormAsprBlcst15", name: "other" })), 2000)

      }


    }

  }, [JSON.stringify(otherRadioButtonClicked)])

  useEffect(() => {
    if (leadaddeddata && Object.keys(leadaddeddata) && leadaddeddataFlag) {

      // dispatch(
      //   setSnackbar({ open: true, message: 'Thank You for Submiting!', status: 'success' })
      // )
      // resolvefunction(navigate, `/thankyousubmission/${leadaddeddata._id}`, fetchbluecodelead(leadaddeddata._id))
      // // navigate(`/thankyou/${leadaddeddata._id}`)
      // dispatch(clearadddsuccessflag())
    }
  }, [leadaddeddataFlag, JSON.stringify(leadaddeddata)])
  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);

  const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val }
    let formbody = {
      "first_name": val.first_name,
      "last_name": val.last_name,
      "email": val.email,
      "phone": val.phone,
      "prospectbusinessname": val.prospect_business_name,
      "prospectfirstname": val.prospect_first_name,
      "prospectlastname": val.prospect_last_name,
      "prospecttitle": val.prospect_title,
      "prospectemail": val.prospect_email,
      "prospectcontactnumber": val.secondary_phone,
      "city": val.city,
      "state": val.state,
      "medicalfacilitytype": val.business_or_group_type,
      // "facilitytype": val.facility_type,
      "other": val.other,
      "subscription_membership": val.subscription_membership,
      "natureofrelationship": val.nature_of_relationship,
      "relationshipdescription":val.relationshipdescription,
      "approxgrossreciept": val.approx_gross_reciepts,
      "createdon_datetime": new Date().getTime(),
      "added_type": 'landingpage',
      "page_id": userData.landing_page_id,
      "page_slug": userData.landing_page_slug,
      "ipinfo": ipinfo,
      "other_info": val.other_info,
      "extensive_notes": val.extensive_notes,
      "company_website": val.company_website,
      "number_of_people_type":val.number_of_people_type
      // 'bluecoastPh':true
    }
    if (flag && flag == 1) {
      formbody['bluecoastPh'] = true
    }
    if (flag && flag == 2) {
      formbody['bluecoastphblue'] = true
    }

    if (Object.keys(formbody).length > 0) {
      console.log("formbody-----", formbody);
      // return
      // setloading(true)
      let dataset = await dispatch(addlandingpageninedatabluecosde(formbody));
      console.log("Response dataset", dataset);

     
      if (dataset && dataset.payload && dataset.payload.status == 'error') {
        store.dispatch(
          setSnackbar({ open: true, message: dataset.payload.message ? dataset.payload.message : 'Something went wrong', status: 'error' })
        )
      }else{
        redirect(dataset.payload.results._id)
        
      }
      // setActive(false)
      // setloading(false)

    }
  };
const redirect=async(id)=>{
  dispatch(
    setSnackbar({ open: true, message: 'Thank You for Submiting!', status: 'success' })
  )
  resolvefunction(navigate, `/thankyousubmission/${id}`, fetchbluecodelead(id))
  // navigate(`/thankyou/${leadaddeddata._id}`)
  dispatch(clearadddsuccessflag())
}
 


  const form15AsprBlcst = useRef({
    id: 'userFormAsprBlcst15',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {

      "status": 0,
      // "roles": { is_rep: 1 },

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "first_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 15,
        heading: "Your Last Name",
        label: "Last Name",
        name: "last_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 13,
        heading: "Your Email",
        label: "Your Email",
        name: "email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 14,
        heading: "Your Phone Number",
        label: "Your Phone Number",
        name: "phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 16,
        heading: "Potential Pharmacy Partner Name",
        label: "Potential Pharmacy Partner Name",
        name: "prospect_business_name",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 21,
        heading: "Prospect's Title",
        label: "Prospect's Title",
        name: "prospect_title",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 17,
        heading: "Prospect's First Name",
        label: "Prospect's First Name",
        name: "prospect_first_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 18,
        heading: "Prospect's Last Name",
        label: "Prospect's Last Name",
        name: "prospect_last_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 19,
        heading: "Prospect's Email",
        label: "Prospect's Email",
        name: "prospect_email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 20,
        heading: "Prospect's Phone Number ",
        label: "Prospect's Phone Number ",
        name: "secondary_phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 42,
        heading: "Town / City",
        label: "Town / City",
        name: "city",
        className: 'form_item formfild formfild2 city',
        type: "text",
        rules: { required: true },
        errorMessage: {
            required: "This Field is Required",
            custom: "Value is Invalid"
        },

    },
    {
        id: 41,
        heading: "State",
        label: "State",
        name: "state",
        className: 'form_item formfild formfild2 state',
        type: "select",
        // other: true,
        values: StateArray,
        rules: { required: true },
        errorMessage: {
            required: "This Field is Required",
            custom: "Value is Invalid"
        },
        defaultValue: "",
    },


      {
        id: 11,
        heading: "Type of business or group",
        name: "business_or_group_type",
        className: 'form_radio',
        values: [{ key: 'Affinity Group', val: 'Affinity Group' }, { key: 'MLM', val: 'MLM' }, { key: 'Affiliate Organization', val: 'Affiliate Organization' }, { key: 'Employer', val: 'Employer' }, { key: 'Other', val: 'Other' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: "No"
      },

      {
        id: 3,
        heading: "Subscription Membership Opportunity Lead (Group must be over 250 Members or Employees)",
        // label: "Submitted Opportunities must have a reach of 5000 or more",
        name: "subscription_membership",
        className: " inputBlock1line checkBox_subscrptn",
        type: 'singleCheckbox',
        values: { key: "Yes", val: 'Yes, this group has a reach of 250+ Members or Employees' },
        // defaultValue: true,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },


      },

      // {
      //   id: 22,
      //   heading: "Type of facility",
      //   name: "facility_type",
      //   className: 'form_radio',
      //   values: [{ key: 'Med Practice', val: 'Med Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Other', val: 'Other' }],
      //   type: 'radio',
      //   // hint: 'Yes/No',
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },



      // {
      //   id: 1,
      //   heading: "Name of medical facility",
      //   label: "Name of medical facility",
      //   name: "medical_facility_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      // },
      // {
      //   id: 2,
      //   heading: "Name of their relationship at facility and that persons title (would be good to have this fill able so that they can type details)",
      //   label: "Name of their relationship at facility and that persons title",
      //   name: "relationship_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true},
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },

      {
        id: 40,
        heading: "Number of Members or Employees ",
        label: "How many people are in your organization? ",
        name: "number_of_people_type",
        className: 'formfild formfild4 employeeNum',
        type: "text",
        // inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 31,
        heading: "Website",
        label: "Website",
        name: "company_website",
        className: 'formfild formfild1 webSite',
        type: "text",
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 10,
        heading: "What is your relationship with the prospect?  ",
        name: "nature_of_relationship",
        className: 'form_radio',
        values: [{ key: 'Client', val: 'Client' }, { key: 'Client/Friend', val: 'Client/Friend' }, { key: 'Friend', val: 'Friend' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: "No"
      },
      {
        id: 51,
        heading: "Please describe your relationship",
        placeholder: "Please describe your relationship",
        label: "Please describe your relationship",
        name: "relationshipdescription",
        className: "formfild inputBlock1line grossreceipts",
        type: "text",
        // inputType: "email",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid",
        // },
      },
      // {
      //   id: 24,
      //   type: "html",
      //   className: " inputBlock1line heading_subscrptn",
      //   value: "<p>Subscription Membership Opportunity Lead</p>"
      // },




      // {
      //   id: 3,
      //   heading: "Approx gross receipts with top 3 insurers",
      //   label: "Approx gross receipts with top 3 insurers",
      //   name: "approx_gross_reciepts",
      //   className: 'formfild',
      //   type: "text",
      //   // inputType: "email",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },
     
      {
        id: 20,
        heading: "Any other information we should know?",
        placeholder: 'Any other information we should know?',
        label: "Any other information we should know?",
        name: "other_info",
        className: 'formfild textareafild ',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        }

      },
      {
        id: 22,
        heading: "Must add extensive notes for this to be reviewed for qualification",
        placeholder: 'Must add extensive notes for this to be reviewed for qualification',
        label: "Must add extensive notes for this to be reviewed for qualification",
        name: "extensive_notes",
        className: 'formfild textareafild',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        }

      },
      {
        id: 125,
        // heading: "Captcha",
        // label: "Captcha",
        name: "captcha",
        className: 'formfild formfild4',
        type: "captcha",
        rules: { required: true },
        errorMessage: {
          required: "Prove, You're Not a Robot",
          custom: "Value is Invalid"
        },
      },

    ]

  });

  return (
    <>
      <div>
        <Form formData={form15AsprBlcst.current} />
        {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}
      </div>
    </>
  )
}

export default Lp15AspireBlCstForm