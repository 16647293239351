import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));




export default function CustomizedAccordions({AccordionVal}) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };







  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography className='lp14_txt_wrapper1'>What’s the difference between a brand-name drug and a generic equivalent drug?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            According to the Food and Drug Administration, generic drugs are the same as their brand-name equivalents in active ingredients, dosage, safety, strength, and performance. They are held to the same strict standards as their brand-name counterparts. The only noticeable difference between a generic drug and its brand-name counterpart may be its shape and/or color. While generic drugs are just as effective as the corresponding brand-name drugs, they typically cost up to 70 percent less, which helps control healthcare costs. This helps you in the long run because the generic option is always the lowest cost.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className='lp14_txt_wrapper1'>What if my drug is not available in generic form?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            Even if your drug is not yet available in generic form, there may be a similar generic drug that can be just as effective. If you are currently taking a brand-name medication, ask your doctor about if there is a generic equivalent drug that could treat your condition just as well. In most cases, brand-name drugs are protected under a 10–17-year patent and a generic equivalent cannot be made. During this period of time, manufacturers control pricing to make up for expensive research and development. In the interests of saving time, and money and taking care of patients, most doctors will consider substituting a generic therapeutic alternative. A therapeutic similar is actually the generic match for the brand-name drug that treats the same condition using different ingredients. In most cases, these alternatives are just as effective.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography className='lp14_txt_wrapper1'>What should I ask my doctor?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
          First, make sure your doctor knows you have a new pharmacy program and that you get any of the 605 generic medications for FREE. Then, ask, “Would one of these generic drugs be a good option for me, and could it treat my condition similarly?” You can also ask what other brand­ name drugs you are taking that could be replaced with generic options on this list. Remember, most medications Americans take are generic.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography className='lp14_txt_wrapper1'>Why aren’t ALL medicines on your drug list?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            Generic medications make up for 85% of the medications dispensed in America yearly. Our list accounts for 90% of medications prescribed. Our list has medications grouped in more than 22 therapeutic classes making sure that we have a very well-rounded formulary treating a variety of chronic and acute conditions.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography className='lp14_txt_wrapper1'>Will all prescribed drugs be free at no cost to me?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            Yes, all acute and chronic generic medications in our formulary are free to members.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography className='lp14_txt_wrapper1'>Can I begin using this benefit right after I sign up? Or is there a minimum wait time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            Yes, you can use it immediately for online orders and retail 24 hours after enrolling.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography className='lp14_txt_wrapper1'>Are my brand name drugs free?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            Only your generic medications on the formulary are free with your monthly subscription.
            Our pharmacists can help determine if there is an equivalent drug to help meet your medical need. Or, you may obtain brand name medications through your retail pharmacy at a contracted cost.

          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography className='lp14_txt_wrapper1'>Does this plan have over-the-counter medications, and are they also free?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='lp14_txt_wrapper2'>
            We offer over-the-counter medications through mail order at 50% or more off retail prices.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {AccordionVal > 8 &&

        <>
          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
            <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
              <Typography className='lp14_txt_wrapper1'>Are there any additional costs?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Standard shipping is free. Expedited shipping prices are as follows: Next Day
                $44.95, 2 Day $29.95, 3rd day $19.95. Over-the-counter medications only are $5.99 for shipping.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
            <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
              <Typography className='lp14_txt_wrapper1'>How many of my family members can be on the plan with me?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                As many family members as you’d like as long as they are dependent. All family members and dependents on the plan must live at the same address.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
            <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
              <Typography className='lp14_txt_wrapper1'>Can I have multiple addresses for family members?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                All family members must live at the same address.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
            <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
              <Typography className='lp14_txt_wrapper1'>Can I cancel at any time?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Yes
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
            <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
              <Typography className='lp14_txt_wrapper1'>How do I transfer my prescriptions?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Our pharmacist will contact your local pharmacy or physician and transfer your prescription to us.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
            <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
              <Typography className='lp14_txt_wrapper1'>What will the quantity be that I will receive as free?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                The quantity will be as per the doctor’s order on the prescription. However, there is a maximum quantity limit allowed by the program. Charges may occur for maximum quantity limits exceeded.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
            <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
              <Typography className='lp14_txt_wrapper1'>Do you support 30-day prescriptions? How about 90-day prescriptions?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Yes, we support 30-day and 90-day prescriptions. The most economical will be a 90-day supply!
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
            <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
              <Typography className='lp14_txt_wrapper1'>Is there a limit to how many prescriptions I can get filled?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                There is no limit.
              </Typography>
            </AccordionDetails>
          </Accordion>


        </>




      }

      {AccordionVal > 16 &&

        <>

          <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
            <AccordionSummary aria-controls="panel17d-content" id="panel17d-header">
              <Typography className='lp14_txt_wrapper1'>What type of membership card will I receive?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                A digital member card will be available online to download or print after you enroll.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
            <AccordionSummary aria-controls="panel18d-content" id="panel18d-header">
              <Typography className='lp14_txt_wrapper1'>Do I have to contact my doctor?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Our pharmacists will contact your doctor to request a new prescription or refill.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
            <AccordionSummary aria-controls="panel19d-content" id="panel19d-header">
              <Typography className='lp14_txt_wrapper1'>Can I use this plan at a retail location?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Yes, present your membership card to your local pharmacy. Your prescription cost will be at the contractual rate.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
            <AccordionSummary aria-controls="panel20d-content" id="panel20d-header">
              <Typography className='lp14_txt_wrapper1'>Are diabetic drugs covered for free? What about meters and test strips?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                You will receive a free meter, lancing device, test solution, and lancets. Members can buy test strips (50) for $3.50.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
            <AccordionSummary aria-controls="panel21d-content" id="panel21d-header">
              <Typography className='lp14_txt_wrapper1'>If I don’t use the plan, will I still be charged?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Yes. A monthly subscription applies to remain active in the program.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
            <AccordionSummary aria-controls="panel22d-content" id="panel22d-header">
              <Typography className='lp14_txt_wrapper1'>Can I manage my account online: Change my credit card? Can I cancel online? Can I change my address?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Call customer service to update your account.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
            <AccordionSummary aria-controls="panel23d-content" id="panel23d-header">
              <Typography className='lp14_txt_wrapper1'>How long will it take for me to receive my order?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                It will depend on your shipping preference. If you use standard shipping, delivery time is 2-4 days.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
            <AccordionSummary aria-controls="panel24d-content" id="panel24d-header">
              <Typography className='lp14_txt_wrapper1'>Do you ship to P.O. Boxes?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Yes, we can ship to a P.O. Box.
              </Typography>
            </AccordionDetails>
          </Accordion>




        </>



      }

      {AccordionVal > 24 &&
        <>

          <Accordion expanded={expanded === 'panel25'} onChange={handleChange('panel25')}>
            <AccordionSummary aria-controls="panel25d-content" id="panel25d-header">
              <Typography className='lp14_txt_wrapper1'>Is tracking information available?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                Yes, your delivery courier provides tracking information.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel26'} onChange={handleChange('panel26')}>
            <AccordionSummary aria-controls="panel26d-content" id="panel26d-header">
              <Typography className='lp14_txt_wrapper1'>What if my medication is not on the formulary list?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='lp14_txt_wrapper2'>
                If the medication is not on the formulary, call customer service to see if we can special order the medication. Additionally, our pharmacists can help determine an equivalent drug to help meet your medication need.
              </Typography>
            </AccordionDetails>
          </Accordion>




        </>

      }



    </div>
  );
}