import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import store from "../../../../store";
import CloseIcon from "@mui/icons-material/Close";

// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Grid, IconButton, LinearProgress, List, Tooltip } from "@mui/material";
// import { timeConverter } from "../../helper/helperFunctions";
import { Box, display } from "@mui/system";
// import { useCookies, Cookies } from "react-cookie";
// import { clearViewData } from "../../listing/listReducer";
// import { clearViewMaterialData } from "../../Pages/Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { Carousel } from "react-responsive-carousel";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from "./FDSearch";
import { UserActivity } from "./folderViewReducer";


const TrainingProgress = (props) => {
    const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const [queryParamId, setQueryParamId] = useState(); 

    // ---- States ----
    const [loading, setLoading] = useState(true); //*****FOR LODING****//

    // ---- Cookies ---
    // const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // // ---- UseSelectors ----
    // const users = useSelector((state) => state.userManagment.usersList)
    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)

    // --- loader --- 
    const loader = useSelector(state => (state.userManagment?.loading
    ) ? state.userManagment.loading
        : null);

    console.log("<<<<< props from training Progress  compo >>>>", props);

    // // ------- onModalClose --------
    function ModalClose() {
        props.popuptooglefunc(false);

    }

    const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

    const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

    
//-------------From Input fields for serach--------------------------------------
    const searchFields=[
        {
            id: 0,
            label: "Search by Category ",
            name: "category",
            className: 'inputBlock inputBlock3line',
            type: "autoComplete",
            sx: { m: 1, width: "100%" },
            base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-training-category",
            reqBody: {
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "field": "_id",
                "type": "desc"
              },
              "searchcondition": {
                "user_id":queryParamId?queryParamId:undefined
                
              },
              "project": {},
              "token": "",
              "email": ""
            },
          },
          
    ]
    //---------------------------------------------------------------------

    //-----------------------get data from search fields--------------------

    useEffect(() => {
        if (submissionState === 4) {
          setSearchDraw(false)
            // let activity_time={
            //     "$gte":searchData?.start_date?searchData.start_date:undefined,
            //     "$lte":searchData?.end_date?searchData.end_date:undefined
            //   }
        
            //   activity_time.$lte !== " " || activity_time.$lte !== undefined &&  delete activity_time["$lte"];
        
            //   activity_time.$gte !== " " || activity_time.$gte !== undefined &&  delete activity_time["$gte"];
            const payload = {
                "condition": {
                  "limit": 5,
                  "skip": 0
                },
                "sort": {
                  "field": "activity_time",
                  "type": "desc"
                },
                "searchcondition": {
                  "user_id": queryParamId ? queryParamId : undefined,
                  "category": searchData?.category?searchData.category:undefined,
                  // "activity_time":activity_time
              
          
                },
                "project": {},
                "token": "",
                "email": ""
              }
          dispatch(UserActivity(payload))
        }
      }, [submissionState])
      // -----------------------------------------------------------------------
    

    return (
        <>
            {/* ------ Confirmation Modal ------ */}


            <div className=" ModalBox_scroll">
                <div className="heading">
                    <h3>
                        <ModelTrainingIcon /> Training Report
                        <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Search Training Progress">
                <IconButton onClick={() => setSearchDraw(true)}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
                        {/* For {props?.userTrainingData?.firstname} {props?.userTrainingData?.lastname} */}
                    </h3>
                </div>

                {loader ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : ""}


                {props.trainingData?.length > 0 ? (
                    <div className="listflexblock training-block-per-user">
                        {props.userdata?.done_training_percentage ? (
                            <div className="total-percentage" >
                                <h3>Total Training Completed   {Math.round(props?.userdata?.done_training_percentage)}%</h3>
                                {/* <LinearProgress variant="determinate" value={props?.userdata?.done_training_percentage} /> */}

                            </div>
                        ) : (
                            ""
                        )}


                        {props.trainingData?.length > 0 ? props.trainingData?.map((x) =>

                            <ul className='TS-block'>
                                <li>Category: {x?.category_name}  </li>
                                {/* <li>Total Lesson Count: {x.total_lesson_count}  </li>
                                            <li>Done Lesson Count: {x.done_lesson_count}  </li> */}
                                <li>Training Completed: <LinearProgress className="progressBarNew" variant="determinate" value={x.training_percentage} /> {x.training_percentage}%  </li>
                                <li>Updated On: {format(x.updated_datetime, 'dd-MM-yyyy')} </li>


                            </ul>

                        ) : <p> No Data Found !</p>}


                    </div>) : ""}
            </div>

            {searchDraw?<FDSearch 
     open={searchDraw}
     close={setSearchDraw}
     formFields={searchFields}
     searchFields={searchFields}
        type="trainin_progress "/>:""}

        </>
    );
};

export default TrainingProgress;
