import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { employeePackageData } from '../../../EmployerReducer';
import { IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { TableSkeleton } from '../../../../../../helper/TableSkeleton';
import Toolbar from '@mui/material/Toolbar';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PackageStatus from './PackageStatus';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ReplayIcon from '@mui/icons-material/Replay';


const EmployeePackageData = () => {

    const dispatch = useDispatch();

    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});
    const employeeData = useSelector((state) => state.EmployerReducer?.employeePackageAllData && Object.keys(state.EmployerReducer.employeePackageAllData).length > 0 ? state.EmployerReducer?.employeePackageAllData : {})
    const employeeLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)

    useEffect(() => {
        // console.log("logedUserData===========>>>>>>>>", employeeData.location_name);
    }, [employeeData])


    /*****************************For Resolve*********************** */
    const resolved = useSelector((state) =>
        state.ResolveReducer?.resolved["/new-employee-dashboard"]
            ? state.ResolveReducer.resolved["/new-employee-dashboard"]
            : false
    );

    useEffect(() => {
        if (!resolved) {

            let reqBody = {
                "_id": logedUserData?._id
            }


            dispatch(employeePackageData(reqBody))

        }
    }, [])

    useEffect(() => {

        if (logedUserData?.main_role === "is_employer") {
            let body = {
                "_id": logedUserData?._id
            }
            dispatch(employeePackageData(body))
        }
    }, [])

    const [packageStatusOpen, setPackageStatusOpen] = useState(false)
    const [loaderOpen, setLoaderOpen] = useState(false)


    const packageMangeMOdal = () => {
        setLoaderOpen(true)

        setTimeout(() => {
            setPackageStatusOpen(true)

        }, 500)

        setTimeout(() => {
            setLoaderOpen(false)

        }, 1000)


    }

    const TestReplay = () => {
        let reqBody = {
            "_id": logedUserData?._id
        }


        dispatch(employeePackageData(reqBody))
    }



    return (
        <>

            {employeeLoader ? <LinearProgress sx={{ marginTop: "10px" }} /> : ""}

            <div className="employee_Count NotesViewList_main totalCost_table EmployeeManage_pakcage">
                <div className="rolelist_mainwrp_Table">
                    <div>
                        <h3 style={{ textAlign: "center", fontWeight: 400 }}>Location Name: <span style={{ color: "#f87f07" }}>{employeeData?.location_name}</span></h3>
                    </div>
                    <Toolbar
                    >
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Employee Package Management
                        </Typography>

                        <Tooltip title="Reload" onClick={() => TestReplay()}>
                            <IconButton>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>

                    </Toolbar>

                    {loaderOpen && <LinearProgress sx={{ marginTop: "10px", width: "100%" }} />}


                    {/* <h4>{JSON.stringify(employeeData.package_details)}</h4> */}
                    {!employeeLoader && Object.keys(employeeData).length > 0 ?
                        (
                            <Table sx={{ minWidth: 750 }} aria-label="caption table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>	Package Name</TableCell>
                                        <TableCell align="right">Package Price</TableCell>
                                        <TableCell align="right">Package Status</TableCell>
                                        <TableCell title="Actions" align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                {/* <p>{JSON.stringify(employeeData)}</p> */}

                                <TableBody>
                                    {Object.keys(employeeData).length > 0 && (
                                        <TableRow >
                                            <TableCell align="right">{employeeData?.package_details?.package_name}</TableCell>
                                            <TableCell align="right">{employeeData?.package_details?.package_price}</TableCell>
                                            <TableCell align="right">{employeeData?.package_status}</TableCell>
                                            <TableCell align="right" title="Actions">
                                                <div className='listing_btn_class'>
                                                    <Tooltip title="Manage Package Status">
                                                        <IconButton
                                                            onClick={packageMangeMOdal}
                                                            className="modal_icon_button"
                                                        >
                                                            <ToggleOnIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                        ) : employeeLoader ? <TableSkeleton count={5} />
                            : <div className="noDataAvailable">
                                <img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" alt='' />
                            </div>

                    }
                </div>
            </div>

            {packageStatusOpen ? <PackageStatus
                packageStatusOpen={packageStatusOpen}
                setPackageStatusOpen={setPackageStatusOpen}
                TestReplay={TestReplay}
            /> : null}
        </>
    )
}

export default EmployeePackageData