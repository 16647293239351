import React, { useEffect, useRef, useState } from 'react'
import "./LandingpageblockAD1.css";
import { Button, IconButton, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';

import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { MedicineListing } from '../../../../Backend/Medicine/MedicineReducer';
import { deleteTempSearchEndpoint, setReloadTableFlag } from '../../../../../listing/listReducer';
import { resetFormData, resetautocompletelist } from '../../../../../form/formReducer';
import { clearMedicineList } from '../../../../Backend/Medicine/MedicineReducer';


export default function LandingpageblockAD1({ scrollToview, openSearch, SetopenSearch }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody));
  }, [])


  useEffect(() => {
    if (openSearch) {
      setOpen(true)
    }

  }, [openSearch])

  const medcat = useSelector((state) => (state.medicineSlice?.medicinedata && state.medicineSlice?.medicinedata.length > 0) ? state.medicineSlice.medicinedata : [])
  const loadingInMedCat = useSelector(state => state.medicineSlice?.loading ? state.medicineSlice.loading : false);

  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleClose = () => setOpen(false);


// ===================================drug list toggle button and data function==================================
const [menuToggle, setmenuToggle] = useState(1)


const menuToggleSelected = (val) => {
  
  setmenuToggle(val)

  if (val == 2) {
    tableDataForMedCatlist.current.reqBody.searchcondition = {};
    tableDataForMedCatlist.current.reqBody.searchcondition["is_allmedicine"] = {$eq: true};
    // tableDataForMedCatlist.current.reqBody.condition["skip"] = 0;
    tableDataForMedCatlist?.current?.searchData?.formData?.fields?.forEach(
      (data) => {
        data.defaultValue = undefined;
      }
    );
   

    
    tableDataForMedCatlist.current.tableTitle = "Additional Discounted Medical Items"
   
    dispatch(setReloadTableFlag({ tableId: "medcatlist" }))
    dispatch(deleteTempSearchEndpoint({ tableId: "medcatlist" }));

    dispatch(resetFormData({ formId: "searchMedicineandCategoryList" }));
    dispatch(resetautocompletelist());

    
    dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody))
    dispatch(clearMedicineList())
 
    
  }
  else if (val == 1) {
    
    tableDataForMedCatlist.current.reqBody.searchcondition = {};
    // tableDataForMedCatlist.current.reqBody.condition["skip"] = 0;
   
    tableDataForMedCatlist.current.tableTitle = "Free Medical Items"
    tableDataForMedCatlist?.current?.searchData?.formData?.fields?.forEach(
      (data) => {
        if (data.reqBody.searchcondition) {
          delete data.reqBody.searchcondition;
        }
      }
    );
   
    dispatch(setReloadTableFlag({ tableId: "medcatlist" }))
    dispatch(deleteTempSearchEndpoint({ tableId: "medcatlist" }));
    dispatch(resetFormData({ formId: "searchMedicineandCategoryList" }));
    dispatch(resetautocompletelist());
    
    dispatch(MedicineListing(tableDataForMedCatlist.current.reqBody))
    dispatch(clearMedicineList())
    

  }
}
// ===================================drug list toggle button and data function==================================




  var modifyTableHeadersforMedCatlist =
    [

      { val: "name", name: "Medicine Name" },
      { val: "med_by_catagorey", name: "Medicine Category" },



    ];

  let tableDataForMedCatlist = useRef({
    tableId: "medcatlist",
    tableTitle: "Free Medical Items",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {

      // "source": "training_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["name", "med_by_catagorey"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

    ],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      // heading: "Search Medicine & Category List",
      //   type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchMedicineandCategoryList',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },

          },



        ]
      }
    },

  });
  return (

    <div>
      <Modal
        className="modalBaseStyle allMeetingMOdal  MOdallanding13 SD_MOdallanding SR_ClassModal sr_toggle_btn"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={handleClose}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div>
            <div className="rolelist_mainwrp">

              <div className='listing_heading'>

                <h1>Access Free Pharmacy Medicine</h1>

              </div>

              <div className="bluecoastLead_list_mainwrpr">
                <div className='click_buttonwp navigationMain'>

                  <Button
                    id="buttonzero"
                    className={menuToggle === 1 ? "active" : ""}
                    variant="contained"
                    onClick={() => menuToggleSelected(1)}
                  >
                    Free Medical Items
                  </Button>

                  <Button
                    id="buttonone"
                    className={menuToggle === 2 ? "active" : ""}
                    variant="contained"
                    onClick={() => menuToggleSelected(2)}
                  >
                    Additional Discounted Medical Items
                  </Button>
                </div>
              </div>


              {/* <Paper>
  <Button variant='contained' className='addrole_btn' ></Button>
</Paper> */}
              <div className='rolelist_mainwrp_Table'>
                {!loadingInMedCat ? (
                  <ListingTable
                    tableData={tableDataForMedCatlist.current}
                    dataset={medcat}
                    modifyHeaders={modifyTableHeadersforMedCatlist}
                  />
                ) : (
                  <TableSkeleton count={5} />
                )}

              </div>
            </div>
          </div>

        </Box>
      </Modal>


      <div className='adBlock1'>
        <div className='adBlock1_wrapper'>

          <div className='adBlock1_wrapperText'>

            <h2>We are committed to improving your life and saving you money.</h2>
            <h3>We are turning heads as we release America’s First Free Pharmacy.</h3>
            <p>Our pharmacy program makes the most used, generic medications available for FREE to everyone. These drugs treat conditions such as Diabetes, Heart Disease, High Cholesterol, and Depression.</p>
            <p>We have assembled a team of pharmacy professionals with experience in all aspects of the industry. Our expertise resides in medication pricing, sourcing, distribution, strategy, and marketing. With decades of real-world knowledge, we set out to build the most efficient and cost-effective pharmacy in the United States. We have the most high-tech software, hardware, and processing system around and utilize its capabilities to deliver medications within 1-3 days right to your door for prices you won’t find anywhere else.</p>
            <button onClick={() => setScroll()}>Enroll today to gain access to your free medication!</button>
          </div>



        </div>

      </div>

      <div className='adBlock2'>
        <div className='adBlock2_wrapper'>
          <div className='adBlock2_text'>
            <div className='adBlock2_text_con'>
              <h2>SEARCH FOR YOUR PHARMACY PRESCRIPTIONS AND SEE IF WE HAVE WHAT YOU NEED!</h2>
              <button variant="contained" onClick={() => setOpen(true)}>Click Here to Search</button>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
