import React, { useEffect, useState } from "react";
import {
  setEnableRowLoader,
  setReloadTableFlag,
} from "../../../listing/listReducer";
import {
  blueCoastGetPriorityData,
  blueCoastSetPriority,
  clearPriorityData,
} from "./BlueCoastReducer";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import {
    FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PrioritySetForPharmacyAspire = ({ paymentPriority,setPaymentPriority, rowData ,setPaymentRowData,setForLoading}) => {
  const [managePriority, setmanagePriority] = React.useState("");
  const [priorityModalOpen, setPriorityModalOpen] = useState(false);
  const [priorityLoader, setPriorityLoader] = useState(false);
  const [userData, setUserData] = useState(false);

  const priorityData = useSelector((state) => (state.BlueCoastSlice?.prioritydata ? state.BlueCoastSlice?.prioritydata : {}))


  const dispatch = useDispatch();

  useEffect(() => {
    if (rowData !== null && rowData !== undefined) {
      setUserData(rowData);
    }
  }, [rowData]);


  useEffect(() => {
    if (priorityData && priorityData.priority) {
      setmanagePriority(priorityData.priority);
    }
  }, [priorityData]);

  const prioritydatafetchedapi = async (accceptdata) => {
    // setIpInfo(rowData)

    let rowDataId = {
      _id: accceptdata._id,
      priority: accceptdata.priority,
      source: "bluecoast_leads",
    };
    dispatch(blueCoastGetPriorityData(rowDataId));
    setPriorityModalOpen(true);
  };

  const selectpriorities = ["Low", "Medium", "High"];

  const handleChangeOnPriority = async (event) => {
    // console.log("event.target.value>>>123", event.target.value)
    setPriorityLoader(true);

    setmanagePriority(event.target.value);
    await dispatch(
      blueCoastSetPriority({
        priority: event.target.value,
        addedfor: userData._id,
        source: "bluecoast_leads"
      })
    );
    setPriorityLoader(false);

    dispatch(
      setSnackbar({
        open: true,
        message: "Priority Changed Successfully",
        status: "success",
      })
    );
  };

  const CloseSetPriorityModal = () => {
    setPaymentPriority(false);
    setForLoading(true)
    setPaymentRowData({})
    dispatch(clearPriorityData());
  };

  return (
    <>
      {/* *********************Set Prioirty Modal**************** */}
<div>
      <Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast"
        open={paymentPriority}
        // onClose={CloseSetPriorityModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={CloseSetPriorityModal}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className="priority_Box_Main">
            <h2 className="previewModalTitle ">
              {userData?.fullname && (
                <div className="modal_title modal_titlenew">
                  {" "}
                  Set Priority for {userData?.fullname}
                </div>
              )}
            </h2>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Set Priority
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={managePriority}
                  label="Manage Set Priority"
                  onChange={handleChangeOnPriority}
                  // input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {selectpriorities.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {priorityLoader && (
              <Box
                className="loaderProgress"
                sx={{ width: "100%", mt: "10px" }}
              >
                <LinearProgress />
              </Box>
            )}
          </div>
        </Box>
      </Modal>

      </div>
    </>
  );
};

export default PrioritySetForPharmacyAspire;
