
import ListingTable from "../../../../listing/listing";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import store from "../../../../store";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, Snackbar, Tooltip, } from "@mui/material";
import { fetchUserData } from "../fetchUserDataReducer";
import SnackBarComp from "../../../../commoncomponents/snackbar/snackBar";
import AddIcon from "@mui/icons-material/Add";
import "../../../../assets/List.css";
import { getusersList, userManagementDelete, userManagementMultiDelete, sendResetPassword, logInInfo, userManagementResendWelcomeEmail, usermanagestatusUpdate, usermanageMultiStatusUpdate, fetchUserRoles, fetchUserRolesName, roundRobin, FetchHMSettingModal, FetchTagDetails, FetchTrainingStatus, FetchStages, fetchContract, rolesForUpgrade, fetchContractTemplate, fetchBDDHmInfo, clearRemasaage, setStatesBlank, cleardebriefCalldata, interviewContractEmail } from "./userListReducer";
import { setEnableRowLoader, setReloadTableFlag, } from "../../../../listing/listReducer";
import { useCookies, Cookies } from "react-cookie";
import ConfirmationModal from "../../../../commoncomponents/modal/confirmationModal";
import PopupModal from "../../../../commoncomponents/modal/popupmodal";
import { resetresolve } from "../../../Layout/layoutReducer";
import { resolvefunction } from "../../../../helper/helperFunctions";
import MultiStatusChangeModal from "../../../../commoncomponents/modal/multipleStatusChnageModal";
import ConfigureRoleModal from "./configureRoleModal";
import LastLoginModal from "./lastloginmodal";
import ConfigureRoundRobinModal from "./configureRoundRobinModal";
import ManageHmSettingModal from "./manageHmSettingModal";
import ConfigureTagsModal from "./configureTagsModal";
import TrainingStatusModal from "./trainingStatusModal";
import ManageStageModal from "./manageStageModal";
import UpgradeRoleModal from "./upgradeRoleModal";
import ContactDrawer from "./sendcontractDrawer";
import ContractViewModal from "./contractViewModal";
import FollowUpcall from "../../LeadManagement/FollowUpcall";
import { autoCompleteBddData, autoCompleteHMData, cleartaglifecyclestatus, getDatafollowUpCallList, gettagalldata, leadHmBddData, ShowStageList, } from "../../LeadManagement/LeadReducer";
import { folderViewVisitLog } from "../folderView/folderViewReducer";
import { getEmailTeamplatedata } from "../../EmailTemplateManagement/EmailTemplateReducer";
import EmailSendDrower from "../../LeadManagement/EmailSendDrower";
import ShowStageListModal from "../../LeadManagement/ShowStageListModal";
import ShowTagListModal from "../../LeadManagement/ShowTagListModal";
import AssociateBbdHmInfoModal from "./associate_bbd_hm_info_modal";
import AssignBddHm from "./assign_BDD_HM";
import DNotes from "../../Dashboard/DNotes";
import AssinProgramModal from "./AssinProgramModal";
import LogMeInConfermationModal from "../../Dashboard/LogMeInConfermationModal";
import ComitionInfoModal from "./ComitionInfoModal";
import ConfigureComitionModal from "../../AffiliateManagement/ViewAffiliate/ConfigureComitionModal";
import DeleteModalUser from "./DeleteModalUser";
import { clearprogramdataStatuserr, W9FormViewData } from "../../AffiliateManagement/AffiliateReducer";
import W9FormViewModal from "../../AffiliateManagement/ViewAffiliate/W9FormViewModal";
import ViewNDAFormModal from "../../AffiliateManagement/ViewAffiliate/ViewNDAFormModal";
import AproveEmployerContract from "./AproveEmployerContract.js/AproveEmployerContract";
import TrafficPartnerList from "./TrafficPartnerList/TrafficPartnerList";
import AproveTrafficPartner from "./AproveTrafficPartner/AproveTrafficPartner";
import TrafficPartnerCommission from "./TrafficPartnerCommission/TrafficPartnerCommission";
import EmployerListforAE from "./EmployerListforAE/EmployerListforAE";
import DebriefCallModal from "./DebriefCallModal";



const UserManagementList = (props) => {
  const dispatch = useDispatch(); // ---- Dispatch -----
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const [addDrawerOpen, setAddDrawerOpen] = useState(false); //--- Open AddUserDrawer ----//
  const [snackFire, setSnackFire] = useState(false); //--- Open SnackBar ----//
  const [confrimationMsg, setConfrimationMsg] = useState(null); //--- ConfrimationMsg  ----//
  const [resMessage, setResMessage] = useState(null); //--- resMessage  ----//
  const [endpointResStatus, setEndpointResStatus] = useState(null); //--- resMessage  ----//
  const [btnType, setBtnType] = useState(""); //--- ButtonType Set  ----//
  const [toogle, setToogle] = useState(false); //--- Toogle ----//
  const [popupToogle, setPopupToogle] = useState(false); //--- Toogle ----//
  const [loginPopupToogle, setloginPopupToogle] = useState(false); //--- Toogle ----//
  const [loginPopupData, setloginPopupData] = useState([]); //--- Toogle ----//
  const [popupData, setPopupData] = useState([]); //--- popupData ----//
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//
  const [modalResponse, setModalResponse] = useState(false); //--- after Confirmtion modal cofirm button clicked ----//
  const [statusAction, setStatusAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//
  const [multiStatusModal, setMultiStatusModal] = useState(false); //--- Toogle ----//
  const [configureRoleModal, setConfigureRoleModal] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [roundRobinModal, setRoundRobinModal] = useState(false);
  const [hmSettingModal, setHmSettingModal] = useState(false);
  const [tagManageModal, setTagManageModal] = useState(false);
  const [trainingDataModal, setTrainingDataModal] = useState(false);
  const [stageModal, setStageModal] = useState(false);
  const [contractDrawer, setContractDrawer] = useState(false);
  const [contractPreviewModal, setContractPreviewModal] = useState(false);
  const [upgradeRoleModal, setUpgradeRoleModal] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [openDrowerForEmail, setOpenDrowerForEmail] = React.useState(false);
  const [multiMail, setMultiMail] = useState(false);
  const [multiIdData, setMultiIdData] = useState([]);
  const [showstageList, setshowstageList] = useState(false);
  const [showstageListAction, setshowstageListAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//
  const [showtagListModal, setshowtagListModal] = useState(false);
  const [associateModalData, setAssociateModalData] = useState([]);
  const [associateModal, setAssociateModal] = useState(false);
  const [assignBddHmModal, setAssignBddHmModal] = useState(false);
  const [openNote, setOpenNotes] = useState(false);
  const [datarefId, setDatarefId] = useState(null);
  const [fixedData, setFixeddata] = useState({});
  const [openProgramModal, setOpenProgramModal] = useState(false);
  const [bddHmModel, setBddHmModel] = useState(false);
  const [hmBddRowData, setHMBddRowData] = useState(null);
  const [programRowData, setProgramRowData] = useState(null)
  const [snackOpen, setSnackOpen] = useState(false)
  const [logMeInModal, setLogMeInModal] = useState(false)
  const [deleteSingalModalState, setDeleteSingalModalState] = useState(false)


  const [comitionModal, setComitionModal] = useState(false)
  const [configerComitionModalState, setConfigerComitionModalState] = useState(false)
  const [ndaModal, setNDAModal] = useState(false)
  const [w9modal, setW9Modal] = useState(false)


  const tempRowData = useSelector((state) => state?.tableSlice?.tempRowData?.userslist1 ? state.tableSlice.tempRowData.userslist1 : null);
  const [currRowData, setCurrRowData] = useState(); //***FOR ROW DATA****//


  const [snackbarState, setSnackbarState] = useState(false)
  const [snackBarMassage, setSnackBarMassage] = useState('')



  const [debriefCallModalState, setDebriefCallModalState] = useState(false)

  const closeDebriefCallModal = () => {
    setDebriefCallModalState(false)
    setTimeout(() => {
      dispatch(cleardebriefCalldata())

    }, 3000);
  }


  useEffect(() => {
    if (tempRowData && Object.keys(tempRowData).length > 0)
      setCurrRowData(tempRowData);
  }, [JSON.stringify(tempRowData)]);


  // ---- Cookies ---
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

  /*******************For Resolve****** */
  const resolved = useSelector((state) => state.ResolveReducer?.resolved["/usermanagement"] ? state.ResolveReducer.resolved["/usermanagement"] : false);
  const users = useSelector((state) => state.userManagment?.usersList);
  const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {});

  const usersToken = "ssdsd";








  // --- UserTableList Call ---
  useEffect(() => {
    if (!resolved) {
      store.dispatch(getusersList(tableDataForUsers.current.reqBody));
    }
    return () => {
      dispatch(resetresolve());
      dispatch(setStatesBlank())
    };
  }, []);

  useEffect(() => {
    tableDataForUsers.current.searchData.formData.fields.forEach((data) => {
      data.defaultValue = searchFormData[data.name]
        ? searchFormData[data.name]
        : undefined;
    });
  }, [JSON.stringify(searchFormData)]);


  //------------------------------Affiliate Buttons click-------------------------------
  const commision_info = useSelector(state => state.tableSlice?.customButtonClicked?.userslist1?.commision_info ? state.tableSlice.customButtonClicked.userslist1.commision_info : false)
  const CurrencyExchangeIcon = useSelector(state => state.tableSlice?.customButtonClicked?.userslist1?.CurrencyExchangeIcon ? state.tableSlice.customButtonClicked.userslist1.CurrencyExchangeIcon : false)

  // --- for selection of NDAForm button ---
  const NDAFormBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.NDAForm ? state.tableSlice.customButtonClicked.userslist1.NDAForm : false
  );


  const FetchedW9FormData = useSelector(state => (state.AffiliateReducer?.W9FormData) ? state.AffiliateReducer.W9FormData : [])
  const FetchedNDAFormData = useSelector(state => (state.AffiliateReducer?.ViewNDAFormModalData) ? state.AffiliateReducer.ViewNDAFormModalData : [])

  const fetchNDAFormModalDataSuccess = useSelector(state => (state.AffiliateReducer?.fetchNDAFormModalSuccess) ? state.AffiliateReducer.fetchNDAFormModalSuccess : [])


  // --- for selection of w9form button ---
  const w9FormBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.w9form ? state.tableSlice.customButtonClicked.userslist1.w9form : false);


  const download_w9form = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.download_w9form ? state.tableSlice.customButtonClicked.userslist1.download_w9form : false);








  // --- for selection of addUser button ---
  const Btn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.adduser ? state.tableSlice.customButtonClicked.userslist1.adduser : false);

  // ----- On editBtn trigger ----
  const editBtn = useSelector((state) => state.tableSlice.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.edittable9 ? state.tableSlice.customButtonClicked.userslist1.edittable9 : false);

  // ----- On chnageStatus Btn trigger ----
  const chnageStatus = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.change_status1 ? state.tableSlice?.customButtonClicked?.userslist1?.change_status1 : false);

  // ----- On viewBtnAction trigger ----
  const viewBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.viewBtnAction ? state.tableSlice?.customButtonClicked?.userslist1?.viewBtnAction : false);

  // ----- On DataRef on every btn trigger ----
  const dataRef = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1 ? state.tableSlice?.tempRowData?.userslist1 : {});

  // // ----- On DataRef on every btn trigger ----
  const multidataRef = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.multipleSelectionList?.userslist1 ? state.tableSlice?.multipleSelectionList?.userslist1 : []);

  // // ----- On multiple Status Btn trigger ----
  const mltStatusBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.editMultiple ? state.tableSlice?.customButtonClicked?.userslist1?.editMultiple : false);

  // ----- On deleteBtn Btn trigger ----
  const deleteBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.deletetable1 ? state.tableSlice?.customButtonClicked?.userslist1?.deletetable1 : false);

  // ----- On deleteBtn Btn trigger ----
  const asignRoleBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.assignRole ? state.tableSlice?.customButtonClicked?.userslist1?.assignRole : false);

  // // ----- On multiple deleteBtn Btn trigger ----
  const multipalCampainAssecc = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.multipalCampainAssecc ? state.tableSlice?.customButtonClicked?.userslist1?.multipalCampainAssecc : false);

  // ----- On welcomeMail Btn trigger ----
  const welcomeMailBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.resendwelcomemail1 ? state.tableSlice?.customButtonClicked?.userslist1?.resendwelcomemail1 : false);

  // ----- On LoginInfo Btn trigger ----
  const loginInfoBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.logInDetailsBtn ? state.tableSlice?.customButtonClicked?.userslist1?.logInDetailsBtn : false);

  // ------- sendResetPassword ------
  const sendResetPasswordBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.sendResetPassword ? state.tableSlice?.customButtonClicked?.userslist1?.sendResetPassword : false);

  // ------- stageLifeCycleBtn ------
  const stageLifeCycleBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.stageLifeCycle ? state.tableSlice?.customButtonClicked?.userslist1?.stageLifeCycle : false);

  // ------- tagLifeCycleBtn ------
  const tagLifeCycleBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.tagLifeCycle ? state.tableSlice?.customButtonClicked?.userslist1?.tagLifeCycle : false);

  // -----------Employer Manage--------------
  const employerManageClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.badge_icon ? state.tableSlice?.customButtonClicked?.userslist1?.badge_icon : false);

  //----------------Employer List-----------------
  const employerListClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.ballot_icon ? state.tableSlice?.customButtonClicked?.userslist1?.ballot_icon : false);

  // -----------Employer Manage--------------
  const trafficPartnerManageClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.attribution_icon ? state.tableSlice?.customButtonClicked?.userslist1?.attribution_icon : false);

  //----------------Traffic Partner List-----------------
  const trafficListClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.dynamic_form_icon ? state.tableSlice?.customButtonClicked?.userslist1?.dynamic_form_icon : false);

  //--------------------Traffic Partner Commission Configure------------
  const trafficCommissionClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.configrcomsntraficpartnr ? state.tableSlice?.customButtonClicked?.userslist1?.configrcomsntraficpartnr : false);



  let mindate = useSelector((state) => state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {});
  let maxDate = useSelector((state) => state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {});

  // ------- logMeInBtn ------
  const logMeInBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.logMeInBtn ? state.tableSlice?.customButtonClicked?.userslist1?.logMeInBtn : false);

  const DeleteSingelBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.accesscampaign ? state.tableSlice?.customButtonClicked?.userslist1?.accesscampaign : false);

  const forceloginapicallloader = useSelector((state) => state.LandingpagefontReducer?.forceloginapicallloader ? state.LandingpagefontReducer?.forceloginapicallloader : null);

  //---------------View Lead ------------------------------
  const ViewLeadBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.streetview_icon ? state.tableSlice.customButtonClicked.userslist1.streetview_icon : false);

  //----------------------------NotesBtn--------------------------------------------
  const NoteBtnClick = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.summarize_icon ? state.tableSlice.customButtonClicked.userslist1.summarize_icon : false);

  const noteSubmission = useSelector((state) => state.formSlice?.formSubmissionState?.noteAddForm ? state.formSlice?.formSubmissionState?.noteAddForm : undefined);

  // --- SendMultipleEmailBtn Clicked ------
  const sendMultiMailClicked = useSelector((state) => state.tableSlice.customButtonClicked?.userslist1?.sendMail_multiple);

  // --- SendEmailBtn Clicked ------
  const sendMailClicked = useSelector((state) => state.tableSlice.customButtonClicked?.userslist1?.sendMail);

  // ---- emailTemplateData
  const emailtemplateData = useSelector((state) => state.EmailTemplateReducer?.emailtemplateListData ? state.EmailTemplateReducer.emailtemplateListData : []);

  const loaderForsendmail = useSelector((state) => state.LeadReducer?.loaderForsendmail);

  const submistionstate = useSelector((state) => state.LeadReducer?.submistionstate);

  // ------ folderViewBtn ------
  const folderViewBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.folderView ? state.tableSlice?.customButtonClicked?.userslist1?.folderView : false);

  // ------- Fetched User Data ------
  const fetchData = useSelector((state) => state.fetchUserDataReducer?.fetchUserDataReducer ? state.fetchUserDataReducer.fetchUserDataReducer : null);

  // ------ Loading during any action -----
  const loader = useSelector((state) => state.userManagment?.loading ? state.userManagment.loading : false);

  // ------ endpoint response failed -----
  const endpointHitFailed = useSelector((state) => state.userManagment?.failed ? state.userManagment.failed : false);

  // ------ endpoint response success -----
  const endpointHitSuccess = useSelector((state) => state.userManagment?.success ? state.userManagment.success : false);

  // ------ successAction response -----
  const successAction = useSelector((state) => state.userManagment?.successAction ? state.userManagment.successAction : false);

  // ------ endpoint response success Msg-----
  const endpointResMsg = useSelector((state) => state.userManagment?.resMessage ? state.userManagment.resMessage : null);



  const loadinglist = useSelector((state) => state.userManagment?.loadinglist ? state.userManagment.loadinglist : false);

  const userRoles = useSelector((state) => state.userManagment?.userRoles ? state.userManagment.userRoles : []);

  // -------- LoginInfoData here -----
  const loginInfoData = useSelector((state) => state.userManagment?.logininfo ? state.userManagment.logininfo : null);

  const userNotLoginYetStatus = useSelector((state) => state.userManagment?.userNotLoginYet ? state.userManagment?.userNotLoginYet : false);

  // ------- User Role names -------
  const userRoleNames = useSelector((state) => state.userManagment?.userRoleNames ? state.userManagment.userRoleNames : []);

  // ------- Role Added done  ------
  const roleAddedDone = useSelector((state) => state.userManagment?.roleAdded ? state.userManagment.roleAdded : null);

  // ------ roundRobinBtn ------
  const roundRobinBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.Manage_Round_Robin ? state.tableSlice?.customButtonClicked?.userslist1?.Manage_Round_Robin : false);

  // ------- User Role names -------
  const roundRobinData = useSelector((state) => state.userManagment?.roundRobinData ? state.userManagment.roundRobinData : null);

  // ------- roundRobinEdit Success -------
  const roundRobinEditSuccess = useSelector((state) => state.userManagment?.roundRobinSuccess ? state.userManagment.roundRobinSuccess : false);

  // ------ roundRobinBtn ------
  const ManageHMSettingsBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.Manage_HM_Settings ? state.tableSlice?.customButtonClicked?.userslist1?.Manage_HM_Settings : false);

  //---- FetchHMSettingModalData ----
  const FetchHMSettingModalData = useSelector((state) => state.userManagment?.HMSettingModalData ? state.userManagment?.HMSettingModalData : null);

  //---- HMSettingModalStatus after submiting ----
  const HMSettingModalStatus = useSelector((state) => state.userManagment?.HMSettingModalStatus ? state.userManagment?.HMSettingModalStatus : false);

  // ------ TagManageBtn ------
  const TagManageBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.Tag_Manage ? state.tableSlice?.customButtonClicked?.userslist1?.Tag_Manage : false);

  // ----- choose_HM_BDD_Btn -----
  const choose_HM_BDD_Btn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.choose_HM_BDD ? state.tableSlice?.customButtonClicked?.userslist1?.choose_HM_BDD : false);

  // ------ TagManageData ------
  const TagManageModalData = useSelector((state) => state.userManagment?.FetchTagDetailsData ? state.userManagment?.FetchTagDetailsData : []);

  // ------- onTagUpdateSucess
  const tagUpdated = useSelector((state) => state.userManagment?.tagUpdated ? state.userManagment.tagUpdated : false);

  // ------- onStageUpdateSucess
  const stageUpdated = useSelector((state) => state.userManagment?.stageUpdated ? state.userManagment.stageUpdated : false);

  // ------- sendContractBtn -------
  const sendContractBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.send_contract ? state.tableSlice.customButtonClicked.userslist1.send_contract : false);

  //-------- fetchContractData------
  const contractData = useSelector((state) => state.userManagment?.fetchContractData ? state.userManagment.fetchContractData : []);

  // ------- trainingStatusBtn -------
  const trainingStatusBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.trainingStatus ? state.tableSlice.customButtonClicked.userslist1.trainingStatus : false);

  // --- after Endpoint Call TrainingData Fetched ----
  const trainingData = useSelector((state) => state.userManagment?.trainingData ? state.userManagment.trainingData : []);

  // --- after Endpoint Call userTrainingData Fetched ----
  const userTrainingData = useSelector((state) => state.userManagment?.userTrainingData ? state.userManagment.userTrainingData : null);

  // ------- ManageStateBtn-----
  const manageStageBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.manage_stage ? state.tableSlice.customButtonClicked.userslist1.manage_stage : false);

  //------AssinProgramBtn-------
  const AssinProgramBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.manageProgram ? state.tableSlice?.customButtonClicked?.userslist1?.manageProgram : false);

  // ------- FetchedStateData----
  const fetchedStateData = useSelector((state) => state.userManagment?.FetchStages ? state.userManagment.FetchStages : []);

  // ------- upgradeRoleBtn-----
  const upgradeRoleBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.upgrade_role ? state.tableSlice.customButtonClicked.userslist1.upgrade_role : false);

  const userRolesNewData = useSelector((state) => state.userManagment?.userRolesNew ? state.userManagment.userRolesNew : []);

  // ------- contractTemplateData -------
  const contractTemplateData = useSelector((state) => state.userManagment?.contractTemplate ? state.userManagment.contractTemplate : {});

  // ------ followup_call ------
  const followupCallBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.followup_call ? state.tableSlice?.customButtonClicked?.userslist1?.followup_call : false);

  // --- FollowupCall listing ----
  const followUpCallList = useSelector((state) => state.LeadReducer?.followUpCallData && state.LeadReducer?.followUpCallData.length > 0 ? state.LeadReducer.followUpCallData : []);

  // ---- loaderForFollowup
  const loaderForFollowUp = useSelector((state) => state.LeadReducer?.loaderForFollowUp);

  // ----- Data for StageLifeCycle modal ------
  const fetchStagelistData = useSelector((state) => state.LeadReducer?.ShowStageListData ? state.LeadReducer.ShowStageListData : []);

  const ShowStageListDataFetch = useSelector((state) => state.LeadReducer?.ShowStageListDataFetch ? state.LeadReducer.ShowStageListDataFetch : false);

  // ----- Data for taglifecycle modal ------
  const taglifecycledatas = useSelector((state) => state.LeadReducer?.taglifecycledata && state.LeadReducer?.taglifecycledata.length > 0 ? state.LeadReducer?.taglifecycledata : []);

  const taglifecyclestatus = useSelector((state) => state.LeadReducer?.taglifecyclestatus && state.LeadReducer?.taglifecyclestatus.length > 0 ? state.LeadReducer?.taglifecyclestatus : []);

  const programdataStatuserr = useSelector(state => state.AffiliateReducer?.programdataStatuserr ? state.AffiliateReducer?.programdataStatuserr : false)



  // ------- associate_BBD_HM_Btn -----
  const associate_BBD_HM_Btn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.associate_BBD_HM ? state.tableSlice.customButtonClicked.userslist1.associate_BBD_HM : false);

  // ----- Data for Associate_BBD_HM ------
  const Associate_BBD_HMData = useSelector((state) =>
    state.userManagment?.BDDHMInfo ? state.userManagment.BDDHMInfo : []
  );

  const BDDHMInfoStatus = useSelector((state) => state.userManagment?.BDDHMInfoStatus ? state.userManagment.BDDHMInfoStatus : []);


  // -------------

  //----------------------------get follow up form  submition status---------------
  const followUpStatus = useSelector((state) => state.formSlice?.status?.followUpcallForm ? state.formSlice.status.followUpcallForm : "error"); // ------ ------

  const followUpsnackMsg = useSelector(state => (state?.formSlice?.message?.followUpcallForm) ? state.formSlice.message.followUpcallForm : null)



  //===============================================================================
  let leadHmBddDataStatus = useSelector((state) => state.LeadReducer?.leadHmBddDataStatus && state.LeadReducer?.leadHmBddDataStatus.length > 0 ? state.LeadReducer?.leadHmBddDataStatus : []);
  let singalDataForHM_BDD = useSelector((state) => state.tableSlice?.tempRowData?.userslist1 ? state.tableSlice.tempRowData.userslist1 : null);
  let leadHmBddDataInfo = useSelector((state) => state.LeadReducer?.leadHmBddDataInfo && state.LeadReducer?.leadHmBddDataInfo.length > 0 ? state.LeadReducer?.leadHmBddDataInfo : []);
  const emailStatus = useSelector(state => state.LeadReducer?.emailStatus ? state.LeadReducer.emailStatus : null)

  const addcallbutton = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1?.add_call_icon ? state.tableSlice.customButtonClicked.userslist1.add_call_icon : false)

  const debrifcallData = useSelector(state => state.userManagment.debrifcallData ? state.userManagment.debrifcallData : {})

  // ----- On contractSend Btn trigger ----
  const contractSendBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.contractSend ? state.tableSlice?.customButtonClicked?.userslist1?.contractSend : false);


  // ------- choose_HM_BDD_Btn -----
  useEffect(() => {
    if (choose_HM_BDD_Btn) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );

      setHMBddRowData(singalDataForHM_BDD);
    }
  }, [choose_HM_BDD_Btn]);


  useEffect(() => {
    if (hmBddRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );

      let payload = {
        condition: { limit: 10, skip: 0, },
        sort: { type: "asc", field: "email", },
        searchcondition: {},
      };

      dispatch(autoCompleteHMData(payload));
      dispatch(autoCompleteBddData(payload));

      let data = {
        _id: hmBddRowData._id,
        source: "users",
        project: true,
      };

      dispatch(leadHmBddData(data));
    }
  }, [hmBddRowData]);


  useEffect(() => {
    if (leadHmBddDataStatus === "success") {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );

      setAssignBddHmModal(true);
    }
  }, [leadHmBddDataStatus]);



  useEffect(() => {
    if (bddHmModel === true) {
      dispatch(setReloadTableFlag({ tableId: "userslist1" }));
    }
    setTimeout(() => {
      setBddHmModel(false);
    }, 3000);
  }, [bddHmModel]);
  //  --------------------------


  //----------manageProgram--------------
  useEffect(() => {
    if (AssinProgramBtn === true) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );
      setDataCopy(dataRef);
      setOpenProgramModal(true);
    }
  }, [AssinProgramBtn]);


  ///---------------------lead list reload configration on follow up update--------------------

  useEffect(() => {
    if (followUpStatus === "success") {
      setSnackOpen(true)
      dispatch(setReloadTableFlag({ tableId: "userslist1" }));
    }
  }, [followUpStatus]);

  //=============================================================================================
  useEffect(() => {
    if (
      dataRef !== null &&
      dataRef !== undefined &&
      Object.keys(dataRef).length > 0
    ) {
      setDataCopy(dataRef);
    }
  }, [JSON.stringify(dataRef)]);

  // --------------------------------------------------------------

  //------------ associate_BBD_HM_Btn -----------
  useEffect(() => {
    if (associate_BBD_HM_Btn) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      ); //---- row loadder false----

      const payload = {
        _id: dataRef?._id,
      };
      dispatch(fetchBDDHmInfo(payload));
    }
  }, [associate_BBD_HM_Btn]);
  // -----------------------------

  // ------------- Associate_BBD_HMData here ---------
  useEffect(() => {
    if (Associate_BBD_HMData.length > 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setAssociateModalData(Associate_BBD_HMData);
      setAssociateModal(true);
    }
  }, [Associate_BBD_HMData]);

  useEffect(() => {
    if (Associate_BBD_HMData.length === 0 && BDDHMInfoStatus === "success") {
      setSnackFire(true);
      setResMessage("No Associatte BDD HM Yet!");
      // setEndpointResStatus("failed");
    }
    setTimeout(() => {
      dispatch(clearRemasaage())
    }, 2000);
  }, [Associate_BBD_HMData])


  // ------------------------------------------

  //----- stageLifeCycleBtn click-----
  useEffect(() => {
    if (stageLifeCycleBtn) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      dispatch(
        ShowStageList({
          condition: {
            limit: 30,
            skip: 0,
          },
          sort: {
            field: "createdon_datetime",
            type: "desc",
          },
          searchcondition: dataRef?._id ? { _id: dataRef._id } : {},

          count: false,
        })
      );
    }
  }, [stageLifeCycleBtn]);
  // ----------------------------

  // ---------- on StageLifecycle Data fetch mmodal fire or snackbar fire ------
  useEffect(() => {
    if (fetchStagelistData.length > 0) {
      setshowstageList(true);
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
    } else if (ShowStageListDataFetch && fetchStagelistData.length === 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
      setSnackFire(true);
      setResMessage("No Stage Assigned yet!");
      setEndpointResStatus("failed");
    }
  }, [JSON.stringify(fetchStagelistData), ShowStageListDataFetch]);
  // --------------------------------------------------------------------

  //----- tagLifeCycleBtn click -----
  useEffect(() => {
    if (tagLifeCycleBtn) {
      setDataCopy(dataRef);

      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      dispatch(gettagalldata({ _id: dataRef._id }));
    }
  }, [tagLifeCycleBtn]);



  useEffect(() => {
    if (taglifecycledatas.length > 0) {



      setshowtagListModal(true);

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
    } else {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataRef._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      // setSnackFire(true);
      // setResMessage("No Tag Assigned yet!");
      // setEndpointResStatus("failed");
    }
  }, [JSON.stringify(taglifecycledatas)]);

  useEffect(() => {
    if (taglifecycledatas.length === 0 && taglifecyclestatus === "success") {
      setSnackFire(true);
      setResMessage("No Tag Assigned yet!");
      setEndpointResStatus("failed");
    }
    setTimeout(() => {
      dispatch(cleartaglifecyclestatus())
    }, 2000);
  }, [taglifecyclestatus])
  // -----------------------------------------------------------



  // ---- sendMultiMailClicked ------
  let multiId = [];

  useEffect(() => {
    if (sendMultiMailClicked) {
      setMultiMail(true);

      for (let i = 0; i < multidataRef?.length; i++) {
        multiId[i] = multidataRef[i]._id;
      }
    }
  }, [sendMultiMailClicked]);



  useEffect(() => {
    if (multiId.length > 0) {
      setMultiIdData(multiId);

      let reqbody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {
          roles: [],
        },
        count: false,
        source: "",
      };

      store.dispatch(getEmailTeamplatedata(reqbody));
    }
  }, [multiId]);

  // ------------------------

  /**********************For Email Send start********************************* */

  useEffect(() => {
    dispatch(
      setEnableRowLoader({
        tableId: "userslist1",
        rowId: dataRef._id,
        load: true,
      })
    ); //---- row loadder ----

    if (sendMailClicked === true && dataRef?.unsubscribe_email !== 1) {
      let roles = Object.keys(dataRef?.roles).map((key) => key);

      let reqbody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {
          // "added_for": addedForId
          roles: { $in: roles },
        },
        project: {
          subject: 1,
        },
        count: false,
        source: "",
      };

      setDataCopy(dataRef);
      store.dispatch(getEmailTeamplatedata(reqbody));
    }

    if (sendMailClicked === true && dataRef?.unsubscribe_email == 1) {
      setSnackFire(true);
      setResMessage("Mail to this person restricted due to mail unsubscribed!");
      setEndpointResStatus("failed");
    }
  }, [sendMailClicked]);

  useEffect(() => {
    if (emailtemplateData?.length == 0 && successAction == true) {

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setSnackFire(true);
      setResMessage("No Email Template Found!");
      setEndpointResStatus("failed");
    } else if (emailtemplateData?.length > 0) {
      setSnackFire(false);
      setResMessage(null);

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1500);
      setOpenDrowerForEmail(true);
    }
  }, [emailtemplateData]);

  const closeDrower = () => {
    setSnackFire(false);
    setResMessage(null);
    setOpenDrowerForEmail(false);
  };

  // --------------------------------------------

  //--------------------------------Vieww Lead Btn--------------------------------
  useEffect(() => {
    if (ViewLeadBtn === true) {
      window.open(`/lead-list/is_rep/${dataRef._id}`, "_blank");
    }
  }, [ViewLeadBtn]);



  // ----------- on followupCallBtn call endpoint call ----
  useEffect(() => {
    if (followupCallBtn == true) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      // setDataCopy(dataRef)

      if (currRowData?._id) {
        let reqbody = {
          condition: { limit: 5, skip: 0 },
          sort: { field: "_id", type: "desc" },
          searchcondition: { added_for: currRowData?._id },
          count: false,
          source: "",
        };
        dispatch(getDatafollowUpCallList(reqbody));
      }

      setFollowUpModal(true);
    }
  }, [followupCallBtn, currRowData?._id]);

  // --- On Successful endpoint call followup listing open ---
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: false,
        })
      ); //---- row loadder false----
    }, 1000);
    // if (followUpCallList.length > 0) {
    //   setFollowUpModal(true)
    // }
  }, [followUpCallList]);

  const closeModal = () => {
    setFollowUpModal(false);
  };

  // -------------------------------------
  // -------------------------------------

  //----- on upgradeRoleBtn click ----
  useEffect(() => {
    if (upgradeRoleBtn === true) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      setDataCopy(dataRef);
      dispatch(rolesForUpgrade(dataRef));
    }
  }, [upgradeRoleBtn]);
  // ---------------------------------

  useEffect(() => {
    if (userRolesNewData.length > 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setUpgradeRoleModal(true);
    }
  }, [userRolesNewData]);

  // ------- On ManageStateBtn Fire -----
  useEffect(() => {
    if (manageStageBtn === true) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----

      let roles = Object.keys(dataRef?.roles).map((key) => key);

      setDataCopy(dataRef);

      const data = {
        condition: {},
        sort: {},
        searchcondition: {
          added_for: dataRef._id,
          roles: { $in: roles },
        },
        project: {
          subject: 1,
        },
        token: "",
        count: false,
      };
      dispatch(FetchStages(data));
    }
  }, [manageStageBtn]);
  // -------------------------------

  // modal after fetchedStateData fetched -----
  useEffect(() => {
    if (fetchedStateData.length > 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      // setUserId(dataRef._id)
      setStageModal(true);
    } else {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setSnackFire(true);
      setResMessage("Stage Not Found For Assigned !");
      setEndpointResStatus("failed");
    }

  }, [fetchedStateData]);

  //--- after Sucessfully Updated Stage ----
  useEffect(() => {
    if (stageUpdated) {
      dispatch(setReloadTableFlag({ tableId: "userslist1" }));
    }
  }, [stageUpdated]);


  // ---- onTagUpdateSucess----
  useEffect(() => {
    if (tagUpdated == true) {
      dispatch(setReloadTableFlag({ tableId: "userslist1" }));
    }
  }, [tagUpdated]);
  // -------

  // ------ trainingDataModal on ------
  useEffect(() => {
    if (trainingData.length > 0) {
      setTrainingDataModal(true);
    } else {
      setSnackFire(true);
      setResMessage("No Training Data Found!");
      setEndpointResStatus("failed");
    }
  }, [trainingData]);
  //------------------

  // -------- on trainingStatusBtn click endpoint call done ----
  useEffect(() => {
    if (trainingStatusBtn) {
      const data = {
        source: "lesson_list",
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {
          user_id: dataRef._id,
        },
        project: {},
        token: "",
        email: "",
      };

      dispatch(FetchTrainingStatus(data));
    }
  }, [trainingStatusBtn]);

  //-------------------

  //  if sendContractBtn triggred ---
  useEffect(() => {
    setContractPreviewModal(false);


    if (sendContractBtn) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      setDataCopy(dataRef);
      setContractPreviewModal(false);

      // checking active role type then stor those active roles in a array
      let roles = [];
      Object.keys(dataRef.roles).forEach(function (key, index) {
        if (dataRef.roles[key] == 1) {
          return roles.push(key);
        }
      });


      const data = {
        condition: {},
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {
          status: 1,
          roles: { $in: roles },
        },
        project: { name: 1 },
        token: "",
        data: { user_id: dataRef._id },
      };

      dispatch(fetchContract(data));
    }
  }, [sendContractBtn]);
  // ------------------------

  // ----- drawer opens or endpoint call and modal open ------
  useEffect(() => {
    if (contractData.length > 1) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setContractPreviewModal(false);
      setContractDrawer(true);
    } else if (contractData.length == 1) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
      setContractDrawer(false);
      setContractPreviewModal(false);

      const payload = {
        searchcondition: {
          user_id: dataCopy._id,
          contract_id: contractData[0]._id,
        },
      };
      dispatch(fetchContractTemplate(payload));
    }
  }, [contractData]);

  useEffect(() => {
    if (
      contractTemplateData &&
      Object.keys(contractTemplateData).length > 0 &&
      contractTemplateData.status !== "error" &&
      contractDrawer !== true
    ) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setContractPreviewModal(true);
    }
  }, [contractTemplateData]);

  // ---------------------------------------------

  //  on sucessfully data fetch TAG Modal open
  useEffect(() => {
    if (TagManageModalData.length > 0) {
      setTagManageModal(true);
    }
  }, [TagManageModalData]);
  //---------------------------

  // -------- tagManagementBtn -----
  useEffect(() => {
    if (TagManageBtn) {

      let roles = Object.keys(dataRef?.roles).map((key) => key);

      setUserId(dataRef._id);
      setDataCopy(dataRef);

      const data = {
        _id: dataRef._id,
        roles: { $in: roles },
        project: {
          subject: 1,
        },
      };

      dispatch(FetchTagDetails(data));
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        }))
    }
  }, [TagManageBtn]);

  // ---------------------

  // ------ After Sucessfull Submission HMSetting Change
  useEffect(() => {
    if (HMSettingModalStatus) {
      setHmSettingModal(false);
      setSnackFire(true);
      setResMessage("HM Setting Updated Sucessfull");
      setEndpointResStatus("success");
    }
  }, [HMSettingModalStatus]);

  // ----- HMSettingModal Open After fetching data
  useEffect(() => {
    if (FetchHMSettingModalData !== null) {
      setHmSettingModal(true);
    }
  }, [FetchHMSettingModalData]);

  // ----- HMSettingModal Data fetch on HMSettingBtn Click
  useEffect(() => {
    if (ManageHMSettingsBtn) {
      const data = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {
          _id: dataRef._id,
        },
        count: false,
      };

      dispatch(FetchHMSettingModal(data));
    }
  }, [ManageHMSettingsBtn]);
  // -----------------------------------------------------------------

  // ------- roundRobinEditSuccess
  useEffect(() => {
    if (roundRobinEditSuccess) {
      setRoundRobinModal(false);
      setSnackFire(true);
      setResMessage("Round Robin Updated Sucessfull");
      setEndpointResStatus("success");
    }
  }, [roundRobinEditSuccess]);
  // ---------------

  // ------- if roundRobinData found after endpoint call
  useEffect(() => {
    if (roundRobinData !== null) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        }))
      setRoundRobinModal(true);
    }
  }, [roundRobinData]);
  // ---------------

  // -- after click on roundRobinBtn -----
  useEffect(() => {
    if (roundRobinBtn) {
      const data = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {
          _id: dataRef._id,
        },
        count: false,
      };

      dispatch(roundRobin(data));
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        }))
    }
  }, [roundRobinBtn]);
  // ----------------------------------

  // -- after sucessfully role assigned from userListing refresh the list -----
  useEffect(() => {
    if (roleAddedDone) {
      setTimeout(() => {
        dispatch(setReloadTableFlag({ tableId: "userslist1" }));
      }, 200);
    }
  }, [roleAddedDone]);
  // ----------------------------------

  useEffect(() => {
    if (folderViewBtn) {
      setDataCopy(dataRef);
      // dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: true })) //---- row loadder true----

      const payload = {
        user_for: dataRef._id,
        user_by: userInfo?.userinfo._id,
      };
      dispatch(folderViewVisitLog(payload));
      resolvefunction(navigate, "/folderview?id=" + dataRef._id);

      // dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
    }
  }, [folderViewBtn]);

  useEffect(() => {
    if (userNotLoginYetStatus === true) {
      setloginPopupData(null);
      setloginPopupToogle(false);
      setSnackFire(true);
      setEndpointResStatus("failed");
      setResMessage("Login information not found");
    }
  }, [userNotLoginYetStatus]);

  // ------ on fetched userLoginInfo ----
  useEffect(() => {
    if (loginInfoData !== null) {
      setSnackFire(false);
      setResMessage(null);
      setloginPopupData(loginInfoData);
      // setloginPopupToogle(true);
    }
  }, [loginInfoData]);

  useEffect(() => {
    if (loginPopupData !== null) {
      setSnackFire(false);
      setResMessage(null);
      setloginPopupToogle(true);
    }
  }, [loginPopupData]);

  //-- on Sucessfully delete or status update of any user table refresh
  useEffect(() => {
    if (successAction === true) {
      setToogle(false);
      setMultiStatusModal(false);
      dispatch(setReloadTableFlag({ tableId: "userslist1" }));
      setSnackFire(true);
      setResMessage(endpointResMsg);
      setEndpointResStatus("success");
    }
  }, [successAction]);
  // ---------------

  //   ------- if any endpoint hit sucess or failed ------
  useEffect(() => {
    if (endpointHitSuccess) {
      setSnackFire(true);
      setResMessage(endpointResMsg);
      setEndpointResStatus("success");
    }
  }, [endpointHitSuccess]);

  useEffect(() => {
    if (endpointHitFailed) {
      setSnackFire(true);
      setResMessage(endpointResMsg);
      setEndpointResStatus("failed");
    }
  }, [endpointHitFailed]);



  useEffect(() => {
    if (programdataStatuserr === true) {
      setSnackFire(true);
      setResMessage("Program data not Found");
      setEndpointResStatus("failed");
    }
  }, [programdataStatuserr])



  useEffect(() => {
    if (snackFire === false) {
      setResMessage(null);
      setEndpointResStatus(null);
      dispatch(clearprogramdataStatuserr())
    }
  }, [snackFire])



  // ---- LogMein Btn Trigger ------
  useEffect(() => {

    if (logMeInBtn === true) {
      setDataCopy(dataRef);
      setTimeout(() => {
        setLogMeInModal(true)
      }, 500);
    }
  }, [logMeInBtn]);



  useEffect(() => {
    if (forceloginapicallloader === false) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: false,
        })
      );
    }
  }, [forceloginapicallloader]);

  // ------ viewBtn triigred -------
  useEffect(() => {
    if (viewBtn === true) {
      setDataCopy(dataRef);
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      dispatch(fetchUserData(dataRef._id)); //-- fetchUserData endpoint call from this func --
    }
  }, [viewBtn]);




  useEffect(() => {
    if (fetchData !== null && fetchData !== undefined && comitionModal === false && configerComitionModalState === false) {
      setPopupData(fetchData);
      setPopupToogle(true);
    }
  }, [fetchData]);


  // ---- delte btn triggred setting the confirmation modal msg ------
  useEffect(() => {
    if (deleteBtn === true) {
      setBtnType("dltBtn");
      setConfrimationMsg(
        "Are you sure you want to delete this record? This can't be undone."
      );
      setToogle(true);
      setDataCopy(dataRef);
    }
  }, [deleteBtn]);

  // ---- asignRoleBtn btn clicked ------
  useEffect(() => {
    if (asignRoleBtn === true) {
      dispatch(fetchUserRoles(dataRef));
      setUserId(dataRef._id);
    }
  }, [asignRoleBtn]);

  useEffect(() => {
    setSnackFire(false);
    if (userRoles.length > 0 && userRoles !== null) {
      setSnackFire(false);
      setConfigureRoleModal(true);
    } else if (userRoles == null) {
      setSnackFire(true);
      setResMessage("Roles not found!");
      setEndpointResStatus("failed");
    }
  }, [userRoles]);

  // ---- contractSendBtn btn clicked ------
  useEffect(() => {
    if (contractSendBtn === true) {
      console.warn("contractSendBtn", dataRef)
      setBtnType("contractSendBtn");
      setDataCopy(dataRef);
      dispatch(interviewContractEmail(dataRef));
      // setSnackFire(true);
      // setResMessage("")
    }
  }, [contractSendBtn]);

  // ------------------------------
  // ------------------------------

  // ---- welcomeMailBtn btn clicked ------
  useEffect(() => {
    if (welcomeMailBtn === true) {
      setBtnType("welcomeMailBtn");
      setDataCopy(dataRef);
      dispatch(userManagementResendWelcomeEmail(dataRef)); //-- fetchUserData endpoint call from this func --
    }
  }, [welcomeMailBtn]);
  // ------------------------------
  // ------------------------------

  // ---- sendResetPasswordBtn btn clicked ------
  useEffect(() => {
    if (sendResetPasswordBtn === true) {
      setBtnType("sendResetPassword");
      setDataCopy(dataRef);
      dispatch(sendResetPassword(dataRef)); //-- fetchUserData endpoint call from this func --
    }
  }, [sendResetPasswordBtn]);
  // ------------------------------

  // -------- UeEffect for calling endpoints condinationally based on action btn clicked ------
  useEffect(() => {
    if (modalResponse === true) {
      if (btnType == "dltBtn") {
        dispatch(userManagementDelete(dataCopy)); //-- delete endpoint call from this func --
      } else if (btnType == "multidltBtn") {
        let copyArr = [];
        for (let i = 0; i < dataCopy.length; i++) {
          copyArr[i] = dataCopy[i]._id;
        }
        dispatch(userManagementMultiDelete(copyArr)); //-- multi dlt endpoint call from this func --
      }
    }
  }, [modalResponse]);
  // -------------------------------------------------------------------------------------



  //-------------on Email send sucessfully send--------------

  useEffect(() => {
    if (emailStatus === "success") {
      dispatch(setReloadTableFlag({ tableId: "userslist1" }))
    }
  }, [emailStatus])
  //=========================================================

  // --- Refresh Table on load ---
  useEffect(() => {
    setshowtagListModal(false);
    setshowstageList(false);
    setPopupToogle(false);
    setToogle(false);
    setSnackFire(false);
    setloginPopupToogle(false);
    setResMessage(null);
    setStatusAction(null);
    setloginPopupData(null);
    setConfigureRoleModal(false);

    const data = {
      condition: {
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {},
      project: { name: 1, slug: 1, status: 1 },
      token: "",
      count: false,
    };
    dispatch(fetchUserRolesName(data)); //Fetch user roles

    setRoundRobinModal(false);
    setHmSettingModal(false);
    setTagManageModal(false);
    setTrainingDataModal(false);
    setStageModal(false);
    setContractDrawer(false);
    setUpgradeRoleModal(false);
    setContractPreviewModal(false);
    setFollowUpModal(false);
    closeModal();
    setOpenDrowerForEmail(false);
    setAssociateModal(false);


    // ------- if we loggedin with Hiring manger ------- //
    if (userInfo.userinfo?.main_role == "hr_manager") {
      const data = {
        id: 2,
        label: "Status",
        name: "status",
        type: "select",
        multiple: false,
        values: [
          { key: 1, name: "Active" },
          { key: 0, name: "Inactive" },
        ],
        sx: { m: 1, minWidth: 120 },
        className: "inputblock2line",
        payloadFormat: { key: "status", param: "$in", options: "i" },
      };

      tableDataForUsers.current.searchData.formData.fields.splice(2, 1);
      tableDataForUsers.current.searchData.formData.fields.splice(2, 1, data);
    }
    // ----------------------------------------------- //


    // ------------------- if source is affiliateListing then  tableBtns, tableHeader, searchFeilds modification  -------------- //
    if (props.source === "affiliateListing") {

      const tableHeader = modifyTableHeaders.current.filter((x) => { return x.val !== "status" && x.val !== "last_login_time" && x.val !== "last_activity" && x.val !== "lead_count" && x.val !== "associated_bdd__hm" && x.val !== "training_percentage_html" && x.val !== "stage_name" && x.val !== "roles_string" && x.val !== "landing_page_name" && x.val !== "tag_names" && x.val !== "address" && x.val !== "agent_code" })
      modifyTableHeaders.current = tableHeader

      const headersForAffiliate = [
        { val: "all_stages", name: "All Stage" },
        // { val: "nda_stage", name: "NDA Stage" },
        // { val: "landing_page_stage", name: "Landing Page Stage" },

      ]

      headersForAffiliate.map((x) => modifyTableHeaders.current.push(x))


      const tableBtns = tableDataForUsers.current.buttons.filter((y) => { return y.id !== "trainingStatus" && y.id !== "assignRole" })
      tableDataForUsers.current.buttons = tableBtns

      const searchFeilds = tableDataForUsers.current.searchData.formData.fields.filter((z) => { return z.name !== "signup_form" && z.name !== "user_type" && z.name !== "page_id" && z.name !== "tag" && z.name !== "stage" && z.name !== "email" })
      tableDataForUsers.current.searchData.formData.fields = searchFeilds


      let searchFeild = [
        {
          id: 6,
          label: "Search By Keywords",
          name: "allFields",
          type: "text",
          className: 'list_search_item countDiv__column--col6 ',
          sx: { m: 1, minWidth: 300 },
          // payloadFormat: { key: "allFields", param: "$regex", options: "i" },
          payloadFormat: { key: "allFields", param: "$regex", options: "i" },

        }
      ]

      searchFeild.map((x) => tableDataForUsers.current.searchData.formData.fields.push(x))


      const newSearchFeilds = tableDataForUsers.current.searchData.formData.fields.filter((x) => { return x.name !== "agent_code" && x.name !== "allFields" })
      tableDataForUsers.current.searchData.formData.fields = newSearchFeilds


    }
    // --------------------------- --------- ------ //



    // ------------------- if source is partnerListing then  tableBtns, tableHeader, searchFeilds modification  -------------- //
    if (props.source === "partnerListing") {

      const tableHeader = modifyTableHeaders.current.filter((x) => { return x.val !== "status" && x.val !== "last_login_time" && x.val !== "last_activity" && x.val !== "lead_count" && x.val !== "associated_bdd__hm" && x.val !== "training_percentage_html" && x.val !== "stage_name" && x.val !== "roles_string" && x.val !== "landing_page_name" && x.val !== "tag_names" && x.val !== "address" && x.val !== "phone" && x.val !== "agent_code" })
      modifyTableHeaders.current = tableHeader





      const tableBtns = tableDataForUsers.current.buttons.filter((y) => { return y.id == "viewBtnAction" || y.id == "sendResetPassword" || y.id == "change_status1" || y.id == 'configrcomsntraficpartnr' })
      tableDataForUsers.current.buttons = tableBtns

      const commision_info = {
        id: "commision_info",
        type: "other",
        name: "attach_money_icon",
        label: "Commision Info Details",
      }

      tableDataForUsers.current.buttons.push(commision_info)

      const searchFeilds = tableDataForUsers.current.searchData.formData.fields.filter((z) => { return z.name !== "signup_form" && z.name !== "user_type" && z.name !== "page_id" && z.name !== "tag" && z.name !== "stage" && z.name !== "agent_code" && z.name !== "allFields" })
      tableDataForUsers.current.searchData.formData.fields = searchFeilds


    }



    // ------------------- if source is Employer Listing then  tableBtns, tableHeader, searchFeilds modification  -------------- //
    if (props.source === "employer_listing") {

      const tableHeader = modifyTableHeaders.current.filter((x) => { return x.val !== "status" && x.val !== "last_login_time" && x.val !== "last_activity" && x.val !== "lead_count" && x.val !== "associated_bdd__hm" && x.val !== "training_percentage_html" && x.val !== "stage_name" && x.val !== "roles_string" && x.val !== "landing_page_name" && x.val !== "tag_names" && x.val !== "address" && x.val !== "phone" && x.val !== "agent_code" })
      modifyTableHeaders.current = tableHeader

      const tableBtns = tableDataForUsers.current.buttons.filter((y) => { return y.id == "viewBtnAction" || y.id == "sendResetPassword" || y.id == "change_status1" })
      tableDataForUsers.current.buttons = tableBtns

      const commision_info = {
        id: "commision_info",
        type: "other",
        name: "attach_money_icon",
        label: "Commision info details",
      }
      tableDataForUsers.current.buttons.push(commision_info)
      const searchFeilds = tableDataForUsers.current.searchData.formData.fields.filter((z) => { return z.name !== "signup_form" && z.name !== "user_type" && z.name !== "page_id" && z.name !== "tag" && z.name !== "stage" && z.name !== "agent_code" && z.name !== "allFields" })
      tableDataForUsers.current.searchData.formData.fields = searchFeilds
    }







    // --------------------------- --------- ------ //

    if (userInfo.userinfo?.roles?.is_super_admin === 1) {

      const btnSetNew = [{
        id: "logInDetailsBtn",
        type: "other",
        name: "info",
        label: "Login Info",
        condField: "main_role",
        condVal: "is_affiliate"
      },
      {
        id: "NDAForm",
        name: "list_alt_icon",
        type: "other",
        label: "Downlod NDA Form",
        condField: "main_role",
        condVal: "is_affiliate"
      },
      {
        id: "commision_info",
        type: "other",
        name: "attach_money_icon",
        label: "Commision info details",
        condField: "main_role",
        condVal: "is_affiliate"
      },

      {
        id: "download_w9form",
        name: "feed_icon",
        type: "other",
        label: "Download W9 From",
        condField: "main_role",
        condVal: "is_affiliate"
      },
      ]
      btnSetNew.map((x) => tableDataForUsers.current.buttons.push(x))
    }
  }, []);
  // ----------

  // --- on AddUser button click drawer open ---
  useEffect(() => {
    if (Btn === true) {
      // openAddUserDrawer();
      navigate("/useraddedit");
    }
  }, [Btn]);

  // ----------

  // -- open addUserDrawer --
  function openAddUserDrawer() {
    setAddDrawerOpen(true);
  }
  // ----------

  // --- on AddUser button click drawer open ---
  useEffect(() => {
    if (editBtn === true) {
      if (dataRef.roles.is_affiliate === 1) {
        resolvefunction(navigate, "/create-affiliate?id=" + dataRef._id);
      } else if (dataRef.roles.is_employer === 1) {
        resolvefunction(navigate, "/add-edit-employer?id=" + dataRef._id);
      }
      else {
        resolvefunction(navigate, "/useraddedit?id=" + dataRef._id);
      }

    }
  }, [editBtn]);


  // ----- loginInfo Btn triggrs ----
  useEffect(() => {

    if (loginInfoBtn === true) {
      setDataCopy(dataRef);
      setSnackFire(false);
      setResMessage(null);
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      );

      dispatch(
        logInInfo({
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            field: "user_id",
            type: "desc",
          },
          searchcondition: {
            user_id: dataRef._id,
          },
        })
      );
    }
  }, [loginInfoBtn]);

  // ---- Single Status Chnage btn clicked ------
  useEffect(() => {
    if (chnageStatus === true) {
      setBtnType("singleStatusBtn");
      setConfrimationMsg("Are you sure you that want to change the status?");
      setMultiStatusModal(true);
      setDataCopy(dataRef);
    }
  }, [chnageStatus]);
  // ------------------------------
  // ------------------------------

  // ------- Multiple Status Btn Click ------
  useEffect(() => {
    if (mltStatusBtn == true) {
      setDataCopy(multidataRef);
      setBtnType("mltStatusBtn");
      setConfrimationMsg("Are you sure you that want to change the status?");
      setMultiStatusModal(true);
    }
  }, [mltStatusBtn]);
  // --------------------------------

  // -------- multiple status chnage modal action btn clicked ------
  useEffect(() => {
    if (statusAction !== null && statusAction !== undefined) {
      if (btnType == "mltStatusBtn") {
        let copyArr = [];

        for (let i = 0; i < dataCopy.length; i++) {
          copyArr[i] = dataCopy[i]._id;
        }

        dispatch(usermanageMultiStatusUpdate({ copyArr, statusAction })); //--  multiple status endpoint call
      } else if (btnType == "singleStatusBtn") {
        dispatch(
          usermanagestatusUpdate({
            dataCopy: dataCopy,
            status: statusAction == 1 ? true : false,
          })
        ); //-- Single Status Update endpoint call from this func --
      }
    }
  }, [statusAction]);
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------

  // ----- UserRoles injecting in user search dynamically ---------
  useEffect(() => {
    if (userRoleNames.length > 0) {
      let roles;

      if (userInfo?.userinfo?.main_role === "hr_manager") {
        roles = userRoleNames.filter((val) => val.name === "AE" || val.name === "AEE").map((data) => {
          return { val: data?.slug, name: data?.name }
        })
      } else {
        roles = userRoleNames.map((data) => {
          return { val: data?.slug, name: data?.name };
        });
      }
      const feild = tableDataForUsers.current.searchData?.formData?.fields.find(
        (data) => data.name === "user_type"
      );
      if (feild) {
        feild.values = roles;
      }
      // setRoles()
    } else {
      const feild = tableDataForUsers.current.searchData?.formData?.fields.find(
        (data) => data.name === "user_type"
      );

      if (feild) {
        feild.values = [{ name: "Roles not found" }];
      }
    }
  }, [userRoleNames]);
  // -------------------------

  //--------------------------------------------------Add note funtion0-----------------------------

  useEffect(() => {
    if (NoteBtnClick === true) {
      setOpenNotes(true);
    }
  }, [NoteBtnClick]);



  //-----------------------------------------Button configaration ---------------------------------------------------
  useEffect(() => {
    if (Object.keys(userInfo?.userinfo?.roles).length > 0) {
      //-------------------------------------if admin or super admin-----------------------------------
      if (userInfo?.userinfo?.roles.is_super_admin === 1) {
        tableDataForUsers.current.buttons.push({
          id: "streetview_icon",
          label: "View Leads",
          type: "other",
          name: "streetview_icon",
          title: "View Leads",
          condField: "view_Lead_btn",
          condVal: 1,
          showcount: true,
          countFiled: "lead_count",
        });
        tableDataForUsers.current.buttons.push(
          {
            id: "logMeInBtn",
            type: "other",
            name: "login",
            label: "Log me in",
            condField: "main_role",
            condVal: ["customer_support", "hr_manager", "is_aer", "is_bdd", "is_rep", "tech_support"]
          });
      }
      //-------------------------------------if customer supoort -----------------------------------
      else if (userInfo?.userinfo?.roles?.customer_support === 1) {
        tableDataForUsers.current.buttons = [

          {
            id: "summarize_icon",
            label: "Notes",
            type: "other",
            name: "summarize_icon",
            title: "Notes",
            showcount: true,
            countFiled: 'notescount'
          },
          {
            id: "logMeInBtn",
            type: "other",
            name: "login",
            label: "Log me in",
          }
        ]
      }
      // --------------------------------for log  me in button -------------------------------------
      else if (userInfo?.userinfo?.roles?.is_admin === 1) {
        tableDataForUsers.current.buttons.push(
          {
            id: "logMeInBtn",
            type: "other",
            name: "login",
            label: "Log me in",
            condField: "main_role",
            condVal: ["customer_support", "hr_manager", "is_aer", "is_bdd", "is_rep", "tech_support"]
          }
        )
      }
    }
  }, [userInfo]);

  //------------------------------------------if customer support -----------------------------------------


  useEffect(() => {
    if (Object.keys(dataRef).length > 0) {
      setDatarefId(dataRef._id);
      setFixeddata(dataRef);
    }
  }, [dataRef]);





  // -------------------------






  //------------------------------ if Rep with new dashboad button config --------------------------------------------

  useEffect(() => {
    if (userInfo?.userinfo.rep_with_new_dashboard === true) {
      tableDataForUsers.current.buttons = [
        (props.source !== "employer_listing" && {
          id: "commision_info",
          type: "other",
          name: "attach_money_icon",
          label: "Commision info details"
        }),
        (props.source === "employer_listing" && {
          id: "edittable9",
          type: "other",
          icon_type: "edit",
          label: "Edit",
          name: "edit",
        }),
        (props.source === "employer_listing" && {
          id: "viewBtnAction",
          type: "other",
          name: "preview",
          label: "Preview",
        }),


      ]


    }
  }, [userInfo])



  //---------------------------Comition modal preview funtion-------------------------------------------------
  useEffect(() => {
    if (commision_info === true) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      );



      dispatch(fetchUserData(dataRef._id));
      setComitionModal(true)
    }
  }, [commision_info])


  useEffect(() => {
    if (CurrencyExchangeIcon === true) {
      dispatch(fetchUserData(dataRef._id));
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      );

      setConfigerComitionModalState(true)
    }
  }, [CurrencyExchangeIcon])








  //------------------delete singal Confs---------------
  useEffect(() => {
    if (DeleteSingelBtn === true || multipalCampainAssecc === true) {
      setDeleteSingalModalState(true)
    }
  }, [DeleteSingelBtn, multipalCampainAssecc])



  // ----- Table list for user -----
  var modifyTableHeaders = useRef([
    { val: "name", name: "Name" },
    { val: "email", name: "E-mail" },
    { val: "phone", name: "Contact" },
    { val: "tag_names", name: "Tags" },
    { val: "stage_name", name: "Stage" },
    { val: "landing_page_name", name: "Source" },
    { val: "address", name: "Address" },
    { val: "training_percentage_html", name: "Training Complete (%)" },
    { val: "last_activity", name: "Last Activity" },
    { val: "roles_string", name: "Type" },
    { val: "lead_count", name: "Lead Count" },
    { val: "agent_code", name: "Agent Code" },
    {
      val: "status",
      customVal: props.source === "employer_listing" ? { 1: true, 0: false } : { 1: "Active", 0: "Inactive" },
      name: "Status",
    },
    {
      val: "createdon_datetime",
      name: "Joined On",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
    {
      val: "last_login_time",
      name: "Last Login",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
    { val: "associated_bdd__hm", name: "Associate BDD/HM" },
    {
      val: "updatedon_datetime",
      name: "Updated On",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
  ]);

  let tableDataForUsers = useRef({
    tableId: "userslist1",
    tableTitle:
      props.source === "HmFolderView" ||
        userInfo?.userinfo?.main_role == "is_aer"
        ? "AE Listing"
        : userInfo?.userinfo?.main_role == "hr_manager"
          ? "User Management (AE | AEE)"
          : userInfo?.userinfo?.main_role === "is_bdd"
            ? "Hiring Manager management"
            : props.source === "affiliateListing" ? "Traffic Partner Listing" :
              props.source === "partnerListing" ? "Partner Listing" : props.source === "employer_listing" ? "Employer Listing"
                : "Users Listing",

    enableRowActionLoader: true,
    showReload: false,
    showFilter: true,
    adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/user-list-or-count",
      // deleteSingleUserEndpoint: "api/delete-user",
      tableCountEndpoint: "users/user-list-or-count",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition:
        props.source === "HmFolderView"
          ? { hiring_manager: props.queryParamId, "roles.is_rep": 1 }
          : userInfo?.userinfo?.main_role === "hr_manager"
            ? {
              hiring_manager: userInfo?.userinfo?._id,
              $or: [{ "roles.is_rep": 1 }, { "roles.is_aer": 1 }],
            }
            : userInfo?.userinfo?.main_role === "is_aer"
              ? { aer_id: userInfo?.userinfo?._id }
              : userInfo?.userinfo?.main_role === "is_bdd"
                ? {
                  associated_bdd: { $in: [userInfo?.userinfo?._id] },
                  "roles.hr_manager": 1,
                }
                : props.source === "affiliateListing" ? { "roles.is_affiliate": 1, "added_by": userInfo.userinfo._id } :
                  props.source === "partnerListing" ? { "roles.is_partner": 1 } : props.source === "employer_listing" ? { "roles.is_employer": 1, "added_by": userInfo.userinfo._id }
                    : {},

      // "project": {

      // },

      count: false,
      // "token": usersToken
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: usersToken,
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: usersToken,
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: [
      "name",
      "type",
      "login_time",
      "createdon_datetime",
      "status",
      "email",
      (props.source === "employer_listing" && "updatedon_datetime")
    ],
    paginationType: "stackPagination",
    // customSort: true,
    customBtnHead: [
      {
        id: "multipalCampainAssecc",
        type: "perm_data_setting_icon",
        title: "Campaign Access",
      },
      {
        id: "editMultiple",
        type: "toggle_on_icon",
        title: "Status Change",
      },

      {
        id: "sendMail_multiple",
        title: "Bulk Email",
        type: "forward_to_inbox_two_tone_icon",
        className: "forward_to_inbox_two_tone_icon",
      },

      // {
      //     id: "adduser",
      //     type: "add_icon",
      //     title: "ADD USER",
      //     className: "add_user_icon",
      //     default: true,
      // },
    ],
    buttons: [
      {
        id: "choose_HM_BDD",
        name: "group_add_icon",
        type: "other",
        label: "Choose HM/BDD",
        condField: "main_role",
        condVal: ["is_rep", "hr_manager"],
      },

      {
        id: "associate_BBD_HM",
        name: "people_alt_icon",
        type: "other",
        label: "Associate BDD/HM",
        condField: "role_upgrade",
        condVal: 1,
      },

      {
        id: "stageLifeCycle",
        label: "Stage Lifecycle",
        type: "other",
        name: "view_day_icon",
        // condField: "rr_btn",
        // condVal: 1,
      },

      {
        id: "tagLifeCycle",
        label: "Tag Lifecycle",
        type: "other",
        name: "data_saver_off_icon",
        // condField: "rr_btn",
        // condVal: 1,
      },

      {
        id: "manageProgram",
        label: "Manage Programe",
        type: "other",
        name: "fact_check_icon",
        condField: "rr_btn",
        condVal: 1,
      },

      {
        id: "sendMail",
        label: "Send Email",
        type: "other",
        name: "forward_to_inbox_two_tone_icon",
        showcount: true,
        countFiled: "email_sent_count",
      },
      {
        id: "manage_stage",
        type: "other",
        name: "clear_all_icon",
        label: "Manage Stage",
        // condField: "roles_array",
        // condVal: ["is_rep"],
      },
      {
        id: "send_contract",
        type: "other",
        name: "article_icon",
        label: "Send Contract",
        condField: "contract_send_flag",
        condVal: 1,
      },
      {
        id: "Tag_Manage",
        type: "other",
        name: "local_offer_icon",
        label: "Tag Manage",
        // condField: "roles_array",
        // condVal: ["hr_manager", "is_aer", "is_rep"],
      },
      {
        id: "Manage_Round_Robin",
        type: "other",
        name: "webhook_icon",
        label: "Manage Round Robin",
        condField: "rr_btn",
        condVal: 1,
      },

      {
        id: "followup_call",
        type: "other",
        name: "follow_the_signs_icon",
        label: "Followup Call",
        condField: "contract_send_flag",
        condVal: 1,
        showcount: true,
        countFiled: "follow_up_count",
      },

      {
        id: "trainingStatus",
        type: "other",
        name: "assessment_icon",
        label: "Training Report",
        // condField: "roles_array",
        // condVal: ["hr_manager", "is_aer", "is_rep"],
      },
      {
        id: "assignRole",
        type: "other",
        label: "Assign User Role",
        name: "settings_suggest_icon",
      },

      {
        id: "folderView",
        type: "other",
        label: "Folder View",
        name: "folder_copy_icon",
      },
      {
        id: "contractSend",
        type: "other",
        name: "mark_as_unread",
        label: "Send Contract By Email",
        condField: "main_role",
        condVal: ["is_rep", "is_aer"],
      },
      {
        id: "edittable9",
        type: "other",
        icon_type: "edit",
        label: "Edit",
        name: "edit",
      },
      {
        id: "resendwelcomemail1",
        type: "other",
        label: "Resend Welcome Mail",
        name: "markunread",
      },

      {
        id: "change_status1",
        type: "other",
        label: "Change Status",
        name: "toggle_off",
        condField: "inactive",
        condVal: 1,
      },


      {
        id: "change_status1",
        type: "other",
        label: "Change Status",
        name: "toggle_on_icon",
        condField: "inactive",
        condVal: 2,
      },

      {
        id: "sendResetPassword",
        type: "other",
        label: "Send Reset Password",
        name: "lockreset",
      },

      {
        id: "viewBtnAction",
        type: "other",
        name: "preview",
        label: "Preview",
      },

      {
        id: "logInDetailsBtn",
        type: "other",
        name: "info",
        label: "Login Info",
      },


      // ###########################configure commission traffuic partner#########################
      (process.env.REACT_APP_ENVIRONMENT !== "prod" && props.source === "partnerListing" && {
        id: "configrcomsntraficpartnr",
        type: "other",
        name: "currency_exchange_icon",
        label: "Configure Commission for Traffic Partner",
      }),

      // ##############################Employer Mange################################
      (process.env.REACT_APP_ENVIRONMENT !== "prod" && {
        id: "badge_icon",
        type: "other",
        name: "badge_icon",
        label: "Manage Employer",
        condField: "main_role",
        condVal: ["is_employer"],
      }),
      // ##############################Employer List for AE################################

      (process.env.REACT_APP_ENVIRONMENT !== "prod" && {
        id: "ballot_icon",
        type: "other",
        name: "ballot_icon",
        label: "Employer List for AE",
        condField: "main_role",
        condVal: ["is_rep"],
        showcount: true,
        countFiled: 'employercount'
      }),

      // ##############################Employer Mange################################
      (process.env.REACT_APP_ENVIRONMENT !== "prod" && {
        id: "attribution_icon",
        type: "other",
        name: "attribution_icon",
        label: "Manage Traffic Partner",
        condField: "main_role",
        condVal: ["is_affiliate"],
      }),
      // ##############################Traffic Partner List for AE################################

      (process.env.REACT_APP_ENVIRONMENT !== "prod" && {
        id: "dynamic_form_icon",
        type: "other",
        name: "dynamic_form_icon",
        label: "Traffic Partner List for AE",
        condField: "main_role",
        condVal: ["is_rep"],
        showcount: true,
        countFiled: 'trafficpartnercount'
      }),
      //  ########################### Notes ########################################
      {
        id: "summarize_icon",
        label: "Notes",
        type: "other",
        name: "summarize_icon",
        title: "Notes",
        showcount: true,
        countFiled: 'notescount'
      },
      //************************************ ACTION BUTTON FOR LOGIN MODAL ************************************//

      {
        id: "accesscampaign",
        type: "other",
        name: "perm_data_setting_icon",
        icon_type: "perm_data_setting_icon",
        label: "Campaign Access"
      },
      {
        id: "add_call_icon",
        type: "other",
        name: "add_call_icon",
        icon_type: "add_call_icon",
        label: "Training Debrief Call",
        condField: "main_role",
        condVal: "is_rep",
      }

    ],
    deleteModal: {
      modalClassName: "Delete Modal Deletemodal",
    },

    searchData: {
      heading:
        userInfo?.userinfo?.main_role == "hr_manager"
          ? "Search User" : props.source === "affiliateListing" ? "Search Traffic Partner" : props.source === "partnerListing" ? "Search Partner" : props.source === "employer_listing" ? "Search Employer Listing"
            : "Search User",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        resetBtnName: "REFRESH",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: props.source === "affiliateListing" ? "Search By Name & Email" : "Search By Name",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              props.source === "employer_listing" ? process.env.REACT_APP_API_URL + "employer_manage/autocompleteemployersname" : props.source === "affiliateListing" ? process.env.REACT_APP_API_URL + "employer_manage/trafficpartneremailautocomplete" : process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "name",
              },
              searchcondition:
                props.source === "affiliateListing" ? { "roles.is_affiliate": 1, "added_by": userInfo.userinfo._id } : props.source === "employer_listing" ? { "roles.is_employer": 1, "added_by": userInfo.userinfo._id }
                  : {}
              ,
              type: "user_name",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 1,
            label: "Search By Email",
            name: "email",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: props.source === "employer_listing" ? { "roles.is_employer": 1, "added_by": userInfo.userinfo._id } : {},
              type: "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },
          {
            id: 6,
            label: "Search By Stage",
            name: "stage",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "dashboard-cards/auto-complete-users-stage",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
              },
              type: "user_stage",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "latest_stage.stage_slug" },
          },

          {
            id: 7,
            label: "Search By Tag",
            name: "tag",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "dashboard-cards/auto-complete-users-tag",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_tag",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "latest_tag.tag_slug" },
          },

          {
            id: 8,
            label: "Search by User Types",
            name: "user_type",
            type: "select",
            multiple: true,
            checkbox: true,
            values: [],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            payloadFormat: { key: "user_type", param: "$in", options: "i" },
          },

          {
            id: 9,
            label: "Search by Source",
            name: "page_id",
            type: "autoComplete",
            multiple: true,
            values: [],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "users/autocomplete-pageid",
            reqBody: {
              condition: {
                limit: 30,
                skip: 0,
              },
              sort: {
                field: "createdon_datetime",
                type: "desc",
              },
              searchcondition: {
                // "rep_only": 1
              },
              project: {},
            },
            payloadFormat: { key: "page_id", param: "$in" },
          },
          {
            id: 10,
            label: "Search by Sign-up Source",
            name: "signup_form",
            type: "select",
            multiple: true,
            checkbox: true,
            values: [
              { val: "aspire", name: "Aspire" },
              { val: "bluecoast", name: "Bluecoast" },
              { val: "naka", name: "NAKA Games" },

            ],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            payloadFormat: { key: "signup_form", param: "$in", options: "i" },
          },
          {
            id: 11,
            label: "Search by Agent Code",
            name: "agent_code",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/autocomplete-agent-code",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                // createdon_datetime: {
                //   $gte: mindate,
                //   $lte: maxDate,
                // },
              },
              // "type": "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 12,
            label: "Search by Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "inputblock2line",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 13,
            label: "Search by End Date",
            name: "end_date",
            className: "inputblock2line",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });
  // -----------------------

  // injecting  button in user listing based on user role login -------
  useEffect(() => {
    const deletetable1 = {
      id: "deletetable1",
      type: "other",
      label: "Delete",
      name: "delete_icon",
    };

    const roleUpgrade = {
      id: "upgrade_role",
      type: "other",
      name: "moving_icon",
      label: "Upgrade Role",
      condField: "role_upgrade",
      condVal: 1,
    };

    const HM_Settings = {
      id: "Manage_HM_Settings",
      type: "other",
      name: "layers_icon",
      label: "Manage HM Settings",
      condField: "mhm_btn",
      condVal: 1,
    };



    if (
      userInfo?.userinfo?.main_role == "is_super_admin" ||
      userInfo?.userinfo?.main_role == "is_developer" ||
      userInfo?.userinfo?.main_role == "special_entities" ||
      userInfo?.userinfo?.other_role?.includes("is_super_admin") ||
      userInfo?.userinfo?.other_role?.includes("is_developer")
    ) {
      tableDataForUsers.current.buttons.splice(12, 0, deletetable1);
    }
    if (
      userInfo.userinfo?.main_role == "is_admin" ||
      userInfo.userinfo?.other_role?.includes("is_admin") ||
      userInfo.userinfo?.main_role == "hr_manager" ||
      userInfo.userinfo?.other_role?.includes("hr_manager")
    ) {
      tableDataForUsers.current.buttons.splice(13, 0, roleUpgrade);
    }

    if (
      userInfo.userinfo?.main_role == "is_bdd" ||
      userInfo.userinfo?.other_role?.includes("is_bdd")
    ) {
      tableDataForUsers.current.buttons.splice(14, 0, HM_Settings);
    }

    if (
      userInfo.userinfo.main_role === "hr_manager" ||
      userInfo.userinfo.main_role === "is_rep" ||
      userInfo.userinfo.main_role === "is_aer"
    ) {
      const btnSet = tableDataForUsers.current.buttons.filter(
        (x) => x.id !== "send_contract"
      );
      tableDataForUsers.current.buttons = btnSet;
    }
  }, []);
  // ----------------------

  // ------ handel Close ----------
  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  useEffect(() => {
    if (noteSubmission === 2) {
      dispatch(setReloadTableFlag({ tableId: "userslist1" }));
    }
  }, [noteSubmission])

  //  --------------------------------------- //









  // ------------ NDAFormBtn --------- //
  useEffect(() => {
    if (NDAFormBtn) {

      window.open('/download-nda-signature/' + dataRef._id, "target_blank")
    }
  }, [NDAFormBtn])
  // ---------------------------------- //



  // ------------ w9FormBtn --------- //
  useEffect(() => {
    if (w9FormBtn) {
      dispatch(W9FormViewData({ "_id": dataRef._id }))
    }
  }, [w9FormBtn])
  // ---------------------------- //


  // ----- if FetchedW9FormData available then modal open ----- //
  useEffect(() => {
    if (FetchedW9FormData.length > 0) {

      // setSnackFire(false)
      setW9Modal(true)

    } else if (FetchedW9FormData.length == 0) {


      setResMessage("No W9Form Data Found!")
      setEndpointResStatus('error')
      setSnackFire(true)
    }
  }, [FetchedW9FormData])
  // ------------------------------------------------ //


  // --------------- if FetchedNDAFormData available then modal open --------- //
  useEffect(() => {
    if (fetchNDAFormModalDataSuccess && FetchedNDAFormData.length > 0) {

      // setSnackFire(false)

      // setNDAModal(true)

    } else if (fetchNDAFormModalDataSuccess && FetchedNDAFormData.length == 0) {

      setResMessage("No NDAForm Data Found!")
      setEndpointResStatus('error')
      setSnackFire(true)

    }
  }, [FetchedNDAFormData])
  // --------------------------------------- //




  useEffect(() => {
    if (download_w9form) {
      window.open('/download-w9form/' + dataRef._id, "target_blank")
      // dispatch(W9FormViewData({ "_id": dataRef._id }))

    }
  }, [download_w9form])


  // --------------------------------Employer Manage Button----------------------
  const [manageEMployerOpen, setManageEMployerOpen] = useState(false)
  useEffect(() => {
    if (employerManageClicked === true && dataRef) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      );

      setManageEMployerOpen(true)

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataRef._id,
            load: false,
          })
        );
      }, 1000);

    }

  }, [employerManageClicked, JSON.stringify(dataCopy)])

  // -------------------------------Employer lsit for AEs----------------------
  const [employerListMOdalOpen, setEmployerListMOdalOpen] = useState(false)

  useEffect(() => {
    if (employerListClicked && dataCopy) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );
      setEmployerListMOdalOpen(true)

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: true,
          })
        );
      }, 1000)



    }
  }, [employerListClicked, JSON.stringify(dataCopy)])



  // -----------------------------Traffic Partner Manage------------------------------

  const [manageTFOpen, setManageTFOpen] = useState(false)

  useEffect(() => {
    if (trafficPartnerManageClicked === true && dataRef) {

      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      );
      setManageTFOpen(true)

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataRef._id,
            load: false,
          })
        );
      }, 1000);
    }
  }, [trafficPartnerManageClicked, dataRef])


  // -------------------------------Traffic Partner list for AEs----------------------
  const [trafficPartnerMOdalOpen, setTrafficPartnerMOdalOpen] = useState(false)

  useEffect(() => {
    if (trafficListClicked && dataCopy) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );

      setTrafficPartnerMOdalOpen(true)

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: true,
          })
        );
      }, 1000);
    }
  }, [trafficListClicked, JSON.stringify(dataCopy)])

  //--------------------------------Traffic Partner COmmission COnfigure useEffect-----
  const [commissionTraffic, setCommissionTraffic] = useState(false)

  useEffect(() => {
    if (trafficCommissionClicked === true && dataCopy) {

      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: true,
        })
      );


      setTimeout(() => {

        setCommissionTraffic(true)
      }, 500);


      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: true,
          })
        );
      }, 700);
    }
  }, [trafficCommissionClicked, JSON.stringify(dataCopy)])



  useEffect(() => {
    if (addcallbutton) {
      setDebriefCallModalState(true)
    }
  }, [addcallbutton])



  useEffect(() => {
    if (debrifcallData.status === "success") {
      setSnackbarState(true)
      setSnackBarMassage('Debrief Call added successfully')
    }
  }, [debrifcallData])


  const snackHandleClosedebriccall = () => {
    setSnackbarState(false)
    setSnackBarMassage("")
  }



  return (
    <>
      <div className="rolelist_mainwrp">
        {forceloginapicallloader === true && <LinearProgress />}

        {props.source !== "HmFolderView" ? (
          <div className="listing_heading">
            <h1>
              {userInfo?.userinfo?.main_role === "is_aer"
                ? "AE Management"
                : userInfo?.userinfo?.main_role === "is_bdd"
                  ? "Hiring Manager management"
                  : props.source === "affiliateListing" ? "Traffic Partner Listing" : props.source === "partnerListing" ? "Partner Listing" : props.source === "employer_listing" ? "Employer Listing"
                    : "User Management"}{" "}
            </h1>



            <Tooltip title="ADD USER">
              <button
                className="addrole_btn"
                onClick={() => props.source === "affiliateListing" ? navigate("/create-affiliate") : props.source === "partnerListing" ? navigate("/add-partners") : props.source === "employer_listing" ? navigate("/add-edit-employer") : navigate("/useraddedit")}
              >
                <AddIcon />
              </button>
            </Tooltip>
          </div>) : ("")}

        <div className={(props.source === "affiliateListing" || props.source === "partnerListing" || props.source === "employer_listing") ? "rolelist_mainwrp_Table" : "userlisttable"}>
          <div className="reactTableWrapper">
            <div className="adminlisttable_v2 table_Icons ">
              {!loadinglist && users ? (<ListingTable tableData={tableDataForUsers.current} dataset={users} modifyHeaders={modifyTableHeaders.current} />) : (<TableSkeleton count={5} />)}
            </div>
          </div>
        </div>
      </div>

      {/* ------ Confirmation Modal ------ */}
      {toogle ? (<ConfirmationModal className="confirmaModal" confrimationMsg={confrimationMsg} toogle={toogle} tooglefunc={setToogle} setModalResponse={setModalResponse} />) : ("")}
      {/* -------------------------------- */}

      {/* ------ Status Chnage Modal ------ */}
      {multiStatusModal ? (<MultiStatusChangeModal className="confirmaModal" confrimationMsg={confrimationMsg} toogle={multiStatusModal} tooglefunc={setMultiStatusModal} setModalResponse={setStatusAction} />) : ("")}
      {/* -------------------------------- */}

      {/* ConfigureRoleModal */}
      {configureRoleModal && userRoles !== null ? (<ConfigureRoleModal toogle={configureRoleModal} tooglefunc={setConfigureRoleModal} setModalResponse={setStatusAction} userRoles={userRoles} UserId={UserId} />) : ("")}

      {/* ------- Popup Modal for preview user details ------------ */}
      {popupToogle && comitionModal === false ? (<PopupModal popupdata={popupData} popuptoogle={popupToogle} popuptooglefunc={setPopupToogle} type="profileInfo" sourceRoleListing={props.source} />) : ("")}
      {/* ----------------- */}

      {/* ---- configureRoundRobinModal------ */}
      {roundRobinModal ? (<ConfigureRoundRobinModal toogle={roundRobinModal} tooglefunc={setRoundRobinModal} setModalResponse={setStatusAction} data={roundRobinData} />) : ("")}

      {/* ---- ManageHmSettingModal------ */}
      {hmSettingModal ? (<ManageHmSettingModal toogle={hmSettingModal} tooglefunc={setHmSettingModal} setModalResponse={setStatusAction} data={FetchHMSettingModalData} />) : ("")}


      {/* ---- ConfigureTagsModal------ */}
      {tagManageModal ? (<ConfigureTagsModal toogle={tagManageModal} tooglefunc={setTagManageModal} setModalResponse={setStatusAction} tags={TagManageModalData} UserId={UserId} Userinfo={dataCopy} />) : ("")}

      {/* ---------------------------- */}

      {/* ------ trainingStatusModal ----- */}
      {trainingDataModal ? (<TrainingStatusModal popupdata={trainingData} popuptoogle={trainingDataModal} userTrainingData={userTrainingData} popuptooglefunc={setTrainingDataModal} />) : ("")}
      {/* --------------------------------- */}

      {/* -------- ManageStageModal ------ */}

      {stageModal ? (<ManageStageModal toogle={stageModal} tooglefunc={setStageModal} stage={stageModal} UserId={dataCopy._id} Userinfo={dataCopy} />) : ("")}
      {/* ---------------------- */}

      {/* -------- UpgradeRoleModal ------ */}
      {userRolesNewData.length > 0 ? (<UpgradeRoleModal open={upgradeRoleModal} tooglefunc={setUpgradeRoleModal} role={userRolesNewData} userId={dataCopy._id} />) : ("")}
      {/* ---------------------- */}

      {/* ---- Drawer for send contract ---- */}
      {contractDrawer ? (<ContactDrawer open={contractDrawer} setcontractdrawer={setContractDrawer} contractdata={contractData} userid={dataCopy._id} userinfo={dataCopy} className="contract-drawer" source="user" />) : ("")}

      {/* ------------------------------ */}

      {/* -------- ContractViewModal ----- */}
      {contractPreviewModal ? (<ContractViewModal toogle={contractPreviewModal} tooglefunc={setContractPreviewModal} contractdata={contractTemplateData} userinfo={dataCopy} source="user" />) : ("")}
      {/* --------------------------------- */}

      {/* ------ followup ------ */}

      {followUpModal ? (<FollowUpcall open={followUpModal} close={closeModal} addedById={userInfo.userinfo._id} addedForId={dataCopy._id} addedForNmae={currRowData?.name} listData={followUpCallList} loading={loaderForFollowUp} />) : ("")}
      {/* ------------------------ */}

      {/* ---- Last login Modal details ---- */}
      {loginPopupData != null && loginPopupData.length > 0 ? (<LastLoginModal popupdata={loginPopupData} popuptoogle={loginPopupToogle} popuptooglefunc={setloginPopupToogle} />) : ("")}
      {/* ------------------------- */}

      {/* ---- Email Send Drawer --- */}

      {dataCopy.unsubscribe_email !== 1 && emailtemplateData !== null ? (<EmailSendDrower open={openDrowerForEmail} close={closeDrower} data={emailtemplateData} addedById={userInfo.userinfo._id} addedForId={multiMail ? multiIdData : dataCopy._id} loading={loaderForsendmail} submistionstate={submistionstate} />) : ("")}
      {/* --------------------------- */}

      {/* ----- StageLifecycleModal ----- */}

      {showstageList ? (<ShowStageListModal open={showstageList} toogle={showstageList} tooglefunc={setshowstageList} setModalResponse={setshowstageListAction} stagesdata={fetchStagelistData} userName={dataCopy} />) : ("")}
      {/* --------------------------------- */}

      {/* ---- tagLifecycleModal------ */}
      {showtagListModal ? (<ShowTagListModal toogle={showtagListModal} tooglefunc={setshowtagListModal} setModalResponse={setStatusAction} tagsdata={taglifecycledatas} userName={dataCopy} />) : ("")}
      {/* ------------------------------ */}

      {/* ---- AssociateBbdHmInfoModal -----  */}
      {associateModalData.length > 0 && associateModal ? (<AssociateBbdHmInfoModal popuptoogle={associateModal} data={associateModalData} rowdata={dataCopy} popuptooglefunc={setAssociateModal} source="users" />) : ("")}
      {/* -------------------- */}

      {/* ---- AssignBddHm Modal -----  */}
      {assignBddHmModal ? (<AssignBddHm popuptoogle={assignBddHmModal} data={associateModalData} leadHmBddDataInfo={leadHmBddDataInfo} rowdata={hmBddRowData} popuptooglefunc={setAssignBddHmModal} setBddHmModel={setBddHmModel} setHMBddRowData={setHMBddRowData} source="users" />) : ("")}
      {/* -------------------- */}

      {openProgramModal ? (<AssinProgramModal Modalstate={openProgramModal} togalState={setOpenProgramModal} userName={dataCopy?.name} commonData={dataCopy} setProgramRowData={setProgramRowData} />) : ("")}

      {/* -------------------DNNotes--------------------------*/}
      {openNote && datarefId !== null && (<DNotes modalComonId={datarefId} gridData={fixedData} openMoadl={openNote} closeModal={setOpenNotes} userName={{ fullname: fixedData.name }} openMoadlforNotesUsertype={"users"} types="dashboardNotes" />)}

      {/* --------- SnackBar -------- */}
      {snackFire !== false && resMessage !== null ? (<SnackBarComp setSnackFire={setSnackFire} resMessage={resMessage} snackFire={snackFire} endpointResStatus={endpointResStatus} />) : ("")}


      {/* ----------------Log Me In ----------------- */}

      {logMeInModal && <LogMeInConfermationModal comonData={dataCopy} Modalstate={logMeInModal} setModalState={setLogMeInModal} Name={dataCopy.name} />}

      {/* ----------------Comition modal -------------- */}
      {(comitionModal && fetchData) && <ComitionInfoModal modalState={comitionModal} ModalData={fetchData} togelModalstate={setComitionModal} />}


      {/* ----------------ConfigerComitionModal-------------- */}
      {(configerComitionModalState && fetchData) && <ConfigureComitionModal id={fetchData._id} chakingData={fetchData} modalState={configerComitionModalState} togalModalState={setConfigerComitionModalState} />}


      {deleteSingalModalState && <DeleteModalUser modalState={deleteSingalModalState} TogalModalState={setDeleteSingalModalState} usebelData={dataCopy} />}
      {/* <DeleteModalUser modalState={deleteSingalModalState} TogalModalState={setDeleteSingalModalState} usebelData={dataCopy}/> */}


      {/* {-------------------------Employer Manage Modal----------------} */}
      {manageEMployerOpen && <AproveEmployerContract manageEMployerOpen={manageEMployerOpen} setManageEMployerOpen={setManageEMployerOpen} userDataList={dataCopy} />}

      {/* {-------------------------Employer List Modal----------------} */}
      {employerListMOdalOpen && <EmployerListforAE employerListMOdalOpen={employerListMOdalOpen} setEmployerListMOdalOpen={setEmployerListMOdalOpen} userDataList={dataCopy} employerListButtonClicked={employerListClicked} />}

      {/* -------------------------------Traffic Partner Manage------------------ */}
      {manageTFOpen && <AproveTrafficPartner manageTFOpen={manageTFOpen} setManageTFOpen={setManageTFOpen} userDataList={dataCopy} />}

      {/* {-------------------------------Traffic Partner List Modal--------------------} */}
      {trafficPartnerMOdalOpen && <TrafficPartnerList trafficPartnerMOdalOpen={trafficPartnerMOdalOpen} setTrafficPartnerMOdalOpen={setTrafficPartnerMOdalOpen} userDataList={dataCopy} trafficListClicked={trafficListClicked} />}

      {debriefCallModalState && <DebriefCallModal modalState={debriefCallModalState} HandelClose={closeDebriefCallModal} />}

      {/* {--------------------------Traffic Partner Commission COnfigure Modal-------------------} */}
      {commissionTraffic && <TrafficPartnerCommission commissionTraffic={commissionTraffic} setCommissionTraffic={setCommissionTraffic} />}
      {w9modal && (<W9FormViewModal toogle={w9modal} tooglefunc={setW9Modal} />)}
      {ndaModal && (<ViewNDAFormModal toogle={ndaModal} tooglefunc={setNDAModal} />)}
      {snackOpen ? (<Snackbar open={snackOpen} autoHideDuration={3000} onClose={snackHandleClose} message={followUpsnackMsg} />) : ""}
      <div className='class_snack'>
        <Snackbar open={snackbarState} autoHideDuration={3000} onClose={snackHandleClosedebriccall} message={snackBarMassage} />
      </div>
    </>
  );
};

export default UserManagementList;
