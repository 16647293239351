import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment";



export const getDataForBddDashboardGridList = createAsyncThunk("Bdd-dashboard-grid-list", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/bdd-dashboard-grid-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getDataForBddDashboardChart = createAsyncThunk("Bdd-dashboard-chart", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/bdd-dashboard-chart", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})




export const getDataForBddDashboardCard = createAsyncThunk("Bdd-dashboard-card", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    // console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/bdd-dashboard-card", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})






const BddDashboardReducer = createSlice({
    name: 'BddDashboardReducer',
    initialState: {

        mindate: moment().subtract(7, "days").valueOf(),
        maxDate: moment().valueOf(),

        submitting: false,
        loading: false,
        loaderForBddData: false,
        bddChartData: [],
        bddGridData: [],
        bddCardData: [],
        bddListData: null,
        gridListName:"AEE Joined"

    },
    reducers: {
        clearBddDashboardData(state, action) {
            // state.BddChartData = []
            // state.BddGridData = []
            // state.bddListData = []
            // state.BddListData = null

        },

        setMinMaxTime(state, action) {

            state.mindate = (moment(action.payload[0]).valueOf())
            state.maxDate = (moment(action.payload[1]).valueOf())
            // console.log("action11111", state.mindate, state.maxDate);

        }
    },
    extraReducers: {
        [getDataForBddDashboardGridList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.loading = false;
            state.successAction = false;
            state.loaderForBddData = false;

        },
        [getDataForBddDashboardGridList.pending]: (state, action) => {

            state.loading = true;
            state.loaderForBddData = true;


        },
        [getDataForBddDashboardGridList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loading = false;
                state.bddGridData = action.payload.results.grid_data;
                state.bddListData = action.payload.results.list_data;
                if(action.meta.arg.type === "conversion_done") state.gridListName = "Conversion"
                state.loaderForBddData = false;
            }

        },


        [getDataForBddDashboardCard.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForBddData = false;

        },
        [getDataForBddDashboardCard.pending]: (state, action) => {


           
            state.loading = true;
            state.loaderForBddData = true;


        },
        [getDataForBddDashboardCard.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loading = false;
                state.bddCardData = action.payload.results.card_data;
                state.loaderForBddData = false;

            }

        },


        [getDataForBddDashboardChart.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false

            state.successAction = false;
            state.loaderForBddData = false;

        },
        [getDataForBddDashboardChart.pending]: (state, action) => {

            state.loaderForBddData = true;


        },
        [getDataForBddDashboardChart.fulfilled]: (state, action) => {

            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.bddChartData = action.payload.results;
                state.loaderForBddData = false;

            }

        },




    }

})

export default BddDashboardReducer.reducer;
export const { clearBddDashboardData, setMinMaxTime } = BddDashboardReducer.actions;

