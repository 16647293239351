
import Typography from '@mui/material/Typography';
// import ListingTable from "../../../../listing/listing";
// import { TableSkeleton } from "../../../../helper/TableSkeleton";
import Drawer from '@mui/material/Drawer';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Grid, LinearProgress, List, Snackbar } from '@mui/material';

// import SnackBarComp from "../../../../commoncomponent/snackBar/snackBar"


// import {
//     getusersList,
// } from "./userManagementListingReducer";

import {
    clearCustomButtonClicked,
    clearEditData,
    clearTablerowData,
    clearViewData,
    closeModal,
    openModal,
    resetRowActionBtnClicked,
    resetRowActionData,
    setReloadTableFlag,
    spinLoader,
    stopLoader,
    unmountTable,
} from "../../../../listing/listReducer";

import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { resetresolve } from '../../../Layout/layoutReducer';
import UserManagementList from '../../userManagement/userList/userList';
import { setStatesBlank } from '../../userManagement/userList/userListReducer';

const EmployerListing = () => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//


    // ---- States ----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    const [userType, setUserType] = useState(true);  //-- for setting userType from cookie--//
    const [editdata, setEditdata] = useState({}); //**** FOR EDIT DATA****//


    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // ---- UseSelectors ----
    /*******************For Resolve****** */
    const resolved = useSelector(state => state.ResolveReducer.resolved['/usermanagement'] ? state.ResolveReducer.resolved['/usermanagement'] : false)
    const users = useSelector((state) => state.userManagment.usersList);
    const usersToken = "ssdsd"

    // console.warn("<<< userinfocookies goes here >>>>", userInfo.userInfo._id)

    // --- UserTableList Call ---
    useEffect(() => {


        if (!resolved) {
            // store.dispatch(getusersList(tableDataForUsers.current.reqBody));
        }
        return () => {
            dispatch(resetresolve())
            dispatch(setStatesBlank())

            //whenever the component removes it will executes
        }
    }, []);




    return (
        <>

            <div className='admin_page admin_addForm'>
                <div className='admin_pageList_wrapper'>


                    {/* ----- Loader ---- */}
                    {/* {endpointLoading ? (<Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>) : ""} */}
                    {/* ------------------- */}


                    {/* ---- taable of User Listing --- */}
                    <UserManagementList source="employer_listing" />
                    {/* ------------------------- */}


                </div>
            </div>





        </>
    )
}


export default EmployerListing;