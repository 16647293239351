import "../LandingPage5/LandingPage5.css";
import "../LandingPage18/LandingPage18.css";
import Asvideoblock from "./Component/Asvideoblock";
import React, { useEffect, useRef, useState } from "react";
import "../LandingPage5/LandingPage5.css";
import { Box } from "@mui/system";
import {
  Button,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from "@mui/material";
import { Form } from "../../../../form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../store";
import {
  addconverstiondata,
  addlandingpageonedata,
  addlandingpagetwodatalead,
  clearaddedinfo,
  clearlandingpagesubmittionstate,
  getlandingpagedata,
  getlandingpageoneuserdata,
  updateStages,
  updateTags,
} from "../LandingpageReducer";
import { setSnackbar } from "../../../Layout/layoutReducer";
import { fetchCalenderEvents } from "../../../Calendar/calenderReducer";
import { setLeadinfo } from "../../../login/loginReducer";
import StateArray from "../../../../assets/json/state";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import MedCatList from "../../../Backend/MedCatOnlyList/MedCatList";
import { MedicineListing } from "../../../Backend/Medicine/MedicineReducer";
import ListingTable from "../../../../listing/listing";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import { inputExternalPlugin } from "../../../../helper/helperFunctions";
import BBblock3 from "./Component/BBblock3";

function LandingPage18() {
  const [open2, setOpen2] = React.useState(false);
  const [locationArry, setLocationArry] = useState([]);
  const [loading, setloading] = useState(false);
  //   const [scroll, setScroll] = useState(false);
  const scrollToview = useRef();
  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [locationArry, setLocationArry] = useState([]);
  const [submissionState, setSubmissionState] = useState(0);
  // const [loading, setloading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openMoadl, setopenMoadl] = useState(false);
  const [openMoadl2, setopenMoadl2] = useState(false);
  const [newloader, setnewloader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen2(true);
      console.log("setOpen2----------", open2);
    }, 3000);
  }, [open2]);

  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlisting.current.reqBody));
  }, []);

  const medcatListing = useSelector((state) =>
    state.medicineSlice?.medicinedata &&
      state.medicineSlice?.medicinedata.length > 0
      ? state.medicineSlice.medicinedata
      : []
  );
  const loadingInMedCatListing = useSelector((state) =>
    state.medicineSlice?.loading ? state.medicineSlice.loading : false
  );

  const formInputData = useSelector((state) =>
    state.formSlice.formData.userForm5
      ? state.formSlice.formData.userForm5
      : null
  );
  const { uniquename } = useParams();
  console.log("uniquename******", uniquename);
  //  console.warn("process.env>>>>>>>",process.env);

  // console.log("loading", loading)
  let reqbodyforjobcategory = {};
  if (typeof uniquename != "undefined") {
    reqbodyforjobcategory = {
      uniquename: uniquename,
    };
  } else {
    reqbodyforjobcategory = {
      page_slug: process.env.REACT_APP_LANDINGPAGE_SLUG,
    };
    console.warn("process.env>>>>>>>", process.env);
  }

  const userData = useSelector((state) =>
    state.LandingpagefontReducer?.landingpageoneuserdata &&
      Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0
      ? state.LandingpagefontReducer.landingpageoneuserdata
      : {}
  );
  const landingpageformsubmitionstate = useSelector((state) =>
    state.LandingpagefontReducer?.landingpagesubmittionstate
      ? state.LandingpagefontReducer.landingpagesubmittionstate
      : 0
  );
  const landingpageformdeta = useSelector((state) =>
    state.formSlice?.formData?.userForm5
      ? state.formSlice.formData.userForm5
      : null
  );

  const addedData = useSelector((state) =>
    state.LandingpagefontReducer?.addedinfo &&
      Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0
      ? state.LandingpagefontReducer.addedinfo
      : {}
  );

  // const fetchedStateData = useSelector(state => (state.LandingpagefontReducer?.Stagesdata) ? state.LandingpagefontReducer.Stagesdata : [])
  const fetchedStateData = useSelector((state) =>
    state.userManagment?.FetchStages ? state.userManagment?.FetchStages : []
  );

  const tagsDetails = useSelector((state) =>
    state.userManagment?.FetchTagDetailsData
      ? state.userManagment?.FetchTagDetailsData
      : []
  );
  const ipinfo = useSelector((state) =>
    state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0
      ? state.loginSlice.ipInfo
      : null
  );
  const [conversionapicall, setConversionapicall] = useState(false);
  const [stoeFormdata, setstoeFormdata] = useState(null);

  console.log("fetchedStateData", fetchedStateData);

  useEffect(() => {
    if (userData && ipinfo) {
      console.log("userData", userData);
      let body = {
        // "campaign_id":userData.campaign_id,
        associated_program: userData.associated_program,
        landing_page_id: userData.landing_page_id,
        landing_page_slug: userData.landing_page_slug,
        // "campaign_added_by":userData.user_id,
        // "hiring_manager":userData.hm_id,
        ipInfo: ipinfo,
      };
      if (userData.campaign_id) body["campaign_id"] = userData.campaign_id;
      if (userData.user_id) body["campaign_added_by"] = userData.user_id;
      if (userData.hm_id) body["hiring_manager"] = userData.hm_id;
      if (userData.rep_id) body["rep_id"] = userData.rep_id;
      console.log("+++++++++++++++++++", body);
      if (body && body.landing_page_id && !conversionapicall) {
        dispatch(addconverstiondata(body));
        setConversionapicall(true);
      }

      //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
      //    console.log("bodyc",bodyc);
    }
  }, [JSON.stringify(ipinfo), JSON.stringify(userData)]);
  let currTime = new Date().getTime();
  let dayAfterTomorrow = currTime + 48 * 3600 * 1000;
  const [slotListBody, setSlotListBody] = useState({
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "start_datetime_unix",
      type: "desc",
    },
    searchcondition: {
      // userid: getcookies.userInfo?._id,
      start_datetime_unix: {
        $gte: dayAfterTomorrow,
      },
    },
    // "slot_creation_type": props.type === 'np' ? 2 : 1
  });

  useEffect(() => {
    if (uniquename) {
      store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
    } else {
      store.dispatch(getlandingpagedata(reqbodyforjobcategory));
    }
  }, []);

  useEffect(() => {
    console.log("userData", landingpageformsubmitionstate, addedData);
    if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
      // dispatch(setSnackbar({ open: true, message: "Thank you for Submitting !", status: 'success' }))
      // dispatch(clearlandingpagesubmittionstate())

      callstageandredirecr();

      // setopenMoadl(true);

      // if (userData.calendar_access && userData.calendar_access == 1) {
      //     if (userData?.hm_id) {
      //         slotListBody.searchcondition['userid'] = userData.hm_id
      //         slotListBody['organiser_role'] = 'hm'
      //     }
      //     slotListBody['_id'] = addedData._id;
      //     resolvefunction(navigate, `/calendar/${addedData._id}/hm/${userData.hm_id}`, [
      //         fetchCalenderEvents(slotListBody),
      //     ]);
      // }
      // else {
      //     navigate(`/thankyou`);
      // }

      // dispatch(setLeadinfo(addedData));
      // store.dispatch(clearlandingpagesubmittionstate())
    }
  }, [landingpageformsubmitionstate, fetchedStateData]);
  // ------------subhashis--------------
  const openModal = () => {
    setopenMoadl2(true);
  };

  const closeModal = () => {
    setopenMoadl2(false);
  };
  // ------------
  const callstageandredirecr = async () => {
    setnewloader(true);

    await dispatch(clearlandingpagesubmittionstate());
    let body = {
      // "campaign_id":userData.campaign_id,
      associated_program: userData.associated_program,
      landing_page_id: userData.landing_page_id,
      landing_page_slug: userData.landing_page_slug,
      lead_id: addedData._id,
      // "campaign_added_by":userData.user_id,
      // "hiring_manager":userData.hm_id,
      ipInfo: ipinfo,
    };
    if (userData.campaign_id) body["campaign_id"] = userData.campaign_id;
    if (userData.user_id) body["campaign_added_by"] = userData.user_id;
    if (userData.hm_id) body["hiring_manager"] = userData.hm_id;
    if (userData.rep_id) body["rep_id"] = userData.rep_id;
    console.log("+++++++++++++++++++-------------------", body.landing_page_id);
    if (body && body.landing_page_id) {
      console.log("jkjhkljdfkdsfkldfgk");

      dispatch(addconverstiondata(body));
    }
    const dataC = {
      stage_slug: "MJuEcxiUQqAPy",
      added_by: userData.rep_id,
      added_for: addedData._id,
      is_delete: 0,
      status: 1,
    };
    console.log("data===========================>", dataC);
    // return
    await dispatch(updateStages(dataC));

    dispatch(
      setSnackbar({
        open: true,
        message: "Thank you for submitting!",
        status: "success",
      })
    );
    setnewloader(false);

    const data = {
      stage_slug: "6oZTsBFEkuX7J",
      added_by: userData.rep_id,
      added_for: addedData._id,
      is_delete: 0,
      status: 1,
    };
    console.log("data===========================>", data);
    // return
    dispatch(updateStages(data));

    dispatch(clearaddedinfo());
    document.forms["externalForm"].submit();
    setTimeout(() => {
      setstoeFormdata(null);
    }, 1000);
    // if (userData?.agent_code) {
    //     window.location.replace(`https://enroll.adhcrx.com/?agentcode=${userData.agent_code}`);

    // } else {
    //     window.location.replace("https://enroll.adhcrx.com");

    // }
  };

  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//

  useEffect(() => {
    console.log("addedData", addedData);
  }, [addedData]);

  useEffect(() => {
    if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
      return;
      setConversionapicall(false);
      setDataCopy(addedData);
      const dataC = {
        stage_slug: "MJuEcxiUQqAPy",
        added_by: userData.rep_id,
        added_for: addedData._id,
        is_delete: 0,
        status: 1,
      };
      console.log("data===========================>", dataC);
      // return
      dispatch(updateStages(dataC));

      // let body = {
      //     // "campaign_id":userData.campaign_id,
      //     "associated_program": userData.associated_program,
      //     "landing_page_id": userData.landing_page_id,
      //     "landing_page_slug": userData.landing_page_slug,
      //     "lead_id":addedData._id,
      //     // "campaign_added_by":userData.user_id,
      //     // "hiring_manager":userData.hm_id,
      //     "ipInfo": ipinfo
      // }
      // if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
      // if (userData.user_id) body['campaign_added_by'] = userData.user_id;
      // if (userData.hm_id) body['hiring_manager'] = userData.hm_id;

      // console.log("+++++++++++++++++++-------------------", body.landing_page_id);
      // if (body && body.landing_page_id ) {

      //     console.log("jkjhkljdfkdsfkldfgk")

      //     dispatch(addconverstiondata(body))
      //     setConversionapicall(true)

      // }

      const tagdata = {
        added_by: userData.rep_id,
        added_for: addedData._id,
        key: "Wnv14tRS9FlmV",
        label: "hm-policiesandcompliance-signed-success",
        is_delete: 0,
        status: 1,
      };
      console.log("tagdata===========================>", tagdata);
      // return
      dispatch(updateTags(tagdata));

      const data = {
        stage_slug: "6oZTsBFEkuX7J",
        added_by: userData.rep_id,
        added_for: addedData._id,
        is_delete: 0,
        status: 1,
      };
      console.log("data===========================>", data);
      // return
      dispatch(updateStages(data));
    }
  }, [landingpageformsubmitionstate]);

  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      setLocationArry(Newarr);
    }
  }, []);

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      let dataset = { ...formInputData };
      if (dataset.state) {
        console.log("if 1");
        if (StateArray.length > 0) {
          console.log("if 2");
          for (let i in StateArray) {
            console.log("if 3");
            if (StateArray[i].name == dataset.state) {
              console.log("if 4");
              dataset = { ...dataset, state_value: StateArray[i].val };
            }
          }
        }
      }
      console.log("dataset", dataset);
      addapicall(formInputData);

      setstoeFormdata(dataset);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);

  const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val };
    let body = {
      firstname: val.firstname,
      lastname: val.lastname,
      email: val.email,
      phone: val.phone,
      city: val.city,
      state: val.state,
      zip: val.zip,
      streetaddress: val.streetaddress,
      apartment: val.apartment,

      // "married": val.married,
      campaign_id: userData.campaign_id,
      associated_program: userData.associated_program,
      page_id: userData.landing_page_id,
      page_slug: userData.landing_page_slug,
      added_by: userData.user_id,
      hiring_manager: userData.hm_id,
      rep_page_id: userData.rep_page_id,
      rep_page_slug: userData.rep_page_slug,
      rep_id: userData.rep_id,
      createdon_datetime: new Date().getTime(),
      status: 0,
      // "roles": { is_rep: 1 },
      aer_id: userData.aer_id,
      associated_bdd: userData.associated_bdd,
      redirected_to_accesspage: true,
      added_type: "landingpage",
      calender_access_form_submit_time: userData.calendar_access ? 2 : 1,
      ipinfo: ipinfo,
      choose_prescription_plan: val.choose_prescription_plan,
      // is_rep: true,
    };

    if (Object.keys(body).length > 0) {
      console.log("body-----", body);

      setloading(true);
      let responsedataset = await store.dispatch(
        addlandingpagetwodatalead(body)
      );
      console.log("responsedataset", responsedataset);
      if (
        responsedataset &&
        responsedataset.payload &&
        responsedataset.payload.status == "error"
      ) {
        store.dispatch(
          setSnackbar({
            open: true,
            message: responsedataset.payload.message
              ? responsedataset.payload.message
              : "Something went wrong",
            status: "error",
          })
        );
        setSubmissionState(0);
      }
      if (userData.pixel_tracking_conversion) {
        const node = document
          .createRange()
          .createContextualFragment(userData.pixel_tracking_conversion);
        refforconversion.current.appendChild(node);
      }
      // setActive(false)
      setloading(false);
    }
  };

  const formDataL2 = {
    id: "userForm5",
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "lead-manage/add-update-intake-form",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/calendar",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "l2submitbtnsection",
    submitBtnName: "Select your subscription on the next step",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      type: "admin",
      status: "active",
    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        // heading: "Full Name",
        label: "First Name",
        name: "firstname",
        className: "formfild",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 1,
        // heading: "Full Name",
        label: "Last Name",
        name: "lastname",
        className: "formfild",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 2,
        // heading: "Full Name",
        label: "Phone",
        name: "phone",
        className: "formfild",
        type: "text",
        inputType: "phone",
        rules: { required: true, minLength: 10 },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 3,
        // heading: "Full Name",
        label: "Email",
        name: "email",
        className: "formfild email_marg",
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 30,
        type: "html",
        className: "email_btm_line",
        value: "<p></p>",
      },

      // {
      //     id: 4,
      //     // heading: "Street Address",
      //     label: "Street Address",
      //     name: "streetaddress",
      //     className: 'formfild',
      //     type: "text",
      //     inputType: "text",/////////////// If not mentioned default will be text //////////////
      //     rules: { required: true },
      //     errorMessage: {
      //         required: "This Field is Required",
      //         custom: "Value is Invalid"
      //     },
      // },

      // {
      //     id: 5,
      //     // heading: "Street Address",
      //     label: "Apartment, suit, unit etc. (optional)",
      //     name: "apartment",
      //     className: 'formfild',
      //     type: "text",
      //     inputType: "text",/////////////// If not mentioned default will be text //////////////
      //     // rules: { required: true },
      //     // errorMessage: {
      //     //     required: "This Field is Required",
      //     //     custom: "Value is Invalid"
      //     // },
      // },

      {
        id: 6,
        // heading: "Full Name",
        label: "Town / City",
        name: "city",
        className: "formfild",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 7,
        // heading: "Full Name",
        label: "State",
        name: "state",
        className: "formfild state",
        type: "select",
        // other: true,
        values: locationArry,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: "",
      },
      {
        id: 8,
        // heading: "Full Name",
        label: "Zip / Postal Code",
        name: "zip",
        className: "formfild",
        type: "text",
        // inputType:"number",
        inputType: "zip",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      // {
      //     id: 11,
      //     heading: "Please Choose Your Prescription Plan",
      //     name: "choose_prescription_plan",
      //     className: 'form_radio',
      //     values: [{ key: 'Family ($31.99 per mo.)', val: 'Family ($31.99 per mo.)' }, { key: 'Single +1 ($26.99 per mo.)', val: 'Single +1 ($26.99 per mo.)' }, { key: 'Single ($21.99 per mo.)', val: 'Single ($21.99 per mo.)' }],
      //     type: 'radio',
      //     // hint: 'Yes/No',
      //     // rules: { required: true },
      //     // errorMessage: {
      //     //   required: "This Field is Required",
      //     //   custom: "Value is Invalid"
      //     // },
      //     // defaultValue: "No"
      // }
      {
        id: 125,
        // heading: "Captcha",
        // label: "Captcha",
        name: "captcha",
        className: 'formfild formfild4 reCaptcha',
        type: "captcha",
        rules: { required: true },
        errorMessage: {
          required: "Prove, You're Not a Robot",
          custom: "Value is Invalid"
        },
      },
    ],
  };

  store.subscribe(() => {
    const storeData = store.getState();
    if (
      storeData &&
      storeData.formSlice &&
      storeData.formSlice.formSubmissionState &&
      storeData.formSlice.formSubmissionState.userForm5
    ) {
      console.log(
        "submition state",
        storeData.formSlice.formSubmissionState.userForm5
      );
      setSubmissionState(storeData.formSlice.formSubmissionState.userForm5);
    }
    // console.log(setSubmissionState, "setSubmissionState")
    // console.log("landing store", storeData)
  });

  const closemodalandnavigate = () => {
    setopenMoadl(false);
    // document.forms["externalForm"].submit();
    return;
    if (userData?.agent_code) {
      window.location.replace(
        `https://enroll.adhcrx.com/?agentcode=${userData.agent_code}`
      );
    } else {
      window.location.replace("https://enroll.adhcrx.com");
    }
    // window.open("https://enroll.adhcrx.com")
  };
  console.log(
    "*****************",
    process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL
  );

  var modifyTableHeadersforMedCatlisting = [
    { val: "name", name: "Medicine Name" },
    { val: "med_by_catagorey", name: "Medicine Category" },
  ];

  let tableDataForMedCatlisting = useRef({
    tableId: "medcatlist",
    tableTitle: "Medicine List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {
      // "source": "training_list",
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
      email: "",

      count: false,
    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: [
      "name",
      "med_by_catagorey",
      "priority",
      "status",
      "createdon_datetime",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },

    searchData: {
      // heading: "Search Medicine & Category List",
      //   type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchMedicineandCategory",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "desc",
                field: "_id",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "name",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },
          },
        ],
      },
    },
  });

  const ref = useRef();
  const refforconversion = useRef();
  useEffect(() => {
    if (userData.pixel_tracking_click) {
      const node = document
        .createRange()
        .createContextualFragment(userData.pixel_tracking_click);
      ref.current.appendChild(node);
    }
  }, [JSON.stringify(userData)]);

  return (
    <>
      <div className="lpage_main_5_wrapper">
        <div className="landingpage5_whole_cont">
          <div className="landingpage_new_bnnr_block">
            <div className="landing_page5_bannerblock">
              {/* <h1>POPo</h1> */}
              {userData.pixel_tracking_click && (
                //  <span className='visiblehiddenclas' dangerouslySetInnerHTML={{__html: userData.pixel_tracking_click}}></span>
                <span className="visiblehiddenclas" ref={scrollToview}></span>
              )}
              {userData.pixel_tracking_conversion && (
                //  <span className='visiblehiddenclas' dangerouslySetInnerHTML={{__html: userData.pixel_tracking_click}}></span>
                <span
                  className="visiblehiddenclas"
                  ref={refforconversion}
                ></span>
              )}
              <div className="landing-container">
                <div className="landing-col-container">
                  <div className="landing-banner-left">
                    <div className="lpage_logowrapper">
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}access_discount_logo.webp`}
                      />
                    </div>

                    <div className="lpage_textWrapper_main">
                      <div className="lpage_textWrapper">
                        <h1>Thank you for supporting your </h1>
                        <h1>friend’s fundraising activity.</h1>
                      </div>

                      <div className="lpage_pWrapper">
                        <p>
                          This fundraiser is NOT selling candy, cookie dough,
                          popcorn, pizza, car washes, donations, gift cards,
                          charity auctions, raffle tickets, walk-a-thon, casino
                          night, or any other fundraising you have already
                          supported.
                        </p>
                      </div>

                      <div className="lpage_colorBox">
                        <div className="lpage_colorBox_innr">
                          <h2>
                            This fundraiser is <span>DIFFERENT!</span>
                          </h2>
                          <div className="lpage_colorBox_p_wrapper">
                            <p>
                              As a company, we believe that the best way to help
                              your friend fundraising activity is to HELP YOU
                              SAVE MONEY on things you already buy. That way
                              your friend isn’t selling you on anything you
                              don’t already do. When we help you save money, we
                              pass the savings on to your friend in the form of
                              a fundraiser.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='lpage_banner_titlewrapper'>
                                <div className='lpage_banner_sub_wrapper'>
                                    <h2><span>ASPIRE PHARMACY SAVINGS</span> <br />
                                        Pay Nothing For The Most Prescribed Generic Prescriptions!
                                    </h2>

                                    <div className='lpage_banner_textwrapper2'>
                                        <div className='lpage_banner_textwrapper1'>
                                            <p>We Make It Easy For Both You and Your Doctor</p>
                                        </div>
                                        <p>Medications can be e-prescribed, called in or faxed and can be picked up at your local pharmacy or delivered right to your home!</p>

                                    </div>

                                </div>

                            </div> */}
                    {/* <div className='lpage_banner_sub_titlewrapper'>
                                <h3>
                                    We are looking for business professionals to deliver our leading-edge, cost savings solutions to companies nationwide.
                                </h3>
                            </div> */}
                    {/* <div className='lpage_banner_textwrapper'>
                                <p>
                                    <span>Our Friendly Pharmacist Coaches Are Here To Help</span><br />
                                    Have a question or need a prescription transferred? <br />
                                    Sit back and relax, we take care of it for you.
                                </p>
                            </div> */}
                    <div className="lpage_arrow_wrapper">
                      <h2>
                        Enroll today to gain access to over{" "}
                        <span>605 medications for free at</span> one{" "}
                        <span>low membership price!</span>
                      </h2>
                    </div>
                  </div>
                  <div
                    className="landing-banner-right landingOne lnding_P18"
                    ref={scrollToview}
                  >
                    <div className="formwrapper">
                      <div className="form_title_wrapper">
                        <h2>
                          <span>
                            COMPLETE THE FORM BELOW TO ENROLL
                          </span>
                          {/* <span>Below To Speak With One</span>
                                        <span>Of Our Team Members</span> */}
                        </h2>
                      </div>
                      <div className="form_style">
                        <Form formData={formDataL2} />
                        {loading || newloader ? (
                          <Box
                            className="loaderProgress"
                            sx={{ width: "100%", mt: "0px" }}
                          >
                            <LinearProgress />
                          </Box>
                        ) : null}

                        <div className="formmm_new_hidden">
                          <form
                            id="externalForm"
                            action="https://enroll.adhcrx.com/Prefill"
                            method="post"
                          >
                            <input
                              name="FirstName"
                              value={stoeFormdata?.firstname}
                            />

                            <input
                              name="lastName"
                              value={stoeFormdata?.lastname}
                            />

                            <input name="email" value={stoeFormdata?.email} />

                            <input name="phone" value={stoeFormdata?.phone} />

                            {/* <input name="dob" value="01/01/2001" /> */}

                            <input name="city" value={stoeFormdata?.city} />

                            <input
                              name="state"
                              value={stoeFormdata?.state_value}
                            />

                            <input name="zipCode" value={stoeFormdata?.zip} />

                            <input
                              name="agentCode"
                              value={userData?.agent_code}
                            />

                            {/* <!-- <input name="promoCode" value="Promo123" />--> */}

                            {/* <input name="gender" value="M" /> */}

                            {/* <input name="address1" value={userData?.agent_code} /> */}

                            {/* <input name="address2" value="#1" /> */}

                            <button type="submit">Submit</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='lpage_banner_list_wrapper'>

                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>01</div>
                          <h2>No investment required</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon1.png' />
                          </div>
                      </div>
                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>02</div>
                          <h2>No employees to manage</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon2.png' />
                          </div>
                      </div>
                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>03</div>
                          <h2>No overhead</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon3.png' />
                          </div>
                      </div>
                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>04</div>
                          <h2>No risk</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon4.png' />
                          </div>
                      </div> 

                  </div> */}
              </div>
            </div>
          </div>

          {/* --------------------------block2------------------------------- */}
          <Asvideoblock />

          {/* --------------------------block2------------------------------- */}

          {/* --------------------------block2------------------------------- */}
          <BBblock3 scrollToview={scrollToview} />
          {/* --------------------------block2------------------------------- */}

          <div className="landingpage5_bannerblock8">
            <div className="landing-container">
              <div className="landingpage5_bannerblock8_mainwrapper">
                <h2>Simply Follow These 6 Steps To Free Medication</h2>
                <div className="landingpage5_bannerblock8_sub_mainwrapper">
                  <div className="landingpage5_bannerblock8_step5">
                    <div className="landingpage5_bannerblock8_step1_img">
                      <h4>STEP 1</h4>
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}landingpage5_bannerblock8_1step_img.png`}
                      ></img>
                    </div>
                    <div className="landingpage5_bannerblock8_step1_text">
                      <h3>SIGN-UP FOR YOUR MONTHLY MEMBERSHIP</h3>
                      <p>
                        As A Member In Our Program, You Get Access To All Of Our
                        Medications For<span> FREE</span>!
                      </p>
                    </div>
                  </div>
                  <div className="landingpage5_bannerblock8_step5">
                    <div className="landingpage5_bannerblock8_step2_img">
                      <h4>STEP 2</h4>
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}landingpage5_bannerblock8_2step_img.png`}
                      ></img>
                    </div>
                    <div className="landingpage5_bannerblock8_step2_text">
                      <h3>SEARCH OUR LIST OF 605 GENERIC MEDICATIONS</h3>
                      <p>
                        Identify The Medications You Are Taking. Compare What
                        You Paid For Them To <span>FREE With Us</span>!
                      </p>
                    </div>
                  </div>
                  <div className="landingpage5_bannerblock8_step5">
                    <div className="landingpage5_bannerblock8_step3_img">
                      <h4>STEP 3</h4>
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}landingpage5_bannerblock8_3step_img.png`}
                      ></img>
                    </div>
                    <div className="landingpage5_bannerblock8_step3_text">
                      <h3>
                        CALL OUR PHARMACY COACHES TO REVIEW YOUR MEDICATIONS
                      </h3>
                      <p>
                        Our Team Is Here To Help. We Will Make Sure Your
                        Medications Are On The List Or
                        <span> Find Therapeutically Similar Ones</span>
                      </p>
                    </div>
                  </div>
                  <div className="landingpage5_bannerblock8_step5">
                    <div className="landingpage5_bannerblock8_step4_img">
                      <h4>STEP 4</h4>
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}landingpage5_bannerblock8_4step_img.png`}
                      ></img>
                    </div>
                    <div className="landingpage5_bannerblock8_step4_text">
                      <h3>
                        TRANSFER YOUR PRESCRIPTIONS, OR YOUR DOCTOR CAN
                        E-PRESCRIBE TO US
                      </h3>
                      <p>
                        Transferring Prescriptions Can Be Difficult, So Our
                        Pharmacy Team Does It <span>ALL For You</span>!
                      </p>
                    </div>
                  </div>
                  <div className="landingpage5_bannerblock8_step5">
                    <div className="landingpage5_bannerblock8_step5_img">
                      <h4>STEP 5</h4>
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}landingpage5_bannerblock8_5step_img.png`}
                      ></img>
                    </div>
                    <div className="landingpage5_bannerblock8_step5_text">
                      <h3>
                        WE WILL CONTACT YOU TO CONFIRM THE ORDER AND COLLECT
                        PAYMENT
                      </h3>
                      <p>
                        We Always Make Sure Everything Is Accurate. We Double
                        Check The Prescription And{" "}
                        <span>Securely Place Your Order</span>
                      </p>
                    </div>
                  </div>
                  <div className="landingpage5_bannerblock8_step5">
                    <div className="landingpage5_bannerblock8_step6_img">
                      <h4>STEP 6</h4>
                      <img
                        src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}landingpage5_bannerblock8_6step_img.png`}
                      ></img>
                    </div>
                    <div className="landingpage5_bannerblock8_step6_text">
                      <h3>RECEIVE YOUR MEDICATIONS</h3>
                      <p>
                        You Can Pick-up{" "}
                        <span>
                          Emergency Medications at your Local Pharmacy{" "}
                        </span>{" "}
                        or Have Chronic Medications{" "}
                        <span>
                          Shipped To Your Home For FREE in 2-4 business days
                        </span>
                        !
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => setScroll()}
                  className="gradiant_fontBW gradiant_black_darkBG"
                >
                  <h2>Enroll today to gain access to your free medication!</h2>
                </button>
              </div>
            </div>
          </div>

          <div className="landingpage5_bannerblock2">
            <div className="landing-container">
              <div>
                <div className="gradiant_fontBW gradiant_darkBG">
                  <h2>
                    We are committed to improving your life and saving you
                    money.
                  </h2>
                  {/* <img className='trans_img' src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/transparent_tablet_img.webp' /> */}
                </div>
                <h3>
                  <span>
                    We are turning heads as we release America’s First Free
                    Pharmacy.
                  </span>
                </h3>
                <div className="landing-col-container2">
                  <div className="landingpage5_bannerblock2_right_section">
                    {/* <p>This pharmacy program has been designed to make the most used, generic medications <span>available for FREE to everyone</span>. These drugs are typically used to treat conditions like Diabetes, Heart Disease, High Cholesterol, and Depression.</p> */}
                    <p>
                      Our pharmacy program makes the most used, generic
                      medications <span>available for FREE to everyone</span>.
                      These drugs treat conditions such as Diabetes, Heart
                      Disease, High Cholesterol, and Depression.
                    </p>

                    {/* <p>We have assembled a team of pharmacy professionals with experience in all aspects of the industry. Our expertise resides in medication pricing, sourcing, distribution, strategy and marketing. With decades of real world knowledge, we set out to build the most efficient and cost effective pharmacy in the United States. We have the most high-tech software, hardware and processing system around and utilize its capabilities to deliver medications<span> within 1-3 days right to your door for prices you won’t find anywhere else.
                                    </span>
                                    </p> */}
                    <p>
                      We have assembled a team of pharmacy professionals with
                      experience in all aspects of the industry. Our expertise
                      resides in medication pricing, sourcing, distribution,
                      strategy, and marketing. With decades of real-world
                      knowledge, we set out to build the most efficient and
                      cost-effective pharmacy in the United States. We have the
                      most high-tech software, hardware, and processing system
                      around and utilize its capabilities to deliver medications{" "}
                      <span>
                        within 1-3 days right to your door for prices you won’t
                        find anywhere else
                      </span>
                      .
                    </p>
                    <button
                      onClick={() => setScroll()}
                      className="gradiant_fontBW gradiant_black_darkBG"
                    >
                      <h2>
                        Enroll today to gain access to your free medication!
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className='drug_search_main_wrp'>
                            <div className='drug_search_sub_wrp'>
                                <div className='drug_search_wrp'>
                                    <embed src='https://drugsearch.pharmacymembers.com/?planID=MYFREERX'/> :
                                    <iframe src='https://drugsearch.pharmacymembers.com/?planID=MYFREERX'></iframe>
                                    <img src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}drug_search_img.webp`} />

                                </div>
                            </div>
                        </div> */}
            </div>
          </div>

          <div className="prescriptions_block">
            <div className="landing-container">
              <div className="prescriptions_subblock">
                <h2>
                  Search for your pharmacy prescriptions and see if we have what
                  you need!
                </h2>
                <button onClick={() => openModal()}>
                  Click Here to Search
                </button>
              </div>
            </div>
          </div>

          <div className="landingpage5_bannerblock3">
            <div className="landing-container">
              <div className="landingpage5_bannerblock3_subwrapper">
                <div className="landing-col-container3">
                  <div className="landingpage5_bannerblock3_firstblock gradiant_fontBW gradiant_darkBG">
                    <h2>
                      We offer the 605 most prescribed drugs in America for
                      free!
                    </h2>
                  </div>
                  <div className="landingpage5_bannerblock3_secondblock">
                    <div className="landingpage5_bannerblock3_secondblock_leftsection">
                      <p>
                        <span>125</span> Generic Acute <br />
                        Medications<strong> (Immediate Need)</strong>{" "}
                      </p>
                    </div>
                    <div className="landingpage5_bannerblock3_secondblock_leftsection">
                      <p>
                        <span>480</span> Generic Chronic <br />
                        Medications<strong> (Maintenance)</strong>
                      </p>
                    </div>
                  </div>
                  <div className="landingpage5_bannerblock3_thirdblock">
                    <h2>Here is a sample of the medications on our list</h2>
                    <div className="hr"></div>
                    <ul>
                      <li>ACYCLOVIR</li>
                      <li>ALLOPURINOL</li>
                      <li>AMITRITYLINE</li>
                      <li>AMLODIPINE</li>
                      <li>AMOXICILLIN</li>
                      <li>ANASTRAZOLE</li>
                      <li>ATENOLOL</li>
                      <li>ATORVASTATIN</li>
                      <li>AZITHROMYCIN</li>
                      <li>AMLODIPINE</li>
                      <li>BUPROPION</li>
                      <li>CARVEDILOL</li>
                      <li>CEFDINIR</li>
                      <li>CELECOXIB</li>
                      <li>CEPHALEXIN</li>
                      <li>CIPROFLOXACIN</li>
                      <li>CITALOPRAM</li>
                      <li>CLOPIDOGREL</li>
                      <li>CYCLOBENZAPRINE</li>
                      <li>DICLOFENAC</li>
                      <li>DILTIAZEM</li>
                      <li>DOXYCYCLINE</li>
                      <li>DULOXETINE</li>
                      <li>ESCITALOPRAM</li>
                      <li>ESTRADIOL</li>
                      <li>EZETIMIBE</li>
                      <li>FENOFIBRATE</li>
                      <li>FINASTERIDE</li>
                      <li>FLUCONAZOLE</li>
                      <li>FLUOXETINE</li>
                      <li>GLIMEPIRIDE</li>
                      <li>GLIPIZIDE</li>
                      <li>HYDRALAZINE</li>
                      <li>IBUPROFEN</li>
                      <li>LAMOTRIGINE</li>
                      <li>LEVOTHYROXINE</li>
                      <li>LIOTHYRONINE</li>
                      <li>LISINOPRIL</li>
                      <li>LISINOPRIL/HCTZ</li>
                      <li>LOSARTAN</li>
                      <li>MELOXICAM</li>
                      <li>METFORMIN</li>
                      <li>METHOTREXATE</li>
                      <li>METOPROLOL</li>
                      <li>MONTELUKAST</li>
                      <li>OMEPRAZOLE</li>
                      <li>PANTOPRAZOLE</li>
                      <li>PAROXETINE</li>
                      <li>PRAVASTATIN</li>
                      <li>PROGESTERONE</li>
                      <li>ROSUVASTATIN</li>
                      <li>SERTRALINE</li>
                      <li>SIMVASTATIN</li>
                      <li>SILDENAFIL</li>
                      <li>TAMSULOSIN</li>
                      <li>TRAMADOL</li>
                      <li>TRAZODONE</li>
                      <li>TRIAMCINOLONE</li>
                      <li>VALACYCLOVIR</li>
                      <li>VENLAFAXINE</li>
                    </ul>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setScroll()}
                className="landingpage5_bannerblock3_fourblock gradiant_fontBW gradiant_black_darkBG"
              >
                <h2>Enroll today to gain access to your free medication!</h2>
              </button>
            </div>
          </div>

          <div className="landingpage5_bannerblock5">
            <div className="landing-container">
              <div className="landingpage5_bannerblock5_firstblock">
                {/* <h3>LAUREN KALVIN</h3> */}

                <p>
                  {" "}
                  <span>
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}left_quoatation_img.webp`}
                    ></img>
                  </span>
                  The prices on prescriptions for my family were going up every
                  year, and I couldn't keep paying the high prices, so I
                  searched for a better option. I came across Access Discount
                  Healthcare and I won't buy them any other way now. With Access
                  Discount Healthcare , I pay nothing for our medication!
                  <span>
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}right_quoatation_img.webp`}
                    ></img>
                  </span>
                </p>
                <strong>-LAUREN KALVIN</strong>
              </div>
            </div>
          </div>
          <div className="landingpage5_bannerblock6">
            <div className="landing-container">
              <div className="landingpage5_bannerblock6_mainwrapper">
                <h2>ACCEPTED AT 64,000 PHARMACIES NATIONWIDE INCLUDING:</h2>
                <div className="landingpage5_bannerblock6_firstblock">
                  <div className="landingpage5_bannerblock6_sub_firstblock">
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}wallgreens_logo.webp`}
                    ></img>
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}rite_aid_logo.webp`}
                    ></img>
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}walmart_logo.webp`}
                    ></img>
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}cvs_logo.webp`}
                    ></img>
                    <img
                      src={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}albertsons_logo.webp`}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landingpage5_bannerblock7">
            <div className="landing-container">
              <div className="landingpage5_bannerblock7_mainwrapper">
                {/* <img className='tablet_img' src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/white_blue_tablet.webp' /> */}
                <div className="gradiant_fontBW gradiant_darkBG">
                  <h2>Everything you need to know about the program</h2>
                  {/* <h2>about the program</h2> */}
                </div>
                <h3>
                  <span>Stop Paying For Prescriptions…It’s Easy!</span>
                </h3>
                {/* <p>At<span> Access Discount Healthcare Rx </span>we have made it our mission to help the uninsured and underinsured (people who have insurance but deductibles are so high they never meet them and they are paying full price for medicine). With decades or relationships, partnerships and buying power, we have pooled our resources and are bringing you the lowest prices on medications in the country.</p> */}
                <p>
                  At <span>Access Discount Healthcare </span>we have made it our
                  mission to help the uninsured and underinsured (people who
                  have insurance but whose deductibles are so high they never
                  meet them, and they are paying full price for medicine). With
                  decades of relationships, partnerships, and buying power, we
                  have pooled our resources and are bringing you the lowest
                  prices on medications in the country.
                </p>
              </div>
            </div>
          </div>

          <div className="landingpage5_bannerblock9">
            <div className="landingpage5_bannerblock9_mainwrapper">
              <div className="landingpage5_bannerblock9_subwrapper1">
                <div className="landing-container">
                  <div className="landingpage5_bannerblock9_mainwrapper_firstpart">
                    <div className="gradiant_fontBW gradiant_darkBG">
                      <h2>Frequently Asked Questions</h2>
                    </div>
                    <h3>
                      What’s the difference between a brand-name drug and a
                      generic equivalent drug?
                    </h3>
                    {/* <p>According to the Food and Drug Administration, generic drugs are the same as their brand-name equivalents in active ingredients, dosage, safety, strength, and performance, and are held to the same strict standards as their brand-name counterparts. The only noticeable difference between a generic drug and its brand-name counterpart may be the shape and/or color of the drug. <span> While generic drugs are just as effective as the corresponding brand-name drugs, they typically cost up to 70 percent less,</span> which helps control health care costs. This helps you in the long run because the generic option is always the lowest cost to you.</p> */}
                    <p>
                      According to the Food and Drug Administration, generic
                      drugs are the same as their brand-name equivalents in
                      active ingredients, dosage, safety, strength, and
                      performance. They are held to the same strict standards as
                      their brand-name counterparts. The only noticeable
                      difference between a generic drug and its brand-name
                      counterpart may be its shape and/or color.{" "}
                      <span>
                        While generic drugs are just as effective as the
                        corresponding brand-name drugs, they typically cost up
                        to 70 percent less,
                      </span>{" "}
                      which helps control healthcare costs.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="landingpage5_bannerblock9_subwrapper2">
                <div className="landing-container">
                  <div className="landingpage5_bannerblock9_mainwrapper_secondpart">
                    <h2>What if my drug is not available in generic form?</h2>
                    <p>
                      Even if your drug is not yet available in generic form, a
                      similar generic drug may be just as effective. If you are
                      taking a brand-name medication, ask your doctor if a
                      generic equivalent drug could treat your condition just as
                      well. Generally, brand-name drugs are protected under a
                      10-17 year patent, prohibiting a generic equivalent from
                      being made. During this period, manufacturers control
                      pricing to make up for expensive research and development.
                      In the interests of saving time, money and taking care of
                      patients, most doctors will consider substituting a
                      generic therapeutic alternative. A therapeutic similar is
                      the generic match for the brand-name drug that treats the
                      same condition using different ingredients. In most cases,
                      these alternatives are just as effective.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {open2 && (
            <div className="landingpage5_block12">
              <div className="landing-container">
                <div className="landingpage5_block12_subwrapper">
                  <div className="landingpage5textwrapper">
                    <div className="gradiant_fontBW gradiant_darkBG">
                      <h2>What should I ask my doctor?</h2>
                    </div>
                    <p>
                      First, ensure your doctor knows you have a new pharmacy
                      program and that you get any of our 605 generic
                      medications for FREE. Then ask, “would one of these
                      generic drugs be a good option for me, and could it treat
                      my condition similarly?” You can also ask if a generic
                      option exists for your other brand­ name drugs. Remember,
                      most medications Americans take are generic.
                    </p>

                    <div className="gradiant_fontBW gradiant_darkBG">
                      <h2>Why aren’t ALL medicines on your drug list?</h2>
                    </div>
                    <p>
                      Generic medications make up 85% of the medicines in
                      America each year. Our list accounts for 90% of drugs
                      prescribed. Our list of medications is grouped into more
                      than 22 therapeutic classes, ensuring we have a very
                      well-rounded formulary for various chronic and acute
                      conditions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {open2 && (
            <div className="landingpage5_lstblock">
              <div className="landing-container">
                <div className="landingpage5_lstblock_subwrapper">
                  <div className="landingpage5textwrapper gradiant_fontBW">
                    <div className="landingpage5textwrapper_lastblock">
                      <h2>
                        Members can get a <span>90-Day </span>Prescription for
                        $0 Shipped for <span>Free</span>!{" "}
                      </h2>
                    </div>

                    <button
                      onClick={() => setScroll()}
                      className="gradiant_fontBW gradiant_black_darkBG "
                    >
                      <h2>
                        Enroll today to gain access to your free medication!
                      </h2>
                    </button>
                  </div>
                  {/* <img src={"https://all-frontend-assets.s3.amazonaws.com/landing-page5/lpage5lst1image1.png"} className='abimage' /> */}
                  {/* <img src={"https://all-frontend-assets.s3.amazonaws.com/landing-page5/lpage5lst1image2.png"} className='abimage1' /> */}
                </div>
              </div>
            </div>
          )}

          {open2 && (
            <div className="last_phone_block">
              <div className="landing-container">
                <div className="last_phone_inner_block">
                  <img
                    className="lady_call_grl"
                    src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/landing_page5_img.webp"
                  />
                  <div className="last_phone_text_wrp">
                    {/* <h2>Please call our Customer Support</h2>
                                {userData?.agent_code?<h2>team at 800.863.8926 ({userData.agent_code})</h2>:
                                <h2>team at 800.863.8926</h2>}
                                
                                <h2>if you have
                                    any questions.</h2>

                                <h1>THIS IS NOT INSURANCE</h1> */}

                    <h2>
                      Please call our Customer Support
                      <br />{" "}
                      <span>
                        {userData?.agent_code ? (
                          <h2>
                            team at <a href="tel:800.863.8926">800.863.8926</a>
                          </h2>
                        ) : (
                          <h2>
                            team at <a href="tel:800.863.8926">800.863.8926</a>{" "}
                          </h2>
                        )}
                      </span>{" "}
                      if you have any questions. Enrollment Code: "
                      {userData.agent_code ? userData.agent_code : "N/A"}"
                    </h2>
                    {/* <h1>THIS IS NOT INSURANCE</h1> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="land_footer_block">
        <p>© 2023 Access discount healthcare. All Right Reserved</p>
        <h2>This is not insurance</h2>
      </div> */}

      <div>
        <Modal
          className="image_card_modal modalBaseStyle"
          open={openMoadl}
          onClose={() => closemodalandnavigate()}
        >
          <Box className="modalBaseBox largeModal">
            {/* <IconButton className="modal_icon_button" onClick={closeModal}>
                            <CloseIcon className="modal_close_icon" />
                        </IconButton> */}

            <div className="dataform1 addedit_wrp">
              <p>Be Sure To Use Same Email On Next Step</p>
            </div>
            <div className="navigatebtnwrpper">
              <Button
                variant="contained"
                onClick={() => closemodalandnavigate()}
              >
                Ok{" "}
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          className="modalBaseStyle allMeetingMOdal  MOdallanding5 SD_MOdallanding"
          open={openMoadl2}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalBaseBox mediumModal">
            <Tooltip title="Close">
              <IconButton
                onClick={() => closeModal()}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <div className="rolelist_mainwrp">
                <div className="listing_heading">
                  <h1>Access Free Pharmacy Medicine</h1>
                </div>

                <div className="rolelist_mainwrp_Table">
                  {!loadingInMedCatListing ? (
                    <ListingTable
                      tableData={tableDataForMedCatlisting.current}
                      dataset={medcatListing}
                      modifyHeaders={modifyTableHeadersforMedCatlisting}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default LandingPage18;
