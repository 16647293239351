import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'


import '../../../../assets/List.css'
import store from '../../../../store'

import { Button, Drawer, IconButton, LinearProgress, Paper, Snackbar, Tooltip } from '@mui/material'
import { TableSkeleton } from '../../../../helper/TableSkeleton'
import ListingTable from '../../../../listing/listing'
import { aeCommissionSummary, aeFilterCommissionSummary, aeFilterCommissionSummaryCount, affiliateFilterSummary, affiliateFilterSummaryCount, affiliateSummary, clearPartnerReducerReportData, partnerCommissionSummary, resetdata } from '../PartnersReducer'
import { resolvefunction } from '../../../../helper/helperFunctions'
import moment from 'moment'
import { clearPageState, setTableCounts, setTableData, setTableDefaultreqBody, unmountTable } from '../../../../listing/listReducer'
import { Form } from '../../../../form/Form'
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { summaryexportCSV } from '../../AffiliateManagement/AffiliateReducer'
import IosShareIcon from '@mui/icons-material/IosShare';


function AffiliateSummaryReport() {

    const paramData = useParams();
    const navigate = useNavigate();
    const Location = useLocation();
    const dispatch = useDispatch();


    const [toogleVal, setToogleVal] = useState('all')
    const [filterData, setFilterData] = useState(false)
    const [listData, setListData] = useState([])
    const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
    const [amtToogleFlag, setAmtToogleFlag] = useState(true);
    const [listDataInsert, setListDataInsert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);


    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});


    const filteredReportData = useSelector((state) => state.PartnersReducer?.affiliateFilterSummaryData ? state.PartnersReducer.affiliateFilterSummaryData : []);

    const filteredReportCount = useSelector((state) => state.PartnersReducer?.filterAffiliateSummaryCount ? state.PartnersReducer.filterAffiliateSummaryCount : null);

    const commisionReportSuccess = useSelector((state) => state.PartnersReducer?.filterAffiliateSummarySuccess ? state.PartnersReducer.filterAffiliateSummarySuccess : false);


    // -------- Main List Data ------ //
    let listingData = useSelector((state) => state.PartnersReducer?.affiliateSummaryData ? state.PartnersReducer.affiliateSummaryData : [])
    // ---------------------------- //


    // -------- resolve ------ //
    const resolved = useSelector((state) => state.ResolveReducer.resolved["/affiliate-summary-report"] ? state.ResolveReducer.resolved["/affiliate-summary-report"] : false);
    //  --------------------- //



    // ----------- Loader ------------ //
    let loader = useSelector((state) => (state.PartnersReducer?.loading) ? state.PartnersReducer.loading : false)
    let loading = useSelector((state) => (state.AffiliateReducer?.loading) ? state.AffiliateReducer.loading : false)
    //  --------------------------- //


    // ----------- transactionReportBtn ------------ //
    const transactionReportBtn = useSelector((state) => (state.tableSlice.customButtonClicked.affiliate_commission_summary && state.tableSlice.customButtonClicked.affiliate_commission_summary.transactional_report) ? state.tableSlice.customButtonClicked.affiliate_commission_summary.transactional_report : false)
    // ------------------------------------------ //






    // ----- On DataRef on every btn trigger ---- //
    const dataRef = useSelector((state) =>
        state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.affiliate_commission_summary
            ? state.tableSlice?.tempRowData?.affiliate_commission_summary
            : {}
    );
    // ----------------------------------------- //





    const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.affiliate_summary_report_Search ? state.formSlice.formSubmissionState.affiliate_summary_report_Search : false);

    const partnerType = useSelector((state) => state.formSlice?.formData?.affiliate_summary_report_Search?.partnerType ? state.formSlice.formData.affiliate_summary_report_Search.partnerType : null);


    const plan_type = useSelector((state) => state.formSlice?.formData?.affiliate_summary_report_Search?.plan_type ? state.formSlice.formData.affiliate_summary_report_Search.plan_type : null);


    const username = useSelector((state) => state.formSlice?.formData?.affiliate_summary_report_Search?.username ? state.formSlice.formData.affiliate_summary_report_Search.username : null);


    const start_date = useSelector((state) => state.formSlice?.formData?.affiliate_summary_report_Search?.start_date ? state.formSlice.formData.affiliate_summary_report_Search.start_date : null);


    const end_date = useSelector((state) => state.formSlice?.formData?.affiliate_summary_report_Search?.end_date ? state.formSlice.formData.affiliate_summary_report_Search.end_date : null);

    const affiliate_name_search = useSelector((state) => state.formSlice?.formData?.affiliate_summary_report_Search?.name ? state.formSlice.formData.affiliate_summary_report_Search.name : null);

    const dataFetched = useSelector((state) => state.PartnersReducer?.dataFetched ? state.PartnersReducer.dataFetched : false);

    const csvSentSuccess = useSelector((state) => state.AffiliateReducer?.csvSent ? state.AffiliateReducer.csvSent : false);








    // ================ setting time stamps ====================//
    const todaysStartDate = moment().startOf('day').valueOf()
    const todaysEndDate = moment().endOf('day').valueOf()


    const weekStartDate = moment().startOf('week').valueOf()
    const weekEndDate = moment().endOf('week').valueOf()


    const monthStartDate = moment().startOf('month').valueOf()
    const monthEndDate = moment().endOf('month').valueOf()
    // ======================================================== //








    // ------- entrypoint triggrer ---- //
    useEffect(() => {
        setSnackOpen(false)

        if (!resolved) {

            // dispatch(aeCommissionSummary(reqBody))
            setTimeout(() => {
                searchTable('all')
            }, 100);
        }


        return () => {
            store.dispatch(clearPartnerReducerReportData());
            setSnackOpen(false)

        };
    }, []);
    //  ------------------------ //



    //   -------- transactionReportBtn trigger ------ // 
    useEffect(() => {
        if (transactionReportBtn) {
            // resolvefunction(navigate, "/partners-transactional-report");
            window.open("/commission-transactional-report/" + dataRef._id, "_blank")
        }
    }, [transactionReportBtn])
    //   ------------------------------------------ //







    // --------- Table list for affiliateCommissionSummaryList --------- //
    var modifyTableHeaders =
        [
            { val: "affiliate_name", name: "Affiliate Name" },
            { val: "totalCommisionAmount", name: "Total Commission Amt ($)" },
        ];

    let affiliateCommissionSummaryList = useRef({
        tableId: "affiliate_commission_summary",
        tableTitle: ` Affiliate Commission Summary`,
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            // endPoint: "commission/rep-commission-summary-list",
            // deleteSingleUserEndpoint: "role/delete-roles",
            // tableCountEndpoint: "commission/rep-commission-summary-list",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "role_list",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [



        ],
        buttons: [
            // {
            //     id: "transactional_report",
            //     name: "summarize_icon",
            //     type: "other",
            //     label: "Transactional Report",
            // },

            // {
            //     id: "affiliate_summary_report",
            //     name: "wysiwyg_icon",
            //     type: "other",
            //     label: "Affiliate Transactional Report",
            // },


        ],

        searchData: {
            heading: "Search Role",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [

                    {
                        id: 8,
                        label: "Search By Creation start Time",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search By Creation end Time",
                        name: "end_date",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                    {
                        id: 8,
                        label: "Search By Updated on start Time",
                        name: "start_date_by_update",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "updatedon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search By Updated on end Time",
                        name: "end_date_by_update",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "updatedon_datetime", param: "$lte" }
                    },

                ]
            }
        },
    });


    //  ----------------------------- //













    // ==== setTableFilterData === //
    useEffect(() => {

        if (dataFetched && filterData && commisionReportSuccess && filteredReportData.length > 0) {
            setListData(filteredReportData)
            setListDataInsert(true)
        }

    }, [JSON.stringify(filteredReportData), dataFetched, filterData, commisionReportSuccess])
    //  ================== //




    // ==== setAllTableData === //
    useEffect(() => {

        if (dataFetched && listingData.length > 0 && !filterData) {
            setListData(listingData)
            setListDataInsert(true)
        }

    }, [JSON.stringify(listingData), dataFetched, filterData])
    //  ================== //





    useEffect(() => {

        if (dataFetched && listData) {
            dispatch(setTableData({ "tableId": "affiliate_commission_summary", "dataset": listData }))
            setListDataInsert(true)
        }

    }, [JSON.stringify(listData), dataFetched])








    // ============  on table filters click =========== //
    function searchTable(val) {
        setToogleVal(val);

        switch (val) {

            case 'all':
                dispatch(unmountTable({ "tableId": "affiliate_commission_summary" }));
                setFilterData(false)
                setAmtToogleFlag(false)
                setListData([])
                setListDataInsert(false)

                let payloadAll = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {

                    },
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"

                }


                affiliateCommissionSummaryList.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "affiliate_commission_summary", "reqBody": payloadAll }))


                dispatch(affiliateSummary(payloadAll))

                setTimeout(() => {
                    payloadAll['count'] = true
                    dispatch(affiliateFilterSummaryCount(payloadAll))
                }, 200);

                break;

            case 'this_month':
                dispatch(unmountTable({ "tableId": "affiliate_commission_summary" }));

                setFilterData(true)
                setListData([])
                setListDataInsert(false)
                setAmtToogleFlag(true)

                let payloadMonth = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {},
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"
                }

                payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }


                dispatch(setTableDefaultreqBody({ "tableId": "affiliate_commission_summary", "reqBody": payloadMonth }))

                affiliateCommissionSummaryList.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

                dispatch(affiliateFilterSummary(payloadMonth))


                setTimeout(() => {
                    payloadMonth['count'] = true
                    dispatch(affiliateFilterSummaryCount(payloadMonth))
                }, 200);


                break;

            case 'this_week':
                dispatch(unmountTable({ "tableId": "affiliate_commission_summary" }));

                setFilterData(true)
                setAmtToogleFlag(true)
                setListData([])
                setListDataInsert(false)


                let payloadWeek = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {},
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"

                }

                payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }


                affiliateCommissionSummaryList.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "affiliate_commission_summary", "reqBody": payloadWeek }))

                dispatch(affiliateFilterSummary(payloadWeek))

                dispatch(clearPageState({ "tableId": "affiliate_commission_summary" }))

                // dispatch(clearTableCounts({ "tableId": "affiliate_commission_summary" }))

                setTimeout(() => {
                    payloadWeek['count'] = true
                    dispatch(affiliateFilterSummaryCount(payloadWeek))
                }, 200);


                break;

            case 'today':
                dispatch(unmountTable({ "tableId": "affiliate_commission_summary" }));
                setFilterData(true)
                setAmtToogleFlag(true)
                setListData([])
                setListDataInsert(false)


                let payloadToday = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {},
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"

                }


                payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }

                affiliateCommissionSummaryList.current['reqBody']['searchcondition'] = payloadToday['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "affiliate_commission_summary", "reqBody": payloadToday }))


                dispatch(affiliateFilterSummary(payloadToday))


                setTimeout(() => {
                    payloadToday['count'] = true
                    dispatch(affiliateFilterSummaryCount(payloadToday))
                }, 200);



                break;

            default:
            // code block
        }
    }
    //  ================================== //









    // ==== setTableCount === //
    useEffect(() => {
        if (filteredReportCount !== null) {
            dispatch(clearPageState({ "tableId": "affiliate_commission_summary" }))
            dispatch(setTableCounts({ "tableId": "affiliate_commission_summary", "count": filteredReportCount }))
        }
    }, [JSON.stringify(filteredReportCount)])
    // ===================== //








    //----------------------search form configration---------------------
    const dataform = useRef({
        id: 'affiliate_summary_report_Search',
        submitBtnName: "Search",
        submitBtnEndIcon: 'search_icon',
        resetForm: true,
        resetBtnName: "Refresh",
        resetBtnEndIcon: 'refresh',
        endPointBodyWrapper: "searchcondition",
        formButtonClass: "submitbtnsection",
        formWrapperClass: "formWrapperClass formCls",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {},

        fields: [

            {
                id: 0,
                label: "Search by Affiliate Name",
                name: "name",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
                reqBody: {
                    "condition": {
                        "limit": 10,
                        "skip": 0
                    },
                    "sort": {
                        "type": "asc",
                        "field": "name"
                    },
                    "searchcondition": {
                        "added_by": paramData?.id,
                        "roles.is_affiliate": 1,
                        "label": {
                            "$regex": "",
                            "$options": "i"
                        }
                    },
                    "type": "user_name",
                    "source": "users"
                },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "_id" },

            },

            {
                id: 31,
                label: "Search by Recorded on Start Date",
                name: "start_date",
                className: "datePicker",
                type: "datePicker",
                sx: { m: 1, width: 300 },
                className: "list_search_item countDiv__column--col6 ",
                payloadFormat: { key: "record_date", param: "$gte" },
            },
            {
                id: 4,
                label: "Search by Recorded on End Date",
                name: "end_date",
                className: "list_search_item countDiv__column--col6 ",
                type: "datePicker",
                fullDay: true,
                sx: { m: 1, width: 300 },
                payloadFormat: { key: "record_date", param: "$lte" },
                // minDate:{minDate}
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "End Date can not be less than Start Date"
                },
            },


        ],
    })

    // ================================================== //

    // ========= endpoint call for search after search drawwer ===== //
    useEffect(() => {
        if (formSubmissionState === 4) {
            setFilterData(true);
            setToogleVal(null);
            setAmtToogleFlag(false)
            setListDataInsert(false)
            setListData([])
            searchTable('search')


            const payload = {
                "condition": { "limit": 30, "skip": 0 },
                "sort": {
                    "field": "record_date",
                    "type": "desc"
                },
                "searchcondition": {

                },
                "project": {},
                "token": "",
                "count": false,
                "rep_id": paramData.id,
                "role": "is_affiliate"

            }

            if (start_date !== null || end_date !== null) {
                payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

            }

            if (affiliate_name_search !== null) {
                delete payload['searchcondition']['user_id']
                payload['searchcondition']['user_id'] = affiliate_name_search
            }


            affiliateCommissionSummaryList.current['reqBody']['searchcondition'] = payload['searchcondition']


            dispatch(affiliateFilterSummary(payload))


            setTimeout(() => {
                payload['count'] = true
                dispatch(affiliateFilterSummaryCount(payload))
            }, 200);

            setSearchDrawer(false)
        }
    }, [formSubmissionState])
    // ================================================================= //













    // =============== exportCSV ============= //
    function exportCSVthroughmail() {

        let payload = {}


        switch (toogleVal) {


            case 'all':

                payload = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {

                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "affiliate_commission_summary",
                    "export_type": "affiliate_summary",
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"

                }


                dispatch(summaryexportCSV(payload));
                break;


            case 'this_month':

                payload = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {
                        'record_date': { $gte: monthStartDate, $lte: monthEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "affiliate_commission_summary",
                    "export_type": "affiliate_summary",
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"
                }

                dispatch(summaryexportCSV(payload));

                break;

            case 'this_week':

                payload = {
                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },
                    "searchcondition": {
                        "record_date": { $gte: weekStartDate, $lte: weekEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "affiliate_commission_summary",
                    "export_type": "affiliate_summary",
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"
                }

                dispatch(summaryexportCSV(payload));

                break;

            case 'today':

                payload = {
                    "sort": { "type": "desc", "field": "record_date" },
                    "searchcondition": {

                        "record_date": { $gte: todaysStartDate, $lte: todaysEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "affiliate_commission_summary",
                    "export_type": "affiliate_summary",
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"
                }

                dispatch(summaryexportCSV(payload));
                break;

            case 'search':


                payload = {
                    "sort": { "type": "desc", "field": "record_date" },
                    "searchcondition": {

                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "affiliate_commission_summary",
                    "export_type": "affiliate_summary",
                    "rep_id": paramData?.id,
                    "role": "is_affiliate"
                }

                if (start_date !== null || end_date !== null) {
                    payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

                }

                if (affiliate_name_search !== null) {
                    delete payload['searchcondition']['user_id']
                    payload['searchcondition']['user_id'] = affiliate_name_search
                }

                dispatch(summaryexportCSV(payload));

                break;

            default:
                break;
        }

    }
    //  ================================== //








    // ===== open snackbar if csv export sucessfull ==== //
    useEffect(() => {
        if (csvSentSuccess) {
            setSnackOpen(true)
        }
    }, [csvSentSuccess])

    //  ================================================ //

    // ======== snackbar close ======= //
    function handleClose() {
        setSnackOpen(false)

    }
    // ========================= //




    return (

        <div className='rolelist_mainwrp'>

            {(loader === true || listDataInsert === false || loading) && <LinearProgress />}

            <div className='listing_heading'>
                <h1> Traffic Partner Commission Summary  </h1>
            </div>



            <div className='listNavigate affiliate-reportlist-navigation'>
                <ul className='toogleBtns'>
                    <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
                    <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Commission </Button>  </li>

                    <li> <Button variant="contained" onClick={() => {
                        dispatch(unmountTable({ "tableId": "affiliate_commission_summary" }));
                        searchTable('all')
                    }}>
                        <Tooltip title="Reload" placement="bottom" className=''>
                            <ReplayIcon />
                        </Tooltip>
                    </Button>
                    </li>

                    {(listData?.length > 0) && <li>
                        <Button variant="contained" onClick={() => exportCSVthroughmail()} >
                            <Tooltip title="Export CSV" placement="bottom" className=''>
                                <IosShareIcon />
                            </Tooltip>
                        </Button>
                    </li>}


                </ul>
            </div>



            <div className='rolelist_mainwrp_Table'>

                {!loader && listDataInsert ? (
                    <ListingTable

                        tableData={affiliateCommissionSummaryList.current}
                        dataset={listData}

                        modifyHeaders={modifyTableHeaders}
                    />
                ) : (<TableSkeleton count={5} />)}

            </div>






            {/* ============ search drawer ======== */}
            <Drawer
                anchor="bottom"
                open={searchDrawer}
                onClose={() => setSearchDrawer(false)}
            >
                <Paper elevation={7} className='list_search team_management_list_search search_img'>

                    <div className="searchBarHead DrawerBox">
                        <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Traffic Partner Commission Summary</h2>
                        <span className="searchBarCloseIcon">
                            <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
                        </span>
                    </div>

                    <Form formData={dataform.current} />
                </Paper>
            </Drawer>
            {/* =================================== */}



            {
                snackOpen && (
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message="CSV Data Sent Successfully. Please Check Your Mail"
                    />
                )
            }



        </div>
    )
}

export default AffiliateSummaryReport
