
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, LinearProgress, List, Tooltip } from '@mui/material';

import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";

const ConfirmationModal = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // ------ Loading during any action -----
    const loader = useSelector(state => (state.userManagment?.loading
    ) ? state.userManagment.loading
        : false);
  const comloading = useSelector((state) => state.commissionReducer?.loading ? state.commissionReducer.loading : false)


    // ---- UseSelectors ----
    // const users = useSelector((state) => state.userManagment.usersList)
    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)


    // console.warn("<<<<< props from confirmation modal compo >>>>", props)

    useEffect(() => {
        props.setModalResponse(false);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModelDelete(true)

        props.setModalResponse(false);
        props.tooglefunc(false);


    }

    // ------- onModalConfirmation ------
    function ConfirmExe() {
        props.setModalResponse(true);
        // props.tooglefunc(false);

    }


    return (
        <>

            {/* ------ Confirmation Modal ------ */}

            <Modal
                className="modalblock "
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="BoxMui_modal" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    // width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    // p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h3" sx={{p:10}}>
                        <p className='alertPara'> Alert</p>
                        {props.confrimationMsg}
                    </Typography>
                    <Button variant="contained" onClick={ConfirmExe} className="modalBTN">Confirm</Button>
                    <Button variant="contained" onClick={ModalClose} className="modalBTN">Cancel</Button>

                    {loader || comloading ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

                    <Button onClick={ModalClose} className="closeModalBtn">

                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>

                    </Button>
                </Box>
            </Modal>

        </>
    )
}


export default ConfirmationModal;