import { IconButton, LinearProgress, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "../../../form/Form";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../store";
import ListingTable from "../../../listing/listing";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import { setReloadTableFlag } from "../../../listing/listReducer";
import { clearFoloUPCallData, getDatafollowUpCallList } from "./LeadReducer";
import { useCookies } from "react-cookie";
import {
  resetForm,
  resetFormData,
  resetFormResetFlag,
  setFieldExtraData,
} from "../../../form/formReducer";
import moment from "moment";

const FollowUpcall = ({
  open,
  close,
  addedForId,
  addedById,
  // listData,
  addedForNmae,
  source,
  loading,
  listFlag,
  followupGridData,
  typeBtn,
  collectionName
}) => {
  console.log(" lead followupGridData props lucky",  addedForId);
  // console.log(" lead followupGridData props----", typeBtn);

  const [listData, setsListData] = useState([]);
  const [rowData, setRowData] = useState({});
  const [formReload, setFormReload] = useState(false);
  const [hearder, setHeader] = useState("Add a Follow Up call");
  const [btnTrigger, setBtnTrigger] = useState("");
  const [autId,setAutId]=useState()

  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
  let userID = useSelector((state) =>
    state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null
  );

  //-------------------------------button click on Add new follow------------------------------------
  let rescheduleClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.followUpCallTable?.reschedule_icon
      ? state.tableSlice.customButtonClicked.followUpCallTable.reschedule_icon
      : false
  ); // ------ ShowStageListBtn ------
  let addFollowupClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.followUpCallTable?.followup_icon
      ? state.tableSlice.customButtonClicked.followUpCallTable.followup_icon
      : false
  ); // ------ ShowStageListBtn ------

  const followUpRowData = useSelector((state) =>
    state.tableSlice?.tempRowData?.followUpCallTable
      ? state.tableSlice.tempRowData.followUpCallTable
      : {}
  ); // ------ ShowStageListBtn ------

  //-------------------------------------------------------------------------------------------------

  const followUpCallList = useSelector((state) =>
    state.LeadReducer?.followUpCallData && state.LeadReducer?.followUpCallData
      ? state.LeadReducer.followUpCallData
      : []
  ); //<<<<<<<<<<<<--------------data for follow up call

  const formSubmissionState = useSelector((state) =>
    state.formSlice?.formSubmissionState?.followUpcallForm
      ? state.formSlice?.formSubmissionState?.followUpcallForm
      : null
  );

  const dispatch = useDispatch();

  const searchData = useSelector((state) =>
    state?.formSlice?.formFieldsData?.searchForm
      ? state.formSlice.formFieldsData.searchForm
      : {}
  );
  const FormData = useSelector((state) =>
    state.formSlice?.formData?.followUpcallForm
      ? state.formSlice.formData.followUpcallForm
      : {}
  );

  const searchSubmission = useSelector((state) =>
  state.formSlice?.formData?.searchForm
    ? state.formSlice.formData.searchForm
    : {}
);

console.log("searchSubmission",searchSubmission);

//--------------------when user user search then this fields add--------------------------
useEffect(()=>{
if(searchSubmission?.added_by){
  tabledataForFollowUpCall.current.reqBody.searchcondition["added_by"]=searchSubmission.added_by

}
},[searchSubmission])

useEffect(()=>{
  
  tabledataForFollowUpCall.current.searchData.formData.fields[0].reqBody.searchcondition["added_for"]=addedForId
    // console.log("this is follow search",tabledataForFollowUpCall.current.searchData.formData.fields[0].reqBody.searchcondition["added_for"]=addedForId);
  
  
  },[])

// useEffect(()=>{

//     tabledataForFollowUpCall.current.reqBody.searchcondition["added_"]=searchSubmission.added_by
  
  
//   },[])
//====================================================================================



  

  const maxDateForDateRangePicker = new Date();
  console.log("maxDateForDateRangePicker", maxDateForDateRangePicker);

 

  useEffect(() => {
    if (addedForId !== undefined && addedForId !== null) {
      dataform.current.formAdditionalSubmissionData.added_for = addedForId;
    }
  }, [addedForId]);

 




  useEffect(() => {
    if (followupGridData && Object.keys(followupGridData).length > 0) {
      setRowData(followupGridData);
    }
  }, [followupGridData]);

  // console.warn("rowData=======>>>>>>", rowData)

  // useEffect(() => {
  //   if (Object.keys(rowData).length > 0) {
  //     setTimeout(() => {
  //       setFormReload(false)

  //     }, 3000)
  //   }
  // }, [rowData])

  //-----------------btn Click configration for Reschdule---------------------
  useEffect(() => {
    if (rescheduleClick === true || typeBtn === "reschudle") {
      // dataform.current.scrollIntoView({behavior: 'smooth'});
      setBtnTrigger("reschudle");
      setHeader("Reschedule a Follow Up Call");
      setFormReload(true);
      if (followUpRowData && Object.keys(followUpRowData).length > 0) {
        setRowData(followUpRowData);
      } else if (followupGridData && Object.keys(followupGridData).length > 0) {
        setRowData(followupGridData);
      }
    }
  }, [rescheduleClick]);
  //============================================================================

  //-------------------btn Click configration for Add new Follow Call----------------

  useEffect(() => {
    if (addFollowupClick === true || typeBtn === "addfollowup") {
      setBtnTrigger("addfollowup");
      setHeader("Add a New Follow Up Call");

      // setTimeout(() => {
      //   store.dispatch(resetForm({ formId: "followUpcallForm" }));

      // }, 1000);

      setFormReload(true);
      if (followUpRowData && Object.keys(followUpRowData).length > 0) {
        setRowData(followUpRowData);
      } else if (followupGridData && Object.keys(followupGridData).length > 0) {
        setRowData(followupGridData);
      }
    }
  }, [addFollowupClick]);

  ///================================================================================

  //---------------------rescheduleClick configration---------------------------

  useEffect(() => {
    if (rowData && Object.keys(rowData)?.length > 0) {
      if (rowData?._id && btnTrigger === "reschudle") {
        dataform.current.formAdditionalSubmissionData.prequest_id = rowData._id;
        dataform.current.formAdditionalSubmissionData.reschedule = true;
      }
      if (rowData?._id && btnTrigger === "addfollowup") {
        dataform.current.formAdditionalSubmissionData.prequest_id = rowData._id;
      }

      dataform.current?.fields?.forEach((data) => {
        console.log("rowData..data=====================", rowData);

        // console.log("followup..data=====================", data);
        data.defaultValue =
          data?.name === "start_time"
            ? rowData["start_time_format_unix_timestamp"]
            : rowData[data?.name]
            ? rowData[data.name]
            : "";
        console.log("rowData===================", rowData[data.name]);

        setFormReload(false);
      });
    }
  }, [JSON.stringify(rowData)]);

  //---------------------------------------------------------------------------

  // //--------------------fetch follow up row data--------------------------------
  // useEffect(() => {
  //   if (Object.keys(followUpRowData).length > 0) {
  //     setRowData(followUpRowData)
  //   }
  // }, [followUpRowData])
  // //----------------------------------------------------------------------------

  console.log("followUpRowData", rowData);

  useEffect(() => {
    tabledataForFollowUpCall.current.tableTitle = `FOLLOW UP CALL LIST FOR : ${addedForNmae}`;
  }, [addedForNmae]);

  useEffect(() => {
    if (addedForId !== undefined && addedForId !== null && collectionName==="lead") {
           tabledataForFollowUpCall.current.reqBody.searchcondition.added_for = addedForId ;
    }

    
   
  }, [addedForId]);

  useEffect(() => {
    store.dispatch(
      setFieldExtraData({
        formId: "followUpcallForm",
        fieldName: "date",
        data: { minTime: maxDateForDateRangePicker },
      })
    );
 
  }, []);


  useEffect(() => {
    if (formSubmissionState === 2) {

      let reqbody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {
          added_for: addedForId,
        },
        // "project": {},
        // "token": "",
        count: false,
        source: "",
      };
      dispatch(getDatafollowUpCallList(reqbody));



      setBtnTrigger("");
      setHeader("Add a Follow Up Call");
      setRowData({});

      delete dataform.current.formAdditionalSubmissionData["prequest_id"];
      delete dataform.current.formAdditionalSubmissionData["reschedule"];
    }

    // if(followUpCallList.length > 0 && formSubmissionState === 2)
    // {

    //   let reqbody = {
    //     condition: {
    //       limit: 5,
    //       skip: 0,
    //     },
    //     sort: {
    //       field: "_id",
    //       type: "desc",
    //     },
    //     searchcondition: {
    //       added_for: addedForId,
    //     },
    //     // "project": {},
    //     // "token": "",
    //     count: false,
    //     source: "",
    //   };

    //   dispatch(getDatafollowUpCallList(reqbody));

    //   // dispatch(setReloadTableFlag({ tableId: "followUpCallTable" }))
    // }else if((followUpCallList.length === 0) && (formSubmissionState === 2)){
    //   let reqbody = {
    //     condition: {
    //       limit: 5,
    //       skip: 0,
    //     },
    //     sort: {
    //       field: "_id",
    //       type: "desc",
    //     },
    //     searchcondition: {
    //       added_for: addedForId,
    //     },
    //     // "project": {},
    //     // "token": "",
    //     count: false,
    //     source: "",
    //   };

    //   dispatch(getDatafollowUpCallList(reqbody));
    // // }
    // }
  }, [formSubmissionState]);
  // -------------------------------------------------






  

  // ------- Followup listing here --------
  var modifyTableHeaders = [
    { val: "notes", name: "Notes" },
    { val: "added_by_name", name: "Added By" },
    { val: "date", name: "Date", type: "datetime", format: "MM/DD/yy" },
    { val: "start_time", name: "Time" },
  ];

  let tabledataForFollowUpCall = useRef({
    tableId: "followUpCallTable",
    tableTitle: "",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/follow-up-call-list-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/follow-up-call-list-count",
    },

    reqBody: {
      condition: {
        limit: 3,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        added_for: addedForId,
      },
     
      
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [3],
    sortFields: ["notes", "added_by_name", "date"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      // {
      //   id: "delete",
      //   label: "Delete",
      //   type: "api",
      //   category: "modal",
      //   icon_type: "delete",
      //   className: "delete_data",
      //   modalClassName: "delete_modal",
      //   modalTitle:
      //     "<p>Are you sure that you want to delete this record(s)?</p>",
      //   modalClassName: "delete_modal",
      //   modalHeader: "<p> Alert !!</p>",
      //   btn_label: ["Yes", "No"],
      //   api_data: {
      //     base_url: process.env.REACT_APP_API_URL,
      //     endpoint: "",
      //     body: {
      //       source: "users",
      //       secret: "AZ|lepL`",
      //       token:
      //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
      //     },
      //   },
      // },
      {
        id: "reschedule_icon",
        label: "Reschedule",
        type: "other",
        name: "event_repeatIcon_icon",
        title: "Reschedule",
        condField: "added_by",
        condVal: addedById,
      },

      {
        id: "followup_icon",
        label: "Follow Up",
        type: "other",
        name: "add_box_icon_icon",
        title: "FOLLOW UP",
        condField: "added_by",
        condVal: addedById,
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Follow Up Call Search",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Added By",
            name: "added_by",
            className: "inputBlock inputBlock3line",
            type: "autoComplete",
            sx: { m: 1, width: 600 },
            base_url: process.env.REACT_APP_API_URL + "users/follow-up-call-by",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "_id",
                type: "desc",
              },
              searchcondition: {
                // added_for:addedForId
              },
            },
          },
          {
            id: 8,
            label: "Search by  Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "date", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by  End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "date", param: "$lte" },
          },
        ],
      },
    },
  });
  // ------------------------------------------
  //-----------------Add buuton in table conditionaly-----------------
  // useEffect(()=>{
  //   console.warn("button",tabledataForFollowUpCall);
  //   if(followUpCallList && userID===followUpCallList.added_by){
  //     tabledataForFollowUpCall.current.buttons.splice(0,0,{
  //       id: "reschedule_icon",
  //       label: "Reschedule",
  //       type: "other",
  //       name: "event_repeatIcon_icon",
  //       title: "Reschedule",
  //     },)
  //   }
  // },[followUpCallList])
  //---------------------------------------------------------

  // ---- Followup Form for add followUp Calls ----------

  const dataform = useRef({
    id: "followUpcallForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "users/follow-up-call-add",
    // urlPathOnSuccessfulFormSubmission: "/contract-list",
    submitBtnName: "Submit",
    // cancelBtnName: "Cancel",
    className: "inputBlock inputBlock3line",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
      added_by:addedById !== undefined && addedById !== null ? addedById : undefined,
      collection_name:collectionName==="lead"?"google_events":"users",
      timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
    },

    fields: [
      {
        id: 8,
        heading: "Date ",
        label: "Plese select date here ",
        name: "date",
        className: "inputBlock inputBlock3line",
        type: "datePicker",
        // fullDay: true,
        minDate: { maxDateForDateRangePicker },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        sx: { m: 1, width: 300 },
        dateFormat: "MM/DD/YYYY", //////////////////////////// MM should be capital //////////////////
        defaultValue:
          typeBtn == "reschudle"
            ? followupGridData.date
            : rowData &&
              Object.keys(rowData)?.length > 0 &&
              rowData.date !== undefined
            ? rowData.date
            : undefined,
      },

      {
        id: 10,
        heading: " Time ",
        label: "Plese select time here",
        name: "start_time",
        // minTime:{maxDateForDateRangePicker},
        className: "inputBlock inputBlock3line",
        type: "timePicker",
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        sx: { m: 1, width: 300 },
        defaultValue:
          typeBtn == "reschudle"
            ? followupGridData.start_time_format_unix_timestamp
            : rowData &&
              Object.keys(rowData).length > 0 &&
              rowData.start_time_format_unix_timestamp !== undefined
            ? rowData.start_time_format_unix_timestamp
            : undefined,
      },

      {
        id: 3,
        heading: "Notes",
        name: "notes",
        placeholder: "Please type here...",
        className: "inputBlock inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: (rowData && Object.keys(rowData).length > 0 && rowData.notes !== undefined) ? rowData.notes : undefined,
        defaultValue:
          typeBtn == "reschudle"
            ? followupGridData.notes
            : rowData &&
              Object.keys(rowData).length > 0 &&
              rowData.notes !== undefined
            ? rowData.notes
            : undefined,
      },
    ],
    customButtons: [],
  });
  // --------------------------------------------

  // console.log("newTime", new Date((1679941800000).toString()).getTime());
  // console.log("newTime1", new Date("1679941800000").getTime());

  // useEffect(() => {
  //   console.log("rowData", rowData);
  // }, [rowData])

  return (
    <>
      <div>
        <Modal
          // style={{zIndex:"0",position:"absolute"}}
          className="image_card_modal modalBaseStyle indexclass"
          open={open}
          onClose={close}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal followup_container">
            <IconButton className="modal_icon_button" onClick={close}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>

            <div className="followup_wrap">
              {followUpCallList.length > 0 && source !== "folderView" && (
                <div className="rolelist_mainwrp_Table">
                  <ListingTable
                    tableData={tabledataForFollowUpCall.current}
                    dataset={followUpCallList}
                    modifyHeaders={modifyTableHeaders}
                  />
                </div>
              )}

              <h1 className="page_heading">
                {hearder}{" "}
                {source && source == "folderView" && `for : ${addedForNmae}`}
              </h1>

              <div className="dataform2_wrapper">
                {loading == true && formReload ? <LinearProgress /> : ""}
                <div className="adminformbody">
                  {formReload === false && rowData?._id? (
                    <>
                      {" "}
                      <Form formData={dataform.current} />
                    </>
                  ) : (
                    <Form formData={dataform.current} />
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default FollowUpcall;
