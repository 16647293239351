import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



// ==================================add employer==================================

export const EmployerAddEdit = createAsyncThunk("employer",
    async (reqbody) => {
        // console.log("reqbody==>", reqbody);
        let req_body = {};
        req_body = { ...reqbody };
        // console.log("req_body==", req_body);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(req_body),
        };
        // console.log("process.env==>", process.env);
        const response = await fetch(
            process.env.REACT_APP_API_URL + "employer_manage/add_employers",
            requestOptions
        );
        const respdata = await response.json();
        // console.log("respdata after endpoint fetch==>", respdata);
        return respdata;
    }
);

// ==============================get Employer redireted======================================

export const getEmployerData = createAsyncThunk("getemployerdata", async (id) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { "id": id };
    // console.log("req_body==>>", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/getusers", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// =============================get landing page data=========================

export const getlandingpagedataEmployer = createAsyncThunk("getlandingpagedata", async (reqBody) => {
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/get-page-info", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// ==================================Assign Stage=====================================

export const updateStagesEmployer = createAsyncThunk("landing/updatestages", async (reqbody) => {
    // console.warn("<<<<<<<< payload from AssignStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assign-stage", requestOptions);
    const updateStages = await response.json();

    return updateStages;
});

// ====================================NDA Contract Employer=================================

export const contractEmployerFormdata = createAsyncThunk(
    "contractemployerFormdata",
    async (reqbody) => {
        // console.log("reqbody==>", reqbody);
        let req_body = {};
        req_body = { ...reqbody };
        // console.log("req_body==", req_body);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(req_body),
        };
        // console.log("process.env==>", process.env);
        const response = await fetch(
            process.env.REACT_APP_API_URL + "employer_manage/employersndaform",
            requestOptions
        );
        const respdata = await response.json();
        // console.log("respdata after endpoint fetch==>", respdata);
        return respdata;
    }
);


// ==============================Locations for Employee==================================

export const viewlocationsListData = createAsyncThunk(
    "locationlist",
    async (reqbody) => {
        // console.log("reqbody==>", reqbody);
        let req_body = {};
        req_body = { ...reqbody };
        // console.log("req_body==", req_body);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(req_body),
        };
        // console.log("process.env==>", process.env);
        const response = await fetch(
            process.env.REACT_APP_API_URL + "employer_manage/managelocationlist",
            requestOptions
        );
        const respdata = await response.json();
        // console.log("respdata after endpoint fetch==>", respdata);
        return respdata;
    }
);

// ==============================Add/edit-update Locations===========================

export const addUpdateLocation = createAsyncThunk("addeditlocation", async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/addmanagelocation",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}
);

// ===========================Location status change==============================

export const locationStatusUpdate = createAsyncThunk("locationStatuschange", async (reqbody) => {

    // console.warn("<<<<< locationStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/location_status_update", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ================================LOcation Delete Modal============================

export const deleteLocationList = createAsyncThunk("deletelocationdata", async (reqbody) => {

    // console.warn("<<<<< locationStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/deletelocation", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ===============================Employee Listing=================================

export const viewEmployeeList = createAsyncThunk("employeelist", async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/employees_list",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}
);

// ==============================Add/edit-update Employee===========================

export const addUpdateEmployee = createAsyncThunk("addeditemployee", async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/add_employees",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}
);

// ===========================Employee status change==============================

export const employeeStatusUpdate = createAsyncThunk("employeeStatuschange", async (reqbody) => {

    // console.warn("<<<<< employeeStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/employee_status_change", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ================================Employee Delete Modal============================

export const deleteEmployeeList = createAsyncThunk("deletelocationdata", async (reqbody) => {

    // console.warn("<<<<< locationStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/delete_employee", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ===============================Sub Employer Listing=================================

export const viewSubEmployersList = createAsyncThunk("subemployerlist", async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/sub_login_list",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}
);

// ==============================Add/edit-update Employee===========================

export const addUpdateSubEmployer = createAsyncThunk("addeditsubemployeer", async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/add_sub_employers",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}
);

// =================================total cost list===================================

export const employeePackageTotalCost = createAsyncThunk("totalcostdata", async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/package_cost_list",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}
);

// ================================add notes list for location=========================

export const addedNotesListLocation = createAsyncThunk("addednoteslistlocation", async (reqbody) => {
    // const cookies = new Cookies();
    // const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/location_notes_list",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// ================================add notes list for subemployer=========================

export const addedNotesListSubemployer = createAsyncThunk("addednoteslistsubemployer", async (reqbody) => {
    // const cookies = new Cookies();
    // const token = cookies.getAll().token;

    // if (reqbody == null && reqbody === undefined) {
    //   reqbody = {
    //     condition: {
    //       limit: 30,
    //       skip: 0,
    //     },
    //     sort: {
    //       field: "_id",
    //       type: "desc",
    //     },
    //     searchcondition: {},
    //     project: {},
    //     token: "",
    //     count: false,
    //   };
    // }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/subemployee_notes_list",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// ================================add notes list for subemployer=========================

export const addedNotesListEmployee = createAsyncThunk("addednoteslistemployee", async (reqbody) => {
    // const cookies = new Cookies();
    // const token = cookies.getAll().token;
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/employee_notes_list",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// ================================Employee Delete Modal============================

export const deleteSubEmployeeList = createAsyncThunk("deletesubemployeeList", async (reqbody) => {

    // console.warn("<<<<< locationStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/deleteSub_employers", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ===========================Sub Employer status change==============================

export const SubEmployerStatus = createAsyncThunk("subemployerstatusupdate", async (reqbody) => {

    // console.warn("<<<<< employeeStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/subemployers_status", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ================================employee dashboard package=========================

export const employeePackageData = createAsyncThunk("employeepackagedata", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_API_URL + "employer_manage/fetch_employee",
        requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// ===========================Employee package status change==============================

export const employeePackageStatus = createAsyncThunk("employeepackagestatus", async (reqbody) => {

    // console.warn("<<<<< employeeStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/employee_package_status", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});

// ================================Notes Delete Modal============================

export const deleteNotes = createAsyncThunk("deletenotes", async (reqbody) => {

    // console.warn("<<<<< locationStatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "employer_manage/deletenotes", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});




const EmployerReducer = createSlice({
    name: 'EmployerReducer',
    initialState: {
        submitting: 0,
        loading: false,
        success: false,
        error: false,
        snackbarData: null,
        contractFormSubmissiontateEmployer: 0,
        contractFormDataEmployer: {},
        policyFormSubmissiontate: 0,
        commisionReportData: [],
        commisionAmount: null,
        commisionReportSuccess: false,
        filterAffiliateDataSuccess: false,
        employerAddEditSuccess: false,
        employerData: [],
        addedinfo: [],
        landingpageoneuserdata: [],
        employerAddError: false,
        locationsListData: [],
        locationDetailsData: [],
        locationMessageSuccess: "",
        locationMessageError: "",
        locationStatusData: [],
        deleteLocationSuccess: {},
        addLocationError: null,
        locationAddLoader: false,
        employeeListData: [],
        deleteEmployeeSuccess: {},
        subEmployerListData: [],
        subEmployerIdData: [],
        subEmployerDataSuccess: "",
        employeeTotalPAckageCost: [],
        totalPackageListData: [],
        addlContactData: [],
        addnotesListData: [],
        loadingForNotes: false,
        addnotesEmployeeListData: [],
        addnotesLocationListData: [],
        statusloading: false,
        deleteLoader: false,
        successLocation: 0,
        addloadingemployee: false,
        subEmployerSubmission: 0,
        subloginSnackFire: "",
        subloginStatus: null,
        employeeSubmission: 0,
        employeeStatus: null,
        employeePackageAllData: {}
    },
    reducers: {

        clearData(state, action) {
            state.commisionReportData = [];
            state.filterAffiliateDataSuccess = false;

            // state.commisionAmount= null;

        },

        clearLocatioTable(state, action) {
            state.locationsListData = []
        },

        addlContactDataEach(state, action) {
            state.addlContactData = action.payload

            // console.log("text===================>>>>>>>>", action.payload)
        },
        deletecontactdata(state, action) {
            // state.addlContactData = action.payload
            if (state.addlContactData && state.addlContactData.length > 0 && action.payload) {
                let newdata = [...state.addlContactData]

                newdata.splice(newdata.indexOf(action.payload), 1);
                // console.log("newdata", newdata);
                state.addlContactData = newdata
            }

            // console.log("text===================>>>>>>>>", action.payload)
        },
        clearRespomseSubEmplyrEdit(state, action) {
            state.subEmployerSubmission = 0
        },
        clearRespomseEmplyeeEdit(state, action) {
            state.employeeSubmission = 0
        }


    },

    extraReducers: {





        [EmployerAddEdit.pending]: (state, action) => {
            state.loading = true;
            state.employerAddEditSuccess = false;
            state.employerAddError = false;
            state.snackbarData = null;
        },

        [EmployerAddEdit.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.employerAddEditSuccess = true;
                state.snackbarData = action?.payload?.message ? action.payload.message : null;


            } else if (action.payload.status === "Error") {
                state.loading = false;
                state.employerAddEditSuccess = false;
                state.employerAddError = true;
                state.snackbarData = action?.payload?.message ? action.payload.message : null;
            }
            state.loading = false;
        },
        [EmployerAddEdit.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.loading = true;
            state.employerAddEditSuccess = false;
            state.employerAddError = true;
            state.snackbarData = action?.payload;

        },



        [getEmployerData.pending]: (state, action) => {
            state.loading = true;
        },
        [getEmployerData.rejected]: (state, action) => {
            state.loading = false;
        },
        [getEmployerData.fulfilled]: (state, action) => {
            // console.log("action======>>>", state.employerData);
            state.loading = false;
            state.employerData = action.payload.results?.res[0];
        },


        [getlandingpagedataEmployer.rejected]: (state, action) => {
            state.loader = false
        },
        [getlandingpagedataEmployer.pending]: (state, action) => {
            state.loader = true
        },
        [getlandingpagedataEmployer.fulfilled]: (state, action) => {
            state.loader = false
            state.landingpageoneuserdata = action.payload.results;
        },



        [contractEmployerFormdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },
        [contractEmployerFormdata.pending]: (state, action) => {


            // console.log("getContractSlagData=========================>")
            state.loading = true;
            state.contractFormSubmissiontateEmployer = 1;

        },

        [contractEmployerFormdata.fulfilled]: (state, action) => {


            state.contractFormDataEmployer = action.payload.results;
            state.loading = false;
            state.contractFormSubmissiontateEmployer = 2;
            // console.log("state.contractFormDataEmployer======>>>>>321", state.contractFormDataEmployer);
        },



        [viewlocationsListData.rejected]: (state, action) => {
            state.loading = false
        },
        [viewlocationsListData.pending]: (state, action) => {
            state.loading = true
        },
        [viewlocationsListData.fulfilled]: (state, action) => {
            state.locationsListData = action.payload.results.res;
            state.loading = false
            // console.log("state.locationsListData", state.locationsListData)
        },


        [addUpdateLocation.rejected]: (state, action) => {
            state.locationAddLoader = false
            // if(action.payload.status === "Error"){
            // state.locationMessage = action?.payload?.message ;
            // console.log("error========>>> 1", state.locationMessage)

            // }
        },
        [addUpdateLocation.pending]: (state, action) => {
            state.locationAddLoader = true
            state.successLocation = 1
        },
        [addUpdateLocation.fulfilled]: (state, action) => {

            state.successLocation = 2
            state.addLocationError = action.payload.status
            state.locationMessageSuccess = action?.payload?.message;

            // setTimeout(() => {
            // state.successLocation = 0

            // }, 1000);


            state.locationAddLoader = false;
            // console.log("=======>>>>>>>>>>>>>>>", action.payload)


            // console.log("state.addLocationError===========>>>>", state.addLocationError);


        },

        // ****************Location Status Update******************************

        [locationStatusUpdate.rejected]: (state, action) => {
            state.statusloading = false
        },
        [locationStatusUpdate.pending]: (state, action) => {
            state.statusloading = true
        },
        [locationStatusUpdate.fulfilled]: (state, action) => {
            state.statusloading = false
            // state.locationStatusData = action.payload;
        },

        // *****************Location Delete*********************
        [deleteLocationList.rejected]: (state, action) => {
            state.deleteLoader = false
        },
        [deleteLocationList.pending]: (state, action) => {
            state.deleteLoader = true
        },
        [deleteLocationList.fulfilled]: (state, action) => {
            state.deleteLoader = false
            state.deleteLocationSuccess = action?.payload?.message;
            // console.log("state.deleteLocationSuccess====>>>>", state.deleteLocationSuccess);
        },

        //****************************Employee List LifeCycle***************** */

        [viewEmployeeList.rejected]: (state, action) => {
            state.loading = false
        },
        [viewEmployeeList.pending]: (state, action) => {
            state.loading = true
        },
        [viewEmployeeList.fulfilled]: (state, action) => {
            state.employeeListData = action.payload.results.res;
            state.employeeTotalPAckageCost = action.payload.results.total_cost[0];

            // console.log("logs======>>>>", state.employeeTotalPAckageCost)

            state.loading = false
        },

        // ****************Employee Status Update******************************

        [employeeStatusUpdate.rejected]: (state, action) => {
            state.statusloading = false
        },
        [employeeStatusUpdate.pending]: (state, action) => {
            state.statusloading = true
        },
        [employeeStatusUpdate.fulfilled]: (state, action) => {
            state.statusloading = false
            // state.locationStatusData = action.payload;
        },

        // *****************Employee Delete*********************
        [deleteEmployeeList.rejected]: (state, action) => {
            state.statusloading = false
        },
        [deleteEmployeeList.pending]: (state, action) => {
            state.statusloading = true
        },
        [deleteEmployeeList.fulfilled]: (state, action) => {
            state.statusloading = false
            state.deleteLocationSuccess = action?.payload?.message;
            // console.log("state.deleteLocationSuccess====>>>>", state.deleteLocationSuccess);
        },

        //****************************Sub Employer List LifeCycle***************** */

        [viewSubEmployersList.rejected]: (state, action) => {
            state.loading = false
        },
        [viewSubEmployersList.pending]: (state, action) => {
            state.loading = true
        },
        [viewSubEmployersList.fulfilled]: (state, action) => {
            state.subEmployerListData = action.payload.results.res;
            state.subEmployerDataSuccess = action?.payload?.message;
            state.subEmployerIdData = action.payload.results.res.map(x => x._id)

            // console.log("state.subEmployerListData=========>>>>>", state.subEmployerIdData);
            state.loading = false
        },

        //****************************Sub Employer List LifeCycle***************** */

        [employeePackageTotalCost.rejected]: (state, action) => {
            state.loading = false
        },
        [employeePackageTotalCost.pending]: (state, action) => {
            state.loading = true
        },
        [employeePackageTotalCost.fulfilled]: (state, action) => {
            state.totalPackageListData = action.payload.results.res;
            // console.log("Package========>>>>", state.totalPackageListData)
            state.loading = false
        },

        // ************************location notes lsit life cycle*********************

        [addedNotesListLocation.rejected]: (state, action) => {
            state.loadingForNotes = false
        },
        [addedNotesListLocation.pending]: (state, action) => {
            state.loadingForNotes = true
        },
        [addedNotesListLocation.fulfilled]: (state, action) => {
            state.addnotesLocationListData = action.payload.results.res;
            // console.log("Package========>>>>", state.addnotesLocationListData)
            state.loadingForNotes = false
        },

        // ************************Subemployer notes lsit life cycle*********************

        [addedNotesListSubemployer.rejected]: (state, action) => {
            state.loadingForNotes = false
        },
        [addedNotesListSubemployer.pending]: (state, action) => {
            state.loadingForNotes = true
        },
        [addedNotesListSubemployer.fulfilled]: (state, action) => {
            state.addnotesListData = action.payload.results.res;
            // console.log("Package========>>>>", state.addnotesListData)
            state.loadingForNotes = false
        },

        // ************************employee notes lsit life cycle*********************

        [addedNotesListEmployee.rejected]: (state, action) => {
            state.loadingForNotes = false
        },
        [addedNotesListEmployee.pending]: (state, action) => {
            state.loadingForNotes = true
        },
        [addedNotesListEmployee.fulfilled]: (state, action) => {
            state.addnotesEmployeeListData = action.payload.results.res;
            // console.log("Package========>>>>", state.addnotesEmployeeListData)
            state.loadingForNotes = false
        },

        // ***********************delete subemployee lifecycle*****************
        [deleteSubEmployeeList.rejected]: (state, action) => {
            state.statusloading = false
        },
        [deleteSubEmployeeList.pending]: (state, action) => {
            state.statusloading = true
        },
        [deleteSubEmployeeList.fulfilled]: (state, action) => {
            state.statusloading = false
            state.deleteLocationSuccess = action?.payload?.message;
            // console.log("state.deleteLocationSuccess====>>>>", state.deleteLocationSuccess);
        },

        // ****************Sub Employer Status Update******************************

        [SubEmployerStatus.rejected]: (state, action) => {
            state.statusloading = false
        },
        [SubEmployerStatus.pending]: (state, action) => {
            state.statusloading = true
        },
        [SubEmployerStatus.fulfilled]: (state, action) => {
            state.statusloading = false
            // state.locationStatusData = action.payload;
        },



        // ****************add Employee Update******************************

        [addUpdateEmployee.rejected]: (state, action) => {
            state.addloadingemployee = false
        },
        [addUpdateEmployee.pending]: (state, action) => {
            state.addloadingemployee = true
            state.employeeSubmission = 1

        },
        [addUpdateEmployee.fulfilled]: (state, action) => {
            state.addloadingemployee = false
            state.employeeSubmission = 2
            state.employeeStatus = action.payload.status
            state.employeeSnackFire = action?.payload?.message;

            // state.locationStatusData = action.payload;
        },

        // ****************add subEmployer  Update******************************

        [addUpdateSubEmployer.rejected]: (state, action) => {
            state.addloadingemployee = false
        },
        [addUpdateSubEmployer.pending]: (state, action) => {
            state.addloadingemployee = true;
            state.subEmployerSubmission = 1
        },
        [addUpdateSubEmployer.fulfilled]: (state, action) => {
            state.subEmployerSubmission = 2
            state.addloadingemployee = false
            state.subloginStatus = action.payload.status
            state.subloginSnackFire = action?.payload?.message;

        },

        // ****************employee package derails Update******************************

        [employeePackageData.rejected]: (state, action) => {
            state.loading = false
        },
        [employeePackageData.pending]: (state, action) => {
            state.loading = true
        },
        [employeePackageData.fulfilled]: (state, action) => {
            state.loading = false
            state.employeePackageAllData = action.payload.results.res[0];
            // state.locationStatusData = action.payload;
        },

        // ****************employee package Status Update******************************

        [employeePackageStatus.rejected]: (state, action) => {
            state.statusloading = false
        },
        [employeePackageStatus.pending]: (state, action) => {
            state.statusloading = true
        },
        [employeePackageStatus.fulfilled]: (state, action) => {
            state.statusloading = false
            // state.locationStatusData = action.payload;
        },

        // ***********************delete Notes lifecycle*****************
        [deleteNotes.rejected]: (state, action) => {
            state.statusloading = false
        },
        [deleteNotes.pending]: (state, action) => {
            state.statusloading = true
        },
        [deleteNotes.fulfilled]: (state, action) => {
            state.statusloading = false
            // state.deleteLocationSuccess = action?.payload?.message;
            // console.log("state.deleteLocationSuccess====>>>>", state.deleteLocationSuccess);
        },








    }

})

export default EmployerReducer.reducer;
export const { clearData, clearLocatioTable, addlContactDataEach, deletecontactdata, clearRespomseSubEmplyrEdit, clearRespomseEmplyeeEdit } = EmployerReducer.actions;

