import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import store from '../../store'
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer'

export const MultipleCheckBox = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {

  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)


  const dispatch = useDispatch()
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, []);

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])


  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        {
          fielddata.values.map(eachVal => <FormControlLabel key={eachVal.key} control={
            <Checkbox
              checked={getValues(fielddata.name) && getValues(fielddata.name).includes(eachVal.val) ? true : false}
              onChange={(e) => {
                //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                let tempCheckboxValue = getValues(fielddata.name);
                if (tempCheckboxValue === undefined) tempCheckboxValue = [];
                if (e.target.checked === true) {
                  tempCheckboxValue = [...tempCheckboxValue];// Make a copy of array or error will occured
                  tempCheckboxValue.push(eachVal.val);
                } else {
                  tempCheckboxValue = [...tempCheckboxValue];// Make a copy of array or error will occured
                  tempCheckboxValue.splice(tempCheckboxValue.indexOf(eachVal.key), 1);
                }
                setValue(fielddata.name, tempCheckboxValue);
                clearErrors(fielddata.name);
              }}
            />
          } label={eachVal.val} />)
        }
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
