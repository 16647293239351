import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";




/*........................form list.......................*/

export const getformdata = createAsyncThunk("getformdata", async (reqBody) => {
    if (reqBody == null && reqBody === undefined) {
        reqBody = 
          {
  
            "source": "form_list",
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
               
              "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {
    
            },
            "project": {},
            "token": "",
            "count": false,
    
        }
    }
    // console.log("reqBody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/form-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;




})


export const getformeditdata = createAsyncThunk("editform", async (id) => {
    let reqBody={
        "source": "",
       "condition": {
         "limit": 10,
         "skip": 0
       },
       "sort": {
         "field": "createdon_datetime",
         "type": "desc"
       },
       "searchcondition": {"_id":id},
       "project": {},
       "token": "",
       "count": false
       }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/form-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  })


/*...........group list...........*/

export const groupList = createAsyncThunk("group/List", async (reqbody) => {

  if (reqbody == null && reqbody === undefined) {
    reqbody = {



        "source": "group_list",
        "condition": {
            "limit": 30,
            "skip": 0
        },
        "sort": {
            "field": "createdon_datetime",
            "type": "desc"
        },
        "searchcondition": {

        },
        "project": {},
        "token": "",
        

        "count": false,



    }
}

//   console.log("reqbody==>", reqbody);
  let req_body = {};
  req_body = { ...reqbody };
//   console.log("req_body==", req_body);
  const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req_body)
  };
//   console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/group-list-count", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;


})
/**********************************Group Edit******************* */
export const getgroupeditdata = createAsyncThunk("editgroup", async (id) => {
    let reqBody={
        
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "name",
                "type": "desc"
            },
            "searchcondition": {
                "_id":id
            },
            "project": {},
            "token": ""
        
    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/group-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

// /***********************Parent Group********************************* */

export const getParentData = createAsyncThunk("getparentcategorydata/list", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "group_list",
        "sort": {
            "type": "desc",
            "field": "label"
        },
        "searchcondition": {

        }
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/related-groups", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});

/********************************Field Management ****************** */

/*********Filed List******* */

export const fieldList = createAsyncThunk("fieldList", async (reqbody) => {

    if (reqbody == null && reqbody === undefined) {
      reqbody = {
  
  
  
          "source": "field_list",
          "condition": {
              "limit": 10,
              "skip": 0
          },
          "sort": {
              "field": "createdon_datetime",
              "type": "desc"
          },
          "searchcondition": {
  
          },
          "project": {},
          "token": "",
          
  
          "count": false,
  
  
  
      }
  }
  
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/field-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  
  
  })

  /**********************************Field Edit******************* */
export const getfieldeditdata = createAsyncThunk("editfield", async (id) => {
    let reqBody={
        
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "name",
                "type": "desc"
            },
            "searchcondition": {
                "_id":id
            },
            "project": {},
            "token": ""
        
    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/field-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

// /***********************Parent Group********************************* */

// export const getRelatedFormsData = createAsyncThunk("getformsdata/list", async () => {

//     // console.log("reqbody==>", reqbody);
//     let req_body = {
//         "source": "form_list",
//         "sort": {
//             "type": "desc",
//             "field": "label"
//         },
//         "searchcondition": {

//         }
//     };

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     // console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "manage-group/related-forms", requestOptions);
//     const respdata = await response.json();
//     // console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;

// });













const FormManagementReducer = createSlice({
name: 'FormManagementReducer',
initialState: {

    submitting: false,
    loading: false,
    editloading: false,
    formListData: [],
    editformdata:[],
    testdata: '',
    groupData:[],
    getparentgroupdata:"",
    editDataGroup: [],
    editGroupDataLoading: false,
    fieldData:[],
    editFieldDataLoading:false,
    editDataField:[]

    // getrelatedforms:[]
    
   

 


},
reducers: {
    changeTestData(state, action) {
        state.testdata = action.payload


    },
    clearformdata(state, action) {
        state.formListData=[]
    },
    cleareditformdata(state, action) {
        state. editformdata=[]
    },
    cleargroupdata(state, action) {
        state.groupData=[]
    },
    cleareditdatagroup(state, action) {
        state.editDataGroup=[];
    },
    clearfielddata(state, action) {
        state.fieldData=[]
    },
    cleareditdatafield(state, action) {
        state.editDataField=[];
    },
    // resetendpointSUccess(state, action) {
    //     state.endpointsuccess= {}
    // },
    // cleartrainingcatagorydata(state, action) {
    //     state.trainingcatagorydata= []
    // }
},
extraReducers: {

    /// #################### Form Listing ############################### ///
    [getformdata.rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
        // state.loading = false;
        state.successAction = false;

    },
    [getformdata.pending]: (state, action) => {


        // console.log("getformdata=========================>")
        state.loading = true;

    },
    [getformdata.fulfilled]: (state, action) => {

        // console.log("action data", action.payload.status)
        if (action.payload.status == "success" ) {
            // console.log("in form");
            state.formListData = action.payload.results.res;
            state.loading = false;

        }

    },

    /*...................formdataEdit......................*/


    [getformeditdata .rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
        // state.loading = false;
        // state.successAction = false;

    },
    [getformeditdata .pending]: (state, action) => {


        // console.log("getroleeditdata=========================>")
        state.editloading = true;

    },
    [getformeditdata .fulfilled]: (state, action) => {

        // console.log("action from storedata", action)
        if (action.payload.status === 'success') {
            state.editformdata = action.payload.results.res;
            state.editloading = false;

        }

    },

          /****************Group Listing****** */
          [groupList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
      
        },
        [groupList.pending]: (state, action) => {
      
      
            // console.log("groupList=========================>")
            state.loading = true;
      
        },
        [groupList.fulfilled]: (state, action) => {
      
            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.groupData = action.payload.results.res;
                state.loading = false;
      
            }
      
        },
         /**************************************Training Edit******************** */
         [getgroupeditdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [getgroupeditdata.pending]: (state, action) => {


            // console.log("getgroupeditdata=========================>")
            state.editGroupDataLoading = true;

        },
        [getgroupeditdata.fulfilled]: (state, action) => {

            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.editDataGroup = action.payload.results.res[0];
                // console.log("state for edit data training", state.editDataGroup)
                state.editGroupDataLoading = false;

            }

        },

         /*************************For Parent category in add group form page****** */
         [getParentData.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getParentData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getParentData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                // console.log("Resyyyyyyyyyyyyyyyyyyy",action.payload.results.res)
                state.getparentgroupdata = action.payload.results.res;

            }
        },

        //  /*************************For Related forms in add group form page****** */
        // [getRelatedFormsData.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.successAction = false;

        // },
        // [getRelatedFormsData.pending]: (state, action) => {
        //     // console.log("action p", action)
        //     // state.usersList = [];
        //     state.loading = true;
        // },
        // [getRelatedFormsData.fulfilled]: (state, action) => {
        //     console.log("action f storedata", action)
        //     if (action.payload.status === 'success') {
        //         // console.log("Resyyyyyyyyyyyyyyyyyyy",action.payload.results.res)
        //         state.getrelatedforms = action.payload.results.res;

        //     }
        // },
          /****************field Listing****** */
          [fieldList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
      
        },
        [fieldList.pending]: (state, action) => {
       
            // console.log("fieldList=========================>")
            state.loading = true;
      
        },
        [fieldList.fulfilled]: (state, action) => {
      
            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.fieldData = action.payload.results.res;
                state.loading = false;
      
            }
      
        },

         /**************************************Field Edit******************** */
         [getfieldeditdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [getfieldeditdata.pending]: (state, action) => {


            // console.log("getfieldeditdata=========================>")
            state.editFieldDataLoading = true;

        },
        [getfieldeditdata.fulfilled]: (state, action) => {

            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.editDataField = action.payload.results.res[0];
                // console.log("state for edit data training", state.editDataField)
                state.editFieldDataLoading = false;

            }

        },

     

 

}

})

export default FormManagementReducer.reducer;
export const { clearfielddata, changeTestData,clearformdata,cleareditformdata,cleargroupdata,cleareditdatagroup, cleareditdatafield } = FormManagementReducer.actions;
