import React, { useState } from 'react'
import "../Components/LandingPage14.css"
import LandingPage14FormModal from '../LandingPage14FormModal'
import { setscrollView } from '../../../../Layout/layoutReducer';
import { useDispatch } from 'react-redux';

function Lp14block4({ page }) {


    const [openModal, setopenModal] = useState(false);

    const modalOpen = () => {
        setopenModal(true);
    };
    const setsScroll = () => {
        dispatch(setscrollView("scroll"));
    }
    const dispatch = useDispatch();

    return (
        <>
            <div className='Lp14block4_main_wrapper'>
                <div className='landingpage14_common_width'>
                    <div className='Lp14block4_sub_wrapper'>
                        <h2>Pricing</h2>
                        <div className='Lp14block4_sub1_wrapper'>
                            <div className='Lp14block4_sub1_sec_wrapper'>
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/Lp14block4_sub_wrapper_1logo.png'/> */}
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/Lp14block4_sub_wrapper_1logo.png`} />
                                <h3>Individual</h3>
                                <h4>$21.99</h4>
                                <p>Smart Medical Access for one
                                    individual member.</p>
                            </div>
                            <div className='Lp14block4_sub1_sec_wrapper'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/Lp14block4_sub_wrapper_2logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/Lp14block4_sub_wrapper_2logo.png'/> */}
                                <h3>2 Person Family</h3>
                                <h4>$26.99</h4>
                                <p>Easily add on one additional
                                    household family member.</p>
                            </div>
                            <div className='Lp14block4_sub1_sec_wrapper'>
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/Lp14block4_sub_wrapper_3logo.png`} />
                                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/Lp14block4_sub_wrapper_3logo.png'/> */}
                                <h3>Family Plan</h3>
                                <h4>$31.99</h4>
                                <p>Smart Medical Access for the
                                    entire family.</p>
                            </div>
                        </div>
                        <div className='Lp14block2_sub1_wrapper'>
                            {page === "17" ? <button onClick={() => setsScroll()}>speak with one of our organization specialist right away</button> : <button onClick={() => modalOpen()}>Enroll Today</button>}
                        </div>

                    </div>
                </div>
            </div>
            <div><LandingPage14FormModal openModal={openModal} setopenModal={setopenModal} /></div>
        </>
    )
}

export default Lp14block4