import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { unstable_HistoryRouter, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeExternalFormData, resetForm, resetFormData, setExternalFormData, setFieldNewValue, setFormFieldsData, setOtherData, setTempFormUpdateData } from '../../../../form/formReducer';
import { Alert, Button, Grid, IconButton, LinearProgress, Modal, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { useCookies } from "react-cookie";
// import "../../BackEndPages.css";
import StateArray from '../../../../assets/json/state';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { getusersList } from '../../userManagement/userList/userListReducer';

import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import zIndex from '@mui/material/styles/zIndex';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';
import { clearfetchPackageInfoSuccess, clearprogramdataStatuserr, configerComitionEdit, fetchAssociatedPackages, fetchLandingPage, setAffiliateInitData } from '../../AffiliateManagement/AffiliateReducer';
import ConfigureComissionModal from './ConfigureComissionModal';
import { EmployerAddEdit, addlContactDataEach } from '../EmployerReducer';
import AdditionalCompanyContact from './AdditionalCompanyContact';


const AddEditFormEmployer = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 
  // const history = window.history.back();

  // console.warn("<<<<<<<<<< history >>>>>>", history.back())

  // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

  // console.warn("props.fetchData==============>>>>>>>>>>", props.fetchData);

  // ---- States here -----
  const [loading, setLoading] = useState(true);  //*****FOR LODING****//
  const [modal, setModal] = useState(false);  //*****modal Open toogle ****//
  const [userInfo, setUserInfo] = useState(null); // Set User Information from Cookie
  const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
  const [locationArry, setLocationArry] = useState([]);
  const [comissionConfig, setComissionConfig] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [formOpen, setFormOpen] = useState(true)
  // const [snackbarMSG, setSnackbarMSG] = useState(null)
  const [packageData, setPackageData] = useState([])
  // const [commissionDataMissing, setCommissionDataMissing] = useState(false)
  const [programSnackbarstate, setProgramSnackbarState] = useState(false)



  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from addEditFrom component>>>>>", cookieData)

  // ----- On editDataRef trigger ----
  const goBackBtnClick = useSelector(state => (state.formSlice?.customButtonClicked?.employerAddForm && state.formSlice?.customButtonClicked?.employerAddForm?.goBack001) ? state.formSlice?.customButtonClicked?.employerAddForm?.goBack001 : false);


  const addlcontactBtnClick = useSelector(state => (state.formSlice?.customButtonClicked?.employerAddForm && state.formSlice?.customButtonClicked?.employerAddForm?.addlcontact) ? state.formSlice?.customButtonClicked?.employerAddForm?.addlcontact : false);

  // ------------------------------------- //

  // ----------- packages -------- //
  // const packages = useSelector(state => state.AffiliateReducer?.packageInfo.length > 0 ? state.AffiliateReducer?.packageInfo : []);
  // ------------------------------------- //

  // ---------- loader ------- //
  const loader = useSelector(state => state.AffiliateReducer?.loading ? state.AffiliateReducer?.loading : false);
  // -------------------- //

  // ---------- AddEditSuccess ------- //
  const AddEditSuccess = useSelector(state => state.EmployerReducer?.employerAddEditSuccess ? state.EmployerReducer?.employerAddEditSuccess : false);
  // ------------------------------------- //

  // -------- affiliateAddError ---------//
  const employerAddError = useSelector(state => state.EmployerReducer?.employerAddError ? state.EmployerReducer?.employerAddError : false);

  // ---------------------------- --

  //  ---- Commission modal btn -------- // 
  // const comissionModalBtn = useSelector(state => state.formSlice?.customButtonClicked?.employerAddForm ? state.formSlice?.customButtonClicked?.employerAddForm : false);
  //  ----------------------------------- //

  // --------- fetchPackageInfoSuccess --------- //
  // const fetchPackageInfoSuccess = useSelector(state => state.AffiliateReducer?.fetchPackageInfoSuccess ? state.AffiliateReducer?.fetchPackageInfoSuccess : false);


  // --------- landingPageDetails --------- //
  const landingPageDetails = useSelector(state => state.AffiliateReducer?.landingPageDetails ? state.AffiliateReducer?.landingPageDetails : []);
  // -------------------------------------- //


  // --------- firstname --------- //
  const firstName = useSelector(state => state.formSlice?.formData?.employerAddForm?.firstname ? state.formSlice.formData.employerAddForm.firstname : null);

  // --------- lastname --------- //
  const lastName = useSelector(state => state.formSlice?.formData?.employerAddForm?.lastname ? state.formSlice.formData.employerAddForm.lastname : null);

  // --------- email --------- //
  const email = useSelector(state => state.formSlice?.formData?.employerAddForm?.email ? state.formSlice.formData.employerAddForm.email.toLowerCase() : null);

  // --------- user_name --------- //
  const userName = useSelector(state => state.formSlice?.formData?.employerAddForm?.user_name ? state.formSlice.formData.employerAddForm.user_name : null);
  const title = useSelector(state => state.formSlice?.formData?.employerAddForm?.title ? state.formSlice.formData.employerAddForm.title : null);
  const phoneNumber = useSelector(state => state.formSlice?.formData?.employerAddForm?.phone ? state.formSlice.formData.employerAddForm.phone : null);
  const companyName = useSelector(state => state.formSlice?.formData?.employerAddForm?.company_name ? state.formSlice.formData.employerAddForm.company_name : null);
  const employeesNumber = useSelector(state => state.formSlice?.formData?.employerAddForm?.employees_no ? state.formSlice.formData.employerAddForm.employees_no : null);
  const notesEmployer = useSelector(state => state.formSlice?.formData?.employerAddForm?.notes ? state.formSlice.formData.employerAddForm.notes : null);
  const anyOther = useSelector(state => state.formSlice?.formData?.employerAddForm?.other_info ? state.formSlice.formData.employerAddForm.other_info : null);

  const signfirstName = useSelector(state => state.formSlice?.formData?.employerAddForm?.signfirstName ? state.formSlice.formData.employerAddForm.signfirstName : null);
  const signlastName = useSelector(state => state.formSlice?.formData?.employerAddForm?.signlastName ? state.formSlice.formData.employerAddForm.signlastName : null);
  const ccfirstName = useSelector(state => state.formSlice?.formData?.employerAddForm?.ccfirstName ? state.formSlice.formData.employerAddForm.ccfirstName : null);
  const cclastName = useSelector(state => state.formSlice?.formData?.employerAddForm?.cclastName ? state.formSlice.formData.employerAddForm.cclastName : null);

  const checkBoxStat = useSelector(state => state.formSlice?.formData?.employerAddForm?.same_details ? state.formSlice.formData.employerAddForm.same_details : false);
  const status = useSelector(state => state.formSlice?.formData?.employerAddForm?.status ? state.formSlice.formData.employerAddForm.status : false);


  const formDataObj = useSelector(state => state.formSlice?.formData?.employerAddForm ? state.formSlice.formData.employerAddForm : null);


  // useEffect(() => {
  //   // console.log("email=========>>>", email);
  // }, [])

  // // --------- programe info --------- //
  // const programInfo = useSelector(state => state.formSlice?.formData?.employerAddForm?.associated_program ? state.formSlice.formData.employerAddForm.associated_program : null);

  //-----------  landing_page_access -------- //
  const landing_page_access = useSelector(state => state.formSlice?.formData?.employerAddForm?.landing_page_access ? state.formSlice.formData.employerAddForm.landing_page_access : []);


  // --------- formSubmission --------//
  const fromSubmission = useSelector(state => state.formSlice?.formSubmissionState?.employerAddForm ? state.formSlice.formSubmissionState.employerAddForm : 5);


  // -------------- preview status -------- // 
  const preview = useSelector(state => state.formSlice?.listnerEditFormData?.employerAddForm && state.formSlice?.listnerEditFormData?.employerAddForm?.preview ? state.formSlice.listnerEditFormData.employerAddForm.preview : []);



  // --------------programdataStatuserr------------------------
  const programdataStatuserr = useSelector(state => state.AffiliateReducer?.programdataStatuserr ? state.AffiliateReducer?.programdataStatuserr : false)


  // --------- errorMsg --------//
  const errorMsg = useSelector(state => state.EmployerReducer?.snackbarData ? state.EmployerReducer.snackbarData : null);

  useEffect(() => {
    console.log("errorMsg==========>>>>>>>>", errorMsg)
  }, [errorMsg])




  // const addaddlContactFormData = useSelector((state) => state.formSlice.formData.addaddlCompanyTable ? state.formSlice.formData.addaddlCompanyTable : null);

  const contactCompanyData = useSelector((state) => state.EmployerReducer.addlContactData ? state.EmployerReducer.addlContactData : []);



  // *********************for count in additional contact*********************

  //   const [contactCount, setContactCount] = useState(0)

  //   useEffect(() => {



  //     if (contactCompanyData?.length > 0) {
  //     setContactCount(contactCompanyData?.length)

  //     console.log("addtionalData==============>>>1", contactCount)



  //     }



  //   }, [contactCompanyData.length])

  //   useEffect(() => {


  // if (contactCount>0) { 
  //   console.log("addtionalData==============>>>2", contactCount)

  //   console.log("addtionalData==============>>>789", typeof(contactCount))


  //     setTimeout(() => {

  //       setFormOpen(false)
  //     }, 300)
  //     setTimeout(() => {

  //       setFormOpen(true)
  //     }, 500)
  //   }

  //   }, [contactCount])




  useEffect(() => {
    if (programdataStatuserr === true) {
      setProgramSnackbarState(true)
    }
  }, [programdataStatuserr])

  useEffect(() => {
    if (programSnackbarstate === false) {
      dispatch(clearprogramdataStatuserr())
    }
  }, [programSnackbarstate])






  // ----------------- settings landingPageDetails ------------- //
  let landing = []

  if (landingPageDetails && landingPageDetails.length > 0) {
    landingPageDetails.map((x) => landing.push(x.val))
  }


  useEffect(() => {
    if (landingPageDetails.length > 0) {
      // const assignLandingPageFeild = formData.current.fields.filter((x) => x.name === "landing_page_access")

      // assignLandingPageFeild[0].values = landingPageDetails

      // console.warn("landingPageDetails", landingPageDetails)


      // formData.current.fields.splice(5, 0, assignLandingPageFeild)

    }
  }, [JSON.stringify(landingPageDetails)])

  // -------------------------------------------------- //


  useEffect(() => {
    // console.log("props.fetchData============>>>>>>>>>", props.fetchData?.addaddlContactFormData);
    store.dispatch(addlContactDataEach(props?.fetchData?.addaddlContactFormData));

  }, [props.fetchData])








  // ---  After sucessfully form submissiion ----- //
  useEffect(() => {
    if (fromSubmission === 4) {
      // console.warn("landingPageDetails", landingPageDetails)
      const payload = {
        firstname: firstName !== null ? firstName : undefined,
        lastname: lastName !== null ? lastName : undefined,
        email: email !== null ? email : undefined,
        user_name: userName !== null ? userName : undefined,
        // associated_program: programInfo !== null ? programInfo : undefined,
        roles: { 'is_employer': 1 },
        // commissionsdata: comissionConfig ? comissionConfig : undefined,
        added_by: cookieData?.userinfo?._id,
        landing_page_access: landing_page_access.length > 0 ? landing_page_access : undefined,
        title: title !== null ? title : undefined,
        phone: phoneNumber !== null ? phoneNumber : undefined,
        company_name: companyName !== null ? companyName : undefined,
        employees_no: employeesNumber !== null ? employeesNumber : undefined,
        // notes: notesEmployer !== null ? notesEmployer : undefined,
        other_info: anyOther !== null ? anyOther : undefined,
        // _id: _id ? _id : undefined
        _id: props?.queryParamId ? props.queryParamId : undefined,
        signfirstName: signfirstName !== null ? signfirstName : undefined,
        signlastName: signlastName !== null ? signlastName : undefined,
        ccfirstName: ccfirstName !== null ? ccfirstName : undefined,
        cclastName: cclastName !== null ? cclastName : undefined,
        addaddlContactFormData: contactCompanyData !== null ? contactCompanyData : undefined,
        checkBoxStat: checkBoxStat !== null ? checkBoxStat : undefined,
        // status: status !== null ? status : undefined
        // landing_page_access: landing
      }

      // console.warn("payload===========>>>>>", payload)


      dispatch(EmployerAddEdit({ data: payload }))




    }
  }, [fromSubmission])

  // ------------------------------------------------ //

  // ------------------------singning party checkbox functioanlity------------------------- //
  useEffect(() => {

    if (signfirstName !== null && signlastName !== null) {
      // console.log("hitttt===================>")

      addForm.current[13].disabled = false

      // console.log("===================>>>>>>>>>", addForm.current[13].disabled)

      setFormOpen(false)

      // setTimeout(() => {
      setFormOpen(true)
      // }, 300);
    }
    if (checkBoxStat === true) {
      // console.log("checkBoxStat============== 1", checkBoxStat)
      addForm.current[11].disabled = true
      addForm.current[12].disabled = true

    } else if (checkBoxStat === false) {
      addForm.current[11].disabled = false
      addForm.current[12].disabled = false
      // console.log("checkBoxStat============== 2", checkBoxStat)

    }
    if (checkBoxStat === true && signfirstName !== null && signlastName !== null) {

      // console.log("checkBoxStat=========>>>1", checkBoxStat, signfirstName, signlastName)


      // console.log("formtest=====>>>>", formData);

      for (let k in formData.current.fields) {

        // console.log("formtest=====>>>>", formData.current.fields[k]);

        for (let i in Object.keys(formDataObj)) {
          // console.log("Object.keys(formDataObj)=====>>>", Object.keys(formDataObj)[i]);
          if (formData.current.fields[k].name == Object.keys(formDataObj)[i]) {

            formData.current.fields[k].defaultValue = formDataObj[Object.keys(formDataObj)[i]];

            // console.log("hitTest================>>>>>>", formDataObj[Object.keys(formDataObj)[i]])

          }

          if (formData.current.fields[k].name == "ccfirstName") {
            formData.current.fields[k].defaultValue = formDataObj.signfirstName
            // console.log("hitTest================>>>>>>2", formData.current.fields[k].defaultValue)


          }

          if (formData.current.fields[k].name == "cclastName") {
            formData.current.fields[k].defaultValue = formDataObj.signlastName

          }

        }
      }


      // console.log("text===========>>>>", addForm.current[9])
      setFormOpen(false)

      setTimeout(() => {
        setFormOpen(true)
      }, 300);

    }
    // console.log("checkBoxStat=========>>>2", checkBoxStat, signfirstName, signlastName)
  }, [checkBoxStat, signfirstName, signlastName])










  // ---------- on form backbutton clicked ----------------// 
  useEffect(() => {
    if (goBackBtnClick) {
      // console.log("goBackBtnClick============>>>>", goBackBtnClick)
      // setModal(false)
      // dispatch(clearfetchPackageInfoSuccess())


      const payload_employerList = {
        "condition": {
          "limit": 30, "skip": 0
        },
        "sort": {
          "field": "createdon_datetime", "type": "desc"
        },
        "searchcondition": {
          "roles.is_employer": 1,
          "added_by": cookieData?.userinfo?._id

        },
        "count": false
      }

      resolvefunction(
        navigate,
        "/employer-listing",
        getusersList(payload_employerList)
      );



      // navigate('/view-affiliate');
    }
  }, [goBackBtnClick])

  // ------------------------------------------------------ //


  //  ------- on sucessfull Affiliate add route to affiliate listings-------- // 
  useEffect(() => {
    if (AddEditSuccess) {

      // console.log("AddEditSuccess=====1213>>>>>", AddEditSuccess);

      setOpenSnackbar(true)

      const payload_employer_listing = {
        "condition": {
          "limit": 30, "skip": 0
        },
        "sort": {
          "field": "createdon_datetime", "type": "desc"
        },
        "searchcondition": {
          "roles.is_employer": 1,
          "added_by": cookieData?.userinfo?._id && cookieData.userinfo._id
        },
        "count": false
      }

      resolvefunction(
        navigate,
        "/employer-listing",
        getusersList(payload_employer_listing)
      );

    }
  }, [AddEditSuccess])

  // ------------------------------------------ // 

  //--------------------Additional Company COntact-----------------------//

  const [addlcontactOpen, setAddlcontactOpen] = useState(false)

  useEffect(() => {
    if (addlcontactBtnClick) {
      setAddlcontactOpen(true)
    }
  }, [addlcontactBtnClick])




  //  --------------------- affiliateAddError ------------------------------- //

  useEffect(() => {
    if (employerAddError) {

      setOpenSnackbar(true)
    }
  }, [employerAddError])

  // ---------------------------------------------------------------------- //








  let addForm = useRef([
    {
      id: 0,
      heading: "First Name",
      label: "First Name",
      name: "firstname",
      className: 'inputBlock inputBlock4line',
      defaultValue: props.fetchData?.firstname ? props.fetchData.firstname : '',

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 1,
      heading: "Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 2,
      heading: "Email",
      label: "Email",
      name: "email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: '',
      autoCapitalize: "none",

      value: '',
      inputType: "email",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 3,
      heading: "Unique Name",
      label: "Unique Name",
      name: "user_name",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },




    {
      id: 8,
      heading: "Title",
      label: "Title",
      name: "title",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 9,
      heading: "Phone",
      label: "Phone",
      name: "phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 12,
      heading: "Company Name",
      label: "Company Name",
      name: "company_name",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 13,
      heading: "Number of Employees",
      label: "Number of Employees",
      name: "employees_no",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },



    // {
    //   id: 10,
    //   heading: "Notes",

    //   name: "notes",
    //   className: 'inputBlock inputBlock1line',
    //   type: "textarea",
    //   inputType: "phone",/////////////// If not mentioned default will be text //////////////
    //   // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
    //   // rules: { required: true },
    //   errorMessage: {
    //     required: "This Field is Required",
    //     custom: "Value is Invalid"
    //   },

    //   // defaultValue: "Test"
    // },

    {
      id: 20,
      heading: "Notes / Other Information",
      // label: "Any other information we should know?",
      name: "other_info",
      className: 'inputBlock inputBlock1line',
      type: "textarea",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 30,
      heading: "Signing Party First Name",
      label: "Signing Party First Name",
      name: "signfirstName",
      className: 'inputBlock inputBlock4line',
      defaultValue: props.fetchData?.signfirstName ? props.fetchData.signfirstName : '',

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 31,
      heading: "Signing Party Last Name",
      label: "Signing Party Last Name",
      name: "signlastName",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 41,
      heading: "Company Contact First Name",
      label: "Company Contact First Name",
      name: "ccfirstName",
      className: 'inputBlock inputBlock4line',
      defaultValue: props.fetchData?.ccfirstName ? props.fetchData.ccfirstName : '',
      disabled: false,

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 42,
      heading: "Company Contact Last Name",
      label: "Company Contact Last Name",
      name: "cclastName",
      className: 'inputBlock inputBlock4line',
      type: "text",
      disabled: false,

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 93,
      // heading: "Status",
      name: "same_details",
      className: "form_item form_item statusCheck",
      disabled: true,
      type: 'singleCheckbox',
      values: { key: 0, val: 'Same as Signing Party' },
      // defaultValue: (editLocation && Object.keys(editLocation).length > 0 && editLocation.status !== undefined && editLocation.status == "Active") ? true : false,
    },

    // {
    //   id: 43,
    //   // heading: "Status",
    //   name: "status",
    //   className: "form_item form_item statusCheck",
    //   type: 'singleCheckbox',
    //   values: { key: 0, val: 'Active' },
    //   // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.status !== undefined && editEmployee.status == "Active") ? true : false,
    // },







  ])


  let editForm = [
    {
      id: 0,
      heading: "First Name",
      label: "First Name",
      name: "firstname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.firstname ? props.fetchData.firstname : '',
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
    },


    {
      id: 1,
      heading: "Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.lastname ? props.fetchData.lastname : '',
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 2,
      heading: "Email",
      label: "Email",
      name: "email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      disabled: true,
      defaultValue: props.fetchData?.email ? props.fetchData.email : '',
      inputType: "email",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },



    {
      id: 4,
      heading: "Unique Name",
      label: "Unique Name",
      name: "user_name",
      className: 'inputBlock inputBlock4line',
      type: "text",
      disabled: true,

      defaultValue: props.fetchData?.user_name ? props.fetchData.user_name : '',

      //inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 21,
      heading: "Title",
      label: "Title",
      name: "title",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.title ? props.fetchData.title : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 22,
      heading: "Phone",
      label: "Phone",
      name: "phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      defaultValue: props.fetchData?.phone ? props.fetchData.phone : '',

      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 23,
      heading: "Company Name",
      label: "Company Name",
      name: "company_name",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      defaultValue: props.fetchData?.company_name ? props.fetchData.company_name : '',

      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 24,
      heading: "Number of Employees",
      label: "Number of Employees",
      name: "employees_no",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      defaultValue: props.fetchData?.employees_no ? props.fetchData.employees_no : '',

      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },



    // {
    //   id: 25,
    //   heading: "Notes",

    //   name: "notes",
    //   className: 'inputBlock inputBlock1line',
    //   type: "textarea",
    //   inputType: "phone",/////////////// If not mentioned default will be text //////////////
    //   // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
    //   // rules: { required: true },
    //   defaultValue: props.fetchData?.notes ? props.fetchData.notes : '',
    //   errorMessage: {
    //     required: "This Field is Required",
    //     custom: "Value is Invalid"
    //   },

    //   // defaultValue: "Test"
    // },

    {
      id: 27,
      heading: "Notes/Other Information",
      // label: "Any other information we should know?",
      name: "other_info",
      className: 'inputBlock inputBlock1line',
      type: "textarea",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      // rules: { required: true },
      defaultValue: props.fetchData?.other_info ? props.fetchData.other_info : '',

      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 30,
      heading: "Signing Party First Name",
      label: "Signing Party First Name",
      name: "signfirstName",
      className: 'inputBlock inputBlock4line',
      defaultValue: props.fetchData?.signfirstName ? props.fetchData.signfirstName : '',

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 31,
      heading: "Signing Party Last Name",
      label: "Signing Party Last Name",
      name: "signlastName",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      defaultValue: props.fetchData?.signlastName ? props.fetchData.signlastName : '',

      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 41,
      heading: "Company Contact First Name",
      label: "Company Contact First Name",
      name: "ccfirstName",
      className: 'inputBlock inputBlock4line',
      defaultValue: props.fetchData?.ccfirstName ? props.fetchData.ccfirstName : '',
      disabled: false,

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },


    {
      id: 42,
      heading: "Company Contact Last Name",
      label: "Company Contact Last Name",
      name: "cclastName",
      className: 'inputBlock inputBlock4line',
      type: "text",
      disabled: false,
      defaultValue: props.fetchData?.cclastName ? props.fetchData.cclastName : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      // defaultValue: "Test"
    },

    {
      id: 93,
      // heading: "Status",
      name: "same_details",
      className: "form_item form_item inputBlock inputBlock1line",
      disabled: true,
      type: 'singleCheckbox',
      values: { key: 0, val: 'Same as Signing Party' },
      defaultValue: props.fetchData?.checkBoxStat ? props.fetchData.checkBoxStat : '',

      // defaultValue: (editLocation && Object.keys(editLocation).length > 0 && editLocation.status !== undefined && editLocation.status == "Active") ? true : false,
    },

    // {
    //   id: 43,
    //   // heading: "Status",
    //   name: "status",
    //   className: "form_item form_item statusCheck",
    //   type: 'singleCheckbox',
    //   values: { key: 0, val: 'Active' },
    //   defaultValue: props.fetchData?.status ? props.fetchData.status : '',

    //   // defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.status !== undefined && editEmployee.status == "Active") ? true : false,
    // },




  ]


  // ---- userCreateForm ------
  const formData = useRef({
    id: 'employerAddForm',
    formtype: "add",
    api_url: process.env.REACT_APP_API_URL,
    // endPoint: "users/add-update-affiliate",
    endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/usermanagement",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "tblform",// Can be undefined default will be formCls
    formInlineCLass: "inlnfrm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: props.queryParamId ? "UPDATE" : "SUBMIT",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      // type: "admin",
      // status: "active",
      type: props.fetchData?.type ? props.fetchData.type : props.userType,
      added_by: cookieData?.userinfo?._id,
      _id: props?.queryParamId ? props.queryParamId : undefined,
      roles: ['is_employer']
    },

    fields: props?.queryParamId ? editForm : addForm.current,

    customButtons: [
      {
        id: 'goBack001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },

      {
        id: 'addlcontact',
        label: "Additional Company Contact",
        className: 'default',
        type: 'button',
        showcount: true,
        // countFiled:contactCount>0 ? contactCount : undefined ,
      }
    ]

  });








  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );








  return (
    <>
      <div>


        <div className='adminform_wrapper'>

          {/* ---------- loader goes here ------- */}
          {loader && <LinearProgress sx={{ marginTop: '10px' }} />}
          {/* ----------------------------------- */}


          {formOpen == true && <Form formData={formData.current} />}  {/* --- form here -- */}
        </div>





      </div>



      {/* ---------------Additional Company Contact---------------------  */}

      {/* <Tooltip className='count'>{contactCompanyData.length}</Tooltip> */}

      {formOpen && addlcontactOpen ? <AdditionalCompanyContact
        addlcontactOpen={addlcontactOpen}
        setAddlcontactOpen={setAddlcontactOpen}
        added_by={cookieData?.userinfo?._id}
      /> : ""}



      {/*  --- snakcbar section here ---- */}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={errorMsg !== null ? errorMsg : "All fields must be filled up with correct value"}
        action={action}
        sx={{ bottom: "24px !important" }}
      />


      {programSnackbarstate === true &&
        <SnackBarComp
          setSnackFire={setProgramSnackbarState}
          resMessage={"Program data not found"}
          snackFire={programSnackbarstate}
          endpointResStatus={"failed"}
        />
      }





      {/*  ---------------------- */}


    </>
  )
}

export default AddEditFormEmployer;