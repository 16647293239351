
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, roundRobinEdit } from '../AffiliateReducer';
import NDAContractForm from '../../../Frontend/NDAContractForm/NDAContractForm';



const ViewNDAFormModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    const [priority, setPriority] = useState('');
    const [value, setValue] = useState('');


    // console.warn("<<<<<< configureRoundRobin props goes here >>>>>>", props)
    // console.warn("<<<<<< cookies goes here >>>>>", userInfo)

    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)

    const FetchedNDAFormData = useSelector(state => (state.AffiliateReducer?.ViewNDAFormModalData) ? state.AffiliateReducer.ViewNDAFormModalData : [])


    // useEffect(() => {
    //     props?.setModalResponse(false);
    // }, [])


    // ------- onModalClose --------
    function ModalClose() {

        props?.tooglefunc(false);

    }






    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock SD_w9_modal"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h3">
                        {loader ? <LinearProgress /> : ""}

                        NDA Form Details
                    </Typography>


                    <div className='formSection'>
                    <NDAContractForm source="affiliate_listing" FetchedNDAFormData={FetchedNDAFormData}/>
                    </div>

                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>

                </Box>
            </Modal>





        </>
    )
}


export default ViewNDAFormModal;



