import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";





export const gettriggerdata = createAsyncThunk("gettriggerdata", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }

    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-trigger/email-trigger-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const gettriggereditdata = createAsyncThunk("gettriggereditdata", async (reqBody) => {

    if (!reqBody || reqBody._id) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {...reqBody},
            "project": {},
            "token": "",
            "count": false
        }
    }

    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-trigger/email-trigger-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

const TriggerReducer = createSlice({
    name: 'TriggerReducer',
    initialState: {
    
        submitting: false,
        loading: false,
        editloading: false,
        Triggerdata: [],
        editriggerdata:[],
        testdata: '',
    
    
    },
    reducers: {
        changeTestData(state, action) {
            state.testdata = action.payload
    
    
        },
        cleartriggerdata(state, action) {
            state.Triggerdata=[]
        },
        clearedittriggerdata(state, action) {
            state.editriggerdata=[]
        }
        // resetendpointSUccess(state, action) {
        //     state.endpointsuccess= {}
        // },
        // cleartrainingcatagorydata(state, action) {
        //     state.trainingcatagorydata= []
        // }
    },
    extraReducers: {
        /// #################### Training Listing ###############################
        [gettriggerdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
    
        },
        [gettriggerdata.pending]: (state, action) => {
    
    
            // console.log("getprogramdata=========================>")
            state.loading = true;
    
        },
        [gettriggerdata.fulfilled]: (state, action) => {
    
            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.Triggerdata = action.payload.results.res;
                state.loading = false;
    
            }
    
        },
        [gettriggereditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;
    
        },
        [gettriggereditdata.pending]: (state, action) => {
    
    
            // console.log("getroleeditdata=========================>")
            state.editloading = true;
    
        },
        [gettriggereditdata.fulfilled]: (state, action) => {
    
            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.editriggerdata = action.payload.results.res;
                state.editloading = false;
    
            }
    
        }
    
    }
    
    })


export default TriggerReducer.reducer;
export const { changeTestData,cleartriggerdata,clearedittriggerdata } = TriggerReducer.actions;



















