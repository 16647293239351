import React, { useEffect, useRef, useState } from 'react'
import "../Components/LandingPage14.css"
import LandingPage14FormModal from '../LandingPage14FormModal';

import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { MedicineListing, clearMedicineList } from '../../../../Backend/Medicine/MedicineReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, Modal, Tooltip } from '@mui/material';
import { setscrollView } from '../../../../Layout/layoutReducer';
import { deleteTempSearchEndpoint, setReloadTableFlag } from '../../../../../listing/listReducer';
import { resetFormData, resetautocompletelist } from '../../../../../form/formReducer';




function Lp14block2({ page }) {

  const [openModal, setopenModal] = useState(false);

  const modalOpen = () => {
    setopenModal(true);
  };


  const [openMoadl2, setopenMoadl2] = useState(false)
  const openModal3 = () => {
    setopenMoadl2(true);
  };
  const closeModal = () => {
    setopenMoadl2(false);
  };
  const medcatListing = useSelector((state) => (state.medicineSlice?.medicinedata && state.medicineSlice?.medicinedata.length > 0) ? state.medicineSlice.medicinedata : [])
  const loadingInMedCatListing = useSelector(state => state.medicineSlice?.loading ? state.medicineSlice.loading : false);

// ===================================drug list toggle button and data function==================================
const [menuToggle, setmenuToggle] = useState(1)


const menuToggleSelected = (val) => {
  
  setmenuToggle(val)

  if (val == 2) {
    tableDataForMedCatlisting.current.reqBody.searchcondition = {};
    tableDataForMedCatlisting.current.reqBody.searchcondition["is_allmedicine"] = {$eq: true};
    // tableDataForMedCatlisting.current.reqBody.condition["skip"] = 0;
    tableDataForMedCatlisting?.current?.searchData?.formData?.fields?.forEach(
      (data) => {
        data.defaultValue = undefined;
      }
    );
   

    
    tableDataForMedCatlisting.current.tableTitle = "Additional Discounted Medical Items"
   
    dispatch(setReloadTableFlag({ tableId: "medcatlist" }))
    dispatch(deleteTempSearchEndpoint({ tableId: "medcatlist" }));

    dispatch(resetFormData({ formId: "searchMedicineandCategoryList" }));
    dispatch(resetautocompletelist());

    
    dispatch(MedicineListing(tableDataForMedCatlisting.current.reqBody))
    dispatch(clearMedicineList())
 
    
  }
  else if (val == 1) {
    
    tableDataForMedCatlisting.current.reqBody.searchcondition = {};
    // tableDataForMedCatlisting.current.reqBody.condition["skip"] = 0;
   
    tableDataForMedCatlisting.current.tableTitle = "Free Medical Items"
    tableDataForMedCatlisting?.current?.searchData?.formData?.fields?.forEach(
      (data) => {
        if (data.reqBody.searchcondition) {
          delete data.reqBody.searchcondition;
        }
      }
    );
   
    dispatch(setReloadTableFlag({ tableId: "medcatlist" }))
    dispatch(deleteTempSearchEndpoint({ tableId: "medcatlist" }));
    dispatch(resetFormData({ formId: "searchMedicineandCategoryList" }));
    dispatch(resetautocompletelist());
    
    dispatch(MedicineListing(tableDataForMedCatlisting.current.reqBody))
    dispatch(clearMedicineList())
    

  }
}
// ===================================drug list toggle button and data function==================================



  var modifyTableHeadersforMedCatlisting =
    [

      { val: "name", name: "Medicine Name" },
      { val: "med_by_catagorey", name: "Medicine Category" },



    ];
  useEffect(() => {
    console.log("medcatListing--------------", medcatListing);

  }, [medcatListing])

  let tableDataForMedCatlisting = useRef({
    tableId: "medcatlist",
    tableTitle: "Free Medical Items",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {

      // "source": "training_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["name", "med_by_catagorey", "priority", "status", "createdon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
    
    ],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      // heading: "Search Medicine & Category List",
      // type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchMedicineandCategory',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },

          },



        ]
      }
    },

  });
  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlisting.current.reqBody));
  }, [])

  const setsScroll = () => {
    dispatch(setscrollView("scroll"));
  }
  const dispatch = useDispatch();


  return (
    <>
      <div className='Lp14block2_main_wrapper'>
        <div className='landingpage14_common_width'>
          <div className='Lp14block2_sub1_wrapper'>
            {page === "17" ? <button onClick={() => setsScroll()}>speak with one of our organization specialist right away</button> : <button onClick={() => modalOpen()}>Enroll Today</button>}
            {/* <button onClick={() => modalOpen()}>Enroll Today</button> */}
          </div>
          <div className='Lp14block2_sub2_wrapper'>
            <div className='Lp14block2_sub2_sec_wrapper'>
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_block2_1logo.png' /> */}
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_block2_1logo.png`} />
              <div className='Lp14block2_sub2_text_sec_wrapper'>
                <p>Pay $0 for your
                  medications</p>
              </div>
            </div>
            <div className='Lp14block2_sub2_sec_wrapper'>
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_block2_2logo.png' /> */}
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_block2_2logo.png`} />

              <div className='Lp14block2_sub2_text_sec_wrapper'>
                <p>No limit on orders/refills</p>
              </div>
            </div>
            <div className='Lp14block2_sub2_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_block2_3logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_block2_3logo.png' /> */}
              <div className='Lp14block2_sub2_text_sec_wrapper'>
                <p>Add new covered
                  medications anytime</p>
              </div>
            </div>
            <div className='Lp14block2_sub2_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_block2_4logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_block2_4logo.png' /> */}
              <div className='Lp14block2_sub2_text_sec_wrapper'>
                <p>Licensed in all 50
                  US States</p>
              </div>
            </div>
            <div className='Lp14block2_sub2_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_block2_5logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_block2_5logo.png' /> */}
              <div className='Lp14block2_sub2_text_sec_wrapper'>
                <p>Free Standard Shipping</p>
              </div>
            </div>
            <div className='Lp14block2_sub2_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_block2_6logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_block2_6logo.png' /> */}
              <div className='Lp14block2_sub2_text_sec_wrapper'>
                <p>Licensed Pharmacists
                  & Experienced
                  Customer Service Team</p>
              </div>

            </div>
          </div>
          <div className='Lp14block2_sub3_wrapper'>
            <div className='Lp14block2_sub3_sec1_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landinpage14_medicine_img.webp`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landinpage14_medicine_img.webp' /> */}
            </div>
            <div className='Lp14block2_sub3_sec2_wrapper'>
              <h1><span>605+ </span>Prescriptions Covering <span>93%</span> of All Conditions</h1>

              <div className='Lp14block2_sub1_wrapper'>
                <button onClick={() => openModal3()}>See the Drug List</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div><LandingPage14FormModal openModal={openModal} setopenModal={setopenModal} /></div>



      <div>
        <Modal
          className="modalBaseStyle allMeetingMOdal  MOdallanding5 SD_MOdallanding sr_toggle_btn"
          open={openMoadl2}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={() => closeModal()}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <div className="rolelist_mainwrp">

                <div className='listing_heading'>

                  <h1>Access Free Pharmacy Medicine</h1>

                </div>
                <div className="bluecoastLead_list_mainwrpr">
                  <div className='click_buttonwp navigationMain'>

                    <Button
                      id="buttonzero"
                      className={menuToggle === 1 ? "active" : ""}
                      variant="contained"
                      onClick={() => menuToggleSelected(1)}
                    >
                      Free Medical Items
                    </Button>

                    <Button
                      id="buttonone"
                      className={menuToggle === 2 ? "active" : ""}
                      variant="contained"
                      onClick={() => menuToggleSelected(2)}
                    >
                      Additional Discounted Medical Items
                    </Button>
                  </div>
                </div>

                <div className='rolelist_mainwrp_Table'>
                  {!loadingInMedCatListing ? (
                    <ListingTable
                      tableData={tableDataForMedCatlisting.current}
                      dataset={medcatListing}
                      modifyHeaders={modifyTableHeadersforMedCatlisting}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}

                </div>
              </div>
            </div>

          </Box>
        </Modal>
      </div>

    </>
  )
}

export default Lp14block2