import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close"


function DeleteFromList({deleteOpen, setDeleteOpen, deleteFunctioanl}) {

    const handleCloseDelete = () => {
        setDeleteOpen(false)
    }
    return (
        <>
            <Modal className='modalblock notes_details delete_modal'
                open={deleteOpen}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    border: '10px solid #0569ad!important',
                    background: '#fff',
                    boxShadow: 24,
                    textAlign: "center",
                }}>
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseDelete}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography id="modal-modal-title" variant="h5" component="h5">
                        <p className='alertPara2'> Alert !!</p>
                        <div className='notes_header'>
                            <p> Are you sure you want to delete this record? This can't be undone. </p>
                        </div>
                    </Typography>
                    <div>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={deleteFunctioanl} className="modalBTN">Yes</Button>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={handleCloseDelete} className="modalBTN">No</Button>
                    </div>

                    {/* {employeeLoader && <LinearProgress sx={{ marginTop: '10px' }} />} */}

                </Box>
            </Modal>

          


        </>
    )
}

export default DeleteFromList