import React from 'react'
import "../Components/LandingPage14.css"

function Lp14block3() {
  return (
    <>
      <div className='Lp14block3_main_wrapper'>
        <div className='landingpage14_common_width'>
          <div className='Lp14block3_sub_wrapper'>
            <h2><span>With </span> ACCESS DISCOUNT
              HEALTHCARE <span>you will</span> </h2>
            <p><span>AVOID </span> the high cost of the prescriptions you need</p>
            <p><span>AVOID</span>  the worry about how you will pay for them each month</p>
            <p><span> AVOID</span> putting off other purchases because of the cost of your prescriptions</p>
            <p><span> AVOID </span>getting the runaround from unresponsive customer service representatives</p>


          </div>
        </div>
      </div>
    </>
  )
}

export default Lp14block3