import { Box, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EmployersFormLP from '../../../Frontend/LandingPage/EmployersFormLP/EmployersFormLP'
import NDAContractForm from '../../../Frontend/NDAContractForm/NDAContractForm'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getEmployerData } from '../EmployerReducer'
import { clearforceloginapidata, forcelogin } from '../../../Frontend/LandingPage/LandingpageReducer'
import { Cookies } from 'react-cookie'
import { setLogedinUserInfo } from '../../../login/loginReducer'
import NDAContractEmployer from './NDAContractEmployer'
import ThankYouPageEmployer from './ThankYouPageEmployer'

function EmployersFormsRedirect() {

    const { _id } = useParams();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const navigate = useNavigate();


    const [employerFlag, setEmployerFlag] = useState('')
    // console.log("employerFlag=========>>>>", employerFlag);


    useEffect(() => {
        if (_id !== null && _id !== undefined) {
            // console.log("idHitt===>>>>", _id)
            dispatch(getEmployerData(_id))
        }

    }, [])



    const Loader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : false)
    const employerState = useSelector((state) => state.EmployerReducer?.employerData ? state.EmployerReducer.employerData : null)
    const employerInfo = useSelector((state) => state.EmployerReducer?.employerData ? state.EmployerReducer.employerData : null)
    const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
    const forceloginapicallfalg = useSelector(state => (state.LandingpagefontReducer?.forceloginapicall) ? state.LandingpagefontReducer?.forceloginapicall : false)
    const forceLOgindata = useSelector(state => (state.LandingpagefontReducer?.forcelogindata) ? state.LandingpagefontReducer?.forcelogindata : null)




    useEffect(() => {
        // console.log("employerState===123", employerState);
        if (_id != null && _id != undefined &&  employerState && employerState.employer_signup_stage) {
            // console.log("employerState=================>>>>>>>>", employerState.employer_signup_stage, _id);
            setEmployerFlag(employerState.employer_signup_stage)
        }
    }, [employerState])


    // =========================Force_login_fire=======================
    // useEffect(() => {
    //     if (Object.keys(employerInfo).length > 0 && employerInfo.employer_SignUp_stage === 5) {
    //         redirectedtoNewRepDahsboard()
    //     }
    //     console.log("hit============>>>>>>>>>", employerInfo);
    // }, [employerInfo])

    const redirectedtoNewRepDahsboard = async () => {
        let body = {
            "email": employerInfo.email,
            "_id": employerInfo._id,
            "login_time": Math.round(new Date().getTime()),
            "login_data": ipinfo
        }
        await dispatch(forcelogin(body))
    }

    // const redirectandcookieset = async () => {
    //     let userInfodata = {
    //         "main_role": forceLOgindata.item.main_role,
    //         ...forceLOgindata.item.results[0]
    //     }
    //     await cookies.remove("userinfo", { path: "/" });
    //     await cookies.remove("lastLoginTime", { path: "/" });

    //     await cookies.set('userinfo', userInfodata, { path: '/' });
    //     await cookies.set('token', forceLOgindata.token, { path: '/' });
    //     await cookies.set('lastLoginTime', new Date().getTime(), { path: '/' });
    //     if (!userInfodata.last_login_time) {
    //         userInfodata.last_login_time = new Date().getTime()
    //     }
    //     let dataset = {
    //         "userinfo": userInfodata,
    //         "token": forceLOgindata.token
    //     }
    //     await dispatch(setLogedinUserInfo(dataset));
    //     // setloader(false)
    //     navigate('/new-ae-dashboard');
    //     dispatch(clearforceloginapidata())
    // }


    // useEffect(() => {
    //     if (forceloginapicallfalg && forceLOgindata) {
    //         redirectandcookieset()
    //         // console.warn(existingUserInfo, "existingUserInfo>>>>");
    //     }
    // }, [JSON.stringify(forceLOgindata), forceloginapicallfalg])


    return (
        <>
            {Loader && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}
            {employerFlag == 1 && <EmployersFormLP />}
            {employerFlag == 2 && <NDAContractEmployer />}
            {employerFlag == 3 && <ThankYouPageEmployer/>}

        </>
    )
}

export default EmployersFormsRedirect