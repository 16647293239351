import { IconButton, Modal, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import ListingTable from '../../../listing/listing';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from 'react-redux';
import { BusinessManagersList, clearBusinessManager } from './BlueCoastReducer';
import AddBusinessManagerModal from './AddBusinessManagerModal';
import { setReloadTableFlag } from '../../../listing/listReducer';

const BusinessManagerModal = ({ rowData, userData, modalOpen, setModalClose }) => {

  const dispatch = useDispatch()

  const [addformModalOpen, setAddFormModalOpen] = useState(false);
  const [editTaskData, setEditTaskData] = useState(null)

  const editButtonClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.businessmanagers?.editmanager ? state.tableSlice.customButtonClicked.businessmanagers.editmanager : false)
  const editData = useSelector((state) => state.tableSlice.tempRowData.businessmanagers && Object.keys(state.tableSlice.tempRowData.businessmanagers).length > 0 ? state.tableSlice.tempRowData.businessmanagers : {})

  useEffect(() => {
    const reqBody1 = {
      "condition": {
        "limit": 30,
        "skip": 0,
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {
        "addedfor": rowData?.rep_id
      },
      // "searchcondition": {},
      "project": {},
      "token": "",
      "email": "",
      "count": false,
    }
    dispatch(BusinessManagersList(reqBody1))
  }, [])

  const businessManagerListData = useSelector((state) => (state.BlueCoastSlice.businessmanagerslistdata && state.BlueCoastSlice?.businessmanagerslistdata.length > 0) ? state.BlueCoastSlice.businessmanagerslistdata : [])

  const businessManagerDataLoading = useSelector((state) => state.BlueCoastSlice?.businessmanagerLoader ? state.BlueCoastSlice.businessmanagerLoader : false)


  var modifyTableHeaders = [
    { val: "title", name: "Title" },
    { val: "name", name: "Name" },
    { val: "email", name: "Email" },
    { val: "phone", name: "Phone" },

    // { val: "notes", name: "Notes" },
    { val: "addedby_name", name: "Added By" },
    {
      val: "created_on",
      name: "Creation Time",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ];

  let tableDatabusinessmanagers = useRef({
    tableId: "businessmanagers",
    // tableTitle: "Business Manager's",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/buisnessmanagerleadlist",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/buisnessmanagerleadlist",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        "addedfor": rowData?.rep_id
      },
      project: {},
      token: "",
      email: "",
      count: false,
    },

    rowsPerPageOptions: [30],
    sortFields: ["name", "createdon_datetime", "notes", "addedby_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [
      {
        id: "editmanager",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "viewApi",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Business Manager Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/buisnessmanagerleadlist",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {
            },
            "token": "",
          }
        },
        headers: [
          { val: "title", label: "Title" },
          { val: "name", label: "Name" },
          { val: "email", label: "Email" },
          { val: "phone", label: "Phone" },
          // { val: "state", label: "State" },
          // { val: "city", label: "City" },
          // { val: "zip", label: "Zip" },
          { val: "addedfor_name", label: "AE/REP Name" },
          { val: "addedby_name", label: "Added By" },

          { val: "created_on", label: "Manager Added On", type: "datetime", format: "MM/DD/YYYY " },

        ]
      },
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this record ? This can't be undone. </p>",
        modalHeader: "<p> Alert!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/deletebuisnessmanagerlead",
          body: {
            // source: "users",

            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search About Business Manager",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchNotesForm",
        formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [


          {
            id: 0,
            label: "Search by Title",
            name: "title",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 bmSearch',
            base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompletebmtitle",
            reqBody: {
              "condition": {
                "limit": 30,
                "skip": 0
              },
              "sort": {
                "field": "createdon_datetime",
                "type": "desc"
              },
              "searchcondition": {
                "addedfor": rowData?.rep_id,
                "label": {
                  "$regex":"",
                  "$options":"i"
                }
              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 1,
            label: "Search by Name",
            name: "manager_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 bmSearch',
            base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompletebmname",
            reqBody: {
              "condition": {
                "limit": 30,
                "skip": 0
              },
              "sort": {
                "field": "createdon_datetime",
                "type": "desc"
              },
              "searchcondition": {
                "addedfor": rowData?.rep_id,
                "label": {
                  "$regex":"",
                  "$options":"i"
                }
              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Email",
            name: "email",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 bmSearch",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/autocompletebmemail",
            reqBody: {
              "condition": {
                "limit": 30,
                "skip": 0
              },
              "sort": {
                "field": "createdon_datetime",
                "type": "desc"
              },
              "searchcondition": {
                "addedfor": rowData?.rep_id,
                "label": {
                  "$regex":"",
                  "$options":"i"
                }
              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 3,
            label: "Search by Added By",
            name: "addedby",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 bmSearch",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/bclautocompleteforaddedby",
            reqBody: {
              condition: {
                limit: 30,
                skip: 0,
              },
              sort: {
                field: "createdon_datetime",
                type: "desc",
              },
              searchcondition: {
                label: {
                  $regex: "",
                  $options: "i",
                },
              },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedby" },
          },
          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "createdon_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col4 bmSearch",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "createdtedon_datetime",
            className: "list_search_item countDiv__column--col4 bmSearch",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });



  useEffect(() => {
    if (editButtonClicked && editData && Object.keys(editData).length > 0) {
      setEditTaskData(editData)
      setAddFormModalOpen(true);

    }
  }, [editButtonClicked, JSON.stringify(editData)])





  const handleCloseModal = () => {
    setModalClose(false);

  };

  const addFormOpen = () => {
    setAddFormModalOpen(true);
  };

  const addFormClose = () => {
    setAddFormModalOpen(false);
    setEditTaskData(null)
    dispatch(setReloadTableFlag({ tableId: "businessmanagers" }));
    dispatch(clearBusinessManager())


  };







  return (

    <>
      <div>
        <Modal
          className="modalBaseStyle maintaskList_modal BusinessManager_modal  "
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalBaseBox mediumModal">
            <Tooltip title="Close">
              <IconButton
                onClick={handleCloseModal}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className="rolelist_mainwrp All_Task_List">
              <h2 className="previewModalTitle">
                <div className="modal_title">Business Manager(s) </div>
              </h2>
              <div className='rolelist_mainwrp_Table'>
                {!businessManagerDataLoading ? (
                  <div>
                    <Tooltip title="Add Business Manager">
                      <button className="addTask_btn" onClick={() => addFormOpen()}>
                        {" "}
                        <AddIcon />
                      </button>

                    </Tooltip>

                    <ListingTable
                      tableData={tableDatabusinessmanagers.current}
                      dataset={businessManagerListData}
                      modifyHeaders={modifyTableHeaders}
                    />
                  </div>
                ) : (
                  <TableSkeleton count={5} />
                )}

              </div>
            </div>
          </Box>
        </Modal>

        {addformModalOpen && <AddBusinessManagerModal
          businessManagerFormOpen={addformModalOpen}
          businessManagerFormClose={addFormClose}
          rowData={rowData}
          userData={userData}
          editData={editTaskData}

        />}


      </div>
    </>

  )
}

export default BusinessManagerModal