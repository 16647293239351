import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from 'react-cookie';

const cookies = new Cookies().getAll();

export const getjobcategorydata = createAsyncThunk("getjobcategorydata", async (reqBody) => {
    let req_body = {};
    req_body = { ...reqBody };
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/job-cat-list", requestOptions);
    const respdata = await response.json();
    return respdata;
})


export const getjobcategoryeditdata = createAsyncThunk("editrole", async (id) => {
    let reqBody = {
        "condition": {},
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/job-category-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getroledata = createAsyncThunk("getroledata", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "role/roles-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch 111==>", respdata);

    return respdata;

    // console.log("respdata.res", respdata.results.res);


})



export const getjobticketsdata = createAsyncThunk("getjobticketsdata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/addorupdatejobticket", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const geteditjobticketsdata = createAsyncThunk("geteditjobticketsdata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    let req_body = {};
    req_body = { ...reqBody };
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/job-ticket-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;
})

export const getjobticketlistdata = createAsyncThunk("getjobticketlistdata", async (reqBody) => {
    let req_body = {};
    req_body = { ...reqBody };
    if (cookies.userinfo.main_role != 'is_admin') {
        reqBody.searchcondition['ticket_addedby'] = cookies.userinfo._id
    }


    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/job-ticket-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;
})

export const getjobticketreplydata = createAsyncThunk("getjobticketreplydata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { ticketid: id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/get-reply-data", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const getchangestatusdata = createAsyncThunk("getchangestatusdata", async (data) => {
    let reqBody = {
        data
    }
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/jobticket-status-change", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const addjobcatereplydata = createAsyncThunk("addjobcatereplydata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/reply-jobticket", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


const JobTicketReducer = createSlice({
    name: 'JobTicketReducer',
    initialState: {
        submitting: false,
        loading: false,
        editloading: false,
        jobcategorydata: [],
        editjobcategorydata: [],
        roledata: [],
        jobticketsdata: [],
        jobticketlistdata: [],
        editjobticketsdata: [],
        jobcatereplydata: [],
        jobticketreplydata: [],
        changestatusdata: [],
        jobticketsubmittionstate: 0,

    },

    reducers: {
        clearjobcategorydata(state, action) {
            state.jobcategorydata = []
            state.editjobcategorydata = []
            state.jobticketsdata= []
            state.jobticketlistdata= []
            state.editjobticketsdata= []
            state.jobcatereplydata= []
            state.jobticketreplydata= []
            state.changestatusdata= []
            state.jobticketsubmittionstate= 0

        },
        cleareditjobcategorydata(state, action) {
 
            state.editjobcategorydata = []
        },

        clearroledata(state, action) {
            state.roledata = []
        },
        clearjobticketdata(state, action) {
            state.jobticketsdata = []
        },
        cleareditjobticketsdata(state, action) {
            state.editjobticketsdata = []
        },
        clearjobticketlistdata(state, action) {
            state.jobticketlistdata = []
        },
        clearjobcatereplydata(state, action) {
            state.jobticketlistdata = []
        },
        clearjobticketreplydata(state, action) {
            state.jobticketreplydata = []
        },


    },
    extraReducers: {

        [getjobcategorydata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getjobcategorydata.pending]: (state, action) => {
            state.loading = true;
            // state.jobcategorydata = [];

        },
        [getjobcategorydata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.jobcategorydata = action.payload.results.res;
                state.loading = false;
            }


        },


        [getroledata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getroledata.pending]: (state, action) => {
            state.loading = true;
        },
        [getroledata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.roledata = action.payload.results.res;
                state.loading = false;
            }


        },


        [getjobticketsdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getjobticketsdata.pending]: (state, action) => {
            state.loading = true;
        },
        [getjobticketsdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.jobcategorydata = action.payload.results.res;
                state.loading = false;
            }


        },





        [getjobticketlistdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getjobticketlistdata.pending]: (state, action) => {
            state.loading = true;
            state.jobticketlistdata = []
        },
        [getjobticketlistdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.jobticketlistdata = action.payload.results.res;
                state.loading = false;
            }


        },

        // ================ For EDIT DATA ================ //

        [getjobcategoryeditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [getjobcategoryeditdata.pending]: (state, action) => {
            state.editloading = true;
            state.jobticketsubmittionstate = 0
        },
        [getjobcategoryeditdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editjobcategorydata = action.payload.results.res;
                state.editloading = false;
                console.log('Full', state.editjobcategorydata)


            }
        },




        [geteditjobticketsdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false

        },
        [geteditjobticketsdata.pending]: (state, action) => {
            state.editloading = true;
            state.loading = true;
        },
        [geteditjobticketsdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editjobticketsdata = action.payload.results.res;
                state.editloading = false;
                state.loading = false;

                console.log('Full', state.editjobticketsdata)

            }
        },


        [getjobticketreplydata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [getjobticketreplydata.pending]: (state, action) => {
            state.editloading = true;
            state.jobticketsubmittionstate = 0
        },
        [getjobticketreplydata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.jobticketreplydata = action.payload.results.res;
                state.editloading = false;
                console.log('Full', state.jobticketreplydata)

            }
        },

        [addjobcatereplydata.rejected]: (state, action) => {
            state.jobticketsubmittionstate = 3
        },
        [addjobcatereplydata.pending]: (state, action) => {

            state.jobticketsubmittionstate = 1
        },
        [addjobcatereplydata.fulfilled]: (state, action) => {
            state.jobticketsubmittionstate = 2
        },


        [getchangestatusdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [getchangestatusdata.pending]: (state, action) => {
            state.editloading = true;
            state.jobticketsubmittionstate = 0
        },
        [getchangestatusdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.changestatusdata = action.payload.results.res;
                state.editloading = false;
                console.log('Full', state.changestatusdata)

            }
        },



    }

})


export default JobTicketReducer.reducer;
export const { clearjobcategorydata, cleareditjobcategorydata, clearjobticketreplydata, clearjobcatereplydata, clearroledata, clearjobticketdata, cleareditjobticketsdata, clearjobticketlistdata } = JobTicketReducer.actions;