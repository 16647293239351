import { Button, Checkbox, FormControlLabel, LinearProgress, Modal, TextField, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addlandingpageonedata, getAffiliateManageData } from '../../Frontend/LandingPage/LandingpageReducer';
import { useParams } from 'react-router-dom';
import { policyFormdata } from './W9FormReducer';
import '../AffiliateManagement/W9Form.css'





function AcceptPrivacy() {

    const { _id } = useParams();
    const [acceptTermsType, setacceptTermsType] = useState(false);
    const [signatureType, setsignatureType] = useState("");
    const [submitState, setSubmitState] = useState(1);
    const [error, setError] = useState(false);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();

    const affiliateState = useSelector((state) => state.LandingpagefontReducer?.affiliatedata ? state.LandingpagefontReducer.affiliatedata : null)
    const data = useSelector((state) => (state.W9FormReducer?.policyFormSubmissiontate) ? state.W9FormReducer.policyFormSubmissiontate : {})

    useEffect(() => {
        if (signatureType.length === 0) {
            setError(true)
        } else {
            setError(false)
        }


    }, [signatureType])

    const handleSubmit = () => {
        // console.log("acceptTermsType-----------", acceptTermsType);
        setSubmitState(2);
        const content = document.getElementById("policy_content").innerHTML
        let obj = {
            agent_sign: signatureType,
            policy_content: content,
            user_id: (_id !== undefined && _id !== null) ? _id : undefined,
        }
        if (error === false) {
            // console.log("obj-----------", obj);
            dispatch(policyFormdata(obj))
        }

    }
    useEffect(() => {
        // console.log("signatureType--------->", signatureType);

    }, [signatureType])


    useEffect(() => {
        let body = {
            "affiliate_signup_stage": 5,
            "_id": (_id !== undefined && _id !== null) ? _id : undefined,
        }


        if (data === 2) {
            updateApi(body)
        }
        // console.log("data---------", data);

    }, [data])
    const updateApi = async (body) => {
        setloading(true)
        await dispatch(addlandingpageonedata(body));
        dispatch(getAffiliateManageData(_id))
        setloading(false)

    }


    return (
        <>


            <div className='acceptTerms_wrapper'>

                <Box
                    className="w9_modal_heading"
                //  sx={{
                //     position: "absolute",
                //     top: "50%",
                //     left: "50%",
                //     transform: "translate(-50%, -50%)",
                //     width: "80%",
                //     bgcolor: "background.paper",
                //     border: "2px solid #000",
                //     boxShadow: 24,
                //     p: 4,
                //     textAlign: "center",
                // }}
                >

                    {/* <Typography id="modal-modal-title" variant="h6" component="h3">
                        {loader ? <LinearProgress /> : ""}

                        W9 Form Details
                    </Typography> */}
                    <div className='acceptTerms_sub_wrapper'>
                        <div id="policy_content">
                            <h2>You are agreeing to the following policy.</h2>
                            <p>I hereby attest and accept that I recognize the hazards associated with any form of misrepresentation of the ADHC RX Free Drug Subscription program, be it intentional or otherwise and the impact that same would have on both the consumer and ADHC Corporate.  Accordingly, I attest herein that I take full responsibility for mastery over the offering as it exists at the time of my signing this attestation and for the full duration of my association with same, per any modifications that may occur as well as my responsibilities for my communication of same.  Furthermore, I attest and accept that I am not permitted to create any content whatsoever as all permitted content has been created for me so as to ensure accuracy and compliance.  In accordance with the above, I fully indemnify and hold harmless ADHC Corporate, it’s ownership, officers, partners and partnerships, employees, associates and all affiliated entities from any negative consequences associated with my actions as it relates to my sales efforts, communications and any and all activity by me or on my behalf as it relates to the ADHC RX Free Drug Subscription program.  Furthermore, I accept full responsibility for all legal expenses and any monetary penalties and/or associated rewards be they related to civil or criminal actions taken against me or ADHC Corporate and/or any of same’s related parties (ownership, officers, partners, partnerships, employees, associates) both during and following my association with same.
                            </p>
                            <p><span>Note:</span> Commissions are paid monthly. Commission payments are made once commission amounts total $25.00 or more.</p>
                        </div>
                        <div className='accept_btn_wrp'>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            //  checked={getValues(fielddata.name) ? Boolean(getValues(fielddata.name)) : false}
                                            onChange={(e) => {
                                                //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                                                if (e.target.checked === true) {
                                                    setacceptTermsType(true);
                                                } else {
                                                    setacceptTermsType(false);
                                                }
                                                //   clearErrors(fielddata.name);
                                            }}
                                        />
                                    }
                                    label="I Agree to Privacy Policy"

                                />
                            </div>
                            <div className='accept_input_wrp'>
                                <TextField
                                    hiddenLabel
                                    id=""
                                    // defaultValue={firstName + ' ' + lastName}
                                    variant="filled"
                                    value={signatureType}
                                    onChange={(e) => setsignatureType(e.target.value)}
                                    helperText={
                                        submitState === 2 && signatureType?.length < 1
                                            ? "Signature is required"
                                            : ""
                                    }
                                />
                                <p>Signature</p>
                            </div>
                            <div className='accept_button'>
                                <Button
                                    onClick={handleSubmit}
                                    disabled={acceptTermsType === true ? false : true}
                                    className="w9_btn">
                                    Submit
                                </Button>
                            </div>
                        </div>
                        {data === 1 && (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>)}
                    </div>

                    {/* 
                    <Button
                        //  onClick={ModalClose}
                        className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button> */}

                </Box>






            </div>


        </>
    )
}

export default AcceptPrivacy
