import { Button } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import React, { useEffect } from 'react'
import '../NakaGamesLandingPage/NakaGamesLandingPage.css'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function NakaGamesLandingPage() {

    const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
    const username = useParams();
    useEffect(() => {
        console.log("username works", username.username);

    }, [])

    return (
        <>
            <div className='nakagames_banner'>
                {/* <img className='naka_games_logo' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/nakagames_logo.webp' /> */}
                <img className='naka_games_logo' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/nakagames_logo.webp`} />
                <div className='nakagames_wrapper'>
                    <div className='naka_banner_tetxwrp'>
                        <div className='naka_banner_sub_tetxwrp'>
                            {/* <img className='naka_accs_logo' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/naka_access_logo.webp' /> */}
                            <img className='naka_accs_logo' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/naka_access_logo.webp`} />
                            <div className='text_block'>
                                {/* <img className='position_heading' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/banner_heading.webp' /> */}
                                <img className='position_heading' src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/banner_heading.webp`} />
                                <h2>IN PARTNERSHIP WITH </h2>
                                <h1>ACCESS HEALTH FREE PHARMACY.</h1>
                                <p>Access Free Pharmacy is part of the Nakagames affiliate program. If you sign up for our Nakagames opportunity you will automatically get access to share the free Pharmacy program with your friends and family and get paid every month!  </p>

                            </div>
                            <div className='gree_grad_btn'>
                                <a href={`https://app.mynakagames.com/register/${username.username}?locale=en`} target='_blank' className='linked_tle'>
                                    <Button>Click here to sign up with Naka games right away!</Button></a>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

            <div className='nakagames_block1'>
                <div className='nakagames_wrapper'>
                    <div className='nakagames_block1_sub_wrp'>
                        <h1>Pay Nothing For The Most Prescribed Generic Prescriptions!</h1><h1> </h1>
                        <div className='block1_inner_text'>
                            <h2>Enroll today to gain access to over 605 medications for free at one low membership price!</h2>
                            <p>We are committed to improving your life and saving you money. We are turning heads as we release America’s First Free Pharmacy. Our pharmacy program makes the most used, generic medications available for FREE to everyone. These drugs treat conditions such as Diabetes, Heart Disease, High Cholesterol, and Depression.</p>
                            <p>We have assembled a team of pharmacy professionals with experience in all aspects of the industry. Our expertise resides in medication pricing, sourcing, distribution, strategy, and marketing. With decades of real-world knowledge, we set out to build the most efficient and cost-effective pharmacy in the United States. We have the most high-tech software, hardware, and processing system around and utilize its capabilities to deliver medications within 1-3 days right to your door for prices you won’t find anywhere else.</p>
                        </div>
                    </div>
                    <div className='gree_grad_btn'>
                        <a href={`https://app.mynakagames.com/register/${username.username}?locale=en`} target='_blank' className='linked_tle'>
                            <Button>Click here to sign up with Naka games right away!</Button>
                        </a>
                    </div>

                </div>

            </div>


            <div className='nakagames_block2'>
                <div className='nakagames_block2_flex'>
                    <div className='nakagames_block2_flex_img'>
                        {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/mob_coin_img.webp' /> */}
                        <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/mob_coin_img.webp`} />

                    </div>
                    <div className='nakagames_block2_flex_text'>
                        <h1>Earn 20%</h1>
                        <div className='commission_text'>
                            <h3>in commissions every single month 10% </h3><h3> direct and 10% team commissions.</h3>
                        </div>
                        <p>And earn on everything else anyone on your team decides to buy</p>
                    </div>

                </div>
                <div className='nakagames_wrapper'>
                    <div className='nakagames_flex'>
                        <div className='nakagames_flex_left'>
                            <h3>LATEST TECHNOLOGY NFT</h3>
                            <h1>Why betting on</h1>
                            <h1>Nakagames?</h1>
                            <p>Nakagames is the first platform that bets on the
                                opening of a growing market, thanks to the possibilities of NFT technology and Smart Contracts.</p>
                        </div>
                        <div className='nakagames_flex_right'>
                            <div className='ash_bg_blk'>
                                <div className='naka_circle_img'>
                                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/gadget_img.webp' /> */}
                                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/gadget_img.webp`} />
                                </div>
                                <p><span>1.</span> Play</p>
                            </div>
                            <div className='ash_bg_blk'>
                                <div className='naka_circle_img'>
                                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/NFT_img.webp' /> */}
                                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/NFT_img.webp`} />
                                </div>
                                <p><span>2.</span> Share</p>
                            </div>
                            <div className='ash_bg_blk'>
                                <div className='naka_circle_img'>
                                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/star_light_img.webp' /> */}
                                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/star_light_img.webp`} />
                                </div>
                                <p><span>3.</span> Score</p>
                            </div>
                            <div className='naka_join_btn'>
                                <a href={`https://app.mynakagames.com/register/${username.username}?locale=en`} target='_blank' className='linked_tle'>
                                    <Button>JOIN OUR TEAM</Button>
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
                <div className='nakagames_wrapper'>
                    <div className='nakagames_block2_btm'>
                        <h3>At Nakagames we love multiplayer mode, because we play as a team.</h3>
                        <p>With this philosophy we provide innovative solutions to the crownfunding landscape and we are spearheading a market that is consolidating day by day.</p>


                    </div>
                </div>
            </div>


            <div className='nakagames_block3'>
                <div className='nakagames_wrapper nakagames_wrapper2'>
                    <h1>Unlock Premium Benefits,<br></br> when you purchase your NFT for only $250</h1>
                    <ul className='naka_list'>
                        <li><span>GAME AVAILABILITY </span></li>
                        <li><span>$450,000 IN REWARDS</span></li>
                        <li><span> BLOCKCHAIN SECURITY</span></li>
                        <li><span>PROPERTY RIGHTS</span></li>
                        <li><span>EXCLUSIVE PRIZES AND REWARDS</span></li>
                    </ul>

                </div>
                <div className='nakagames_block3_inner'>
                    <div className='nakagames_wrapper'>
                        <div className='naka_block3_btm'>
                            <h1>Begin and win with CANCUN GAMES!</h1>
                            <p>Just follow the in-game prompts and immediately receive a certificate*<br></br>
                                for a <span>$2,299</span> vacation to Cancun, Mexico.</p>
                            <div className='level_box'><h3>ADVANCE TO THE NEXT LEVEL</h3></div>


                        </div>
                        <div className='naka_block3_last_blk'>
                            <div className='naka_block3_inner_flex'>
                                <div className='box_height'>
                                    <div className='green_box'>
                                        01
                                    </div></div>
                                <p>Recommend Cancun Game and
                                    connect with others to acquire their
                                    own NFT, and earn $125 in BTC.</p>

                            </div>
                            <div className='naka_block3_inner_flex'>
                                <div className='box_height'>
                                    <div className='green_box'>
                                        02
                                    </div>
                                </div>
                                <p>Cancun game provides payment
                                    every day and allows you to be part
                                    of the matrix (3 X 15).</p>

                            </div> <div className='naka_block3_inner_flex'>
                                <div className='box_height'>
                                    <div className='green_box'>
                                        03
                                    </div>
                                </div>
                                <p>Once you purchase our Cancun
                                    Game, you will be positioned in
                                    our matrix from the very first moment.</p>

                            </div> <div className='naka_block3_inner_flex'>
                                <div className='box_height'>
                                    <div className='green_box'>
                                        04
                                    </div>
                                </div>
                                <p>If you refer two people directly,
                                    you get $250 and your NFT is
                                    practically free!</p>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className='nakagames_block4'>
                <div className="nakagames_wrapper">
                    <div className='nakagames_block4_flex'>
                        <div className='nakagames_block4_flex_text'>
                            <p>Every time you add a new member to your team,<br />
                                you <span>get $5</span> in commissions, so you never stop earning!</p>
                        </div>
                        <div className='nakagames_block4_flex_img'>
                            {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/stock_graph_img.webp' /> */}
                            <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/stock_graph_img.webp`} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='nakagames_block5'>
                <div className='nakagames_block5_flex_text'>
                    <p>©2023 ALL RIGHTS RESERVED</p>
                </div>
                <div className='nakagames_block5_flex_icon'>
                    <span>
                        {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/facebook.webp' /> */}
                        <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/facebook.webp`} />
                    </span>
                    <span>
                        {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Nakagames/instagram.webp' /> */}
                        <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Nakagames/instagram.webp`} />
                    </span>
                </div>
            </div>




        </>
    )
}

export default NakaGamesLandingPage
