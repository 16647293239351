import React,{useRef} from 'react'


import "../../LandingPage5/LandingPage5.css"
import "./asvideoblock.css"

function BBblock3({scrollToview}) {

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
};

const setScroll = () => {
  scrollToview.current.scrollIntoView({ behavior: "smooth" });
};

  return (
    <div>
      <div className='landing-container'>
        <div className='lp18_block3_main_wrapper'>
          <div className='lp18_block3_full_width'>
            <div className='lp18_block3_sub_width'>
              <div className='lp18_block3_text_section'>
                <h1>PRICING</h1>
              </div>
              <div className='lp18_block3_box_section'>
                <div className='lp18_block3_square_box'>
                  <div className='lp18_block3_box_img'>
                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Landingpage18/lp18_block3_box_full_img1.webp'> */}
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Landingpage18/lp18_block3_box_full_img1.webp`} ></img>
                  </div>
                  <div className='lp18_block3_box_text'>
                    <h2>Individual</h2>
                    <h1>$21.99</h1>
                    <p>Smart Medical Access for
                      one individual member.</p>
                  </div>
                </div>
                <div className='lp18_block3_square_box box_part_2'>
                  <div className='lp18_block3_box_img img_part_2'>
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Landingpage18/lp18_block3_box_full_img2.webp`}></img>
                  </div>
                  <div className='lp18_block3_box_text text_part_2'>
                    <h2>2 Person Family</h2>
                    <h1>$26.99</h1>
                    <p>Easily add on one additional housohold family member.</p>
                  </div>
                </div>
                <div className='lp18_block3_square_box box_part_3'>
                  <div className='lp18_block3_box_img img_part_3'>
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}Landingpage18/lp18_block3_box_full_img3.webp`}></img>
                  </div>
                  <div className='lp18_block3_box_text text_part_3'>
                    <h2>Family Plan</h2>
                    <h1>$31.99</h1>
                    <p>Smart Medical Access
                      for the ontire family.</p>
                  </div>
                </div>
              </div>
              <div className='lp18_block3_green_button'>
                <button onClick={() => setScroll()} className='gradiant_fontBW gradiant_black_darkBG' >
                  <h2>Enroll today to gain access to your free medication!</h2>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BBblock3
