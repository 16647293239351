import { IconButton, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "../../../../form/Form";
import { useDispatch, useSelector } from "react-redux";
import { getRoundRobinData } from "../ManageRoundRobinReducer";


const SearchRoundRobin = ({ handleCloseDrawer, setOpenDrawer }) => {
  const [searchBody, setSearchBody] = useState(null);
  const dispatch = useDispatch();

  const searchFormSubmissionState = useSelector((state) =>state.formSlice.formSubmissionState &&state.formSlice.formSubmissionState.roundRobinSearchForm === 4? true: false);
  const searchFormData = useSelector((state) =>state.formSlice.formData.roundRobinSearchForm ? state.formSlice.formData.roundRobinSearchForm: null);
  const shortCondition = useSelector(state=>state.ManageRoundRodinReducer?.shortCondition?state.ManageRoundRodinReducer.shortCondition:false)
  const shortConditionField =useSelector(state=>state.ManageRoundRodinReducer?.shortConditionField?state.ManageRoundRodinReducer.shortConditionField:"_id")
  const sortconditionType =useSelector(state=>state.ManageRoundRodinReducer?.sortconditionType?state.ManageRoundRodinReducer.sortconditionType:"desc")




  const dateCheck = () => {
    if (
      searchFormData !== undefined &&
      searchFormData.start_date !== undefined &&
      searchFormData.start_date !== null &&
      searchFormData.end_date !== undefined &&
      searchFormData.end_date !== null &&
      searchFormData.end_date <= searchFormData.start_date
    ) {
      return false;
    }
    return true;
  };








  const generateBody = (data) => {
    console.log("data111", data);
    let modifiedBody = {
      source: "",
      condition: {
        limit: 8,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: {},
    };
     if(shortCondition){
        console.log("shortcondition",shortCondition,shortConditionField,sortconditionType);
      modifiedBody["sort"]={
        type:sortconditionType,
        field:shortConditionField
      }
    }

    Object.keys(data).forEach((curKey) => {
      console.log("data111111111", data , curKey) ;
      if (data[curKey] !== undefined && curKey !== "formId") {
          switch(curKey){
            case "_id":
              modifiedBody.searchcondition["_id"] = data._id
            break;
            case "role":
              modifiedBody.searchcondition[`roles.${data.role}`] = 1;
                modifiedBody["role"] = data.role
            break;
            case ("start_date"||"end_date"):
              modifiedBody.searchcondition["createdon_datetime"] = {$gte: data.start_date,$lte:data.end_date}
            break;
          }
        }
      if (Array.isArray(modifiedBody.searchcondition[curKey]) && modifiedBody.searchcondition[curKey].length == 0) {
        delete modifiedBody.searchcondition[curKey];
      }
      console.log("modifiedBody111", modifiedBody);
    });
    return modifiedBody;
  };







  const dataform = {
    id: "roundRobinSearchForm",
    submitBtnName: "Search",
    submitBtnEndIcon: "search_icon",
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: "refresh",
    endPointBodyWrapper: "searchcondition",
    className: "inputBlock inputBlock3line",
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {},
    fields: [
      {
        id: 0,
        label: "Search By User Name",
        name: "_id",
        type: "autoComplete",
        className: "inputBlock inputBlock3line",
        base_url:
          process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
        reqBody: {
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: "asc",
            field: "name",
          },

          searchcondition: {
            status: 1,
          },
        },
        sx: { m: 1, minWidth: 300 },
      },
      {
        id: 1,
        label: "Search By Role",
        name: "role",
        type: "autoComplete",
        className: "inputBlock inputBlock3line",
        base_url:
          process.env.REACT_APP_API_URL + "users/auto-complete-role-slug",
        reqBody: {
          sort: {
            type: "asc",
            field: "name",
          },
          searchcondition: {},
          type: { "roles.is_bdd": 1 },
        },
        sx: { m: 1, minWidth: 300 },
      },
      {
        id: 2,
        label: "Search By Email",
        name: "email",
        type: "autoComplete",
        className: "inputBlock inputBlock3line",
        base_url:
          process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
        reqBody: {
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: "asc",
            field: "name",
          },

          searchcondition: {
            status: 1,
           
          },
        },
        sx: { m: 1, minWidth: 300 },
      },
      {
        id: 8,
        label: "Search by Start Date",
        name: "start_date",
        className: "inputBlock inputBlock3line",
        type: "datePicker",
        sx: { m: 1, width: 300 },
        dateFormat: "MM/DD/YYYY",
       
      },
      {
        id: 9,
        label: "Search by End Date",
        name: "end_date",
        className: "datePicker",
        type: "datePicker",
        fullDay: true,
        sx: { m: 1, width: 300 },
        dateFormat: "MM/DD/YYYY",
        
        className: "inputBlock inputBlock3line",

        rules: { validate: { dateCheck } },
        errorMessage: {
          dateCheck: "End Date can not be less than Start Date",
        },
      },
      
    ],
  };






  useEffect(() => {
    if (searchFormSubmissionState == true && searchFormData !== null) {
      const body = generateBody(searchFormData);
      setSearchBody(body);
      console.log("body", body);
      console.log("searchFormData", searchFormData);

      dispatch(getRoundRobinData(body));

      let reqBody = { ...body, count: true };

      console.log("reqBody", reqBody);

      dispatch(getRoundRobinData(reqBody));
    }
  }, [searchFormSubmissionState, searchFormData]);

  useEffect(() => {
    if (searchFormSubmissionState == true) {
      setOpenDrawer(false);
    }

    // console.log("tempdata", imgArray);
  }, [searchFormSubmissionState]);








  return (
    <Paper
      elevation={7}
      className="list_search team_management_list_search search_img"
    >
      <div className="searchBarHead DrawerBox">
        <h2 className="searchBarHeading">Search Round Robin</h2>
        <span className="searchBarCloseIcon">
          <Tooltip title="Close">
            <IconButton onClick={() => handleCloseDrawer()}>
              <CloseIcon className="closeDrawer" />
            </IconButton>
          </Tooltip>
        </span>
      </div>

      <Form formData={dataform} />
    </Paper>
  );
};

export default SearchRoundRobin;
