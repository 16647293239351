import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const NoLinkPage = () => {
    return (
        <>
            <div className='forEmployerThanks noLinks'>
                <div className='thankyou-container'>
                    <div className='thankyou-block'>
                        <div className='contentEmployer'>
                            <Box sx={{ flexGrow: 1, }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <div className="">
                                            <h2>This link is no longer accessible.</h2>
                                            <h3>Please use the new link labeled 'Training Centre' for updated information.</h3>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoLinkPage