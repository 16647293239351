import { Button, Checkbox, Chip, FormControl, Grid, IconButton, InputLabel, LinearProgress, ListItemText, MenuItem, Modal, OutlinedInput, Select, TextField, TextareaAutosize, Tooltip, Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetresolvenew } from '../../../helper/ResolveReducer';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import ListingTable from '../../../listing/listing';
import store from '../../../store';

// import { setEnableRowLoader } from '../../../listing/listReducer';
import moment from 'moment';
import { timeConverter } from '../../../helper/helperFunctions';
import { useNavigate } from 'react-router-dom';
import LeadList from '../LeadManagement/LeadList';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const PharmacysavingsAspire = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()



    return (
        <>
            <div className='bluecoastLead_list_mainwrpr'>

                <LeadList source="PharmacysavingsAspire" />

            </div>
        </>
    )
}

export default PharmacysavingsAspire