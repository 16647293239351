import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../listing/listing';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import { IconButton, LinearProgress, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from 'react-redux';
import { DispositonTaskList, clearCurrentDispositionTaskList, clearDispositionData, clearDispositionTaskList, clearcurrentdispflag, cleardispositionTask, manageDispositionTask, statusChangeTask } from '../BlueCoastReducer';
import { clearCustomButtonClicked, clearTableData, clearTablerowData, resetRowActionBtnClicked, setReloadTableFlag } from '../../../../listing/listReducer';
import AddIcon from "@mui/icons-material/Add";
import AddTaskForm from '../AddTaskForm';
import { Form } from '../../../../form/Form';
import { setSnackbar } from '../../../Layout/layoutReducer';
import store from '../../../../store';
import TaskStatusChangeModal from '../../../../commoncomponents/modal/TaskStatusChangeModal';
import { cloneDeep } from 'lodash';
import { Cookies } from 'react-cookie';

function DispositionTaskList({ dispTaskOpen, dispTaskClose, rowData,setsaveRowdata}) {

  const cookies = new Cookies();
  const token = cookies.getAll().token;

  const dispatch = useDispatch();
  const [editTaskData, setEditTaskData] = useState(null)
  let user = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice.userInfo : null)
  let manageDispFullList = useSelector((state) => state.BlueCoastSlice?.dispositontasklistdata && state.BlueCoastSlice?.dispositontasklistdata.length > 0 ? state.BlueCoastSlice.dispositontasklistdata : []);
  const loading = useSelector((state) => state.BlueCoastSlice?.dispositionTaskLoader ? state.BlueCoastSlice.dispositionTaskLoader : false);

  const dispositionFormData = useSelector((state) => state.formSlice.formData.dispositiontask ? state.formSlice.formData.dispositiontask : null);
  const dispositionTaskSubmissionState = useSelector(state => (state.formSlice?.formSubmissionState?.dispositiontask) ? state.formSlice?.formSubmissionState?.dispositiontask : 0);
  const dispostiontaskLoader = useSelector((state) => state.BlueCoastSlice?.dispositionTaskAddloader ? state.BlueCoastSlice.dispositionTaskAddloader : false)
  
    const addapidispositiontask = async (val) => {
        // console.log("val>>>", val);
        // console.log("menuSelect",menuSelect);
        val = { ...val };
        let body = {
          tasktitle: val.tasktitle,
          taskdescription: val.taskdescription,
          tasktargetdate: val.tasktargetdate,
          currentdisposition: val.currentdisposition,
          assignee:val.assignee,
          createdon_datetime: new Date().getTime(),
          contact_id:rowData?.rep_id,
          // contact_name:rowData?.fullname,
          prospect_id: rowData?._id,
          // prospect_name:rowData?.prospectfullname,
          addedby: user._id,
          // addedbyname: user.name,
          markasdone: 1,
    
          _id:
            editTaskData && Object.keys(editTaskData).length > 0
              ? editTaskData._id
              : undefined,
    
        };
       
    
        if (Object.keys(body).length > 0) {
    
          await store.dispatch(manageDispositionTask(body));
    
        }
        for (let k in formDispositionTask.current.fields) {
          
          
          let field = cloneDeep(formDispositionTask.current.fields[k]);
          field.defaultValue = undefined;
          formDispositionTask.current.fields[k] = field;
          // console.log("k+++++++++++++",k);
          // let fieldnew =  formDispositionTask.current.fields[k]
          // fieldnew = {...fieldnew,defaultValue:undefined}
        }

        dispatch(
          setSnackbar({
            open: true,
            message:
              editTaskData && Object.keys(editTaskData).length > 0
                ? "Updated Successfully"
                : "Added Successfully",
            status: "success",
          })
        );
      
        setTimeout(() => { onCLoseDisposition()},3000)
        setEditTaskData(null) 
        dispatch(setReloadTableFlag({ tableId: "managedisptask" }));
        dispatch(cleardispositionTask())
        clearDispositionTaskList()
        // setsaveRowdata(null)
       
      };




  const handleCLoseDispTask = () => {
   

    dispTaskClose(false)
    clearDispositionTaskList()
    dispatch(clearTableData({tableId: "managedisptask"}))
    clearTablerowData()
    setsaveRowdata(null)
  }

  const [taskStatusChangeModalOpen, setTaskStatusChangeModalOpen] = useState(false)
  const [taskStatusValue, setTaskStatusValue] = useState(null)
  const [saveStatusRowval, setSaveStatusRowval] = useState(null)
  

  const taskStatusClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.managedisptask?.Block1 ? state.tableSlice.customButtonClicked.managedisptask.Block1 : false)
  const taskStatusValData = useSelector((state) => state.tableSlice.tempRowData?.managedisptask?.markasdone ? state.tableSlice.tempRowData.managedisptask.markasdone : null)

  const statusRowDataVal = useSelector((state) => state.tableSlice.tempRowData?.managedisptask && Object.keys(state.tableSlice.tempRowData.managedisptask).length>0 ? state.tableSlice.tempRowData.managedisptask : null)

  const taskEditButtonClicked = useSelector((state)=> state.tableSlice?.customButtonClicked?.managedisptask?.editalltask ? state.tableSlice.customButtonClicked.managedisptask.editalltask : false)

  const taskEditData = useSelector((state)=> state.tableSlice.tempRowData.managedisptask && Object.keys(state.tableSlice.tempRowData.managedisptask).length > 0 ? state.tableSlice.tempRowData.managedisptask : {} )

  useEffect(() => {
    if(statusRowDataVal && Object.keys(statusRowDataVal).length>0){
      setSaveStatusRowval(statusRowDataVal)
    }
  
  
  }, [JSON.stringify(statusRowDataVal)])
  

  useEffect(() => {
    if (taskStatusClicked) {
      setTaskStatusChangeModalOpen(true)
    }
  }, [taskStatusClicked])

  useEffect(() => {
    if (taskStatusValData) {
      setTaskStatusValue(taskStatusValData)

    }
    // console.log("taskStatusValue==>>", taskStatusValData);
  }, [taskStatusValData])

  const handleCloseStatusUpdate = () => {
    // dispatch(clearTablerowData({ tableId: "alltaskdata" }));
    // setSaveRowAllTask(null)
    dispatch(clearCustomButtonClicked({ tableId: "managedisptask", buttonId: "Block1" }));
    setTaskStatusChangeModalOpen(false);

  };

  const handleStatusUpdate = (markasdone) => {
    const body = {

      "_id": saveStatusRowval?._id,
      "status": markasdone

    }
    dispatch(statusChangeTask(body))


    setTimeout(() => {
      dispatch(
        setSnackbar({
          open: true,
          message: "Task Status Updated Successfully",
          status: "success",
        })
      )
    }, 1000)

    dispatch(setReloadTableFlag({ tableId: "managedisptask" }))

    setTimeout(() => {
      handleCloseStatusUpdate()
    }, 3000);


  };

  var modifyTableHeaders =
    [
      { val: "tasktitle", name: "Task Title" },
      // { val: "taskdescription", name: "Task Description" },
      // { val: "currentdisposition", name: "Disposition" },
      // { val: "addedby_name", name: "Added By" },
      { val: "assignee_name", name: "Assignee" },
      { val: "contact_name", name: "Contact" },
      { val: "prospect_name", name: "Prospect" },
      

      { val: "markasdone_type", name: "Disposition Task Status" },
      { val: "tasktargetdate", name: "Due Date", type: "datetime", format: "MM/DD/YYYY hh:mm:ss a" },

    ];

  let tableDataForManageDispTaskList = useRef({
    tableId: "managedisptask",
    // tableTitle: "Disposition Task List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/dispositiontasklist",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/dispositiontasklist",
    },

    reqBody: {
      // // "source": "field_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {
        "prospect_id": rowData?._id
      },
      "project": {},
      "token": token,
      "email": "",
      "count": false
    },


    // deleteSingleUserReqBody: {
    //   source: "training_list",
    //   secret: "AZ|lepL`",

    // },
    // deleteMultipleReqBody: {
    //   source: "training_list",
    //   secret: "AZ|lepL`",

    // },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["createdon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to delete these records?</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          // endpoint: "manage-form/delete-field",
          body: {
            "source": "field_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },

      // {
      //   id: "adduser",
      //   type: "add_icon",
      //   title: "Add Training",
      //   className: "add_user_icon",
      //   default: true,
      // },
    ],
    buttons: [
      {
        id: "editalltask",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "viewApi",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Manage Disposition Task Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/dispositiontasklist",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {
            },
            "token":token,
          }
        },
        headers: [
          { val: "tasktitle", label: "Task Title" },
          { val: "taskdescription", label: "Task Description" },
          { val: "currentdisposition", label: "Current Disposition" },
          { val: "addedby_name", label: "Added By" },
          { val: "assignee_name", label: "Assignee" },
          { val: "contact_name", label: "Contact" },
          { val: "prospect_name", label: "Prospect" },
          { val: "tasktargetdate", label: "Due Date", type: "datetime", format: "MM/DD/YYYY hh:ss a" },
          { val: "markasdone_type", label: "Disposition Task" }

        ]
      },
      {
        id: "Block1",
        type: "other",
        label: "Change Task Status",
        name: "history_toggle_off",
      },

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this Task ? This can't be undone. </p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/deletedispositiontask",
          body: {
            // source: "users",

            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },

    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      heading: "Search Task List",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          // {
          //     id: 0,
          //     label: "Search by Name",
          //     name: "fullname",
          //     type: "autoComplete",
          //     className: 'list_search_item countDiv__column--col6 ',
          //     base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforemailname",
          //     reqBody: {
          //         // "source": "field_list",
          //         "condition": {
          //             "limit": 10,
          //             "skip": 0
          //         },
          //         "sort": {
          //             "type": "desc",
          //             "field": "name"
          //         },
          //         "searchcondition": {

          //         }
          //     },
          //     sx: { m: 1, minWidth: 300 },
          //     payloadFormat: { key: "_id" },

          // },
          {
            id: 19,
            label: "Search by Assignee",
            name: "assignee_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforspecialentities",
            reqBody: {

              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "assignee"},

          },
          {
            id: 3,
            label: "Search By Contact",
            name: "contact_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "lead-manage/disposionsrepfetch",
            reqBody: {

              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "contact_id" },

          },
          {
            id: 2,
            label: "Search by Task",
            name: "task",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompletefordispositiontask",
            reqBody: {

              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "name"
              },
              "searchcondition": {
                "addedfor": rowData?._id
              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "tasktitle" },

          },
          // {
          //   id: 10,
          //   label: "Search by Disposition",
          //   name: "notessearch",
          //   type: "autoComplete",
          //   className: "list_search_item countDiv__column--col6 ",
          //   base_url:
          //     process.env.REACT_APP_API_URL +
          //     "lead-manage/autocompletedispositionscurrentdisposition",
          //   reqBody: {
          //     condition: {
          //       limit: 5,
          //       skip: 0,
          //     },
          //     sort: {
          //       type: "asc",
          //       field: "email",
          //     },
          //     searchcondition: { "addedfor": rowData?._id },
          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "currentdisposition" },
          // },
          // {
          //   id: 3,
          //   label: "Search by Added By",
          //   name: "addedby",
          //   type: "autoComplete",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompletefordispositiontaskaddedbyname",
          //   reqBody: {

          //     "condition": {
          //       "limit": 10,
          //       "skip": 0
          //     },
          //     "sort": {
          //       "type": "desc",
          //       "field": "name"
          //     },
          //     "searchcondition": {

          //     }
          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "addedbyname" },

          // },
          {
            id: 21,
            label: "Search by Task Status",
            name: "status_show",
            type: "select",
            multiple: false,
            values: [
              { val: 1, name: "Pending" },
              { val: 2, name: "Completed" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "markasdone" },
          },


          {
            id: 8,
            //   heading: "Search By Creation Time",
            label: "Search by Task Start Date",
            name: "due_date_time",
            className: 'datePicker list_search_item countDiv__column--col6',
            type: 'datePicker',
            // fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "tasktargetdate",  param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Task End Date",
            name: "end_date_by_update",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "tasktargetdate", param: "$lte" }
          },

        ]
      }
    },

  });





  const formDispositionTask = useRef({
    id: 'dispositiontask',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/addupdateemailcampaignapprovers",
    endPointBodyWrapper: "",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/r",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: (taskEditData?._id !== undefined && taskEditData?._id !== null) ? "Update" : "Submit",
    // submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {


        _id: (taskEditData._id !== undefined && taskEditData._id !== null) ? taskEditData._id : undefined,
      currentdisposition: rowData?.last_disposition_name

      //   "status": 0,
      // "roles": { is_rep: 1 },

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Task Title",
        label: "Task Title",
        name: "tasktitle",
        className: 'formfild formfild4 taskLabel',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: (taskEditData && taskEditData.tasktitle !== undefined) ? taskEditData.tasktitle : undefined,
      },
      {
        id: 15,
        heading: "Task Description",
        label: "Task Description",
        name: "taskdescription",
        className: 'formfild formfild4 dispField taskLabel',
        type: "text",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        // defaultValue: (taskEditData && taskEditData.taskdescription !== undefined) ? taskEditData.taskdescription : undefined,
      },
      {
        id:7,
        label:  "Assignee",
        heading:  "Assignee",
        name: "assignee",
        type: "autoComplete",
        preload:false,
        className: 'inputBlock inputBlock3line taskLabel',
        multiple: false,
        base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforspecialentities",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {
            // "status": 1
          }
        },
        sx: { m: 1, minWidth: 300 },
        // defaultValue: (taskEditData && Object.keys(taskEditData).length > 0 && taskEditData.assignee_name !== undefined) ? taskEditData.assignee_name : undefined,
        // defaultValue: user?._id,
      },
      // {
      //   id: 15,
      //   heading: "Prospect's Name",
      //   label: "Prospect's Name",
      //   name: "prospect_name",
      //   className: 'formfild formfild4',
      //   type: "text",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      //   disabled: true,
      //   defaultValue: (currentDisposTask && currentDisposTask.prospect_name !== undefined) ? currentDisposTask.prospect_name :saveRowdata?.prospectfullname,
      // },

      {
        id: 31,
        heading: "Due Date",
        label: "Due Date",
        name: "tasktargetdate",
        type: "dateTimePicker",
        sx: { m: 1, width: 300 },
        className: "datePicker list_search_item countDiv__column--col6 dispField taskLabel",
        // defaultValue: (taskEditData && taskEditData.tasktargetdate !== undefined) ? taskEditData.tasktargetdate : new Date().getTime(), // Always use new Date().getTime() to convert into miliseconds
        defaultValue: new Date().getTime(),
        minDate: new Date().getTime(),
        // payloadFormat: { key: "tasktargetdate", param: "$gte" },
      },
    ]

  });

  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false)

  const addTaskModal = () => {
    setAddTaskModalOpen(true)
  }

  useEffect(() => {
    if (dispositionTaskSubmissionState && dispositionTaskSubmissionState === 4) {

      addapidispositiontask(dispositionFormData);
      // setTimeout(() => {
      //   dispatch(
      //     setSnackbar({
      //       open: true,
      //       message: "Task Added Successfully",
      //       status: "success",
      //     })
      //   )
      // }, 1000)
      dispatch(setReloadTableFlag({ tableId: "managedisptask" }))         
      dispatch(clearcurrentdispflag())
      dispatch(clearDispositionData())
      dispatch(clearCurrentDispositionTaskList())
      dispatch(cleardispositionTask())



      // console.log("formInputData", dispositionFormData, dispositionFormData);
    }
  }, [dispositionTaskSubmissionState]);

  const onCLoseDisposition = () => {

    dispatch(setReloadTableFlag({ tableId: "managedisptask" }))
    setAddTaskModalOpen(false);
    clearDispositionTaskList()
    setEditTaskData(null)
    
    

  }


  useEffect(()=>{
    if(taskEditButtonClicked && taskEditData && Object.keys(taskEditData).length>0){
      setEditTaskData(taskEditData)

      for (let k in formDispositionTask.current.fields) {
        // console.log("editDataLead+++ ", formDispositionTask.current.fields[k])

        if (taskEditData[formDispositionTask.current.fields[k].name]) {
          formDispositionTask.current.fields[k].defaultValue =
            taskEditData[formDispositionTask.current.fields[k].name];
          console.warn("taskEditData+++ ", formDispositionTask.current.fields[k].name)
        }
        if (taskEditData["assignee"]) {
          if (formDispositionTask.current.fields[k].name == "assignee") {
            formDispositionTask.current.fields[k].defaultValue =
            taskEditData["assignee"];
          }}
        
      }

      setAddTaskModalOpen(true)


    }
  },[taskEditButtonClicked,JSON.stringify(taskEditData)])

  return (

    <>
      <div>
        <Modal
          className="modalBaseStyle  SentMail_mainModal maintaskList_modal"
          open={dispTaskOpen}
          onClose={handleCLoseDispTask}
        >
          <Box className="modalBaseBox mediumModal">
            <Tooltip title="Close">
              <IconButton
                onClick={handleCLoseDispTask}
                // className="CloseModal"
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className="rolelist_mainwrp All_Task_List">
              {rowData?.prospectfullname && <h2 className='previewModalTitle'><div className='modal_title'>Task List For : {rowData?.prospectfullname}</div></h2>}


              {loading ? (
                <LinearProgress sx={{ marginBottom: "10px" }} />
              ) : (
                ""
              )}
              <div className='rolelist_mainwrp_Table'>
                {!loading ? (
                  <div>
                    <button className="addTask_btn" title="Add Task" onClick={() => addTaskModal()} >
                      {" "}
                      <AddIcon />
                    </button>
                    <ListingTable
                      tableData={tableDataForManageDispTaskList.current}
                      dataset={manageDispFullList}
                      modifyHeaders={modifyTableHeaders}
                    />
                  </div>
                ) : (
                  <TableSkeleton count={5} />
                )}

              </div>
            </div>
          </Box>
        </Modal>

        {/*********** Modal For Add Task****** */}
        <Modal
          className="All_Noteswrpr addtaskListMOdal SR_addTask_list"
          open={addTaskModalOpen}
          // onClose={handleCloseDistManageModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalBaseBox mediumModal">
            <Tooltip title="Close">
              <IconButton
                onClick={() => onCLoseDisposition()}
                // className="CloseModal"
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <h2 className="previewModalTitle ">
                <div className='modal_title modal_titlenew'> 
                {editTaskData &&
                              Object.keys(editTaskData).length > 0
                              ? "Edit Task"
                              : "Add Task"} </div>
              
              </h2>
              <div className="inputBlock inputBlock3line disposition_task">
                <Box sx={{ minWidth: 120 }}>
                  <div className='otherForm_mainWrpr'>
                    <Form formData={formDispositionTask.current} />
                  </div>
              
                </Box>
              </div>
            </div>
            {dispostiontaskLoader ? (
                <Box
                  className="loaderProgress"
                  sx={{ width: "100%", mt: "10px" }}
                >
                  <LinearProgress />
                </Box>
              ) : (
                ""
              )}
          </Box>
        </Modal>


        {/* ========================Modal For Status CHanged==================== */}
        {taskStatusChangeModalOpen && <TaskStatusChangeModal
          openStatusModal={taskStatusChangeModalOpen}
          taskStatusFormClose={handleCloseStatusUpdate}
          handleStatusChange={handleStatusUpdate}
          value={taskStatusValue}
        />}

      </div>
    </>

  )
}

export default DispositionTaskList