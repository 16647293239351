import { IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { resolvefunction } from '../../../../../helper/helperFunctions';
import PopupModal from '../../../../../commoncomponents/modal/popupmodal';
import CloseIcon from "@mui/icons-material/Close";
import { commissiondelite, commissionStatusUpdate, getCommissionConfigList, getCommissionSingalData } from '../../commissionReducer';
import ConfirmationModal from '../../../../../commoncomponents/modal/confirmationModal';
import { setEnableRowLoader, setReloadTableFlag } from '../../../../../listing/listReducer';
import MultiStatusChangeModal from '../../../../../commoncomponents/modal/multipleStatusChnageModal';
import { set } from 'lodash';
import { clearDeleteData } from '../../commissionReducer';
import DNotes from '../../../Dashboard/DNotes';

const ConfigurCommissionList = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [popupToogle, setPopupToogle] = useState(false);  //--- Toogle ----//
  const [perviwModel, setPerviwModel] = useState(false);  //--- Toogle ----//
  const [open, setOpen] = useState(false);//----snack bar state-----
  const [errPopup, setErrPopup] = useState(false); // snackbar const
  const [snackMsgCopy, setSnackMsgCopy] = useState(null); // snackbar const
  const [toogle, setToogle] = useState(false);  //--- Toogle ----//
  const [confrimationMsg, setConfrimationMsg] = useState(null);
  const [btnType, setBtnType] = useState("");  //--- ButtonType Set  ----//
  const [modalResponse, setModalResponse] = useState(false);
  const [dataRef, setDataRef] = useState({})
  const [statusChangeModel, setStatusChangeModel] = useState(false)//---------state for status model
  const [statusConfrimationMsg, setStatusConfrimationMsg] = useState("")//--state for status confirmation message
  const [userStatus, setUserstatus] = useState(null)//state for status userStatus
  const [modelDelete,setModelDelete]=useState(false)
  const [openMoadlforNotes,setOpenMoadlforNotes] = useState(false)




  //-----------------------------------Status Change Button Click-------------------------
  const statusChangeClick = useSelector(state => (state.tableSlice.customButtonClicked?.configurCommission && state.tableSlice.customButtonClicked?.configurCommission?.change_status1) ? state.tableSlice.customButtonClicked.configurCommission.change_status1 : false);

  //--------------------------------------------------------------------------------------

  //----------------------------------for snack bar data---------------------------------
  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.commissionConfigForm) ? state.formSlice.formSubmissionState.commissionConfigForm : false);
  const snackMsg = useSelector(state => (state.formSlice?.message?.commissionConfigForm) ? state.formSlice.message.commissionConfigForm : null);
  //----------------------------------------------------------------------------------------

  //----------------------------------edit button fetch-------------------------------------------
  const commissionEditClick = useSelector(state => (state.tableSlice.customButtonClicked?.configurCommission && state.tableSlice.customButtonClicked?.configurCommission?.edittable9) ? state.tableSlice.customButtonClicked.configurCommission.edittable9 : false);

  //----------------------------------Preview button fetch------------------------------------------
  const previewClick = useSelector(state => (state.tableSlice.customButtonClicked?.configurCommission && state.tableSlice.customButtonClicked?.configurCommission?.previewBtnAction) ? state.tableSlice.customButtonClicked.configurCommission.previewBtnAction : false);


  //------------------------------Note button fetch----------------------------------

  const notesBtnClick = useSelector(state => (state.tableSlice.customButtonClicked?.configurCommission && state.tableSlice.customButtonClicked?.configurCommission?.notes) ? state.tableSlice.customButtonClicked.configurCommission.notes : false);



  //----------------------------------Deleted button fetch-------------------------------------------

  const deleteClick = useSelector(state => (state.tableSlice.customButtonClicked?.configurCommission && state.tableSlice.customButtonClicked?.configurCommission?.delete01) ? state.tableSlice.customButtonClicked.configurCommission.delete01 : false);

  const deletsnackmsg = useSelector(state => (state.commissionReducer?.snakebarData) ? state.commissionReducer.snakebarData : {});
  //=====================================================================================

  //-------------------fetch data for commission list------------------------

  const listData = useSelector((state) => state.commissionReducer?.commisssionListData ? state.commissionReducer.commisssionListData : [])
  const loading = useSelector((state) => state.commissionReducer?.loading ? state.commissionReducer.loading : false)

  const loadinglist = useSelector((state) => state.commissionReducer?.loadinglist ? state.commissionReducer.loadinglist : false)

  //---------------------------fetch commission row data---------------------
  const commissionRowData = useSelector((state) => state?.tableSlice?.tempRowData?.configurCommission ? state.tableSlice.tempRowData.configurCommission : {})


  
  // console.log("commissionRowData", commissionRowData);
  //=========================================================================


  
  


  //--------------Get CommissionConfig  List--------------------
  useEffect(() => {
    dispatch(getCommissionConfigList())
  }, [])
  //--------------------------------------------------------

  //------------------------------preview model configration---------------------

  useEffect(() => {
    if (previewClick === true) {
      dispatch(getCommissionSingalData(commissionRowData._id));
      setDataRef(commissionRowData)
      setPopupToogle(true)
      setPerviwModel(true)
      dispatch(setReloadTableFlag({ tableId: "configurCommission" }))

    }
  }, [previewClick])

  //============================================================================



  //--------------------Commission Edit from configration-------------------------------

  useEffect(() => {
    if (commissionEditClick) {
      resolvefunction(navigate, "/commission-add-edit?id=" + commissionRowData._id)
    }
  }, [commissionEditClick])

  //------------------------------------------------------------------------------------


  //--------------Snack Bar configration--------------------

  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrPopup(false);
    setSnackMsgCopy(null);
  };


  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );



  //---- based on endpoint call ---


  useEffect(() => {
    if (endpointProcessState == 1) {
      setOpen(false);
    }

    else if (endpointProcessState == 2) {
      setOpen(true);
      setSnackMsgCopy(snackMsg ? snackMsg : deletsnackmsg.message);

    }
    else if (endpointProcessState == 3) {

      setSnackMsgCopy(snackMsg);
      setErrPopup(true)

    }

  }, [endpointProcessState])


  //======================================================


  // -------  Delte icon Click configration ------------------------ 
  useEffect(() => {
    if (modalResponse === true) {

      let body = {
        "_id": [dataRef._id],
        "secret": "AZ|lepL`",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
      }
      dispatch(commissiondelite(body))
      


    }
  }, [modalResponse])

  useEffect(() => {
    if (deleteClick === true) {
      setBtnType("dltBtn")
      setConfrimationMsg("Are you sure you want to delete this  record? This can't be undone.")
      setToogle(true)
      setDataRef(commissionRowData)
      dispatch(
        setEnableRowLoader({
          tableId: "configurCommission",
          rowId: dataRef._id,
          load: true,
        })
      );


    }
  }, [deleteClick])

  useEffect(() => {
    if (deletsnackmsg.status === "success") {
      setSnackMsgCopy(deletsnackmsg.message)
      setToogle(false)
      setOpen(true)
      dispatch(setReloadTableFlag({ tableId: "configurCommission" }))

      setTimeout(() => {
        dispatch(clearDeleteData())
      }, 2000);
      
    }

  }, [deletsnackmsg])


  // --------------------------------


  //-------------------------Status Change Configration--------------------------------

  useEffect(() => {
    if (statusChangeClick === true) {
      setStatusConfrimationMsg("Are you sure you that want to change the status?");
      setStatusChangeModel(true)
      setDataRef(commissionRowData)
      // dispatch(setReloadTableFlag({ tableId: "configurCommission" }))


    }
  }, [statusChangeClick])




  useEffect(() => {
  
    if ((userStatus !== null && userStatus !== undefined)) {



      let body = {
        "_id": [dataRef._id],
        "status": userStatus,
        "secret": "AZ|lepL`",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
      }
      dispatch(commissionStatusUpdate(body))

      setStatusChangeModel(false)
      setTimeout(() => {
        dispatch(setReloadTableFlag({ tableId: "configurCommission" }))
      }, 2000)

    }

  }, [userStatus]);

  useEffect(() => {
    if ((userStatus !== null && userStatus !== undefined)) {
      dispatch(setReloadTableFlag({ tableId: "configurCommission" }))

    }
  }, [userStatus])

  // console.log("commission userstatus", userStatus);


  //-----------------------------------------------------------------------------------



  // ----- Table list for user -----
  var modifyTableHeaders =
    [
      { val: "programe_name", name: "Programme" },
      { val: "package_name", name: "Package Name" },
      { val: "package_price", name: "Package Price" },
      { val: "commission_amount", name: "Commission Amount ($)" },
      { val: "commission_type", name: "Type", customVal: { 'bps': "BPS", 'non_bps': "Percentage", 'flat':'Flat' }},
      { val: "bps_info", name: "BPS Info" },
      { val: "commission_percentage", name: "Commission(%)" },
      { val: "priority", name: "Priority" },
      { val: "status", customVal: { '1': "Active", '0': "Inactive", "true": "Active", 'false': "Inactive" }, name: "Status" },

      // { val: "description", name: "Description" },

    ];

  let tableConfigurCommission = useRef({
    tableId: "configurCommission",
    tableTitle: "",
    enableRowActionLoader: true,
    showReload: true,
    showFilter: true,
    adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "commission/config-list-count",
      tableCountEndpoint: "commission/config-list-count",
      deleteSingleUserEndpoint: "commission/delete",
    },

    reqBody: {

      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "count": false,

    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: "",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token: "",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10],
    sortFields: ["name", "type", "login_time", "createdon_datetime", "status", "email"],
    paginationType: "stackPagination",
    // customSort: true,
    customBtnHead: [
      {
        id: "deleteMultiple",
        type: "delete_icon",
        title: "Delete",
      },
      {
        id: "editMultiple",
        type: "toggle_on_icon",
        title: "Status Change",
      },

      {
        id: "sendMail_multiple",
        title: "Bulk Email",
        type: "forward_to_inbox_two_tone_icon",
        className: "forward_to_inbox_two_tone_icon",

      },


    ],
    buttons: [

      {
        id: "edittable9",
        type: "other",
        icon_type: "edit",
        label: "Edit",
        name: "edit",
      },


      {
        id: "change_status1",
        type: "other",
        label: "Change Status",
        name: " toggle_off ",
      },



      {
        id: "previewBtnAction",
        type: "other",
        name: "preview",
        label: "Preview",
      },

      {
        id: "delete01",
        type: "other",
        name: "delete_icon_icon",
        label: "Delete",
      },
      {
        id:"notes",
        type:"other",
        name:"summarize_icon",
        label:"Add Notes"
      }





      //  ########################### Notes ########################################


    ],
    deleteModal: {
      modalClassName: "Delete Modal Deletemodal",
    },

    searchData: {
      heading: "",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        resetBtnName: "REFRESH",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [

         
         
          {
            id: 0,
            label: "Search By Programe Name",
            name: "associated_programme",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },


          {
            id: 1,
            label: "Search By Comission Type",
            name: "comission_type",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },



          {
            id: 2,
            label: "Search By Package Name",
            name: "package_name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },



         

        ]
      }
    },

  });
  // -----------------------



  useEffect(()=>{
    // console.log("notesBtnClick",notesBtnClick);
    if(notesBtnClick === true){
      setOpenMoadlforNotes(true)
      setDataRef(commissionRowData)
    }
  },[notesBtnClick])


  return (
    <div>
      <div className='rolelist_mainwrp'>

       
        <div className='listing_heading'>
          <h1> Manage AE Commission Configure</h1>

          <Tooltip title="ADD USER">
            <button className='addrole_btn' onClick={() => navigate("/commission-add-edit")} >
              <AddIcon />
            </button>
          </Tooltip>

        </div>

        <div className='tableNote'>  
          <p> Note : NON BPS Stands For Flat Rate of Comission(%) Calculation and BPS Stands For Variable Rate of commission(%)  </p>
        </div>


        <div className='rolelist_mainwrp'>
          {/* {loading && <LinearProgress/>} */}
          <div className="reactTableWrapper">
            <div className="rolelist_mainwrp_Table table_Icons ">
              {console.warn("listData===============>>>>", listData)}
              {!loadinglist && listData ? (
                <ListingTable
                  tableData={tableConfigurCommission.current}
                  dataset={listData}
                  modifyHeaders={modifyTableHeaders}
                />
              ) : (<TableSkeleton count={5} />)}

            </div>
          </div>
        </div>

        {/* ------- Popup Modal for preview user details ------------ */}
        {perviwModel === true && commissionRowData ?
          (<PopupModal
            popupdata={dataRef}
            popuptoogle={popupToogle}
            popuptooglefunc={setPopupToogle}
            type="CommissionleInfo"
          />) : ""}
        {/* ----------------- */}

        {/* ---- SnackBar on data Submisstion */}
        {endpointProcessState === 2 || deletsnackmsg ? (<Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={snackMsgCopy}
          action={action}
        />) : ""}

        {/* ------------ */}

        {/* ------Delete Confirmation Modal ------ */}
        {toogle ? (<ConfirmationModal className="confirmaModal"
          confrimationMsg={confrimationMsg}
          toogle={toogle}
          tooglefunc={setToogle}
          setModalResponse={setModalResponse}
          setModelDelete={setModelDelete}
        />) : ""}
        {/* -------------------------------- */}

        {/* -------------------Status Confirmation Modal--------------------------------- */}
        {statusChangeModel && <MultiStatusChangeModal
          className="confirmaModal"
          confrimationMsg={statusConfrimationMsg}
          toogle={statusChangeModel}
          // deliteLeadModal={deliteLeadModal}
          tooglefunc={setStatusChangeModel}
          setModalResponse={setUserstatus}
        />}
        {/* ------------------------------------------------------------------------------------- */}
        {/* ---------------------Note Modal------------------------------------------------------- */}

        {(openMoadlforNotes === true ) && (
        <DNotes
          // openMoadlforNotesUsertype={openMoadlforNotesUsertype}
          modalComonId={dataRef._id}
          gridData={dataRef}
          openMoadl={openMoadlforNotes}
          closeModal={setOpenMoadlforNotes}
          userName={{fullname: dataRef?.programe_name}}
          types="dashboardNotes"
        />
      )}


      </div>
    </div>
  )
}

export default ConfigurCommissionList