import { Button, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function Footer() {
  const [openMoadl, setOpenMoadl] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log("location", location);
  }, [location]);

  const closeModal = () => {
    setOpenMoadl(false);
  };

  return (
    <>
      <div className="footerBody">
        {/* <span onClick={() => setOpenMoadl(true)}>Terms & Conditions</span>|{" "} */}
        {/* <span onClick={() => setOpenMoadl(true)}>Privacy Policy Copyright 2023 © Aspire Partners USA</span>  */}
        <span>Copyright 2023 © Access Discount Healthcare</span> 
        {/* <span>Copyright 2023 © Aspire Partners USA</span> */}
        {/* {location.pathname !== "/new-ae-dashboard" &&  "Aspire Partners USA"} */}
        {/* "Aspire Partners USA" */}
        {/* {location.pathname !== "/new-ae-dashboard" &&  */}
         
            
             {/* <p className="footer_mail">
              If you have any questions about the tools in this Dashboard, Please
              Contact{" "}
              <a
                href="mailto:support@aspirepartnersusa.com"
                className="mail_to"
              >
                support@aspirepartnersusa.com
              </a>{" "}
            </p>  */}
         
        {/* // } */}
      </div>

      <div className="SD_comming_modal_main">
        <Modal
          classname="image_card_modal modalBaseStyle SD_comming_modal"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal SD_comming_modal_wrp">
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            <h1>Coming Soon</h1>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Footer;
