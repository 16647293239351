import React from 'react'
import { Cookies } from 'react-cookie';
import { resolvefunction } from '../../helper/helperFunctions';
import { getusersList } from '../Backend/userManagement/userList/userListReducer';
import {MdPerson} from "react-icons/md"
import { useLocation, useNavigate } from 'react-router-dom';
const CustomerSupportMenu = () => {
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

  return (
    <>
    {/* //-------------------Menue----------------*/}
        <div>
            <ul>
                {getcookies?.userinfo?.roles?.customer_support == 1  ? (
                    <li>
                        <button onClick={() => resolvefunction(navigate, "/usermanagement", [getusersList()])} className={pathname && pathname == "/usermanagement" ? "active" : ""} >
                            <MdPerson />
                            <span>Users</span>
                        </button>
                    </li>) : ""}
            </ul>
        </div>        
    </>
  )
}

export default CustomerSupportMenu