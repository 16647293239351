import React, { useEffect, useRef, useState } from 'react'
import '../Components/LandingPage14.css'
import Lp14block2 from './Lp14block2'
import Lp14blocklogo from './Lp14blocklogo'
import Lp14block5 from './Lp14block5'
import Lp14block1 from './Lp14block1'
import Lp14block3 from './Lp14block3'
import Lp14block6 from './Lp14block6'
import Lp14block4 from './Lp14block4'
import Lp14block8 from './Lp14block8'
import Lp14block7 from './Lp14block7'
import Lp14block9 from './Lp14block9'
import Lp14block10 from './Lp14block10'
import Lp14blockheader from './Lp14blockheader'
import Lp14blockfooter from './Lp14blockfooter'
import { addconverstiondata } from '../../LandingpageReducer'
import { useDispatch, useSelector } from 'react-redux'


function LandingPage14() {
  const dispatch = useDispatch()
  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
  const [conversionapicall, setConversionapicall] = useState(false);

  useEffect(() => {
    console.log("ipinfo++++++", ipinfo, userData);
    if (userData && ipinfo) {
      console.log("userData", userData);
      let body = {
        // "campaign_id":userData.campaign_id,
        "associated_program": userData.associated_program,
        "landing_page_id": userData.landing_page_id,
        "landing_page_slug": userData.landing_page_slug,
        // "campaign_added_by":userData.user_id,
        // "hiring_manager":userData.hm_id,
        "ipInfo": ipinfo
      }
      if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
      if (userData.user_id) body['campaign_added_by'] = userData.user_id;
      if (userData.hm_id) body['hiring_manager'] = userData.hm_id;
      if (userData.rep_id) body['rep_id'] = userData.rep_id;
      console.log("+++++++++++++++++++", body);
      if (body && body.landing_page_id && !conversionapicall) {
        dispatch(addconverstiondata(body))
        setConversionapicall(true)

      }

      //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
      //    console.log("bodyc",bodyc);
    }

  }, [JSON.stringify(ipinfo), JSON.stringify(userData)])
  const ref = useRef();
  useEffect(() => {
    if (userData.pixel_tracking_click) {
      const node = document.createRange().createContextualFragment(userData.pixel_tracking_click);
      ref.current.appendChild(node);
    }
  }, [JSON.stringify(userData)])
  return (
    <>
      <div className='landingoage14_main'>
        {userData.pixel_tracking_click &&
          //  <span className='visiblehiddenclas' dangerouslySetInnerHTML={{__html: userData.pixel_tracking_click}}></span>
          <span className='visiblehiddenclas' ref={ref}></span>

        }
        <Lp14blockheader />
        <Lp14blocklogo />
        <Lp14block1 />
        <Lp14block2 />
        <Lp14block3 />
        <Lp14block4 />
        <Lp14block5 />
        <Lp14block6 />
        <Lp14block7 />
        <Lp14block8 />
        <Lp14block9 />
        <Lp14block10 />
        <Lp14blockfooter />
      </div>
    </>
  )
}

export default LandingPage14