import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
// import * as React from 'react';
import React, { useEffect, useRef, useState, useReducer } from 'react'

import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { BlueCoastlist } from '../BlueCoastReducer';
import { setReloadTableFlag } from '../../../../listing/listReducer';

const initialState = { visible: false };                      // initial state for useReducer hook
const reducer = (state, action) => {                          // actions for useReducer
  switch (action.type) {
    case "show":
      return { visible: true };

    case "hide":
      return { visible: false };

    default:
      return state;
  }
};

// main functiona component for search key started

function SearchKey({ requestBody, setLoadingKey, menuSelect, reQuestNewsearchConditin }) {

  const dispatchVal = useDispatch()

  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchVal, setSearchVal] = React.useState('');
  const [searchDisp, setSearchDisp] = React.useState('');


  // ==========search buttons visibility functions==============
  function showSearchIcon() {
    dispatch({ type: "show" });
  }
  function hideSearchIcon() {
    dispatch({ type: "hide" });
  }


  //====================input event for keywrod search=======================
  const dataInputfield = (e) => {
    let eventVal = e.target.value

    setSearchVal(eventVal)
    console.warn("eventVal15241652=======>>>", eventVal);


    if (eventVal === '') {
      hideSearchIcon()
      // console.log("eventVal======>>>>", eventVal, searchVal);

      if (
        requestBody &&
        requestBody.searchcondition && Object.keys(requestBody.searchcondition).length > 0
      ) {
        Object.keys(requestBody.searchcondition).forEach(key => {
          if (key == 'all_field')
            delete requestBody.searchcondition[key]

        });

      }
      // dispatchVal(setReloadTableFlag({ tableId: "bluecoastdata" }));
      dispatchVal(BlueCoastlist(requestBody))


    }
    else if (eventVal !== '') {
      showSearchIcon()
    }
    else {
      hideSearchIcon()
    }

  }

  //====================input event for disposition search=======================

  const inputDisposition = (event) => {
    let eventDisp = event.target.value
    setSearchDisp(eventDisp)
    // console.log(eventDisp, "eventDisp================>>>>>>>>>>>>")
    // console.log("eventDisp==============>>>>>>>1", eventDisp);

    if (eventDisp !== '') {
      showSearchIcon()
    }
    else if (eventDisp === '') {
      hideSearchIcon()
      setSearchDisp('')
      if (
        requestBody &&
        requestBody.searchcondition && Object.keys(requestBody.searchcondition).length > 0
      ) {
        Object.keys(requestBody.searchcondition).forEach(key => {
          if (key == 'last_disposition_name')
            delete requestBody.searchcondition[key]

        });

      }
      dispatchVal(BlueCoastlist(requestBody))

      // console.log("eventDisp==============>>>>>>>2", eventDisp);
    }
    else {
      hideSearchIcon()
    }
  }

  // search button clicked functioanlity===============>>>>

  // const [searchCOnditionSet, setSearchCOnditionSet] = useState(null)

  // useEffect(() => {

  //   if (reQuestNewsearchConditin.length > 0) {
  //     setSearchCOnditionSet(reQuestNewsearchConditin)
  //     console.log("searchCOnditionSet=>>>", reQuestNewsearchConditin)

  //   }
  // }, [reQuestNewsearchConditin])


  const searchButtonClicked = async () => {



    // requestBody.searchcondition = { searchCOnditionSet };

    // console.log("searchCOnditionSet=>>>", searchCOnditionSet)

    // console.warn("blockHittt===>>>1");


    console.warn("searchVal===>>>", searchVal);






    if (searchVal !== '' && searchVal !== undefined) {

      if (requestBody && requestBody.searchcondition["all_field"] && Object.keys(requestBody.searchcondition["all_field"]).length > 0) {
        delete requestBody.searchcondition["all_field"]
        // console.log("blockHittt===>>>1")
      }

      let all_field = { $regex: searchVal, $options: "i" }
      let newSearchCondition = { all_field, ...requestBody.searchcondition }
      // console.warn("blockHittt===>>>3");
      requestBody.searchcondition = newSearchCondition


      console.log("newSearchCondition==============>>>>>>>>", newSearchCondition);

    } else if (searchDisp !== '' && searchDisp !== undefined) {

      if (requestBody && requestBody.searchcondition["last_disposition_name"] && Object.keys(requestBody.searchcondition["last_disposition_name"]).length > 0) {
        delete requestBody.searchcondition["last_disposition_name"]
        // console.log("blockHittt===>>>2")
      }
      let last_disposition_name = { $regex: searchDisp, $options: "im" }
      // console.log("searchDisp===========>>>>", searchDisp);
      let newSearchfordisp = { last_disposition_name, ...requestBody.searchcondition }
      // console.log("blockHittt===>>>4");

      requestBody.searchcondition = newSearchfordisp
    }
    requestBody.condition.skip = 0;

    // console.log("requestBody=====>>>>", requestBody)

    await dispatchVal(BlueCoastlist(requestBody))

    // console.log("searchVal===========hit>>>", searchVal);
    // console.log("blockHittt===>>>3");

  }

  // refresh button clicked functioanlity===============>>>>

  const refreshClicked = () => {
    setSearchVal('')
    setSearchDisp('')

    if (requestBody && requestBody.searchcondition["all_field"] && Object.keys(requestBody.searchcondition["all_field"]).length > 0) {
      delete requestBody.searchcondition["all_field"]
      // console.log("delete hit===>>>1")
    }


    if (
      requestBody &&
      requestBody.searchcondition && Object.keys(requestBody.searchcondition).length > 0
    ) {
      Object.keys(requestBody.searchcondition).forEach(key => {
        if (key == 'all_field')
          delete requestBody.searchcondition[key]
        // console.log("searchCondiftionRemoved=================>>>>1");
      });

      // console.log("requestBody.searchcondition===========>>>> 1", requestBody.searchcondition);


    }
    if (
      requestBody &&
      requestBody.searchcondition && Object.keys(requestBody.searchcondition).length > 0
    ) {
      Object.keys(requestBody.searchcondition).forEach(key => {
        if (key == 'last_disposition_name')
          delete requestBody.searchcondition[key]
        // console.log("searchCondiftionRemoved=================>>>>2");

      });

      // console.log("requestBody.searchcondition===========>>>> 2", requestBody.searchcondition);

    }
    requestBody.condition.skip = 0;


    dispatchVal(BlueCoastlist(requestBody))
    // console.log("delete hit===>>>2")

    // dispatchVal(setReloadTableFlag({ tableId: "bluecoastdata" }));


  }


  useEffect(() => {
    if (menuSelect === 1) {
      setSearchVal('')
      setSearchDisp('')
    } else if (menuSelect === 2) {
      setSearchVal('')
      setSearchDisp('')
    } else if (menuSelect === 5) {
      setSearchVal('')
      setSearchDisp('')
    } else if (menuSelect === 6) {
      setSearchDisp('')
      setSearchVal('')
    } else if (menuSelect === 9) {
      setSearchDisp('')
      setSearchVal('')
    } else if (menuSelect === 10) {
      setSearchDisp('')
      setSearchVal('')
    }
    dispatchVal(BlueCoastlist(requestBody))
  }, [menuSelect])





  return (
    <>
      <div className='KeywordSerach_Main'>

        {/* ===========================keyWord Search============================= */}
        <TextField id="outlined-basic" className='mainKeywrd_search' value={searchVal ? searchVal : ""} label="Keyword Search" variant="outlined" onChange={dataInputfield} />

        {/* =============================disposition Search========================= */}
        <div className='dispositionKey_search'>
          <Box sx={{ minWidth: 120 }}>
            <FormControl id="disposition-select" fullWidth>
              <InputLabel id="demo-simple-select-label">Search by Disposition</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchDisp}
                label="Search by Disposition"
                onChange={inputDisposition}
              >
                <MenuItem value={""}>None </MenuItem>
                <MenuItem value={"New Lead"}>New Lead </MenuItem>
                <MenuItem value={"Accepted"}>Accepted</MenuItem>
                <MenuItem value={"Declined"}>Declined</MenuItem>
                <MenuItem value={"Advisor/AE Contacted"}>Advisor/AE Contacted</MenuItem>
                <MenuItem value={"Advisor/AE Meeting Set"}>Advisor/AE Meeting Scheduled</MenuItem>
                <MenuItem value={"Advisor/AE Meeting Completed"}>Advisor/AE Meeting Completed</MenuItem>
                <MenuItem value={"Advisor/AE Meeting Missed"}>Advisor/AE Meeting Missed</MenuItem>
                <MenuItem value={"Discovery Call Set"}>Discovery Call Scheduled</MenuItem>
                <MenuItem value={"Discovery Call Completed"}>Discovery Call Completed</MenuItem>
                <MenuItem value={"Discovery Call Cancelled"}>Discovery Call Cancelled</MenuItem>
                <MenuItem value={"Discovery Call Missed"}>Discovery Call Missed</MenuItem>
                <MenuItem value={"Contract Sent"}>Contract Sent</MenuItem>
                <MenuItem value={"Contract Review Scheduled"}>Contract Review Scheduled</MenuItem>
                <MenuItem value={"Contract Review Completed"}>Contract Review Completed</MenuItem>
                <MenuItem value={"Contract Signed"}>Contract Signed</MenuItem>
                <MenuItem value={"Contract Completed"}>Contract Completed</MenuItem>
                <MenuItem value={"Data Call Requested"}>Data Call Requested</MenuItem>
                <MenuItem value={"Data Call Scheduled"}>Data Call Scheduled</MenuItem>
                <MenuItem value={"Data Call Completed"}>Data Call Completed</MenuItem>
                <MenuItem value={"Link Sent"}>Link Sent</MenuItem>
                <MenuItem value={"Has Contracts No Claims Uploaded"}>Has Contracts No Claims Uploaded</MenuItem>
                <MenuItem value={"Has Claims No Contracts Uploaded"}>Has Claims No Contracts Uploaded</MenuItem>
                <MenuItem value={"Data and Contracts Upload Completed"}>Data and Contracts Upload Completed</MenuItem>
                <MenuItem value={"Initial Evaluation"}>Initial Evaluation</MenuItem>
                <MenuItem value={"On Hold"}>On Hold</MenuItem>
                <MenuItem value={"Collection Begun"}>Collection Begun</MenuItem>
                <MenuItem value={"Follow-Up Collection"}>Follow-Up Collection</MenuItem>
                <MenuItem value={"In Settlement"}>In Settlement</MenuItem>
                <MenuItem value={"Account Cancelled"}>Account Cancelled</MenuItem>
                <MenuItem value={"Lost"}>Lost</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* ===============================Search & Reload button================================= */}
        <div className='buttons_keyword'>
          {state.visible && <Tooltip title="Search"> <Button onClick={searchButtonClicked}><SearchIcon /></Button> </Tooltip>}
          {state.visible && <Tooltip title="Reload"> <Button onClick={refreshClicked}><RefreshIcon /></Button> </Tooltip>}
        </div>
      </div>

    </>
  )
}

export default SearchKey