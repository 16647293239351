import React, { useEffect, useState } from 'react'
import "../Components/LandingPage14.css"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ReactPlayer from 'react-player';
function Lp14block1() {
  const [play, SetPlay] = useState(false);
  const [seek, setSeek] = useState(1);
  let [pause, setPause] = useState(1);

  const videoPlay = () => {

    var element = document.getElementById("classGrp");
    console.log("element",element);
    element.classList.remove("Lp14block1_sub_left_wrapper2");

    SetPlay(true)

  }

  const videoPause = () => {

  }

  useEffect(() => {
    console.log("seek in useeffect++++", seek, pause, 'Play', play);
    // setTimeout(() => {
    //   console.log("seek in useeffect in set",seek,pause);
    //   if(seek==1 && pause==2){
    //     // SetPlay(false)
    //   }
    // }, 1000);
    
  }, [seek, pause, play])




  return (
    <>
      <div className='Lp14block1_main_wrapper'>
        <div className='Lp14block1_sub_wrapper'>
          <div id="classGrp" className='Lp14block1_sub_left_wrapper Lp14block1_sub_left_wrapper2'>
            <div className='Lp14block1_sub_sec1_wrapper'>
              <h1>ACCESS FREE <br></br> PHARMACY</h1>

              <h2>Pay Nothing For The Most <br></br>
                Prescribed Prescriptions!</h2>
              <h4>With the cost of your prescription
                medications rising, there is no better
                time to subscribe to get them for free</h4>
              <p>This is a subscription service. It is not a discount
                or insurance plan. </p>
            </div>
          </div>
          <div className='Lp14block1_sub_right_wrapper'>
            {play && <ReactPlayer className='page14_react_player'
              // url='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/AspirePharma_banner_video.mp4'
              url={`${process.env.REACT_APP_PHARMACY_SAVINGS_IMG_URL}AspirePharma_banner_video.mp4`}
              // light="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_1block_2bg.webp"
              // playing={true}
              controls={true}
              // onPlay={videoPlay()}
              onPause={() => {

                console.log("pause hit");
                // setTimeout(() => {
                // if (seek == 1) {
                // console.log("pause hit in intervel",seek);
                // SetPlay(false)
                // let val = pause++
                setPause(2)
                // }
                // }, 2000)
              }
              }
              // autoplay={true}
              // muted={true}
              onSeek={() => {
                // console.log("seek hit",seek)
                setSeek(2)
                // SetPlay(true)
                // setTimeout(() => {
                //   console.log("seek hit Interval",seek)
                //   setSeek(1)
                // }, 4000);
              }}
              playing={play}
            />}
            {!play &&
              <>
                <div className='SD_video_img'>
                  {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_1block_2bg.webp' /> */}
                  <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_1block_2bg.webp`} />
                </div>
                <div  id="playBtn" onClick={() => videoPlay()} className='Lp14block1_sub_sec2_wrapper'>
                  <div className='Lp14block1_sub_sec2_wrapper_ply_btn'>
                    <PlayCircleOutlineIcon />
                  </div>
                  <button>Play Video</button>
                </div></>}
          </div>






        </div>
      </div>
    </>
  )
}

export default Lp14block1