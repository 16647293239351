import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import W9Form from '../../Backend/AffiliateManagement/W9Form';
import LandingPage16 from '../LandingPage/LandingPage16/LandingPage16'
import { clearforceloginapidata, forcelogin, getAffiliateManageData } from '../LandingPage/LandingpageReducer';
import NDAContractForm from '../NDAContractForm/NDAContractForm';
import { resolvefunction } from '../../../helper/helperFunctions';
import { getlandingpageForRep } from '../../Backend/Dashboard/DashboardReducer';
import { Cookies } from 'react-cookie';
import { setLogedinUserInfo } from '../../login/loginReducer';
import AcceptPrivacy from '../../Backend/AffiliateManagement/AcceptPrivacy';
import ThankYouPageEmployer from '../../Backend/EmployerManagement/EmployersFormsRedirect/ThankYouPageEmployer';



function AffilaiteFormsRedirect() {


  const { _id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const cookies = new Cookies();
  const getcookies = cookies.getAll();

  const [affiliateFlag, setAffiliateFlag] = useState('');


  console.log("affiliateFlag=======>>>>>", affiliateFlag)

  useEffect(() => {

    if (_id != null && _id != undefined) {
      console.log("getUIdUser============>>>>", _id);
      dispatch(getAffiliateManageData(_id))
    }

  }, [])

  const Loading = useSelector((state) => state.LandingpagefontReducer?.loader ? state.LandingpagefontReducer.loader : false)

  const affiliateState = useSelector((state) => state.LandingpagefontReducer?.affiliatedata ? state.LandingpagefontReducer.affiliatedata : null)

  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)

  const affiliateInfo = useSelector((state) => (state.LandingpagefontReducer?.affiliatedata) ? state.LandingpagefontReducer.affiliatedata : {})

  const forceloginapicallfalg = useSelector(state => (state.LandingpagefontReducer?.forceloginapicall) ? state.LandingpagefontReducer?.forceloginapicall : false)

  const forceLOgindata = useSelector(state => (state.LandingpagefontReducer?.forcelogindata) ? state.LandingpagefontReducer?.forcelogindata : null)


  const submittionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer?.landingpagesubmittionstate : null)





  useEffect(() => {
    if (affiliateState && affiliateState.affiliate_signup_stage) {

      console.log("affiliateState=========>>>>>", affiliateState.affiliate_signup_stage)
      setAffiliateFlag(affiliateState.affiliate_signup_stage)
    }
  }, [affiliateState])




  // ------------------- Force login fire  --------------------//

  // useEffect(() => {
  //   if (Object.keys(affiliateInfo).length > 0 && affiliateInfo.affiliate_signup_stage === 5) {
  //     redirectToAffiliateDashboard();
  //     // redirectandcookieset();
  //   }
  //   console.log("affiliateInfo==============Hitttt", affiliateInfo)
  // }, [affiliateInfo])

  const redirectToAffiliateDashboard = async () => {
    // setloader(true)
    let data = {
      "email": affiliateInfo.email,
      // "password": affiliateInfo.email,
      "_id": affiliateInfo._id,
      "login_time": Math.round(new Date().getTime()),
      "login_data": ipinfo
    }
    dispatch(forcelogin(data))
  }

  //  ---------------------------------------------- ///






  const redirectandcookieset = async () => {
    let userInfodata = {
      "main_role": forceLOgindata.item.main_role,
      ...forceLOgindata.item.results[0]
    }
    await cookies.remove("userinfo", { path: "/" });
    await cookies.remove("lastLoginTime", { path: "/" });

    await cookies.set('userinfo', userInfodata, { path: '/' });
    await cookies.set('token', forceLOgindata.token, { path: '/' });
    await cookies.set('lastLoginTime', new Date().getTime(), { path: '/' });
    if (!userInfodata.last_login_time) {
      userInfodata.last_login_time = new Date().getTime()
    }
    let dataset = {
      "userinfo": userInfodata,
      "token": forceLOgindata.token
    }
    await dispatch(setLogedinUserInfo(dataset));
    // setloader(false)
    navigate('/affiliate-dashboard');
    dispatch(clearforceloginapidata())
  }


  useEffect(() => {
    if (forceloginapicallfalg && forceLOgindata) {
      redirectandcookieset()
      // console.warn(existingUserInfo, "existingUserInfo>>>>");
    }
  }, [JSON.stringify(forceLOgindata), forceloginapicallfalg])










  // const navigateFunction = () => {
  //   resolvefunction(navigate, '/affiliate-dashboard', getlandingpageForRep())
  // }







  return (
    <>
      {Loading && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}
      {affiliateFlag == 1 && <LandingPage16 />}
      {/* {affiliateFlag == 2 && <W9Form />} */}
      {affiliateFlag == 2 && <NDAContractForm />}
      {/* {affiliateFlag == 3 && <W9Form />} */}

      {/* {affiliateFlag == 4 && <AcceptPrivacy />} */}
      {/* {affiliateFlag == 4 && redirectandcookieset()} */}

      {affiliateFlag == 5 && <ThankYouPageEmployer/>}


    </>
  )
}

export default AffilaiteFormsRedirect