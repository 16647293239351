import React, { useEffect, useRef,useState } from 'react'
import { useSelector } from 'react-redux';
import ListingTable from "../../../../listing/listing";
import {useNavigate} from 'react-router-dom';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { resetresolvenew } from '../../../../helper/ResolveReducer'
import AddIcon from '@mui/icons-material/Add';
import { Cookies,useCookies } from 'react-cookie';
import store from '../../../../store';
import { clearjobticketlistdata, getjobticketlistdata, geteditjobticketsdata, clearjobticketdata } from '../JobTicketReducer';
function ManageJobtickets(props) {
  const navigate = useNavigate();
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  let responseData = useSelector(state => (state.JobTicketReducer?.jobticketlistdata && state.JobTicketReducer?.jobticketlistdata.length > 0) ? state.JobTicketReducer.jobticketlistdata : [])
  let loader = useSelector(state => (state.JobTicketReducer?.loading) ? state.JobTicketReducer.loading : false)
  const resolved = useSelector(state => state.ResolveReducer.resolved['/manage-jobtickets'] ? state.ResolveReducer.resolved['/manage-jobtickets'] : false)
  const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.jobtickettable?.editjobtickets ? state.tableSlice?.customButtonClicked?.jobtickettable?.editjobtickets : false)
  const editData = useSelector(state => state.tableSlice?.tempRowData?.jobtickettable ? state.tableSlice.tempRowData.jobtickettable : {})
  const replyClicked = useSelector(state => state.tableSlice?.customButtonClicked?.jobtickettable?.replyjobtickets ? state.tableSlice?.customButtonClicked?.jobtickettable?.replyjobtickets : false)
  const apipreviewdata = useSelector(state => state.tableSlice?.apiViewActionData?.jobtickettable ? state.tableSlice?.apiViewActionData?.jobtickettable : false)


  
  useEffect(() => {
    console.log("jgfjh",editClicked,editData);
    if (editClicked && Object.keys(editData).length > 0) {
      resolvefunction(navigate, `/edit-jobtickets/${editData._id}`, geteditjobticketsdata(editData._id))
    }
  }, [editClicked]) 


  useEffect(() => {
    if (replyClicked && Object.keys(editData).length > 0) {
      resolvefunction(navigate, `/reply-jobticket/${editData._id}`, geteditjobticketsdata(editData._id))
    }
  }, [replyClicked]) 




  useEffect(() => {
if (!resolved){
  store.dispatch(getjobticketlistdata(reqbody)); 
} 
    
    return () => {
      store.dispatch(clearjobticketdata())
      store.dispatch(clearjobticketlistdata())
      store.dispatch(resetresolvenew('/manage-jobtickets'))
    }
  }, [])



  var modifyTableHeaders =
    [
      {
        val: "ticket_id", name: "Ticket Id" },
      { val: "ticket_name", name: "Title" },
      { val: "category_name", name: "Ticket Category" },
      { val: "hm_fullname", name: "Name" },
      { val: "hm_email", name: "Email" },
      // { val: "subject_val", name: "Subject" },
      { val: "priority", name: "Priority", customVal: { "medium": 'Medium', "high": "High", "Low":"Low" } },
      { val: "createdon_datetime", name: "Created On", type: "datetime", format: "MM/DD/YYYY" },
      { val: "status", name: "Status", customVal: { "open": 'Open', "close": 'Close', "suspend": "Suspend", "replied": "Replied", "awaiting_support_reply": "Awaiting Support Reply" } },
    ];
  let reqbody = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {
      "ticket_addedby": props.source==="folderView"? props.userid: userInfo?.userinfo?.main_role !== "is_admin" ? userInfo?.userinfo?._id : undefined
    },
    "project": {},
    "token": ""
  }

  const tableData1 = useRef({
    tableId: "jobtickettable",
    tableTitle: "Jobtickets",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/job-ticket-list-count",
      deleteSingleUserEndpoint: "lead-manage/delete-job-ticket",
      tableCountEndpoint: "lead-manage/job-ticket-list-count",
    },
    reqBody: {
      "source": "",
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "type": "desc",
        "field": "createdon_datetime"
      },
      "searchcondition": {
        "ticket_addedby":  props.source==="folderView"? props.userid : userInfo?.userinfo?.main_role !== "is_admin" ? userInfo?.userinfo?._id : undefined
      },
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      "source": "users",
      "secret": "AZ|lepL`",
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
    },
    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["ticket_id", "ticket_name","category_name", "status", "priority", "createdon_datetime"],
    clickableColumn: ["firstname", "lastname"],
    enableRowActionLoader: true,
    paginationType: "stackPagination",
    customBtnHead: [
      {
        id: "deleteMultiple",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-job-ticket",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      }
    ],
    buttons: [
      {
        id: props.source=="folderView"? "editdata" :"editjobtickets",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "deletejobticketcategory",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-job-ticket",
          body: {
            "source": "lead_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "viewBtnAction",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Job Ticket Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/job-ticket-list-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {}
          }
        },
        headers: [
          {
            val: "ticket_id", label: "Ticket Id" },
          { val: "ticket_name", label: "Title" },
          { val: "ticket_des", label: "Ticket Description" },
          { val: "category_name", label: "Ticket Category" },
          { val: "status", label: "Status", customVal: { "open": 'Open' } },
          { val: "hm_fullname", label: "Name" },
          { val: "hm_email", label: "Email"},
          { val: "subject_val", label: "Subject"},
          { val: "priority", label: "Priority", customVal: { "medium": 'Medium', "high": "High", "Low": "Low" } },
          // { val: "url_img", label: "Ticket Attachment", type: "image" },
          { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
        ]
      },
      {
        id: "replyjobtickets",
        type: "other",
        icon_type: "chat",
        label: "Reply",
        name: "chat",
        className: "chat",
      },
     

    ],
    deleteModal: {
      modalClassName: "Delete Modal delete_modal",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchData: {
      heading: "Job Ticket Search",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          
          {
            id: 0,
            label: "Search By Ticket ID ",
            name: "ticket_id",
            type: 'text',
            inputType:"number",
            className: "inputblock2line",
            payloadFormat: { key: "ticket_id" },

          },
          {
            id: 1,
            label: "Search By Ticket Title ",
            name: "ticket_name",
            type: 'text',
            className: "inputblock2line",
            payloadFormat: { key: "ticket_name", param: "$regex", options: "i" },

          },
          {
            id: 2,
            label: "Search By priority ",
            name: "priority",
            type: 'select',
            multiple: false,
            values: [
              {val:'low', name:"Low"},
              {val:'mid', name:"Mid"},
              {val:'high', name:"High"}
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'inputblock2line',
            payloadFormat: { key: "priority", param: "$regex", options: "i"  },

          },


          {
            id: 3,
            label: "Search by Status",
            name: "status",
            type: 'select',
            multiple: false,
            values: [
              {val:'open', name:"Open"},
              {val:'close', name:"Close"},
              {val:'awaiting_support_reply', name:"Awaiting Support Reply"},
              {val:'suspend', name:"Suspend"},
              { val: 'replied_by_support', name:"Replied by Support"},
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'inputblock2line',
            payloadFormat: { key: "status" , param: "$regex", options: "i" },
          },

          {
            id: 4,
            label: "Search by Start Date",
            name: "createdon_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: 'inputblock2line',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 5,
            label: "Search by End Date",
            name: "createdon_datetime1",
            className: 'inputblock2line',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },


        ]
      }
    },
  })


  useEffect(()=>{
    if(apipreviewdata && apipreviewdata[0].url_img.length > 0){
      tableData1.current.buttons[2].headers.push({ val: "url_img", label: "Ticket Attachment", type: "image" })
    }else{
        tableData1.current.buttons[2].headers.map((item,index)=>{
          if(item.val === "url_img"){
            tableData1.current.buttons[2].headers.splice(index,1)
          }
        })
    }
  },[apipreviewdata])



  return (
    <>
      <div className='rolelist_mainwrp'>
      {props.source!=="folderView"?  (<div className='listing_heading'>
          <h1> Manage Job Tickets</h1>
          <button className='addrole_btn' onClick={() => navigate('/add-Jobtickets')}><AddIcon /></button>
        </div>): ""}
        <div className='rolelist_mainwrp_Table'>
          {(responseData && responseData.length > 0) ? <ListingTable tableData={tableData1.current} dataset={responseData} modifyHeaders={modifyTableHeaders} /> : <p>No Records Found</p>}
        </div>
      </div>
    </>
  )
}

export default ManageJobtickets
