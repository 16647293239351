import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../listing/listing';
import { Button, Drawer, IconButton, LinearProgress, Paper, Snackbar, TableContainer, Tooltip } from '@mui/material';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommisionReportData, getcommisionReportData } from '../W9FormReducer';
import store from '../../../../store';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { useLocation } from 'react-router-dom';
import { clearPageState, clearTableCounts, clearTableOtherData, setTableCount, setTableCounts, setTableData, setTableDataAndCountAndBody, setTableDefaultreqBody, setTableFieldsData, unmountTable } from '../../../../listing/listReducer';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { AffiliateReportTotalAmt, exportCSV, FilterAffiliateReport, FilterAffiliateReportCount, setAffiliateInitData } from '../AffiliateReducer';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';
import IosShareIcon from '@mui/icons-material/IosShare';



const AffiliateReport = () => {

  const Location = useLocation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.W9FormReducer?.loading ? state.W9FormReducer.loading : false);
  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});



  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/affiliate-report"]
      ? state.ResolveReducer.resolved["/affiliate-report"]
      : false
  );

  const listData = useSelector((state) => state.W9FormReducer?.commisionReportData && state.W9FormReducer?.commisionReportData.length > 0 ? state.W9FormReducer.commisionReportData : []);

  // let commisionAmount = useSelector((state) => state.tableSlice?.otherResData?.CommisionReport?.total_commission ? state.tableSlice?.otherResData?.CommisionReport?.total_commission : state.W9FormReducer?.commisionAmount ? state.W9FormReducer.commisionAmount : null);

  let commisionAmount = useSelector((state) => state.W9FormReducer?.commisionAmount ? state.W9FormReducer.commisionAmount : null);

 

  const filteredCommisionAmount = useSelector((state) => state.AffiliateReducer?.filteredCommissionAmount ? state.AffiliateReducer.filteredCommissionAmount : null);

  const filteredReportData = useSelector((state) => state.AffiliateReducer?.filteredAffiliateReportData ? state.AffiliateReducer.filteredAffiliateReportData : []);

  const filteredAffiliateReportDataCount = useSelector((state) => state.AffiliateReducer?.filteredAffiliateReportDataCount ? state.AffiliateReducer.filteredAffiliateReportDataCount : null);

  const commisionReportSuccess = useSelector((state) => state.AffiliateReducer?.filterAffiliateDataSuccess ? state.AffiliateReducer.filterAffiliateDataSuccess : false);

  const loading = useSelector((state) => state.AffiliateReducer?.loading ? state.AffiliateReducer.loading : false);


  const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.CommisionReport_Search ? state.formSlice.formSubmissionState.CommisionReport_Search : false);

  const plan_type = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.plan_type ? state.formSlice.formData.CommisionReport_Search.plan_type : null);


  const username = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.username ? state.formSlice.formData.CommisionReport_Search.username : null);

  const affiliate_name_search = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.name ? state.formSlice.formData.CommisionReport_Search.name : null);



  const start_date = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.start_date ? state.formSlice.formData.CommisionReport_Search.start_date : null);


  const end_date = useSelector((state) => state.formSlice?.formData?.CommisionReport_Search?.end_date ? state.formSlice.formData.CommisionReport_Search.end_date : null);

  const affiliateTotalAmt = useSelector((state) => state.AffiliateReducer?.affiliateTotalAmt ? state.AffiliateReducer.affiliateTotalAmt : null);


  const pagginationTriggred = useSelector((state) => state.tableSlice?.pageState?.CommisionReport ? state.tableSlice.pageState.CommisionReport : null);


  const tableFeching = useSelector((state) => state.tableSlice?.loading?.CommisionReport ? state.tableSlice.loading.CommisionReport : false);


  const csvSentSuccess = useSelector((state) => state.AffiliateReducer?.csvSent ? state.AffiliateReducer.csvSent : false);

  // console.warn("affiliateTotalAmt ====>>>>>", affiliateTotalAmt)





  // ----- all states here ---- //
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [toogleVal, setToogleVal] = useState('all')
  const [filterData, setFilterData] = useState(false)
  const [filterListData, setFilterListData] = useState([])
  const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
  const [amtToogleFlag, setAmtToogleFlag] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);

  //  -------------------------- //


  // ================ setting time stamps ====================//
  const todaysStartDate = moment().startOf('day').valueOf()
  const todaysEndDate = moment().endOf('day').valueOf()


  const weekStartDate = moment().startOf('week').valueOf()
  const weekEndDate = moment().endOf('week').valueOf()


  const monthStartDate = moment().startOf('month').valueOf()
  const monthEndDate = moment().endOf('month').valueOf()
  // ======================================================== //








  // ===== open snackbar if csv export sucess === //
  useEffect(() => {
    if (csvSentSuccess) {
      setSnackOpen(true)
    }
  }, [csvSentSuccess])

  // ======================================== //

  // ======== snackbar close =================//
  function handleClose() {
    setSnackOpen(false)

  }
  // ============================================= //








  // console.warn("Location here ======>>>>>>>>", Location.pathname.split('/').join(''))

  //  ------- setting route path ---------- //
  var routeUrl = null
  useEffect(() => {
    if (Location?.pathname?.split('/').join('') === 'affiliate-report') {
      routeUrl = Location.pathname.split('/').join('')

      console.log("routeUrl===>", routeUrl);
    }
  }, [JSON.stringify(Location)])
  //  ---------------------------------- //



  useEffect(() => {


    return () => {
      store.dispatch(clearTableOtherData({ tableId: "CommisionReport" }));
    }
  }, [JSON.stringify(Location.pathname)])



  //  ======= main table ======== //
  let tableDataForCommisionReport = useRef({
    tableId: "CommisionReport",
    tableTitle: " Traffic Partner Commission Report",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/commisionreportList",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "bclusers/commisionreportList",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "record_date",
        type: "desc",
      },
      searchcondition: logedUserData.main_role === "is_rep" ? { rep_id: logedUserData._id } : { user_id: logedUserData._id },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "record_date"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [


    ],
    buttons: [

    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Search Commission Report",
      type: "drawer",
      //   condition_type: "$or",
      formData: {
        id: "commisionsearchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [


          {
            id: 30,
            label: "Search by Plan Type",
            name: "plan_type",
            // className: 'Status',
            type: 'select',
            multiple: false,
            values: [
              { val: "single", name: "single" },
              { val: "single+1", name: "Single+1" },
              { val: "Family", name: "Family" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "plan_type" },
          },

          {
            id: 31,
            label: "Search by Recorded on Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "record_date", param: "$gte" },
          },
          {
            id: 4,
            label: "Search by Recorded on End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "record_date", param: "$lte" },
            // minDate:{minDate}
            // rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "End Date can not be less than Start Date"
            },
          },


        ],
      },
    },
  });
  // ========================= //



  if (logedUserData.main_role === "is_rep") {

    var modifyTableHeaders = [
      { val: "username", name: "Name" },
      // { val: "name", name: "Name" },
      // { val: "role", name: "Role" },s
      { val: "plan_type", name: "Plan Type" },
      { val: "unit", name: "# Of Package" },
      //  { val: "commision_amount_for_rep", name: "AE commission Amount ($)" },
      { val: "commision_rate", name: "Commission Rate [Flat($)]" },
      { val: "commision_amt", name: "Commission Amount ($)" },

      {
        val: "record_date",
        name: "Recorded On",
        type: "datetime",
        format: "MM/DD/YYYY",
      },
    ];

  } else {


    var modifyTableHeaders = [
      { val: "username", name: "Name" },
      // { val: "role", name: "Role" },
      { val: "plan_type", name: "Plan Type" },
      { val: "unit", name: "Unit" },
      { val: "commision_rate", name: "Commission Rate ($)" },
      { val: "commision_amt", name: "Commission Amount ($)" },

      {
        val: "record_date",
        name: "Recorded On",
        type: "datetime",
        format: "MM/DD/YYYY",
      },
    ];
  }



  useEffect(() => {

    setSnackOpen(false)


    if (logedUserData.main_role === "is_rep") {
      let searchArr = [
        {
          id: 0,
          label: "Search By Name",
          name: "username",
          type: "autoComplete",
          // multiple: true,
          // preload:true,
          // autoStage:true,
          className: "list_search_item countDiv__column--col6 ",
          base_url:
            process.env.REACT_APP_API_URL + "bclusers/commisionusernameautocomp",
          reqBody: {
            // "formId": 'teamsearch',
            condition: {
              limit: 10,
              skip: 0,
            },
            sort: {
              type: "asc",
              field: "username",
            },
            searchcondition: {},
          },
          sx: { m: 1, minWidth: 300 },
          // payloadFormat: { key: "username", param: "$regex", options: "i" },
          payloadFormat: { key: "agent_code" },
        },

        // {
        //   id: 3,
        //   label: "Search by Role",
        //   name: "role",
        //   // className: 'Status',
        //   type: 'select',
        //   multiple: false,
        //   values: [
        //     { val: "is_rep", name: "Rep" },
        //     { val: "is_affiliate", name: "Affiliate" },
        //   ],
        //   sx: { m: 1, minWidth: 120 },
        //   className: 'list_search_item countDiv__column--col6 ',
        //   payloadFormat: { key: "role" },
        // },

      ]


      // searchArr.forEach((item) => { tableDataForCommisionReport.current.searchData.formData.fields.push(item) })

      // searchArr.forEach((x) => {dataform.current.fields.push(x)})


    }

  }, [])


  useEffect(() => {
    let reqBody = {
      // "formId": 'teamsearch',
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "record_date",
      },
      searchcondition: logedUserData.main_role === "is_rep" ? { rep_id: logedUserData._id } : { user_id: logedUserData._id },
    };

    // console.log("RoleList", resolve);
    if (!resolved) {

      // store.dispatch(getcommisionReportData(reqBody))

        searchTable('all');
   

    }


    // store.dispatch(resetresolvenew("/affiliate-report"));

    return () => {
      store.dispatch(clearCommisionReportData());
      dispatch(setAffiliateInitData())

    };
  }, []);








  // ============  on table filters click =========== //
  function searchTable(val) {
    setToogleVal(val);

    switch (val) {

      case 'all':
        dispatch(unmountTable({ "tableId": "CommisionReport" }));
        setFilterData(false)
        setAmtToogleFlag(false)
        setDataFetching(true)


        let payloadAll = {
          "condition": {
            "limit": 30, "skip": 0
          },
          "sort": {
            "type": "desc", "field": "record_date"
          },
          "searchcondition": { "rep_id": logedUserData._id }

        }

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReport", "reqBody": payloadAll }))


        dispatch(getcommisionReportData(payloadAll))

        break;

      case 'this_month':
        dispatch(unmountTable({ "tableId": "CommisionReport" }));
        setDataFetching(true)

        setFilterData(true)
        dispatch(AffiliateReportTotalAmt({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": { "rep_id": logedUserData._id }
        }))

        setAmtToogleFlag(true)

        let payloadMonth = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": logedUserData.main_role === "is_rep" ? { rep_id: logedUserData._id } : { user_id: logedUserData._id }
        }

        payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }

        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReport", "reqBody": payloadMonth }))

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

        dispatch(FilterAffiliateReport(payloadMonth))


        setTimeout(() => {
          payloadMonth['count'] = true
          dispatch(FilterAffiliateReportCount(payloadMonth))
        }, 200);


        break;

      case 'this_week':
        dispatch(unmountTable({ "tableId": "CommisionReport" }));
        setDataFetching(true)

        setFilterData(true)
        setAmtToogleFlag(true)
        // dispatch(AffiliateReportTotalAmt({
        //   "condition": { "limit": 30, "skip": 0 },
        //   "sort": { "type": "desc", "field": "record_date" },
        //   "searchcondition": { "rep_id": logedUserData._id }
        // }))


        let payloadWeek = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": logedUserData.main_role === "is_rep" ? { rep_id: logedUserData._id } : { user_id: logedUserData._id }

        }

        payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReport", "reqBody": payloadWeek }))

        dispatch(FilterAffiliateReport(payloadWeek))

        dispatch(clearPageState({ "tableId": "CommisionReport" }))

        // dispatch(clearTableCounts({ "tableId": "CommisionReport" }))

        setTimeout(() => {
          payloadWeek['count'] = true
          dispatch(FilterAffiliateReportCount(payloadWeek))
        }, 200);


        break;

      case 'today':
        dispatch(unmountTable({ "tableId": "CommisionReport" }));
        setFilterData(true)
        setAmtToogleFlag(true)
        setDataFetching(true)

        // dispatch(AffiliateReportTotalAmt({
        //   "condition": { "limit": 30, "skip": 0 },
        //   "sort": { "type": "desc", "field": "record_date" },
        //   "searchcondition": { "rep_id": logedUserData._id }
        // }))


        let payloadToday = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": logedUserData.main_role === "is_rep" ? { rep_id: logedUserData._id } : { user_id: logedUserData._id }

        }

        payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }

        tableDataForCommisionReport.current['reqBody']['searchcondition'] = payloadToday['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "CommisionReport", "reqBody": payloadToday }))


        dispatch(FilterAffiliateReport(payloadToday))


        setTimeout(() => {
          payloadToday['count'] = true
          dispatch(FilterAffiliateReportCount(payloadToday))
        }, 200);



        break;

      default:
      // code block
    }
  }
  //  ================================== //






  


    // ==== setTableData === //
    useEffect(() => {

      if (listData) {
        setDataFetching(false)
      }

  
    }, [JSON.stringify(listData)])
    //  ================== //





  // ==== setTableData === //
  useEffect(() => {

    if (filterData && commisionReportSuccess) {
      dispatch(clearCommisionReportData())
      setFilterListData(filteredReportData)
      dispatch(setTableData({ "tableId": "CommisionReport", "dataset": filteredReportData }))
      setDataFetching(false)


    }
    // JSON.stringify(filteredReportData), JSON.stringify(listData)

  }, [JSON.stringify(filterData), commisionReportSuccess])
  //  ================== //



  // ==== setTableCount === //
  useEffect(() => {
    if (filteredAffiliateReportDataCount !== null) {
      dispatch(clearPageState({ "tableId": "CommisionReport" }))
      // dispatch(clearTableCounts({ "tableId": "CommisionReport" }))
      dispatch(setTableCounts({ "tableId": "CommisionReport", "count": filteredAffiliateReportDataCount }))
      setDataFetching(false)

    }
  }, [filteredAffiliateReportDataCount])
  // ===================== //




  //----------------------search form configration---------------------
  const dataform = useRef({
    id: 'CommisionReport_Search',
    submitBtnName: "Search",
    submitBtnEndIcon: 'search_icon',
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: 'refresh',
    endPointBodyWrapper: "searchcondition",
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {},

    fields: [

      {
        id: 0,
        label: "Search by Affiliate Name",
        name: "name",
        type: "autoComplete",
        className: 'list_search_item countDiv__column--col6 ',
        base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
        reqBody: {
          "condition": {
              "limit": 10,
              "skip": 0
          },
          "sort": {
              "type": "asc",
              "field": "name"
          },
          "searchcondition": {
              "added_by": logedUserData._id,
              "roles.is_affiliate":1,
              "label": {
                  "$regex": "d",
                  "$options": "i"
              }
          },
          "type": "user_name",
          "source": "users"
      },
        sx: { m: 1, minWidth: 300 },
        payloadFormat: { key: "_id" },

      },

      {
        id: 30,
        label: "Search by Plan Type",
        name: "plan_type",
        // className: 'Status',
        type: 'select',
        multiple: false,
        values: [
          { val: "single", name: "single" },
          { val: "single+1", name: "Single+1" },
          { val: "Family", name: "Family" },
        ],
        sx: { m: 1, minWidth: 120 },
        className: 'list_search_item countDiv__column--col6 ',
        payloadFormat: { key: "plan_type" },
      },

      {
        id: 31,
        label: "Search by Recorded on Start Date",
        name: "start_date",
        className: "datePicker",
        type: "datePicker",
        sx: { m: 1, width: 300 },
        className: "list_search_item countDiv__column--col6 ",
        payloadFormat: { key: "record_date", param: "$gte" },
      },
      {
        id: 4,
        label: "Search by Recorded on End Date",
        name: "end_date",
        className: "list_search_item countDiv__column--col6 ",
        type: "datePicker",
        fullDay: true,
        sx: { m: 1, width: 300 },
        payloadFormat: { key: "record_date", param: "$lte" },
        // minDate:{minDate}
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "End Date can not be less than Start Date"
        },
      },


    ],
  })

  // ================================================== //




  // ========= endpoint call for search after search drawwer ===== //
  useEffect(() => {
    if (formSubmissionState === 4) {
      setFilterData(false);
      setToogleVal(null);
      setAmtToogleFlag(false)
      setToogleVal("search");


      const payload = {
        "condition": { "limit": 30, "skip": 0 },
        "sort": {
          "field": "record_date",
          "type": "desc"
        },
        "searchcondition": {
          "rep_id": logedUserData._id,
          "plan_type": plan_type !== null ? plan_type : undefined,
          "username": username !== null ? username : undefined,

        },
        "project": {},
        "token": "",
        "count": false
      }

      if (start_date !== null || end_date !== null) {
        payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

      }


      if(affiliate_name_search!==null){
        payload['searchcondition']['user_id']= affiliate_name_search 
      }



      tableDataForCommisionReport.current['reqBody']['searchcondition'] = payload['searchcondition']


      dispatch(getcommisionReportData(payload))
      setSearchDrawer(false)
    }
  }, [formSubmissionState])
  // ================================================================= //





  // =============== exportCSV ============= //
  function exportCSVthroughmail() {

    let payload = {}


    switch (toogleVal) {


      case 'all':
        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "rep_id": logedUserData._id,
            "role": 'is_affiliate'
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "affiliate_commission_data"
        }

        dispatch(exportCSV(payload));
        break;

      case 'this_month':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "rep_id": logedUserData._id,
            "role": 'is_affiliate',
            "record_date": { $gte: monthStartDate, $lte: monthEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "affiliate_commission_data"
        }

        dispatch(exportCSV(payload));

        break;

      case 'this_week':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "rep_id": logedUserData._id,
            "role": 'is_affiliate',
            "record_date": { $gte: weekStartDate, $lte: weekEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "affiliate_commission_data"
        }

        dispatch(exportCSV(payload));

        break;

      case 'today':


        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "rep_id": logedUserData._id,
            "role": 'is_affiliate',
            "record_date": { $gte: todaysStartDate, $lte: todaysEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "affiliate_commission_data"
        }

        dispatch(exportCSV(payload));
        break;


      case 'search':


        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "rep_id": logedUserData._id,
            "plan_type": plan_type !== null ? plan_type : undefined,
            "username": username !== null ? username : undefined,

          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data"
        }

        if (start_date !== null || end_date !== null) {
          payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }
  
        }

        if(affiliate_name_search!==null){
          payload['searchcondition']['user_id']= affiliate_name_search 
        }

        dispatch(exportCSV(payload));

        break;

      default:
        break;
    }

  }
  //  ================================== //






  return (
    <>
      <div className="rolelist_mainwrp">
        <div className='listing_heading'>
          <h1>Traffic Partner Commission Report</h1>
        </div>

        <div className='listNavigate affiliate-reportlist-navigation'>

          <ul className='toogleBtns'>
            <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
            <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Commission </Button>  </li>
            <li> <Button variant="contained" onClick={() => {
              dispatch(unmountTable({ "tableId": "CommisionReport" }));
              searchTable('all')
            }}>
              <Tooltip title="Reload" placement="bottom" className=''>
                <ReplayIcon />
              </Tooltip>
            </Button>
            </li>


            {(filterListData.length > 0 || listData.length > 0) && <li>  <Button variant="contained" onClick={() => exportCSVthroughmail()} >
              <Tooltip title="Export CSV" placement="bottom" className=''>
                <IosShareIcon />
              </Tooltip>
            </Button> </li>}

          </ul>

          <ul className='total_bill_mt'>

            {toogleVal !== null && toogleVal !== "all" && toogleVal !== "search" && !loading &&
              (<li className='double_bill'>
                <span className='total_bill_mt_text'>
                  {toogleVal === "this_month" ? "This Month" : toogleVal === "this_week" ? "This Week" : toogleVal === "today" && "Today's"} Amount
                </span>
                <span className='total_bill_mt_amount'>
                  {filteredCommisionAmount !== null ? '$' + parseFloat(filteredCommisionAmount)?.toFixed(2) : '$0'}
                </span>
              </li>)}

            <li className='double_bill'>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {/* {affiliateTotalAmt !== null && amtToogleFlag ? '$' + parseFloat(affiliateTotalAmt)?.toFixed(2) : commisionAmount !== null ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'} */}

                {commisionAmount !== null ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'}
                
              </span>
            </li>

          </ul>

        </div>



        <div className='rolelist_mainwrp_Table affiliate_report'>


          {(loading || dataFetching ) && <LinearProgress />}

          {dataFetching === false && loader === false  ?
            <TableContainer>
              <ListingTable
                tableData={tableDataForCommisionReport.current}
                dataset={filterData ? filterListData : listData}
                modifyHeaders={modifyTableHeaders}
              />
            </TableContainer> : <TableSkeleton count={5} />

          }
          <ul className='total_bill_mt'>

            <li>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {affiliateTotalAmt !== null && amtToogleFlag ? '$' + parseFloat(affiliateTotalAmt)?.toFixed(2) : commisionAmount !== null ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'}

                {/* {commisionAmount !== null ? '$' + parseFloat(commisionAmount)?.toFixed(2) : '$0'} */}
              </span>
            </li>

          </ul>
        </div>




        <Drawer
          anchor="bottom"
          open={searchDrawer}
          onClose={() => setSearchDrawer(false)}
        >
          <Paper elevation={7} className='list_search team_management_list_search search_img'>

            <div className="searchBarHead DrawerBox">
              <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Traffic Partner Commission Report</h2>
              <span className="searchBarCloseIcon">
                <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
              </span>
            </div>

            <Form formData={dataform.current} />
          </Paper>
        </Drawer>


        {
          snackOpen && (
            <Snackbar
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              message="CSV Data Sent Successfully. Please Check Your Mail"
            />
          )
        }


      </div>

    </>
  )
}

export default AffiliateReport