import React from 'react'
import LandingPage11Form from './LandingPage11Form'
import "../LandingPage11/LandingPage11.css"


function LandingPage11() {
  
  return (

    <div className='LandingPage9Body LandingBlCst_New'>
      <div className='LandingPage9Body_wrapper'>
        <div className='LandingPage9Body_Top'>
          <div className='LandingPage9Body_TopLeft'> <img src='https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp' alt=''/> </div>
          <div className='LandingPage9Body_TopRight'>
          {/* <h2><span>Sign up and get access to landing pages to start </span></h2> */}
            {/* <h2><span>  sharing the Access Health Discount Pharmacy Program</span></h2> */}
            <h2><span>SIGN UP FOR YOUR ACCESS HEALTH DISCOUNT PHARMACY LANDING PAGES </span></h2>
            <h3><span></span> <strong>right away!</strong></h3>
          </div>
        </div>

        <div className='LandingPage9Body_Formwrapper LandingPage10Body'>
          <div className='LandingPage9Body_Formwrapper_con LandingPage11Body_sub'>
            <div className='LandingPage9FormCon LandingPage11Body_subCon'>
              <LandingPage11Form />
            </div>
          </div>
        </div>

        <div className='LandingPage9BottomLogo'>
          <span> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img></span>
        </div>

        {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_img1.webp' className='landingpage9_img1'></img> */}
      </div>


    </div>
  )
}

export default LandingPage11