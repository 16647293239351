import React, { useEffect, useRef, useState } from 'react'
import "../Components/LandingPage14.css"
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../../helper/TableSkeleton';
import { MedicineListing } from '../../../../Backend/Medicine/MedicineReducer';


function Lp14block5(setOpen, SetopenSearch) {
  const [openMoadl2, setopenMoadl2] = useState(false)
  const openModal = () => {
    setopenMoadl2(true);
  };
  const closeModal = () => {
    setopenMoadl2(false);
  };
const dispatch=useDispatch();
  const medcatListing = useSelector((state) => (state.medicineSlice?.medicinedata && state.medicineSlice?.medicinedata.length > 0) ? state.medicineSlice.medicinedata : [])
  const loadingInMedCatListing = useSelector(state => state.medicineSlice?.loading ? state.medicineSlice.loading : false);


  var modifyTableHeadersforMedCatlisting =
  [

      { val: "name", name: "Medicine Name" },
      { val: "med_by_catagorey", name: "Medicine Category" },



  ];
useEffect (() => {
console.log("medcatListing--------------", medcatListing);

}, [medcatListing])

  let tableDataForMedCatlisting = useRef({
    tableId: "medcatlist",
    tableTitle: "Medicine List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/medicinelist",
      //   deleteSingleUserEndpoint: "bclusers/deletemedicinename",
      tableCountEndpoint: "bclusers/medicinelist",
    },

    reqBody: {

      // "source": "training_list",
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {

      },
      "project": {},
      "token": "",
      "email": "",

      "count": false,



    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["name", "med_by_catagorey", "priority", "status", "createdon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "alldruglist",
        type: "format_list_bulleted",
        title: "All Drug List",
        className: "format_list_bulleted",
        default: true,
      },
    ],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      // heading: "Search Medicine & Category List",
      //   type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchMedicineandCategory',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Medicine Name",
            name: "medicine_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebymedicinename",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Medicine Category",
            name: "category_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "bclusers/autocompletebycatagory",
            reqBody: {
              // "source": "training_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "category" },

          },



        ]
      }
    },

  });
  useEffect(() => {
    dispatch(MedicineListing(tableDataForMedCatlisting.current.reqBody));
  }, [])



  return (
    <>
      <div className='landingpage14_bannerblock5_mainwrapper'>
        <div className='landingpage14_common_width'>
          <h2>WE OFFER THE 605 MOST PRESCRIBED DRUGS IN AMERICA FOR FREE!</h2>
          <p>Here Are Just A Few From Our List</p>
          <div className="landingpage5_bannerblock3_thirdblock">
            <ul>
              <li>ACYCLOVIR</li>
              <li>ALLOPURINOL</li>
              <li>AMITRITYLINE</li>
              <li>AMLODIPINE</li>
              <li>AMOXICILLIN</li>
              <li>ANASTRAZOLE</li>
              <li>ATENOLOL</li>
              <li>ATORVASTATIN</li>
              <li>AZITHROMYCIN</li>
              <li>AMLODIPINE</li>
              <li>BUPROPION</li>
              <li>CARVEDILOL</li>
              <li>CEFDINIR</li>
              <li>CELECOXIB</li>
              <li>CEPHALEXIN</li>
              <li>CIPROFLOXACIN</li>
              <li>CITALOPRAM</li>
              <li>CLOPIDOGREL</li>
              <li>CYCLOBENZAPRINE</li>
              <li>DICLOFENAC</li>
              <li>DILTIAZEM</li>
              <li>DOXYCYCLINE</li>
              <li>DULOXETINE</li>
              <li>ESCITALOPRAM</li>
              <li>ESTRADIOL</li>
              <li>EZETIMIBE</li>
              <li>FENOFIBRATE</li>
              <li>FINASTERIDE</li>
              <li>FLUCONAZOLE</li>
              <li>FLUOXETINE</li>
              <li>GLIMEPIRIDE</li>
              <li>GLIPIZIDE</li>
              <li>HYDRALAZINE</li>
              <li>IBUPROFEN</li>
              <li>LAMOTRIGINE</li>
              <li>LEVOTHYROXINE</li>
              <li>LIOTHYRONINE</li>
              <li>LISINOPRIL</li>
              <li>LISINOPRIL/HCTZ</li>
              <li>LOSARTAN</li>
              <li>MELOXICAM</li>
              <li>METFORMIN</li>
              <li>METHOTREXATE</li>
              <li>METOPROLOL</li>
              <li>MONTELUKAST</li>
              <li>OMEPRAZOLE</li>
              <li>PANTOPRAZOLE</li>
              <li>PAROXETINE</li>
              <li>PRAVASTATIN</li>
              <li>PROGESTERONE</li>
              <li>ROSUVASTATIN</li>
              <li>SERTRALINE</li>
              <li>SIMVASTATIN</li>
              <li>SILDENAFIL</li>
              <li>TAMSULOSIN</li>
              <li>TRAMADOL</li>
              <li>TRAZODONE</li>
              <li>TRIAMCINOLONE</li>
              <li>VALACYCLOVIR</li>
              <li>VENLAFAXINE</li>
            </ul>
          </div>
          <div className='lp14_block5_last_button'>
            <button onClick={() => openModal()}>VIEW FULL DRUG LIST</button>
          </div>
        </div>
      </div>


      <div>
        <Modal
          className="modalBaseStyle allMeetingMOdal  MOdallanding5 SD_MOdallanding"
          open={openMoadl2}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modalBaseBox mediumModal' >
            <Tooltip title='Close' >
              <IconButton onClick={() => closeModal()}
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div>
              <div className="rolelist_mainwrp">

                <div className='listing_heading'>

                  <h1>Access Free Pharmacy Medicine</h1>

                </div>

                <div className='rolelist_mainwrp_Table'>
                  {!loadingInMedCatListing ? (
                    <ListingTable
                      tableData={tableDataForMedCatlisting.current}
                      dataset={medcatListing}
                      modifyHeaders={modifyTableHeadersforMedCatlisting}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}

                </div>
              </div>
            </div>

          </Box>
        </Modal>
      </div>


    </>
  )
}

export default Lp14block5