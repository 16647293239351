import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from 'react'
import { Form } from '../../../../../form/Form';
import store from '../../../../../store';
import { addUpdateEmployee, clearRespomseEmplyeeEdit, viewEmployeeList, viewlocationsListData } from '../../EmployerReducer';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import StateArray from '../../../../../assets/json/state';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import { setReloadTableFlag } from '../../../../../listing/listReducer';
// import myfile from '../../../../../assets/myfile.json'

function AddEditEmployee({ employeeModlOpen, setEmployeeModlOpen, logedUserData, employeeListDetails, employeeReqBody, editEmployeeClicked }) {



  const dispatch = useDispatch();

  const [openSnackbar, setOpenSnackbar] = useState(false)


  const employeeLoader = useSelector((state) => state.EmployerReducer?.addloadingemployee ? state.EmployerReducer.addloadingemployee : null)


  const locationListDataSet = useSelector((state) => state.EmployerReducer?.locationsListData ? state.EmployerReducer.locationsListData : null)

  const submissionEmployeeState = useSelector(state => state.EmployerReducer?.employeeSubmission ? state.EmployerReducer.employeeSubmission : null);

  const employeeSnackBar = useSelector(state => state.EmployerReducer?.employeeSnackFire ? state.EmployerReducer.employeeSnackFire : "");

  const EmployeeStatus = useSelector(state => state.EmployerReducer?.employeeStatus ? state.EmployerReducer.employeeStatus : null);


  const [locationArrys, setLocationArrys] = useState([]);
  // const [formOpen, setFormOpen] = useState(false)


  // ------------- setting location array ----------------
  let NewArray = [];
  useEffect(() => {
    if (locationListDataSet && locationListDataSet.length > 0) {
      // console.log("locationListDataSet===============>>>>>", locationListDataSet)
      for (let i in locationListDataSet) {
        // console.log("locationListDataSet===============>>>>>3", locationListDataSet)
        NewArray.push({ val: locationListDataSet[i]._id, name: locationListDataSet[i].location_name + " " + "(" + locationListDataSet[i].address + "," + " " + locationListDataSet[i].city + ", " + locationListDataSet[i].state + " " + locationListDataSet[i].zip + ")", key: locationListDataSet[i]._id });
      }
      // console.log("NewArray+", NewArray);
      setLocationArrys(NewArray);
      // console.log("locationArrys===========>>>>", locationArrys)

    }
  }, [locationListDataSet]);





  const employeeFormData = useSelector((state) => state.formSlice.formData.addEmployeeTable ? state.formSlice.formData.addEmployeeTable : null);

  const employeeDataSubmissionState = useSelector(state => (state.formSlice?.formSubmissionState?.addEmployeeTable) ? state.formSlice?.formSubmissionState?.addEmployeeTable : 0);
  const employeeEditData = useSelector((state) => state.tableSlice.tempRowData.tableForEmployeeData && Object.keys(state.tableSlice.tempRowData.tableForEmployeeData).length > 0 ? state.tableSlice.tempRowData.tableForEmployeeData : {})


  // =============================employee edit funtionality=================

  const [editEmployee, setEditEmployee] = useState({})

  // ============================add Employee function======================

  const addEmployeeDetails = async (val) => {
    // console.log("val>>>", val);
    val = { ...val };

    let body = {
      firstname: val.firstname.trim(),
      lastname: val.lastname.trim(),
      email: val.email.trim(),
      phone: val.phone.trim(),
      address: val.address.trim(),
      city: val.city.trim(),
      state: val.state.trim(),
      zip: val.zip.trim(),
      notes: val.notes,
      createdon_datetime: new Date().getTime(),
      employer_id: logedUserData?._id,
      location_id: val.location,
      // location_id: val.NewArray.key,
      // status: val.status === 1 ? true : val.status === 6 ? false : null,
      status: val.status,
      package_details: {
        package_name: val.package_name,
        package_price: val.package_name == "Single" ? 21.99 : val.package_name == "Single+1" ? 26.99 : 31.99,
      },
      package_status: val.status,
      // package_name: val.package_name,
      // package_price: val.package_name == "Single" ? 21.99 : val.package_name == "Single+1" ? 26.99 : 31.99,
      added_type: "Manual",
      password: val.password,
      confirmPassword: val.confirmPassword,

      _id:
        editEmployee && Object.keys(editEmployee).length > 0
          ? editEmployee._id
          : undefined,


    };

    // console.log("body==========>>>>", body)



    if (Object.keys(body).length > 0) {

      await store.dispatch(addUpdateEmployee(body));

      // console.log("body------------>>>>", body)

      for (let k in addformEmployeeDetails.fields) {

        let field = cloneDeep(addformEmployeeDetails.fields[k]);
        field.defaultValue = undefined;
        addformEmployeeDetails.fields[k] = field;
      }

      // setOpenSnackbar(true)


      // dispatch(
      //   setSnackbar({
      //     open: true,
      //     message:
      //       editEmployee && Object.keys(editEmployee).length > 0
      //         ? "Updated Successfully"
      //         : "Added Successfully",
      //     status: "success",
      //   })
      // );



      // setTimeout(() => {
      // handleCloseAddEMployee()
      setEditEmployee({})
      // }, 1000);

      setOpenSnackbar(true)

      dispatch(viewEmployeeList(employeeReqBody))
      // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }));
    }

  };

  useEffect(() => {
    if (submissionEmployeeState === 2 && EmployeeStatus == 'success') {
        // console.log("submissionEmployeeState============>>>>", submissionEmployeeState, EmployeeStatus)
        setTimeout(() => {
          handleCloseAddEMployee()
        }, 1000);
    }
}, [submissionEmployeeState, EmployeeStatus])

  // *********************add EMployee Form Submission state*******************

  useEffect(() => {
    if (employeeDataSubmissionState && employeeDataSubmissionState === 4) {

      addEmployeeDetails(employeeFormData);

      // console.log("employeeFormData================>>>>", employeeFormData)

      // dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }))

    }
  }, [employeeDataSubmissionState]);

  // ======================Edit temp row Data set========================

  useEffect(() => {
    if (employeeEditData && Object.keys(employeeEditData).length > 0) {
      // console.log("employeeEditData============>>>", employeeEditData);
      setEditEmployee(employeeEditData)
    }
  }, [JSON.stringify(employeeEditData)])



  useEffect(() => {
    // console.log("editEmployee======>>>1", editEmployee);

    // let statusEdit = editEmployee

  }, [editEmployee])


  // ===========================edit employee button clicked========================

  useEffect(() => {
    if (editEmployeeClicked && editEmployee && Object.keys(editEmployee).length > 0) {

      // console.log("editEmployee======>>>2", editEmployee);


      // setEditEmployee(employeeEditData)

      for (let k in addformEmployeeDetails.fields) {

        // if (editEmployee[addformEmployeeDetails.fields[k].name]) {
        addformEmployeeDetails.fields[k].defaultValue =
          editEmployee[addformEmployeeDetails.fields[k].name];
        // console.log("testhit====>>>>", employeeEditData[addformEmployeeDetails.fields[k].name])
        // setFormOpen(true)
        // }
      }
      // console.log("editEmployee======>>>3", editEmployee)


      // setTimeout(() => {
      //   setLocationMOdalOpen(true)
      // }, 1000);




    }
  }, [editEmployeeClicked, JSON.stringify(employeeEditData)])

  // ==========================add/edit employee form close function=======================
  const handleCloseAddEMployee = () => {

    for (let k in addformEmployeeDetails.fields) {
      let field = cloneDeep(addformEmployeeDetails.fields[k]);
      field.defaultValue = undefined;
      addformEmployeeDetails.fields[k] = field;
    }

    setEmployeeModlOpen(false)
    setEditEmployee({})
    dispatch(clearRespomseEmplyeeEdit())
    dispatch(setReloadTableFlag({ tableId: "addEmployeeTable" }));


  }

  // ===========================Add employee form============================

  const addformEmployeeDetails = {
    id: 'addEmployeeTable',
    formtype: "add",
    endPointBodyWrapper: "",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button SubmitEmploy",
    submitBtnName: (Object.keys(editEmployee).length > 0) ? "Update" : "Submit",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      _id: (editEmployee?._id !== null && editEmployee?._id !== undefined) ? editEmployee._id : null
    },

    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "First Name",
        label: "First Name",
        name: "firstname",
        className: 'form_item formfild inputBlock inputBlock2line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.firstname !== undefined && editEmployee.firstname !== null) ? editEmployee.firstname : undefined,

      },

      {
        id: 1,
        heading: "Last Name",
        label: "Last Name",
        name: "lastname",
        className: 'form_item formfild inputBlock inputBlock2line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.lastname !== undefined && editEmployee.lastname !== null) ? editEmployee.lastname : undefined,


      },

      {
        id: 2,
        heading: "Email",
        label: "Email",
        name: "email".trim(),
        className: "form_item formfild inputBlock inputBlock2line",
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.email !== undefined && editEmployee.email !== null) ? editEmployee.email : undefined,
      },

      {
        id: 3,
        heading: "Phone Number",
        label: "Phone Number Format: (123) 456-7890",
        name: "phone",
        className: "form_item formfild inputBlock inputBlock2line",
        type: "text",
        inputType: "phone",
        // rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.phone !== undefined && editEmployee.phone !== null) ? editEmployee.phone : undefined,
      },

      {
        id: 4,
        // heading: "Address",
        label: "Address",
        name: "address",
        className: "form_item form_item inputBlock inputBlock1line",
        type: "textarea",
        inputType: "address",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.address !== undefined && editEmployee.address !== null) ? editEmployee.address : undefined,
      },

      {
        id: 9,
        heading: "Location",
        label: "Choose a Location",
        name: "location",
        className: 'form_item formfild inputBlock inputBlock2line',
        type: 'select',
        multiple: false,
        // checkbox: true,
        values: locationArrys,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        // sx: { m: 1, minWidth: 120 },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee?.location_id !== undefined && editEmployee?.location_id !== null) ? editEmployee.location_id : undefined,

      },


      // {
      //   id: 29,
      //   heading: "Status",
      //   label: "Select Status",
      //   name: "status",
      //   className: 'form_item formfild inputBlock inputBlock2line',
      //   type: 'select',
      //   multiple: false,
      //   checkbox: true,
      //   values: [
      //     { val: 1, name: "Active" },
      //     { val: 6, name: "Inactive" },
      //   ],
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required"
      //   },
      //   defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.status == "Active") ? 1 : editEmployee.status == "Inactive" ? 6 : null,
      // },

      {
        id: 99,
        heading: "Package",
        label: "Choose a Package",
        name: "package_name",
        className: 'form_item formfild inputBlock inputBlock2line',
        type: 'select',
        multiple: false,
        // checkbox: true,
        // values: pacArray,
        values: [
          { val: "Single", name: "Single ($21.99)" },
          { val: "Single+1", name: "Single+1 ($26.99)" },
          { val: "Family", name: "Family ($31.99)" },
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        // sx: { m: 1, minWidth: 120 },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee?.package_details?.package_name !== undefined && editEmployee?.package_details?.package_name !== null) ? editEmployee.package_details.package_name : undefined,

      },


      {
        id: 5,
        heading: "City",
        label: "City",
        name: "city",
        className: 'form_item formfild inputBlock inputBlock3line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.city !== undefined && editEmployee.city !== null) ? editEmployee.city : undefined,
      },


      {
        id: 6,
        heading: "State",
        label: "State",
        name: "state",
        className: 'form_item formfild inputBlock inputBlock3line',
        type: "select",
        values: StateArray,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.state !== undefined && editEmployee.state !== null) ? editEmployee.state : undefined,
      },


      {
        id: 7,
        heading: "Zip",
        label: "Zip",
        name: "zip",
        className: 'form_item formfild inputBlock inputBlock3line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.zip !== undefined && editEmployee.zip !== null) ? editEmployee.zip : undefined,
      },


      {
        id: 8,
        heading: "Notes",
        // label: "Notes",
        name: "notes",
        className: 'form_item form_item inputBlock inputBlock1line',
        type: "textarea",
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.notes !== undefined && editEmployee.notes !== null) ? editEmployee.notes : undefined,
      },


      {
        id: 21,
        heading: "Password",
        label: "Password",
        name: "password",
        className: 'inputBlock formfild6 inputBlock2line passwordinput',
        type: (editEmployee && Object.keys(editEmployee).length > 0) ? 'hidden' : 'password',
        disabled: (editEmployee && Object.keys(editEmployee).length > 0) ? true : false,
        rules: { required: editEmployee ? false : true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },

        errorMessage: {
          required: "This Field is Required",
          // errorMessageConfirmPassword: "Password does not match",         
          custom: "Value is Invalid",
          validate: "Password does not match",
          maxLength: "Maximum allowed input length is 15 characters",

          pattern: "You need to provide a strong password",
        },

      },

      {
        id: 22,
        heading: "Confirm Password",
        label: "Confirm Password",
        name: "confirmPassword",
        className: 'inputBlock formfild6 inputBlock2line passwordinput',
        type: (editEmployee && Object.keys(editEmployee).length > 0) ? 'hidden' : 'confirm_password',
        disabled: (editEmployee && Object.keys(editEmployee).length > 0) ? true : false,
        rules: { required: (editEmployee && Object.keys(editEmployee).length > 0) ? false : true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
        errorMessage: {
          required: "This Field is Required",
          validate: "Password does not match",
          errorMessageConfirmPassword: "Password does not match",
          maxLength: "Maximum allowed input length is 15 characters",
          pattern: "You need to provide a strong password",
          custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
        },



      },

      {
        id: 13,
        // heading: "Status",
        name: "status",
        className: "form_item form_item statusCheck",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Active' },
        defaultValue: (editEmployee && Object.keys(editEmployee).length > 0 && editEmployee.status !== undefined && editEmployee.status == "Active") ? true : false,
      },


    ]

  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );




  return (
    <>
      <Modal
        className="add_Employee_Modal addtaskListMOdal"
        open={employeeModlOpen}
        onClose={handleCloseAddEMployee}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={handleCloseAddEMployee}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className='dataform2_wrapper'>
            <div className='adminformbody'>
              <h2 className="previewModalTitle ">
                <div className='modal_title modal_titlenew'>
                  {editEmployee &&
                    Object.keys(editEmployee).length > 0
                    ? "Edit Employee"
                    : "Add Employee"}
                </div>
              </h2>
              {/* {JSON.stringify(editEmployee)} */}
              <div className="inputBlock inputBlock3line disposition_task">
                <Box sx={{ minWidth: 120 }}>
                  <div className='otherForm_mainWrpr'>

                    {(editEmployee && editEmployee !== null && Object.keys(editEmployee).length > 0) && <Form formData={addformEmployeeDetails} />}
                    {Object.keys(editEmployee).length === 0 && <Form formData={addformEmployeeDetails} />}

                  </div>
                </Box>
              </div>

              {employeeLoader ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                <LinearProgress />
              </Box>
              ) : (
                ""
              )}
            </div>

          </div>

        </Box>
      </Modal>

      {/* ========================SnackBar======================= */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={employeeSnackBar}
        action={action}
        sx={{ bottom: "24px !important" }}
      />
    </>
  )
}

export default AddEditEmployee