import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { folderAccessInfo, UserActivity } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';

const FolderViewAccess = (props) => {
  // const [users, setusers] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 

  // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

  // ---- States here -----
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [open, setOpen] = React.useState(false); // snackbar const
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  


  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();
  const paramId = queryParam.search.split("=");

  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [JSON.stringify(paramId)])

  // -------------------------------


  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


  // --- loader --- 
  const loader = useSelector(state => (state.fetchUserDataReducer?.loading
  ) ? state.fetchUserDataReducer.loading
    : null);

  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)

  const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

  const accesed_for = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search?.accesed_for) ? state.formSlice.formData.Folder_View_Search.accesed_for : null)

  const folderAccessData = useSelector(state => (state?.userFolderViewReducer?.folderAccessData) ? state?.userFolderViewReducer?.folderAccessData : [])



  //  console.log("folderAccessData for folder view",folderAccessData);
  // ----------------


  // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)



  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // setErrPopup(false);
    // setErrMsgCopy(null);
  };

  //--------------------------Form Input Fields for Folder_Access-------------------------
  const searchFields_folderAccess = [
    {
      id: 0,
      label: "Search Access By ",
      name: "activity_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activityby",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "activity_type": 'folder_accessed',
          "user_id": queryParamId ? queryParamId : props.queryParamId,
          "activity_for": queryParamId ? queryParamId : props.queryParamId
        },
        "project": {},
        "token": "",
        "email": ""
      },
    },
    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]
  //---------------------------------------------------------------------------------------

  //---------------------------From Input Fields For Folder_Access_By----------------------
  const searchFields_folderAccessBy = [

    {
      id: 6,
      label: "Search By Accessed For",
      name: "accesed_for",
      type: "autoComplete",
      className: "inputblock2line",
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activityfor",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "type": "asc",
          "field": "_id"
        },
        "searchcondition": {
          "activity_type": 'folder_accessed',
          "user_id": queryParamId ? queryParamId : undefined,
          "activity_by": queryParamId ? queryParamId : undefined
        }
      },
      // payloadFormat: { key: "_id" },
    },

    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]
  //---------------------------------------------------------------------------------------

  //------------------Fetch search data from folder_access and folder_access_by-------------------
  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)

      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      if (activity_time.$gte == "" || activity_time.$gte == undefined || activity_time.$gte == 'undefined') {
        delete activity_time["$gte"];
      }

      if (activity_time.$lte == "" || activity_time.$lte == undefined || activity_time.$lte == 'undefined') {
        delete activity_time["$lte"];
      }

      const payload_folderAccess = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : props.queryParamId? props.queryParamId : undefined,
          "activity_for": props.type === "Folder_Access_By"?  accesed_for : queryParamId ? queryParamId : props.queryParamId? props.queryParamId : undefined,
          "activity_type": props.type=="Folder_Access"? "folder_accessed" : props.type=="Folder_Access_By"? "folder_accessed" : undefined,
          "activity_by": searchData?.activity_by ? searchData.activity_by : undefined,
          "activity_time": Object.keys(activity_time).length > 0 ? activity_time : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      }

      if (payload_folderAccess.searchcondition?.activity_time == undefined || payload_folderAccess.searchcondition?.activity_time == 'undefined') {
        delete payload_folderAccess.searchcondition["activity_time"]
      }

      if (payload_folderAccess.searchcondition?.activity_by == undefined || payload_folderAccess.searchcondition?.activity_by == 'undefined') {
        delete payload_folderAccess.searchcondition['activity_by'];
      }

      props.type !== "Folder_Access" && delete payload_folderAccess?.searchcondition["activity_by"];


      dispatch(folderAccessInfo(payload_folderAccess))
    }
  }, [submissionState])
  //-------------------------------------------------------------------------------



  return (
    <>

      <div>
        <h3><FolderIcon /> {props.type.split("_").join(" ")}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title={props.type === "Folder_Access" ? "Search Folder Access" : "Search Folder Access By"}>
              <IconButton onClick={() => setSearchDraw(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </div>
        </h3>
        {loading ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


        {props.activity?.length > 0 ? props.activity.map((x) =>

          <div className='user-activity'>

            <ul className='ua-data-block'>
              <li className='heading'>
                {x.activity_type == 'folder_accessed' || x.activity_type == 'folder_accessed_by' ? (<FolderIcon />) : ' '}
                {x.activity} On {format(x.activity_time, 'dd-MM-yyyy HH:mm:ss')}
              </li>
            </ul>

          </div>) : (
          // <Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>
          <p> No Data Found !</p>)}

      </div>




      {open ? (<Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={queryParamId ? "Updated Sucessfully" : " "}
      // action={action}
      />) : ""}

      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        //  formFields={searchFields}
        searchFields={props.type === "Folder_Access" ? searchFields_folderAccess : searchFields_folderAccessBy}
        type={props.type === "Folder_Access" ? "folder_access " : "folder_access_by"} /> : ""}

    </>
  )
}

export default FolderViewAccess;






