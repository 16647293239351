import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import store from "../../../../store";
import CloseIcon from "@mui/icons-material/Close";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Grid, IconButton, List, Tooltip } from "@mui/material";
import { Box, display } from "@mui/system";
// import { useCookies, Cookies } from "react-cookie";


const AssociateBbdHmInfoModal = (props) => {
  const dispatch = useDispatch(); // ---- Dispatch -----
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

  // ---- States ----
  const [loading, setLoading] = useState(true); //*****FOR LODING****//
  const [bddLength, setBddLength] = useState(null);
  const [hmLength, setHmLength] = useState(null)

  useEffect(() => {
    if (props?.data) {
      setBddLength(props?.data.filter(role => role?.roles?.is_bdd === 1).length)
      setHmLength(props?.data.filter(role => role?.roles?.hr_manager === 1).length)
    }
  }, [props.data])

  console.log("length of bdd ", props);



  console.log("<<<<< props from popupToogle modal compo >>>>", props);

  // ------- onModalClose --------
  function ModalClose() {
    props.popuptooglefunc(false);
  }

  //The Lead Hasn't Any Associated HM !

  return (
    <>
      {/* ------ Confirmation Modal ------ */}

      <Modal
        className=" modalblock userList_modal"
        open={props.popuptoogle}
        onClose={ModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={"BoxMui_modal other_BoxMui_modal"}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >

          <div className="assign_hm_bdd_details">
            <div className=" ModalBox_scroll">
              <div className="heading">
                <h3>
                Associate BDD/HM For : {props?.rowdata?.name ? props.rowdata.name : props.rowdata?.fullname ? props.rowdata.fullname : props.userName}
                </h3>
              </div>



              {
                props?.source === "lead" ?

                  <>

                    <div className="associate_hm_details">

                      {props?.data?.length > 0 && hmLength > 0 ?
                        props?.data.filter(role => role.roles.hr_manager === 1).map((x) =>

                          <ul className="listflexblocknew" key={x._id}>
                            <h3 className="heading"> Hiring Manager Details  </h3>

                            <li>
                              <strong>Full Name :</strong>{" "}
                              <span>{x.firstname + " " + x.lastname}</span>
                            </li>


                            <li>
                              <strong>E-mail :</strong>{" "}
                              <span className="emailText">{x.email}</span>
                            </li>



                            <li>
                              <strong>Phone :</strong>{" "}
                              <span>{x.phone}</span>
                            </li>

                          </ul>
                        )
                        : " The Lead Hasn't Any Associated HM !"}

                    </div>



                    <div className="associate_BDD_details">

                      {props?.data?.length > 0 && bddLength > 0 ?

                        props?.data.filter(role => role.roles.is_bdd === 1).map((x) =>

                          <ul className="listflexblocknew" key={x._id}>
                            <h3 className="heading"> BDD Details  </h3>

                            <li>
                              <strong>Full Name :</strong>{" "}
                              <span>{x.firstname + " " + x.lastname}</span>
                            </li>


                            <li>
                              <strong>E-mail :</strong>{" "}
                              <span className="emailText">{x.email}</span>
                            </li>



                            <li>
                              <strong>Phone :</strong>{" "}
                              <span>{x.phone}</span>
                            </li>

                          </ul>
                        )
                        : " The Lead Hasn't Any Associated BDD!"}

                    </div>

                  </>



                  :
                  <>

                    <div className="associate_hm_details">

                      {props?.data?.length > 0 && hmLength > 0 ?
                        props?.data.filter(role => role.roles.hr_manager === 1).map((x) =>

                          <ul className="listflexblocknew" key={x._id}>
                            <h3 className="heading"> Hiring Manager Details  </h3>

                            <li>
                              <strong>Full Name :</strong>{" "}
                              <span>{x.firstname + " " + x.lastname}</span>
                            </li>


                            <li>
                              <strong>E-mail :</strong>{" "}
                              <span className="emailText">{x.email}</span>
                            </li>



                            <li>
                              <strong>Phone :</strong>{" "}
                              <span>{x.phone}</span>
                            </li>

                          </ul>
                        )
                        : " Not HM Assign Yet!"}

                    </div>



                    <div className="associate_BDD_details">

                      {props?.data?.length > 0 && bddLength > 0 ?

                        props?.data.filter(role => role.roles.is_bdd === 1).map((x) =>

                          <ul className="listflexblocknew" key={x._id}>
                            <h3 className="heading"> BDD Details  </h3>

                            <li>
                              <strong>Full Name :</strong>{" "}
                              <span>{x.firstname + " " + x.lastname}</span>
                            </li>


                            <li>
                              <strong>E-mail :</strong>{" "}
                              <span className="emailText">{x.email}</span>
                            </li>



                            <li>
                              <strong>Phone :</strong>{" "}
                              <span>{x.phone}</span>
                            </li>

                          </ul>
                        )
                        : " Not BDD Assign Yet!"}

                    </div>

                  </>

              }

           


            </div>
          </div>


          <Tooltip title="Close" placement="right-start">
            <IconButton className="modalCloseBtn" onClick={ModalClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>


        </Box>
      </Modal>
    </>
  );
};

export default AssociateBbdHmInfoModal;
