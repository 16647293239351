import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


import store from './store';
import { CookiesProvider,Cookies } from "react-cookie";
import GetRoutes from './config/routes';
import { Provider } from 'react-redux/es';
import { setLogedinUserInfo } from './Pages/login/loginReducer';
import "./assets/meterial-css/indigo-pink.css"


function App() {

  const cookies = new Cookies();

  const getcookies = cookies.getAll();

  if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
    store.dispatch(setLogedinUserInfo(getcookies));
  }






  // useEffect(() => {

  //   setInterval(tokenInterval, 1.5 * 60 * 1000)

  //   return () => clearInterval(tokenInterval);
  // }, [])



  // const tokenInterval = async () => {
  //   if (getcookies !== null && getcookies !== undefined && getcookies.token !== undefined && getcookies.token !== null && Object.keys(getcookies).length > 0) {
  //     let decode = jwt_decode(getcookies?.token)
  //     if (decode && decode._id && decode.exp) {
  //       if ((decode.exp - 60 * 2) < moment().unix()) {
  //         let body = {
  //           "_id": decode._id
  //         }
  //         const requestOptions = {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify(body)
  //         }
  //         const response = await fetch(`${process.env.REACT_APP_API_URL}lead-manage/jwtgeneratetoken`, requestOptions);
  //         const res = await response.json()
  //         await cookies.remove("token", { path: "/" });
  //         await cookies.set('token', res.token, { path: '/' });
  //         await store.dispatch(setToken(res.token));
  //       }
  //     }

  //   }
  // }








  return (

    <>
      <CookiesProvider>
        <Provider store={store}>
          <GetRoutes />
        </Provider>
      </CookiesProvider>
    </>
  );
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);
root.render(<App />);
