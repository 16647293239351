import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearforceloginapidata, forcelogin, getNakaGamesData } from '../LandingPage/LandingpageReducer';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import NakaGamesPage from '../LandingPage/NakaGamesLandingPage/NakaGamesPage';
import { setLogedinUserInfo } from '../../login/loginReducer';
import { Cookies } from 'react-cookie';

function NakaGamesFormRedirect() {

  const { _id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const [nakaFlag, setNakaFlag] = useState('');


  const Loading = useSelector((state) => state.LandingpagefontReducer?.loader ? state.LandingpagefontReducer.loader : false)
  const nakaGamesState = useSelector((state) => state.LandingpagefontReducer?.nakagamesdata ? state.LandingpagefontReducer.nakagamesdata : null)
  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
  const forceloginapicallfalg = useSelector(state => (state.LandingpagefontReducer?.forceloginapicall) ? state.LandingpagefontReducer?.forceloginapicall : false)
  const forceLOgindata = useSelector(state => (state.LandingpagefontReducer?.forcelogindata) ? state.LandingpagefontReducer?.forcelogindata : null)
  const submittionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer?.landingpagesubmittionstate : null)


  console.warn("nakaFlag ====>>>>", nakaFlag, forceLOgindata, forceloginapicallfalg)


  useEffect(() => {

    if (_id != null && _id != undefined) {
      dispatch(getNakaGamesData(_id))
    }

  }, [])


  useEffect(() => {

    console.log("nakaGamesState", nakaGamesState);
    if (nakaGamesState && nakaGamesState.rep_signup_stage) {
      setNakaFlag(nakaGamesState.rep_signup_stage)
    }
  }, [nakaGamesState])






  // useEffect(() => {
  //   if (submittionstate === 2) {
  //     dispatch(getNakaGamesData(_id))
  //   }
  // }, [submittionstate])





  // =================== force login into dashboard if nakaFlag 2 =============== //
  useEffect(() => {
    if (nakaFlag === 2) {
      redirectToAffiliateDashboard();
    }
  }, [nakaFlag])


  const redirectToAffiliateDashboard = async () => {
    // setloader(true)
    let data = {
      "email": nakaGamesState.email,
      // "password": affiliateInfo.email,
      "_id": nakaGamesState._id,
      "login_time": Math.round(new Date().getTime()),
      "login_data": ipinfo
    }
    dispatch(forcelogin(data))
  }
  //  ========================================== //



  // ===== after sucssfull forcelogin redirect to new rep dashbaord ======= //
  useEffect(() => {
    if (forceloginapicallfalg && forceLOgindata) {
      redirectandcookieset()
    }
  }, [JSON.stringify(forceLOgindata), forceloginapicallfalg])



  const redirectandcookieset = async () => {
    let userInfodata = {
      "main_role": forceLOgindata.item.main_role,
      ...forceLOgindata.item.results[0]
    }
    await cookies.remove("userinfo", { path: "/" });
    await cookies.remove("lastLoginTime", { path: "/" });

    await cookies.set('userinfo', userInfodata, { path: '/' });
    await cookies.set('token', forceLOgindata.token, { path: '/' });
    await cookies.set('lastLoginTime', new Date().getTime(), { path: '/' });
    if (!userInfodata.last_login_time) {
      userInfodata.last_login_time = new Date().getTime()
    }
    let dataset = {
      "userinfo": userInfodata,
      "token": forceLOgindata.token
    }
    await dispatch(setLogedinUserInfo(dataset));
    // setloader(false)
    navigate('/new-ae-dashboard');
    dispatch(clearforceloginapidata())
  }
  //  ========================================================= //



  return (
    <>
      {Loading && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}
      {nakaFlag === 1 && <NakaGamesPage />}
      {/* {nakaFlag===2  && <NakaGamesPage/>} */}
    </>
  )
}

export default NakaGamesFormRedirect