import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



// API call for users-list
export const MedicineCategorylist = createAsyncThunk("Medicine-Category/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false,
        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/listmedicinecatagories", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/**********************************Medicine Category Edit******************* */
export const medicineCategoryeditdata = createAsyncThunk("edit/medicine-category", async (id) => {
    let reqBody={
        
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "name",
                "type": "desc"
            },
            "searchcondition": {
                "_id":id
            },
            "project": {},
            "token": ""
        
    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/listmedicinecatagories", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


const medicineCategory = createSlice({

    name: 'medicinecategory',

    initialState:{

        submitting: false,
        loading: false,
        submitted: false,
        resMessage: null,
        successmsg: null,
        success: false,
        failed: false,
        medicinecategorydata: [],
        editLoading: false,
        editDataMedicineCategory: [],
        
        // materialGridData:[],
        // materialGridDataCount:null,
        


    },
    reducers:{
        clearMedicineCategoryList(state, action) {
            state.medicinecategorydata = [];
           
        },
        cleareditdataMedicineCategory(state, action) {
            state.editDataMedicineCategory=[];
        },
      

    },

    extraReducers:{

        /// #################### Medicine Category Listing ###############################
        [MedicineCategorylist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [MedicineCategorylist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [MedicineCategorylist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.medicinecategorydata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
            }
        },

          /**************************************Medicine Category Edit******************** */
          [medicineCategoryeditdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [medicineCategoryeditdata.pending]: (state, action) => {


            // console.log("medicineCategoryeditdata=========================>")
            state.editLoading = true;

        },
        [medicineCategoryeditdata.fulfilled]: (state, action) => {

            // console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.editDataMedicineCategory = action.payload.results.res[0];
                // console.log("state for edit data training", state.editDataTraining)
                state.editLoading = false;

            }

        },




    }
})

export default medicineCategory.reducer;

export const { clearMedicineCategoryList, cleareditdataMedicineCategory} = medicineCategory.actions;