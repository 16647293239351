import { Button, IconButton, InputAdornment, LinearProgress, Modal, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { blueCoastaddannualBilling, clearAnnualBillingData } from './BlueCoastReducer';
import { setSnackbar } from '../../Layout/layoutReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setEnableRowLoader, setReloadTableFlag } from '../../../listing/listReducer';


const PaymentTotalGrossInsu = ({ rowdata, paymentAnnulBillingModaOpenl, setPaymentAnnulBillingModalOpen, setForLoading, setPaymentRowData }) => {


  let firstPayer = useRef(null)
  let annulBillingValue = useRef(null)

  let secondPayer = useRef(null)

  let thiredPayer = useRef(null)
  let govtPay = useRef()
  let privatePay = useRef(null)

  const [saveRowdata, setsaveRowdata] = useState(null)

  const [loaderannulBillingValue, setLoaderannulBillingValue] = useState(null);
  const [annulBillingModaOpenl, setannulBillingModalOpen] = useState(false);
  const [sendContractTo, setSendContractTo] = useState(null);
  const [sendEmailtoSpecialEntity, setSendEmailtoSpecialEntity] = useState(false);
  const [sendEmailtoTravisModal, setSendEmailtoTravisModal] = useState(false);
  // const [annulBillingValue, setannulBillingValue] = useState(null);
  // const [annulBillingValue, setannulBillingValue] = useState(null);


  const handleCloseEmailtoSpecialEntity = () => {
    setSendEmailtoSpecialEntity(false)
  }

  const handleCloseEmailtoTravis = () => {
    setSendEmailtoTravisModal(false)
  }

  const ChooseSendEmail = (val) => {
    setSendContractTo(val)
    if (val == 'Beto') {
      handleCloseEmailtoSpecialEntity()

    } else {
      handleCloseEmailtoTravis()
    }
  }




  const dispatch = useDispatch()

  const annualBillingData = useSelector((state) => state.BlueCoastSlice?.annualBillingData?.annual_billing ? state.BlueCoastSlice.annualBillingData.annual_billing : null)
  const blueCoastData = useSelector((state) => state.BlueCoastSlice?.annualBillingData ? state.BlueCoastSlice.annualBillingData : null)


  const annualBillingDataLoadingstate = useSelector((state) => state.BlueCoastSlice?.annualBillingDataLoadingstate ? state.BlueCoastSlice.annualBillingDataLoadingstate : null)

  const handleChangeAnnualBilling = async (event) => {
    setLoaderannulBillingValue(true)

    await dispatch(blueCoastaddannualBilling({ annual_billing: parseFloat(annulBillingValue.current), firstPayer: firstPayer.current, secondPayer: secondPayer.current, thiredPayer: thiredPayer.current, govtPay: parseFloat(govtPay.current), privatePay: parseFloat(privatePay.current), "addedfor": rowdata._id, source: "bluecoast_leads", }))
    setLoaderannulBillingValue(false)

    dispatch(setSnackbar({ open: true, message: 'Annual Billing Verified added  Successfully', status: 'success' }))
  };


  const CloseAnnulBillingModaOpenl = () => {
    setPaymentRowData({})
    setForLoading(true)
    setPaymentAnnulBillingModalOpen(false);
    dispatch(clearAnnualBillingData())
    // dispatch(clearPriorityData())
  };



  useEffect(() => {

    if (annualBillingDataLoadingstate === 2 && saveRowdata) {
      // console.log("annualBillingDataLoadingstate", annualBillingDataLoadingstate);
      setannulBillingModalOpen(true)
      govtPay.current = blueCoastData?.govtPay !== null ? blueCoastData?.govtPay : undefined

      firstPayer.current = blueCoastData?.firstPayer !== null ? blueCoastData?.firstPayer : undefined
      secondPayer.current = blueCoastData?.secondPayer !== null ? blueCoastData?.secondPayer : undefined
      thiredPayer.current = blueCoastData?.thiredPayer !== null ? blueCoastData?.thiredPayer : undefined
      privatePay.current = blueCoastData?.privatePay !== null ? blueCoastData?.privatePay : undefined
      annulBillingValue.current = annualBillingData !== null ? annualBillingData : undefined

      

      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata._id,
          load: false,
        }))

    }

  }, [annualBillingDataLoadingstate])













  return (
    <div>
      <Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast"
        open={paymentAnnulBillingModaOpenl}
        onClose={CloseAnnulBillingModaOpenl}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={CloseAnnulBillingModaOpenl}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className='priority_Box_Main'>
            <h2 className='previewModalTitle '>

              {rowdata?.prospectfullname && <div className='modal_title modal_titlenew'> Total Gross insurance gross receipts for : {rowdata?.prospectfullname}</div>}
            </h2>
            <Box sx={{ minWidth: 120 }} className="mpdal_healper">
              <TextField
                id="annual_billing"
                label="Annual Billing"
                fullWidth
                // value={annulBillingValue}
                defaultValue={annualBillingData !== null ? annualBillingData : undefined}
                onChange={(e) => {
                  // setannulBillingValue(Number(e.target.value));
                  annulBillingValue.current = e.target.value;
                }}
                type="number"
                // onChange={handleChangeUnderPayment}annualBillingData
                helperText="This should be dollar amount"
                // variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}

              />
              <TextField
                className='modal_text_field_cls'

                id="firstPayer"
                label="First Payer"
                fullWidth
                // value={firstPayer.current}
                defaultValue={blueCoastData?.firstPayer !== null ? blueCoastData?.firstPayer : undefined}
                onChange={(e) => {
                  // setfirstPayer(e.target.value);
                  // console.log("firstPayer",firstPayer);
                  firstPayer.current = e.target.value
                }}


              // helperText="This should be dollar amount"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}

              />

              <TextField
                className='modal_text_field_cls'

                id="secondPayer"
                label="Second Payer"
                fullWidth
                // value={secondPayer}
                defaultValue={blueCoastData?.secondPayer !== null ? blueCoastData?.secondPayer : undefined}
                onChange={(e) => {
                  secondPayer.current = e.target.value;
                }}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}

              />


              <TextField
                className='modal_text_field_cls'

                id="thiredPayer"
                label="Third Payer"
                fullWidth
                // value={thiredPayer}
                defaultValue={blueCoastData?.thiredPayer !== null ? blueCoastData?.thiredPayer : undefined}
                onChange={(e) => {
                  thiredPayer.current = e.target.value;
                }}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}

              />


              <TextField
                className='modal_text_field_cls'
                id="govtPay"
                label="Government %"
                fullWidth
                // value={govtPay}
                defaultValue={blueCoastData?.govtPay !== null ? blueCoastData?.govtPay : undefined}
                onChange={(e) => {
                  govtPay.current = e.target.value;
                }}
                // onChange={(e) => handleKeyPress(e)}

                onKeyPress={(e) => {
                  // console.log("e=============>", e.target.value, e.which, e.key);
                  let tval = (e.target.value).toString();
                  if (isNaN(e.key) && e.key !== '.') e.preventDefault();
                  if (tval == null || tval === undefined || isNaN(tval)) tval = 0;
                  let tempval = '';
                  // let tempval = parseFloat(tval.toString() +''+ (e.key).toString());
                  tempval = tval.toString().concat("", (e.key).toString());
                  console.log(tval, 'tv==al', (e.key).toString());
                  console.log(tempval, 'tval', tval);
                  if (tempval != null && tempval !== undefined) {
                    tempval = parseFloat(tempval).toFixed(2);
                    // e.target.value = tempval;
                    let tempsplit = tval.split('.');
                    if (tempsplit[1] != null && tempsplit[1] !== undefined && tempsplit[1].toString().length >= 2) {
                      e.preventDefault()

                    }
                  }
                  if (tempval > 100 || isNaN(tempval)) {
                    e.preventDefault()
                  }
                }
                }
                type="text"
              // onChange={handleChangeUnderPayment}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}

              />



              <TextField
                id="privatePay"
                className='modal_text_field_cls'

                label="Private Pay %"
                fullWidth
                // value={privatePay}
                defaultValue={blueCoastData?.privatePay !== null ? blueCoastData?.privatePay : undefined}
                onChange={(e) => {
                  // setprivatePay(Number(e.target.value));
                  privatePay.current = e.target.value;
                }}
                onKeyPress={(e) => {
                  // console.log("e=============>", e.target.value, e.which, e.key);
                  let tval = (e.target.value).toString();
                  if (isNaN(e.key) && e.key !== '.') e.preventDefault();
                  if (tval == null || tval === undefined || isNaN(tval)) tval = 0;
                  let tempval = '';
                  // let tempval = parseFloat(tval.toString() +''+ (e.key).toString());
                  tempval = tval.toString().concat("", (e.key).toString());
                  console.log(tval, 'tv==al', (e.key).toString());
                  console.log(tempval, 'tval', tval);
                  if (tempval != null && tempval !== undefined) {
                    tempval = parseFloat(tempval).toFixed(2);
                    // e.target.value = tempval;
                    let tempsplit = tval.split('.');
                    if (tempsplit[1] != null && tempsplit[1] !== undefined && tempsplit[1].toString().length >= 2) {
                      e.preventDefault()

                    }
                  }
                  if (tempval > 100 || isNaN(tempval)) {
                    e.preventDefault()
                  }
                }
                }
                type="text"
              // onChange={handleChangeUnderPayment}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}

              />

            </Box>
            <div className='Formsubmit_button modal_sbBtn'>
              <Button variant="contained" onClick={() => handleChangeAnnualBilling()}>Submit</Button>
            </div>
            {loaderannulBillingValue && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}

          </div>
        </Box>
      </Modal>




    </div>
  )
}

export default PaymentTotalGrossInsu