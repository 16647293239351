
import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip } from '@mui/material'
import React, { useEffect, useState, useLocation } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import FDSearch from './FDSearch';
import { clearLeadNotes, folderAccessDeleteNotes, getNotes, UserActivity } from './folderViewReducer';
import FDNotes from './FDNotes';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { cleargetNotesleaddata, getNotesleaddata, getNotesleaddataCount } from '../../LeadManagement/LeadReducer';
import store from '../../../../store';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { useCookies } from 'react-cookie';
import NoteViewModal from '../../Dashboard/NoteViewModal';

const Notesview = ({ notesData, leadData, source, paramId }) => {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch()
  const { id } = useParams();
  const [cookieData, setCookieData] = useCookies();
  const [queryParamId, setQueryParamId] = useState();
  const [notesViewState,setNotesViewState]=useState(false)
  const [notesViewData,setNotesViewData]=useState({})



  // console.warn("<<<<<<<<< props from notes component >>>>>>", notesData)

  // ---- geeting and setting QueryParam -----
  // const queryParam = useLocation();
  // const paramId = queryParam?.search?.split("=");

  // useEffect(() => {
  //   if (paramId[1]) {
  //     setQueryParamId(paramId[1])
  //   }
  // }, [JSON.stringify(paramId)])
  // -------------------------------

  // console.warn("<<<<<<<<< queryParamId from NotesView component >>>>>>", queryParamId)

  // console.warn("cookieData here >>>>>>>>>>////", cookieData)

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const loader = useSelector(state => (state?.DashboardReducer?.loading) ? state.DashboardReducer.loading : false)
  const notesLoading = useSelector((state) => state.LeadReducer?.notesloading ? state.LeadReducer.notesloading : false)


  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
  const noteData = useSelector(state => (state?.userFolderViewReducer?.notesData) ? state.userFolderViewReducer.notesData : [])
  const noteLeadData = useSelector(state => (state?.LeadReducer?.Notesleaddata && state?.LeadReducer?.Notesleaddata.length > 0) ? state.LeadReducer.Notesleaddata : [])
  const snackbarStatus = useSelector((state) => state.userFolderViewReducer?.success ? state.userFolderViewReducer.succes : false);
  const noteDeleteStatus = useSelector((state) => state.userFolderViewReducer?.notesDelete ? state.userFolderViewReducer.notesDelete : false)

  const noteAdded = useSelector((state) => state.userFolderViewReducer?.noteAdded ? state.userFolderViewReducer.noteAdded : false)

  // ----------------------------------------Components Private State---------------------------------------- //
  const [searchDraw, setSearchDraw] = useState(false); // snackbar const  
  const [notesModel, setNotesModel] = useState(false); // notemodel state

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [noteDeleteid, setNoteDeleteid] = useState();
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [load, setload] = useState(false);
  const [noteListData, setNoteListData] = useState([]);



  // const [skipData, setskipData] = useState(0);

  useEffect(() => {
    setSnackOpen(false)
    setOpenDeleteModal(false)
  }, [])


  // ---------- DeleteFunction Here ----------
  const deleteModal = (notesid) => {
    // console.warn("<<<<< notes id >>>>>", notesid)
    setNoteDeleteid(notesid)
    // setload(true)

    setTimeout(() => {
      setOpenDeleteModal(true)
    }, 1000);

    setTimeout(() => {
      setload(false)
    }, 2000);
  }
  // -------------------------------------------



  // --------- After Sucessfully Added Notes reload the listing --------- 
  useEffect(() => {
    if (noteAdded) {
      setNotesModel(false)
      setSnackOpen(true)
      let notesPaylod = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "notes_for": leadData?._id ? leadData._id : undefined
        },
        // "project": {},
        // "token": "",
        "count": false,
        "source": "",
      }
      dispatch(getNotes(notesPaylod))

      setTimeout(() => {
        setSnackOpen(false)
      }, 1000);


      setTimeout(() => {
        const data = {
          condition: {
            limit: 10,
            skip: 0
          },
          searchcondition: {
            notes_for: leadData?._id ? leadData._id : undefined
          },
          sort: {
            type: "desc",
            field: "createdon_datetime"
          },
          count: true
        }
        dispatch(getNotesleaddataCount(data));
        // dispatch(getNotes(data));

      }, 500);

    }
    // setDataLoad(true);

  }, [noteAdded])
  // ----------------------------------------------



  // --------  Confirm Delete fuction ---------------
  const confirmDelete = () => {
    setSnackOpen(false)
    console.log("noteLeadData===============", noteLeadData)
    // if (noteLeadData && noteLeadData?.length > 0) {
    const deleteReqBody = {
      "source": source === 'userFolderView' ? "users" : "google_events",
      "notes_for": id,
      "id": noteDeleteid
    }

    dispatch(folderAccessDeleteNotes(deleteReqBody))

    // }
  }
  // ------- --------------------- --------------


  // ----- once note delete sucessfull notes list reloaded ------
  useEffect(() => {

    if (noteDeleteStatus == true) {
      setSnackOpen(false)
      setTimeout(dispatch(
        setSnackbar({ open: true, message: "Notes Deleted Successfully !!", status: snackbarStatus })), 1000)

      // setTimeout(setOpenDeleteModal(false), 500)
      // dispatch(clearLeadNotes())
      // dispatch(cleargetNotesleaddata())

      setTimeout(() => {
        const data = {
          condition: {
            limit: 10,
            skip: 0
          },
          searchcondition: {
            notes_for: id ? id : leadData._id
          },
          sort: {
            type: "desc",
            field: "createdon_datetime"
          },
          count: true

        }
        dispatch(getNotesleaddataCount(data));
        // dispatch(getNotes(data));
      }, 1000);


      const data = {
        condition: {
          limit: 10,
          skip: 0
        },
        searchcondition: {
          notes_for: id ? id : leadData._id
        },
        sort: {
          type: "desc",
          field: "createdon_datetime"
        },
      }
      // dispatch(getNotesleaddata(data));
      dispatch(getNotes(data));
      // setDataLoad(true);
    }

  }, [noteDeleteStatus])
  // -------- ----------------------- ------------------- ----------



  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };
  // ---------------



  // --------------------------------------------Other Veriables-------------------------------------------- //
  // console.log("queryParamId====>>>>>", queryParamId);
  const searchFields = [
    {
      id: 1,
      label: "Search By Notes",
      name: "notes",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, minWidth: 300 },
      base_url: process.env.REACT_APP_API_URL + "note/auto-complete-notes",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "notes_for": paramId ? paramId : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      },

    },
    {
      id: 2,
      label: "Search By Added By",
      name: "notes_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, minWidth: 300 },
      base_url: process.env.REACT_APP_API_URL + "note/auto-complete-note-added-by",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "notes_for": paramId ? paramId : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      },

    },
    {
      id: 3,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 4,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];
      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];

      const payload = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "createdon_datetime",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          "_id": searchData?.notes ? searchData.notes : undefined,
          "notes_for": searchData?.notes_added ? searchData.notes_added : undefined,
          "notes_by": searchData?.notes_by ? searchData.notes_by : undefined,
          "createdon_datetime": activity_time
        },
        "project": {},
        "token": "",
        "email": ""
      }
      
      activity_time.$gte == undefined && activity_time.$lte == undefined && delete payload.searchcondition["createdon_datetime"];

      const notes_by_exsists = payload?.searchcondition?.hasOwnProperty('notes_by')

      if (notes_by_exsists) {
        payload.searchcondition["notes_for"] = paramId
      }


      dispatch(getNotes(payload))
    }
  }, [submissionState])

  // -------------------------------------------Other Handlers------------------------------------------- //
  const closeModal = () => setNotesModel(false)

  //----------------------------------------------return-----------------------------------------------//


  // useEffect(()=>{
  //     console.log("notesDataefsdf",notesData);
  // },[notesData])

console.log("notes data for folder view",noteData);
  return (
    <>
      <div>
        <h3>
          <SummarizeIcon /> Notes {" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search Notesview">
              <IconButton onClick={() => setSearchDraw(true)}><SearchIcon /></IconButton></Tooltip>
            <Tooltip title="Add Notes"><IconButton onClick={() => setNotesModel(true)}><AddIcon /></IconButton></Tooltip>
          </div>
        </h3>

        {loading || loader || notesLoading || load ? <Box sx={{ width: "100%", margin: "10px" }}><LinearProgress /></Box> : ''}

        {
         (notesData && notesData?.length > 0) ?
            notesData.map((item) => {
              return (
                <div className="user-activity" key={item?._id}>
                  <ul className="ua-data-block">
                    <div className='notes_data_list_wrapper'>
                      <li>Note ({item?.added_name}): {item?.notes} &nbsp; Added On: {format(item?.createdon_datetime, "dd-MM-yyyy HH:mm:ss")} </li>
                    </div>
                   
                    {item?.upload_file && (<Tooltip title="Preview Notes">
                      <IconButton aria-label="preview" onClick={()=>setNotesViewState(true)}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>)}
                       
                    {item?.notes_by === cookieData?.userinfo?._id && (<Tooltip title="Delete">
                      <IconButton aria-label="delete" onClick={() => { deleteModal(item?._id) }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>)}

                    


                  </ul>
                </div>
              )
            }) : <p>No Notes found!</p>
        }
      </div>


      {/* --------- Notes delete Modal ---------- */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=" modalblock userList_modal"
      >
        <Box className="BoxMui_modal"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}>

          <div className='delete_modal'>
            <p> Alert !!</p>
          </div>
          <div className='delete_modal_Detail'>
            <p> Are you sure you want to delete this record? This can't be undone.   </p>
          </div>
          <div className='delete_modal_Btn'>
            <Button variant="contained" onClick={() => confirmDelete()}> Yes</Button>
            <Button variant="contained" onClick={() => setOpenDeleteModal(false)}> No </Button>
          </div>

          {loader || loading ? (
            <Box sx={{ width: "90%", margin: "5px auto" }}>
              <LinearProgress />
            </Box>
          ) : null}
          {/* -------------------- */}

          <IconButton className='modalCloseBtn' onClick={() => setOpenDeleteModal(false)}><CloseIcon /></IconButton>
        </Box>




      </Modal>
      {/* ################################# */}



      {searchDraw && <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type="notes " />}
      {/* {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null} */}



      {/* ------- Modal For Add Notes ------- */}
      {notesModel && <FDNotes
        openMoadl={notesModel}
        closeModal={closeModal}
        noteData={noteData}
        leadInfo={leadData}
        source={source}
      />}
      {/* ------------------------------- */}


      {/* {console.warn("noteAdded=============>>>>", noteAdded)} */}
      {/* ------ SnackBar -------  */}
      {noteAdded ? (<Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Notes Added Successfully"
      // action={action}
      />) : ""}
      {/* ------------------------- */}

      {notesViewState && (
        <NoteViewModal ComonData={notesData[0]}  modalState={notesViewState} togalModalState={setNotesViewState} />
      ) }



    </>
  )
}

export default Notesview