
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from '../../../../store';
import '../../../../assets/List.css'

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, List } from '@mui/material';

import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import ListingTable from '../../../../listing/listing';
import { TableSkeleton } from '../../../../helper/TableSkeleton';

const LastLoginModal = (props) => {
    const usersToken = "ssdsd"
    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // ---- UseSelectors ----
    // const users = useSelector((state) => state.userManagment.usersList)
    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)


    // console.warn("<<<<< props from  >>>>", props.userId)

    // useEffect(() => {
    //     props.setModalResponse(false);
    // }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        // props.popuptooglefunc(false);
        // props.tooglefunc(false);
        props.popuptooglefunc(false)


    }

    // ------- onModalConfirmation ------
    function ConfirmExe() {
        props.setModalResponse(true);
        props.tooglefunc(false);

    }









    // ----- Table list for user -----
    var modifyTableHeaders =
        [

            { val: "login_time", name: "Last Login", type: "datetime", format: "MM/DD/YYYY hh:mm:ss" },
            { val: "city", name: "City" },
            { val: "country", name: "Country" },
            { val: "hostname", name: "Host" },
            { val: "ip", name: "IP" },
            { val: "org", name: "ISP" },
            { val: "postal", name: "Postal" },
            { val: "region", name: "Region" },
            { val: "timezone", name: "Time Zone" },


        ];

    const pageState = useSelector(state => (state.tableSlice.pageState.loginInfoData) ? state.tableSlice.pageState.loginInfoData : 0);


    let tableDataUserLogin = {
        tableId: "loginInfoData",
        tableTitle: "Login Info Data",
        showReload: true,
        showFilter: false,
        adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "profile/fetch-login-details",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "profile/fetch-login-details",
        },

        reqBody: {

            // "_id": userInfo?.userInfo?._id ? userInfo?.userInfo?._id : undefined, //-- list will show exculding this refrence user
            "source": "users",
            "condition": {
                "limit": 3,
                "skip": pageState ? ((pageState.newPage - 1) * 3) : 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
                "_id": props.userId

            },
            // "project": {
            //     "firstname": 1,
            //     "lastname": 1,
            //     "email": 1,
            //     "type": 1,
            //     "phone": 1,
            //     "createdon_datetime": 1,
            //     "address": 1,
            //     "status": 1,
            //     "last_login_time": 1
            // },
            "count": false,
            // "token": usersToken


        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["login_time",],
        paginationType: "stackPagination",
        // customSort: true,
        enableRowActionLoader: true,
        customBtnHead: [

        ],
        buttons: [

        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },


    };
    // -----------------------



    return (
        <>

            {/* ------ Confirmation Modal ------ */}

            <Modal
                className="modalblock lastLogin_Info"
                open={props.popuptoogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="BoxMui_modal" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>

                    <div className='rolelist_mainwrp'>


                        <div className='userlisttable'>

                            <div className="reactTableWrapper">
                                <div className="adminlisttable_v2 table_Icons ">
                                    {props.popupdata.length > 0 ? (
                                        <ListingTable
                                            tableData={tableDataUserLogin}
                                            dataset={props.popupdata}
                                            modifyHeaders={modifyTableHeaders}
                                        />
                                    ) : (<TableSkeleton count={5} />)}
                                </div>
                            </div>

                        </div>

                    </div>


                    <IconButton className="modalCloseBtn" onClick={ModalClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Modal>

        </>
    )
}


export default LastLoginModal;