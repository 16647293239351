import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchemailapprover } from '../../LandingpageReducer';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';

function OtherFormSuccessBlueCoast() {

  const dispatch = useDispatch()
  const { aeid } = useParams();
  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (aeid) dispatch(fetchemailapprover(aeid))
  }, [aeid])

  const loading = useSelector(state => (state.LandingpagefontReducer?.emailapproverloader) ? state.LandingpagefontReducer?.emailapproverloader : false)

  const editEmailApproverData = useSelector(state => (state.LandingpagefontReducer?.emailapproverfetchdata) ? state.LandingpagefontReducer?.emailapproverfetchdata : null)

  // const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.formothersedit) ? state.formSlice?.formSubmissionState?.formothersedit : 0)

  // const handleClose = (event, reason) => {
  //   setOpenSnack(false);
  // };



  // const formOtherseditBluecoast = useRef({
  //   id: 'formotherseditbluecoast',
  //   formtype: "edit",
  //   api_url: process.env.REACT_APP_API_URL,
  //   endPoint: "landing-page-manage/addupdateemailcampaignapprovers",
  //   // endPointBodyWrapper: "data",
  //   // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
  //   msgOnFailedFormSubmission: "Something Went Wrong",
  //   formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
  //   formInlineCLass: "userForm", // Can be undefined default will be formInline
  //   formButtonClass: "submitbtnsection9",
  //   submitBtnName: "Submit",
  //   // submitBtnStartIcon: 'image_search_icon',
  //   // submitBtnEndIcon: 'search_icon',
  //   // resetBtnName: "Reset",
  //   formAdditionalSubmissionData: {
  //     'id': editEmailApproverData && editEmailApproverData._id ? editEmailApproverData._id : undefined,
  //     //   "status": 0,
  //     // "roles": { is_rep: 1 },

  //   },
  //   fields: [
  //     //////////////////////////////////// Text Input ///////////////////////////////
  //     {
  //       id: 0,
  //       heading: "AE First Name",
  //       label: "AE First Name",
  //       name: "firstname",
  //       className: 'formfild formfild4',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.firstname !== undefined) ? editEmailApproverData.firstname : undefined,

  //     },
  //     {
  //       id: 2,
  //       heading: "AE Last Name",
  //       label: "AE Last Name",
  //       name: "lastname",
  //       className: 'formfild formfild4',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.lastname !== undefined) ? editEmailApproverData.lastname : undefined,

  //     },
  //     {
  //       id: 3,
  //       heading: "AE Email",
  //       label: "AE Email",
  //       name: "email",
  //       className: 'formfild formfild4',
  //       type: "text",
  //       inputType: "email",
  //       // disabled: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.email !== undefined) ? true : false,
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.email !== undefined) ? editEmailApproverData.email : undefined,

  //     },

  //     {
  //       id: 4,
  //       heading: "AE Phone Number",
  //       label: "AE Phone Number",
  //       name: "phone",
  //       className: 'formfild formfild4',
  //       type: "text",
  //       inputType: "phone",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.phone !== undefined) ? editEmailApproverData.phone : undefined,

  //     },
  //     {
  //       id: 5,
  //       heading: "Company Name (You are Buying/Hiring the List From)",
  //       label: "Company Name (You are Buying/Hiring the list)",
  //       name: "company_name",
  //       className: 'formfild formfild1',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.company_name !== undefined) ? editEmailApproverData.company_name : undefined,

  //     },

  //     {
  //       id: 6,
  //       heading: "Company Website",
  //       label: "Company Website",
  //       name: "company_website",
  //       className: 'formfild formfild1',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.company_website !== undefined) ? editEmailApproverData.company_website : undefined,
  //     },

  //     {
  //       id: 7,
  //       hheading: "Company Policy Page Link",
  //       label: "Company Policy Page Link",
  //       name: "company_policy",
  //       className: 'formfild formfild1',
  //       type: "text",
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.company_policy !== undefined) ? editEmailApproverData.company_policy : undefined,
  //     },
  //     {
  //       id: 11,
  //       heading: "Who do you plan to email?",
  //       name: "business_consumer_type",
  //       className: 'form_radio',
  //       values: [{ key: 'Business', val: 'Business' }, { key: 'Consumers', val: 'Consumers' }],
  //       type: 'multipleCheckbox',
  //       // hint: 'Yes/No',
  //       rules: { required: true },
  //       errorMessage: {
  //         required: "This Field is Required",
  //         custom: "Value is Invalid"
  //       },
  //       defaultValue: (editEmailApproverData && editEmailApproverData.length > 0 && editEmailApproverData.business_consumer_type !== undefined) ? editEmailApproverData.business_consumer_type : undefined,
  //       // defaultValue: "No"
  //     },
  //   ]

  // })

  return (
    <div>
       <div className='thankYou_wrpr blueCoast_Success'>
      <div className='thankYou_bgdesgn'>
        <div className="bgdesgn1"></div>
        <div className="bgdesgn2"></div>
        <div className="bgdesgn3"></div>
        <div className="bgdesgn4"></div>
        <div className="bgdesgn5"></div>
        <div className="bgdesgn6"></div>
        <div className="bgdesgn7"></div>
        <div className="bgdesgn8"></div>
      </div>
      <div className='thankyou-container'>
        <div className='thankyou-block'>

          <div className='content center'>
            {/* <h1>Successful, please review your submission </h1> */}
            <h1> <span>Success!</span>
              <span>Following are the details you submitteddddd. You will also receive a copy of this in your email from Do-Not-Reply@dealfirecrm.com.</span>
            </h1>
            {/* {!params && !params.rep && <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>}  */}
            {aeid ? <div className='thankyou-containerSub'>
              <div className='thankyou-blockSub'>
                {(loading) && <Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>}
                <div className="landingpage9successBody">
                  {/* <div className="editiconinsucccess" onClick={() => modalopen()}>
                   <EditIcon />
                 </div> */}
                  {!loading && editEmailApproverData && <div className="landingpage9success">

                    <p><strong>AE Name:</strong> <span> {editEmailApproverData.firstname} {editEmailApproverData.lastname}</span> </p>
                    <p><strong>AE Email:</strong> <span> {editEmailApproverData.email} </span> </p>
                    <p><strong>AE Phone Number:</strong> <span> {editEmailApproverData.phone} </span> </p>
                    <p><strong> Company Name (You are Buying/Hiring the List From):</strong> <span> {editEmailApproverData.company_name} </span> </p>
                    <p><strong> Company Website:</strong> <span> {editEmailApproverData.company_website} </span> </p>
                    <p><strong> Company Policy Page Link:</strong>  {editEmailApproverData?.company_policy ? <span>{editEmailApproverData.company_policy}</span> : <span>NA</span>}  </p>
                    <p><strong> Who do you plan to email?:</strong> {editEmailApproverData?.business_consumer_type ? <span>{editEmailApproverData.business_consumer_type.join(",")}</span> : <span>NA</span>} </p>
                    {/* <p><strong> Zip Code:</strong> {editEmailApproverData?.zip ? <span>{editEmailApproverData.zip}</span> : <span>NA</span>}  </p> */}
                    {/* <p><strong> Any other information we should know? :</strong>  {editEmailApproverData?.other_info ? <span>{editAspdatablue.other_info} </span> : <span>NA</span>} </p> */}

                    {/* <div className="bttnWrpr">

                      {doneBtnSuccess == 1 && <div className="editiconinsucccess" onClick={() => modalopen()}>
                        Edit

                      </div>}
                      <div className="editiconinsucccess" onClick={() => donebtnclicked()}>Done</div>
                    </div> */}


                    {/* <button className='b2dashboard' >Confirm and Go to Dashboard <DashboardIcon /></button> */}


                  </div>}
                </div>
              </div>
            </div> : <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>}
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default OtherFormSuccessBlueCoast