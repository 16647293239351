import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HmDashboardReducer from "./HmDashboardReducer";



export const getHmDashboardListData = createAsyncThunk("getHmDashboardListData",async(reqbody)=>{
    let req_body = {};
    req_body = { ...reqbody };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list",requestOptions)
    const respdata = await response.json();
    return respdata;
})


export const getDataFoHMDashboardChart = createAsyncThunk("getDataFoHMDashboardChart",async(reqbody)=>{
    let req_body = {};
    req_body = { ...reqbody };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-dashboard-chart",requestOptions)
    const respdata = await response.json();
    return respdata;
})


const HmDashboardListReducer=createSlice({
    name:"HmDashboardListReducer",
    initialState:{
        listData:[],
        gridData:[],
        listType:null,
        gridListName:"AE Created Grid View",
        hmChartdata:[],
        loader:false,
        loaderforHM:false
    },
    reducers:{
        cleardashbordListdata(state,action){
            state.listData=[]
            state.gridData=[]
            state.hmChartdata=[]
            state.listType=null
        },
        clearGridListName(state,action){
            state.gridListName=null
        },
        clearGridList(state,action){
            state.gridData=[]
        }

    },
    extraReducers:{
        [getHmDashboardListData.rejected]:(state,action)=>{

        },
        [getHmDashboardListData.pending]:(state,action)=>{
            state.loader = true
        },
        [getHmDashboardListData.fulfilled]:(state,action)=>{
            state.listType = action.meta.arg.type
            if(action.meta.arg.type === "rep_created") state.gridListName = "AE Created"
            if(action.meta.arg.type === "opportunities") state.gridListName = "Opportunities"
            if(action.meta.arg.type === "training") state.gridListName = "Training"
            if(action.meta.arg.type === "folder_access_lead") state.gridListName = "Folder Access Lead"
            if(action.meta.arg.type === "folder_access_rep") state.gridListName = "Folder Access AE"
            if(action.meta.arg.type === "follow_up_call") state.gridListName = "Follow Up Call"
            if(action.meta.arg.type === "lead_appointment") state.gridListName = " Appointment"
            if(action.meta.arg.type === "aer_created") state.gridListName = "AEE Joined"
            if(action.meta.arg.type === "discovery_call_rep") state.gridListName = " Discovery call for AE"
            if(action.meta.arg.type === "conversion_done") state.gridListName = " Conversion Done"
            if(action.meta.arg.type === "email_sent") state.gridListName = "Email Sent"
            if(action.meta.arg.type === "note_added") state.gridListName = "Note Added"
            if(action.meta.arg.type === "info_link_sent") state.gridListName = "Information Link send"
            state.listData=action.payload.results.res
            console.log("test============>>>>1", state.listData);
            state.gridData=action.payload.results.grid_data
            console.log("test============>>>>2", state.gridData);

            state.loader = false
            
        },
        [getDataFoHMDashboardChart.rejected]:(state,action)=>{
            state.successAction = false;
            state.loaderForAdminData = false;
        },
        [getDataFoHMDashboardChart.pending]:(state,action)=>{
            state.loaderforHM = true;
            state.loader = true
        },
        [getDataFoHMDashboardChart.fulfilled]:(state,action)=>{
            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.hmChartdata = action.payload.results;

                state.loaderforHM = false;

            }
            
        }
    }
})

export default HmDashboardListReducer.reducer
export const {clearHmListData, clearGridList, cleardashbordListdata, clearGridListName}=HmDashboardListReducer.actions