import React from 'react'
import "../LandingPage13.css";

function PharmacyCoachingBlock() {
    return (
        <>
            {/* <div className='pharmacy_coaching_block right_img_left_text'>
                <div className='SD_common_wrapper SD_common_padding SD_font45 SD_font30'>
                    <h2>Our Pharmacy Coaching <br />Team Is Second-To-None! Call Us Today!
                    </h2>
                    <h3>Please call our Customer Support team at <a href="tel:800.863.8926">800.863.8926</a> if you have
                        any questions.</h3>
                </div>

            </div> */}
            <div className='kalvin_block'>
                <div className='SD_common_wrapper'>
                    <div className='SD_flex13 SD_font25'>
                        <div className='SD_imagewrapper13'>
                            {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/cloud_left_img.webp' /> */}
                            <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/cloud_left_img.webp`}  />
                        </div>
                        <div className='SD_textwrapper13'>
                            <p>The prices on prescriptions for my family were going up every year, and I couldn't keep paying the high prices, so I searched for a better option. I came across Access Discount Healthcare and I won't buy them any other way now. With Access Discount Healthcare, I pay nothing for our medication!<span>-LAUREN KALVIN</span></p>
                        </div>

                    </div>
                </div>

            </div>
            <div className='pharmacylogo_block'>
                <div className='red_heading'>
                    <h2>ACCEPTED AT 64,000 PHARMACIES NATIONWIDE INCLUDING:</h2>

                </div>
                <div className='logo_flex'>
                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/wallgreens_logo+(1).webp' /> */}
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/wallgreens_logo.webp`} />
                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/rite_logo.webp' /> */}
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/rite_logo.webp`} />
                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/walmart_logo+(1).webp' /> */}
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/walmart_logo.webp`} />
                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/cvs_logo+(1).webp' /> */}
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/cvs_logo.webp`} />
                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/albertsons_logo+(1).webp' />   */}
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage13/albertsons_logo.webp`} />  

                </div>

            </div>

            <div className='pharmacy_program_block right_img_left_text'>
                <div className='SD_common_wrapper SD_common_padding SD_font45 SD_font30'>
                    <h2>Everything you need to know about the program
                    </h2>
                    <h3>Stop Paying For Prescriptions…It’s Easy!</h3>
                    <p>At Access Discount Healthcare we have made it our mission to help the uninsured and underinsured (people who have insurance but whose deductibles are so high they never meet them, and they are paying full price for medicine). With decades of relationships, partnerships, and buying power, we have pooled our resources and are bringing you the lowest prices on medications in the country.</p>
                </div>

            </div>


        </>
    )
}

export default PharmacyCoachingBlock
