import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for Medicine-list
export const MedicineListing = createAsyncThunk("medicine/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false,
        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/medicinelist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


/**********************************Medicine Edit******************* */
export const medicineEditdata = createAsyncThunk("edit/medicinename", async (id) => {
    let reqBody={
        
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "name",
                "type": "desc"
            },
            "searchcondition": {
                "_id":id
            },
            "project": {},
            "token": ""
        
    }
    // console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/medicinelist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})



const medicine = createSlice({

    name: 'medicine',

    initialState:{

        submitting: false,
        loading: false,
        submitted: false,
        resMessage: null,
        successmsg: null,
        success: false,
        failed: false,
        medicinedata: [],
        editLoading: false,
        editDataMedicine: [],
        
        
        // materialGridData:[],
        // materialGridDataCount:null,
        


    },
    reducers:{
        clearMedicineList(state, action) {
            state.medicinecategorydata = [];
           
        },
        cleareditdataMedicine(state, action) {
            state.editDataMedicine=[];
        },
        clearDrugList(state, action){
            state.medicinedata = [];
        },
      

    },

    extraReducers:{

        /// #################### Training Listing ###############################
        [MedicineListing.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [MedicineListing.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [MedicineListing.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.medicinedata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
            }
        },
              /**************************************Medicine Category Edit******************** */
              [medicineEditdata.rejected]: (state, action) => {
                // state.endpointsuccess[action.type] = false
                // state.loading = false;
                // state.successAction = false;
    
            },
            [medicineEditdata.pending]: (state, action) => {
    
    
                // console.log("medicineEditdata=========================>")
                state.editLoading = true;
    
            },
            [medicineEditdata.fulfilled]: (state, action) => {
    
                // console.log("action from storedata", action)
                if (action.payload.status === 'success') {
                
                    state.editDataMedicine = action.payload.results.res[0];
                    // console.log("state for edit data training", state.editDataTraining)
                    state.editLoading = false;
    
                }
    
            },




    }
})

export default medicine.reducer;

export const { clearMedicineList, cleareditdataMedicine, clearDrugList} = medicine.actions;