import { FormControl, FormHelperText, FormLabel, InputLabel, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import store from '../../store'
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer'

function ColorPicker({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) {
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)


  const dispatch = useDispatch()
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue) {
      setValue(fielddata.name, fielddata.defaultValue);
    }
  }, [])

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })
  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel className='input_heading'><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        {fielddata.label && <FormLabel className='input_label'>{fielddata.label}</FormLabel>}
        <div className='color_picker_container'>
          <input
            className='color_picker'
            type='color'
            value={getValues(fielddata.name) ? getValues(fielddata.name) : null}
            onChange={(e) => {
              // console.log("e===========================>", e.target.value)
              setValue(fielddata.name, e.target.value);
              clearErrors(fielddata.name); // Clearing errors on focus or change
            }}
          />

          <input className='color_viewer' type="text" value={getValues(fielddata.name) ? getValues(fielddata.name) : null} disabled={true} />
        </div>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText className='input_error' sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}

export default ColorPicker