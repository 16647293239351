import { IconButton, LinearProgress, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Form } from '../../../form/Form';
import StateArray from '../../../assets/json/state';
import store from '../../../store';
import { businessManager, clearBusinessManager } from './BlueCoastReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setReloadTableFlag } from '../../../listing/listReducer';
import { setSnackbar } from '../../Layout/layoutReducer';


const AddBusinessManagerModal = ({ rowData, userData, businessManagerFormOpen, businessManagerFormClose, editData }) => {


  const dispatch = useDispatch()
  const [formOpen, setFormOpen] = useState(false)
  const [loader, setLoader] = useState(false);
  const [submissionState, setSubmissionState] = useState(0);
  const formInputData = useSelector((state) =>
    state.formSlice.formData.addeditbusinessmanagerform
      ? state.formSlice.formData.addeditbusinessmanagerform
      : null
  );

  const cancelButtonClicked = useSelector((state) => state.formSlice.customButtonClicked?.addeditbusinessmanagerform?.goback001 ? state.formSlice.customButtonClicked.addeditbusinessmanagerform.goback001 : false)



  // useEffect(()=>{
  //   console.log("rowData+++++", rowData);
  //   console.log("userData++++",userData);
  //   console.log("editData+++++++++", editData)
  // },[])




  const addapi = async (val) => {

    val = { ...val };
    let body = {
      title: val.title,
      name: val.name,
      email: val.email,
      phone: val.phone,
      notes: val.notes,
      // state: val.state,
      // city: val.city,
      // zip: val.zip,
      createdon_datetime: new Date().getTime(),
      addedfor: rowData?.rep_id,
      // addedfor_name:rowData?.fullname,
      prospect_id: rowData?._id,
      // prospect_name:rowData?.prospectfullname,
      addedby: userData._id,
      // addedbyname: userData.name,


      _id:
        editData && Object.keys(editData).length > 0
          ? editData._id
          : undefined,

    };

    setLoader(true);
    if (Object.keys(body).length > 0) {

      await store.dispatch(businessManager(body));

    }
    setLoader(false);
    // for (let k in formDispositionTask.current.fields) {


    //   let field = cloneDeep(formDispositionTask.current.fields[k]);
    //   field.defaultValue = undefined;
    //   formDispositionTask.current.fields[k] = field;

    //   // let fieldnew =  formDispositionTask.current.fields[k]
    //   // fieldnew = {...fieldnew,defaultValue:undefined}
    // }

    dispatch(
      setSnackbar({
        open: true,
        message:
          editData && Object.keys(editData).length > 0
            ? "Updated Successfully"
            : "Added Successfully",
        status: "success",
      })
    );

    setTimeout(() => { handleCloseModal() }, 3000)
    // setEditTaskData(null) 
    dispatch(setReloadTableFlag({ tableId: "businessmanagers" }));
    dispatch(clearBusinessManager())
    // clearDispositionTaskList()
    // setsaveRowdata(null)

  };


  const addBusinessManager = useRef({

    id: 'addeditbusinessmanagerform',
    api_url: process.env.REACT_APP_API_URL,
    endPointBodyWrapper: " ",
    submitBtnName: (editData?._id !== undefined && editData?._id !== null) ? "Update" : "Submit",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin dispositiontask",
    formInlineCLass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {
      // _id: (editData._id !== undefined && editData._id !== null) ? editData._id : undefined,
    },


    fields: [
      {
        id: 0,
        heading: "Title",
        label: "Title",
        name: "title",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editData && editData.title !== undefined) ? editData.title : undefined,
        // defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.tasktitle !== undefined) ? editeddata.tasktitle : undefined,


      },
      {
        id: 1,
        heading: "Name",
        label: "Name",
        name: "name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editData && editData.name !== undefined) ? editData.name : undefined,
        // defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.tasktitle !== undefined) ? editeddata.tasktitle : undefined,


      },
      {
        id: 13,
        heading: "Your Email",
        label: "Email",
        name: "email",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: (editData && editData.email !== undefined) ? editData.email : undefined,

      },

      {
        id: 14,
        heading: "Your Phone Number",
        label: "Phone Number Format: (123) 456-7890",
        name: "phone",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "phone",
        // rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
        defaultValue: (editData && editData.phone !== undefined) ? editData.phone : undefined,

      },
      //   {
      //     id: 6,
      //     // heading: "Full Name",
      //     label: "Town / City",
      //     name: "city",
      //     className: 'formfild',
      //     type: "text",
      //     rules: { required: true },
      //     errorMessage: {
      //         required: "This Field is Required",
      //         custom: "Value is Invalid"
      //     },
      //     defaultValue: (editData && editData.city !== undefined) ? editData.city : undefined,


      // },
      // {
      //     id: 7,
      //     // heading: "Full Name",
      //     label: "State",
      //     name: "state",
      //     className: 'formfild state',
      //     type: "select",
      //     // other: true,
      //     values: StateArray,
      //     rules: { required: true },
      //     errorMessage: {
      //         required: "This Field is Required",
      //         custom: "Value is Invalid"
      //     },
      //     defaultValue: (editData && editData.state !== undefined) ? editData.state : undefined,

      // },
      //   {
      //     id: 8,
      //     // heading: "Full Name",
      //     label: "Zip / Postal Code",
      //     name: "zip",
      //     className: 'formfild',
      //     type: "text",
      //     // inputType:"number",
      //     inputType: "zip",
      //     // rules: { required: true },
      //     // errorMessage: {
      //     //     required: "This Field is Required",

      //     // },
      //     defaultValue: (editData && editData.zip !== undefined) ? editData.zip : undefined,


      // },


      {
        id: 15,
        // heading: "Task Description",
        label: "Notes",
        name: "notes",
        className: 'formfild formfild4 notes',
        type: "textarea",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        // defaultValue: (editeddata && Object.keys(editeddata).length > 0 && editeddata.taskdescription !== undefined) ? editeddata.taskdescription : undefined,
        defaultValue: (editData && editData.notes !== undefined) ? editData.notes : undefined,


      },






    ],

    customButtons: [
      {
        id: 'goback001',
        label: 'Cancel',
        className: 'default',
        type: 'button',
      },
    ]
  })


  const handleCloseModal = () => {
    businessManagerFormClose(false);
    setFormOpen(false)

  };

  store.subscribe(() => {
    const storeData = store.getState();
    if (
      storeData &&
      storeData.formSlice &&
      storeData.formSlice.formSubmissionState &&
      storeData.formSlice.formSubmissionState.addeditbusinessmanagerform
    ) {
      setSubmissionState(storeData.formSlice.formSubmissionState.addeditbusinessmanagerform);
    }

  });

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapi(formInputData);

    }
  }, [submissionState]);


  useEffect(() => {
    if (cancelButtonClicked) {
      handleCloseModal()

    }

  }, [cancelButtonClicked])


  return (
    <>
      <Modal
        className="addFormBlueCOast addtaskListMOdal SR_addTask_list addBusinessMngr"
        open={businessManagerFormOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={handleCloseModal}
              // className="CloseModal"
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <div className='taskMOdal_wrp'>
            <h2 className="previewModalTitle ">

              <div className="modal_title modal_titlenew">
                {editData &&
                  Object.keys(editData).length > 0
                  ? "Edit Business Manager"
                  : "Add Business Manager"}
              </div>

            </h2>
            {!formOpen && editData && Object.keys(editData).length > 0 && editData?._id ? <Form formData={addBusinessManager.current} /> : !editData?._id && <Form formData={addBusinessManager.current} />}


          </div>
          {loader ? (
            <Box
              className="loaderProgress"
              sx={{ width: "100%", mt: "10px" }}
            >
              <LinearProgress />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Modal>

    </>
  )
}

export default AddBusinessManagerModal