import React, { useEffect, useState } from 'react'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from '@mui/system';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import store from '../../store';

export const DateRangePickerField = ({ fielddata, value, setValue, getValues, errors, clearErrors, setError, formId }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [customError, setCustomError] = useState(null)



  const dispatch = useDispatch()

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue) {
      setDateRange(fielddata.defaultValue);
      setValue(fielddata.name, fielddata.defaultValue);
    }
  }, []);

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      setValue(fielddata.name, dateRange);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [dateRange])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setDateRange(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Check-in"
            endText="Check-out"
            value={dateRange}
            onOpen={() => {
              clearErrors(fielddata.name);

              //////////////////////// Removing Missing License Key Message /////////////////////
              setTimeout(() => {
                if (document.querySelector(fielddata.className) != undefined && document.querySelector(fielddata.className).style != undefined)
                  document.querySelector(fielddata.className).style.display = 'none';

                let e = document.getElementsByTagName("div");
                for (let i = 0; i < e.length; i++) {
                  let textval = e[i].innerText;
                  if (textval.includes('Missing license key') && textval.length < 30) {
                    e[i].style.display = 'none';
                  }

                }
                if (document.querySelector(fielddata.className) != undefined && document.querySelector(fielddata.className).style != undefined)
                  document.querySelector(fielddata.className).style.display = 'block'
              }, 200);
            }}
            onChange={(newValue) => {
              // console.log('newValue', newValue);
              newValue.forEach(val => console.log(val));
              setDateRange(newValue);
              clearErrors(fielddata.name);
            }}
            onClose={() => {
              if (!dateRange.includes(null)) {
                setValue(fielddata.name, dateRange.map(date => date.getTime())); //////////// Converting Date Object to milisecond ////////////
              } else {
                setError(fielddata.name, { type: "invalid" });
              }
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
        {/* ERROR */}
        {errors[fielddata.name]?.type && fielddata?.errorMessage && <FormHelperText sx={{ color: 'red' }}>{fielddata?.errorMessage[errors[fielddata.name]?.type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}

// Remove License Console Error : go to the file node_modules/@mui/x-license-pro/utils/licenseErrorMessageUtils.js and remove console.error() of showError() function
