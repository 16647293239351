import React, { useEffect, useRef } from 'react'
import ListingTable from "../../../../listing/listing";
import store from '../../../../store';
import { getjobcategorydata, clearjobcategorydata, getjobcategoryeditdata } from '../JobTicketReducer';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer'
import AddIcon from '@mui/icons-material/Add';



function JobticketsCategory() {


  var modifyTableHeaders =
    [
      { val: "category_name", name: "Category Name" },
      { val: "priority", name: "Priority" },
      { val: "user_role", name: "User Type" },
      { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active", } },
      { val: "createdon_datetime", name: "Created On", type: "datetime", format: "MM/DD/YYYY" },
    ];
  let reqbody = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }
  // let [responseData, setresponseData] = React.useState([]);

  const tableData1 = useRef({
    tableId: "jobcategorytable",
    tableTitle: "Job Category Listing",
    showReload: true,
    showFilter: true,
    // recieveResponseParam: 'results',
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/job-cat-list",
      deleteSingleUserEndpoint: "lead-manage/delete-job-category",
      tableCountEndpoint: "lead-manage/job-category-list-count",
    },
    reqBody: {
      "source": "",
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      "source": "users",
      "secret": "AZ|lepL`",
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
    },
    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["category_name", "status", "priority", "createdon_datetime"],
    enableRowActionLoader: true,
    paginationType: "stackPagination",
    customBtnHead: [
      {
        id: "deleteMultiple",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-job-category",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "editMultiple",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/job-category-status-update",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      // {
      //   id: "home",
      //   type: "home_icon",
      //   title: "Home",
      // }
    ],
    buttons: [
      {
        id: "editjobcategory",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "deletejobticketcategory",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-job-category",
          body: {
            "source": "lead_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },

      {
        id: "viewBtnAction",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Category Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/job-cat-list",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {}
          }
        },
        headers: [
          { val: "category_name", label: "Category Name" },
          { val: "category_des", label: "Description" },
          { val: "priority", label: "Priority" },
          // { val: "status", label: "Status", customVal: {"1":"Active"} },
          { val: "user_role", label: "User Type" },
          { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
        ]
      },

      {
        id: "statuschange",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/job-category-status-update",
          body: {
            "source": "lead_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },


    ],
    deleteModal: {
      modalClassName: "Delete Modal",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },

    searchData: {
      heading: "Search Job Ticket Category",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [

          {
            id: 1,
            label: "Search By Category Name",
            name: "category_name",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-category",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "category_name",
              },
              "searchcondition": {
              }
            },
            payloadFormat: { key: "category_name", param: '$regex', options: 'i' },
          },
          {
            id: 2,
            label: "Search By priority ",
            type: "autoComplete",
            inputType: "number",
            name: "priority",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-category",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "category_name",
              },
              "searchcondition": {
                
              }
            },
            payloadFormat: { key: "priority" },

          },
          {
            id: 3,
            label: "Search By Type ",
            type: "autoComplete",
            name: "user_type",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-user-type-search",
            reqBody: {
              source: "roles",
              sort: {
                type: "desc",
                field: "user_type",
              },
              "searchcondition": {

              }
            },
            payloadFormat: { key: "user_type",param: '$regex', options: 'i' },

          },
          {
            id: 4,
            label: "Search by Status",
            name: "status",
            type: 'select',
            multiple: false,
            other: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            // sx: { m: 1, minWidth: 120 },
            className: 'inputblock2line',
            payloadFormat: { key: "status" },
          },
          {
            id: 5,
            label: "Search by Start Date",
            name: "createdon_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: 'inputblock2line',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 6,
            label: "Search by End Date",
            name: "createdon_datetime1",
            className: 'inputblock2line',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },


        ]
      }
    },

  })






  let responseData = useSelector(state => (state.JobTicketReducer?.jobcategorydata && state.JobTicketReducer?.jobcategorydata.length > 0) ? state.JobTicketReducer.jobcategorydata : [])
  let loader = useSelector(state => (state.JobTicketReducer?.loading) ? state.JobTicketReducer.loading : false)
  const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.jobcategorytable?.editjobcategory ? state.tableSlice?.customButtonClicked?.jobcategorytable?.editjobcategory : false)
  const editData = useSelector(state => state.tableSlice?.tempRowData?.jobcategorytable ? state.tableSlice.tempRowData.jobcategorytable : {})
  const resolved = useSelector(state => state.ResolveReducer.resolved['/jobticket-category'] ? state.ResolveReducer.resolved['/jobticket-category'] : false)


  useEffect(() => {
    if (!resolved) store.dispatch(getjobcategorydata(reqbody));
    return () => {
      store.dispatch(clearjobcategorydata())
      store.dispatch(resetresolvenew('/jobticket-category'))
    }
  }, [])



  const navigate = useNavigate();
  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      resolvefunction(navigate, `/edit-jobtickets-category/${editData._id}`, getjobcategoryeditdata(editData._id))
    }
  }, [editClicked])

  useEffect(() => {
    store.dispatch(getjobcategorydata(reqbody));
    return () => {
      store.dispatch(clearjobcategorydata())
    }
  }, [])




  return (
    <>
      <div className='rolelist_mainwrp'>
        <div className='listing_heading'>
          <h1>Job Tickets Category</h1>
          {/* <button className='addrole_btn'>Add Role</button> */}
          <button className='addrole_btn' onClick={() => navigate('/add-jobtickets-category')}><AddIcon /></button>
        </div>
        <div className='rolelist_mainwrp_Table'>
          {(responseData && responseData.length > 0) ? <ListingTable tableData={tableData1.current} dataset={responseData} modifyHeaders={modifyTableHeaders} /> : <p>No Records Found</p>}
        </div>
      </div>




    </>
  )
}

export default JobticketsCategory
