import { Button, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { Box, width } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { MdDashboard, MdManageAccounts } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { inputExternalPlugin, resolvefunction } from '../../helper/helperFunctions';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MdKeyboardArrowDown, MdOutlineModelTraining } from "react-icons/md";
import { getusersList } from '../Backend/userManagement/userList/userListReducer';
import { FilterAffiliateReport, setAffiliateInitData } from '../Backend/AffiliateManagement/AffiliateReducer';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { EmailCampaignApproverlist } from '../Frontend/LandingPage/LandingpageReducer';
import { useCookies } from 'react-cookie';
import { getcommisionReportData } from '../Backend/AffiliateManagement/W9FormReducer';
import moment from 'moment';
import { affiliateConversionReportList } from '../Backend/AffiliateManagement/AffiliateConversionReport/AfffiliateConverisonReportReducer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { employeePackageTotalCost, viewEmployeeList, viewSubEmployersList, viewlocationsListData } from '../Backend/EmployerManagement/EmployerReducer';
import { MdConfirmationNumber } from "react-icons/md";
import {getjobticketlistdata } from '../Backend/Jobtickets/JobTicketReducer';
import { trainingCenterCategoryFetching } from '../Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer';
import { TrainingMaterialCenterlist } from '../Backend/Training/TrainingMaterial/TrainingMaterialReducer';

function BlueCoastNew({ topmenu }) {
  // const dispatch = useDispatch();
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate()

  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});

  const splitLocation = pathname.split("/");
  const [openModalCampaign, setOpenModalCampaign] = React.useState(false);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [anchorEl44, setAnchorEl44] = useState(null);
  const [anchorEl45, setAnchorEl45] = useState(null);
  const [childMenuActive, setChildMenuActive] = useState(false);
  const [anchorEl6, setAnchorEl6] = useState(null);

  const [childMenuEmpl, setChildMenuEmpl] = useState(false);

  const [anchorEl75, setAnchorEl75] = useState(null);





  const [openMoadl, setOpenMoadl] = React.useState(false);
  const [cookieData, setCookieData] = useCookies();

  const userInfo = useSelector((state) => (state.loginSlice && state.loginSlice?.userInfo) ? state.loginSlice?.userInfo : null)



  const monthStartDate = moment().startOf('month').valueOf()
  const monthEndDate = moment().endOf('month').valueOf()



  const closeModalCampaign = () => {
    setOpenModalCampaign(false)
  };

  const open4 = Boolean(anchorEl4);
  const handleClose4 = () => setAnchorEl4(null);
  const handleClick4 = (event) => setAnchorEl4(event.currentTarget);

  //=================Training Center Menu===================
  const [anchorEl40, setAnchorEl40] = useState(null);
  const open40 = Boolean(anchorEl40);
  const handleClose40 = () => setAnchorEl40(null);
  const handleClick40 = (event) => setAnchorEl40(event.currentTarget);

  //============Manage Location=======================

  const open75 = Boolean(anchorEl75)
  const handleClick75 = (event) => { setChildMenuEmpl(true); setAnchorEl75(event.currentTarget) };
  const handleClose75 = () => setAnchorEl75(null);

  useEffect(() => {
    if (anchorEl75 === null) {
      setChildMenuActive(false)
    }
  }, [anchorEl75])

  //============Manage Location=======================


  const handleClick44 = (event) => { setChildMenuActive(true); setAnchorEl44(event.currentTarget) };
  const handleClose44 = () => { setAnchorEl44(null); }
  const open44 = Boolean(anchorEl44);


  const handleClick45 = (event) => { setChildMenuActive(true); setAnchorEl45(event.currentTarget) };
  const handleClose45 = () => { setAnchorEl45(null); }
  const open45 = Boolean(anchorEl45);

  const handleClick6 = (event) => setAnchorEl6(event.currentTarget);
  const handleClose6 = (evevt) => setAnchorEl6(false)

  useEffect(() => {
    if (anchorEl44 === null) {
      setChildMenuActive(false)
    }
  }, [anchorEl44])


  useEffect(() => {
    if (anchorEl45 === null) {
      setChildMenuActive(false)
    }
  }, [anchorEl45])

  // console.log("logedUserData==============>>>>", logedUserData)




  const payload_affiliatelist = {
    "condition": {
      "limit": 30, "skip": 0
    },
    "sort": {
      "field": "createdon_datetime", "type": "desc"
    },
    "searchcondition": {
      "roles.is_affiliate": 1,
      "added_by": cookieData?.userinfo?._id && cookieData.userinfo._id

    },
    "count": false
  }



  const commisionBody = {
    "condition": {
      "limit": 30, "skip": 0
    },
    "sort": {
      "field": "record_date", "type": "desc"
    },
    "searchcondition": { rep_id: logedUserData._id },
    "count": false
  }


  const repandaffcommisionBody = {
    "condition": {
      "limit": 30, "skip": 0
    },
    "sort": {
      "field": "record_date", "type": "desc"
    },
    "searchcondition": { $or: [{ user_id: logedUserData._id }, { rep_id: logedUserData._id }] },
    "count": false
  }

  let reqbodyforjobcategory = {
    condition: {
        limit: 10,
        skip: 0,
    },
    sort: {
        field: "createdon_datetime",
        type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
};


  // const downloadEmployeeData = () => {
  //   fetch('https://all-frontend-assets.s3.amazonaws.com/Aspire_Free_Pharmacy_Savings_Overview.pdf')
  //     .then(response => {
  //       response.blob().then(blob => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement('a');
  //         a.href = url;
  //         a.download = 'Aspire_Free_Pharmacy_Savings_Overview.pdf';
  //         a.click();
  //       });
  //       //window.location.href = response.url;
  //     });
  // }



  //  ============  resolve call for affiliate commission report ======= // 
  function commissionReportMonthly() {
    let payloadMonth = {
      "condition": {
        "limit": 30,
        "skip": 0
      },

      "sort": {
        "type": "desc",
        "field": "record_date"
      },

      "searchcondition": logedUserData.main_role === "is_rep" ? { $or: [{ rep_id: logedUserData._id }, { user_id: logedUserData._id }] } : { _id: logedUserData._id }
    }

    payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }

    dispatch(FilterAffiliateReport(payloadMonth))
  }

  // ================================================================ //





  const closeModal = () => {
    setOpenMoadl(false)


  };
  const openModal = () => {
    setOpenMoadl(true)


  };

  // =====================payload for location list API for employer dashboard===================

  const payLoadLocationList = {

    "condition": {
      "limit": 30,
      "skip": 0
    },
    "sort": {
      "field": "createdon_datetime",
      "type": "desc"
    },
    "searchcondition": {
      employer_id: logedUserData?._id
    },
    "project": {},
    "token": "",
    "email": "",
    "count": false

  }

  // =====================payload for location list API for employer dashboard===================

  const payLoadEmployeeList = {

    "condition": {
      "limit": 30,
      "skip": 0
    },
    "sort": {
      "field": "createdon_datetime",
      "type": "desc"
    },
    "searchcondition": {
      employer_id: logedUserData?._id
    },
    "project": {},
    "token": "",
    "email": "",
    "count": false

  }

  // =====================payload for location list API for employer dashboard===================

  const payLoadSubEmployerList = {

    "condition": {
      "limit": 30,
      "skip": 0
    },
    "sort": {
      "field": "createdon_datetime",
      "type": "desc"
    },
    "searchcondition": {
      added_by: logedUserData?._id
    },
    "project": {},
    "token": "",
    "email": "",
    "count": false

  }

  // =====================payload for employee count list API for employer dashboard===================

  const payLoadEmployeeCountList = {

    "condition": {
      "limit": 30,
      "skip": 0
    },
    "sort": {
      "field": "createdon_datetime",
      "type": "desc"
    },
    "searchcondition": {
      employer_id: logedUserData?._id,
      // status: true,
    },
    "project": {},
    "token": "",
    "email": "",
    "count": false

  }


  // =====================payload for sub employer count list API for employer dashboard===================

  const payLoadSubEmployerCountList = {

    "condition": {
      "limit": 30,
      "skip": 0
    },
    "sort": {
      "field": "createdon_datetime",
      "type": "desc"
    },
    "searchcondition": {
      added_by: logedUserData?._id,
      status: true,
    },
    "project": {},
    "token": "",
    "email": "",
    "count": false

  }


  const payLoadEmployeeListSublogin = {

    "condition": {
      "limit": 30,
      "skip": 0
    },
    "sort": {
      "field": "createdon_datetime",
      "type": "desc"
    },
    "searchcondition": {
      // employer_id: logedUserData?._id

      $or: [{ employer_id: logedUserData?._id },
      {
        location_id: {
          $in:
            logedUserData?.location_id

        }
      }]
    },
    "project": {},
    "token": "",
    "email": "",
    "count": false

  }

  let userslug = useSelector(state => (state.loginSlice?.user_slug && state.loginSlice?.user_slug.length > 0) ? state.loginSlice.user_slug : [])


  let reqbodyformeterilcenter = {
    "roles": userslug,
    "searchcondition":{},
  }


  const trainingCenterFUnc = async () => {
    await inputExternalPlugin("training-center", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/elements.js");
    resolvefunction(navigate, "/training-center", [
        trainingCenterCategoryFetching()
    ]);
    setTimeout(() => {
        document.location.reload();
    }, 2000)
}




  return (

    <>
      <div>
        <ul>

          {logedUserData?.roles?.is_employer !== 1 && logedUserData?.roles?.is_subEmployer !== 1 && logedUserData?.roles?.is_employee !== 1 && <li>
            <button onClick={() => { navigate("/new-ae-dashboard") }} className={(pathname && pathname == '/new-ae-dashboard') ? 'active' : ''}>
              <MdDashboard />

              <span>Dashboard</span>
            </button>
          </li>}

          {/* <li>
          <button onClick={() => { navigate("/lead-list") }} className={(pathname && pathname == '/lead-list') ? 'active' : ''}>
            <span>Enrollment Form List</span>
          </button>
        </li> */}





          {logedUserData?.roles?.is_employer !== 1 && logedUserData?.roles?.is_subEmployer !== 1 && logedUserData?.roles?.is_employee !== 1 && <li>
            <button onClick={() => { navigate("/enrollment-list") }} className={(pathname && pathname == '/enrollment-list') ? 'active' : ''}>
              <span>Landing Page Forms Filled Out</span>
            </button>
          </li>}

          {/* {process.env.REACT_APP_ENVIRONMENT != 'prod' && */}

          {/* {console.warn("logedUserData===>>>", logedUserData)} */}


          {(logedUserData?.signup_form == "aspire" || logedUserData?.signup_form == "bluecoast") && logedUserData?.roles?.is_employer !== 1 && logedUserData?.roles?.is_subEmployer !== 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/commission-report",
                // getcommisionReportData(repandaffcommisionBody)

              );
            }} className={(pathname && pathname == '/commission-report') ? 'active' : ''}>
              <span>Commission Report</span>
            </button>
          </li>}



          {(logedUserData?.signup_form !== "aspire" && logedUserData?.signup_form !== "bluecoast") && logedUserData?.roles?.is_employer !== 1 && logedUserData?.roles?.is_subEmployer !== 1 && logedUserData?.roles?.is_employee !== 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/ae-sales-report",
                getcommisionReportData(repandaffcommisionBody)

              );
            }} className={(pathname && pathname == '/ae-sales-report') ? 'active' : ''}>
              <span>Sales Report</span>
            </button>
          </li>}


            {/* Training */}
            {logedUserData?.roles?.is_employer !== 1 && logedUserData?.roles?.is_subEmployer !== 1 && logedUserData?.roles?.is_employee !== 1 &&  <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open40 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open40 ? "true" : undefined}
                        onClick={handleClick40}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={
                            pathname == "/training-category/list" ||
                                pathname == "/training-center" ||
                                pathname == "/training-lesson/list" ||
                                pathname === "/training-material" ||
                                pathname === "/quizzes" ||
                                pathname === "/quiz-add" ||
                                pathname === "/quiz-edit"
                                ? "active"
                                : "inactive"
                        }
                    >


                        <MdOutlineModelTraining />
                        <span>Training</span>
                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>

                </li>}

                <li>
                    <Menu
                        // className="addVid_dropDwn"
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}

                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl40}
                        open={open40}
                        onClose={handleClose40}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                      
                        <MenuItem
                            onClick={() => {
                                handleClose40();
                                trainingCenterFUnc();


                            }}
                        >
                            <span>Training Center</span>
                        </MenuItem>
                     

                        <MenuItem
                            className={
                                splitLocation[1] === "training-material-centar-list"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(
                                    navigate,
                                    "/training-material-centar-list",
                                    TrainingMaterialCenterlist(reqbodyformeterilcenter)
                                );
                            }}
                        >
                            <span> Training Material Center </span>
                        </MenuItem>

                    </Menu>
                </li>




          {/* {/ ------------- ############ Manage Affiliate Menu #################   -------------- } */}

          {/* process.env.REACT_APP_ENVIRONMENT != 'prod' && */}
          {process.env.REACT_APP_ENVIRONMENT != 'prod' && cookieData?.userinfo?.is_nakagames !== true && (cookieData?.userinfo?.signup_form == "aspire" || cookieData?.userinfo?.signup_form == "bluecoast") && <li>
            <Button
              id="demo-positioned-button"
              aria-controls={open4 ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open4 ? "true" : undefined}
              onClick={handleClick4}
              endIcon={<KeyboardArrowDownIcon />}
              className={
                pathname == "/affiliate-report" ||
                  pathname == "/view-affiliate" ||
                  pathname == "/create-affiliate"
                  ? "active"
                  : "inactive"
              }
            >


              <MdOutlineModelTraining />
              <span>Manage Users</span>
              <MdKeyboardArrowDown className="leftArrowIcon" />
            </Button>

          </li>}



                <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={anchorEl6 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl6 ? "true" : undefined}
                        onClick={handleClick6}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={(pathname == "/jobticket-category" || pathname == "/manage-jobtickets" || pathname == "/add-jobtickets-category") ? "active" : "inactive"}
                    >
                        <MdConfirmationNumber />
                        <span>Job Ticket</span>
                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>
                </li>
                <li>
                    <Menu
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl6}
                        open={anchorEl6}
                        onClose={handleClose6}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <MenuItem
                            className={
                                splitLocation[1] === "manage-jobtickets"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/manage-jobtickets", [
                                    getjobticketlistdata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleClose6}>Manage Job Tickets</span>
                        </MenuItem>
                    </Menu>
                </li>






          <li>

            <Menu
              // className="addVid_dropDwn"
              className={topmenu ? 'addVid_dropDwn topmenu aeRep_top' : 'addVid_dropDwn'}

              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl4}
              open={open4}
              onClose={handleClose4}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >

              <MenuItem className='manage_affEmp'>

                <li>
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open4 ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open4 ? "true" : undefined}
                    onClick={handleClick44}
                    endIcon={<MdKeyboardArrowDown className="leftArrowIcon" />
                    }
                  >
                    <span>Manage Traffic Partner</span>
                  </Button>
                </li>


                <li>
                  <Menu
                    // className="addVid_dropDwn"
                    className={childMenuActive ? 'childMenuActive topmenu' : 'addVid_dropDwn'}

                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl44}
                    open={open44}
                    onClose={handleClose44}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >


                    <MenuItem
                      onClick={() => {
                        handleClose4();
                        handleClose44();

                        resolvefunction(
                          navigate,
                          "/create-affiliate"

                        );
                      }}
                    >
                      <span> Add Traffic Partner </span>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose4();
                        handleClose44();
                        resolvefunction(
                          navigate,
                          "/view-affiliate",
                          // getusersList(payload_affiliatelist)

                        );

                      }}
                    >
                      <span> View Traffic Partners </span>
                    </MenuItem>

                    <MenuItem
                      className={
                        splitLocation[1] === "commision-report"
                          ? "submenu_item active"
                          : "submenu_item"
                      }
                      onClick={() => {
                        handleClose4();
                        handleClose44();
                        resolvefunction(
                          navigate,
                          "/affiliate-report",
                          // "/traffic-partner-commission-summary"
                          // getcommisionReportData(commisionBody)
                          // commissionReportMonthly()

                        );

                        // openModal()
                      }}
                    >
                      <span>Traffic Partner Commission</span>
                    </MenuItem>


                    <MenuItem
                      onClick={() => {
                        handleClose4();
                        handleClose44();
                        resolvefunction(

                          navigate,
                          "/affiliate-converison-report",
                          // affiliateConversionReportList({
                          //   "condition": {
                          //     "limit": 30, "skip": 0
                          //   },
                          //   "sort": {
                          //     "type": "desc", "field": "createdon_datetime"
                          //   },
                          //   "searchcondition": { "rep_id": logedUserData._id }
                          // })

                        );

                      }} className={(pathname && pathname == '/affiliate-converison-report') ? 'active' : ''}>
                      <span>Traffic Partner Conversion Report </span>
                    </MenuItem>

                  </Menu>
                </li>

              </MenuItem>



              <MenuItem className='manage_affEmp'>

                <li>
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open45 ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open45 ? "true" : undefined}
                    onClick={handleClick45}
                    // endIcon={<KeyboardArrowRightIcon />}
                    endIcon={<MdKeyboardArrowDown className="leftArrowIcon" />}
                  // endIcon={<MdKeyboardArrowDown className="leftArrowIcon" />

                  >
                    <span>Manage Employers </span>
                  </Button>
                </li>


                <li>
                  <Menu
                    // className="addVid_dropDwn"
                    className={childMenuActive ? 'childMenuActive topmenu' : 'addVid_dropDwn'}

                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl45}
                    open={open45}
                    onClose={handleClose45}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >


                    <MenuItem
                      onClick={() => {
                        handleClose4();
                        handleClose45();

                        resolvefunction(
                          navigate,
                          "/add-edit-employer"

                        );
                      }}
                    >
                      <span> Add Employers </span>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose4();
                        handleClose45();
                        resolvefunction(
                          navigate,
                          "/employer-listing",
                          // getusersList(payload_affiliatelist)

                        );

                      }}
                    >
                      <span> View Employers </span>
                    </MenuItem>



                  </Menu>
                </li>
              </MenuItem>


            </Menu>


          </li>


          {/* {============================EMployer Dashboard============================} */}

          {/* {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employer === 1 && <li>
            <Button
              id="demo-positioned-button"
              aria-controls={open75 ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open75 ? "true" : undefined}
              onClick={handleClick75}
              endIcon={<KeyboardArrowDownIcon />}
              className={
                pathname == "/create-location" || "/view-location-list"
                  ? "active"
                  : "inactive"
              }
            >


              <MdOutlineModelTraining />
              <span>Manage Location</span>
              <MdKeyboardArrowDown className="leftArrowIcon" />
            </Button>

          </li>}


          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && <li>
            <Menu
              // className="addVid_dropDwn"
              className={childMenuEmpl ? 'childMenuEmpl topmenu' : 'addVid_dropDwn'}

              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl75}
              open={open75}
              onClose={handleClose75}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >




              <MenuItem
                onClick={() => {
                  handleClose75();
                  resolvefunction(
                    navigate,
                    "/view-location-list",
                    viewlocationsListData(payLoadLocationList)

                  );
                }}
              >
                <span> View Locations </span>
              </MenuItem>


            </Menu>
          </li>} */}

          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employer === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/new-employer-dashboard",
                // viewSubEmployersList(payLoadSubEmployerCountList),
                employeePackageTotalCost(payLoadEmployeeCountList),


              );
            }} className={(pathname && pathname == '/new-employer-dashboard') ? 'active' : ''}>
              <span> Dashboard </span>
            </button>
          </li>}



          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employer === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/view-location-list",
                viewlocationsListData(payLoadLocationList)

              );
            }} className={(pathname && pathname == '/view-location-list') ? 'active' : ''}>
              <span> Manage Location </span>
            </button>
          </li>}


          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employer === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/view-employee-list",
                viewEmployeeList(payLoadEmployeeList)
              );
            }}
              className={(pathname && pathname == '/view-employee-list') ? 'active' : ''}>
              <span> Manage Employee </span>
            </button>
          </li>}
          {/* 
          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employer === 1 && <li>
            <button

              className=''>
              <span> Manage Card </span>
            </button>
          </li>} */}

          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employer === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/view-sub-employer",
                viewSubEmployersList(payLoadSubEmployerList)
              );
            }}
              className={(pathname && pathname == '/view-sub-employer') ? 'active' : ''}>
              <span> Manage Sub-Login </span>
            </button>
          </li>}


          {/* =================================Sub-login EMployer Dashboard================================= */}

          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_subEmployer === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/new-sublogin-dashboard",
                // viewSubEmployersList(payLoadSubEmployerCountList),
                // employeePackageTotalCost(payLoadEmployeeCountList),


              );
            }} className={(pathname && pathname == '/new-sublogin-dashboard') ? 'active' : ''}>
              <span> Dashboard </span>
            </button>
          </li>}

          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_subEmployer === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/view-employee-list-sublogin",
                viewEmployeeList(payLoadEmployeeListSublogin)
              );
            }}
              className={(pathname && pathname == '/view-employee-list-sublogin') ? 'active' : ''}>
              <span> Manage Employee </span>
            </button>
          </li>}


          {/* =================================Employee Dashboard================================= */}

          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && cookieData?.userinfo?.is_nakagames !== true && logedUserData?.roles?.is_employee === 1 && <li>
            <button onClick={() => {
              resolvefunction(
                navigate,
                "/new-employee-dashboard",
                // viewSubEmployersList(payLoadSubEmployerCountList),
                // employeePackageTotalCost(payLoadEmployeeCountList),


              );
            }} className={(pathname && pathname == '/new-employee-dashboard') ? 'active' : ''}>
              <span> Dashboard </span>
            </button>
          </li>}



          {/* { process.env.REACT_APP_ENVIRONMENT != 'prod' && cookieData?.userinfo?.is_nakagames!==true && <li>
                    <button
                        onClick={() => {
                            resolvefunction(
                                navigate,
                                "/email-campaign-approver-list",
                                EmailCampaignApproverlist()
                            );
                        }}
                        className={
                            pathname && pathname == "/email-campaign-approver-list" ? "active" : ""
                        }
                    >
                        <MdManageAccounts />

                        <span>Email Campaign Approver</span>
                    </button>
                </li>} */}





          {/* <li>
            <button title='Click Here to Download the PDF' onClick={() => setOpenModalCampaign(true)}  >
              <span>Marketing Campaign Instructions</span>
            </button>
          </li> */}

          {logedUserData?.roles?.is_employer !== 1 && logedUserData?.roles?.is_subEmployer !== 1 && logedUserData?.roles?.is_employee !== 1 && <li>
            <button onClick={() => { navigate("/marketing-campaign-instruction") }} className={(pathname && pathname == '/marketing-campaign-instruction') ? 'active' : ''}>
              <span>Marketing Campaign Instructions</span>
            </button>
          </li>}


        </ul>

        {/* <div>
          <Modal
            classname="image_card_modal modalBaseStyle SD_marketing_modal"
            open={openModalCampaign}
            onClose={closeModalCampaign}
            closeAfterTransition
          >
            <Box className='modalBaseBox largeModal marketin_campaign_instrctn'>
              <IconButton className="modal_icon_button" onClick={closeModalCampaign}>
                <CloseIcon className="modal_close_icon" />
              </IconButton>
              <h1>Marketing Campaign Instructions</h1>
              <div className='Marketing_cmpgn_mainwrpr'>
                <h2>Training Video</h2>
                <div className="landpage_videowrapper">
                  <div className="landpage_video_subwrapper">
                    <ReactPlayer
                      className="page5_react_player"
                      url="https://vimeo.com/825297740/b601fe14e0?share=copy"
                      playing={true}
                      controls={true}
                    />
                  </div>
                </div>
                <div className='socialMedia_post wrpr'>
                  <h2>Social Media Posts</h2>
                  <ol>
                    <li>Say goodbye to expensive medications! Join the Free Prescription Pharmacy
                      Membership today and get FREE access to over 605 generic medications. Click
                      [insert your URL here] to enroll now.</li>
                    <li>Don't let high prescription costs keep you from taking care of your health. With a
                      Free Prescription Pharmacy Membership, you can get your medications for FREE.
                      Learn more by clicking [insert your URL here].</li>
                    <li>Want to save money on everyday medications? We've got you covered! Free
                      Prescription Pharmacy Membership offers the most common Over-The-Counter
                      medications for Free. Click [insert your URL here] to learn more.</li>
                    <li>Free Prescription Pharmacy Membership is affordable and easy to use! Say
                      goodbye to high prescription costs and enroll today by clicking [insert your URL
                      here].</li>
                    <li>We take pride in helping our customers save money one member at a time. Enroll
                      in Free Prescription Pharmacy Membership today and start saving! Click [insert
                      your URL here] to learn more.</li>
                    <li>Join the Free Prescription Pharmacy Membership today and get FREE access to
                      over 605 medications. Learn more by clicking [insert your URL here].</li>
                    <li>Save money on everyday medications! Join the Free Prescription Pharmacy
                      Membership today and get FREE access to over 605 medications. Click [insert
                      your URL here] to learn more.</li>
                    <li>Get your medications for FREE! Free Prescription Pharmacy Membership offers
                      the most common Over-The-Counter medications for Free. Click [insert your URL
                      here] to learn more.</li>
                    <li>Say goodbye to high prescription costs. Join the Free Prescription Pharmacy
                      Membership today and get FREE access to over 605 medications. Learn more by
                      clicking [insert your URL here].</li>
                    <li>Free Prescription Pharmacy Membership is affordable and easy to use! Get FREE
                      access to over 605 medications. Enroll today [insert your URL here]</li>
                  </ol>
                </div>
                <div className='facebook_post_details'>
                  <h2 className='fb_post_header'>Facebook Post Instructions</h2>
                  <div className='fb_post_inst_wrpr'>
                    <ol>
                      <li><a href='https://www.facebook.com/' target="_blank">Go to www.facebook.com and login</a></li>
                      <li>Select the Home button
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins1.png' alt='' />
                      </li>
                      <li>Select “Photo/Video”
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins2.png' alt='' />
                      </li>
                      <li>Click on “Add Photos/Videos”
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins3.png' alt='' />
                      </li>
                      <li>Select the video file Aspire Pharma Landscape file where it is saved on your computer
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins4.png' alt='' />
                      </li>
                      <li>Click on Friends drop down
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins5.png' alt='' />
                      </li>
                      <li>Select Public
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins6.png' alt='' />
                      </li>
                      <li>Click on “Done”
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins7.png' alt='' />
                      </li>
                      <li>Click on “What's on your mind”
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins8.png' alt='' />
                      </li>
                      <li><a href='https://docs.google.com/document/d/1mFTVx4DthCoTl7woyKC8cG8rCOvspXR1LT5xbjOqGu4/edit#heading=h.b6hi13q4y1vn' target="_blank">Go to the document Social Media Posts</a></li>
                      <li>Select which text that you want to use and right click
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins9.png' alt='' />
                      </li>
                      <li>Click on Copy...
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins10.png' alt='' />
                      </li>
                      <li><a href='https://www.facebook.com/' target="_blank">Go back to Facebook</a></li>
                      <li>Paste the text in the field. Make sure you insert your unique URL!
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins11.png' alt='' />
                      </li>
                      <li>Click on “Post”
                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_post_ins12.png' alt='' />
                      </li>
                    </ol>
                  </div>
                </div>
                <div className='facebook_grp_post_details facebook_post_details'>
                  <h2 className='fb_post_header'>Facebook Group Post Instructions </h2>
                  <div className='items_wrpr'>
                    <p>Items used from Aspire Member Training Center:</p>
                    <span>➢ Social Media Posts (select one and insert your unique URL)</span>
                    <span>➢ Aspire Pharmacy Video (select one and download)</span>
                  </div>
                  <div className='fb_post_inst_wrpr'>
                    <ol>
                      <li>
                        <a href='https://www.facebook.com/' target="_blank">Go to www.facebook.com and login</a>
                      </li>
                      <li>Click on “Groups”
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins1.png' alt='' />
                      </li>
                      <li>Select the Facebook Group you want
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins2.png' alt='' />
                      </li>
                      <li>Click on “Photo/video” and choose the video you previously downloaded
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins3.png' alt='' />
                      </li>
                      <li>Click on “Write something...”
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins4.png' alt='' />
                      </li>
                      <li>Paste the social media post you are using. Be sure to insert your unique URL!
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins5.png' alt='' />
                      </li>
                      <li>Click on “Add Photos/Videos...”
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins6.png' alt='' />
                      </li>
                      <li>Click on “Post”
                      <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/Marketing_instruction/fb_grp_ins7.png' alt='' />
                      </li>
                    </ol>
                  </div>
                </div>
              </div>


            </Box>
          </Modal>
        </div> */}







      </div>


      {/* <div>
        <Modal
          classname="image_card_modal modalBaseStyle SD_marketing_modal"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className='modalBaseBox largeModal marketin_campaign'>
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            <h2>Cooming Soon</h2>

          </Box>
        </Modal>
      </div> */}

    </>
  )
}

export default BlueCoastNew