import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../../listing/listing'
import { TableSkeleton } from '../../../../../helper/TableSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress, Tooltip } from '@mui/material'
import { viewEmployeeList, viewSubEmployersList, viewlocationsListData } from '../../EmployerReducer'
import AddIcon from "@mui/icons-material/Add";
import { resolvefunction } from '../../../../../helper/helperFunctions'
import { useNavigate } from 'react-router-dom'
import AddEditEmployee from './AddEditEmploye'
import { setEnableRowLoader } from '../../../../../listing/listReducer'
import PreviewEmployeeDetails from './PreviewEmployeeDetails'
import StatusChangeEmployee from './StatusChangeEmployee'
import DeleteEmployee from './DeleteEmployee'
import AddNotesEmployee from './AddNotesEmployee'

function EmployeeListing() {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});

    const employeeListDetails = useSelector((state) => state.EmployerReducer?.employeeListData ? state.EmployerReducer.employeeListData : null)
    const employeeLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)
    const employeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForEmployeeData && Object.keys(state.tableSlice.tempRowData.tableForEmployeeData).length > 0 ? state.tableSlice.tempRowData.tableForEmployeeData : {})

    const editEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForEmployeeData?.editemployeeid ? state.tableSlice.customButtonClicked.tableForEmployeeData.editemployeeid : false)

    const previewEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForEmployeeData?.previewEmployee ? state.tableSlice.customButtonClicked.tableForEmployeeData.previewEmployee : false)
    const statusEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForEmployeeData?.change_status1 ? state.tableSlice.customButtonClicked.tableForEmployeeData.change_status1 : false)

    const notesEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForEmployeeData?.notesEmployee ? state.tableSlice.customButtonClicked.tableForEmployeeData.notesEmployee : false)


    // ===============================Location Delete Clicke============================

    const deleteClick = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForEmployeeData?.deleteEmployee ? state.tableSlice.customButtonClicked.tableForEmployeeData.deleteEmployee : null)

    useEffect(() => {
        // console.log("employeeRowData=========>>>>>>>>", employeeRowData);
    }, [JSON.stringify(employeeRowData)])



    // ----------api trigger for the location arrays---------------
    useEffect(() => {
        let reqBody = {

            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                added_by: logedUserData?._id
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false

        }
        dispatch(viewSubEmployersList(reqBody))
    }, [])


    // ----------api trigger for the location arrays---------------
    useEffect(() => {
        let reqBody = {

            // "condition": {
            //     "limit": 30,
            //     "skip": 0
            // },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                employer_id: logedUserData?._id,
                status: true
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false

        }
        dispatch(viewlocationsListData(reqBody))
    }, [])

    useEffect(() => {
        // console.log("employeeListDetails===========>>>", employeeListDetails)
    }, [JSON.stringify(employeeListDetails)])


    const [employeeModlOpen, setEmployeeModlOpen] = useState(false)

    const addEmployeefnc = () => {
        setEmployeeModlOpen(true)
    }

    // **********************edit employee clicked***********************
    useEffect(() => {
        if (editEmployeeClicked == true) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForEmployeeData",
                    rowId: employeeRowData._id,
                    load: true,
                })
            );
            // setTimeout(() => {
            setEmployeeModlOpen(true)
            // }, 1000);

            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForEmployeeData",
                        rowId: employeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);

        }
    }, [editEmployeeClicked])

    // **************************Preview Employee Details********************

    const [previewOpen, setPreviewOpen] = useState(false)

    useEffect(() => {
        if (previewEmployeeClicked) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForEmployeeData",
                    rowId: employeeRowData._id,
                    load: true,
                })
            );
            // setTimeout(() => {

            setPreviewOpen(true)
            // }, 1000);
            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForEmployeeData",
                        rowId: employeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [previewEmployeeClicked])

    // *******************************Add notes*****************************

    const [notesOpen, setNotesOpen] = useState(false)

    useEffect(() => {
        if (notesEmployeeClicked === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForEmployeeData",
                    rowId: employeeRowData._id,
                    load: true,
                })
            );

            setNotesOpen(true)

            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForEmployeeData",
                        rowId: employeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [notesEmployeeClicked])


    // *********************Status Chnage modal*************************
    const [statusModalOpen, setStatusModalOpen] = useState(false)

    useEffect(() => {
        if (statusEmployeeClicked) {
            setStatusModalOpen(true)
        }
    }, [statusEmployeeClicked])

    // ===============================Delete location modal Clicked=========================

    const [deleteOpen, setDeleteOpen] = useState(false)


    useEffect(() => {
        if (deleteClick === true) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForEmployeeData",
                    rowId: employeeRowData?._id,
                    load: true,
                })
            );
            setDeleteOpen(true)

            setTimeout(() => {

                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForEmployeeData",
                        rowId: employeeRowData?._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [deleteClick])


    /*****************************For Resolve*********************** */
    const resolved = useSelector((state) =>
        state.ResolveReducer?.resolved["/view-employee-list"]
            ? state.ResolveReducer.resolved["/view-employee-list"]
            : false
    );

    useEffect(() => {
        if (!resolved) {
            dispatch(viewEmployeeList(tableDataforEmployeeList.current.reqBody))
        }
    }, [])

    // ====================main table header===============
    var modifyTableHeadersForEmployee =
        [
            { val: "name", name: "Full Name" },
            { val: "email", name: "Email" },
            { val: "phone", name: "Phone Number" },
            { val: "full_address", name: "Address" },

            {
                val: "status",
                name: "Status",
                // customVal: { false: "Inactive", true: "Active" },
            },
            { val: "package_name", name: "Package" },
            { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY" },
            { val: "updatedon_datetime", name: "Updated On", type: "datetime", format: "MM/DD/YYYY" },

        ];

    // ======================main table for employee lsit==================

    let tableDataforEmployeeList = useRef({
        tableId: "tableForEmployeeData",
        tableTitle: "Employee Lists",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "employer_manage/employees_list",
            tableCountEndpoint: "employer_manage/employees_list",
        },

        reqBody: {

            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                $or: [{ employer_id: logedUserData?._id },
                { mainEmployer_id: logedUserData?._id },]
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false
        },




        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [

            {
                id: "editemployeeid",
                type: "other",
                icon_type: "edit_icon",
                label: "Edit",
                name: "edit",
            },

            {
                id: "previewEmployee",
                label: "Preview",
                type: "other",
                icon_type: "preview",
                name: "preview",
            },

            // {
            //     id: "change_status1",
            //     type: "other",
            //     label: "Change Status",
            //     name: "toggle_on_icon",
            //     condField: "inactive",
            //     condVal: 2,
            // },

            {
                id: "change_status1",
                type: "other",
                label: "Change Status",
                name: "toggle_on_icon",
            },

            {
                id: "notesEmployee",
                label: "Notes",
                type: "other",
                icon_type: "newspaper_icon",
                name: "newspaper_icon",
                showcount: true,
                countFiled: "notes_count",
            },

            {
                id: "deleteEmployee",
                label: "Delete",
                type: "other",
                name: "delete_icon",
            },


        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },


        searchData: {
            heading: " Search Employee List",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [


                    {
                        id: 0,
                        label: "Search by Name and Email",
                        name: "name",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL + "employer_manage/autocomplete_employee_name",
                        reqBody: {
                            condition: {
                                limit: 30,
                                skip: 0,
                            },
                            sort: {
                                type: "asc",
                                field: "createdon_datetime",
                            },
                            searchcondition: {

                                $or: [
                                    {
                                        employer_id: logedUserData?._id,
                                    },
                                    {
                                        mainEmployer_id: logedUserData?._id,
                                    },
                                ],

                                label: {
                                    $regex: "",
                                    $options: "i"
                                }
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },


                    {
                        id: 3,
                        label: "Search by Status",
                        name: "status",
                        type: "select",
                        values: [
                            { val: 1, name: "Active" },
                            { val: 0, name: "Inactive" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "status" },
                    },

                    {
                        id: 4,
                        label: "Search by Package",
                        name: "package_name",
                        type: "select",
                        values: [
                            { val: "Single", name: "Single ($21.99)" },
                            { val: "Single+1", name: "Single+1 ($26.99)" },
                            { val: "Family", name: "Family ($31.99)" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "package_name" },
                    },

                    {
                        id: 8,
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker list_search_item countDiv__column--col6',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },

                ]
            }
        },

    });

    return (
        <>
            <div className="rolelist_mainwrp Manage_location">
                <div className="listing_heading ">
                    <h1>Employee List</h1>

                    <Tooltip title="ADD USER">
                        <button
                            className="addrole_btn"
                            onClick={() => addEmployeefnc()}
                        >
                            <AddIcon />
                        </button>
                    </Tooltip>
                </div>


                {employeeLoader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

                <div className='rolelist_mainwrp_Table'>
                    {!employeeLoader ? (
                        <div>
                            {employeeListDetails.length > 0 && <ListingTable
                                tableData={tableDataforEmployeeList.current}
                                dataset={employeeListDetails}
                                modifyHeaders={modifyTableHeadersForEmployee}
                            />}
                        </div>
                    ) : (
                        <div><TableSkeleton count={5} /></div>
                    )}


                </div>

            </div>

            {/* ***************************EDD/Edit Employee Modal************************ */}
            {employeeModlOpen ? <AddEditEmployee
                employeeModlOpen={employeeModlOpen}
                setEmployeeModlOpen={setEmployeeModlOpen}
                logedUserData={logedUserData}
                employeeListDetails={employeeListDetails}
                employeeReqBody={tableDataforEmployeeList.current.reqBody}
                editEmployeeClicked={editEmployeeClicked}
            /> : ""
            }

            {/* ********************************Preview Employee Details*********************** */}
            {previewOpen ? <PreviewEmployeeDetails
                previewOpen={previewOpen}
                setPreviewOpen={setPreviewOpen}
                previewEmployeeClicked={previewEmployeeClicked}
                employeeRowData={employeeRowData}
            /> : ""
            }

            {/* ********************************Notes MOdal****************************** */}
            {notesOpen ? <AddNotesEmployee
                notesOpen={notesOpen}
                setNotesOpen={setNotesOpen}
                notesEmployeeClicked={notesEmployeeClicked}
            /> : ""}

            {/* ***************************Employee Status Change************************* */}
            {statusModalOpen ? <StatusChangeEmployee
                statusModalOpen={statusModalOpen}
                setStatusModalOpen={setStatusModalOpen}
                statusEmployeeClicked={statusEmployeeClicked}
                employeeRowData={employeeRowData}
            /> : ""
            }

            {/* ========================Delete Modal Location======================== */}
            {deleteOpen && <DeleteEmployee
                deleteOpen={deleteOpen}
                setDeleteOpen={setDeleteOpen}
            // rowDataID={employeeRowData}
            />}
        </>
    )
}

export default EmployeeListing