import React, { useEffect, useState } from "react";
import {
  setEnableRowLoader,
  setReloadTableFlag,
} from "../../../listing/listReducer";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { alldispositionData, clearAllDisposition } from "./BlueCoastReducer";

const DispositionCycleForPharmacyAspire = ({ paymentDispositionCycle, setPaymentDispositionCycle, rowData, allDispositionData, setPaymentRowData }) => {

  const dispatch = useDispatch();

  // console.log("allDispositionData-----------------", allDispositionData);




  const handleCloseAllDispositionModal = () => {
    setPaymentDispositionCycle(false);
    setPaymentRowData({})
    dispatch(clearAllDisposition())

  };


  return (
    <>
      {/* ************************Manage Dist Modal*******************************/}

      <Modal
        className="modalBaseStyle disposition_Modal notesModal_blcoast"
        open={paymentDispositionCycle}
        onClose={handleCloseAllDispositionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modalBaseBox mediumModal' >
          <Tooltip title='Close' >
            <IconButton onClick={handleCloseAllDispositionModal}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className='showDispositionMap'>
            <h2 className='previewModalTitle '>
              {/* {ipInfo?.fullname && <div className='modal_title modal_titlenew'> Manage Disposition for {ipInfo.fullname}</div>} */}
              <div className='modal_title modal_titlenew'> All Disposition </div>
              {/* <p>{JSON.stringify(allDispositionData[0])}</p> */}
            </h2>
            <Box>
              {allDispositionData && allDispositionData[0]?.disposition_data ? (allDispositionData[0]?.disposition_data?.slice(0).reverse().map((item) => (
                <span className='tagChipwrp'>

                  <Chip label={item?.disposition_name} key={item.addedby} variant="outlined" />
                  <QuestionMarkIcon className='qIcon' />
                  <span className='chipDesBuble'>
                    {item?.disposition_name} <br />
                    Added by: {item.addedby_name}<br />
                    Date:  {item.action_added_time_human}<br />
                  </span>
                </span>
              ))) : <div><p>No Records Found</p></div>}

            </Box>
          </div>
        </Box>
      </Modal>






    </>
  )
}

export default DispositionCycleForPharmacyAspire