import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment";



export const getDataForRepDashboardGridList = createAsyncThunk("AE-dashboard-grid-list", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/rep-dashboard-grid-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getDataForRepDashboardChart = createAsyncThunk("AE-dashboard-chart", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/rep-dashboard-chart", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})




export const getDataForRepDashboardCard = createAsyncThunk("AE-dashboard-card", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/rep-dashboard-card", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})






const RepDashboardReducer = createSlice({
    name: 'RepDashboardReducer',
    initialState: {

        mindate: moment().subtract(7, "days").valueOf(),
        maxDate: moment().valueOf(),

        submitting: false,
        loading: false,
        loaderForRepData: false,
        RepChartData: {},
        RepGridData: [],
        RepCardData: [],
        RepListData: null,
        listType:null,
        gridListName:"Opportunity Created",
        failmassage:null,
        listStatus:null


    },
    reducers: {
        clearRepDashboardData(state, action) {
            state.RepChartData = {}
            state.RepGridData = []
            state.RepListData = null
            state.listType = null
            state.gridListName = "Opportunity Created"

        },

        setMinMaxTime(state, action) {

            state.mindate = (moment(action.payload[0]).valueOf())
            state.maxDate = (moment(action.payload[1]).valueOf())
            

        },
        clearsnackBarData(state,action){
            state.failmassage = null
            state.listStatus=null
        },
        clearRepgridData(state,action){
            state.RepGridData = []
        }

    },
    extraReducers: {
        
        [getDataForRepDashboardGridList.pending]: (state, action) => {

            state.loading = true;
            state.loaderForRepData = true;
            state.RepGridData = [];
            state.RepListData = null;

            state.listType =null
            state.listsource=null
        },
        [getDataForRepDashboardGridList.fulfilled]: (state, action) => {

            
            // if (action.payload.status === 'success') {

            state.listType = action.meta.arg.type
            state.listsource=action.meta.arg.source
            if(action.meta.arg.type === "opportunities") state.gridListName = "Opportunities"
            if(action.meta.arg.type === "lead_appointment") state.gridListName = "Lead Appointment"
            if(action.meta.arg.type === "training") state.gridListName = "Training"
            if(action.meta.arg.type === "follow_up_call") state.gridListName = "Follow Up Call"
            if(action.meta.arg.type === "folder_access_rep") state.gridListName = "Folder Access AE"
            if(action.meta.arg.type === "folder_access_lead") state.gridListName = "Folder Access Lead"
            if(action.meta.arg.type === "email_sent") state.gridListName = "Email Sent"
            if(action.meta.arg.type === "info_link_sent") state.gridListName = "Information Link"
            if(action.meta.arg.type === "discovery_call_rep") state.gridListName = "Discovery Call AE"
            if(action.meta.arg.type === "discovery_call_lead") state.gridListName = "Discovery Call Lead"
            if(action.meta.arg.type === "activity_deatails") state.gridListName = "All Activity"
            if(action.meta.arg.type === "conversion_done") state.gridListName = "Conversion"
            if(action.meta.arg.type === "note_added") state.gridListName = "Note Added"
            state.RepGridData = action.payload.results.grid_data;
            state.RepListData = action.payload.results.res;
            state.loading = false;
            state.loaderForRepData = false;

            // }

        },
        [getDataForRepDashboardGridList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.loading = false;
            state.successAction = false;
            state.loaderForRepData = false;
            state.RepGridData = [];
            state.RepListData = null;
            state.listType =null
            state.listsource=null
        },


        [getDataForRepDashboardCard.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.loading = false;
            state.successAction = false;
            state.loaderForRepData = false;

        },
        [getDataForRepDashboardCard.pending]: (state, action) => {
            state.loading = true;
            state.loaderForRepData = true;
        },
        [getDataForRepDashboardCard.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.loading = false;

                state.RepCardData = action.payload.results.card_data;
                state.loaderForRepData = false;

            }

        },


        [getDataForRepDashboardChart.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.loading = false;
            state.successAction = false;
            state.loaderForRepData = false;
            state.RepChartData={}
        },
        [getDataForRepDashboardChart.pending]: (state, action) => {
            state.loading = true;
            state.loaderForRepData = true;
            state.RepChartData={}

        },
        [getDataForRepDashboardChart.fulfilled]: (state, action) => {

           
            if (action.payload.status === 'success') {
                state.RepChartData = action.payload.results;
                state.loaderForRepData = false;

            }
            state.loading = false;
        },




    }

})

export default RepDashboardReducer.reducer;
export const { clearRepDashboardData, setMinMaxTime,clearsnackBarData,clearRepgridData} = RepDashboardReducer.actions;

