import { Button, LinearProgress, Modal, Snackbar, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { CampainStatusChang, clearstatusChangSnakeData, clearstatusChangdone, usermanageMultiStatusUpdate } from './userListReducer';
import { useDispatch, useSelector } from 'react-redux';
import { usermanagestatusUpdate } from './userListReducer';
import { setReloadTableFlag } from '../../../../listing/listReducer';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';
import { setSnackbar } from '../../../Layout/layoutReducer';

const DeleteModalUser = ({modalState,TogalModalState,usebelData}) => {
    const loader = useSelector((state) =>state.userManagment?.loading ? state.userManagment.loading : false);
    const statusChangSnakeDataState = useSelector((state) =>state.userManagment?.statusChangSnakeDataState ? state.userManagment.statusChangSnakeDataState : null);
    const statusChangdone = useSelector((state) =>state.userManagment?.statusChangdone ? state.userManagment.statusChangdone : null);
    const statusChangSnakeDataMassage = useSelector((state) =>state.userManagment?.statusChangSnakeDataMassage ? state.userManagment.statusChangSnakeDataMassage : null);
    const multidata = useSelector((state)=>state.tableSlice?.multipleSelectionList?.userslist1?state.tableSlice.multipleSelectionList.userslist1:[])

    const [MultiID,setMultyID] = useState([])

    const dispatch = useDispatch()
    const [snackbarStatus,setSnakeBarStatus]=useState(false)
    const [massage,setmassage]=useState('')

    const handelDelete =(val)=>{
        let _id=MultiID.length >0 ? MultiID :[usebelData._id]
        let reqbody={_id,campaign_access:val}
        dispatch(CampainStatusChang(reqbody))
        console.log("in dide the funtion ",reqbody);
    }
    useEffect(()=>{
        if(multidata.length>0){
            let arrId = []
            for (let i = 0; i < multidata?.length; i++) {
                arrId[i] = multidata[i]._id;
              }
              setMultyID(arrId)
            
        }
    },[multidata])


    useEffect(()=>{
        if(statusChangdone === 2 ){
            // HandelClose()
            dispatch(
                setSnackbar({ open: true, message:statusChangSnakeDataMassage, status: 'success' })
              )
            TogalModalState(false)
            dispatch(setReloadTableFlag({ tableId: "userslist1" }));


            setTimeout(() => {
                dispatch(
                    setSnackbar({ open: false, message:statusChangSnakeDataMassage, status: 'success' })
                  )
                  
                  dispatch(clearstatusChangSnakeData())
                  dispatch(clearstatusChangdone())
                
            }, 2000);


            
        }

        if(statusChangdone === 3 ){
            // HandelClose()
            // dispatch(
            //     setSnackbar({ open: true, message: 'Somthing went wrong', status: 'error' })
            //   )
            TogalModalState(false)
            dispatch(clearstatusChangdone())

        }
    },[statusChangdone])




    // useEffect(())



    // useEffect(()=>{
    //     console.log("hbfdjvnjhvuihfdvfbd",statusChangSnakeDataState);

    //     if(statusChangSnakeDataState !== null && statusChangSnakeDataState !== undefined){

    //         setSnakeBarStatus(true)
    //         setmassage(statusChangSnakeDataMassage)
    //     }
    // },[statusChangSnakeDataState,statusChangSnakeDataMassage])


    const HandelClose= ()=>{
     
        TogalModalState(false)
    }


    // useEffect(()=>{
    //     if(snackbarStatus === false){
        
    //         dispatch(clearstatusChangSnakeData())
    //     }
    // },[snackbarStatus])

  return (
    <>
        <Modal className="modalblock" open={modalState} onClose={HandelClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="" sx={{ position: "absolute", top: "50%", left: "50%",transform: "translate(-50%, -50%)", width: 600,bgcolor: "background.paper", border: '10px solid #0569ad!important',background:'#fff',boxShadow: 24,textAlign: "center",}}>
                <Typography id="modal-modal-title" variant="h5" component="h5">
                    {/* <p className='alertPara2'> Alert !</p> */}
                    <p>Are you sure you want to give access to the user?</p>
                </Typography>
                <div style={{padding:"20px"}}>
                    <Button variant="contained" onClick={()=>handelDelete(2)}  className="modalBTN">Yes</Button>
                    <Button variant="contained" onClick={()=>handelDelete(1)} className="modalBTN">No</Button>
                </div>
                {loader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}
                
                <Button onClick={HandelClose} className="closeModalBtn">
                    <Tooltip title="Close" placement="right-start">
                        <CloseIcon />
                    </Tooltip>
                </Button>
            </Box>
        </Modal>


        {((snackbarStatus === true) && (massage !== undefined)) && <SnackBarComp
        setSnackFire={setSnakeBarStatus}
        resMessage={massage}
        snackFire={snackbarStatus}
        endpointResStatus={statusChangSnakeDataState==="success" ? "success":"failed" }
      />}



    </>
  )
}

export default DeleteModalUser