import React, { useEffect, useState } from 'react'
import loader from "../../../../../assets/image/loader.gif"

function LazyImage({ src, className, alt, onClick }) {
  const [imgLoaded, setImgLoaded] = useState(false);
  useEffect(() => {
    const img = document.createElement("img");
    img.src = src;

    img.addEventListener("load", () => {
      setImgLoaded(true)
    })

    img.addEventListener("error", () => {
      setImgLoaded(true)
    })
  }, [])

  return (
    <>
      {imgLoaded === true ? (
        // <img src={src} className={className} alt={alt} onClick={() => onClick()} />
        <img src={src} className={className} alt={alt}  />
      ) : (
        // <img src={loader} className={className} alt={alt} onClick={() => onClick()} />
        <img src={loader} className={className} alt={alt}  />
      )}
    </>
  )
}

export default LazyImage