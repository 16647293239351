import React, { useEffect, useRef, useState } from 'react'
import { Form } from '../../../../form/Form';
import StateArray from '../../../../assets/json/state'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addlandingpageonedata, getMLMData } from '../LandingpageReducer';
import { Box } from '@mui/system';
import { Alert, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import { setIpinfo } from '../../../login/loginReducer';
import store from '../../../../store';

const MlmPageForm = () => {

    const { _id } = useParams();
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [snackStatusSeverity, setSnackStatusSeverity] = useState('success');

  const formInputData = useSelector((state) =>
  state.formSlice.formData.userFormMlM ? state.formSlice.formData.userFormMlM : null
);

const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userFormMlM) ? state.formSlice?.formSubmissionState?.userFormMlM : 0)

const logInIpInfo = useSelector((state) => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice?.ipInfo).length > 0) ? state.loginSlice?.ipInfo : null)

const mlmPrePopulateData = useSelector((state) => (state.LandingpagefontReducer?.mlmdata && Object.keys(state.LandingpagefontReducer.mlmdata).length > 0) ? state.LandingpagefontReducer.mlmdata : {})

const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val }
    let body = {
      "firstname": val.firstname,
      "lastname": val.lastname,
      // "associated_program": val.associated_program,
      "email": val.email,
      "phone": val.phone,
      "address": val.address,
      "state": val.state,
      "city": val.city,
      "zip": val.zip,
      "password": val.password,
      "confirm_password": val.confirmPassword,
      "rep_signup_stage": 2,
      // "is_nakagames": true,
      // "associated_program":["63cf8c25ae5ab31db330fe10"],
      // "roles": { "is_rep": 1 },
      // "rep_with_new_dashboard":true,
      // "mailsent":1,
      // "user_name": "pritam123",

      // "affiliate_signup_stage": 2,
      "signup_form":"mlm",
  
      "createdon_datetime": new Date().getTime(),
      "_id": (_id !== undefined && _id !== null) ? _id : undefined,
      "ipinfo": logInIpInfo,

    }
    console.log("body-----------", body);
    if (Object.keys(body).length > 0) {
      console.log("body-----", body);
      // return
      // setLoader(true)
      setloading(true)

      let dataresponse = await store.dispatch(addlandingpageonedata(body));
      console.log("dataresponse", dataresponse);
      setloading(false)
      if (dataresponse && dataresponse.payload && dataresponse.payload.status == 'error') {
        console.log("HITTTTT", dataresponse.payload.message);
        setSnackMessage(dataresponse.payload?.message)
        setSnackStatusSeverity('error')
        setTimeout(() => { setOpenSnack(true) }, 1000)
        // // if (landingPageSubmissionState && landingPageSubmissionState === 2) {
        // // setSnackopen(true)
        // setMsg(dataresponse.payload.message)
        // // dispatch(setSnackbar({ open: true, message: "jiiii", status: 'error' }))}
      }
      else {
        // console.log("Responseeeeeeeeeeeeeeeee");
        redirect(_id)
        // console.log("dataaddedinfo",dataaddedinfo);


      }


    }
  };

  const redirect = (id) => {
    // console.log("Res+++++++++++++++++++++++++++++");
    // dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))
    setSnackMessage("Thank you for submitting!")
    setOpenSnack(true)

    // const data = {
    //   stage_slug: '5QH6nS8FXNawY',
    //   added_by: 'System',
    //   added_for: id ? id : dataaddedinfo._id,
    //   is_delete: 0,
    //   status: 1
    // }
    // console.log("data===========================>", data)
    // // return
    // dispatch(updateStages(data));
    // dispatch(clearaddedinfo())
    setTimeout(() => {
      dispatch(getMLMData(_id))
    }, 1000)



  }

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);

  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });

  }, []);



    const formDataMlM = useRef({
        id: 'userFormMlM',
        formtype: "add",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "landing-page-manage/add-user",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection9",
        submitBtnName: "Submit",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
          _id: (_id !== undefined && _id !== null) ? _id : undefined
          // "status": 0,
          // "roles": { is_rep: 1 },
    
        },
        fields: [
          //////////////////////////////////// Text Input ///////////////////////////////
          {
            id: 0,
            heading: "Your First Name",
            label: " First Name",
            name: "firstname",
            className: 'formfild formfild4',
            type: "text",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid"
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.firstname !== undefined) ? mlmPrePopulateData.firstname : undefined,
    
          },
          {
            id: 15,
            heading: "Your Last Name",
            label: "Last Name",
            name: "lastname",
            className: 'formfild formfild4',
            type: "text",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid"
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.lastname !== undefined) ? mlmPrePopulateData.lastname : undefined,
          },
          {
            id: 13,
            heading: "Your Email",
            label: "Your Email",
            name: "email",
            className: 'formfild formfild4',
            type: "text",
            inputType: "email",
            rules: { required: true },
            disabled: (mlmPrePopulateData && mlmPrePopulateData.email !== undefined) ? true : false,
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid"
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.email !== undefined) ? mlmPrePopulateData.email : undefined,
          },
    
          {
            id: 14,
            heading: "Your Phone Number",
            label: "Your Phone Number",
            name: "phone",
            className: 'formfild formfild4',
            type: "text",
            inputType: "phone",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "Value is Invalid"
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.phone !== undefined) ? mlmPrePopulateData.phone : undefined,
          },
          // {
          //   id: 26,
          //   heading: "SELECT HM ",
          //   label: "SELECT HM ",
          //   name: "select_hm",
          //   className: 'inputBlock usertypebox inputBlock4line',
          //   type: "select",
          //   other: true,
          //   values: [],
          //   // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',
    
          //   // inputType: "number",/////////////// If not mentioned default will be text //////////////
          //   // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
          //   rules: { required: true },
          //   errorMessage: {
          //     required: "This Field is Required",
          //     custom: "Value is Invalid"
          //   },
    
          // },
          {
            id: 25,
            heading: "Address",
            placeholder: 'Address',
            label: "Address",
            name: "address",
            className: 'formfild textareafild',
            type: "textarea",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              // custom: "Value is Invalid"
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.address !== undefined) ? mlmPrePopulateData.address : undefined,
    
    
          },
          {
            id: 6,
            heading: "State",
            label: "State",
            name: "state",
            className: 'list_search_item formfild6',
            type: "select",
            // other: true,
            values: StateArray,
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.state !== undefined) ? mlmPrePopulateData.state : undefined,
          },
    
          {
            id: 7,
            heading: "City",
            label: "City",
            name: "city",
            className: 'inputBlock formfild6',
            type: "text",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.city !== undefined) ? mlmPrePopulateData.city : undefined,
    
    
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
            // rules: { required: true },
            // errorMessage: {
            //   required: "This Field is Required",
            //   // custom: "Value is Invalid"
            // },
    
          },
    
          {
            id: 8,
            heading: "Zip Code",
            label: "Zip Code",
            autoComplete: "off",
            name: "zip",
            className: 'inputBlock formfild6',
            type: "text",
            // inputType:"number",
            inputType: "zip",
            rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
            },
            defaultValue: (mlmPrePopulateData && mlmPrePopulateData.zip !== undefined) ? mlmPrePopulateData.zip : undefined,
    
    
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
            // rules: { required: true },
            // errorMessage: {
            //   required: "This Field is Required",
            //   // custom: "Value is Invalid"
            // },
    
          },
    
          {
            id: 11,
            heading: "Password",
            label: "Password",
            name: "password",
            className: 'inputBlock formfild6 passwordinput',
            type: 'password',
            rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
    
            errorMessage: {
              required: "This Field is Required",
              // errorMessageConfirmPassword: "Password does not match",         
              custom: "Value is Invalid",
              validate: "Password does not match",
              maxLength: "Maximum allowed input length is 15 characters",
    
              pattern: "You need to provide a strong password",
            },
            // defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.password !== undefined) ? affiliatePrePopulateData.password : undefined,
    
          },
    
          {
            id: 12,
            heading: "Confirm Password",
            label: "Confirm Password",
            name: "confirmPassword",
            className: 'inputBlock formfild6 passwordinput',
            type: 'confirm_password',
            defaultValue: '',
            rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
            errorMessage: {
              required: "This Field is Required",
              validate: "Password does not match",
              errorMessageConfirmPassword: "Password does not match",
              maxLength: "Maximum allowed input length is 15 characters",
    
              pattern: "You need to provide a strong password",
              custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
            },
            // defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.confirmPassword !== undefined) ? affiliatePrePopulateData.confirmPassword : undefined,
          },
          {
            id: 22,
            type: "html",
            className: "passwordCls inputBlock1line ",
            value: "<p>* Password should contain at least 8 characters and max 15 characters with one numeric digit, one uppercase letter, one lowercase letter, and a special character.</p>"
          },
          // {
          //   id: 20,
          //   heading: "Any other information we should know?",
          //   placeholder: 'Any other information we should know?',
          //   label: "Any other information we should know?",
          //   name: "other_info",
          //   className: 'formfild textareafild',
          //   type: "textarea",
          //   // rules: { required: true },
          //   // errorMessage: {
          //   //   required: "This Field is Required",
          //   //   custom: "Value is Invalid"
          //   // }
    
          // },
    
    
        ]
    
      });

      const handleClose = (event, reason) => {
        setOpenSnack(false);
      };
  return (
    <div>


    {/* <h1 className='page_heading'>Affiliate</h1> */}



    <div className='dataform2_wrapper'>

      <div className='adminformbody'>
        {/* <p>llll</p> */}
        {_id !== undefined && mlmPrePopulateData && (mlmPrePopulateData.length > 0 || Object.keys(mlmPrePopulateData).length > 0) && <Form formData={formDataMlM.current} />}
        {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}

        {/* <Form formData={formDataMlM.current} /> */}

        <Snackbar
          className="form_error_snackbar"
          open={openSnack}
          autoHideDuration={10000}
          onClose={() => handleClose()}
          // message={message}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          
          <Alert onClose={handleClose} severity={snackStatusSeverity} > {snackMessage}</Alert>
        </Snackbar>
      </div>
    </div>
  </div>
  )
}

export default MlmPageForm