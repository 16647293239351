import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";




export const getw9Formdata = createAsyncThunk(
  "w9Formdata",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/wnineformdata",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);



export const contractFormdata = createAsyncThunk(
  "contractFormdata",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead-manage/affiliatecontract",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const policyFormdata = createAsyncThunk(
  "policyFormdata",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "landing-page-manage/addpolicydata",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);



export const getcommisionReportData = createAsyncThunk(
  "commisionReportData",
  async (reqbody) => {
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "bclusers/commisionreportList",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);




// export const getw9Formdata = createAsyncThunk("w9Formdata", async (id) => {
//     let reqBody = {
//         "condition": {
//             "limit": 1,
//             "skip": 0
//         },
//         "sort": {
//             "field": "_id",
//             "type": "desc"
//         },
//         "searchcondition": { "_id": id },
//         "project": {},
//         "token": ""
//     }
//     console.log("reqbody==>", reqBody);
//     let req_body = {};
//     req_body = { ...reqBody };
//     console.log("req_body==", req_body);
//     const requestOptions = {
//         method: 'post',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/wnineformdata", requestOptions);
//     // const response = await apicall("contract-manage/contract-list-count", requestOptions);

//     // const respdata = response;
//     // return respdata;


// })





const W9FormReducer = createSlice({
  name: 'W9FormReducer',
  initialState: {
    submitting: 0,
    loading: false,
    w9Formdata: [],
    w9Formsubdata: 0,
    contractFormSubmissiontate: 0,
    contractFormData: [],
    policyFormSubmissiontate: 0,
    commisionReportData: [],
    commisionAmount: null,
    commisionReportSuccess: false,
    filterAffiliateDataSuccess: false

  },
  reducers: {

    clearCommisionReportData(state, action) {
      state.commisionReportData = [];
      state.filterAffiliateDataSuccess = false;
      // state.commisionAmount= null;

    },
  },

  extraReducers: {
    [getw9Formdata.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false
      // state.loading = false;
      state.successAction = false;

    },
    [getw9Formdata.pending]: (state, action) => {


      // console.log("getContractSlagData=========================>")
      state.loading = true;
      state.w9Formsubdata = 1;
      state.contractFormSubmissiontate = 1;

    },

    [getw9Formdata.fulfilled]: (state, action) => {


      state.w9Formdata = action.payload.results.res;
      state.loading = false;
      state.w9Formsubdata = 2;

    },




    [contractFormdata.rejected]: (state, action) => {
      // state.endpointsuccess[action.type] = false
      // state.loading = false;
      state.successAction = false;

    },
    [contractFormdata.pending]: (state, action) => {


      // console.log("getContractSlagData=========================>")
      state.loading = true;
      state.contractFormSubmissiontate = 1;

    },

    [contractFormdata.fulfilled]: (state, action) => {


      state.contractFormData = action.payload.results.res;
      state.loading = false;
      state.contractFormSubmissiontate = 2;

      // console.log("state.contractFormData========>>>>", state.contractFormData);
    },



    [policyFormdata.rejected]: (state, action) => {
      // state.endpointsuccess[action.type] = false
      // state.loading = false;
      state.successAction = false;

    },
    [policyFormdata.pending]: (state, action) => {


      // console.log("getContractSlagData=========================>")
      state.loading = true;
      state.policyFormSubmissiontate = 1;

    },

    [policyFormdata.fulfilled]: (state, action) => {


      state.contractFormData = action.payload.results.res;
      state.loading = false;
      state.policyFormSubmissiontate = 2;

    },



    [getcommisionReportData.rejected]: (state, action) => {
      state.commisionReportSuccess = false
      state.filterAffiliateDataSuccess = false
    },
    [getcommisionReportData.pending]: (state, action) => {

      // state.commisionAmount= null
      // console.log("getContractSlagData=========================>")
      state.loading = true;
      state.commisionReportSuccess = false
      state.filterAffiliateDataSuccess = false


    },

    [getcommisionReportData.fulfilled]: (state, action) => {


      state.commisionReportData = action.payload?.results?.res ? action.payload.results.res : [];
      state.loading = false;
      state.commisionAmount = action.payload?.results?.total_commission ? action.payload.results.total_commission : null
      state.commisionReportSuccess = true
      state.filterAffiliateDataSuccess = true

    },






  }

})

export default W9FormReducer.reducer;
export const { clearCommisionReportData } = W9FormReducer.actions;

