import { IconButton, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableSkeleton } from '../../../../helper/TableSkeleton'
import ListingTable from '../../../../listing/listing'
import { setEnableRowLoader } from '../../../../listing/listReducer';
import { SendMailFulList } from '../BlueCoastReducer';
import CloseIcon from "@mui/icons-material/Close";
import { format } from 'date-fns';


function SendContactMailList({ sentMailAEsOpen, SentMailAEsClose, rowData ,setsaveRowdata}) {

    const dispatch = useDispatch();

    // console.log("rowData>>>>1212", rowData);

    let sendContactMailFullList = useSelector((state) => state.BlueCoastSlice?.sendMailFullList && state.BlueCoastSlice?.sendMailFullList.length > 0 ? state.BlueCoastSlice.sendMailFullList : []);
    const loadingFullMAil = useSelector((state) => state.BlueCoastSlice?.sentmailAEsLoader ? state.BlueCoastSlice.sentmailAEsLoader : false);

    const [previewModal, setPreviewModal] = useState(false);
    const [previewModalData, setPreviewModalData] = useState(null);


    const viewBtnClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.sendmailfulllist && state.tableSlice?.customButtonClicked?.sendmailfulllist?.viewBtnAction ? state.tableSlice?.customButtonClicked?.sendmailfulllist?.viewBtnAction
        : false);

    const previewData = useSelector((state) => state.tableSlice?.tempRowData?.sendmailfulllist && Object.keys(state.tableSlice.tempRowData.sendmailfulllist).length > 0 ? state.tableSlice.tempRowData.sendmailfulllist
        : null);

    // console.log("sendContactMailFullList>>>>>", sendContactMailFullList)


    // useEffect(() => {
    //     dispatch(SendMailFulList(tableDataForSendCOntactMAilFullList.current.reqBody))
    // }, [])

    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})
    useEffect(() => {
        tableDataForSendCOntactMAilFullList.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            // console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);



    // console.log("previewModalData1234>>>>>>>", previewModalData);


    useEffect(() => {
        if (viewBtnClicked) {
            dispatch(
                setEnableRowLoader({
                    tableId: "sendmailfulllist",
                    rowId: previewData._id,
                    load: true,
                })
            );
            setPreviewModal(true);
            setPreviewModalData(previewData);

            // console.log("");

            setTimeout(
                () =>
                    dispatch(
                        setEnableRowLoader({
                            tableId: "sendmailfulllist",
                            rowId: previewData._id,
                            load: false,
                        })
                    ),
                1000
            );
        }
    }, [viewBtnClicked]);

    const handleClosePreview = () => {
        setPreviewModal(false);
    };

    const handleCLoseMailListAes = () => {
        SentMailAEsClose(false)
        setsaveRowdata(null)
    }


    var modifyTableHeaders =
        [
            { val: "subject", name: "Subject" },
            { val: "createdon_datetime", name: "Updation Time", type: "datetime", format: "MM/DD/YYYY" },
        ];

    let tableDataForSendCOntactMAilFullList = useRef({
        tableId: "sendmailfulllist",
        tableTitle: "Email Send To AEs",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "lead-manage/sendemaillist",
            // deleteSingleUserEndpoint: "training-manage/delete-training",
            tableCountEndpoint: "lead-manage/sendemaillist",
        },

        reqBody: {
            // // "source": "field_list",
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                "recipients": rowData.email
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false
        },


    

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to delete these records?</p>",
                modalHeader: "<p> Alert !! </p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    // endpoint: "manage-form/delete-field",
                    body: {
                        "source": "field_list",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },

            // {
            //   id: "adduser",
            //   type: "add_icon",
            //   title: "Add Training",
            //   className: "add_user_icon",
            //   default: true,
            // },
        ],
        buttons: [

            {
                id: "viewBtnAction",
                label: "Preview",
                type: "other",
                icon_type: "preview",
                name: "preview",
            },

            // {
            //     id: "viewApi",
            //     label: "Preview",
            //     type: "api",
            //     category: "view",
            //     icon_type: "preview",
            //     className: "preview",
            //     modalTitle: "Sent Mail to AEs Details",
            //     api_data: {
            //         base_url: process.env.REACT_APP_API_URL,
            //         endpoint: "lead-manage/sendemaillist",
            //         body: {
            //             "source": "",
            //             "condition": { "limit": 1, "skip": 0 },
            //             "sort": { "type": "asc", "field": "fullname" },
            //             "searchcondition": {
            //             }
            //         }
            //     },
            //     headers: [
            //         { val: "name", name: "Name" },
            //         { val: "sender", name: "Sender Mail" },
            //         { val: "recipients", name: "Recipient Mail" },
            //         { val: "subject", name: "Subject" },
            //         { val: "createdon_datetime", name: "Updation Time", type: "datetime", format: "MM/DD/YYYY" },

            //     ]
            // },

        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },


        searchData: {
            heading: " Search Email Send To AEs",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    // {
                    //     id: 0,
                    //     label: "Search by Name",
                    //     name: "fullname",
                    //     type: "autoComplete",
                    //     className: 'list_search_item countDiv__column--col6 ',
                    //     base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforemailname",
                    //     reqBody: {
                    //         // "source": "field_list",
                    //         "condition": {
                    //             "limit": 10,
                    //             "skip": 0
                    //         },
                    //         "sort": {
                    //             "type": "desc",
                    //             "field": "name"
                    //         },
                    //         "searchcondition": {

                    //         }
                    //     },
                    //     sx: { m: 1, minWidth: 300 },
                    //     payloadFormat: { key: "_id" },

                    // },
                    {
                        id: 2,
                        label: "Search by Subject",
                        name: "subject",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforemailsubject",
                        reqBody: {

                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "type": "desc",
                                "field": "name"
                            },
                            "searchcondition": {
                                "recipients": rowData.email

                            }
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "subject", param: "$regex", options: "i" },

                    },



                    {
                        id: 8,
                        //   heading: "Search By Creation Time",
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker list_search_item countDiv__column--col6',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                ]
            }
        },

    });
    return (
        <>
            <div className='SentMAil_wrpr'>

                <Modal
                    className="modalBaseStyle  SentMail_mainModal "
                    open={sentMailAEsOpen}
                    onClose={handleCLoseMailListAes}
                >
                    <Box className="modalBaseBox mediumModal">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleCLoseMailListAes}
                                // className="CloseModal"
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className="rolelist_mainwrp">

                            <h2 className='previewModalTitle'><div className='modal_title'>All Email List</div></h2>


                            <div className='AEs_name_header'>
                                {rowData?.fullname && <h2 >AEs Name: {rowData?.fullname}</h2>}
                                {rowData?.email && <h2 >Email: {rowData?.email}</h2>}
                            </div>
                            <div className='rolelist_mainwrp_Table'>
                                {!loadingFullMAil && sendContactMailFullList.length > 0 ? (
                                    <ListingTable
                                        tableData={tableDataForSendCOntactMAilFullList.current}
                                        dataset={sendContactMailFullList}
                                        modifyHeaders={modifyTableHeaders}
                                    />
                                ) : (
                                    <TableSkeleton count={5} />
                                )}

                            </div>
                        </div>
                    </Box>
                </Modal>


                <Modal
                    className="modalBaseStyle   SentMailAEs"
                    open={previewModal}
                    onClose={handleClosePreview}
                >
                    <Box className="modalBaseBox mediumModal">
                        <Tooltip title="Close">
                            <IconButton
                                onClick={handleClosePreview}
                                // className="CloseModal"
                                className="modal_icon_button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className="view_details">

                            <div className="modal_title modal_titlenew SD_bluecoast_preview"> {" "}
                                <h1 > Sent Mail Preview Details</h1>



                            </div>
                            <div className="modalview_group">
                                {previewModalData ? (
                                    <div className="data_container data_containerscroll">
                                        {/* <p>{JSON.stringify(previewModalData)}</p> */}

                                        <div className="data_block">
                                            {previewModalData?.body && (
                                                <div className="property_container">
                                                    <label className="property_label">Email:</label>
                                                    <label className="property_value">
                                                        <span dangerouslySetInnerHTML={{ __html: previewModalData.body }}></span>
                                                    </label>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                ) : (
                                    <div className="nodatafound">No Records Found</div>
                                )}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default SendContactMailList