import React, { useEffect, useState } from 'react'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { Chip, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { format } from 'date-fns'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ManageStageModal from '../userList/manageStageModal';
import { FetchStages } from '../userList/userListReducer';
import FDSearch from './FDSearch';
import { UserActivity,getStageLifeCycleData, StageLogsInfo } from './folderViewReducer';
import { useLocation } from 'react-router-dom';
import LeadStageModal from '../../LeadManagement/LeadStageModal';
const StageLifeCycle = ({ LifeCycleDataStage, folderdata }) => {

  console.log("folder view stagdata", folderdata);
  console.warn("folder view stagdata-----", LifeCycleDataStage);

  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [openStageModal, setOpenstageModal] = useState(false)
  const [open, setOpen] = React.useState(false); // snackbar const

  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const stageloading = useSelector(state => (state?.userManagment?.loading) ? state.userManagment.loading : false)
  const stagloading = useSelector(state => (state?.UserFolderViewReducer?.stageloading) ? state.UserFolderViewReducer.stageloading : false)
  const stageupdate = useSelector(state => (state?.userManagment?.stageUpdated) ? state.userManagment.stageUpdated : false)
   console.log("stageupdate",stageupdate);
  const dispatch = useDispatch()

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();

  console.log("stage search data",searchData);

  // ------- FetchedStateData----
  const fetchedStateData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment.FetchStages : [])
  const paramId = queryParam.search.split("=");
  console.log("fetchedStateData",fetchedStateData);

  console.log("data paramId", paramId);

  // ------------ ####### ------------
  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [JSON.stringify(paramId)])

  // -------------------------------





  useEffect(() => {
    // console.log("lucky data",fet);
    if (fetchedStateData.length > 0) {
      setOpenstageModal(true)
    } else if (fetchedStateData.length === 0) {
      setOpen(true);
    }
  }, [fetchedStateData])





  useEffect(() => {
    setOpenstageModal(false)
    setOpen(false);

  }, [])



  const handelStagemodal = () => {
    

    // let roles = Object.keys(folderdata?.roles).map((key) => key)
    // const data = {
    //   "condition": {},
    //   "sort": {},
    //   "searchcondition": {
    //     "added_for": folderdata._id,
    //     "roles": { $in: roles },
    //   },
    //   "project": {
    //     "subject": 1
    //   },
    //   "token": "",
    //   "count": false

    // }
    const data={
      "condition": {
        "limit": 5,
        "skip": 0
    },
    "sort": {
        "type": "desc",
        "field": "_id"
    },
    "searchcondition": {
      "added_for":folderdata._id,
      "roles":"is_lead"
    },
    "count": false

    }
    dispatch(FetchStages(data))
    
  }
  const closestagemodal = () => {
    setOpenstageModal(false)
    // dispatch(setReloadTableFlag({ tableId: 'leaddata' }))
  }

  //--------------------fetch stage lifecycle data-------------------
    
   useEffect(()=>{
   if(stageupdate===true){
    let data = {

      "condition": { "limit": 30, "skip": 0 },
      "sort": { "field": "createdon_datetime", "type": "desc" },
      "searchcondition": { "_id": folderdata._id },
      "count": false

    }  // Here id is the lead id
    dispatch(getStageLifeCycleData(data))
    dispatch(StageLogsInfo(data));
   }

   },[fetchedStateData])




  //=================================================================


  // snackbar Close snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);

  };

  const searchFields = [
    {
      id: 7,
      label: "Search By Stage",
      name: "stage_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "assign-stage/assign-stage-autocomplete",
      reqBody: {

          "added_for": folderdata._id
      },

    },
    {
      id: 8,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',


    },
    {
      id: 9,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'MM/DD/YYYY',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]




  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)

      let activity_times = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined

      }

   


      if (activity_times.$gte == "" || activity_times.$gte == undefined || activity_times.$gte == 'undefined') {
        delete activity_times["$gte"];
      }

      if (activity_times.$lte == "" || activity_times.$lte == undefined || activity_times.$lte == 'undefined') {
        delete activity_times["$lte"];
      }




      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "_id": folderdata._id,
          "stage_slug":searchData.stage_by,
          "createdon_datetime": Object.keys(activity_times).length > 0 ? activity_times : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      }


  

      if (payload.searchcondition?.createdon_datetime == undefined || payload.searchcondition?.createdon_datetime == 'undefined') {
        delete payload.searchcondition["createdon_datetime"]
      }



      // console.warn("<<<<<< Final payload Goes Here >>>>>", payload)
      // dispatch(StageLogsInfo(payload))
      dispatch(getStageLifeCycleData(payload))



    }
  }, [submissionState])


  console.log("StageLifeCycleData", LifeCycleDataStage);
  return (
    <>
      <div className='blocks'>
        <h3>
          <PlaylistPlayIcon /> Stage Life Cycle {" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>

            <Tooltip title="Search StageLifeCycle">
              <IconButton onClick={() => setSearchDraw(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Stage">
              <IconButton onClick={handelStagemodal}>
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>

          </div>
        </h3>
        {loading || stageloading||stagloading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            < LinearProgress />
          </Box>
        ) : null}


        {LifeCycleDataStage?.length > 0 ?
          LifeCycleDataStage.map((x) => (
            <span className='stageChipB'>
              <Chip label={x.stage_name} sx={{ margin: '10px' }} variant="outlined" />
              <QuestionMarkIcon className='qIcon' />


              <span className='chipDesBuble'>
                {x.stage_name} <br />
                Added by: {x.added_by_name} On {format(x.createdon_datetime, 'dd-MM-yyyy HH:mm')}
              </span>
            </span>
          )) :
          (<p> No stage assigned!</p>)}




      </div>


      {openStageModal && folderdata ? (<ManageStageModal
        toogle={openStageModal}
        tooglefunc={setOpenstageModal}
        stage={openStageModal}
        UserId={folderdata._id}
        Userinfo={folderdata}
        LifeCycleDataStage={LifeCycleDataStage}
      />) : ''}

      {/* {openStageModal ? (<LeadStageModal
        toogle={openStageModal}
        tooglefunc={() =>closestagemodal()}
        stage={openStageModal}
        UserId={folderdata._id}
        UserName={folderdata.fullname}
      />):""} */}



      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type="stage_life_cycle " /> : ""}



      {open ? (<Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="No Stage Found For Assigned"
      // action={action}
      />) : ""}



    </>
  )
}

export default StageLifeCycle