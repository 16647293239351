import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, LinearProgress, Snackbar } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { UserActivity } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const PersonalInfoBlock = (props) => {
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const



    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


    // --- loader --- 
    const loader = useSelector(state => (state.fetchUserDataReducer?.loading
    ) ? state.fetchUserDataReducer.loading
        : null);

    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

    const loading = useSelector(state => (state?.userFolderViewReducer?.waiting) ? state.userFolderViewReducer.waiting : false)

    // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)



    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };



    return (
        <>

            <div className='personalInfoFV'>
                <h3><PersonIcon /> Personal Information</h3>
                <p> <strong>Name:</strong> {props.folderdata !== null ? <span>{props.folderdata.firstname + ' ' + props.folderdata.lastname}</span> : 'N/A'} </p>

                <p><strong>Type:</strong>
                    <span>
                        {props.folderdata?.roloesArr?.length > 0 ? props.folderdata?.roloesArr.map(x => (
                            <span>
                                {x == 'is_admin' ? <span className='chipss'><Chip label="Admin" variant="outlined" /> </span> : x == 'is_rep' ? <span className='chipss'><Chip label="AE" variant="outlined" /></span> : x == 'is_aer' ? <span className='chipss'><Chip label="AEE" variant="outlined" /></span> : x == 'is_bdd' ? <span className='chipss'><Chip label="BDD" variant="outlined" /></span> : x == 'hr_manager' ? <span className='chipss'><Chip label="Hiring Manager" variant="outlined" /></span> : x == 'customer_support' ? <span className='chipss'> <Chip label="Customer Support" variant="outlined" /></span> : x == 'tech_support' ? <span className='chipss'><Chip label="Tech Support" variant="outlined" /></span> : ""}
                            </span>

                        )) : 'N/A'}
                    </span>
                </p>



                <p className='email'> <strong>Email:</strong> {props.folderdata !== null ? <span>{props.folderdata.email}</span> : 'NA'}</p>


                {props.folderdata?.roles?.hr_manager!==1 && props.folderdata?.main_role!=="hr_manager" ? <p> 
                <strong>Hiring Manager :</strong> 
                 {/* {props.folderdata?.hm_name && props.folderdata?.hm_name?.length > 0 ? props.folderdata?.hm_name?.map((x) => <span>{x.name}</span>) : 'NA'}   */}

                 {props.folderdata?.associated_hm_info ? props.folderdata.associated_hm_info: 'NA'} 
                </p> : ""} 


               {props.folderdata?.roles?.is_bdd!==1? <p> <strong>BDD :</strong> 
                    {/* {props.folderdata?.bdd_name?.length > 0 ? props.folderdata?.bdd_name?.map((x) => <span>{x.name}</span>) : "N/A"} */}
                   {props.folderdata?.associated_bdd_info ? props.folderdata.associated_bdd_info : "NA"}
                </p>:""}


               {props.folderdata?.roles?.is_rep!==1 && props.folderdata?.main_role!=="is_rep" ? 
                 <p>
                    <strong>AE :</strong> 
                    {/* {props.folderdata?.rep_name?.length > 0 ? props.folderdata?.rep_name?.map((x) => <span>{x.name}</span>) : "N/A"} */}
                   {/* {props.folderdata?.rep_name ? props.folderdata.rep_name : "NA"} */}
                 </p>  : ""}


                {props.folderdata?.roles?.is_aer!==1 || props.folderdata?.roles?.is_rep!==1 || props.folderdata?.roles?.hr_manager!==1 ? 
                <p><strong>Agent Code:</strong> {props?.folderdata?.agent_code !== null || props?.folderdata?.agent_code !== undefined? <span>{props.folderdata.agent_code}</span> : 'N/A'}</p>:""}




                <p><strong>Phone:</strong> {props.folderdata !== null ? <span>{props.folderdata.phone}</span> : 'NA'}</p>
                <p><strong>Address:</strong> {props.folderdata !== null ? <span>{props.folderdata.address}</span> : 'NA'}</p>
                <p><strong>Secondary Phone:</strong> {props.folderdata.secondary_phone !== undefined ? <span>{props.folderdata.secondary_phone}</span> : <span>N/A</span>}</p>
                <p><strong>Secondary Email:</strong> {props.folderdata?.secondary_email !== undefined ? <span>{props.folderdata.secondary_email}</span> : <span>N/A</span>}</p>
                <p><strong>State:</strong> {props.folderdata !== null ? <span>{props.folderdata.state}</span> : 'NA'}</p>
                <p><strong>City:</strong> {props.folderdata !== null ? <span>{props.folderdata.city}</span> : 'NA'}</p>
                <p><strong>Zip:</strong> {props.folderdata !== null ? <span>{props.folderdata.zip}</span> : 'NA'}</p>
            </div>




            {open ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={queryParamId ? "Updated Sucessfully" : " "}
            // action={action}
            />) : ""}

        </>
    )
}

export default PersonalInfoBlock;






