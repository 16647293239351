import React, { useEffect, useState } from 'react'
import MlmPage from '../LandingPage/MLMLandingpages/MlmPage'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { clearforceloginapidata, forcelogin, getMLMData } from '../LandingPage/LandingpageReducer';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { setLogedinUserInfo } from '../../login/loginReducer';
import { Cookies } from 'react-cookie';

const MlmFormsRediredct = () => {

    const {_id}= useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const [mlmFlag, setMlmFlag] = useState('');

    const Loading = useSelector((state) => state.LandingpagefontReducer?.loader ? state.LandingpagefontReducer.loader : false)

    const mlmStateData  = useSelector((state)=> state.LandingpagefontReducer?.mlmdata ? state.LandingpagefontReducer.mlmdata : null )

    const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)

    const forceloginapicallfalg = useSelector(state => (state.LandingpagefontReducer?.forceloginapicall) ? state.LandingpagefontReducer?.forceloginapicall : false)

    const forceLOgindata = useSelector(state => (state.LandingpagefontReducer?.forcelogindata) ? state.LandingpagefontReducer?.forcelogindata : null)




    useEffect(() => {

        if (_id != null && _id != undefined) {
          dispatch(getMLMData(_id))
        }
    
      }, [])

      useEffect(() => {

        console.log("mlmStateData", mlmStateData);
        if (mlmStateData && mlmStateData.rep_signup_stage) {
            setMlmFlag(mlmStateData.rep_signup_stage)
        }
      }, [mlmStateData])

      useEffect(() => {
        if (mlmFlag === 2) {
          redirectToAffiliateDashboard();
        }
      }, [mlmFlag])


      const redirectToAffiliateDashboard = async () => {
        // setloader(true)
        let data = {
          "email": mlmStateData.email,
          // "password": affiliateInfo.email,
          "_id": mlmStateData._id,
          "login_time": Math.round(new Date().getTime()),
          "login_data": ipinfo
        }
        dispatch(forcelogin(data))
      }

      useEffect(() => {
        if (forceloginapicallfalg && forceLOgindata) {
          redirectandcookieset()
        }
      }, [JSON.stringify(forceLOgindata), forceloginapicallfalg])



      const redirectandcookieset = async () => {
        let userInfodata = {
          "main_role": forceLOgindata.item.main_role,
          ...forceLOgindata.item.results[0]
        }
        await cookies.remove("userinfo", { path: "/" });
        await cookies.remove("lastLoginTime", { path: "/" });
    
        await cookies.set('userinfo', userInfodata, { path: '/' });
        await cookies.set('token', forceLOgindata.token, { path: '/' });
        await cookies.set('lastLoginTime', new Date().getTime(), { path: '/' });
        if (!userInfodata.last_login_time) {
          userInfodata.last_login_time = new Date().getTime()
        }
        let dataset = {
          "userinfo": userInfodata,
          "token": forceLOgindata.token
        }
        await dispatch(setLogedinUserInfo(dataset));
        // setloader(false)
        navigate('/new-ae-dashboard');
        dispatch(clearforceloginapidata())
      }






  return (
   <>
     {Loading && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)}
    {mlmFlag === 1  && <MlmPage />}
   </>
  )
}

export default MlmFormsRediredct