
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { useCookies } from "react-cookie";
// import { fetchUserData, setInitData } from '../AffiliateReducer';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system';
import { resolvefunction } from "../../../../helper/helperFunctions";
import { getusersList } from "../../userManagement/userList/userListReducer";
import { fetchUserData } from "../../userManagement/fetchUserDataReducer";

import AddEditFormEmployer from "./AddEditFormEmployer";
import { clearData } from "../EmployerReducer";
// import "../../BackEndPages.css";

const EmployerAddEdit = (props) => {
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 



    // ---- States here -----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    const [userInfo, setUserInfo] = useState(null); // Set User Information from Cookie
    const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [errPopup, setErrPopup] = React.useState(false); // snackbar const
    const [errMsgCopy, setErrMsgCopy] = React.useState(null); // snackbar const

    const [open, setOpen] = React.useState(false); // snackbar const
    // const [loader, setLoader] = useState(false);

    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    // console.warn("<<<<< cookieData from addEditFrom component>>>>>", cookieData)

    // ------ useSelector here ------
    // const userType = useSelector((state) => state.addUserSlice?.routeType)


    // --- loader --- 
    const loader = useSelector(state => (state.EmployerReducer?.loading
    ) ? state.EmployerReducer.loading
        : null);


    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.affiliateAddForm) ? state.formSlice.formSubmissionState.affiliateAddForm : false);


    const errMsg = useSelector(state => (state.formSlice?.message?.affiliateAddForm) ? state.formSlice.message.affiliateAddForm : null);


    // --- fetched data from query param --- 
    const fetchData = useSelector(state => (state.fetchUserDataReducer?.fetchUserDataReducer
    ) ? state.fetchUserDataReducer.fetchUserDataReducer
        : null);




    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setErrPopup(false);
        setErrMsgCopy(null);
    };

    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );



    //---- based on endpoint call ---
    useEffect(() => {
        if (endpointProcessState == 1) {
            // setLoader(true);
            setOpen(false);
        } else if (endpointProcessState == 2) {
            // setLoader(false);

            const payload_affiliatelist = {
                "condition": {
                    "limit": 30, "skip": 0
                },
                "sort": {
                    "field": "createdon_datetime", "type": "desc"
                },
                "searchcondition": {
                    "roles.is_employer": 1,
                    "added_by": userInfo.userinfo._id 
                },
                "count": false
            }


            setOpen(true);

            setTimeout(() => {
                // navigate('/view-affiliate');

                resolvefunction(
                    navigate,
                    "/employer-listing",
                    getusersList(payload_affiliatelist)
                );


            }, 800);

        } else if (endpointProcessState == 3) {

            setErrMsgCopy(errMsg);
            setErrPopup(true)

        }
    }, [endpointProcessState])


    useEffect(() => {
        if (cookieData && cookieData?.userinfocookies) {
            setUserInfo(cookieData.userinfocookies);
        }

        dispatch(clearData())

    }, []);



    // -------- Getting QueryParam and Calling thunk for fetching data through endpoint ---------

    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])


    useEffect(() => {
        if (queryParamId != undefined && queryParamId != null) {
            dispatch(fetchUserData(queryParamId));

        }
    }, [queryParamId])

    // console.warn("<<<<< query param from addEditFrom component>>>>>",queryParamId)

    //----------------------------------------------------------------------------------------




    // console.warn("<<<<< fetchData goes here>>>>>",fetchData)


    useEffect(() => {
        dispatch(clearData())
    }, [])



    return (
        <>
            <h1 className='page_heading'>{queryParamId ? "Edit" : "Add"} Employer</h1>



            <div className='dataform2_wrapper'>

                <div className='adminformbody'>

                    {/* {loader ? (<Box className="loader-block">
            <div className="lds-facebook"><div></div><div></div><div></div></div>
          </Box>) : ""} */}
                    {loader ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null}

                    {(queryParamId) ? (fetchData && <AddEditFormEmployer
                        queryParamId={queryParamId}
                        formType={props.formType}
                        fetchData={fetchData}
                        userType={props.userType}
                    />) : (<AddEditFormEmployer
                        userType={props.userType}
                        formType={props.formType}

                    />)}

                    {/* ---- SnackBar on data Submisstion */}
                    {endpointProcessState == 2 ? (<Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={queryParamId ? "Employer Updated Sucessfully" : "Employer Created Sucessfully"}
                        // message={signUpMsg}
                        action={action}
                    />) : ""}


                    {errMsgCopy ? (<Snackbar
                        open={errPopup}
                        autoHideDuration={4000}
                        onClose={handleClose}
                        message={errMsgCopy}
                        action={action}
                    />) : ""}


                </div>
            </div>


        </>
    )
}

export default EmployerAddEdit;