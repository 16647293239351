import React from 'react'
import "./Asblockstyle.css";

function Aslandingpageblock11() {
    return (
        <>
            <div className='aslandingpageblock11_mainwrapper'>
            <div className='aslandingpageblock11_container'>
                <div className='aslandingpageblock11_subwrapper'>
                    <div className='aslandingpageblock10_textwrapper'>
                        <h1>What should I ask my doctor?</h1>
                        <p>First, ensure your doctor knows you have a new pharmacy program and that you get any of our 605 generic medications for FREE. Then ask, “would one of these generic drugs be a good option for me, and could it treat my condition similarly?” You can also ask if a generic option exists for your other brand­ name drugs. Remember, most medications Americans take are generic.</p>
                    </div>
                    <div className='aslandingpageblock10_textwrapper'>
                        <h1>Why aren’t ALL medicines on your drug list?</h1>
                        <p>Generic medications make up 85% of the medicines in America each year. Our list accounts for 90% of drugs prescribed. Our list of medications is grouped into more than 22 therapeutic classes, ensuring we have a very well-rounded formulary for various chronic and acute conditions.</p>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Aslandingpageblock11
