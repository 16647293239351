import React, { useEffect, useRef, useState } from "react";
import {
  setEnableRowLoader,
  setReloadTableFlag,
} from "../../../listing/listReducer";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import {
    Button,
    Chip,
    FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { dispositionCalllist } from "./BlueCoastReducer";
import ListingTable from "../../../listing/listing";
import { TableSkeleton } from "../../../helper/TableSkeleton";

const MeetingListForPharmacyAspire = ({rowData,click,setPaymentRowData}) => {

    const dispatch = useDispatch();

  const [saveRowdata, setsaveRowdata] = useState(null)
  const [dispositionCallListModalOpen, setDispositionCallListModalOpen] = useState(false);


  useEffect(() => {
    if (rowData && Object.keys(rowData).length > 0) {
      setsaveRowdata(rowData)
    }
  }, [JSON.stringify(rowData)])


    const dispositionCallData = useSelector((state) => (state.BlueCoastSlice?.dispositioncalllistdata && state.BlueCoastSlice?.dispositioncalllistdata.length > 0) ? state.BlueCoastSlice.dispositioncalllistdata : [])

    const loadinginDispCall = useSelector(state => state.BlueCoastSlice?.dispcallloading ? state.BlueCoastSlice.dispcallloading : false);




  /**************************************Table List for Disposition Call************** */

  var modifyTableHeadersforDispCall =
    [
      { val: "disposition_name", name: "Disposition Name" },
      { val: "addedby_name", name: "Added By" },
      // { val: "createdon_datetime", name: "Creation Time", type: "datetime", format: "MM/DD/YYYY" },
      { val: "set_up_date", name: "Meeting Date", type: "datetime", format: "MM/DD/YYYY" },
      { val: "set_up_time", name: "Meeting Time" },

    ];

  let tableDataForDispCall = useRef({
    tableId: "disp_call_list",
    tableTitle: "All Meeting List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/disposition_call_datalist",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/disposition_call_datalist",
    },

    reqBody: {
      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {
        "addedfor": saveRowdata?._id
      },
      "project": {},
      "token": "",
      "email": "",
      "count": false,
    },

    rowsPerPageOptions: [30],
    sortFields: ["addedby_name", "set_up_time", "disposition_name", "set_up_date"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search All Meeting",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchNotesForm',
        formWrapperClass: "searchBarHead formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [

          {
            id: 10,
            label: "Search by Disposition",
            name: "notessearch",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "lead-manage/allmeetingdispositionserach",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": { "addedfor": saveRowdata?._id },

            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },
          {
            id: 25,
            label: "Search by Added By",
            name: "user",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            // multiple: true,
            base_url: process.env.REACT_APP_API_URL + "lead-manage/spcialautocompletename",
            reqBody: {
              // "condition": {
              //   "limit": 5,
              //   "skip": 0
              // },
              // "sort": {
              //   "type": "asc",
              //   "field": "email"
              // },
              // "searchcondition": { "addedfor": saveRowdata?._id },
              // // "type": "prospectemail",
              // // "source": "bluecoast_leads"

              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {

              }

            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedby" },
          },

        ]
      }
    },

  });

















  
  
    useEffect(() => {
      if (click && saveRowdata && Object.keys(saveRowdata).length > 0) {
  
        dispatch(
          setEnableRowLoader({
            tableId: "paymentDetials",
            rowId: saveRowdata._id,
            load: true,
          }))
  
        // setDispositionCallListModalOpen(true)
  
        dispositionCall(saveRowdata)
  
      }
    }, [click, JSON.stringify(saveRowdata)])
  
    const dispositionCall = async (val) => {
  
      setsaveRowdata(val)
  
      tableDataForDispCall.current.reqBody.searchcondition.addedfor = val._id
      tableDataForDispCall.current.searchData.formData.fields[0].reqBody.searchcondition.addedfor = val._id
      tableDataForDispCall.current.searchData.formData.fields[1].reqBody.searchcondition.addedfor = val._id
  
      await dispatch(dispositionCalllist(tableDataForDispCall.current.reqBody))
      setDispositionCallListModalOpen(true)
  
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: saveRowdata._id,
          load: false,
        }))
  
  
    }
  
    const handleCloseDispositionListModal = () => {
      setsaveRowdata(null)
      setPaymentRowData({})
      setDispositionCallListModalOpen(false);
    };
  
  return (
    <>
    
              {/* *********Modal For Disposition List***********  */}

     <Modal
            className="modalBaseStyle allMeetingMOdal notesModal_blcoast"
            open={dispositionCallListModalOpen}
            onClose={handleCloseDispositionListModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className='modalBaseBox mediumModal' >
              <Tooltip title='Close' >
                <IconButton onClick={handleCloseDispositionListModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div>
                <h2 className='previewModalTitle'>
                  <div className='modal_title'>All Meeting List </div>
                </h2>
                <div className="rolelist_mainwrp_Table">
                  {!loadinginDispCall && dispositionCallData && dispositionCallData.length > 0 ? (
                    <ListingTable
                      tableData={tableDataForDispCall.current}
                      dataset={dispositionCallData}
                      modifyHeaders={modifyTableHeadersforDispCall}
                    />
                  ) : <div><h3>No Records Found</h3></div>}

                  {loadinginDispCall && <TableSkeleton count={5} />}
                </div>
              </div>
            </Box>
          </Modal>
    
    
    
    
    
    </>
  )
}

export default MeetingListForPharmacyAspire