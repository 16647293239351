import { Button, LinearProgress, Modal, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';

const TaskStatusChangeModal = ({ openStatusModal, taskStatusFormClose, handleStatusChange, value }) => {

    const loader = useSelector((state) => state.BlueCoastSlice?.loading ? state.BlueCoastSlice.loading : false)



    return (
        <Modal
            className="modalblock TaskStatusChange"
            open={openStatusModal}
            onClose={taskStatusFormClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {/* BoxMui_modal */}
            <Box className="" sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "background.paper",
                // border: "2px solid #000",
                border: '10px solid #0569ad!important',
                background: '#fff',
                boxShadow: 24,
                // p: 4,
                textAlign: "center",
            }}>
                <Typography id="modal-modal-title" variant="h5" component="h5">

                    <p className='alertPara2'> Alert !!</p>

                    <p> Are You Sure You Want Change Task Status? </p>
                </Typography>
                <div>
                    {value == 1 ? <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => handleStatusChange(2)} className="modalBTN">Mark As Completed</Button> : <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => handleStatusChange(1)} className="modalBTN">Mark As Pending</Button>}

                </div>

                {loader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

                <Button onClick={taskStatusFormClose} className="closeModalBtn">
                    <Tooltip title="Close" placement="right-start">

                        <CloseIcon />
                    </Tooltip>
                </Button>
            </Box>
        </Modal>
    )
}

export default TaskStatusChangeModal