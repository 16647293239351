import { LinearProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../../listing/listing'
import { TableSkeleton } from '../../../../../helper/TableSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import { viewEmployeeList, viewSubEmployersList } from '../../EmployerReducer'

function SubLoginEmployerCount() {

    const dispatch = useDispatch()

    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});

    const subEmployerLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)
    const subEmployerListDetails = useSelector((state) => state.EmployerReducer?.subEmployerListData ? state.EmployerReducer.subEmployerListData : null)

    const [subEmployerListActive, setSubEmployerListActive] = useState([]);








    // ====================main table header===============
    var modifyTableHeadersForSubEmployers =
        [
            { val: "name", name: "Full Name" },
            { val: "email", name: "Email" },
            { val: "phone", name: "Phone Number" },
            { val: "title", name: "Title" },
            { val: "address", name: "Address" },
            { val: "city", name: "City" },
            { val: "state", name: "State" },
            { val: "zip", name: "Zip" },
            // { val: "company_name", name: "Company Name" },
            {
                val: "status",
                name: "Status",
                customVal: { false: "Inactive", true: "Active" },
            },
            { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY" },
        ];

    // ======================main table for employee lsit==================

    let tableDataforSubEmployerList = useRef({
        tableId: "tableForSubEmployerData",
        tableTitle: "Active Sub Employer",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "employer_manage/sub_login_list",
            tableCountEndpoint: "employer_manage/sub_login_list",
        },

        reqBody: {

            "condition": {
                "limit": 15,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                added_by: logedUserData?._id,
                status: true,
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false
        },




        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [],

    });

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(tableDataforSubEmployerList.current.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "employer_manage/sub_login_list",
            requestOptions
        );
        const respdata = await response.json();
        // console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setSubEmployerListActive([...respdata.results.res])
        }
        // setloaderFlag(false);
        // conflictingSlotFetched(true);
    }

    useEffect(() => {
        if (subEmployerListActive && subEmployerListActive.length === 0) {
            // console.log("subEmployerListActive==============>>>>>>", subEmployerListActive)
            getList()
        }
    }, [])


    return (
        <>
            {subEmployerLoader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

            <div className='employee_Count'>
                <div className='rolelist_mainwrp_Table'>
                    {!subEmployerLoader && Object.keys(subEmployerListActive).length > 0 ? (
                        <ListingTable
                            tableData={tableDataforSubEmployerList.current}
                            dataset={subEmployerListActive}
                            modifyHeaders={modifyTableHeadersForSubEmployers}
                        />

                    ) : (
                        <div><TableSkeleton count={5} /></div>
                    )}


                </div>
            </div>
        </>
    )
}

export default SubLoginEmployerCount