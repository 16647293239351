import { Grid } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import TotalGrossPackage from '../../EmployerDashBoardDetails/TotalGrossPackage';
import EmployeeListCount from '../../EmployerDashBoardDetails/EmployeeListCount';

function SubLoginDashboard() {

    const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
    let lastloginTime = useSelector(state => state.loginSlice.last_login_time);

    const [totalCostFetchStatus, setTotalCostFetchStatus] = useState(false)
    const [employeeFetchStatus, setEmployeeFetchStatus] = useState(false)


    const totalGrossFetching = (event) => {
        // console.log("event", event)
        if (event !== undefined && event !== null) {
            setTotalCostFetchStatus(event);
        }

        // console.log("========>>>>>>>", totalCostFetchStatus)
    }

    const employeeFetching = (event) => {
        // console.log("event", event)
        if (event !== undefined && event !== null) {
          setEmployeeFetchStatus(event);
        }
    
        // console.log("========>>>>>>>", employeeFetchStatus)
      }

    return (
        <>
            <h1 className="page_heading">Sub-Login Dashboard</h1>

            <Grid container spacing={1} className="dashboard_con">
                <Grid item md={12} xs={12}>
                    <div className="dashboard_block1 dashboardCommon">
                        <h2>
                            <span>Welcome:</span>{" "}
                            {userInfo.userinfo?.firstname + " " + userInfo.userinfo?.lastname}{" "}
                        </h2>

                        <h3>
                            <span>Last Login:</span>{" "}
                            {lastloginTime !== null
                                ? moment(Number(lastloginTime)).format("dddd, MMMM Do YYYY, h:mm a")
                                : "N/A"}
                        </h3>
                    </div>
                </Grid>
            </Grid>

            <TotalGrossPackage totalGrossFetched={totalGrossFetching} />
            <br />

            {(totalCostFetchStatus) ? <EmployeeListCount employeeFetched={employeeFetching} /> : ""}
        </>
    )
}

export default SubLoginDashboard