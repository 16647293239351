import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import store from "../../../../store";
import CloseIcon from "@mui/icons-material/Close";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Button, Checkbox, Chip, Grid, IconButton, LinearProgress, List, ListItemText, MenuItem, TextField, Tooltip } from "@mui/material";
import { Box, display, Stack } from "@mui/system";
import { useCookies } from "react-cookie";
import { Form } from "../../../../form/Form";
import { addlead, autoCompleteBddData, autoCompleteHMData, clearHmBddData, clearSnakebarData, clearUpdateHmBddStatus, clearleadHmBddDataInfo, leadHmBddData, updateAssignHmBdd } from "../../LeadManagement/LeadReducer";

// import { useCookies, Cookies } from "react-cookie";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AssignBddHm = (props) => {
    console.log("luckyk rowdata", props);
    const dispatch = useDispatch(); // ---- Dispatch -----
    const [bddFlag, setBddFlag] = useState(false)
    const [bddClicked, setBddClicked] = useState([])
    const [open, setOpen] = useState(false)
    const [snackMsgCopy, setSnackMsgCopy] = useState(null);
    const [errPopup, setErrPopup] = useState(false);
    // ---- States ----
    const [loading, setLoading] = useState(true); //*****FOR LODING****//
    const [bddDefaultValue, setBddDefaultValue] = useState([])///this state for default value for Bdd
    const [bddLength, setBddLength] = useState(null)
    const [hmLength ,setHmLength]=useState(null)


    // ---- Cookies ---
    // const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // // ---- UseSelectors ----
    // const users = useSelector((state) => state.userManagment.usersList)
    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)

    // console.log("<<<<< props from popupToogle modal compo >>>>", bddClicked);


    //    console.log("props here ====>>>>>", props.leadHmBddDataInfo)



    //--------------------------fetch updateHmBddStatus----------------------------------------------------
    let updateHmBddStatus = useSelector((state) => state.LeadReducer?.updateHmBddStatus && state.LeadReducer?.updateHmBddStatus.length > 0 ? state.LeadReducer?.updateHmBddStatus : []);

    //-----------------------------------------------------------------------------------------------------

    let snackMsg = useSelector((state) => state.LeadReducer?.snakebarData && state.LeadReducer?.snakebarData.length > 0 ? state.LeadReducer?.snakebarData : null);


    const endpointLoading = useSelector((state) => state.LeadReducer?.loading ? state.LeadReducer.loading : false);
    const addleadloader = useSelector((state) => state.LeadReducer?.addleadloader ? state.LeadReducer.addleadloader : false);

    const loader = useSelector((state) => state.fetchUserDataReducer?.loading ? state.fetchUserDataReducer.loading : false);

    //-------------------------Fetch BDD Autocomplete Data-------------------------
    let BddDataAutocomplete = useSelector((state) => state.LeadReducer?.autoCompleteBddData && state.LeadReducer?.autoCompleteBddData?.length > 0 ? state.LeadReducer?.autoCompleteBddData : []);
    //==============================================================================

    //-------------------------Fetch HM Autocomplete Data-------------------------
    let HMDataAutocomplete = useSelector((state) => state.LeadReducer?.autoCompleteHMData && state.LeadReducer?.autoCompleteHMData.length > 0 ? state.LeadReducer?.autoCompleteHMData : []);
    //==============================================================================


useEffect(()=>{
    if(props?.leadHmBddDataInfo.filter((x)=>x?.type==="HM")){

        setHmLength(props?.leadHmBddDataInfo.filter((x)=>x?.type==="HM").length)
        // setBddLength(props?.leadHmBddDataInfo.filter((x)=>x?.type==="BDD").length)

    }
},[props?.leadHmBddDataInfo])

useEffect(()=>{
    if(props?.leadHmBddDataInfo.filter((x)=>x?.type==="BDD")){

        // setHmLength(props?.leadHmBddDataInfo.filter((x)=>x?.type==="HM").length)
        setBddLength(props?.leadHmBddDataInfo.filter((x)=>x?.type==="BDD").length)

    }
},[props?.leadHmBddDataInfo])

console.log("assign hm hmlength",hmLength);


    //----------------------If Hm update Endpoint succcess then after----------------------------

    useEffect(() => {
        if (updateHmBddStatus === "success") {
            let data = {
                _id: props.rowdata._id,
                source: props.source === "users" ? "users" : "google_events",
                project: true
            }
            dispatch(leadHmBddData(data))
            setOpen(true)

            setTimeout(() => {
                dispatch(clearUpdateHmBddStatus())
            }, 1000)
        }
    }, [updateHmBddStatus])

    //    let dvalue=[]

    //     useEffect(() => {
    //         if (props.leadHmBddDataInfo?.length > 0) {
    //             props.leadHmBddDataInfo.forEach(element => {
    //                 if (element.type === "BDD")
    //                    dvalue.push(element.label)

    //             });
    //         }
    //     }, [])



    useEffect(() => {
        if (props.leadHmBddDataInfo?.length > 0) {
            props.leadHmBddDataInfo.forEach(element => {
                if (element.type === "BDD")
                    setBddClicked([...bddClicked, element.key])
                setBddDefaultValue([element.label])

            });
        }
    }, [])

    //    useEffect(()=>{
    //     dispatch(leadHmBddData(props?.rowdata._id))
    //    },[])



    // ------- onModalClose --------
    function ModalClose() {
        props.popuptooglefunc(false);
        dispatch(clearHmBddData())
        dispatch(clearleadHmBddDataInfo())
        props.setHMBddRowData(null)
        props.setBddHmModel(true)


    }




    //------------------------BDD  Onclick------------------------------
    useEffect(() => {
        if (bddFlag) {

            let payload = {
                _id: props.rowdata._id,
                associated_bdd: bddClicked,
                source: props.source === "users" ? "users" : "google_events"
            }
            dispatch(updateAssignHmBdd(payload))
            setOpen(true)


            setTimeout(() => {
                dispatch(clearleadHmBddDataInfo())
                setOpen(false)
            }, 2000);
            setBddFlag(false)

        }
    }, [bddClicked])








    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setErrPopup(false);
        setSnackMsgCopy(null);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );



    function chipsDlt(val) {
        let payload = {
            _id: props.rowdata._id,
            hiring_manager: "",
            source: props.source === "users" ? "users" : "google_events"
        }
        dispatch(updateAssignHmBdd(payload))

    }


    const handleDeleteBdd = (val) => {

        console.log("this is bddclick value", val);

        const index = bddClicked.indexOf(val);
        if (index > -1) {
            bddClicked.splice(index, 1);
            let payload = {
                _id: props.rowdata._id,
                associated_bdd: bddClicked,
                source: props.source === "users" ? "users" : "google_events"
            }
            dispatch(updateAssignHmBdd(payload))


        }

    }







    return (
        <>
            {/* ------ Confirmation Modal ------ */}

            <Modal
                className=" modalblock userList_modal"
                open={props.popuptoogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className={"BoxMui_modal other_BoxMui_modal"}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        textAlign: "center",
                    }}
                >

                    <div className=" ModalBox_scroll">
                        <div className="heading">
                            <h3>
                                {(props?.rowdata?.main_role === "is_rep") && "Assign BDD/HM For :"}
                                {(props?.rowdata?.main_role === "hr_manager") && "Assign BDD For :"}
                                {(props?.source === "leadList") && "CHOOSE HM/BDD FOR :"}
                                {props?.rowdata?.name ? props.rowdata.name : props.rowdata?.fullname ? props.rowdata.fullname : props.userName}
                            </h3>
                            {endpointLoading || loader || addleadloader ? < LinearProgress /> : ""}
                        </div>

                        {
                            props?.source !== "leadList" ? <div className="assign_hm_bdd_field">

                                {(props?.rowdata?.main_role === "is_rep") &&
                                    <div className="assign_hm_field">

                                        <Autocomplete
                                            // freeSolo={true}

                                            id="combo-box-demo"
                                            options={HMDataAutocomplete}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <Box component="li" key={option.key}  {...props}>
                                                    {option.label}
                                                </Box>
                                            )}




                                            sx={{ width: 300 }}
                                            onChange={(e, newValue) => dispatch(updateAssignHmBdd({
                                                _id: props.rowdata._id,
                                                hiring_manager: newValue?.key,
                                                source: props.source === "users" ? "users" : "google_events"
                                            }))}

                                            renderInput={(params) => <TextField {...params} label="Select HM" onChange={(x) => dispatch(autoCompleteHMData(
                                                {
                                                    "condition": {
                                                        "limit": 10,
                                                        "skip": 0
                                                    },
                                                    "sort": {
                                                        "type": "asc",
                                                        "field": "name"
                                                    },
                                                    "searchcondition": {

                                                        "label": {
                                                            "$regex": x.target.value,
                                                            "$options": "i"
                                                        }
                                                    },
                                                    "type": "user_name",
                                                    "source": "users"
                                                }))} />}

                                        />



                                        <div className="chips">

                                            {props.leadHmBddDataInfo.length > 0 && hmLength > 0 ?

                                                props.leadHmBddDataInfo.map((x) => x.type === "HM" && <Chip label={x.label} variant="outlined" onDelete={() => chipsDlt(x.key)} />)

                                                :    "No HM Assign!"}

                                            


                                        </div>


                                    </div>}


                                {/* --------------------------------------------------This is Select BDD Fields---------------------------------------- */}

                                {((props?.rowdata?.main_role === "hr_manager") || (props?.rowdata?.main_role === "is_rep")) &&
                                    <div className="assign_bdd_field">

                                        <Autocomplete
                                            multiple
                                            limitTags={3}
                                            id="combo-box-demo"
                                            options={BddDataAutocomplete}
                                            getOptionLabel={(option) => option.label}
                                            // value={bddDefaultValue.map(item=>item)}
                                            renderOption={(props, option) => (
                                                <Box component="li" key={option.key}  {...props}>
                                                    {option.label}
                                                </Box>
                                            )}

                                            sx={{ width: 300 }}
                                            onChange={(e, newValue) => { newValue.map(item => setBddClicked([...bddClicked, item.key])); setBddFlag(true) }}



                                            renderInput={(params) => <TextField {...params} label="Select BDD" onChange={(x) => dispatch(autoCompleteBddData(
                                                {
                                                    "condition": {
                                                        "limit": 10,
                                                        "skip": 0
                                                    },
                                                    "sort": {
                                                        "type": "asc",
                                                        "field": "name"
                                                    },
                                                    "searchcondition": {

                                                        "label": {
                                                            "$regex": x.target.value,
                                                            "$options": "i"
                                                        }
                                                    },
                                                    "type": "user_name",
                                                    "source": "users"
                                                }))} />}

                                        />




                                        <div className="chips">



                                            <Stack direction="row" spacing={1} sx={{
                                                marginTop: "10px",
                                            }}>

                                                <div className='ChipsSet-grp' sx={{
                                                    display: "flex",
                                                    width: '100%',
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    margin: "0 auto",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                }}>
                                                    {props.leadHmBddDataInfo.length > 0 && bddLength >0?

                                                        props.leadHmBddDataInfo.map((x) => x.type === "BDD" && <Chip label={x.label} variant="outlined" onDelete={() => handleDeleteBdd(x.key)} />)

                                                        : "No BDD Assign!"}

                                                </div>


                                            </Stack>





                                            {/* {props.leadHmBddDataInfo.length > 0 ?

                                        props.leadHmBddDataInfo.map((x) => x.type === "BDD" && <Chip label={x.label} variant="outlined" onDelete={() => handleDeleteBdd(x.key)} />)

                                        : "No BDD Assign!"} */}


                                        </div>



                                    </div>}



                            </div>

                                :

                                <div className="assign_hm_bdd_field">


                                    <div className="assign_hm_field">

                                        <Autocomplete
                                            // freeSolo={true}

                                            id="combo-box-demo"
                                            options={HMDataAutocomplete}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <Box component="li" key={option.key}  {...props}>
                                                    {option.label}
                                                </Box>
                                            )}




                                            sx={{ width: 300 }}
                                            onChange={(e, newValue) => dispatch(updateAssignHmBdd({
                                                _id: props.rowdata._id,
                                                hiring_manager: newValue?.key,
                                                source: props.source === "users" ? "users" : "google_events"
                                            }))}

                                            renderInput={(params) => <TextField {...params} label="Select HM" onChange={(x) => dispatch(autoCompleteHMData(
                                                {
                                                    "condition": {
                                                        "limit": 10,
                                                        "skip": 0
                                                    },
                                                    "sort": {
                                                        "type": "asc",
                                                        "field": "name"
                                                    },
                                                    "searchcondition": {

                                                        "label": {
                                                            "$regex": x.target.value,
                                                            "$options": "i"
                                                        }
                                                    },
                                                    "type": "user_name",
                                                    "source": "users"
                                                }))} />}

                                        />



                                        <div className="chips">

                                            {props.leadHmBddDataInfo.length > 0 &&  hmLength >0 ?

                                                props.leadHmBddDataInfo.map((x) => x.type === "HM" && <Chip label={x.label} variant="outlined" onDelete={() => chipsDlt(x.key)} />)

                                                : "No HM Assign!"}


                                        </div>


                                    </div>


                                    {/* --------------------------------------------------This is Select BDD Fields---------------------------------------- */}


                                    <div className="assign_bdd_field">

                                        <Autocomplete
                                            multiple
                                            limitTags={3}
                                            id="combo-box-demo"
                                            options={BddDataAutocomplete}
                                            getOptionLabel={(option) => option.label}
                                            // value={bddDefaultValue.map(item=>item)}
                                            renderOption={(props, option) => (
                                                <Box component="li" key={option.key}  {...props}>
                                                    {option.label}
                                                </Box>
                                            )}

                                            sx={{ width: 300 }}
                                            onChange={(e, newValue) => { newValue.map(item => setBddClicked([...bddClicked, item.key])); setBddFlag(true) }}



                                            renderInput={(params) => <TextField {...params} label="Select BDD" onChange={(x) => dispatch(autoCompleteBddData(
                                                {
                                                    "condition": {
                                                        "limit": 10,
                                                        "skip": 0
                                                    },
                                                    "sort": {
                                                        "type": "asc",
                                                        "field": "name"
                                                    },
                                                    "searchcondition": {

                                                        "label": {
                                                            "$regex": x.target.value,
                                                            "$options": "i"
                                                        }
                                                    },
                                                    "type": "user_name",
                                                    "source": "users"
                                                }))} />}

                                        />




                                        <div className="chips">



                                            <Stack direction="row" spacing={1} sx={{
                                                marginTop: "10px",
                                            }}>

                                                <div className='ChipsSet-grp' sx={{
                                                    display: "flex",
                                                    width: '100%',
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    margin: "0 auto",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                }}>
                                                    {props.leadHmBddDataInfo.length > 0 ?

                                                        props.leadHmBddDataInfo.map((x) => x.type === "BDD" && <Chip label={x.label} variant="outlined" onDelete={() => handleDeleteBdd(x.key)} />)

                                                        : "No BDD Assign!"}

                                                </div>


                                            </Stack>





                                            {/* {props.leadHmBddDataInfo.length > 0 ?

            props.leadHmBddDataInfo.map((x) => x.type === "BDD" && <Chip label={x.label} variant="outlined" onDelete={() => handleDeleteBdd(x.key)} />)

            : "No BDD Assign!"} */}


                                        </div>



                                    </div>



                                </div>
                        }






                    </div>



                    <Tooltip title="Close" placement="right-start">
                        <IconButton className="modalCloseBtn" onClick={ModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>


                </Box>
            </Modal>

            {/* ---- SnackBar on data Submisstion */}
            {<Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={snackMsg}
                action={action}
            />}

            {/* ------------ */}
        </>
    );
};

export default AssignBddHm;
