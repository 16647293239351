import { Button, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, Modal, OutlinedInput, Select, Snackbar, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { clearProgramData, fetchProgram, programDetails } from './userListReducer';

const AssinProgramModal = ({ togalState, Modalstate, userName, commonData, source, setProgramRowData }) => {

    console.log("for lead assign program", commonData);

    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState(null)

    const dispatch = useDispatch()

    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)

    const programData = useSelector(state => state.userManagment?.programDetailsData ? state.userManagment.programDetailsData : [])
    const programUpdate = useSelector(state => state.userManagment?.programUpdate ? state.userManagment.programUpdate : [])


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {
        // console.log("dfjhgdfjghf",clicked);
        if (clicked && Object.keys(clicked).length > 0) {
            console.log("clicked", clicked);
            setOpenDropDown(false)
            let reqBody = {
                program_id: clicked.key,
                _id: commonData._id,
                source: source === "lead" ? "google_events" : "users"
            }
            dispatch(fetchProgram(reqBody))
            setSnackMessage("Program Assigned Successfully")
            setSnackOpen(true)
        }
    }, [clicked])

    const deleteChips = (val) => {
        let reqBody = {
            program_id: val.key,
            _id: commonData._id,
            delete: 1,
            source: source === "lead" ? "google_events" : "users"
        }
        dispatch(fetchProgram(reqBody))
        setSnackMessage("Program Removed Successfully")
        setSnackOpen(true)
    }



    const ModalClose = () => {
        togalState(false)
        // setProgramRowData(null) // clear this data outside from component (chek LeadList Line 693 )

    }

    useEffect(() => {
        let reqbody = {
            _id: commonData._id,
            source: source === "lead" ? "google_events" : "users"
        }
        dispatch(programDetails(reqbody))
    }, [])

    useEffect(() => {
        if (programUpdate === true) {
            let reqbody = {
                _id: commonData._id,
                source: source === "lead" ? "google_events" : "users"
            }
            dispatch(programDetails(reqbody))
        }
    }, [programUpdate])

    useEffect(() => {
        if (Modalstate === false) {
            dispatch(clearProgramData())
        }
    }, [Modalstate])

    const snackHandleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <Modal className="modalblock MK_modal_wrap" open={Modalstate} onClose={ModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="BoxMui_modal configure-role" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, bgcolor: "background.paper", border: "2px solid #000", boxShadow: 24, p: 4, textAlign: "center", }}>
                    <Typography id="modal-modal-title" variant="h6" component="h4">
                        {loader ? <LinearProgress /> : ""}

                        Manage Program For: <span className='name'>{userName}</span>
                    </Typography>



                    <form>
                        <div className='MK_tagmodel'>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Select Program</InputLabel>
                                <Select
                                    open={openDropDown}
                                    onOpen={() => setOpenDropDown(true)}
                                    onClose={() => setOpenDropDown(false)}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={programData.filter((item) => item.status === 1).map((item) => item)}
                                    input={<OutlinedInput label="tag" />}
                                    renderValue={(tagList) => tagList.map(tag => tag.label).join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {programData.map((item) => (
                                        <MenuItem key={item.key} value={item} onClick={() => setClicked(item)}>
                                            <Checkbox checked={Boolean(item.status)} />
                                            <ListItemText primary={item.label} />
                                        </MenuItem>
                                    ))}

                                </Select>


                                <Stack direction="row" spacing={1} sx={{ marginTop: "10px", }}>
                                    <div className='ChipsSet-grp' sx={{
                                        display: "flex",
                                        width: '100%',
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        margin: "0 auto",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                    }}>

                                        {programData.length > 0
                                            ? programData.filter((tag) => tag.status === 1).map((val) => <Chip
                                                label={val.label}
                                                variant="outlined"
                                                sx={{ margin: '3px 3px' }}
                                                onDelete={() => deleteChips(val)}
                                            />)
                                            : (<h3> No Program Assigned</h3>)}

                                        {/* {copyUserRoles.length > 0 ? copyUserRoles.map((val) => val.length > 0 ? (<Chip label={val} variant="outlined" onDelete={() => deleteChips(val)} />):"") : (<h3> No Role Assigned</h3>)} */}
                                    </div>


                                </Stack>




                            </FormControl>
                        </div>
                    </form>



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">
                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>

            {snackOpen ? (<Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={snackHandleClose}
                message={snackMessage}

            />) : ""}
        </>
    )
}

export default AssinProgramModal