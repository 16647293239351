
import { Button, IconButton, LinearProgress, Modal, Snackbar, Tooltip } from '@mui/material'
import React, { useEffect, useState, useLocation } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import FDSearch from './FDSearch';
import { clearLeadNotes, folderAccessDeleteNotes, getNotes, UserActivity } from './folderViewReducer';
import FDNotes from './FDNotes';
import DeleteIcon from '@mui/icons-material/Delete';

import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { cleargetNotesleaddata, getNotesleaddata, getNotesleaddataCount } from '../../LeadManagement/LeadReducer';
import store from '../../../../store';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { useCookies } from 'react-cookie';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ManageJobtickets from '../../Jobtickets/ManageJobtickets/ManageJobtickets';
import JobticketAddEditFV from './JobticketAddEditFV';
import { clearjobcategorydata, geteditjobticketsdata, getjobcategorydata, getjobticketlistdata } from '../../Jobtickets/JobTicketReducer';



const Jobticket = (props) => {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch()
  const { id } = useParams();
  const [cookieData, setCookieData] = useCookies();
  const [queryParamId, setQueryParamId] = useState();
  const [jobticketModal, setJobticketModal] = useState(false); // setJobticketModal state
  const [snackOpen, setSnackOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // console.warn("<<<<<<<<< queryParamId from NotesView component >>>>>>", queryParamId)

  // console.warn("cookieData here >>>>>>>>>>////", cookieData)

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const loading = useSelector(state => (state?.JobTicketReducer?.loading) ? state.JobTicketReducer.loading : false)


  const tbaleRowData = useSelector(state => (state?.tableSlice?.tempRowData?.jobtickettable) ? state.tableSlice.tempRowData.jobtickettable : {})


  const editData = useSelector(state => (state?.JobTicketReducer?.editjobticketsdata) ? state.JobTicketReducer.editjobticketsdata : [])

  const editBtnClick = useSelector((state) => state.tableSlice?.customButtonClicked?.jobtickettable?.editdata ? state.tableSlice.customButtonClicked.jobtickettable.editdata : false)


  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.manageJobAddEditForm) ? state.formSlice.formSubmissionState.manageJobAddEditForm : 0)


  const jobtktcategorydata = useSelector((state) => (state?.JobTicketReducer?.jobcategorydata) ? state.JobTicketReducer.jobcategorydata : [])

  // ----------------------------------------Components Private State---------------------------------------- //




  console.warn(`jobticketModal=====>>>>>> modalOpen=====>>>>>>`, jobticketModal, modalOpen)

  useEffect(() => {
    dispatch(clearjobcategorydata())
    setModalOpen(false)
  }, [])



  // ------ on addjobTktButton Click getjobcategorydata call done --------
  useEffect(() => {
    if (modalOpen) {


      let reqbodyforjobcategory = {
        "condition": {
          "limit": 100,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": { $or: [{ status: 1 }] },
        "project": {},
        "token": ""
      }

      dispatch(getjobcategorydata(reqbodyforjobcategory))

    }
  }, [modalOpen])



  // ----------------------------------------------- //

  useEffect(() => {
    if (editBtnClick && jobtktcategorydata && jobtktcategorydata.length > 0) {
      setModalOpen(true)
    }
  }, [jobtktcategorydata])





  // ------- on sucessfull jobTKTAdd refresh jobtktlist ----- //
  useEffect(() => {
    if (submissionState === 2) {
      let reqbody = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "createdon_datetime",
          "type": "desc"
        },
        "searchcondition": {
          "ticket_addedby": props.source === "folderView" ? props.paramId : undefined
        },
        "project": {},
        "token": "",
        "count": false
      }

      store.dispatch(getjobticketlistdata(reqbody));

    }
  }, [submissionState])

  // -------------------------------------------------- //




  // --------- on Edit Btn Click ----------- // 

  useEffect(() => {
    if (editBtnClick) {



      let reqbodyforjobcategory = {
        "condition": {
          "limit": 100,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": { $or: [{ status: 1 }] },
        "project": {},
        "token": ""
      }

      dispatch(getjobcategorydata(reqbodyforjobcategory))

      dispatch(geteditjobticketsdata(tbaleRowData._id))


    }
  }, [editBtnClick])

  //  ------------------------------------ //


  useEffect(() => {
    if (editData && Object.keys(editData)?.length > 0) {
      setModalOpen(true)
    }
  }, [editData])




  return (
    <>
      <div>
        <h3> <FeedbackIcon /> Job Ticket Details
          <span className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Add JobTicket"><IconButton onClick={() => setModalOpen(true)}><AddIcon /></IconButton></Tooltip>
          </span>
        </h3>


        {/* ----- Loader here ----- */}
        {loading ? <Box sx={{ width: "100%", margin: "10px" }}><LinearProgress /></Box> : ''}
        {/* ----------------------- */}


        {/* -------- Jobticket Listing ----- */}
        <ManageJobtickets
          source="folderView"
          userid={props.paramId} />
        {/* --------------------------------- */}


      </div>




      {/* ----- Jobticket AddEdit Form Here ----- */}
      {modalOpen && <JobticketAddEditFV
        toogle={modalOpen}
        source="folderView"
        paramId={props.paramId}
        modalclose={setModalOpen}
      />
      }
      {/* -------------------------------------- */}



    </>
  )
}

export default Jobticket