import { Close } from '@mui/icons-material';
import { Alert, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StateArray from '../../../../assets/json/state';
import { Form } from '../../../../form/Form';
import { resolvefunction } from '../../../../helper/helperFunctions';
import store from '../../../../store';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { addlandingpageonedata, clearaddedinfo, getAffiliateManageData, getlandingpagedata, updateStages } from '../LandingpageReducer';

function LandingPage16Form(props) {

  console.log("props+++++++++++++", props)

  const { _id } = useParams(); //--- Fetch PathParams from URL ---
  const [loading, setloading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [snackStatusSeverity, setSnackStatusSeverity] = useState('success')

  const dispatch = useDispatch()
  const [locationArrys, setLocationArrys] = useState([]);

  let reqbodyforjobcategory = { "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG, }
  console.log("reqbodyforjobcategory===>>>", reqbodyforjobcategory);

  // --- setting state array 
  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      console.log("Newarr+", Newarr);
      setLocationArrys(Newarr);
    }
  }, []);

  const affiliatePrePopulateData = useSelector((state) => (state.LandingpagefontReducer?.affiliatedata && Object.keys(state.LandingpagefontReducer.affiliatedata).length > 0) ? state.LandingpagefontReducer.affiliatedata : {})



  const formInputData = useSelector((state) =>
    state.formSlice.formData.userForm16 ? state.formSlice.formData.userForm16 : null
  );

  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userForm16) ? state.formSlice?.formSubmissionState?.userForm16 : 0)

  const dataaddedinfo = useSelector((state) => state.LandingpagefontReducer?.addedinfo ? state.LandingpagefontReducer.addedinfo : null)

  useEffect(() => {
    console.log("dataaddedinfo====>>>", dataaddedinfo)
  }, [])

  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});


  useEffect(() => {

    store.dispatch(getlandingpagedata(reqbodyforjobcategory));

  }, [])

  // useEffect(()=>{

  //   if(_id != null && _id != undefined){
  //     dispatch(getAffiliateManageData(_id))
  //   }

  // },[])








  const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val }
    let body = {
      "firstname": val.firstname,
      "lastname": val.lastname,
      // "associated_program": val.associated_program,
      "email": val.email,
      "phone": val.phone,
      "address": val.address,
      "state": val.state,
      "city": val.city,
      "zip": val.zip,
      "password": val.password,
      "confirm_password": val.confirmPassword,
      "affiliate_signup_stage": 2,

      // "time_zone": val.time_zone,
      // "dnd_flag": val.dnd_flag,
      // "email_flag": val.email_flag,
      // "phone_flag": val.phone_flag,
      // "voice_mail_flag": val.voice_mail_flag,
      // "other": val.other,
      // "profile_logo": val.profile_logo,
      // "facilitytype": val.facility_type,
      // "other": val.other,
      // "status": val.status,
      //   "approxgrossreciept": val.approx_gross_reciepts,
      "createdon_datetime": new Date().getTime(),
      // "added_type": 'manually',
      // "added_by": user_id,
      // "rep_id": user_data.main_role == 'is_rep' ? user_id : val.rep_id,
      "_id": (_id !== undefined && _id !== null) ? _id : undefined,
      // "calender_access_form_submit_time": 2,
      //   "rep_id": user_data.main_role == 'is_rep' ? user_id : undefined,
      //   "added_type": 'landingpage',
      "page_id": userData.landing_page_id,
      "page_slug": userData.landing_page_slug,
      // "ipinfo": logInIpInfo,

      //   "other_info":val.other_info
    }

    if (Object.keys(body).length > 0) {
      console.log("body-----", body);
      // return
      // setLoader(true)
      setloading(true)

      let dataresponse = await store.dispatch(addlandingpageonedata(body));
      console.log("dataresponse", dataresponse);
      setloading(false)
      if (dataresponse && dataresponse.payload && dataresponse.payload.status == 'error') {
        console.log("HITTTTT", dataresponse.payload.message);
        setSnackMessage(dataresponse.payload?.message)
        setSnackStatusSeverity('error')
        setTimeout(() => { setOpenSnack(true) }, 1000)
        // // if (landingPageSubmissionState && landingPageSubmissionState === 2) {
        // // setSnackopen(true)
        // setMsg(dataresponse.payload.message)
        // // dispatch(setSnackbar({ open: true, message: "jiiii", status: 'error' }))}
      }
      else {
        // console.log("Responseeeeeeeeeeeeeeeee");
        redirect(_id)
        // console.log("dataaddedinfo",dataaddedinfo);


      }


    }
  };

  const redirect = (id) => {
    // console.log("Res+++++++++++++++++++++++++++++");
    // dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))
    setSnackMessage("Thank you for submitting!")
    setOpenSnack(true)

    const data = {
      stage_slug: '5QH6nS8FXNawY',
      added_by: 'System',
      added_for: id ? id : dataaddedinfo._id,
      is_delete: 0,
      status: 1
    }
    console.log("data===========================>", data)
    // return
    dispatch(updateStages(data));
    dispatch(clearaddedinfo())
    setTimeout(() => {
      dispatch(getAffiliateManageData(_id))
    }, 1000)



  }

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);





  const formData16 = {
    id: 'userForm16',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      _id: (_id !== undefined && _id !== null) ? _id : undefined

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "firstname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.firstname !== undefined) ? affiliatePrePopulateData.firstname : undefined,

      },
      {
        id: 15,
        heading: "Your Last Name",
        label: "Last Name",
        name: "lastname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.lastname !== undefined) ? affiliatePrePopulateData.lastname : undefined,
      },
      {
        id: 13,
        heading: "Your Email",
        label: "Your Email",
        name: "email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        disabled: (affiliatePrePopulateData && affiliatePrePopulateData.email !== undefined) ? true : false,
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.email !== undefined) ? affiliatePrePopulateData.email : undefined,
      },

      {
        id: 14,
        heading: "Your Phone Number",
        label: "Your Phone Number",
        name: "phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.phone !== undefined) ? affiliatePrePopulateData.phone : undefined,
      },
      // {
      //   id: 26,
      //   heading: "SELECT HM ",
      //   label: "SELECT HM ",
      //   name: "select_hm",
      //   className: 'inputBlock usertypebox inputBlock4line',
      //   type: "select",
      //   other: true,
      //   values: [],
      //   // defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',

      //   // inputType: "number",/////////////// If not mentioned default will be text //////////////
      //   // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },
      {
        id: 25,
        heading: "Address",
        placeholder: 'Address',
        label: "Address",
        name: "address",
        className: 'formfild textareafild',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          // custom: "Value is Invalid"
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.address !== undefined) ? affiliatePrePopulateData.address : undefined,


      },
      {
        id: 6,
        heading: "State",
        label: "State",
        name: "state",
        className: 'list_search_item formfild6',
        type: "select",
        // other: true,
        values: locationArrys,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.state !== undefined) ? affiliatePrePopulateData.state : undefined,
      },

      {
        id: 7,
        heading: "City",
        label: "City",
        name: "city",
        className: 'inputBlock formfild6',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.city !== undefined) ? affiliatePrePopulateData.city : undefined,


        // inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   // custom: "Value is Invalid"
        // },

      },

      {
        id: 8,
        heading: "Zip Code",
        label: "Zip Code",
        autoComplete: "off",
        name: "zip",
        className: 'inputBlock formfild6',
        type: "text",
        // inputType:"number",
        inputType: "zip",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.zip !== undefined) ? affiliatePrePopulateData.zip : undefined,


        // inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   // custom: "Value is Invalid"
        // },

      },

      {
        id: 11,
        heading: "Password",
        label: "Password",
        name: "password",
        className: 'inputBlock formfild6 passwordinput',
        type: 'password',
        rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },

        errorMessage: {
          required: "This Field is Required",
          // errorMessageConfirmPassword: "Password does not match",         
          custom: "Value is Invalid",
          validate: "Password does not match",
          maxLength: "Maximum allowed input length is 15 characters",

          pattern: "You need to provide a strong password",
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.password !== undefined) ? affiliatePrePopulateData.password : undefined,

      },

      {
        id: 12,
        heading: "Confirm Password",
        label: "Confirm Password",
        name: "confirmPassword",
        className: 'inputBlock formfild6 passwordinput',
        type: 'confirm_password',
        defaultValue: '',
        rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
        errorMessage: {
          required: "This Field is Required",
          validate: "Password does not match",
          errorMessageConfirmPassword: "Password does not match",
          maxLength: "Maximum allowed input length is 15 characters",

          pattern: "You need to provide a strong password",
          custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
        },
        defaultValue: (affiliatePrePopulateData && affiliatePrePopulateData.confirmPassword !== undefined) ? affiliatePrePopulateData.confirmPassword : undefined,
      },
      {
        id: 22,
        type: "html",
        className: "passwordCls inputBlock1line ",
        value: "<p>* Password should contain at least 8 characters and max 15 characters with one numeric digit, one uppercase letter, one lowercase letter, and a special character.</p>"
      },
      // {
      //   id: 20,
      //   heading: "Any other information we should know?",
      //   placeholder: 'Any other information we should know?',
      //   label: "Any other information we should know?",
      //   name: "other_info",
      //   className: 'formfild textareafild',
      //   type: "textarea",
      //   // rules: { required: true },
      //   // errorMessage: {
      //   //   required: "This Field is Required",
      //   //   custom: "Value is Invalid"
      //   // }

      // },
      {
        id: 125,
        // heading: "Captcha",
        // label: "Captcha",
        name: "captcha",
        className: 'formfild formfild4',
        type: "captcha",
        rules: { required: true },
        errorMessage: {
          required: "Prove, You're Not a Robot",
          custom: "Value is Invalid"
        },
      },


    ]

  };

  const handleClose = (event, reason) => {
    setOpenSnack(false);
  };
  return (
    <div>
      {/* <p>{JSON.stringify(affiliatePrePopulateData)}</p> */}
      {_id !== undefined && affiliatePrePopulateData && (affiliatePrePopulateData.length > 0 || Object.keys(affiliatePrePopulateData).length > 0) && <Form formData={formData16} />}
      {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}

      <Snackbar
        className="form_error_snackbar"
        open={openSnack}
        autoHideDuration={10000}
        onClose={() => handleClose()}
        // message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => handleClose()}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        {/* <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClose}> */}
        <Alert onClose={handleClose} severity={snackStatusSeverity} > {snackMessage}</Alert>
      </Snackbar>
      {/* <Snackbar
        className="form_error_snackbar"
        open={snackopen}
        autoHideDuration={6000}
        onClose={() => setSnackopen(false)}
        // message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() =>
              setSnackopen(false)
            }
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={() => setSnackopen(false)} severity={'error'} >{msg} </Alert>

      </Snackbar> */}
    </div>
  )
}

export default LandingPage16Form