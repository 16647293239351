import React, { useState, useEffect } from "react";
import {
    Link,
    NavLink,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Cookies, useCookies } from "react-cookie";
import {
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Snackbar,
    Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { inputExternalPlugin, resolvefunction } from "../../helper/helperFunctions";
import { getroledata } from "../Backend/Role/RoleReducer";
import { logOut, setloginRoute, userLogout } from "../login/loginReducer";
import { Lessonlist, Traininglist } from "../Backend/Training/TrainingReducer";
import { getLandingListData } from "../Backend/LandingPage/LandingPageReducer";
import { trainingCenterCategoryFetching } from "../Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer";
import {
    getContractListData,
    getContractSlagData,
} from "../Backend/ContractManagement/ContractReducer";
import { getprogramdata } from "../Backend/Program/ProgramReducer";
import { getleaddata } from "../Backend/LeadManagement/LeadReducer";
import { fetchQuizList, fetchTrainingMaterial, TrainingMaterialCenterlist } from "../Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { getusersList } from "../Backend/userManagement/userList/userListReducer";
import { getCampaignListData } from "../CampaignManagement/CampaignReducer";
import fetchUserDataReducer from "../Backend/userManagement/fetchUserDataReducer";
import { MdConfirmationNumber, MdDashboard, MdInventory2, MdKeyboardArrowDown, MdLeaderboard, MdLocalLibrary, MdManageAccounts, MdMiscellaneousServices, MdModelTraining, MdOutlineAssignmentTurnedIn, MdOutlineLink, MdOutlineModelTraining, MdPerson } from "react-icons/md";
import { VscSymbolMisc } from "react-icons/vsc";
import { AiOutlineForm } from "react-icons/ai";

import { getjobcategorydata, clearjobcategorydata, getjobticketlistdata } from '../Backend/Jobtickets/JobTicketReducer';
import { fetchEventsData, fetchEventsDataNew } from "../Calendar/calenderReducer";
import FieldList from "../Backend/FormManagement/FieldModule/FieldList";
import FormList from "../Backend/FormManagement/FormModule/FormList"
import { fieldList, getformdata, groupList } from "../Backend/FormManagement/FormManagementReducer";
import { getTagListdata } from "../Backend/TagManagement/TagReducer";
import { getEmailSlagData, getEmailTeamplatedata } from "../Backend/EmailTemplateManagement/EmailTemplateReducer";
import { stageEditData, stageListData } from "../Backend/StageDistribution/StageReducer";
import { getHmGridData } from "../Backend/HmDashboard/HmDashboardReducer";
import { getRoundRobinData } from "../Backend/ManageRoundRobin/ManageRoundRobinReducer";
import { gettriggerdata } from "../Backend/EmailTemplateManagement/TriggerReducer";


function HmMenu({ topmenu }) {
    // console.log("Props topmenu -------->", topmenu)
    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const [childMenuActive, setChildMenuActive] = useState(false);
    // console.warn("<<<<<< getcookies from BddMenu component  >>>>>>", getcookies)

    const location = useLocation();
    const { pathname } = location;
    const { id } = useParams();
    const splitLocation = pathname.split("/");
    let userType = useSelector(state => (state.loginSlice.userInfo?.user_types) ? state.loginSlice.userInfo?.user_types[0]?.slug : null);

    // console.log("splitLocation", splitLocation, pathname);

    // ----------------------------------------Components Private State---------------------------------------- //
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const [anchorEl8, setAnchorEl8] = useState(null);
    const [anchorEl9, setAnchorEl9] = useState(null);
    const [eventBodyList, setEventBodyList] = useState(
        {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "start_date_unix",
                "type": "desc"
            },
            "searchcondition": {
                // "userid": getcookies.userinfo?._id,
                "userid": "6423e01fb32dceb05c4e1682",

                // "start_date_unix": {
                //     "$gte": dayAfterTomorrow
                // },
                // "end_date_unix": {
                //     "$lte": 1675146756000
                // }
            },
            count: false
        }
    );

    // ---------------------------------------------Other Handelers--------------------------------------------- //
    const handleClick4 = (event) => setAnchorEl4(event.currentTarget);
    const open4 = Boolean(anchorEl4);
    const handleClose4 = () => setAnchorEl4(null);
    const handleClick5 = (event) => {
        console.log("event handleClick5", event)
        setAnchorEl5(event.currentTarget);
        setChildMenuActive(true)
    }
    const open5 = Boolean(anchorEl5);
    const handleClose5 = () => {
        setAnchorEl5(null);

        setChildMenuActive(false)
    }
    const handleClick6 = (event) => setAnchorEl6(event.currentTarget);
    const open6 = Boolean(anchorEl6);
    const handleClose6 = () => setAnchorEl6(null);
    const handleClick7 = (event) => {
        setAnchorEl7(event.currentTarget);
        setChildMenuActive(true)
    }
    const open7 = Boolean(anchorEl7);
    const handleClose7 = () => {
        setAnchorEl7(null);
        setChildMenuActive(false)
    }
    const handleClick8 = (event) => {
        setAnchorEl8(event.currentTarget);
        setChildMenuActive(true)
    }
    const open8 = Boolean(anchorEl8);
    const handleClose8 = () => {
        setAnchorEl8(null);
        setChildMenuActive(false)
    }
    const handleClick9 = (event) => setAnchorEl9(event.currentTarget);
    const open9 = Boolean(anchorEl9);
    const handleClose9 = () => setAnchorEl9(null);

    // const [selectMenu, setselectMenu] = React.useState(null);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [anchorE2, setAnchorE2] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const [isActive, setIsActive] = useState(false);
    // const handleClick = (val, event) => {
    //     setIsActive((current) => !current);
    //     setselectMenu(val);
    // };

    // const [isHidedropdown, setHidedropdown] = useState(false);
    // const hidedropdown = (event) => {
    //     setHidedropdown((current) => !current);
    //     setIsActive(false);
    // };

    // const handleToggle = (temp) => {
    //     if (temp == "HeaderMenuToggle") setIsActive(!isActive);
    // };
    // const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);

    // let logoutSucess = useSelector((state) =>
    //     state.loginSlice?.message !== null &&
    //         state.loginSlice?.message === "Logout successful"
    //         ? true
    //         : false
    // );

    // const clickedLogout = () => {
    //     console.warn("<<<<< inside clicked logut");

    //     let data = {
    //         _id: userInfo?.userinfo?._id,
    //     };

    //     dispatch(logOut());

    //     // setLogoutClick(true);
    //     setAnchorEl(null);

    //     cookies.remove("userinfo", { path: "/" });
    //     cookies.remove("lastLoginTime", { path: "/" });
    //     cookies.remove("token", { path: "/" });
    //     setIsActive(false);

    //     navigate("/login");

    //     // dispatch(logOut());
    //     dispatch(userLogout(data));
    //     // dispatch(setloginRoute(false));
    // };

    // const [anchorEl3, setAnchorEl3] = React.useState(null);

    // const handleClose3 = () => {
    //     setAnchorEl3(null);
    // };

    // const handleClose = () => {
    //     // console.log('before close ..', open, open1, anchorEl, selectMenu)
    //     setAnchorEl(null);
    //     setAnchorE2(null);
    // };

    // const open3 = Boolean(anchorEl3);
    // const handleClick3 = (event) => {
    //     setAnchorEl3(event.currentTarget);
    // };

    // let resolveloader = useSelector((state) =>
    //     state.ResolveReducer?.loader !== null &&
    //         state.ResolveReducer?.loader !== undefined
    //         ? state.ResolveReducer.loader
    //         : false
    // );

    let reqbodyforRole = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "updatedon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };
    let reqbodyforprogram = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "updatedon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };
    let reqbodyforjobcategory = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "createdon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };
    let reqbodyforgetlead = {
        condition: {
            limit: 30,
            skip: 0,
        },
        sort: {
            field: "createdon_datetime",
            type: "desc",
        },
        searchcondition: userInfo?.userinfo?.main_role === 'hr_manager'? { 'hiring_manager': userInfo?.userinfo?._id }: userInfo?.userinfo?.main_role === 'is_aer'? { 'aer_id': userInfo?.userinfo?._id} :{},

        project: {},
        token: "",
    };
    let userslug = useSelector(state => (state.loginSlice?.user_slug && state.loginSlice?.user_slug.length > 0) ? state.loginSlice.user_slug : [])

    let reqbodyformeterilcenter = {
      "roles": userslug,
      "searchcondition":{},
    }

    const dashboardLoadFunc = async () => {
        await inputExternalPlugin("dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/dashboard');
            document.location.reload(); 
        }, 1500)
    }



    const hmdashboardLoadFunc = async () => {
        await inputExternalPlugin("hm-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/hm-dashboard');
            document.location.reload();
        }, 1500)
    }
    const aerdashboardLoadFunc = async () => {
        await inputExternalPlugin("ae-aee-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/ae-aee-dashboard');
            document.location.reload();
        }, 1500)
    }
    const repdashboardLoadFunc = async () => {
        await inputExternalPlugin("ae-aee-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/ae-aee-dashboard');
            document.location.reload();
        }, 1500)
    }

    const trainingCenterFUnc = async () => {
        await inputExternalPlugin("training-center", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/elements.js");
        resolvefunction(navigate, "/training-center", [
            trainingCenterCategoryFetching()
        ]);
        setTimeout(() => {
            document.location.reload();
        }, 2000)
    }

    //----------------------------------------------return-----------------------------------------------//
    return (
        <div>
            <ul>
                {/* Dashboard */}

               


               


                    <li>
                        <button onClick={() => { hmdashboardLoadFunc() }} className={(pathname && pathname == '/hm-dashboard') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Dashboard</span>
                        </button>
                    </li>



                

               




                {/* Users */}
                {/* {(getcookies?.userinfo?.roles?.is_admin == 1 || getcookies?.userinfo?.roles?.is_aer == 1 || getcookies?.userinfo?.roles?.hr_manager == 1 || getcookies?.userinfo?.roles?.is_bdd == 1) ? ( */}
                
                <li>
                    <button
                        onClick={() => resolvefunction(navigate, "/usermanagement", getusersList(
                           {

                                "condition": {
                                  "limit": 30,
                                  "skip": 0
                                },
                                "sort": {
                                  "field": "createdon_datetime",
                                  "type": "desc"
                                },
                                "searchcondition": userInfo?.userinfo?.main_role === 'hr_manager'? { 'hiring_manager': userInfo?.userinfo?._id , '$or':[{'roles.is_rep': 1}, {'roles.is_aer': 1}]} : {},
                          
                                // "parent_id": userInfo?.userinfo?.roles?.is_admin !== 1 ? userInfo?.userinfo?._id : undefined 
                          
                          
                          
                                // "project": {
                                //     "name": 1,
                                //     "email": 1,
                                //     "phone": 1,
                                //     "tag_names": 1,
                                //     "address": 1,
                                //     "city": 1,
                                //     "training_percentage_html": 1,
                                //     "roles_string": 1,
                                //     "status": 1,
                                //     "rr_btn": 1,
                                //     "mhm_btn": 1,
                                //     "last_login_time": 1,
                                //     "createdon_datetime":1,
                          
                                // },
                                "count": false,
                                // "token": usersToken
                          
                          
                              }
                        ))}
                        className={pathname && pathname == "/usermanagement" ? "active" : ""}
                    >
                        <MdPerson />
                        <span>Users</span>
                    </button>
                </li>
                
                {/* // ) : ""} */}

              
                <li>
                    <button
                        onClick={() => resolvefunction(navigate, "/lead-list", getleaddata(reqbodyforgetlead))}
                        className={
                            pathname &&
                                (pathname == "/lead-list" ||
                                    pathname == `/lead-folder-view/${id}` ||
                                    pathname == `/lead-edit/${id}` ||
                                    pathname == "/lead-add")
                                ? "active"
                                : ""
                        }
                    >
                        <MdLeaderboard />
                        <span>Lead</span>
                    </button>
                </li>
 {/* *********************Drop Down for Job Ticket********************* */}
 <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open6 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open6 ? "true" : undefined}
                        onClick={handleClick6}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={(pathname == "/jobticket-category" || pathname == "/manage-jobtickets" || pathname == "/add-jobtickets-category") ? "active" : "inactive"}

                    >

                        <MdConfirmationNumber />
                        <span>Job Ticket</span>

                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>


                </li>
                {/* Job Ticket Menus */}
                <li>
                    <Menu
                        // className="addVid_dropDwn"
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl6}
                        open={open6}
                        onClose={handleClose6}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                       
                        {/* <MenuItem
                            className={
                                splitLocation[1] === "jobticket-category"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/jobticket-category", [
                                    getjobcategorydata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleClose6}> Job Ticket Category</span>
                        </MenuItem> */}
                        <MenuItem
                            className={
                                splitLocation[1] === "manage-jobtickets"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/manage-jobtickets", [
                                    getjobticketlistdata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleClose6}>Manage Job Tickets</span>
                        </MenuItem>
                    </Menu>
                </li>
                {/* Training */}
                <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open4 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open4 ? "true" : undefined}
                        onClick={handleClick4}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={
                            pathname == "/training-category/list" ||
                                pathname == "/training-center" ||
                                pathname == "/training-lesson/list" ||
                                pathname === "/training-material" ||
                                pathname === "/quizzes" ||
                                pathname === "/quiz-add" ||
                                pathname === "/quiz-edit"
                                ? "active"
                                : "inactive"
                        }
                    >


                        <MdOutlineModelTraining />
                        <span>Training</span>
                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>

                </li>

                <li>
                    <Menu
                        // className="addVid_dropDwn"
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}

                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl4}
                        open={open4}
                        onClose={handleClose4}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                      
                        <MenuItem
                            onClick={() => {
                                handleClose4();
                                trainingCenterFUnc();


                            }}
                        >
                            <span>Training Center</span>
                        </MenuItem>
                     

                        <MenuItem
                            className={
                                splitLocation[1] === "training-material-centar-list"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(
                                    navigate,
                                    "/training-material-centar-list",
                                    TrainingMaterialCenterlist(reqbodyformeterilcenter)
                                );
                            }}
                        >
                            <span> Training Material Centar </span>
                        </MenuItem>

                    </Menu>
                </li>

                {/* *********************Drop Down for Job Ticket********************* */}
               
                {/* Job Ticket Menus */}
             

                {/* Calendar Availability */}
                <li>
                    <button
                        onClick={() => {
                            resolvefunction(
                                navigate,
                                "/avilibility-list",
                                fetchEventsDataNew(eventBodyList)
                            );
                        }}
                        className={
                            splitLocation[1] === "avilibility-list" || splitLocation[1] === "available-slots" || splitLocation[1] === "create-event" || splitLocation[1] === "booked-slot" ? "active" : ""
                        }
                    >
                        <MdLocalLibrary />
                        <span>Calendar Availability</span>
                    </button>
                </li>


            </ul>
        </div>
    )
}

export default HmMenu