import React, { useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Cookies, useCookies } from "react-cookie";
import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Snackbar,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { resolvefunction } from "../../helper/helperFunctions";
import { getroledata } from "../Backend/Role/RoleReducer";
import { logOut, resetlogedMeInFlag, setGoBackLoader, setLogedinUserInfo, setlogedMeInFlag, setloginRoute, setUserSlugBlank, userLogout } from "../login/loginReducer";
import { Lessonlist, Traininglist } from "../Backend/Training/TrainingReducer";
import { getLandingListData } from "../Backend/LandingPage/LandingPageReducer";
import { trainingCenterCategoryFetching } from "../Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer";
import {
  getContractListData,
  getContractSlagData,
} from "../Backend/ContractManagement/ContractReducer";
import { getprogramdata } from "../Backend/Program/ProgramReducer";
import { getleaddata } from "../Backend/LeadManagement/LeadReducer";
import { fetchQuizList, fetchTrainingMaterial } from "../Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { getusersList } from "../Backend/userManagement/userList/userListReducer";
import { getCampaignListData } from "../CampaignManagement/CampaignReducer";
import fetchUserDataReducer, { fetchUserData } from "../Backend/userManagement/fetchUserDataReducer";
import AdminMenu from "./AdminMenu";
import BddMenu from "./BddMenu";
import HmMenu from "./HmMenu";
import AerMenu from "./AerMenu";
import RepMenu from "./RepMenu";
import SuperAdminMenu from "./SuperAdminMenu";
import DeveloperMenu from "./DeveloperMenu";
import BlueCoastNew from "./BlueCoastNew";
import store from "../../store";
import CustomerSupportMenu from "./CustomerSupportMenu";
import AffiliateMenu from "./AffiliateMenu";
import SpecialEntityMenu from "./SpecialEntityMenu";

function BackendHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const getcookies = cookies.getAll();


  const [selectMenu, setselectMenu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [logoutMsg, setLogoutMsg] = React.useState(false);

  const open = Boolean(anchorEl);
  const [isActive, setIsActive] = useState(false);
  const handleClick = (val, event) => {
    setIsActive((current) => !current);
    setselectMenu(val);
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  // const [anchorEl6, setAnchorEl6] = React.useState(null);

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const open4 = Boolean(anchorEl4);
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const open5 = Boolean(anchorEl5);
  const handleClose5 = () => {
    setAnchorEl5(null);
  };
  // const handleClick6 = (event) => {
  //   setAnchorEl6(event.currentTarget);
  // };
  // const open6 = Boolean(anchorEl5);
  // const handleClose6 = () => {
  //   setAnchorEl6(null);
  // };

  const [isHidedropdown, setHidedropdown] = useState(false);
  const hidedropdown = (event) => {
    setHidedropdown((current) => !current);
    setIsActive(false);
  };

  const handleToggle = (temp) => {
    if (temp == "HeaderMenuToggle") setIsActive(!isActive);
  };
  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
  let userlogindata = useSelector(state => (state.loginSlice.userInfo) ? state.loginSlice.userInfo : null);

  // console.warn("userlogindata ====>>>>", userlogindata)

  let gobackLoad = useSelector((state) => state.loginSlice?.gobackLoad? state.loginSlice.gobackLoad: false)

  let logoutSucess = useSelector((state) =>
    state.loginSlice?.message !== null &&
      state.loginSlice?.message === "Logout successful"
      ? true
      : false
  );

  const clickedLogout = () => {
    // console.warn("<<<<< inside clicked logut", userInfo);
    // return
    setLogoutMsg(true);


    let data = {
      _id: userInfo?.userinfo?._id,
    };

    dispatch(userLogout(data));


    // setLogoutClick(true);
    setAnchorEl(null);

    setTimeout(() => {
      dispatch(logOut());
      cookies.remove("userinfo", { path: "/" });
      cookies.remove("lastLoginTime", { path: "/" });
      cookies.remove("token", { path: "/" });
      cookies.remove("org_userinfo", { path: "/" });
      setIsActive(false);
      dispatch(setUserSlugBlank());

      navigate("/login");

    }, 2000);



    // dispatch(logOut());
    // dispatch(setloginRoute(false));

  };

const goBackInYourPage = async ()=>{

  store.dispatch(setGoBackLoader());

  


  await cookies.remove("userinfo", { path: "/" });
  await cookies.remove("lastLoginTime", { path: "/" });




   let userType = getcookies.org_userinfo.userinfo.main_role;
      let otherrole = getcookies.org_userinfo.userinfo.other_role;
      let userData = {
        userinfo: {
          ...getcookies.org_userinfo.userinfo,
          main_role: userType,
          other_role: otherrole,
        },
        token: getcookies.org_userinfo.token,
        userType: userType,
        lastLoginTime:getcookies.org_userinfo.userinfo?.last_login_time
    
      };
   
    
      store.dispatch(setLogedinUserInfo(userData));




  setTimeout(() => {

      cookies.set("userinfo", getcookies.org_userinfo.userinfo, { path: "/" });
      cookies.set("token", getcookies.org_userinfo.token, { path: "/" });
      cookies.set("lastLoginTime", getcookies.org_userinfo.lastLoginTime, { path: "/" });

  }, 10);

 



  setTimeout(() => {
    let userRole= store.getState().loginSlice.userInfo.main_role
    let rep_with_new_dashboard= store.getState().loginSlice.userInfo.rep_with_new_dashboard
   
    switch (userRole) {

      case "is_admin":
        // navigate("/dashboard", { replace: true });
        window.location.href = "/dashboard"
   

        break;

      case "hr_manager":
        // navigate("/hm-dashboard", { replace: true });
        window.location.href = "/hm-dashboard"
   


        break;
        case "is_rep":
          {
            if (rep_with_new_dashboard) {
              // resolvefunction(navigate, '/new-ae-dashboard', getlandingpageForRep(reqLandingPagebody))
              window.location.href = "/new-ae-dashboard";
            } else {
              // resolvefunction(navigate, '/ae-dashboard', getDataForRepDashboardCard(reqcardBodyFordashboard))
              window.location.href = "/ae-dashboard";
            }
          }
          break;
        break;
      case "is_aer":
        // navigate("/ae-dashboard", { replace: true });
        window.location.href = "/aee-dashboard"
   


        break;
      case "is_bdd":
        // navigate("/ae-dashboard", { replace: true });
        window.location.href = "/bdd-dashboard"
    


        break;
        case "customer_support":
          // navigate("/ae-dashboard", { replace: true });
          window.location.href = "/usermanagement"
          break;


      // default:
      //   resolvefunction(navigate, '/dashboard', getDataFoAdminDashboardCard(reqBodyFordashboard))
    }
     cookies.remove("org_userinfo", { path: "/" })
  }, 2000);



}
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleClose = () => {
  
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  let resolveloader = useSelector((state) =>
    state.ResolveReducer?.loader !== null &&
      state.ResolveReducer?.loader !== undefined
      ? state.ResolveReducer.loader
      : false
  );

  let reqbodyforRole = {
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      field: "_id",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
  };
  let reqbodyforprogram = {
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      field: "_id",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
  };

  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();
  const splitLocation = pathname.split("/");

  return (
    <>
      {resolveloader && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}




      <div className="header_menu_block">
        <div className="headerLogo">
          {" "}
          <img src="https://allfrontend-assets.s3.amazonaws.com/Dealfirecrmlogo.png" alt=""/>
        </div>

        <div className="headerRight">
          <div className="admin_topRight">
            <div className="topuserblock">
              <div className="toggle_Container">
                <div className="toggle_button">
                  <button onClick={() => handleToggle("HeaderMenuToggle")}>
                    <MenuIcon />
                  </button>{" "}
                </div>
              </div>
              <div className="name_drop">
                <Button
                  id="demo-positioned-button"
                  aria-controls={open3 ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open3 ? "true" : undefined}
                  onClick={handleClick3}
                // endIcon={<KeyboardArrowDownIcon />}
                >
                  <PersonIcon className="PersonIcon" />
                </Button>
              </div>

              <Menu
                className="userDetail_dropDwn"
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <h1>
                  {" "}
                  {userInfo?.userinfo?.firstname && userInfo?.userinfo?.lastname
                    ? userInfo.userinfo.firstname +
                    " " +
                    userInfo.userinfo.lastname
                    : ""}
                </h1>

                <MenuItem onClick={handleClose}>
                  <NavLink
                    to="/profile"
                    end
                    onClick={() => {
                      resolvefunction(navigate, "/profile", fetchUserData({ "id": userInfo?.userinfo?._id }));
                      handleClose3();
                    }}
                  >
                    My account
                  </NavLink>
                </MenuItem>

                <MenuItem onClick={() => clickedLogout()}>Logout</MenuItem>
                {getcookies.org_userinfo &&
                
                <MenuItem className="returnToOwn"  onClick={() => goBackInYourPage()}>Return to Your Own Account</MenuItem>
                
                }
              </Menu>
            </div>
          </div>
          <div className={`header_centerblock ${isActive ? "showdiv" : ""}`}>

            {getcookies?.userinfo?.main_role == 'is_super_admin' || getcookies?.userinfo?.roles?.is_super_admin === 1  && <SuperAdminMenu topmenu={1} />}

            {userlogindata?.main_role =="is_super_admin" && <SuperAdminMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'special_entities'  && <SpecialEntityMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_developer' && <DeveloperMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_admin' && <AdminMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_bdd' && <BddMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'hr_manager' && <HmMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_aer' && <AerMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_rep' && !getcookies?.userinfo?.rep_with_new_dashboard && <RepMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'customer_support' && <CustomerSupportMenu topmenu={1}/>}
            {userlogindata?.main_role == 'is_rep' && userlogindata?.rep_with_new_dashboard == true && <BlueCoastNew topmenu={1} />}
            {userlogindata?.main_role == 'is_affiliate' && <AffiliateMenu topmenu={1} />}
            {userlogindata?.main_role == 'is_employer' && <BlueCoastNew topmenu={1} />}
            {userlogindata?.roles?.is_subEmployer === 1 && <BlueCoastNew topmenu={1} />}
            {userlogindata?.roles?.is_employee === 1 && <BlueCoastNew topmenu={1} />}



            {/* {userlogindata?.main_role == 'special_entities' && userlogindata?.rep_with_new_dashboard == true && <BlueCoastNew topmenu={1} />} */}



            {/* <AdminMenu topmenu={1} /> */}
          </div>
        </div>
      </div>

      {gobackLoad === true && <LinearProgress />}


      {logoutMsg ? (<Snackbar
        open={logoutMsg}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Logging out ...."
      // message={signUpMsg}
      // action={action}
      />) : " "}
    </>
  );
}

export default BackendHeader;
