import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";

export const fetchUserData = createAsyncThunk("fetch/editUser", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const fetchHmBddData = createAsyncThunk("fetch/fetchHmBddData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchHmBddData >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/bdd-hm-info", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// export const leadHmBddData = createAsyncThunk("fetch/leadHmBddData", async (reqBody) => {
//     // console.warn("<<<<<< inside fetchHmBddData >>>>", reqBody)
//     const reqOption = {
//         method: "POST",
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({_id:reqBody,project:true })
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/bdd-hm-info", reqOption);
//     const respdata = await response.json();
//     // console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;
// });


export const updateUserInfoAfterUpdateProfile = createAsyncThunk("update/profile-update-user", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



const fetchUserDataReducer = createSlice({
    name: 'fetchUserDataReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        fetchUserDataReducer: null,
        userDetailsInfo: {},
        userInfo: {},
        setCookie: null,
        HmBddData:null,
        // leadHmBddDataInfo:null,
        // leadHmBddDataStatus:null

    },
    reducers: {
        setInitData(state, action) {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.successMsg = null;
            state.errorMsg = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';
            state.fetchUserDataReducer = null;
            state.userInfo = {};
        },
        clearPreviewData(state, action) {
            state.fetchUserDataReducer = null
        },

        clearHmBddData(state,action){
            state.HmBddData = null
        },
        clearErr(state,action){
            state.errorMsg = null;
        }
        // clearleadHmBddDataInfo(state,action){
        //     state.leadHmBddDataInfo=[]
        // }
    },
    extraReducers: {


        [fetchUserData.pending]: (state, action) => {
            state.loading = true
            state.fetchUserDataReducer = null
            // state.userInfo ={}

        },
        [fetchUserData.fulfilled]: (state, action) => {
            console.log("dash action op", action)
            state.loading = false;
            state.success = true;
            state.fetchUserDataReducer = action.payload?.results?.res;
            // state.userInfo = action.payload?.results?.res;

        },
        [fetchUserData.rejected]: (state, action) => {
            state.loading = false
        },


        ///--------------------fetch hm bdd data-----------------
            
        [fetchHmBddData.pending]: (state, action) => {
            state.loading = true
            state.fetchHmBddDataReducer = null
            // state.userInfo ={}

        },
        [fetchHmBddData.fulfilled]: (state, action) => {
            console.log("dash action ophm", action.payload)
            if(action.payload?.status === "success"){
                if(action.payload?.results?.res[0]?.bdd_data.length === 0 && action.payload?.results?.res[0]?.hiring_manager_data === undefined){
                    state.errorMsg="Not HM/BDD assign"
                }else if(action.payload?.results?.res[0]?.bdd_data.length === 0){
                    state.errorMsg="Not BDD assign"
                }else if(action.payload?.results?.res[0]?.hiring_manager_data === undefined){
                    state.errorMsg="Not HM assign"
                }
                state.HmBddData = action.payload?.results?.res[0];
            }else (
                state.errorMsg="something went wrong"
            )
            state.loading = false;
            
            // state.userInfo = action.payload?.results?.res;

        },
        [fetchHmBddData.rejected]: (state, action) => {
            state.loading = false
        },


        //========================================================
      //leadHmBddData
       

    //    ///--------------------fetch hm bdd data-----------------
            
    //    [leadHmBddData.pending]: (state, action) => {
    //     state.loading = true
    //     state.leadHmBddDataReducer = null
    //     // state.userInfo ={}

    // },
    // [leadHmBddData.fulfilled]: (state, action) => {
       
    //     state.loading = false;
    //     state.leadHmBddDataInfo = action.payload?.results?.res;
    //     state.leadHmBddDataStatus = action.payload.status
    //     // state.userInfo = action.payload?.results?.res;
    //     console.log("dash actionleadHmBddData", state.leadHmBddDataStatus)
    // },
    // [leadHmBddData.rejected]: (state, action) => {
    //     state.loading = false
    // },


    // //========================================================


        [updateUserInfoAfterUpdateProfile.pending]: (state, action) => {
            state.loading = true
            state.updateCookieInfo = false
            state.userInfo = {};

        },
        [updateUserInfoAfterUpdateProfile.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            if (action.payload.status === "success") {

                state.loading = false;
                state.success = true;
                state.userInfo = action.payload?.results?.res;

                const cookie = new Cookies();
                console.warn("all cookies", cookie.getAll('userinfo'))
                const cookieData = cookie.getAll();

                const finalCookie = { ...cookieData }

                // ------------ Updating current cookie ----------- //
                finalCookie.userinfo.firstname = state.userInfo?.firstname
                finalCookie.userinfo.lastname = state.userInfo?.lastname
                finalCookie.userinfo.email = state.userInfo?.email
                finalCookie.userinfo.address = state.userInfo?.address
                finalCookie.userinfo.city = state.userInfo?.city
                finalCookie.userinfo.zip = state.userInfo?.zip
                finalCookie.userinfo.phone = state.userInfo?.phone
                finalCookie.userinfo.phone = state.userInfo?.phone
                finalCookie.userinfo.name = state.userInfo?.firstname + " " + state.userInfo?.lastname
                // -------------------------------------------------- // 

                // ----------- Setting Cookie ----------------- // 
                cookie.set('userinfo', { ...finalCookie.userinfo }, { path: "/" })
                //  -------------------------------------------- //

                state.updateCookieInfo = true


            }

        },
        [updateUserInfoAfterUpdateProfile.rejected]: (state, action) => {
            state.loading = false
            state.updateCookieInfo = false
            state.userInfo = {};

        },




    }
});



export default fetchUserDataReducer.reducer;
export const { setInitData, clearPreviewData,clearHmBddData,clearErr} = fetchUserDataReducer.actions;