import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../listing/listing';
import { Button, Drawer, IconButton, LinearProgress, Paper, TableContainer, Tooltip } from '@mui/material';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../../store';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { useLocation } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';
import { affiliateConversionReportList, affiliateFilterConversionReportList, resetAllListData, resetFilterData, resetInitData } from './AfffiliateConverisonReportReducer';
import AfffiliateConverisonReportModal from './AfffiliateConverisonReportModal';
import { clearPageState, setTableData, setTableDefaultreqBody, unmountTable } from '../../../../listing/listReducer';


const AfffiliateConverisonReport = () => {


    const Location = useLocation();
    const dispatch = useDispatch();

    // ----- all states here ---- //
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    //  -------------------------- //

    // ----- all states here ---- //
    const [toogleVal, setToogleVal] = useState('all')
    const [filterData, setFilterData] = useState(false)
    const [filterListData, setFilterListData] = useState([])
    const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
    const [amtToogleFlag, setAmtToogleFlag] = useState(true);
    const [openModal, setOpenModal] = useState(false)

    //  -------------------------- //



    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});


    const resolved = useSelector((state) =>
        state.ResolveReducer.resolved["/affiliate-converison-report"]
            ? state.ResolveReducer.resolved["/affiliate-converison-report"]
            : false
    );

    const listData = useSelector((state) => state.AfffiliateConverisonReport?.affiliateConversionReportListData && state.AfffiliateConverisonReport?.affiliateConversionReportListData.length > 0 ? state.AfffiliateConverisonReport.affiliateConversionReportListData : []);

    const listDataSuccess = useSelector((state) => state.AfffiliateConverisonReport?.conversionReportListDataSuccess && state.AfffiliateConverisonReport?.conversionReportListDataSuccess.length > 0 ? state.AfffiliateConverisonReport.conversionReportListDataSuccess : false);

    const loader = useSelector((state) => state.AfffiliateConverisonReport?.loading ? state.AfffiliateConverisonReport.loading : false);

    const detailedReport = useSelector((state) => state.tableSlice?.customButtonClicked?.AfffiliateConverisonReportList?.details_report ? state.tableSlice.customButtonClicked.AfffiliateConverisonReportList.details_report : false);



    const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.affiliate_conversion_Search ? state.formSlice.formSubmissionState.affiliate_conversion_Search : false);


    const plan_type = useSelector((state) => state.formSlice?.formData?.affiliate_conversion_Search?.plan_type ? state.formSlice.formData.affiliate_conversion_Search.plan_type : null);

    const start_date = useSelector((state) => state.formSlice?.formData?.affiliate_conversion_Search?.start_date ? state.formSlice.formData.affiliate_conversion_Search.start_date : null);


    const end_date = useSelector((state) => state.formSlice?.formData?.affiliate_conversion_Search?.end_date ? state.formSlice.formData.affiliate_conversion_Search.end_date : null);





    // ----- On DataRef on every btn trigger ---- //
    const dataRef = useSelector((state) =>
        state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.AfffiliateConverisonReportList
            ? state.tableSlice?.tempRowData?.AfffiliateConverisonReportList
            : {}
    );
    // ----------------------------------------- //




    // ================ setting time stamps ====================//
    const todaysStartDate = moment().startOf('day').valueOf()
    const todaysEndDate = moment().endOf('day').valueOf()


    const weekStartDate = moment().startOf('week').valueOf()
    const weekEndDate = moment().endOf('week').valueOf()


    const monthStartDate = moment().startOf('month').valueOf()
    const monthEndDate = moment().endOf('month').valueOf()
    // ======================================================== //








    //  ------- setting route path ---------- //
    var routeUrl = null
    useEffect(() => {
        if (Location?.pathname?.split('/').join('') === 'affiliate-report') {
            routeUrl = Location.pathname.split('/').join('')

            console.log("routeUrl===>", routeUrl);
        }
    }, [JSON.stringify(Location)])
    //  ---------------------------------- //



    //  ======= main table ======== //
    var modifyTableHeaders = [

        { val: "name", name: "Traffic Partner Name" },
        { val: "click_count", name: "Click Count" },
        { val: "conversion_count", name: "Conversion Count" },


    ]


    let tableDataForConversionReport = useRef({
        tableId: "AfffiliateConverisonReportList",
        tableTitle: "Traffic Partner Converison Report",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "campaign-manage/affiliate-conversion-report-list-count",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "campaign-manage/affiliate-conversion-report-list-count",
        },

        reqBody: {
            condition: {
                limit: 30,
                skip: 0,
            },
            sort: {
                field: "record_date",
                type: "desc",
            },
            searchcondition: { "rep_id": logedUserData._id },
            project: {},
            token: "",
            count: false,
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "record_date"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [


        ],
        buttons: [
            {
                id: "details_report",
                name: "poll_icon",
                type: "other",
                label: "Details Conversion report",

            },
        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

        searchData: {
            heading: "Search Commission Report",
            type: "drawer",
            //   condition_type: "$or",
            formData: {
                id: "commisionsearchForm",
                formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                submitBtnName: "Search",
                resetBtnName: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [


                    {
                        id: 30,
                        label: "Search by Plan Type",
                        name: "plan_type",
                        // className: 'Status',
                        type: 'select',
                        multiple: false,
                        values: [
                            { val: "single", name: "single" },
                            { val: "single+1", name: "Single+1" },
                            { val: "Family", name: "Family" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "plan_type" },
                    },

                    {
                        id: 31,
                        label: "Search by Recorded on Start Date",
                        name: "start_date",
                        className: "datePicker",
                        type: "datePicker",
                        sx: { m: 1, width: 300 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "record_date", param: "$gte" },
                    },
                    {
                        id: 4,
                        label: "Search by Recorded on End Date",
                        name: "end_date",
                        className: "list_search_item countDiv__column--col6 ",
                        type: "datePicker",
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "record_date", param: "$lte" },
                        // minDate:{minDate}
                        // rules: { required: true },
                        errorMessage: {
                            required: "This Field is Required",
                            custom: "End Date can not be less than Start Date"
                        },
                    },


                ],
            },
        },
    });
    // ========================= //




    // ============== entry point ======= //
    useEffect(() => {

        if (!resolved) {
            // setTimeout(() => {
            searchTable('all');
            // }, 200);
        }

        return () => {
            dispatch(resetInitData());

        };


    }, []);
    // ================================= //




    // ----- on detailed report btn click route to target blank ------ //
    useEffect(() => {
        if (detailedReport) {
            window.open("/affiliate-conversion-detailed-report/" + dataRef._id + "/" + dataRef.name, "_blank")
        }
    }, [detailedReport])

    // ----------------------------------------------------------- //




    // ============  on table filters click =========== //
    function searchTable(val) {
        setToogleVal(val);

        switch (val) {

            case 'all':
                dispatch(unmountTable({ "tableId": "AfffiliateConverisonReportList" }));
                setFilterData(false)
                setAmtToogleFlag(false)
                // dispatch(resetFilterData())

                let payloadAll = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "asc", "field": "createdon_datetime"
                    },
                    "searchcondition": { "rep_id": logedUserData._id }
                }

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "AfffiliateConverisonReportList", "reqBody": payloadAll }))


                dispatch(affiliateConversionReportList(payloadAll))

                setFilterListData([])


                // setTimeout(() => {
                //     payloadAll['count'] = true
                //     dispatch(affiliateConversionReportList(payloadAll))
                // }, 200);


                break;

            case 'this_month':
                dispatch(unmountTable({ "tableId": "AfffiliateConverisonReportList" }));
                setFilterData(true)
                // dispatch(resetAllListData())

                let payloadMonth = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "createdon_datetime"
                    },

                    "searchcondition": { "rep_id": logedUserData._id }
                }

                payloadMonth['searchcondition']['createdon_datetime'] = { $gte: monthStartDate, $lte: monthEndDate }

                dispatch(setTableDefaultreqBody({ "tableId": "AfffiliateConverisonReportList", "reqBody": payloadMonth }))

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

                dispatch(affiliateFilterConversionReportList(payloadMonth))

                setFilterListData([])

                // setTimeout(() => {
                //     payloadMonth['count'] = true
                //     dispatch(affiliateFilterConversionReportList(payloadMonth))
                // }, 200);


                break;

            case 'this_week':
                dispatch(unmountTable({ "tableId": "AfffiliateConverisonReportList" }));
                setFilterData(true)
                // dispatch(resetAllListData())


                let payloadWeek = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "createdon_datetime"
                    },

                    "searchcondition": { "rep_id": logedUserData._id }

                }

                payloadWeek['searchcondition']['createdon_datetime'] = { $gte: weekStartDate, $lte: weekEndDate }

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "AfffiliateConverisonReportList", "reqBody": payloadWeek }))

                dispatch(affiliateFilterConversionReportList(payloadWeek))

                setFilterListData([])

                dispatch(clearPageState({ "tableId": "AfffiliateConverisonReportList" }))

                // dispatch(clearTableCounts({ "tableId": "AfffiliateConverisonReportList" }))

                // setTimeout(() => {
                //     payloadWeek['count'] = true
                //     dispatch(affiliateFilterConversionReportList(payloadWeek))
                // }, 200);


                break;

            case 'today':
                dispatch(unmountTable({ "tableId": "AfffiliateConverisonReportList" }));
                setFilterData(true)
                // dispatch(resetAllListData())


                let payloadToday = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "createdon_datetime"
                    },

                    "searchcondition": { "rep_id": logedUserData._id }

                }

                payloadToday['searchcondition']['createdon_datetime'] = { $gte: todaysStartDate, $lte: todaysEndDate }

                tableDataForConversionReport.current['reqBody']['searchcondition'] = payloadToday['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "AfffiliateConverisonReportList", "reqBody": payloadToday }))


                dispatch(affiliateFilterConversionReportList(payloadToday))

                setFilterListData([])


                // setTimeout(() => {
                //     payloadToday['count'] = true
                //     dispatch(affiliateFilterConversionReportList(payloadToday))
                // }, 200);



                break;

            default:
            // code block
        }
    }
    //  ================================== //




    //----------------------search form configration---------------------
    const dataform = useRef({
        id: 'affiliate_conversion_Search',
        submitBtnName: "Search",
        submitBtnEndIcon: 'search_icon',
        resetForm: true,
        resetBtnName: "Refresh",
        resetBtnEndIcon: 'refresh',
        endPointBodyWrapper: "searchcondition",
        formButtonClass: "submitbtnsection",
        formWrapperClass: "formWrapperClass formCls",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {},

        fields: [

            {
                id: 0,
                label: "Search by Traffic Partner Name",
                name: "name",
                type: "autoComplete",
                className: "list_search_item countDiv__column--col6 ",
                base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
                reqBody: {
                    condition: {
                        limit: 10,
                        skip: 0,
                    },
                    sort: {
                        type: "asc",
                        field: "name",
                    },
                    searchcondition:
                        { "roles.is_affiliate": 1, "added_by": userInfo.userinfo._id }

                    ,
                    type: "user_name",
                    source: "users",
                },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "_id" },
            },

            {
                id: 31,
                label: "Search by Recorded on Start Date",
                name: "start_date",
                className: "datePicker",
                type: "datePicker",
                sx: { m: 1, width: 300 },
                className: "list_search_item countDiv__column--col6 ",
                payloadFormat: { key: "record_date", param: "$gte" },
            },
            {
                id: 4,
                label: "Search by Recorded on End Date",
                name: "end_date",
                className: "list_search_item countDiv__column--col6 ",
                type: "datePicker",
                fullDay: true,
                sx: { m: 1, width: 300 },
                payloadFormat: { key: "record_date", param: "$lte" },
                // minDate:{minDate}
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "End Date can not be less than Start Date"
                },
            },


        ],
    })

    // ================================================== //




    // ========= endpoint call for search after search drawwer ===== //
    useEffect(() => {
        if (formSubmissionState === 4) {
            setFilterData(false);
            setToogleVal(null);
            setAmtToogleFlag(false)

            const payload = {
                "condition": { "limit": 30, "skip": 0 },
                "sort": {
                    "field": "createdon_datetime",
                    "type": "desc"
                },
                "searchcondition": {
                    "rep_id": logedUserData._id,
                },
                "project": {},
                "token": "",
                "count": false
            }

            if (start_date !== null || end_date !== null) {
                payload['searchcondition']['createdon_datetime'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

            }



            tableDataForConversionReport.current['reqBody']['searchcondition'] = payload['searchcondition']


            dispatch(affiliateFilterConversionReportList(payload))
            setSearchDrawer(false)
        }
    }, [formSubmissionState])
    // ================================================================= //













    // ==== setTableData === //
    useEffect(() => {

        if (listData?.length > 0) {
            setFilterListData(listData)
        }

    }, [JSON.stringify(listData)])
    //  ================== //





    useEffect(() => {
        if (filterListData) {
            dispatch(setTableData({ "tableId": "AfffiliateConverisonReportList", "dataset": filterListData }))
        }
    }, [JSON.stringify(filterListData)])







    return (
        <>
            <div className="rolelist_mainwrp">
                <div className='listing_heading'>
                    <h1>Traffic Partner Conversion Report</h1>
                </div>


                <div className='listNavigate affiliate-reportlist-navigation'>

                    <ul className='toogleBtns'>
                        <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
                        <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
                        <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
                        <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
                        <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Conversion </Button>  </li>
                        <li> <Button variant="contained" onClick={() => {
                            dispatch(unmountTable({ "tableId": "AfffiliateConverisonReportList" }));
                            searchTable('all')
                        }}>

                            <Tooltip title="Reload" placement="bottom" className=''>
                                <ReplayIcon />
                            </Tooltip>
                        </Button>  </li>
                    </ul>



                </div>



                <div className='rolelist_mainwrp_Table affiliate_report'>


                    {loader && <LinearProgress />}

                    {loader === false && !listDataSuccess ?
                        <TableContainer>
                            <ListingTable
                                tableData={tableDataForConversionReport.current}
                                dataset={listData}
                                modifyHeaders={modifyTableHeaders}
                            />
                        </TableContainer> : <TableSkeleton count={5} />

                    }

                </div>


                <Drawer
                    anchor="bottom"
                    open={searchDrawer}
                    onClose={() => setSearchDrawer(false)}
                >
                    <Paper elevation={7} className='list_search team_management_list_search search_img'>

                        <div className="searchBarHead DrawerBox">
                            <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Conversion Detailed Report</h2>
                            <span className="searchBarCloseIcon">
                                <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
                            </span>
                        </div>

                        <Form formData={dataform.current} />
                    </Paper>
                </Drawer>



                {openModal && <AfffiliateConverisonReportModal openModal={openModal} setOpenModal={setOpenModal} />}

            </div>

        </>
    )
}

export default AfffiliateConverisonReport