import { IconButton, LinearProgress, Snackbar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchGlobalSetingsData, getCommissionSingalData } from '../commissionReducer';
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system';
import { Form } from '../../../../form/Form';


const DistributionFormAddEdit = (props) => {

    const [queryParamId, setQueryParamId] = useState();//----state for get ParamId

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false); // snackbar const
    const [errPopup, setErrPopup] = React.useState(false); // snackbar const
    const [errMsgCopy, setErrMsgCopy] = React.useState(null); // snackbar const
    const [showform, setShowForm] = React.useState(false); // showFrom
    //---------------------------get login user id---------

    let userID = useSelector((state) =>
        state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null
    );
    //--------------------------------------------------------------

    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.commissionDistributionForm) ? state.formSlice.formSubmissionState.commissionDistributionForm : 0);

    const errMsg = useSelector(state => (state.formSlice?.message?.userForm) ? state.formSlice.message.userForm : null);
    const loader = useSelector((state) => state.commissionReducer?.loading ? state.commissionReducer.loading : false)
    const globalSetingsData = useSelector(state => (state.commissionReducer?.globalSetingsData) ? state.commissionReducer.globalSetingsData : []);

    const globalDataSucess = useSelector(state => (state.commissionReducer?.globalDataSucess) ? state.commissionReducer.globalDataSucess : false);

    //========================================================================================




    // ---- userCreateForm ------
    const CommissionDistributionForm = useRef({
        id: 'commissionDistributionForm',
        formtype: "add",
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "commission/globalsettings-addupdate",
        endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/configur-commision",
        // msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "tblform",// Can be undefined default will be formCls
        formInlineCLass: "inlnfrm", // Can be undefined default will be formInline
        formButtonClass: "Formsubmit_button",
        submitBtnName: "UPDATE",
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            created_by: userID ? userID : undefined,
            _id: globalSetingsData[0]?._id ? globalSetingsData[0]._id : undefined
        },

        fields: [

            {
                id: 0,
                heading: "Comission Percentage For HM",
                label: "Comission Percentage For HM",
                name: "commission_percentage_for_hm",
                className: 'inputBlock inputBlock4line',

                type: "text",
                // inputType: "number",/////////////// If not mentioned default will be text //////////////
                // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

                defaultValue: globalSetingsData[0]?.commission_percentage_for_hm ? globalSetingsData[0].commission_percentage_for_hm : "0",

            },


            {
                id: 1,
                heading: "Comission Percentage For AEE",
                label: "Comission Percentage For AEE",
                name: "commission_percentage_for_aee",
                className: 'inputBlock inputBlock4line',
                type: "text",
                // inputType: "number",/////////////// If not mentioned default will be text //////////////
                // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: globalSetingsData[0]?.commission_percentage_for_aee ? globalSetingsData[0]?.commission_percentage_for_aee : "0",


            },

            {
                id: 2,
                type: "html",
                className: "CommisionHint",
                value: "<p>(Only applicable if AEE available)  </p>"
            },

        ],

        customButtons: [
            {
                id: 'comGoBack',
                label: 'Go Back',
                className: 'default',
                type: 'button',
            },

        ]

    })


    return (
        <>

        

                {loader ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null}

                {/* {globalDataSucess && showform ? */}
                    <div className='adminform_wrapper'>
                        <div className='dataform2_wrapper'>
                            <div className='adminformbody'>
                                <Form formData={CommissionDistributionForm.current} />
                            </div>
                        </div>
                    </div>
                    {/* : ""} */}

           

        </>
    )
}

export default DistributionFormAddEdit