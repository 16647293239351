import { Box, Grid } from '@mui/material'
import React from 'react'

function ThankYouPageEmployer() {

    return (
        <>
            <div className='forEmployerThanks'>
                <div className='thankyou-container'>
                    <div className='thankyou-block'>
                        <div className='contentEmployer'>
                            <Box sx={{ flexGrow: 1, }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <div className="headerLogoEmployer">
                                            <img src="https://all-aspire-assets.s3.amazonaws.com/DealfirecrmlogoNew.png" alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h2>Thank You!</h2>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3>Your application was submitted successfully.</h3>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h4>We'll contact you when a decision is made.</h4>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>You may now close this window.</p>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ThankYouPageEmployer