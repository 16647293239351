import { IconButton, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system'
import React from 'react'
import { clearPreviewData } from "../fetchUserDataReducer";
import { useDispatch } from "react-redux";

const ComitionInfoModal = ({modalState,ModalData,togelModalstate}) => {

    const dispatch = useDispatch()

    const closeModal =()=>{
        dispatch(clearPreviewData())
        togelModalstate(false)
    }
    console.log("ModalData333333",ModalData);
    { for (const property in ModalData.commissionsdata){
        console.log("propertyifsdfhdf",typeof(property));
   }}
  return (
    <>
        <Modal className="modalblock" open={modalState} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <Box  sx={{ position: "absolute", top: "50%", left: "50%",transform: "translate(-50%, -50%)", width: 600, bgcolor: "background.paper",border: '10px solid #0569ad!important', background:'#fff', boxShadow: 24,textAlign: "center", }}>
                <div className=" ModalBox_scroll">
                    <div className="heading">
                        <h2>
                            {/* Commision info details for affilate */}
                             {ModalData.firstname}'s AE Commission Details
                        </h2>
                    </div>


                   {ModalData.commissionsdata !== undefined && ModalData.commissionsdata !== null && Object.keys(ModalData.commissionsdata).length>0 ?
                   
                   Object.values(ModalData.commissionsdata).map((item)=>{
                    // console.log("jhguh",item);
                    return(
                    <ul className="listflexblock">
                      <li>
                        <strong>{item.package_name }</strong>
                            <div style={{ width: "100%",display: "flex",justifyContent: "center", alignItems: "center",flexDirection: "column"}} >
                          {/* <span>Commission Type :
                            <b style={{textTransform:"capitalize"}}> {item?.affilate_commission_type? item?.affilate_commission_type === "non_bps"?"Percentage":item.affilate_commission_type: "NA"}</b>
                          </span> */}
                          <span>
                            {/* Commission Amount : */}
                            <b> {`$${item?.affilate_commission_value? parseFloat(item.affilate_commission_value).toFixed(2): item.partner_commission_value ? parseFloat(item.partner_commission_value).toFixed(2) :"NA"}`}</b>

                            {/* <b>{item?.affilate_commission_type === "non_bps" ? ` "$"${item?.affilate_commission_value}`: `${item?.affilate_commission_value}%` }</b> */}
                          </span>

                          {/* <span>
                            AE Commission Amount :
                            <b> {`$${item?.affilate_commission_value && item?.ae_commission_amount? item.ae_commission_amount- item.affilate_commission_value:"NA"}`}</b>

                            <b>{item?.affilate_commission_type === "non_bps" ? ` "$"${item?.affilate_commission_value}`: `${item?.affilate_commission_value}%` }</b> *
                          </span> */}

                          {/* {
                            item?.affilate_commission_type === "non_bps" ?
                            <span>
                            Commission Percentage:
                              <b>{item?.affilate_commission_percentage? `${item.affilate_commission_percentage} %`: "NA"}</b>
                            </span>
                            :
                            ""
                          } */}

                          {/* <span>
                             Package Name :
                            <b>{item?.package_name? item.package_name : "NA"}</b>
                          </span>
                          <span>
                            Package Price :
                            <b> ${item?.package_price? item.package_price : "NA"}</b>
                          </span> */}
                        </div>
                      </li>
                      </ul>
                    )
                })
                :
                <h1>No data found</h1>
                  }
                        


                        
                        
                   
                </div>

                <Tooltip title="Close" placement="right-start">
                    <IconButton className="modalCloseBtn" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                 </Tooltip>


            </Box>
        </Modal>
    </>
  )
}

export default ComitionInfoModal