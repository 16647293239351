import { createSlice } from "@reduxjs/toolkit";



const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: {
    loader: false,
    resolved: {},
    open:false,
    message:"",
    status:'success',
    scrollView :{}
  },
  reducers: {
    setSnackbar(state,action){
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    setLoader(state, action) {
      if (action.payload && action.payload.loader == true) state.loader = true
      else state.loader = false;
    },
    setResloved(state, action) {
      if (action.payload.path) {
        if (action.payload.resolve == true) state.resolved[action.payload.path] = true;
        else if (action.payload.resolve == false) state.resolved[action.payload.path] = false;
      }
    },
    resetresolve(state, action){
      state.resolved={};
      state.loader=false;
    },
    // --------------------landing page 17 -------------------


setscrollView(state, action) {
  state.scrollView = action.payload
},
resetsetscrollView(state, action) {
  state.scrollView = action.payload
}
  },
  extraReducers: {}
})


export default layoutSlice.reducer;
export const { setSnackbar, setLoader, setResloved,resetresolve, setscrollView,resetsetscrollView } = layoutSlice.actions;
