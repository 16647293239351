import { fontWeight, margin } from "@mui/system";
import React from "react";
import { MdAlignHorizontalLeft } from "react-icons/md";

export default function LandingPage14Email() {
  return (
    <>
    <div>

   
      <div style={{
        width: "660px",
        margin: "0px auto",
        maxWidth: "100%",
        backgroundColor: "#fff",

      }}>
        <div style={{
          width: "fit-content",
          margin: "0px auto",
        }}>
          <img style={{
            display: "block",
            padding: "10px 0px"
          }} src={"https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/Email_access_logoforL14.png"} />
        </div>
        <div style={{
          width: "100%",
          backgroundImage: "url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/EmailL14_headerBG.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          backgroundSize: "contain"
        }}>
          <div style={{
            width: "50%",
            backgroundColor: "#015bf3",
            padding: "35px",
            position: "relative",
          }}>
            <img style={{
              position: "absolute",
              right: "-46px",
              top: "0",
              height: "100%"
            }}
              src={"https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/Email_header_rightBlue.png"} />
            <h1 style={{
              fontSize: "28px",
              fontWeight: "800",
              color: "#fff",
              lineHeight: "33px"
            }}>
              ACCESS Discount HealthCare
            </h1>
            <p style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#fff",
              lineHeight: "21px",
              marginTop: "10px",
            }}>
              PAY NOTHING FOR THE MOST PRESCRIBED GENERIC PRESCRIPTIONS!
            </p>
          </div>
        </div>
        <button style={{
          backgroundColor: "#015bf3",
          width: "310px",
          height: "40px",
          color: "#fff",
          margin: "0 auto",
          border: "none",
          borderRadius: "5px",
          marginTop: "15px",
          fontSize: "14px",
          fontWeight: "600",
        }}>
          Click here to learn more and enroll today!
        </button>

        <div style={{
          width: "580px",
          margin: "0px auto",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            width: "100%",
            marginBottom: "15px"
          }}>
            <div style={{
              width: "30%",
            }}>
              <img style={{
                display: "block",
                width: "100%"
              }}
                src={"https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/LP14_Email_capsule.png"} />
            </div>
            <div style={{
              width: "89%"
            }}>
              <p style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#0b395a",
                lineHeight: "22px",
                paddingTop: "10px"
              }}>
                Access your pharmacy prescriptions at no cost every month for one low subscription fee! Your prescriptions are shipped direct to your door within 2-4 days and, if needed, can be picked up at
                <strong style={{
                  color: "#015bf3",
                  fontWeight: "600",
                }}>
                  &nbsp; 64,000 pharmacies nationwide.
                </strong>
              </p>
            </div>



          </div>


        </div>
        <div style={{
          padding: "20px 0",
          backgroundColor: "#f6f6f6",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px auto",
            marginTop: "15px",
            marginBottom: "15px",
            flexDirection: "row-reverse",
            width: "580px",

          }}>
            <div style={{
              width: "38%",
            }}>
              <img style={{
                display: "block",
                width: "100%"
              }}
                src={"https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/LP14_Email_family.png"} />
            </div>
            <div style={{
              width: "61%"
            }}>
              <p style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#0b395a",
                lineHeight: "22px",
                paddingTop: "10px"
              }}>
                We have helped many individuals and families save big on the most common prescriptions, with over a million subscribers and growing!
              </p>
              <p style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#0b395a",
                lineHeight: "22px",
                paddingTop: "10px"
              }}>
                If you pay monthly, it is as easy as reviewing our subscription levels and comparing them to what you currently pay
              </p>
              <p style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#0b395a",
                lineHeight: "22px",
                paddingTop: "10px"
              }}>
                This is a great way to cover your prescription and medical durable costs and save BIG.
              </p>
            </div>



          </div>


        </div>
        <div style={{
          backgroundColor: "#015bf3",
          padding: "25px 10px"



        }}>
          <p style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#fff",
            lineHeight: "22px",
            textAlign: "center"
          }}>
            We have Single, Single +1, and Plans for the whole family
          </p>
          <button style={{
            backgroundColor: "#317dff",
            width: "310px",
            height: "40px",
            color: "#fff",
            margin: "0 auto",
            border: "none",
            borderRadius: "5px",
            marginTop: "15px",
            fontSize: "14px",
            fontWeight: "600",
          }}>
            Click here to learn more and enroll today!
          </button>
        </div>
        <div>
          <div style={{
            margin: "0px auto",
            width: "580px",
            padding: "15px 0",
            borderBottom: "1px solid #dbe1e6",

          }}>
            <p style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#0b395a",
              lineHeight: "19px",
              textAlign: "center",
            }}>
              800.863.8926 | adhcrx.com
            </p>
          </div>
          <p style={{
            fontSize: "12px",
            fontWeight: "500",
            color: "#0b395a",
            lineHeight: "17px",
            padding: "10px 0",
            textAlign: "center"
          }}>
            2023 Access Discount Healthcare Rx All Rights Reserved
          </p>
        </div>
      </div>
      </div>
    </>
  );
}
