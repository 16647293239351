import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const UserActivity = createAsyncThunk("fetch/userDashboardUserActivity", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "activity/activity-list", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const UserActivityInfinityScroll = createAsyncThunk("fetch/userActivityInfinityScroll", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "activity/activity-list", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const interviewFormdata = createAsyncThunk("fetch/interviewFormdata", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/name-interview-data-list-count", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// ---------  USer management Folder View  Visit Logs ------
export const folderViewVisitLog = createAsyncThunk("user/folderviewvisit", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/add-update-folder-records", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
// -------------------------------------------------------------


// ---------   Folder View Logs fetched ------
export const folderAccessInfo = createAsyncThunk("folderview/accesslogs", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/folder-accessed-list", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});


// ---------   Folder View Delete notes  ------
export const folderAccessDeleteNotes = createAsyncThunk("folderview/deletenotes", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "note/delete-notes", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});
// -------------------------------------------------------------


// ---------   Tags Logs fetched ------
export const TagLogsInfo = createAsyncThunk("folderview/tagsloginfo", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-tag-detail", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});
// -------------------------------------------------------------


// ---------   StageLogsInfo fetched ------
export const StageLogsInfo = createAsyncThunk("folderview/stageloginfo", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-stage-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});
// -------------------------------------------------------------


// ---------   Fetch ContractDetails  ------
export const ContractLogsInfo = createAsyncThunk("folderview/contractlogsinfo", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/contract-details", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});
// -------------------------------------------------------------



// // -------------------------------------------------------------
export const getMailInfo = createAsyncThunk("folderview/getmailinfo", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/folder-view-emails", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});
// -------------------------------------------------------------


export const getNotes = createAsyncThunk("usermanagement/getNotes", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "note/note-list-count", requestOptions);
    const res = await response.json();

    return res;
});

// ------------ folderViewCounts -----------
export const folderViewCounts = createAsyncThunk("userfolder/foldercounts", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/folder-view-details", requestOptions);
    const res = await response.json();

    return res;
});



export const getStageLifeCycleData = createAsyncThunk("getStageLifeCycleData", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-stage-data", requestOptions);
    const res = await response.json();

    return res;
});



export const getTagLifeCycleData = createAsyncThunk("getTagLifeCycleData", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-tag-detail", requestOptions);
    const res = await response.json();

    return res;
});

//---------------------------------tag life cycle count---------------------------
export const getTagLifeCycleCount = createAsyncThunk("getTagLifeCycleCount", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-tag-detail", requestOptions);
    const res = await response.json();

    return res;
});
//================================================================================



// --------------- AddNotes --------------
export const addNotes = createAsyncThunk("userFolderView/addNotes", async (reqbody) => {
    console.warn("<<<<<<<< payload from addNotes thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "note/add-note", requestOptions);
    const res = await response.json();

    return res;
});

// ----------------------------------------








const UserFolderViewReducer = createSlice({
    name: 'userFolderViewReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        userActivityList: [],
        leadActivityList: [],
        waiting: false,
        interviewData: [],
        folderAccessData: {},
        folderViewSucess: false,
        TagLogsInfo: [],
        tagloading: false,
        StageLogsData: [],
        stageloading: false,
        ContractLogsData: [],
        loginInfoData: {},
        // notesData: [],
        folderCounts: {},
        notesData: [],
        emailData: [],
        emailDataCount: null,
        LifeCycleDataStage: [],
        LifeCycleDataTag: [],
        noteAdded: false,
        notesDelete: false,
        contractSend: false,
        LifeCycleTagCount:null

    },
    reducers: {
        setInitData(state, action) {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.successMsg = null;
            state.errorMsg = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';
            state.userActivityList = [];
            state.waiting = false;
            state.folderAccessData = {};
            state.TagLogsInfo = null;
            state.tagloading = false;
            state.StageLogsData = null
            state.stageloading = false;
            state.ContractLogsData = []
            state.loginInfoData = {}
            state.folderCounts = {}
            state.noteAdded = false



        },
        clearInterviewData(state, action) {
            state.interviewData = []
        },
        clearLeadActivityList(state, action) {
            state.leadActivityList = []
        },
        clearLeadNotes(state, action) {
            state.notesDelete = false
        },
    },
    extraReducers: {
        // <-------------------------- User Activity Section -------------------------->
        [UserActivity.pending]: (state, action) => {
            state.waiting = true;
            // state.userActivityList = [];
        },
        [UserActivity.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.waiting = false;
            state.success = true;
            state.userActivityList = action.payload?.results?.res ? action.payload.results.res : [];
            state.leadActivityList = action.payload?.results?.res ? [...state.leadActivityList, ...action.payload.results.res] : [];
        },
        [UserActivity.rejected]: (state, action) => {
            state.waiting = false
        },
        // -----------------------------------------------------------------------------



        [interviewFormdata.pending]: (state, action) => {
            state.loaderForiInterviewForm = true
            state.interviewData = [];
        },
        [interviewFormdata.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loaderForiInterviewForm = false;
            state.interviewData = action.payload?.results?.res ? action.payload.results.res : []

        },
        [interviewFormdata.rejected]: (state, action) => {
            state.loaderForiInterviewForm = false
        },


        // ---------  USer management Folder View  Visit Logs ------
        [folderViewVisitLog.pending]: (state, action) => {
            state.folderViewSucess = false
        },
        [folderViewVisitLog.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.folderViewSucess = true
        },
        [folderViewVisitLog.rejected]: (state, action) => {
            state.folderViewSucess = false
        },
        // ----------------------------------------------------------



        // ---------  folderAccessInfo  ------
        [folderAccessInfo.pending]: (state, action) => {
            state.loading = true;
            state.folderAccessData = {}
        },
        [folderAccessInfo.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;

            state.folderAccessData = action.payload?.results ? action.payload.results : {}
        },
        [folderAccessInfo.rejected]: (state, action) => {
            state.loading = false;

            state.folderAccessData = {}
        },
        // ----------------------------------------------------------



        // ------------- TagLogsInfo --------------
        [TagLogsInfo.pending]: (state, action) => {
            state.tagloading = true;
            state.TagLogsInfo = null
        },
        [TagLogsInfo.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.tagloading = false;

            state.TagLogsInfo = action.payload?.results?.res ? action.payload.results.res : []
        },
        [TagLogsInfo.rejected]: (state, action) => {
            state.tagloading = false;

            state.TagLogsInfo = []
        },
        // ----------------------------------------------------------



        // ------------- TagLogsInfo --------------
        [StageLogsInfo.pending]: (state, action) => {
            state.stageloading = true;
            state.StageLogsData = null
        },
        [StageLogsInfo.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.stageloading = false;
            state.StageLogsData = action.payload?.results?.res ? action.payload.results.res : []
        },
        [StageLogsInfo.rejected]: (state, action) => {
            state.stageloading = false;
            state.StageLogsData = []
        },
        // ----------------------------------------------------------



        // ------------- Contrac Logs List --------------
        [ContractLogsInfo.pending]: (state, action) => {
            state.loading = true;
            state.ContractLogsData = []
            state.contractSend = false;
        },
        [ContractLogsInfo.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.contractSend = false;
            state.ContractLogsData = action.payload?.results?.res ? action.payload.results.res : []
        },
        [ContractLogsInfo.rejected]: (state, action) => {
            state.loading = false;
            state.ContractLogsData = []
        },
        // ----------------------------------------------------------



        [getMailInfo.pending]: (state, action) => {
            state.loading = true;
            state.emailData = []
        },
        [getMailInfo.fulfilled]: (state, action) => {
            console.log("getMailInfo", action)
            state.loading = false;

            // state.emailData = action.payload?.results ? action.payload.results : [];
            action?.payload?.hasOwnProperty("count") ? state.emailDataCount = action.payload.count : state.emailData = (action.payload?.results ? action.payload.results : []);
        },
        [getMailInfo.rejected]: (state, action) => {
            state.loading = false;
            state.emailData = []
        },



        // ------------------------------------------------------------
        [getNotes.rejected]: (state, action) => {
            state.loading = false;


        },
        [getNotes.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                console.log("notes data from reducer", action);
                state.notesData = action?.payload?.results?.res

            } else {
                state.loading = false;

            }
        },
        [getNotes.pending]: (state, action) => {
            state.loading = true;
            state.notesData = []
        },




        // ----------------------------------------


        // ---------- folderViewCounts -----------------
        [folderViewCounts.pending]: (state, action) => {
            state.loading = true;
            // state.folderCounts = {}
        },
        [folderViewCounts.fulfilled]: (state, action) => {
            console.log("getMailInfo", action.payload.results.res)
            state.loading = false;
            state.folderCounts = action.payload?.results.res ? action.payload.results.res : {}

        },
        [folderViewCounts.rejected]: (state, action) => {
            state.loading = false;
            // state.folderCounts = {}
        },
        // -------------------------
        [getStageLifeCycleData.pending]: (state, action) => {
            state.loading = true;
            state.LifeCycleDataStage = []
        },
        [getStageLifeCycleData.fulfilled]: (state, action) => {
            state.loading = false;
            console.log("action", action);
            state.LifeCycleDataStage = action.payload?.results?.res ? action.payload.results.res : []
        },
        [getStageLifeCycleData.rejected]: (state, action) => {
            state.loading = false;
        },






        [getTagLifeCycleData.pending]: (state, action) => {
            state.loading = true;
            state.LifeCycleDataTag = []
        },
        [getTagLifeCycleData.fulfilled]: (state, action) => {
            if(action.payload.status==="success"){
                state.loading = false;
                console.log("action", action);
                state.LifeCycleDataTag = action.payload?.results?.res ? action.payload.results.res : []
            }
        },
        [getTagLifeCycleData.rejected]: (state, action) => {
            state.loading = false;
        },

        ///------------------------for tag  life cycle count------------------
          
        [getTagLifeCycleCount.pending]: (state, action) => {
            state.loading = true;
            state.LifeCycleDataTag = []
        },
        [getTagLifeCycleCount.fulfilled]: (state, action) => {
            state.loading = false;
            console.log("LifeCycleTagCount", action);
            state.LifeCycleTagCount = action.payload.count
        },
        [getTagLifeCycleCount.rejected]: (state, action) => {
            state.loading = false;
        },

        //=====================================================================




        // ----####### addNotes ######## ------

        [addNotes.pending]: (state, action) => {
            state.loading = true;
            state.noteAdded = false
        },
        [addNotes.fulfilled]: (state, action) => {
            state.loading = false;
            console.log("action", action);
            state.noteAdded = true
        },
        [addNotes.rejected]: (state, action) => {
            state.loading = false;
            state.noteAdded = false
        },

        // ###################################

        // ----####### Folder View deleteNotes ######## ------

        [folderAccessDeleteNotes.pending]: (state, action) => {
            state.notesDelete = false
            state.loading = true;

        },
        [folderAccessDeleteNotes.fulfilled]: (state, action) => {

            state.success = true;
            if (action.payload.status === 'success') {
                state.loading = false;

                state.notesDelete = true

            }

            // state.loading = false;
            // // console.log("action", action);
            // state.notesDelete = true
        },
        [folderAccessDeleteNotes.rejected]: (state, action) => {

            state.notesDelete = false
        },
        // --------------------------------------------------------- //


        // <-------------------------- User Activity Infinity Scroll  -------------------------->
        [UserActivityInfinityScroll.pending]: (state, action) => {
            state.waiting = true;
            // state.userActivityList = [];
        },
        [UserActivityInfinityScroll.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.waiting = false;
            state.success = true;
            state.userActivityList = action.payload?.results?.res ? [...state.userActivityList, ...action.payload.results.res] : [];
            // state.leadActivityList = action.payload?.results?.res ? [...state.leadActivityList, ...action.payload.results.res] : [];
        },
        [UserActivityInfinityScroll.rejected]: (state, action) => {
            state.waiting = false
        },
        // -----------------------------------------------------------------------------


    },
});



export default UserFolderViewReducer.reducer;
export const { setInitData, clearInterviewData, clearLeadActivityList, clearLeadNotes } = UserFolderViewReducer.actions;



