import React, { useEffect, useState } from 'react'
import './InterviewContract.css'
import { useParams } from 'react-router-dom/dist';

function InterviewContract() {

  const [contractData, setcontractData] = useState();
  const param = useParams();
  
  useEffect(() => {
    // console.log("route", param);
    fatchcontract()
  }, []);

  const fatchcontract = async () => {
    let body = {
      condition:{
        limit:5,
        skip:0
      },
      sort:{
        field:"activity_time",        
      },
      type:"desc",
      searchcondition:{       
        user_id: param?._id,
      },
      project:{},
      token:"",
      email:''
    };
    let requestOptions = {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    let res = await fetch(
      `${process.env.REACT_APP_API_URL}users/name-interview-data-list-count`,requestOptions
    );
    res = await res.json();
    console.log("submit data", res);
    if(res.status=="success"){
      setcontractData(res.results?.res)
      console.log("contractData=======>", contractData);
    }
    
  };

  useEffect(() => {
    console.log("contractData????", contractData);   
  }, [contractData]);



  return (
    <>
      <div className='contract-mainwrapper'>
        <div className='contract-sub-wrapper'>
          <div className='contract-title-wrapper titlecenter'>
            <h2>ACCOUNT EXECUTIVE AGREEMENT</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p>
              This Account Executive Agreement (the “Agreement”) is made and entered into as of
              <i>test</i>, (the “Effective Date”) by and between ASPIRE PARTNERS, LLC (hereinafter
              referred to as “Company”), and <i>test</i> (hereinafter referred to as “Account
              Executive”).

            </p>
          </div>

          <div className='contract-title-wrapper titlecenter'>
            <h2>WITNESSETH:</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p><strong> WHEREAS, </strong> the Company is engaged in the business of offering <span>(i)</span> Aspire Partners’
              Services in Exhibit A; and</p>
            <p><strong>WHEREAS,</strong> the Company desires to retain the services of the Account Executive for the
              purpose of promoting the Services; and</p>
            <p><strong> WHEREAS, </strong>  the parties hereto deem it to be in their best interests to set forth in writing
              the terms and conditions under which said appointment is to be made;</p>
            <p><strong> NOW, THEREFORE,  </strong> in consideration of the mutual promises and covenants of the
              parties contained herein, the parties hereby agree as follows:</p>
          </div>

          <div className='contract-title-wrapper '>
            <h2>SECTION 1. APPOINTMENT</h2>
          </div>

          <div className='contract-text-wrapper'>
            <p><strong> 1.1 </strong> Services. The Company hereby appoints the Account Executive as its sales
              representative for the purpose of promoting the Services, and the Account Executive hereby accepts
              such appointment (the “Appointment”). Account Executive will perform the “Referral Services,”
              which involves promoting the Company and the Services through the Account Executive’s contacts
              to Candidates. The term “Candidate” means a customer or prospective customer of Account
              Executive that: <span>(i)</span> is directly solicited by Account Executive to use the Services, <span>(ii)</span> is not a current
              Company customer (i.e., not currently using the Services), and <span>(iii)</span> is approved by Company after
              Account Executive submits a customer profile form to Company. In addition to the foregoing,
              during the Term (as defined below) Account Executive shall <span> (a) </span> make no false or misleading
              representations with regard to Company or its third-party provider; and <span>(b)</span> refrain from making
              any representations, warranties, or guarantees to Customers or Candidates that are inconsistent with
              any customer agreement or any literature previously authorized by Company.</p>
          </div>

          <div className='contract-title-wrapper '>
            <h2>SECTION 2. INDEPENDENT CONTRACTOR</h2>
          </div>

          <div className='contract-text-wrapper'>
            <p><strong> 2.1 </strong> Relationship of Parties. The sole relationship of the parties is that of independent
              contractors and nothing in this Agreement or otherwise shall be deemed or construed to create any
              other relationship, including one of employment, joint venture, or agency. Account Executive shall
              be solely responsible for any taxes of any type, including social security taxes, workers’
              compensation taxes or costs, unemployment compensation taxes or costs, or any other taxes or
              charges related to Account Executive or Account Executive’s personnel’s receipt of compensation
              and performance of the Appointment under this Agreement. Account Executive agrees to
              indemnify and hold the Company harmless from any claims, demands, levies, or assessments
              related to the payment or nonpayment of any of the taxes described above.</p>

            <p><strong> 2.2 </strong> Benefits. The parties acknowledge that neither Account Executive nor its
              employees shall be entitled to receive any benefits which employees of Company are entitled to
              receive and shall not be entitled to workers’ compensation, unemployment compensation, medical
              insurance, life insurance, paid vacations, paid holidays, pension, profit sharing, or social security
              on account of their work for Company.</p>
          </div>
          <div className='contract-title-wrapper'>
            <h2>SECTION 3. TERM AND TERMINATION</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p><strong> 3.1 </strong>  Term. The term of this Agreement shall begin on the Effective Date and shall
              continue until terminated as set forth in Section 3.2 below (the “Term”). </p>
            <p><strong> 3.2 </strong> Termination. This Agreement may be terminated as follows:
            <span> 3.2.1 </span>  Bankruptcy. In the event of bankruptcy, insolvency, or assignment for
              the benefit of creditors of all or a substantial part of the assets of the Account Executive, whether
              voluntary or involuntary or an equivalent adjudication, the Company may immediately terminate
              the Agreement by providing written notice to the Account Executive;
              <span> 3.2.2 </span>  Material Breach. The Agreement may be terminated by the Company
              upon the giving of ten (10) days’ prior written notice to Account Executive in the event of a breach
              of Section 6 of this Agreement, if such breach is not cured within ten (10) days of notice of said
              breach; and
              <span> 3.2.3 </span> Written Notice. The Agreement may be terminated by either party hereto
              upon thirty (30) days’ prior written notice delivered to the other party.</p>
            <p><strong> 3.3 </strong>  Effect of Termination. Upon a termination of this Agreement, however
              effectuated, both parties and their personnel shall return all documentation, and any copies thereof,
              to the other party specifically including, but not limited to, any Confidential Information (as defined
              below) and any information received and/or deduced from customers concerning their stated or
              perceived needs for the Services and/or any other products or services.</p>
          </div>
          <div className='contract-title-wrapper'>
            <h2>SECTION 4. COMPENSATION</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p><strong>4.1 </strong>  Commissions. Subject to the terms and conditions of this Agreement, during the
              Term and in consideration of the services provided during the Appointment, the Company shall
              pay to the Account Executive, and the Account Executive shall accept commissions only, which
              shall be determined pursuant to the “Account Executive Compensation Program” described on
              Exhibit A attached hereto and incorporated herein by this reference (the “Account Executive
              compensation”). Such Account Executive compensation shall be payable in accordance with the
              Account Executive Compensation Program. Account Executive recognizes that the Account
              Executive Compensation Program may change from time to time; provided, however, that the
              Account Executive compensation will only change upon thirty (30) days’ written notice from the
              Company. </p>
            <p><strong>4.2 </strong> Effect of Termination. Upon termination of this Agreement, Account Executive
              shall continue to be entitled to payment of the Account Executive compensation, pursuant to
              Section <span> 4.1 </span>  above. Notwithstanding the foregoing or anything contained in this Agreement to the
              contrary, no payment shall be due to Account Executive if Account Executive has breached this
              Agreement or any portion hereof in any manner. The obligation, if any, of the Company to pay the
              Account Executive compensation is expressly conditioned upon the faithful performance by
              Account Executive of the terms and conditions of this Agreement. This Section <span> 4.2 </span> shall survive
              the termination of this Agreement.</p>
          </div>
          <div className='contract-title-wrapper'>
            <h2>SECTION 5. INDEMNIFICATION</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p><strong>5.1 </strong> Indemnification. Account Executive shall indemnify Company from, and defend
              Company against, any and all liability or expenses (including attorneys’ fees and expenses as
              reasonably incurred) arising out of or relating to any injuries of any kind resulting from Account
              Executive traveling to meet with a Candidate in the performance of this Agreement.</p>
          </div>
          <div className='contract-title-wrapper'>
            <h2>SECTION 6. RESTRICTIVE COVENANTS</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p><strong>6.1 </strong> Agreement Not to Solicit Customers. During the Term of this Agreement and for
              a period of two (2) years following the termination of this Agreement, Account Executive, its
              employees, subcontractors, officers, and agents shall not (except on behalf of or with the prior
              written consent of Company), either directly or indirectly, (1) solicit, contact, or call upon, or (2)
              attempt to solicit, contact, or call upon any Candidate with whom Account Executive or any of its
              employees, subcontractors, officers, and agents has had contact during the course of the Agreement
              for the purposes of providing and or offering competitive services. For purposes of this Agreement,
              “competitive services” shall mean products or services that are competitive with, the same as, or
              similar to the products or services being offered by the Company at any time during the Term of
              this Agreement, including, but not limited to, the Aspire Partners’ Services in Exhibit A</p>
            <p><strong>6.2 </strong> Agreement Not to Solicit Employees. During the Term of this Agreement and for
              a period of one (1) year following the termination of this Agreement for any reason whatsoever,
              Account Executive and its employees, subcontractors, officers, and agents shall not, either directly
              or indirectly, solicit, divert or hire, or attempt to solicit, divert or hire, any person employed by or
              contracted with Company, whether or not the employment of any such person is pursuant to a
              written agreement for a determined period or at will.</p>
            <p><strong>6.3 </strong> Agreement Not to Disclose Confidential Information.
            <span>6.3.1 </span> Confidential Information. “Confidential Information” shall mean any
              confidential business, technical or data processing information, trade secret or other proprietary
              information acquired by Account Executive or any of its employees, subcontractors, officers, or
              agents in the course of carrying out the tasks hereunder or as a result of access to the Company,
              whether or not conceived of or prepared by Account Executive or any of its employees,
              subcontractors, officers, or agents whether or not reduced to writing, and whether or not in human
              readable or machine readable form, including, without limitation, any information concerning data
              processing concepts, techniques, or procedures, software in various stages of development,
              discoveries, ideas, inventions, operations, data, designs, drawings, diagrams, specifications,
              documentation, research, know-how, compilations of information, records, costs, purchasing data,
              financial data, accounting, marketing and development plans, sales, pricing, profits, business plans
              or procedures, customer data, employee information and other information not generally known to
              non-Company personnel. Confidential Information also includes any information described above
              which Company obtains from another party and treats as proprietary or designates as confidential
              information, whether or not owned or developed by Company. Confidential Information shall
              cease to be Confidential Information after it has been voluntarily disclosed to the public by
              Company or independently developed and disclosed by others or has otherwise entered the public
              domain through lawful means. In any dispute with respect to these exclusions, the burden of proof
              will be on Account Executive to show that the exclusion applies.
              <span> 6.3.2 </span> Agreement Not to Disclose Confidential Information. The Account
              Executive agrees to maintain in confidence all information and Confidential Information received
              from the Company under this Agreement, not to disclose the same to third parties, and to obligate
              all personnel having access to such information to adhere to this obligation of confidentiality.
              These obligations of confidentiality shall not apply to any information or Confidential Information
              that is or becomes public knowledge. The Account Executive further agrees that neither it nor any
              of its employees, subcontractors, officers, or agents shall, during the Term of this Agreement or for
              a period of one (1) year thereafter, without the prior written consent of the Company, use, disclose,
              or otherwise make available to any person or entity, except as required in performing services under
              this Agreement, any Confidential Information or other information received from the Company
              under this Agreement. Notwithstanding the foregoing, the Company shall not be deemed to have
              Page 4 of 10
              Aspire Partners, LLC/
              Account Executive Agreement
              waived any extended period of coverage or any rights or remedies it may be entitled to under the
              Georgia Trade Secrets Act. In addition to the foregoing, promptly upon expiration or termination
              of this Agreement or earlier, if requested by the Company, except to the extent specifically provided
              elsewhere in this Agreement, Account Executive shall return, erase or destroy all Confidential
              Information in its possession or control, including Confidential Information stored in any computer
              memory or data storage apparatus, and, at the Company’s request, provide an affidavit that Account
              Executive retains no Confidential Information in any form whatsoever.
              <span> 6.3.3 </span> Non-Disparagement. Account Executive agrees not to make any statements,
              written or verbal, or cause or encourage others to make any statements, written or verbal, that
              defame or disparage the personal or business reputation, practices, or conduct of, Aspire Partners
              or their employees, owners, managers, directors, agents, successors, or assigns. Account Executive
              acknowledges and agrees that this provision extends to statements, written or verbal, made to
              anyone including, but not limited to, the news media, competitors, vendors, agents, other Account
              Executives, or employees, and to any comments made or posted in or on any social media platform,
              blog, website, chatroom, or other public or private forum.</p>
          </div>
          <div className='contract-title-wrapper'>
            <h2>SECTION 7. GENERAL</h2>
          </div>
          <div className='contract-text-wrapper'>
            <p><strong>7.1 </strong> Entire Agreement. This Agreement constitutes the entire agreement between the
              parties and supersedes any and all previous representations, understandings, discussions, or
              agreements between Company and Account Executive as to the subject matter hereof. The parties
              further agree that they are not relying upon any representations, statements, or agreements from the
              other as a basis for entering into this Agreement except for those expressly set forth in this
              Agreement. This Agreement may only be amended by an instrument in writing signed by Company
              and Account Executive.</p>
            <p><strong>7.2 </strong> Governing Law. This Agreement shall be subject to the laws of the State of
              Georgia. Venue shall lie in the State of Georgia for all causes of action under this Agreement</p>
            <p><strong>7.3 </strong> Notices. All notices required or permitted hereunder shall be in writing and shall
              be deemed effectively given: <span> (a) </span> upon personal delivery to the party to be notified, <span> (b) </span> when sent
              by confirmed electronic mail or facsimile if sent during normal business hours of the recipient, if
              not, then on the next business day, <span> (c) </span> five days after having been sent by registered or certified
              mail, return receipt requested, postage prepaid, or <span> (d) </span> one day after deposit with a nationally
              recognized overnight courier, specifying next day delivery, with written verification of receipt. All
              communications shall be sent to the Company at the address on the signature page below and to
              the Account Executive at the address set forth on the signature page below. </p>
            <p><strong>7.4 </strong> No Waiver. The failure of either party to insist upon a strict performance of or to
              seek remedy of any one of the terms or conditions of this Agreement or to exercise any right,
              remedy, or election set forth herein or permitted by law shall not constitute nor be construed as a
              waiver or relinquishment for the future of such term, condition, right, remedy or election, but such
              items shall continue and remain in force and effect. </p>
            <p><strong>7.5 </strong>  Assignment. This Agreement may not be assigned by Account Executive without
              the written consent of Company, which may be withheld for any reason, and any such purported
              assignment, including full or partial assignment or delegation to any agent or subcontractor, is void.</p>
            <p><strong>7.6 </strong> Severability. All rights and restrictions contained herein may be exercised and
              shall be applicable and binding only to the extent that they do not violate any applicable laws and
              are intended to be limited to the extent necessary so that they will not, under this Agreement, be
              Page 5 of 10
              Aspire Partners, LLC/
              Account Executive Agreement
              illegal, invalid, or unenforceable. If any term of this Agreement shall be held to be illegal, invalid,
              or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the
              remaining terms hereof shall constitute their Agreement with respect to the subject matter hereof
              and all such remaining terms shall remain in full force and effect. To the extent legally permissible,
              any illegal, invalid, or unenforceable provision of this Agreement shall be replaced by a valid
              provision, which will implement the commercial purposes of the illegal, invalid, or unenforceable
              provision.
            </p>
            <p><strong>7.7 </strong>  Successors, Heirs, Assigns. Except as otherwise provided herein to the contrary,
              this Agreement shall be binding upon and shall inure to the benefit of the parties to this Agreement,
              and their respective heirs, legal representatives, successors, and assigns.</p>
            <p><strong>7.8 </strong> Counterparts. This Agreement may be executed in one or more counterparts, each
              of which shall be deemed original, but all of which together shall constitute one and the same
              instrument. Delivery of a copy of a signature by facsimile transmission or an electronic exchange
              methodology shall constitute a valid and binding execution and delivery of this Agreement, and
              such electronic copy shall constitute an enforceable original document.</p>
            <p><strong>7.9 </strong> Attorneys’ Fees. Should either party institute any action or proceeding in any court
              to enforce any of the provisions hereof, for damages by reason of any alleged breach of any
              provision of this Agreement or for the enforcing of any covenant herein contained, the prevailing
              party shall be entitled to receive from the losing party such amounts that the court shall adjudge to
              be reasonable attorney’s fees for the services rendered the prevailing party in such action (including
              any appeal thereof).</p>
          </div>

          <div className='contract-container'>
          <div className='contract-col'>
            <h2>COMPANY: ASPIRE PARTNERS, LLC</h2>
            <p>By: <b>{contractData && contractData[0].firstname} {contractData && contractData[0].lastname}</b></p>
            <p>Shawn Hull, CEO</p>
            <p>ASPIRE PARTNERS, LLC </p>
          </div>
          <div className='contract-col'>
            <h2>ACCOUNT EXECUTIVE:</h2>
            <p>By: <b>{contractData && contractData[0].firstname} {contractData && contractData[0].lastname}</b></p>
            <p>Name: <i>{contractData && contractData[0].firstname} {contractData && contractData[0].lastname}</i></p>
            <p>Notice Address: <i>test</i></p>
            <p>E-Mail: <i>{contractData && contractData[0].email}</i></p>
          </div>

          <div className='contract-text-wrapper'>
            <p><b>Notice Address:</b></p>
            <p>33 South Main Street, Suite 302 Alpharetta, GA 30009</p>
            <p>E-Mail: <a href='mailto:${contractData[0].email}'>{contractData && contractData[0].email}</a> </p>
          </div>

          </div>

          

        </div>
      </div>

    </>
  )
}

export default InterviewContract
