import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import '../LandingPage17/LandingPage17.css'
import Lp14block1 from '../LandingPage14/Components/Lp14block1';
import Lp14block2 from '../LandingPage14/Components/Lp14block2';
import Lp14block3 from '../LandingPage14/Components/Lp14block3';
import Lp14block4 from '../LandingPage14/Components/Lp14block4';
import Lp14block5 from '../LandingPage14/Components/Lp14block5';
import Lp14block6 from '../LandingPage14/Components/Lp14block6';
import Lp14block7 from '../LandingPage14/Components/Lp14block7';
import Lp14block8 from '../LandingPage14/Components/Lp14block8';
import Lp14block9 from '../LandingPage14/Components/Lp14block9';
import Lp14block10 from '../LandingPage14/Components/Lp14block10';
import Lp14blockfooter from '../LandingPage14/Components/Lp14blockfooter';
import { useDispatch, useSelector } from 'react-redux';
import Lp14blockheader from '../LandingPage14/Components/Lp14blockheader';
import Lp14blocklogo from '../LandingPage14/Components/Lp14blocklogo';
import { addconverstiondata, addlandingpageninedatabluecosde, clearadddsuccessflag, fetchbluecodelead } from '../LandingpageReducer';
// import { addconverstiondata } from '../../LandingpageReducer'
import '../LandingPage14/Components/LandingPage14.css'
import { Form } from '../../../../form/Form';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { resetsetscrollView, setSnackbar } from '../../../Layout/layoutReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { removeField, setField } from '../../../../form/formReducer';
import { useNavigate } from 'react-router-dom';
import store from '../../../../store';



function LandingPage17({ dataset }) {
    // console.log("dataset", dataset);
    const dispatch = useDispatch()
    const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
    const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
    const [conversionapicall, setConversionapicall] = useState(false);

    const formInputData = useSelector((state) =>
        state.formSlice.formData.userFormAsprBlcst17 ? state.formSlice.formData.userFormAsprBlcst17 : null
    );
    const loading = useSelector(state => (state.LandingpagefontReducer?.bluecoseloader) ? state.LandingpagefontReducer?.bluecoseloader : false)
    const otherRadioButtonClicked = useSelector((state) => (state.formSlice?.formData?.userFormAsprBlcst17 && Object.keys(state.formSlice.formData.userFormAsprBlcst17).length > 0) ? state.formSlice.formData.userFormAsprBlcst17 : undefined)
    const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userFormAsprBlcst17) ? state.formSlice?.formSubmissionState?.userFormAsprBlcst17 : 0)

    const leadaddeddata = useSelector(state => (state.LandingpagefontReducer?.bluecouseadddata) ? state.LandingpagefontReducer?.bluecouseadddata : null)
    const leadaddeddataFlag = useSelector(state => (state.LandingpagefontReducer?.adddsuccessflag) ? state.LandingpagefontReducer?.adddsuccessflag : false)
    useEffect(() => {
        console.log("ipinfo++++++", ipinfo, userData);
        if (userData && ipinfo) {
            console.log("userData", userData);
            let body = {
                // "campaign_id":userData.campaign_id,
                "associated_program": userData.associated_program,
                "landing_page_id": userData.landing_page_id,
                "landing_page_slug": userData.landing_page_slug,
                // "campaign_added_by":userData.user_id,
                // "hiring_manager":userData.hm_id,
                "ipInfo": ipinfo
            }
            if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
            if (userData.user_id) body['campaign_added_by'] = userData.user_id;
            if (userData.hm_id) body['hiring_manager'] = userData.hm_id;
            if (userData.rep_id) body['rep_id'] = userData.rep_id;
            console.log("+++++++++++++++++++", body);
            if (body && body.landing_page_id && !conversionapicall) {
                dispatch(addconverstiondata(body))
                setConversionapicall(true)

            }

            //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
            //    console.log("bodyc",bodyc);
        }

    }, [JSON.stringify(ipinfo), JSON.stringify(userData)])
    const ref = useRef();
    // useEffect(() => {
    //     if (userData.pixel_tracking_click) {
    //         const node = document.createRange().createContextualFragment(userData.pixel_tracking_click);
    //         ref.current.appendChild(node);
    //     }
    // }, [JSON.stringify(userData)])

    const navigate = useNavigate()
    useEffect(() => {
        console.log("otherRadioButtonClicked", otherRadioButtonClicked);
        if (otherRadioButtonClicked && otherRadioButtonClicked.business_or_group_type) {
            if (otherRadioButtonClicked.business_or_group_type && otherRadioButtonClicked.business_or_group_type == 'Other') {

                console.log("otherRadioButtonClicked", otherRadioButtonClicked);


                let field = {

                    id: 35,
                    // heading: "Other",
                    label: "Other",
                    name: "other",
                    className: 'formfild formfild1',
                    // multiple: true,
                    type: "text",


                };
                setTimeout(() => dispatch(setField({ formId: "userFormAsprBlcst17", after: "business_or_group_type", fieldData: field })), 200);


            } else {
                setTimeout(() => dispatch(removeField({ formId: "userFormAsprBlcst17", name: "other" })), 2000)

            }


        }

    }, [JSON.stringify(otherRadioButtonClicked)])



    useEffect(() => {
        if (leadaddeddata && Object.keys(leadaddeddata) && leadaddeddataFlag) {

            dispatch(
                setSnackbar({ open: true, message: 'Thank You for Submiting!', status: 'success' })
            )
            resolvefunction(navigate, `/thankyouaccess/${leadaddeddata._id}`, fetchbluecodelead(leadaddeddata._id))
            // navigate(`/thankyou/${leadaddeddata._id}`)
            dispatch(clearadddsuccessflag())
        }
    }, [leadaddeddataFlag, JSON.stringify(leadaddeddata)])
    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
            console.log("formInputData", formInputData, submissionState);
        }
    }, [submissionState]);

    const addapicall = async (val) => {
        console.log("val>>>", val);

        val = { ...val }
        let formbody = {
            "first_name": dataset.firstname,
            "last_name": dataset.lastname,
            "email": dataset.email,
            "phone": dataset.phone,
            "prospectbusinessname": val.prospect_business_name,
            "prospectfirstname": val.prospect_first_name,
            "prospectlastname": val.prospect_last_name,
            "prospecttitle": val.prospect_title,
            "prospectemail": val.prospect_email,
            "prospectcontactnumber": val.phone,
            "medicalfacilitytype": val.business_or_group_type,
            // "facilitytype": val.facility_type,
            "other": val.other,
            "natureofrelationship": val.nature_of_relationship,
            "relationshipdescription":val.relationshipdescription,
            "approxgrossreciept": val.approx_gross_reciepts,
            "createdon_datetime": new Date().getTime(),
            "added_type": 'landingpage',
            // "page_id": userData.landing_page_id,
            // "page_slug": userData.landing_page_slug,
            "ipinfo": ipinfo,
            'source': 'https://accessfreepharmacy.com/',
            "other_info": val.other_info,
            "extensive_notes": val.extensive_notes,

            // "accessfree": true
            // 'bluecoastPh':true
        }
        if (dataset && dataset.page_slug == 'NycjyqRnjFUEd') {
            formbody['bluecoastPh'] = true
        }
        if (dataset && dataset.page_slug == 'gc7MqHWxe7HIW') {
            formbody['bluecoastphblue'] = true
        }

        if (Object.keys(formbody).length > 0) {
            console.log("formbody-----", formbody);
            // return
            // setloading(true)
            let dataset = await dispatch(addlandingpageninedatabluecosde(formbody));
            console.log("Response dataset", dataset);
            if (dataset && dataset.payload && dataset.payload.status == 'error') {
                store.dispatch(
                    setSnackbar({ open: true, message: dataset.payload.message ? dataset.payload.message : 'Something went wrong', status: 'error' })
                )
            }
            // setActive(false)
            // setloading(false)

        }
    };



    const form15AsprBlcst = useRef({
        id: 'userFormAsprBlcst17',
        formtype: "add",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "landing-page-manage/add-user",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection9",
        submitBtnName: "Submit",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {

            // "status": 0,
            // "roles": { is_rep: 1 },

        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////

            {
                id: 16,
                // heading: "Potential Pharmacy Partner Name",
                label: "Potential Pharmacy Partner Name",
                name: "prospect_business_name",
                className: 'formfild formfild1 ',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },

            {
                id: 21,
                // heading: "Prospect's Title",
                label: "Prospect's Title",
                name: "prospect_title",
                className: 'formfild formfild1 ',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },

            {
                id: 17,
                // heading: "Prospect's First Name",
                label: "Prospect's First Name",
                name: "prospect_first_name",
                className: 'formfild formfild4',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 18,
                // heading: "Prospect's Last Name",
                label: "Prospect's Last Name",
                name: "prospect_last_name",
                className: 'formfild formfild4',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },

            {
                id: 19,
                // heading: "Prospect's Email",
                label: "Prospect's Email",
                name: "prospect_email",
                className: 'formfild formfild4',
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },

            {
                id: 29,
                // heading: "Prospect's Phone Number ",
                label: "Prospect's Phone Number ",
                name: "phone",
                className: 'formfild formfild4',
                type: "text",
                inputType: "phone",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },



            {
                id: 11,
                heading: "Type of business or group",
                name: "business_or_group_type",
                className: 'form_radio',
                values: [{ key: 'Affinity Group', val: 'Affinity Group' }, { key: 'Chamber of Commerce', val: 'Chamber of Commerce' }, { key: 'MLM', val: 'MLM' }, { key: 'Affiliate Organization', val: 'Affiliate Organization' }, { key: 'Employer', val: 'Employer' }, { key: 'Other', val: 'Other' }],
                type: 'radio',
                // hint: 'Yes/No',
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                // defaultValue: "No"
            },

            {
                id: 40,
                // heading: "Prospect's Phone Number ",
                label: "How many people are in your organization? ",
                name: "number_of_people_type",
                className: 'formfild formfild4',
                type: "text",
                inputType: "number",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            // {
            //   id: 22,
            //   heading: "Type of facility",
            //   name: "facility_type",
            //   className: 'form_radio',
            //   values: [{ key: 'Med Practice', val: 'Med Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Other', val: 'Other' }],
            //   type: 'radio',
            //   // hint: 'Yes/No',
            //   rules: { required: true },
            //   errorMessage: {
            //     required: "This Field is Required",
            //     custom: "Value is Invalid"
            //   },

            // },



            // {
            //   id: 1,
            //   heading: "Name of medical facility",
            //   label: "Name of medical facility",
            //   name: "medical_facility_name",
            //   className: 'formfild',
            //   type: "text",
            //   rules: { required: true },
            //   errorMessage: {
            //     required: "This Field is Required",
            //     custom: "Value is Invalid"
            //   },
            // },
            // {
            //   id: 2,
            //   heading: "Name of their relationship at facility and that persons title (would be good to have this fill able so that they can type details)",
            //   label: "Name of their relationship at facility and that persons title",
            //   name: "relationship_name",
            //   className: 'formfild',
            //   type: "text",
            //   rules: { required: true},
            //   errorMessage: {
            //     required: "This Field is Required",
            //     custom: "Value is Invalid"
            //   },

            // },


            {
                id: 10,
                heading: "What is your relationship with the prospect?",
                name: "nature_of_relationship",
                className: 'form_radio',
                values: [{ key: 'Client', val: 'Client' }, { key: 'Client/Friend', val: 'Client/Friend' }, { key: 'Friend', val: 'Friend' }],
                type: 'radio',
                // hint: 'Yes/No',
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                // defaultValue: "No"
            },
            {
                id: 51,
                heading: "Please describe your relationship",
                placeholder: "Please describe your relationship",
                label: "Please describe your relationship",
                name: "relationshipdescription",
                className: "formfild inputBlock1line grossreceipts",
                type: "text",
                // inputType: "email",
                // rules: { required: true },
                // errorMessage: {
                //   required: "This Field is Required",
                //   custom: "Value is Invalid",
                // },
              },



            // {
            //   id: 3,
            //   heading: "Approx gross receipts with top 3 insurers",
            //   label: "Approx gross receipts with top 3 insurers",
            //   name: "approx_gross_reciepts",
            //   className: 'formfild',
            //   type: "text",
            //   // inputType: "email",
            //   rules: { required: true },
            //   errorMessage: {
            //     required: "This Field is Required",
            //     custom: "Value is Invalid"
            //   },

            // },
            {
                id: 20,
                // heading: "Any other information we should know?",
                placeholder: 'Any other information we should know?',
                // label: "Any other information we should know?",
                name: "other_info",
                className: 'formfild textareafild ',
                type: "textarea",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                }

            },
            {
                id: 22,
                // heading: "Must add extensive notes for this to be reviewed for qualification",
                placeholder: 'Must add extensive notes for this to be reviewed for qualification',
                // label: "Must add extensive notes for this to be reviewed for qualification",
                name: "extensive_notes",
                className: 'formfild textareafild ',
                type: "textarea",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                }

            },
            {
                id: 125,
                // heading: "Captcha",
                // label: "Captcha",
                name: "captcha",
                className: 'formfild formfild4 reCaptcha',
                type: "captcha",
                rules: { required: true },
                errorMessage: {
                    required: "Prove, You're Not a Robot",
                    custom: "Value is Invalid"
                },
            },

        ]

    });

    const dataref = useRef();



    let scrollview = useSelector((state) => state.layoutSlice?.scrollView ? state.layoutSlice.scrollView : {});

    useEffect(() => {
        console.log("scrollview", scrollview);

        if (scrollview === "scroll") {

            dataref.current.scrollIntoView({ behavior: "smooth" });

            setTimeout(() => {

                dispatch(resetsetscrollView())

            }, 500);

        }


    }, [scrollview])









    return (
        <>

            <div className='landingoage14_main landingoage17_main'>
                {userData.pixel_tracking_click &&
                    //  <span className='visiblehiddenclas' dangerouslySetInnerHTML={{__html: userData.pixel_tracking_click}}></span>
                    <span className='visiblehiddenclas' ref={ref}></span>

                }
                <Lp14blockheader />
                {/* <Lp14blocklogo /> */}
                <div className='lpage17_main_container'>
                    {/* <div className='page17_top_sec'>
                        <div className='page17_top_flex'>
                            <div className='page17_logo_sec'>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landing-page17/page17_access_discount_logo.webp' />
                            </div>
                            <div className='page17_text_sec'>
                                <h3>Offering the Access Health Free Pharmacy program to your group of affiliates, memberships and associations will prove to be a big win for your organization.</h3>
                                <p>We are able to offer customized programs and training for you and your team and quickly get you access to literature, funnel pages and immediate opportunities for enrollment!</p>
                            </div>
                        </div>

                        <div className='page17_form_wrp' ref={dataref}>
                            <Form formData={form15AsprBlcst.current} />
                            {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}
                        </div>




                    </div> */}
                    <div className='landing-container'>
                        <div className='landing-col-container'>
                            <div className='landing-banner-left'>
                                <div className='lpage_logowrapper'>
                                    {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landing-page17/pharmacy_savingspage3_logo.webp" /> */}
                                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landing-page17/pharmacy_savingspage3_logo.webp`} />
                                </div>
                                <div className='lpage17_banner_titlewrapper'>
                                    <h1>
                                        Offering the <span>Access<br></br> Health Free  Pharmacy<br></br> program</span>
                                    </h1>
                                </div>
                                <div className='lpage17_banner_sub_titlewrapper'>
                                    <h3>
                                        to your group of affiliates,<br></br>  memberships and associations<br></br>
                                        will prove to be a big win for<br></br>
                                        your organization.
                                    </h3>
                                </div>
                                {/* <div className='lpage17_banner_textwrapper'>
                                    <p>
                                        We are able to offer customized programs and training for you and your team and quickly get you access to literature, funnel pages and immediate opportunities
                                        for enrollment!
                                    </p>
                                </div> */}
                                <div className='lpage17_banner_img_wrp'>
                                    {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landing-page17/round_box_img.webp' /> */}
                                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landing-page17/round_box_img.webp`} />
                                    {/* {`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}aspire-landingpages/landing-page17/round_box_img.webp`}  */}
                                </div>
                                <div className='lpage17_arrow_wrapper'>
                                    <h2>SPEAK WITH ONE OF OUR ORGANIZATION SPECIALIST RIGHT AWAY</h2>
                                </div>


                            </div>
                            <div className='landing17-banner-right landing17' ref={dataref}>
                                <div className='formwrapper17'>
                                    <div className='form_title_wrapper17'>
                                        <h2>COMPLETE THE FORM BELOW TO ENROLL
                                        </h2>
                                    </div>
                                    <div className='page17_form_wrp'>
                                        <Form formData={form15AsprBlcst.current} />
                                        {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}
                                    </div>

                                </div>
                            </div></div>
                    </div>



                </div>
                <Lp14block1 />
                <Lp14block2 page="17" />
                <Lp14block3 />
                <Lp14block4 page="17" />
                <Lp14block5 />
                <Lp14block6 />
                <Lp14block7 page="17" />
                <Lp14block8 page="17" />
                <Lp14block9 page="17" />
                <Lp14block10 page="17" />
                <Lp14blockfooter page="17" />
            </div>








        </>
    )
}

export default LandingPage17
