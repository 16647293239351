
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, roundRobinEdit } from '../AffiliateReducer';
import W9Form from '../W9Form';



const W9FormViewModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    const [priority, setPriority] = useState('');


    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)

    const FetchedW9FormData = useSelector(state => (state.AffiliateReducer?.W9FormData) ? state.AffiliateReducer.W9FormData : [])


    // useEffect(() => {
    //     props?.setModalResponse(false);
    // }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        // props?.setModalResponse(false);
        props?.tooglefunc(false);

    }





    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock SD_w9_modal"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box 
                className="BoxMui_modal configure-role w9_modal_heading"
                //  sx={{
                //     position: "absolute",
                //     top: "50%",
                //     left: "50%",
                //     transform: "translate(-50%, -50%)",
                //     width: "80%",
                //     bgcolor: "background.paper",
                //     border: "2px solid #000",
                //     boxShadow: 24,
                //     p: 4,
                //     textAlign: "center",
                // }}
                >
                    
                    <Typography id="modal-modal-title" variant="h6" component="h3">
                        {loader ? <LinearProgress /> : ""}

                        W9 Form Details
                    </Typography>

                    <div className='formSection'>
                        <W9Form source="affiliate_listing" FetchedW9FormData={FetchedW9FormData} />
                    </div>

                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>

                </Box>
            </Modal>




            {/* {success != null ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                // onClose={() => setopen(false)}
                message={success}
            // action={action}
            />) : ""} */}

        </>
    )
}


export default W9FormViewModal;



