import React, { useEffect, useState } from "react";
import {
  deleteTempSearchEndpoint,
  setEnableRowLoader,
  setReloadTableFlag,
} from "../../../listing/listReducer";

import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import {
    Button,
    FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { clearoperationaldispostionData, dispositionDataLoad, getDisposition, operationalDataLoad, operationaldispositionadd, pharmacydispositionadd } from "./BlueCoastReducer";
import { timeConverter } from '../../../helper/helperFunctions';
import { Form } from "../../../form/Form";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DispositionAfterContract = ({click,rowData}) => {

    let token = useSelector((state) => state.loginSlice.token);


    const operationaldispostionData = useSelector((state) => (state.BlueCoastSlice?.operationaldispostionData?.operational_disposition ? state.BlueCoastSlice?.operationaldispostionData?.operational_disposition : {}))

    const dispatch = useDispatch();

    const [dispositionSelectValueModal, setDispositionSelectValueModal] = useState(false);
  const [specialEntityInfo, setspecialEntityInfo] = useState(null)
  const [dispositionSucessLoader, setDispositionSucessLoader] = useState(false);



    const [manageDistModalOpen, setManageDistOpenModal] = useState(false)
    const [manageDist, setManageDist] = React.useState('');
  const [ipInfo, setIpInfo] = useState(null)

  const userInfo = useSelector((state) => (state.loginSlice && state.loginSlice?.userInfo) ? state.loginSlice?.userInfo : null)




  useEffect(() => {
    if (click == true && rowData !== null) {
      setIpInfo(rowData)
      setManageDist('')
      disposData()
    }

  }, [click])


  const disposData = async (event) => {
    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: true,
      }))
    await dispatch(operationalDataLoad({ _id: rowData?._id ,source: "bluecoast_leads", token: token}))



    setManageDistOpenModal(true)

    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: false,
      }))

  }


  useEffect(() => {
    if (operationaldispostionData && operationaldispostionData.operational_disposition_name) {
      setManageDist(operationaldispostionData.operational_disposition_name);
    }
  }, [operationaldispostionData])



  const handleCloseDistManageModal = () => {
    setManageDistOpenModal(false);
    dispatch(setReloadTableFlag({ tableId: 'bluecoastdata' }))
    setManageDist('Agent submission form')
    dispatch(clearoperationaldispostionData())
    // dispatch(deleteTempSearchEndpoint({ tableId: 'bluecoastdata' }))

  };

  const handleChange =  (event) => {
    // console.log("event.target.value", event.target.value);
    setManageDist(event.target.value);
    // if (manageDist && event.target.value && event.target.value != manageDist) {
  // };
  }
  const submitDispAfterContract = async()=>{

    let dataSet = {
      "addedby": userInfo._id,
      "addedfor": ipInfo._id,
      "operational_disposition_name": manageDist,
      "action_added_time_human": timeConverter({
        'time': Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      })
    }
    setDispositionSucessLoader(true)
    await dispatch(operationaldispositionadd(dataSet))

    dispatch(setSnackbar({ open: true, message: 'Operational Disposition Updated Successfully', status: 'success' }))
    setManageDistOpenModal(false);
     dispatch(setReloadTableFlag({ tableId: 'bluecoastdata' }))
    setManageDist('Agent submission form')
    dispatch(clearoperationaldispostionData())
    setTimeout(() => { setDispositionSucessLoader(false) }, 1000)
  }


  return (
   <>
   
         {/* ************************Manage Disposion after contract signed Modal*******************************/}

         <Modal className='mngDispotn_modal All_Noteswrpr'
            open={manageDistModalOpen}
            onClose={handleCloseDistManageModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className='modalBaseBox mediumModal'>
              <Tooltip title='Close' >
                <IconButton onClick={handleCloseDistManageModal}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div>
                <h2 className='previewModalTitle '>
                  {/* <h1>{JSON.stringify(dispositionData)}</h1> */}
                  {ipInfo?.prospectfullname && <div className='modal_title modal_titlenew'> Manage Operational Disposition for {ipInfo.prospectfullname}</div>}
                  {/* <h1>{JSON.stringify(ipInfo)}</h1> */}
                </h2>
                <div className='inputBlock inputBlock3line'>

                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select A Operational Disposition</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={manageDist}
                        label="Manage Disposition"
                        onChange={handleChange}
                      >
{/*                       
                        <MenuItem value={'Agent submission form'}>Agent submission form</MenuItem>
                        <MenuItem value={'Submission form review and directive provided (parking point pending outlined next step, clarify, more info, send to beto cold process, travis warm up, book call with jason )'}>Submission form review and directive provided (parking point pending outlined next step, clarify, more info, send to beto cold process, travis warm up, book call with jason )</MenuItem>
                        <MenuItem value={'Agent review call'}>Agent review call</MenuItem>
                        <MenuItem value={'Prospect call (warm up or closing)'}>Prospect call (warm up or closing)</MenuItem>
                        <MenuItem value={'Contract send'}>Contract send</MenuItem> */}



                        
                        <MenuItem value={'Prospect contract review and kickoff call'}>Prospect contract review and kickoff call</MenuItem>
                        <MenuItem value={'Prospect delivery of adhc contract, payor contract, emr and clearing house access'}>Prospect delivery of adhc contract, payor contract, emr and clearing house access</MenuItem>
                        <MenuItem value={'Adhc software run and result produced'}>Adhc software run and result produced</MenuItem>
                        <MenuItem value={'Prospect call to review findings and initiate collections efforts'}>Prospect call to review findings and initiate collections efforts</MenuItem>
                        <MenuItem value={'Initial moneies posted (setting precedent)'}>Initial moneies posted (setting precedent)</MenuItem>
                        <MenuItem value={'Settlement offer received from payor(s)'}>settlement offer received from payor(s)</MenuItem>
                        <MenuItem value={'Settlemengt review call with client  and agreed upon action taken'}>settlement review call with client  and agreed upon action taken</MenuItem>
                        <MenuItem value={'Settlement accepted'}>settlement accepted</MenuItem>
                        <MenuItem value={'Payment executed by client and initial batch closed'}>Payment executed by client and initial batch closed</MenuItem>
                        <MenuItem value={'Next step (additional service)'}>Next step (additional service)</MenuItem>

                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="Formsubmit_button modal_sbBtn">
              <Button
                variant="contained"
                onClick={() => submitDispAfterContract()}
              >
                Submit
              </Button>
            </div>
              </div>
              {dispositionSucessLoader ? <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box> : ''}

            </Box>
          </Modal>
   
   
   
   </>
  )
}

export default DispositionAfterContract