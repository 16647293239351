import { LinearProgress, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../../listing/listing'
import { TableSkeleton } from '../../../../../helper/TableSkeleton'
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from 'react-redux';
import { SubEmployerStatus, deleteSubEmployeeList, viewSubEmployersList, viewlocationsListData } from '../../EmployerReducer';
import AddEditSubLogin from './AddEditSubLogin';
import { setEnableRowLoader, setReloadTableFlag } from '../../../../../listing/listReducer';
import PreviewSubLogin from './PreviewSubLogin';
import AddNotesSub from './AddNotesSub';
import DeleteFromList from '../DeleteFromList';
import { setSnackbar } from '../../../../Layout/layoutReducer';
import StatusChangeData from '../StatusChangeData';


function EmployerSubLoginListing() {

    const dispatch = useDispatch()

    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});


    const subEmployerLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)
    const subEmployerListDetails = useSelector((state) => state.EmployerReducer?.subEmployerListData ? state.EmployerReducer.subEmployerListData : null)
    const subEmployeeRowData = useSelector((state) => state.tableSlice.tempRowData.tableForSubEmployerData && Object.keys(state.tableSlice.tempRowData.tableForSubEmployerData).length > 0 ? state.tableSlice.tempRowData.tableForSubEmployerData : {})

    const editSubEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForSubEmployerData?.editsubemployer ? state.tableSlice.customButtonClicked.tableForSubEmployerData.editsubemployer : false)
    const previewSubEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForSubEmployerData?.previewSubEmployee ? state.tableSlice.customButtonClicked.tableForSubEmployerData.previewSubEmployee : false)

    // ---------------------------------------status chnage clciked----------------------
    const statusSubEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForSubEmployerData?.change_status1 ? state.tableSlice.customButtonClicked.tableForSubEmployerData.change_status1 : false)

    // ---------------------notes clicked----------------
    const notesSubEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForSubEmployerData?.notesSubEmployer ? state.tableSlice.customButtonClicked.tableForSubEmployerData.notesSubEmployer : false)

    // ----------------------------Delete button click-----------------------------
    const deleteSubEmployeeClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.tableForSubEmployerData?.deleteEmployee ? state.tableSlice.customButtonClicked.tableForSubEmployerData.deleteEmployee : false)



    // ***********************row data storing in state*********************

    const [rowdatasub, setRowdatasub] = useState({})

    useEffect(() => {
        if (subEmployeeRowData && Object.keys(subEmployeeRowData).length > 0) {
            setRowdatasub(subEmployeeRowData)
        }
    }, [JSON.stringify(subEmployeeRowData)])

    useEffect(() => {
        // console.log("rowdatasub==========>>>>>", rowdatasub);
    }, [rowdatasub])




    // ----------api trigger for the location arrays---------------
    useEffect(() => {
        let reqBody = {

            "condition": {
                "limit": 100,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                employer_id: logedUserData?._id,
                status: true
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false

        }
        dispatch(viewlocationsListData(reqBody))
    }, [])

    // ********************addSub EMployer Open and functioan*************
    const [subEmployerOpen, setSubEmployerOpen] = useState(false)

    const addSubEmployerfnc = () => {
        setSubEmployerOpen(true)
    }

    // **********************edit subemployer clicked***********************
    useEffect(() => {
        if (editSubEmployeeClicked === true) {

            dispatch(
                setEnableRowLoader({
                    tableId: "tableForSubEmployerData",
                    rowId: subEmployeeRowData._id,
                    load: true,
                })
            );

            setSubEmployerOpen(true)


            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForSubEmployerData",
                        rowId: subEmployeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);

        }
    }, [editSubEmployeeClicked])

    // **********************Peview Sub EMployer********************

    const [previewOpen, setPreviewOpen] = useState(false)

    useEffect(() => {
        if (previewSubEmployeeClicked === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForSubEmployerData",
                    rowId: subEmployeeRowData._id,
                    load: true,
                })
            );
            setPreviewOpen(true)
            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForSubEmployerData",
                        rowId: subEmployeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [previewSubEmployeeClicked])

    // ***************************Status changes function************************

    const [statusOpen, setStatusOpen] = useState(false)

    useEffect(() => {
        if (statusSubEmployeeClicked === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForSubEmployerData",
                    rowId: subEmployeeRowData._id,
                    load: true,
                })
            );
            setStatusOpen(true)
            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForSubEmployerData",
                        rowId: subEmployeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [statusSubEmployeeClicked])


    //******************************status change functiaon************************** */
    const statusChnageApiCall = async (val) => {
        let value = val === 1 ? true : false;
        // console.log("value===============>>>>", value)

        let body = {
            "_id": rowdatasub?._id,
            "status": value
        }

        // console.log("body=====>>>>11", body);

        await dispatch(SubEmployerStatus(body))
        // console.log("body=====>>>>", body);

        // setDltLoader(true)

        dispatch(
            setSnackbar({
                open: true,
                message: "Status Updated Succesfully!",
                status: "success",
            })
        );
        setTimeout(() => {

            setStatusOpen(false)
        }, 1000);
        // console.log("rowid==================>>>", rowid);

        setTimeout(() => {
            dispatch(setReloadTableFlag({ tableId: "tableForSubEmployerData" }));

        }, 2000)


    }


    //******************Add Notes************************************* */

    const [notesOpen, setNotesOpen] = useState(false)

    useEffect(() => {
        if (notesSubEmployeeClicked === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForSubEmployerData",
                    rowId: subEmployeeRowData._id,
                    load: true,
                })
            );


            setNotesOpen(true)

            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForSubEmployerData",
                        rowId: subEmployeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [notesSubEmployeeClicked])


    // *********************************delete functioanlity************************
    const [deleteOpen, setDeleteOpen] = useState(false)

    useEffect(() => {
        if (deleteSubEmployeeClicked === true) {
            dispatch(
                setEnableRowLoader({
                    tableId: "tableForSubEmployerData",
                    rowId: subEmployeeRowData._id,
                    load: true,
                })
            );
            setDeleteOpen(true)
            setTimeout(() => {
                dispatch(
                    setEnableRowLoader({
                        tableId: "tableForSubEmployerData",
                        rowId: subEmployeeRowData._id,
                        load: false,
                    })
                );
            }, 1000);
        }
    }, [deleteSubEmployeeClicked])

    const deleteFunctioanl = () => {
        let body = {
            "_id": rowdatasub?._id
        }

        dispatch(deleteSubEmployeeList(body))

        // console.log("body=========>>>>", body)

        // setDltLoader(true)

        setTimeout(() => {
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Sub Employer Deleted Succesfully!",
                    status: "success",
                })
            );
        }, 1000)



        setTimeout(() => {
            setDeleteOpen(false)

        }, 2000);
        // }


        setTimeout(() => {
            dispatch(setReloadTableFlag({ tableId: "tableForSubEmployerData" }));

        }, 2000)
    }


    /*****************************For Resolve*********************** */
    const resolved = useSelector((state) =>
        state.ResolveReducer?.resolved["/view-sub-employer"]
            ? state.ResolveReducer.resolved["/view-sub-employer"]
            : false
    );

    useEffect(() => {
        if (!resolved) {
            dispatch(viewSubEmployersList(tableDataforSubEmployerList.current.reqBody))
        }
    }, [])

    // ====================main table header===============
    var modifyTableHeadersForSubEmployers =
        [
            { val: "name", name: "Full Name" },
            { val: "email", name: "Email" },
            { val: "phone", name: "Phone Number" },
            { val: "title", name: "Title" },
            { val: "full_address", name: "Address" },
            // { val: "city", name: "City" },
            // { val: "state", name: "State" },
            // { val: "zip", name: "Zip" },
            // { val: "company_name", name: "Company Name" },
            {
                val: "status",
                name: "Status",
                // customVal: { false: "Inactive", true: "Active" },
            },
            { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY" },
            { val: "updatedon_datetime", name: "Updated On", type: "datetime", format: "MM/DD/YYYY" },

        ];

    // ======================main table for employee lsit==================

    let tableDataforSubEmployerList = useRef({
        tableId: "tableForSubEmployerData",
        tableTitle: "Sub Employer Lists",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "employer_manage/sub_login_list",
            tableCountEndpoint: "employer_manage/sub_login_list",
        },

        reqBody: {

            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                added_by: logedUserData?._id
            },
            "project": {},
            "token": "",
            "email": "",
            "count": false
        },




        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [30],
        sortFields: ["createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [],
        buttons: [

            {
                id: "editsubemployer",
                type: "other",
                icon_type: "edit_icon",
                label: "Edit",
                name: "edit",
            },

            {
                id: "previewSubEmployee",
                label: "Preview",
                type: "other",
                icon_type: "preview",
                name: "preview",
            },

            // {
            //     id: "change_status1",
            //     type: "other",
            //     label: "Change Status",
            //     name: "toggle_on_icon",
            //     condField: "inactive",
            //     condVal: 2,
            // },

            {
                id: "change_status1",
                type: "other",
                label: "Change Status",
                name: "toggle_on_icon",
            },

            {
                id: "notesSubEmployer",
                label: "Notes",
                type: "other",
                icon_type: "newspaper_icon",
                name: "newspaper_icon",
                showcount: true,
                countFiled: "notes_count",
            },

            {
                id: "deleteEmployee",
                label: "Delete",
                type: "other",
                name: "delete_icon",
            },


        ],
        deleteModal: {
            modalClassName: "modalblock dltStsModal",
        },


        searchData: {
            heading: " Search Sub Employer List",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [


                    {
                        id: 0,
                        label: "Search by Name and Email",
                        name: "name",
                        type: "autoComplete",
                        className: "list_search_item countDiv__column--col6 ",
                        base_url:
                            process.env.REACT_APP_API_URL + "employer_manage/sublogin_name_email_autocomplete",
                        reqBody: {
                            condition: {
                                limit: 30,
                                skip: 0,
                            },
                            sort: {
                                type: "asc",
                                field: "createdon_datetime",
                            },
                            searchcondition: {
                                added_by: logedUserData?._id,
                                label: {
                                    $regex: "",
                                    $options: "i"
                                }
                            },
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },


                    {
                        id: 3,
                        label: "Search by Status",
                        name: "status",
                        type: "select",
                        values: [
                            { val: 1, name: "Active" },
                            { val: 0, name: "Inactive" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: "list_search_item countDiv__column--col6 ",
                        payloadFormat: { key: "status" },
                    },

                    {
                        id: 8,
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker list_search_item countDiv__column--col6',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                ]
            }
        },

    });


    return (
        <>
            <div className="rolelist_mainwrp Manage_location">
                <div className="listing_heading ">
                    <h1>Sub Employer List</h1>

                    <Tooltip title="ADD USER">
                        <button
                            className="addrole_btn"
                            onClick={() => addSubEmployerfnc()}
                        >
                            <AddIcon />
                        </button>
                    </Tooltip>
                </div>


                {subEmployerLoader ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}

                <div className='rolelist_mainwrp_Table'>
                    {!subEmployerLoader ? (
                        <div>
                            {Object.keys(subEmployerListDetails).length > 0 && <ListingTable
                                tableData={tableDataforSubEmployerList.current}
                                dataset={subEmployerListDetails}
                                modifyHeaders={modifyTableHeadersForSubEmployers}
                            />}
                        </div>
                    ) : (
                        <div><TableSkeleton count={5} /></div>
                    )}


                </div>

            </div>

            {/* ***************************aDD/Edit Employee Modal************************ */}
            {subEmployerOpen ? <AddEditSubLogin
                subEmployerOpen={subEmployerOpen}
                setSubEmployerOpen={setSubEmployerOpen}
                logedUserData={logedUserData}
                subEmployerListDetails={subEmployerListDetails}
                subEmployerReqBody={tableDataforSubEmployerList.current.reqBody}
                editSubEmployeeClicked={editSubEmployeeClicked}
            /> : ""
            }

            {/* ******************************Preview SUbLogin******************************* */}
            {previewOpen ? <PreviewSubLogin
                previewOpen={previewOpen}
                setPreviewOpen={setPreviewOpen}
                previewSubEmployeeClicked={previewSubEmployeeClicked}
            /> : ""}

            {/* ***********************************Status Chnage****************************** */}
            {statusOpen ? <StatusChangeData
                statusOpen={statusOpen}
                setStatusOpen={setStatusOpen}
                statusChnageApiCall={statusChnageApiCall}
            /> : ""}

            {/* *******************************Add notes*************************** */}

            {notesOpen ? <AddNotesSub
                notesOpen={notesOpen}
                setNotesOpen={setNotesOpen}
                notesSubEmployeeClicked={notesSubEmployeeClicked}
            /> : ""}

            {/* ************************Delete Sublogin************************ */}
            {deleteOpen ? <DeleteFromList
                deleteOpen={deleteOpen}
                setDeleteOpen={setDeleteOpen}
                deleteFunctioanl={deleteFunctioanl}
            /> : ""}
        </>
    )
}

export default EmployerSubLoginListing