


import { Form } from "../../../../form/Form";
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { unstable_HistoryRouter, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Alert, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, NativeSelect, OutlinedInput, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from "@mui/icons-material/Close";
import { clearUpdateState, configerComitionEdit, fetchAssociatedPackages, setAffiliateInitData } from "../AffiliateReducer";
import { resetFormData } from "../../../../form/formReducer";
import SnackBarComp from "../../../../commoncomponents/snackbar/snackBar";
import { setEnableRowLoader, setReloadTableFlag } from "../../../../listing/listReducer";
import { clearPreviewData } from "../../userManagement/fetchUserDataReducer";


const ConfigureComitionModal = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 




    // ---- States here -----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    const [modal, setModal] = useState(false);  //*****modal Open toogle ****//

    // ---- Cookies here ----
    const [percentage, setPercentage] = useState({});
    const [aePercentage, setAePercentage] = useState({});
    const [packageName, setPackageName] = useState({});
    const [packagePrice, setPackagePrice] = useState([]);
    let [commisionType, setCommisionType] = useState({});
    const [commisionAmount, setCommisionAmount] = useState({});
    const [aeCommisionAmount, setAeCommisionAmount] = useState({});
    const [comitionData,setComissinData] = useState({})
    const [isDataSetAvalabel,setIsDataSetAvalabel] = useState(false)
    const [paylod,setPaylod]=useState({})
    const [isValidationCurrect,setIsValidationCurrect] = useState("NoteValidet")
    const [snackbarMassage,setSnackBarMassage] = useState(null)
    const [snackBarState,setSnackbarState]=useState(false)
    const [snackbarStatus,setSnackbarstatus] = useState(null)



    // ----------- affiliateSelected -------- //
    const packages = useSelector(state => state.AffiliateReducer?.packageInfo.length > 0 ? state.AffiliateReducer?.packageInfo : []);
    // ------------------------------------- //


    const programeSelected = useSelector(state => state.formSlice?.formData?.ConfigerComition?.associated_programme ? state.formSlice?.formData?.ConfigerComition?.associated_programme : null);


    // ---------- loader ------- //
    const loader = useSelector(state => state.AffiliateReducer?.loading ? state.AffiliateReducer?.loading : false);
    // -------------------- //



    // ------------------snackbar------------------

    const snackbarData = useSelector(state=>state.AffiliateReducer?.snackbarData ? state.AffiliateReducer.snackbarData:{})
    const affiliateAddSuccess = useSelector(state=>state.AffiliateReducer?.affiliateAddSuccess ? state.AffiliateReducer.affiliateAddSuccess:false)

    

    useEffect(()=>{
        if(affiliateAddSuccess === true){
            setTimeout(() => {
            props.togalModalState(false)
            }, 2000);
            dispatch(clearUpdateState())
            dispatch(setReloadTableFlag({tableId :"userslist1"}))
        }
    },[affiliateAddSuccess])


    // useEffect(()=>{
    //     if(props.modalState === false){
    //         dispatch(clearPreviewData())
    //     }
    // },[props.modalState])










    // --- After Selecting programe name package name fetched ---- //
    useEffect(() => {
    if (programeSelected !== null  ) {
      dispatch(fetchAssociatedPackages({ "associated_programme": programeSelected }))
    }
    }, [(programeSelected)])
   // --------------------------------------------------------- //






  useEffect(()=>{
    if(props.chakingData.commissionsdata!== undefined){
        setComissinData(props.chakingData.commissionsdata)
    }
  },[props.chakingData])








  useEffect(()=>{
    if(commisionAmount){
        Object.keys(commisionAmount).map((x,y)=>{
            let TempA={...commisionAmount}
            let Amount = parseFloat(TempA[y])

            TempA[y]=Amount.toFixed(2)
        })
    }
  },[commisionAmount])





    //------------------Submit Button Funtion -----------------   

    function submit() {
        const dataset={}
        setIsValidationCurrect(true)
        
        if(Object.keys(comitionData).length > 0){
            Object.values(comitionData).map((item,index)=>{
                dataset[item.package_name]={
                    package_name:packageName[index]?packageName[index]:undefined,
                    package_price:packagePrice[index]?packagePrice[index]:undefined,
                    ae_commission_type:item?.ae_commission_type,
                    ae_commission_percentage:aePercentage[index]?aePercentage[index]:undefined,
                    ae_commission_amount:aeCommisionAmount[index]?aeCommisionAmount[index]:undefined,
                    affilate_commission_percentage:percentage[index] ? percentage[index] : undefined,
                    affilate_commission_type:commisionType[index]?commisionType[index]:undefined,
                    affilate_commission_value: commisionAmount[index]?commisionAmount[index]:undefined
                }
            })
        }else if(Object.keys(packages).length>0){
            // console.log("packagespackages",packages);
            Object.values(packages).map((item,index)=>{
                dataset[item.package_name]={
                    package_name:packageName[index]?packageName[index]:undefined,
                    package_price:packagePrice[index]?packagePrice[index]:undefined,
                    ae_commission_type:item?.commission_type,
                    ae_commission_percentage:aePercentage[index]?aePercentage[index]:undefined,
                    ae_commission_amount:aeCommisionAmount[index]?aeCommisionAmount[index]:undefined,
                    affilate_commission_percentage:percentage[index] ? percentage[index] : undefined,
                    affilate_commission_type:commisionType[index]?commisionType[index]:undefined,
                    affilate_commission_value: commisionAmount[index]?commisionAmount[index]:undefined
                }
            })
        }
        // console.log("dfjvofdijvfivfd packagespackages",dataset);
        
        setPaylod(dataset)
        // dispatch(configerComitionEdit(dataset))


        if(Object.values(dataset).find((val)=> val.affilate_commission_type === null || val.affilate_commission_type === undefined )){
            setIsValidationCurrect(false)
            setSnackBarMassage("Please select a Commision Type")
            setSnackbarState(true)
            setSnackbarstatus("failed")
            // console.log("paylod Please select a Commision Type",paylod);
        }else if(Object.values(dataset).find((val)=>(val.affilate_commission_type === "non_bps") && (val.affilate_commission_percentage === null || val.affilate_commission_percentage ===undefined || val.affilate_commission_value === undefined || val.affilate_commission_value === null))){
            setIsValidationCurrect(false)
            setSnackBarMassage("Please fill all the field")
            setSnackbarState(true)
            setSnackbarstatus("failed")
            // console.log("paylod Please fill all the field",paylod);
        }else if(Object.values(dataset).find((val)=>(val.affilate_commission_type === "flat") &&  (val.affilate_commission_value === undefined || val.affilate_commission_value === null))){
            setIsValidationCurrect(false)
            setSnackBarMassage("Please fill all the field")
            setSnackbarState(true)
            setSnackbarstatus("failed")
            // console.log("paylod Please fill all the field",paylod);
        }else if(Object.values(dataset).find((val)=>((val.affilate_commission_percentage >= val.ae_commission_percentage) || (val.affilate_commission_value >= val.ae_commission_amount) || (val.affilate_commission_percentage <=0 || val.affilate_commission_value <= 0)))){
            setIsValidationCurrect(false)
            setSnackBarMassage("Please fill proper data")
            setSnackbarState(true)
            setSnackbarstatus("failed")
            // console.log("paylod Please fill proper data");
        }else{
            let data = {"commissionsdata":dataset,"_id":props.id}
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: props.id , load: true }))
            dispatch(configerComitionEdit({data:data}))
            setTimeout(()=>{
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: props.id , load: false }))
            },2000)
        }

    }





    useEffect(()=>{
        if(Object.keys(snackbarData).length > 0){
            if(snackbarData.status === "success"){
                setSnackbarState(true)
                setSnackBarMassage("Affiliate added successfully")
                setSnackbarstatus("success")
                // console.log("status is success");
            }else if(snackbarData.status === "error"){
                setSnackbarState(true)
                setSnackBarMassage("Something went wrong")
                setSnackbarstatus("failed")
                // console.log("status is success");
                }
        }
    },[snackbarData])















    // ------------- modal Close Function here -------- //
    function ModalClose() {
        props.togalModalState(false)
        dispatch(setAffiliateInitData())
        dispatch(resetFormData({formId:"ConfigerComition"}))
    }
    //   -------------- ------------------ //




    // -----------Configer Form Field-------------------
    const ConfigerComition = {
      id: 'ConfigerComition',
      formtype: "add",
      api_url: process.env.REACT_APP_API_URL,
      endPointBodyWrapper: "data",
      msgOnFailedFormSubmission: "Something Went Wrong",
      formWrapperClass: "tblform",
      formInlineCLass: "inlnfrm", 
      formButtonClass: "Formsubmit_button afiliat_comition_configaration_btn",
      resetBtnName: "Reset",
      formAdditionalSubmissionData: {
      },
      fields:[
        {
          id: 4,
          heading: "",
          label: "Choose Program",
          name: "associated_programme",
          type: "autoComplete",
          className: "inputBlock inputBlock4line ",
          base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
          rules: { required: true },
          errorMessage: {
            required: "This Field is Required",
            custom: "Value is Invalid"
          },
          reqBody: {
            "condition": {
              "limit": 10,
              "skip": 0
            },
            "sort": {
              "type": "asc",
              "field": "name"
            },
            "searchcondition": {
              "status": 1,
            }
          },
          sx: { m: 1, minWidth: 300 },
        }
      ],
    }
    // -----------------------------------------------



    //________________ show defolt value funtion ----------------------------------
    useEffect(()=>{
      if(comitionData!==undefined &&  Object.keys(comitionData).length >0){
        let comT = {}
        let comA={}
        let comP={}
        let PakagPrice = {}
        let AeComP={}
        let AecomA={}
        let pakageName={}


        comT = {...commisionType}
        comA = {...commisionAmount} 
        comP={...percentage}
        PakagPrice={...packagePrice}
        AeComP = {...aeCommisionAmount}
        AecomA = {...aePercentage}
        pakageName ={...packageName}
        Object.values(comitionData).map((item,i)=>{
            comT[i]=item?.affilate_commission_type
            comA[i] = parseFloat(item?.affilate_commission_value)
            comP[i] = parseFloat(item?.affilate_commission_percentage)
            PakagPrice[i] = item?.package_price
            AeComP[i] = item?.ae_commission_percentage
            AecomA[i] = item?.ae_commission_amount
            pakageName[i] = item?.package_name
        })
        setCommisionType(comT);
        setCommisionAmount(comA)
        setPercentage(comP)
        setPackagePrice(PakagPrice)
        setAeCommisionAmount(AecomA)
        setAePercentage(AeComP)
        setPackageName(pakageName)
        setIsDataSetAvalabel(true)
      }
    //   console.log("comitionData",comitionData);

    },[comitionData])
    // ----------------------------------------------------------------------------







    // -------------show defolt value for no commition configered data-------------

    useEffect(()=>{
        if(packages!==undefined &&  packages.length >0){
            let comT = {}
            let comA={}
            let comP={}
            let PakagPrice = {}
            let AeComP={}
            let AecomA={}
            let pakageName={}
    
    
            comT = {...commisionType}
            comA = {...commisionAmount} 
            comP={...percentage}
            PakagPrice={...packagePrice}
            AeComP = {...aeCommisionAmount}
            AecomA = {...aePercentage}
            pakageName ={...packageName}
            packages.map((item,i)=>{
                // comT[i]=item?.commission_type
                PakagPrice[i] = item?.package_price
                AeComP[i] = item?.commission_percentage
                AecomA[i] = item?.commission_amount
                pakageName[i] = item?.package_name
            })
            // setCommisionType(comT);
            setCommisionAmount(comA)
            setPercentage(comP)
            setPackagePrice(PakagPrice)
            setAeCommisionAmount(AecomA)
            setAePercentage(AeComP)
            setPackageName(pakageName)
            setIsDataSetAvalabel(true)
          }


    },[packages])


    useEffect(()=>{
        // console.log("isDataSetAvalabel",packages);
    },[packages])











        // ---------------------------Testting Section _--------------------
        useEffect(()=>{
            // console.log("CommisionAmount configerComition",commisionAmount);
        },[commisionAmount])
        
        useEffect(()=>{
            // console.log("commisionType configerComition",commisionType);
        },[commisionType])
    
    
        useEffect(()=>{
            // console.log("percentage configerComition",percentage);
        },[percentage])
    
        useEffect(()=>{
            // console.log("packagePrice configerComition",packagePrice);
        },[packagePrice])

        useEffect(()=>{
            // console.log("aeCommisionAmount configerComition",aeCommisionAmount);
        },[aeCommisionAmount])

        useEffect(()=>{
            // console.log("aePercentage configerComition",aePercentage);
        },[aePercentage])


        useEffect(()=>{
            // console.log("packageName configerComition",packageName);
        },[packageName])






        useEffect(()=>{
            if(snackBarState === false){
                setSnackBarMassage(null)
                setSnackbarstatus(null)
            }
        },[snackBarState])




    return (
        <>


            {loader && <LinearProgress sx={{ marginTop: '10px' }} />}


            <Modal className='modalblock affiliatemodal'
                
                open={props.modalState}
                onClose={() => props.togalModalState(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="BoxMui_modal  other_BoxMui_modal">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Configure Commision for Affiliate
                    </Typography>

                    <div className='SD_affiliate_cls'>
                    { props.chakingData?.commissionsdata === undefined && <Form formData={ConfigerComition} />}


                    {loader && <LinearProgress sx={{ marginTop: '10px' }} />}


                        
                            
                            
                        {( isDataSetAvalabel == true && Object.keys(comitionData).length>0 ) &&  
                         Object.keys(comitionData).length>0 && Object.values(comitionData).map((item, i) => {
                                console.log("commisionAmount[i]",typeof commisionAmount[i] );
                                return (
                                    <>
                                        {(commisionType[i] && commisionType[i] == "non_bps") && (percentage[i] && percentage[i] >= aePercentage[i]) &&
                                            <div className='notifyMsg'>
                                                <p> Note: For {item.package_name} package Percentage should be less than {item.ae_commission_percentage} </p>
                                            </div>}

                                        <div className='package_blocks'>

                                           
                                            <FormControl>
                                                <TextField id="outlined-basic" label="Package Name" name={packageName[i]} disabled={true} variant="outlined" value={packageName[i]} defaultValue={packageName[i]}/>
                                            </FormControl>



                                            <FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount"> Package price</InputLabel>
                                                <OutlinedInput id="outlined-basic" label="Package price"  disabled={true} name="package_price"  type="number" variant="outlined" value={packagePrice[i]}/>
                                            </FormControl>

                                            {commisionType[i] === "non_bps" && (<FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">AE Percentage </InputLabel>
                                                <OutlinedInput  id="outlined-basic"  label="AE Percentage" disabled={true} name="ae_percentage" endAdornment={<InputAdornment position="end">%</InputAdornment>} value={aePercentage[i]} type="number" variant="outlined"/>
                                            </FormControl>)}



                                            {item.ae_commission_amount !== null && item.ae_commission_amount !== undefined && (<FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">AE Commision Amount </InputLabel>
                                                <OutlinedInput  id="outlined-basic" abel="AE Commision Amount " disabled={true}  value={aeCommisionAmount[i]} startAdornment={<InputAdornment position="start">$</InputAdornment>}  type="number" variant="outlined" />
                                            </FormControl>)}


                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">Commision Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={commisionType[i]}
                                                    name={'commission_type' + i}
                                                    label="Commision Type"
                                                    defaultValue={item.affilate_commission_type}
                                                    onChange={(x) => { 
                                                        let tempComissionVal = {}
                                                        tempComissionVal = { ...commisionType }
                                                        tempComissionVal[i.toString()] = x.target.value
                                                        setCommisionType(tempComissionVal)
                                                        setPercentage((prev) => ({ ...prev, [`${i}`]: undefined }))
                                                    }}
                                                >
                                                    <MenuItem value="non_bps">Percentage</MenuItem>
                                                    <MenuItem value="flat">Flat</MenuItem>

                                                </Select>
                                            </FormControl>


                                            {
                                                commisionType[i] && commisionType[i] == "non_bps" &&
                                                (<FormControl>
                                                    <InputLabel htmlFor="outlined-adornment-amount"> Affiliate Percentage </InputLabel>

                                                    <OutlinedInput
                                                        id={i}
                                                        label=" Affiliate Percentage"
                                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                        name="percentage"
                                                        value={(percentage[i] !== NaN && percentage[i] !== undefined)&&percentage[i]}
                                                        onChange={(e) => {
                                                            // setPercentage((prev) => ({ ...prev, [`${i}`]:parseInt( e.target.value) }))
                                                            setPercentage((prev) => ({ ...prev, [`${i}`]:e.target.value }))
                                                            setCommisionAmount((val)=>({...val,[`${i}`]:(packagePrice[i] * e.target.value) /100}))}
                                                        }
                                                        type="number"
                                                        variant="outlined"
                                                    />

                                                    {percentage[`${i}`] && percentage[`${i}`] >= item.ae_commission_percentage && (
                                                        <FormHelperText error id="accountId-error">
                                                            {percentage[`${i}`] && percentage[`${i}`] >= item.ae_commission_percentage && `For ${item.package_name} package Percentage should be less than ${item.ae_commission_percentage}`}
                                                        </FormHelperText>
                                                    )}

                                                </FormControl>)
                                            }




                                            {commisionType[i] && (commisionType[i] == "non_bps" || commisionType[i] == "flat") &&
                                                <FormControl>
                                                    <InputLabel htmlFor="outlined-adornment-amount"> Commision Amount</InputLabel>

                                                    <OutlinedInput autoFocus
                                                        value={(commisionAmount[i] !== NaN && commisionAmount[i] !== undefined) && commisionAmount[i]}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        InputLabelProps={{ shrink: percentage[i] || commisionType[i] == "flat" ? true : false }}
                                                        disabled={commisionType[i] == "non_bps"}
                                                        id="outlined-basic"
                                                        label="Commision Amount"
                                                        name={`${item.package_name}_commision_amount`}
                                                        variant="outlined"
                                                        type="number"                                               
                                                        onChange={(e) => setCommisionAmount((prev) => ({ ...prev, [`${i}`]:e.target.valueAsNumber}))}
                                                    />

                                                    {percentage[i] && percentage[i] >= item.ae_commission_percentage ? (
                                                        <FormHelperText error id="accountId-error">
                                                            {`For ${item.package_name} package amount should be less than ${aeCommisionAmount[i].toFixed(2)}`}
                                                        </FormHelperText>
                                                    ) : commisionAmount[i] && commisionAmount[i]>= aeCommisionAmount[i].toFixed(2) ?
                                                       ( <FormHelperText error id="accountId-error">
                                                            {`For ${item.package_name} package amount should be less than ${aeCommisionAmount[i].toFixed(2)}`}
                                                        </FormHelperText>)
                                                        : ""}


                                                </FormControl>}




                                        </div>
                                    </>
                                )
                            })
                        }

                        {(isDataSetAvalabel === true && packages.length>0) &&

                            packages.map((item,i)=>{
                                return(
                                    <>
                                     {(commisionType[i] && commisionType[i] == "non_bps") && (percentage[i] && percentage[i] >= aePercentage[i]) &&
                                            <div className='notifyMsg'>
                                                <p> Note: For {item.package_name} package Percentage should be less than {item.commission_percentage} </p>
                                            </div>}

                                        <div className='package_blocks'>

                                           
                                            <FormControl>
                                                <TextField id="outlined-basic" label="Package Name" name={packageName[i]} disabled={true} variant="outlined" value={packageName[i]} defaultValue={packageName[i]}/>
                                            </FormControl>



                                            <FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount"> Package price</InputLabel>
                                                <OutlinedInput id="outlined-basic" label="Package price"  disabled={true} name="package_price"  type="number" variant="outlined" value={packagePrice[i]}/>
                                            </FormControl>

                                            {commisionType[i] === "non_bps" && (<FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">AE Percentage </InputLabel>
                                                <OutlinedInput  id="outlined-basic"  label="AE Percentage" disabled={true} name="ae_percentage" endAdornment={<InputAdornment position="end">%</InputAdornment>} value={aePercentage[i]} type="number" variant="outlined"/>
                                            </FormControl>)}



                                            {item.commission_amount !== null && item.commission_amount !== undefined && (<FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">AE Commision Amount </InputLabel>
                                                <OutlinedInput  id="outlined-basic" abel="AE Commision Amount " disabled={true}  value={aeCommisionAmount[i]} startAdornment={<InputAdornment position="start">$</InputAdornment>}  type="number" variant="outlined" />
                                            </FormControl>)}


                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">Commision Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={(commisionType[i] !== null && commisionType !== undefined ) && commisionType[i] }
                                                    name={'commission_type' + i}
                                                    label="Commision Type"
                                                    onChange={(x) => { 
                                                        let tempComissionVal = {}
                                                        tempComissionVal = { ...commisionType }
                                                        tempComissionVal[i.toString()] = x.target.value
                                                        setCommisionType(tempComissionVal)
                                                        setPercentage((prev) => ({ ...prev, [`${i}`]: undefined }))
                                                    }}
                                                >
                                                    <MenuItem value="non_bps">Percentage</MenuItem>
                                                    <MenuItem value="flat">Flat</MenuItem>

                                                </Select>
                                            </FormControl>


                                            {
                                                commisionType[i] && commisionType[i] == "non_bps" &&
                                                (<FormControl>
                                                    <InputLabel htmlFor="outlined-adornment-amount"> Affiliate Percentage </InputLabel>

                                                    <OutlinedInput
                                                        id={i}
                                                        label=" Affiliate Percentage"
                                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                        name="percentage"
                                                        value={(percentage[i] !== NaN && percentage[i] !== undefined)&&percentage[i]}
                                                        onChange={(e) => {
                                                            // setPercentage((prev) => ({ ...prev, [`${i}`]:parseInt( e.target.value) }))
                                                            setPercentage((prev) => ({ ...prev, [`${i}`]:e.target.value }))
                                                            setCommisionAmount((val)=>({...val,[`${i}`]:(packagePrice[i] * e.target.value) /100}))}
                                                        }
                                                        type="number"
                                                        variant="outlined"
                                                    />

                                                    {percentage[`${i}`] && percentage[`${i}`] >= item.commission_percentage && (
                                                        <FormHelperText error id="accountId-error">
                                                            {percentage[`${i}`] && percentage[`${i}`] >= item.commission_percentage && `For ${item.package_name} package Percentage should be less than ${item.commission_percentage}`}
                                                        </FormHelperText>
                                                    )}

                                                </FormControl>)
                                            }




                                            {commisionType[i] && (commisionType[i] == "non_bps" || commisionType[i] == "flat") &&
                                                <FormControl>
                                                    <InputLabel htmlFor="outlined-adornment-amount"> Commision Amount</InputLabel>

                                                    <OutlinedInput autoFocus
                                                        value={(commisionAmount[i] !== NaN && commisionAmount[i] !== undefined) && commisionAmount[i]}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        InputLabelProps={{ shrink: percentage[i] || commisionType[i] == "flat" ? true : false }}
                                                        disabled={commisionType[i] == "non_bps"}
                                                        id="outlined-basic" 
                                                        label="commission Amount"
                                                        name={`${item.package_name}_commision_amount`}
                                                        variant="outlined"
                                                        type="number"                                                     
                                                        onChange={(e) => setCommisionAmount((prev) => ({ ...prev, [`${i}`]: e.target.valueAsNumber }))}
                                                    />

                                                    {percentage[i] && percentage[i] >= aePercentage[i] ? (
                                                        <FormHelperText error id="accountId-error">
                                                            {`For ${item.package_name} package amount should be less than ${aeCommisionAmount[i].toFixed(2)}`}
                                                        </FormHelperText>
                                                    ) : commisionAmount[i] && commisionAmount[i] >= aeCommisionAmount[i].toFixed(2) ?
                                                       ( <FormHelperText error id="accountId-error">
                                                            {`For ${item.package_name} package amount should be less than ${aeCommisionAmount[i].toFixed(2)}`}
                                                        </FormHelperText>)
                                                        : ""}


                                                </FormControl>}




                                        </div>
                                       
                                    </>
                                )
                            })
                        }


                        


                        <div className='btnSection'>
                            <Button variant="contained" onClick={submit}>Submit</Button>
                        </div>
                    </div>
                    <Tooltip title="Close" placement="right-start">
                        <IconButton className="modalCloseBtn" onClick={ModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                </Box>

            </Modal>



                   { snackBarState === true &&   <SnackBarComp 
                                setSnackFire={setSnackbarState}
                                resMessage={snackbarMassage}
                                snackFire={snackBarState}
                                endpointResStatus={snackbarStatus}
                        />}
            


        </>
    )
}

export default ConfigureComitionModal;









