import React, { useEffect, useState } from "react";
import { redirect, useLocation, useParams } from "react-router-dom";
import '../../Frontend/NDAContractForm/NDAContract.css'
import W9Form from "./W9Form";
import NDAContractForm from "../../Frontend/NDAContractForm/NDAContractForm";
import { Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

function DownloadW9NDA(props) {
    const { _id } = useParams();
    const [loading, setloading] = useState(false);

    const location = useLocation();
    const path = location?.pathname?.split('/');



    return (
        <>

            <W9Form />

            <NDAContractForm />


            <div className="w9_btn_wrp">
                <Button onClick={() => window.print()} className="w9_btn downloadBtn" sx={{ width: "auto !important" }}>
                    <DownloadIcon /> Download
                </Button>
            </div>



        </>
    );
}

export default DownloadW9NDA;
