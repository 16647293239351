import { FemaleSharp } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { trusted } from "mongoose";

export const affiliateConversionReportList = createAsyncThunk("affiliate/affiliate-conversion-report-list", async (reqBody) => {

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/affiliate-conversion-report-list-count", reqOption);
    const respdata = await response.json();

    return respdata;
});




export const affiliateFilterConversionReportList = createAsyncThunk("affiliate/affiliate-filter-conversion-report-list", async (reqBody) => {

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/affiliate-conversion-report-list-count", reqOption);
    const respdata = await response.json();

    return respdata;
});












//  ============ detailedConversionReportAffiliateListCount ======= //
export const detailedConversionReportAffiliateListCount = createAsyncThunk("affiliate/detailedConversionReportAffiliateListCount", async (reqBody) => {

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/conversion-detailed-report-affiliate-list-count", reqOption);
    const respdata = await response.json();

    return respdata;
});
// =============================================================== //






//  ============= detailedConversionFiltredReportAffiliateListCount =========== //
export const detailedConversionFiltredReportAffiliateListCount = createAsyncThunk("affiliate/ConversionFilterReportAffiliateList", async (reqBody) => {

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/conversion-detailed-report-affiliate-list-count", reqOption);
    const respdata = await response.json();

    return respdata;
});
//  ===================================================================== //



//  ============= detailedConversionReportCount =========== //
export const detailedConversionReportCount = createAsyncThunk("affiliate/detailedConversionReportCount", async (reqBody) => {

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/conversion-detailed-report-affiliate-list-count", reqOption);
    const respdata = await response.json();

    return respdata;
});
//  ===================================================================== //




//  ============= conversionPieChart =========== //
export const conversionPieChart = createAsyncThunk("campaign-manage/conversionpiechart", async (reqBody) => {

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "campaign-manage/paichardata", reqOption);
    const respdata = await response.json();

    return respdata;
});
//  ===================================================================== //






const AfffiliateConverisonReport = createSlice({
    name: 'AfffiliateConverisonReport',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',

        affiliateConversionReportListData: [],
        affiliateConversionFilterReportListData: [],



        conversionReportListDataSuccess: false,

        detailedConversionReportAffiliate: [],
        detailedConversionReportSuccess: false,

        detailedConversionFiltredReportData: [],

        ConversionDetailedReportCount: null,

        pieChartData: [],
        piechartloader: false,

        dataloading:false,

    },
    reducers: {
        resetInitData(state, action) {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.successMsg = null;
            state.errorMsg = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';

            state.affiliateConversionReportListData = [];
            state.conversionReportListDataSuccess = false;

            state.detailedConversionReportAffiliate = [];
            state.detailedConversionReportSuccess = false;

            state.detailedConversionFiltredReportData = [];
            state.pieChartData = [];
            state.dataloading=false;


        },

        resetAllListData(state, action) {
            state.detailedConversionReportAffiliate = [];
        },

        resetFilterData(state, action) {
            state.detailedConversionFiltredReportData = [];
            state.detailedConversionReportSuccess = false;

        },

        clearSnackdata(state, action) {
            state.snackbarData = {}
        },

    },
    extraReducers: {




        //  ==================  affiliateConversionReportList =============== //
        [affiliateConversionReportList.pending]: (state, action) => {
            state.loading = true
            state.affiliateConversionReportListData = []
            state.conversionReportListDataSuccess = false
        },
        [affiliateConversionReportList.fulfilled]: (state, action) => {
            // console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.affiliateConversionReportListData = action.payload?.results?.res ? action.payload.results.res : [];
            state.conversionReportListDataSuccess = true;
            if (action.payload.status === "error") {
                state.conversionReportListDataSuccess = false;
                state.errorMsg = action.payload?.results ? action.payload.results : "Something went wrong!!"
            }
        },
        [affiliateConversionReportList.rejected]: (state, action) => {
            state.loading = false
            state.conversionReportListDataSuccess = false;
            state.affiliateConversionReportListData = []
        },
        // =============================================================== //






        //  ==================  affiliateFilterConversionReportList =============== //
        [affiliateFilterConversionReportList.pending]: (state, action) => {
            state.loading = true
            state.affiliateConversionReportListData = []
            state.conversionReportListDataSuccess = false
        },
        [affiliateFilterConversionReportList.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.affiliateConversionReportListData = action.payload?.results?.res ? action.payload.results.res : [];
            state.conversionReportListDataSuccess = true;
            if (action.payload.status === "error") {
                state.conversionReportListDataSuccess = false;
                state.errorMsg = action.payload?.results ? action.payload.results : "Something went wrong!!"
            }
        },
        [affiliateFilterConversionReportList.rejected]: (state, action) => {
            state.loading = false
            state.conversionReportListDataSuccess = false;
            state.affiliateConversionReportListData = []
        },
        // =============================================================== //
        










        // ============================= detailedConversionReportAffiliateListCount ======================== //
        [detailedConversionReportAffiliateListCount.pending]: (state, action) => {
            state.loading = true
            state.detailedConversionReportAffiliate = []
        },
        [detailedConversionReportAffiliateListCount.fulfilled]: (state, action) => {

            state.loading = false;
            state.success = true;
            state.detailedConversionReportAffiliate = action.payload?.results?.res ? action.payload.results.res : [];


            if (action.payload.status === "error") {
                state.errorMsg = action.payload?.results ? action.payload.results : "Something went wrong!!"
            }
        },
        [detailedConversionReportAffiliateListCount.rejected]: (state, action) => {
            state.loading = false

            state.detailedConversionReportAffiliate = []
        },
        // =============================================================== //







        // ============================= detailedConversionFiltredReportAffiliateListCount ======================== //
        [detailedConversionFiltredReportAffiliateListCount.pending]: (state, action) => {

            state.loading = true
            state.success = false;
            state.detailedConversionReportAffiliate = []
            state.detailedConversionFiltredReportData = []
            state.detailedConversionReportSuccess = false

        },
        [detailedConversionFiltredReportAffiliateListCount.fulfilled]: (state, action) => {

            state.loading = false;
            state.success = true;
            state.detailedConversionFiltredReportData = action.payload?.results?.res ? action.payload.results.res : [];
            state.detailedConversionReportSuccess = true;

            if (action.payload.status === "error") {
                state.detailedConversionReportSuccess = false;
                state.errorMsg = "Something went wrong!!"
            }

        },
        [detailedConversionFiltredReportAffiliateListCount.rejected]: (state, action) => {

            state.loading = false
            state.detailedConversionReportSuccess = false;
            state.detailedConversionFiltredReportData = [];

        },
        // =============================================================== //






        // ============================= detailedConversionReportCount ======================== //
        [detailedConversionReportCount.pending]: (state, action) => {

            state.success = false;
            state.ConversionDetailedReportCount = null


        },
        [detailedConversionReportCount.fulfilled]: (state, action) => {

            state.success = true;
            state.ConversionDetailedReportCount = action.payload?.count ? action.payload.count : null;


            if (action.payload.status === "error") {
                state.detailedConversionReportSuccess = false;
                state.errorMsg = "Something went wrong!!";
                state.ConversionDetailedReportCount = null;
            }

        },
        [detailedConversionReportCount.rejected]: (state, action) => {

            state.ConversionDetailedReportCount = null;

        },
        // =============================================================== //





        // ============================= conversionPieChart ======================== //
        [conversionPieChart.pending]: (state, action) => {
            state.dataloading = true;
            state.pieChartData = [];
            state.piechartloader = true
        },
        [conversionPieChart.fulfilled]: (state, action) => {


            state.dataloading = false;
            state.pieChartData = action.payload?.results?.res ? action.payload.results.res : [];
            state.piechartloader = false

            if (action.payload.status === "error") {
                state.dataloading = false;
                state.pieChartData = [];
                state.errorMsg = "Something went wrong!!";
                state.piechartloader = false
            }

        },
        [conversionPieChart.rejected]: (state, action) => {
            state.dataloading = false;
            state.pieChartData = [];
            state.piechartloader = false

        },
        // =============================================================== //




    }
});



export default AfffiliateConverisonReport.reducer;
export const { resetAllListData, resetInitData, resetFilterData } = AfffiliateConverisonReport.actions;