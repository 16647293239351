import { Button, IconButton, LinearProgress, Modal, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close"
import { useSelector } from 'react-redux'


function StatusChangeData({ statusOpen, setStatusOpen, statusChnageApiCall }) {

    const employeeLoader = useSelector((state) => state.EmployerReducer?.statusloading ? state.EmployerReducer.statusloading : null)


    const handleCloseStatusMOdal = () => {
        setStatusOpen(false)
    }
    return (
        <>
            <Modal
                className="modalblock TaskStatusChange"
                open={statusOpen}
                onClose={handleCloseStatusMOdal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    border: '10px solid #0569ad!important',
                    background: '#fff',
                    boxShadow: 24,
                    textAlign: "center",
                }}>
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseStatusMOdal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography id="modal-modal-title" variant="h5" component="h5">

                        <p className='alertPara2'> Alert !!</p>

                        <p> Are You Sure You Want To Change Status? </p>
                    </Typography>
                    <div>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => statusChnageApiCall(1)} className="modalBTN">Active</Button>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => statusChnageApiCall(0)} className="modalBTN">Incative</Button>

                    </div>

                    {employeeLoader && <LinearProgress sx={{ marginTop: '10px' }} />}



                </Box>
            </Modal>
        </>
    )
}

export default StatusChangeData