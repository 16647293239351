import React, { useEffect } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Dialog, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { Form } from '../../form/Form';
import CloseIcon from "@mui/icons-material/Close";
import { setSnackbar } from '../Layout/layoutReducer';
import { useCookies } from 'react-cookie';
import store from '../../store';
import { setFormFieldError } from '../../form/formReducer';
import { cleareditdata, addusernamedata } from './../CampaignManagement/CampaignReducer';
import { setUSername } from '../login/loginReducer';


function UserNameCreate({ open, close, user_id }) {
  console.log("ALL DATA ======>>")
  let user_name = null;
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [loader, setLoader] = useState(false)
  const [redundatecall, setredundatecall] = useState(false)
  const dispatch = useDispatch();

  const formSubmissionState = useSelector((state) => state.formSlice.formSubmissionState.UserNameCreate ? state.formSlice.formSubmissionState.UserNameCreate : 0)
  const formdeta = useSelector(state => (state.formSlice?.formData?.UserNameCreate) ? state.formSlice.formData.UserNameCreate : null)
  const adduseronstate = useSelector(state => state.camPaignManagementReducer.addusernamestate ? state.camPaignManagementReducer.addusernamestate : 0);
  const user_data = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);


  const dataform = {

    id: 'UserNameCreate',
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "campaign-manage/set-username",
    endPointBodyWrapper: "data",
    urlPathOnSuccessfulFormSubmission: '',
    submitBtnName: "Submit",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
    },
    fields: [
      {
        id: 3,
        type: "html",
        className: "domainUrlField",
        // value: process.env.REACT_APP_DOMAIN_URL
        value: 'https://accessfreepharmacy.com/',
      },
      {
        id: 0,
        // heading: "Unique URL",
        name: "user_name",
        className: "inputBlock inputBlock3line uniq_URL",
        label: "Type Here",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // adornments: {
        //   type: "prefix",
        //   content: `<span>${process.env.REACT_APP_DOMAIN_URL}</span>`,
        //   position: "start"
        // },
        // adornments: {
        //   type: "suffix",
        //   content: `<span>X</span>`,
        //   // value: <p>{process.env.REACT_APP_DOMAIN_URL}</p>,
        //   position: "end"
        // },
      },
      // {
      //   id: 2,
      //   type: "html",
      //   className: "commonText",
      //   value: "<span>X</span>"
      // },

      {
        id: 22,
        type: "html",
        className: "passwordCls urlHints inputBlock1line ",
        value: `
        <p><strong>EXAMPLE: IF YOUR UNIQUE URL IS "Smith" THEN YOUR CAMPAIGN FOR A SOCIAL MEDIA PLATFORM OR EMAIL CAMPAIGN COULD BE :</strong></p> 
        <div class="HINT_urlwrapper"> 
        <span>https://accessfreepharmacy.com/Smith1</span>   
        <span>https://accessfreepharmacy.com/Smith2</span> 
        <span>https://accessfreepharmacy.com/Smith3</span> 
        </div>
        <h6> Note: Special Characters Are Not Accepted</h6>
        <p><strong> *Note: Please Choose Your Unique URL Carefully. This Cannot Be Changed in Future.</strong></p>`
        
      },

      {
        id: 1,
        heading: "Unique User ID",
        lable: "Unique User ID",
        name: "_id",
        className: "inputBlock inputBlock3line",
        type: "hidden",
        defaultValue: user_id
      },
    ]

  }




  useEffect(() => {
    console.log("formSubmissionState", formSubmissionState, formdeta);
    if (formdeta !== undefined && formdeta !== null && formdeta.user_name !== undefined && formdeta.user_name !== null && !redundatecall) {
      console.log("formSubmissionState", formSubmissionState, formdeta);
      if (formSubmissionState && formSubmissionState == 4) {
        calladdusernameapi(formdeta)
      }
      return
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (format.test(formdeta.user_name)) {
        // dispatch(setFormFieldError({ formId: 'UserNameCreate', fieldName: "user_name", error: { type: 'custom' } }))
      } else {
        if (formSubmissionState && formSubmissionState == 4) {
          calladdusernameapi(formdeta)
        }
      }
    }

  }, [formSubmissionState, JSON.stringify(formdeta)])



  const calladdusernameapi = async (val) => {
    val = { ...val }
    let user_name = val.user_name.trim();
    console.log("val.user_name.trim(),", val.user_name.trim(), user_name);
    
    let body = {
      data: {
        "user_name": val.user_name.trim(),
        "_id": user_id,
      }
    }
    // console.log("body-----", body);
    if (Object.keys(body).length > 0 && user_name) {
      setLoader(true)
      let resposeofusernamecrete = await store.dispatch(addusernamedata(body));
      setLoader(false)
      console.log("resposeofusernamecrete++", resposeofusernamecrete);
      if (resposeofusernamecrete && resposeofusernamecrete.payload && resposeofusernamecrete.payload.status && resposeofusernamecrete.payload.status == "error") {
        dispatch(setSnackbar({ open: true, message: resposeofusernamecrete.payload.message ? resposeofusernamecrete.payload.message : "This Unique URL is already taken.Please try another Unique URL", status: 'error' }));
        // setTimeout(() => {dispatch(setSnackbar({ open: true, message: "This Unique URL is already taken.Please try another Unique URL", status: 'error' }))}, 2000);
        // dispatch(setSnackbar({ open: true, message: "This Unique URL is already taken.Please try another Unique URL", status: 'error' }))
      } else {
        // store.subscribe(() => {
        // const storedata = store.getState();
        // if (
        //   storedata?.loginSlice?.userInfo !== undefined &&
        //   storedata?.loginSlice?.userInfo?._id !== null &&
        //   storedata?.loginSlice?.userInfo?._id !== undefined
        // ) {
        const objTwo = { ...user_data, user_name }

        setuserInfoCookie(
          "userinfo",
          JSON.stringify(objTwo),
          { path: "/" }
        );
        dispatch(setUSername(user_name))


        // }


        // });
        setredundatecall(true)
        dispatch(setSnackbar({ open: true, message: "Your Unique URL Created Successfully", status: 'success' }));
        close(1, user_name);
      }



    }

  }

  // useEffect(() => {
  //   if (adduseronstate == 2) {
  //     dispatch(setSnackbar({ open: true, message: "User Name Create Successfully !", status: 'success' }));
  //   }

  // }, [adduseronstate])


  return (
    <div>

      <div className='ddssfs'>

        <Dialog
          className="hmmodal modoal_for_CampaignUrl"
          open={open}
          onClose={() => close}

        >
          {/* <Tooltip title='Close' >
            <Button onClick={close} className="hmmodalCloseBTN"> <CloseIcon /> </Button>
          </Tooltip> */}
          <Box className='hmmodal_user_Box' >

            <div className='hmmodal_user_Box_wrapper' >
              <h3>Create Your Unique URL </h3>

              <div className='mainwrpr_unique_url'>
                {/* <p>{process.env.REACT_APP_DOMAIN_URL}</p> */}
                <Form formData={dataform} />
              </div>
              {loader && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}

            </div>

          </Box>
        </Dialog>
      </div>
    </div>
  )
}


export default UserNameCreate