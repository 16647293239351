
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Form } from "../../../../../form/Form"
import { removeField, setField, setTempFormUpdateData } from "../../../../../form/formReducer";
import { resolvefunction } from "../../../../../helper/helperFunctions";
import { getusersList } from "../../../userManagement/userList/userListReducer";
import { clearDeleteData, getCommissionConfigList } from "../../commissionReducer";





const AddCommissionForm = (props) => {

  // console.log("props CommissionAddEditForm", props);
  const dispatch = useDispatch()
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const location = useLocation()

  // console.warn("props", props)

  // console.log("current location",location);


  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.commissionConfigForm) ? state.formSlice.formSubmissionState.commissionConfigForm : false);
  const snackMsg = useSelector(state => (state.formSlice?.message?.commissionConfigForm) ? state.formSlice.message.commissionConfigForm : null);
  const leadData = useSelector((state) => state.commissionReducer?.fetchLeadInfo ? state.commissionReducer.fetchLeadInfo : [])
  const AeData = useSelector((state) => state.commissionReducer?.fetchedAeInfo ? state.commissionReducer.fetchedAeInfo : [])
  const commissionFetchData = useSelector((state) => state.commissionReducer?.commissionSingleData ? state.commissionReducer.commissionSingleData : [])

  const is_lead_info = useSelector(state => (state.formSlice?.formData?.AddFormCommission?.have_lead_info) ? state.formSlice.formData.AddFormCommission.have_lead_info : null);


  // ------- Global Settings Data ------- // 
  const globalSettingsData = useSelector((state) => state.commissionReducer?.globalSettingsData ? state.commissionReducer.globalSettingsData : [])
  // --------------------------------- // 

  // ---- afterEndpoint Call Comission Config data --- ///
  const commissionConfigInfoo = useSelector(state => (state.commissionReducer?.commissionConfigInfo) ? state.commissionReducer.commissionConfigInfo : []);
  // -------------------------------------------------- //

  //  ------ payout amount from form ----- // 

  const payout_amount = useSelector(state => (state.formSlice?.formData?.AddFormCommission?.payout_amount) ? state.formSlice?.formData?.AddFormCommission?.payout_amount : null);

  // --------------------------------- // 

  //---------------------------get login user id--------- //
  let userID = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null);
  // ------------------------------------------------- // 

  //----------fetch Commission type data from  form fields----------------------- //
  const searchCommissionType = useSelector((state) => state.formSlice?.formData?.commissionConfigForm?.commission_type ? state.formSlice.formData.commissionConfigForm.commission_type : null
  );
  // -------------------------------------------------------------------------- //

  // ----- On goBackBtnClick  ---- //
  const goBackBtnClick = useSelector(state => (state.formSlice.customButtonClicked?.AddFormCommission && state.formSlice.customButtonClicked?.AddFormCommission?.comGoBack) ? state.formSlice.customButtonClicked.AddFormCommission.comGoBack : false);
  const formSubmissionState = useSelector(state => (state.formSlice.formSubmissionState?.AddFormCommission && state.formSlice.formSubmissionState?.AddFormCommission) ? state.formSlice.formSubmissionState.AddFormCommission : null);

  // --------------------------- //





  // ------ on sucessfull form submission ----- //
  useEffect(() => {
    if (formSubmissionState === 2) {

      dispatch(clearDeleteData())
    }
  }, [formSubmissionState])
  // ------------------------------------ //


  // ------ gobackButton Click -------
  useEffect(() => {
    if (goBackBtnClick === true) {
      dispatch(clearDeleteData())

      navigate("/commission-listing")

    }
  }, [goBackBtnClick])
  // ----------------------------




  //  ------ After endpoint call based on lead selection ------- //

  useEffect(() => {
    if (leadData && leadData.length > 0) {

      console.warn("lead data 3===>>>>", leadData)
      formData.current.formAdditionalSubmissionData['associated_rep'] = leadData[0]?.rep_id ? leadData[0].rep_id : undefined;
      formData.current.formAdditionalSubmissionData['associated_hm'] = leadData[0]?.hiring_manager ? leadData[0].hiring_manager : undefined;
      formData.current.formAdditionalSubmissionData['associated_bdd'] = leadData[0]?.associated_bdd ? leadData[0].associated_bdd : undefined;
      formData.current.formAdditionalSubmissionData['associated_aer'] = leadData[0]?.aer_id ? leadData[0].aer_id : undefined;
      formData.current.formAdditionalSubmissionData['conversion_datetime'] = leadData[0]?.conversion_datetime ? leadData[0].conversion_datetime : undefined;

      formData.current.fields.forEach((x) => x['name'] === 'associated_programme' ? x['defaultValue'] = leadData[0].associated_program
        : undefined)
      // {x['name']==='associated_programme'? x['defaultValue'] = leadData[0].associated_programme : ""}
      // x['defaultValue'] = leadData[0].associated_programme

      setTimeout(() => {
        dispatch(setTempFormUpdateData({
          formId: "AddFormCommission", fieldName: "associated_hm", value: leadData[0]?.hiring_manager
            ? leadData[0].hiring_manager : '',
        }))
      }, 500);


      setTimeout(() => {
        dispatch(setTempFormUpdateData({
          formId: "AddFormCommission", fieldName: "associated_rep", value: leadData[0]?.rep_id
            ? leadData[0].rep_id
            : '',
        }))
      }, 500);


      setTimeout(() => {
        dispatch(setTempFormUpdateData({
          formId: "AddFormCommission", fieldName: "associated_aer", value: leadData[0]?.hiring_manager
            ? leadData[0].hiring_manager : '',
        }))
      }, 500);





      const AE= {
        id: 20,
        heading: "Choose AE",
        label: "Choose AE",
        name: "associated_rep",
        type: "autoComplete",
        className: "inputBlock inputBlock4line ",
        base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
        reqBody: {
          // "formId": "searchtestimonial",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {},
          "searchcondition": {
            "roles.is_rep": 1 
          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: leadData[0]?.rep_id && leadData[0]?.rep_id,
        preload:true
      }

      setTimeout(() => dispatch(setField({ formId: "AddFormCommission", after: "lead", fieldData: AE })), 500)


    }
  }, [leadData])

  // ------------------------------------------------------- //




  //  ------ After endpoint call based on AE selection ------- //

  useEffect(() => {
    if (AeData && AeData.length > 0) {

      formData.current.formAdditionalSubmissionData['associated_hm'] = AeData[0]?.hiring_manager ? AeData[0].hiring_manager : undefined;
      formData.current.formAdditionalSubmissionData['associated_bdd'] = AeData && AeData[0]?.associated_bdd?.length > 0 ? AeData[0].associated_bdd : undefined;
      formData.current.formAdditionalSubmissionData['associated_aer'] = AeData[0]?.aer_id ? AeData[0].aer_id : undefined;
      formData.current.fields.forEach((x) => x['name'] === 'associated_programme' ? x['defaultValue'] = AeData[0].associated_program
        : undefined)

    }
  }, [AeData])

  // ------------------------------------------------------- //



  // -------- payout_amount ------ // 

  // console.warn("payout_amount=====>>>>", payout_amount)

  useEffect(() => {
    if (payout_amount !== null) {


      let hm_amount = null 

      if (globalSettingsData[0]?.commission_percentage_for_hm !== null && globalSettingsData[0]?.commission_percentage_for_hm !== undefined && globalSettingsData[0]?.commission_percentage_for_hm !== '') {

       hm_amount = payout_amount * globalSettingsData[0]?.commission_percentage_for_hm / 100

     
      }


      // if (globalSettingsData[0]?.commission_percentage_for_hm !== null && globalSettingsData[0]?.commission_percentage_for_hm !== undefined && globalSettingsData[0]?.commission_percentage_for_hm !== '') {

      //   hm_amount = payout_amount * globalSettingsData[0]?.commission_percentage_for_hm / 100
 
      
      //  }


      dispatch(setTempFormUpdateData({ formId: "AddFormCommission", fieldName: "hm_commission_amount", value: hm_amount!==null && hm_amount!== undefined? hm_amount : '' }))

    }


  }, [payout_amount])






  useEffect(() => {
    if (commissionConfigInfoo && commissionConfigInfoo.length > 0) {

      // console.warn("commissionConfigInfoo", commissionConfigInfoo)
      dispatch(setTempFormUpdateData({ formId: "AddFormCommission", fieldName: "commission_type", value: commissionConfigInfoo[0]?.commission_type ? commissionConfigInfoo[0].commission_type : '', }))

      setTimeout(() => {
        dispatch(setTempFormUpdateData({ formId: "AddFormCommission", fieldName: "commission_percentage", value: commissionConfigInfoo[0]?.commission_percentage ? commissionConfigInfoo[0].commission_percentage : '', }))
      }, 500);

      setTimeout(() => {
        dispatch(setTempFormUpdateData({
          formId: "AddFormCommission", fieldName: "associated_hm", value: leadData[0]?.hiring_manager
            ? leadData[0].hiring_manager : '',
        }))
      }, 500);


      if (commissionConfigInfoo[0]?.commission_type === "non_bps") {
        dispatch(setTempFormUpdateData({ formId: "AddFormCommission", fieldName: "hm_percentage", value: globalSettingsData[0]?.commission_percentage_for_hm ? globalSettingsData[0].commission_percentage_for_hm : '', }))

        dispatch(setTempFormUpdateData({ formId: "AddFormCommission", fieldName: "aee_percentage", value: globalSettingsData[0]?.commission_percentage_for_aee ? globalSettingsData[0].commission_percentage_for_aee : '', }))

        dispatch(setTempFormUpdateData({ formId: "AddFormCommission", fieldName: "ae_percentage", value: commissionConfigInfoo[0]?.commission_percentage ? commissionConfigInfoo[0].commission_percentage : '', }))

      }




    }
  }, [commissionConfigInfoo])









  // ---- userCreateForm ------
  const formData = useRef({
    id: 'AddFormCommission',
    formtype: "add",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "commission/add-commission",
    endPointBodyWrapper: "data",
    urlPathOnSuccessfulFormSubmission: "/commission-listing",
    // msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "tblform",// Can be undefined default will be formCls
    formInlineCLass: "inlnfrm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    // submitBtnName: props.queryParamId ? "UPDATE" : "SUBMIT",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      created_by: userID ? userID : undefined,
      _id: props?.commissionsdata?._id ? props.commissionsdata._id : undefined,
    },

    fields: [


      {
        id: 0,
        heading: "Do You Have Lead Info",
        label: "Select Here",
        name: "have_lead_info",
        className: 'inputBlock usertypebox inputBlock4line',
        type: 'select',
        multiple: false,
        checkbox: true,
        rules: { required: true },
        values: [{ val: 'true', name: "YES" }, { val: 'false', name: "NO" }],
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: props?.commissionsdata?.commission_type ? props.commissionsdata.commission_type : "",

        sx: { m: 1, minWidth: 120 },
      },

      {
        id: 20,
        heading: "Choose AE",
        label: "Choose AE",
        name: "associated_rep",
        type: "autoComplete",
        className: "inputBlock inputBlock4line ",
        base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
        preload: true,
        defaultValue:"",
        reqBody: {
          // "formId": "searchtestimonial",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {},
          "searchcondition": {
            "roles.is_rep": 1 
          }
        },
        sx: { m: 1, minWidth: 300 },
      },
     
      {
        id: 1,
        heading: "Choose Program",
        label: "Choose Program",
        name: "associated_programme",
        type: "autoComplete",
        // multiple: true,
        className: "inputBlock inputBlock4line ",
        base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
        reqBody: {
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        defaultValue: props.commissionsdata?.associated_programme ? props.commissionsdata.associated_programme : leadData?.associated_program ? leadData.associated_program : undefined,

        sx: { m: 1, minWidth: 300 },
      },

      {
        id: 2,
        heading: "Choose Package",
        label: "Package",
        name: "package",
        className: 'inputBlock inputBlock4line',
        type: 'select',
        // multiple: true,
        checkbox: true,
        other: true,
        // rules: { required: true },
        values: [],
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: 2024,
      },


      {
        id: 3,
        heading: "Package Amount",
        label: "Package Amount",
        name: "package_amount",
        className: 'inputBlock inputBlock4line',
        type: "text",
        // inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

        adornments: {
          type: "prefix",
          content: "$",
          position: "start"
        },

        defaultValue: props.commissionsdata?.payout_amount ? props.commissionsdata.payout_amount : "",

      },


      {
        id: 4,
        heading: "Payout Amount",
        label: "Payout Amount",
        name: "payout_amount",
        className: 'inputBlock inputBlock4line',
        type: "text",
        // inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

        adornments: {
          type: "prefix",
          content: "$",
          position: "start"
        },

        defaultValue: props.commissionsdata?.payout_amount ? props.commissionsdata.payout_amount : "",

      },



      {
        id: 5,
        heading: "Commission Type",
        label: "Commission Type",
        name: "commission_type",
        className: 'inputBlock inputBlock4line',
        type: "text",
        // inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },

      {
        id: 6,
        heading: "Commission Percentage",
        label: "Commission Percentage",
        name: "commission_percentage",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },





      {
        id: 7,
        heading: "HM",
        label: "HM",
        name: "associated_hm",
        className: 'inputBlock inputBlock4line',
        type: "text",
        // inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },


      {
        id: 8,
        heading: "AEE",
        label: "AEE",
        name: "associated_aer",
        className: 'inputBlock inputBlock4line',
        type: "text",
        // inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },








      {
        id: 9,
        heading: "HM Percentage",
        label: "HM Percentage",
        name: "hm_percentage",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },


      {
        id: 10,
        heading: "AEE Percentage",
        label: "AEE Percentage",
        name: "aee_percentage",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },

      {
        id: 11,
        heading: "AE Percentage",
        label: "AE Percentage",
        name: "ae_percentage",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },




      {
        id: 12,
        heading: "HM Commission Amount",
        label: "HM Commission Amount",
        name: "hm_commission_amount",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },

      {
        id: 13,
        heading: "AEE Commission Amount",
        label: "AEE Commission Amount",
        name: "aee_commission_amount",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },

      {
        id: 14,
        heading: "AE Commission Amount",
        label: "AE Commission Amount",
        name: "ae_commission_amount",
        className: 'inputBlock inputBlock4line',
        type: "text",
        inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props?.commissionsdata?.commission_percentage ? props.commissionsdata.commission_percentage : "",
      },
       
   




      {
        id: 15,
        label: "Description",
        name: "description",
        className: 'inputBlock addressbox inputBlock1line',
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: props.commissionsdata?.description ? props.commissionsdata.description : "",

      },



      {
        id: 17,
        heading: "Active",
        name: "status",
        className: "inputBlock inputBlock1line usertypebox",
        type: 'singleCheckbox',
        values: { key: 1 },
        defaultValue: props.commissionsdata?.status ? props.commissionsdata.status : undefined,

      },


    ],

    customButtons: [
      {
        id: 'comGoBack',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },

    ]

  })




  //  -------- Based on Do You Have Lead Info question answer felid selection ------- //
  useEffect(() => {

    console.warn("have_lead_info", is_lead_info)

    const Lead = {
      id: 19,
      heading: "Choose Lead",
      label: "Choose Lead",
      name: "lead",
      type: "autoComplete",
      className: "inputBlock inputBlock4line ",
      base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead-name",
      reqBody: {
        "source": "google_events",
        "sort": { "type": "desc", "field": "fullname" },
        "condition": { "limit": 10, "skip": 0 },
        "searchcondition": {},
        "autocomplete_for": "fullname",
        "autoCompleteId": "fullname"
      },
      defaultValue: props.commissionsdata?.lead ? props.commissionsdata?.lead : "",

      sx: { m: 1, minWidth: 300 },
    }

  


    if (is_lead_info === "true") {

      // dispatch(removeField({ formId: "AddFormCommission", name: "associated_rep" }))
      setTimeout(() => dispatch(setField({ formId: "AddFormCommission", after: "have_lead_info", fieldData: Lead })), 500)

    } else if (is_lead_info === "false") {

    //  const AE= {
    //     id: 20,
    //     heading: "Choose AE",
    //     label: "Choose AE",
    //     name: "associated_rep",
    //     type: "autoComplete",
    //     className: "inputBlock inputBlock4line ",
    //     base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
    //     reqBody: {
    //       // "formId": "searchtestimonial",
    //       "condition": {
    //         "limit": 10,
    //         "skip": 0
    //       },
    //       "sort": {},
    //       "searchcondition": {
    //         "roles.is_rep": 1 
    //       }
    //     },
    //     sx: { m: 1, minWidth: 300 },
    //   }

      dispatch(removeField({ formId: "AddFormCommission", name: "lead" }))
      // setTimeout(() => dispatch(setField({ formId: "AddFormCommission", after: "have_lead_info", fieldData: AE })), 500)

    }


  }, [is_lead_info])

  // ------------------------------------------------------------- //



  return (

    <>
      <div>

        <div className='adminform_wrapper'>
          <div className='dataform2_wrapper'>
            <div className='adminformbody'>
              <Form formData={formData.current} />

            </div>

          </div>



        </div>

      </div>


    </>
  )
}


export default AddCommissionForm