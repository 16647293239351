import React, { useRef } from 'react'
import '../LandingPage19/LandingPage19.css'
import Landingpage19Banner from './Components/Landingpage19Banner'
import Landingpage19Block1 from './Components/Landingpage19Block1'
import Landingpage19Block2 from './Components/Landingpage19Block2'
import Landingpage19Block3 from './Components/Landingpage19Block3'
import Landingpage19Footer from './Components/Landingpage19Footer'
export default function LandingPage19() {
  const scrollToview = useRef();

  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
  };


  return (
    <>
      <div className='whole_cont'>


        <Landingpage19Banner scrollToview={scrollToview} />
        <Landingpage19Block1 scrollToview={scrollToview} />
        <Landingpage19Block2 scrollToview={scrollToview} />
        <Landingpage19Block3 scrollToview={scrollToview} />
      </div>
      <Landingpage19Footer />

    </>
  )
}
