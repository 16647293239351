import React from 'react'
import "../Components/LandingPage14.css"
function Lp14blocklogo() {
  return (
    <>
    <div className='Lp14blocklogo_main_wrapper'>
        <div className='landingpage14_common_width'>
            <div className='Lp14blocklogo_sub_wrapper'>
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/landingpage14_logo_img.png`} />
                {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/landingpage14_logo_img.png' /> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default Lp14blocklogo