import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'


import '../../../../assets/List.css'
import store from '../../../../store'

import { Button, Drawer, IconButton, LinearProgress, Paper, Snackbar, Tooltip } from '@mui/material'
import { TableSkeleton } from '../../../../helper/TableSkeleton'
import ListingTable from '../../../../listing/listing'
import { aeCommissionSummary, aeFilterCommissionSummary, aeFilterCommissionSummaryCount, clearPartnerReducerReportData, partnerCommissionSummary, resetdata } from '../PartnersReducer'
import { resolvefunction } from '../../../../helper/helperFunctions'
import moment from 'moment'
import { clearPageState, clearTableCounts, setTableCounts, setTableData, setTableDefaultreqBody, unmountTable } from '../../../../listing/listReducer'
import { Form } from '../../../../form/Form'
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import IosShareIcon from '@mui/icons-material/IosShare';
import { summaryexportCSV } from '../../AffiliateManagement/AffiliateReducer'


function AeSummarryReport() {

    const paramData = useParams();
    const navigate = useNavigate();
    const Location = useLocation();
    const dispatch = useDispatch();

    const sourceName = `${paramData?.id[0].toUpperCase()}${paramData?.id.slice(1)}`



    const [toogleVal, setToogleVal] = useState('all')
    const [filterData, setFilterData] = useState(false)
    const [ListData, setListData] = useState([])
    const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
    const [amtToogleFlag, setAmtToogleFlag] = useState(true);
    const [snackOpen, setSnackOpen] = useState(false);
    const [listDataInsert, setListDataInsert] = useState(false);


    const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});


    // ------------ filtred datas -------- //
    const filteredReportData = useSelector((state) => state.PartnersReducer?.aeFilterSummaryData ? state.PartnersReducer.aeFilterSummaryData : []);

    const filteredReportCount = useSelector((state) => state.PartnersReducer?.aeFilterSummaryDataCount ? state.PartnersReducer.aeFilterSummaryDataCount : null);

    const commisionReportSuccess = useSelector((state) => state.PartnersReducer?.aeFilterSummarySucess ? state.PartnersReducer.aeFilterSummarySucess : false);
    //  -------------------------------- //


    // -------- Main List Data ------ //
    let listingDataAe = useSelector((state) => state.PartnersReducer?.aeCommissionSummaryListData ? state.PartnersReducer.aeCommissionSummaryListData : [])
    // ---------------------------- //


    // -------- resolve ------ //
    const resolved = useSelector((state) => state.ResolveReducer.resolved["/ae-summary-report"] ? state.ResolveReducer.resolved["/ae-summary-report"] : false);
    //  --------------------- //


    // ----------- Loader ------------ //
    let loader = useSelector((state) => (state.PartnersReducer?.loading) ? state.PartnersReducer.loading : false)
    let loading = useSelector((state) => (state.AffiliateReducer?.loading) ? state.AffiliateReducer.loading : false)
    //  --------------------------- //


    // ----------- transactionReportBtn ------------ //
    const transactionReportBtn = useSelector((state) => (state.tableSlice.customButtonClicked.ae_commission_summary && state.tableSlice.customButtonClicked.ae_commission_summary.transactional_report) ? state.tableSlice.customButtonClicked.ae_commission_summary.transactional_report : false)
    // ------------------------------------------ //


    // ----------- affiliateSummaryReportBtn ------------ //
    const affiliateSummaryReportBtn = useSelector((state) => (state.tableSlice.customButtonClicked.ae_commission_summary && state.tableSlice.customButtonClicked.ae_commission_summary.affiliate_summary_report) ? state.tableSlice.customButtonClicked.ae_commission_summary.affiliate_summary_report : false)
    // ------------------------------------------ //


    // ----- On DataRef on every btn trigger ---- //
    const dataRef = useSelector((state) =>
        state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.ae_commission_summary
            ? state.tableSlice?.tempRowData?.ae_commission_summary
            : {}
    );
    // ----------------------------------------- //


    const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.ae_summary_report_Search ? state.formSlice.formSubmissionState.ae_summary_report_Search : false);

    const partnerType = useSelector((state) => state.formSlice?.formData?.ae_summary_report_Search?.partnerType ? state.formSlice.formData.ae_summary_report_Search.partnerType : null);


    const plan_type = useSelector((state) => state.formSlice?.formData?.ae_summary_report_Search?.plan_type ? state.formSlice.formData.ae_summary_report_Search.plan_type : null);


    const username = useSelector((state) => state.formSlice?.formData?.ae_summary_report_Search?.username ? state.formSlice.formData.ae_summary_report_Search.username : null);

    const ae_name_search = useSelector((state) => state.formSlice?.formData?.ae_summary_report_Search?.name ? state.formSlice.formData.ae_summary_report_Search.name : null);


    const start_date = useSelector((state) => state.formSlice?.formData?.ae_summary_report_Search?.start_date ? state.formSlice.formData.ae_summary_report_Search.start_date : null);


    const end_date = useSelector((state) => state.formSlice?.formData?.ae_summary_report_Search?.end_date ? state.formSlice.formData.ae_summary_report_Search.end_date : null);


    const csvSentSuccess = useSelector((state) => state.AffiliateReducer?.csvSent ? state.AffiliateReducer.csvSent : false);
    const dataFetched = useSelector((state) => state.PartnersReducer?.dataFetched ? state.PartnersReducer.dataFetched : false);



    // ================ setting time stamps ====================//
    const todaysStartDate = moment().startOf('day').valueOf()
    const todaysEndDate = moment().endOf('day').valueOf()


    const weekStartDate = moment().startOf('week').valueOf()
    const weekEndDate = moment().endOf('week').valueOf()


    const monthStartDate = moment().startOf('month').valueOf()
    const monthEndDate = moment().endOf('month').valueOf()
    // ======================================================== //



    
    useEffect(() => {
     if((paramData.id!==null && paramData.id!==undefined ) && (paramData.id!=="aspire" && paramData.id!=="bluecoast")  ){
      

        const actionBtns = aeCommissionSummaryList.current.buttons.filter((x)=> x.id!=="affiliate_summary_report")

        aeCommissionSummaryList.current.buttons = actionBtns
     }
    }, [paramData])
    

  



    // ------- entrypoint triggrer ---- //
    useEffect(() => {

        let reqBody = {
            "source": paramData.id,
            condition: {
                limit: 30,
                skip: 0,
            },
            sort: {
                // type: "desc",
                // field: "record_date",
            },
            searchcondition: {},
        };
        setSnackOpen(false)


        if (!resolved) {

            // dispatch(aeCommissionSummary(reqBody))
            // setTimeout(() => {
            searchTable('all')
            // }, 40);
        }


        return () => {
            store.dispatch(clearPartnerReducerReportData());
            setSnackOpen(false)

        };
    }, []);
    //  ------------------------ //



    //   -------- transactionReportBtn trigger ------ // 
    useEffect(() => {
        if (transactionReportBtn) {
            // resolvefunction(navigate, "/partners-transactional-report");
            window.open("/commission-transactional-report/" + dataRef._id + "/" + dataRef.name, "_blank")
        }
    }, [transactionReportBtn])
    //   ------------------------------------------ //







    //   -------- affiliateSummaryReportBtn trigger ------ // 
    useEffect(() => {
        if (affiliateSummaryReportBtn) {
            window.open("/affiliate-summary-report/" + dataRef._id, "_blank")
        }
    }, [affiliateSummaryReportBtn])

    //   ------------------------------------------ //







    // ==== setTableCount === //
    useEffect(() => {
        if (filteredReportCount !== null) {
            dispatch(clearPageState({ "tableId": "ae_commission_summary" }))
            dispatch(setTableCounts({ "tableId": "ae_commission_summary", "count": filteredReportCount }))
        }
    }, [JSON.stringify(filteredReportCount)])
    // ===================== //








    // --------- Table list for user --------- //
    var modifyTableHeaders =
        [
            { val: "name", name: "AE Name" },
            { val: "totalCommission", name: "Total Commission Amt ($)" },
        ];

    let aeCommissionSummaryList = useRef({
        tableId: "ae_commission_summary",
        tableTitle: `${sourceName} AE Commission Summary `,
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            // endPoint: "commission/rep-commission-summary-list",
            // deleteSingleUserEndpoint: "role/delete-roles",
            tableCountEndpoint: "commission/rep-commission-summary-list",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "role_list",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "totalCommission"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [

            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
                modalHeader: "<p>Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "role/delete-roles",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                modalHeader: "<p>Alert !!</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "role/status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],
        buttons: [
            {
                id: "transactional_report",
                name: "summarize_icon",
                type: "other",
                label: "Transactional Report",
            },

            {
                id: "affiliate_summary_report",
                name: "wysiwyg_icon",
                type: "other",
                label: "Affiliate Summary Report",
            },


        ],

        searchData: {
            heading: "Search Role",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [

                    {
                        id: 8,
                        label: "Search By Creation start Time",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search By Creation end Time",
                        name: "end_date",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                    {
                        id: 8,
                        label: "Search By Updated on start Time",
                        name: "start_date_by_update",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "updatedon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search By Updated on end Time",
                        name: "end_date_by_update",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "updatedon_datetime", param: "$lte" }
                    },

                ]
            }
        },
    });

    let reqbody = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            // "field": "_id",
            "field": "record_date",
            "type": "desc"
        },
        "searchcondition": {},
        "project": {},
        "token": ""
    }

    //  ----------------------------- //













    // ==== setFilterTableData === //
    useEffect(() => {

        if (filterData && dataFetched && commisionReportSuccess && filteredReportData?.length > 0) {

            setListData(filteredReportData)
            setListDataInsert(true)

        }


    }, [JSON.stringify(filteredReportData), commisionReportSuccess, filterData, dataFetched])
    //  ================== //



    // ==== setAllTableData === //
    useEffect(() => {

        if (dataFetched && listingDataAe.length > 0) {
            setListData(listingDataAe)
            setListDataInsert(true)

        }

    }, [JSON.stringify(listingDataAe), dataFetched])
    //  ================== //



    // ==== setAllTableData === //
    useEffect(() => {


        if (dataFetched && ListData) {
            dispatch(setTableData({ "tableId": "ae_commission_summary", "dataset": ListData }))
            setListDataInsert(true)


        }

    }, [JSON.stringify(ListData), dataFetched])
    //  ================== //








    // ============  on table filters click =========== //
    function searchTable(val) {
        setToogleVal(val);

        switch (val) {

            case 'all':
                setListDataInsert(false)
                dispatch(unmountTable({ "tableId": "ae_commission_summary" }));
                setFilterData(false)
                setAmtToogleFlag(false)
                dispatch(clearPartnerReducerReportData())

                let payloadAll = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {

                    },
                    "source": paramData?.id

                }


                aeCommissionSummaryList.current['reqBody']['searchcondition'] = payloadAll['searchcondition']


                dispatch(setTableDefaultreqBody({ "tableId": "ae_commission_summary", "reqBody": payloadAll }))
               
                dispatch(clearTableCounts({ "tableId": "ae_commission_summary" }))

                dispatch(aeCommissionSummary(payloadAll))

                setListData([])


                // setTimeout(() => {
                //     payloadAll['count'] = true
                //     dispatch(aeFilterCommissionSummaryCount(payloadAll))
                // }, 200);

                break;

            case 'this_month':
                setListDataInsert(false)
                dispatch(unmountTable({ "tableId": "ae_commission_summary" }));
                setFilterData(true)
                setAmtToogleFlag(true)
                dispatch(clearPartnerReducerReportData())


                let payloadMonth = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {},
                    "source": paramData?.id
                }

                payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }


                dispatch(setTableDefaultreqBody({ "tableId": "ae_commission_summary", "reqBody": payloadMonth }))

                aeCommissionSummaryList.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

                dispatch(clearTableCounts({ "tableId": "ae_commission_summary" }))


                dispatch(aeFilterCommissionSummary(payloadMonth))
                setListData([])


                // setTimeout(() => {
                //     payloadMonth['count'] = true
                //     dispatch(aeFilterCommissionSummaryCount(payloadMonth))
                // }, 200);


                break;

            case 'this_week':
                dispatch(unmountTable({ "tableId": "ae_commission_summary" }));
                setListDataInsert(false)
                setFilterData(true)
                setAmtToogleFlag(true)
                dispatch(clearPartnerReducerReportData())


                let payloadWeek = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {},
                    "source": paramData?.id

                }

                payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }


                aeCommissionSummaryList.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

                dispatch(setTableDefaultreqBody({ "tableId": "ae_commission_summary", "reqBody": payloadWeek }))

                dispatch(clearTableCounts({ "tableId": "ae_commission_summary" }))
                
                dispatch(aeFilterCommissionSummary(payloadWeek))

                setListData([])


                dispatch(clearPageState({ "tableId": "ae_commission_summary" }))


                // setTimeout(() => {
                //     payloadWeek['count'] = true
                //     dispatch(aeFilterCommissionSummaryCount(payloadWeek))
                // }, 200);


                break;

            case 'today':
                setListDataInsert(false)
                dispatch(unmountTable({ "tableId": "ae_commission_summary" }));
                setFilterData(true)
                setAmtToogleFlag(true)
                dispatch(clearPartnerReducerReportData())




                let payloadToday = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {},
                    "source": paramData?.id

                }


                payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }

                aeCommissionSummaryList.current['reqBody']['searchcondition'] = payloadToday['searchcondition']


                dispatch(setTableDefaultreqBody({ "tableId": "ae_commission_summary", "reqBody": payloadToday }))


                dispatch(aeFilterCommissionSummary(payloadToday))

                setListData([])


                // setTimeout(() => {
                //     payloadToday['count'] = true
                //     dispatch(aeFilterCommissionSummaryCount(payloadToday))
                // }, 200);



                break;

            default:
            // code block
        }
    }
    //  ================================== //









    //----------------------search form configration---------------------
    const dataform = useRef({
        id: 'ae_summary_report_Search',
        submitBtnName: "Search",
        submitBtnEndIcon: 'search_icon',
        resetForm: true,
        resetBtnName: "Refresh",
        resetBtnEndIcon: 'refresh',
        endPointBodyWrapper: "searchcondition",
        formButtonClass: "submitbtnsection",
        formWrapperClass: "formWrapperClass formCls",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {},

        fields: [

            {
                id: 0,
                label: "Search by AE Name",
                name: "name",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
                reqBody: {
                    "condition": {
                        "limit": 10,
                        "skip": 0
                    },
                    "sort": {
                        "type": "asc",
                        "field": "name"
                    },
                    "searchcondition": {
                        "signup_form": paramData?.id,
                        "roles.is_rep": 1,
                        "label": {
                            "$regex": "",
                            "$options": "i"
                        }
                    },
                    "type": "user_name",
                    "source": "users"
                },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "_id" },

            },

            {
                id: 1,
                label: "Search by Recorded on Start Date",
                name: "start_date",
                className: "datePicker",
                type: "datePicker",
                sx: { m: 1, width: 300 },
                className: "list_search_item countDiv__column--col6 ",
                payloadFormat: { key: "record_date", param: "$gte" },
            },
            {
                id: 4,
                label: "Search by Recorded on End Date",
                name: "end_date",
                className: "list_search_item countDiv__column--col6 ",
                type: "datePicker",
                fullDay: true,
                sx: { m: 1, width: 300 },
                payloadFormat: { key: "record_date", param: "$lte" },
                // minDate:{minDate}
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "End Date can not be less than Start Date"
                },
            },


        ],
    })

    // ================================================== //

    // ========= endpoint call for search after search drawwer ===== //
    useEffect(() => {
        if (formSubmissionState === 4) {
            setListDataInsert(false)
            setFilterData(true);
            setToogleVal(null);
            setAmtToogleFlag(false)
            dispatch(clearPartnerReducerReportData())

            const payload = {
                "condition": { "limit": 30, "skip": 0 },
                "sort": {
                    "field": "record_date",
                    "type": "desc"
                },
                "searchcondition": {
                  "role":"is_rep"
                },
                "project": {},
                "token": "",
                "count": false,
                "source": paramData.id
            }

            if (start_date !== null || end_date !== null) {
                payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

            }


            if (ae_name_search !== null ) {
                payload['user_id'] = ae_name_search
            }
            


            aeCommissionSummaryList.current['reqBody']['searchcondition'] = payload['searchcondition']


            dispatch(aeFilterCommissionSummary(payload))
            setListData([])


            searchTable('search')

            setTimeout(() => {
                payload['count'] = true
                dispatch(aeFilterCommissionSummaryCount(payload))
            }, 200);

            setSearchDrawer(false)
        }
    }, [formSubmissionState])
    // ================================================================= //








    // =============== exportCSV ============= //
    function exportCSVthroughmail() {

        let payload = {}


        switch (toogleVal) {


            case 'all':

                payload = {
                    "condition": {
                        "limit": 30, "skip": 0
                    },
                    "sort": {
                        "type": "desc", "field": "record_date"
                    },
                    "searchcondition": {
                        "source": paramData?.id
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "ae_commission_summary",
                    "export_type": "ae_summary",

                }

                dispatch(summaryexportCSV(payload));
                break;


            case 'this_month':

                payload = {
                    "condition": {
                        "limit": 30,
                        "skip": 0
                    },

                    "sort": {
                        "type": "desc",
                        "field": "record_date"
                    },

                    "searchcondition": {
                        "source": paramData?.id,
                        'record_date': { $gte: monthStartDate, $lte: monthEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "ae_commission_summary",
                    "export_type": "ae_summary"
                }

                dispatch(summaryexportCSV(payload));

                break;

            case 'this_week':

                payload = {
                    "sort": { "type": "desc", "field": "record_date" },
                    "searchcondition": {
                        "source": paramData?.id,
                        "record_date": { $gte: weekStartDate, $lte: weekEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "ae_commission_summary",
                    "export_type": "ae_summary"
                }

                dispatch(summaryexportCSV(payload));

                break;

            case 'today':

                payload = {
                    "sort": { "type": "desc", "field": "record_date" },
                    "searchcondition": {
                        "source": paramData?.id,
                        "record_date": { $gte: todaysStartDate, $lte: todaysEndDate }
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "ae_commission_summary",
                    "export_type": "ae_summary"
                }

                dispatch(summaryexportCSV(payload));
                break;

            case 'search':


                payload = {
                    "sort": { "type": "desc", "field": "record_date" },
                    "searchcondition": {
                        "source": paramData?.id
                    },
                    "email": logedUserData.email,
                    "source": "access_h_com_datas",
                    "list_ref": "ae_commission_summary",
                    "export_type": "ae_summary"
                }

                if (start_date !== null || end_date !== null) {
                    payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

                }

                // if (partnerType !== null) {
                //     payload['searchcondition']['partnerType'] = partnerType
                // }

                dispatch(summaryexportCSV(payload));

                break;

            default:
                break;
        }

    }
    //  ================================== //







    // ===== open snackbar if csv export sucessfull ==== //
    useEffect(() => {
        if (csvSentSuccess) {
            setSnackOpen(true)
        }
    }, [csvSentSuccess])

    //  ================================================ //

    // ======== snackbar close ======= //
    function handleClose() {
        setSnackOpen(false)

    }
    // ========================= //



    return (

        <div className='rolelist_mainwrp'>

            {(loader === true || listDataInsert===false || loading) && <LinearProgress />}

            <div className='listing_heading'>
                <h1> <span className='cST'> {paramData?.id} </span> AE Commission Summary  </h1>
            </div>



            <div className='listNavigate affiliate-reportlist-navigation'>
                <ul className='toogleBtns'>
                    <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
                    <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
                    <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Commission </Button>  </li>
                    <li> <Button variant="contained" onClick={() => {
                        dispatch(unmountTable({ "tableId": "ae_commission_summary" }));
                        searchTable('all')
                    }}>
                        <Tooltip title="Reload" placement="bottom" className=''>
                            <ReplayIcon />
                        </Tooltip>
                    </Button>  </li>


                    {(ListData?.length > 0) && <li>
                        <Button variant="contained" onClick={() => exportCSVthroughmail()} >
                            <Tooltip title="Export CSV" placement="bottom" className=''>
                                <IosShareIcon />
                            </Tooltip>
                        </Button>
                    </li>}
                </ul>
            </div>



            <div className='rolelist_mainwrp_Table'>

                {!loader && listDataInsert ? (
                    <ListingTable

                        tableData={aeCommissionSummaryList.current}
                        dataset={ListData}
                        modifyHeaders={modifyTableHeaders}
                    />
                ) : (<TableSkeleton count={5} />)}

            </div>






            {/* ============ search drawer ======== */}
            <Drawer
                anchor="bottom"
                open={searchDrawer}
                onClose={() => setSearchDrawer(false)}
            >
                <Paper elevation={7} className='list_search team_management_list_search search_img'>

                    <div className="searchBarHead DrawerBox">
                        <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search {paramData?.id} AE Commission Summary</h2>
                        <span className="searchBarCloseIcon">
                            <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
                        </span>
                    </div>

                    <Form formData={dataform.current} />
                </Paper>
            </Drawer>
            {/* =================================== */}




            {
                snackOpen && (
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message="CSV Data Sent Successfully. Please Check Your Mail"
                    />
                )
            }



        </div>
    )
}

export default AeSummarryReport
