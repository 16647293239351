import {Button,IconButton,InputAdornment,LinearProgress,Snackbar,TextField} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Visibility, VisibilityOff, AccountCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {clearMessageIsLoggedIn,login,setIpinfo, stepUpFncEcecutn,} from "./loginReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import store from "../../store";
import { resolvefunction } from "../../helper/helperFunctions";
import { useCookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import "../login/login.css";
import {getDataFoAdminDashboardCard,getDataForHmDashboardCard,getlandingpageForRep,} from "../Backend/Dashboard/DashboardReducer";
import { getDataForRepDashboardCard } from "../Backend/RepDashboard/RepDashboardReducer";
import { getDataForAerDashboardCard } from "../Backend/AerDashboard/AerDashboardReducer";
import ReportAnIssueModal from "./reportanissuemodal";
import { getDataForBddDashboardCard } from "../Backend/BDDdashboard/BDDdashBoardReducer";
import {BlueCoastlist,} from "../Backend/BlueCoast/BlueCoastReducer";
import { getusersList } from "../Backend/userManagement/userList/userListReducer";
import { employeePackageData, employeePackageTotalCost} from "../Backend/EmployerManagement/EmployerReducer";

function Login({props}) {
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [loginData, setLoginData] = useState({})

  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);

  let userType = useSelector((state) =>state.loginSlice.userInfo?.main_role? state.loginSlice.userInfo?.main_role: null);
  let userdata = useSelector((state) =>state.loginSlice.userInfo ? state.loginSlice.userInfo : null);
  let rep_new_dashboard = useSelector((state) =>state.loginSlice.userInfo?.rep_with_new_dashboard? state.loginSlice.userInfo.rep_with_new_dashboard: false);
  let userID = useSelector((state) =>state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null);
  let loginVerify = useSelector(state => state.loginSlice.loginVerification ? state.loginSlice.loginVerification : null); 


  // ***********************useEffect for login verificational routing*********************
  useEffect(() => {
    if(loginVerify === 1) {
      navigate("/otp-verify");
    }
  }, [JSON.stringify(loginVerify)])



  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState({required: "This Field is Required",validate: "Please Enter Valid Email",pattern: "Invalid Email Address",});
  const validate = (value) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const isOk = strongRegex.test(value);
    if (!isOk) setWrongPassword(true);
    else setWrongPassword(false);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);


  const isLoggedIn = useSelector((state) =>state.loginSlice.isLoggedIn ? state.loginSlice.isLoggedIn : false);
  const message = useSelector((state) =>state.loginSlice?.message ? state.loginSlice.message : null);
  const loading = useSelector((state) =>state.loginSlice?.loading ? state.loginSlice.loading : false);
  const loading0 = useSelector((state) =>state.userDashboardKey?.loading ? state.userDashboardKey.loading : false);
  const ipdetails = useSelector((state) =>state.loginSlice.ipInfo ? state.loginSlice.ipInfo : null);


  ///////////////////////////////// Hook Form /////////////////////////////////////////////
  const {handleSubmit,control,formState: { errors },clearErrors,setValue,} = useForm();

  let mindate = useSelector((state) =>state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {});
  let maxDate = useSelector((state) =>state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {});

  const location = useLocation();
  const finalURL = location.pathname.split("/").join("");

  let associateProgramRef = [];

  useEffect(() => {
    if (finalURL === "affiliate-dashboard") {
      associateProgramRef.push(userdata.associated_program);
    }
  }, []);


  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        dispatch(setIpinfo(data));
      });
  }, []);

  //   //////////////////////////////// Form Submission //////////////////////////////////////
  const onSubmit = async (data) => {
    data.login_time = Math.round(new Date().getTime());
    data.login_data = ipdetails;
    setLoginData(data)
     await dispatch(login(data));
    };
    
 
  

  //////////////////////////////////// Functions /////////////////////////////////////////

  const isValidPassword = (password) => (password !== "" ? true : false);
  /////////////////////////////////store/////////////////////////////
  store.subscribe(() => {
    const storedata = store.getState();
    managenav(storedata,setuserInfoCookie,setUserTokenCookie,setLastLoginTimeCookie,navigate);
  });
  ///////////////////////////////useeffect/////////////////////////////////////////

 

 


  useEffect(() => {

    let reqBodyFordashboard = {
      condition: {limit: 10,skip: 0,},
      sort: {field: "_id",type: "desc",},
      searchcondition: {
        _id: userID,
        createdon_datetime: {$gte: mindate,$lte: maxDate},
      },
      project: {},
      token: "",
      count: false,
    };

    let reqcardBodyFordashboard = {
      condition: {limit: 10,skip: 0,},
      sort: {field: "_id",type: "desc",},
      searchcondition: {
        rep_id: userID,
        createdon_datetime: {$gte: mindate,$lte: maxDate},
      },
      project: {},
      token: "",
      count: false,
    };

    let reqLandingPagebody = {
      condition: {limit: 10,skip: 0,},
      sort: {field: "priority",type: "asc",},
      searchcondition: {
        $and: [
          { status: 1 },
          {
            associated_program: {
              $in:
                finalURL == "affiliate-dashboard"
                  ? associateProgramRef
                  : userdata?.associated_program,
            },
          },
        ],
      },
      project: {},
      token: "",
      count: false,
    };

    let payLoadEmployeeCountList = {

      "condition": {"limit": 30,"skip": 0},
      "sort": {"field": "createdon_datetime","type": "desc"},
      "searchcondition": {employer_id: userdata?.main_role == "is_employer",},
      "project": {},
      "token": "",
      "email": "",
      "count": false
    }

    let payLoadSubEmployeeCountList = {

      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {
        employer_id: userdata?.main_role == "is_subEmployer",
      },
      "project": {},
      "token": "",
      "email": "",
      "count": false
    }


    if (userdata?.main_role == "is_aer") {
      reqLandingPagebody.searchcondition["rep_only"] = { $in: [0, 1] };
    } else {
      reqLandingPagebody.searchcondition["rep_only"] = 0;
    }


    let payloadEmployeePackageData = {

      "condition": {
        "limit": 30,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {
        employer_id: userdata?.main_role == "is_subEmployer",
      },
      "project": {},
      "token": "",
      "email": "",
      "count": false
    }

    if (isLoggedIn !== null &&isLoggedIn !== undefined &&isLoggedIn === true) {
      setOpen(true);

      setTimeout(() => {
        switch (userType) {
          case "is_admin":
            {resolvefunction(navigate,"/dashboard",getDataFoAdminDashboardCard(reqBodyFordashboard));}
            break;
          case "hr_manager":
            {resolvefunction(navigate,"/hm-dashboard",getDataForHmDashboardCard(reqBodyFordashboard));}
            break;

          case "is_affiliate": {
            if (userdata && userdata.affiliate_signup_stage == 1) {
              resolvefunction(navigate, `/affiliate-signup/${userdata._id}`);
              break;
            } else if (userdata && userdata.affiliate_signup_stage == 2) {
              resolvefunction(navigate, `/affiliate-signup/${userdata._id}`);
              break;
            } else if (userdata && userdata.affiliate_signup_stage == 3) {
              resolvefunction(navigate, `/affiliate-signup/${userdata._id}`);
              break;
            } else if (userdata && userdata.affiliate_signup_stage == 4) {
              resolvefunction(navigate, `/affiliate-signup/${userdata._id}`);
              break;
            } else {
              resolvefunction(navigate,"/affiliate-dashboard",getlandingpageForRep(reqLandingPagebody));
              break;
            }
          }
          case "is_rep":
            {
              if (rep_new_dashboard) {
                resolvefunction(navigate,"/new-ae-dashboard",getlandingpageForRep(reqLandingPagebody));
              }
              else {
                resolvefunction(navigate,"/ae-dashboard",getDataForRepDashboardCard(reqcardBodyFordashboard));
              }
            }
            break;
          case "is_aer":
            {
              resolvefunction(navigate,"/aee-dashboard",getDataForAerDashboardCard(reqBodyFordashboard));
            }
            break;
          case "is_bdd":
            {
              resolvefunction(navigate,"/bdd-dashboard",getDataForBddDashboardCard(reqBodyFordashboard));
            }
            break;
          case "special_entities":
            {
              let previus_url = window.sessionStorage.getItem('previus_url') 
              // dispatch(stepUpFncEcecutn())
              if(previus_url){
                resolvefunction(navigate, previus_url, BlueCoastlist());
              }else {
                resolvefunction(navigate, "/businesslead-list", BlueCoastlist());
              }
             
            }
            break;
          case "customer_support":
            {
              resolvefunction(navigate, "/usermanagement", getusersList());
            }
            break;
          case "is_employer":
            {
              resolvefunction(navigate, "/new-employer-dashboard", employeePackageTotalCost(payLoadEmployeeCountList));
            }
            break;

          case "is_subEmployer":
            {
              resolvefunction(navigate, "/new-sublogin-dashboard", employeePackageTotalCost(payLoadSubEmployeeCountList));
            }
            break;

          case "is_employee":
            {
              resolvefunction(navigate, "/new-employee-dashboard", employeePackageData(payloadEmployeePackageData));
            }
            break;
          default:
            resolvefunction(navigate, "/error-page");
        }

      }, 100);
    }
  }, [isLoggedIn]);




  useEffect(() => {
    if (message !== null) {
      setOpen(true);
    } else {
      setOpen(true);
    }
  }, [message]);




  useEffect(() => {
    return () => {
      setOpen(true);
    };
  }, []);




  function onChnageAction() {
    setOpen(false);
  }


  
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small">
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );




  return (
    <div className="loginBody">
      {(loading || loading0) && <LinearProgress />}

      <div className="loginBox">
        <div className="loginBox_body">
          <img
            className="logo"
            src="https://allfrontend-assets.s3.amazonaws.com/Dealfirecrmlogo.png"
            alt=""
          />



          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={() => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="text"
                  autoFocus
                  onChange={(e) => {
                    setValue("email", e.target.value?.trim());
                    clearErrors("email");
                    dispatch(clearMessageIsLoggedIn());
                  }}
                  error={Object.keys(errors).length > 0 && errors.email != null}
                  helperText={errors.email && errorMessages[errors.email.type]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" edge="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{ required: true, validate: isValidPassword }}
              render={() => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  error={
                    Object.keys(errors).length > 0 && errors.password != null
                  }
                  helperText={
                    errors.password && errorMessages[errors.password.type]
                  }
                  onChange={(e) => {
                    setValue("password", e.target.value);
                    clearErrors("password");
                    dispatch(clearMessageIsLoggedIn());
                    validate(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />


            <div>
              <Link className="forgot_password" to="/forget-password">
                Forgot Password?
              </Link>
            </div>

            <Button
              className="passBttn"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disabled={loading}
            >
              <span> Login </span>
            </Button>
          </form>
          {loading && <LinearProgress sx={{ margin: "5px" }} />}
        </div>
      </div>

      {/* ------------ ReportAnIssueModal ------ */}

      {reportModal == true ? (<ReportAnIssueModal toogle={reportModal} tooglefunc={setReportModal} />) : ("")}

    

      {message !== null ? (
        <Snackbar
          className=""
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          message={
            <a href="mailto:lhagele@cox.net" className="mail_to">
              {message}
            </a>
          }
          action={action}
          onClick={onChnageAction}
        />
      ) : (
        ""
      )}
   
    </div>

  );
}

export default Login;

function managenav(
  tempstore,
  setuserInfoCookie,
  setUserTokenCookie,
  setLastLoginTimeCookie,
  navigate
) {
  if (
    tempstore?.loginSlice?.userInfo !== undefined &&
    tempstore?.loginSlice?.userInfo?._id !== null &&
    tempstore?.loginSlice?.userInfo?._id !== undefined
  ) {
    setuserInfoCookie(
      "userinfo",
      JSON.stringify(tempstore.loginSlice.userInfo),
      { path: "/" }
    );

    setUserTokenCookie("token", tempstore.loginSlice.token, { path: "/" });


    setLastLoginTimeCookie(
      "lastLoginTime",
      tempstore.loginSlice.last_login_time,
      { path: "/" }
    );
  }
}
