import React, { useState } from "react";
import "../Components/LandingPage14.css";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CustomizedAccordions from "../CustomizedAccordions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LandingPage14FormModal from "../LandingPage14FormModal";
import { useDispatch } from "react-redux";
import { setscrollView } from "../../../../Layout/layoutReducer";

function Lp14block10({ page }) {

  const [openModal, setopenModal] = useState(false);
  const [AccordionVal, setAccordionVal] = useState(1);



  const modalOpen = () => {
    setopenModal(true);
  };

  const faqBtnClicked = () => {
    setAccordionVal(AccordionVal + 8)
  };

  const setsScroll = () => {
    dispatch(setscrollView("scroll"));
  }
  const dispatch = useDispatch();



  //   export default function CustomizedAccordions() {
  //     const [expanded, setExpanded] = React.useState('panel1');

  //     const handleChange = (panel) => (event, newExpanded) => {
  //       setExpanded(newExpanded ? panel : false);
  //     };

  return (
    <>
      <div className="landingpage14_bannerblock10_mainwrapper">
        <div className="landingpage14_common_width">
          <div className="lp14_block10_sub_wrapper">
            <h1>
              FREQUENTLY ASKED <span>QUESTIONS</span>
            </h1>
          </div>



          <CustomizedAccordions AccordionVal={AccordionVal} />
          {/* </div> */}
          <div className="Lp14_last_button_wrapper">


            {AccordionVal < 24 &&
              <div className='Lp14last_sub_wrapper'>

                <button onClick={() => faqBtnClicked()}>Load More FAQs <ArrowDropDownIcon /></button>
              </div>


            }

            <div className='Lp14block2_sub1_wrapper'>
              {page === "17" ? <button onClick={() => setsScroll()}>speak with one of our organization specialist right away</button> : <button onClick={() => modalOpen()}>Enroll Today</button>}
            </div>
          </div>
        </div>
      </div>
      <div><LandingPage14FormModal openModal={openModal} setopenModal={setopenModal} /></div>
    </>
  );
}

export default Lp14block10;
