import React from 'react'
import "../LandingPage13.css";

function Landingpageblock4() {

  return (
  <>
  <div className='Landingpage13block4_main_wrapper'>
    <h1>We offer the 605 most prescribed drugs in <br></br>
      America for free!</h1>
    <div className='Landingpage13block4_sub_wrapper'>
      <div className='cercle_main_blc'>
    <div className='Landingpage13block4_cercial_sec'>
      <div className='Landingpage13block4_cercial_sub_sec'>
        <h2>125</h2>
       
       

      </div>
      <img className='landingpage13block4_abs_img' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/blue_logo.png'/>
    </div>
    <div className='Landingpage13block4_text_sub_sec'>
        <p><strong> Generic Acute Medications</strong> <br></br>
       (Immediate Need) </p>
      </div>
    </div>
    

    <div className='cercle_main_blc'>
    <div className='Landingpage13block4_cercial_sec'>
      <div className='Landingpage13block4_cercial_sub_sec'>
        <h2>480</h2>
        

      </div>
      <img className='landingpage13block4_abs_img' src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/red_logo.png'/>
    </div>
    <div className='Landingpage13block4_text_sub_sec'>
        <p><strong>  Generic ChronicMedications</strong> <br></br>
        (Maintenance) </p>
      </div>
    </div>
    </div>

  </div>
 
  </>
  )
}

export default Landingpageblock4