import { Button, IconButton, LinearProgress, Modal, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { locationStatusUpdate } from '../../EmployerReducer'
import { setReloadTableFlag } from '../../../../../listing/listReducer'
import { setSnackbar } from '../../../../Layout/layoutReducer'

function StatusChangeModal(props) {

    const dispatch = useDispatch()
    const [dltLoader, setDltLoader] = useState(false)


    // ***************************row data from store***********************
    const rowdataforlocation = useSelector((state) => state.tableSlice?.tempRowData?.tableForLocationData ? state.tableSlice.tempRowData.tableForLocationData : {})

    // ************************save rwo data from store******************
    const [rowid, setRowid] = useState(null)

    useEffect(() => {
        if (rowdataforlocation._id !== null && rowdataforlocation._id !== undefined) {
            // console.log("props.statusChangeClick===========>>>", rowdataforlocation)
            setRowid(rowdataforlocation._id)
            props.setStatusModalOpen(true)
            // console.log("props.rowid===========>>>", rowid)
        }
    }, [JSON.stringify(rowdataforlocation)])


    //******************************status change functiaon************************** */
    const statusChnageApiCall = async (val) => {
        let value = val === 1 ? true : false;
        // console.log("value===============>>>>", rowid)

        let body = {
            "_id": rowid,
            "status": value
        }

        // console.log("body=====>>>>11", body);

        await dispatch(locationStatusUpdate(body))
        // console.log("body=====>>>>", body);

        setDltLoader(true)

        dispatch(
            setSnackbar({
                open: true,
                message: "Status Updated Succesfully!",
                status: "success",
            })
        );
        setTimeout(() => {

            props.setStatusModalOpen(false)
        }, 1000);
        // console.log("rowid==================>>>", rowid);

        setTimeout(() => {
            dispatch(setReloadTableFlag({ tableId: "tableForLocationData" }));

        }, 2000)


    }

    //*********************status modal close****************************** */
    const handleCloseStatusMOdal = () => {
        props.setStatusModalOpen(false)
        // dispatch(setReloadTableFlag({ tableId: "paymentDetials" }));

    }

    return (
        <>
            <Modal
                className="modalblock TaskStatusChange"
                open={props.statusModalOpen}
                onClose={handleCloseStatusMOdal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    border: '10px solid #0569ad!important',
                    background: '#fff',
                    boxShadow: 24,
                    textAlign: "center",
                }}>
                    <Tooltip title="Close">
                        <IconButton
                            onClick={handleCloseStatusMOdal}
                            className="modal_icon_button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography id="modal-modal-title" variant="h5" component="h5">

                        <p className='alertPara2'> Alert !!</p>

                        <p> Are You Sure You Want Change Location Status? </p>
                    </Typography>
                    <div>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => statusChnageApiCall(1)} className="modalBTN">Active</Button>
                        <Button variant="contained" sx={{ 'margin': '10px' }} onClick={() => statusChnageApiCall(2)} className="modalBTN">Incative</Button>

                    </div>

                    {dltLoader && <LinearProgress sx={{ marginTop: '10px' }} />}



                </Box>
            </Modal>
        </>
    )
}

export default StatusChangeModal