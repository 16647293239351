import React from 'react'
import MlmPageForm from './MlmPageForm'
import '../NakaGamesLandingPage/NakaGame.css'

const MlmPage = () => {
  return (
    <>
    <div className='LandingPage9Body'>
      <div className='LandingPage9Body_wrapper'>
        <div className='LandingPage9Body_Top'>
          <div className='LandingPage9Body_TopLeft'> <img src='https://d37pbainmkhx6o.cloudfront.net/landing-page5/access_discount_logo.webp' alt='' /> </div>
          <div className='LandingPage9Body_TopRight'>
            {/* <h2><span>ACCESS DISCOUNT HEALTHCARE</span></h2> 
        <h2><span>ARE GETTING BIG RETURNS FOR MEDICAL.</span></h2>
        <h3><span>ENTER YOUR</span> <strong>INFORMATION BELOW!</strong></h3> */}
            <h2><span>Multi Level Marketing (MLM-ONE)</span></h2>
            {/* <h2><span>ARE GETTING BIG RETURNS FOR MEDICAL.</span></h2>  */}
            <h3><span>Multi Level Marketing (MLM-ONE)</span> <strong>PAGES</strong></h3>


          </div>

        </div>

        <div className='LandingPage9Body_Formwrapper nakagames_form_wrp'>
          <div className='LandingPage9Body_Formwrapper_con prospect_Details'>
            <div className='LandingPage9FormCon'>
              <MlmPageForm />
            </div>
          </div>
        </div>

        <div className='LandingPage9BottomLogo'>
          <span> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img></span>
        </div>

        {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_img1.webp' className='landingpage9_img1'></img> */}
      </div>


    </div>




 


  </>
  )
}

export default MlmPage