import React from 'react'
import OtherFormsBlueCoast from './OtherFormsBlueCoast'

function OtherFormViewBlueCoast() {
  return (
    <div className='LandingPage9Body LandingBlCst_New'>
          <div className='LandingPage9Body_wrapper'>
            <div className='LandingPage9Body_Top'>
              <div className='LandingPage9Body_TopLeft'> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img> </div>
              <div className='LandingPage9Body_TopRight'>
                {/* <h2><span>Sign up and get access to landing pages to start </span></h2>
                <h2><span>  sharing the Access Health Discount Pharmacy Program</span></h2> */}
                {/* <h2><span>Email Campaign Approver </span></h2> */}
                <h3><span></span> <strong>Email Campaign Approver</strong></h3>
              </div>
            </div>
    
            <div className='LandingPage9Body_Formwrapper LandingPage10Body'>
              <div className='LandingPage9Body_Formwrapper_con'>
                <div className='LandingPage9FormCon'>
                  <OtherFormsBlueCoast />
                </div>
              </div>
            </div>
    
            <div className='LandingPage9BottomLogo'>
              <span> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img></span>
            </div>
    
            {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_img1.webp' className='landingpage9_img1'></img> */}
          </div>
    
    
        </div>
  )
}

export default OtherFormViewBlueCoast