import React, { useEffect, useRef, useState } from 'react'
import { Chip, Drawer, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, Stack, TextField } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { randomId } from '../../helper/helperFunctions';
import { useDispatch } from 'react-redux';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import store from '../../store';

export const ChipTextBox = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {
  const [chips, setChips] = useState([])
  const [open, setOpen] = useState(false)
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)

  const dispatch = useDispatch()
  const ref = useRef()

  const handleTextInput = () => {
    // console.log('ref====>', ref.current.value)
    if (ref.current.value) {
      setChips((prev) => {
        return [...prev, ref.current.value]
      })
      ref.current.value = ''
    } else {
      setError(fielddata.name, { type: 'invalidInput', message: 'Please type something to add' })
    }

  }

  const handleDeleteChip = (index) => {
    setChips((prev) => {
      const prevChips = [...prev]
      prevChips.splice(index, 1);
      return prevChips
    })
  }

  const openChipsDrawer = () => {
    setOpen(true)
  }

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  /////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue && Array.isArray(fielddata.defaultValue) && fielddata.defaultValue.every((cur) => typeof (cur) == 'string')) {
      setValue(fielddata.name, fielddata.defaultValue);
      setChips(fielddata.defaultValue)
    } else {
      setError(fielddata.name, { type: 'invalidDefaultValue', message: 'Invalid format of Default Value' })
    }

  }, [])

  useEffect(() => {
    if (chips.length > 0) {
      setValue(fielddata.name, chips)
    } else {
      setValue(fielddata.name, undefined)
    }
  }, [chips])

  //////////////////////////////////////// Effect the field value update from outside //////////////////////////////
  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])


  store.subscribe(() => {
    const storeData = store.getState();

    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      // console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })


  return (
    <>
      <div className={"form_item " + fielddata.className}>
        {/* HEADING */}
        {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
        <div className='chip_textbox_container'>
          <div className='chip_textbox_input_container'>
            <TextField
              inputRef={ref}
              disabled={fielddata.disabled !== undefined ? fielddata.disabled : false}
              label={fielddata.label}
              type={(fielddata.inputType !== undefined || fielddata.inputType !== "") ? fielddata.inputType : "text"}
              error={
                Object.keys(errors).length > 0 && errors[fielddata.name] != null
              }
              helperText={errors[fielddata.name] && errors[fielddata.name].type ? (fielddata.errorMessage[errors[fielddata.name].type] || errors[fielddata.name].message) : ""}
              onChange={() => {
                clearErrors(fielddata.name)
              }}
            />
            <IconButton onClick={handleTextInput}><AddCircleIcon fontSize='large' /></IconButton>
          </div>
          {chips.length > 0 && (
            <div className='chips_container'>
              <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {chips.map((curChip, index) => {
                  if ((fielddata.chipLimit && index < fielddata.chipLimit) || index < 3) return <Chip key={index + randomId()} label={curChip} onDelete={() => handleDeleteChip(index)} />
                  else return
                })}
                {fielddata.chipLimit ? (chips.length > fielddata.chipLimit && <span onClick={openChipsDrawer} style={{ cursor: 'pointer' }}>+{chips.length - fielddata.chipLimit} More</span>) : (chips.length > 3 && <span onClick={openChipsDrawer} style={{ cursor: 'pointer' }}>+{chips.length - 3} More</span>)}
              </Stack>
            </div>
          )}
        </div>
      </div>
      <Drawer className='drawer' anchor='bottom' open={open} onClose={handleCloseDrawer}>
        <ol className='drawer_chip_list'>
          {chips.map((text, index) => (
            <li key={index + randomId()} className='drawer_chip_item'>
              {text}
            </li>
          ))}
        </ol>
      </Drawer>
    </>
  )
}
