import React, { useEffect, useRef, useState } from "react";
import ListingTable from "../../../../../listing/listing";
import { TableSkeleton } from "../../../../../helper/TableSkeleton";
import { LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { viewEmployeeList } from "../../EmployerReducer";

function EmployeeListCount({ employeeFetched }) {

  const dispatch = useDispatch();



  const employeeLoader = useSelector((state) => state.EmployerReducer?.loading ? state.EmployerReducer.loading : null)
  const employeeListDetails = useSelector((state) => state.EmployerReducer?.employeeListData ? state.EmployerReducer.employeeListData : null)
  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});

  useEffect(() => {
    // console.log("logedUserData======>>>>", logedUserData)
  }, [JSON.stringify(logedUserData)])


  const [employeeListActive, setEmployeeListActive] = useState([]);


  // /*****************************For Resolve*********************** */
  // const resolved = useSelector((state) =>
  //   state.ResolveReducer?.resolved["/new-employer-dashboard"]
  //     ? state.ResolveReducer.resolved["/new-employer-dashboard"]
  //     : false
  // );

  // useEffect(() => {
  //   if (!resolved) {
  //     dispatch(viewEmployeeList(tableDataforEmployeeList.current.reqBody))
  //   }
  // }, [])

  // useEffect(() => {
  //   if (employeeListDetails) {
  //     employeeFetched(true)
  //   }
  // }, [employeeListDetails])





  // ====================main table header===============
  var modifyTableHeadersForEmployee =
    [
      { val: "name", name: "Full Name" },
      { val: "email", name: "Email" },
      { val: "phone", name: "Phone Number" },
      { val: "address", name: "Address" },
      { val: "city", name: "City" },
      { val: "state", name: "State" },
      { val: "zip", name: "Zip" },
      {
        val: "status",
        name: "Status",
        customVal: { false: "Inactive", true: "Active" },
      },
      { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY" },
    ];

  // ======================main table for employee lsit==================

  let tableDataforEmployeeList = useRef({
    tableId: "tableForEmployeeData",
    tableTitle: "Active Employee",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "employer_manage/employees_list",
      tableCountEndpoint: "employer_manage/employees_list",
    },

    reqBody: {

      "condition": {
        "limit": 15,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      // "searchcondition": {
      //   employer_id: logedUserData?._id,
      //   // location_id: logedUserData?.roles == "is_subemployer" ? 
      //   status: true,
      // },
      "searchcondition": logedUserData?.main_role === "is_employer" ? {
        // employer_id: logedUserData?._id,
        $or: [{ employer_id: logedUserData?._id },
        { mainEmployer_id: logedUserData?._id },],
        status: true,
      } :
        {
          $or: [{ employer_id: logedUserData?._id },
          {
            location_id: {
              $in:
                logedUserData?.location_id

            }
          },

          ], status: true,
        },
      "project": {},
      "token": "",
      "email": "",
      "count": false
    },




    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: ["createdon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [],





  });

  const getList = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(tableDataforEmployeeList.current.reqBody),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "employer_manage/employees_list",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("++++cv+++++++", respdata);
    if (respdata && respdata.status === "success") {
      setEmployeeListActive([...respdata.results.res])
    }
    // setloaderFlag(false);
    employeeFetched(true);
  }

  useEffect(() => {
    if (employeeListActive && employeeListActive.length === 0) {
      // console.log("employeeListActive==============>>>>>>", employeeListActive)
      getList()
    }
  }, [])


  return (
    <>
      {employeeLoader ? <LinearProgress sx={{ marginTop: "10px" }} /> : ""}

      <div className="employee_Count">

        <div className="rolelist_mainwrp_Table">
          {!employeeLoader && Object.keys(employeeListActive).length > 0 ? (
            <div>

              <ListingTable
                tableData={tableDataforEmployeeList.current}
                dataset={employeeListActive}
                modifyHeaders={modifyTableHeadersForEmployee}
              />

            </div>
          ) : (
            <div>
              <TableSkeleton count={5} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EmployeeListCount;
