import React, { useState } from 'react'
import "../Components/LandingPage14.css"
import LandingPage14FormModal from '../LandingPage14FormModal'
import { setscrollView, setsetscrollView } from '../../../../Layout/layoutReducer'
import { useDispatch } from 'react-redux';

function Lp14block7({page}) {


  const dispatch = useDispatch();

  const [openModal, setopenModal] = useState(false);

  const modalOpen = () => {
    setopenModal(true);
  };

  const setsScroll = () => {
    dispatch(setscrollView("scroll"));
  }

  return (
    <>
      <div className='Lp14block7_main_wrapper'>
        <div className='landingpage14_common_width'>
          <div className='Lp14block7_sub_wrapper'>
            <div className='Lp14block7_sub_sec1_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/LP14_blc7_delivery_pic.webp`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/LP14_blc7_delivery_pic.webp'/> */}
            </div>
            <div className='Lp14block7_sub_sec2_wrapper'>
              <h1><span>Shipping is FREE in 2-4 business days, or</span>  accepted at 64,000 pharmacies nationwide including</h1>
            </div>
          </div>
          <div className='Lp14block7_sub1_wrapper'>
            <div className='Lp14block7_sub1_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl7_1logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl7_1logo.png'/> */}
            </div>
            <div className='Lp14block7_sub1_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl7_2logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl7_2logo.png'/> */}
            </div>
            <div className='Lp14block7_sub1_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl7_3logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl7_3logo.png'/> */}
            </div>
            <div className='Lp14block7_sub1_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl7_4logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl7_4logo.png'/> */}
            </div>
            <div className='Lp14block7_sub1_sec_wrapper'>
              <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_bl7_5logo.png`} />
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_bl7_5logo.png'/> */}
            </div>
          </div>
          <div className='Lp14block2_sub1_wrapper'>
            {page === "17" ? <button onClick={() => setsScroll()}>speak with one of our organization specialist right away</button> : <button onClick={() => modalOpen()}>Enroll Today</button>}
          </div>
        </div>
      </div>
      <div><LandingPage14FormModal openModal={openModal} setopenModal={setopenModal} /></div>
    </>
  )
}

export default Lp14block7