import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";

export const RedundantEventArray = ({ redundantEventArrayFetched }) => {

    const [redundantEventsList, setRedundantEventsList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const redundantEvents = {
        tableId: "redundantEventsLists",
        tableTitle: "Redundant Events",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/redundant-event-array-list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/redundant-event-array-list",
        },

        reqBody: {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["lastname", "email", "type"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchBarData: {
            heading: "Search Conflicting Fullday Events",
            api: {
                url: process.env.REACT_APP_API_URL,
                endPoint: "calendar-api/redundant-event-array-list",
                tableCountEndpoint: "calendar-api/redundant-event-array-list",
                reqBody: {
                    // count:true,
                    // "source": "users",
                    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
                    condition: {
                        limit: 5,
                        skip: 0,
                    },
                    sort: {
                        type: "desc",
                        field: "_id",
                    },
                },
            },

            searchSettings: {
                datesearch: [
                    {
                        id: 0,
                        heading: "Search By Joining Date",
                        startdatelabel: "Start Date",
                        enddatelabel: "End Date",
                        className: "formGroup createdon_datetime countDiv__column--col4",
                        submit: "Search",
                        field: "createdon_datetime",
                    },
                ],
                selectsearch: [
                    // {
                    //     id: 1,
                    //     label: "Search By Status",
                    //     field: "status",
                    //     type: "select",
                    //     values: [0, 1],
                    //     // value: "",
                    // },


                ],
                textsearch: [
                    // {
                    //     id: 1,
                    //     // heading: "Search By First Name",
                    //     label: "Search By Full Name ",
                    //     field: "name",
                    //     className: "formGroup firstname countDiv__column--col4",
                    //     value: "",
                    // },


                    // {
                    //     id: 3,
                    //     // heading: "Search By Email",
                    //     label: "Search By Email ",
                    //     field: "email",
                    //     className: "formGroup email  inputcol-2",
                    //     value: "",
                    // },
                ],

            },
        },
    };




    var modifyTableHeaders =
        [
            { val: "summary", name: "Summary" },
            // { val: "connected_gmail", name: "connected_gmail" },
            // { val: "user_email", name: "user_email" },
            { val: "organizer_email", name: "Organizer Email" },
            { val: "timespan", name: "Timespan" },
            { val: "slot_start_time_unix", name: "Appointment Start Time Unix" },
            { val: "slot_end_time_unix", name: "Appointment End Time Unix" },

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(redundantEvents.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendar-api/redundant-event-array-list",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setRedundantEventsList([...respdata.results.res]);
        }
        setloaderFlag(false);
        redundantEventArrayFetched(true)
    }

    useEffect(() => {
        if (redundantEventsList && redundantEventsList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList();
        }

    }, [redundantEventsList]);


    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">
                {!loaderFlag && redundantEventsList !== undefined ? (
                    <ListingTable
                        tableData={redundantEvents}
                        dataset={redundantEventsList}
                        modifyHeaders={modifyTableHeaders}
                    />
                ) : (<TableSkeleton count={5} />)}
                </div>
            </div>
        </>
    )
}