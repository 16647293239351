import React from 'react'
import "../Components/LandingPage14.css"
import { useSelector } from 'react-redux';

function Lp14blockfooter({ page }) {

  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});






  return (
    <>
      <div className='Lp14blockfooter_main_wrapper'>
        <div className='landingpage14_common_width'>
          <div className='Lp14blockfooter_sub_wrapper'>
            <img src={`${process.env.REACT_APP_CLOUDFRONT_IMG_URL}landingpage14/lp14_footer_logo.webp`} />
            {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage14/lp14_footer_logo.webp'/> */}
            <h5>THIS IS NOT INSURANCE </h5>
            <p>Questions? Please contact our Customer Support Team at <a href='tel:1-800-378-4025'>1-800-378-4025</a></p>
            {/* <p className='enrol_text'>Enrollment Code:"{userData.agent_code ? userData.agent_code : "N/A"}"</p> */}
            {page === "17" ? "" : <p className=''>Enrollment Code:"{userData.agent_code ? userData.agent_code : "N/A"}"</p>}
            {/* <p className=''>Enrollment Code:"{userData.agent_code ? userData.agent_code : "N/A"}"</p> */}
            {/* <p>Terms and Conditions | Privacy Policy</p> */}
            {(userData?.is_nakagames === true) &&
              <a href={`/nakagames/${userData?.user_name}`} target='_blank' className='linked_btn'>
                <button className=' lp14_btn SD_btn'>
                  join as an affiliate

                </button>
              </a>
            }

          </div>

        </div>
        <div className='Lp14blockfooter_sub1_wrapper'>
          <h4>2023 Access Discount Healthcare All Rights Reserved</h4>
        </div>
      </div>
    </>
  )
}

export default Lp14blockfooter