import React from 'react'
import "./Asblockstyle.css";

function Aslandingpageblock10() {
    return (
        <>
            <div className='aslandingpageblock10_mainwrapper'>
                <div className='aslandingpageblock10_subwrapper'>
                    <div className='aslandingpageblock10_colcontainer'>
                        <div className='aslandingpageblock10_col2'>
                            <div className='aslandingpageblock10_imgwrapper'>
                                <img src="https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/aslandingpageblock10_image1.webp" />
                            </div>
                        </div>
                        <div className='aslandingpageblock10_col8'>
                            <div className='aslandingpageblock10_textwrapper'>
                                <h1>What if my drug is not available in generic form?</h1>
                                <p>Even if your drug is not yet available in generic form, a similar generic drug may be just as effective. If you are taking a brand-name medication, ask your doctor if a generic equivalent drug could treat your condition just as well. Generally, brand-name drugs are protected under a 10-17 year patent, prohibiting a generic equivalent from being made. During this period, manufacturers control pricing to make up for expensive research and development. In the interests of saving time, money and taking care of patients, most doctors will consider substituting a generic therapeutic alternative. A therapeutic similar is the generic match for the brand-name drug that treats the same condition using different ingredients. In most cases, these alternatives are just as effective.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aslandingpageblock10
