import {Button,Grid,IconButton,LinearProgress,Modal,Tooltip,} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ListingTable from "../../../listing/listing";
import { useCookies } from "react-cookie";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../../form/Form";
import { clearDnNotes, getDataForNotes } from "./DashboardReducer";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import { setReloadTableFlag } from "../../../listing/listReducer";
import { resetForm, setField, setFieldExtraData } from "../../../form/formReducer";
import { cleargetNotesleaddata, getNotesleaddata } from "../LeadManagement/LeadReducer";
import { setInitialState } from "../../../form/fileUploaderReducer";
import NoteViewModal from "./NoteViewModal";
import store from "../../../store";

export default function DNotes({ openMoadl, closeModal, gridData, userName, collectionName, types, openMoadlforNotesUsertype }) {

  useEffect(() => {
   
    console.log("girddata for notes checkbox",gridData);
  }, [])
  
   


  const dispatch = useDispatch();
  const [notesListData, setNotesListData] = useState(false)//---state for lead notes data
  const [notesViewData,setNotesViewData]=useState({})
  const [notesViewState,setNotesViewState]=useState(false)
  const [hmName,setHmName]=useState(null)
  const [bddName,setBddName]=useState([])


  let userID = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null);


  //---------------------Fetch user Notes Data-----------------------
  let DNotesData = useSelector((state) =>state.DashboardReducer?.DNotesData ? state.DashboardReducer.DNotesData : []);
  //-------------------------------------------------------------------------- 

  //--------------------fetch lead note data-----------------------------
  let leadNotesData = useSelector((state) =>state.LeadReducer?.Notesleaddata ? state.LeadReducer.Notesleaddata : []);
  
  
  // -----------------------------------------------search Noates-----------------------------------------------------------------------
  const SearchedNoteID = useSelector(state=>state.formSlice?.formData?.searchForm?.notes?state.formSlice.formData.searchForm.notes:null)

  //-----------------------------------------------row data----------------------------------------------------------------------------
  const noteRowData = useSelector(state=>state.tableSlice?.tempRowData?.noteTableCoustom?state.tableSlice.tempRowData.noteTableCoustom:{})


  const AddNoteButton = useSelector(state=>state.formSlice?.formSubmissionState?.noteAddForm?state.formSlice.formSubmissionState.noteAddForm:0)
  const viewbtnClick = useSelector(state=>state.tableSlice?.customButtonClicked?.noteTableCoustom?.RemoveRedEyeIcon?state.tableSlice.customButtonClicked.noteTableCoustom.RemoveRedEyeIcon:false)

//---------------------------fetch check box data------------------------------------

const checkBox = useSelector(state=>state.formSlice?.formData?.noteAddForm?state.formSlice.formData.noteAddForm:null)

//  console.log("this is notes check box",checkBox);



  //----------------------------------Loader for dashboard data------------------------------
  const Dloading = useSelector((state) => state.DashboardReducer?.loading ? state.DashboardReducer.loading : false);
  //--------------------------------------------------------------------------------

  const formSubmissionState = useSelector((state) =>state.formSlice?.formSubmissionState?.noteAddForm ? state.formSlice?.formSubmissionState?.noteAddForm : undefined);


  //---------------------------defolt value --------------------------
  const searchFormData = useSelector((state) =>state.formSlice?.formData?.searchForm? state.formSlice.formData.searchForm: {});


  
//---------------------check box configration----------------------

useEffect(()=>{
  if(openMoadlforNotesUsertype==="lead"){

    if(checkBox){
      if(checkBox?.HM === true){
        for(let i=0;i<gridData?.hiring_manager_data.length ; i++){
          dataform.current.formAdditionalSubmissionData['MailTo_HM']=gridData?.hiring_manager_data[i]?._id
        }
  
      }
  
      if(checkBox?.AE === true){
   
          dataform.current.formAdditionalSubmissionData['MailTo_AE']=gridData?.rep_page_id
        
  
      }
      if(checkBox?.lastvisitMultiple){
        let arr=[]
  
        for(let j=0;j<checkBox?.lastvisitMultiple.length ;j++){
  
          for(let k=0;k<gridData?.bdd_data.length;k++){
  
            if(checkBox?.lastvisitMultiple[j]=== gridData?.bdd_data[k].name){
  
              arr.push(gridData?.bdd_data[k]._id)
  
            }
          }
        }
  
  
        dataform.current.formAdditionalSubmissionData['MailTo_BDD']=arr
        
        
  
  
      }
    }

  }


  if(openMoadlforNotesUsertype==="users"){
    if(checkBox){
      if(checkBox?.HM === true){
        for(let i=0;i<gridData?.hm.length ; i++){
          dataform.current.formAdditionalSubmissionData['MailTo_HM']=gridData?.hm[i]?._id
        }
  
      }


      if(checkBox?.lastvisitMultiple){
        let arr=[]
  
        for(let j=0;j<checkBox?.lastvisitMultiple.length ;j++){
  
          for(let k=0;k<gridData?.bdd.length;k++){
  
            if(checkBox?.lastvisitMultiple[j]===  `${gridData?.bdd[k].firstname} ${gridData?.bdd[k].lastname}`){
  
              arr.push(gridData?.bdd[k]._id)
  
            }
          }
        }
  
  
        dataform.current.formAdditionalSubmissionData['MailTo_BDD']=arr
        
        
  
  
      }




    }
  }
  
},[checkBox])


//=================================================================


  //----------------------------defolt value funtion------------------------------

  useEffect(() => {
    tableNotesData.current.searchData.formData.fields.forEach((data) => {
      data.defaultValue = searchFormData[data.name]? searchFormData[data.name] : undefined;
    });

    if (searchFormData !== undefined && searchFormData?.start_date !== undefined && searchFormData?.start_date !== null) {
      store.dispatch(
        setFieldExtraData({
          formId: "searchForm",
          fieldName: "end_date",
          data: { minTime: searchFormData?.start_date },
        })
      );
    }
    }, [JSON.stringify(searchFormData)]);
  








  //------------------------------------seting notes View data--------------------------------------
  useEffect(()=>{
    if((viewbtnClick === true) && (Object.keys(noteRowData).length > 0)){
      setNotesViewState(true)
      setNotesViewData(noteRowData)
    }
  },[viewbtnClick,noteRowData])






  //------------------------------------clear File from form ----------------------------------------
  useEffect(()=>{
    if(AddNoteButton === 1){
      dispatch(setInitialState({fieldName :"upload_file"}))
    }
  },[AddNoteButton])




//-------------------------------MAKING COMMON DATA---------------------------------------------MUKHES--
  useEffect(() => {
    if (leadNotesData.length > 0) {
      setNotesListData(leadNotesData);
    }
  }, [leadNotesData]);

  useEffect(() => {
    if (DNotesData.length > 0) {
      setNotesListData(DNotesData);
    }
  }, [DNotesData]);







  useEffect(() => {
    if (types === "dashboardNotes") {
      if ((formSubmissionState === 2 || formSubmissionState === 3) && (DNotesData.length > 0)) {
        dispatch(setReloadTableFlag({ tableId: "noteTableCoustom" }))

      } else if ((formSubmissionState === 2 || formSubmissionState === 3) && (DNotesData.length === 0)) {

        let reqbody = {
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            field: "createdon_datetime",
            type: "desc",
          },
          searchcondition: {
            "notes_for": gridData._id
          },
          project: {},
          token: "",
        };
        // console.log("this funtion warking 1");
        dispatch(getDataForNotes(reqbody));
        dispatch(setReloadTableFlag({ tableId: "noteTableCoustom" }))
      }
    }
  }, [formSubmissionState])



  useEffect(() => {
    setTimeout(() => {
      if (types === "dashboardNotes") {
        let reqbody = {
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            field: "createdon_datetime",
            type: "desc",
          },
          searchcondition: {
            "notes_for": gridData._id
          },
          project: {},
          token: "",
        };
        // console.log("this funtion warking 2");

        dispatch(getDataForNotes(reqbody));
        dispatch(setReloadTableFlag({ tableId: "noteTableCoustom" }))
      }
    }, 1000);
  }, []);



  //---------------------------Lead Notes Configration Notes Data---------------------------------

  useEffect(() => {
    if (types === "LeadNotes") {
      let reqbody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {
          "notes_for": gridData._id
        },
        project: {},
        token: "",
      };
      // console.log("this funtion warking 3");

      dispatch(getNotesleaddata(reqbody));
      dispatch(setReloadTableFlag({ tableId: "noteTableCoustom" }))
    }
  }, []);


  useEffect(() => {
    if (types === "LeadNotes") {
      if ((formSubmissionState === 2 || formSubmissionState === 3) && (DNotesData.length > 0)) {
        dispatch(setReloadTableFlag({ tableId: "noteTableCoustom" }))
      } else if ((formSubmissionState === 2 || formSubmissionState === 3) && (DNotesData.length === 0)) {
        let reqbody = {
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            field: "createdon_datetime",
            type: "desc",
          },
          searchcondition: {
            "notes_for": gridData._id
          },
          project: {},
          token: "",
        };
        dispatch(getNotesleaddata(reqbody));
        dispatch(setReloadTableFlag({ tableId: "noteTableCoustom" }))
      }
    }
  }, [formSubmissionState])

  //=================================================================================



  //-----------------------------------------------------search function ---------------------------------------------------------


  //---------------------------------BDD Data fetch---------------------------------------------------

  //------------------------------------------These Check box  Will fill according to lead data-------------------------------
  useEffect(() => {

    if(openMoadlforNotesUsertype==="lead"){

      if (gridData && gridData?.bdd_data) {
     
        let arr = [];
        for (let j in gridData?.bdd_data) {
          arr.push({ key:gridData?.bdd_data[j]._id, val: gridData?.bdd_data[j].name })
        }
        
  
  
        if((openMoadlforNotesUsertype==="lead" ) && gridData?.associated_bdd !==null && gridData?.associated_bdd!==undefined && gridData?.associated_bdd.length>0){
          let fieldBdds =  {
           id: 5,
           heading: "Send notification to BDD :-",
           name: "lastvisitMultiple",
           className: 'multipleCheckbox',
           hint: 'In months',
           type: 'multipleCheckbox',
           values:arr
          
         
         }
         setTimeout(() => dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldBdds })), 500);
       }
      }
  
    }

     }, [gridData])






  useEffect(() => {
    if((openMoadlforNotesUsertype==="lead" ) && gridData?.rep_id !==null && gridData?.rep_id !==undefined && gridData?.rep_id.length>0){

      let fieldAe = {
        id: 3,
        name: "AE",
        className: "inputBlock1line statusinput notesNotificationSpecial",
        type: 'singleCheckbox',
        values: {
           key: 0,
            val: `Send notification to : ${gridData.rep_name.split(" ")[0].concat(" ",gridData.rep_name.split(" ")[1])} (AE)` },
        disable: true,
      }
  
  
      //  dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldAe }))
      setTimeout(() => dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldAe })), 1000);

    }

    if((openMoadlforNotesUsertype==="lead" ) && gridData?.hiring_manager!==null && gridData?.hiring_manager !==undefined && gridData?.hiring_manager.length>0){


      for(let i=0;i<gridData?.hiring_manager_data.length;i++){

        let fieldHm = {
          id: 4,
          name: "HM",
          className: "inputBlock1line statusinput notesNotificationSpecial",
          type: 'singleCheckbox',
          values: {
             key: 0,
              val:`Send notification to : ${gridData.hiring_manager_data[i].name} (HM)`
             },
          disable: true,
        }
    
    
        //  dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldAe }))
        setTimeout(() => dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldHm })), 1000);

      }

    

    }



  }, [])

//==================================================================================================================


///-------------------------------These check box fill according to user data--------------------------------

useEffect(() => {
  if((openMoadlforNotesUsertype==="users" ) && gridData?.hm !==null && gridData?.hm !==undefined && gridData?.hm.length>0 && gridData?.main_role !== "is_bdd" && gridData?.main_role !== "hr_manager" && gridData?.main_role !== "is_aer"){

    for(let i=0;i<gridData?.hm.length;i++){

      let fieldHm = {
        id: 4,
        name: "HM",
        className: "multipleCheckbox notesNotificationSpecial",
        type: 'singleCheckbox',
        values: {
           key: 0,
            val:`Send notification to HM: ${gridData.hm[i].name}`
           },
        disable: true,
      }
  
  
      //  dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldAe }))
      setTimeout(() => dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldHm })), 1000);

    }
  }


}, [])

useEffect(() => {

  if(openMoadlforNotesUsertype==="users"){

    if (gridData && gridData?.bdd) {
   
      let arr = [];
      for (let j in gridData?.bdd) {
        arr.push({ key:gridData?.bdd[j]._id, val: `${gridData?.bdd[j].firstname} ${gridData?.bdd[j].lastname}`})
      }
      


      if(( openMoadlforNotesUsertype==="users") && gridData?.bdd !==null && gridData?.bdd!==undefined && gridData?.bdd.length>0 && gridData?.main_role !== "is_bdd" && gridData?.main_role !== "is_aer"){
        let fieldBdds =  {
         id: 5,
         heading: "Send notification to BDD:",
         name: "lastvisitMultiple",
         className: 'multipleCheckbox',
         hint: 'In months',
         type: 'multipleCheckbox',
         values:arr
        
       
       }
       setTimeout(() => dispatch(setField({ formId: "noteAddForm", after: "upload_file", fieldData: fieldBdds })), 500);
     }
    }

  }

   }, [gridData])




//===========================================================================================================




  var modifyTableHeaders = [
    { val: "notes", name: "Note" },
    { val: "added_name", name: "Added By" },
    // { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss" }
    { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY" }
  ];

  let tableNotesData = useRef({
    tableId: "noteTableCoustom",
    tableTitle: "Notes Table",
    showReload: true,
    showFilter: true,
    // adduser: true,
    payload_key: "notes_for",
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "note/note-list-count",
      deleteSingleUserEndpoint: "note/delete-notes",
      tableCountEndpoint: "note/note-list-count",
    },

    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        notes_for: gridData._id,
        
      },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5],
    sortFields: ["notes", "added_name", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        // condField: "notes_by",
        // condVal: userID,
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "note/delete-notes",
          body: {
            source:openMoadlforNotesUsertype==="lead"?undefined: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
      },
    },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "note-list-count",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      {
          id: "RemoveRedEyeIcon",
          label: "Attachment",
          type: "other",
          name: "remove_red_eye_icon",         
          condField: "file",
          condVal:"exist"
      }
      ,
      {
        id: "delete",
        label: "Delete",
        type: "api",
        condField: "notes_by",
        condVal: userID,
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "note/delete-notes",
          body: {
            source:openMoadlforNotesUsertype==="lead"?undefined: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      }
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },
    searchData: {
      heading: "Search Notes",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search By Notes",
            name: "notes",
            className: "inputBlock inputBlock3line",
            type: "autoComplete",
            sx: { m: 1, minWidth: 300 },
            base_url:
              process.env.REACT_APP_API_URL + "note/auto-complete-notes",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "_id",
                type: "desc",
              },
              searchcondition: {
                notes_for: gridData._id,
              },
              project: {},
              token: "",
              email: "",
            },
              payloadFormat: { key: "_id" },
          },

          {
            id: 2,
            label: "Search By Added By",
            name: "notes_by",
            className: "inputBlock inputBlock3line",
            type: "autoComplete",
            sx: { m: 1, minWidth: 300 },
            base_url:
              process.env.REACT_APP_API_URL +
              "note/auto-complete-note-added-by",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "_id",
                type: "desc",
              },
              searchcondition: {
                notes_for: gridData._id,
              },
              project: {},
              token: "",
              email: "",
            },
            payloadFormat: { key: "notes_by" },
          },
          {
            id: 8,
            label: "Search By Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });





  const dataform = useRef({
    id: 'noteAddForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "note/add-note",
    cancelBtnName: "Submit",
    className: "inputBlock inputBlockline",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    endPointBodyWrapper: "data",
    formAdditionalSubmissionData: {
      "notes_for": gridData._id,
      "notes_by": userID,
      collection_name: openMoadlforNotesUsertype === "lead" ? "google_events" : "users"


    },


    fields: [
      {
        id: 3,
        heading: "Notes",
        name: "notes",
        placeholder: "Please type here...",
        className: "inputBlock inputBlock1line",
        type: "textarea",
        rules: { required: true },
        resetForm: true,
        reqbody: {
          source: "",
          condition: { limit: 5, skip: 0 },
          sort: { type: "_", field: "assigned_datetime" },
          searchcondition: {
            notes_by: gridData._id,
            collection_name: "users",
          },
        },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 2,
        heading: "Upload file",
        name: "upload_file",
        className: "inputBlock inputBlock1line",
        type: "fileUploader",
        multiple: false,
        accept: ".pdf,image/*,.doc,.docx,video/*,audio/*", //////// Write properly /////////
        bucket: "uploaded-files-react",
        path: "aspireuploadimage/",
        base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        endpoint: "requestUploadURL",
        deleteEndpoint: "deletefilefromBucket",
      },


    ],
    customButtons: [],
  });






  const closeModalForNotes = () => {
    dispatch(clearDnNotes());
    dispatch(cleargetNotesleaddata());
    
    closeModal(false);
    setNotesListData([])
    dispatch(resetForm({formId:"searchForm"}))
    dispatch(setReloadTableFlag({ tableId: "userslist1" }));

  };

  // console.log("this is notes check box",dataform);

  return (
    <>

      <Modal
        className="image_card_modal modalBaseStyle "
        open={openMoadl}
        onClose={closeModalForNotes}
        closeAfterTransition
      >

        <Box className="modalBaseBox largeModal followup_container">
          <IconButton className="modal_icon_button" onClick={closeModalForNotes}>
            <CloseIcon className="modal_close_icon" />
          </IconButton>
          <h2 className="notes_details_custom_head">Notes For: {userName?.fullname ? userName.fullname : "N/A"}</h2>
          {Dloading && <LinearProgress />}
          <div className="dashboard_con3 note_list_head">
            {(notesListData.length > 0) && (
              <div className="rolelist_mainwrp_Table" style={{width:"100%"}}>
                <ListingTable
                  tableData={tableNotesData.current}
                  dataset={notesListData}
                  modifyHeaders={modifyTableHeaders}
                />
              </div>
            )}
          </div>

          <div className="dataform2_wrapper">
            <div className="adminformbody">
              <Form formData={dataform.current} />
            </div>
          </div>

        </Box>

      </Modal>
      {notesViewState && (
        <NoteViewModal ComonData={notesViewData}  modalState={notesViewState} togalModalState={setNotesViewState} />
      ) }


      {/* )} */}
    </>
  );
}
