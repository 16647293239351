import React from 'react'
import "../LandingPage13.css";

function Landingpageblock9() {
    return (
        <>
            <div className='Landingpage13block9_main_wrapper'>
                <div className='Landingpage13block9_cmn_width'>
                    <h1>Frequently Asked Questions</h1>
                    <h2>What’s the difference between a brand-name drug and a<br></br>
                         generic equivalent drug?</h2>
                    <p>According to the Food and Drug Administration, generic drugs are the same as their brand-name equivalents in active ingredients, dosage, safety, strength, and performance. They are held to the same strict standards as their brand-name counterparts. The only noticeable difference between a generic drug and its brand-name counterpart may be its shape and/or color. While generic drugs are just as effective as the corresponding brand-name drugs, they typically cost up to 70 percent less, which helps control healthcare costs.</p>
                </div>

            </div>
        </>
    )
}

export default Landingpageblock9