import React, { useEffect, useRef, useState } from 'react'
import { Form } from '../../../../form/Form';
import store from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { addemailcampaignapprover, clearaddedinfo, fetchemailapprover } from '../LandingpageReducer';
import { useNavigate } from 'react-router-dom';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { Box } from '@mui/system';
import { Alert, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import './OtherForms.css';

function OtherForms() {

  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [snackStatusSeverity, setSnackStatusSeverity] = useState('success')

  const formInputData = useSelector((state) =>
    state.formSlice.formData.userOtherForms ? state.formSlice.formData.userOtherForms : null
  );

  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userOtherForms) ? state.formSlice?.formSubmissionState?.userOtherForms : 0);

  const dataaddedinfo = useSelector((state) => state.LandingpagefontReducer?.addedemailapproverinfo ? state.LandingpagefontReducer.addedemailapproverinfo : null)

  // const snackStatus = useSelector((state)=> state.LandingpagefontReducer?.response?.status ? state.LandingpagefontReducer?.response?.status: false)



  const handleClose = (event, reason) => {
    setOpenSnack(false);
  };




  const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val }
    let body = {
      "firstname": val.firstname,
      "lastname": val.lastname,
      "email": val.email,
      "phone": val.phone,
      "company_name": val.company_name,
      "company_website": val.company_website,
      "company_policy": val.company_policy,
      "business_consumer_type": val.business_consumer_type,
      "createdon_datetime": new Date().getTime(),
      "status": 0,
      "email_campaign_approver": "aspire"
      // "roles": { is_rep: 1 },

      // is_rep: true,
    }

    if (Object.keys(body).length > 0) {
      console.log("body-----", body);

      setloading(true)
      let responsedataset = await store.dispatch(addemailcampaignapprover(body));
      setloading(false)
      console.log("responsedataset", responsedataset);
      if (responsedataset && responsedataset.payload && responsedataset.payload.status == 'error') {
        // dispatch(
        //     setSnackbar({ open: true, message: responsedataset.payload.message ? responsedataset.payload.message : 'Something went wrong', status: 'error' })
        // )
        setSnackMessage(responsedataset.payload?.message)
        setSnackStatusSeverity('error')
        setTimeout(() => { setOpenSnack(true) }, 1000)



      }
      else {

        redirect(responsedataset.payload.results.res._id)
        // console.log("dataaddedinfo",dataaddedinfo);

        // navigate(`/thankyou/bluecoastasphire`);
        // }
      }

    }
  };

  const redirect = (aeid) => {

    // dispatch(setSnackbar({ open: true, message: "Thank you for submitting!", status: 'success' }))

    setSnackMessage("Thank you for submitting!")
    setOpenSnack(true)
    setTimeout(() => { resolvefunction(navigate, `/email-campaign-approver-aspire/${aeid ? aeid : dataaddedinfo._id}`, fetchemailapprover(aeid ? aeid : dataaddedinfo._id)) }, 1000)
    dispatch(clearaddedinfo())
  }

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);


  const formOther = useRef({
    id: 'userOtherForms',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/addupdateemailcampaignapprovers",
    endPointBodyWrapper: "",
    urlPathOnSuccessfulFormSubmission: "/thankyou/r",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {

      //   "status": 0,
      // "roles": { is_rep: 1 },

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "AE First Name",
        label: "AE First Name",
        name: "firstname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 15,
        heading: "AE Last Name",
        label: "AE Last Name",
        name: "lastname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 13,
        heading: "AE Email",
        label: "AE Email",
        name: "email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 14,
        heading: "AE Phone Number",
        label: "AE Phone Number",
        name: "phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 16,
        heading: "Company Name (You are Buying/Hiring the List From)",
        label: "Company Name (You are Buying/Hiring the list)",
        name: "company_name",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 21,
        heading: "Company Website",
        label: "Company Website",
        name: "company_website",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 17,
        heading: "Company Policy Page Link",
        label: "Company Policy Page Link",
        name: "company_policy",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 11,
        heading: "Who do you plan to email?",
        name: "business_consumer_type",
        className: 'form_radio',
        values: [{ key: 'Business', val: 'Business' }, { key: 'Consumers', val: 'Consumers' }],
        type: 'multipleCheckbox',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: "No"
      },
      {
        id: 125,
        // heading: "Captcha",
        // label: "Captcha",
        name: "captcha",
        className: 'formfild formfild4',
        type: "captcha",
        rules: { required: true },
        errorMessage: {
          required: "Prove, You're Not a Robot",
          custom: "Value is Invalid"
        },
      },





    ]

  });
  return (
    <>
      <div className='otherForm_mainWrpr'>
        <Form formData={formOther.current} />
        {loading ? (<Box className="loaderProgress" sx={{ width: "100%", marginTop: '15px' }}><LinearProgress /></Box>) : (null)}
      </div>
      <Snackbar
        className="form_error_snackbar"
        open={openSnack}
        autoHideDuration={10000}
        onClose={() => handleClose()}
        // message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => handleClose()}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        {/* <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClose}> */}
        <Alert onClose={handleClose} severity={snackStatusSeverity} > {snackMessage}</Alert>
      </Snackbar>

    </>

  )
}

export default OtherForms