import { Chip, IconButton, Modal, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { timeConverter } from "../../../../helper/helperFunctions";

const PreviewModal = (props) => {
  const [saveDataRow, setSaveDataRow] = useState({});
  const tempRowDataCopy = useSelector((state) =>
    state?.tableSlice?.tempRowData?.userslist1
      ? state.tableSlice.tempRowData.userslist1
      : null
  );

  useEffect(() => {
    console.log("tempRowDataCopy=========>>>>>", tempRowDataCopy);
    if (
      tempRowDataCopy !== null &&
      tempRowDataCopy !== undefined &&
      Object.keys(tempRowDataCopy).length > 0
    )
      setSaveDataRow(tempRowDataCopy);
  }, [JSON.stringify(tempRowDataCopy)]);

  const handleCloseModal = () => {
    props.setPreviewOpen(false);
  };

  return (
    <>
      <Modal
        className=" modalblock userList_modal SR_UserDetail_preview"
        open={props.previewOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`BoxMui_modal ${
            props.type === "materialInfo"
              ? "onlymaterial_BoxMui_modal"
              : "other_BoxMui_modal"
          }`}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <div className=" ModalBox_scroll">
            <div className="heading">
              <h2>User Details</h2>
            </div>
            {/* <div>{JSON.stringify(saveDataRow)}</div> */}
            <ul className="listflexblock">
              {saveDataRow ? (
                <li>
                  {" "}
                  <strong>Full Name :</strong>{" "}
                  <span>
                    {saveDataRow?.firstname + " " + saveDataRow?.lastname}
                  </span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.email ? (
                <li>
                  <strong>E-mail :</strong>
                  <span
                    dangerouslySetInnerHTML={{ __html: saveDataRow.email }}
                  ></span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.phone ? (
                <li>
                  <strong>Phone :</strong>
                  <span
                    dangerouslySetInnerHTML={{ __html: saveDataRow.phone }}
                  ></span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.address &&
              saveDataRow?.main_role !== "is_employer" ? (
                <li>
                  <strong>Address :</strong>
                  <span> {saveDataRow.address}</span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.state &&
              saveDataRow?.main_role !== "is_employer" ? (
                <li>
                  {" "}
                  <strong>State :</strong>
                  <span> {saveDataRow.state}</span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.city && saveDataRow?.main_role !== "is_employer" ? (
                <li>
                  {" "}
                  <strong>City :</strong> <span>{saveDataRow.city}</span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.zip && saveDataRow?.main_role !== "is_employer" ? (
                <li>
                  {" "}
                  <strong>Zip :</strong> <span>{saveDataRow.zip}</span>
                </li>
              ) : (
                ""
              )}
              {props.sourceRoleListing !== "employer_listing" &&
              saveDataRow?.roles !== undefined &&
              Object.keys(saveDataRow?.roles).length > 0 ? (
                <li>
                  {" "}
                  <strong>Type :</strong>{" "}
                  <span>
                    {saveDataRow?.roles?.is_admin === 1 ? (
                      <Chip
                        className="viewModChips"
                        label="Admin"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}{" "}
                    {saveDataRow?.customer_support === 1 ? (
                      <Chip
                        label="Customer Support"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.hr_manager === 1 ? (
                      <Chip
                        label="Hiring Manager"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.tech_support === 1 ? (
                      <Chip
                        label="Tech Support"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.is_rep === 1 ? (
                      <Chip
                        label="AE"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.is_aer === 1 ? (
                      <Chip
                        label="AEE"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.is_bdd === 1 ? (
                      <Chip
                        label="BDD"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.is_affiliate === 1 ? (
                      <Chip
                        label="TRAFFIC PARTNER"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.is_partner === 1 ? (
                      <Chip
                        label="PROFESSIONAL PARTNER"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                    {saveDataRow?.roles?.is_employer === 1 ? (
                      <Chip
                        label="Employer"
                        className="viewModChips"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </li>
              ) : (
                ""
              )}
              {saveDataRow?.status == 1 ? (
                <li>
                  {" "}
                  <strong>Status:</strong> <span>Active</span>
                </li>
              ) : (
                ""
              )}

              {saveDataRow?.createdon_datetime ? (
                <li>
                  {" "}
                  <strong> Added On :</strong>{" "}
                  <span>
                    {timeConverter({
                      time: saveDataRow?.createdon_datetime,
                      format: "MM/DD/YYYY hh:mm A",
                    })}
                  </span>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>

          <Tooltip title="Close" placement="right-start">
            <IconButton className="modalCloseBtn" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Modal>
    </>
  );
};

export default PreviewModal;
