import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import store from '../../../../store';
import { resolvefunction, randomId, timeConverter } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import './ManageJobtickets.css'
import { Button, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../JobticketsCategory/JobticketsCategory.css'

import { getchangestatusdata, cleareditjobticketsdata, getjobticketreplydata, geteditjobticketsdata, addjobcatereplydata, getjobticketlistdata, clearjobticketreplydata, clearjobcatereplydata } from '../../Jobtickets/JobTicketReducer';
import moment from 'moment';
import { Cookies } from 'react-cookie';

// import { randomId, timeConverter } from '../../helper/helperFunctions'
import { LinearProgress, Paper } from '@mui/material';
import { Box } from '@mui/system';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { setSnackbar } from '../../../Layout/layoutReducer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ReplyJobticket() {
    const cookies = new Cookies().getAll();
    const [selecttypeArry, setselecttypeArry] = useState([]);
    const pathname = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [active, setActive] = useState(null)
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const resolved = useSelector(state => state.ResolveReducer?.resolved[`/reply-jobticket/${id}`] ? state.ResolveReducer.resolved[`/reply-jobticket/${id}`] : false)
    const editdata = useSelector(state => (state.JobTicketReducer?.editjobticketsdata && state.JobTicketReducer?.editjobticketsdata.length > 0) ? state.JobTicketReducer.editjobticketsdata[0] : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //



    const jobticketformsubmitionstate = useSelector(state => (state.formSlice?.formSubmissionState?.replyaddForm)? state.formSlice.formSubmissionState.replyaddForm: null )
    const jobticketformdeta = useSelector(state => (state.formSlice?.formData?.replyaddForm) ? state.formSlice.formData.replyaddForm : null)
    
    const jobticketreplaydata = useSelector(state => (state.JobTicketReducer?.jobticketreplydata && state.JobTicketReducer?.jobticketreplydata.length > 0) ? state.JobTicketReducer.jobticketreplydata : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //

    const jobticketsubmittionstate = useSelector(state => state.JobTicketReducer.jobticketsubmittionstate ? state.JobTicketReducer.jobticketsubmittionstate : 0);

    const replybtnclick = useSelector(state => state.JobTicketReducer.jobticketsubmittionstate ? state.JobTicketReducer.jobticketsubmittionstate:0)


    const changestatusdata = useSelector(state => (state.JobTicketReducer?.changestatusdata && state.JobTicketReducer?.changestatusdata.length > 0) ? state.JobTicketReducer.jobticketreplydata : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //

    
    const usertypestste = useSelector(state => state.loginSlice.user_slug ? state.loginSlice.user_slug : 0);


    const responseData = useSelector(state => (state.JobTicketReducer?.jobticketlistdata && state.JobTicketReducer?.jobticketlistdata.length > 0) ? state.JobTicketReducer.jobticketlistdata : [])

    // const [liststatus, setListstatus] = useState()
    

    // useEffect(() => {
    //     setListstatus(editdata.status)
    // }, [])


    useEffect(() => {
        console.log("responseData>>>", responseData);
     }, [loader, responseData])

   
    // let liststatus = editdata.status
    // console.log("liststatus", liststatus);

    let reqbody = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        // "searchcondition": { $or: [{ status: { $regex: 'close', $options: "i" } }] },
        // "searchcondition": {
        //     "status": responseData?.status == "open"
        // },
        "project": {},
        "token": ""
    }

    // const [searchstatus, setSearchstatus] = useState('')

    console.log("editdata and responseData", editdata, responseData);

    useEffect(() => {
        if (editdata && editdata.status) {
            // setSearchstatus(editdata.status);
            reqbody.searchcondition = { $or: [{ status: { $regex: editdata.status, $options: "i" } }], "ticket_id": { "$ne": editdata.ticket_id } }
            store.dispatch(getjobticketlistdata(reqbody))
        }

    }, [editdata])
    // console.log("searchstatus", searchstatus);

    useEffect(() => {
       
        // store.dispatch(getjobcategorydata(reqbodyforjobcategory));
        store.dispatch(getjobticketreplydata(id));
       
        if (!resolved) {
            // store.dispatch(getjobticketlistdata(reqbody))
            if (id !== undefined && pathname !== null && pathname.pathname !== undefined) {
                console.log("ppp>>", editdata)
                store.dispatch(geteditjobticketsdata(id));

            }

        }
        // console.log("dateval", dateval);
       

        return () => {
            store.dispatch(cleareditjobticketsdata())
            store.dispatch(resetresolvenew('/reply-jobticket/:id'))
        }
    }, [])







    useEffect(() => {
        if (jobticketsubmittionstate == 2)  store.dispatch(getjobticketreplydata(id));
       
    }, [jobticketsubmittionstate])

    const dispatch = useDispatch();

    useEffect(() => {
        if (replybtnclick == 2){
            console.log("replybtnclick", replybtnclick);
            dispatch(setSnackbar({ open: true, message: "Reply Successfully !", status: 'success' }))
        } ;

    }, [replybtnclick])

    // reply form submit 

    useEffect(() => {
        console.log("jobticketformdeta------", jobticketformsubmitionstate, jobticketformdeta);
        if (jobticketformsubmitionstate && jobticketformsubmitionstate==4){
            calladdapi(jobticketformdeta)
        }
    }, [jobticketformsubmitionstate, jobticketformdeta ])

    const calladdapi = async(val)=>{
        val={...val}
        let body = {
            "reply_txt": val.reply_txt,
            "user_id": cookies.userinfo._id,
            "ticketid": id,
            "reply_by": cookies.userinfo.main_role,
            "reply_file": val.reply_file,
        }
        console.log("body-----", body);
        if(Object.keys(body).length> 0){
            setLoader(true)
            await store.dispatch(addjobcatereplydata(body));
            setActive(false)
            setLoader(false)
        }

    }

    useEffect(() => {
        console.log("jobticketreplaydata--", jobticketreplaydata);
    }, [jobticketreplaydata])





    // console.log("editdata--", editdata);
    
    

    const dataform = {
        id: 'replyaddForm',
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "lead-manage/addorupdatejobticket",
        urlPathOnSuccessfulFormSubmission: "",
        submitBtnName: "Submit",
        cancelBtnName: "Cancel",
        className: 'inputBlock inputBlock3line',
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        

        fields: [
            {
                id: 1,
                heading: "Description",
                label: "SunEditor",
                name: "reply_txt",
                type: "sunEditor",
                className: "inputBlock1line",
                // rules: { required: true },
                // errorMessage: {
                //   required: "This Field is Required",
                //   //   minLength: "Minimum length should be 15",
                //   //   custom: "Value is Invalid"
                // },

                placeholder: "Please type here...",
                ////////////////////////////// Mandatory or no options will be available /////////////////////
                editorWidth: 300,
                buttonListOptions: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                ],
                // defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.ticket_des !== undefined) ? editdata.ticket_des : undefined,

            },
            {
                id: 8,
                heading: "Thumbnail",
                lable: "Add Thumbnail",
                name: "reply_file",
                className: "inputBlock inputBlock1line",
                type: "fileUploader",
                multiple: true,

                accept: ".pdf,image/*,.doc,.docx,video/*,audio/*",//////// Write properly /////////
                bucket: "uploaded-files-react",
                path: "aspireuploadimage/",
                base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
                endpoint: "requestUploadURL",
                deleteEndpoint: "deletefilefromBucket",

                // defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.thumbnail !== undefined) ? editdata.thumbnail[0] : undefined,

                // defaultFileLinks: (editdata && Object.keys(editdata).length > 0 && typeof editdata?.ticket_attachment !== undefined && typeof editdata?.ticket_attachment == undefined) ? [{

                //     url: editdata.ticket_attachment.url,
                //     type: "image",
                //     name: editdata.ticket_attachment.file_local_name

                // }] : undefined


            },
        ]
    }

    const findfileextenssion = (val) => {
        var re = /(?:\.([^.]+))?$/;
        let extension = re.exec(val)[1];
        console.log("extension", extension);
        if (extension != '' && extension !== undefined && (extension == 'webp' || extension == 'WEBP' || extension == 'png' || extension == 'PNG' || extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' || extension == 'JPEG' || extension == 'gif' || extension == 'GIF' || extension == 'bmp' || extension == 'BMP' || extension == 'ico' || extension == 'ICO' || extension == 'svg' || extension == 'SVG' || extension == 'tif' || extension == 'TIF' || extension == 'tiff' || extension == 'TIFF')) {
            return 'img'
        }
        if (extension != '' && extension !== undefined && (extension == 'mp4' || extension == 'MP4' || extension == 'mkv' || extension == 'MKV' || extension == 'wemb' || extension == 'WEMB' || extension == 'flv' || extension == 'FLV' || extension == 'avi' || extension == 'AVI' || extension == 'mov' || extension == 'MOV' || extension == 'swf' || extension == 'SWF' || extension == 'wmv' || extension == 'WMV')) {
            return 'video'
        }
        if (extension != '' && extension !== undefined && (extension == 'mp3' || extension == 'MP3' || extension == 'aif' || extension == 'AIF' || extension == 'cda' || extension == 'CDA' || extension == 'mid' || extension == 'MID' || extension == 'mpa' || extension == 'MPA' || extension == 'ogg' || extension == 'OGG' || extension == 'wav' || extension == 'WAV' || extension == 'wma' || extension == 'WMA' || extension == 'wpl' || extension == 'WPL')) {
            return 'audio'
        }
        if (extension != '' && extension !== undefined && (extension == 'pdf' || extension == 'PDF' || extension == 'tex' || extension == 'TEX' || extension == 'txt' || extension == 'TXT')) {
            return 'pdf'
        }
        if (extension != '' && extension !== undefined && (extension == 'doc' || extension == 'DOC' || extension == 'docx' || extension == 'DOCX' || extension == 'xlsx' || extension == 'XLSX' || extension == 'xlsm' || extension == 'XLSM' || extension == 'pptx' || extension == 'PPTX' || extension == 'csv' || extension == 'CSV' || extension == 'xml' || extension == 'XML' || extension == 'odp' || extension == 'ODP' || extension == 'ppt' || extension == 'PPT' || extension == 'pps' || extension == 'PPS' || extension == 'ods' || extension == 'ODS' || extension == 'xls' || extension == 'XLS' || extension == 'odt' || extension == 'ODT' || extension == 'rtf' || extension == 'RTF')) {
            return 'doc';
        }
        // return false;
    };

    // let modalDatat =;
    const [modalDatat, setmodalDatat] = useState(null);
    const [flag, setflag] = useState("");

    const handleOpen = (modalData, flag) => {
        if (flag =='image' && !open){
            console.log("callll", flag)
            setOpen(true)
            setmodalDatat(modalData.url) 
            setflag("image")
        }
        else if (flag == 'pdf' && !open) {
            console.log("callll", flag)
            setOpen(true)
            setmodalDatat(modalData.url)
            setflag("pdf")
        }
        else if (flag == 'video' && !open) {
            console.log("callll", flag)
            setOpen(true)
            setmodalDatat(modalData.url)
            setflag("video")
        }
        else if (flag == 'audio' && !open) {
            console.log("callll", flag)
            setOpen(true)
            setmodalDatat(modalData.url)
            setflag("audio")
        }
        else{
            setOpen(false)
        }
        
    }

   const ststusarry = [
       { "val": "open", name: "Open" },
       { "val": "close", name: "Close" },
       { "val": "awaiting_support_reply", name:"Awaiting Support Reply"},
       { "val": "suspend", name: "Suspend" }
   ]

    const [status, setStatus] = React.useState('');
    
    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    useEffect(() => {
       
    }, [editdata])
    // console.log("status----", status);

    const statuschange = (e) => {
        callststuschangeapi(e)
        console.log("changestatusdata", e);
    }


    useEffect(() => {
        
    }, [status])

   
    // useEffect(() => {
    //     store.dispatch(getchangestatusdata(status))
    // }, [status])
    // console.log("status===>>>", status);
   
    const callststuschangeapi = async (val) => {
      
        let body = {
            "_id": id,
            "status": val,
        }
        console.log("body-----", body);
        if (Object.keys(body).length > 0) {
            setLoader(true)
            await store.dispatch(getchangestatusdata(body));
            setActive(false)
            setLoader(false)
            setLoader(true)
            await store.dispatch(geteditjobticketsdata(id))
            setLoader(false)
            
        }

    }

    const [usertype, setUsertype] = React.useState(false);


    useEffect(() => {

        usertypestste.forEach((userdata) => {
            if (userdata && userdata !== undefined && userdata === 'is_admin') {
                setUsertype(true)
                
            }
            
            console.log("usertype>>>", usertype);
        })

    }, [usertype])


    // useEffect(() => {
    //     store.dispatch(getjobticketreplydata(replyDatatid))
     
    // }, [])
    
    const [replyDatat, setreplyDatat] = useState(null);
    const [replyDatatid, setreplyDatatid] = useState(null);
    const [replyflag, setreplyflag] = useState(true);

    const replyclock = (replylistData, flag) => {
        if (flag == '1'){
            setreplyflag(false)
        }
        else{
            setreplyflag(true)
        }
        setreplyDatat(replylistData)

        setreplyDatatid(replylistData._id)
        console.log("changestatusdataaaaaa", replylistData);
        setLoader(true)
        // navigate(`/reply-jobticket/${replylistData._id}`);
        store.dispatch(getjobticketreplydata(replylistData._id))
        setLoader(false)
    } 
    console.log("replyDatat", replyDatatid,);



  return (
    <>
          <div className='rolelist_mainwrp'>
              <div className='listing_heading'>
                  <h1>Job Ticket ID: {replyDatat !== null ? replyDatat.ticket_id : editdata && editdata.ticket_id}</h1>
              </div>
              <div className='rolelist_mainwrp_Table replyblock'>

                  <div className='folder_view_body'>
                      <div className='folder_view_left'>
                          {/* <h3 className='replyblock_title'>
                              <span>Current Status</span> <span>{editdata !== null && editdata?.status == "open" ? "Open" : editdata?.status == "replied" ? "Replied" : editdata !== null && editdata?.status == "close" ? "Close" : editdata !== null && editdata?.status == "suspend" ? "Suspend" : "Awaiting Support Reply"}</span>
                        </h3> */}


                          <div className={`profile_block ${replyflag == true ? "active":"aaa" }`} onClick={() => replyclock(editdata, "0")}>
                              {/* <p>{replyDatatid} {editdata && editdata._id}</p> */}
                              <div className='profile_block_left'>
                                  <img src='https://backoffice.betoparedes.com/assets/images/user.png' />
                              </div>
                              <div className='profile_block_right'>
                                  <h4>
                                      Status :
                                      <span>{editdata !== null && editdata?.status == "open" ? "Open" : editdata?.status == "replied" ? "Replied" : editdata !== null && editdata?.status == "close" ? "Close" : editdata !== null && editdata?.status == "suspend" ? "Suspend" : "Awaiting Support Reply"}</span>
                                  </h4>
                                <h4>
                                    Added By :
                                    <span>{editdata !== null ? editdata.user_name : ""}</span>
                                </h4>
                                  <h4>
                                      Added On :
                                      <span>{editdata !== null ? moment(editdata.createdon_datetime).format("MM/DD/YYYY") : ''}</span>
                                  </h4>
                              </div>
                        </div>

                          {responseData.map((managejobticketdata) => {
                              return (
                                  <div className={`profile_block ${replyDatatid == managejobticketdata._id ? "active" : "aaa"}`} onClick={() => replyclock(managejobticketdata, "1")}>
                                      <div className='profile_block_left'>
                                          <img src='https://backoffice.betoparedes.com/assets/images/user.png' />
                                      </div>
                                      <div className='profile_block_right'>
                                          <h4>
                                              Status :
                                              <span>{managejobticketdata !== null && managejobticketdata?.status == "open" ? "Open" : managejobticketdata?.status == "replied" ? "Replied" : managejobticketdata !== null && managejobticketdata?.status == "close" ? "Close" : managejobticketdata !== null && managejobticketdata?.status == "suspend" ? "Suspend" : "Awaiting Support Reply"}</span>
                                          </h4>
                                          <h4>
                                              Added By :
                                              <span>{managejobticketdata !== null ? managejobticketdata.user_name : ""}</span>
                                          </h4>
                                          <h4>
                                              Added On :
                                              <span>{managejobticketdata !== null ? moment(managejobticketdata.createdon_datetime).format("MM/DD/YYYY") : ''}</span>
                                          </h4>
                                      </div>
                                  </div>
                              )
                          })}




                          {/* <div className='profile_textblock'>
                                <h2>
                                  <strong>Title:</strong>
                                  <span>{editdata !== null ? editdata.ticket_name : ""}</span>
                                </h2>
                              <h2>
                                  <strong>Category Name:</strong>
                                  <span>{editdata !== null ? editdata.category_name : ""}</span>
                              </h2>
                              <h2>
                                  <strong>Status:</strong>
                                  <span>{editdata !== null && editdata?.status == "open" ? "Open" : editdata?.status == "replied" ? "Replied" : "Awaiting Support Reply"}</span>
                              </h2>
                              <h2>
                                  <strong>Added On:</strong>
                                  <span>{editdata !== null ? moment(editdata.createdon_datetime).format("MM/DD/YYYY") : ''}</span>
                              </h2>
                          </div> */}
                      </div>
                      <div className='folder_view_con'>
                          <h3 className='folder_view_con_title replyrighttitle'>
                            <div className='lefttitlecon'>
                                  Catagory Name : {replyDatat !== null ? replyDatat.category_name : editdata && editdata.category_name}
                            </div>
                            <div className='righttitlecon'>

                                  {usertype && usertype == true ? <div>
                                      {editdata && Object.keys(editdata).length > 0 && <FormControl fullWidth>
                                          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                          <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              //   value={age}
                                              //   label="Age"
                                              onChange={handleChange}
                                              defaultValue={editdata !== null ? editdata?.status : editdata?.status}
                                          >
                                              {ststusarry.map((changestatusData) => {
                                                  return (
                                                      <MenuItem className={changestatusData.name === "Close" || changestatusData.name === "Suspend" ? "statusshow":"statushide"} onClick={() => statuschange(changestatusData.val)} value={changestatusData.val}>{changestatusData.name}</MenuItem>
                                                  )
                                              })
                                              }


                                          </Select>
                                      </FormControl>}
                                  </div> :""}
                                  
                                 
                            </div>
                            
                            
                            </h3>
                          {/* <h4>
                              <strong>Added On:</strong>
                              <span>{editdata !== null ? editdata.ticket_name : ""}</span>
                          </h4> */}
                           <h4>
                              <strong>Title:</strong>
                              <div dangerouslySetInnerHTML={{ __html: replyDatat !== null ? replyDatat.ticket_name : editdata && editdata.ticket_name }}></div>
                          </h4>
                          <h4>
                              <strong>Description:</strong>
                              <div dangerouslySetInnerHTML={{ __html: replyDatat !== null ? replyDatat.ticket_des : editdata && editdata.ticket_des }}></div>
                          </h4>
                          
                          {jobticketreplaydata && jobticketreplaydata.map((replydata)=>{
                              return(
                                  <div className='chat_mainwrapper'>
                                      <div className='chat_user'>
                                          <img src='https://backoffice.betoparedes.com/assets/images/user.png' />
                                      </div>
                                      <div className='chat_content'>
                                          <div className='reply_text' dangerouslySetInnerHTML={{ __html: replydata.reply_txt }} ></div>

                                          
                                          
                                          <div className='fileswrapper'>
                                          {replydata.reply_file.map((replyfiledata) => {
                                              return (
                                                <div className='filewrap'>

                                                      {(findfileextenssion(replyfiledata['url']) == 'img') ? <div className='replay_imagewrapper' onClick={() => handleOpen(replyfiledata, "image")}><img src={replyfiledata.url} /></div> : (findfileextenssion(replyfiledata['url']) == 'pdf') ? <div className='replay_imagewrapper' onClick={() => handleOpen(replyfiledata, "pdf")}><img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/pdf_icon.jpg" /></div> : (findfileextenssion(replyfiledata['url']) == 'video') ? <div className='replay_imagewrapper' onClick={() => handleOpen(replyfiledata, "video")}><img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/video_icon.jpg" /></div> : (findfileextenssion(replyfiledata['url']) == 'audio') ? <div className='replay_imagewrapper' onClick={() => handleOpen(replyfiledata, "audio")}><img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/audio_icon.jpg" /></div> :null }

                                                </div>
                                               
                                              )
                                          })} 
                                          </div>
                                      </div>
                                     
                                  </div>
                              )
                              
                          })}

                          
                          <div className='Formsubmit_button replybtnwrapper'>
                              <Button className='' onClick={() => setActive(true) }>Reply</Button >
                              {/* <Button className='' onClick={() => statuschange('close')}>Close</Button > */}
                          </div>
                          
                          {active == true ? <Form formData={dataform} /> : ""}
                          {loader && (
                              <Box sx={{ width: "100%" }}>
                                  <LinearProgress />
                              </Box>
                          )}
                          
                      </div>
                  </div>

    </div>
    </div>

{/* <div className='jobticket_blog_listwrapper'>
    
              {responseData.map((managejobticketdata)=>{
                return(
                    <div className='jobticket_blog_list'>
                        <div className='jobticket_blog_list_image'>
                            <img src='https://backoffice.betoparedes.com/assets/images/user.png' />
                        </div>
                        <p>{managejobticketdata.category_name}</p>
                        <p>{managejobticketdata.status}</p>
                    </div>
                )
              })}
</div> */}




          {open ?
              <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="replay_imgandvideomodal"
              >
                  <Box sx={style} className="BoxMui_modal">
                     
                      {flag && flag == "image" ? <img src={modalDatat} /> : "" } 
                      {flag && flag == "pdf" ? <embed src={modalDatat} width="100%" height="600px" class='docshowifram' />: "" } 
                      {flag && flag == "video"  ?
                          <video preload="auto" controls autoPlay className='player'>
                              <source src={modalDatat} type="video/mp4" />
                          </video>
                          :  "" 

                          }

                      {flag && flag == "audio" ? 
                      <audio controls>
                          <source src={modalDatat} />

                      </audio> : ""}
                      

                      <Button onClick={handleClose} className="closeModalBtn">
                          <Tooltip title="Close" placement="right-start">

                              <CloseIcon />
                          </Tooltip>
                      </Button>
                  </Box>
              </Modal>
              : ""}
          {/* <p>{editdata !== null ? <span>{editdata.ticket_name}</span> : ''}</p> */}
    </>
  )
}

export default ReplyJobticket
