import { useSelect } from '@mui/base';
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from "../../../form/Form";
import store from '../../../store';


import { fetchUserData, updateUserInfoAfterUpdateProfile } from "../userManagement/fetchUserDataReducer";
import CircularProgress from '@mui/material/CircularProgress';

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { resetForm } from '../../../form/formReducer';
import StateArray from '../../../assets/json/state';
import { setLogedinUserInfo } from '../../login/loginReducer';
import { BlueCoastlist } from '../BlueCoast/BlueCoastReducer';
import { resolvefunction } from '../../../helper/helperFunctions';




function Profile() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [signUpMsg, setsignUpMsg] = useState(""); // snackbar message  const
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = React.useState(false); // snackbar const
    const [profileFormOneState, setprofileFormOneState] = useState(null); // signup Form  success =2  const
    const [userInfo, setuserInfoCookie] = useCookies(['userinfo']);
    const [fetchprofileData, setFetchprofileData] = useState(null)
    const [resMsg, setResMsg] = useState(null);
    const [formToogle, setFormToogle] = useState(false)
    const [locationArry, setLocationArry] = useState([]);

    // console.warn("<<<<<<<<< userInfo >>>>>>>", userInfo)


    // --- fetched data from query param --- 
    const fetchData = useSelector(state => (state.fetchUserDataReducer?.fetchUserDataReducer
    ) ? state.fetchUserDataReducer.fetchUserDataReducer
        : null);

    // -----  endpointProcessState during update  ----
    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userProfileForm) ? state.formSlice.formSubmissionState.userProfileForm : false);

    // ---- Goback btn --- 
    const goBackBtnClick = useSelector(state => (state.formSlice?.customButtonClicked?.userProfileForm && state.formSlice?.customButtonClicked?.userProfileForm?.goBack001) ? state.formSlice?.customButtonClicked?.userProfileForm?.goBack001 : false);

    // ---- changePassBtn  --- 
    const changePassBtn = useSelector(state => (state.formSlice?.customButtonClicked?.userProfileForm && state.formSlice?.customButtonClicked?.userProfileForm?.changePass) ? state.formSlice?.customButtonClicked?.userProfileForm?.changePass : false);

    const loading = useSelector(state => (state.fetchUserDataReducer?.loading) ? state.fetchUserDataReducer?.loading : false)

    // ---- endpointResMsg  --- 
    const endpointResMsg = useSelector(state => (state.formSlice?.message?.userProfileForm) ? state.formSlice.message.userProfileForm : false);

    const repWithNewDashboard = useSelector((state) => state.loginSlice?.userInfo?.rep_with_new_dashboard ? state.loginSlice.userInfo.rep_with_new_dashboard : false)


    const updateCookieInfo = useSelector(state => (state.fetchUserDataReducer?.updateCookieInfo) ? state.fetchUserDataReducer?.updateCookieInfo : false)

    const userMainRole = useSelector(state => (state.loginSlice?.user_main_role) ? state.loginSlice?.user_main_role : null)

    useEffect(() => {
        // console.log("userMainRole======================", userMainRole);
    }, [userMainRole])




    useEffect(() => {
        if (loading) {
            setLoader(true)
        } else {
            setLoader(false)

        }
    }, [loading])


    // ----- On chnagePasssBtn click
    useEffect(() => {
        if (changePassBtn) {
            if (formToogle == false) {
                dispatch(resetForm({ formId: 'userProfileForm' }));
                setFormToogle(true);


            } else {
                //    dispatch(resetForm({formId:'userProfileForm'}));
                setFormToogle(false);

            }
        }
    }, [changePassBtn])




    // onGoBackBtn click backto dashboard
    let reqbodyforbluecoast = {
        "condition": {
            "limit": 5,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": {

        },
        "project": {},
        "token": ""
    }


    useEffect(() => {

        if (goBackBtnClick) {
            if (userMainRole !== null && userMainRole === "special_entities") {

                // console.log("userMainRole===========>>>>>", userMainRole);


                resolvefunction(navigate, '/businesslead-list', BlueCoastlist(reqbodyforbluecoast))

            } else if (repWithNewDashboard) {

                navigate('/new-ae-dashboard');

            } else if (userMainRole !== null && userMainRole === "is_employer") {

                navigate('/new-employer-dashboard')

            } else if (userMainRole !== null && userMainRole === "is_subEmployer") {

                navigate('/new-sublogin-dashboard')

            } else if (userMainRole !== null && userMainRole === "is_employee") {

                navigate('/new-employee-dashboard')
                
            } else {
                navigate('/dashboard');
            }
        }
    }, [goBackBtnClick])


    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };



    //---- based on endpoint call ---
    useEffect(() => {
        if (endpointProcessState == 1) {
            setLoader(true);
            setOpen(false);
        } else if (endpointProcessState == 2) {
            setLoader(false);
            setOpen(true);
            formToogle == false ? setResMsg("Profile Updated Sucessfully") : setResMsg("Password Updated Sucessfully");

            setTimeout(() => {
                if (userInfo.userinfo._id !== null && userInfo.userinfo._id !== undefined) {
                    let data = { id: userInfo.userinfo._id }

                    dispatch(fetchUserData(data))

                    dispatch(updateUserInfoAfterUpdateProfile(data))
                }
            }, 200);

        } else if (endpointProcessState == 3) {
            setLoader(false);
            setOpen(true);
            setResMsg(endpointResMsg)

        }
    }, [endpointProcessState])




    useEffect(() => {
        if (updateCookieInfo) {

            // const storedata = store.getState();

            // console.warn("storedata", storedata)
            // setuserInfoCookie(
            //     "userinfo",
            //     storedata.loginSlice?.userInfo,
            //     { path: "/" }
            // );


            // setTimeout(() => {
            //     navigate("/dashboard");
            // }, 2000);
        }
    }, [updateCookieInfo])




    // ----- On component load ----
    useEffect(() => {
        setLoader(false);
        setOpen(false);
    }, [])



    // console.warn("<<<<<< userInfo.userinfo >>>", userInfo)

    useEffect(() => {
        if (userInfo.userinfo._id !== null && userInfo.userinfo._id !== undefined) {
            let data = { id: userInfo.userinfo._id }
            dispatch(fetchUserData(data))
        }
    }, [])



    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // --- fetched data from query param --- 
    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData && storeData.profileManagementReducer && storeData.profileManagementReducer.success) {
            setFetchprofileData(storeData.profileManagementReducer.fetchProfileData)
        }
    })


    // const fetchprofileData = useSelector(state => (state.profileManagementReducer?.fetchProfileData
    //   )?state.profileManagementReducer.fetchProfileData
    //   :null );
    // console.warn("fetchprofileData", fetchprofileData)




    // --- setting state array 
    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            setLocationArry(Newarr);
        }
    }, [])





    const chnagePassword = [
        {
            id: 0,
            heading: "Old Password",
            // label: "Old Password",
            // label: "Training name",
            name: "old_password",
            className: "inputBlock inputBlock1line",
            type: "password",
            old_password_field: "old_password",
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            rules: { required: true, maxLength: 20 },
            errorMessage: {
                required: "This Field is Required",
                pattern: "Old password does not match",
            },
        },

        {
            id: 11,
            heading: "Password",
            label: "Password",
            name: "password",
            className: 'inputBlock inputBlock1line',
            type: 'password',
            defaultValue: '',
            rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },

            errorMessage: {
                required: "This Field is Required",
                // errorMessageConfirmPassword: "Password does not match",         
                custom: "Value is Invalid",
                validate: "Password does not match",
                maxLength: "Maximum allowed input length is 15 characters",
                pattern: "You need to provide a strong password",
            },


        },

        {
            id: 12,
            heading: "Confirm Password",
            label: "Confirm Password",
            name: "confirmPassword",
            className: 'inputBlock inputBlock1line',
            type: 'confirm_password',
            defaultValue: '',
            rules: { required: true, maxLength: 15, pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/ },
            errorMessage: {
                required: "This Field is Required",
                validate: "Password does not match",
                errorMessageConfirmPassword: "Password does not match",
                maxLength: "Maximum allowed input length is 15 characters",

                pattern: "You need to provide a strong password",
                custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
            },


        },













        // {
        //     id: 1,
        //     heading: "New Password",
        //     // label: "Training name",
        //     name: "new_password",
        //     className: 'inputBlock inputBlock1line',
        //     type: "password",
        //     // inputType: "number",/////////////// If not mentioned default will be text //////////////
        //     rules: { required: true, maxLength: 20 },
        //     errorMessage: {
        //         required: "This Field is Required",
        //         errorMessageConfirmPassword: "Password does not match",
        //         pattern: "Your password must contain 6 characters with at least one lower case, upper case alphabets and special character",
        //         custom: "Value is Invalid",
        //     },
        //     // defaultValue:(dataEdit&& dataEdit.traning_name!== undefined) ?  dataEdit.traning_name : undefined,
        // },

        // {
        //     id: 2,
        //     heading: "Confirm New Password",
        //     // label: "Training name",
        //     name: "confirmPassword",
        //     className: 'inputBlock inputBlock1line',
        //     type: "password",
        //     // inputType: "number",/////////////// If not mentioned default will be text //////////////
        //     rules: { required: true, maxLength: 20 },
        //     errorMessage: {
        //         required: "This Field is Required",
        //         errorMessageConfirmPassword: "Password does not match",
        //         pattern: "Confirm password field value must be matched",
        //         custom: "Value is Invalid",
        //         ////////////////////// Include this for confirm password field //////////
        //     },
        //     // defaultValue:(dataEdit&& dataEdit.traning_name!== undefined) ?  dataEdit.traning_name : undefined,
        // },

    ]

    const profile = [
        {
            id: 0,
            heading: "First Name",
            label: "First Name",
            name: "firstname",
            className: 'inputBlock inputBlock3line',
            type: "text",
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.firstname ? fetchData.firstname : ''
        },

        {
            id: 1,
            heading: "Last Name",
            label: "Last Name",
            name: "lastname",
            className: 'inputBlock inputBlock3line',
            type: "text",
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.lastname ? fetchData.lastname : ''
        },

        {
            id: 2,
            heading: "Email",
            label: "Email",
            name: "email",
            className: 'inputBlock inputBlock3line',
            type: "text",
            disabled: true,

            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.email ? fetchData.email : ''
        },
        {
            id: 3,

            label: "Address",
            name: "address",
            className: 'inputBlock addressbox inputBlock1line',
            type: "textarea",

            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.address ? fetchData.address : ''
        },


        {
            id: 4,
            heading: "State",
            label: "Select State",
            name: "state",
            className: 'inputBlock usertypebox inputBlock4line',
            defaultValue: fetchData?.state ? fetchData.state : '',
            type: "select",
            values: locationArry,
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            // defaultValue: "Test"
        },

        {
            id: 5,
            heading: "City",
            label: "City",
            name: "city",
            className: 'inputBlock inputBlock4line',
            type: "text",

            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.city ? fetchData.city : ''
        },

        {
            id: 6,
            heading: "Zip Code",
            label: "Zip Code",
            name: "zip",
            className: 'inputBlock inputBlock4line',
            type: "text",
            // inputType: "number",
            inputType: "zip",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.zip ? fetchData.zip : ''
        },

        {
            id: 7,
            heading: "Phone",
            label: "Phone",
            name: "phone",
            className: 'inputBlock inputBlock4line',
            type: "text",
            inputType: "phone",
            // inputType: "number",/////////////// If not mentioned default will be text //////////////
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },

            defaultValue: fetchData?.phone ? fetchData.phone : ''
        },

        {
            id: 8,
            heading: "Secondary Email",
            label: "Secondary Email",
            name: "secondary_email",
            className: 'inputBlock inputBlock2line',
            type: "text",



            defaultValue: fetchData?.secondary_email ? fetchData.secondary_email : ''
        },

        {
            id: 9,
            heading: "Secondary Phone",
            label: "Secondary Phone",
            name: "secondary_phone",
            className: 'inputBlock inputBlock2line',
            type: "text",
            inputType: "phone",

            defaultValue: fetchData?.secondary_phone ? fetchData.secondary_phone : ''
        },


        {
            id: 10,
            // heading: "Status",
            label: "Status",
            name: "status",
            className: 'singleCheckbox inputBlock1line',
            type: 'singleCheckbox',
            values: { key: 1, val: 'Active' },
            // rules: { required: true, validate: isValidCheck },
            errorMessage: {
                required: "This Field is Required",
                validate: "is not valid",
                custom: "Value is Invalid"
            },
            defaultValue: fetchData?.status ? fetchData.status : '',
        },


    ]

    const formData = {
        id: "userProfileForm",
        formtype: "add",
        api_url: process.env.REACT_APP_API_URL,
        endPoint: formToogle == false ? "profile/update-profile" : "profile/profile-change-password",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/dashboard",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "userformWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userProfileForm" + `${formToogle == true ? " passwordBlock-Profile" : ""}`, // Can be undefined default will be formInline
        formButtonClass: "Formsubmit_button",
        submitBtnName: "Update",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            // type: "admin",
            // status: "active",
            token: "",
            _id: fetchData && Object.keys(fetchData).length > 0 && fetchData._id != null ? fetchData._id : null,
            page_slug: fetchData && Object.keys(fetchData).length > 0 && fetchData.page_slug != null ? fetchData.page_slug : null
        },

        fields: formToogle == false ? profile : chnagePassword,


        customButtons: [
            {
                id: 'goBack001',
                label: 'Go Back',
                className: 'default',
                type: 'button',
            },

            {
                id: 'changePass',
                label: formToogle == false ? 'Change Password' : 'My Profile',
                className: 'default',
                type: 'button',
            },

        ]
    };




    return (
        <div>
            <h1 className='page_heading'>{formToogle == false ? "Profile" : "Change Password"}</h1>

            <div className={formToogle == false ? 'dataform2_wrapper' : 'dataform1_wrapper'}>


                {loader ? (<Box sx={{ width: '100%', margin: '1px auto' }}>
                    <LinearProgress />
                </Box>) : ""}


                <div className={formToogle == true ? 'adminformbody' + ' ' + 'passwordBlock' : "adminformbody"}>
                    <div className='adminform_wrapper'>

                        {/* {!fetchData ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null} */}


                        {fetchData !== null && fetchData !== undefined && Object.keys(fetchData)?.length > 0 ?
                            <Form formData={formData} /> : ''}

                    </div>
                </div>

            </div>

            {resMsg ? (<Snackbar
                open={open}
                autoHideDuration={7000}
                onClose={handleClose}
                message={resMsg}
                // message={signUpMsg}
                action={action}
            />) : " "}

        </div>
    )
}

export default Profile
