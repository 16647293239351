import { Button } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { resolvefunction, timeConverter } from "../../helper/helperFunctions";
import { bookedSlotData, bookedSlotListData, fetchCalenderEvents, fetchEventsData, fetchEventsDataNew } from "./calenderReducer";
// import { setLogedinUserInfo } from "../Login/loginReducer";

export const CalendarAllTabs = (props) => {
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const titleBarLocation = useLocation();
    const stroreDispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate()
    const [connectgmailaccount, setconnectgmailaccount] = useState(getcookies.userinfo?.connected_gmail);
    const [googlecaledersynctime, setgooglecaledersynctime] = useState(getcookies.userinfo?.google_calendar_connect_time);

    const userslug = useSelector(state => state.loginSlice.userInfo?.main_role ? state.loginSlice.userInfo.main_role : null);

    const GOOGLE_AUTHENTICATION_URL = process.env.REACT_APP_API_ENV === "isDev" ? process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL : process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL;
    console.log(getcookies, "userslug=====>>>>>")

    let currTime = new Date().getTime();

    let dayAfterTomorrow = currTime + 48 * 3600 * 1000;


    const [eventBodyList, seteventBodyList] = useState(
        {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "start_date_unix",
                "type": "desc"
            },
            "searchcondition": {"userid": "6423e01fb32dceb05c4e1682",},
            count: false
        }
    );
    // console.log("getcookies.userinfo._id123",getcookies.userinfo?._id)


    const [bookedSlotList, setbookedSlotList] = useState(
        {
            "condition": {
                "limit": 30,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "asc"
            },
            "searchcondition": {
                userid: getcookies.userinfo?._id
                // userid: "6423e01fb32dceb05c4e1682"
                
            },
            "count": false
        },
    );

    const [calenderEventsData, setcalenderEventsData] = useState(
        {
            condition: {
                limit: 100,
                skip: 0,
            },
            sort: {
                field: "start_datetime_unix",
                type: "desc",
            },
            searchcondition: {
                userid: getcookies?.userinfo?.is_admin == 1 ? undefined : getcookies?.userinfo?._id,

                // userid: getcookies.userinfo?.is_admin == 1 ? getcookies.userinfo._id : getcookies.userinfo._id,
                // userid: "63ad18604344ee3d619b6c7e"
                "start_datetime_unix": {
                    "$gte": dayAfterTomorrow
                }
            },
        }
    );

    useEffect(() => {
        if (titleBarLocation && titleBarLocation.search) {
            let locationSplit = titleBarLocation.search.split('&');
            let connectionTime = locationSplit[0].split('=');
            let connectionGmail = locationSplit[1].split('=');
            let currentUserInfo = cookies.get('userinfo') ? cookies.get('userinfo') : {};
            console.log("first console", connectionGmail, "vd", connectionTime);
            setconnectgmailaccount(connectionGmail[1]);
            setgooglecaledersynctime(parseInt(connectionTime[1]));
            currentUserInfo.google_calendar_connect_time = parseInt(connectionTime[1]);
            currentUserInfo.connected_gmail = connectionGmail[1];
            cookies.set('userinfo', currentUserInfo, { path: '/' })
            // stroreDispatch(setLogedinUserInfo(currentUserInfo));
        };
    }, []);



    const availabilitySlotFetch = () => {
        resolvefunction(navigate, "/available-slots", fetchCalenderEvents(calenderEventsData))
    }
    const availabilityListFetch = () => {
        resolvefunction(navigate, "/avilibility-list", fetchEventsDataNew(eventBodyList))
    }
    const bookedSlotFetch = () => {
        resolvefunction(navigate, "/booked-slot", bookedSlotListData(bookedSlotList))
    }

    return (
        <>
            {/* {getcookies.userInfo?.lead_organizer ?"" :
<> */}
            <div className="listing_heading">
                <h1>{
                    props.from === 'createEvent' ? "Create Event" :
                        props.from === 'eventList' ? "Availability List" :
                            props.from === 'eventSlot' ? "Availability Slot" :
                                "Booked Slot"}</h1>

              {(userslug && !userslug.includes("is_admin")) ? <div className="calenderinfo">
                    <label>Connected With Google Calendar </label>
                    <p>
                        <strong>Last Updated On:</strong><span>{timeConverter({ time: new Date(googlecaledersynctime), format: "L" })} </span><br />
                        <strong>  Email Connected:</strong> <span>{connectgmailaccount}</span>
                    </p>
                </div> : ""}
            </div>
            <div className="buttonsetToolbar">

                {/* <p>{JSON.stringify(userslug)}</p> */}

                {(props.from !== 'createEvent' && userslug && !userslug.includes("is_admin") && !userslug.includes("is_rep")) ?
                    <Button variant="contained" onClick={() => navigate("/create-event")}>

                        Create New Availability
                    </Button>
                    : ''}
                {(props.from !== 'eventSlot' && userslug  && !userslug.includes("is_rep") && !userslug.includes("is_admin")) ?
                    <Button variant="contained" onClick={() => availabilitySlotFetch()}>
                        Availability Slot
                    </Button>
                    : ""}
                {props.from !== 'eventList' ?
                    <Button variant="contained" onClick={() => availabilityListFetch()}>
                        Availability List
                    </Button>
                    : ''}
                {(userslug&&!userslug.includes("is_admin") && !userslug.includes("is_rep")) ?
                    <Button href={`${GOOGLE_AUTHENTICATION_URL}?id=${getcookies.userinfo?._id}`} variant="contained">
                        Update Google Account
                    </Button> : ''}

                {props.from !== 'bookedSlot' ?
                    <Button variant="contained" onClick={() => bookedSlotFetch()}>
                        Booked Slot
                    </Button>
                    : ""}

            </div>
        </>
    )
}