import React from "react";
import "../LandingPage13.css";

function Landingpageblock1({scrollToview}) {

  const setScroll = () => {
    scrollToview.current.scrollIntoView({ behavior: "smooth" });
};
  return (
    <>
    <div className="landingpage13_bannerblock2_mainwrapper">
      <div className="landingpage5_bannerblock8">
        <div className="landing-container">
          <div className="landingpage5_bannerblock8_mainwrapper">
            <h1>Simply Follow These 6 Steps To Free Medication</h1>
            <div className="landingpage5_bannerblock8_sub_mainwrapper">
              <div className="landingpage5_bannerblock8_step5">
                <div className="landingpage5_bannerblock8_step1_img">
                  <h4>STEP 1</h4>
                </div>
                <div className="landingpage5_bannerblock8_step1_text">
                  <h3>SIGN-UP FOR YOUR MONTHLY MEMBERSHIP</h3>
                  <p>
                    As A Member In Our Program, You Get Access To All Of Our
                    Medications For FREE!
                  </p>
                </div>
              </div>
              <div className="landingpage5_bannerblock8_step5">
                <div className="landingpage5_bannerblock8_step1_img">
                  <h4>STEP 2</h4>              
                </div>
                <div className="landingpage5_bannerblock8_step2_text">
                  <h3>SEARCH OUR LIST OF 605 GENERIC MEDICATIONS</h3>
                  <p>
                    Identify The Medications You Are Taking. Compare What You
                    Paid For Them To FREE With Us!
                  </p>
                </div>
              </div>
              <div className="landingpage5_bannerblock8_step5">
                <div className="landingpage5_bannerblock8_step1_img">
                  <h4>STEP 3</h4>
                </div>
                <div className="landingpage5_bannerblock8_step3_text">
                  <h3>CALL OUR PHARMACY COACHES TO REVIEW YOUR MEDICATIONS</h3>
                  <p>
                    Our Team Is Here To Help. We Will Make Sure Your Medications
                    Are On The List Or
                     Find Therapeutically Similar Ones
                  </p>
                </div>
              </div>
              <div className="landingpage5_bannerblock8_step5">
                <div className="landingpage5_bannerblock8_step1_img">
                  <h4>STEP 4</h4>
                </div>
                <div className="landingpage5_bannerblock8_step4_text">
                  <h3>
                    TRANSFER YOUR PRESCRIPTIONS, OR YOUR DOCTOR CAN E-PRESCRIBE
                    TO US
                  </h3>
                  <p>
                    Transferring Prescriptions Can Be Difficult, So Our Pharmacy
                    Team Does It ALL For You!
                  </p>
                </div>
              </div>
              <div className="landingpage5_bannerblock8_step5">
                <div className="landingpage5_bannerblock8_step1_img">
                  <h4>STEP 5</h4>
                </div>
                <div className="landingpage5_bannerblock8_step5_text">
                  <h3>
                    WE WILL CONTACT YOU TO CONFIRM THE ORDER AND COLLECT PAYMENT
                  </h3>
                  <p>
                    We Always Make Sure Everything Is Accurate. We Double Check
                    The Prescription And Securely Place Your Order
                  </p>
                </div>
              </div>
              <div className="landingpage5_bannerblock8_step5">
                <div className="landingpage5_bannerblock8_step1_img">
                  <h4>STEP 6</h4>
                </div>
                <div className="landingpage5_bannerblock8_step6_text">
                  <h3>RECEIVE YOUR MEDICATIONS</h3>
                  <p>
                  You Can Pick-up <strong>Emergency Medications at your Local Pharmacy</strong>  or Have Chronic Medications <strong>Shipped To Your Home For FREE in 2-4 business days</strong>!
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => setScroll()}
              className="gradiant_fontBW gradiant_black_darkBG txt_button_lp13"
            >
              <h2>Enroll today to gain access to your free medication!</h2>
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Landingpageblock1;
