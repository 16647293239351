import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";

export const UsersSlotForSixMonthList = ({ usersSlotForSixMonthListFetched }) => {
    const [usersSlotForSixMonthList, setUsersSlotForSixMonthList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})


    useEffect(() => {
        usersSlotForSixMonthListObj.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);

    const usersSlotForSixMonthListObj = useRef({
        tableId: "usersSlotForSixMonthLists",
        tableTitle: "Users Appointment For Six Month List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/users-slot-for-six-month-list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/users-slot-for-six-month-list",
        },

        reqBody: {
            "condition": {
                "limit": 0,
                "skip": 0
            },
            "sort": {
                "field": "last_calendar_sync_time",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["createdTimeDifference", "last_calendar_sync_time", "start_date", "end_date"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchData: {
            heading: "Search Users Appointment For Six Month List",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                // submitBtnName: "Search",
                submitBtnEndIcon: "search",
                // resetBtnName: "Reset",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [

                    {
                        id: 2,
                        label: "Search By Connected Gmail",
                        name: "connected_gmail",
                        type: "text",
                        className: 'list_search_item countDiv__column--col4 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "connected_gmail" },

                    },
                    // {
                    //     id: 3,
                    //     label: "Search by Status",
                    //     name: "booking_status",
                    //     // className: 'Status',
                    //     type: 'select',
                    //     multiple: false,
                    //     values: [  
                    //     { val: "Booking completed", name: "Booking completed" },
                    //     { val: "Sign-up completed", name: "Sign-up completed" }],
                    //     sx: { m: 1, minWidth: 120 },
                    //     className: 'list_search_item countDiv__column--col4 ',
                    //     payloadFormat: { key: "booking_status", param: "$eq", $options: "ims" },
                    //   },
                    {
                        id: 8,
                        label: "Search by Start Date",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
                        className: 'list_search_item countDiv__column--col4 ',
                        payloadFormat: { key: "start_date", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by End Date",
                        name: "end_date_unix",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'MM/DD/YYYY'
                        payloadFormat: { key: "end_date_unix", param: "$lte" }
                    },
                    {
                        id: 0,
                        label: "Search By Summary",
                        name: "summary",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "summary", param: "$regex", options: "i" },

                    },
                ]
            },
        },

        // searchBarData: {
        //     heading: "Search Users Appointment For Six Month List",
        //     api: {
        //         url: process.env.REACT_APP_API_URL,
        //         endPoint: "calendar-api/users-slot-for-six-month-list",
        //         tableCountEndpoint: "calendar-api/users-slot-for-six-month-list",
        //         reqBody: {
        //             // count:true,
        //             // "source": "users",
        //             token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
        //             condition: {
        //                 limit: 5,
        //                 skip: 0,
        //             },
        //             sort: {
        //                 type: "desc",
        //                 field: "_id",
        //             },
        //         },
        //     },

        //     searchSettings: {
        //         datesearch: [
        //             {
        //                 id: 0,
        //                 heading: "Search By Last Calendar Sync",
        //                 startdatelabel: "Start Date",
        //                 enddatelabel: "End Date",
        //                 className: "formGroup createdon_datetime countDiv__column--col4",
        //                 submit: "Search",
        //                 field: "last_calendar_sync_time",
        //             },
        //         ],
        //         selectsearch: [
        //             // {
        //             //     id: 1,
        //             //     label: "Search By Status",
        //             //     field: "status",
        //             //     type: "select",
        //             //     values: [0, 1],
        //             //     // value: "",
        //             // },


        //         ],
        //         textsearch: [
        //             {
        //                 id: 1,
        //                 // heading: "Search By First Name",
        //                 label: "Search By Summary ",
        //                 field: "summary",
        //                 className: "formGroup summary countDiv__column--col4",
        //                 value: "",
        //             },
        //             {
        //                 id: 2,
        //                 // heading: "Search By First Name",
        //                 label: "Search By Connected Gmail ",
        //                 field: "connected_gmail",
        //                 className: "formGroup connected_gmail countDiv__column--col4",
        //                 value: "",
        //             },
        //         ],

        //     },
        // },
    });

    var modifyTableHeaders =
        [
            { val: "summary", name: "Summary" },
            { val: "connected_gmail", name: "Connected Gmail" },
            { val: "user_email", name: "User Email" },
            { val: "createdTimeDifference", name: "Created Time Difference" },
            // { val: "updatedTimeDifference", name: "Updated Time Difference" },
            { val: "allday", name: "Allday" },
            { val: "last_calendar_sync_time", name: "Last Calendar Sync", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },
            { val: "start_date", name: "Start Date", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },
            { val: "end_date", name: "End Date", type: "dateTime", format: "MM/DD/YYYY hh:mm A" },

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(usersSlotForSixMonthListObj.current.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendar-api/users-slot-for-six-month-list",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setUsersSlotForSixMonthList([...respdata.results.res])
        }
        setloaderFlag(false);
        usersSlotForSixMonthListFetched(true);
    }

    useEffect(() => {
        if (usersSlotForSixMonthList && usersSlotForSixMonthList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList()
        }

    }, [usersSlotForSixMonthList]);

    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">
                    {!loaderFlag && usersSlotForSixMonthList !== undefined ? (
                        <ListingTable
                            tableData={usersSlotForSixMonthListObj.current}
                            dataset={usersSlotForSixMonthList}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}

                </div>
            </div>
        </>
    )
}