import {
  Alert,
  Badge,
  Box,
  Button,
  FormHelperText,
  Icon,
  LinearProgress,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AutoCompleteField } from "./formComponents/AutoCompleteField";
import { DatePickerField } from "./formComponents/DatePickerField";
import { DateRangePickerField } from "./formComponents/DateRangePicker";
import { FileUploader } from "./formComponents/FileUploader";
import { MultipleCheckBox } from "./formComponents/MultipleCheckBox";
import { PasswordField } from "./formComponents/PasswordField";
import { RadioSelection } from "./formComponents/Radio";
import { SelectField } from "./formComponents/Select";
import { SingleCheckbox } from "./formComponents/SingleCheckbox";
import { Text } from "./formComponents/Text";
import { TextArea } from "./formComponents/TextArea";
import { TimePickerField } from "./formComponents/TimePickerField";
import {
  addDataIntoDB,
  resetCustomButtonClicked,
  resetexternalSeachReset,
  resetField,
  resetForm,
  resetFormData,
  resetFormFieldDataForAutoClear,
  resetFormResetFlag,
  resetMessageAndStatus,
  setCustomButtonCLicked,
  setexternalSeachReset,
  setFormData,
  setFormFieldsData,
  setFormSubmissionStateStore,
  setFormSubmissionStateWithoutEndpoint,
} from "./formReducer";
import store from "../store";
import { SwitchButton } from "./formComponents/SwitchButton";
import { SunEditorField } from "./formComponents/SunEditor";
import { HiddenField } from "./formComponents/HiddenField";
import CachedIcon from "@mui/icons-material/Cached";
import debounce from "lodash.debounce";
import { Youtube } from "./formComponents/Youtube";
import { ChipTextBox } from "./formComponents/ChipTextBox";
import { cloneDeep } from "lodash";
import DateTimePickerField from "./formComponents/DateTimePickerField";
import { randomId } from "../helper/helperFunctions";
import RatingField from "./formComponents/RatingField";
import ExternalButton from "./formComponents/ExternalButton";
import { YoutubeTestimonial } from "./formComponents/Youtube_testimonial";
import CustomButton from "./formComponents/CustomButton";
import {
  deleteTempSearchEndpoint,
  resetReloadTableFlag,
  resetTempBody,
  setReloadTableFlag,
} from "../listing/listReducer";
import ColorPicker from "./formComponents/ColorPicker";
import ConfirmPassword from "./formComponents/ConfirmPassword";
import moment from "moment";
import Captcha from "./formComponents/Captcha";

export const Form = ({ formData, search, tableId }) => {
  const [formState, setFormState] = useState({}); ////////////////// State only to rerender on default value set ///////
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formFields, setFormFields] = useState(formData.fields);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm();
  const { fields, formAdditionalSubmissionData } = formData;
  const formError = useSelector((state) =>
    Object.keys(state.formSlice.message).length > 0 &&
      state.formSlice.message[formData.id]
      ? state.formSlice.message[formData.id]
      : ""
  );
  const autoCompleteLoading = useSelector((state) =>
    state.formSlice.autoCompleteLoading[formData.id]
      ? state.formSlice.autoCompleteLoading[formData.id]
      : false
  );
  const formSubmissionState = useSelector((state) =>
    state.formSlice.formSubmissionState[formData.id]
      ? state.formSlice.formSubmissionState[formData.id]
      : 0
  );
  const formReset = useSelector((state) =>
    state.formSlice.formResetFlag[formData.id]
      ? state.formSlice.formResetFlag[formData.id]
      : false
  );

  const formResetFlagForAuto = useSelector((state) =>
    state.formSlice.formResetFlagForAuto[formData.id]
      ? state.formSlice.formResetFlagForAuto[formData.id]
      : false
  );

  const newFieldData = useSelector((state) =>
    state.formSlice.fieldInputData[formData.id]
      ? state.formSlice.fieldInputData[formData.id]
      : null
  );
  const removeField = useSelector((state) =>
    state.formSlice.fieldRemoveData[formData.id]
      ? state.formSlice.fieldRemoveData[formData.id]
      : null
  );
  const message = useSelector((state) =>
    state.formSlice.message[formData.id]
      ? state.formSlice.message[formData.id]
      : null
  );
  const status = useSelector((state) =>
    state.formSlice.status[formData.id]
      ? state.formSlice.status[formData.id]
      : null
  );
  const disableState = useSelector((state) =>
    state.formSlice?.disableState[formData.id]
      ? state.formSlice?.disableState[formData.id]
      : null
  );
  const formCustomButtonClicked = useSelector((state) =>
    state.formSlice.formCustomButtonClicked[formData.id]
      ? state.formSlice.formCustomButtonClicked[formData.id]
      : null
  );

  useEffect(() => {
    if (disableState === 2) setDisable(true);

    if (disableState === 1) setDisable(false);
  }, [disableState]);

  useEffect(() => {
    if (message || status)
      setTimeout(
        () => dispatch(resetMessageAndStatus({ formId: formData.id })),
        7000
      );
  }, [message, status]);

  // useEffect(() => {
  //   if (formCustomButtonClicked && formFieldsData && formData.fields && formFieldsData.length !== formData.fields.length) {
  //     formData.fields = formFieldsData
  //   }
  // }, [formCustomButtonClicked])

  // Add Field
  useEffect(() => {
    if (newFieldData) {
      const fieldData = newFieldData.fieldData;
      const ifAlreadyExists = formData.fields.find(
        (field) => field.name === fieldData.name
      );
      if (ifAlreadyExists) return;
      const baseFieldIndex = formData.fields.findIndex((field) =>
        field.name === newFieldData.after
          ? newFieldData.after
          : newFieldData.before
            ? newFieldData.before
            : ""
      );
      if (baseFieldIndex !== -1 && newFieldData.after) {
        formData.fields =
          baseFieldIndex + 1 < formData.fields.length
            ? [
              ...formData.fields.slice(0, baseFieldIndex + 1),
              fieldData,
              ...formData.fields.slice(baseFieldIndex + 1),
            ]
            : [...formData.fields.slice(0, baseFieldIndex + 1), fieldData];
      } else if (baseFieldIndex !== -1 && newFieldData.before) {
        formData.fields =
          baseFieldIndex > 0
            ? [
              ...formData.fields.slice(0, baseFieldIndex),
              fieldData,
              ...formData.fields.slice(baseFieldIndex),
            ]
            : [fieldData, ...formData.fields.slice(baseFieldIndex)];
      }
      setFormFields(formData.fields);

      dispatch(resetField({ formId: formData.id }));
    }
  }, [newFieldData]);

  // Remove Field
  useEffect(() => {
    if (removeField) {
      const fieldName = removeField.name;
      const fieldIndex = formData.fields.findIndex(
        (field) => fieldName === field.name
      );
      if (fieldIndex !== -1) {
        formData.fields.splice(fieldIndex, 1);
      }
      setFormFields(formData.fields);
      dispatch(resetField({ formId: formData.id }));
    }
  }, [removeField]);

  useEffect(() => {
    formData.fields = formFields;
    const formDataClone = cloneDeep(formData);

    dispatch(setFormFieldsData(formDataClone));

    // console.log("formDataClone=============>>>>>", formDataClone)
  }, [formFields]);

  ////////////////////////////////////////// Set State on Initialization /////////////////////
  useEffect(() => {
    const formDataClone = cloneDeep(formData);

    dispatch(setFormFieldsData(formDataClone));
  }, []);

  ////////////////// Form Reset from outside ///////////////////
  useEffect(() => {
    if (formReset === true) {
      handleFormReset();
      dispatch(resetFormResetFlag({ formId: formData.id }));
    }
  }, [formReset]);

  ///////////////////// On Form Submission ////////////////
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (formSubmissionState === 0) {
      // console.log("Nothing Happening", formSubmissionState);
      setLoader(false);
    }
    if (formSubmissionState === 1) {
      // console.log("Form Submission Pending", formSubmissionState);
      setLoader(true);
      setDisable(true);
      // dispatch(setFormSubmissionStateStore(formData.id));
    }
    // console.log("formSubmissionState1", formSubmissionState);
    if (formSubmissionState === 2) {
      setTimeout(() => reset(), 500);

      if (formData.urlPathOnSuccessfulFormSubmission !== undefined) {
        // console.log(
        //   "Form has been Submitted",
        //   formSubmissionState,
        //   formData.urlPathOnSuccessfulFormSubmission
        // );
        navigate(formData.urlPathOnSuccessfulFormSubmission, { replace: true });
      }
      // console.log("formSubmissionState2", formSubmissionState);

      setDisable(false);
      // dispatch(setFormSubmissionStateStore(formData.id));
      setLoader(false);

      setTimeout(
        () => dispatch(setFormSubmissionStateStore(formData.id)),
        1000
      );
    }
    if (formSubmissionState === 3) {
      // console.log("Something Went Wrong", formSubmissionState);
      setDisable(false);
      dispatch(setFormSubmissionStateStore(formData.id));
      setOpen(true);
      setLoader(false);
    }
    if (formSubmissionState === 4) {
      setTimeout(
        () => dispatch(setFormSubmissionStateStore(formData.id)),
        1000
      );
    }
  }, [formSubmissionState]);

  ///////////////////////////////////// Form Data Check ////////////////////////////////
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log("value", value, "name", name, "type", type);
      // console.log("errors", errors);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // console.log("errors123", errors);
  }, [errors]);

  ///////////////// Used only for renrender on default value set ///////////////////
  useEffect(() => {
    setFormState(getValues());
  }, []);

  ///////////////////////////////////// SET STATE ON CHANGE ///////////////////////////////////
  ///// LOADASH DEBOUNCE ///
  const debouncedSetFormData = useCallback(
    debounce((value) => {
      dispatch(setFormData(value));
    }, 500),
    []
  );

  useEffect(() => {
    const subscribe = watch((value) => {
      // console.log(
      //   "WATCH____________---",
      //   store.getState().formSlice?.formFieldsData?.userForm?.fields
      // );
      value.formId = formData.id;
      debouncedSetFormData(value);
    });

    return () => subscribe.unsubscribe();
  }, [watch]);

  ///////////////////////////////////// Check error on Form of Custom Validation ////////////////////////////

  ///////////////////////////////////// SET STATE ON SUBMIT /////////////////////////////////
  const onSubmit = (data) => {
    // console.log("data101", data);
    data.formId = formData.id;

    // For Calendar
    if (data.start_time !== undefined) {
      data.start_time_format_unix_timestamp = data.start_time;
      data.end_time_format_unix_timestamp = data.end_time;
      data.start_time = moment(data.start_time).format("LT");
      if (data.end_time) data.end_time = moment(data.end_time).format("LT");
    }
    ////////////////////// Adding additional data on form submission /////////////////
    if (
      formAdditionalSubmissionData !== undefined &&
      formAdditionalSubmissionData !== {}
    ) {
      for (let eachData in formAdditionalSubmissionData) {
        if (
          formAdditionalSubmissionData[eachData] !== undefined &&
          formAdditionalSubmissionData[eachData] !== null &&
          formAdditionalSubmissionData[eachData] !== ""
        ) {
          data[eachData] = formAdditionalSubmissionData[eachData];
        }
      }
    }

    //////////////////// Storing Data in the Store //////////////////////
    dispatch(setFormData(data));
    //-------------
    // store.dispatch(setTempSearchEndpoint(cloneReqBody))
    // console.log("data12345", data);
    /////////////////// Storing Data in the DB /////////////////////////////
    if (formData.endPoint !== undefined && formData.api_url !== undefined) {
      dispatch(
        addDataIntoDB({
          reqBody: data,
          BASE_URL: formData.api_url,
          endPoint: formData.endPoint,
          bodyWrapper: formData.endPointBodyWrapper,
          restResponseAttachment: { formId: formData.formId },
          endPointType: formData.endPointType,
        })
      );
    } else {
      dispatch(setFormSubmissionStateWithoutEndpoint(formData.id));
    }
  };

  useEffect(() => {
    if (formResetFlagForAuto === true) {
      // console.log("formResetFlagForAuto", formResetFlagForAuto);


      setAutoCompleteReset(true);

      store.dispatch(resetForm({ formId: formData.id }));
      store.dispatch(resetFormData({ formId: formData.id }));

      store.dispatch(resetTempBody({ tableId }));
      store.dispatch(deleteTempSearchEndpoint({ tableId }));


      dispatch(
        setexternalSeachReset({ formId: formData.id, name: "restform" })
      );
      setTimeout(() => {
        dispatch(
          resetexternalSeachReset({ formId: formData.id, name: "restform" })
        );
      }, 500);

      setTimeout(() => {
        reset();
      }, 1000);

      setTimeout(() => {
        store.dispatch(resetFormFieldDataForAutoClear({ formId: formData.id }));
      }, 2000);
    }
  }, [formResetFlagForAuto]);

  ////////////////////// Function to reset form //////////////////////
  const handleFormReset = () => {
    // console.log("resethit");
    reset();
    store.dispatch(deleteTempSearchEndpoint({ tableId }));
    setAutoCompleteReset(true);
    // if (search === true && tableId) {
    //   dispatch(setReloadTableFlag({ tableId }));

    //   setTimeout(() => {
    //     dispatch(resetReloadTableFlag({ tableId }));
    //   }, 1000);
    // }

    dispatch(setexternalSeachReset({ formId: formData.id, name: "restform" }));
    setTimeout(() => {
      dispatch(
        resetexternalSeachReset({ formId: formData.id, name: "restform" })
      );
    }, 1000);
  };

  const handleCustomBtnClicked = (btnId) => {
    dispatch(setCustomButtonCLicked({ formId: formData.id, btnId }));
    setTimeout(() => {
      dispatch(resetCustomButtonClicked({ formId: formData.id, btnId }));
    }, 1000);
  };

  useEffect(()=>{
    console.log("fgfdgfd",formData);
  },[formData])

  return (
    <div
      id={formData.id}
      className={`${formData.formWrapperClass !== undefined ? formData.formWrapperClass : ""
        } ${formData.id !== undefined ? formData.id : ""} formCls`}
    >
      <form
        autoComplete="none"
        onSubmit={handleSubmit(onSubmit)}
        className={`${formData.formInlineCLass !== undefined ? formData.formInlineCLass : ""
          } formInline previewfield`}
      >
        {fields.map((field) => {
          switch (field.type) {
            case "text":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Text
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );


            case "captcha":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Captcha
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );


            case "textarea":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "radio":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioSelection
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "multipleCheckbox":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultipleCheckBox
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "singleCheckbox":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleCheckbox
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "select":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "autoComplete":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteField
                      reset={autoCompleteReset}
                      setReset={setAutoCompleteReset}
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "datePicker":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "dateTimePicker":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePickerField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "timePicker":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePickerField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "password":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={
                    field.old_password_field !== "old_password" && {
                      ...field.rules,
                      validate: (data) =>
                        data ===
                        getValues(
                          field.match_field
                            ? field.match_field
                            : "confirmPassword"
                        ),
                    }
                  }
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PasswordField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "confirm_password":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={{
                    ...field.rules,
                    required: true,
                    validate: (data) =>
                      data ===
                      getValues(
                        field.match_field ? field.match_field : "password"
                      ),
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConfirmPassword
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            /////////////////////////////////////// Implemented but without Pro version of MUI-X console error coming ////////////////////////
            case "dateRangePicker":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateRangePickerField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "sunEditor":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SunEditorField
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "fileUploader":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FileUploader
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "switch":
              return (
                <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SwitchButton
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            case "html":
              return (
                <div
                  className={field.className}
                  key={field.id}
                  dangerouslySetInnerHTML={{ __html: field.value }}
                ></div>
              );

            case "hidden":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <HiddenField
                        formId={formData.id}
                        fielddata={field}
                        setValue={setValue}
                      />
                    );
                  }}
                />
              );
            case "youtube":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Youtube
                        formId={formData.id}
                        fielddata={field}
                        value={value}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  }}
                />
              );
            case "youtube_testimonial":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <YoutubeTestimonial
                        formId={formData.id}
                        fielddata={field}
                        value={value}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  }}
                />
              );
            case "chipTextBox":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ChipTextBox
                        formId={formData.id}
                        fielddata={field}
                        value={value}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  }}
                />
              );
            case "rating":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <RatingField
                        formId={formData.id}
                        fielddata={field}
                        value={value}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  }}
                />
              );
            case "externalButton":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ExternalButton
                        formId={formData.id}
                        fielddata={field}
                        value={value}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  }}
                />
              );
            case "button":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomButton
                        formId={formData.id}
                        fielddata={field}
                        value={value}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  }}
                />
              );

            case "colorPicker":
              return (
                <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ColorPicker
                      formId={formData.id}
                      fielddata={field}
                      value={value}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                  )}
                />
              );

            default:
              return null;
          }
        })}
        <div
          className={
            formData.formButtonClass !== undefined
              ? formData.formButtonClass
              : ""
          }
        >
          {formData.customButtons &&
            formData.customButtons?.length > 0 &&
            formData.customButtons.map((btn, index) => {
              return btn.type == "iconButton" ? (
                <Tooltip
                  key={index + randomId()}
                  title={btn.label ? btn.label : btn.id}
                >
                  <IconButton
                    size={btn.size ? btn.size : "large"}
                    disabled={
                      disable == true
                        ? true
                        : btn.disabled
                          ? btn.disabled
                          : false
                    }
                    onClick={() => handleCustomBtnClicked(btn.id)}
                    className={`${btn.className ? btn.className : ""
                      } form_custom_button`}
                  >
                    <Icon>{btn.icon ? btn.icon : "reset_icon"}</Icon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Button
                  disabled={
                    disable == true ? true : btn.disabled ? btn.disabled : false
                  }
                  key={index + randomId()}
                  className={`${btn.className ? btn.className : ""
                    } form_custom_button`}
                  startIcon={btn.startIcon && <Icon>{btn.startIcon}</Icon>}
                  endIcon={btn.endIcon && <Icon>{btn.endIcon}</Icon>}
                  onClick={() => handleCustomBtnClicked(btn.id)}
                >
                  {btn.showcount==true ?(<Badge className={`badgeCls ${btn.id}`} color="primary"  badgeContent={btn.countFiled}>{btn.label}</Badge>):`${btn.label}`}

                </Button>
              );
            })}
          {formData.resetForm && (
            <Button
              className="reset"
              onClick={() => handleFormReset()}
              disabled={disable || autoCompleteLoading}
              startIcon={
                formData.resetBtnStartIcon && (
                  <Icon>{formData.resetBtnStartIcon}</Icon>
                )
              }
              endIcon={
                formData.resetBtnEndIcon && (
                  <Icon>{formData.resetBtnEndIcon}</Icon>
                )
              }
            >
              <span>
                {formData.resetBtnName ? formData.resetBtnName : "Reset"}
              </span>
            </Button>
          )}
          <Button
            type="submit"
            className="submit"
            disabled={disable || autoCompleteLoading}
            startIcon={
              formData.submitBtnStartIcon && (
                <Icon>{formData.submitBtnStartIcon}</Icon>
              )
            }
            endIcon={
              formData.submitBtnEndIcon && (
                <Icon>{formData.submitBtnEndIcon}</Icon>
              )
            }
          >
            <span>
              {formData.submitBtnName ? formData.submitBtnName : "Submit"}
            </span>
          </Button>
        </div>
      </form>
      <div className="loaderDiv">
        {loader && (
          <Box sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        )}
      </div>
      <Snackbar
        className="form_error_snackbar"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        // message={
        //   formData.msgOnFailedFormSubmission
        //     ? formData.msgOnFailedFormSubmission
        //     : formError
        // }
        action={action}
      >
        <Alert severity="error">
          {formData.msgOnFailedFormSubmission
            ? formData.msgOnFailedFormSubmission
            : formError}
        </Alert>
      </Snackbar>
    </div>
  );
};
