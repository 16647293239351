import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../listing/listing';
import { Button, Drawer, IconButton, LinearProgress, Paper, TableContainer, Tooltip } from '@mui/material';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommisionReportData, getcommisionReportData } from '../../AffiliateManagement/W9FormReducer';
import store from '../../../../store';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { useLocation, useParams } from 'react-router-dom';
import { clearPageState, clearTableCounts, clearTableOtherData, setTableCount, setTableCounts, setTableData, setTableDataAndCountAndBody, setTableDefaultreqBody, setTableFieldsData, unmountTable } from '../../../../listing/listReducer';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { AffiliateReportTotalAmt, FilterAffiliateReport, FilterAffiliateReportCount } from '../../AffiliateManagement/AffiliateReducer';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';
import { clearPartnerReducerReportData, filterPartnerCommissionList, filterPartnerCommissionListCount, partnerCommissionListData, partnerCommissionTotalAmount } from '../PartnersReducer';


const PartnersCommissionReport = () => {
  
  const paramData = useParams();
  const Location = useLocation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.W9FormReducer?.loading ? state.W9FormReducer.loading : false);
  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});



  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/partners-transactional-report"]
      ? state.ResolveReducer.resolved["/partners-transactional-report"]
      : false
  );




  const listData = useSelector((state) => state.PartnersReducer?.partnerReportData && state.PartnersReducer?.partnerReportData.length > 0 ?
    state.PartnersReducer.partnerReportData : []);

  let commisionAmount = useSelector((state) => state.PartnersReducer.total_commission_amt ? state.PartnersReducer.total_commission_amt : null);

  const filteredCommisionAmount = useSelector((state) => state.PartnersReducer?.filteredCommissionAmount ? state.PartnersReducer.filteredCommissionAmount : null);

  const filteredReportData = useSelector((state) => state.PartnersReducer?.filterPartnerCommListData ? state.PartnersReducer.filterPartnerCommListData : []);

  const filteredPartnerReportCount = useSelector((state) => state.PartnersReducer?.partnerCommissionListCount ? state.PartnersReducer.partnerCommissionListCount : null);

  const commisionReportSuccess = useSelector((state) => state.PartnersReducer?.filterPartnerDataSuccess ? state.PartnersReducer.filterPartnerDataSuccess : false);

  const loading = useSelector((state) => state.PartnersReducer?.loading ? state.PartnersReducer.loading : false);


  const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.partner_CommisionReport_Search ? state.formSlice.formSubmissionState.partner_CommisionReport_Search : false);

  const partnerType = useSelector((state) => state.formSlice?.formData?.partner_CommisionReport_Search?.partnerType ? state.formSlice.formData.partner_CommisionReport_Search.partnerType : null);


  const plan_type = useSelector((state) => state.formSlice?.formData?.partner_CommisionReport_Search?.plan_type ? state.formSlice.formData.partner_CommisionReport_Search.plan_type : null);


  const username = useSelector((state) => state.formSlice?.formData?.partner_CommisionReport_Search?.username ? state.formSlice.formData.partner_CommisionReport_Search.username : null);


  const start_date = useSelector((state) => state.formSlice?.formData?.partner_CommisionReport_Search?.start_date ? state.formSlice.formData.partner_CommisionReport_Search.start_date : null);


  const end_date = useSelector((state) => state.formSlice?.formData?.partner_CommisionReport_Search?.end_date ? state.formSlice.formData.partner_CommisionReport_Search.end_date : null);

  const affiliateTotalAmt = useSelector((state) => state.PartnersReducer?.affiliateTotalAmt ? state.PartnersReducer.affiliateTotalAmt : null);


  const pagginationTriggred = useSelector((state) => state.tableSlice?.pageState?.partnerCommisionReport ? state.tableSlice.pageState.partnerCommisionReport : null);


  const tableFeching = useSelector((state) => state.tableSlice?.loading?.partnerCommisionReport ? state.tableSlice.loading.partnerCommisionReport : false);

  // console.warn("pagginationTriggred ====>>>>>", pagginationTriggred)





  // ----- all states here ---- //
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [toogleVal, setToogleVal] = useState('this_month')
  const [filterData, setFilterData] = useState(false)
  const [filterListData, setFilterListData] = useState([])
  const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
  const [amtToogleFlag, setAmtToogleFlag] = useState(true);
  //  -------------------------- //


  // ================ setting time stamps ====================//
  const todaysStartDate = moment().startOf('day').valueOf()
  const todaysEndDate = moment().endOf('day').valueOf()


  const weekStartDate = moment().startOf('week').valueOf()
  const weekEndDate = moment().endOf('week').valueOf()


  const monthStartDate = moment().startOf('month').valueOf()
  const monthEndDate = moment().endOf('month').valueOf()
  // ======================================================== //





  useEffect(() => {


    return () => {
      store.dispatch(clearTableOtherData({ tableId: "partnerCommisionReport" }));
    }
  }, [JSON.stringify(Location.pathname)])



  var modifyTableHeaders = [
    { val: "partnerType", name: "Partner Type" },
    { val: "plan_type", name: "Plan Type" },
    { val: "unit", name: "# Of Package" },
    { val: "commision_rate", name: "Commission Rate [Flat($)]" },
    { val: "commision_amt", name: "Commission Amount ($)" },
    {
      val: "record_date", name: "Recorded On", type: "datetime",
      format: "MM/DD/YYYY",
    },




  ];

  //  ======= main table ======== //
  let tableDataForPartnerCommisionReport = useRef({
    tableId: "partnerCommisionReport",
    tableTitle: "Professional Partner Commission Report",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/partner-commission-list",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/partner-commission-list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "record_date",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "record_date"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [


    ],
    buttons: [

    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Search Commission Report",
      type: "drawer",
      //   condition_type: "$or",
      formData: {
        id: "commisionsearchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [


          {
            id: 30,
            label: "Search by Plan Type",
            name: "plan_type",
            // className: 'Status',
            type: 'select',
            multiple: false,
            values: [
              { val: "single", name: "single" },
              { val: "single+1", name: "Single+1" },
              { val: "Family", name: "Family" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "plan_type" },
          },

          {
            id: 31,
            label: "Search by Recorded on Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "record_date", param: "$gte" },
          },
          {
            id: 4,
            label: "Search by Recorded on End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "record_date", param: "$lte" },
            // minDate:{minDate}
            // rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "End Date can not be less than Start Date"
            },
          },


        ],
      },
    },
  });
  // ========================= //




  useEffect(() => {



    if (logedUserData.main_role === "is_rep") {
      let searchArr = [
        {
          id: 0,
          label: "Search By Name",
          name: "username",
          type: "autoComplete",
          // multiple: true,
          // preload:true,
          // autoStage:true,
          className: "list_search_item countDiv__column--col6 ",
          base_url:
            process.env.REACT_APP_API_URL + "bclusers/commisionusernameautocomp",
          reqBody: {
            // "formId": 'teamsearch',
            condition: {
              limit: 10,
              skip: 0,
            },
            sort: {
              type: "asc",
              field: "username",
            },
            searchcondition: {},
          },
          sx: { m: 1, minWidth: 300 },
          // payloadFormat: { key: "username", param: "$regex", options: "i" },
          payloadFormat: { key: "agent_code" },
        },

      ]



    }

  }, [])


  useEffect(() => {
    let reqBody = {
      // "formId": 'teamsearch',
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "record_date",
      },
      searchcondition: {},
    };

    // console.log("RoleList", resolve);
    if (!resolved) {

      // store.dispatch(getcommisionReportData(reqBody))

      setTimeout(() => {
        searchTable('this_month');
      }, 200);

    }


    // store.dispatch(resetresolvenew("/affiliate-report"));

    return () => {
      store.dispatch(clearPartnerReducerReportData());

    };
  }, []);








  // ============  on table filters click =========== //
  function searchTable(val) {
    setToogleVal(val);

    switch (val) {

      case 'all':
        dispatch(unmountTable({ "tableId": "partnerCommisionReport" }));
        setFilterData(false)
        setAmtToogleFlag(false)

        let payloadAll = {
          "condition": {
            "limit": 30, "skip": 0
          },
          "sort": {
            "type": "desc", "field": "record_date"
          },
          "searchcondition": {"partnerType":paramData.id}
        }

        tableDataForPartnerCommisionReport.current['reqBody']['searchcondition'] = payloadAll['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "partnerCommisionReport", "reqBody": payloadAll }))


        dispatch(partnerCommissionListData(payloadAll))

        setTimeout(() => {
          payloadAll['count'] = true
          dispatch(filterPartnerCommissionListCount(payloadAll))
        }, 200);

        break;

      case 'this_month':
        dispatch(unmountTable({ "tableId": "partnerCommisionReport" }));

        setFilterData(true)
        dispatch(partnerCommissionTotalAmount({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "partnerType":paramData.id,
            }
        }))

        setAmtToogleFlag(true)

        let payloadMonth = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": {}
        }

        payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }
        payloadMonth['searchcondition']['partnerType'] = paramData.id

        dispatch(setTableDefaultreqBody({ "tableId": "partnerCommisionReport", "reqBody": payloadMonth }))

        tableDataForPartnerCommisionReport.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

        dispatch(filterPartnerCommissionList(payloadMonth))


        setTimeout(() => {
          payloadMonth['count'] = true
          dispatch(filterPartnerCommissionListCount(payloadMonth))
        }, 200);


        break;

      case 'this_week':
        dispatch(unmountTable({ "tableId": "partnerCommisionReport" }));

        setFilterData(true)
        setAmtToogleFlag(true)
        dispatch(partnerCommissionTotalAmount({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {"partnerType":paramData?.id}
        }))


        let payloadWeek = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": {}

        }

        payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }
        payloadWeek['searchcondition']['partnerType'] = paramData?.id

        tableDataForPartnerCommisionReport.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "partnerCommisionReport", "reqBody": payloadWeek }))

        dispatch(filterPartnerCommissionList(payloadWeek))

        dispatch(clearPageState({ "tableId": "partnerCommisionReport" }))

        // dispatch(clearTableCounts({ "tableId": "partnerCommisionReport" }))

        setTimeout(() => {
          payloadWeek['count'] = true
          dispatch(filterPartnerCommissionListCount(payloadWeek))
        }, 200);


        break;

      case 'today':
        dispatch(unmountTable({ "tableId": "partnerCommisionReport" }));
        setFilterData(true)
        setAmtToogleFlag(true)

        dispatch(partnerCommissionTotalAmount({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {"partnerType":paramData?.id}
        }))


        let payloadToday = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": {}

        }

        payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }
        payloadToday['searchcondition']['partnerType'] = paramData?.id


        tableDataForPartnerCommisionReport.current['reqBody']['searchcondition'] = payloadToday['searchcondition']

        dispatch(setTableDefaultreqBody({ "tableId": "partnerCommisionReport", "reqBody": payloadToday }))


        dispatch(filterPartnerCommissionList(payloadToday))


        setTimeout(() => {
          payloadToday['count'] = true
          dispatch(FilterAffiliateReportCount(payloadToday))
        }, 200);



        break;

      default:
      // code block
    }
  }
  //  ================================== //









  // ==== setTableData === //
  useEffect(() => {

    if (filterData && commisionReportSuccess) {
      dispatch(clearCommisionReportData())
      setFilterListData(filteredReportData)
      dispatch(setTableData({ "tableId": "partnerCommisionReport", "dataset": filteredReportData }))

    }
    // JSON.stringify(filteredReportData), JSON.stringify(listData)

  }, [JSON.stringify(filterData), commisionReportSuccess])
  //  ================== //



  // ==== setTableCount === //
  useEffect(() => {
    if (filteredPartnerReportCount !== null) {
      dispatch(clearPageState({ "tableId": "partnerCommisionReport" }))
      // dispatch(clearTableCounts({ "tableId": "partnerCommisionReport" }))
      dispatch(setTableCounts({ "tableId": "partnerCommisionReport", "count": filteredPartnerReportCount }))
    }
  }, [filteredPartnerReportCount])
  // ===================== //




  //----------------------search form configration---------------------
  const dataform = useRef({
    id: 'partner_CommisionReport_Search',
    submitBtnName: "Search",
    submitBtnEndIcon: 'search_icon',
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: 'refresh',
    endPointBodyWrapper: "searchcondition",
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {},

    fields: [


      // {
      //   id: 29,
      //   label: "Search by Partner Type",
      //   name: "partnerType",
      //   // className: 'Status',
      //   type: 'select',
      //   multiple: false,
      //   values: [
      //     { val: "naka", name: "NAKA" },
      //     { val: "aspire", name: "Aspire" },
      //     { val: "bluecoast", name: "Bluecoast" },
      //   ],
      //   sx: { m: 1, minWidth: 120 },
      //   className: 'list_search_item countDiv__column--col6 ',
      //   payloadFormat: { key: "partnerType" },
      // },

      {
        id: 30,
        label: "Search by Plan Type",
        name: "plan_type",
        // className: 'Status',
        type: 'select',
        multiple: false,
        values: [
          { val: "Single", name: "Single" },
          { val: "Single+1", name: "Single+1" },
          { val: "Family", name: "Family" },
        ],
        sx: { m: 1, minWidth: 120 },
        className: 'list_search_item countDiv__column--col6 ',
        payloadFormat: { key: "plan_type" },
      },

      {
        id: 31,
        label: "Search by Recorded on Start Date",
        name: "start_date",
        className: "datePicker",
        type: "datePicker",
        sx: { m: 1, width: 300 },
        className: "list_search_item countDiv__column--col6 ",
        payloadFormat: { key: "record_date", param: "$gte" },
      },
      {
        id: 4,
        label: "Search by Recorded on End Date",
        name: "end_date",
        className: "list_search_item countDiv__column--col6 ",
        type: "datePicker",
        fullDay: true,
        sx: { m: 1, width: 300 },
        payloadFormat: { key: "record_date", param: "$lte" },
        // minDate:{minDate}
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "End Date can not be less than Start Date"
        },
      },


    ],
  })

  // ================================================== //




  // ========= endpoint call for search after search drawwer ===== //
  useEffect(() => {
    if (formSubmissionState === 4) {
      setFilterData(true);
      setToogleVal(null);
      setAmtToogleFlag(false)

      const payload = {
        "condition": { "limit": 30, "skip": 0 },
        "sort": {
          "field": "record_date",
          "type": "desc"
        },
        "searchcondition": {
          // "rep_id": logedUserData._id,
          "plan_type": plan_type !== null ? plan_type : undefined,
          "username": username !== null ? username : undefined,
          "partnerType": paramData.id !== null ? paramData.id : undefined

        },
        "project": {},
        "token": "",
        "count": false
      }

      if (start_date !== null || end_date !== null) {
        payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

      }



      tableDataForPartnerCommisionReport.current['reqBody']['searchcondition'] = payload['searchcondition']


      dispatch(filterPartnerCommissionList(payload))


      setTimeout(() => {
        payload['count'] = true
        dispatch(filterPartnerCommissionListCount(payload))
      }, 200);

      setSearchDrawer(false)
    }
  }, [formSubmissionState])
  // ================================================================= //











  return (
    <>
      <div className="rolelist_mainwrp">
        <div className='listing_heading'>
          <h1>Professional Partner Commission Report</h1>
        </div>

        <div className='listNavigate affiliate-reportlist-navigation'>

          <ul className='toogleBtns'>
            <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
            <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Commission </Button>  </li>
            <li> <Button variant="contained" onClick={() => {
              dispatch(unmountTable({ "tableId": "partnerCommisionReport" }));
              searchTable('this_month')
            }}><ReplayIcon /></Button>  </li>
          </ul>

          <ul className='total_bill_mt'>

            {toogleVal !== null && toogleVal !== "all" && !loading &&
              (<li className='double_bill'>
                <span className='total_bill_mt_text'>
                  {toogleVal === "this_month" ? "This Month" : toogleVal === "this_week" ? "This Week" : toogleVal === "today" && "Today's"} Amount
                </span>
                <span className='total_bill_mt_amount'>
                  {filteredCommisionAmount !== null ? '$' + parseFloat(filteredCommisionAmount).toFixed(2) : '$0'}
                </span>
              </li>)}

            <li className='double_bill'>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {affiliateTotalAmt !== null && amtToogleFlag ? '$' + affiliateTotalAmt?.toFixed(2) : commisionAmount !== null ? '$' + parseFloat(commisionAmount).toFixed(2) : '$0'}
              </span>
            </li>

          </ul>

        </div>



        <div className='rolelist_mainwrp_Table affiliate_report'>


          {loading && <LinearProgress />}

          {loader === false ?
            <TableContainer>
              <ListingTable
                tableData={tableDataForPartnerCommisionReport.current}
                dataset={filterData ? filterListData : listData}
                // dataset={listData}
                modifyHeaders={modifyTableHeaders}
              />
            </TableContainer> : <TableSkeleton count={5} />

          }


          {/* ==== total bill amount ===== */}
          <ul className='total_bill_mt'>

            <li>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {affiliateTotalAmt !== null && amtToogleFlag ? '$' + affiliateTotalAmt?.toFixed(2) : commisionAmount !== null ? '$' + parseFloat(commisionAmount).toFixed(2) : '$0'}
              </span>
            </li>

          </ul>
          {/* ============================ */}

        </div>








        {/* ============ search drawer ======== */}
        <Drawer
          anchor="bottom"
          open={searchDrawer}
          onClose={() => setSearchDrawer(false)}
        >
          <Paper elevation={7} className='list_search team_management_list_search search_img'>

            <div className="searchBarHead DrawerBox">
              <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Professional Partner Commission Report</h2>
              <span className="searchBarCloseIcon">
                <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
              </span>
            </div>

            <Form formData={dataform.current} />
          </Paper>
        </Drawer>
        {/* =================================== */}


      </div>

    </>
  )
}

export default PartnersCommissionReport