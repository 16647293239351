import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../../listing/listing';
import { Button, Drawer, IconButton, LinearProgress, Paper, Snackbar, TableContainer, Tooltip } from '@mui/material';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommisionReportData, getcommisionReportData } from '../../AffiliateManagement/W9FormReducer';
import store from '../../../../store';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { useLocation, useParams } from 'react-router-dom';
import { clearPageState, clearTableCounts, clearTableOtherData, setTableCount, setTableCounts, setTableData, setTableDataAndCountAndBody, setTableDefaultreqBody, setTableFieldsData, unmountTable } from '../../../../listing/listReducer';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { AffiliateReportTotalAmt, exportCSV, FilterAffiliateReport, FilterAffiliateReportCount } from '../../AffiliateManagement/AffiliateReducer';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';
import { clearPartnerReducerReportData, clearReportData, commissionTransactionalReport, filterCommissionTransactionalReport, filterCommissionTransactionalReportCount, resetdata, searchCommissionTransactional, transactionListTotalAmount } from '../PartnersReducer';
import IosShareIcon from '@mui/icons-material/IosShare';

const ComissionTransactionalReport = () => {

  const paramData = useParams();
  const Location = useLocation();
  const dispatch = useDispatch();

  const logedUserData = useSelector((state) => state.loginSlice?.userInfo ? state.loginSlice?.userInfo : {});



  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/commission-transactional-report"]
      ? state.ResolveReducer.resolved["/commission-transactional-report"]
      : false
  );




  const listAllData = useSelector((state) => state.PartnersReducer?.commissionTransactionalListData ? state.PartnersReducer.commissionTransactionalListData : []);


  const filteredReportData = useSelector((state) => state.PartnersReducer?.filterCommissionTransactionalListData ? state.PartnersReducer.filterCommissionTransactionalListData : []);

  const commisionReportSuccess = useSelector((state) => state.PartnersReducer?.filterTransactionalSuccess ? state.PartnersReducer.filterTransactionalSuccess : false);










  let commisionAmount = useSelector((state) => state.PartnersReducer.transactionTotalAmount ? state.PartnersReducer.transactionTotalAmount : null);

  const filteredCommisionAmount = useSelector((state) => state.PartnersReducer?.filteredCommissionTransactionaAmount ? state.PartnersReducer.filteredCommissionTransactionaAmount : null);

  const filteredReportCount = useSelector((state) => state.PartnersReducer?.filterTransactionalReportCount ? state.PartnersReducer.filterTransactionalReportCount : null);


  const loading = useSelector((state) => state.PartnersReducer?.loading ? state.PartnersReducer.loading : false);
  let loader = useSelector((state) => (state.AffiliateReducer?.loading) ? state.AffiliateReducer.loading : false)



  const formSubmissionState = useSelector((state) => state.formSlice?.formSubmissionState?.transactional_commission_report_Search ? state.formSlice.formSubmissionState.transactional_commission_report_Search : false);

  const partnerType = useSelector((state) => state.formSlice?.formData?.transactional_commission_report_Search?.partnerType ? state.formSlice.formData.transactional_commission_report_Search.partnerType : null);


  const plan_type = useSelector((state) => state.formSlice?.formData?.transactional_commission_report_Search?.plan_type ? state.formSlice.formData.transactional_commission_report_Search.plan_type : null);


  const username = useSelector((state) => state.formSlice?.formData?.transactional_commission_report_Search?.username ? state.formSlice.formData.transactional_commission_report_Search.username : null);


  const start_date = useSelector((state) => state.formSlice?.formData?.transactional_commission_report_Search?.start_date ? state.formSlice.formData.transactional_commission_report_Search.start_date : null);


  const end_date = useSelector((state) => state.formSlice?.formData?.transactional_commission_report_Search?.end_date ? state.formSlice.formData.transactional_commission_report_Search.end_date : null);



  const pagginationTriggred = useSelector((state) => state.tableSlice?.pageState?.ComissionTransactionalReportList ? state.tableSlice.pageState.ComissionTransactionalReportList : null);


  const tableFeching = useSelector((state) => state.tableSlice?.loading?.ComissionTransactionalReportList ? state.tableSlice.loading.ComissionTransactionalReportList : false);

  // console.warn("pagginationTriggred ====>>>>>", pagginationTriggred)

  const csvSentSuccess = useSelector((state) => state.AffiliateReducer?.csvSent ? state.AffiliateReducer.csvSent : false);

  const dataFetched = useSelector((state) => state.PartnersReducer?.dataFetched ? state.PartnersReducer.dataFetched : false);



  // ----- all states here ---- //
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [toogleVal, setToogleVal] = useState('all')
  const [filterData, setFilterData] = useState(false)
  const [ListData, setListData] = useState([])
  const [searchDrawer, setSearchDrawer] = useState(false); // snackbar const  
  const [amtToogleFlag, setAmtToogleFlag] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [listDataInsert, setListDataInsert] = useState(false);

  //  -------------------------- //


  // ================ setting time stamps ====================//
  const todaysStartDate = moment().startOf('day').valueOf()
  const todaysEndDate = moment().endOf('day').valueOf()


  const weekStartDate = moment().startOf('week').valueOf()
  const weekEndDate = moment().endOf('week').valueOf()


  const monthStartDate = moment().startOf('month').valueOf()
  const monthEndDate = moment().endOf('month').valueOf()
  // ======================================================== //







  // ===== open snackbar if csv export sucessfull ==== //
  useEffect(() => {
    if (csvSentSuccess) {
      setSnackOpen(true)
    }
  }, [csvSentSuccess])

  //  ================================================ //

  // ======== snackbar close ======= //
  function handleClose() {
    setSnackOpen(false)

  }
  // ========================= //




  useEffect(() => {
    return () => {

      store.dispatch(clearTableOtherData({ tableId: "ComissionTransactionalReportList" }));
    }
  }, [JSON.stringify(Location.pathname)])



  var modifyTableHeaders = [
    { val: "plan_type", name: "Plan Type" },
    { val: "unit", name: "# Of Package" },
    { val: "commision_rate", name: "AE Commission Rate [Flat($)]" },
    { val: "commision_amt", name: "AE Commission Amount ($)" },
    {
      val: "record_date", name: "Recorded On", type: "datetime",
      format: "MM/DD/YYYY",
    },




  ];

  //  ======= main table ======== //
  let tableDataForComissionTransactionalReport = useRef({
    tableId: "ComissionTransactionalReportList",
    tableTitle: "Commission Transactional Report",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "commission/commission-transactional-report-list",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "commission/commission-transactional-report-list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "record_date",
        type: "desc",
      },
      searchcondition: {
        "user_id": paramData.id,
        "role": "is_rep"
      },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "record_date"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [


    ],
    buttons: [

    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Search Commission Report",
      type: "drawer",
      //   condition_type: "$or",
      formData: {
        id: "commisionsearchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [


          {
            id: 30,
            label: "Search by Plan Type",
            name: "plan_type",
            // className: 'Status',
            type: 'select',
            multiple: false,
            values: [
              { val: "single", name: "single" },
              { val: "single+1", name: "Single+1" },
              { val: "Family", name: "Family" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "plan_type" },
          },

          {
            id: 31,
            label: "Search by Recorded on Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "record_date", param: "$gte" },
          },
          {
            id: 4,
            label: "Search by Recorded on End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "record_date", param: "$lte" },
            // minDate:{minDate}
            // rules: { required: true },
            errorMessage: {
              required: "This Field is Required",
              custom: "End Date can not be less than Start Date"
            },
          },


        ],
      },
    },
  });
  // ========================= //








  // ------- entrypoint triggrer ---- //
  useEffect(() => {
    setSnackOpen(false)
    if (!resolved) {

      // dispatch(partnerCommissionSummary(reqBody))

      setTimeout(() => {
        searchTable('all');
      }, 200);
    }


    return () => {
      store.dispatch(clearPartnerReducerReportData());
      setSnackOpen(false)
    };
  }, []);
  //  ------------------------ //









  // ============  on table filters click =========== //
  function searchTable(val) {
    setToogleVal(val);

    switch (val) {

      case 'all':

        dispatch(unmountTable({ "tableId": "ComissionTransactionalReportList" }));
        setFilterData(false)
        setAmtToogleFlag(false)
        setListDataInsert(false)
        setListData([])

        let payloadAll = {
          "condition": {
            "limit": 30, "skip": 0
          },
          "sort": {
            "type": "desc", "field": "record_date"
          },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep"
          }

        }


        tableDataForComissionTransactionalReport.current['reqBody']['searchcondition'] = payloadAll['searchcondition']


        dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReportList", "reqBody": payloadAll }))


        dispatch(commissionTransactionalReport(payloadAll))



        // setTimeout(() => {
        //   payloadAll['count'] = true
        //   dispatch(filterCommissionTransactionalReportCount(payloadAll))
        // }, 200);

        break;

      case 'this_month':
        dispatch(unmountTable({ "tableId": "ComissionTransactionalReportList" }));
        setListDataInsert(false)
        setFilterData(true)
        setListData([])

        dispatch(transactionListTotalAmount({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep"
          }
        }))

        setAmtToogleFlag(true)

        let payloadMonth = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": {}
        }

        payloadMonth['searchcondition']['record_date'] = { $gte: monthStartDate, $lte: monthEndDate }
        payloadMonth['searchcondition']['user_id'] = paramData.id
        payloadMonth['searchcondition']['role'] = "is_rep"



        dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReportList", "reqBody": payloadMonth }))

        tableDataForComissionTransactionalReport.current['reqBody']['searchcondition'] = payloadMonth['searchcondition']

        dispatch(filterCommissionTransactionalReport(payloadMonth))


        // setTimeout(() => {
        //   payloadMonth['count'] = true
        //   dispatch(filterCommissionTransactionalReportCount(payloadMonth))
        // }, 200);


        break;

      case 'this_week':
        dispatch(unmountTable({ "tableId": "ComissionTransactionalReportList" }));
        setListDataInsert(false)
        setFilterData(true)
        setAmtToogleFlag(true)
        dispatch(transactionListTotalAmount({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData?.id,
            "role": "is_rep"
          }
        }))


        let payloadWeek = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": {}

        }
        payloadWeek['searchcondition']['role'] = "is_rep"
        payloadWeek['searchcondition']['record_date'] = { $gte: weekStartDate, $lte: weekEndDate }
        payloadWeek['searchcondition']['user_id'] = paramData?.id

        tableDataForComissionTransactionalReport.current['reqBody']['searchcondition'] = payloadWeek['searchcondition']


        dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReportList", "reqBody": payloadWeek }))

        dispatch(filterCommissionTransactionalReport(payloadWeek))

        setListData([])


        dispatch(clearPageState({ "tableId": "ComissionTransactionalReportList" }))

        // dispatch(clearTableCounts({ "tableId": "ComissionTransactionalReportList" }))

        // setTimeout(() => {
        //   payloadWeek['count'] = true
        //   dispatch(filterCommissionTransactionalReportCount(payloadWeek))
        // }, 200);


        break;

      case 'today':
        dispatch(unmountTable({ "tableId": "ComissionTransactionalReportList" }));
        setFilterData(true)
        setAmtToogleFlag(true)
        setListDataInsert(false)

        dispatch(transactionListTotalAmount({
          "condition": { "limit": 30, "skip": 0 },
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData?.id,
            "role": "is_rep"
          }
        }))


        let payloadToday = {
          "condition": {
            "limit": 30,
            "skip": 0
          },

          "sort": {
            "type": "desc",
            "field": "record_date"
          },

          "searchcondition": {}

        }

        payloadToday['searchcondition']['role'] = "is_rep"
        payloadToday['searchcondition']['record_date'] = { $gte: todaysStartDate, $lte: todaysEndDate }
        payloadToday['searchcondition']['user_id'] = paramData?.id


        tableDataForComissionTransactionalReport.current['reqBody']['searchcondition'] = payloadToday['searchcondition']



        dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReportList", "reqBody": payloadToday }))


        dispatch(filterCommissionTransactionalReport(payloadToday))

        setListData([])


        // setTimeout(() => {
        //   payloadToday['count'] = true
        //   dispatch(filterCommissionTransactionalReportCount(payloadToday))
        // }, 200);



        break;

      default:
      // code block
    }
  }
  //  ================================== //










  // ==== setTableData with filtred data === //
  useEffect(() => {
    dispatch(resetdata())

    if (filterData && dataFetched && commisionReportSuccess && filteredReportData.length > 0) {
      setListData(filteredReportData)
      setListDataInsert(true)
    }


  }, [JSON.stringify(filteredReportData), commisionReportSuccess, filterData, dataFetched])
  //  ================== //




  // ==== setAllTableData === //
  useEffect(() => {

    dispatch(resetdata())

    if (dataFetched && listAllData.length > 0) {

      setListData(listAllData)
      setListDataInsert(true)

    }

  }, [JSON.stringify(listAllData), dataFetched])
  //  ================== //






  // ==== set final list data  === //
  useEffect(() => {
    if (dataFetched && ListData) {
      dispatch(setTableData({ "tableId": "ComissionTransactionalReportList", "dataset": ListData }))
      setListDataInsert(true)
      // console.warn("listDataInsert", listDataInsert)
    }

  }, [JSON.stringify(ListData), dataFetched])
  //  ================== //








  // ==== setTableCount === //
  useEffect(() => {
    if (filteredReportCount !== null) {
      dispatch(clearPageState({ "tableId": "ComissionTransactionalReportList" }))
      // dispatch(clearTableCounts({ "tableId": "ComissionTransactionalReportList" }))
      dispatch(setTableCounts({ "tableId": "ComissionTransactionalReportList", "count": filteredReportCount }))
    }
  }, [filteredReportCount])
  // ===================== //











  //----------------------search form configration---------------------
  const dataform = useRef({
    id: 'transactional_commission_report_Search',
    submitBtnName: "Search",
    submitBtnEndIcon: 'search_icon',
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: 'refresh',
    endPointBodyWrapper: "searchcondition",
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {},

    fields: [

      {
        id: 30,
        label: "Search by Plan Type",
        name: "plan_type",
        // className: 'Status',
        type: 'select',
        multiple: false,
        values: [
          { val: "single", name: "Single" },
          { val: "single+1", name: "Single+1" },
          { val: "Family", name: "Family" },
        ],
        sx: { m: 1, minWidth: 120 },
        className: 'list_search_item countDiv__column--col6 ',
        payloadFormat: { key: "plan_type" },
      },

      {
        id: 31,
        label: "Search by Recorded on Start Date",
        name: "start_date",
        className: "datePicker",
        type: "datePicker",
        sx: { m: 1, width: 300 },
        className: "list_search_item countDiv__column--col6 ",
        payloadFormat: { key: "record_date", param: "$gte" },
      },
      {
        id: 4,
        label: "Search by Recorded on End Date",
        name: "end_date",
        className: "list_search_item countDiv__column--col6 ",
        type: "datePicker",
        fullDay: true,
        sx: { m: 1, width: 300 },
        payloadFormat: { key: "record_date", param: "$lte" },
        // minDate:{minDate}
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "End Date can not be less than Start Date"
        },
      },


    ],
  })
  // ================================================== //




  // ========= endpoint call for search after search drawwer ===== //
  useEffect(() => {
    if (formSubmissionState === 4) {
      dispatch(unmountTable({ "tableId": "ComissionTransactionalReportList" }));

      setFilterData(true);
      setToogleVal(null);
      setAmtToogleFlag(false)
      setListDataInsert(false)
      setListData([])


      const payload = {
        "condition": { "limit": 30, "skip": 0 },
        "sort": {
          "field": "record_date",
          "type": "desc"
        },
        "searchcondition": {
          // "rep_id": logedUserData._id,
          "plan_type": plan_type !== null ? plan_type : undefined,
          "username": username !== null ? username : undefined,
          "user_id": paramData?.id ? paramData.id : undefined,
          "role": "is_rep"

        },
        "project": {},
        "token": "",
        "count": false
      }

      if (start_date !== null || end_date !== null) {
        payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

      }


      dispatch(setTableDefaultreqBody({ "tableId": "ComissionTransactionalReportList", "reqBody": payload }))



      dispatch(commissionTransactionalReport(payload))

      

      setToogleVal('search')


  

      setSearchDrawer(false)
    }
  }, [formSubmissionState])
  // ================================================================= //











  // =============== exportCSV ============= //
  function exportCSVthroughmail() {

    let payload = {}


    switch (toogleVal) {


      case 'all':
        payload = {
          "sort": {
            "type": "desc",
            "field": "record_date"
          },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep"
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "rep_commission_data",
        }

        dispatch(exportCSV(payload));
        break;

      case 'this_month':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep",
            "record_date": { $gte: monthStartDate, $lte: monthEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "commission_transactional_report_rep_admin_level"
        }

        dispatch(exportCSV(payload));

        break;

      case 'this_week':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep",
            "record_date": { $gte: weekStartDate, $lte: weekEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "commission_transactional_report_rep_admin_level"
        }

        dispatch(exportCSV(payload));

        break;

      case 'today':

        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep",
            "record_date": { $gte: todaysStartDate, $lte: todaysEndDate }
          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "commission_transactional_report_rep_admin_level"
        }

        dispatch(exportCSV(payload));
        break;

      case 'search':


        payload = {
          "sort": { "type": "desc", "field": "record_date" },
          "searchcondition": {
            "user_id": paramData.id,
            "role": "is_rep",
            "plan_type": plan_type !== null ? plan_type : undefined,

          },
          "email": logedUserData.email,
          "source": "access_h_com_datas",
          "list_ref": "commission_transactional_report_rep_admin_level"
        }

        if (start_date !== null || end_date !== null) {
          payload['searchcondition']['record_date'] = { $gte: start_date !== null ? start_date : undefined, $lte: end_date !== null ? end_date : undefined }

        }



        dispatch(exportCSV(payload));

        break;

      default:
        break;
    }

  }
  //  ================================== //











  return (
    <>
      <div className="rolelist_mainwrp">
        <div className='listing_heading'>
          <h1> {paramData.name}'s Commission Transactional Report</h1>
        </div>

        <div className='listNavigate affiliate-reportlist-navigation'>

          <ul className='toogleBtns'>
            <li> <Button variant="contained" className={toogleVal === 'all' && 'active'} onClick={() => searchTable('all')}>All</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_month' && 'active'} onClick={() => searchTable('this_month')}>This Month</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'this_week' && 'active'} onClick={() => searchTable('this_week')}>This Week</Button>  </li>
            <li> <Button variant="contained" className={toogleVal === 'today' && 'active'} onClick={() => searchTable('today')}>Today</Button>  </li>
            <li> <Button variant="contained" onClick={() => setSearchDrawer(true)} className={searchDrawer && 'active'}> Query Commission </Button>  </li>
            <li> <Button variant="contained" onClick={() => {
              dispatch(unmountTable({ "tableId": "ComissionTransactionalReportList" }));
              searchTable('all')
            }}>
              <Tooltip title="Reload" placement="bottom" className=''>
                <ReplayIcon />
              </Tooltip>
            </Button>
            </li>


            {(ListData?.length > 0) && <li>
              <Button variant="contained" onClick={() => exportCSVthroughmail()} >
                <Tooltip title="Export CSV" placement="bottom-start" className=''>
                  <IosShareIcon />
                </Tooltip>
              </Button>
            </li>}


          </ul>


          {/* ====== total billing section ======= */}
          <ul className='total_bill_mt'>

            {toogleVal !== null && toogleVal !== "all" && toogleVal !== "search" && !loading &&
              (<li className='double_bill'>
                <span className='total_bill_mt_text'>
                  {toogleVal === "this_month" ? "This Month" : toogleVal === "this_week" ? "This Week" : toogleVal === "today" && "Today's"} Amount
                </span>
                <span className='total_bill_mt_amount'>
                  {filteredCommisionAmount !== null ? '$' + parseFloat(filteredCommisionAmount).toFixed(2) : '$0'}
                </span>
              </li>)}

            <li className='double_bill'>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {commisionAmount !== null ? '$' + parseFloat(commisionAmount).toFixed(2) : '$0'}
              </span>
            </li>

          </ul>
          {/* =================================== */}

        </div>

 


        <div className='rolelist_mainwrp_Table affiliate_report'>


          {(loading || loader || listDataInsert===false)  && <LinearProgress />}




          {/*  ------- table render here ----------- */}
          { listDataInsert ?
            <TableContainer>
              <ListingTable
                tableData={tableDataForComissionTransactionalReport.current}
                dataset={ListData}
                modifyHeaders={modifyTableHeaders}
              />
            </TableContainer>
            :
            <TableSkeleton count={5} />

          }
          {/* --------------------------------------- */}






          {/* ==== total bill amount ===== */}
          <ul className='total_bill_mt'>

            <li>
              <span className='total_bill_mt_text'> Total Commission Amount</span>
              <span className='total_bill_mt_amount'>
                {/* {affiliateTotalAmt !== null && amtToogleFlag ? '$' + affiliateTotalAmt?.toFixed(2) : commisionAmount !== null ? '$' + parseFloat(commisionAmount).toFixed(2) : '$0'} */}

                {/* {console.log("parseFloat(commisionAmount).toFixed(2)",commisionAmount?.toFixed(2))} */}
                {commisionAmount !== null ? '$' + parseFloat(commisionAmount).toFixed(2) : '$0'}
              </span>
            </li>

          </ul>
          {/* ============================ */}

        </div>



        {
          snackOpen && (
            <Snackbar
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              message="CSV Data Sent Successfully. Please Check Your Mail"
            />
          )
        }




        {/* ============ search drawer ======== */}
        <Drawer
          anchor="bottom"
          open={searchDrawer}
          onClose={() => setSearchDrawer(false)}
        >
          <Paper elevation={7} className='list_search team_management_list_search search_img'>

            <div className="searchBarHead DrawerBox">
              <h2 className="searchBarHeading" style={{ textTransform: "capitalize" }}>Search Commission Transactional Report</h2>
              <span className="searchBarCloseIcon">
                <Tooltip title='Close' ><IconButton onClick={() => setSearchDrawer(false)}><CloseIcon className="closeDrawer" /></IconButton></Tooltip>
              </span>
            </div>

            <Form formData={dataform.current} />
          </Paper>
        </Drawer>
        {/* =================================== */}


      </div>

    </>
  )
}

export default ComissionTransactionalReport


