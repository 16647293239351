import { Button, LinearProgress, Modal, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react'
import { clearforceloginapidata, forcelogin } from '../../Frontend/LandingPage/LandingpageReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Cookies, useCookies } from 'react-cookie';
import { setLogedinUserInfo } from '../../login/loginReducer';
import { resolvefunction } from '../../../helper/helperFunctions';
import { getDataFoAdminDashboardCard } from './DashboardReducer';

const LogMeInConfermationModal = ({comonData,Modalstate,setModalState,Name}) => {

    const [mailRole,setMainRole] = useState("")
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    const dispatch = useDispatch();





    const logedmeInUserdata = useSelector((state) => state.LandingpagefontReducer?.forcelogindata?.item? state.LandingpagefontReducer?.forcelogindata?.item: null);
    const ipinfo = useSelector((state) =>state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0? state.loginSlice.ipInfo: null);
    const cookies = new Cookies();
    const logMeInLoader = useSelector((state) => state.LandingpagefontReducer?.loader? state.LandingpagefontReducer.loader: false);





    useEffect(()=>{
        if(Object.keys(comonData).length > 0){
            switch(comonData.main_role){
                case "hr_manager":
                    setMainRole("HM")
                    break;
                case "is_rep":
                    setMainRole("AE")
                    break;
                case "is_aer":
                    setMainRole("AEE")
                    break;
                case "is_bdd":
                    setMainRole("BDD")
                    break;
            }
        }
    },[JSON.stringify(comonData)])


    const LogintoOtherUser=(data)=>{
        setuserInfoCookie("org_userinfo", JSON.stringify(userInfo), {path: "/",});
        let body = {
          email: data.email,
          password: data.email,
          _id: data._id,
          login_time: Math.round(new Date().getTime()),
          login_data:ipinfo,
        };
        dispatch(forcelogin(body));
      }

      useEffect(()=>{
          if( logedmeInUserdata && logedmeInUserdata !== null && Object.keys(logedmeInUserdata).length > 0){
      
      
          cookies.remove("userinfo", { path: "/" });
          cookies.remove("lastLoginTime", { path: "/" });
      
      
      
          let userType = logedmeInUserdata?.main_role;
            let otherrole = logedmeInUserdata?.other_role;
            let userData = {
              userinfo: {
                ...logedmeInUserdata?.results[0],
                main_role: userType,
                other_role: otherrole,
              },
              token: userInfo.token,
              userType: userType,
              lastLoginTime: logedmeInUserdata?.results[0]?.last_login_time,
            };
            dispatch(setLogedinUserInfo(userData));
      
      
            let dataNew ={ ...logedmeInUserdata.results[0],main_role: userType,other_role: otherrole,};
            cookies.set("userinfo", dataNew, { path: "/" });
            cookies.set("lastLoginTime", new Date().getTime(), { path: "/" });
      
      
            setTimeout(() => {
              // console.log("userType11111111111", logedmeInUserdata);
              switch (logedmeInUserdata.main_role) {
                case "is_admin":
                  window.location.href = "/dashboard";
                  break;
                case "hr_manager":
                  window.location.href = "/hm-dashboard";
                  break;
                case "is_rep":
                  {
                    if (logedmeInUserdata.results[0]?.rep_with_new_dashboard) {
                      window.location.href = "/new-ae-dashboard";
                    } else {
                      window.location.href = "/ae-dashboard";
                    }
                  }
                  break;
                case "is_aer":
                  window.location.href = "/aee-dashboard";
                  break;
                case "is_bdd":
                  window.location.href = "/bdd-dashboard";
                  break;
                case "customer_support":
                  window.location.href = "/usermanagement"
                  break;
                  // default:
                  //   resolvefunction(navigate, '/dashboard', getDataFoAdminDashboardCard(reqBodyFordashboard))
              }
      
              dispatch(clearforceloginapidata());
            }, 1000);
          }
          },[logedmeInUserdata])












    const ModalClose =()=>{
        setModalState(false)
    }

    
    useEffect(()=>{
        // console.log("logedmeInUserdata",logedmeInUserdata);
    },[logedmeInUserdata])


  return (
        <>
            <Modal className="modalblock" open={Modalstate} onClose={ModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box  sx={{ position: "absolute", top: "50%", left: "50%",transform: "translate(-50%, -50%)", width: 600, bgcolor: "background.paper",border: '10px solid #0569ad!important', background:'#fff', boxShadow: 24,textAlign: "center", }}>
                    <Typography id="modal-modal-title" variant="h5" component="h5">
                        <p className='alertPara2'> Alert !!</p>
                        <p> {`Do you want to login as ${ mailRole !== undefined ? mailRole:comonData.main_role  } : ${Name}`}  </p>
                    </Typography>
                    <div style={{padding:"20px"}}>
                        <Button variant="contained" onClick={()=>LogintoOtherUser(comonData)} className="modalBTN">Yes</Button>
                        <Button variant="contained" onClick={ModalClose} className="modalBTN">No</Button>
                    </div>
                    
                    
                    
                    { logMeInLoader ? <LinearProgress sx={{ marginTop: '10px' }} />:"" }



                    <Button  onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">
                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>
        </>
  )
}

export default LogMeInConfermationModal