import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, LinearProgress, Modal, Snackbar, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import "./DebriefCallModal.css"
import { useDispatch, useSelector } from 'react-redux'
import { debriefCallAdd } from './userListReducer'

const DebriefCallModal = ({modalState,HandelClose}) => {

    const deipatch = useDispatch()

    const [formData,setFormData] = useState({'service_1':[],'service_2':[],'service_3':[],'service_4':[]})
    const [errorObject,setErrorObject] = useState({})
    const [showError,setShowError] = useState(false)

    // use selector wark space
    const debrifcallData = useSelector(state=>state.userManagment.debrifcallData?state.userManagment.debrifcallData:{})
    const debrifcallLoader = useSelector(state=>state.userManagment.debrifcallLoader?state.userManagment.debrifcallLoader:false)





    // use effects wark space 
    useEffect(()=>{
        if(debrifcallData.status === "success"){
            HandelClose()
        }
    },[debrifcallData])


    useEffect(()=>{

        let obj = {...formData}
        let errObj = {...errorObject}


        if(!obj.name){
            errObj['name'] = "Full name is required"
        }else if (obj.name){
            delete errObj['name']
        }

        if(!obj.email){
            errObj['email'] = "Email is required"
        }else if (obj.email){
            delete errObj['email']
        }

        if(!obj.phone){
            errObj['phone'] = "Phone number is required"
        }else if(obj.phone){
            delete errObj['phone']
        }

        if(obj.phone && obj.phone.length < 14){
            errObj['phone'] = "Phone number is not valid"
        }else if(obj.phone && obj.phone.length === 14){
            delete errObj['phone']
        }

        if(obj.service_1.length < 1){
            errObj['service_1'] = "Plese chose at least one option"
        }else if(obj.service_1.length > 0){
            delete errObj['service_1']
        }

        if(obj.service_2.length < 1){
            errObj['service_2'] = "Plese chose at least one option"
        }else if(obj.service_2.length > 0){
            delete errObj['service_2']
        }

        if(obj.service_3.length < 1){
            errObj['service_3'] = "Plese chose at least one option"
        }else if(obj.service_3.length >0){
            delete errObj['service_3']
        }

        if(obj.service_4.length < 1){
            errObj['service_4'] = "Plese chose at least one option"
        }else if(obj.service_4.length > 0){
            delete errObj['service_4']
        }
        
        if(!obj.first_person_plans){
            errObj['first_person_plans'] = "First person the AE plans to contact is required"
        }else if(obj.first_person_plans){
            delete errObj['first_person_plans']   
        }

        if(!obj.second_person_plans){
            errObj['second_person_plans'] = "Second person the AE plans to contact is required"
        }else if(obj.second_person_plans){
            delete errObj['second_person_plans']
        }

        setErrorObject(errObj)

    },[formData])



    // custom funtions wark space
    const handelInput=(e)=>{
        let obj = {...formData}
        obj[e.target.name] = e.target.value
        setFormData(obj)
    }

    const handelCheckBoxes=(e)=>{
        let obj = {...formData}
        let name = e.target.name

        if(e.target.checked){
            obj[name].push(e.target.value)
        }else if(!e.target.checked){
            obj[name].map((item,index)=>{
                if(item === e.target.value){
                    obj[name].splice(index, 1)
                }
        })
    }
    setFormData(obj)
  }





    const phoneFormatting = (event) => {
        if (event.target.value.split("").length <= 14) {
            var formatted = formatPhoneText(event.target.value);
            let name = event.target.name;
            setFormData({ ...formData, [name]: formatted })
        }
    }

    function formatPhoneText(value) {
        value = value.trim().replaceAll("-", "");
        value = value.trim().replaceAll("(", "");
        value = value.trim().replaceAll(")", "");
        value = value.trim().replaceAll(" ", "");
        if (value.length > 3 && value.length <= 6)
            value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
        else if (value.length > 6)
            value = "(" + value.slice(0, 3) + ")" + " " +value.slice(3, 6) + "-" + value.slice(6);
        return value;
    }






    const submitDataFuntion=()=>{
        setShowError(true)
        console.log("jgfhghgfhgf",errorObject);

        if(Object.keys(errorObject).length === 0){
            console.log("jgfhghgfhgf",errorObject);
            setShowError(false)
            deipatch(debriefCallAdd(formData))
        }
    }
    
  return (
    <>
        <Modal className="modalblock" open={modalState} onClose={HandelClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="" sx={{ position: "absolute", top: "50%", left: "50%",transform: "translate(-50%, -50%)", width: 600,bgcolor: "background.paper", border: '10px solid #0569ad!important',background:'#fff',boxShadow: 24,textAlign: "center",}}>
                <Typography id="modal-modal-title" variant="h5" component="h5">
                    <p className='header'>Training Debrief Call Form</p>
                </Typography>

                {debrifcallLoader && <Box sx={{ width: "100%" }}><LinearProgress /></Box>}

                <div className='debrief_wraper'>

                
                <FormControl>
                    
                    <div className='debrief_call'>
                        <FormLabel className='input_label'>Name</FormLabel>
                        <TextField required name='name' onChange={(e)=>handelInput(e)} label="Full Name" />
                        {(errorObject.name && showError) && <FormHelperText>{errorObject.name}</FormHelperText>}
                    </div>

                    <div className='debrief_call'>
                        <FormLabel className='input_label'>Email</FormLabel>
                        <TextField required onChange={(e)=>handelInput(e)} name='email' label="Email" />
                        {(errorObject.email && showError) && <FormHelperText>{errorObject.email}</FormHelperText>}
                    </div>

                    <div className='debrief_call'>
                        <FormLabel className='input_label'>Phone</FormLabel>
                        <TextField required onChange={(e)=>phoneFormatting(e)} value={formData.phone} name='phone' label="Phone" />
                        {(errorObject.phone && showError) && <FormHelperText>{errorObject.phone}</FormHelperText>}
                    </div>

                    <div className='debrief_call'>
                        <FormGroup>
                            <FormLabel component="legend">Service(s)*</FormLabel>
                            <FormControlLabel control={<Checkbox  name="service_1" onChange={(e)=>handelCheckBoxes(e)} value="AP General"/>} label="AP General"/>
                            {(errorObject.service_1 && showError) && <FormHelperText>{errorObject.service_1}</FormHelperText>}
                            <div className='info_text' ><FormHelperText>During the debrif call, please CONFIRM the AE Understands the following for EACH service checked above. if needed,refer to the "Quick Reference guide"</FormHelperText></div>
                          </FormGroup>
                    </div>

                    <div className='debrief_call'>
                        <FormGroup>
                        <FormLabel component="legend">*</FormLabel>
                            <FormControlLabel control={<Checkbox  name="service_2" onChange={(e)=>handelCheckBoxes(e)} value="The role of the AE (to pique interest and schedule a Discovery meeting with their contact(s))"/>} label="The role of the AE (to pique interest and schedule a Discovery meeting with their contact(s))"/>
                            <FormControlLabel control={<Checkbox  name="service_2" onChange={(e)=>handelCheckBoxes(e)} value="Key benefits of the service(s)" />} label="Key benefits of the service(s)"/>
                            <FormControlLabel control={<Checkbox  name="service_2" onChange={(e)=>handelCheckBoxes(e)} value="What are the qualification for each service(s)(HM - refer to 'Quick Reference guide' if unsure of qualifiers)"/>} label="What are the qualification for each service(s)(HM - refer to 'Quick Reference guide' if unsure of qualifiers)"/>
                            <FormControlLabel control={<Checkbox  name="service_2" onChange={(e)=>handelCheckBoxes(e)} value="The AE's next step and when they are going to reach out to their contacts" />} label="The AE's next step and when they are going to reach out to their contacts"/>
                            {(errorObject.service_2 && showError) && <FormHelperText>{errorObject.service_2}</FormHelperText>}
                        </FormGroup>
                    </div>

                    <div className='debrief_call'>
                        <FormLabel className='input_label'>First person the AE plans to contact</FormLabel>
                        <TextField required name='first_person_plans' onChange={(e)=>handelInput(e)} label="first person"/>
                        {(errorObject.first_person_plans && showError) && <FormHelperText>{errorObject.first_person_plans}</FormHelperText>}
                    </div>

                    <div className='debrief_call'>
                        <FormLabel className='input_label'>Second person the AE plans to contact</FormLabel>
                        <TextField required onChange={(e)=>handelInput(e)} name='second_person_plans' label="Second person"/>
                        {(errorObject.second_person_plans && showError)&& <FormHelperText>{errorObject.second_person_plans}</FormHelperText>}
                    </div>

                    <div className='debrief_call'>
                        <FormGroup>
                            <FormLabel component="legend">*</FormLabel>
                            <FormControlLabel control={<Checkbox  name="service_3" onChange={(e)=>handelCheckBoxes(e)} value="Steps to take when the AE's prospect wants to have a Discovery call" />} label="Steps to take when the AE's prospect wants to have a Discovery call"/>
                            <FormControlLabel control={<Checkbox  name="service_3" onChange={(e)=>handelCheckBoxes(e)} value="When to submit a lead (AFTER they introduce the service to a client)" />} label="When to submit a lead (AFTER they introduce the service to a client)"/>
                            <FormControlLabel control={<Checkbox  name="service_3" onChange={(e)=>handelCheckBoxes(e)} value="Where tofind the Lead Submission Froms (in their Member Center/AE Training Center - NOTE:Direct the AE to EXACTLY where to find the form for each service)" />} label="Where tofind the Lead Submission Froms (in their Member Center/AE Training Center - NOTE:Direct the AE to EXACTLY where to find the form for each service)"/>
                            <FormControlLabel control={<Checkbox  name="service_3" onChange={(e)=>handelCheckBoxes(e)} value="Explain what happens After they submit a lead (a BDD will reach out to the AE)"/>} label="Explain what happens After they submit a lead (a BDD will reach out to the AE)"/>
                            {(errorObject.service_3 && showError )&& <FormHelperText>{errorObject.service_3}</FormHelperText>}
                            <div className='info_text' ><FormHelperText>Other important items to review:</FormHelperText></div>
                        </FormGroup>
                    </div>

                    <div className='debrief_call'>
                        <FormGroup>
                            <FormLabel component="legend">*</FormLabel>
                            <FormControlLabel control={<Checkbox  name="service_4" onChange={(e)=>handelCheckBoxes(e)} value="Weekly call for CSI - Tuesdays at 12 ET (Zoom link can be found in AE's Quick Reference guide)" />} label="Weekly call for CSI - Tuesdays at 12 ET (Zoom link can be found in AE's Quick Reference guide)"/>
                            <FormControlLabel control={<Checkbox  name="service_4" onChange={(e)=>handelCheckBoxes(e)} value="Weekly Aspire National call - Wednesdays at 1 ET (Zoom link can be found in AE's Quick Reference Guide)"/>} label="Weekly Aspire National call - Wednesdays at 1 ET (Zoom link can be found in AE's Quick Reference Guide)"/>
                            <FormControlLabel control={<Checkbox  name="service_4" onChange={(e)=>handelCheckBoxes(e)} value="Quick Reference Guide (Plese tell then where to find it in the Member Center/Training Center)" />} label="Quick Reference Guide (Plese tell then where to find it in the Member Center/Training Center)"/>
                            <FormControlLabel control={<Checkbox  name="service_4" onChange={(e)=>handelCheckBoxes(e)} value="What they do when they have questions (contact HM not BDD). Only contact BDDs for deal specific questions" />} label="What they do when they have questions (contact HM not BDD). Only contact BDDs for deal specific questions"/>
                            {(errorObject.service_4 && showError) && <FormHelperText>{errorObject.service_4}</FormHelperText>}
                        </FormGroup>
                    </div>

                    <Button variant="contained" onClick={()=>submitDataFuntion()} >Complete Call</Button>

                </FormControl>
                </div>
            </Box>
        </Modal>

    </>
  )
}

export default DebriefCallModal