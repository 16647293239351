import {
  Button,
  InputAdornment,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  forgotPassword,
  login,
  resendOtpVerify,
  verifycodeSend,
} from "../../login/loginReducer";
import { useDispatch, useSelector } from "react-redux";

const OTPVerify = () => {
  const dispatch = useDispatch();
  const [otpVal, setOtpVal] = useState(""); //---------------otp value set in a state-------------//
  const userLoginEmail = useSelector((state) => state.loginSlice?.userInfo?.email ? state.loginSlice.userInfo.email : null);


  const handleChangeValue = (e) => {
    // console.log(e.target.value, "eeeeeeeeeeeeeeeee===>>>");
    setOtpVal(e.target.value.slice(0, 6).replace(/\D/g, ""));
  };

  // useEffect(() => {
  // // console.log("OtpVal====>>>>", otpVal);
  // }, [otpVal])

  const handleSubmit = () => {
    let reqBody = {
      email: userLoginEmail,
      code: otpVal,
    };
    dispatch(verifycodeSend(reqBody));
  };

 

  const resendCode = () => {
    let body = {
      email: userLoginEmail,
    };
    dispatch(resendOtpVerify(body));
  };

  return (
    <>
      <div className="loginBody otp_veriifcation">
        <div className="loginBox">
          <div className="loginBox_body">
            <img
              className="logo"
              src="https://allfrontend-assets.s3.amazonaws.com/Dealfirecrmlogo.png"
              alt=""
            />

            <h1>Verify Your Email</h1>
            <p>
              PLease enter the 6-digit verification code that was send to your
              email id.
            </p>
            <p>The code is valid for 15 minutes</p>

            <form>
              <p>Veirfication Code</p>
              <TextField
                margin="normal"
                fullWidth={true}
                id="otp"
                placeholder="Enter 6 digit code"
                name="otp"
                type="text"
                sx={{ textAlign: "center" }}
                autoFocus
                value={otpVal}
                onChange={handleChangeValue}
                // error={Object.keys(errors).length > 0 && errors.email != null}
                // helperText={errors.email && errorMessages[errors.email.type]}
              />
            </form>

            <Button
              className="passBttn"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              // disabled={loader}
              onClick={() => handleSubmit()}
            >
              <span> Verify </span>
            </Button>
            <span>
              Didn't receive the verification code?{" "}
              <Typography
                sx={{
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#fa7e06",
                  fontWeight: "700",
                  textTransform: "uppercase",
                }}
                onClick={() => resendCode()}
              >
                Resend again
              </Typography>
            </span>
            {/* {loader && <LinearProgress sx={{ margin: "2px" }} />} */}
          </div>
        </div>

        {/* {opensnack && message != null ? (
          <Snackbar
            className="loginSnack"
            open={opensnack}
            // open={true}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={4000}
            message={message}
            // message="login sucessfull"
            sx={{ width: "100%" }}
          />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default OTPVerify;
